import api from '@api';

export const state = {
  loading: false,
  staffs: [],
  total: 0,
};

export const getters = {
  loading: state => state.loading,
  staffs: state => state.staffs,
  total: state => state.total,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_STAFFS(state, { data = [], total = 0 }) {
    state.staffs = data;
    state.total = total;
  },
};

export const actions = {
  async getStaffs({ commit }, params) {
    try {
      commit('SET_LOADING', true);
      const res = await api.staff.getAll(params);
      commit('SET_STAFFS', res.data.staffs);
    } catch (error) {
      commit('SET_STAFFS', { data: [], total: 0 });
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getAllStaffs({ commit }) {
    try {
      commit('SET_LOADING', true);
      const res = await api.staff.getAll();
      commit('SET_STAFFS', { data: res.data.staffs });
    } catch (error) {
      commit('SET_STAFFS', { data: [], total: 0 });
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
