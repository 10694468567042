import axios from '../axios';

const BASE_URL = '/v2/staff/memo';

export default {
  getAll: (ref_type, ref_id) => {
    const params = {
      ref_type,
      ref_id,
    };
    return axios.get(BASE_URL, {
      params,
    });
  },
  get: id => axios.get(`${BASE_URL}/${id}`),
  create: data => axios.post(BASE_URL, data),
  update: data => axios.patch(`${BASE_URL}/${data.id}`, data),
  delete: id => axios.delete(`${BASE_URL}/${id}`),
};
