<template>
  <div class="custom-number-input" :class="{ withSuffix: !!suffix }">
    <label v-if="label">{{ label }}</label>
    <el-input-number
      v-bind="$attrs"
      :value="value"
      :size="size"
      @input="
        value => {
          $emit('input', value);
        }
      "
      @change="
        value => {
          $emit('change', value);
        }
      "
      @keypress.native="validateKey"
    />
    <span class="custom-number-input__suffix">
      {{ suffix }}
    </span>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: Number,
    label: String,
    size: String,
    suffix: String,
  },

  methods: {
    validateKey(e) {
      if (!e.key.replace(/\D/g, '')) e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-number-input {
  @include flex(column);
  width: 100%;
  position: relative;

  label {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 5px;
  }

  &__suffix {
    @include flex(row, center);
    position: absolute;
    color: #c0c4cc;
    top: 100%;
    height: 32px;
    right: 32px;
    transform: translateY(-100%);
  }

  /deep/ .el-input-number {
    position: relative;
    width: 100%;

    &.is-disabled /deep/ .el-input-number__decrease:hover,
    &.is-disabled /deep/ .el-input-number__increase:hover {
      background: rgba($charcoal-grey, 0.1);
    }
  }

  /deep/ .el-input-number__decrease,
  /deep/ .el-input-number__increase {
    @include flex(row, center, center);
    background: none;
    border: none;
    position: absolute;
    top: 0;
    width: 32px;
    height: 32px;
    transition: 0.15s background;

    &:hover {
      background: rgba($link-color, 0.1);
    }

    &.is-disabled:hover {
      background: rgba($charcoal-grey, 0.1);
    }
  }

  /deep/ .el-input-number__decrease {
    left: 0;
  }

  /deep/ .el-input-number__increase {
    right: 0;
  }

  /deep/ .el-input {
    @include flex(row, stretch, stretch);
    width: calc(100% - 2px);
    margin: auto;
  }

  /deep/ .el-input__inner {
    border-radius: 2px;
    height: 32px;
    padding: 0 32px;
  }

  &.withSuffix {
    /deep/ .el-input__inner {
      padding-right: 60px;
      text-align: center;
    }
  }
}
</style>
