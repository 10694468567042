import api from '@api';

const DEFAULT_PARAMS = {
  page: 1,
  limit: 12,
  filter: {
    isOnSale: '1',
    courseType: null,
    ticketType: null,
    maxTrainee: null,
    keyword: '',
  },
};

export const state = {
  params: { ...DEFAULT_PARAMS },
  loading: false,
  tickets: [],
  total: 0,
};

export const getters = {
  params: state => state.params,
  tickets: state => state.tickets,
  loading: state => state.loading,
  total: state => state.total,
};

export const mutations = {
  SET_PARAMS(state, values) {
    state.params = {
      ...state.params,
      ...values,
    };
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_TICKETS(state, { data = [], meta: { total = 0 } }) {
    state.tickets = data;
    state.total = total;
  },
};

export const actions = {
  async getTickets({ state, commit }) {
    try {
      commit('SET_LOADING', true);
      const { page, limit, filter } = state.params;
      
      const ticketTypeConverter = filter.ticketType === 'AD' ? ['T'] : filter.ticketType === 'R' ? ['RM', 'RT', 'RP'] : [filter.ticketType];

      const params = {
        page,
        limit,
        orderBy: 'desc',
        search: filter.keyword,
        is_selling_now: filter.isOnSale === 'favorite' ? null : filter.isOnSale,
        is_favorite: filter.isOnSale === 'favorite' ? 1 : null,
        class_type: filter.courseType,
        ticket_type: ticketTypeConverter,
        use_weekly_auto_coupon_balance: filter.ticketType === 'AD' ? 1 : filter.ticketType === 'T' ? 0 : null,
        max_trainee: filter.courseType === 'P' ? filter.maxTrainee : null,
        is_web: 'Y'
      };

      const res = await api.product.getAll(params);
      commit('SET_TICKETS', res.data);
    } catch (error) {
      commit('SET_TICKETS', { data: [], total: 0 });
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
