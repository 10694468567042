<template>
  <div
    class="ticket-card"
    :class="{
      P: courseType === 'P',
      G: courseType === 'G',
      I: courseType === 'I',
      clickable,
      inactive: !isTicketSelling,
      showPrice,
    }"
  >
    <div v-if="clickable" class="ticket-card__click-listener" @click="$emit('click', ticket.id)" />

    <div
      class="ticket-card__meta"
      :style="
        isTicketSelling && haveCustomColor
          ? `background-image: linear-gradient(to bottom, #${ticketColor[0]},#${ticketColor[1]})`
          : ''
      "
    >
      <div class="ticket-card__header">
        <h5>{{ ticketType }}</h5>
        <span v-if="!isTicketSelling" class="ticket-card__header__tag">
          판매정지
        </span>
        <img
          v-else-if="showFavoriteIcon"
          class="ticket-card__header__button favorite"
          :src="favoriteIcon"
          alt="수강권 즐겨찾기"
          @click.stop="$emit('favorite-icon-click', ticket.id)"
        />
      </div>
      <div class="ticket-card__body">
        <h3>{{ ticketTitle }}</h3>
        <span v-if="tutorial" class="ticket-card__body__tutorial">체험권</span>
      </div>
      <div class="ticket-card__footer">
        <p>
          <span v-for="(item, index) in ticketMeta" :key="index">{{ item }}</span>
        </p>
      </div>
    </div>

    <div v-if="showPrice" class="ticket-card__extra">
      <div class="ticket-card__extra__price">
        <p class="ticket-card__extra__price__total">판매 금액 {{ ticketPrice.total | comma }}원</p>
        <p v-if="!isMaxCoupon" class="ticket-card__extra__price__per-coupon">회당 가격 {{ ticketPrice.perCoupon | comma }}원</p>
      </div>
      <img
        v-if="showCheckbox"
        class="ticket-card__extra__checkbox"
        :src="checkboxIcon"
        alt="수강권 선택"
        @click.stop="$emit('checkbox-click', ticket)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket: { type: Object, default: () => ({}) },
    userTicket: { type: Object },
    clickable: { type: Boolean, default: false },
    showFavoriteIcon: { type: Boolean, default: true },
    showPrice: { type: Boolean, default: false },
    showCheckbox: { type: Boolean, default: false },
    isChecked: { type: Boolean, default: false },
  },

  computed: {
    courseType() {
      return _.get(this.ticket, 'available_class_type');
    },
    ticketType() {
      if (this.userTicket) {
        return this.$utils.translate.ticketType(this.ticket, this.userTicket, 'userTicket');
      } else {
        return this.$utils.translate.ticketType(this.ticket, {}, 'ticket');
      }
    },
    ticketTitle() {
      return _.get(this.ticket, 'title');
    },
    isTicketSelling() {
      return _.get(this.ticket, 'is_selling');
    },
    tutorial() {
      return _.get(this.ticket, 'is_ignore_new_payment');
    },
    haveCustomColor() {
      return this.ticket && this.ticket.colors.length;
    },
    ticketColor() {
      return _.get(this.ticket, 'colors');
    },
    isSaleProduct() {
      return _.get(this.ticket, 'type') === 'S';
    },
    isMaxCoupon() {
      return _.get(this.ticket, 'max_coupon') === 999 || _.get(this.ticket, 'max_coupon') === null;
    },

    ticketMeta() {
      if (this.isSaleProduct) return;

      const { comma } = this.$filters;
      const ticketType = comma(_.get(this.ticket, 'type'));
      const period = comma(_.get(this.ticket, 'available_period'));
      const maxCoupon = comma(_.get(this.ticket, 'max_coupon'));
      const dailyBookingChangeLimit = comma(_.get(this.ticket, 'daily_booking_change_limit'));

      let items = [];
      if (ticketType === 'RP') {
        items = [`${period}일`];
      } else if (ticketType === 'RT') {
        items = [`${maxCoupon}회`];
      } else {
        items = [`${period}일`];
      }
      if (ticketType === 'T' || ticketType === 'RM') items.push(`${maxCoupon}회`);
      if (dailyBookingChangeLimit) items.push(`당일변경 ${dailyBookingChangeLimit}`);
      return items;
    },

    ticketPrice() {
      const maxCoupon = _.get(this.ticket, 'max_coupon');
      const actualPrice = _.get(this.ticket, 'actual_price', 0);
      return {
        total: actualPrice,
        perCoupon: parseInt(actualPrice / maxCoupon),
      };
    },

    favoriteIcon() {
      const filename = !this.ticket.favorite_stamp ? 'star-filled.svg' : 'star-outlined.svg';
      return require(`@/assets/images/${filename}`);
    },

    checkboxIcon() {
      const prefix = this.isChecked ? 'checked' : 'unchecked';
      return require(`@/assets/images/icons/checkbox_circle_${prefix}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-card {
  @include flex(column);
  border-radius: 8px;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  position: relative;
  width: 256px;

  &__meta {
    @include flex(column);
    width: 100%;
    height: 144px;
    border-radius: 8px;
    padding: 12px;
    position: relative;
  }

  &.showPrice &__meta {
    border-radius: 8px 8px 0 0;
  }

  &.P &__meta {
    background-image: linear-gradient(to bottom, rgba($pink, 0), rgba($pink, 0.7)),
      linear-gradient(to bottom, $light-purple, $light-purple);
  }

  &.P &__header__tag {
    color: $light-purple;
  }

  &.G &__meta,
  &.I &__meta {
    background-image: linear-gradient(122deg, rgba($light-purple, 0), rgba($light-purple, 0.7)),
      linear-gradient(to bottom, $deep-sky-blue, $deep-sky-blue);
  }

  &.G &__header__tag,
  &.I &__header__tag {
    color: $deep-sky-blue;
  }

  &.clickable:hover {
    box-shadow: 1px 2px 7px 0 rgba(#000, 0.5);
    transform: translateY(-1px);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }

  &.inactive &__meta {
    background-image: linear-gradient(122deg, rgba(#fff, 0.2), rgba(#fff, 0)), linear-gradient(to bottom, #000, #000);
  }

  &.inactive &__header__tag {
    color: #000;
  }

  &__click-listener {
    background-image: linear-gradient(to bottom, rgba(white, 0.2), rgba(white, 0));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
  }

  &.clickable:hover &__click-listener {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }

  &__header {
    @include flex(row, center, space-between);
    margin-bottom: 4px;

    h5 {
      color: white;
      font-size: 12px;
      font-weight: 400;
    }

    &__tag {
      width: 48px;
      height: 16px;
      background-color: #fff;
      border-radius: 8px;
      font-size: 10px;
      font-weight: 500;
      line-height: 1;
      @include flex(row, center, center);
    }

    &__button {
      cursor: pointer;
      z-index: 10;

      &.favorite {
        transition: 0.15s opacity;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &__body {
    flex: 1;
    @include flex(row, flex-start, space-between);

    h3 {
      flex: 1;
      color: white;
      font-size: 18px;
      font-weight: 500;
      max-height: 54px;
      overflow: hidden;
      white-space: normal;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__tutorial {
      width: 48px;
      height: 16px;
      border-radius: 2px;
      border: solid 1px #fff;
      color: #fff;
      font-size: 10px;
      font-weight: 400;
      text-align: center;
      margin: 6px 0 4px 8px;
      line-height: 1;
      font-family: 'Noto Sans KR', sans-serif;
      @include flex(row, center, center);
    }
  }

  &__footer {
    color: white;
    font-size: 12px;

    p {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      span + span::before {
        content: ' · ';
      }
    }
  }

  &__extra {
    @include flex(row, center, space-between);
    padding: 12px;
    height: 64px;

    &__price {
      display: grid;
      grid-template-rows: 20px 15px;
      grid-gap: 5px;
      align-items: center;

      &__total {
        font-size: 14px;
        font-weight: 500;
      }

      &__per-coupon {
        color: #333;
        font-size: 10px;
      }
    }

    &__checkbox {
      align-self: flex-end;
      cursor: pointer;
      transition: 0.15s opacity;
      z-index: 10;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
