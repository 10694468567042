<template>
  <section class="content-header-tabs">
    <ul class="content-header-tabs__tabs">
      <li
        v-for="tab in tabs"
        :key="tab.path"
        :class="{
          'is-active': activeTab === tab.path,
          disabled: tab.path === '/users' && !canViewMembers,
        }"
        @click="handleClickTab(tab.path)"
      >
        {{ tab.label }}
      </li>
    </ul>
    <div>
      <el-button
        v-if="handleExcelDownloadClick"
        v-loading="exceldownloading"
        :disabled="exceldownloading"
        type="primary"
        size="medium"
        @click="handleExcelDownloadClick"
        >엑셀다운로드</el-button
      >
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tabs: Array,
    activeTab: String,
    exceldownloading: Boolean,
    handleExcelDownloadClick: Function,
  },

  methods: {
    handleClickTab(path) {
      if (path === '/users' && !this.canViewMembers) return;
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-header-tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;

  ul {
    display: flex;

    li {
      cursor: pointer;
      font-size: 28px;
      font-weight: 700;
      opacity: 0.2;
      transition: 0.15s;

      &:hover {
        opacity: 0.5;
      }

      &.is-active {
        opacity: 1;
      }

      &.disabled {
        cursor: not-allowed;

        &:hover {
          opacity: 0.2;
        }
      }

      & + li {
        margin-left: 12px;
      }
    }
  }
}
</style>
