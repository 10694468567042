<template>
  <div class="product-user-tickets" v-loading="loading">
    <el-table :data="userTickets" @row-click="handleClickRow" fit>
      <el-table-column label="회원" min-width="80" header-align="center" align="center">
        <div class="name-wrapper" slot-scope="scope">
          <span>{{ getMemberName(scope.row) }}</span>
          <vaccine-image v-if="getVaccination(scope.row)" />
        </div>
      </el-table-column>

      <el-table-column label="전화번호" min-width="100" header-align="center" align="center">
        <template slot-scope="scope">{{ getMemberMobile(scope.row) }}</template>
      </el-table-column>

      <el-table-column :label="`${title}정보`" min-width="250" header-align="center">
        <template slot-scope="scope" v-if="!isSaleProduct(scope.row)">
          <div class="ticket-info-wrapper" v-if="!isRentalOnlyTime(scope.row)">
            <el-tag size="small" type="info">
              {{ getStartOn(scope.row) | date }} ~
              {{ getEndOn(scope.row) | date }}
            </el-tag>
            <el-tag size="small" type="info">{{ getRemainingDays(scope.row) }}</el-tag>
          </div>
          <div class="ticket-info-wrapper" v-if="isNotPeriodType(scope.row)">
            <el-tag size="small" type="info">{{ getRemainingCount(scope.row) }}</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="결제정보" min-width="150" header-align="center">
        <template slot-scope="scope">
          <el-tag size="small" type="info">{{ getPaymentTotal(scope.row) | comma }}원</el-tag>
          <el-tag v-if="!!getPaymentTotal(scope.row)" size="small" type="info">{{ getPaymentMethod(scope.row) }}</el-tag>
          <el-tag v-if="!!getPaymentTotal(scope.row) && getPaymentMethod(scope.row) !== '현금'" size="small" type="info">{{
            getInstallmentPeriod(scope.row)
          }}</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="product-user-tickets__pagination"
      @current-change="dispatchGetProductUserTickets"
      :current-page.sync="page"
      :page-size="limit"
      layout="prev, pager, next"
      :total="total"
      hide-on-single-page
    />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  computed: {
    ticket() {
      return this.$store.getters['ticket/ticket'];
    },
    loading() {
      return this.$store.getters['ticket/userTicketsLoading'];
    },
    userTickets() {
      return this.$store.getters['ticket/userTickets'];
    },
    total() {
      return this.$store.getters['ticket/userTicketsTotal'];
    },
    params() {
      return this.$store.getters['ticket/userTicketsParams'];
    },

    ticketType() {
      return _.get(this.ticket, 'type');
    },

    page: {
      get() {
        return this.params.page;
      },
      set(page) {
        this.$store.commit('ticket/SET_USER_TICKETS_PARAMS', { page });
      },
    },

    limit: {
      get() {
        return this.params.limit;
      },
      set(limit) {
        this.$store.commit('ticket/SET_USER_TICKETS_PARAMS', { limit });
      },
    },
  },

  methods: {
    getMemberName(row) {
      return _.get(row, 'member.name');
    },

    getVaccination(row) {
      const vaccine = _.get(row, 'member.vaccination_yn');
      return vaccine === 'Y';
    },

    getMemberMobile(row) {
      const mobile = _.get(row, 'member.mobile');
      if (!mobile) return null;

      return this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);
    },

    isNotPeriodType(row) {
      const ticketType = _.get(row, 'ticket.type');
      return ticketType === 'T' || ticketType === 'RT' || ticketType === 'RM';
    },

    isRentalOnlyTime(row) {
      const ticketType = _.get(row, 'ticket.type');
      return ticketType === 'RT';
    },

    isSaleProduct(row) {
      const ticketType = _.get(row, 'ticket.type');
      return ticketType === 'S';
    },

    getStartOn(row) {
      return _.get(row, 'availability_start_at');
    },

    getEndOn(row) {
      return _.get(row, 'expire_at');
    },

    getRemainingDays({ expire_at, availability_start_at }) {
      return this.$utils.getRemainingDaysText(expire_at, availability_start_at);
    },

    getRemainingCount(row) {
      const remainingCoupon = _.get(row, 'remaining_coupon') || 0;
      const maxCoupon = _.get(row, 'max_coupon') || 0;
      return `잔여횟수 ${remainingCoupon}/${maxCoupon}`;
    },

    getPaymentTotal({ payments }) {
      return payments.reduce((sum, payment) => sum + payment.amount, 0);
    },

    getPaymentMethod({ payments }) {
      const paymentSum = payments.reduce(
        (sum, payment) => {
          return {
            amount: sum.amount + payment.amount,
            card_amount: sum.card_amount + payment.card_amount,
            cash_amount: sum.cash_amount + payment.cash_amount,
            wiretransfer_amount: sum.wiretransfer_amount + payment.wiretransfer_amount,
          };
        },
        { card_amount: 0, cash_amount: 0, wiretransfer_amount: 0, amount: 0 },
      );

      return this.$utils.translate.paymentMethod(this.$utils.getPaymentMethod(paymentSum));
    },

    getInstallmentPeriod({ payments }) {
      const lastPayment = payments[payments.length - 1];
      if (!lastPayment) return null;

      const installmentPeriod = _.get(lastPayment, 'installment_period');
      return installmentPeriod <= 1 ? '일시불' : `${installmentPeriod}개월`;
    },

    dispatchGetProductUserTickets() {
      this.$store.dispatch('ticket/getUserTickets', {
        ticketId: this.ticket.id,
        params: this.userTicketsParams,
      });
    },

    handleClickRow(row) {
      const memberId = _.get(row, 'member.id');
      if (this.canViewMembers) {
        this.$router.push(`/users/detail?id=${memberId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-user-tickets {
  @include flex(column, center, center);
  width: 100%;

  span + span {
    margin-left: 4px;
  }

  .ticket-info-wrapper {
    display: inline-block;
    margin-right: 4px;
  }

  /deep/ .el-table__body tr {
    cursor: pointer;
  }

  &__pagination {
    padding: 20px 0;
  }
}
.name-wrapper {
  span {
    text-align: left;
  }
}
</style>
