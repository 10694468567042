<template>
  <section class="auto-message-list">
    <div class="auto-message-list__header">
      <div class="auto-message-list__header__selects">
        <el-select
          :value="getFilter(FILTERS.TYPE)"
          :placeholder="FILTERS.TYPE.placeholder"
          filterable
          :class="{ 'is-filtered': !!getFilter(FILTERS.TYPE) }"
          @change="value => setFilter(FILTERS.TYPE, value)"
        >
          <el-option
            v-for="(option, optionKey) in autoMessageUnitOptions"
            :key="optionKey"
            :value="option.value || option.message_type"
            :label="option.label || option.unitTypeDescription"
          >
          </el-option>
        </el-select>

        <el-date-picker
          :value="[getFilter(FILTERS.START), getFilter(FILTERS.END)]"
          type="daterange"
          range-separator="-"
          :start-placeholder="FILTERS.START.placeholder"
          :end-placeholder="FILTERS.END.placeholder"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          :clearable="false"
          required
          @input="setDateFilter"
        />
      </div>

      <TextInput
        style="width: 200px;"
        v-model="search"
        :placeholder="FILTERS.USER.placeholder"
        prefix-icon="el-icon-search"
        round
        @enter="() => setFilter(FILTERS.USER, search)"
      />
    </div>

    <el-table
      v-loading="loadingMessages"
      :data="messages"
      :row-style="{ cursor: 'pointer', height: '40px' }"
      @row-click="handleClickRow"
      fit
    >
      <template slot="empty">
        <div>{{ loadingMessages ? '' : '데이터 없음' }}</div>
      </template>
      <el-table-column label="발송시간" align="center" min-width="120">
        <template slot-scope="scope">
          {{ getTime(scope.row.created_at) }}
        </template>
      </el-table-column>

      <el-table-column label="알림 종류" align="center" min-width="100">
        <template slot-scope="scope">
          {{ convertOptionEngToKor(scope.row.message_type) }}
        </template>
      </el-table-column>

      <el-table-column label="메시지" header-align="center" min-width="450">
        <template slot-scope="scope">
          <p class="auto-message-list__title">
            {{ scope.row.context }}
          </p>
        </template>
      </el-table-column>

      <el-table-column label="받는 사람" align="center" min-width="100">
        <template slot-scope="scope">
          {{ scope.row.member.name }}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="auto-message-list__pagination"
      v-show="meta.last_page > 1"
      @current-change="page => setFilter(FILTERS.PAGE, page)"
      :current-page="meta.current_page"
      :page-size="DEFAULT_LIMIT"
      layout="prev, pager, next"
      :total="meta.total"
    />
  </section>
</template>

<script>
import moment from 'moment';

const FILTERS = {
  TYPE: {
    key: 'message-type',
    placeholder: '알림 종류 전체',
  },
  START: {
    key: 'start-date',
    placeholder: '이용 시작일',
    default: moment().format('YYYY-MM-01'),
  },
  END: {
    key: 'end-date',
    placeholder: '이용 종료일',
    default: moment().format('YYYY-MM-DD'),
  },
  USER: {
    key: 'search',
    placeholder: '회원 이름으로 검색',
  },
  PAGE: {
    key: 'page',
    default: 1,
  },
};

const DEFAULT_LIMIT = 10;

export default {
  data() {
    return {
      loadingMessages: false,
      messages: [],
      meta: {
        current_page: 1,
        last_page: 1,
        total: 0,
      },
      search: '',
    };
  },

  computed: {
    autoMessageUnitOptions() {
      const initialOption = [{ value: '', label: FILTERS.TYPE.placeholder }];
      return [...initialOption, ...this.$store.getters['autoMessageNotification/autoMessageUnitOptions']];
    },
    currentQuery() {
      return this.$utils.uriQuery.getInitializedQuery(this, FILTERS);
    },
  },

  created() {
    this.FILTERS = FILTERS;
    this.DEFAULT_LIMIT = DEFAULT_LIMIT;
    this.setQuery = changed => this.$utils.uriQuery.setQuery(this, changed);
    this.getMessages();
  },

  methods: {
    async getMessages() {
      this.loadingMessages = true;
      try {
        const params = { limit: this.DEFAULT_LIMIT, ...this.currentQuery };
        const { data } = await this.$api.message.automessage.getAll(params);
        this.messages = data.data;
        this.meta = data.meta;
        this.search = params.search || '';
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loadingMessages = false;
      }
    },

    handleClickRow(row) {
      this.$router.push({
        path: `/message/auto-message/${row.id}`,
        query: this.$route.query,
      });
    },

    convertOptionEngToKor(option) {
      const res = this.autoMessageUnitOptions.filter(o => o.message_type === option)[0];
      return res?.unitTypeDescription;
    },
    getTime(timeString) {
      return moment(timeString).format('YYYY.MM.DD. HH:mm');
    },
    getFilter(option) {
      return this.currentQuery[option.key];
    },
    setFilter(option, value) {
      this.setQuery({ [option.key]: value });
      this.getMessages();
    },
    setDateFilter([start, end]) {
      this.setQuery({
        [FILTERS.START.key]: start,
        [FILTERS.END.key]: end,
      });
      this.getMessages();
    },
  },
};
</script>

<style lang="scss" scoped>
.auto-message-list {
  @include flex(column, center, center);

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    &__selects {
      @include flex(row, center, flex-start);
      /deep/ .el-select {
        width: 190px;
        margin-right: 8px;
      }

      /deep/ .el-input__inner {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        height: 40px;
        padding: 0 15px;
        color: #5d646b;
      }

      /deep/ .el-date-editor {
        max-width: 230px;
      }

      /deep/ .el-range-separator {
        line-height: 3;
      }

      /deep/ .el-range-input:nth-child(2) {
        margin-left: 10px;
      }

      /deep/ .el-range__close-icon {
        display: none;
      }

      p {
        color: #64aeff;
        font-weight: bold;
        font-size: 16px;
        margin-left: 20px;
      }
    }
  }

  &__title {
    @include ellipsis;
  }

  &__pagination {
    margin-top: 20px;
  }

  .is-filtered {
    color: $color-primary;

    /deep/ .el-input__inner {
      color: $color-primary;
    }
  }
}
</style>
