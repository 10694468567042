<template>
  <div class="container">
    <TrendChartNew
      :category="category"
      :categoryOptions="categoryOptions"
      :chartData="trendChartData"
      :changeFilter="changeFilter"
      v-loading="loading"
    />
    <CategoryChartNew
      v-for="(categoryChartData, i) in categoryChartDatas"
      :title="categoryChartData.title"
      :chartData="categoryChartData.categoryChartData"
      :key="i"
      v-loading="loading"
    />
  </div>
</template>

<script>
import { PAYMENT_VAR } from '@constants';
import moment from 'moment';
import TrendChartNew from '@components/Sales/TrendChartNew';
import CategoryChartNew from '@components/Sales/CategoryChartNew';
export default {
  components: {
    TrendChartNew,
    CategoryChartNew,
  },
  data() {
    return {
      loading: false,
      wholeData: [],
      category: 'lectureType',
      categoryOptions: [
        { value: 'lectureType', label: '수업 구분' },
        { value: 'ticketType', label: '수강권 유형' },
        { value: 'ticketId', label: '수강권명' },
        { value: 'staffId', label: '강사명' },
      ],
      filterOptions: {
        lectureType: [
          { value: 'G', label: '그룹' },
          { value: 'P', label: '프라이빗' },
        ],
        ticketType: [
          { value: 'T', label: '횟수제' },
          { value: 'D', label: '차감제' },
          { value: 'P', label: '기간제' },
        ],
        ticketId: [],
        staffId: [],
      },
    };
  },

  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },
    refreshAll() {
      return this.$store.getters['salesNew/refreshAll'];
    },
    trendStartDate() {
      if (this.date.rangeType === 'day') {
        return moment(this.date.date)
          .subtract(6, 'day')
          .format('YYYY-MM-DD');
      } else if (this.date.rangeType === 'week') {
        return moment(this.date.date)
          .subtract(4, 'week')
          .format('YYYY-MM-DD');
      } else {
        return moment(this.date.date)
          .subtract(3, 'month')
          .format('YYYY-MM-DD');
      }
    },

    trendDateRanges() {
      const { rangeType } = this.date;
      const dateRanges = [];
      if (rangeType === 'day') {
        for (let i = 0; i < 7; i++) {
          dateRanges.push({
            start: moment(this.trendStartDate)
              .add(i, 'day')
              .format('YYYY-MM-DD'),
            end: moment(this.trendStartDate)
              .add(i, 'day')
              .format('YYYY-MM-DD'),
          });
        }
      } else if (rangeType === 'week') {
        for (let i = 0; i < 5; i++) {
          dateRanges.push({
            start: moment(this.trendStartDate)
              .add(i, 'week')
              .format('YYYY-MM-DD'),
            end: moment(this.trendStartDate)
              .add(i, 'week')
              .add(6, 'day')
              .format('YYYY-MM-DD'),
          });
        }
      } else {
        for (let i = 0; i < 4; i++) {
          dateRanges.push({
            start: moment(this.trendStartDate)
              .add(i, 'month')
              .format('YYYY-MM-DD'),
            end: moment(this.trendStartDate)
              .add(i + 1, 'month')
              .subtract(1, 'day')
              .format('YYYY-MM-DD'),
          });
        }
      }
      return dateRanges;
    },

    trendLabels() {
      const { rangeType } = this.date;
      if (rangeType === 'day') {
        return this.trendDateRanges.map(range => moment(range.start).format('M월 D일 (ddd)'));
      } else if (rangeType === 'week') {
        return this.trendDateRanges.map(
          range => `${moment(range.start).format('M월 D일')} ~ ${moment(range.end).format('M월 D일')}`,
        );
      } else {
        return this.trendDateRanges.map(range => moment(range.start).format('YY년 M월'));
      }
    },

    trendDatasets() {
      const types = this.filterOptions[this.category];
      const datasets = types.map((type, i) => {
        const typeDatas = this.wholeData.filter(data => data[this.category] === type.value);

        const datas = this.trendDateRanges.map(range =>
          typeDatas
            .filter(
              typeData =>
                moment(typeData.settlementAt).isAfter(moment(range.start)) &&
                moment(typeData.settlementAt).isBefore(moment(range.end).endOf('day')),
            )
            .reduce((prev, cur) => {
              return prev + cur.deductedAmount;
            }, 0),
        );
        return {
          backgroundColor: this.$utils.salesColors[this.category][i % 11],
          data: datas,
          label: type.label,
          stack: 1,
          type: 'bar',
          yAxisID: 'amount',
        };
      });
      return datasets;
    },

    trendChartData() {
      return { labels: this.trendLabels, datasets: this.trendDatasets };
    },

    categoryChartDatas() {
      const curData = this.wholeData.filter(
        dataItem =>
          moment(dataItem.settlementAt).isAfter(moment(this.date.date)) &&
          moment(dataItem.settlementAt).isBefore(moment(this.date.endDate).endOf('day')),
      );
      const data = Object.keys(this.filterOptions).map(type => {
        const labels = this.filterOptions[type].map(option => option.label);

        const countData = this.filterOptions[type].map(
          option => curData.filter(dataItem => dataItem[type] === option.value).length,
        );
        const amountData = this.filterOptions[type].map(option =>
          curData.filter(dataItem => dataItem[type] === option.value).reduce((prev, cur) => prev + cur.deductedAmount, 0),
        );

        const datasets = {
          backgroundColor: this.$utils.salesColors[type],
          countData: countData,
          data: amountData,
          type: 'pie',
        };
        return { title: this.getTitle(type), categoryChartData: { labels, datasets: [datasets] } };
      });
      return data;
    },
  },

  watch: {
    date: function() {
      this.fetchData();
    },
    refreshAll: function(value) {
      if (value) {
        this.fetchData();
      }
    },
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async getLectures(startDate, endDate) {
      const params = {
        start_date: startDate,
        end_date: endDate,
      };
      const lectures = await this.$utils.promiseAllData(this.$api.salesNew.getLectures, params);
      return lectures;
    },

    async getDeducts(startDate, endDate) {
      const params = {
        start_date: startDate,
        end_date: endDate,
      };
      const deducts = await this.$utils.promiseAllData(this.$api.salesNew.getDeducted, params);

      return deducts;
    },

    getTotalAmount(maxCoupon, payments) {
      const exceptTotalPaymentStatus = ['installment_payment', 'full_payment', 'refund'];
      const { total } = payments.reduce(
        ({ beforeAmount, total }, payment) => {
          if (payment.status === PAYMENT_VAR.transfer) {
            total = beforeAmount + payment.before_user_ticket_amount;
          } else if (exceptTotalPaymentStatus.indexOf(payment.status) === -1 || !payment.status) {
            // !payment.status는 회원 생성시에 수강권 등록할 때
            total = beforeAmount + payment.amount + payment.unpaid_amount;
          }
          beforeAmount = total;

          return { beforeAmount, total };
        },
        { beforeAmount: 0, unpaid: 0, total: 0 },
      );

      return { total, perCoupon: total / maxCoupon };
    },

    async fetchData() {
      try {
        this.loading = true;

        const lectures = await this.getLectures(this.trendStartDate, this.date.endDate);
        const deducts = await this.getDeducts(this.trendStartDate, this.date.endDate);
        const staffs = [];
        const staffIdSet = new Set();
        const tickets = [];
        const ticketIdSet = new Set();

        const wholeData = [...lectures, ...deducts].map(item => {
          const lectureType = item.ticket.class_type;
          const ticketType = item.status ? item.ticket.type : 'D';
          const staffId = item.lecture?.staff_id;
          const ticketId = item.ticket.id;
          const isPeriod = item.ticket.type === 'P';
          const lectureTime = item.status ? { startOn: item.lecture.start_on, endOn: item.lecture.end_on } : null;
          const memberName = item.member_name;
          const ticketName = item.ticket.name;
          const staffName = item.status ? item.lecture.staff_name : '-';
          const status = item.status;
          const settlementAt = item.status ? item.lecture.start_on : item.created_at;
          const maxCoupon = item.ticket.max_coupon;

          const { total: totalAmount, perCoupon: oneTimeAmount } = this.getTotalAmount(maxCoupon, item.payments);
          const deductedCount = item.coupon_deduction;
          const deductedAmount = deductedCount * oneTimeAmount;
          const cumulativeCount = item.cumulative.booked + item.cumulative.deducted;
          const cumulativeAmount = cumulativeCount * oneTimeAmount;
          const remainingCount = maxCoupon - cumulativeCount;
          const remainingAmount = remainingCount * oneTimeAmount;

          if (
            moment(settlementAt).isAfter(moment(this.date.date)) &&
            moment(settlementAt).isBefore(moment(this.date.endDate).endOf('day'))
          ) {
            if (!ticketIdSet.has(ticketId)) {
              tickets.push({ value: ticketId, label: ticketName });
              ticketIdSet.add(ticketId);
            }
            if (staffId && !staffIdSet.has(staffId)) {
              staffs.push({ value: staffId, label: staffName });
              staffIdSet.add(staffId);
            }
          }

          return {
            lectureType,
            ticketType,
            lectureTime,
            memberName,
            staffName,
            ticketName,
            isPeriod,
            status,
            maxCoupon,
            totalAmount,
            oneTimeAmount: ticketType === 'P' ? 0 : Math.ceil(oneTimeAmount),
            deductedCount: ticketType === 'P' ? 0 : deductedCount,
            deductedAmount: ticketType === 'P' ? 0 : Math.ceil(deductedAmount),
            cumulativeCount: ticketType === 'P' ? 0 : cumulativeCount,
            cumulativeAmount: ticketType === 'P' ? 0 : Math.ceil(cumulativeAmount),
            remainingCount: ticketType === 'P' ? 0 : remainingCount,
            remainingAmount: ticketType === 'P' ? 0 : Math.ceil(remainingAmount),
            settlementAt,
            staffId,
            ticketId,
          };
        });
        this.wholeData = wholeData;
        this.filterOptions.ticketId = tickets;
        this.filterOptions.staffId = staffs;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.$store.dispatch('salesNew/setRefreshAll', false);
        this.loading = false;
      }
    },

    changeFilter(value) {
      this.category = value;
    },

    getTitle(type) {
      return this.categoryOptions.find(option => option.value === type).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: auto;
  padding: 0 30px 30px 30px;
  max-width: 1440px;
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  & > *:first-child {
    grid-column: 1 / 3;
  }

  @include mq-max(968px) {
    grid-template-columns: repeat(1, 1fr);
    & > *:first-child {
      grid-column: 1 / 2;
    }
  }
}
</style>
