import api from '@api';
import moment from 'moment';

const DEFAULT_STATE = {
  rangeFilter: {
    type: 'date',
    dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  },
  histories: [],
  total: 0,
  params: {},
  sortType: 'ascending',
  staffFilterList: [],
  searchName: '',
  limit: 10,
  page: 1,
  loading: false,
};

export const state = {
  ...DEFAULT_STATE,
};

export const getters = {
  histories: state => state.histories,
  total: state => state.total,
  rangeFilter: state => state.rangeFilter,
  limit: state => state.limit,
  page: state => state.page,
  searchName: state => state.searchName,
  staffFilterList: state => state.staffFilterList,
  params: state => state.params,
  sortType: state => state.sortType,
  loading: state => state.loading,
};

export const mutations = {
  SET_HISTORIES(state, payload) {
    if (!payload) return;
    state.histories = [...payload.data];
    state.total = payload.meta.total;
  },

  SET_TOTAL(state, total) {
    state.total = total || 1;
  },

  SET_PARAMS(state, params) {
    state.params = params;
  },

  SET_DATE_FILTER(state, rangeFilter) {
    state.rangeFilter = { ...rangeFilter };
  },

  SET_PAGE(state, page) {
    state.page = page;
  },

  SET_SIZE(state, limit) {
    state.limit = limit;
  },

  SET_SEARCH_NAME(state, search) {
    state.searchName = search;
  },

  SET_STAFF_FILTER_LIST(state, staffList) {
    state.staffFilterList = [...staffList];
  },

  SET_RESET(state) {
    Object.assign(state, DEFAULT_STATE);
  },

  SET_SORT_TYPE(state, sort) {
    state.sortType = sort;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async getHistories({ commit }, params) {
    try {
      commit('SET_LOADING', true);

      const { data: res } = await api.userTicket.getStudioTicketHistory(params);

      commit('SET_TOTAL', res.meta.total);
      commit('SET_HISTORIES', res);
    } catch (error) {
      commit('SET_HISTORIES', null);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
