/**
 * 브라우저 확인
 * @return {Boolean} is_mobile
 * @return {Boolean} is_chrome
 */
export default () => {
  const vender = window.navigator.vendor;
  const is_chromium = window.chrome;
  const is_opera = typeof window.opr !== 'undefined';
  const is_ie_edge = window.navigator.userAgent.indexOf('Edge') > -1;

  /** 모바일 체크 */
  let is_mobile = false;
  if (/Android|webOS|BlackBerry|IEMobile|Opera Mini|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    is_mobile = true;
  }

  /** 브라우저 체크 */
  let is_chrome = false;
  if (
    is_chromium !== null &&
    typeof is_chromium !== 'undefined' &&
    vender === 'Google Inc.' &&
    is_opera === false &&
    is_ie_edge === false
  ) {
    is_chrome = true;
  }

  return { is_mobile, is_chrome };
};
