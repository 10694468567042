import api from '@api';
import store from '@store';
import { NOTIFICATION_ACTION_TYPES } from '@constants';
import getNearestLectureIdOfCourse from './getNearestLectureIdOfCourse';

export default async notification => {
  const { msg_type, ref_type, ref_id, ref } = notification;

  const actionType = NOTIFICATION_ACTION_TYPES[msg_type];
  let link = '';

  /**
   * 해당 수업으로 이동
   * -> 예약, 예약 취소, 예약 대기
   */
  if (actionType === 'REDIRECT_TO_LECTURE') {
    const lectureId = ref.lecture_id || ref_id;
    link = `/lecture/detail?id=${lectureId}`;

    /**
     * 가장 가까운 수업으로 이동
     * -> 일괄 예약
     */
  } else if (actionType === 'REDIRECT_TO_NEAREST_LECTURE') {
    const res = await api.course.get(ref_id);
    const lectureId = getNearestLectureIdOfCourse(res.data.course.lectures);
    if (lectureId) link = `/lecture/detail?id=${lectureId}`;

    /**
     * 삭제된 수업 목록으로 이동
     * -> 수업 삭제, 클래스 폐강, 최소 수강인원 미달
     */
  } else if (actionType === 'REDIRECT_TO_DELETED_LECTURE_LIST') {
    link = '/lectures/deleted';

    /**
     * 회원 상세 페이지로 이동
     * -> 수강권 등록, 담당강사 배정
     */
  } else if (actionType === 'REDIRECT_TO_USER') {
    if (ref_type === 'studio_users') {
      link = `/users/detail?id=${ref_id}`;
    } else if (ref_type === 'user_tickets' && !!ref) {
      link = `/users/detail?id=${ref.user_id}&user_ticket_id=${ref_id}`;
    }

    /**
     * 상담고객 목록 페이지로 이동
     * -> 상담 등록, 변경, 취소
     */
  } else if (actionType === 'REDIRECT_TO_COUNSEL') {
    link = `/users/counseling?id=${ref_id}`;

    /**
     * 회원 목록 페이지로 이동
     * -> 잔여일, 잔여횟수 미리 알림
     */
  } else if (actionType === 'REDIRECT_TO_USERLIST') {
    const type = msg_type.split('.').reverse()[1];
    const { remainder_coupon_noti_count, ticket_expire_noti_term } = store.getters['studio/policies'];

    if (type === 'count') {
      link = `/users?remaining_coupon=${remainder_coupon_noti_count}`;
    } else if (type === 'expiration') {
      link = `/users?remaining_day=${ticket_expire_noti_term}`;
    }

    /**
     * 수강권 상세 페이지로 이동
     * -> 수강권 일괄 연장 알림
     */
  } else if (actionType === 'REDIRECT_TO_TICKET') {
    link = `/products/detail?id=${ref_id}`;
  } else if (actionType === 'REDIRECT_TO_BOARD' ) {
    link = `/boards/qna/${ref_id}`;
  }

  return link;
};
