<template>
  <el-dialog class="schedule-settings" title="달력 보기 설정" :visible="show" @close="handleClose">
    <div class="schedule-settings__block">
      <h5 class="schedule-settings__block__label">
        시간
      </h5>
      <ul class="schedule-settings__block__content hours-list">
        <li v-for="index in _.range(displayHours.length)" :key="displayHours[index].start">
          <HoursInput
            :value="displayHours[index]"
            @input="value => handleInputDisplayHours(value, index)"
            :startHourOptions="getStartHourOptions(index)"
            :endHourOptions="getEndHourOptions(index)"
            :clearable="false"
          />
          <el-button v-if="index > 0" icon="el-icon-minus" size="mini" circle @click="removeDisplayHours(index)" />
        </li>
        <li v-if="displayHours.length < 2">
          <el-button size="mini" round :disabled="addDisplayHoursDisabled" @click="addDisplayHours">
            표시 시간 추가
            <i class="el-icon-plus"></i>
          </el-button>
        </li>
      </ul>
    </div>

    <div class="schedule-settings__block">
      <h5 class="schedule-settings__block__label">
        요일
      </h5>
      <div class="schedule-settings__block__content">
        <el-checkbox
          :indeterminate="weekdayIndeterminate"
          v-model="checkAllWeekdays"
          @change="handleChangeCheckAllWeekdays"
          >모든 요일</el-checkbox
        >
        <el-checkbox-group
          class="weekday-checkbox"
          v-model="displayWeekdays"
          @change="handleChangeDisplayWeekdays"
          size="small"
        >
          <el-checkbox-button v-for="weekday in weekdayOptions" :key="weekday" :label="weekday">{{
            weekday
          }}</el-checkbox-button>
        </el-checkbox-group>
      </div>
    </div>

    <div class="schedule-settings__color-setting">
      <h5 class="schedule-settings__color-setting__label">
        인원 마감 / 예약 마감 수업 배경색 설정
      </h5>
      <div class="schedule-settings__color-setting__desc">
        <span
          >최대 수강 인원이 다 찼거나 스태프가 예약 마감을 한 경우 일정 화면에서 수업의 배경색을 채우는
          설정입니다.</span
        >
      </div>
      <div class="schedule-settings__block__content">
        <el-checkbox v-model="displayColor.private" :true-label="1" :false-label="0" slot="checkbox"
          >프라이빗 수업</el-checkbox
        >
        <el-checkbox v-model="displayColor.group" :true-label="1" :false-label="0" slot="checkbox"
          >그룹 수업</el-checkbox
        >
      </div>
    </div>

    <div slot="footer">
      <el-button type="info" size="small" @click="handleClose">취소</el-button>
      <el-button type="primary" size="small" @click="handleClickSave">저장</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },

  data() {
    return {
      displayHours: [{ start: '00:00', end: '23:55' }],
      displayWeekdays: ['월', '화', '수', '목', '금', '토', '일'],
      displayColor: { private: false, group: false },
      weekdayOptions: ['월', '화', '수', '목', '금', '토', '일'],
      checkAllWeekdays: true,
      weekdayIndeterminate: false,
    };
  },

  computed: {
    calendarDisplayHours() {
      return this.$store.getters['schedule/calendarDisplayHours'];
    },
    calendarDisplayWeekdays() {
      return this.$store.getters['schedule/calendarDisplayWeekdays'];
    },

    calendarDisplayLectureColor() {
      return this.$store.getters['schedule/calendarDisplayLectureColor'];
    },

    lastDisplayHours() {
      return this.displayHours[this.displayHours.length - 1];
    },

    addDisplayHoursDisabled() {
      return this.lastDisplayHours.end > '23:30';
    },
  },

  watch: {
    calendarDisplayHours: {
      handler() {
        this.displayHours = this.calendarDisplayHours.map(hours => ({
          start: hours.start,
          end: hours.end,
        }));
      },
      immediate: true,
    },
    calendarDisplayWeekdays: {
      handler() {
        this.displayWeekdays = [...this.calendarDisplayWeekdays];
        this.handleChangeDisplayWeekdays(this.displayWeekdays);
      },
      immediate: true,
    },
  },

  created() {
    this.displayColor = { ...this.calendarDisplayLectureColor };
  },

  methods: {
    /** 표시 시간 입력시 null방지 */
    handleInputDisplayHours(value, index) {
      const defaultStart = !this.displayHours[index - 1] ? '00:00' : this.displayHours[index - 1].end;
      const defaultEnd = !this.displayHours[index + 1] ? '23:55' : this.displayHours[index + 1].start;

      if (!value.start) this.displayHours[index].start = defaultStart;
      if (!value.end) this.displayHours[index].end = defaultEnd;
    },

    /** 시작 시간 옵션 */
    getStartHourOptions(index) {
      const options = { start: '00:00', end: '23:30', step: '00:30' };
      if (index > 0) {
        options.start = this.displayHours[index - 1].end;
      }
      return options;
    },

    /** 종료 시간 옵션 */
    getEndHourOptions(index) {
      const options = {
        start: this.displayHours[index].start || '00:00',
        end: '23:55',
        step: '00:30',
        minTime: this.displayHours[index].start,
      };
      return options;
    },

    /** 표시 시간 추가 */
    addDisplayHours() {
      this.displayHours = [
        ...this.displayHours,
        {
          start: this.lastDisplayHours.end,
          end: '23:55',
        },
      ];
    },

    /** 표시 시간 제거 */
    removeDisplayHours(indexToRemove) {
      this.displayHours = this.displayHours.filter((hours, index) => {
        return index !== indexToRemove;
      });
    },

    /** 모든 요일 선택 */
    handleChangeCheckAllWeekdays(value) {
      this.displayWeekdays = value ? this.weekdayOptions : [];
      this.weekdayIndeterminate = false;
    },

    /** 요일 선택 변경시 */
    handleChangeDisplayWeekdays(weekdays) {
      const weekdaysCount = weekdays.length;
      const optionsCount = this.weekdayOptions.length;
      this.checkAllWeekdays = weekdaysCount === optionsCount;
      this.weekdayIndeterminate = weekdaysCount > 0 && weekdaysCount < optionsCount;
    },

    /** 모달 닫기 */
    handleClose() {
      this.displayHours = this.calendarDisplayHours.map(hours => ({
        start: hours.start,
        end: hours.end,
      }));
      this.displayWeekdays = [...this.calendarDisplayWeekdays];
      this.displayColor = { ...this.calendarDisplayLectureColor };
      this.handleChangeDisplayWeekdays(this.displayWeekdays);
      this.$emit('close');
    },

    /** 변경 사항 저장 */
    handleClickSave() {
      if (!this.validate()) return;

      this.$store.commit('schedule/SET_CALENDAR_DISPLAY_HOURS', this.displayHours);
      this.$store.commit('schedule/SET_CALENDAR_DISPLAY_WEEKDAYS', this.displayWeekdays);
      this.$store.commit('schedule/SET_CALENDAR_DISPLAY_LECTURE_COLOR', {
        private: this.displayColor.private,
        group: this.displayColor.group,
      });

      this.$emit('close');
    },

    validate() {
      if (!this.displayWeekdays.length) {
        this.$utils.notify.error(this, '오류', '모든 요일을 선택해제하실 수 없습니다.');
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-settings {
  /deep/ .el-dialog {
    width: 90%;
    max-width: 400px;
  }

  /deep/ .el-dialog__footer {
    border-top: 1px solid #eee;
    padding: 20px;
  }

  /deep/ .hours-input {
    padding: 0;
  }

  &__block {
    @include flex(column);

    @include mq(440px) {
      display: grid;
      grid-template-columns: 40px 1fr;
      grid-gap: 8px;
    }

    &__label {
      margin-bottom: 8px;
    }

    &__content {
      margin-left: 8px;

      @include mq(440px) {
        margin: 0;
      }
    }
  }

  &__block + &__block {
    margin-top: 16px;
  }

  &__color-setting {
    margin-top: 16px;
    &__desc {
      margin: 8px 0 8px 0;
      span {
        color: $color-primary;
        font-size: 12px;
      }
    }
  }

  .hours-list {
    li {
      @include flex(row, center, flex-start);

      .hours-input {
        margin-right: 8px;
      }
    }

    li + li {
      margin-top: 8px;
    }
  }

  .weekday-checkbox {
    margin-top: 8px;
  }
}
</style>
