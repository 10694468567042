/**
 * 브라우저 확인
 * @return {Number} is_mobile
 * @return {Number} is_chrome
 */
export default (data) => {
  if (typeof data === 'string') {
    let timeSplit = data.split(':');
    return Number(`${timeSplit[0] + timeSplit[1]}`);
  }
  return data;
};
