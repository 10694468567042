import moment from 'moment';

/**
 * 기간 형식 텍스트 반환
 *
 * @param {Date} startOn
 * @param {Date} endOn
 */
export default (startOn, endOn) => {
  const start = moment(startOn).format('YYYY. MM. DD');
  const end = moment(endOn).format('YYYY. MM. DD');
  return `${start} ~ ${end}`;
};
