<template>
  <div :class="`booking ${size}`" :style="{ borderLeftColor: instructor.color }">
    <div class="booking__header">
      <h5>{{ title }}</h5>
      <img :src="instructor.image" :alt="instructor.name" />
      <span>{{ instructor.name }}</span>
    </div>
    <div class="booking__content">
      <div class="booking__content__datetime">
        <p>{{ lecture_time.date }}</p>
        <p>{{ lecture_time.time }}</p>
      </div>
      <div class="booking__content__updated-at">
        <label>{{ `${booking_status.status}한 일시` }}</label>
        <span>{{ booking_status.updated_at }}</span>
      </div>
      <div class="booking__content__status">
        {{ booking_status.status }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    booking: Object,
    size: String,
  },

  computed: {
    title() {
      return this.booking.lecture.title || this.booking.lecture.course.title;
    },

    instructor() {
      const { instructor } = this.booking.lecture;

      const image = this.$utils.getImageUrl(this._.get(instructor, 'avatars[0]'), '24x24');
      const name = `${instructor.profile.name} 강사`;
      const color = `#${instructor.profile.representative_color}`;

      return { image, name, color };
    },

    lecture_time() {
      const { start_on, end_on } = this.booking.lecture;
      const date = this.calculateDay(start_on).slice(0, -8);
      const time = `${start_on.slice(-8)} ~ ${end_on.slice(-8)}`;
      return { date, time };
    },

    booking_status() {
      let { status, updated_at, created_at } = this.booking;
      updated_at = this.calculateDay(updated_at);
      created_at = this.calculateDay(created_at);
      return {
        status: this.$utils.translate.bookingStatus(status),
        updated_at: updated_at || created_at,
      };
    },
  },

  methods: {
    calculateDay(time) {
      const settlementYear = time.slice(0, 4);
      const oneDigitMonth = time.slice(6, 7);
      const twoDigitMonth = time.slice(5, 7);
      const oneDigitDay = time.slice(9, 10);
      const twoDigitDay = time.slice(8, 10);
      const settlementTime = time.slice(11);
      if (time.slice(5, 6) === '0') {
        if (time.slice(8, 9) === '0') {
          return `${settlementYear}. ${oneDigitMonth}. ${oneDigitDay}. ${settlementTime}`;
        }
        return `${settlementYear}. ${oneDigitMonth}. ${twoDigitDay}. ${settlementTime}`;
      } else if (time.slice(8, 9) === '0') {
        return `${settlementYear}. ${twoDigitMonth}. ${oneDigitDay}. ${settlementTime}`;
      }
      return `${settlementYear}. ${twoDigitMonth}. ${twoDigitDay}. ${settlementTime}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.booking {
  &.medium {
    border: 1px solid #eceff1;
    border-left: 2px solid #000;
    padding: 10px 20px;
    margin-bottom: 10px;

    .booking__header {
      @include flex(row, center);
      border-bottom: 1px solid #eceff1;
      margin-bottom: 10px;
      padding-bottom: 10px;

      h5 {
        font-size: 14px;
        font-weight: bold;
        margin-right: 20px;
      }

      img {
        border-radius: 50%;
        width: 24px;
        height: 24px;
      }

      span {
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .booking__content {
      @include flex(row, center, space-between);

      &__datetime {
        flex: 2;
        p {
          font-size: 14px;
          &:first-child {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      &__updated-at {
        flex: 3;
        align-self: flex-end;
        border-left: 1px solid #d8d8d8;
        font-size: 10px;
        padding-left: 16px;

        label {
          margin-right: 16px;
          opacity: 0.6;
        }
      }

      &__status {
        flex: 1;
        font-weight: bold;
        text-align: right;
      }
    }
  }
}
</style>
