<template>
  <el-dialog
    class="success-charge-dialog"
    :title="modalTitle"
    width="430px"
    :visible="show"
    @close="handleClickCancel"
    append-to-body
  >
    <p>
      <label>{{ amountLabel }}</label>
      <span>
        {{ payload.amount | comma }}원
        <span v-if="showInstallment">(할부 {{ payload.installment }}개월)</span>
        /{{ payload.point | comma }}P
      </span>
    </p>
    <p>
      <label>{{ pointLabel }}</label>
      <span>{{ payload.total | comma }}P</span>
    </p>

    <div slot="footer" class="set-auto-charge-dialog__buttons">
      <el-button @click="handleClickCancel" type="primary">확인</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    handleClickCancel: Function,
    payload: { flag: String | undefined, amount: Number, point: Number, total: Number, installment: Number },
  },

  computed: {
    modalTitle() {
      if (this.payload.flag === 'UPDATE') {
        return '자동 충전 금액이 변경되었습니다.';
      }
      return '결제가 완료되었습니다';
    },

    amountLabel() {
      if (this.payload.flag === 'UPDATE') {
        return '변경 금액';
      }
      return '결제 금액';
    },

    pointLabel() {
      if (this.payload.flag === 'UPDATE') {
        return '현재 잔여 포인트';
      }
      return '충전 후 잔여 포인트';
    },

    showInstallment() {
      return this.payload.installment > 1;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  top: 20vh;
  height: 308px !important;
  padding: 135px 30px 30px 30px;
  &__title {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #343a40;
  }

  &__header {
    padding: 0px 0px 15px 0px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 30px;
      left: 30px;
      width: 90px;
      height: 90px;
      background: url('../../../assets/images/icons/circle_check.svg') 0 0 no-repeat;
      background-size: cover;
    }
  }

  &__body {
    padding: 0px 0px 30px 0px;

    p {
      display: flex;
      flex-direction: row;

      > label {
        width: 117px;
        font-size: 14px;
        color: #a3a3a3;
      }

      > span {
        padding: 0px 0px 0px 16px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #606266;
      }
    }
  }

  &__footer {
    padding: 0px 0px 0px 0px;

    .el-button {
      width: 64px;
      height: 32px;
      padding: 0px 0px 0px 0px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>
