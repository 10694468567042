import _ from 'lodash';
import { COLORS, PAYMENT_VAR } from '@constants';

/** 차트 생성용으로 카테고리별 데이터 생성 */
export default (dataSource, dataType) => {
  const data = {
    total: {
      count: 0,
      value: 0,
    },
    paymentType: {
      labels: ['신규결제', '재결제', '업그레이드', '양도', '환불', '체험', '미수금결제'],
      counts: [],
      values: [],
      colors: ['#c0e796', '#d1d1d1', '#a2ceff', '#F9B45E', '#fb9898', '#d1b6f6', '#fdde77'],
    },
    instructors: {
      counts: [],
      labels: [],
      values: [],
      colors: [],
    },
    courseType: {
      labels: ['프라이빗', '그룹'],
      counts: [],
      values: [],
      colors: ['#b286f0', '#64aeff'],
    },
    paymentMethod: {
      labels: ['카드', '현금'],
      counts: [],
      values: [],
      colors: ['#998EC3', '#F1A340'],
    },
    ticketTitle: {
      labels: [],
      counts: [],
      values: [],
      colors: [],
    },
  };
  if (!dataSource) return data;

  let newAmountCount = 0;
  let newAmount = 0;
  let renewAmountCount = 0;
  let renewAmount = 0;
  let upgradeAmountCount = 0;
  let upgradeAmount = 0;
  let refundAmountCount = 0;
  let refundAmount = 0;
  let tutorialAmountCount = 0;
  let tutorialAmount = 0;
  let unPaidAmountCount = 0;
  let unPaidAmount = 0;
  let transferAmountCount = 0;
  let transferAmount = 0;
  let instructorsCounts = {};
  let instructorsValues = {};
  let instructorsColors = {};
  let privateAmountCount = 0;
  let privateAmount = 0;
  let groupAmountCount = 0;
  let groupAmount = 0;
  let cardAmountCount = 0;
  let cardAmount = 0;
  let cashAmountCount = 0;
  let cashAmount = 0;
  let ticketTitleCounts = {};
  let ticketTitleValues = {};

  dataSource.forEach(row => {
    const amount = dataType === 'ticketsRevenue' ? row.totalAmount : row.deductedAmount;
    /** 합계 */
    data.total.count++;
    data.total.value += amount;

    /** 결제구분별 - 수강권 매출 only */
    if (row.paymentType === 'installment_payment' || row.paymentType === 'full_payment') {
      unPaidAmount += row.totalAmount;
      unPaidAmountCount++;
    } else if (row.paymentType === PAYMENT_VAR.upgrade) {
      upgradeAmount += row.totalAmount;
      upgradeAmountCount++;
    } else if (row.paymentType === PAYMENT_VAR.refund) {
      refundAmount += row.totalAmount;
      refundAmountCount++;
    } else if (row.tutorialTicket) {
      tutorialAmount += row.totalAmount;
      tutorialAmountCount++;
    } else if (row.paymentType === PAYMENT_VAR.transfer) {
      transferAmount += row.totalAmount;
      transferAmountCount++;
    } else if (row.isNew) {
      newAmount += row.totalAmount;
      newAmountCount++;
    } else {
      renewAmount += row.totalAmount;
      renewAmountCount++;
    }

    /** 강사별 - 수업 매출 only */
    const staffName = row.staffName || '담당강사 없음';
    if (!instructorsCounts[staffName]) {
      data.instructors.labels.push(staffName);
      instructorsCounts[staffName] = 1;
      instructorsValues[staffName] = amount;
      instructorsColors[staffName] = !row.staffColor ? '#eee' : `#${row.staffColor}`;
    } else {
      instructorsCounts[staffName] += 1;
      instructorsValues[staffName] += amount;
    }

    /** 수업구분별 */
    if (row.courseType === 'P') {
      privateAmount += amount;
      privateAmountCount++;
    } else if (row.courseType === 'G') {
      groupAmount += amount;
      groupAmountCount++;
    }

    /** 결제방법별 - 수강권 매출 only */
    if (row.paymentType === 'refund') {
      cardAmount -= row.cardAmount;
      cashAmount -= row.cashAmount;
      cashAmount -= row.wiretransferAmount;
    } else {
      cardAmount += row.cardAmount;
      cashAmount += row.cashAmount;
      cashAmount += row.wiretransferAmount;
    }
    if (row.cardAmount > 0) cardAmountCount++;
    if (row.cashAmount > 0 || row.wiretransferAmount > 0) cashAmountCount++;

    /** 수강권명별 */
    if (typeof ticketTitleValues[row.ticketTitle] === 'number') {
      ticketTitleCounts[row.ticketTitle] += 1;
      ticketTitleValues[row.ticketTitle] += amount;
    } else {
      data.ticketTitle.labels.push(row.ticketTitle);
      ticketTitleCounts[row.ticketTitle] = 1;
      ticketTitleValues[row.ticketTitle] = amount;
    }
  });

  /** 중복제거 */
  data.instructors.labels = _.uniq(data.instructors.labels);
  data.ticketTitle.labels = _.sortBy(_.uniq(data.ticketTitle.labels));

  data.paymentType.counts = [
    newAmountCount,
    renewAmountCount,
    upgradeAmountCount,
    transferAmountCount,
    refundAmountCount,
    tutorialAmountCount,
    unPaidAmountCount
  ];
  data.paymentType.values = [newAmount, renewAmount, upgradeAmount, transferAmount, refundAmount, tutorialAmount, unPaidAmount];
  data.instructors.labels.forEach(staffName => {
    data.instructors.counts.push(instructorsCounts[staffName]);
    data.instructors.values.push(instructorsValues[staffName]);
    data.instructors.colors.push(instructorsColors[staffName]);
  });
  data.courseType.counts = [privateAmountCount, groupAmountCount];
  data.courseType.values = [privateAmount, groupAmount];
  data.paymentMethod.counts = [cardAmountCount, cashAmountCount];
  data.paymentMethod.values = [cardAmount, cashAmount];
  data.ticketTitle.labels.forEach((ticketTitle, index) => {
    data.ticketTitle.counts.push(ticketTitleCounts[ticketTitle]);
    data.ticketTitle.values.push(ticketTitleValues[ticketTitle]);
    data.ticketTitle.colors.push(`#${COLORS[index % 11]}`);
  });

  return data;
};
