/**
 * 문자열 comma로 나열
 * ex) 유효, 만료
 *
 * @param {Array<{label: string, value: boolean}>}
 * @param {string}: 없음 표기할 대체텍스트
 */
export default (array, altText = '-') => {
  const tempLabel = [];

  array.forEach(({ label, value }) => {
    if (value) {
      tempLabel.push(label);
    }
  });

  return tempLabel.length ? tempLabel.join(', ') : altText;
};
