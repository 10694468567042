<template>
  <el-dialog class="ticket-issue-modal" :visible.sync="show" :before-close="handleClose" :close-on-click-modal="false">
    <div slot="title" class="ticket-issue-modal__title">
      <h4>{{ title }} 등록</h4>
      <ul>
        <li v-for="step in steps" :key="step.index" :class="{ active: step.active }">
          {{ step.index }}
        </li>
      </ul>
    </div>

    <p class="ticket-issue-modal__description">
      {{ steps[currentStep - 1].description }}
    </p>

    <SelectTicket v-if="currentStep === 1" :isProduct="isProduct" :title="title" />
    <TicketDetail v-if="currentStep === 2" :isProduct="isProduct" />
    <PaymentDetail v-if="currentStep === 3" @complete="handleComplete" />
  </el-dialog>
</template>

<script>
import SelectTicket from './SelectTicket';
import TicketDetail from './TicketDetail';
import PaymentDetail from './PaymentDetail';

export default {
  components: {
    SelectTicket,
    TicketDetail,
    PaymentDetail,
  },

  props: {
    show: Boolean,
    initialData: { type: Object, default: null },
    isProduct: Boolean,
  },

  computed: {
    formData() {
      return this.$store.getters['ticketIssue/formData'];
    },

    title() {
      return this.isProduct ? '상품' : '수강권';
    },

    currentStep() {
      return this.formData.currentStep;
    },

    steps() {
      return [
        {
          index: 1,
          title: `${this.title} 선택`,
          description: `${this.title}을 선택해주세요`,
          active: this.currentStep === 1,
        },
        {
          index: 2,
          title: '상세정보 입력',
          description: '상세정보를 입력해주세요',
          active: this.currentStep === 2,
        },
        {
          index: 3,
          title: '결제정보 입력',
          description: '결제정보를 입력해주세요',
          active: this.currentStep === 3,
        },
      ];
    },

    pointDetail() {
      return this.$store.getters['pointHistory/pointDetail'];
    },

    memberId() {
      return this.$route.query.id;
    },

    currentPoint() {
      const pointDetail = this.$store.getters['pointHistory/pointDetail'];
      return _.get(pointDetail, 'current_point');
    },
  },

  created() {
    this.$store.dispatch('ticketIssue/getTickets', this.isProduct);
    this.$store.dispatch('pointHistory/getPointDetail', this.memberId);

    if (this.initialData) {
      this.$store.commit('ticketIssue/SET_VALUES', {
        ...this.initialData,
        currentStep: 2,
      });
    }
  },

  methods: {
    handleComplete() {
      const { selectedTicket, ticketDetail, paymentDetail } = this.formData;
      const { reward_point, point_amount, amount } = paymentDetail;

      const pointValidates = [reward_point, point_amount].map((point, index) => {
        if (index === 0) return this.$utils.paymentValidation('reward', point);
        return this.$utils.paymentValidation('ticket', point, this.currentPoint, amount);
      });

      if (pointValidates[0].error || pointValidates[1].error) {
        const message = pointValidates[0].error ? pointValidates[0].message : pointValidates[1].message;
        this.$utils.notify.error(this, '오류', message);
      } else {
        this.$store.commit('ticketIssue/SET_VALUES');
        this.$emit('close', { selectedTicket, ticketDetail, paymentDetail });
      }
    },

    handleClose() {
      this.$store.commit('ticketIssue/SET_VALUES');
      this.$emit('close', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-issue-modal {
  /deep/ .el-dialog {
    @include flex(column);
    width: 90%;
    max-width: 620px;
  }

  /deep/ .el-dialog__body {
    flex: 1;
  }

  &__title {
    @include flex(row, center, flex-start);

    ul {
      @include flex(row, center, flex-start);

      li {
        @include flex(row, center, center);
        background: white;
        border: 1px solid rgba($charcoal-grey, 0.2);
        border-radius: 50%;
        font-size: 9px;
        width: 18px;
        height: 18px;
        margin: 0 12px;
        position: relative;
      }

      li + li::before {
        content: '';
        width: 12px;
        border-bottom: 1px solid rgba($charcoal-grey, 0.2);
        position: absolute;
        left: -18px;
      }

      li.active {
        border-color: $charcoal-grey;
        background: $charcoal-grey;
        color: white;
      }
    }
  }

  &__description {
    opacity: 0.6;
    text-align: center;
  }
}
</style>
