import moment from 'moment';
// import api from '@api';
// import utils from '@utils';

const DEFAULT_FILTER = {
  rangeType: 'day',
  date: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
};

export const state = {
  filter: {
    ...DEFAULT_FILTER,
  },
  activeSalesTab: 'ticket',
  chartOrTable: 'table',
  refreshAll: false,
  showPasswordInput: true,
};

export const getters = {
  filter: state => state.filter,
  activeSalesTab: state => state.activeSalesTab,
  chartOrTable: state => state.chartOrTable,
  refreshAll: state => state.refreshAll,
  showPasswordInput: state => state.showPasswordInput,
};

export const mutations = {
  SET_FILTER(state, filterOptions) {
    const newFilter = {
      ...state.filter,
      ...filterOptions,
    };
    let endDate = newFilter.endDate;
    if (newFilter.rangeType === 'day') {
      endDate = newFilter.date;
    } else if (newFilter.rangeType === 'week') {
      endDate = moment(newFilter.date)
        .add(6, 'day')
        .format('YYYY-MM-DD');
    } else if (newFilter.rangeType === 'month') {
      endDate = moment(newFilter.date)
        .add(1, 'month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    }
    newFilter.endDate = endDate;

    state.filter = !filterOptions
      ? {
          ...DEFAULT_FILTER,
        }
      : newFilter;
  },
  SET_ACTIVE_SALES_TAB(state, tab) {
    state.activeSalesTab = tab;
  },
  SET_CHART_OR_TABLE(state, type) {
    state.chartOrTable = type;
  },
  SET_REFRESH_ALL(state, value) {
    state.refreshAll = value;
  },

  SET_SHOW_PASSWORD_INPUT(state, value) {
    state.showPasswordInput = value;
  },
};

export const actions = {
  setActiveSalesTab({ commit }, tab) {
    commit('SET_ACTIVE_SALES_TAB', tab);
  },
  setChartOrTable({ commit }, type) {
    commit('SET_CHART_OR_TABLE', type);
  },
  setRefreshAll({ commit }, value) {
    commit('SET_REFRESH_ALL', value);
  },
};
