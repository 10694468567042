import moment from 'moment';
import api from '@api';

export const state = {
  staff: {},
  lecturesLoading: false,
  lectures: [],
  coursesLoading: false,
  courses: [],
  membersLoading: false,
  members: [],
  working_times: [],
  pagination: {
    page: 1,
    limit: 10,
  },
  total: 0,
};

export const getters = {
  staff: state => state.staff,
  lecturesLoading: state => state.lecturesLoading,
  lectures: state => state.lectures,
  coursesLoading: state => state.coursesLoading,
  courses: state => state.courses,
  membersLoading: state => state.membersLoading,
  members: state => state.members,
  working_times: state => state.working_times,
  pagination: state => state.pagination,
  total: state => state.total,
};

export const mutations = {
  SET_STAFF(state, staff) {
    state.staff = staff;
  },

  SET_LECTURES_LOADING(state, loading) {
    state.lecturesLoading = loading;
  },

  SET_LECTURES(state, lectures) {
    state.lectures = lectures;
  },

  SET_COURSES_LOADING(state, loading) {
    state.coursesLoading = loading;
  },

  SET_COURSES(state, courses) {
    state.courses = courses;
  },

  SET_MEMBERS_LOADING(state, loading) {
    state.membersLoading = loading;
  },

  SET_MEMBERS(state, members) {
    state.members = members;
  },

  SET_WORKING_TIMES(state, working_times) {
    state.working_times = working_times;
  },

  SET_PAGINATION(state, value) {
    state.pagination = {
      ...state.pagination,
      ...value,
    };
  },

  SET_TOTAL(state, value) {
    state.total = value;
  },
};

export const actions = {
  async getStaff({ commit, dispatch }, staffId) {
    try {
      const res = await api.staff.get(staffId);
      commit('SET_STAFF', res.data.staff);
      dispatch('getLectures', staffId);
      dispatch('getCourses', staffId);
      return { success: true };
    } catch (error) {
      commit('SET_STAFF', {});
      return { success: false, error };
    }
  },

  async getLectures({ commit }, staffId) {
    try {
      commit('SET_LECTURES_LOADING', true);
      const params = {
        staff_id: staffId,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment()
          .add(1, 'week')
          .format('YYYY-MM-DD'),
        course_type: 'P',
      };
      const res = await api.lecture.getAll({
        ...params,
        with: 'bookings.userTicket.ticket.divisions;bookings.member.avatars;bookings.member;staff.avatars;staff',
      });
      commit('SET_LECTURES', res.data);
    } catch (error) {
      commit('SET_LECTURES', []);
    } finally {
      commit('SET_LECTURES_LOADING', false);
    }
  },

  async getCourses({ commit }, staffId) {
    try {
      commit('SET_COURSES_LOADING', true);
      const params = {
        staff_id: staffId,
        type: 'G',
        base_date: moment().format('YYYY-MM-DD'),
      };
      const res = await api.course.getAll(params);
      const courses = res.data.courses.filter(({ lectures_count }) => lectures_count);
      commit('SET_COURSES', courses);
    } catch (error) {
      commit('SET_COURSES', []);
    } finally {
      commit('SET_COURSES_LOADING', false);
    }
  },

  async getMembers({ commit }, params) {
    try {
      commit('SET_MEMBERS_LOADING', true);
      const res = await api.member.getMembers({
        ...params,
        sort_name: 'desc',
      });

      commit('SET_MEMBERS', res.data.data);
      commit('SET_TOTAL', res.data.meta.total);
    } catch (error) {
      commit('SET_MEMBERS', []);
      commit('SET_TOTAL', 0);
    } finally {
      commit('SET_MEMBERS_LOADING', false);
    }
  },

  async getWorkTimes({ commit }, staffId) {
    try {
      const res = await api.staff.workTimeSchedule(staffId);
      commit('SET_WORKING_TIMES', res.data);
    } catch (error) {
      commit('SET_WORKING_TIMES', []);
    }
  },
};
