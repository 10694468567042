export default {
  // 이름
  name: name => /^[가-힣|a-z|A-Z|0-9|\s]+$/.test(name),

  // 휴대폰 번호
  mobile: mobile => /^(01)[0,1,6,7,8,9][0-9]{7,8}$/i.test(mobile),

  // 전화번호 (휴대폰+일반전화)
  phone: phone => /^(01)[0,1,6,7,8,9][0-9]{7,8}|(0)[2-8]\d{7,9}$/i.test(phone),

  // 날짜형식 ('YYYY-MM-DD')
  date: date => /([0-9]{4})-([0-9]{2})-([0-9]{2})/i.test(date),

  // 생년월일
  birthDay: date => {
    const year = Number(date.substr(0, 4));
    const month = Number(date.substr(5, 2));
    const day = Number(date.substr(8, 2));
    const today = new Date();
    const yearNow = today.getFullYear();

    if (
      1900 > year ||
      year > yearNow ||
      month < 1 ||
      month > 12 ||
      day < 1 ||
      day > 31 ||
      ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31)
    ) {
      return false;
    }
    return true;
  },
};
