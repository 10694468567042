<template>
  <section class="payment-detail">
    <div class="payment-detail__summary">
      <PriceInput label="판매가" labelAlign="row" v-model="initialPayment" readonly />
      <PriceInput class="payment-detail__summary__total" label="결제총액" labelAlign="row" :value="totalAmount.paid" readonly />
      <PriceInput
        class="payment-detail__summary__unpaid"
        :label="isRefunded ? '위약금' : '미수총액'"
        labelAlign="row"
        :value="isRefunded ? refundFee : unpaidMount"
        readonly
      />
    </div>

    <el-table :data="payments" height="353" fit>
      <el-table-column prop="settlement_at" :formatter="formatSettlementAt" label="결제일시" min-width="130" align="center" />
      <el-table-column prop="status" :formatter="formatStatus" label="구분" width="90" align="center" />
      <el-table-column prop="amount" :formatter="formatCurrency" label="결제금액" min-width="90" align="center" />
      <el-table-column :formatter="formatCurrency" label="미수금(위약금)" min-width="110" align="center">
        <span class="hint-box" slot="header">
          <p>미수금(위약금)</p>
          <el-tooltip effect="light" content="미수금을 수정하려면 아래 결제 목록에서 미수금액을 선택하세요" placement="bottom">
            <i class="el-icon-question" :style="{ color: '#64AEFF', fontSize: '18px' }" />
          </el-tooltip>
        </span>
        <template slot-scope="scope">
          <span
            :class="checkUseUnpiad(scope.row.unpaid_amount) ? 'change-unpaid' : null"
            @click="handleClickEditPayment(scope.row, scope.$index, true)"
          >
            {{ scope.row.unpaid_amount | comma }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="56" align="center" label="···">
        <template slot-scope="scope">
          <CircleButton
            @click="handleClickEditPayment(scope.row, scope.$index, false)"
            :disabled="!canUpdateMembersTicket || isTicketDeleted"
          >
            <img
              v-if="scope.$index === payments.length - 1"
              src="@/assets/images/01_graphic/ic_16x16/solid/edit.svg"
              alt="수정"
            />
            <i v-else class="el-icon-view" style="font-size: 16px"></i>
          </CircleButton>
        </template>
      </el-table-column>
    </el-table>

    <EditPaymentDialog
      v-if="showEditPaymentDialog"
      :show="showEditPaymentDialog"
      :payment="paymentEditing"
      :payment-status="formatStatus(paymentEditing)"
      :reference-price="paymentEditing.status !== 'installment_payment' ? actualPrice : unpaidMount"
      :updating="paymentUpdating"
      @cancel="handleClickEditCancel"
      @save="handleClickEditSave"
      :readonly="editPaymentReadonly"
    />
  </section>
</template>

<script>
import EditPaymentDialog from '../EditPayment';
import { PAYMENT_STATUS, PAYMENT_VAR } from '@constants';

export default {
  components: { EditPaymentDialog },

  props: {
    currentPoint: Number,
  },

  data() {
    return {
      showEditPaymentDialog: false,
      editPaymentReadonly: false,
      paymentEditing: null,
      paymentUpdating: false,
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },

    userTicket() {
      return _.get(this.formData, 'userTicket');
    },
    member() {
      return _.get(this.userTicket, 'member');
    },
    isTicketDeleted() {
      return !!_.get(this.userTicket, 'ticket.deleted_at');
    },
    actualPrice() {
      return _.get(this.userTicket, 'ticket.actual_price');
    },
    payments() {
      return _.get(this.formData, 'userTicket.payments');
    },
    isTransfered() {
      return this.payments[0].status === 'transfer';
    },
    beforeUserTicketAmount() {
      return this.payments[0].before_user_ticket_amount;
    },
    // 판매가 표시 (최초 발급시 결제한 금액)
    initialPayment() {
      if (this.isTransfered) {
        return this.beforeUserTicketAmount;
      } else {
        const { amount, unpaid_amount } = this.payments[0];
        return amount + unpaid_amount;
      }
    },

    unpaidMount() {
      const amount = this.comparePayment();
      return amount.unpaid - amount.paid;
    },

    totalAmount() {
      return this.payments.reduce(
        (sum, payment) => {
          const paid = payment.status === 'refund' ? sum.paid - payment.amount : sum.paid + payment.amount;
          const unpaid = sum.unpaid + payment.unpaid_amount;

          return { paid, unpaid };
        },
        { paid: 0, unpaid: 0 },
      );
    },

    isRefunded() {
      return this.payments[this.payments.length - 1].status === 'refund';
    },
    refundFee() {
      if (!this.isRefunded) return 0;
      return this.payments[this.payments.length - 1].unpaid_amount;
    },
    beforePointAmount() {
      return this.payments[this.payments.length - 1].point_amount;
    },
  },

  watch: {
    showEditPaymentDialog(show) {
      if (!show) {
        this.paymentEditing = { ...this.paymentEditing, current_point: this.currentPoint };
      }
    },
  },

  methods: {
    checkUseUnpiad(unpaidAmount) {
      if (!unpaidAmount) return false;
      if (this.userTicket.status === 'refund') return false;
      let compareAmount = { paid: 0, unpaid: 0 };
      this.payments.map(({ status, amount, unpaid_amount }) => {
        if (status === PAYMENT_VAR.installment_payment || status === PAYMENT_VAR.full_payment) {
          compareAmount.paid += amount;
        }
        compareAmount.unpaid += unpaid_amount;
      });
      return unpaidAmount !== 0 && compareAmount.paid - compareAmount.unpaid >= 0 ? false : true;
    },

    formatSettlementAt({ settlement_at }) {
      const settlementYear = settlement_at.slice(0, 4);
      const oneDigitMonth = settlement_at.slice(6, 7);
      const twoDigitMonth = settlement_at.slice(5, 7);
      const oneDigitDay = settlement_at.slice(9, 10);
      const twoDigitDay = settlement_at.slice(8, 10);
      const settlementTime = settlement_at.slice(11);
      if (settlement_at.slice(5, 6) === '0') {
        if (settlement_at.slice(8, 9) === '0') {
          return `${settlementYear}. ${oneDigitMonth}. ${oneDigitDay}. ${settlementTime}`;
        }
        return `${settlementYear}. ${oneDigitMonth}. ${twoDigitDay}. ${settlementTime}`;
      } else if (settlement_at.slice(8, 9) === '0') {
        return `${settlementYear}. ${twoDigitMonth}. ${oneDigitDay}. ${settlementTime}`;
      }
      return `${settlementYear}. ${twoDigitMonth}. ${twoDigitDay}. ${settlementTime}`;
    },

    formatStatus(row) {
      return PAYMENT_STATUS[row.status];
    },

    formatCurrency(row, column, value) {
      const formatted = this.$filters.comma(value);
      return row.status === 'refund' && column.label !== '미수금(위약금)' ? `-${formatted}` : formatted;
    },

    handleClickEditPayment(row, index, unpaid) {
      const payment = {
        ...row,
        installment: row.installment_period < 2 ? 1 : 0,
        unpaid,
      };

      this.paymentEditing = {
        ...this.paymentEditing,
        ...payment,
        current_point: this.currentPoint,
      };

      if (payment.status === 'refund') {
        this.paymentEditing = {
          ...this.paymentEditing,
          prevTotal: this.payments.reduce((sum, payment) => {
            return payment.status !== 'refund' ? sum + payment.amount : sum;
          }, 0),
        };
      }

      if (unpaid) {
        if (this.userTicket.status !== 'refund') {
          let compareAmount = this.comparePayment();
          this.editPaymentReadonly = compareAmount.paid > compareAmount.unpaid;
          this.paymentEditing = {
            ...this.paymentEditing,
            card_amount: 0,
            cash_amount: 0,
            wiretransfer_amount: 0,
            amount: 0,
            point_amount: 0,
            status: PAYMENT_VAR.installment_payment,
          };
          this.showEditPaymentDialog = compareAmount.paid < compareAmount.unpaid;
        }
      } else {
        this.editPaymentReadonly = index !== this.payments.length - 1;
        this.showEditPaymentDialog = true;
      }
    },

    /** 결제 정보 수정 취소 */
    handleClickEditCancel() {
      this.showEditPaymentDialog = false;
      this.editPaymentReadonly = false;
    },

    /** 결제 정보 저장 */
    async handleClickEditSave(payment, deletePayment) {
      if (!payment) return;

      try {
        this.paymentUpdating = true;

        const { amount, point_amount, unpaid } = payment;
        const pointValidate = this.$utils.paymentValidation('ticket', point_amount, this.currentPoint, amount);
        const { error, message } = pointValidate;
        if (error) {
          if (message !== '잔여 포인트가 부족합니다.' || point_amount !== this.paymentEditing.point_amount) {
            this.$utils.notify.error(this, '오류', message);
            return;
          }
        }

        if (deletePayment) {
          await this.$api.userTicket.deletePayment(payment);
        } else {
          /** 미수금 수정 (결제내역 추가) */
          if (unpaid) {
            await this.$api.userTicket.addPayment(payment);
          } else {
            /** 결제내역 수정 */
            if (
              /** 결제내역 수정 - 미수금 수정 - 납부 금액 초과시 */
              this.formatStatus(this.paymentEditing) === '미수금' &&
              amount > this.unpaidMount + this.paymentEditing.amount
            ) {
              return this.$utils.notify.error(this, '오류', '미수금 납부 금액이 초과되었습니다.');
            }

            await this.$api.userTicket.updatePayment(payment);
          }
        }

        this.$utils.notify.success(this, '성공', `결제 정보가 ${deletePayment ? '삭제' : '저장'}되었습니다`);
        this.$store.dispatch('pointHistory/getPointDetail', this.member.id);
        this.$store.dispatch('ticketEdit/getUserTicket', this.userTicket.id);
        this.handleClickEditCancel();
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.paymentUpdating = false;
      }
    },

    comparePayment() {
      let compareAmount = { paid: 0, unpaid: 0 };
      this.payments.map(({ status, amount, unpaid_amount }) => {
        if (status === PAYMENT_VAR.installment_payment || status === PAYMENT_VAR.full_payment) {
          compareAmount.paid += amount;
        }
        compareAmount.unpaid += unpaid_amount;
      });
      return compareAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-detail {
  &__summary {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    border-bottom: 1px solid rgba(#000, 0.15);
    padding: 15px 20px;

    @include mq(568px) {
      grid-template-columns: repeat(3, 1fr);
    }

    &__total {
      /deep/ .el-input__inner {
        color: $color-primary !important;
      }
    }

    &__unpaid {
      /deep/ .el-input__inner {
        color: $color-danger !important;
      }
    }
  }
}
.change-unpaid:hover {
  cursor: pointer;
  color: $coral;
  text-decoration: underline;
}
.hint-box {
  display: flex;
  justify-content: center;
}
/deep/ .el-icon-question {
  line-height: 24px;
  margin-left: 3px;
}
</style>
