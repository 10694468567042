import Vue from 'vue';
import Vuex from 'vuex';
import dispatchActionForAllModules from '@utils/dispatchActionForAllModules';
import createPersistedState from 'vuex-persistedstate';

import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      paths: ['studio', 'payment', 'boardNotice'],
    }),
  ],
});

export default store;

dispatchActionForAllModules('init');
