import axios from '../axios';
const BASE_URL = '/v2/staff/enter';

export default {
  // 회원 조회
  checkMember: params => {
    return axios.get(`${BASE_URL}/${params}`);
  },

  // 수강권 조회
  checkTickets: params => {
    return axios.get(`${BASE_URL}?member_id=${params}`);
  },

  // 수업 조회
  checkLectures: params => {
    return axios.get(`${BASE_URL}?member_id=${params.member_id}&user_ticket_id=${params.user_ticket_id}`);
    // return axios.get(`${BASE_URL}?member_id=4873&user_ticket_id=2709&booking_id가 있을 경우 booking_id를 추가해서 보내기`)
  },

  // 최종 체크인
  checkinFinal: params => {
    return axios.post(`${BASE_URL}`, params);
  },
};
