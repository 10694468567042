<template>
  <button v-bind="$attrs" :class="[type, size, round ? 'round' : null]" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: String,
    size: String,
    round: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
button {
  border: solid 1px $dodger-blue;
  border-radius: 2px;
  background: $dodger-blue;
  color: #fff;
  font-size: 14px;
  padding: 4px 8px;
  transition: background 0.15s;

  &.round {
    border-radius: 13px;
  }

  &:hover {
    background: rgba($dodger-blue, 0.8);
    border-color: rgba($dodger-blue, 0.8);
  }

  &:disabled {
    opacity: 0.5;
    background: $dodger-blue;
    cursor: not-allowed;
  }

  &.danger {
    background: $color-danger;
    border-color: $color-danger;
    color: #fff !important;

    &:hover {
      background: rgba($color-danger, 0.8);
      border-color: rgba($color-danger, 0.8);
    }

    &:disabled {
      opacity: 0.5;
      background: $color-danger;
    }
  }

  &.white {
    background: #fff;
    border-color: #fff;
    color: $dodger-blue;

    &:hover {
      background: rgba(#fff, 0.8);
      border-color: rgba(#fff, 0.8);
    }

    &:disabled {
      opacity: 0.5;
      background: #fff;
    }
  }

  &.info {
    background: #5d646b;
    border-color: #5d646b;
    color: #fff;

    &:hover {
      background: rgba(#5d646b, 0.8);
      border-color: rgba(#5d646b, 0.8);
    }

    &:disabled {
      opacity: 0.5;
      background: #5d646b;
    }
  }

  &.large {
    border-radius: 4px;
    padding: 7px 14px;
  }
  &.large.round {
    border-radius: 16px;
  }
  &.block-btn {
    display: inline-block;
    width: 100%;
    border-radius: 4px;
    padding: 14px;
    font-size: 24px;
  }
}
</style>
