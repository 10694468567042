<template>
  <el-dialog
    v-loading="updating"
    element-loading-text="수업을 수정중입니다."
    class="bulk-update-lectures"
    :visible="show"
    title="수업 일괄 수정"
    @close="handleClose"
  >
    <div class="bulk-update-lectures__form">
      <FormElement :enabled="shouldUpdate.title" @checkbox-change="shouldUpdate.title = !shouldUpdate.title" label="수업명">
        <TextInput ref="title" v-model="data.title" :disabled="!shouldUpdate.title" material />
      </FormElement>

      <FormElement
        class="bulk-update-lectures__form__select"
        :enabled="shouldUpdate.instructor_id"
        @checkbox-change="shouldUpdate.instructor_id = !shouldUpdate.instructor_id"
        label="강사"
      >
        <el-select
          ref="instructor_id"
          v-model="data.instructor_id"
          placeholder="강사 선택"
          :disabled="!shouldUpdate.instructor_id"
          automatic-dropdown
        >
          <el-option v-for="item in instructorOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </FormElement>

      <FormElement
        v-if="studioPolicies.is_use_rooms"
        class="bulk-update-lectures__form__select"
        :enabled="shouldUpdate.room_id"
        @checkbox-change="shouldUpdate.room_id = !shouldUpdate.room_id"
        label="룸"
      >
        <el-select v-model="data.room_id" placeholder="룸 선택" :disabled="!shouldUpdate.room_id" clearable>
          <el-option v-for="item in roomOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </FormElement>

      <!-- <FormElement
        class="bulk-update-lectures__form__number-input"
        :enabled="shouldUpdate.max_trainee"
        @checkbox-change="shouldUpdate.max_trainee = !shouldUpdate.max_trainee"
        label="최대수강인원"
        :error-message="maxTraineeErrorMessage"
      >
        <el-input-number
          v-if="!maxTraineeErrorMessage"
          v-model="data.max_trainee"
          controls-position="right"
          :min="!data.min_trainee ? 1 : data.min_trainee"
          :max="99"
          :disabled="!shouldUpdate.max_trainee"
          size="small"
        />
      </FormElement>-->

      <FormElement
        class="bulk-update-lectures__form__number-input"
        :enabled="shouldUpdate.min_trainee"
        @checkbox-change="shouldUpdate.min_trainee = !shouldUpdate.min_trainee"
        label="최소 수강 인원"
        :error-message="minTraineeErrorMessage"
        :warning-message="minTraineeWarningMessage"
        :disabled="minTraineeInputDisabled"
      >
        <el-input-number
          v-if="!minTraineeErrorMessage"
          ref="min_trainee"
          v-model="data.min_trainee"
          controls-position="right"
          :min="0"
          :max="!data.max_trainee ? 0 : data.max_trainee"
          :disabled="!shouldUpdate.min_trainee"
          size="small"
        />
      </FormElement>

      <FormElement
        v-if="studioPolicies.weekly_waiting_limit"
        class="bulk-update-lectures__form__number-input"
        :enabled="shouldUpdate.waiting_trainee_limit"
        @checkbox-change="shouldUpdate.waiting_trainee_limit = !shouldUpdate.waiting_trainee_limit"
        label="예약 대기 가능 인원"
        :error-message="waitingLimitErrorMessage"
      >
        <el-input-number
          v-if="!waitingLimitErrorMessage"
          ref="waiting_trainee_limit"
          v-model="data.waiting_trainee_limit"
          controls-position="right"
          :min="0"
          :max="99"
          :disabled="!shouldUpdate.waiting_trainee_limit"
          size="small"
        />
      </FormElement>

      <FormElement
        :enabled="shouldUpdate.bookableTime"
        @checkbox-change="shouldUpdate.bookableTime = !shouldUpdate.bookableTime"
        label="예약 가능 시간"
        :error-message="bookableTimeErrorMessage"
      >
        <AvailableHoursInput
          v-if="!bookableTimeErrorMessage"
          :type="bookingRuleType"
          :disabled="!shouldUpdate.bookableTime"
          :relativeStart="data.booking_startline"
          relativeStartPropName="booking_startline"
          :relativeEnd="data.booking_deadline"
          relativeEndPropName="booking_deadline"
          :absoluteStartDays="data.booking_start_days"
          absoluteStartDaysPropName="booking_start_days"
          :absoluteStartTime="data.booking_start_time"
          absoluteStartTimePropName="booking_start_time"
          :absoluteEndDays="data.booking_end_days"
          absoluteEndDaysPropName="booking_end_days"
          :absoluteEndTime="data.booking_end_time"
          absoluteEndTimePropName="booking_end_time"
          @change="handleAvailableHoursChange"
        >
          <span slot="suffix">까지</span>
        </AvailableHoursInput>
      </FormElement>

      <FormElement
        :enabled="shouldUpdate.cancellableTime"
        @checkbox-change="shouldUpdate.cancellableTime = !shouldUpdate.cancellableTime"
        label="취소 가능 시간"
        :error-message="cancellableTimeErrorMessage"
      >
        <AvailableHoursInput
          v-if="!cancellableTimeErrorMessage"
          :type="cancelRuleType"
          :disabled="!shouldUpdate.cancellableTime"
          :relativeStart="data.booking_cancel_startline"
          relativeStartPropName="booking_cancel_startline"
          :relativeEnd="data.booking_cancel_deadline"
          relativeEndPropName="booking_cancel_deadline"
          :absoluteStartDays="data.booking_cancel_start_days"
          absoluteStartDaysPropName="booking_cancel_start_days"
          :absoluteStartTime="data.booking_cancel_start_time"
          absoluteStartTimePropName="booking_cancel_start_time"
          :absoluteEndDays="data.booking_cancel_end_days"
          absoluteEndDaysPropName="booking_cancel_end_days"
          :absoluteEndTime="data.booking_cancel_end_time"
          absoluteEndTimePropName="booking_cancel_end_time"
          @change="handleAvailableHoursChange"
        >
          <span slot="suffix">까지</span>
        </AvailableHoursInput>
      </FormElement>

      <FormElement
        :enabled="shouldUpdate.daily_booking_change_deadline"
        @checkbox-change="shouldUpdate.daily_booking_change_deadline = !shouldUpdate.daily_booking_change_deadline"
        label="당일 예약 변경 가능 시간"
        :error-message="bookingChangeDeadlineErrorMessage"
      >
        <AvailableHoursInput
          v-if="!bookingChangeDeadlineErrorMessage"
          type="R"
          :disabled="!shouldUpdate.daily_booking_change_deadline"
          :relativeEnd="data.daily_booking_change_deadline"
          relativeEndPropName="daily_booking_change_deadline"
          @change="handleAvailableHoursChange"
        >
          <span slot="suffix">까지</span>
        </AvailableHoursInput>
      </FormElement>

      <FormElement
        :enabled="shouldUpdate.autoclose_deadline"
        @checkbox-change="shouldUpdate.autoclose_deadline = !shouldUpdate.autoclose_deadline"
        label="폐강 시간"
        :error-message="autocloseDeadlineErrorMessage"
      >
        <AvailableHoursInput
          v-if="!autocloseDeadlineErrorMessage"
          type="R"
          :disabled="!shouldUpdate.autoclose_deadline"
          :relativeEnd="data.autoclose_deadline"
          relativeEndPropName="autoclose_deadline"
          @change="handleAvailableHoursChange"
        ></AvailableHoursInput>
      </FormElement>

      <FormElement
        :enabled="shouldUpdate.booking_auto_shift_available_minutes_from_start_deadline"
        @checkbox-change="
          shouldUpdate.booking_auto_shift_available_minutes_from_start_deadline = !shouldUpdate.booking_auto_shift_available_minutes_from_start_deadline
        "
        label="예약대기 자동변경 시간"
        :error-message="bookingAutoShiftAvailableMinutesFromStartErrorMessage"
      >
        <AvailableHoursInput
          v-if="!bookingAutoShiftAvailableMinutesFromStartErrorMessage"
          type="R"
          :disabled="!shouldUpdate.booking_auto_shift_available_minutes_from_start_deadline"
          :relativeEnd="data.booking_auto_shift_available_minutes_from_start_deadline"
          relativeEndPropName="booking_auto_shift_available_minutes_from_start_deadline"
          @change="handleAvailableHoursChange"
        ></AvailableHoursInput>
      </FormElement>

      <!--체크인 시간 -->
      <FormElement
        v-show="(studio.grade === 2 || studio.usable_enter) && studioPolicies.is_enter"
        :enabled="shouldUpdate.enterTime"
        @checkbox-change="shouldUpdate.enterTime = !shouldUpdate.enterTime"
        label="체크인 가능 시간"
        class="bulk-update-lectures__form__enter"
      >
        <AvailableHoursInput
          type="R"
          :disabled="!shouldUpdate.enterTime"
          :relativeStart="data.enter_start_at"
          relativeStartPropName="enter_start_at"
          :relativeEnd="data.enter_start_at"
          relativeEndPropName="enter_start_at"
          :setMaxTime="5"
          @change="handleAvailableHoursChange"
        >
          <span slot="prefix">수업 시작</span>
          <span slot="suffix">부터</span>
        </AvailableHoursInput>
        <AvailableHoursInput
          type="R"
          :disabled="!shouldUpdate.enterTime"
          :relativeStart="data.enter_end_at"
          relativeStartPropName="enter_end_at"
          :relativeEnd="data.enter_end_at"
          relativeEndPropName="enter_end_at"
          :afterText="true"
          :setMaxTime="5"
          @change="handleAvailableHoursChange"
        >
          <span slot="prefix">수업 종료</span>
          <span slot="suffix">까지</span>
        </AvailableHoursInput>
      </FormElement>
    </div>

    <div slot="footer">
      <a
        v-if="!!duplicatedLectures.length"
        v-loading="checkingDuplication"
        class="duplication-message"
        @click="showDuplicatedLectures = true"
        >강사 또는 룸이 중복되는 수업이 있습니다.</a
      >
      <PlainButton size="large" type="info" @click="handleClose">취소</PlainButton>
      <BaseButton size="large" :disabled="confirmButtonDisabled" @click="handleClickConfirm">확인</BaseButton>
    </div>

    <!-- 중복 수업 표시 모달 -->
    <el-dialog
      class="duplicated-lectures"
      title="강사/룸 중복"
      :visible="showDuplicatedLectures"
      @close="showDuplicatedLectures = false"
      append-to-body
    >
      <div class="duplicated-lectures__list-header">
        <span></span>
        <span>수업명</span>
        <span>수업일</span>
        <span>수업시간</span>
        <span>강사</span>
        <span>룸</span>
      </div>

      <ul class="duplicated-lectures__list">
        <li class="duplicated-lectures__list-item" v-for="lecture in duplicatedLectures" :key="lecture.id">
          <i class="el-icon-error"></i>
          <span>{{ lecture.title }}</span>
          <span>{{ lecture.lectureDate }}</span>
          <span>{{ lecture.lectureTime }}</span>
          <span :class="{ highlight: lecture.instructorDuplicated }">{{
            lecture.staff ? `${lecture.staff.name} 강사` : ''
          }}</span>
          <span :class="{ highlight: lecture.roomDuplicated }">{{ lecture.room ? `${lecture.room.name} 룸` : '' }}</span>
        </li>
      </ul>

      <div slot="footer" class="duplicated-lectures__buttons">
        <el-button @click="showDuplicatedLectures = false" type="primary">확인</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import FormElement from './FormElement';
import AvailableHoursInput from '@components/Settings/AvailableHoursInput';

const BOOKING_DEADLINE_PROPS = {
  R: ['rule_type', 'booking_deadline'],
  RR: ['rule_type', 'booking_startline', 'booking_deadline'],
  A: ['rule_type', 'booking_end_days', 'booking_end_time'],
  AR: ['rule_type', 'booking_start_days', 'booking_start_time', 'booking_end_days', 'booking_end_time'],
};

const CANCEL_DEADLINE_PROPS = {
  R: ['rule_type', 'booking_cancel_deadline'],
  RR: ['rule_type', 'booking_cancel_startline', 'booking_cancel_deadline'],
  A: ['rule_type', 'booking_cancel_end_days', 'booking_cancel_end_time'],
  AR: [
    'rule_type',
    'booking_cancel_start_days',
    'booking_cancel_start_time',
    'booking_cancel_end_days',
    'booking_cancel_end_time',
  ],
};

// const ENTER_DEADLINE_PROPS = {
//   R: ['rule_type', 'booking_deadline'],
//   RR: ['rule_type', 'booking_startline', 'booking_deadline'],
//   A: ['rule_type', 'booking_end_days', 'booking_end_time'],
//   AR: ['rule_type', 'booking_start_days', 'booking_start_time', 'booking_end_days', 'booking_end_time'],
// };

const PROPS_TO_LABEL = {
  title: '수업명',
  instructor_id: '강사',
  room_id: '룸',
  max_trainee: '최대 수강 인원',
  min_trainee: '최소 수강 인원',
  waiting_trainee_limit: '예약 대기 가능 인원',
  bookableTime: '예약 가능 시간',
  cancellableTime: '취소 가능 시간',
  daily_booking_change_deadline: '당일 예약 변경 가능 시간',
  autoclose_deadline: '폐강 시간',
  booking_auto_shift_available_minutes_from_start_deadline: '예약대기 자동변경 시간',
  enterTime: '체크인 가능 시간',
};

export default {
  components: {
    FormElement,
    AvailableHoursInput,
  },

  props: {
    show: Boolean,
    instructorOptions: { type: Array, default: () => [] },
    roomOptions: { type: Array, default: () => [] },
    lectures: { type: Array, required: true },
  },

  data() {
    return {
      data: {
        title: '',
        instructor_id: null,
        room_id: null,
        max_trainee: null,
        min_trainee: null,
        waiting_trainee_limit: 0,
        booking_startline: 0,
        booking_deadline: 0,
        booking_start_days: 8,
        booking_start_time: 1200,
        booking_end_days: 1,
        booking_end_time: 2400,
        booking_cancel_startline: 0,
        booking_cancel_deadline: 0,
        booking_cancel_start_days: 8,
        booking_cancel_start_time: 1200,
        booking_cancel_end_days: 1,
        booking_cancel_end_time: 2400,
        booking_auto_shift_available_minutes_from_start_deadline: 0,
        daily_booking_change_deadline: 0,
        autoclose_deadline: 0,
        enter_start_at: 0,
        enter_end_at: 0,
      },

      shouldUpdate: {
        title: false,
        instructor_id: false,
        room_id: false,
        max_trainee: false,
        min_trainee: false,
        waiting_trainee_limit: false,
        bookableTime: false,
        cancellableTime: false,
        booking_auto_shift_available_minutes_from_start_deadline: false,
        daily_booking_change_deadline: false,
        autoclose_deadline: false,
        enterTime: false,
      },

      checkingDuplication: false,
      showDuplicatedLectures: false,
      duplicatedLectures: [],
      updating: false,
    };
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },

    bookingRuleType() {
      const ruleTypes = _.uniq(this.lectures.map(({ booking_rule_type }) => booking_rule_type));
      if (!ruleTypes.length || ruleTypes.length >= 2) return null;
      return ruleTypes[0];
    },
    cancelRuleType() {
      const ruleTypes = _.uniq(this.lectures.map(({ booking_cancel_rule_type }) => booking_cancel_rule_type));
      if (!ruleTypes.length || ruleTypes.length >= 2) return null;
      return ruleTypes[0];
    },
    ruleType() {
      const ruleTypes = _.uniq(this.lectures.map(({ rule_type }) => rule_type));
      if (!ruleTypes.length || ruleTypes.length >= 2) return null;
      return ruleTypes[0];
    },

    courseType() {
      const courseTypes = _.uniq(this.lectures.map(({ type }) => type));
      if (!courseTypes.length || courseTypes.includes('P')) return null;
      return courseTypes[0];
    },

    maxTraineeMax() {
      return this.lectures.reduce((max, { max_trainee }) => {
        return Math.max(max, max_trainee);
      }, 0);
    },

    bookableTimeErrorMessage() {
      return !this.bookingRuleType ? '선택하신 수업의 예약 가능 시간은 일괄 수정할 수 없습니다.' : '';
    },

    cancellableTimeErrorMessage() {
      return !this.cancelRuleType ? '선택하신 수업의 취소 가능 시간은 일괄 수정할 수 없습니다.' : '';
    },

    maxTraineeErrorMessage() {
      return !this.courseType ? '프라이빗 수업은 최대 수강 인원을 수정할 수 없습니다.' : '';
    },

    minTraineeErrorMessage() {
      return !this.courseType ? '프라이빗 수업은 최소 수강 인원을 수정할 수 없습니다.' : '';
    },

    minTraineeWarningMessage() {
      if (this.hasPastLecture) {
        return '시작된 수업의 최소 수강 인원은 수정할 수 없습니다.';
      }
      return '';
    },

    waitingLimitErrorMessage() {
      return !this.courseType ? '프라이빗 수업은 예약 대기 가능 인원을 수정할 수 없습니다.' : '';
    },

    bookingChangeDeadlineErrorMessage() {
      return !this.courseType ? '프라이빗 수업은 당일 예약 변경 가능 시간을 수정할 수 없습니다' : '';
    },

    autocloseDeadlineErrorMessage() {
      return !this.courseType ? '프라이빗 수업은 폐강 시간을 수정할 수 없습니다' : '';
    },

    bookingAutoShiftAvailableMinutesFromStartErrorMessage() {
      return !this.courseType ? '프라이빗 수업은 예약대기 자동 예약 시간을 수정할 수 없습니다' : '';
    },

    propsToUpdate() {
      const shouldUpdate = Object.keys(this.shouldUpdate)
        .filter(key => this.shouldUpdate[key])
        .map(key => {
          if (key === 'bookableTime') {
            return BOOKING_DEADLINE_PROPS[this.bookingRuleType];
          } else if (key === 'cancellableTime') {
            return CANCEL_DEADLINE_PROPS[this.cancelRuleType];
          } else if (key === 'enterTime') {
            return ['enter_start_at', 'enter_end_at'];
          }
          return key;
        });

      return _.flattenDeep(shouldUpdate);
    },

    lecturesRange() {
      return this.lectures.reduce(
        (sum, lecture, index) => {
          if (index === 0) {
            const { start_on, end_on } = lecture;
            return { start: start_on, end: end_on };
          } else {
            const start = sum.start < lecture.start_on ? sum.start : lecture.start_on;
            const end = sum.end > lecture.end_on ? sum.end : lecture.end_on;
            return { start, end };
          }
        },
        { start: '', end: '' },
      );
    },

    confirmButtonDisabled() {
      return !this.propsToUpdate.length || this.checkingDuplication || !!this.duplicatedLectures.length;
    },

    hasPastLecture() {
      return _.some(this.lectures, lecture => this.moment(lecture.start_on).isBefore(this.moment()));
    },

    minTraineeUpdateAvailableLectures() {
      return this.lectures.filter(lecture => this.moment(lecture.start_on).isAfter(this.moment()));
    },

    updateLecturesLength() {
      // 최소 수강 인원만 변경할 때는 미래 수업만 수정 수업 갯수로 카운트
      const data = _.pick(this.data, this.propsToUpdate);
      const updateOnlyMinTrainee = !Object.keys(data).filter(prop => prop !== 'min_trainee').length;
      if (updateOnlyMinTrainee) {
        return this.minTraineeUpdateAvailableLectures.length;
      }
      return this.lectures.length;
    },

    minTraineeInputDisabled() {
      if (this.minTraineeUpdateAvailableLectures.length < 1) return true;
      return false;
    },
  },

  watch: {
    maxTraineeMax: {
      handler(value) {
        this.data.max_trainee = value;
      },
      immediate: true,
    },
    'data.instructor_id'() {
      this.checkDuplication();
    },
    'data.room_id'() {
      this.checkDuplication();
    },
    propsToUpdate() {
      if (this.propsToUpdate.some(key => ['instructor_id', 'room_id'].includes(key))) {
        this.checkDuplication();
      } else {
        this.duplicatedLectures = [];
      }
    },
  },

  methods: {
    handleAvailableHoursChange(value) {
      this.data = { ...this.data, ...value };
    },

    handleClose(refresh = false) {
      this.data = {
        title: '',
        instructor_id: null,
        room_id: null,
        max_trainee: null,
        min_trainee: null,
        waiting_trainee_limit: 0,
        booking_startline: 0,
        booking_deadline: 0,
        booking_start_days: 8,
        booking_start_time: 1200,
        booking_end_days: 1,
        booking_end_time: 2400,
        booking_cancel_startline: 0,
        booking_cancel_deadline: 0,
        booking_cancel_start_days: 8,
        booking_cancel_start_time: 1200,
        booking_cancel_end_days: 1,
        booking_cancel_end_time: 2400,
        daily_booking_change_deadline: 0,
        booking_auto_shift_available_minutes_from_start_deadline: 0,
        autoclose_deadline: 0,
        enter_start_at: 0,
        enter_end_at: 0,
      };
      this.shouldUpdate = {
        title: false,
        instructor_id: false,
        room_id: false,
        max_trainee: false,
        min_trainee: false,
        waiting_trainee_limit: false,
        bookableTime: false,
        cancellableTime: false,
        daily_booking_change_deadline: false,
        booking_auto_shift_available_minutes_from_start_deadline: false,
        autoclose_deadline: false,
        enterTime: false,
      };
      this.$emit('close', refresh);
    },

    async handleClickConfirm() {
      const { bookableTime, cancellableTime } = this.shouldUpdate;
      const data = _.pick(this.data, this.propsToUpdate);
      if (bookableTime) {
        data.booking_rule_type = this.bookingRuleType;
      }

      if (cancellableTime) {
        data.booking_cancel_rule_type = this.cancelRuleType;
      }

      if (!(await this.validate(data))) return;

      if (!(await this.confirmChange(data))) return;

      try {
        this.updating = true;
        if (data.hasOwnProperty('min_trainee')) {
          // 최소 수강 인원 수정 시, 미래 수업만 변경되도록 요청 분기
          await this.$api.lecture.updateAll({
            ...this.$utils.replaceNullWithMinusOne({ min_trainee: data.min_trainee }),
            lectures: this.minTraineeUpdateAvailableLectures.map(({ id }) => id),
          });
          await delete data.min_trainee;
        }
        if (Object.entries(data).length) {
          await this.$api.lecture.updateAll({
            ...this.$utils.replaceNullWithMinusOne(data),
            lectures: this.lectures.map(({ id }) => id),
          });
        }
        this.updating = false;
        const message = `${this.$filters.comma(this.updateLecturesLength)}개의 수업이 일괄 수정되었습니다.`;
        await this.$alert(message, null, {
          showClose: false,
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);
        this.handleClose(true);
      } catch (error) {
        this.updating = false;
        this.$utils.notify.parseError(this, error);
      }
    },

    async validate(data) {
      if (this.propsToUpdate.includes('title') && !data.title) {
        this.$utils.notify.error(this, '오류', '수업명을 입력해주세요.');
        this.$refs.title.$el.querySelector('input').focus();
        return false;
      } else if (this.propsToUpdate.includes('instructor_id') && !data.instructor_id) {
        this.$utils.notify.error(this, '오류', '강사를 선택해주세요.');
        this.$refs.instructor_id.$el.querySelector('input').focus();
        return false;
      } else if (this.propsToUpdate.includes('booking_startline') && data.booking_startline <= data.booking_deadline) {
        this.$utils.notify.error(this, '오류', '예약 가능 시간을 확인해주세요.');
        return false;
      } else if (
        this.propsToUpdate.includes('booking_cancel_startline') &&
        data.booking_cancel_startline <= data.booking_cancel_deadline
      ) {
        this.$utils.notify.error(this, '오류', '취소 가능 시간을 확인해주세요.');
        return false;
      } else if (this.propsToUpdate.includes('min_trainee') && isNaN(data.min_trainee)) {
        this.$utils.notify.error(this, '오류', '최소 수강 인원을 입력해주세요.');
        this.$refs.min_trainee.$el.querySelector('input').focus();
        return false;
      } else if (this.propsToUpdate.includes('waiting_trainee_limit') && isNaN(data.waiting_trainee_limit)) {
        this.$utils.notify.error(this, '오류', '예약 대기 가능 인원을 입력해주세요.');
        this.$refs.waiting_trainee_limit.$el.querySelector('input').focus();
        return false;
      }

      return true;
    },

    async confirmChange(data) {
      const title = '수업 일괄 수정';

      const messageContent = Object.keys(this.shouldUpdate)
        .filter(key => this.shouldUpdate[key])
        .reduce((array, key) => {
          const label = PROPS_TO_LABEL[key];
          let content = '';
          if (key === 'bookableTime') {
            content = this.getAvailableTimeText('booking', this.bookingRuleType, data);
          } else if (key === 'cancellableTime') {
            content = this.getAvailableTimeText('booking_cancel', this.cancelRuleType, data);
          } else if (key === 'daily_booking_change_deadline') {
            content = this.getAvailableTimeText('daily_booking_change', 'R', data);
          } else if (key === 'autoclose_deadline') {
            content = this.getAvailableTimeText('autoclose', 'R', data);
            content = content.replace('까지', '');
          } else if (key === 'booking_auto_shift_available_minutes_from_start_deadline') {
            content = this.getAvailableTimeText('booking_auto_shift_available_minutes_from_start', 'R', data);
            content = content.replace('까지', '');
          } else if (key === 'instructor_id') {
            const instructor = this.instructorOptions.find(({ value }) => value == data[key]);
            content = instructor.label;
          } else if (key === 'room_id') {
            const room = this.roomOptions.find(({ value }) => value == data[key]);
            content = _.get(room, 'label', '룸 없음');
          } else if (key === 'enterTime') {
            content = this.getAvailableTimeText(['enter_start', 'enter_end'], 'ENTER', data);
          } else {
            content = data[key];
          }
          array.push(`${label}: ${content}`);
          return array;
        }, [])
        .join('<br>');

      let minTraineeAlert = '';
      if (this.propsToUpdate.includes('min_trainee') && data.min_trainee > 0) {
        minTraineeAlert = `
            <br><br>
            <p style="color: #f95454;">
              최소 수강 인원 (${data.min_trainee}명) 이 채워지지 않으면 수업은 자동으로 삭제되고 예약은 자동 취소됩니다.
            </p>
          `;
      }

      const message = `
          ${this.$filters.comma(this.updateLecturesLength)}개의 수업이 다음과 같이 수정됩니다.<br>
          <b>${messageContent}</b><br>
          계속 하시겠습니까?
          ${minTraineeAlert}
        `;

      return await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);
    },

    getAvailableTimeText(propPrefix, ruleType, data) {
      const { parseHourMinute } = this.$utils.convertTime;

      if (ruleType === 'R') {
        const { hour, minute } = parseHourMinute(data[`${propPrefix}_deadline`]);
        return `수업시작 ${hour}시간 ${minute}분 전까지`;
      } else if (ruleType === 'RR') {
        const start = parseHourMinute(data[`${propPrefix}_startline`]);
        const end = parseHourMinute(data[`${propPrefix}_deadline`]);
        return `수업시작 ${start.hour}시간 ${start.minute}분 전부터 ${end.hour}시간 ${end.minute}분 전까지`;
      } else if (ruleType === 'A') {
        const hour = parseInt(data[`${propPrefix}_end_time`] / 100);
        const minute = parseInt(data[`${propPrefix}_end_time`] % 100);
        const endDay = data[`${propPrefix}_end_days`];
        return `수업시작 ${endDay}일 전 ${hour}시 ${minute}분까지`;
      } else if (ruleType === 'AR') {
        const startDay = data[`${propPrefix}_start_days`];
        const endDay = data[`${propPrefix}_end_days`];
        const startHour = parseInt(data[`${propPrefix}_start_time`] / 100);
        const startMinute = parseInt(data[`${propPrefix}_start_time`] % 100);
        const endHour = parseInt(data[`${propPrefix}_end_time`] / 100);
        const endMinute = parseInt(data[`${propPrefix}_end_time`] % 100);
        return `수업시작 ${startDay}일 전 ${startHour}시 ${startMinute}분부터 ${endDay}일 전 ${endHour}시 ${endMinute}분까지`;
      } else if (ruleType === 'ENTER') {
        //체크인 시간 체크할 때
        const startHour = parseInt(data[`${propPrefix[0]}_at`] / 60);
        const startMinute = parseInt(data[`${propPrefix[0]}_at`] % 60);
        const endHour = parseInt(data[`${propPrefix[1]}_at`] / 60);
        const endMinute = parseInt(data[`${propPrefix[1]}_at`] % 60);
        return `수업시작 ${startHour}시간 ${startMinute}분 전부터
                수업종료 후 ${endHour}시간 ${endMinute}분 까지`;
      }

      return null;
    },

    /** 강사, 룸 변경시 중복 체크 */
    async checkDuplication() {
      this.duplicatedLectures = [];
      this.checkingDuplication = true;

      /** 선택된 수업 범위 내의 수업 가져오기 */
      const { start, end } = this.lecturesRange;
      if (!start || !end) return;

      const start_date = this.moment(start).format('YYYY-MM-DD');
      const end_date = this.moment(end).format('YYYY-MM-DD');
      const start_time = this.moment(start).format('HH:mm');
      const end_time = this.moment(end).format('HH:mm');
      const period_time = `${start_time},${end_time}`;
      const params = { start_date, end_date, period_time };
      const res = await this.$api.lecture.getAll({ ...params, with: 'staff.avatars;staff.profile' });

      /** 강사/룸 중복되는 수업 걸러내기 */
      const shouldUpdateInstructorId = this.propsToUpdate.includes('instructor_id');
      const shouldUpdateRoomId = this.propsToUpdate.includes('room_id');
      const duplicates = this.lectures.reduce((duplicates, lecture) => {
        return [
          ...duplicates,
          ...res.data.filter(({ id, start_on, end_on, staff, room }) => {
            // 동일 수업 제외
            if (id === lecture.id) return false;
            // 시간 겹치는지
            const lectureTimeOverlaps = start_on < lecture.end_on && lecture.start_on < end_on;
            // 강사 겹치는지
            const instructorDuplicated = shouldUpdateInstructorId ? staff.id === this.data.instructor_id : false;
            // 룸 겹치는지
            const roomDuplicated = shouldUpdateRoomId && this.data.room_id ? _.get(room, 'id') === this.data.room_id : false;

            return lectureTimeOverlaps && (instructorDuplicated || roomDuplicated);
          }),
        ];
      }, []);

      /** 중복 제거 / 리스트 표시를 위한 추가 정보 */
      this.duplicatedLectures = this.appendAdditionalInfo(_.uniqBy(duplicates, 'id'), this.data.instructor_id, this.data.room_id);
      this.checkingDuplication = false;
    },

    /** 수업시간, 중복되는 항목 추가 */
    appendAdditionalInfo(lectures, instructorId, roomId) {
      return lectures.map(lecture => {
        const { start_on, end_on } = lecture;
        const date = this.$filters.dateKorean(start_on);
        const weekday = this.moment(start_on).format('(ddd)');
        const startOn = this.moment(start_on).format('HH:mm');
        const endOn = this.moment(end_on).format('HH:mm');

        return {
          ...lecture,
          lectureDate: `${date} ${weekday}`,
          lectureTime: `${startOn}~${endOn}`,
          instructorDuplicated: lecture.staff && lecture.staff.id === instructorId,
          roomDuplicated: lecture.room && lecture.room.id === roomId,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-update-lectures {
  /deep/ .el-dialog {
    max-width: 564px;
    width: 95%;
  }

  /deep/ .el-dialog__footer {
    border-top: 1px solid #ebebeb;
    padding: 20px;

    button {
      margin-left: 8px;
    }
  }

  &__form {
    &__select {
      /deep/ .el-input__inner {
        padding: 5px;
      }
    }

    &__number-input {
      /deep/ .el-input-number {
        width: 100%;

        /deep/ .el-input-number__decrease {
          border: none;
          border-radius: 4px 4px 0 0;
          bottom: 2px;
          width: 20px;
        }

        /deep/ .el-input-number__increase {
          border: none;
          border-radius: 0 0 4px 4px;
          width: 20px;
        }

        /deep/ .el-input__inner {
          padding: 0 25px 0 5px;
          text-align: left;
        }
      }
    }

    &__enter {
      p {
        margin-bottom: 15px;
      }
    }
  }
}

.duplication-message {
  color: $color-danger;
}

.duplicated-lectures {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 720px;
  }

  &__list {
    @include flex(column);
    margin-top: 5px;
    max-height: 40vh;
    overflow-y: auto;

    &-header,
    &-item {
      display: grid;
      grid-template-columns: 20px 2fr 3fr repeat(3, 2fr);
      place-items: center;
      padding-bottom: 8px;
    }

    &-header {
      border-bottom: 1px solid #ebebeb;
      margin-top: 8px;
      font-weight: bold;
    }

    &-item {
      i {
        color: $color-danger;
      }

      a,
      span {
        @include ellipsis;
        text-align: center;
        width: 100%;
      }

      span.highlight {
        color: $color-danger;
        font-weight: bold;
      }
    }
  }
}
</style>
