<template>
  <section class="ticket-detail">
    <form
      class="ticket-detail__form"
      :class="{
        rental_period: isRentalOnlyPeriod,
        rental_time: isRentalOnlyTime,
        rental_all: isRentalAll,
        sale_product: isSaleProduct,
      }"
    >
      <template v-if="!isSaleProduct">
        <div v-if="!isRentalOnlyTime" class="ticket-detail__form__element start_date">
          <label>이용시작일</label>
          <el-date-picker
            placeholder="이용시작일"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :picker-options="startDatePickerOptions"
            :clearable="false"
            v-model="ticketDetail.availability_start_at"
          />
        </div>

        <div v-if="!isRentalOnlyTime" class="ticket-detail__form__element end_date">
          <label>이용종료일</label>
          <el-date-picker
            placeholder="이용종료일"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :picker-options="endDatePickerOptions"
            :disabled="!ticketDetail.availability_start_at"
            :clearable="false"
            v-model="ticketDetail.expire_at"
          />
        </div>

        <div v-if="isProduct" class="ticket-detail__form__element staff">
          <label>담당강사</label>
          <el-select
            v-model="ticketDetail.staffs"
            placeholder="강사 선택"
            clearable
            filterable
            multiple
            :multiple-limit="1000"
            collapse-tags
          >
            <el-option v-for="staff in staffOptions" :key="staff.id" :value="staff.id" :label="staff.name" />
          </el-select>
        </div>

        <div v-if="isNotPeriodType" class="ticket-detail__form__element remaining_coupon">
          <label>잔여 횟수</label>
          <NumberInput
            v-model="ticketDetail.remaining_coupon"
            :min="bookedCount"
            :max="999 - deductedCount"
            @change="handleChangeNumberInput('remaining_coupon')"
          />
        </div>

        <div v-if="isNotPeriodType" class="ticket-detail__form__element max_coupon">
          <label>전체 횟수</label>
          <NumberInput
            v-model="ticketDetail.max_coupon"
            :min="deductedCount + bookedCount"
            :max="999"
            @change="handleChangeNumberInput('max_coupon')"
          />
        </div>

        <div v-if="userTicket.ticket.type === 'T'" class="ticket-detail__form__element remaining_cancel">
          <label>예약 취소 가능 횟수</label>
          <NumberInput
            v-model="ticketDetail.remaining_cancel"
            :min="0"
            :max="999"
            @change="handleChangeNumberInput('remaining_cancel')"
          />
        </div>

        <div
          v-if="isPeriodTicket && userTicket.is_show_cancel_count"
          class="ticket-detail__form__element plus-grid remaining_coupon"
        >
          <label>예약 취소 가능 횟수</label>
          <NumberInput
            v-model="ticketDetail.remaining_cancel"
            :min="0"
            :max="999"
            @change="handleChangeNumberInput('remaining_cancel')"
          />
        </div>

        <div v-if="!isProduct" class="ticket-detail__form__element is_shared">
          <label>패밀리 수강권 설정</label>
          <el-switch v-model="ticketDetail.is_shared" />
          <p>
            패밀리 수강권으로 가족이 함께 이용할 수 있습니다.<br />
            하나의 수업에 중복으로 예약할 수 있고 이용 가능한 총 횟수를 통합 관리합니다.<br />
            패밀리수강권은 '일일 예약 가능횟수 설정'에 영향받지 않습니다.
          </p>
        </div>
      </template>

      <div v-if="isSaleProduct || !isProduct" class="ticket-detail__form__element staff">
        <label>담당강사</label>
        <el-select
          v-model="ticketDetail.staffs"
          placeholder="강사 선택"
          clearable
          filterable
          multiple
          :multiple-limit="1000"
          collapse-tags
        >
          <el-option v-for="staff in staffOptions" :key="staff.id" :value="staff.id" :label="staff.name" />
        </el-select>
      </div>
    </form>

    <div class="ticket-detail__buttons">
      <el-button @click="handleClickSave" type="primary" :disabled="saveButtonDisabled">{{ title }} 정보 저장</el-button>
    </div>
  </section>
</template>

<script>
import { BOOKING_STATUS } from '@constants';

export default {
  props: {
    isProduct: Boolean,
    title: String,
  },

  data() {
    return {
      ticketDetail: {
        availability_start_at: this.moment().format('YYYY-MM-DD'),
        expire_at: this.moment().format('YYYY-MM-DD'),
        remaining_coupon: 0,
        max_coupon: 0,
        remaining_cancel: 0,
        is_shared: false,
        staffs: [],
      },
      loading: false,
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },

    staffOptions() {
      return _.get(this.formData, 'staffOptions');
    },
    userTicket() {
      return _.get(this.formData, 'userTicket');
    },
    deductedCount() {
      return _.get(this.formData, 'deductedCount', 0);
    },
    bookedCount() {
      return _.get(this.formData, 'bookedCount', 0);
    },
    userTicketBookings() {
      return _.get(this.formData, 'userTicketBookings', []);
    },
    holdings() {
      return _.get(this.userTicket, 'holdings');
    },

    isTicketDeleted() {
      return !!_.get(this.userTicket, 'ticket.deleted_at');
    },
    isPeriodTicket() {
      return _.get(this.userTicket, 'ticket.type') === 'P';
    },

    member() {
      return _.get(this.formData, 'userTicket.member');
    },

    endDatePickerOptions() {
      const { moment, ticketDetail } = this;
      return {
        disabledDate(time) {
          return moment(time).isBefore(ticketDetail.availability_start_at) || moment(time).isAfter('2031-12-31');
        },
      };
    },

    startDatePickerOptions() {
      const { moment } = this;
      return {
        disabledDate(time) {
          return moment(time).isAfter('2031-12-30');
        },
      };
    },

    saveButtonDisabled() {
      const valuesInvalid =
        this.ticketDetail.remaining_coupon === undefined ||
        this.ticketDetail.max_coupon === undefined ||
        this.ticketDetail.remaining_cancel === undefined ||
        !this.ticketDetail.expire_at ||
        !this.ticketDetail.availability_start_at;

      return valuesInvalid || !this.canUpdateMembersTicket || this.isTicketDeleted || this.loading;
    },

    isNotPeriodType() {
      const { type } = this.userTicket.ticket;
      return type === 'T' || type === 'RT' || type === 'RM';
    },

    isSaleProduct() {
      const { ticket } = this.userTicket;
      return ticket.type === 'S';
    },

    isRentalAll() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RM';
    },

    isRentalOnlyPeriod() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RP';
    },

    isRentalOnlyTime() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RT';
    },
  },

  async created() {
    this.ticketDetail = { ..._.get(this.formData, 'ticketDetail') };

    /** 시작일, 전체 횟수 변경 감지 */
    this.$watch('ticketDetail.availability_start_at', () => {
      this.setEndDate();
    });
  },

  watch: {
    userTicket: function() {
      this.ticketDetail = { ..._.get(this.formData, 'ticketDetail') };
    },
  },

  methods: {
    /** 종료일 자동 설정 */
    setEndDate() {
      const { moment, ticketDetail, userTicket } = this;
      const period = _.get(userTicket, 'ticket.available_period') - 1;
      const endDate = moment(ticketDetail.availability_start_at)
        .add(period, 'd')
        .format('YYYY-MM-DD');

      this.ticketDetail.expire_at = !this.ticketDetail.availability_start_at
        ? ''
        : moment(endDate).isAfter('2031-12-31')
        ? '2031-12-31'
        : endDate;
    },

    /** 숫자 입력 변경시 */
    handleChangeNumberInput(key) {
      if (key === 'remaining_coupon') {
        this.handleChangeRemainingCoupon();
      } else if (key === 'max_coupon') {
        this.handleChangeMaxCoupon();
      }

      this.preventNegativeValues(key);
    },

    /**
     * 잔여횟수 변경시: (잔여횟수 + 이미 차감된 횟수)가 전체횟수보다 클 경우
     * 전체횟수 = 잔여횟수
     */
    handleChangeRemainingCoupon() {
      const { remaining_coupon, max_coupon } = this.ticketDetail;
      if (remaining_coupon + this.deductedCount > max_coupon) {
        this.ticketDetail.max_coupon = remaining_coupon + this.deductedCount;
      }
    },

    /**
     * 전체횟수 변경시: 전체횟수가 (잔여횟수 + 이미 차감된 횟수)보다 작을 경우
     * 잔여횟수 = 전체횟수
     */
    handleChangeMaxCoupon() {
      const { remaining_coupon, max_coupon } = this.ticketDetail;
      if (remaining_coupon + this.deductedCount > max_coupon) {
        this.ticketDetail.remaining_coupon = max_coupon - this.deductedCount;
        this.preventNegativeValues('remaining_coupon');
      }
    },

    /** 음수입력 차단 */
    preventNegativeValues(key) {
      if (this.ticketDetail[key] < 0) this.ticketDetail[key] = 0;
    },

    /** 수강권 정보 저장 */
    async handleClickSave() {
      this.loading = true;

      const availability_start_at = this.moment(this.ticketDetail.availability_start_at).format('YYYY-MM-DD');
      const expire_at = this.moment(this.ticketDetail.expire_at).format('YYYY-MM-DD');

      const rangeConfirmed = await this.checkTicketRange(availability_start_at, expire_at);
      if (!rangeConfirmed) return;

      try {
        const data = {
          ...this.ticketDetail,
          availability_start_at,
          expire_at,
        };
        await this.$api.userTicket.update(this.userTicket.id, data);
        this.$utils.notify.success(this, '성공', `${this.title} 정보가 저장되었습니다.`);
        this.$store.dispatch('ticketEdit/getUserTicket', this.userTicket.id);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    async checkTicketRange(start, end) {
      // if (!this.userTicketBookings.length) return true;

      let unchangable = []; // '출석', '결석', '노쇼', '예약확정'
      let booked = [];
      this.userTicketBookings.forEach(booking => {
        const { status, lecture } = booking;
        const isInRange = this.moment(lecture.start_on).isBetween(
          this.moment(start).startOf('day'),
          this.moment(end).endOf('day'),
          null,
          '[)',
        );
        const isUnchangable = [
          BOOKING_STATUS.ATTENDED,
          BOOKING_STATUS.ABSENT,
          BOOKING_STATUS.NOSHOW,
          BOOKING_STATUS.CONFIRMED,
        ].includes(status);
        const isBooked = [BOOKING_STATUS.BOOKED, BOOKING_STATUS.WAITING].includes(status);

        if (!isInRange && isUnchangable) unchangable.push(booking);
        else if (!isInRange && isBooked) booked.push(booking);
      });

      if (unchangable.length) {
        const list = unchangable.map((booking, index) => {
          const courseTitle = booking.lecture.course.title;
          const startTime = this.$filters.datetime(booking.lecture.start_on);
          const status = this.$utils.translate.bookingStatus(booking.status);
          return `<p style="margin-left: 1em; color: #f56c6c">${index + 1}. <b>${startTime}</b> ${courseTitle} (${status})</p>`;
        });

        const title = '수강권 이용일 변경 불가';
        const message = `
            <p style="margin-bottom: 1em;">
              출결이 확정된 수업이 존재하여 수강권 이용일을 변경할 수 없습니다.
            </p>
            ${list.join('')}
          `;

        return await this.$alert(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => false)
          .catch(() => false);
      }

      if (booked.length) {
        const list = booked.map((booking, index) => {
          const lectureTitle = booking.lecture.title || booking.lecture.course.title;
          const startTime = this.$filters.datetime(booking.lecture.start_on);
          const status = this.$utils.translate.bookingStatus(booking.status);
          return `<p style="margin-left: 1em; color: #f56c6c">${index + 1}. <b>${startTime}</b> ${lectureTitle} (${status})</p>`;
        });

        const title = '수강권 이용일 변경';
        const message = `
            <p style="margin-bottom: 1em;">
              수강권 이용일을 <b>${start} ~ ${end}</b> 로 변경하시면 아래의 예약은 자동으로 취소됩니다.
            </p>
            ${list.join('')}
            <p style="margin-top: 1em;">
              계속 하시겠습니까?
            </p>
          `;

        return await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);
      }

      /**
       * * 수강권 정지 기간 보다 수업 종료일이 과거로 변경 안되도록 막는 기능
       */
      if (this.holdings.length) {
        let holdingDiffs = [];

        const list = this.holdings.map(holding => {
          const startTime = holding.start_on;
          const endTime = holding.end_on;

          const diff = this.$utils.getDaysDiff(this.ticketDetail.expire_at, endTime);

          holdingDiffs.push(diff);
          if (diff < 0) {
            return `<p style="margin-left: 1em; color: #f56c6c">정지기간정보: <b>${startTime}</b> ~ <b>${endTime}</b></p>`;
          }
        });

        const title = '수강권 이용일 변경 불가';
        const message = `
            <p style="margin-bottom: 1em;">
              정지 종료일보다 과거 날짜로 변경할 수 없습니다.
            </p>
            ${list.join('')}
          `;

        const result = holdingDiffs.some(holding => holding < 0);

        return result
          ? await this.$alert(message, title, {
              dangerouslyUseHTMLString: true,
            })
              .then(() => false)
              .catch(() => false)
          : true;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-detail {
  &__form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'start_date'
      'end_date'
      'remaining_coupon'
      'max_coupon'
      'remaining_cancel'
      'is_shared'
      'staff';

    &__element {
      @include flex(column);
      border-bottom: 1px solid rgba(#000, 0.04);
      padding: 15px;

      label {
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 5px;
      }
    }

    &__element.plus-grid {
      width: 290px;
    }
    &__element.start_date {
      grid-area: start_date;
    }
    &__element.end_date {
      grid-area: end_date;
    }
    &__element.remaining_coupon {
      grid-area: remaining_coupon;
    }
    &__element.max_coupon {
      grid-area: max_coupon;
    }
    &__element.remaining_cancel {
      grid-area: remaining_cancel;
    }
    &__element.is_shared {
      @include flex(row, center, space-between);
      flex-wrap: wrap;
      grid-area: is_shared;

      p {
        font-size: 10px;
      }
    }
    &__element.staff {
      grid-area: staff;

      /deep/ .el-select__tags > span {
        @include flex(row, center);
        flex-wrap: wrap;
      }

      /deep/ .el-select__input {
        margin-left: 0;
      }

      /deep/ .el-tag {
        background: none !important;
        color: $charcoal-grey;
        font-size: 14px;
        margin: 0;
        padding: 0;

        &::after {
          content: ', ';
        }

        &:last-child::after {
          content: '';
        }

        i {
          display: none;
        }
      }
    }
  }

  &__buttons {
    @include flex(row, flex-end, flex-end);
    flex: 1;
    width: 100%;
    padding: 20px 20px 0;
  }
}

.weekly-auto-rebalance-message {
  color: $color-danger;
  font-size: 11px;
  margin: 4px 0 0 4px;
}

@media (min-width: 568px) {
  .ticket-detail__form {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'start_date start_date start_date end_date end_date end_date'
      'remaining_coupon remaining_coupon max_coupon max_coupon remaining_cancel remaining_cancel'
      'is_shared is_shared is_shared is_shared staff staff';

    &__element.start_date,
    &__element.remaining_coupon,
    &__element.max_coupon,
    &__element.is_shared {
      border-right: 1px solid rgba(#000, 0.04);
    }

    &.sale_product {
      display: block;
      width: 100%;
    }

    &.sale_product &__element {
      padding: 15px 200px;
    }

    &.rental_all {
      grid-template-areas:
        'start_date start_date start_date end_date end_date end_date'
        'remaining_coupon remaining_coupon max_coupon max_coupon staff staff';
    }

    &.rental_all &__element {
      & > div {
        width: 100%;
      }
    }

    &.rental_period {
      display: flex;
      flex: none;
      width: 100%;

      & > div {
        flex: 1;
        width: 100%;
      }
    }

    &.rental_time {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'remaining_coupon max_coupon staff';
    }

    &.rental_period &__element,
    &.rental_time &__element {
      & > div {
        width: 100%;
      }

      &.end_date,
      &.max_coupon {
        border-right: 1px solid rgba(#000, 0.04);
      }
    }
  }
}
</style>
