import api from '@api';
import { BOOKING_STATUS, BOOKING_STATUS_ARRAY } from '@constants';

const DEFAULT_FORM_DATA = {
  currentTab: 'bookingHistory',
  user: null,
  userTicket: null,
  userTicketBookings: [],
  userTicketBookingsCount: {},
  userTicketTab: 'total',
  userTicketPage: 1,
  userTicketTotal: 100,
  deductedCount: 0, // 이미 사용한 수강권 횟수
  bookedCount: 0, // 예약된 횟수 (예약 | 예약확정)
  ticketDetail: null,
  history: [],
  memos: [],
  memosLoading: false,
  staffOptions: [],

};

export const state = {
  formData: { ...DEFAULT_FORM_DATA },
  loading: false,
  transferMembers: [],  
  transferKeyword: '',
  transferDetail: {
    availability_start_at: 0,
    expire_at: 0,
    remaining_coupon: 0,
    max_coupon: 0,
    remaining_cancel: 0,
    remaining_modify: 0,
  },
  pagination: {
    page: 1,
    limit: 10,
  },
  total: 0,
};

export const getters = {
  formData: state => state.formData,
  loading: state => state.loading,
  transferMembers: state => state.transferMembers,
  transferKeyword: state => state.transferKeyword,
  transferDetail: state => state.transferDetail,
  pagination: state => state.pagination,
  total: state => state.total,
};

export const mutations = {
  SET_VALUES(state, values) {
    state.formData = !values ? { ...DEFAULT_FORM_DATA } : { ...state.formData, ...values };
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_TRANSFER_MEMBERS(state, data) {
    if(data) {
      state.transferMembers = data.data;
      state.total = data.meta.total;
    } else {
      state.transferMembers = [];
      state.total = 0;
    }
  },

  SET_TRANSFER_KEYWORD(state, keyword) {
    state.transferKeyword = keyword;
  },

  SET_TRANSFER_TICKET(state, ticket) {
    state.transferDetail = ticket;
  },

  SET_PAGINATION(state, value) {
    state.pagination = {
      ...state.pagination,
      ...value,
    };
  },
};

export const actions = {
  async getUserTicket({ commit, dispatch }, userTicketId) {
    try {
      const staffOptions = await dispatch('getStaffOptions');
      const res = await api.userTicket.get(userTicketId, {  with: 'ticket.divisions' } );
      const data = mapUserTicketData(res.data, staffOptions);
      commit('SET_VALUES', data);
      dispatch('getBookings', {ticketId: userTicketId, status: 'total'});
      dispatch('getHistory', userTicketId);
    } catch (error) {
      commit('SET_VALUES', {});
    }
  },

  async getBookings({ commit }, { ticketId, status='total', limit=10, page=1 }) {
    try {
      commit('SET_VALUES', {
        userTicketTab: status,
        userTicketPage: page
      });

      const res = await api.userTicket.getPagedBookings(ticketId, status, {page, limit});
      let deductedCount = BOOKING_STATUS_ARRAY.AFTER_CLASS.reduce((prev, cur) => prev + res.data.kind[`${cur}_count`], 0);
      let bookedCount = res.data.kind[`${BOOKING_STATUS.BOOKED}_count`];

      commit('SET_VALUES', {
        userTicketBookings: res.data.bookings.data,
        userTicketBookingsCount: res.data.kind,
        deductedCount,
        bookedCount,
        userTicketTotal: res.data.bookings.total,
      });
    } catch (error) {
      commit('SET_VALUES', {
        userTicketBookings: [],
        userTicketBookingsCount: {},
        deductedCount: 0,
        bookedCount: 0,
        userTicketTotal: 0,
        userTicketTab: 'total',
        userTicketPage: 1
      });
    }
  },

  async getHistory({ commit }, ticketId) {
    try {
      const res = await api.userTicket.getHistory(ticketId);
      commit('SET_VALUES', { history: res.data });
    } catch (error) {
      commit('SET_VALUES', { history: [] });
    }
  },

  async getMemo({ commit }, ticketId) {
    try {
      commit('SET_VALUES', { memosLoading: true });
      const res = await api.memo.getAll('user_tickets', ticketId);
      commit('SET_VALUES', {
        memos: res.data,
        memosLoading: false,
      });
    } catch (error) {
      commit('SET_VALUES', {
        memos: [],
        memosLoading: false,
      });
    }
  },

  async getStaffOptions() {
    try {
      const res = await api.staff.getAll();
      return res.data.staffs.map(staff => ({
        id: staff.id,
        name: staff.profile.name,
      }));
    } catch (error) {
      return [];
    }
  },
  
  async getTransferMembers({ commit }, transferUserId) {
    try {
      commit('SET_LOADING', true);
      let params = {
        transfer_user_id: transferUserId,
        page: state.pagination.page,
        limit: state.pagination.limit,
        sort_name: 'asc',
      }

      if(state.transferKeyword) {
        params = {
          ...params,
          search_name: state.transferKeyword
        }
      }

      const res = await 
        api.member.getAll({ ...params, with: 'profile;account;avatars;tickets.ticket' });
        
      commit('SET_TRANSFER_MEMBERS', res.data);
    } catch (error) {
      commit('SET_TRANSFER_MEMBERS');
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

const mapUserTicketData = (userTicket, staffOptions) => {
  if (!userTicket) {
    return {
      userTicket: null,
      ticketDetail: null,
      staffOptions,
    };
  }

  const staffIds = staffOptions.map(({ id }) => id);
  const validStaffIds = userTicket.staffs.filter(({ id }) => staffIds.includes(id)).map(({ id }) => id);

  const ticketDetail = {
    availability_start_at: userTicket.availability_start_at,
    expire_at: userTicket.expire_at,
    remaining_coupon: userTicket.remaining_coupon,
    max_coupon: userTicket.max_coupon,
    remaining_cancel: userTicket.remaining_cancel,
    is_shared: userTicket.is_shared === 1,
    staffs: validStaffIds,
  };

  return { userTicket, ticketDetail, staffOptions };
};
