<template>
  <el-tooltip effect="light" :content="content" placement="top">
    <CircleButton class="filter-reset-button" @click="$emit('click')" bordered>
      <img src="@assets/images/icons/refresh.svg" alt="refresh" />
    </CircleButton>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    labelTooltip: String,
  },
  computed: {
    content() {
      return this.labelTooltip ? this.labelTooltip : '필터 초기화';
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-reset-button {
  padding: 0;
}
</style>
