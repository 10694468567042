import _ from 'lodash';

/** 회원 수강권을 회원 리스트로 변환 */
export default userTickets => {
  if (!userTickets) return [];

  const members = userTickets.reduce((members, ticket) => {
    const { id } = ticket.member;

    if (!members[id]) {
      members[id] = {
        ...ticket.member,
        tickets: [_.omit(ticket, 'member')],
      };
    } else {
      members[id].tickets.push(_.omit(ticket, 'member'));
    }
    return members;
  }, {});

  return Object.values(members);
};
