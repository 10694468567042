<template>
  <label :class="{ disabled }">
    <input
      v-bind="$attrs"
      type="checkbox"
      :disabled="disabled"
      :class="{ indeterminate, disabled }"
      :style="style"
      @change="$emit('change', value)"
    />
    <slot></slot>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    color: { type: String, default: '#4799f2' },
    value: [Number, String],
    disabled: Boolean,
    indeterminate: Boolean,
  },

  computed: {
    style() {
      return { '--color': this.color };
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  padding: 0.5rem;

  &.disabled {
    cursor: not-allowed;
  }

  input {
    appearance: none;
    cursor: pointer;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 16px;
    height: 16px;
    margin: 0 1rem 0 0;
    outline: none !important;
    border: 1px solid $gray-400;
    border-radius: 2px;
    background: transparent;
    color: white;
    opacity: 1;
    transition: 0.15s opacity, 0.15s background;

    &::before {
      content: '';
      left: 4.5px;
      top: 1.5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      border-radius: 1px;
      position: absolute;
      transform: rotate(-40deg);
      transition: 0.15s opacity, 0.15s transform;
      opacity: 0;
    }

    &:checked {
      background: var(--color);
      border: 1px solid var(--color);

      &:hover {
        opacity: 0.7;
        background: var(--color);
        border: 1px solid var(--color);
      }

      &::before {
        transform: rotate(40deg);
        opacity: 1;
      }
    }

    &.indeterminate {
      background: var(--color);
      border: 1px solid var(--color);
      opacity: 1;

      &::before {
        width: 0;
        top: 2.5px;
        left: 6px;
        transform: rotate(90deg);
        opacity: 1;
      }
    }

    &:hover {
      border: 1px solid $gray-500;
    }

    &:disabled,
    &:disabled:hover {
      cursor: not-allowed;
      background: $gray-400;
      border: 1px solid $gray-400;
    }
  }
}
</style>
