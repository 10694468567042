<template>
  <button :class="[size, bordered ? 'bordered' : null]" :type="type" @click.stop="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    size: { type: String, default: 'medium' },
    bordered: { type: Boolean, default: false },
    type: String,
  },
};
</script>

<style lang="scss" scoped>
button {
  @include flex(row, center, center);
  border-radius: 50%;
  transition: 0.15s;

  &.large {
    width: 48px;
    height: 48px;
  }

  &.medium {
    width: 36px;
    height: 36px;
  }

  &.small {
    width: 24px;
    height: 24px;
  }

  &.bordered {
    border: 1px solid #e1e5ea;
  }

  &:hover {
    background: $gray-200;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background: none;
    }
  }
}
</style>
