<template>
  <div class="container">
    <div class="summary">
      <div class="prev-summary">
        <span class="title">지난 달</span>
        <div v-for="{ label, point, name } in prevData" :key="name">
          <span class="sub-title">{{ $filters.comma(label) }}</span>
          <span>{{ $filters.comma(point) }}P</span>
        </div>
      </div>

      <PointSummary
        v-loading="dashBoardLoading"
        value="remaining_point"
        :summaryItems="dashBoardData"
        :name="filterOptions[1].name"
      />
    </div>

    <FilterNew
      downloadText="엑셀 다운로드"
      :filterOptions="filterOptions"
      :refreshClick="refresh"
      :handleDownload="downloadExcel"
    />

    <SubHeaderNew
      placeholder="회원명 검색"
      :count="total"
      :search="search"
      :handleSearchChange="handleSearchChange"
      :searchResult="searchResult"
    />

    <PointHistoryList v-loading="historiesLoading" :histories="histories" @sort-change="sortChange" />

    <el-pagination
      layout="prev, pager, next, sizes"
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 15, 20, 50]"
      :total="total"
      @current-change="pageChange"
      @size-change="limitChange"
    />
  </div>
</template>

<script>
import { POINT_TYPE_OPTIONS, POINT_STATUS_OPTIONS, POINT_TYPE, POINT_STATUS } from '@constants';

import PointSummary from '@components/Sales/PointSummary';
import FilterNew from '@components/Sales/FilterNew';
import SubHeaderNew from '@components/Sales/SubHeaderNew';
import moment from 'moment';
import PointHistoryList from '@components/MemberDetail/PointHistory/PointHistoryList';

export default {
  components: {
    PointSummary,
    FilterNew,
    SubHeaderNew,
    PointHistoryList,
  },

  data() {
    return {
      prevData: [
        {
          label: '적립',
          point: 0,
          name: 'reward',
        },
        {
          label: '차감',
          point: 0,
          name: 'deduct',
        },
        {
          label: '소멸 포인트',
          point: 0,
          name: 'destruction',
        },
      ],

      dashBoardData: [
        {
          label: '적립 포인트',
          count: 0,
          point: 0,
          name: 'reward',
          info: '수강권, 상품 발급 시 적립된 포인트 또는 관리자에 의해 적립된 포인트의 합계',
        },
        {
          label: '차감 포인트',
          count: 0,
          point: 0,
          name: 'deduct',
          info: '수강권, 상품 결제 시 사용한 포인트 또는 관리자에 의해 수동으로 차감된 포인트의 합계',
        },
        {
          label: '환불 포인트',
          count: 0,
          point: 0,
          name: 'refundReward',
          info: '수강권, 상품 환불 시 회원에게 환불되는 포인트의 합계',
        },
        {
          label: '회수 포인트',
          count: 0,
          point: 0,
          name: 'refundPaid',
          info: '수강권, 상품 환불 시 회원으로부터 회수되는 포인트의 합계',
        },
        {
          label: '소멸 포인트',
          count: 0,
          point: 0,
          name: 'destruction',
          info: '설정한 날짜에 맞춰 자동으로 소멸되는 포인트',
        },
        {
          label: '잔여 포인트',
          point: 0,
          name: 'current',
          info: '전체 회원의 잔여 포인트 합계 (오늘 기준)',
        },
      ],

      filterOptions: [
        {
          name: 'staff',
          value: [],
          options: [],
          onChange: this.handleSelectChange,
          multiple: true,
          placeholder: '강사 전체',
        },
        {
          name: 'pointType',
          value: [],
          options: POINT_TYPE_OPTIONS,
          onChange: this.handleSelectChange,
          multiple: true,
          placeholder: '구분 전체',
        },
        {
          name: 'pointStatus',
          value: [],
          options: POINT_STATUS_OPTIONS,
          onChange: this.handleSelectChange,
          multiple: true,
          placeholder: '분류 전체',
        },
      ],

      historyResponse: {
        data: [],
        meta: {
          total: 0,
          staffs: [],
        },
      },
      search: '',
      page: 1,
      sort: 'asc',
      limit: 10,
      historiesLoading: false,
      dashBoardLoading: false,
    };
  },

  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },

    histories() {
      return this.historyResponse.data;
    },

    total() {
      return this.historyResponse.meta.total;
    },

    staffOptions() {
      const currentStaffs = this.historyResponse.meta.staffs.map(staff => {
        return {
          value: staff.id,
          label: staff.name,
        };
      });
      return currentStaffs;
    },
  },

  watch: {
    date() {
      this.page = 1;
      this.fetchPrevData();
      this.fetchDashBoard();
      this.fetchTableData();
    },

    filterOptions() {
      this.fetchTableData();
    },

    limit() {
      this.fetchTableData();
    },

    page() {
      this.fetchTableData();
    },

    sort() {
      this.fetchTableData();
    },

    staffOptions() {
      this.filterOptions[0].options = this.staffOptions;
    },
  },

  mounted() {
    this.fetchPrevData();
    this.fetchDashBoard();
    this.fetchTableData();
  },

  methods: {
    async fetchPrevData() {
      this.dashBoardLoading = true;

      try {
        const lastMonth = this.moment(this.date.date).subtract(1, 'month');
        const start_date = lastMonth.format('YYYY-MM-01');
        const end_date = lastMonth.endOf('month').format('YYYY-MM-DD');
        const params = {
          start_date,
          end_date,
        };
        const res = await this.$api.salesNew.getDashboard(params);
        const newPrevData = [...this.prevData];
        newPrevData.forEach(data => {
          data.point = res.data[data.name].point;
        });
        this.prevData = newPrevData;
      } catch (error) {
        this.$utils.notify.error(this, '오류', error.response.data.message);
      } finally {
        this.dashBoardLoading = false;
      }
    },

    async fetchDashBoard() {
      this.dashBoardLoading = true;
      try {
        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
        };
        const res = await this.$api.salesNew.getDashboard(params);
        const newDashBoardData = [...this.dashBoardData];
        newDashBoardData.forEach(data => {
          const isRefund = data.name.includes('refund');
          const name = isRefund ? 'refund' : data.name;
          const currentData = res.data[name];
          if (currentData) {
            if (isRefund) {
              data.point = data.name === 'refundReward' ? currentData.reward_point : currentData.paid_point;
            } else {
              data.point = currentData.point;
            }
            data.count = currentData.count;
          }
        });
        this.dashBoardData = newDashBoardData;
      } catch (error) {
        this.$utils.notify.error(this, '오류', error.response.data.message);
      } finally {
        this.dashBoardLoading = false;
      }
    },

    async fetchTableData() {
      this.historiesLoading = true;
      try {
        const staff_ids = this.filterOptions[0].value;
        const type = this.filterOptions[1].value;
        const status = this.filterOptions[2].value;
        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
          staff_ids,
          type,
          status,
          search: this.search,
          limit: this.limit,
          page: this.page,
          order_by: this.sort,
        };
        const res = await this.$api.salesNew.getHistories(params);
        this.historyResponse = res.data;
      } catch (error) {
        this.$utils.notify.error(this, '오류', error.response.data.message);
      } finally {
        this.historiesLoading = false;
      }
    },

    refresh() {
      this.handleSelectChange('refresh', []);
    },

    handleSearchChange(value) {
      this.search = value;
    },

    searchResult() {
      this.page = 1;
      this.fetchTableData();
    },

    handleSelectChange(type, value) {
      const newFilterOptions = [...this.filterOptions];

      if (type === 'refresh') {
        newFilterOptions.forEach(filter => (filter.value = []));
      } else {
        newFilterOptions.find(filter => filter.name === type).value = value;
      }
      this.filterOptions = newFilterOptions;
      this.page = 1;
    },

    formatUpdateFor({ reward_point, point_amount, point_type }) {
      const rewardPoint = this.$filters.comma(reward_point);
      const pointAmount = this.$filters.comma(point_amount);
      if (point_type === 'mix') {
        return `${rewardPoint} / ${pointAmount}`;
      }
      return rewardPoint || pointAmount;
    },

    formatJSON(histories) {
      if (!histories || !histories.length) return [];
      return histories.map(({ settlement_at, member, point_type, reward_point, point_amount, updated_for, status, ...rest }) => {
        const date = moment(settlement_at).format('YYYY. MM. DD (ddd) HH:mm');
        const rewardPoint = this.$filters.comma(reward_point);
        const pointAmount = this.$filters.comma(point_amount);

        return {
          일시: date,
          스태프: rest.staff ? rest.staff.name : '시스템',
          회원명: member.name,
          구분: POINT_TYPE[point_type],
          분류: POINT_STATUS[status],
          '적립(환불)': rewardPoint || '',
          '차감(회수)': pointAmount || '',
          '포인트 수정 사유': updated_for || '',
        };
      });
    },

    async downloadExcel() {
      try {
        const staff_ids = this.filterOptions[0].value;
        const type = this.filterOptions[1].value;
        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
          staff_ids,
          type,
          search: this.search,
          limit: this.limit,
          page: this.page,
          order_by: this.sort,
        };
        const data = await this.$utils.promiseAllData(this.$api.salesNew.getHistories, params);
        const json = this.formatJSON(data);
        this.$utils.downloadExcel(json, `포인트_현황${params.start_date}~${params.end_date}.xlsx`);
      } catch (error) {
        this.$utils.notify.error(this, '오류', error.response.data.message);
      }
    },

    pageChange(value) {
      this.page = value;
    },

    limitChange(value) {
      this.limit = value;
    },

    sortChange(value) {
      this.sort = value === 'ascending' ? 'asc' : 'desc';
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include flex(column);
  margin: auto;
  padding: 30px;
  max-width: 1440px;
  gap: 30px;

  /deep/ .el-pagination {
    @include flex(row, center, center);
  }

  .summary {
    @include flex(row);

    .prev-summary {
      @include flex(column, flex-end);
      margin-right: 8px;
      padding: 20px;
      background-color: #f8f8f8;
      border: 1px solid #dcdfe6;
      border-radius: 8px;
      width: 222px;
      height: 200px;
      color: #545454;

      .title {
        align-self: flex-start;
        margin-bottom: 37px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.25px;
      }

      > div {
        @include flex(row, center, space-between);
        margin-bottom: 6px;
        width: 100%;
        font-weight: 500;

        .sub-title {
          color: rgba(84, 84, 84, 0.8);
          font-weight: 400;
        }
      }
    }
  }
}
</style>
