<template>
  <div :class="`table-container ${type}`">
    <el-table
      :data="tableData"
      style="width: 100%"
      v-if="type === 'spending'"
      @row-click="tableRowClick"
      @sort-change="sortChange"
      :default-sort="{ prop: 'settlement_at', order: 'ascending' }"
    >
      <el-table-column
        prop="settlement_at"
        label="결제일"
        width="130px"
        align="center"
        :formatter="formatSettlementAt"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      >
      </el-table-column>
      <el-table-column prop="context" label="지출 내용" min-width="300px" align="center" class-name="ellipsis"> </el-table-column>
      <el-table-column label="지출 금액" width="110px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.amount.toLocaleString() }}원</span>
          <span v-if="scope.row.installment_period > 1"
            ><br />({{
              (scope.row.card_amount + scope.row.cash_amount + scope.row.wiretransfer_amount).toLocaleString()
            }}원)</span
          >
        </template>
      </el-table-column>
      <el-table-column label="결제 방법" min-width="80px" align="center" :formatter="formatSpendingMethod"> </el-table-column>
      <el-table-column label="할부" width="80px" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.installment_period === 1">일시불</span>
          <template v-else>
            <span>할부</span><br />
            <span>({{ scope.row.installment_period }}개월)</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="담당 강사" width="140px" align="center" :formatter="formatStaff"> </el-table-column>
      <el-table-column label="첨부파일" width="80px" align="center" :formatter="formatAttachment"> </el-table-column>
    </el-table>

    <el-table
      :data="tableData"
      style="width: 100%"
      v-else-if="type === 'ticket'"
      @row-click="tableRowClick"
      @sort-change="sortChange"
      :default-sort="{ prop: 'settlement_at', order: 'ascending' }"
    >
      <el-table-column prop="type" label="구분" width="90px" align="center" :formatter="formatType"> </el-table-column>
      <el-table-column prop="class_type" label="수업" width="90px" align="center" :formatter="formatClassType"> </el-table-column>
      <el-table-column
        prop="settlement_at"
        label="결제일"
        width="130px"
        align="center"
        :formatter="formatSettlementAt"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      >
      </el-table-column>
      <el-table-column prop="member_name" label="회원명" width="140px" align="center"> </el-table-column>
      <el-table-column prop="ticket_name" label="수강권명" min-width="200px" align="center" class-name="ellipsis">
      </el-table-column>
      <el-table-column label="결제 금액" min-width="110px" align="center" class-name="background">
        <span slot="header">결제 금액<br /><span class="red small">(환불금액)</span></span>
        <template slot-scope="scope">
          <span v-html="getAmountText(scope.row)"></span>
        </template>
      </el-table-column>
      <el-table-column min-width="110px" align="center">
        <span slot="header">포인트 금액<br /><span class="red small">(환불 포인트)</span></span>
        <template slot-scope="scope">
          <span v-html="getPointText(scope.row)"></span>
        </template>
      </el-table-column>
      <el-table-column label="회당 금액" min-width="110px" align="center">
        <span slot="header">회당 금액<br /><span class="gray small">(전체 횟수)</span></span>
        <template slot-scope="scope">
          <span v-html="getAtTimeAmountText(scope.row)"></span>
        </template>
      </el-table-column>
      <el-table-column label="미수금" min-width="110px" align="center">
        <span slot="header">미수금<br /><span class="red small">(위약금)</span></span>
        <template slot-scope="scope">
          <span v-html="unPaymentText(scope.row)"></span>
        </template>
      </el-table-column>
      <el-table-column prop="method" label="결제 방법" width="80px" align="center" :formatter="formatItemMethod">
      </el-table-column>
      <el-table-column prop="staffs" label="담당 강사" width="120px" align="center" :formatter="formatStaffs"> </el-table-column>
    </el-table>

    <el-table
      :data="tableData"
      style="width: 100%"
      v-else-if="type === 'item'"
      @row-click="tableRowClick"
      @sort-change="sortChange"
      :default-sort="{ prop: 'settlement_at', order: 'ascending' }"
    >
      <el-table-column prop="type" label="구분" width="90px" align="center" :formatter="formatItemType"> </el-table-column>
      <el-table-column
        prop="settlement_at"
        label="결제일"
        width="130px"
        align="center"
        :formatter="formatSettlementAt"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      >
      </el-table-column>
      <el-table-column prop="member_name" label="회원명" min-width="140px" align="center" class-name="ellipsis">
      </el-table-column>
      <el-table-column prop="item_name" label="상품명" min-width="140px" align="center" class-name="ellipsis"> </el-table-column>
      <el-table-column label="결제 금액" min-width="110px" align="center">
        <span slot="header">결제 금액<br /><span class="red small">(환불금액)</span></span>
        <template slot-scope="scope">
          <span v-html="getAmountText(scope.row)"></span>
        </template>
      </el-table-column>

      <el-table-column min-width="110px" align="center">
        <span slot="header">포인트 금액<br /><span class="red small">(환불 포인트)</span></span>
        <template slot-scope="scope">
          <span v-html="getPointText(scope.row)"></span>
        </template>
      </el-table-column>

      <el-table-column label="미수금" min-width="110px" align="center">
        <span slot="header">미수금<br /><span class="red small">(위약금)</span></span>
        <template slot-scope="scope">
          <span v-html="unPaymentText(scope.row)"></span>
        </template>
      </el-table-column>
      <el-table-column prop="method" label="결제 방법" width="160px" align="center" :formatter="formatItemMethod">
      </el-table-column>
      <el-table-column prop="staffs" label="담당 강사" width="120px" align="center" :formatter="formatStaffs"> </el-table-column>
    </el-table>

    <el-table
      :data="tableData"
      style="width: 100%"
      v-else-if="type === 'un-payment'"
      @row-click="tableRowClick"
      @sort-change="sortChange"
      :default-sort="{ prop: 'recentDate', order: 'ascending' }"
    >
      <el-table-column label="구분" width="90px" align="center" :formatter="formatUnPaymentType"> </el-table-column>
      <el-table-column
        prop="recentDate"
        label="최근 결제일"
        width="130px"
        align="center"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
        :formatter="formatRecentDate"
      ></el-table-column>
      <el-table-column prop="recentPaymentMethod" label="최근 결제방법" width="140px" align="center"> </el-table-column>
      <el-table-column prop="userName" label="회원명" width="140px" align="center"> </el-table-column>
      <el-table-column prop="productName" label="상품명" min-width="200px" align="center" class-name="ellipsis">
      </el-table-column>
      <el-table-column label="누적 결제금액" min-width="80px" align="center" :formatter="formatUnPaymentAmount">
      </el-table-column>
      <el-table-column label="남은 미수금" min-width="80px" align="center" :formatter="formatMoreUnPaymentAmount">
      </el-table-column>
    </el-table>

    <el-table
      :data="tableData"
      style="width: 100%"
      v-else-if="type === 'lecture'"
      @sort-change="sortChange"
      :default-sort="{ prop: 'lectureDate', order: 'ascending' }"
    >
      <el-table-column prop="type" label="수업" width="90px" align="center" :formatter="formatClassType"> </el-table-column>
      <el-table-column label="수강권" width="90px" align="center" :formatter="formatTicketType"></el-table-column>
      <el-table-column
        label="수업 일시"
        width="130px"
        align="center"
        prop="lectureDate"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      >
        <template slot-scope="scope">
          <span> {{ scope.row.lectureTime ? moment(scope.row.lectureTime.startOn).format('YYYY. MM. DD(ddd)') : '-' }}</span>
          <br />
          <span v-if="scope.row.lectureTime">
            {{ moment(scope.row.lectureTime.startOn).format('HH:mm') }} ~
            {{ moment(scope.row.lectureTime.endOn).format('HH:mm') }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="회원명" width="130px" align="center" prop="memberName" class-name="ellipsis"></el-table-column>
      <el-table-column label="수강권명" width="130px" align="center" prop="ticketName" class-name="ellipsis"></el-table-column>
      <el-table-column min-width="110px" align="center">
        <span slot="header">회당 금액<br /><span class="gray small">(전체 횟수)</span></span>
        <template slot-scope="scope">
          <template v-if="!scope.row.isPeriod">
            <span>{{ scope.row.oneTimeAmount.toLocaleString() }}원</span>
            <span class="gray small"> ({{ scope.row.maxCoupon.toLocaleString() }}회)</span>
          </template>
          <template v-else><span>-</span></template>
        </template>
      </el-table-column>
      <el-table-column min-width="110px" align="center">
        <span slot="header">차감 금액<br /><span class="gray small">(차감 횟수)</span></span>
        <template slot-scope="scope">
          <template v-if="!scope.row.isPeriod">
            <span>{{ scope.row.deductedAmount.toLocaleString() }}원</span>
            <span class="gray small"> ({{ scope.row.deductedCount.toLocaleString() }}회)</span>
          </template>
          <template v-else><span>-</span></template>
        </template>
      </el-table-column>
      <el-table-column min-width="110px" align="center">
        <span slot="header">누적사용 금액<br /><span class="gray small">(누적사용 횟수)</span></span>
        <template slot-scope="scope">
          <template v-if="!scope.row.isPeriod">
            <span>{{ scope.row.cumulativeAmount.toLocaleString() }}원</span>
            <span class="gray small"> ({{ scope.row.cumulativeCount.toLocaleString() }}회)</span>
          </template>
          <template v-else><span>-</span></template>
        </template>
      </el-table-column>
      <el-table-column min-width="110px" align="center" class-name="background">
        <span slot="header">미수업금<br /><span class="gray small">(잔여 횟수)</span></span>
        <template slot-scope="scope">
          <template v-if="!scope.row.isPeriod">
            <span>{{ scope.row.remainingAmount.toLocaleString() }}원</span>
            <span class="gray small"> ({{ scope.row.remainingCount.toLocaleString() }}회)</span>
          </template>
          <template v-else><span>-</span></template>
        </template>
      </el-table-column>
      <el-table-column label="결제 금액" min-width="110px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.totalAmount.toLocaleString() }}원</span><br />
        </template>
      </el-table-column>
      <el-table-column label="수업 강사" width="130px" align="center" prop="staffName" class-name="ellipsis"></el-table-column>
      <el-table-column label="출결" width="70px" align="center" :formatter="formatLectureAttendance"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from 'moment';
import { BOOKING_STATUS_LABEL } from '@constants';

export default {
  props: {
    tableData: Array,
    type: String,
    tableRowClick: Function,
    sortChange: Function,
    hidePaymentMethod: Boolean,
  },
  watch: {
    tableData: function() {},
  },

  methods: {
    formatSettlementAt: function({ settlement_at, settlementAt }) {
      return settlement_at ? moment(settlement_at).format('YYYY. MM. DD (dd)') : moment(settlementAt).format('YYYY. MM. DD (dd)');
    },
    formatStaff: function({ staffs }) {
      if (!staffs) return '-';
      return staffs.name;
    },
    formatSpendingMethod: function({ cash_amount, card_amount, wiretransfer_amount }) {
      const arr = [];
      if (card_amount > 0) arr.push('카드');
      if (cash_amount > 0) arr.push('현금');
      if (wiretransfer_amount > 0) arr.push('계좌이체');
      if (arr.length > 0) return arr.join('+');
      else return '-';
    },
    formatItemMethod: function(row) {
      if (this.hidePaymentMethod) return '-';
      const { card_amount, cash_amount, wiretransfer_amount, point_amount } = row.amount;
      const arr = [];
      if (card_amount > 0) arr.push('카드');
      if (cash_amount > 0) arr.push('현금');
      if (wiretransfer_amount > 0) arr.push('계좌이체');
      if (point_amount > 0) arr.push('포인트');
      if (arr.length > 0) return arr.join('+');
      else return '-';
    },
    formatAttachment: function({ attachments }) {
      return attachments.length > 0 ? 'O' : '-';
    },

    formatType: function({ type, orderPayments, user_ticket_id }) {
      const filterOrders = orderPayments.filter(
        ({ goods_id, goods_type }) => user_ticket_id === goods_id && goods_type === 'user_tickets',
      );
      const isExperience = filterOrders.find(order => order.type === 'experience');
      const isNew = filterOrders.find(order => order.type === 'new');
      const map = {
        new: '신규 결제',
        're-take': '재결제',
        experience: '체험',
        refund: '환불',
        upgrade: '업그레이드',
        installment_payment: isExperience ? '미수금 결제(체험)' : isNew ? '미수금 결제(신규)' : '미수금 결제(재결제)',
        commission: '양도 / 환불',
        transfer: '양도',
      };
      return map[type];
    },
    formatClassType: function({ class_type, lectureType }) {
      const map = {
        G: '그룹',
        P: '프라이빗',
      };
      return class_type ? map[class_type] : map[lectureType];
    },
    formatStaffs: function({ staffs }) {
      if (staffs.length === 0) return '-';
      return staffs.join(', ');
    },

    getAmountText(row) {
      const { card_amount, cash_amount, wiretransfer_amount } = row.amount;
      const total = card_amount + cash_amount + wiretransfer_amount;
      if (row.type === 'refund') {
        return `<span class="red">(-${total.toLocaleString()}원)</span>`;
      } else {
        return total.toLocaleString() + '원';
      }
    },

    getPointText(row) {
      const { amount, card_amount, cash_amount, wiretransfer_amount } = row.amount;
      const isOnlyPointAmount = amount && !card_amount && !cash_amount && !wiretransfer_amount;
      const pointAmount = amount - card_amount - cash_amount - wiretransfer_amount;
      const result = isOnlyPointAmount ? amount : pointAmount;

      if (row.type === 'refund') {
        return `<span class="red">(${(result * -1).toLocaleString()}P)</span>`;
      } else {
        return Math.abs(pointAmount).toLocaleString() + 'P';
      }
    },

    getAtTimeAmountText(row) {
      if (row.amount) {
        if (row.amount.at_time_amount === '-') return '-';
        return `${row.amount.at_time_amount.toLocaleString()}원 <span class="gray small">(${row.count.max_coupon}회)</span>`;
      }
      if (row.oneTimeAmount === '-') return '-';
      return `${row.oneTimeAmount.toLocaleString()}원 <span class="gray small">(${row.maxCoupon}회)</span>`;
    },
    unPaymentText(row) {
      if (row.type === 'refund') {
        return `<span class="red">(${row.amount.unpaid_amount.toLocaleString()}원)</span>`;
      } else {
        return row.amount.unpaid_amount.toLocaleString() + '원';
      }
    },
    formatItemType({ type, ticket_type }) {
      const map = {
        S: '판매',
        R: '대여',
        refund: '환불',
        installment_payment: '미수금 결제',
        commission: '양도 / 환불',
        transfer: '양도',
      };
      return type === 'new' || type === 're-take' ? map[ticket_type[0]] : map[type];
    },
    formatMobile({ member_mobile }) {
      if (!member_mobile) return '-';
      return this.$filters.mobile(member_mobile);
    },
    formatUnPaymentType({ classType }) {
      const map = {
        G: '그룹',
        P: '프라이빗',
        S: '판매',
        R: '대여',
      };
      return map[classType];
    },
    // 미수금 > 미수금 결제
    formatUnPaymentAmount: function({ paymentAmount }) {
      return `${paymentAmount.toLocaleString()}원`;
    },
    // 미수금 > 남은 미수금
    formatMoreUnPaymentAmount({ remainingAmount }) {
      return `${remainingAmount.toLocaleString()}원`;
    },

    formatTicketType({ ticketType }) {
      const map = {
        T: '횟수제',
        P: '기간제',
        D: '차감제',
      };
      return map[ticketType];
    },
    formatLectureAttendance({ status }) {
      if (!status) return '차감';
      return BOOKING_STATUS_LABEL[status];
    },
    formatRecentDate({ recentDate }) {
      return moment(recentDate).format('YYYY. MM. DD(dd)');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  @include flex(row, center, space-between);
  max-width: 1440px;
  width: 100%;

  /deep/ .red {
    color: #f56c6c;
  }

  /deep/ .small {
    font-size: 12px;
  }

  /deep/ .background {
    background-color: #f2f6ff;
  }

  &.spending,
  &.ticket,
  &.item,
  &.un-payment {
    /deep/ .el-table__row {
      cursor: pointer;
    }
  }

  /deep/ thead {
    color: #a3a3a3;
  }

  /deep/ .el-table__row {
    height: 56px;
    color: #545454;

    .ellipsis > .cell {
      @include ellipsis;
    }
  }
}
</style>
