<template>
  <section id="settings-permissions">
    <roles-list />
    <permissions-list />
  </section>
</template>

<script>
import RolesList from './RolesList';
import PermissionsList from './PermissionsList';

export default {
  components: { RolesList, PermissionsList },

  watch: {
    'canUpdateFacilityRolesAndPermissions'(newVal) {
      if (!newVal) {
        this.$router.push({name: 'SettingsMain'})
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.canUpdateFacilityRolesAndPermissions) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/settings');
      }

      next();
    });
  },

  created() {
    this.$store.dispatch('studio/getPermissions');
  },
};
</script>

<style lang="scss" scoped>
#settings-permissions {
  @include flex(row, stretch, flex-start);
  flex-wrap: wrap;
  max-width: 1024px;
  margin: auto;
}
</style>
