<template>
  <div class="wrap-intro-main">
    <div class="wrap-intro-right" :style="iosStyle">
      <div><!--그리드 레이아웃 맞춤용--></div>
      <div class="text-input-underline" :style="isFullScreen">
        <div class="number-info-text" v-if="!memberNumber">휴대전화 마지막 4자리를 입력</div>
        <div class="wrap-input">
          <input
            type="text"
            v-for="(n, idx) in 4"
            :key="idx"
            v-model="memberNumber[idx]"
            :disabled="!memberNumber[idx]"
            readonly
          />
        </div>
      </div>

      <div class="keypad-number">
        <button v-for="n in 9" :key="n" @click="checkNumber(n)" onfocus="blur()">{{ n }}</button>
        <div><!--그리드 레이아웃 맞춤용--></div>
        <button class="btn-center" @click="checkNumber(0)">0</button>
        <button type="button" @click="deleteNumber" class="btn-clear">
          <img src="@/assets/images/02_icons/icon_back_arrow.svg" alt="back" />
        </button>
      </div>

      <div class="action-btn">
        <BaseButton
          v-loading="setLoading"
          class="block-btn"
          @click="checkProduct(memberNumber)"
          :disabled="memberNumber.length < 4"
        >
          체크인
        </BaseButton>
      </div>

      <div class="bottom-today-text">
        <i class="el-icon-time" />
        <div>{{ nowTime }}</div>
      </div>

      <WarningCheckin :show="showWarningModal" :title="modalData.title" :content="modalData.content" :onClose="onClose" />
    </div>
  </div>
</template>

<script>
import WarningCheckin from '@/components/Modals/WarningCheckin';

export default {
  components: {
    WarningCheckin,
  },

  props: {
    dataSet: Object,
    fullscreen: Boolean,
  },

  data() {
    return {
      setLoading: false,
      showWarningModal: false,
      memberNumber: '',
      setController: null,
      nowTime: '',

      modalData: {
        title: '',
        content: '',
      },

      selectListView: {
        showList: true,
        multiMember: null,
        screenStep: 0 /** 1: 중복 번호, 2: 수강권 리스트, 3: 수업 리스트, 3.1: 최종 확인, 4: 체크인 완료 */,
      },
    };
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },

    members() {
      return this.$store.getters['checkin/members'];
    },

    iosStyle() {
      const { platform, maxTouchPoints, userAgent } = navigator;

      const checkTouchPoints = maxTouchPoints > 1;
      const checkUserAgent = userAgent.includes('Safari');
      const checkSafariPlatform = platform === 'MacIntel';
      const checkChromePlatform = platform === 'iPad';

      if ((checkSafariPlatform || checkChromePlatform) && checkTouchPoints && checkUserAgent) return 'justify-content: normal;';
      else return '';
    },

    isFullScreen() {
      return this.fullScreen && '-webkit-margin-before: 20px;';
    },
  },

  watch: {
    showWarningModal: function(type) {
      if (type) {
        this.setController = setTimeout(() => this.onClose(), 5000);
      } else {
        clearTimeout(this.setController);
      }
    },
  },

  methods: {
    putNumber(n) {
      if (this.memberNumber.length > 3) return;
      this.memberNumber += n;
    },

    deleteNumber() {
      this.memberNumber = this.memberNumber.slice(0, -1);
    },

    async checkProduct(value) {
      await this.$store.dispatch('studio/getStudio');

      if (this.studio.grade !== 2 && !this.studio.usable_enter) {
        return this.$router.push(`/schedule`);
      }
      this.checkMemberNumber(value);
    },

    async checkMemberNumber() {
      const res = await this.$store.dispatch('checkin/getMembers', this.memberNumber);

      if (res.success) {
        this.selectListView.multiMember = this.members;
        const multiMemberLength = this.selectListView.multiMember.length;

        this.checkMultiMember(multiMemberLength);
      } else return this.notUserTciket();
    },

    notUserTciket() {
      this.modalData.title = '체크인을 사용할 수 있는<br>수강권이 없어요!';
      this.modalData.content = '시설에 수강권을 문의해 주세요!';
      this.showWarningModal = true;
    },

    checkMultiMember(memberLength) {
      if (!memberLength) {
        this.modalData.title = '등록되지 않은<br>회원 번호에요!';
        this.modalData.content = '회원 번호를 다시 한 번 확인해 주세요!';
        this.showWarningModal = true;
      } else if (memberLength > 1) {
        this.selectListView.screenStep = 1;
        this.moveshowList();
      } else if (memberLength === 1) this.checkTicketList();
    },

    moveshowList() {
      this.$emit('showList', this.selectListView);
    },

    checkNumber(n) {
      const length = this.memberNumber.length;
      if (length === 3) {
        this.putNumber(n);
        this.checkProduct(this.memberNumber);
      } else this.putNumber(n);
    },

    nowTimes() {
      this.nowTime = this.moment().format('MM월 DD일 dddd HH : mm : ss');
    },

    onClose() {
      this.showWarningModal = false;
    },

    checkTicketList() {
      if (!this.members[0]) {
        this.modalData.title = '체크인을 사용할 수 있는<br>수강권이 없어요!';
        this.modalData.content = '시설에 수강권을 문의해 주세요!';
        this.showWarningModal = true;
      } else {
        this.selectListView.screenStep = 2;
        this.moveshowList();
      }
    },
  },

  created() {
    this.nowTimes();
    setInterval(() => this.nowTimes(), 1000);
  },
};
</script>

<style lang="scss" scoped>
.wrap-intro-main {
  touch-action: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;

  @include flex(column);
  width: 420px;
  height: 100vh;

  @include mq-max(1200px) {
    width: 320px;
  }

  @include mq-height(960px) {
    width: 500px;
  }
}

.wrap-intro-right {
  @include flex(column, center, center);
  flex-grow: 1;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  .bottom-today-text {
    @include flex(row, stretch, center);
    margin: 24px 0 20px 0;
    font-size: 20px;
    color: #505050;

    div {
      margin: 0 0 4px 10px;
      line-height: 1;
    }

    @include mq-height(960px) {
      font-size: 24px;
      margin-top: 40px;
    }

    @include mq-max(1200px) {
      font-size: 16px;
    }
  }
}

.text-input-underline {
  @include flex(column, center, center);
  -webkit-margin-before: 6vh;

  .number-info-text {
    position: absolute;
    top: 18%;
    font-size: 22px;
    color: $gray-800;

    @include mq-max(1200px) {
      top: 17%;
      font-size: 16px;
    }

    @include mq-height(960px) {
      top: 16%;
      font-size: 26px;
    }
  }

  .wrap-input {
    @include flex(row);

    input {
      border-radius: 0;

      margin: 0 8px;
      padding: 0;
      width: 64px;
      border-bottom: 5px solid #4a8eff;
      text-align: center;
      font-size: 60px;
      font-weight: bold;
      background: transparent;

      &:disabled {
        border-bottom: 5px solid $gray-200;
      }

      @include mq-max(1200px) {
        width: 50px;
        font-size: 46px;
      }

      @include mq-height(960px) {
        width: 70px;
        font-size: 64px;
      }
    }
  }
}

.keypad-number {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  place-items: center;
  margin: 50px auto;

  @include mq-max(1200px) {
    margin: 30px auto;
  }

  button {
    padding: 0;
    border-style: none;
    border-radius: 0;

    padding-bottom: 4px;
    color: $gray-600;
    font-size: 50px;
    background-color: #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);

    &.btn-clear {
      @include flex(row, center, center);
      padding: 0;
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        filter: brightness(0.9);
      }
    }

    @include mq-max(1200px) {
      width: 60px;
      height: 60px;
      font-size: 34px;

      img {
        width: 28px;
      }
    }

    @include mq-height(960px) {
      width: 100px;
      height: 100px;
    }
  }
}

.action-btn {
  margin: 0 auto;
  width: 76%;

  .block-btn {
    height: 70px;
    border-style: none;
    border-radius: 20px;
    background-color: #4a8eff;
    font-weight: 600;
    z-index: 0;

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        filter: brightness(1.1);
      }
    }

    &:disabled {
      font-weight: 600;
      background-color: #ebebeb;
      color: #818181;
      opacity: 1;
      pointer-events: none;
    }

    @include mq-max(1200px) {
      height: 50px;
      font-size: 20px;
      border-radius: 14px;
    }
  }

  @include mq-max(1200px) {
    width: 72%;
  }
}
</style>
