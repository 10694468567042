import api from '@api';

export const state = {
  currentProduct: 0,
  paymentUpdatedAt: null,
  cardName: '',
  cardNumber: '',
  changeCount: 0,
  reservedPayDate: '',
  expireState: '',
};

export const getters = {
  currentProduct: state => state.currentProduct,
  paymentUpdatedAt: state => state.paymentUpdatedAt,
  cardName: state => state.cardName,
  cardNumber: state => state.cardNumber,
  changeCount: state => state.changeCount,
  reservedPayDate: state => state.reservedPayDate,
  expireState: state => state.expireState,
};

export const mutations = {
  SET_EXPIRE_STATUS(state, expireState) {
    state.expireState = expireState;
  },
  SET_LAST_PAYMENT(state, currentProduct) {
    state.currentProduct = currentProduct;
  },
  SET_LAST_PAYMENT_UPDATED(state, paymentUpdatedAt) {
    state.paymentUpdatedAt = paymentUpdatedAt;
  },
  UPDATE_CARD_INFO(state, cardInfo) {
    state.cardName = cardInfo.card_name;
    state.cardNumber = cardInfo.card_number;
  },
  SET_CHANGE_COUNT(state, changeCount) {
    state.changeCount = changeCount;
  },
  INIT_DATA_PURCHASE() {
    state.currentProduct = 0; //purchase
    state.SET_LAST_PAYMENT_UPDATED = null; //purchase
  },
  INIT_DATA_RESERVATION() {
    state.changeCount = 0; //reservation
  },
  SET_RESERVED_PAYDATE(state, dateValue) {
    state.reservedPayDate = dateValue;
  },
};

export const actions = {
  async getLastPayment({ commit }, param) {
    try {
      const res = await api.monthlyPayment.getLastServicePayment(param);
      if (res.data.id) {
        switch (param) {
          case 'purchase':
            commit('SET_LAST_PAYMENT', res.data.subscription.id); //마지막 결제한 상품 정보
            commit('SET_LAST_PAYMENT_UPDATED', res.data.updated_at);
            commit('SET_EXPIRE_STATUS', res.data.expire_status);
            break;
          case 'reservation':
            commit('SET_CHANGE_COUNT', res.data.change_count);
            commit('SET_RESERVED_PAYDATE', res.data.send_on);
            break;
          default:
            return;
        }
      } else if (!res.data.id && param === 'purchase') {
        commit('INIT_DATA_PURCHASE');
      } else if (!res.data.id && param === 'reservation') {
        commit('INIT_DATA_RESERVATION');
      }
    } catch (error) {
      return { success: false, error };
    }
  },
  async getCardDetail({ commit }) {
    try {
      const res = await api.monthlyPayment.checkRegistedCard();
      commit('UPDATE_CARD_INFO', res.data);
      return res.data;
    } catch (error) {
      return { success: false, error };
    }
  },
};
