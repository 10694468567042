<template>
  <div class="product-list-filter">
    <div class="product-list-filter__selects">
      <el-select
        v-for="(options, key) in filterOptions"
        :key="key"
        v-model="filterValues[key]"
        @change="$emit('change', filterValues)"
        :class="{ 'is-filtered': filterValues[key] !== null }"
        :disabled="key === 'courseType' && isProductType"
      >
        <el-option v-for="option in options" :key="option.value" :label="option.label" :value="option.value" />
      </el-select>
      <FilterResetButton @click="$emit('reset')" />
    </div>

    <div class="product-list-filter__buttons">
      <PlainButton type="info" size="large" round @click="$emit('selectAll')">
        {{ isSelectAllText ? '전체 선택 해제' : '전체 선택' }}
      </PlainButton>
      <PlainButton size="large" round :disabled="extendBulkDisabled" @click="$emit('extendBulk')">
        {{ isProductType ? '상품' : '수강권' }} 일괄 연장
      </PlainButton>
      <el-tooltip v-if="!!selectedTickets.length" effect="light" placement="right-start">
        <span class="product-list-filter__selected-tickets-count"> {{ selectedTickets.length }} 개 선택됨 </span>
        <ul slot="content" class="product-list-filter__selected-tickets">
          <li v-for="ticket in selectedTickets" :key="ticket.id">
            {{ ticket.title }}
          </li>
        </ul>
      </el-tooltip>
    </div>

    <div class="product-list-filter__text">
      <TextInput
        v-model="filterValues.keyword"
        :placeholder="placeholders.keyword"
        prefix-icon="el-icon-search"
        round
        @enter="$emit('change', filterValues)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterValues: Object,
    filterOptions: Object,
    placeholders: Object,
    isSelectedAll: { type: Boolean, default: false },
    extendBulkDisabled: { type: Boolean, default: false },
    selectedTickets: { type: Array, default: () => [] },
  },

  computed: {
    isProductType() {
      return this.filterValues.ticketType === 'S' || this.filterValues.ticketType === 'R';
    },

    isSelectAllText() {
      return this.isSelectedAll && this.filterValues.ticketType !== 'S' && this.selectedTickets.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-list-filter {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;
  margin-bottom: 24px;

  &__selects {
    @include flex(row, center, flex-start);
    /deep/ .el-select {
      width: 150px;
      margin: 0 8px 4px 0;
    }

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 40px;
      width: 150px;
      padding: 0 15px;
    }

    button {
      margin-bottom: 4px;
    }
  }

  &__buttons {
    margin: 0 24px;

    button {
      margin: 0 8px 4px 0;
    }
  }

  &__selected-tickets {
    &-count {
      color: $dodger-blue;
      cursor: default;
      font-size: 14px;
      font-weight: bold;
    }

    li + li {
      margin-top: 4px;
    }
  }

  &__text {
    margin-left: auto;

    /deep/ .el-input__inner {
      @extend %input-round;
    }
  }

  .is-filtered {
    color: $color-primary;

    /deep/ .el-input__inner {
      color: $color-primary;
    }
  }
}
</style>
