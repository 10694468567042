<template>
  <el-dialog
    :class="['member-select-dialog', isCounsel ? 'counsel-dialog' : null]"
    :visible="show"
    @close="$emit('close', false, null, filterValues)"
  >
    <h3 slot="title">{{ isCounsel ? '상담고객목록' : '회원목록' }}</h3>
    <p v-if="activeTab" class="member-select-dialog__description">
      앱계정이 연결된 회원만 조회됩니다.
    </p>

    <MembersListFilter
      :filterValues="filterValues"
      :filterOptions="isCounsel ? counselFilterOptions : isUesGrade ? filterOptions : nonGradeFilterOptions"
      :filteredTotal="members.length"
      :smsMemberTotal="smsMemberTotal"
      :selectedLength="selectArray.length"
      :checkSelectAll="checkSelectAll"
      @filter-change="values => handleFilterChange(values)"
      @reset-click="() => handleFilterChange()"
      @handleCheckSelect="handleCheckSelect"
    />

    <div class="header-checkbox">
      <Checkbox
        v-if="!checkSelectAll"
        type="multi"
        :checked="isSelectedAll"
        :indeterminate="isIndeterminate"
        @change="handleToggleSelectAll"
      ></Checkbox>
    </div>

    <el-table
      v-loading="membersLoading"
      :data="members"
      ref="members-list"
      :key="`${isSelectedAll}${isIndeterminate}`"
      @cell-click="row => handleToggleSelection(row)"
      @row-click="handleClickRow"
      :row-style="{ cursor: 'pointer' }"
      :height="membersListHeight"
      fit
      @sort-change="sortChange"
    >
      <el-table-column v-if="checkSelectAll" label="" width="50" align="center">
        <span></span>
      </el-table-column>
      <el-table-column v-if="!checkSelectAll" label=" " width="50" align="center">
        <template slot-scope="scope">
          <Checkbox :checked="memberIdsSelected.includes(scope.row.id)"></Checkbox>
        </template>
      </el-table-column>
      <el-table-column
        label="이름"
        prop="name"
        :width="isCounsel ? 170 : 140"
        align="center"
        :sortable="isCounsel ? false : 'custom'"
        :sort-orders="['ascending', 'descending']"
      >
        <div class="name-wrapper" slot-scope="scope">
          <span>{{ scope.row.name }}</span>
          <vaccine-image v-if="scope.row.vaccination_yn === 'Y'" />
        </div>
      </el-table-column>
      <el-table-column label="전화번호" :width="isCounsel ? 200 : 140" align="center">
        <span v-if="canViewMembersMobile" slot-scope="scope">
          {{ scope.row.mobile || scope.row.phone || '' | mobile }}
        </span>
        <span v-else>
          {{ memberMobile(scope.row.mobile) }}
        </span>
      </el-table-column>
      <el-table-column v-if="selectMemberType !== 'counsels'" label="보유수강권" header-align="center">
        <template slot-scope="scope">
          <ul class="member-select-dialog__ticket-list">
            <li v-for="userTicket in getUserTicketsArray(scope.row.userTickets)" :key="userTicket.id">
              <div class="member-select-dialog__ticket-list__title">
                {{ userTicket.ticket.title }}
              </div>
              <div class="member-select-dialog__ticket-list__tags">
                <div class="ticket-info-wrapper" v-if="!isRentalOnlyTime(userTicket.ticket)">
                  <el-tag size="medium" type="info">
                    {{ userTicket.availability_start_at | date }} ~
                    {{ userTicket.expire_at | date }}
                  </el-tag>
                  <el-tag v-if="!isExpired(userTicket)" size="medium" type="info">
                    {{ $utils.getRemainingDaysText(userTicket.expire_at, userTicket.availability_start_at) }}
                  </el-tag>
                </div>
                <el-tag v-if="isNotPeriodType(userTicket.ticket) && !isExpired(userTicket)" size="medium" type="info">
                  잔여 횟수 {{ userTicket.remaining_coupon }}/{{ userTicket.max_coupon }}
                </el-tag>
                <el-tag v-if="userTicket.is_holding" size="medium" type="danger">
                  {{ $utils.getHoldingPeriodText(userTicket.holdings) }}
                </el-tag>
                <el-tag v-if="isExpired(userTicket)" size="medium" type="danger">
                  {{ getExpiredStatus(userTicket) }}
                </el-tag>
              </div>
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column v-if="isCounsel" label="담당강사" width="190" align="center">
        <template slot-scope="scope">
          {{ scope.row.staff.name }}
        </template>
      </el-table-column>
      <el-table-column v-if="isCounsel" label="상담일자" width="190" align="center">
        <template slot-scope="scope">
          {{ scope.row.start_on | date }}
        </template>
      </el-table-column>
      <el-table-column v-if="isCounsel" label="인입경로" width="190" align="center">
        <template slot-scope="scope">
          {{ counselChannelTypes(scope.row.channel) }}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="pageParams.page"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>

    <div slot="footer">
      <el-button type="primary" @click="sendInfoToSMSForm">
        확인
      </el-button>

      <el-button @click="$emit('close', false, null, filterValues)">
        취소
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import MembersListFilter from '@/components/Members/ListFilter';
import { COUNSEL_CHANNEL_TYPES } from '@constants';
import VaccineImage from '@/components/shared/VaccineImage.vue';

const DEFAULT_FILTER_VALUES = {
  is_account: false,
  type: null,
  course_type: null,
  remaining_coupon: null,
  user_grade: null,
  remaining_day: null,
  searchFor: 'NAME|MOBILE',
  keyword: '',
  channel: null,
  staff_id: null,
  sort_name: 'asc',
};

export default {
  components: { MembersListFilter, VaccineImage },

  props: {
    show: Boolean,
    selectMemberType: String,
    membersSelected: { type: Array, default: () => [] },
  },

  data() {
    return {
      filterValues: { ...DEFAULT_FILTER_VALUES },
      members: [],
      membersLoading: false,
      membersListHeight: 'auto',
      pageParams: { page: 1, limit: 100 },
      total: 0,
      smsMemberTotal: 0,
      pushMemberList: [],
      showSelectAllModal: false,
      selectArray: [],
      checkSelectAll: false,
    };
  },

  computed: {
    isSelectedAll() {
      return !!this.members.length && this.memberIdsOnPage.every(id => this.memberIdsSelected.includes(id));
    },
    isIndeterminate() {
      return !this.isSelectedAll && this.memberIdsOnPage.some(id => this.memberIdsSelected.includes(id));
    },
    memberIdsOnPage() {
      return this.members.map(({ id }) => id);
    },

    activeTab() {
      return this.$route.name === 'message-form';
    },
    isUesGrade() {
      return this.$store.getters['studio/studio'].policy.is_use_user_grade;
    },
    isCounsel() {
      return this.selectMemberType === 'counsels';
    },
    filterOptions() {
      return {
        ...this.nonGradeFilterOptions,
        user_grade: { type: 'select', options: [{ value: null, label: '회원등급 전체' }] },
      };
    },
    nonGradeFilterOptions() {
      return {
        type: {
          type: 'select',
          options: [
            { value: null, label: '전체회원' },
            { value: 'active', label: '이용회원' },
            { value: 'inHolding', label: '정지회원' },
            { value: 'inactive', label: '만료회원' },
            { value: 'unpaid', label: '미결제회원' },
          ],
        },
        course_type: {
          type: 'select',
          options: [
            { value: null, label: '전체수강권' },
            { value: 'G', label: '그룹' },
            { value: 'P', label: '프라이빗' },
          ],
        },
        remaining_day: { type: 'popover' },
        remaining_coupon: { type: 'popover' },
      };
    },
    counselFilterOptions() {
      const options = _.uniqBy(
        this.members.map(({ staff }) => {
          return { value: staff.id, label: staff.name };
        }),
        'value',
      );

      options.unshift({ value: null, label: '전체 강사' });

      return {
        // is_account: {
        //   type: 'select',
        //   options: [
        //     { value: null, label: '전체 회원' },
        //     { value: false, label: '미등록 회원' },
        //     { value: true, label: '등록 회원' },
        //   ],
        // },
        channel: {
          type: 'select',
          options: [
            { value: null, label: '인입경로 전체' },
            { value: 'phone', label: '전화상담' },
            { value: 'visit', label: '방문상담' },
            { value: 'chat', label: '채팅상담' },
            { value: 'etc', label: '기타' },
          ],
        },
        staff_id: {
          type: 'select',
          options,
        },
      };
    },
    memberIdsSelected() {
      return this.selectArray.map(({ id, member_id }) => id || member_id);
    },
  },

  async created() {
    this.selectArray = this.membersSelected.slice();

    if (this.isUesGrade) {
      const getUserGrade = await this.$api.studio.userGrades.get();
      getUserGrade.data.map(el => this.filterOptions.user_grade.options.push({ value: el.id, label: el.name }));
    }

    this.getMembers(this.filterValues);
    this.$nextTick(() => {
      this.membersListHeight = window.innerHeight * 0.45;
    });
  },

  methods: {
    sortChange(e) {
      this.filterValues = { ...this.filterValues, sort_name: e.order === 'ascending' ? 'asc' : 'desc' };
      this.pageParams = { ...this.pageParams, page: 1 };
      this.getMembers(this.filterValues);
    },
    handleCheckSelect(result) {
      this.checkSelectAll = result;
      if (!result) {
        this.selectArray = [];
      }
    },

    handleToggleSelectAll() {
      if (!this.isSelectedAll) {
        this.selectArray = _.uniqBy([...this.selectArray, ...this.members], 'id');
      } else {
        this.memberIdsOnPage.forEach(memberId => {
          const index = this.selectArray.findIndex(el => el.id === memberId);
          if (index !== -1) {
            this.selectArray.splice(index, 1);
          }
        });
      }
    },

    handleToggleSelection(members) {
      if (!this.memberIdsSelected.includes(members.id)) {
        this.selectArray.push(members);
      } else {
        const index = this.selectArray.findIndex(el => el.id === members.id);
        this.selectArray.splice(index, 1);
      }
    },

    async getMembers(filter) {
      try {
        this.membersLoading = true;
        const params = this.$utils.mapParamsForMemberList(filter);
        let utilParams = {};

        if (this.activeTab) {
          utilParams = {
            name: 'memberGetAll',
            params: { ...params, with: 'tickets.ticket', ...this.pageParams },
            once: true,
          };
        } else {
          if (this.isCounsel) {
            utilParams = {
              name: 'counselsGetAll',
              params: {
                ...params,
                ...this.pageParams,
                keyword: params.search_name,
                has_mobile: 1,
                has_member: 1,
              },
              once: true,
            };
          } else {
            utilParams = {
              name: 'memberGetAll',
              params: {
                ..._.omit(params, 'is_account'),
                ...this.pageParams,
                is_mobile: true,
                with: 'tickets.ticket',
              },
              once: true,
            };
          }
        }

        const res = await this.$utils.getApis(utilParams);
        this.members = res.data;

        this.smsMemberTotal = res.meta.total;
        this.total = res.meta.last_page * 10;
      } catch (error) {
        this.members = [];
      } finally {
        this.membersLoading = false;
      }
    },

    handleCurrentChange(values) {
      this.pageParams.page = values;
      this.getMembers(this.filterValues);
    },

    handleFilterChange(values) {
      if (!values) values = { ...DEFAULT_FILTER_VALUES };
      this.filterValues = values;
      this.pageParams = { ...this.pageParams, page: 1 };
      this.getMembers(values);
    },

    handleClickRow(row) {
      this.$refs['members-list'].toggleRowSelection(row);
    },

    isRentalOnlyTime(ticket) {
      const ticketType = _.get(ticket, 'type');
      return ticketType === 'RT';
    },

    isNotPeriodType(ticket) {
      const ticketType = _.get(ticket, 'type');
      return ticketType === 'T' || ticketType === 'RT' || ticketType === 'RM';
    },

    isExpired(userTicket) {
      return this.$utils.getTicketStatus(userTicket) === '이용만료';
    },

    getExpiredStatus({ expire_at, remaining_coupon }) {
      if (remaining_coupon <= 0) return '횟수만료';

      const remainingDays = this.$utils.getDaysDiff(expire_at);
      if (remainingDays < 0) return '기간만료';

      return null;
    },

    memberMobile(value) {
      return this.$filters.mobileMask(value);
    },

    async sendInfoToSMSForm() {
      if (this.checkSelectAll) {
        try {
          this.membersLoading = true;
          const params = this.$utils.mapParamsForMemberList(this.filterValues);
          let utilParams = {
            name: 'memberGetAll',
            params: { ...params, ...this.pageParams, is_account: null, is_mobile: true, with: 'profile' },
            once: false,
          };

          if (this.activeTab) utilParams.params = { ...utilParams.params, is_account: true };
          if (this.isCounsel) {
            utilParams.name = 'counselsGetAll';
            utilParams.params = { ...params, ...this.pageParams, has_mobile: 1, keyword: params.search_name };
          }

          const res = await this.$utils.getApis(utilParams);
          this.selectArray = res.data
            .filter(({ mobile, phone }) => (mobile && mobile.length > 9) || (phone && phone.length > 9))
            .sort((a, b) => {
              if (a.name > b.name) return 1;
              if (a.name === b.name) return 0;
              if (a.name < b.name) return -1;
            });
        } catch (error) {
          this.selectArray = [];
        } finally {
          this.membersLoading = false;
        }
      }
      this.$emit('close', true, this.selectArray, this.filterValues);
    },

    counselChannelTypes(channel) {
      return COUNSEL_CHANNEL_TYPES[channel];
    },

    getUserTicketsArray(tickets) {
      const ticketArray = tickets.filter(({ ticket }) => ticket.available_class_type !== 'I');
      const rentalProductArray = tickets.filter(({ ticket }) => ticket.available_class_type === 'I' && ticket.type !== 'S');
      return [...ticketArray, ...rentalProductArray];
    },
  },
};
</script>

<style lang="scss" scoped>
.member-select-dialog {
  /deep/ .el-dialog {
    width: 100%;
    max-width: 1250px;
  }
  /deep/ .el-dialog__body {
    padding: 20px 20px;
  }
  /deep/ .el-table__row {
    height: 45px;
  }
  /deep/ .el-select {
    margin: 0 4px 0 0;
  }
  /deep/ .el-button {
    margin: 0 4px 0 0;
  }
  /deep/ .el-pagination {
    padding-top: 30px;
    text-align: center;
  }
  /deep/ .el-dialog__footer {
    padding-top: 0;
  }
  /deep/ .el-button--plain {
    margin-right: 10px;
  }

  &__description {
    color: $color-primary;
    font-size: 12px;
    margin-bottom: 12px;
  }

  &__ticket-list {
    li {
      display: flex;
      margin: 6px 6px 6px 0;
    }

    &__title {
      @include ellipsis;
      @include flex(row, center);
      width: 120px;
      text-align: left;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      padding-left: 10px;

      span {
        font-size: 14px;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }
      .ticket-info-wrapper {
        margin-right: 4px;
      }
    }
  }
  .header-checkbox {
    height: 0px;
    position: relative;
    top: 10px;
    left: 10px;
    z-index: 999;

    label {
      max-width: 35px;
    }
  }
}
.counsel-dialog {
  /deep/ .el-dialog {
    max-width: 1030px !important;
  }
}

.name-wrapper {
  span {
    text-align: left;
  }
}
</style>
