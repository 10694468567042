import api from '@api';
import moment from 'moment';

const DEFAULT_USER_TICKETS_PARAMS = {
  page: 1,
  limit: 15,
};

export const state = {
  ticket: null,
  loading: false,
  userTickets: [],
  userTicketsTotal: 0,
  userTicketsLoading: false,
  classesLoading: false,
  userTicketsParams: { ...DEFAULT_USER_TICKETS_PARAMS },
  totalColorSet: [],
};

export const getters = {
  ticket: state => state.ticket,
  loading: state => state.loading,
  userTickets: state => state.userTickets,
  userTicketsTotal: state => state.userTicketsTotal,
  userTicketsLoading: state => state.userTicketsLoading,
  classesLoading: state => state.classesLoading,
  userTicketsParams: state => state.userTicketsParams,
  totalColorSet: state => state.totalColorSet,
};

export const mutations = {
  SET_TICKET(state, { ticket, salesSummary, classes }) {
    if (ticket) {
      state.ticket = { ...state.ticket, ...ticket };
    }
    if (salesSummary) {
      state.ticket = { ...state.ticket, salesSummary };
    }
    if (classes) {
      state.ticket = {
        ...state.ticket,
        classes: classes.filter(({ end_date, lectures }) => {
          const isActive = moment(end_date).isAfter(moment());
          const isEmpty = lectures.length <= 0;
          return isActive && !isEmpty;
        }),
      };
    }
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_USER_TICKETS(state, { data, meta }) {
    state.userTickets = data;
    state.userTicketsTotal = meta.total;
  },

  SET_USER_TICKETS_LOADING(state, loading) {
    state.userTicketsLoading = loading;
  },

  SET_USER_TICKETS_PARAMS(state, values) {
    state.userTicketsParams = {
      ...state.userTicketsParams,
      ...values,
    };
  },

  SET_CLASSES_LOADING(state, loading) {
    state.classesLoading = loading;
  },

  SET_COLORS(state, values) {
    state.totalColorSet = values;
  },
};

export const actions = {
  async getTicket({ commit, dispatch }, ticketId) {
    try {
      commit('SET_LOADING', true);
      const res = await api.product.get(ticketId);
      const data = { ticket: res.data };
      commit('SET_TICKET', data);
      dispatch('getClasses', ticketId);
      dispatch('getUserTickets', { ticketId });
      return data.ticket.id;
    } catch (error) {
      commit('SET_TICKET', { ticket: null });
      return null;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getUserTickets({ state, commit }, { ticketId, params }) {
    try {
      commit('SET_USER_TICKETS_LOADING', true);
      params = params || state.userTicketsParams;
      const res = await api.product.getUserTickets(ticketId, params);
      return commit('SET_USER_TICKETS', res.data);
    } catch (error) {
      return commit('SET_USER_TICKETS', { data: [], total: 0 });
    } finally {
      commit('SET_USER_TICKETS_LOADING', false);
    }
  },

  async getClasses({ commit }, ticketId) {
    try {
      commit('SET_CLASSES_LOADING', true);
      const res = await api.product.getClasses(ticketId);
      commit('SET_TICKET', { classes: res.data.courses || [] });
    } catch (error) {
      commit('SET_TICKET', { classes: [] });
    } finally {
      commit('SET_CLASSES_LOADING', false);
    }
  },

  async getColorSets({ commit }) {
    try {
      commit('SET_LOADING', true);
      const res = await api.product.getColorSet();
      commit('SET_COLORS', res.data);
    } catch (error) {
      commit('SET_COLORS', []);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
