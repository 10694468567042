<template>
  <el-dialog class="point-storage-dialog" :visible="show" @close="$emit('close')">
    <h3 slot="title">미리보기</h3>
    <p class="description">휴대폰마다 다르게 보일 수 있습니다.</p>
    <div class="preview-message">
      <p>{{ message }}</p>
    </div>
    <el-table :data="messageData" :row-style="{ height: '40px' }" fit>
      <el-table-column label="유형" align="center" min-width="20">
        <template>
          {{this.byteCount > 90 ? 'LMS' : 'SMS',}}
        </template>
      </el-table-column>
      <el-table-column label="바이트수" align="center" min-width="30">
        <template>
          {{`${this.byteCount} / ${this.byteCount > 90 ? '2000 바이트' : '90 바이트'}`}}
        </template>
      </el-table-column>
      <el-table-column label="발송건" align="center" min-width="20">
        <template>
          {{`총 ${this.total} 건`}}
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button type="primary" @click="$emit('close')">
        닫기
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    message: String,
    byteCount: Number,
    total: Number
  },
  data(){
    return {
      //table 쓰기 위한 최소 세팅
      messageData : [{}]
    }
  }
};
</script>

<style lang="scss" scoped>
.point-storage-dialog {
  /deep/ .el-dialog {
    width: 100%;
    max-width: 460px;
    &__header,
    &__body {
      padding: 20px 30px;
    }
  }
  &__description {
    margin-bottom: 20px;
  }
  .description {
    text-align: center;
    position: relative;
    top: -20px;
  }
  .preview-message {
    white-space: pre-line;
    border: 1px solid $deep-sky-blue;
    border-radius: 4px;
    height: 500px;
    width: 370px;
    overflow-y: auto;
    margin: 0 auto;
    padding: 10px;
    p {
      word-break: break-word;
    }
  }
}
</style>
