<template>
  <div>
    <el-dialog class="template-edit-modal" :visible.sync="show" :before-close="handleClose" :close-on-click-modal="false">
      <div slot="title" class="template-edit-modal__header">
        <h4>메시지 수정하기</h4>
      </div>
      <div class="template-edit-modal__body">
        <div class="col4">
          <MessagePreview
            ref="templateEditInput"
            :isPreviewed="isPreviewed"
            :context="context"
            :handleInputContext="handleInputContext"
            :byteCount="byteCount"
            :isOver2000Byte="isOver2000Byte"
          />
        </div>
        <div class="col8">
          <label class="template-edit-modal__body__title">{{ inputData.unitTypeDescription }}</label>
          <div>
            <div class="option" v-for="(textOption, textOptionKey) in buttonTextOptionList" :key="textOptionKey">
              <div class="template-edit-modal__body__sub-title">{{ textOption.key }}</div>
              <div class="option-list-container">
                <button
                  :class="isDisabled(text) ? 'disabled' : ''"
                  v-for="(text, textKey) in textOption.values"
                  :key="textKey"
                  @click="handleClickInsertTextButton"
                  :value="text"
                >
                  {{ buttonTextList[text] }}
                </button>
              </div>
            </div>
          </div>
          <div class="template-edit-modal__footer">
            <div class="template-edit-modal__buttons">
              <el-button type="primary" @click="() => handleClickSaveButton()">저장하기</el-button>
              <el-button @click="handleClickPreviewButton">{{ !isPreviewed ? '미리보기' : '돌아가기' }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog class="over-point-dialog" :visible="showOverWriteModal" @close="overWriteClose">
      <h3 slot="title">2000바이트 초과</h3>
      2000바이트를 초과하였습니다. 2000바이트 초과시 문자가 발송 되지 않습니다.
      <div slot="footer">
        <el-button @click="overWriteClose"> 닫기 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MessagePreview from '@/components/shared/MessagePreview.vue';
import emojiCheck from '@/utils/emojiCheck';

export default {
  components: {
    MessagePreview,
  },
  props: {
    show: Boolean,
    handleClose: Function,
  },

  data() {
    return {
      isPreviewed: false,
      showOverWriteModal: false,
    };
  },

  computed: {
    templates() {
      return this.$store.getters['autoMessageNotification/templates'];
    },

    inputData() {
      return this.$store.getters['autoMessageNotification/templateEditInputData'];
    },

    buttonTextList() {
      return this.$store.getters['autoMessageNotification/buttonTextList'];
    },

    previewText() {
      return this.$store.getters['autoMessageNotification/previewText'];
    },

    context() {
      const context = this.inputData.context;
      if (!this.isPreviewed) {
        return this.replaceAllEngToKor(context);
      }

      return this.replaceAllForPreview(context);
    },

    buttonTextOptionList() {
      return this.$store.getters['autoMessageNotification/buttonTextOptionList'];
    },

    byteCount() {
      return this.$utils.smsByteCheckWithPreventWriting(this.context);
    },

    isOver2000Byte() {
      return this.byteCount > 2000;
    },
  },

  watch: {
    show() {
      if (!this.show) return;
      this.isPreviewed = false;
      /** 2000 byte 넘어 있을 때 수정 모달 창 보일 때의 최초 경고창 노출 */
      if (this.isOver2000Byte) {
        this.showOverWriteModal = true;
      }
    },

    isOver2000Byte() {
      /** 수정 모달에서 텍스트 수정 시, 2000 byte 넘을 때마다 경고창 노출 */
      if (this.show && this.isOver2000Byte) {
        this.showOverWriteModal = true;
      }
    },
  },

  methods: {
    async handleClickSaveButton() {
      if (this.isOver2000Byte) {
        this.showOverWriteModal = true;
        return;
      }
      if (this.context.trim().length === 0) {
        this.$utils.notify.error(this, '오류', '메시지를 입력해주세요.');
        return;
      }
      if (emojiCheck(this.context) && ['BOTH', 'MESSAGE'].includes(this.inputData.type)) {
        this.$utils.notify.error(this, '오류', '문자 메시지에는 이모지를 포함할 수 없습니다.');
        return;
      }
      try {
        await this.$store.dispatch('autoMessageNotification/putTemplate', { flag: 'context' });
        await this.$store.dispatch('autoMessageNotification/getAllTemplates');
        this.$utils.notify.success(this, '확인', '메시지를 저장하였습니다.');
      } catch (error) {
        this.$utils.notify.error(this, '오류', error);
      }
    },

    handleClickPreviewButton() {
      if (this.isOver2000Byte) {
        this.showOverWriteModal = true;
        return;
      }
      if (emojiCheck(this.context) && ['BOTH', 'MESSAGE'].includes(this.inputData.type)) {
        this.$utils.notify.error(this, '오류', '문자 메시지에는 이모지를 포함할 수 없습니다.');
        return;
      }
      this.isPreviewed = !this.isPreviewed;
    },

    handleClickInsertTextButton(ev) {
      const inputText = this.$refs.templateEditInput?.$refs['message-preview-textarea'];
      const currentCursorPosition = inputText.selectionStart;
      const beforeCursor = this.context.slice(0, currentCursorPosition);
      const afterCursor = this.context.slice(currentCursorPosition);
      const insertText = ev.target.value;

      const result = beforeCursor + `:${insertText}` + afterCursor;
      const replaced = this.replaceAllKorToEng(result);
      const newCursorPosition = this.replaceAllEngToKor(beforeCursor + `:${insertText}`).length;

      setTimeout(() => {
        this.$refs['templateEditInput'].$refs['message-preview-textarea'].setSelectionRange(newCursorPosition, newCursorPosition);
        this.$refs['templateEditInput'].$refs['message-preview-textarea'].focus();
      }, 0);
      this.$store.dispatch('autoMessageNotification/setTemplateInputData', { context: replaced });
    },

    replaceAllEngToKor(context) {
      const { buttonTextList } = this;
      if (!context) return '';
      return Object.keys(buttonTextList).reduce(
        (acc, currentText) => (acc = acc.replaceAll(`:${currentText}`, `[${buttonTextList[currentText]}]`)),
        context,
      );
    },

    replaceAllKorToEng(context) {
      const { buttonTextList } = this;
      if (!context) return '';
      return Object.keys(buttonTextList).reduce(
        (acc, currentText) => (acc = acc.replaceAll(`[${buttonTextList[currentText]}]`, `:${currentText}`)),
        context,
      );
    },

    replaceAllForPreview(text) {
      const { previewText } = this;
      return Object.keys(previewText).reduce(
        (acc, currentText) => (acc = acc.replaceAll(`:${currentText}`, previewText[currentText])),
        text,
      );
    },

    handleInputContext(ev) {
      const inputText = ev.target.value;
      const replaced = this.replaceAllKorToEng(inputText);
      this.$store.dispatch('autoMessageNotification/setTemplateInputData', { context: replaced });
    },

    isDisabled(text) {
      return !this.inputData?.items?.includes(text) || this.isPreviewed;
    },

    overWriteClose() {
      this.showOverWriteModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.template-edit-modal {
  /deep/ .el-dialog {
    @include flex(column);
    min-width: 858px;
    min-height: 705px;
    padding: 50px 40px;

    .el-dialog__headerbtn {
      top: 0px;
      right: 0px;
      i {
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          right: -10px;
          width: 36px;
          height: 36px;
          background: url('../../../assets/images/icons/x-close.svg') 0 0 no-repeat;
          background-size: cover;
        }
      }
    }

    &__header {
      position: relative;
      padding: 0px 0px 20px 0px !important;
    }

    &__body {
      padding: 0px !important;
    }
  }

  &__header {
    h4 {
      font-size: 28px;
      font-weight: bold;
      text-align: left;
      color: #343a40;
    }
  }

  &__tabs {
    border-bottom: 1px solid #eee;
    justify-content: flex-start;
  }

  &__content {
    @include flex(column);
    min-height: 370px;
  }

  &__body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    &__title {
      position: relative;
      font-size: 20px;
      font-weight: 500;
      color: #343a40;
      padding: 0px 0px 0px 29px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        top: calc(50% - 12px);
        left: -4px;
        background: url('../../../assets/images/icons/message_gray.svg') 0 0 no-repeat;
        background-size: cover;
      }
    }

    &__sub-title {
      font-size: 16px;
      color: #808080;
    }

    &__content_box {
      position: absolute;
      left: 8.5px;
      width: 244px;
      height: 413.5px;
    }

    textarea {
      resize: none;
    }
  }

  .col4 {
    width: 33.33%;
  }

  .col8 {
    position: relative;
    width: 66.66%;
    padding-left: 70px;
  }

  .option {
    padding: 30px 0px 0px;

    .option-list-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 15px;
      align-items: center;

      font-size: 20px;
      font-weight: 500;
      text-align: left;
      color: #343a40;
      padding: 15px 0px 0px;

      button {
        width: 100%;
        height: 42px;
        border-radius: 4px;
        border: solid 1px #eee;
        background-color: #f8f8f8;

        font-size: 15px;
        text-align: center;
        color: #8a8a8a;

        &.disabled {
          background-color: #e5e5e5;
          color: #ffffff;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
  }

  .el-button {
    width: 114px;
    height: 32px;
    border-radius: 2px;
    padding: 0px;

    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);

    &--default {
      font-size: 14px;
      text-align: center;
      color: rgba(138, 138, 138, 0.9);

      &:hover {
        background: none;
      }
    }
  }

  &__footer {
    display: flex;
    padding-top: 45px;
    flex-direction: row-reverse;
    right: 0px;
    bottom: 0px;
  }
}

.over-point-dialog {
  /deep/ .el-dialog {
    width: 700px;
    margin-top: 26vh !important;
  }
}
</style>
