<template>
  <div :class="className">
    <el-tooltip effect="light" :content="labelTooltip" placement="left">
      <label v-if="label" @click="$emit('click-label')">{{ label }}</label>
    </el-tooltip>
    <el-input
      v-bind="$attrs"
      type="text"
      :value="formattedValue"
      :size="size"
      :readonly="readonly"
      @input="
        value => {
          handleInput(value);
        }
      "
      @keypress.native="validateKey"
    >
      <span slot="suffix">원</span>
    </el-input>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: String,
    labelTooltip: String,
    labelAlign: {
      type: String,
      default: 'column',
    },
    value: Number,
    size: String,
    readonly: Boolean,
  },

  computed: {
    className() {
      let className = `price-input label-align-${this.labelAlign}`;
      if (this.readonly) className += ' readonly';
      return className;
    },

    formattedValue() {
      return this.$filters.comma(parseInt(this.value));
    },
  },

  methods: {
    handleInput(value) {
      const filtered = Number(value.replace(/\D/g, ''));
      this.$emit('input', filtered);
    },

    validateKey(e) {
      if (!e.key.replace(/\D/g, '')) e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.price-input {
  display: grid;

  &.label-align-column {
    grid-template-columns: 1fr;
    grid-gap: 4px;
  }

  &.label-align-row {
    align-items: center;
    grid-template-columns: minmax(44px, auto) 1fr;
    grid-gap: 16px;
  }

  &.readonly {
    /deep/ .el-input__inner {
      border: none;
      color: #c0c4cc;
    }
  }

  label {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 0;

    &:hover {
      color: $dodger-blue;
    }
  }

  /deep/ .el-input__inner {
    padding-right: 30px;
    text-align: right;
  }

  /deep/ .el-input__prefix,
  /deep/ .el-input__suffix {
    @include flex(row, center, center);
    height: 30px;
  }
}
</style>
