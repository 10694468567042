const LIMIT = 15000000;

export default (type, point, currentPoint, amount) => {
  const actualPoint = point || 0;
  const notTicket = type !== 'ticket';
  const currentAmount = notTicket ? actualPoint : amount;
  if (currentAmount > LIMIT) {
    const message = notTicket
      ? '포인트 입력값은 15,000,000을 초과할 수 없습니다.'
      : '모든 금액은 15,000,000원을 초과할 수 없습니다.';
    return {
      error: true,
      message,
    };
  }

  const noPoint = notTicket && !actualPoint;
  const isOverCurrentPoint = type === 'reward' ? null : actualPoint > currentPoint;
  const error = currentPoint > -1 && (noPoint || isOverCurrentPoint);
  const message = noPoint ? '포인트를 입력해주세요.' : isOverCurrentPoint && '잔여 포인트가 부족합니다.';
  return { error, message };
};
