import moment from 'moment';
import getDaysDiff from './getDaysDiff';
import getRemainingDaysText from './getRemainingDaysText';
import getHoldingPeriodText from './getHoldingPeriodText';

/**
 * 수강권 상태 문구 생성
 *
 * @param {Object} ticket (user ticket)
 */

export default ticket=> {
  if (!ticket) return null;

  const {
    availability_start_at,
    expire_at,
    active,
    is_holding,
    holdings,
    remaining_coupon,
    status,
    inactive_reason,
    ticket: originalTicket
  } = ticket;

  let ticketStatus = '';
  if (availability_start_at && expire_at) {
    const remaining_days = getDaysDiff(expire_at);
    const isRentalOnlyTime = originalTicket.type === 'RT' // 횟수만 설정한 상품
    const isRentalOnlyPeriod = originalTicket.type === 'RP' // 기간만 설정한 상품
    if (status === 'refund') {
      ticketStatus = '환불';
    } else if(inactive_reason === '양도 기능을 이용한 양도')  {
      ticketStatus = '양도'
    } else if (active === false) {
      ticketStatus = '사용불가';
    } else if (ticket.ticket.type === 'S') {
      ticketStatus = '판매';
    } else if (is_holding) {
      ticketStatus = `정지중 (${getHoldingPeriodText(holdings)})`;
    }
    else if (remaining_days < 0 || !isRentalOnlyPeriod && remaining_coupon <= 0) {
      ticketStatus = '이용만료';
    } else if (
      moment()
      .startOf('day')
      .isBefore(availability_start_at)
    ) {
      ticketStatus = '사용예정';
    } else {
      // 횟수만 설정한 대여상품의 경우 'n일 남음' 미표기
      ticketStatus = `사용중 ${isRentalOnlyTime ? "": `(${getRemainingDaysText(expire_at, availability_start_at)})`}`;
    }
  }
  
  return ticketStatus;
};