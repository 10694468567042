<template>
  <div class="container">
    <div :class="{ summary: true, daterange: date.rangeType === 'daterange' }">
      <PrevSummary
        :title="prevDataTitle"
        :summaryItem="prevData"
        v-if="date.rangeType !== 'daterange'"
        type="item"
        v-loading="prevLoading"
      />
      <SummaryNew
        :summaryItems="dashBoardData"
        type="item"
        @handleSelectChange="handleSelectChange"
        :name="filterOptions[0].name"
        :value="filterOptions[0].value"
        v-loading="dashBoardLoading"
      />
    </div>
    <FilterNew
      :filterOptions="filterOptions"
      :refreshClick="refresh"
      downloadText="엑셀 다운로드"
      :handleDownload="downloadExcel"
    />
    <SubHeaderNew
      :count="total"
      :amount="totalAmount"
      :search="search"
      :handleSearchChange="handleSearchChange"
      :searchResult="searchResult"
      placeholder="회원명 검색"
      :invisibleCount="filterOptions[0].value === 'commission'"
    />
    <ListTableNew
      :tableData="tableData"
      type="item"
      v-loading="tableLoading"
      :tableRowClick="tableRowClick"
      :hidePaymentMethod="hidePaymentMethod"
      :sort="sort"
      :sortChange="handleSortChange"
    />
    <el-pagination
      @current-change="fetchTableData"
      :current-page.sync="page"
      :page-size="limit"
      :pager-count="5"
      layout="prev, pager, next, sizes"
      :total="total"
      v-show="tableData.length > 0"
      :page-sizes="[10, 15, 20, 50]"
      @size-change="size => (limit = size)"
    />
  </div>
</template>

<script>
import SummaryNew from '@components/Sales/SummaryNew';
import PrevSummary from '@components/Sales/PrevSummary';
import FilterNew from '@components/Sales/FilterNew';
import SubHeaderNew from '@components/Sales/SubHeaderNew';
import ListTableNew from '@components/Sales/ListTableNew';
import moment from 'moment';

export default {
  components: {
    SummaryNew,
    PrevSummary,
    FilterNew,
    SubHeaderNew,
    ListTableNew,
  },
  data() {
    return {
      prevData: {
        totalCount: 0,
        totalAmount: 0,
        rental: 0,
        sale: 0,
        refund: 0,
      },

      dashBoardData: [
        { name: 'total', count: 0, amount: 0, label: '합계' },
        { name: 'rental', count: 0, amount: 0, label: '대여' },
        { name: 'sale', count: 0, amount: 0, label: '판매' },
        { name: 'commission', count: 0, amount: 0, label: '양도 / 환불 수수료' },
        { name: 'refund', count: 0, amount: 0, label: '환불' },
        { name: 'installment_payment', count: 0, amount: 0, label: '미수금 결제' },
      ],

      filterOptions: [
        {
          name: 'paymentType',
          onChange: this.handleSelectChange,
          options: [
            { value: 'total', label: '결제 구분 전체' },
            { value: 'rental', label: '대여' },
            { value: 'sale', label: '판매' },
            { value: 'commission', label: '양도 / 환불 수수료' },
            { value: 'refund', label: '환불' },
            { value: 'installment_payment', label: '미수금 결제' },
          ],
          value: 'total',
        },
        {
          name: 'product',
          onChange: this.handleSelectChange,
          options: [],
          value: [],
          placeholder: '상품 전체',
          multiple: true,
        },
        {
          name: 'paymentMethod',
          onChange: this.handleSelectChange,
          options: [
            { value: 'card', label: '카드' },
            { value: 'cash', label: '현금' },
            { value: 'wiretransfer', label: '계좌이체' },
            { value: 'point', label: '포인트' },
          ],
          value: [],
          placeholder: '결제 방법 전체',
          multiple: true,
          disabled: false,
        },
        {
          name: 'staff',
          onChange: this.handleSelectChange,
          options: [],
          value: [],
          multiple: true,
          placeholder: '강사 전체',
        },
      ],
      search: '',
      page: 1,
      limit: 10,
      total: 0,
      totalAmount: 0,
      tableData: [],
      prevLoading: false,
      dashBoardLoading: false,
      tableLoading: false,
      sort: 'ascending',
    };
  },

  mounted() {
    this.fetchDashBoard();
    this.fetchTableData();
    this.fetchAllProduct();
    this.fetchSalesStaff();
  },

  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },
    prevDataTitle() {
      this.fetchPrevData();
      if (this.date?.rangeType === 'day') return '어제';
      else if (this.date?.rangeType === 'week') return '지난 주';
      else if (this.date?.rangeType === 'month') return '지난 달';
      else return '';
    },
    refreshAll() {
      return this.$store.getters['salesNew/refreshAll'];
    },
    hidePaymentMethod() {
      return this.filterOptions.find(item => item.name === 'paymentType').value === 'commission';
    },
  },

  watch: {
    date: function() {
      this.page = 1;
      this.fetchDashBoard();
      this.fetchAllProduct();
      this.fetchTableData();
      this.fetchSalesStaff();
    },
    filterOptions: function() {
      this.fetchTableData();
      this.fetchSalesStaff();
    },
    refreshAll: function(value) {
      if (value) {
        this.fetchPrevData();
        this.fetchAllProduct();
        this.fetchTableData();
        this.fetchDashBoard();
        this.fetchSalesStaff();
      }
    },
    limit: function() {
      this.fetchTableData();
    },
    sort: function() {
      this.fetchTableData();
    },
  },

  methods: {
    async fetchDashBoard() {
      this.dashBoardLoading = true;
      try {
        const res = await this.$api.salesNew.getItemSalesDashboard({
          start_date: this.date.date,
          end_date: this.date.endDate,
        });
        const data = res?.data;
        const newDashBoardData = [...this.dashBoardData];

        Object.keys(data).forEach(type => {
          const dashBoardItem = newDashBoardData.find(data => data.name === type);
          dashBoardItem.count = data[type].count;
          dashBoardItem.amount = type === 'refund' && data[type].amount > 0 ? -data[type].amount : data[type].amount;
        });

        this.dashBoardData = newDashBoardData;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.dashBoardLoading = false;
        if (!this.prevLoading && !this.tableLoading) {
          this.$store.dispatch('salesNew/setRefreshAll', false);
        }
      }
    },
    async fetchTableData() {
      this.tableLoading = true;
      const paymentType = this.filterOptions.find(filter => filter.name === 'paymentType').value;
      const product = this.filterOptions.find(filter => filter.name === 'product').value;
      const paymentMethod = this.filterOptions.find(filter => filter.name === 'paymentMethod').value;
      const staff = this.filterOptions.find(filter => filter.name === 'staff').value;

      try {
        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
          limit: this.limit,
          page: this.page,
          search: this.search,
          payment_type: paymentType === 'total' ? null : paymentType,
          method_type: paymentMethod === 'total' ? null : paymentMethod,
          ticket_id: product,
          staff_id: staff,
          orderBy: this.sort === 'descending' ? 'desc' : 'asc',
        };

        const res = await this.$api.salesNew.getItemSalesData(params);

        this.tableData = this.updateAmount(res.data.data);
        this.total = res.data.meta.total;
        this.totalAmount = res.data.meta.amount;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.tableLoading = false;
        if (!this.dashBoardLoading && !this.prevLoading) {
          this.$store.dispatch('salesNew/setRefreshAll', false);
        }
      }
    },
    async fetchSalesStaff() {
      try {
        const res = await this.$api.salesNew.getStaffsEtcPeriod({
          start_date: this.date.date,
          end_date: this.date.endDate,
        });
        const staffs = res.data;
        const staffOptions = staffs.map(staff => ({
          value: staff.id,
          label: staff.name,
        }));
        this.filterOptions.find(option => option.name === 'staff').options = staffOptions;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      }
    },

    updateAmount(data) {
      const paymentMethod = this.filterOptions.find(filter => filter.name === 'paymentMethod').value;
      if (paymentMethod.length === 0) return data;
      return data.map(item => {
        let totalAmount = 0;
        if (paymentMethod.includes('card')) {
          totalAmount += item.amount.card_amount;
        }
        if (paymentMethod.includes('cash')) {
          totalAmount += item.amount.cash_amount;
        }
        if (paymentMethod.includes('wiretransfer')) {
          totalAmount += item.amount.wiretransfer_amount;
        }
        if (paymentMethod.includes('point')) {
          totalAmount += item.amount.point_amount;
        }
        return {
          ...item,
          amount: {
            ...item.amount,
            amount: totalAmount,
            unpaid_amount: 0,
          },
        };
      });
    },

    async fetchPrevData() {
      this.prevLoading = true;
      const startDate = moment(this.date.date)
        .subtract(1, this.date.rangeType)
        .format('YYYY-MM-DD');
      const endDate =
        this.date.rangeType === 'month'
          ? moment(this.date.date)
              .subtract(1, 'day')
              .format('YYYY-MM-DD')
          : moment(this.date.endDate)
              .subtract(1, this.date.rangeType)
              .format('YYYY-MM-DD');

      const params = {
        start_date: startDate,
        end_date: endDate,
      };

      try {
        const { data } = await this.$api.salesNew.getItemSalesDashboard(params);
        const newPrevData = {
          ...this.prevData,
        };

        Object.keys(data).forEach(type => {
          const typeData = data[type];
          if (type === 'total') {
            newPrevData.totalAmount = typeData.amount;
            newPrevData.totalCount = typeData.count;
          } else if (type === 'sale' || type === 'rental') {
            newPrevData[type] = typeData.amount;
          } else if (type === 'refund') {
            newPrevData[type] = typeData.amount > 0 ? -typeData.amount : typeData.amount;
          }
        });

        this.prevData = newPrevData;
      } catch (e) {
        this.prevData = {
          totalAmount: 0,
          totalCount: 0,
          sale: 0,
          rental: 0,
          refund: 0,
        };
      } finally {
        this.prevLoading = false;
        if (!this.dashBoardLoading && !this.tableLoading) {
          this.$store.dispatch('salesNew/setRefreshAll', false);
        }
      }
    },

    async fetchAllProduct() {
      try {
        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
        };
        const res = await this.$api.salesNew.getProductEtcPeriod(params);
        const products = res.data;
        const newFilterOptions = [...this.filterOptions];
        newFilterOptions.find(filter => filter.name === 'product').options = products.map(product => ({
          value: product.id,
          label: product.title,
        }));
        this.filterOptions = newFilterOptions;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      }
    },

    tableRowClick(item) {
      this.$router.push(`/users/detail?id=${item.member_id}&user_ticket_id=${item.user_ticket_id}`);
    },

    handleSelectChange(name, value) {
      if (name === 'paymentType' && value === 'commission') {
        this.handleSelectChange('paymentMethod', []);
      }
      const newFilterOptions = [...this.filterOptions];
      newFilterOptions.find(filter => filter.name === name).value = value;
      if (name === 'paymentType' && value === 'commission') {
        newFilterOptions.find(filter => filter.name === 'paymentMethod').disabled = true;
      }
      if (name === 'paymentType' && value !== 'commission') {
        newFilterOptions.find(filter => filter.name === 'paymentMethod').disabled = false;
      }
      this.filterOptions = newFilterOptions;
      this.page = 1;
    },
    refresh() {
      this.handleSelectChange('paymentType', 'total');
      this.handleSelectChange('paymentMethod', []);
      this.handleSelectChange('staff', []);
      this.handleSelectChange('product', []);
    },
    handleSearchChange(value) {
      this.search = value;
    },

    searchResult() {
      this.page = 1;
      this.fetchTableData();
    },

    formatJSON(data) {
      if (!data || !data.length) return [];

      return data.map(item => {
        const typeMap = {
          S: '판매',
          R: '대여',
          refund: '환불',
          installment_payment: '미수금 결제',
          commission: '양도 / 환불',
          transfer: '양도',
        };
        const type = item.type === 'new' || item.type === 're-take' ? typeMap[item.ticket_type[0]] : typeMap[item.type];
        const { amount, card_amount, cash_amount, wiretransfer_amount, point_amount, unpaid_amount } = item.amount;

        const date = moment(item.settlement_at).format('YYYY. MM. DD (dd)');
        const mobile = item.member_mobile ? item.member_mobile : '-';
        let method = '-';
        const arr = [];
        if (card_amount > 0) arr.push('카드');
        if (cash_amount > 0) arr.push('현금');
        if (wiretransfer_amount > 0) arr.push('계좌이체');
        if (point_amount > 0) arr.push('포인트');

        if (arr.length > 0) method = arr.join('+');

        const isRefund = item.type === 'refund';

        let cardAmount;
        if (isRefund) cardAmount = card_amount * -1;
        else cardAmount = card_amount;

        let cashAmount;
        if (isRefund) cashAmount = cash_amount * -1;
        else cashAmount = cash_amount;

        let wiretransferAmount;
        if (isRefund) wiretransferAmount = wiretransfer_amount * -1;
        else wiretransferAmount = wiretransfer_amount;

        let pointAmount = point_amount;
        if (isRefund) pointAmount = (amount && amount - card_amount - cash_amount - wiretransfer_amount) * -1;

        return {
          구분: type,
          결제일: date,
          회원명: item.member_name,
          '휴대폰 번호': mobile,
          상품명: item.item_name,
          카드결제금액: cardAmount,
          현금결제금액: cashAmount,
          계좌이체금액: wiretransferAmount,
          '포인트금액(환불 포인트)': pointAmount,
          '결제 금액(환불금액)': cardAmount + cashAmount + wiretransferAmount + pointAmount,
          '미수금(위약금)': unpaid_amount,
          '결제 방법': method,
          '담당 강사': item.staffs.join(','),
        };
      });
    },

    async downloadExcel() {
      // this.exceldownloading = true;
      /** 데이터 가공 */
      try {
        const paymentType = this.filterOptions.find(filter => filter.name === 'paymentType').value;
        const product = this.filterOptions.find(filter => filter.name === 'product').value;
        const paymentMethod = this.filterOptions.find(filter => filter.name === 'paymentMethod').value;
        const staff = this.filterOptions.find(filter => filter.name === 'staff').value;

        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
          limit: this.limit,
          page: this.page,
          search: this.search,
          payment_type: paymentType === 'total' ? null : paymentType,
          method_type: paymentMethod === 'total' ? null : paymentMethod,
          ticket_id: product,
          staff_id: staff,
        };

        const data = await this.$utils.promiseAllData(this.$api.salesNew.getItemSalesData, params);
        const json = this.formatJSON(data);
        this.$utils.downloadExcel(json, `기타매출_현황${params.start_date}~${params.end_date}.xlsx`);
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.loading = false;
      }
      // this.exceldownloading = false;
    },

    handleSortChange(e) {
      this.sort = e.order;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include flex(column);
  margin: auto;
  padding: 0 30px 30px 30px;
  max-width: 1440px;
  gap: 30px;

  /deep/ .el-pagination {
    @include flex(row, center, center);
  }

  .summary {
    display: grid;
    column-gap: 16px;
    grid-template-columns: auto 1fr;

    &.daterange {
      grid-template-columns: 1fr;
    }
  }
}
</style>
