<template>
  <section class="settings-payments">
    <CustomTabs class="settings-payments__tabs" :active_tab="activeTab" :tabs="tabs" :handleChangeTab="handleChangeTab" />
    <div class="settings-payments__content">
      <PaymentInfo v-if="activeTab === `SettingsPayments_paymentInfo`" :active_tab="activeTab" />
      <SMS v-if="activeTab === `SettingsPayments`" :active_tab="activeTab" />
    </div>
  </section>
</template>

<script>
import SMS from './SMS';
import PaymentInfo from './PaymentInfo';
export default {
  components: { SMS, PaymentInfo },
  data() {
    return {
      tabs: [
        { value: 'SettingsPayments_paymentInfo', label: '결제 정보' },
        { value: 'SettingsPayments', label: '요금제' },
      ],
    };
  },
  computed: {
    activeTab() {
      return this.$route.name;
    },
  },
  watch: {
    $route: {
      handler() {
        this.$store.dispatch('payment/updateType', 'point_card_id');
        this.$emit('multipleTitle', [
          { title: '서비스 결제', path: '/settings/service-payments' },
          { title: '포인트 충전', path: '/settings/payments' },
        ]);
      },
      immediate: true,
    },
  },

  methods: {
    handleChangeTab(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-payments {
  &__tabs {
    justify-content: flex-start;
    padding: 0px 68px 0px 68px;
  }
  &__content {
    padding: 16px 8px;
  }
}
</style>
