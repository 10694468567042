<template>
  <div class="product-classes" v-loading="loading">
    <ClassListItem v-if="canCreateGroupLecture()" addNew @click="handleClickAddNew" />
    <ClassListItem
      v-for="course in courses"
      :key="course.id"
      :course="course"
      :instructor="course.instructor"
      @click="handleClickClassListItem"
    />
  </div>
</template>

<script>
export default {
  computed: {
    ticket() {
      return this.$store.getters['ticket/ticket'];
    },
    loading() {
      return this.$store.getters['ticket/loading'];
    },
    courses() {
      return _.get(this.ticket, 'classes') || [];
    },
  },

  methods: {
    handleClickAddNew() {
      this.$router.push('/course/group/create');
    },

    handleClickClassListItem(courseId) {
      const course = this.courses.find(({ id }) => id === courseId);
      const courseType = this._.get(course, 'type');
      const instructorId = this._.get(course, 'instructor_id');

      if (!this.canViewLecture(courseType, instructorId)) {
        return this.$utils.notify.error(this, '오류', '권한이 없습니다.');
      } else {
        const lectureId = this.$utils.getNearestLectureIdOfCourse(course.lectures);

        if (!lectureId) {
          this.$utils.notify.error(this, '오류', '해당 클래스의 수업이 존재하지 않습니다.');
        } else {
          this.$router.push(`/lecture/detail?id=${lectureId}`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-classes {
  display: grid;
  grid-template-columns: repeat(auto-fill, 203px);
  justify-content: center;
  grid-gap: 24px 8px;
  padding: 30px 0;
  width: 100%;
}
</style>
