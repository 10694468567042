<template>
  <section class="schedule-header">
    <h3 v-html="headerTitle" />
    <button class="schedule-header__datepicker" @click="handleDatePickerButtonClick">
      <i class="el-icon-arrow-down"></i>
      <el-date-picker
        ref="datepicker"
        align="left"
        :picker-options="{ firstDayOfWeek: 1 }"
        :type="viewType"
        v-model="datepickerValue"
        :clearable="false"
        @change="datepickerHasFocus = false"
      />
    </button>
    <el-button v-if="canUpdateFacilitySettings" size="small" @click="showSetHoliday = true">휴일설정</el-button>
    <button class="schedule-header__settings" @click="showScheduleSettings = true">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill="#8A8A8A"
          fill-rule="evenodd"
          d="M16.5 3a.5.5 0 0 1 .5.5V5h2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2V3.5a.5.5 0 0 1 1 0V5h8V3.5a.5.5 0 0 1 .5-.5zm3.5 8H4v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-8zM8.5 17a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h2zm4.5 0a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h2zm4.5 0a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h2zm-9-4a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h2zm4.5 0a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h2zm4.5 0a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h2zM7 6H5a1 1 0 0 0-1 1v3h16V7a1 1 0 0 0-1-1h-2v1.5a.5.5 0 1 1-1 0V6H8v1.5a.5.5 0 0 1-1 0V6z"
        />
      </svg>
      달력 설정
    </button>

    <SetHoliday :show="showSetHoliday" @close="handleCloseSetHoliday" />

    <ScheduleSettings :show="showScheduleSettings" @close="handleCloseScheduleSettings" />
  </section>
</template>

<script>
import SetHoliday from '@/components/Modals/SetHoliday';
import ScheduleSettings from '@/components/Modals/ScheduleSettings';

export default {
  components: { SetHoliday, ScheduleSettings },

  data() {
    return {
      showSetHoliday: false,
      showScheduleSettings: false,
      datepickerHasFocus: false,
    };
  },

  computed: {
    calendarViewType() {
      return this.$store.getters['schedule/calendarViewType'];
    },
    datepickerValues() {
      return this.$store.getters['schedule/datepickerValues'];
    },

    viewType() {
      return this.calendarViewType.split('|')[0];
    },

    headerTitle() {
      const { moment, datepickerValues, viewType } = this;
      const selected = moment(datepickerValues[viewType]);
      const year = selected.year();
      const month = selected.month() + 1;
      const date = selected.date();

      let selected_date_string = `${year}년 ${month}월 <b>${date}일</b>`;

      if (viewType === 'week') {
        const end_of_week = selected.add(6, 'd');
        const year_end = end_of_week.year();
        const month_end = end_of_week.month() + 1;
        const date_end = end_of_week.date();

        selected_date_string += ' ~';

        if (year !== year_end) selected_date_string += ` ${year_end}년`;
        if (month !== month_end) selected_date_string += ` ${month_end}월`;
        if (date !== date_end) selected_date_string += ` <b>${date_end}일</b>`;
      } else if (viewType === 'date') {
        const weekday = selected.format('ddd');
        selected_date_string += ` (${weekday})`;
      } else if (viewType === 'month') {
        selected_date_string = `${year}년 <b>${month}월</b>`;
      }

      return selected_date_string;
    },

    datepickerValue: {
      get() {
        return this.datepickerValues[this.viewType];
      },
      set(value) {
        this.$store.commit('schedule/SET_DATEPICKER_VALUES', value);
      },
    },
  },

  methods: {
    handleDatePickerButtonClick() {
      this.datepickerHasFocus = !this.datepickerHasFocus;
      const element = this.$refs.datepicker;
      if (this.datepickerHasFocus) {
        element.focus();
      } else {
        element.blur();
      }
    },

    handleCloseSetHoliday(refresh) {
      this.showSetHoliday = false;
      if (refresh) this.$store.dispatch('schedule/getEvents');
    },

    handleCloseScheduleSettings() {
      this.showScheduleSettings = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-header {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;
  padding-bottom: 12px;

  h3 {
    font-size: 20px;
    font-weight: 400;

    @include mq(768px) {
      font-size: 24px;
    }

    b {
      font-weight: 500;
    }
  }

  &__settings {
    @include flex(row, center, center);
    margin-left: auto;
    height: 32px;
    border-radius: 16px;
    color: #8a8a8a;
    font-size: 14px;
    transition: background 0.15s;

    &:hover,
    &:focus {
      background: #e6e6e6;
    }

    &:active {
      background: rgba($deep-sky-blue, 0.2);
      color: $deep-sky-blue;
      svg > path {
        fill: $deep-sky-blue;
      }
    }

    svg {
      margin-right: 4px;
    }
  }

  &__datepicker {
    border-radius: 4px;
    background: $gray-100;
    margin: 0 12px;
    width: 32px;
    height: 32px;
    transition: 0.15s;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-weight: bold;
    }

    &:hover {
      background: $gray-200;
    }

    /deep/ .el-date-editor,
    /deep/ .el-input__inner {
      cursor: pointer;
      opacity: 0;
      width: 0;
      z-index: -100;
      transform: translateX(-18px);
    }
  }
}
</style>
