import { BOOKING_STATUS_LABEL, COURSE_TYPE, PAYMENT_METHOD, PAYMENT_STATUS, WEEKDAYS } from '@constants';

export default {
  // 수강권 형태
  ticketType: (ticket, userTicket, diviedType) => {
    if (!ticket) return null;

    const { use_weekly_auto_coupon_balance, available_class_type, type, max_trainee, booking_limit_per_week, booking_limit_per_month } = ticket;

    let weeklyLimitCount;
    let monthlyLimitCount;
    if (diviedType === 'userTicket') {
      weeklyLimitCount = userTicket.booking_limit_per_week;
      monthlyLimitCount = userTicket.booking_limit_per_month;
    } else {
      weeklyLimitCount = booking_limit_per_week;
      monthlyLimitCount = booking_limit_per_month;
    }
    
    let ticketType = '';
    if (use_weekly_auto_coupon_balance === 1) {
      ticketType = '차감제'
    } else if (type === 'S') {
      ticketType = '판매형'
    } else if (type === 'RM' || type === 'RP' || type === 'RT') {
      ticketType = '대여형'
    } else if (type === 'T') {
      ticketType = '횟수제'
    } else {
      ticketType = '기간제'
    }
    const courseType = `${COURSE_TYPE[available_class_type]}형`;
    const tranieeCount = `${max_trainee}:1`;
    const weeklyBookingLimit = `주${weeklyLimitCount}회`;
    const monthlyBookingLimit = `월${monthlyLimitCount}회`;
    let items = available_class_type === 'I' ? [ticketType] :[ticketType, courseType, tranieeCount];
    if (weeklyLimitCount) items.push(weeklyBookingLimit);
    if (monthlyLimitCount) items.push(monthlyBookingLimit);
    return items.join(' · ');
  },

  // 결제 방법
  paymentMethod: method => PAYMENT_METHOD[method] || null,

  // 결제 구분
  paymentStatus: status => PAYMENT_STATUS[status] || null,

  // 코스 형태
  courseType: type => COURSE_TYPE[type] || null,

  // 예약 상태
  bookingStatus: status => BOOKING_STATUS_LABEL[status] || null,

  // 요일 숫자 -> 요일 텍스트
  weekdayNumber: number => WEEKDAYS[number] || null,

  // 요일 텍스트 -> 요일 숫자
  weekdayText: text => {
    return Object.keys(WEEKDAYS).filter(key => WEEKDAYS[key] === text)[0] || null;
  },
};

