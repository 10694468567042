<template>
  <el-table
    class="booking-list"
    :data="bookings"
    :span-method="spanMethod"
    :default-sort="{ prop: 'start_on', order: this.checkInSortType }"
    @sort-change="handleClickHeader"
    fit
  >
    <el-table-column
      label="수업일시"
      prop="start_on"
      min-width="210"
      align="center"
      sortable="custom"
      :sort-orders="['ascending', 'descending']"
    >
      <template slot-scope="scope"
        >{{ scope.row.lecture.start_on | dateWithWeekday }} {{ getLectureTime(scope.row.lecture) }}
      </template>
    </el-table-column>

    <el-table-column label="강사" prop="lecture.staff.profile.name" min-width="100" align="center" />

    <el-table-column label="수업구분" min-width="70" align="center">
      <template slot-scope="scope">
        {{ $utils.translate.courseType(scope.row.lecture.course.type) }}
      </template>
    </el-table-column>

    <el-table-column label="수업" min-width="150" header-align="center">
      <template slot-scope="scope">
        {{ getLectureTitle(scope.row) }}
      </template>
    </el-table-column>

    <el-table-column label="룸" min-width="100" align="center">
      <template slot-scope="scope">
        {{ getLectureRoom(scope.row) }}
      </template>
    </el-table-column>

    <el-table-column label="예약 상태" min-width="100" align="center">
      <template slot-scope="scope">
        {{ $utils.translate.bookingStatus(scope.row.status) }}
      </template>
    </el-table-column>

    <el-table-column v-if="!checkIn" label="상태 변경 일시" min-width="150" align="center">
      <template slot-scope="scope">
        {{ scope.row.updated_at | datetime }}
      </template>
    </el-table-column>

    <el-table-column
      v-else
      label="체크인시간"
      prop="enter_at"
      min-width="170"
      align="center"
      sortable="custom"
      :sort-orders="['ascending', 'descending']"
    >
      <template slot-scope="scope">
        {{ scope.row.enter_at | dateWithWeekdayWithTime }}
      </template>
    </el-table-column>

    <el-table-column label="회원" min-width="110" align="center">
      <template slot-scope="scope">
        <div class="name-wrapper">
          <span v-if="!canViewMembers">
            {{ _.get(scope.row, 'member.profile.name') }}
          </span>
          <a v-else @click="handleClickMemberName(scope.row)"> {{ _.get(scope.row, 'member.profile.name') }}</a>
          <vaccine-image v-if="scope.row.member.vaccination_yn === 'Y'" />
        </div>
      </template>
    </el-table-column>

    <el-table-column label="휴대폰번호" min-width="120" align="center">
      <template slot-scope="scope">
        {{ getMemberMobile(scope.row) }}
      </template>
    </el-table-column>

    <el-table-column label="수강권" min-width="150" header-align="center">
      <template slot-scope="scope">
        <span class="booking-list__ticket-title">{{ scope.row.user_ticket.ticket.title }}</span>
      </template>
    </el-table-column>

    <el-table-column label="수강권정보" min-width="200" header-align="center">
      <template slot-scope="scope">
        <el-tag v-if="scope.row.user_ticket.inactive_reason === '사용불가 처리'" size="small" type="danger">
          사용불가 수강권
        </el-tag>
        <el-tag
          v-else-if="getRemainingDays(scope.row.user_ticket) === '만료된 수강권' || scope.row.user_ticket.remaining_coupon === 0"
          size="small"
          type="danger"
        >
          만료된 수강권
        </el-tag>
        <div v-else>
          <el-tag size="small" type="info">
            {{ getRemainingDays(scope.row.user_ticket) }}
          </el-tag>
          <el-tag v-if="scope.row.user_ticket.ticket.type === 'T'" size="small" type="info">
            {{ getRemainingCount(scope.row.user_ticket) }}
          </el-tag>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import VaccineImage from '../shared/VaccineImage.vue';
export default {
  components: { VaccineImage },
  props: {
    bookings: {
      type: Array,
      default: () => [],
    },
    checkIn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isCheckInSort: false,
    };
  },

  computed: {
    checkInSortType() {
      return this.$store.getters['lectures/checkInSortType'];
    },
    updateTimeSortType() {
      return this.$store.getters['lectures/updateTimeSortType'];
    },
  },

  methods: {
    spanMethod({ row, columnIndex }) {
      if (this.isCheckInSort) {
        return { rowspan: 1, colspan: 1 };
      }

      if (columnIndex <= 4) {
        return { rowspan: row.rowspan, colspan: 1 };
      }
      return { rowspan: 1, colspan: 1 };
    },

    getLectureTitle({ lecture }) {
      return lecture.title || lecture.course.title;
    },

    getLectureTime({ start_on, end_on }) {
      if (!start_on || !end_on) return null;
      return `${this.moment(start_on).format('HH:mm')}~${this.moment(end_on).format('HH:mm')}`;
    },

    getLectureRoom(row) {
      const roomName = _.get(row, 'lecture.room.name');
      return !roomName ? '-' : `${roomName}룸`;
    },

    getMemberMobile(row) {
      const mobile = _.get(row, 'member.mobile');
      if (!mobile) return '-';

      return this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);
    },

    getRemainingDays({ expire_at, availability_start_at }) {
      const text = this.$utils.getRemainingDaysText(expire_at, availability_start_at);
      const isExpired = text.split(' ')[1] === '지남';
      return isExpired ? '만료된 수강권' : text;
    },

    getRemainingCount({ remaining_coupon, max_coupon }) {
      return `잔여 횟수 ${remaining_coupon}/${max_coupon}`;
    },

    handleClickMemberName(row) {
      const id = _.get(row, 'member.id');

      if (this.canViewMembers) {
        this.$router.push(`/users/detail?id=${id}&tab=history`);
      }
    },

    handleClickHeader(e) {
      const typeCheck = e.column.property === 'start_on' ? 'lectureStartOn' : 'checkIn';
      const order = e.column.order === 'ascending' ? 'asc' : 'desc';

      if (typeCheck === 'checkIn') {
        this.isCheckInSort = true;
      } else {
        this.isCheckInSort = false;
      }

      this.$store.commit(`lectures/SET_LIST_SORT_TYPE`, { sortType: typeCheck, data: e.column.order });
      this.$emit('sort-change', 'sort', { header: e.column.property, type: 'sort', sortType: order });
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-list {
  span + span {
    margin-left: 4px;
  }

  .name-wrapper {
    a {
      text-align: left;
    }
  }
}

.booking-list__ticket-title {
  @include ellipsis;
}
</style>
