<template>
  <MainLayout>
    <HeaderTabs activeTab="/lectures/deleted" :exceldownloading="exceldownloading" @exceldown="downloadExcel" />

    <ListFilter
      v-loading="loading"
      :rangeFilter="rangeFilter"
      :localFilter="localFilter"
      :localFilterOptions="localFilterOptions"
      @change="handleFilterChange"
    />

    <List v-loading="loading" :data="flattenedLectures" />
  </MainLayout>
</template>

<script>
import HeaderTabs from '@components/Lectures/HeaderTabs';
import ListFilter from '@components/Lectures/ListFilter';
import List from '@components/Lectures/DeletedLectureList';

export default {
  components: { HeaderTabs, ListFilter, List },

  data() {
    const { moment } = this;

    return {
      loading: false,
      lectures: [],
      exceldownloading: false,

      rangeFilter: {
        type: 'date',
        dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },
      localFilter: {
        instructor: null,
        courseType: null,
        keyword: null,
      },
    };
  },

  computed: {
    localFilterOptions() {
      let filterOptions = {
        instructor: [{ value: null, label: '강사 전체' }],
        courseType: [{ value: null, label: '수업구분 전체' }],
      };

      if (!this.lectures.length) return filterOptions;

      this.lectures.forEach(({ staff, course }) => {
        const value = {
          instructor: _.get(staff, 'profile.name'),
          courseType: _.get(course, 'type'),
        };
        const label = {
          instructor: value.instructor,
          courseType: this.$utils.translate.courseType(value.courseType),
        };

        Object.keys(value).forEach(key => {
          if (!filterOptions[key].some(option => option.value === value[key])) {
            filterOptions[key].push({ value: value[key], label: label[key] });
          }
        });
      });

      return filterOptions;
    },

    flattenedLectures() {
      const { instructor, courseType, keyword } = this.localFilter;

      /** 필터 조건 적용 */
      let filteredLectures = this.lectures.filter(({ course, staff, bookings }) => {
        const isInstructorMatch = !instructor || staff.profile.name === instructor;
        const isCourseTypeMatch = !courseType || course.type === courseType;
        let isKeywordMatch = true;
        if (keyword) {
          isKeywordMatch = false;
          bookings.forEach(el => {
            if (
              (el.member && el.member.name && el.member.name.indexOf(keyword) !== -1) ||
              (el.member && el.member.mobile && el.member.mobile.indexOf(keyword) !== -1)
            ) {
              isKeywordMatch = true;
            }
          });
        }

        return isInstructorMatch && isCourseTypeMatch && isKeywordMatch;
      });

      /** 정렬 */
      filteredLectures = _.sortBy(filteredLectures, ['start_on', 'staff.profile.name', 'course.type', 'title', 'course.title']);

      /**
       * 수업 -> 예약 데이터로 변환
       * 첫번째 예약의 rowspan 값 = bookings.length 저장
       */
      let flattened = [];
      filteredLectures.forEach(lecture => {
        const except = [
          '당일예약 변경',
          '수강권 문제로 인한 취소',
          '수강권 삭제',
          '수강권 일정 변경으로 인한 자동취소',
          '수강권 환불',
          '수강권삭제',
          '수업에 결원이 발생하지 않아 취소',
          '예약 가능 수 부족',
          '예약 취소',
          '예약대기취소',
          '예약초과로 인한 취소',
          '예약취소',
          '오버 부킹 취소',
          '일일 예약제한으로 인한 취소',
          '주간 예약 제한',
          '주간 예약제한으로 인한 취소',
          '해당 시간대에 다른 예약으로 인한 취소',
          '수강권 사용불가 처리',
          '수강권 정지',
          '수강권 양도',
        ];

        lecture.bookings = lecture.bookings.filter(({ updated_for }) => except.indexOf(updated_for) === -1);

        if (!lecture.bookings.length) {
          flattened.push({
            ...lecture,
            booking: null,
            member: null,
            rowspan: 1,
          });
        } else {
          lecture.bookings.forEach((booking, index) => {
            flattened.push({
              ...lecture,
              booking: this.getLastBooking(booking),
              member: booking.member,
              rowspan: index === 0 ? lecture.bookings.length : 0,
            });
          });
        }
      });

      return flattened;
    },
  },

  watch: {
    rangeFilter: {
      handler: 'fetchLectures',
      immediate: true,
    },
  },

  methods: {
    getLastBooking(booking) {
      if (booking.updated_for && booking.status === 'attendance') return booking;
      if (booking.updated_for) return booking.histories[booking.histories.length - 1];
      return booking;
    },
    async fetchLectures() {
      try {
        this.loading = true;
        this.localFilter = { instructor: null, courseType: null };

        const res = await this.$api.lecture.getDeleted({
          start_date: this.rangeFilter.dates[0],
          end_date: this.rangeFilter.dates[1],
        });
        this.lectures = res.data.lectures;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    handleFilterChange(key, value) {
      this[key] = value;
    },

    async downloadExcel() {
      this.exceldownloading = true;
      /** 데이터 요청 및 가공 */
      const json = this.formatJSON(this.flattenedLectures);

      let range = this.rangeFilter.dates[0];
      if (this.rangeFilter.type === 'week') {
        range += `_${this.rangeFilter.dates[1]}`;
      }

      /** 다운로드 */
      this.$utils.downloadExcel(json, `삭제된수업_${range}.xlsx`);
      this.exceldownloading = false;
    },

    formatJSON(lectures) {
      return lectures.map(
        ({ start_on, end_on, staff, course, deleted_at, deleter, deleted_for, room, booking, member, title }) => {
          const lectureStartOn = this.moment(start_on);
          const lectureEndOn = this.moment(end_on);
          const courseType = this.$utils.translate.courseType(_.get(course, 'type'));
          const bookingStatus = _.get(booking, 'status') ? this.$utils.translate.bookingStatus(_.get(booking, 'status')) : null;
          const mobile = _.get(member, 'mobile');
          const mobileConverted = this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);

          return {
            수업일: lectureStartOn.format('YYYY-MM-DD'),
            수업시작: lectureStartOn.format('HH:mm'),
            수업종료: lectureEndOn.format('HH:mm'),
            강사: _.get(staff, 'profile.name'),
            수업구분: courseType,
            수업명: title || _.get(course, 'title'),
            룸: _.get(room, 'name'),
            예약상태: bookingStatus,
            회원명: _.get(member, 'profile.name'),
            휴대폰번호: mobile ? mobileConverted : null,
            삭제시간: this.moment(deleted_at).format('YYYY-MM-DD HH:mm:ss'),
            삭제한사람: _.get(deleter, 'profile.name') || '시스템',
            삭제이유: deleted_for,
          };
        },
      );
    },
  },
};
</script>
