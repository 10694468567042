<template>
  <form @submit.prevent="handleSubmit">
    <slot></slot>
    <p class="auth-form__error" v-if="error_message">
      <img src="@/assets/images/error.svg" />
      <span>{{ error_message }}</span>
    </p>

    <div class="auth-form__buttons">
      <auth-button
        v-if="cancel_button"
        type="button"
        variation="cancel"
        :label="cancel_button.label"
        :onClick="cancel_button.onClick"
      />
      <auth-button
        v-loading="submit_button.loading"
        v-if="submit_button"
        variation="success"
        :type="submit_button.type"
        :label="submit_button.label"
        :disabled="submit_button.disabled"
        :onClick="submit_button.onClick"
      />
    </div>
  </form>
</template>

<script>
import AuthButton from './Button';

export default {
  components: {
    AuthButton,
  },

  props: {
    submit: Function,
    error_message: String,
    cancel_button: Object,
    submit_button: Object,
  },

  methods: {
    handleSubmit() {
      if (this.submit_button.type === 'submit') {
        this.submit_button.onClick();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 36px 0;
}

.auth-form__error {
  color: $color-danger;
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
}

.auth-form__buttons {
  display: flex;
  margin-top: 18px;

  > *:nth-child(2) {
    margin-left: 8px;
  }
}
</style>
