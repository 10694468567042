<template>
  <section>
    <div class="member-basic__header">
      <div class="member-basic__header__title">
        <h4>사용중인 수강권</h4>
      </div>
      <div class="member-basic__header__buttons">
        <plain-button
          @click="
            () => {
              showExpiredTicketModal = true;
            }
          "
          round
          >이전 수강권 보기</plain-button
        >
      </div>
      <p>수강권을 클릭하시면 수강권별 이용내역을 확인하실 수 있습니다.</p>
    </div>
    <div v-loading="userTicketsLoading" class="member-basic__user-tickets">
      <UserTicketCard
        v-if="canCreateMembersTicket"
        @click="
          () => {
            isProduct = false;
            showTicketIssueModal = true;
          }
        "
        clickable
        addNew
      />
      <UserTicketCard
        v-for="userTicket in userTicketsActive"
        :key="userTicket.id"
        :userTicket="userTicket"
        @click="handleClickUserTicketCard"
        clickable
        showPrice
        amountTitleText="결제 금액"
      />
    </div>

    <div class="member-basic__header">
      <div class="member-basic__header__title">
        <h4>사용중인 상품</h4>
      </div>
      <div class="member-basic__header__buttons">
        <plain-button
          @click="
            () => {
              showExpiredTicketModal = true;
              isProduct = true;
            }
          "
          round
          >이전 상품 보기</plain-button
        >
      </div>
      <p>상품을 클릭하시면 상품별 이용내역을 확인하실 수 있습니다.</p>
    </div>
    <div v-loading="userTicketsLoading" class="member-basic__user-tickets">
      <UserTicketCard
        v-if="canCreateMembersTicket"
        @click="
          () => {
            isProduct = true;
            showTicketIssueModal = true;
          }
        "
        clickable
        addNew
        addNewLabel="새로운 상품 만들기"
      />
      <UserTicketCard
        v-for="userTicket in userProductsActive"
        :key="userTicket.id"
        :userTicket="userTicket"
        @click="handleClickUserProductCard"
        clickable
        showPrice
      />
    </div>

    <div class="member-basic__header">
      <div class="member-basic__header__title">
        <h4>메모</h4>
        <button class="member-basic__header__sort-button" @click="toggleMemoSortBy">
          <i class="el-icon-sort" />
          <span>{{ memoSortBy === 'desc' ? '최근순' : '과거순' }}</span>
        </button>
      </div>
      <div class="member-basic__header__buttons">
        <PlainButton
          v-if="canUpdateMembers"
          @click="handleClickAddMemo"
          :disabled="!!memoCreating"
          round
          style="width: 104px; margin-right: 16px;"
          >메모 추가</PlainButton
        >
      </div>
    </div>
    <div class="member-basic__memo" v-loading="memosLoading">
      <ul v-if="memoCreating || memos.length > 0">
        <MemoCreatingWithImage
          v-if="memoCreating"
          v-model="memoCreating"
          @cancel="memoCreating = null"
          @save="handleClickCreateMemo"
        />

        <MemoItemWithImage
          v-for="memo in memoSorted"
          :key="memo.id"
          v-model="memoEditing"
          :memo="memo"
          :isEditing="isEditing(memo.id)"
          @delete="handleClickDeleteMemo"
          @edit="handleClickEditMemo"
          @cancel-edit="handleClickCancelEditMemo"
          @save="handleClickSaveEditMemo"
        />
      </ul>

      <p v-else>메모가 없습니다.</p>
    </div>

    <TicketExpiredModal
      v-if="showExpiredTicketModal"
      :show="showExpiredTicketModal"
      :onClose="
        () => {
          isProduct = false;
          showExpiredTicketModal = false;
        }
      "
      :isProduct="isProduct"
      :handleClickCard="handleClickUserTicketCard"
    />

    <TicketIssueModal
      v-if="showTicketIssueModal"
      :isProduct="isProduct"
      :show="showTicketIssueModal"
      @close="handleCloseTicketIssue"
    />

    <TicketEditModal
      v-if="showTicketEditModal"
      :show="showTicketEditModal"
      :isProduct="isProduct"
      @close="handleCloseTicketEdit"
    />
  </section>
</template>

<script>
import TicketExpiredModal from '@/components/Modals/TicketExpired';
import TicketIssueModal from '@/components/Modals/TicketIssue';
import TicketEditModal from '@/components/Modals/TicketEdit';

export default {
  components: {
    TicketExpiredModal,
    TicketIssueModal,
    TicketEditModal,
  },

  data() {
    return {
      showExpiredTicketModal: false,
      showTicketIssueModal: false,
      showTicketEditModal: false,
      isProduct: false,

      memoSortBy: 'desc',
      memoCreating: null,
      memoEditing: null,
    };
  },

  computed: {
    member() {
      return this.$store.getters['member/member'];
    },
    userTicketsLoading() {
      return this.$store.getters['member/userTicketsLoading'];
    },
    userTicketsActive() {
      return this.$store.getters['member/userTicketsActive'];
    },
    userProductsActive() {
      return this.$store.getters['member/userProductsActive'];
    },
    bookedLectures() {
      return this.$store.getters['member/bookedLectures'];
    },
    bookedLecturesLoading() {
      return this.$store.getters['member/bookedLecturesLoading'];
    },
    memos() {
      return this.$store.getters['member/memos'];
    },
    memosLoading() {
      return this.$store.getters['member/memosLoading'];
    },

    memoSorted() {
      const memos = _.sortBy(this.memos, 'updated_at');
      if (this.memoSortBy === 'desc') memos.reverse();
      return memos;
    },
  },

  watch: {
    '$route.query': {
      handler: 'handleRouteQueryChange',
      immediate: true,
    },
  },

  methods: {
    dispatchGetUserTickets() {
      this.$store.dispatch('member/getUserTickets', this.member.id);
    },
    dispatchGetBookedLectures() {
      this.$store.dispatch('member/getBookedLectures', this.member.id);
    },

    /** 메모 정렬 */
    toggleMemoSortBy() {
      this.memoSortBy = this.memoSortBy === 'asc' ? 'desc' : 'asc';
    },

    /** 메모 추가 */
    handleClickAddMemo() {
      this.memoCreating = { memo: '', attachment: [], attachments: [] };
      this.memoEditing = null;
    },

    /** 메모 수정인지 체크 */
    isEditing(memoId) {
      return this.memoEditing && this.memoEditing.id === memoId;
    },

    /** 메모 삭제 */
    async handleClickDeleteMemo(memo) {
      const title = '메모 삭제';
      const message = '메모를 삭제하시겠습니까?';
      const proceed = await this.$confirm(message, title)
        .then(() => true)
        .catch(() => false);
      if (proceed) {
        try {
          await this.$api.memo.delete(memo.id);
          this.$store.dispatch('member/getMemos', this.member.id);
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    /** 메모 추가 */
    async handleClickCreateMemo() {
      try {
        const data = {
          ref_type: 'member',
          ref_id: this.member.id,
          ..._.omit(this.memoCreating, 'attachments'),
        };
        await this.$api.memo.create(data);
        this.$store.dispatch('member/getMemos', this.member.id);
        this.memoCreating = null;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    /** 메모 수정 모드 */
    handleClickEditMemo(memo) {
      this.memoEditing = {
        ...memo,
        attachment: memo.attachments.map(({ id }) => id),
      };
      this.memoCreating = null;
    },

    /** 메모 수정 취소 */
    handleClickCancelEditMemo() {
      this.memoEditing = null;
    },

    /** 메모 수정 내용 저장 */
    async handleClickSaveEditMemo() {
      try {
        const data = _.omit(this.memoEditing, 'attachments');
        await this.$api.memo.update(data);
        this.$store.dispatch('member/getMemos', this.member.id);
        this.memoEditing = null;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    async handleCloseTicketIssue(data) {
      this.showTicketIssueModal = false;

      if (data) {
        try {
          const product = this.$utils.getParamsForTicketIssue(data, this.member.id);
          await this.$api.userTicket.create({ products: [product] });
          this.$utils.notify.success(this, '성공', `${this.isProduct ? '상품' : '수강권'}이 발급되었습니다.`);
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }

        this.isProduct = false;
        this.dispatchGetUserTickets();
      }
    },

    async handleClickUserTicketCard(userTicketId) {
      this.showExpiredTicketModal = false;
      await this.$store.dispatch('ticketEdit/getUserTicket', userTicketId);
      this.showTicketEditModal = true;
    },

    async handleClickUserProductCard(userTicketId) {
      this.isProduct = true;
      this.showExpiredTicketModal = false;
      await this.$store.dispatch('ticketEdit/getUserTicket', userTicketId);
      this.showTicketEditModal = true;
    },

    handleCloseTicketEdit() {
      this.isProduct = false;
      this.showTicketEditModal = false;
      this.dispatchGetUserTickets();
      this.dispatchGetBookedLectures();
    },

    handleClickBookingListItem(lectureId) {
      const lecture = this.bookedLectures.find(({ id }) => id === lectureId);
      const courseType = _.get(lecture, 'course.type');
      const instructorId = _.get(lecture, 'instructor_id');
      if (this.canViewLecture(courseType, instructorId)) {
        this.$router.push(`/lecture/detail?id=${lectureId}`);
      }
    },

    handleRouteQueryChange() {
      const { user_ticket_id } = this.$route.query;
      if (user_ticket_id) {
        this.handleClickUserTicketCard(user_ticket_id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.member-basic__header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;

  &__title {
    @include flex(row, center);

    h4 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__sort-button {
    @include flex(row, center);
    border-radius: 16px;
    height: 32px;
    margin-left: 16px;
    transition: background 0.15s;

    &:hover {
      background: $gray-100;
    }

    i {
      border: 1px solid $gray-200;
      border-radius: 50%;
      padding: 4px;
    }

    span {
      margin-left: 4px;
    }
  }

  &__buttons {
    @include flex(row, center, center);
    button + button {
      margin-left: 8px;
    }
  }

  p {
    grid-column: 1 / 3;
    font-size: 12px;
    opacity: 0.5;
  }
}

.member-basic__user-tickets {
  display: grid;
  grid-template-columns: repeat(auto-fill, 256px);
  grid-gap: 9px;
  place-items: center;
  padding: 15px 0 64px;
}

.member-basic__bookings {
  padding: 15px 0 64px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 203px);
  grid-gap: 8px;
}

.member-basic__memo {
  padding: 15px 0 64px;

  p {
    font-size: 14px;
    opacity: 0.5;
  }
}
</style>
