<template>
  <div class="locker-type-count">
    <div class="wrap-type">
      <div class="total border-normal">
        <div class="box"></div>
        <span>전체 ({{ count.total }})</span>
      </div>

      <div class="available border-blue">
        <div class="box">
          <img src="@/assets/images/cross.svg" alt="cross" />
        </div>
        <span>사용가능 ({{ count.available }})</span>
      </div>

      <div class="toUse border-green">
        <div class="box"></div>
        <span>사용예정 ({{ count.toUse }})</span>
      </div>

      <div class="use border-normal">
        <div class="box"></div>
        <span>사용중 ({{ count.use }})</span>
      </div>

      <div class="impossible border-diagonal">
        <div class="box">
          <div class="diagonal"></div>
        </div>
        <span>사용불가 ({{ count.impossible }})</span>
      </div>

      <div class="toExpire border-yellow">
        <div class="box"></div>
        <span>만료예정 ({{ count.toExpire }})</span>
      </div>

      <div class="expire border-red">
        <div class="box"></div>
        <span>만료 ({{ count.expire }})</span>
      </div>
    </div>

    <div>
      <TextInput
        v-model="keyword"
        placeholder="회원 이름 입력"
        prefix-icon="el-icon-search"
        round
        @keyup.enter.native="handleChangeKeyword"
      />
    </div>
  </div>
</template>

<script>
import { LOCKER_STATE_TYPE } from '@constants';
export default {
  data() {
    return {
      count: {
        total: 0,
        available: 0,
        use: 0,
        impossible: 0,
        toExpire: 0,
        expire: 0,
        toUse: 0,
      },
      keyword: '',
    };
  },

  created() {
    this.countLockerType();
  },

  computed: {
    lockers() {
      return this.$store.getters['locker/locker'];
    },
  },

  watch: {
    lockers: function(value) {
      this.countLockerType(value);
    },
  },

  methods: {
    handleChangeKeyword() {
      this.$store.dispatch('locker/filterLocker', this.keyword);
    },

    async countLockerType(lockers) {
      this.count = { total: 0, available: 0, use: 0, impossible: 0, toExpire: 0, expire: 0, toUse: 0 };
      if (!lockers) {
        lockers = await this.$store.getters['locker/locker'];
      }

      this.count.total = lockers.length;

      lockers.forEach(locker => {
        if (locker.status === LOCKER_STATE_TYPE.IMPOSSIBLE) {
          this.count.impossible += 1;
        } else if (locker.status === LOCKER_STATE_TYPE.USE && !locker.start_on) {
          this.count.available += 1;
        } else if (this.moment(locker.start_on) < this.moment()) {
          const text = this.$utils.getRemainingDaysText(locker.end_on, locker.start_on);
          if (text.slice(-2) === '지남') {
            this.count.expire += 1;
          } else if (Number(text.slice(0, -4)) < 8 || text === '오늘 만료') {
            if (text === '오늘 만료' && this.moment() > this.moment(locker.end_on)) {
              this.count.expire += 1;
            } else {
              this.count.toExpire += 1;
            }
          }
        } else {
          this.count.toUse += 1;
        }
      });
      this.count.use = this.count.total - this.count.available - this.count.expire - this.count.impossible - this.count.toUse;
    },
  },
};
</script>

<style lang="scss" scoped>
.locker-type-count {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  .wrap-type {
    display: flex;
  }
  .total,
  .available,
  .use,
  .impossible,
  .toExpire,
  .expire,
  .toUse {
    display: flex;
    .box {
      width: 32px;
      height: 24px;
      border-radius: 4px;
    }
    span {
      margin: 0 24px 0 8px;
      font-size: 14px;
    }
  }
}
.border-green {
  .box {
    border: solid 2px green;
  }
}
.border-yellow {
  .box {
    border: solid 2px #ffcb1f;
  }
}
.border-red {
  .box {
    border: solid 2px $coral;
  }
}
.border-normal {
  .box {
    border: solid 1px #979797;
  }
}
.border-blue {
  .box {
    border: solid 1px #64aeff;
    img {
      position: relative;
      left: 9px;
      top: 2px;
    }
  }
}
.border-diagonal {
  .box {
    background-color: rgba(249, 84, 84, 0.4);
    border: solid 1px $coral;
    .diagonal {
      width: 35px;
      border-bottom: 1px solid red;
      position: relative;
      top: 28px;
      right: 8px;
      transform: translateY(-18px) translateX(5px) rotate(-35deg);
    }
  }
}
</style>
