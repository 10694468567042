<template>
  <section v-loading="loading" class="lecture-detail-header">
    <div class="lecture-detail-header__breadcrumb">
      <Breadcrumb :parent="{ path: '/schedule', label: '일정' }" current="일정 상세" background-color="transparent" />
      <span v-if="!!bookingClosed" class="booking-closed">
        예약마감
      </span>
    </div>

    <div class="lecture-detail-header__content">
      <el-tooltip effect="light" placement="bottom">
        <CircleButton
          :disabled="!prevLecture"
          class="lecture-detail-header__content__button left"
          @click="handleClickPrevLecture"
        >
          <img src="@/assets/images/icons/arrow-prev.svg" alt="이전수업" />
        </CircleButton>
        <div slot="content" class="lecture-detail-header__content__button__popover">
          <p>이전 수업</p>
          <p>{{ getLectureTime(prevLecture) }}</p>
        </div>
      </el-tooltip>

      <div class="lecture-detail-header__content__title">
        <h3>{{ lecture.title || courseTitle }}</h3>
        <p>{{ lectureMeta }}</p>
      </div>

      <div class="lecture-detail-header__content__tags">
        <span v-if="!!division">
          {{ division }}
        </span>
      </div>

      <el-tooltip effect="light" placement="bottom">
        <CircleButton
          :disabled="!nextLecture"
          class="lecture-detail-header__content__button right"
          @click="handleClickNextLecture"
        >
          <img src="@/assets/images/icons/arrow-next.svg" alt="다음수업" />
        </CircleButton>
        <div slot="content" class="lecture-detail-header__content__button__popover">
          <p>다음 수업</p>
          <p>{{ getLectureTime(nextLecture) }}</p>
        </div>
      </el-tooltip>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    loading() {
      return this.$store.getters['lecture/loading'];
    },
    lecture() {
      return this.$store.getters['lecture/lecture'];
    },

    courseTitle() {
      return _.get(this.lecture, 'course.title') || '';
    },

    division() {
      return _.get(this.lecture, 'division.title') || '';
    },

    bookingClosed() {
      return _.get(this.lecture, 'booking_closed_at');
    },

    lectureMeta() {
      const date = this.moment(this.lecture.start_on).format('ll dddd');
      const start_on = this.moment(this.lecture.start_on).format('HH:mm');
      const end_on = this.moment(this.lecture.end_on).format('HH:mm');
      let lectureMeta = `${date} · ${start_on} ~ ${end_on}`;

      const roomName = _.get(this.lecture, 'room.name');
      if (roomName) lectureMeta += ` · ${roomName}룸`;

      return lectureMeta;
    },

    sameTimeLectures() {
      const lectures = _.get(this.lecture, 'nav.today') || [];
      return _.sortBy(lectures, 'id');
    },

    prevLecture() {
      const sameTimePrev = this.sameTimeLectures.filter(({ id }) => id < this.lecture.id);
      if (sameTimePrev.length) {
        return sameTimePrev[sameTimePrev.length - 1];
      } else {
        return _.get(this.lecture, 'nav.prev');
      }
    },

    nextLecture() {
      const sameTimeNext = this.sameTimeLectures.filter(({ id }) => id > this.lecture.id);
      if (sameTimeNext.length) {
        return sameTimeNext[0];
      } else {
        return _.get(this.lecture, 'nav.next');
      }
    },
  },

  methods: {
    handleClickPrevLecture() {
      if (this.prevLecture) {
        this.$router.push(`/lecture/detail?id=${this.prevLecture.id}`);
      }
    },

    handleClickNextLecture() {
      if (this.nextLecture) {
        this.$router.push(`/lecture/detail?id=${this.nextLecture.id}`);
      }
    },

    getLectureTime(lecture) {
      if (!lecture) return null;

      const startOn = this.moment(lecture.start_on);
      const endOn = this.moment(lecture.end_on);
      const currentLectureStartOn = this.moment(this.lecture.start_on).format('YYYYMMDD');

      let text = `${startOn.format('HH:mm')}~${endOn.format('HH:mm')}`;

      if (startOn.format('YYYYMMDD') !== currentLectureStartOn) {
        text = `${startOn.format('M. D. (ddd)')} ${text}`;
      }

      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-detail-header {
  background: #fff;
  width: 95%;
  max-width: 720px;
  margin: auto;
  padding: 24px 0 8px;

  @include mq(1024px) {
    max-width: 1400px;
    padding: 24px 60px 8px;
  }

  &__breadcrumb {
    position: relative;

    .booking-closed {
      height: 18px;
      border-radius: 4px;
      background: $gray-500;
      color: #fff;
      font-size: 12px;
      padding: 0 8px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'button-left title button-right'
      '. tags .';
    grid-gap: 8px 0;
    place-items: center;

    @include mq(1024px) {
      grid-template-columns: 1fr 2fr 1fr;
      grid-template-rows: 64px 21px;
    }

    &__button {
      &.left {
        grid-area: button-left;
        justify-self: end;
      }
      &.right {
        grid-area: button-right;
        justify-self: start;
      }

      &__popover {
        @include flex(column, center, center);
        p + p {
          margin-top: 4px;
        }
      }
    }

    &__title {
      grid-area: title;
      display: grid;
      grid-gap: 8px;
      place-items: center;
      padding: 0 14px;

      h3 {
        font-size: 23px;
        text-align: center;

        @include mq(1024px) {
          @include ellipsis;
          width: 100%;
        }
      }

      p {
        color: rgba($charcoal-grey, 0.9);
        font-size: 15px;
      }
    }

    &__tags {
      grid-area: tags;

      span {
        height: 18px;
        border-radius: 4px;
        background: $deep-sky-blue;
        color: #fff;
        font-size: 12px;
        padding: 0 8px;
      }

      span + span {
        margin-left: 8px;
      }
    }
  }
}
</style>
