import { render, staticRenderFns } from "./CustomTag.vue?vue&type=template&id=73211168&scoped=true&"
import script from "./CustomTag.vue?vue&type=script&lang=js&"
export * from "./CustomTag.vue?vue&type=script&lang=js&"
import style0 from "./CustomTag.vue?vue&type=style&index=0&id=73211168&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73211168",
  null
  
)

export default component.exports