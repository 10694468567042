import moment from 'moment';

/**
 * 날짜 차이 계산
 *
 * @param {Date} to
 * @param {Date} from
 */
export default (to, from = moment(), type = 'day') => {
  from = moment(from).startOf('day');
  return moment(to)
    .startOf('day')
    .diff(from, type === 'day' ? 'days' : 'months');
};
