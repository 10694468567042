<template>
  <div class="container">
    <div class="no-member" v-if="members.length === 0">
      <img src="/img/icons/icon_nomember.png" alt="no member" />
      <h3>"{{ keyword }}"</h3>
      <p>에 대한 검색 결과가 없습니다.</p>
      <span>회원명, 전화번호를 확인해주세요!</span>
    </div>
    <div class="members" v-else>
      <div
        class="member"
        :class="{ underline: index !== members.length - 1 }"
        v-for="(member, index) in members"
        :key="member.id"
        @click="handleMemberClick(member.id)"
      >
        <img :src="getImageUrl(member)" />
        <span class="name">{{ member.name }}</span>
        <span class="mobile">{{ $filters.mobile(member.mobile) }}</span>
      </div>
      <el-button type="primary" v-if="members.length === 11" @click="getMoreResult">검색 결과 더 보기</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    keyword: String,
    members: Array,
  },
  methods: {
    getImageUrl(member) {
      if (member.avatar) return this.$utils.getImageUrl(member.avatar, '36x36');
      return this.$utils.getImageUrl(member.account_avatar, '36x36');
    },
    handleMemberClick(id) {
      this.$router.push(`/users/detail?id=${id}`);
    },
    getMoreResult() {
      this.$router.push({ name: 'users', query: { keyword: this.keyword } });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
  width: 280px;
  top: calc(100% + 25px);
  background-color: white;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  &.hide {
    display: none;
  }
}
.no-member {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  img {
    margin-bottom: 3px;
  }
  h3 {
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }
  p {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
  }
  span {
    font-size: 12px;
    color: #a3a3a3;
  }
}
.members {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .underline:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;

    width: 250px;
    border-bottom: 1px solid #eee;
  }

  button {
    font-size: 14px;
    width: 198px;
    height: 40px;
    margin: 15px 0;
  }
}
.member {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #eee;
  }
  img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    margin-right: 15px;
  }
  .name {
    font-size: 14px;
    color: #808080;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
  }
  .mobile {
    font-size: 14px;
    flex: 1;
    text-align: end;
    white-space: nowrap;
  }
}
</style>
