<template>
  <div>
    <div class="serach-bar">
      <TextInput
        v-model="keyword"
        placeholder="회원 이름 또는 전화번호 검색"
        prefix-icon="el-icon-search"
        round
        @keyup.enter.native="handleChangeKeyword"
      />
    </div>
    <el-table
      :data="members"
      @cell-click="handleCellClick"
      empty-text="회원이 없습니다"
      row-class-name="staff-members-list__row"
      fit
    >
      <el-table-column label="이름" min-width="145" header-align="center">
        <template slot-scope="scope">
          <div class="staff-members-list__name">
            <img :src="getShowingImageSrc(scope.row)" :alt="scope.row.name" />
            <div class="name-wrapper">
              <h5>{{ scope.row.name }}</h5>
              <vaccine-image v-if="scope.row.vaccination_yn === 'Y'" />
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column v-if="useUserGrades" label="회원등급" min-width="80" header-align="center" align="center">
        <template slot-scope="scope">
          <span
            v-if="!!getUserGrade(scope.row)"
            class="staff-members-list__user-grade"
            :style="{ 'background-color': `#${scope.row.user_grade.representative_color}` }"
            >{{ getUserGrade(scope.row) }}</span
          >
        </template>
      </el-table-column>

      <el-table-column label="전화번호" min-width="125" align="center">
        <template slot-scope="scope">{{ getMobileNumber(scope.row) }}</template>
      </el-table-column>

      <el-table-column label="등록일" min-width="120" align="center">
        <template slot-scope="scope">{{ scope.row.registered_at | date }}</template>
      </el-table-column>

      <el-table-column label="보유수강권" min-width="600" header-align="center">
        <template slot-scope="scope">
          <ul class="staff-members-list__ticket-list">
            <li v-for="ticket in removeInactiveTickets(scope.row.userTickets)" :key="ticket.id">
              <div class="staff-members-list__ticket-list__title">
                {{ ticket.ticket.title }}
              </div>
              <div class="staff-members-list__ticket-list__tags">
                <div class="ticket-info-wrapper" v-if="!isRentalOnlyTime(ticket.ticket)">
                  <el-tag size="medium" type="info">
                    {{ ticket.availability_start_at | date }} ~
                    {{ ticket.expire_at | date }}
                  </el-tag>
                  <el-tag size="medium" type="info">{{ getRemainingDays(ticket) }}</el-tag>
                </div>
                <el-tag size="medium" type="info" v-if="isNotPeriodType(ticket.ticket)"
                  >잔여 횟수 {{ ticket.remaining_coupon }}/{{ ticket.max_coupon }}</el-tag
                >
                <el-tag v-if="ticket.is_holding" size="medium" type="danger">{{
                  $utils.getHoldingPeriodText(ticket.holdings)
                }}</el-tag>
              </div>
            </li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="staff-members__pagination"
      @current-change="dispatchGetStaffMembersList"
      :current-page.sync="page"
      :page-size="limit"
      :page-sizes="[10, 15, 20, 50]"
      @size-change="limit => handleFilterChange({ limit })"
      layout="prev, pager, next, sizes"
      :total="total"
    />
  </div>
</template>

<script>
import VaccineImage from '../shared/VaccineImage.vue';
export default {
  components: { VaccineImage },
  data() {
    return {
      keyword: '',
    };
  },
  computed: {
    members() {
      return this.$store.getters['staff/members'];
    },

    useUserGrades() {
      return !!_.get(this.studioPolicies, 'is_use_user_grade');
    },

    total() {
      return this.$store.getters['staff/total'];
    },

    pagination() {
      return this.$store.getters['staff/pagination'];
    },

    page: {
      get() {
        return this.pagination.page;
      },
      set(page) {
        this.setPagination({ page });
      },
    },

    limit: {
      get() {
        return this.pagination.limit;
      },
      set(limit) {
        this.setPagination({ limit });
      },
    },
  },

  created() {
    this.dispatchGetStaffMembersList();
  },

  methods: {
    handleCellClick(row) {
      this.$router.push('/users/detail?id=' + row.id);
    },

    getUserGrade(row) {
      const grade = _.get(row, 'user_grade.name');
      const { exceeded, exceededAt } = this.$utils.checkBytesOfString(grade, 5);
      return !exceeded ? grade : `${grade.slice(0, exceededAt)}...`;
    },

    getMobileNumber(row) {
      const mobile = _.get(row, 'mobile');
      if (this.canViewMembersMobile) {
        return this.$filters.mobile(mobile);
      } else {
        return this.$filters.mobileMask(mobile);
      }
    },

    removeInactiveTickets(tickets) {
      if (!tickets) return [];
      return tickets.filter(({ active }) => active);
    },

    getRemainingDays(ticket) {
      const startOn = this.moment(ticket.availability_start_at).format('YYYYMMDD');
      const today = this.moment().format('YYYYMMDD');

      return today < startOn ? '사용예정' : this.$utils.getRemainingDaysText(ticket.expire_at, ticket.availability_start_at);
    },

    setPagination(values) {
      this.$store.commit('staff/SET_PAGINATION', values);
    },

    dispatchGetStaffMembersList(page) {
      let staffId = this.$route.query.id;
      if (this.$route.path === '/staffs/me') staffId = this.currentUser.id;
      if (page) this.pagination.page = page;

      this.$store.dispatch('staff/getMembers', {
        staff_id: staffId,
        search_name: this.keyword,
        ...this.pagination,
      });
    },

    handleFilterChange({ limit }) {
      this.setPagination({ limit });
      this.dispatchGetStaffMembersList();
    },

    handleChangeKeyword() {
      this.setPagination({ page: 1 });
      this.dispatchGetStaffMembersList();
    },

    getShowingImageSrc(row) {
      if (row.avatar) {
        return this.$utils.getImagePath(_.get(row, 'avatar'), 36);
      } else if (row.accountAvatar) {
        return this.$utils.getImagePath(_.get(row, 'accountAvatar'), 36);
      } else {
        return this.$utils.getImagePath(_.get(row, 'accountAvatarImage'), 36);
      }
    },

    isRentalOnlyTime(ticket) {
      const ticketType = _.get(ticket, 'type');
      return ticketType === 'RT';
    },

    isNotPeriodType(ticket) {
      const ticketType = _.get(ticket, 'type');
      return ticketType === 'T' || ticketType === 'RT' || ticketType === 'RM';
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-members-list {
  &__name {
    display: grid;
    grid-template-columns: 36px 1fr;
    grid-gap: 5px;
    align-items: center;

    img {
      border-radius: 50%;
      width: 36px;
      height: 36px;

      &.vaccine {
        width: 18px;
        height: 18px;
      }
    }

    .name-wrapper {
      justify-content: flex-start;
    }
  }

  &__user-grade {
    background: #757575;
    color: #fff;
    font-size: 11px;
    padding: 0 8px;
    border-radius: 4px;
  }

  &__ticket-list {
    li {
      display: flex;
      margin: 6px 6px 6px 0;
    }

    &__title {
      @include ellipsis;
      @include flex(row, center);
      width: 120px;
      text-align: left;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      padding-left: 10px;

      span {
        font-size: 14px;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }

      .ticket-info-wrapper {
        margin-right: 4px;
      }
    }
  }
}

.el-table {
  /deep/ .staff-members-list__row {
    cursor: pointer;
  }
}

.serach-bar {
  margin-left: auto;
  /deep/ .custom-text-input__inner {
    width: 220px;
  }
}
.staff-members__pagination {
  @include flex(row, center, center);
  margin-top: 30px;

  /deep/ .el-pagination__sizes {
    margin: 0;

    /deep/ .el-input__inner {
      @extend %input-default;
      border-color: #dcdfe6;
      height: 36px;
    }

    /deep/ .el-input__suffix {
      transform: scale(1);
      padding-right: 8px;
    }
  }
}
</style>
