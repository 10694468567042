<template>
  <div class="sales-summary">
    <div class="spending-container" v-if="type === 'spending'">
      <el-card
        shadow="never"
        :class="{
          card: true,
          active: value === summary.name,
        }"
        v-for="summary in summaryItems"
        :key="summary.name"
      >
        <div class="card-body" @click="() => cardClick(summary.name)">
          <div class="left">
            <img :src="require(`@assets/images/icons/sales_${summary.name}.svg`)" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ summary.label }}</span>
              <el-tooltip effect="light" placement="bottom" v-if="summary.name === 'total'" content="카드 + 현금 + 계좌이체">
                <img src="@/assets/images/icons/sales_tooltip.svg" alt="tooltip" />
              </el-tooltip>
            </div>
            <div class="bottom">
              <div class="count">{{ summary.count.toLocaleString() }} <span class="small">건</span></div>
              <div class="amount">{{ summary.amount.toLocaleString() }} <span class="small">원</span></div>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <div class="ticket-container" v-else-if="type === 'ticket'">
      <el-card
        shadow="never"
        :class="{
          card: true,
          active: value === summary.name,
        }"
        v-for="summary in summaryItems"
        :key="summary.name"
      >
        <div class="card-body" @click="() => cardClick(summary.name)">
          <div class="left">
            <img :src="require(`@assets/images/icons/sales_${summary.name}.svg`)" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ summary.label }}</span>
              <el-tooltip
                effect="light"
                placement="bottom"
                v-if="summary.name === 'total'"
                content="신규결제 + 재결제 + 체험 + 업그레이드 + 미수금 결제 + 양도 & 환불 수수료 + 미수금 결제 - 환불"
              >
                <img src="@/assets/images/icons/sales_tooltip.svg" alt="tooltip" />
              </el-tooltip>
            </div>
            <div class="bottom">
              <div :class="{ count: true, invisible: summary.name === 'commission' }">
                {{ summary.count.toLocaleString() }} <span class="small">건</span>
              </div>
              <div class="amount">{{ summary.amount.toLocaleString() }} <span class="small">원</span></div>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <div class="un-payment-container" v-else-if="type === 'un-payment'">
      <el-card
        shadow="never"
        :class="{
          card: true,
          active: value === summary.name,
        }"
        v-for="summary in summaryItems"
        :key="summary.name"
      >
        <div class="card-body" @click="() => cardClick(summary.name)">
          <div class="left">
            <img :src="require(`@assets/images/icons/sales_${summary.name}.svg`)" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ summary.label }}</span>
            </div>
            <div class="bottom">
              <div class="count">{{ summary.count.toLocaleString() }} <span class="small">건</span></div>
              <div class="amount">{{ summary.amount.toLocaleString() }} <span class="small">원</span></div>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <div class="lecture-container" v-else-if="type === 'lecture'">
      <el-card
        shadow="never"
        :class="{
          card: true,
          active: value === summary.name,
        }"
        v-for="summary in summaryItems"
        :key="summary.name"
      >
        <div class="card-body" @click="() => cardClick(summary.name)">
          <div class="left">
            <img :src="require(`@assets/images/icons/sales_${summary.name}.svg`)" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ summary.label }}</span>
              <el-tooltip effect="light" placement="bottom" v-if="summary.name === 'total'" content="그룹 + 프라이빗">
                <img src="@/assets/images/icons/sales_tooltip.svg" alt="tooltip" />
              </el-tooltip>
            </div>
            <div class="bottom">
              <div class="count">{{ summary.count.toLocaleString() }} <span class="small">건</span></div>
              <div class="amount">{{ summary.amount.toLocaleString() }} <span class="small">원</span></div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="item-container" v-else-if="type === 'item'">
      <el-card
        shadow="never"
        :class="{
          card: true,
          active: value === summary.name,
        }"
        v-for="summary in summaryItems"
        :key="summary.name"
      >
        <div class="card-body" @click="() => cardClick(summary.name)">
          <div class="left">
            <img :src="require(`@assets/images/icons/sales_${summary.name}.svg`)" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ summary.label }}</span>
              <el-tooltip
                effect="light"
                placement="bottom"
                v-if="summary.name === 'total'"
                content="대여 + 판매 + 양도 & 환불 수수료 + 미수금 결제 - 환불"
              >
                <img src="@/assets/images/icons/sales_tooltip.svg" alt="tooltip" />
              </el-tooltip>
            </div>
            <div class="bottom">
              <div :class="{ count: true, invisible: summary.name === 'commission' }">
                {{ summary.count.toLocaleString() }} <span class="small">건</span>
              </div>
              <div class="amount">{{ summary.amount.toLocaleString() }} <span class="small">원</span></div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    summaryItems: Array,
    type: String,
    changeTab: Function,
    name: String,
    value: String,
  },
  methods: {
    cardClick(value) {
      this.$emit('handleSelectChange', this.name, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-summary {
  flex: 1;
  * {
    color: #545454;
  }
}

.spending-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @include mq-max(968px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    width: 291.5px;
  }
}

.ticket-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @include mq-max(968px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.un-payment-container {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .card {
    width: 291.5px;
  }
}
.lecture-container {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  .card {
    width: 291.5px;
  }
}
.item-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @include mq-max(968px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  min-height: 102px;
  cursor: pointer;

  &.active {
    background-color: #f5f9ff;
    border: 1px solid $dodger-blue;

    .amount {
      color: $dodger-blue;
      span {
        color: $dodger-blue;
      }
    }
  }

  /deep/ .el-card__body {
    width: 100%;
    height: 100%;
    padding: 12px;
  }
  .card-body {
    @include flex;
    gap: 10px;
    width: 100%;
    height: 100%;

    .right {
      @include flex(column);
      justify-content: space-between;
      flex: 1;

      .top {
        height: 36px;
        @include flex(row, center);
        span {
          font-size: 18px;
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .small {
          font-size: 12px;
        }
      }
    }
  }

  .count {
    font-size: 15px;
    font-weight: 500;

    &.invisible {
      visibility: hidden;
    }
  }

  .amount {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
  }
}
</style>
