<template>
  <div id="settings-main">
    <ul class="settings__routes">
      <li
        v-for="route in settingsRoutes"
        :key="route.name"
        class="settings__routes__link"
        :class="{ disabled: route.disabled }"
        @click="handleRouteClick(route)"
      >
        <div class="completion-rate">
          <div class="circle">
            <img :src="route.src" />
          </div>
        </div>
        <h5>{{ route.title }}</h5>
        <p>{{ route.description }}</p>
        <i class="el-icon-arrow-right"></i>
      </li>
    </ul>
    <CompanyProfile />
  </div>
</template>
<script>
export default {
  computed: {
    settingsRoutes() {
      return [
        {
          title: '필수정보',
          description: '상호명, 주소, 전화번호, 운영시간 설정',
          completed: 100,
          name: 'SettingsRequired',
          disabled: !this.canUpdateFacilitySettings,
          src: require('@/assets/images/icons/required.svg'),
        },
        {
          title: '운영정보',
          description: '예약, 게시판, 락커, 체크인, 미수금 자동입력 설정',
          completed: 100,
          name: 'SettingsOperations',
          disabled: !this.canUpdateOperationsSettings,
          src: require('@/assets/images/icons/operation.svg'),
        },
        {
          title: '역할별 권한설정',
          description: '역할별 기능 접근 권한',
          completed: 100,
          name: 'SettingsPermissions',
          disabled: !this.canUpdateFacilityRolesAndPermissions,
          src: require('@/assets/images/icons/permission.svg'),
        },
        {
          title: '룸 설정',
          description: '룸 생성, 수정, 삭제',
          completed: 100,
          name: 'SettingsRooms',
          disabled: !this.canUpdateRoomsSettings,
          src: require('@/assets/images/icons/room.svg'),
        },
        {
          title: '수업 구분 설정',
          description: '수업 구분 생성, 수정, 삭제',
          completed: 100,
          name: 'SettingsDivisions',
          disabled: false,
          src: require('@/assets/images/icons/division.svg'),
        },
        {
          title: '회원 등급 설정',
          description: '회원 등급 생성, 수정, 삭제',
          completed: 100,
          name: 'SettingsUserGrades',
          disabled: false,
          src: require('@/assets/images/icons/userGrade.svg'),
        },
        {
          title: '자동 알림 설정',
          description: '수업 시작 전, 잔여횟수 만료 알림 설정',
          completed: 100,
          name: 'SettingsAutoMessageNoti',
          disabled: false,
          src: require('@/assets/images/icons/message.svg'),
        },
        {
          title: '서비스 결제',
          description: '월 이용료 결제, 휴대폰 문자 결제',
          completed: 100,
          name: 'SettingsServicePayments',
          disabled: false,
          src: require('@/assets/images/icons/payment.svg'),
        },
      ];
    },
  },
  methods: {
    handleRouteClick({ name, disabled }) {
      if (!disabled) this.$router.push({ name });
    },
  },
};
</script>
<style lang="scss" scoped>
#settings-main {
  @include flex(column);
  max-width: 1024px;
  margin: auto;

  /deep/ section {
    margin-left: 26px;
  }
}
.settings__routes {
  flex: 1;
  &__link {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'completed title icon'
      'completed description icon';
    padding: 24px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: 0.15s background;
    &:hover {
      background: rgba($link-color, 0.05);
    }
    &.disabled {
      cursor: default;
      background: $gray-100;
      color: $gray-500;
    }
    .completion-rate {
      position: relative;
      padding-right: 32px;
      grid-area: completed;
      .circle {
        width: 48px;
        height: 48px;
        position: relative;
        border-radius: 50%;
        background-color: #f0f9ff;

        img {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    h5 {
      grid-area: title;
      align-self: end;
      padding: 2px 0px;
    }
    p {
      grid-area: description;
      align-self: start;
      padding: 2px 0px;
      opacity: 0.6;
    }
    i {
      color: #979797;
      font-size: 40px;
      grid-area: icon;
      opacity: 0.6;
    }
  }
}
</style>
