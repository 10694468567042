<template>
  <div class="lecture-list-filter">
    <div class="lecture-list-filter__date">
      <el-date-picker
        v-model="range"
        type="daterange"
        align="left"
        format="yyyy. M. d."
        value-format="yyyy-MM-dd"
        :picker-options="rangePickerOptions"
        :clearable="false"
      />
    </div>

    <div
      class="lecture-list-filter__filter"
      :class="{ 'is-filtered': isFiltered(key, filterValues[key]) }"
      v-for="key in filterKeys"
      :key="key"
    >
      <el-popover
        v-if="key === 'timeRanges'"
        popper-class="lecture-list-filter__popover"
        placement="bottom"
        v-model="showPopover[key]"
        @after-leave="() => handleClickCancel(key)"
      >
        <ul class="lecture-list-filter__popover__weekday-select">
          <li v-for="weekday in weekdays" :key="weekday">
            <el-button
              size="small"
              circle
              :type="customValues[key][weekday].length > 0 ? 'primary' : 'default'"
              @click="handleClickWeekday(weekday)"
              >{{ weekday }}</el-button
            >
          </li>
        </ul>

        <ul v-for="weekday in weekdays" :key="weekday" class="lecture-list-filter__popover__hour-select">
          <li v-for="(time, index) in customValues[key][weekday]" :key="index">
            <h5>{{ index === 0 ? `${weekday}요일` : '' }}</h5>
            <HoursInput
              v-model="customValues[key][weekday][index]"
              :startHourOptions="{
                start: '00:00',
                end: '23:50',
                step: '00:05',
              }"
              :endHourOptions="{
                start: customValues[key][weekday][index].start || '00:00',
                end: '23:55',
                step: '00:05',
                minTime: customValues[key][weekday][index].start,
              }"
              :clearable="false"
            />
            <el-button icon="el-icon-minus" size="mini" circle @click="handleClickRemoveSchedule(weekday, index)" />
            <el-button
              v-if="index === customValues[key][weekday].length - 1"
              icon="el-icon-plus"
              size="mini"
              circle
              @click="handleClickAddSchedule(weekday)"
            />
          </li>
        </ul>

        <div class="lecture-list-filter__popover__checkbox">
          <el-checkbox v-model="searchRange">범위로 검색</el-checkbox>
        </div>

        <div class="lecture-list-filter__popover__buttons">
          <el-button @click="showPopover[key] = false">
            취소
          </el-button>
          <el-button type="primary" @click="handleClickApply(key)">
            적용
          </el-button>
        </div>
        <el-button slot="reference">
          {{ timeRangesButtonLabel }}
          <i v-if="!hasTimeRangeValues" class="el-icon-arrow-down"></i>
          <button v-else @click="handleClickResetTimeRange">
            <i class="el-icon-close"></i>
          </button>
        </el-button>
      </el-popover>

      <el-select
        v-else
        :value="filterValues[key]"
        @change="value => handleChangeFilter({ [key]: value })"
        :multiple="filterOptions[key].multiple"
        :placeholder="filterOptions[key].placeholder"
        collapse-tags
        clearable
      >
        <el-option v-for="item in filterOptions[key].options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>

    <FilterResetButton @click="$emit('reset-click')" />
  </div>
</template>

<script>
export default {
  props: {
    filterValues: Object,
    filterOptions: Object,
    availablePeriod: Array,
  },

  data() {
    return {
      weekdays: ['월', '화', '수', '목', '금', '토', '일'],
      showPopover: {
        timeRanges: false,
      },
      customValues: {
        timeRanges: { 월: [], 화: [], 수: [], 목: [], 금: [], 토: [], 일: [] },
        exactTimeRange: true,
      },
    };
  },

  computed: {
    filterKeys() {
      return ['timeRanges', 'instructors'];
    },

    range: {
      get() {
        return this.filterValues.dateRange;
      },
      set(dateRange) {
        this.$emit('change', { ...this.filterValues, dateRange });
      },
    },

    rangePickerOptions() {
      const { moment } = this;
      const [start, end] = this.availablePeriod;
      return {
        disabledDate(date) {
          if (!start || !end) return false;

          date = moment(date).format('YYYY-MM-DD');
          return date < start || date > end;
        },
      };
    },

    hasTimeRangeValues() {
      return this.weekdays.reduce((list, weekday) => [...list, ...this.filterValues.timeRanges[weekday]], []).length;
    },

    timeRangesButtonLabel() {
      if (!this.hasTimeRangeValues) return '수업일정 전체';
      const weekdaysSelected = this.weekdays.filter(weekday => this.filterValues.timeRanges[weekday].length);
      if (weekdaysSelected.length < 5) {
        return weekdaysSelected.join(', ');
      } else {
        return `${weekdaysSelected.slice(0, 4).join(', ')}, ...`;
      }
    },

    searchRange: {
      get() {
        return !this.customValues.exactTimeRange;
      },
      set(value) {
        this.customValues.exactTimeRange = !value;
      },
    },
  },

  methods: {
    handleChangeFilter(values) {
      this.$emit('change', { ...this.filterValues, ...values });
    },

    isFiltered(key, value) {
      if (key === 'timeRanges') return this.hasTimeRangeValues;
      return !(!value || !value.length);
    },

    handleClickApply(key) {
      this.showPopover[key] = false;
      this.handleChangeFilter({
        timeRanges: this.copyTimeRanges(this.customValues.timeRanges),
        exactTimeRange: this.customValues.exactTimeRange,
      });
    },

    handleClickCancel(key) {
      this.showPopover[key] = false;
      this.customValues = {
        timeRanges: this.copyTimeRanges(this.filterValues.timeRanges),
        exactTimeRange: this.filterValues.exactTimeRange,
      };
    },

    /** 요일 선택시 */
    handleClickWeekday(weekday) {
      if (!this.customValues.timeRanges[weekday].length) {
        this.handleClickAddSchedule(weekday);
      } else {
        this.customValues.timeRanges[weekday] = [];
      }
    },

    /** 일정 추가시 */
    handleClickAddSchedule(weekday) {
      const timeRanges = this.customValues.timeRanges[weekday];
      if (!timeRanges.length) {
        this.customValues.timeRanges[weekday].push({ start: '09:00', end: '10:00' });
      } else {
        const { startTime, endTime } = this.$utils.getNextTimeRange(timeRanges[timeRanges.length - 1]);
        const dayOverLimit = this.$utils.checkDayOverLimit(endTime);
        if (!startTime) return;
        if (dayOverLimit) {
          this.$utils.notify.error(this, '오류', '종료시간을 24:00로 사용하실 수 없습니다.');
          return;
        }

        this.customValues.timeRanges[weekday].push({ start: startTime, end: endTime });
      }
    },

    /** 일정 삭제시 */
    handleClickRemoveSchedule(weekday, index) {
      this.customValues.timeRanges[weekday].splice(index, 1);
    },

    handleClickResetTimeRange() {
      this.customValues = {
        timeRanges: { 월: [], 화: [], 수: [], 목: [], 금: [], 토: [], 일: [] },
        exactTimeRange: true,
      };
      this.handleChangeFilter({ ...this.customValues });
    },

    copyTimeRanges(timeRanges) {
      return this.weekdays.reduce(
        (acc, weekday) => {
          acc[weekday] = [...timeRanges[weekday]];
          return acc;
        },
        { 월: [], 화: [], 수: [], 목: [], 금: [], 토: [], 일: [] },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-list-filter {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;

  & > * {
    height: 36px;
    margin: 0 8px 8px 0;
  }

  /deep/ .el-input__inner {
    @extend %input-default;
    border-color: #dcdfe6;
    height: 36px;

    &::placeholder {
      color: $charcoal-grey;
    }
  }

  /deep/ .el-select .el-input__inner {
    padding: 0 20px 0 10px;
  }

  /deep/ .el-date-editor {
    min-width: 135px;
    max-width: 250px;
  }

  &__date {
    @include flex(row, center, center);

    & > * {
      margin-right: 4px;

      &:last-child {
        margin: 0;
      }
    }

    /deep/ .el-button {
      padding: 0;
      height: 36px;
      width: 36px;
    }
  }

  &__filter {
    width: 140px;

    /deep/ .el-select__tags > span {
      @include flex(row, center);
    }

    /deep/ .el-tag {
      background: none !important;
      color: $charcoal-grey;
      font-size: 14px;
      padding: 0;
      max-width: 80px;
      @include ellipsis;

      &::after {
        content: ', ';
      }

      &:last-child::after {
        content: '';
      }

      i {
        display: none;
      }
    }

    /deep/ .el-button {
      padding: 0 10px;
      height: 36px;
      width: 100%;

      span {
        @include flex(row, center, space-between);
        i {
          color: #c0c4cc;
        }

        button {
          @include flex(row, center, center);
          border-radius: 50%;
          padding: 0;

          &:hover {
            background: $deep-sky-blue;
            i {
              color: #fff;
            }
          }
        }
      }
    }

    &.is-filtered {
      /deep/ .el-input__inner,
      /deep/ .el-button {
        border-color: $deep-sky-blue;
        color: $deep-sky-blue;

        i {
          color: $deep-sky-blue;
        }
      }

      /deep/ .el-input__icon,
      /deep/ .el-tag {
        color: $deep-sky-blue;
      }
    }
  }

  &__popover {
    &__weekday-select {
      padding: 10px 20px;
      display: grid;
      grid-template-columns: repeat(7, 32px);
      grid-gap: 8px;

      button {
        width: 32px;
        height: 32px;
      }
    }

    &__hour-select {
      li {
        display: grid;
        align-items: center;
        grid-template-columns: 50px 300px auto auto;
        padding: 8px 20px;

        h5 {
          font-size: 13px;
          font-weight: 300;
        }

        button {
          width: 28px;
          height: 28px;
        }
      }
    }

    &__inputs {
      padding: 10px 0;
    }

    &__checkbox {
      padding: 10px 20px;
    }

    &__buttons {
      padding: 10px 20px;
      text-align: right;
    }
  }
}
</style>
