<template>
  <aside class="roles">
    <h6>역할을 선택해주세요</h6>
    <ul class="roles__list">
      <li
        v-for="role in roles"
        :key="role.id"
        class="roles__list__item"
        :class="{ active: activeRole && activeRole.id === role.id }"
        @click="handleClickRole(role.id)"
      >
        <div class="roles__list__item__inner edit" v-if="roleEditing && roleEditing.id === role.id">
          <input
            ref="roleEditing"
            v-model="roleEditing.display_name"
            placeholder="최대8자"
            @keyup.enter="updateRoleName"
            @input="checkInputLength('roleEditing')"
          />

          <div class="roles__list__item__buttons">
            <circle-button @click="updateRoleName">
              <i class="el-icon-check"></i>
            </circle-button>
            <circle-button @click="handleClickCancelEdit">
              <i class="el-icon-close"></i>
            </circle-button>
          </div>
        </div>

        <div v-else class="roles__list__item__inner">
          <span class="roles__list__item__name">
            {{ role.display_name }}
          </span>

          <div v-if="!role.name" class="roles__list__item__buttons">
            <circle-button @click="handleClickEditRoleName(role.id)">
              <img src="@/assets/images/01_graphic/ic_16x16/solid/edit.svg" alt="수정" />
            </circle-button>
            <circle-button @click="handleClickDeleteRoleName(role.id)">
              <img src="@/assets/images/01_graphic/ic_16x16/solid/delete.svg" alt="삭제" />
            </circle-button>
          </div>
        </div>

        <span class="roles__list__item__is-active"></span>
      </li>
    </ul>
    <el-button
      v-if="!roleCreating"
      class="roles__create-role-button"
      icon="el-icon-plus"
      size="small"
      round
      @click="handleClickCreateNewRole"
      >새로운 역할 추가</el-button
    >
    <div v-else class="roles__create-form">
      <input
        ref="roleCreating"
        v-model="roleCreating.display_name"
        placeholder="최대8자"
        @keyup.enter="saveNewRole"
        @input="checkInputLength('roleCreating')"
      />
      <circle-button @click="saveNewRole">
        <i class="el-icon-check"></i>
      </circle-button>
      <circle-button @click="handleClickCancelCreate">
        <i class="el-icon-close"></i>
      </circle-button>
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      roleEditing: null,
      roleCreating: null,
    };
  },

  computed: {
    roles() {
      return this.$store.getters['studio/roles'];
    },
    activeRole() {
      return this.$store.getters['studio/activeRole'];
    },
  },

  watch: {
    $route: {
      handler: 'dispatchGetRoles',
      immediate: true,
    },
  },

  methods: {
    dispatchGetRoles(activeRoleId) {
      this.$store.dispatch('studio/getRoles', activeRoleId);
    },

    dispatchSetActiveRole(role) {
      this.$store.dispatch('studio/setActiveRole', role);
    },

    handleClickRole(role_id) {
      const role_index = this.roles.findIndex(({ id }) => id === role_id);
      this.dispatchSetActiveRole(this.roles[role_index]);
    },

    /** 역할 이름 수정 버튼 클릭시 */
    handleClickEditRoleName(role_id) {
      const role_index = this.roles.findIndex(({ id }) => id === role_id);
      this.roleEditing = { ...this.roles[role_index] };
      this.$nextTick(() => {
        this.$refs.roleEditing[0].focus();
      });
    },

    /** 역할 이름 수정 */
    async updateRoleName() {
      this.$refs.roleEditing[0].blur();

      if (this.roleEditing && this.roleEditing.display_name) {
        try {
          const { id, display_name } = this.roleEditing;
          await this.$api.studio.roles.update(id, { display_name });
          this.$utils.notify.success(this, '확인', '저장 되었습니다');
          this.dispatchGetRoles();
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }

      this.roleEditing = null;
    },

    /** 역할 이름 수정 취소 */
    handleClickCancelEdit() {
      this.roleEditing = null;
    },

    /** 역할 삭제 */
    handleClickDeleteRoleName(role_id) {
      const role_index = this.roles.findIndex(({ id }) => id === role_id);
      const { display_name } = this.roles[role_index];
      const title = '역할 삭제';
      const message = `<b>${display_name}</b> 역할을 삭제하시겠습니까?`;

      this.$confirm(message, title, {
        confirmButtonText: '',
        cancelButtonText: '',
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          try {
            await this.$api.studio.roles.delete(role_id);
            this.$utils.notify.success(this, '성공', `<b>${display_name}</b> 역할을 삭제했습니다`);
            if (this.activeRole.id === role_id) {
              this.dispatchSetActiveRole(null);
            }
            this.dispatchGetRoles();
          } catch (error) {
            this.$utils.notify.parseError(this, error);
          }
        })
        .catch(() => {});
    },

    /** 역할 생성 버튼 클릭시 */
    handleClickCreateNewRole() {
      this.roleCreating = { display_name: '' };
      this.$nextTick(() => {
        this.$refs.roleCreating.focus();
      });
    },

    /** 역할 생성 */
    async saveNewRole() {
      this.$refs.roleCreating.blur();

      if (this.roleCreating && this.roleCreating.display_name) {
        try {
          const { display_name } = this.roleCreating;
          const res = await this.$api.studio.roles.create({ display_name });
          this.$utils.notify.success(this, '확인', '저장 되었습니다');
          this.dispatchGetRoles(res.data);
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }

      this.roleCreating = null;
    },

    /** 역할 생성 취소 */
    handleClickCancelCreate() {
      this.roleCreating = null;
    },

    /** 역할 이름 글자수 확인 */
    checkInputLength(key) {
      if (!this[key]) return;

      const value = this[key].display_name;
      const { exceeded, exceededAt } = this.$utils.checkBytesOfString(value, 16);

      if (exceeded) {
        this[key].display_name = value.slice(0, exceededAt);
        if (key === 'roleCreating') {
          this.$refs[key].blur();
          this.$refs[key].focus();
        } else if (key === 'roleEditing') {
          this.$refs[key][0].blur();
          this.$refs[key][0].focus();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.roles {
  @include flex(column, center, flex-start);
  width: 260px;
  border-right: 1px solid rgba(#000, 0.12);
  margin: 0 30px 15px 0;
  padding: 0 30px 15px 0;

  h6 {
    align-self: flex-start;
    opacity: 0.8;
    font-size: 12px;
    margin-bottom: 20px;
  }

  &__list {
    margin-bottom: 20px;
    max-height: calc(100vh - 440px);
    overflow: auto;
    width: 100%;

    &__item {
      @include flex(row, center, space-between);
      font-size: 15px;
      opacity: 0.8;
      height: 46px;
      padding: 4px 8px;
      border-bottom: 1px solid rgba(#000, 0.12);
      cursor: pointer;
      transition: 0.15s;

      &__inner {
        flex: 1;
        @include flex(row, center, space-between);

        input {
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 6px;
          width: 130px;
        }
      }

      &__inner.edit &__buttons {
        @include flex(row, center, center);
        margin-left: 4px;
      }

      &__name {
        padding: 8px;
      }

      &__buttons {
        display: none;
      }

      &__is-active {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-left: 4px;
      }

      &:hover {
        background: $gray-100;
      }

      &:hover &__buttons {
        @include flex(row, center, center);
      }

      &.active {
        color: $dark-sky-blue;
        opacity: 1;
      }

      &.active &__is-active {
        background: $dark-sky-blue;
      }
    }
  }

  &__create-form {
    @include flex();

    input {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 6px;
      margin-right: 4px;
    }
  }
}
</style>
