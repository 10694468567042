<template>
  <el-dialog class="locker-add" :title="title" :visible="show" @close="$emit('close', false)">
    <div class="locker-add__body">
      <span>{{ description }}</span>
      <el-input placeholder="숫자를 입력해주세요." v-model="data.addCount" @keypress.native="validateKey" maxlength="3">
      </el-input>
      <div class="warning-delete-locker" v-if="isAssignLocker">삭제할 락커안에 할당된 락커가 있습니다.</div>
      <div class="none-delete-locker" v-else></div>
    </div>
    <div slot="footer">
      <el-button @click="$emit('close', false)">취소</el-button>
      <el-button v-on:keyup.enter="valid" @click="valid" type="primary">
        {{ title.slice(-2) }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { LOCKER_STATE_TYPE } from '@constants';

export default {
  props: {
    show: Boolean,
    lockerChangeType: String,
    lockers: Array,
  },
  data() {
    return {
      data: { type: 'T', addCount: null },
      isAssignLocker: false
    };
  },
  computed: {
    title() {
      return this.lockerChangeType === LOCKER_STATE_TYPE.ADD ? '락커 추가' : '락커 삭제';
    },
    description() {
      return this.lockerChangeType === LOCKER_STATE_TYPE.ADD
        ? '추가 할 락커 개수를 입력하세요'
        : '삭제 할 락커 개수를 입력하세요';
    },
  },

  watch: {
    'data.addCount'(value) {
      if (this.lockerChangeType === LOCKER_STATE_TYPE.ADD) return;
      this.isAssignLocker = !!this.lockers.filter((el, index) => {
        return index + 1 > this.lockers.length - Number(value) && el.member;
      }).length;
    },
  },

  methods: {
    validateKey(e) {
      if (!e.key.replace(/\D/g, '')) e.preventDefault();
    },
    async valid() {
      const { notify } = this.$utils;
      if (this.data.addCount === null) {
        return notify.parseError(this, { message: '추가 락커 갯수를 입력해주세요.' });
      } else if (this.data.addCount <= 0) {
        return notify.parseError(this, { message: '0 이상의 숫자를 입력해주세요.' });
      } else if (isNaN(this.data.addCount)) {
        return notify.parseError(this, { message: '숫자만 입력 가능합니다.' });
      }
      await this.$emit('close', false, this.data, this.lockerChangeType === LOCKER_STATE_TYPE.ADD ? LOCKER_STATE_TYPE.ADD : LOCKER_STATE_TYPE.DELETE);
    },
  },
};
</script>

<style lang="scss" scoped>
.locker-add {
  /deep/ .el-dialog {
    max-width: 550px;
    &__header {
      padding: 20px 30px;
    }
    &__body {
      padding: 10px 30px 20px;
    }
  }
  span {
    font-size: 13px;
  }
  &__radio-group {
    margin: 12px 5px 20px 0;
    /deep/ .el-radio__label {
      font-size: 13px;
    }
  }
  .description {
    p {
      font-size: 15px;
    }
    &__accent {
      font-weight: bold;
      color: $color-danger;
    }
  }
  img {
    width: 100%;
    margin-top: 20px;
  }
  .preview-message {
    white-space: pre-line;
    border: 1px solid $deep-sky-blue;
    border-radius: 4px;
    height: 500px;
    width: 370px;
    overflow-y: auto;
    margin: 0 auto;
    padding: 10px;
    p {
      word-break: break-word;
    }
  }
  /deep/ .el-dialog__footer {
    padding-top: 0;
  }
}
.warning-delete-locker {
  color: $color-danger;
  height: 22px;
}
.none-delete-locker {
  height: 22px;
}
</style>
