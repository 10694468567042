<template>
  <div class="working-hour-input">
    <h4 class="working-hour-input__weekday" :class="{ isHoliday: weekday.isHoliday }">
      <span class="wrap-blue-txt">{{ weekdays[index] }}</span>
    </h4>
    <div class="working-hour-input__hours">
      <div class="working-hour-input__hours__block">
        <h5 :class="{ isHoliday: weekday.isHoliday }">
          {{ openHourLabel }}
        </h5>
        <hours-input
          v-model="weekday.open[0]"
          :startHourOptions="{
            start: '00:00',
            end: '23:50',
            step: '00:05',
          }"
          :endHourOptions="{
            start: weekday.open[0].start || '00:00',
            end: '23:55',
            step: '00:05',
            minTime: weekday.open[0].start,
          }"
          :disabled="weekday.isHoliday"
          :clearable="false"
          showDuration
        />
        <el-checkbox class="working-hour-input__hours__block__isHoliday" v-model="weekday.isHoliday" label="휴무일" />
      </div>

      <transition-group name="el-zoom-in-top" v-if="!hideClosedHours">
        <div
          class="working-hour-input__hours__block"
          v-for="closedHourIndex in _.range(weekday.closed.length)"
          :key="closedHourIndex"
        >
          <h5 class="rest-hour-title" :class="{ isHoliday: weekday.isHoliday }">
            {{ closedHourIndex === 0 ? closedHourLabel : '' }}
          </h5>
          <hours-input
            v-model="weekday.closed[closedHourIndex]"
            :startHourOptions="{
              start: weekday.open[0].start,
              end: $utils.convertTime.subtractThirtyMinutes(weekday.open[0].end),
              step: '00:05',
            }"
            :endHourOptions="{
              start: weekday.closed[closedHourIndex].start || weekday.open[0].start,
              end: weekday.open[0].end,
              step: '00:05',
              minTime: weekday.closed[closedHourIndex].start,
            }"
            :disabled="weekday.isHoliday || !weekday.open[0].start || !weekday.open[0].end"
            :clearable="false"
            showDuration
          />
          <el-button icon="el-icon-minus" size="mini" circle @click="removeClosedHour(index, closedHourIndex)" />
        </div>
      </transition-group>

      <div class="working-hour-input__hours__add-rest-hours" v-if="!hideClosedHours">
        <el-button @click="addClosedHour(index)" size="mini" round :disabled="weekday.isHoliday">
          휴식시간 추가
          <i class="el-icon-plus"></i>
        </el-button>
      </div>
    </div>

    <div class="working-hour-input__button" v-if="index == 0">
      <el-button size="mini" round @click="applyToAllOpenHours()">
        모든 요일에 일괄 적용
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    weekday: Object,
    index: [Number, String],
    openHourLabel: {
      type: String,
      default: '영업시간',
    },
    closedHourLabel: {
      type: String,
      default: '휴식시간',
    },
    addClosedHour: Function,
    removeClosedHour: Function,
    applyToAllOpenHours: Function,
    hideClosedHours: { type: Boolean, default: false },
  },

  data() {
    return {
      weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    };
  },
};
</script>

<style lang="scss" scoped>
.working-hour-input {
  display: grid;
  grid-template-columns: 15px 510px auto;
  grid-gap: 20px;
  h4 {
    .wrap-blue-txt {
      // background: rgba(71, 153, 242, 0.3);
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: inline-block;
    }
  }
  &__weekday {
    font-size: 20px;
    margin: 4px 20px 0 0;

    &.isHoliday {
      opacity: 0.3;
    }
  }

  &__hours {
    &__block {
      display: grid;
      grid-template-columns: 70px 1fr 70px;
      align-items: center;
      justify-items: start;
      padding-bottom: 12px;

      h5 {
        font-size: 13px;
        margin-bottom: 0 !important;

        &.rest-hour-title {
          font-weight: 300;
        }

        &.isHoliday {
          opacity: 0.3;
        }
      }

      &__isHoliday {
        margin: 0;
      }
    }

    &__add-rest-hours {
      padding: 0 0 20px 90px;
      font-weight: 300;
    }
  }

  &__button {
    button {
      background: #fff;
      border: 1px solid $color-primary;
      border-radius: 2px;
      color: $color-primary;
      padding: 5px 10px;

      &:hover {
        background: rgba($color-primary, 0.1);
      }
    }
    button.el-button--mini {
      border: 1px solid #dcdfe6;
      color: #606266;
      border-radius: 20px;
      &:hover {
        color: $color-primary;
      }
    }
  }
}
</style>
