<template>
  <div class="sub-header-container">
    <div class="text">
      <span>총 </span>
      <span v-if="!invisibleCount">{{ count.toLocaleString() }}건</span>
      <span style="color: #808080;" v-if="userCount"> (회원 {{ this.userCount }}명)</span>
      <span v-if="!$route.path.includes('sales/point')"> ({{ Number(amount).toLocaleString() }}원)</span>
    </div>
    <TextInput
      :value="search"
      prefix-icon="el-icon-search"
      :placeholder="placeholder"
      @input="handleSearchChange"
      @keyup.native.enter="searchResult"
      round
    />
  </div>
</template>

<script>
export default {
  props: {
    count: Number,
    amount: Number,
    userCount: Number,
    search: String,
    handleSearchChange: Function,
    searchResult: Function,
    placeholder: String,
    invisibleCount: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.sub-header-container {
  @include flex(row, center, space-between);
  color: #545454;

  .text {
    font-size: 22px;
    font-weight: bold;
  }

  .custom-text-input {
    width: auto !important;
  }
}
</style>
