<template>
  <MainLayout>
    <HeaderTabs activeTab="/lectures/bookings" :exceldownloading="exceldownloading" @exceldown="downloadExcel" />

    <ListFilter
      v-loading="loading"
      :rangeFilter="rangeFilter"
      :localFilter="localFilter"
      :localFilterOptions="localFilterOptions"
      :bookingsCount="bookingsCount"
      @change="handleFilterChange"
    />

    <List v-loading="loading" :bookings="filteredBookings" @sort-change="handleFilterChange" />
  </MainLayout>
</template>

<script>
import { BOOKING_STATUS, BOOKING_STATUS_ARRAY } from '@constants';
import HeaderTabs from '@components/Lectures/HeaderTabs';
import ListFilter from '@components/Lectures/ListFilter';
import List from '@components/Lectures/BookingList';

export default {
  components: { HeaderTabs, ListFilter, List },

  data() {
    const { moment } = this;

    return {
      loading: false,
      bookings: [],
      bookingApiReqArr: [],
      bookingApiReqTerm: 1,
      exceldownloading: false,

      rangeFilter: {
        type: 'date',
        dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },
      localFilter: {
        instructor: null,
        courseType: null,
        bookingStatus: null,
      },
    };
  },

  computed: {
    localFilterOptions() {
      let filterOptions = {
        instructor: [{ value: null, label: '강사 전체' }],
        courseType: [{ value: null, label: '수업구분 전체' }],
        bookingStatus: [
          { value: null, label: '예약상태 전체' },
          { value: BOOKING_STATUS.BOOKED, label: '예약(대기/확정)' },
          { value: BOOKING_STATUS.ATTENDED, label: '출석' },
          { value: BOOKING_STATUS.ABSENT, label: '결석' },
          { value: BOOKING_STATUS.NOSHOW, label: '노쇼' },
          { value: BOOKING_STATUS.CANCEL, label: '취소' },
        ],
      };

      if (!this.bookings.length) return filterOptions;

      this.bookings.forEach(({ lecture }) => {
        if (!lecture.deleted_at) {
          const value = {
            instructor: _.get(lecture, 'staff.profile.name'),
            courseType: _.get(lecture, 'course.type'),
          };
          const label = {
            instructor: value.instructor,
            courseType: this.$utils.translate.courseType(value.courseType),
          };

          Object.keys(value).forEach(key => {
            if (!filterOptions[key].some(option => option.value === value[key])) {
              filterOptions[key].push({ value: value[key], label: label[key] });
            }
          });
        }
      });

      return filterOptions;
    },

    filteredBookings() {
      const { instructor, courseType, bookingStatus } = this.localFilter;

      /**
       * 삭제된 수업 제거
       * 필터 조건 적용
       */
      let bookingsCountInLecture = {};
      let filteredBookings = this.bookings.filter(({ lecture, status }) => {
        // if (status === 'cancel' && updated_for === '당일예약 변경') return false; 당일예약변경으로 인한 취소도 리스트에 나오게 cs 요청??
        if (bookingStatus === 'checkIn') status = 'checkIn'; // 체크인의 경우 status가 백에서 attendence로 넘어옴. 강제로 status의 값을 checkIn으로 할당
        const isInstructorMatch = !instructor || lecture.staff.profile.name === instructor;
        const isCourseTypeMatch = !courseType || lecture.course.type === courseType;
        const isBookingStatusMatch =
          !bookingStatus ||
          (bookingStatus === BOOKING_STATUS.BOOKED ? BOOKING_STATUS_ARRAY.BOOKED.includes(status) : status === bookingStatus);

        if (!isInstructorMatch || !isCourseTypeMatch || !isBookingStatusMatch || !!lecture.deleted_at) {
          return false;
        }

        /** 수업의 예약 카운트 저장 (rowspan 값으로 활용) */
        if (bookingsCountInLecture[lecture.id]) {
          bookingsCountInLecture[lecture.id]++;
        } else {
          bookingsCountInLecture[lecture.id] = 1;
        }

        return true;
      });

      /** 정렬 */
      filteredBookings = _.orderBy(
        filteredBookings,
        [
          'lecture.start_on',
          'lecture.staff.profile.name',
          'lecture.course.type',
          'lecture.title',
          'lecture.course.title',
          'updated_at',
        ],
        [this.checkInSortType === 'ascending' ? 'asc' : 'desc', 'asc', 'asc', 'asc', 'asc', 'asc'],
      );

      /** 데이터에 rowspan 값 추가 */
      let prevLectureId;
      let filteredBookingsWithSpanInfo = [];
      filteredBookings.forEach(booking => {
        const lectureId = booking.lecture_id;
        if (prevLectureId !== lectureId) {
          booking.rowspan = bookingsCountInLecture[lectureId];
          prevLectureId = lectureId;
          filteredBookingsWithSpanInfo.push(booking);
        } else {
          booking.rowspan = 0;
          filteredBookingsWithSpanInfo.push(booking);
        }
        return booking;
      });

      return filteredBookingsWithSpanInfo;
    },

    bookingsCount() {
      const total = this.filteredBookings.length;
      const P = this.filteredBookings.filter(booking => booking.lecture.course.type === 'P').length;
      const G = total - P;
      return { total, P, G };
    },

    checkInSortType() {
      return this.$store.getters['lectures/checkInSortType'];
    },
  },

  watch: {
    $route: {
      handler: 'fetchBookings',
      immediate: true,
    },
  },

  methods: {
    recurReqTerm(count, t1, t2, reqIndex) {
      if (reqIndex === this.bookingApiReqTerm) {
        this.bookingApiReqArr.push({
          start_date: t1.format('YYYY-MM-DD'),
          end_date: t1.add({ months: reqIndex }).format('YYYY-MM-DD'),
        });
      } else {
        this.bookingApiReqArr.push({
          start_date: t1.add({ days: 1 }).format('YYYY-MM-DD'),
          end_date: t1
            .add({ months: this.bookingApiReqTerm })
            .subtract({ day: 1 })
            .format('YYYY-MM-DD'),
        });
      }

      count = count - this.bookingApiReqTerm;

      if (count > this.bookingApiReqTerm) {
        this.recurReqTerm(count, t1, t2, reqIndex + this.bookingApiReqTerm);
      } else {
        this.bookingApiReqArr.push({
          start_date: t1.add({ days: 1 }).format('YYYY-MM-DD'),
          end_date: this.rangeFilter.dates[1],
        });
      }
    },

    async fetchBookings(statusParams, keyword) {
      try {
        this.loading = true;

        let resultParams = {
          params: {
            start_date: this.rangeFilter.dates[0],
            end_date: this.rangeFilter.dates[1],
            page: 1,
            limit: 15,
          },
          name: 'booking',
        };
        if (typeof statusParams === 'string') {
          resultParams.params.status = statusParams;
        }
        let res = await this.$utils.getApis(resultParams);

        let newRes = res.data;

        if (keyword) {
          newRes = newRes.filter(({ member }) => {
            return member.profile.name.includes(keyword) || (member.mobile && member.mobile.includes(keyword));
          });
        }
        this.bookings = newRes;
        this.bookingApiReqArr = [];
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    async handleFilterChange(key, value, keyword) {
      this[key] = value;
      let statusParams = value.bookingStatus;
      if (statusParams === BOOKING_STATUS.BOOKED) {
        statusParams = 'booking_confirmed;booked;booking_waiting';
      }
      this.fetchBookings(statusParams, keyword);
    },

    async downloadExcel() {
      this.exceldownloading = true;
      const json = this.formatJSON(this.filteredBookings);

      let range = this.rangeFilter.dates[0];
      if (this.rangeFilter.type === 'week') {
        range += `_${this.rangeFilter.dates[1]}`;
      }

      /** 다운로드 */
      this.$utils.downloadExcel(json, `예약내역_${range}.xlsx`);
      this.exceldownloading = false;
    },

    formatJSON(bookings) {
      return bookings.map(({ lecture, member, user_ticket, status, updated_at }) => {
        const lectureStartOn = this.moment(_.get(lecture, 'start_on'));
        const lectureEndOn = this.moment(_.get(lecture, 'end_on'));
        const courseType = this.$utils.translate.courseType(_.get(lecture, 'course.type'));
        const remainingDays = this.$utils.getRemainingDaysText(
          _.get(user_ticket, 'expire_at'),
          _.get(user_ticket, 'availability_start_at'),
        );
        const mobile = _.get(member, 'mobile');
        const mobileConverted = this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);

        let data = {
          수업일: lectureStartOn.format('YYYY-MM-DD'),
          수업시작: lectureStartOn.format('HH:mm'),
          수업종료: lectureEndOn.format('HH:mm'),
          강사: _.get(lecture, 'staff.profile.name'),
          수업구분: courseType,
          수업명: _.get(lecture, 'title') || _.get(lecture, 'course.title'),
          룸: _.get(lecture, 'room.name'),
          예약상태: this.$utils.translate.bookingStatus(status),
          상태변경일시: this.moment(updated_at).format('YYYY-MM-DD HH:mm'),
          회원명: _.get(member, 'profile.name'),
          휴대폰번호: mobile ? mobileConverted : null,
          수강권명: _.get(user_ticket, 'ticket.title'),
          수강권잔여기간: remainingDays,
        };

        if (user_ticket.ticket.type !== 'P') {
          data = {
            ...data,
            수강권잔여횟수: _.get(user_ticket, 'remaining_coupon'),
            수강권전체횟수: _.get(user_ticket, 'max_coupon'),
          };
        }

        /** 수강권 상태 */
        data = {
          ...data,
          수강권상태:
            user_ticket.ticket.type === 'P'
              ? remainingDays.slice(-2) === '지남'
                ? '만료'
                : '이용중'
              : remainingDays.slice(-2) === '지남' || _.get(user_ticket, 'remaining_coupon') === 0
              ? '만료'
              : '이용중',
        };

        return data;
      });
    },
  },
};
</script>
