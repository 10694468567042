<template>
  <div class="custom-radio-group" :class="{ disabled }">
    <label v-for="option in options" :key="option.value" :class="{ active: option.value === value }">
      <input
        type="radio"
        :value="option.value"
        :checked="option.value === value"
        :disabled="disabled"
        @change="$emit('input', $event.target.value)"
      />
      <span>{{ option.label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    options: Array,
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.custom-radio-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 10px 0;

  label {
    border: solid $deep-sky-blue;
    border-width: 1px 1px 1px 0;
    color: $deep-sky-blue;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding: 7px;
    text-align: center;
    width: 100%;
    margin: 0;
    transition: 0.2s background;

    &:first-child {
      border-width: 1px;
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      background: rgba($deep-sky-blue, 0.1);
    }

    &.active {
      background: $deep-sky-blue;
      color: #fff;
    }

    input {
      display: none;
    }
  }

  &.disabled label {
    cursor: not-allowed;
  }
}
</style>
