<template>
  <el-card class="trend-chart" shadow="hover">
    <div class="trend-chart__header">
      <h3>TREND</h3>
      <el-select :value="category" @change="changeFilter">
        <el-option v-for="item in categoryOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>
    <div v-if="isDataReady" class="trend-chart__chart">
      <BarChart :chartData="chartData" :options="chartOptions" />
    </div>
    <p class="trend-chart__no-data" v-else>표시할 데이터가 없습니다.</p>
  </el-card>
</template>

<script>
export default {
  props: {
    category: String,
    categoryOptions: Array,
    chartData: Object,
    changeFilter: Function,
  },

  computed: {
    isDataReady() {
      const labels = this._.get(this.chartData, 'labels');
      const datasets = this._.get(this.chartData, 'datasets');
      const hasNoLabels = !labels || !labels.length;
      const hasNoData = datasets.every(item => item.data.every(value => !value));
      return !hasNoLabels && !hasNoData;
    },
    chartOptions() {
      const { comma } = this.$filters;

      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'point',
          bodyFontSize: 15,
          bodySpacing: 7,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            title() {
              return null;
            },
            label(tooltipItem, { datasets }) {
              const { datasetIndex, value } = tooltipItem;
              if (value != 0) {
                return datasets[datasetIndex].label;
              }
            },
          },
        },
        legend: {
          display: true,
        },
        scales: {
          yAxes: [
            {
              id: 'amount',
              position: 'left',
              gridLines: {
                drawOnChartArea: false,
              },
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return `${comma(value)}원`;
                },
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              barPercentage: 0.7,
              gridLines: {
                display: false,
              },
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  &__header {
    @include flex(row, center, space-between);
    margin-bottom: 20px;

    h3 {
      font-weight: bold;
    }

    /deep/ .el-input {
      max-width: 100px;
    }

    /deep/ .el-input__inner {
      @extend %input-default;
      padding: 9.5px 20px 9.5px 10px;
      height: 100%;
      text-align: center;
    }
  }

  &__chart {
    padding-top: 50px;
  }

  &__no-data {
    opacity: 0.6;
  }
}
</style>
