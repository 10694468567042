<template>
  <li class="memo-creating">
    <Textarea ref="memo-creating" :value="value" @input="value => $emit('input', value)" required />
    <div class="memo-creating__buttons">
      <PlainButton @click="$emit('cancel')" type="danger" round>취소</PlainButton>
      <BaseButton @click="$emit('save')" :disabled="disabled" round>저장</BaseButton>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },

  created() {
    this.$nextTick(() => {
      this.$refs['memo-creating'].$el.focus();
    });
  },
};
</script>

<style lang="scss" scoped>
.memo-creating {
  border: solid 1px #cccccc;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  @include flex(column, flex-end, flex-start);

  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 48px);
    grid-gap: 8px;
    margin-top: 8px;
  }
}
</style>
