<template>
  <section class="ticket-detail">
    <div class="ticket-detail__tickets">
      <TicketCard :ticket="userTicket.ticket" :userTicket="userTicket" :showFavoriteIcon="false" />
      <img src="@/assets/images/big-arrow.svg" alt="big arrow" />
      <TicketCard :ticket="ticket" :userTicket="userTicket" :showFavoriteIcon="false" />
    </div>

    <div class="ticket-detail__diff">
      <div class="ticket-detail__diff__row">
        <div class="ticket-detail__diff__col">
          <p class="title">양도</p>
        </div>

        <div class="ticket-detail__diff__col">
          <p class="title">양수</p>
        </div>
      </div>
      <div v-for="item in ticketDetail" :key="item.key" class="ticket-detail__diff__row">
        <div class="ticket-detail__diff__col">
          <label>
            {{ item.label }}
          </label>
          <div>
            <span>{{ item.currentValue }}</span> <span v-if="!item.currentValue.length">회</span>
          </div>
        </div>

        <div class="ticket-detail__diff__col">
          <label>
            {{ item.label }}
          </label>

          <div v-if="item.key === 'period'">
            <el-date-picker
              placeholder="이용시작일"
              type="date"
              format="yyyy. M. d."
              value-format="yyyy-MM-dd"
              :picker-options="startDatePickerOptions"
              :clearable="false"
              v-model="startOn"
            />
            ~
            <el-date-picker
              placeholder="이용종료일"
              type="date"
              format="yyyy. M. d."
              value-format="yyyy-MM-dd"
              :picker-options="endDatePickerOptions"
              :clearable="false"
              v-model="endOn"
            />
          </div>

          <div v-else>
            <div v-if="item.key === 'remaining_modify'">
              <span>{{ transferTicketDetail[item.key] }}</span
              ><span v-if="!transferTicketDetail[item.key].length">회</span>
            </div>

            <el-input
              v-else-if="isNaN(transferTicketDetail[item.key])"
              class="ticket-detail__diff__input"
              :disabled="true"
              v-model="transferTicketDetail[item.key]"
            />

            <NumberInput
              v-else
              v-model="transferTicketDetail[item.key]"
              :min="0"
              :max="999"
              @change="handleChangeNumberInput(item.key)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="ticket-detail__buttons">
      <el-button @click="$emit('click-prev')">
        이전
      </el-button>
      <el-button @click="handleSaveTransferTicket" type="primary">다음</el-button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    userTicket: { type: Object, required: true },
    ticket: { type: Object, required: true },
    isProduct: Boolean,
    title: String,
  },

  data() {
    return {
      today: this.moment().format('YYYY-MM-DD'),
      transferTicketDetail: {
        availability_start_at: this.moment().format('YYYY-MM-DD HH:mm:ss'),
        expire_at: this.moment().format('YYYY-MM-DD HH:mm:ss'),
        remaining_coupon: 0,
        max_coupon: 0,
        remaining_cancel: 0,
        remaining_modify: 0,
      },
    };
  },

  computed: {
    // 대여형 (기간, 횟수 둘다)
    isRentalAll() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RM';
    },

    // 대여형 (기간만)
    isRentalOnlyPeriod() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RP';
    },

    // 대여형 (횟수만)
    isRentalOnlyTime() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RT';
    },

    // 이용기간
    currentTicketStartOn() {
      return this.moment(this.userTicket.availability_start_at);
    },
    currentTicketEndOn() {
      return this.moment(this.userTicket.expire_at);
    },
    availabilityStartAt() {
      return `${this.moment(this.currentTicketStartOn).format('YYYY-MM-DD')} 00:00:00`;
    },
    expireAt() {
      return `${this.moment(this.currentTicketEndOn).format('YYYY-MM-DD')} 23:59:59`;
    },

    ticketDetail() {
      if (!this.ticket) return [];

      /** 잔여횟수, 전체횟수 */
      const remainingCouponCurrentValue = this.ticket.type === 'P' ? '무제한' : this.userTicket.remaining_coupon;
      const maxCouponCurrentValue = this.ticket.type === 'P' ? '무제한' : this.userTicket.max_coupon;

      /** 취소 가능 횟수 */
      const remainingCancelCurrentValue =
        this.ticket.type === 'P' && !this.ticket.is_show_cancel_count
          ? '무제한'
          : this.setMaxNumber999(`${this.userTicket.remaining_cancel}`);

      /** 당일 예약변경 가능횟수 */
      const currentDailyBookingChangeLimit = _.get(this.userTicket, 'ticket.daily_booking_change_limit');
      const dailyBookingChangeLimitCurrentValue = currentDailyBookingChangeLimit ? currentDailyBookingChangeLimit : '사용안함';

      const items = [
        {
          key: 'period',
          label: `${this.title} 이용기간`,
          currentValue: `${this.$filters.date(this.currentTicketStartOn)} ~ ${this.$filters.date(this.currentTicketEndOn)}`,
          availability_start_at: this.availabilityStartAt,
          expire_at: this.expireAt,
        },
        {
          key: 'remaining_coupon',
          label: '잔여 횟수',
          currentValue: remainingCouponCurrentValue,
        },
        {
          key: 'max_coupon',
          label: '전체 횟수',
          currentValue: maxCouponCurrentValue,
        },
        {
          key: 'remaining_cancel',
          label: '예약 취소 가능 횟수',
          currentValue: remainingCancelCurrentValue,
          number: this.userTicket.remaining_cancel,
        },
        {
          key: 'remaining_modify',
          label: '당일 예약 변경 가능 횟수',
          currentValue: dailyBookingChangeLimitCurrentValue,
          number: currentDailyBookingChangeLimit,
        },
      ];

      if (this.isRentalOnlyPeriod) {
        return items.filter(item => item.key === 'period');
      }

      if (this.isRentalOnlyTime) {
        const keys = ['period', 'remaining_modify', 'remaining_cancel'];
        return items.filter(item => !keys.includes(item.key));
      }

      if (this.isRentalAll) {
        const keys = ['remaining_modify', 'remaining_cancel'];
        return items.filter(item => !keys.includes(item.key));
      }

      return items;
    },

    transferDetail() {
      return this.$store.getters['ticketEdit/transferDetail'];
    },

    ticketPeriod() {
      if (!this.userTicket) return null;

      const startOn = _.get(this.userTicket, 'availability_start_at');
      const endOn = _.get(this.userTicket, 'expire_at');
      const remainingDays = this.$utils.getDaysDiff(endOn, startOn);
      return remainingDays;
    },

    endDatePickerOptions() {
      const { moment, today } = this;
      return {
        disabledDate(time) {
          return moment(time).isBefore(today) || moment(time).isAfter('2031-12-31');
        },
      };
    },

    startDatePickerOptions() {
      const { moment } = this;
      return {
        disabledDate(time) {
          return moment(time).isAfter('2031-12-30');
        },
      };
    },

    startOn: {
      get() {
        const { availability_start_at } = this.transferTicketDetail;
        return this.moment(availability_start_at).format('YYYY-MM-DD');
      },
      set(value) {
        if (!value) return;
        this.transferTicketDetail.availability_start_at = `${value} 00:00:00`;
      },
    },

    endOn: {
      get() {
        const { expire_at } = this.transferTicketDetail;
        return this.moment(expire_at).format('YYYY-MM-DD');
      },
      set(value) {
        if (!value) return;
        this.transferTicketDetail.expire_at = `${value} 23:59:59`;
      },
    },
  },

  watch: {
    startOn() {
      this.setEndDate();
    },
  },

  created() {
    const period = this.getIndex('period');
    const remainingCoupon = this.getIndex('remaining_coupon');
    const maxCoupon = this.getIndex('max_coupon');
    const remainingCancel = this.getIndex('remaining_cancel');
    const remainingModify = this.getIndex('remaining_modify');

    // 양도 수강권 인풋 세팅
    this.transferTicketDetail = {
      availability_start_at: this.isRentalOnlyTime ? this.availabilityStartAt : this.ticketDetail[period].availability_start_at,
      expire_at: this.isRentalOnlyTime ? this.expireAt : this.ticketDetail[period].expire_at,
      remaining_coupon: this.ticketDetail[remainingCoupon].currentValue,
      max_coupon: this.ticketDetail[maxCoupon].currentValue,
      remaining_cancel: this.ticketDetail[remainingCancel].currentValue,
      remaining_modify: this.isProduct ? 0 : this.ticketDetail[remainingModify].currentValue,
    };
  },

  methods: {
    getIndex(keyValue) {
      const index = this.ticketDetail.findIndex(el => el.key === keyValue);
      if (index < 0) return 0;

      return index;
    },
    /** 종료일 자동 설정 */
    setEndDate() {
      const { moment, transferTicketDetail } = this;
      const endDate = moment(transferTicketDetail.availability_start_at)
        .add(this.ticketPeriod, 'd')
        .format('YYYY-MM-DD');

      this.transferTicketDetail.expire_at = !this.transferTicketDetail.availability_start_at
        ? ''
        : moment(endDate).isAfter('2031-12-31')
        ? '2031-12-31'
        : endDate;
    },

    handleChangeNumberInput(key) {
      const { remaining_coupon, max_coupon } = this.transferTicketDetail;

      if (remaining_coupon <= max_coupon) return;

      if (key === 'remaining_coupon') {
        this.transferTicketDetail.max_coupon = remaining_coupon;
      } else if (key === 'max_coupon') {
        this.transferTicketDetail.remaining_coupon = max_coupon;
      }
    },

    setTransferTicketDetail() {
      // 기간제 수강권인 경우 무제한을 999로 보내야 함
      if (this.ticket.type === 'P') {
        this.transferTicketDetail = { ...this.transferTicketDetail, remaining_coupon: 999, max_coupon: 999 };
        if (!this.ticket.is_show_cancel_count) {
          this.transferTicketDetail = { ...this.transferTicketDetail, remaining_cancel: 999 };
        }
      }
    },

    handleSaveTransferTicket() {
      this.setTransferTicketDetail();
      this.$store.commit('ticketEdit/SET_TRANSFER_TICKET', this.transferTicketDetail);
      this.$emit('click-next', this.transferTicketDetail);
    },

    setMaxNumber999(value) {
      const valueNum = Number(value);
      return value > 999 ? 999 : valueNum;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-detail {
  &__tickets {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 16px;
    align-items: center;
  }

  &__diff {
    padding: 40px 0;

    &__row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }

    &__col {
      @include flex(column);
      height: 100%;
      padding: 8px;
      position: relative;

      &:first-child {
        border-right: 1px solid rgba(#000, 0.5);
      }
      &:last-child {
        padding-left: 32px;
      }

      /deep/ .el-date-editor.el-input {
        width: 117px;
        /deep/ .el-input--prefix .el-input__inner {
          padding-left: 26px;
        }

        /deep/ .el-input--suffix .el-input__inner {
          padding-right: 7px;
        }
      }

      .title {
        font-size: 14px;
        font-weight: bold;
      }

      label {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;

        &.increase {
          color: $dodger-blue;
        }
        &.decrease {
          color: $color-danger;
        }
      }

      span {
        font-size: 14px;
      }
    }

    /deep/ &__input {
      input {
        text-align: center;
      }
    }
  }

  &__buttons {
    @include flex(row, flex-end, space-between);
    border-top: 1px solid #f0f0f0;
    flex: 1;
    width: 100%;
    padding: 20px 20px 0;
  }
}
</style>
