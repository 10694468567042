import { render, staticRenderFns } from "./FloatingActionButton.vue?vue&type=template&id=7b4e3ea2&scoped=true&"
var script = {}
import style0 from "./FloatingActionButton.vue?vue&type=style&index=0&id=7b4e3ea2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b4e3ea2",
  null
  
)

export default component.exports