<template>
  <section class="settings-user-grades">
    <form-block :index="1" label="회원 등급 사용" required>
      <el-checkbox v-model="useUserGrades" slot="checkbox" @change="handleChangeUseUserGrades">사용함</el-checkbox>
      <p class="settings-user-grades__form-block__description">
        회원 등급을 사용하려면 설정해 주세요.
      </p>
    </form-block>

    <form-block :index="2" label="회원 등급 추가" required v-if="useUserGrades">
      <div class="settings-user-grades__add">
        <button v-if="!gradeCreating" class="settings-user-grades__add__add-button" @click="handleClickAddGrade">
          + 회원 등급 추가
        </button>
        <div v-else>
          <div class="settings-user-grades__add__add-form">
            <input
              v-model="gradeCreating.name"
              ref="new-grade"
              type="text"
              placeholder="회원 등급 이름을 입력하세요."
              @keypress.enter="handleClickAddSave(gradeCreating)"
            />
            <PlainButton @click="handleClickAddCancel">
              취소
            </PlainButton>
            <BaseButton v-loading="loading" @click="handleClickAddSave(gradeCreating)" :disabled="!gradeCreating.name"
              >저장</BaseButton
            >
          </div>

          <!-- color set -->
          <div class="wrap-color">
            <div class="wrap-color__chips">
              <ColorChip
                v-for="(color, index) in colorChips"
                :key="index"
                :color="color"
                :checked="color === selectedColor"
                @click="handleColorSelect"
              />
            </div>
            <plain-button class="wrap-color__add-new" @click="showColorsAll = !showColorsAll">
              색상 추가
            </plain-button>
            <p class="wrap-color__helper">
              {{ messageOnColorSelected }}
            </p>
          </div>
        </div>

        <!-- 수정 -->
        <ul class="settings-user-grades__add__list">
          <li v-for="grade in userGrades" :key="grade.id" class="settings-user-grades__add__list__item">
            <div class="wrap-name">
              <div
                class="initcolor"
                :style="
                  grade.representative_color === '#757575'
                    ? { 'background-color': `${grade.representative_color}` }
                    : { 'background-color': `#${grade.representative_color}` }
                "
              ></div>
              <input
                v-if="isEditing(grade)"
                v-model="gradeEditing.name"
                ref="edit-grade"
                type="text"
                placeholder="회원 등급 이름을 입력하세요."
                @keypress.enter="handleClickEditSave(gradeEditing)"
              />
              <p v-else>{{ grade.name }}</p>
              <PlainButton @click="isEditing(grade) ? handleClickEditSave(gradeEditing) : handleClickEditGrade(grade)" round>{{
                isEditing(grade) ? '저장' : '수정'
              }}</PlainButton>
              <PlainButton
                @click="isEditing(grade) ? handleClickEditCancel(grade) : handleClickDeleteGrade(grade)"
                type="danger"
                round
                >{{ isEditing(grade) ? '취소' : '삭제' }}</PlainButton
              >
            </div>
            <!-- color set -->
            <div v-if="isEditing(grade)" class="palette wrap-color">
              <div class="wrap-color__chips">
                <ColorChip
                  v-for="(color, index) in colorChips"
                  :key="index"
                  :color="color"
                  :checked="color === selectedColor"
                  @click="handleColorSelect(color, grade)"
                />
              </div>
              <plain-button class="wrap-color__add-new" @click="showColorsAll = !showColorsAll">
                색상 추가
              </plain-button>
              <p class="wrap-color__helper">
                {{ messageOnColorSelected }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </form-block>
  </section>
</template>

<script>
export default {
  data() {
    return {
      useUserGrades: false,
      userGrades: [],
      gradeCreating: null,
      gradeEditing: null,
      showColorsAll: false,
      selectedColor: 'f95454',
      selectedId: null,
      initColor: null,
      loading: false,
    };
  },

  watch: {
    studioPolicies: {
      handler() {
        this.useUserGrades = !!_.get(this.studioPolicies, 'is_use_user_grade');
      },
      immediate: true,
    },
  },

  created() {
    this.getUserGrades();
  },

  computed: {
    colorPresets() {
      return this.$store.getters['colorPresets/colorPlainPalette'];
    },
    colorChips() {
      let colorChips = [];
      if (!this.showColorsAll) {
        colorChips = [...this.colorPresets[1]];
        if (!colorChips.includes(this.selectedColor)) colorChips.push(this.selectedColor);
      } else {
        colorChips = this.colorPresets.reduce((combined, colorGroup) => combined.concat(colorGroup), []);
      }
      return colorChips;
    },
    messageOnColorSelected() {
      const duplicateColor = this.userGrades.filter(
        item => item.representative_color === this.selectedColor && item.id !== this.selectedId,
      );
      if (duplicateColor.length) return `${duplicateColor.length} 개의 회원 등급에서 사용 중인 색상입니다.`;
      return '';
    },
  },
  methods: {
    async getUserGrades() {
      try {
        const res = await this.$api.studio.userGrades.get();
        this.userGrades = res.data;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    async handleChangeUseUserGrades() {
      await this.$store.dispatch('studio/updateStudio', {
        policy: {
          ...this.studioPolicies,
          is_use_user_grade: this.useUserGrades,
        },
      });
      this.$store.dispatch('studio/getStudio');
    },

    /** 색상 선택시 */
    handleColorSelect(color, grade) {
      this.selectedColor = color;
      this.showColorsAll = false;
      if (grade) {
        grade.representative_color = color;
        this.gradeEditing.representative_color = color;
      }
    },

    handleClickAddGrade() {
      this.gradeEditing = null;
      this.gradeCreating = { name: '' };
      this.$nextTick(() => {
        this.$refs['new-grade'].focus();
      });
    },
    initSelectedColor() {
      this.selectedColor = 'f95454';
    },
    async handleClickAddSave(grade) {
      this.loading = true;
      this.initSelectedColor;
      try {
        await this.$api.studio.userGrades.create({
          name: grade.name,
          representative_color: this.selectedColor,
        });
        this.$utils.notify.success(this, '성공', '회원등급을 저장하였습니다.');
        this.getUserGrades();
        this.gradeCreating = null;
        this.initSelectedColor;
      } catch (error) {
        this.$utils.notify.error(this, '오류', '중복된 이름입니다.');
      } finally {
        this.loading = false;
      }
    },

    handleClickAddCancel() {
      this.gradeCreating = null;
    },

    handleClickEditGrade(grade) {
      this.handleClickAddCancel();
      this.gradeEditing = { ...grade };
      this.initColor = grade.representative_color;
      this.selectedColor = grade.representative_color;
      this.selectedId = grade.id;
      this.$nextTick(() => {
        this.$refs['edit-grade'][0].select();
      });
    },

    async handleClickEditSave(grade) {
      this.loading = true;
      try {
        await this.$api.studio.userGrades.update(grade);
        this.$utils.notify.success(this, '성공', '회원등급을 수정하였습니다.');
        this.getUserGrades();
        this.gradeEditing = null;
        this.initSelectedColor;
      } catch (error) {
        this.$utils.notify.error(this, '오류', '중복된 이름입니다.');
      } finally {
        this.loading = false;
      }
    },

    handleClickEditCancel(grade) {
      this.gradeEditing = null;
      this.selectedColor = grade.representative_color;
      grade.representative_color = this.initColor;
    },

    async handleClickDeleteGrade(grade) {
      try {
        const title = '회원 등급 삭제';
        const message = !grade.studio_users_count
          ? '회원 등급을 삭제하시겠습니까?'
          : `${this.$filters.comma(grade.studio_users_count)}명의 회원 등급으로 지정되어 있습니다.<br>
            삭제하시면 해당 회원의 등급은 해제됩니다.<br>
            회원 등급을 삭제하시겠습니까?`;

        const proceed = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (proceed) {
          await this.$api.studio.userGrades.delete(grade.id);
          this.$utils.notify.success(this, '확인', `<b>${grade.name}</b> 회원 등급이 삭제 되었습니다.`);
          this.getUserGrades();
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    isEditing(grade) {
      return this.gradeEditing && this.gradeEditing.id === grade.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-user-grades {
  &__form-block {
    &__description {
      font-size: 12px;
      font-weight: 500;
      line-height: 2;
      letter-spacing: normal;
      color: $dodger-blue;
    }
  }

  &__add {
    max-width: 560px;

    &__add-button {
      font-size: 14px;
      font-weight: 500;
      color: $deep-sky-blue;
      border: solid 1px $deep-sky-blue;
      border-radius: 16px;
      padding: 7px;
      width: calc(100% - 32px);
      transition: background 0.15s;
      margin-left: 10px;
      width: 328px;

      &:hover {
        background: rgba($deep-sky-blue, 0.1);
      }
    }

    &__add-form {
      display: grid;
      grid-template-columns: 230px 48px 48px;
      grid-gap: 8px;
      margin-left: 10px;

      input {
        border-radius: 2px;
        border: solid 1px rgba(#8a8a8a, 0.5);
        padding: 7px 4px;
        height: 30px;

        &:hover,
        &:focus {
          border-color: #8a8a8a;
        }

        &::placeholder {
          color: rgba(#8a8a8a, 0.5);
        }
      }

      button {
        border-radius: 16px;
      }
    }

    &__list {
      @include flex(column);
      margin-top: 20px;
      border-top: solid 1px rgba(#8a8a8a, 0.2);

      &__item {
        padding: 13px 16px;
        border-bottom: 1px solid rgba(#000, 0.08);

        .wrap-name {
          display: grid;
          grid-template-columns: 32px 230px 48px 48px;
          grid-template-rows: 32px;
          grid-gap: 8px;
          align-items: center;
          .initcolor {
            border: 1px solid transparent;
            width: 32px;
            height: 32px;
          }
        }
        input {
          border-radius: 2px;
          border: solid 1px rgba(#8a8a8a, 0.5);
          padding: 7px 4px;

          &:hover,
          &:focus {
            border-color: #8a8a8a;
          }

          &::placeholder {
            color: rgba(#8a8a8a, 0.5);
          }
        }

        p {
          @include ellipsis;
        }

        button {
          font-size: 14px;
          padding: 3px;
        }
        .palette {
          margin-left: -0.5px;
        }
      }
    }
  }
}
.wrap-color {
  margin: 16px 0 16px 10px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  align-items: start;
  &__chips {
    display: grid;
    grid-template-columns: repeat(11, 32px);
    grid-gap: 8px;
  }

  &__add-new {
    width: 100px;
    height: 32px;
  }

  &__helper {
    grid-column: span 2;
    height: 20px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.6px;
    color: $coral;
    padding-bottom: 10px;
    // border-bottom: solid 1px rgba(#8a8a8a, 0.2);
  }
}
</style>
