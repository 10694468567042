import axios from '../axios';

const CARD_URL = '/v2/staff/credit-card';
const REGULAR_PAYMENT_URL = '/v2/staff/token-payment';

export default {
  monthlyPay: data => axios.post(`${REGULAR_PAYMENT_URL}`, data),
  checkRegistedCard: () => axios.get(`${CARD_URL}`),

  getLastServicePayment: value => axios.get(`${REGULAR_PAYMENT_URL}/last-payment?type=${value}`),
};
