import api from '@api';

export const state = {
  isNewNotice: false,
  loading: false,
  boardDetail: { title: '', contents: '', attachments: [] },
};

export const getters = {
  isNewNotice: state => state.isNewNotice,
  loading: state => state.loading,
  boardDetail: state => state.boardDetail,
};

export const mutations = {
  GET_NEW_NOTICE(state, result) {
    state.isNewNotice = result;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_BOARD_DETAIL(state, data) {
    if (data) {
      state.boardDetail = data;
    } else {
      state.boardDetail = null;
    }
  },
};

export const actions = {
  async getBoardDetail({ commit }, params) {
    commit('SET_LOADING', true);
    try {
      const res = await api.boards.get(params.id);
      commit('SET_BOARD_DETAIL', res.data);
    } catch (error) {
      commit('SET_BOARD_DETAIL');
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
