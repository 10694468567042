<template>
  <div class="main">
    <Notification
      class="main__notification"
      @checkin-success="data => showCheckinNoti(data)"
      :isEnter="studio.policy ? studio.policy.is_enter : 0"
    />
    <MainHeader class="main__header">
      <p v-if="showExpirationNotice && !isRegularPay" class="main__header__error">
        <span v-if="expireIn <= 7 && !(expireIn === 0)">
          {{ studio.name }} 서비스 계정 만료일은 <b>{{ expireOn | dateKorean }}</b> 입니다.
        </span>
        <span v-if="expireIn === 0"> {{ studio.name }} 서비스 <b>계정 만료</b>일입니다. 내일부터 사용이 제한됩니다. </span>
        <!-- 기간 연장은 카카오톡 플러스친구 ‘스튜디오메이트’ 또는 고객센터로 문의주세요. (02-6952-5533, 5366) -->
        <button @click="goServicePay()">서비스 연장하기</button>
      </p>
      <!-- 2023.10.04 이벤트 종료 광고 띠배너 제거 -->
      <!-- <div v-else-if="banner" class="main__header__banner">
        <p>
          <span>
            [이벤트] 스튜디오메이트 이용시설 대상 <b>스컬피그 VIP 비즈니스 회원</b>
            으로 초대합니다. (~9/30 까지)
          </span>
          <button @click="goContactService()">더보기</button>
        </p>
        <i @click="setBannerCloseLocalStorage()" class="el-icon-close"></i>
      </div> -->
    </MainHeader>

    <section class="main__contents" :class="{ padded }" :style="contentStyle">
      <slot />
    </section>

    <!-- 체크인 완료 시 자동 알림창 -->
    <div class="auto-notification">
      <ul class="auto-notification__list">
        <transition-group name="fade">
          <li
            v-for="(noti, index) in liveCheckinNotis"
            :key="index"
            class="auto-notification__list__checkin-item"
            :style="liveNotiStyle(index)"
          >
            <div class="auto-notification__list__checkin-item__title">
              <h6>체크인 완료</h6>
              <div class="auto-notification__list__checkin-item__title__right">
                <div>
                  <img src="@assets/images/02_icons/icon_notification_watch.svg" alt="watch-icon" />
                  <span>
                    {{ setTime(noti.enter_time) }}
                  </span>
                </div>
                <img src="@assets/images/02_icons/icon_notification_close.svg" alt="close-icon" @click="() => onClose(index)" />
              </div>
            </div>
            <div class="auto-notification__list__checkin-item__profile" @click="() => moveShowMore(noti.member_id)">
              <img class="profile-img" :src="getImageUrl(noti.avatar, 0)" alt="profile" />
              <div class="left-wrap">
                <div>
                  <h6>{{ noti.name }}</h6>
                  <h6>회원님</h6>
                </div>
                <img src="@assets/images/02_icons/icon_notification_arrow.svg" alt="" />
              </div>
            </div>
            <div class="auto-notification__list__checkin-item__info-wrap">
              <div>
                <h6>수업일시</h6>
                <h6>{{ noti.lecture_time }}</h6>
              </div>
              <div>
                <h6>수업명</h6>
                <h6>{{ noti.lecture_title }}</h6>
              </div>
              <hr />
              <div>
                <h6>수강권명</h6>
                <h6>{{ noti.user_ticket_title }}</h6>
              </div>
              <div class="auto-notification__list__checkin-item__info-wrap__remaining">
                <div>
                  <h6>잔여횟수</h6>
                  <h6>{{ noti.remaining_coupon }}회</h6>
                </div>
                <div>
                  <h6>잔여일</h6>
                  <h6>{{ noti.remaining_day }}일</h6>
                </div>
              </div>
            </div>
            <button class="auto-notification__list__checkin-item__moreBtn" @click="moveShowMore">
              체크인 내역 더 보기
            </button>
          </li>
        </transition-group>
      </ul>
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader';
import Notification from '@/components/Notification';

export default {
  components: { MainHeader, Notification },

  data() {
    return {
      notiDatas: [],
      isShowCheckinNoti: false,
    };
  },

  props: {
    padded: { type: Boolean, default: true },
    borderTop: { type: String },
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
    expireOn() {
      return _.get(this.studio, 'expire_on');
    },
    expireIn() {
      if (this.expireOn === null) return false;
      return this.$utils.getDaysDiff(this.expireOn);
    },
    currentProduct() {
      return this.studio.grade;
    },
    paymentPolicy() {
      return this.$store.getters['payment/paymentPolicy'];
    },
    isRegularPay() {
      return !!this.paymentPolicy.usable_subscription_auto_charge;
    },
    showExpirationNotice() {
      return this.studio.name && 0 <= this.expireIn && !(this.expireIn === false) && this.expireIn <= 7;
    },
    showExpirationAlert() {
      return (
        this.isLoggedIn &&
        (this.expireIn < 0 || this.expireIn === false) &&
        !(this.$route.name === 'SettingsServicePayments') &&
        !(this.$route.name === 'SettingsServicePayments_paymentInfo')
      );
    },
    contentStyle() {
      if (!this.borderTop) return null;
      return {
        borderTop: `3px solid ${this.borderTop}`,
      };
    },
    liveCheckinNotis() {
      return this.$store.getters['notification/liveCheckinNotis'];
    },
    contactServiceLink() {
      return 'https://docs.google.com/forms/d/e/1FAIpQLSdhMviDX2kM-1Zseg0FveQ-kAfZP4c2hl2XgCvCLvm5yKECmw/viewform?usp=sf_link';
    },
    banner() {
      return this.$store.getters['banner/banner'];
    },
  },

  watch: {
    $route: {
      handler: 'initData',
      immediate: true,
    },
    expireIn() {
      this.checkExpiration();
    },
  },

  methods: {
    initData() {
      this.checkExpiration();
    },
    async checkExpiration() {
      if (!this.studio || !this.paymentPolicy.studio_id) {
        await this.$store.dispatch('studio/getStudio');
        await this.$store.dispatch('payment/getPaymentPolicy');
      }
      if (this.expireIn < 0 && this.isRegularPay) {
        //만료일이 지났는데 && 월 정기 결제 시설 일 경우
        await this.$store.dispatch('studio/getStudio');
      }
      if (this.showExpirationAlert) {
        let title = '안내';
        let message = `결제후 이용하실수 있습니다. 결제 페이지로 이동합니다. <br> 월 정기 결제 고객님은 새로고침 후 이용해주세요.`;
        if (this.studio.is_old_studio) {
          title = '기간 만료';
          message = `이용기간이 만료되었습니다. 결제 페이지로 이동합니다. <br> 월 정기 결제 고객님은 새로고침 후 이용해주세요.`;
        }

        this.$alert(message, title, {
          dangerouslyUseHTMLString: true,
          showClose: false,
        })

          .then(() => {
            this.$router.push({ name: 'SettingsServicePayments' }).catch(() => {});
          })
          .catch(() => {});
      }
    },
    goServicePay() {
      this.$router.push({ name: 'SettingsServicePayments' }).catch(() => {});
    },
    goContactService() {
      const [width, height] = [window.innerWidth / 2, (window.innerHeight / 3) * 2];
      const floor = num => Math.floor(num);
      window.open(
        this.contactServiceLink,
        '_blank',
        `top=${floor(window.innerHeight / 2 - height / 2)}, left=${floor(
          window.innerWidth / 2 - width / 2,
        )}, width=${width}, height=${height}, menubar=no, toolbar=no`,
      );
    },
    async showCheckinNoti(data) {
      await this.$store.dispatch('notification/getLiveCheckinNotis', data);
      setTimeout(() => this.onClose(data.booking_id), 10000);
    },
    async onClose(index) {
      await this.$store.dispatch('notification/getCloseLiveCheckinNotis', index);
    },
    getImageUrl(path, size) {
      return this.$utils.getImagePath(path, size);
    },
    moveShowMore(id) {
      typeof id === 'number' ? this.$router.push(`/users/detail?id=${id}`) : this.$router.push('/lectures/check_in');
    },
    setTime(time) {
      const fromNow = this.moment(time).fromNow();
      const checkFromTime = fromNow.indexOf('일') > -1;

      if (fromNow.indexOf('하루') > -1) return '1일 전';
      else if (checkFromTime) return this.moment(time).fromNow();
      else return this.moment(time).format('HH:mm');
    },
    liveNotiStyle(idx) {
      const length = this.liveCheckinNotis.length;

      if (length === 1) return '';
      else if (length === 2 && idx === 1) return `bottom: ${120 + 300 + 20}px; z-index: ${999 - idx}`;
      return `bottom: ${120 + idx * 160}px; z-index: ${999 - idx}`;
    },
    setBannerCloseLocalStorage() {
      this.$store.commit('banner/SET_BANNER', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: grid;
  grid-template-columns: 55px calc(100vw - 55px);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'notification header'
    'notification contents';

  &__header {
    grid-area: header;

    &__error {
      background: #fef0f0;
      color: $color-danger;
      padding: 8px 16px;
      font-size: 13px;
      button {
        border: 1px solid $color-danger;
        border-radius: 5px;
        color: $color-danger;
        font-weight: bold;
        line-height: 2;
        margin-left: 10px;
      }
    }

    &__banner {
      background: #f5f7fa;
      color: #646464;
      padding: 8px 16px;
      font-size: 13px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      button {
        border: 1px solid #646464;
        border-radius: 5px;
        color: #646464;
        font-weight: bold;
        line-height: 2;
        margin-left: 10px;
      }

      i {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  &__notification {
    grid-area: notification;
  }

  &__contents {
    position: relative;
    overflow-y: auto;
    grid-area: contents;

    &.padded {
      padding: 10px;

      @include mq(768px) {
        padding: 30px;
      }
    }
  }

  .auto-notification {
    &__list {
      .fade-enter {
        opacity: 0;
      }

      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.5s;
      }

      .fade-leave-to {
        opacity: 0;
      }

      &__checkin-item {
        position: fixed;
        right: 60px;
        bottom: 120px;
        z-index: 9;

        padding: 20px;
        width: 266px;
        height: 300px;

        border: solid 1px #eee;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 3px 4px 6px 0 rgba(0, 0, 0, 0.16);

        &__title {
          @include flex(row, center, space-between);
          margin-bottom: 12px;

          h6 {
            font-size: 13px;
          }

          div {
            @include flex(row, center, space-between);
          }

          &__right {
            gap: 15px;

            div {
              gap: 2px;

              span {
                font-size: 12px;
                color: $color-primary;
              }
            }
          }
        }

        &__profile {
          @include flex();
          gap: 10px;
          margin-bottom: 10px;
          cursor: pointer;

          .profile-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }

          .left-wrap {
            @include flex(row, center, space-between);
            flex: 1;

            div {
              @include flex(column, flex-start, center);

              h6 {
                display: -webkit-box;
                overflow: hidden;
                max-width: 150px;
                font-weight: 500;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-wrap: break-word;

                &:last-child {
                  color: #a3a3a3;
                }
              }
            }
          }
        }

        &__info-wrap {
          margin-bottom: 8px;

          div {
            @include flex(row, center, flex-start);
            gap: 8px;
            margin-bottom: 4px;

            h6 {
              font-weight: 500;
              &:first-child {
                width: 46px;
                color: #a3a3a3;
              }
            }
          }

          hr {
            margin: 12px 0 10px;
            opacity: 0.2;
          }

          &__remaining {
            @include flex();
            gap: 30px !important;

            div:last-child {
              gap: 0;
            }
          }
        }

        &__moreBtn {
          margin-top: 8px;
          width: 100%;
          height: 40px;
          border-radius: 2px;
          background-color: #4799f2;
          color: #fff;
        }
      }
    }
  }
}
</style>
