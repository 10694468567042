<template>
  <MainLayout>
    <div id="tickets" v-loading="loading">
      <h3>총 {{ total | comma }}개의 수강권</h3>
      <ProductListFilter
        :filterValues="params.filter"
        :filterOptions="filterOptions"
        :placeholders="{ keyword: '수강권명 검색' }"
        @change="handleFilterChange"
        @reset="handleFilterReset"
        @selectAll="handleClickSelectAll"
        :isSelectedAll="isSelectedAll"
        :selectedTickets="selectedTickets"
        @extendBulk="showExtendTicket = true"
        :extendBulkDisabled="extendBulkDisabled"
      />

      <div v-if="!!tickets.length" class="product-list">
        <TicketCard
          v-for="ticket in tickets"
          :key="ticket.id"
          :ticket="ticket"
          :isChecked="selectedTicketIds.includes(ticket.id)"
          @click="handleClickCard"
          @favorite-icon-click="handleClickFavoriteIcon"
          @checkbox-click="handleClickCheckbox"
          clickable
          show-favorite-icon
          show-price
          :show-checkbox="ticket.type !== 'S'"
        />
      </div>

      <div class="product-list__no-data" v-else-if="!loading">
        <p>{{ noDataMessage }}</p>
      </div>

      <div class="product-list-pagination" v-if="!!tickets.length">
        <el-pagination
          layout="prev, pager, next"
          @current-change="dispatchGetTickets"
          :current-page.sync="page"
          :page-size="limit"
          :total="total"
        />
      </div>
    </div>

    <FloatingActionButton v-if="canCreateProduct" @click="handleClickCreateButton" />
    <CreateProducts :show="showCreateProductsModal" :onClose="handleCloseCreateProductsModal" />

    <ExtendTicket
      :show="showExtendTicket"
      :ticketIds="selectedTicketIds"
      :selectedOnlyPeriodTickets="selectedOnlyPeriodTickets"
      :selectedOnlyRentalTimeTicket="selectedOnlyRentalTimeTicket"
      @close="handleCloseExtendTicket"
      :extendMemoData="extendMemoData"
      :getMemos="getMemos"
      :selectedTickets="selectedTickets"
      :title="title"
    />
  </MainLayout>
</template>

<script>
import ProductListFilter from '@components/Products/ListFilter';
import ExtendTicket from '@/components/Modals/ExtendTicket';
import CreateProducts from '@/components/Modals/CreateProducts';

export default {
  components: { ProductListFilter, ExtendTicket, CreateProducts },

  data() {
    return {
      selectedTickets: [],
      showExtendTicket: false,
      extendMemoData: {},
      showCreateProductsModal: false,
    };
  },

  computed: {
    tickets() {
      return this.$store.getters['tickets/tickets'];
    },
    params() {
      return this.$store.getters['tickets/params'];
    },
    loading() {
      return this.$store.getters['tickets/loading'];
    },
    total() {
      return this.$store.getters['tickets/total'];
    },

    page: {
      get() {
        return this.params.page;
      },
      set(page) {
        this.$store.commit('tickets/SET_PARAMS', { page });
      },
    },

    limit: {
      get() {
        return this.params.limit;
      },
      set(limit) {
        this.$store.commit('tickets/SET_PARAMS', { limit });
      },
    },

    filterOptions() {
      let options = {
        isOnSale: [
          { value: null, label: '모든 수강권' },
          { value: 'favorite', label: '즐겨찾기 수강권' },
          { value: '1', label: '판매중인 수강권' },
          { value: '0', label: '판매정지 수강권' },
        ],
        courseType: [
          { value: null, label: '모든 상품' },
          { value: 'P', label: '프라이빗형' },
          { value: 'G', label: '그룹형' },
        ],
        ticketType: [
          { value: null, label: '모든 타입' },
          { value: 'T', label: '횟수제' },
          { value: 'P', label: '기간제' },
          { value: 'AD', label: '차감제' },
          { value: 'S', label: '판매' },
          { value: 'R', label: '대여' },
        ],
      };

      if (!(this.params.filter.courseType === null)) {
        options.ticketType = [
          { value: null, label: '모든 타입' },
          { value: 'T', label: '횟수제' },
          { value: 'P', label: '기간제' },
          { value: 'AD', label: '차감제' },
        ];
      }

      if (this.params.filter.courseType === 'P') {
        options.maxTrainee = [
          { value: null, label: '수업인원 전체' },
          { value: 1, label: '프라이빗' },
          { value: 2, label: '듀엣' },
          { value: 3, label: '트리플' },
        ];
      }

      return options;
    },

    title() {
      return this.params.filter.ticketType === 'R' ? '상품' : '수강권';
    },

    noDataMessage() {
      const label = this.filterOptions.isOnSale.find(({ value }) => value === this.params.filter.isOnSale).label;
      return `${label}이 없습니다.`;
    },

    selectedTicketIds() {
      return this.selectedTickets.map(({ id }) => id);
    },

    selectedOnlyPeriodTickets() {
      return this.selectedTickets.every(({ type }) => type === 'P' || type === 'RP');
    },

    selectedOnlyRentalTimeTicket() {
      return this.selectedTickets.every(({ type }) => type === 'RT');
    },

    extendBulkDisabled() {
      return this.selectedTickets.length <= 0;
    },

    ticketIdsOnPage() {
      return this.tickets.map(({ id }) => id);
    },

    ticketIdsOnPageExceptSaleProduct() {
      return this.tickets.filter(({ type }) => type !== 'S').map(({ id }) => id);
    },

    isSelectedAll() {
      return this.ticketIdsOnPageExceptSaleProduct.every(id => {
        return this.selectedTicketIds.includes(id);
      });
    },
  },

  watch: {
    $route: {
      handler() {
        this.dispatchGetTickets();
      },
      immediate: true,
    },
  },

  created() {
    window.addEventListener('resize', this.handleWindowResize);

    const subDomain = window.location.hostname.split('.')[0];
    this.getMemos(subDomain);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    handleClickCreateButton() {
      this.showCreateProductsModal = true;
    },

    handleCloseCreateProductsModal() {
      this.showCreateProductsModal = false;
    },

    handleWindowResize() {
      let limit = 10;
      const browserWidth = window.innerWidth;
      if (572 <= browserWidth && browserWidth < 888) limit = 6;
      else if (888 <= browserWidth && browserWidth < 1164) limit = 9;
      else if (1164 <= browserWidth && browserWidth < 1440) limit = 12;
      else if (1440 <= browserWidth && browserWidth < 1716) limit = 15;
      else if (1716 <= browserWidth) limit = 18;

      if (this.limit !== limit) {
        this.limit = limit;
        this.dispatchGetTickets();
      }
    },

    dispatchGetTickets() {
      this.$store.dispatch('tickets/getTickets');
    },

    handleFilterChange(filter) {
      this.$store.commit('tickets/SET_PARAMS', {
        page: 1,
        filter: { ...filter },
      });
      this.selectedTickets = [];
      this.dispatchGetTickets();
    },

    handleFilterReset() {
      const filter = {
        isOnSale: '1',
        courseType: null,
        ticketType: null,
        maxTrainee: null,
        keyword: '',
      };
      this.$store.commit('tickets/SET_PARAMS', { page: 1, filter });
      this.selectedTickets = [];
      this.dispatchGetTickets();
    },

    handleClickCard(ticketId) {
      this.$router.push(`/products/detail?id=${ticketId}`);
    },

    async handleClickFavoriteIcon(ticketId) {
      const ticket = this.tickets.find(({ id }) => id === ticketId);
      const data = {
        favorite_stamp: !ticket.favorite_stamp ? this.moment().format('YYYY-MM-DD HH:mm:ss') : null,
      };
      await this.$api.product.update(ticketId, data);
      this.dispatchGetTickets();
    },

    handleClickCheckbox(ticket) {
      const exists = this.selectedTicketIds.findIndex(id => id === ticket.id);

      if (exists > -1) {
        this.selectedTickets = this.selectedTickets.filter(({ id }) => id !== ticket.id);
      } else {
        this.selectedTickets.push(ticket);
      }
    },

    handleClickSelectAll() {
      if (this.isSelectedAll) {
        this.selectedTickets = this.selectedTickets.filter(({ id }) => !this.ticketIdsOnPageExceptSaleProduct.includes(id));
      } else {
        const exceptSaleProducts = this.tickets.filter(ticket => ticket.type !== 'S');
        this.selectedTickets = _.uniqBy([...this.selectedTickets, ...exceptSaleProducts], 'id');
      }
    },

    handleCloseExtendTicket(refresh) {
      this.showExtendTicket = false;
      if (refresh) this.selectedTickets = [];
    },

    async getMemos(subDomain) {
      const studioId = await this.$api.studio.getId(subDomain);
      const extendData = await this.$api.userTicket.batchExtend(studioId.data.id);

      this.extendMemoData = extendData;
    },
  },
};
</script>

<style lang="scss" scoped>
#products {
  @include flex(column);
  max-width: 1716px;
  margin: auto;

  h3 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.product-list {
  display: grid;
  grid-template-columns: 256px;
  grid-gap: 10px;
  place-items: center;
  justify-content: center;

  @include mq(572px) {
    grid-template-columns: repeat(2, 256px);
    grid-gap: 20px;
  }

  @include mq(888px) {
    grid-template-columns: repeat(3, 256px);
  }

  @include mq(1164px) {
    grid-template-columns: repeat(4, 256px);
  }

  @include mq(1440px) {
    grid-template-columns: repeat(5, 256px);
  }

  @include mq(1716px) {
    grid-template-columns: repeat(6, 256px);
  }

  &-pagination {
    @include flex(row, center, center);
    padding-top: 30px;
  }

  &__no-data {
    opacity: 0.6;
    padding: 10px;
  }
}
</style>
