<template>
  <MainLayout :padded="false">
    <ContentHeader :breadcrumb="breadcrumb" title="수업 일괄 예약하기" :contentMaxWidth="1240" />

    <section class="bulk-bookings">
      <FormBlock :index="1" label="수강권 선택" v-loading="userTicketsLoading">
        <div class="bulk-bookings__select-ticket-container">
          <SelectTicket
            class="bulk-bookings__select-ticket"
            :userTickets="userTickets"
            :selectedUserTicket="selectedUserTicket"
            @select="handleClickUserTicket"
          />

          <div class="bulk-bookings__select-team" v-if="showSelectTeamUI" v-loading="teamUserTicketsLoading">
            <h5>같이 예약할 회원 선택 ({{ selectedTeamUserTickets.length }}/{{ maxTrainee - 1 }})</h5>
            <ul>
              <UserTicketItem
                v-for="userTicket in selectedTeamUserTickets"
                :key="userTicket.id"
                :userTicket="userTicket"
                selected
                @click="handleClickTeamUserTicket"
              />
              <UserTicketItem
                v-for="userTicket in filteredTeamUserTickets"
                :key="userTicket.id"
                :userTicket="userTicket"
                @click="handleClickTeamUserTicket"
              />
            </ul>
          </div>
        </div>
      </FormBlock>

      <PrivateForm
        v-if="courseType === 'P'"
        ref="privateForm"
        v-model="privateData"
        :userTicket="selectedUserTicket"
        :staffOptions="filteredPrivateStaffOptions"
        :roomOptions="roomOptions"
        :titlePlaceholder="defaultLectureTitle"
        :selectedTeamUserTickets="selectedTeamUserTickets"
      />

      <FormBlock v-if="courseType === 'G'" :index="2" label="수업 일정">
        <LectureList ref="lectureList" v-model="selectedLectures" :userTicket="selectedUserTicket" :staffOptions="staffOptions" />
      </FormBlock>
    </section>

    <BottomActionBar>
      <BaseButton type="white" size="large" @click="handleClickSave">{{ submitButtonTitle }}</BaseButton>
    </BottomActionBar>

    <ResultDialog :show="showResultDialog" :resultData="resultData" :totalTry="totalTry" @close="handleCloseResultDialog" />
  </MainLayout>
</template>

<script>
import { BOOKING_STATUS } from '@constants';
import SelectTicket from '@components/BulkBookings/SelectTicket';
import UserTicketItem from '@components/BulkBookings/UserTicketItem';
import PrivateForm from '@components/BulkBookings/PrivateForm';
import LectureList from '@components/BulkBookings/LectureList';
import ResultDialog from '@components/BulkBookings/ResultDialog';

export default {
  components: {
    SelectTicket,
    UserTicketItem,
    PrivateForm,
    LectureList,
    ResultDialog,
  },

  data() {
    return {
      breadcrumb: {
        parent: { path: '/schedule', label: '일정' },
        current_route_label: '일괄 예약하기',
      },

      // 수강권 선택 - 공통
      userTicketsLoading: false,
      userTickets: [],
      selectedUserTicket: null,
      teamUserTicketsLoading: false,
      teamUserTickets: [],
      selectedTeamUserTickets: [],

      // options
      staffOptions: [],
      roomOptions: [],

      privateData: {
        instructorId: null,
        title: '',
        roomId: null,
        schedules: [],
      },

      selectedLectures: [],

      // 일괄 예약 결과
      showResultDialog: false,
      resultData: null,
      totalTry: 0,
    };
  },

  computed: {
    submitButtonTitle() {
      return this.courseType === 'G' ? '수업 예약 완료' : '수업 등록 완료';
    },
    userId() {
      return _.get(this.$route, 'params.id');
    },

    selectedUserTicketId() {
      return _.get(this.selectedUserTicket, 'id');
    },

    ticketId() {
      return _.get(this.selectedUserTicket, 'ticket.id');
    },

    courseType() {
      return _.get(this.selectedUserTicket, 'ticket.available_class_type');
    },

    maxTrainee() {
      return _.get(this.selectedUserTicket, 'ticket.max_trainee');
    },

    weeklyBookingLimit() {
      return _.get(this.selectedUserTicket, 'ticket.booking_limit_per_week');
    },

    monthlyBookingLimit() {
      return _.get(this.selectedUserTicket, 'ticket.booking_limit_per_month');
    },

    showSelectTeamUI() {
      return !!this.selectedUserTicket && this.courseType === 'P' && this.maxTrainee > 1;
    },

    selectedTeamUserTicketIds() {
      return this.selectedTeamUserTickets.map(({ id }) => id);
    },

    maxTraineeReached() {
      return this.selectedTeamUserTickets.length + 1 >= this.maxTrainee;
    },

    filteredTeamUserTickets() {
      if (this.maxTraineeReached) return [];

      return this.teamUserTickets.filter(
        ({ id, usable_coupon }) => usable_coupon && !this.selectedTeamUserTicketIds.includes(id),
      );
    },

    defaultLectureTitle() {
      if (!this.selectedUserTicket) return '수업명';

      const name = _.get(this.selectedUserTicket, 'member.name');

      return this.maxTrainee === 1 ? `${name}님` : `${name} 외 ${this.maxTrainee - 1}명`;
    },

    selectedDates() {
      return this.privateData.schedules.reduce((dates, { date }) => {
        if (!dates.includes(date)) dates.push(date);
        return dates;
      }, []);
    },

    useRooms() {
      return _.get(this.studioPolicies, 'is_use_rooms');
    },

    filteredPrivateStaffOptions() {
      if (!this.selectedUserTicket) return this.staffOptions;

      if (!this.canCreateOthersPrivateLecture) {
        return this.staffOptions.filter(({ id }) => id === this.currentUser.id);
      } else if (!this.canCreateMyPrivateLecture) {
        return this.staffOptions.filter(({ id }) => id !== this.currentUser.id);
      }

      return this.staffOptions;
    },
  },

  watch: {
    maxTraineeReached(value) {
      if (value) {
        this.scrollToRef('privateForm.$refs.instructorId');
      }
    },

    filteredPrivateStaffOptions(options) {
      if (this.courseType === 'P' && !options.length) {
        const title = '권한 없음';
        const message = '예약할 수 없는 수강권입니다.';
        this.$alert(message, title, { showClose: false })
          .then(() => {
            this.selectedUserTicket = null;
            if (this.userTickets.length <= 1) {
              return this.$router.push(`/users/detail?id=${this.userId}`);
            }
          })
          .catch(() => {});
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!to.params.id) return next('/schedule');
    next();
  },

  async created() {
    this.init();
  },

  methods: {
    async init() {
      // reset component state
      this.userTicketsLoading = false;
      this.userTickets = [];
      this.selectedUserTicket = null;
      this.teamUserTicketsLoading = false;
      this.teamUserTickets = [];
      this.selectedTeamUserTickets = [];
      this.staffOptions = [];
      this.roomOptions = [];
      this.privateData = {
        instructorId: null,
        title: '',
        roomId: null,
        schedules: [],
      };
      this.selectedLectures = [];

      this.resultData = null;
      this.showResultDialog = false;
      this.totalTry = 0;

      // load data
      await this.getStaffOptions();
      await this.getRoomOptions();
      await this.getUserTickets(this.userId);
    },

    async getUserTickets(member_id) {
      try {
        this.userTicketsLoading = true;
        const res = await this.$api.userTicket.getAll({ member_id, is_all: true, with: 'ticket.divisions' });
        /**
         * 제외 수강권
         * 1. 사용불가 (환불)
         * 2. 이용만료 (기간/횟수)
         * 3. 예약가능횟수 없는 수강권
         * 4. 상품
         */
        const userTickets = res.data.filter(userTicket => {
          const isActive = userTicket.active;
          const isExpired = this.moment(userTicket.expire_at).format('YYYYMMDD') < this.moment().format('YYYYMMDD');
          const isProduct = userTicket.ticket.available_class_type === 'I';
          const hasUsableCoupon = userTicket.usable_coupon > 0;
          return isActive && !isExpired && hasUsableCoupon && !isProduct;
        });

        if (!userTickets.length) {
          const title = '수강권 없음';
          const message = '예약 가능한 수강권이 없습니다.';
          return this.$alert(message, title, { showClose: false })
            .then(() => this.$router.push(`/users/detail?id=${this.userId}`))
            .catch(() => {});
        }
        this.userTickets = await Promise.all(
          userTickets.map(async userTicket => {
            const res = await this.$api.userTicket.getBookings(userTicket.id);
            if (!res.data.bookings) userTicket.bookings = [];
            userTicket.bookings = res.data.bookings.filter(({ status }) => status !== BOOKING_STATUS.CANCEL);
            return userTicket;
          }),
        );
        if (userTickets.length === 1) {
          this.handleClickUserTicket(userTickets[0]);
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        this.$router.push(`/users/detail?id=${member_id}`);
      } finally {
        this.userTicketsLoading = false;
      }
    },

    async getTeamUserTickets() {
      try {
        this.teamUserTicketsLoading = true;
        const params = {
          max_trainee: this.maxTrainee,
          ticket_id: this.ticketId,
          available_class_type: 'P',
        };
        const res = await this.$api.userTicket.getAll({ ...params, hasHolding: 1 });
        this.teamUserTickets = res.data.filter(({ id }) => id !== this.selectedUserTicketId);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        this.teamUserTickets = [];
      } finally {
        this.teamUserTicketsLoading = false;
      }
    },

    async getStaffOptions() {
      try {
        const res = await this.$api.staff.getAll();
        this.staffOptions = _.sortBy(
          res.data.staffs.map(staff => ({
            id: _.get(staff, 'id'),
            name: _.get(staff, 'profile.name'),
            image: _.get(staff, 'avatars[0].image'),
          })),
          'name',
        );
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    async getRoomOptions() {
      try {
        const res = await this.$api.studio.rooms.get();
        this.roomOptions = res.data;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    handleClickUserTicket(userTicket) {
      if (!this.selectedUserTicket) {
        this.selectedUserTicket = { ...userTicket };
        if (this.courseType === 'P' && this.maxTrainee > 1) {
          this.getTeamUserTickets();
        }
        const name = this.selectedUserTicket.member.name;
        this.privateData.title = this.maxTrainee === 1 ? `${name}님` : `${name} 외 ${this.maxTrainee - 1}명`;
      } else {
        this.selectedUserTicket = null;
        this.privateData.title = '수업명';
        this.selectedTeamUserTickets = [];
      }
    },

    handleClickTeamUserTicket(userTicket) {
      const isSelected = this.selectedTeamUserTicketIds.includes(userTicket.id);
      this.privateData.schedules = []; // 체크선택/해제 시 수업 일정 초기화

      if (!isSelected) {
        this.selectedTeamUserTickets.push(userTicket);
      } else {
        this.selectedTeamUserTickets = this.selectedTeamUserTickets.filter(({ id }) => id !== userTicket.id);
      }
    },

    async handleClickSave() {
      let loadingInstance = this.$loading({
        fullscreen: true,
        text: '입력 정보 확인 중...',
      });
      if (!(await this.validate())) {
        return loadingInstance.close();
      }

      try {
        loadingInstance.text = '수업 예약중...';

        let res;
        if (this.courseType === 'P') {
          const data = this.mapPrivateLessonData();
          res = await this.$api.lecture.create(data);
          this.totalTry = this.privateData.schedules.length;
        } else if (this.courseType === 'G') {
          const data = {
            lecture_ids: this.selectedLectures.map(({ id }) => id),
            user_ticket_ids: [this.selectedUserTicketId],
          };
          res = await this.$api.booking.createBulk(data);
          this.totalTry = this.selectedLectures.length;
        }

        this.resultData = res.data;
        this.showResultDialog = true;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        loadingInstance.close();
      }
    },

    async validate() {
      /** 공통 */
      if (!this.selectedUserTicket) {
        this.$utils.notify.error(this, '오류', '수강권을 선택해 주세요');
        return false;
      }

      /** 프라이빗 */
      if (this.courseType === 'P') {
        if (!this.privateData.instructorId) {
          this.$utils.notify.error(this, '오류', '강사를 선택해 주세요');
          this.scrollToRef('privateForm.$refs.instructorId');
          return false;
        } else if (!this.privateData.schedules.length) {
          this.$utils.notify.error(this, '오류', '일정을 추가해 주세요');
          this.scrollToRef('privateForm.$refs.calendar');
          return false;
        } else if (this.useRooms && !this.privateData.roomId) {
          const title = '룸 설정';
          const message = '룸이 설정되지 않았습니다.<br>계속 하시겠습니까?';

          return await this.$confirm(message, title, {
            dangerouslyUseHTMLString: true,
          })
            .then(() => true)
            .catch(() => false);
        }

        /** 그룹 */
      } else if (this.courseType === 'G') {
        if (!this.selectedLectures.length) {
          this.$utils.notify.error(this, '오류', '예약할 수업을 선택해 주세요');
          this.scrollToRef('lectureList');
          return false;
        }

        const result = await this.BookingLimitExceeded();
        if (result?.message) {
          return await this.$confirm(result.message, '예약 가능 횟수 초과', {
            dangerouslyUseHTMLString: true,
          })
            .then(() => true)
            .catch(() => false);
        }
      }
      return true;
    },

    mapPrivateLessonData() {
      return {
        start_date: this.selectedDates[0],
        end_date: this.selectedDates[this.selectedDates.length - 1],
        max_trainee: this.maxTrainee,
        min_trainee: _.get(this.selectedUserTicket, 'ticket.min_trainee'),
        type: 'P',

        instructor_id: this.privateData.instructorId,
        room_id: this.privateData.roomId,
        title: this.privateData.title || this.defaultLectureTitle,
        date_times: this.privateData.schedules.map(({ date, start, end }) => {
          return { date, start_time: start, end_time: end };
        }),
        tickets: [this.selectedUserTicketId, ...this.selectedTeamUserTicketIds],
      };
    },

    handleCloseResultDialog(redirectToUserList = false, redirectToUserBookings = false, resetForm = false) {
      if (redirectToUserList) {
        return this.$router.push('/users');
      }

      if (redirectToUserBookings) {
        return this.$router.push({
          path: '/users/detail',
          query: {
            id: this.userId,
            userTicketId: this.selectedUserTicketId,
            tab: 'history',
          },
        });
      }

      if (resetForm) return this.init();

      this.showResultDialog = false;
    },

    /** 주간/월간 예약 횟수 체크 */
    async BookingLimitExceeded() {
      try {
        const lectureIds = this.selectedLectures.map(lecture => `${lecture.id};`).join('');
        const params = {
          lecture_ids: lectureIds,
          user_ticket_id: this.selectedUserTicket.id,
        };
        const res = await this.$api.booking.checkBookingCount(params);
        if (res.data.message === 'success') return;
      } catch (error) {
        const data = error.response.data;

        const label = {
          day: '일일 예약 가능 횟수',
          week: '주간 최대 예약 가능 횟수',
          month: '월간 최대 예약 가능 횟수',
        };

        const currentType = ['daily', 'weekly', 'monthly']
          .map(type => !!data[type] && type)
          .filter(type => {
            /** 패밀리 수강권의 경우 주간/월간 횟수만 제한 */
            if (this.selectedUserTicket.is_shared) return type === 'weekly' ? 'week' : type === 'monthly' && 'month';
            return type !== false;
          });
        const resultType = currentType.map(type => {
          switch (type) {
            case 'daily':
              return 'day';
            case 'weekly':
              return 'week';
            case 'monthly':
              return 'month';
          }
        });

        let message = resultType.reduce((textArray, key) => [...textArray, `${label[key]}를 초과했습니다.`], []).join('<br>');
        message += '<br>계속 하시겠습니까?';

        return {
          message,
        };
      }
    },

    scrollToRef(ref) {
      this.$nextTick(() => {
        const el = _.get(this.$refs, `${ref}.$el`);
        if (!el) return;
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-bookings {
  width: 100%;
  max-width: 1240px;
  margin: auto;
  padding: 10px 10px 60px;

  @include mq(768px) {
    padding: 30px 30px 60px;
  }

  &__select-ticket-container {
    @include flex(row, flex-start, flex-start);
    flex-wrap: wrap;
    min-height: 240px;
  }

  &__select-ticket {
    margin-right: 32px;
    margin-bottom: 32px;
  }

  &__select-team {
    @include flex(column, flex-start, flex-start);

    h5 {
      margin-left: 8px;
      margin-bottom: 8px;
    }

    ul {
      border: 1px solid $gray-300;
      border-radius: 4px;
      min-width: 380px;
      max-height: 200px;
      overflow: auto;
    }
  }
}
</style>
