<template>
  <section class="settings-rooms">
    <form-block :index="1" label="룸 사용" required>
      <el-checkbox v-model="useRooms" slot="checkbox" @change="handleChangeUseRooms">
        사용함
      </el-checkbox>
      <p class="settings-rooms__form-block__description">
        룸 기능을 사용하려면 설정해 주세요.
      </p>
    </form-block>

    <form-block :index="2" label="룸 추가" required v-if="useRooms">
      <div class="settings-rooms__add-rooms">
        <button v-if="!roomCreating" class="settings-rooms__add-rooms__add-button" @click="handleClickAddRoom">
          + 새로운 룸 추가
        </button>
        <div v-else class="settings-rooms__add-rooms__add-form">
          <input
            v-model="roomCreating.name"
            ref="new-room-name"
            type="text"
            placeholder="룸 이름을 입력하세요."
            @keypress.enter="handleClickAddSave(roomCreating)"
          />
          <PlainButton @click="handleClickAddCancel()">취소</PlainButton>
          <BaseButton :disabled="!roomCreating.name" @click="handleClickAddSave(roomCreating)">저장</BaseButton>
        </div>
        <ul class="settings-rooms__add-rooms__list">
          <li v-for="room in rooms" :key="room.id" class="settings-rooms__add-rooms__list__item">
            <input
              v-if="isRoomEditing(room)"
              v-model="roomEditing.name"
              ref="edit-room-name"
              type="text"
              placeholder="룸 이름을 입력하세요."
              @keypress.enter="handleClickEditSave(roomEditing)"
            />
            <p v-else>{{ room.name }}</p>
            <PlainButton
              size="small"
              @click="isRoomEditing(room) ? handleClickEditSave(roomEditing) : handleClickEditRoom(room)"
              >{{ isRoomEditing(room) ? '저장' : '수정' }}</PlainButton
            >
            <PlainButton
              size="small"
              type="danger"
              @click="isRoomEditing(room) ? handleClickEditCancel() : handleClickDeleteRoom(room)"
              >{{ isRoomEditing(room) ? '취소' : '삭제' }}</PlainButton
            >
          </li>
        </ul>
      </div>
    </form-block>
  </section>
</template>

<script>
export default {
  data() {
    return {
      useRooms: false,
      rooms: [],
      roomCreating: null,
      roomEditing: null,
    };
  },

  watch: {
    studioPolicies: {
      handler() {
        this.useRooms = !!_.get(this.studioPolicies, 'is_use_rooms');
      },
      immediate: true,
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.canUpdateRoomsSettings) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/settings');
      }

      next();
    });
  },

  created() {
    this.getRooms();
  },

  methods: {
    async getRooms() {
      try {
        const res = await this.$api.studio.rooms.get();
        this.rooms = res.data;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    async handleChangeUseRooms() {
      await this.$store.dispatch('studio/updateStudio', {
        policy: { ...this.studioPolicies, is_use_rooms: this.useRooms },
      });
      this.$store.dispatch('studio/getStudio');
    },

    handleClickAddRoom() {
      this.roomCreating = { name: '' };
      this.$nextTick(() => {
        this.$refs['new-room-name'].focus();
      });
    },

    async handleClickAddSave(room) {
      try {
        await this.$api.studio.rooms.create(room);
        this.getRooms();
        this.roomCreating = null;
      } catch (error) {
        this.$utils.notify.error(this, '오류', '중복된 이름입니다.');
      }
    },

    handleClickAddCancel() {
      this.roomCreating = null;
    },

    handleClickEditRoom(room) {
      this.roomEditing = { ...room };
      this.$nextTick(() => {
        this.$refs['edit-room-name'][0].select();
      });
    },

    async handleClickEditSave(room) {
      try {
        await this.$api.studio.rooms.update(room);
        this.getRooms();
        this.roomEditing = null;
      } catch (error) {
        this.$utils.notify.error(this, '오류', '중복된 이름입니다.');
      }
    },

    handleClickEditCancel() {
      this.roomEditing = null;
    },

    async handleClickDeleteRoom(room) {
      try {
        const title = '룸 삭제';
        const message = !room.lectures_count
          ? '룸을 삭제하시겠습니까?'
          : `${this.$filters.comma(room.lectures_count)}개의 수업에 룸으로 지정되어 있습니다.<br>
            삭제하시면 해당 수업의 룸 지정은 해제됩니다.<br>
            룸을 삭제하시겠습니까?`;

        const proceed = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (proceed) {
          await this.$api.studio.rooms.delete(room.id);
          this.$utils.notify.success(this, '확인', `<b>${room.name}</b> 룸이 삭제 되었습니다.`);
          this.getRooms();
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    isRoomEditing(room) {
      return this.roomEditing && this.roomEditing.id === room.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-rooms {
  &__form-block {
    &__description {
      font-size: 12px;
      font-weight: 500;
      line-height: 2;
      letter-spacing: normal;
      color: $dodger-blue;
    }
  }

  &__add-rooms {
    max-width: 328px;

    &__add-button {
      font-size: 14px;
      font-weight: 500;
      color: $deep-sky-blue;
      border: solid 1px $deep-sky-blue;
      border-radius: 16px;
      margin: 0 16px;
      padding: 7px;
      width: calc(100% - 32px);
      transition: background 0.15s;

      &:hover {
        background: rgba($deep-sky-blue, 0.1);
      }
    }

    &__add-form {
      display: grid;
      grid-template-columns: 1fr 38px 38px;
      grid-gap: 8px;
      margin: 0 16px;

      input {
        border-radius: 2px;
        border: solid 1px rgba(#8a8a8a, 0.5);
        padding: 7px 4px;

        &:hover,
        &:focus {
          border-color: #8a8a8a;
        }

        &::placeholder {
          color: rgba(#8a8a8a, 0.5);
        }
      }

      button {
        padding: 4px;
      }
    }

    &__list {
      @include flex(column);
      margin-top: 20px;

      &__item {
        display: grid;
        grid-template-columns: 1fr 38px 38px;
        grid-template-rows: 32px;
        grid-gap: 8px;
        align-items: center;
        padding: 13px 16px;
        border-bottom: 1px solid rgba(#000, 0.08);

        input {
          border-radius: 2px;
          border: solid 1px rgba(#8a8a8a, 0.5);
          padding: 7px 4px;

          &:hover,
          &:focus {
            border-color: #8a8a8a;
          }

          &::placeholder {
            color: rgba(#8a8a8a, 0.5);
          }
        }

        p {
          @include ellipsis;
        }

        button {
          font-size: 12px;
          padding: 3.5px 6px;
        }
      }
    }
  }
}
</style>
