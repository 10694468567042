<template>
  <section class="select-product">
    <div class="select-product__header">
      <p>수강권을 선택해주세요.</p>

      <el-popover placement="left" trigger="click">
        <ul class="select-product__conditions">
          <li v-for="(condition, index) in conditions" :key="index">
            {{ condition }}
          </li>
        </ul>
        <PlainButton slot="reference" round>
          업그레이드 가능 조건 보기
        </PlainButton>
      </el-popover>
    </div>

    <div class="select-product__search">
      <TextInput v-model="search" placeholder="수강권명 검색" prefix-icon="el-icon-search" round />
    </div>

    <div v-loading="loading" class="select-product__list">
      <p v-if="!loading && !filteredTickets.length" class="select-product__list__no-data">
        변경 가능한 수강권이 없습니다.
      </p>

      <TicketCard
        v-for="ticket in filteredTickets"
        :key="ticket.id"
        :ticket="ticket"
        @click="$emit('select', ticket.id)"
        clickable
      />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
    tickets: { type: Array, default: () => [] },
  },

  data() {
    return {
      search: '',

      /** 업그레이드 가능 조건 */
      conditions: [
        '같은 수업 형태 (프라이빗/그룹)',
        '같은 수강권 형태 (횟수제/기간제/차감제)',
        '같은 최대 수강인원 또는 같은 수업 구분',
        '같거나 큰 횟수 정보 (전체 횟수, 기간)',
      ],
    };
  },

  computed: {
    filteredTickets() {
      if (!this.search) return this.tickets;
      return this.tickets.filter(ticket => {
        return ticket.title.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-product {
  &__header {
    @include flex(row, center, space-between);
    padding: 0 16px;

    p {
      opacity: 0.6;
      text-align: center;
    }
  }

  &__search {
    padding: 16px;
  }

  &__list {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    grid-template-rows: repeat(auto-fill, 144px);
    grid-gap: 16px;
    height: 420px;
    overflow: auto;
    padding: 16px;

    &__no-data {
      grid-column: span 2;
    }
  }

  &__conditions {
    padding: 16px;

    li {
      list-style: disc;
      margin-left: 16px;
    }

    li + li {
      margin-top: 8px;
    }
  }
}
</style>
