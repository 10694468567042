import api from '@api';
import utils from '@utils';
import { BOOKING_STATUS_ARRAY } from '@constants';

const DEFAULT_BOOKING_HISTORY_PARAMS = {
  status: [...BOOKING_STATUS_ARRAY.BOOKED],
  page: 1,
  per_page: 10,
  userTicket: null,
  orderBy: null,
};

export const state = {
  member: {},
  userTickets: [],
  userTicketsLoading: false,
  bookedLectures: [],
  bookedLecturesLoading: false,
  memos: [],
  memosLoading: false,
  bookingHistory: [],
  bookingHistoryParams: {
    ...DEFAULT_BOOKING_HISTORY_PARAMS,
  },
  bookingHistoryLoading: false,
  bookingHistoryCount: {},
  bookingHistoryTotal: 0,
};

export const getters = {
  member: state => state.member,
  userTickets: state => state.userTickets,
  userTicketsLoading: state => state.userTicketsLoading,
  userTicketsActive: state => {
    return state.userTickets.filter(ticket => {
      if ((ticket.ticket.available_class_type !== 'I')) {
        return !['사용불가', '양도', '이용만료', '환불'].includes(utils.getTicketStatus(ticket)); 
      }
    });
  },
  userTicketsInactive: state => {
    return state.userTickets.filter(ticket => {
      if ((ticket.ticket.available_class_type !== 'I')) {
        return ['사용불가', '양도', '이용만료', '환불'].includes(utils.getTicketStatus(ticket)); 
      }
    });
  },
  userProductsActive: state => {
    return state.userTickets.filter(ticket => {
      if (ticket.ticket.available_class_type === 'I') {
        return !['사용불가', '양도', '이용만료', '환불', '판매'].includes(utils.getTicketStatus(ticket)); 
      }
    });
  },
  userProductsInactive: state => {
    return state.userTickets.filter(ticket => {
      if (ticket.ticket.available_class_type === 'I') {
        return ['사용불가', '양도', '이용만료', '환불', '판매'].includes(utils.getTicketStatus(ticket)); 
      }
    });
  },
  bookedLectures: state => state.bookedLectures,
  bookedLecturesLoading: state => state.bookedLecturesLoading,
  memos: state => state.memos,
  memosLoading: state => state.memosLoading,
  bookingHistory: state => state.bookingHistory,
  bookingHistoryParams: state => state.bookingHistoryParams,
  bookingHistoryLoading: state => state.bookingHistoryLoading,
  bookingHistoryCount: state => state.bookingHistoryCount,
  bookingHistoryTotal: state => state.bookingHistoryTotal,
};

export const mutations = {
  SET_MEMBER(state, member) {
    state.member = member;
  },

  SET_USER_TICKETS(state, userTickets) {
    state.userTickets = userTickets;
  },

  SET_USER_TICKETS_LOADING(state, loading) {
    state.userTicketsLoading = loading;
  },

  SET_BOOKED_LECTURES(state, lectures) {
    state.bookedLectures = lectures;
  },

  SET_BOOKED_LECTURES_LOADING(state, loading) {
    state.bookedLecturesLoading = loading;
  },

  SET_MEMOS(state, memos) {
    state.memos = memos;
  },

  SET_MEMOS_LOADING(state, loading) {
    state.memosLoading = loading;
  },

  SET_BOOKING_HISTORY(state, { data, total, count }) {
    state.bookingHistory = data;
    state.bookingHistoryCount = count;
    state.bookingHistoryTotal = total;
  },

  SET_BOOKING_HISTORY_PARAMS(state, params) {
    state.bookingHistoryParams = {
      ...state.bookingHistoryParams,
      ...params,
    };
  },

  RESET_BOOKING_HISTORY_PARAMS(state) {
    state.bookingHistoryParams = {
      ...DEFAULT_BOOKING_HISTORY_PARAMS,
    };
  },

  SET_BOOKING_HISTORY_LOADING(state, loading) {
    state.bookingHistoryLoading = loading;
  },
};

export const actions = {
  async getMember({ commit }, memberId) {
    try {
      const res = await api.member.get(memberId);
      commit('SET_MEMBER', res.data.member);
      return res.data.member.id;
    } catch (error) {
      commit('SET_MEMBER', {});
    }
  },

  async getUserTickets({ commit }, memberId) {
    try {
      commit('SET_USER_TICKETS_LOADING', true);
      const params = {
        member_id: memberId,
        is_all: true,
      };
      const res = await api.userTicket.getAll(params);
      commit('SET_USER_TICKETS', res.data);
    } catch (error) {
      commit('SET_USER_TICKETS', []);
    } finally {
      commit('SET_USER_TICKETS_LOADING', false);
    }
  },

  async getBookedLectures({ commit }, memberId) {
    try {
      commit('SET_BOOKED_LECTURES_LOADING', true);
      const res = await api.member.getLectures(memberId);
      commit('SET_BOOKED_LECTURES', res.data);
    } catch (error) {
      commit('SET_BOOKED_LECTURES', []);
    } finally {
      commit('SET_BOOKED_LECTURES_LOADING', false);
    }
  },

  async getMemos({ commit }, memberId) {
    try {
      commit('SET_MEMOS_LOADING', true);
      const res = await api.memo.getAll('member', memberId);
      commit('SET_MEMOS', res.data);
    } catch (error) {
      commit('SET_MEMOS', []);
    } finally {
      commit('SET_MEMOS_LOADING', false);
    }
  },

  async getBookingHistory({ state, commit }, memberId) {
    try {
      commit('SET_BOOKING_HISTORY_LOADING', true);
      const params = {
        ...state.bookingHistoryParams,
      };
      const res = await api.member.getBookings(memberId, params);
      const { data, total } = res.data.bookings;
      commit('SET_BOOKING_HISTORY', {
        data,
        total,
        count: res.data.kind,
      });
    } catch (error) {
      commit('SET_BOOKING_HISTORY', {
        data: [],
        total: 0,
        count: {},
      });
    } finally {
      commit('SET_BOOKING_HISTORY_LOADING', false);
    }
  },
};
