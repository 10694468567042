/**
 * 브라우저 확인
 * @return {Number} is_mobile
 * @return {Number} is_chrome
 */
import changeTypeStringToDayNumber from './changeTypeStringToDayNumber';

export default (
  deadlines,
  privateBookingRuleType,
  groupBookingRuleType,
  privateCancelRuleType,
  groupCancelRuleType,
) => {
  const privateBookingStart =
    (deadlines.private_booking_start_days + 1) * 2400 -
    changeTypeStringToDayNumber(deadlines.private_booking_start_time);
  const privateBookingEnd =
    (deadlines.private_booking_end_days + 1) * 2400 - changeTypeStringToDayNumber(deadlines.private_booking_end_time);
  const groupBookingStart =
    (deadlines.group_booking_start_days + 1) * 2400 - changeTypeStringToDayNumber(deadlines.group_booking_start_time);
  const groupBookingEnd =
    (deadlines.group_booking_end_days + 1) * 2400 - changeTypeStringToDayNumber(deadlines.group_booking_end_time);
  const privateBookingCancelStart =
    (deadlines.private_booking_cancel_start_days + 1) * 2400 -
    changeTypeStringToDayNumber(deadlines.private_booking_cancel_start_time);
  const privateBookingCancelEnd =
    (deadlines.private_booking_cancel_end_days + 1) * 2400 -
    changeTypeStringToDayNumber(deadlines.private_booking_cancel_end_time);
  const groupBookingCancelStart =
    (deadlines.group_booking_cancel_start_days + 1) * 2400 -
    changeTypeStringToDayNumber(deadlines.group_booking_cancel_start_time);
  const groupBookingCancelEnd =
    (deadlines.group_booking_cancel_end_days + 1) * 2400 -
    changeTypeStringToDayNumber(deadlines.group_booking_cancel_end_time);

  if (
    (privateBookingRuleType === 'RR' && deadlines.private_booking_startline <= deadlines.private_booking_deadline) ||
    (privateBookingRuleType === 'AR' && privateBookingStart <= privateBookingEnd)
  ) {
    return 'privateBookingTimeError';
  }
  if (
    (groupBookingRuleType === 'RR' && deadlines.group_booking_startline <= deadlines.group_booking_deadline) ||
    (groupBookingRuleType === 'AR' && groupBookingStart <= groupBookingEnd)
  ) {
    return 'groupBookingTimeError';
  }
  if (
    (privateCancelRuleType === 'RR' &&
      deadlines.private_booking_cancel_startline <= deadlines.private_booking_cancel_deadline) ||
    (privateCancelRuleType === 'AR' && privateBookingCancelStart <= privateBookingCancelEnd)
  ) {
    return 'privateCancelTimeError';
  }
  if (
    (groupCancelRuleType === 'RR' &&
      deadlines.group_booking_cancel_startline <= deadlines.group_booking_cancel_deadline) ||
    (groupCancelRuleType === 'AR' && groupBookingCancelStart <= groupBookingCancelEnd)
  ) {
    return 'groupCancelTimeError';
  }

  return 0;
};
