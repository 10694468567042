<template>
  <section class="payment-detail">
    <div class="payment-detail__ticket">
      <UserTicketCard :userTicket="userTicket" />
    </div>

    <div class="payment-detail__compare">
      <div class="payment-detail__compare__column">
        <h5>기존 결제 정보</h5>
        <PointInput
          class="payment-detail__price-input blur"
          label="포인트 적립"
          labelAlign="row"
          suffix="P"
          v-model="currentPaymentDetail.reward_point"
          readonly
        />
        <PriceInput
          class="payment-detail__price-input blur"
          label="판매가"
          labelAlign="row"
          :value="currentPaymentDetail.actual_price"
          readonly
        />
        <h5>결제 금액</h5>
        <PriceInput
          class="payment-detail__price-input blur"
          label="카드"
          labelAlign="row"
          v-model="currentPaymentDetail.card_amount"
          readonly
        />
        <PriceInput
          class="payment-detail__price-input blur"
          label="현금"
          labelAlign="row"
          v-model="currentPaymentDetail.cash_amount"
          readonly
        />
        <PriceInput
          class="payment-detail__price-input blur"
          label="계좌이체"
          labelAlign="row"
          v-model="currentPaymentDetail.wiretransfer_amount"
          readonly
        />
        <PointInput label="포인트" labelAlign="row" suffix="P" :value="pointAmount" readonly />
        <PriceInput
          class="payment-detail__price-input total blur second"
          label="총 결제 금액"
          labelAlign="row"
          :value="currentPaymentDetail.amount"
          readonly
        />
        <PriceInput
          class="payment-detail__price-input unpaid blur second"
          label="미수금"
          labelAlign="row"
          v-model="currentPaymentDetail.unpaid_amount"
          readonly
        />
        <div class="payment-detail__payed-on second">
          <label>결제일</label>
          <el-date-picker
            v-model="currentPaymentDetail.settlement_at"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :clearable="false"
            required
            readonly
          />
        </div>
      </div>

      <div class="payment-detail__compare__column">
        <h5>환불 정보</h5>
        <div class="payment-detail__price-input point-input">
          <PointInput
            label="포인트 회수"
            labelAlign="row"
            suffix="P"
            v-model="inputPointAmount"
            :maxPoint="currentPaymentDetail.reward_point"
          />
          <p class="payment-detail__price-input__bottom">
            <span class="primary" @click="handleUseAllPoint">잔여 포인트</span>
            {{ formatCurrentPoint }} P
          </p>
        </div>
        <div></div>
        <h5>환불 금액</h5>
        <PriceInputWithAction
          class="payment-detail__price-input"
          label="카드"
          labelAlign="row"
          v-model="cardAmount"
          labelTooltip="전액카드"
          @click-label="handleClickFullPayment('card')"
        />
        <PriceInputWithAction
          class="payment-detail__price-input"
          label="현금"
          labelAlign="row"
          v-model="cashAmount"
          labelTooltip="전액현금"
          @click-label="handleClickFullPayment('cash')"
        />
        <PriceInputWithAction
          class="payment-detail__price-input"
          label="계좌이체"
          labelAlign="row"
          v-model="wireTransferAmount"
          labelTooltip="전액계좌이체"
          @click-label="handleClickFullPayment('wiretransfer')"
        />
        <!-- 회원에게 돌려주는 포인트 -->
        <PointInput label="포인트" labelAlign="row" suffix="P" v-model="inputRewardPoint" />
        <PriceInput
          class="payment-detail__price-input unpaid second"
          label="총 환불 금액"
          labelAlign="row"
          :value="refundTotal"
          readonly
        />
        <PriceInput
          class="payment-detail__price-input unpaid second"
          label="위약금"
          labelAlign="row"
          v-model="value.unpaid_amount"
        />
        <div class="payment-detail__payed-on second">
          <label>환불일</label>
          <el-date-picker
            v-model="refundOn"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :clearable="false"
            :picker-options="refundDatePickerOptions"
            required
          />
        </div>
      </div>
    </div>

    <div class="payment-detail__buttons">
      <el-button @click="$emit('cancel')">취소</el-button>
      <el-button :loading="saving" @click="handleClickSave" type="primary">확인</el-button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
    userTicket: { type: Object, required: true },
    saving: { type: Boolean, default: false },
  },

  computed: {
    payments() {
      return _.get(this.userTicket, 'payments', []);
    },

    isTransfered() {
      return this.payments[0].status === 'transfer';
    },

    beforeUserTicketAmount() {
      return this.payments[0].before_user_ticket_amount;
    },

    initialPayment() {
      return this.payments[0];
    },

    pointAmount() {
      return this.payments[this.payments.length - 1].point_amount;
    },

    currentPaymentDetail() {
      let data = {
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        amount: 0,
        unpaid_amount: 0,
        reward_point: 0,
        point_amount: 0,
      };

      if (this.userTicket) {
        data = this.payments.reduce((total, payment) => {
          Object.keys(data).forEach(key => (total[key] += payment[key]));
          return total;
        }, data);
      }

      const lastPayment = this.payments[this.payments.length - 1];

      // 기존 결제 정보 수강권 판매가는 최초 발급시 결제한 금액
      const actual_price = !this.isTransfered
        ? this.initialPayment.amount + this.initialPayment.unpaid_amount
        : this.beforeUserTicketAmount;

      return {
        ...data,
        ..._.pick(lastPayment, ['installment_period', 'settlement_at']),
        actual_price,
      };
    },

    cardAmount: {
      get() {
        return this.value.card_amount;
      },
      set(card_amount) {
        if (card_amount <= 0) card_amount = 0;
        const { cash_amount, wiretransfer_amount } = this.value;
        this.handleChangePayment({
          amount: card_amount + cash_amount + wiretransfer_amount,
          card_amount,
        });
      },
    },

    cashAmount: {
      get() {
        return this.value.cash_amount;
      },
      set(cash_amount) {
        if (cash_amount <= 0) cash_amount = 0;
        const { card_amount, wiretransfer_amount, reward_point } = this.value;
        this.handleChangePayment({
          amount: card_amount + cash_amount + wiretransfer_amount + reward_point,
          cash_amount,
        });
      },
    },

    wireTransferAmount: {
      get() {
        return this.value.wiretransfer_amount;
      },
      set(wiretransfer_amount) {
        if (wiretransfer_amount <= 0) wiretransfer_amount = 0;
        const { card_amount, cash_amount, reward_point } = this.value;
        this.handleChangePayment({
          amount: card_amount + cash_amount + wiretransfer_amount + reward_point,
          wiretransfer_amount,
        });
      },
    },

    inputRewardPoint: {
      get() {
        return this.value.reward_point || 0;
      },
      set(reward_point) {
        const rewardPoint = reward_point || rewardPoint <= 0 || 0;
        const { card_amount, cash_amount, wiretransfer_amount } = this.value;
        this.handleChangePayment({
          amount: card_amount + cash_amount + wiretransfer_amount + rewardPoint,
          reward_point: rewardPoint,
        });
      },
    },

    inputPointAmount: {
      get() {
        return this.value.point_amount || 0;
      },
      set(point_amount) {
        const pointAmount = point_amount || (point_amount <= 0 && 0);
        const { card_amount, cash_amount, wiretransfer_amount, reward_point } = this.value;
        this.handleChangePayment({
          amount: card_amount + cash_amount + wiretransfer_amount + reward_point,
          point_amount: pointAmount,
        });
      },
    },

    refundOn: {
      get() {
        const { settlement_at } = this.value;
        return this.moment(settlement_at).format('YYYY-MM-DD');
      },
      set(value) {
        if (!value) return;
        this.handleChangePayment({
          settlement_at: `${value} ${this.refundAt}:00`,
        });
      },
    },

    refundDatePickerOptions() {
      const { moment, currentPaymentDetail } = this;

      return {
        disabledDate(date) {
          date = moment(date).format('YYYY-MM-DD');
          return date < currentPaymentDetail.settlement_at;
        },
      };
    },

    refundAt: {
      get() {
        const { settlement_at } = this.value;
        return this.moment(settlement_at).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        this.handleChangePayment({
          settlement_at: `${this.refundOn} ${value}:00`,
        });
      },
    },

    refundTotal() {
      const { card_amount, cash_amount, wiretransfer_amount, reward_point } = this.value;
      return card_amount + cash_amount + wiretransfer_amount + reward_point;
    },

    currentPoint() {
      const pointDetail = this.$store.getters['pointHistory/pointDetail'];
      return _.get(pointDetail, 'current_point');
    },

    formatCurrentPoint() {
      return this.$filters.comma(this.currentPoint);
    },
  },

  created() {
    const { reward_point, point_amount } = this.currentPaymentDetail;
    const baseDetail = _.pick(this.currentPaymentDetail, ['card_amount', 'cash_amount', 'wiretransfer_amount', 'amount']);
    /** reward_point(회수)와 point_amount(환불 포인트), 초기값으로는 서로 반대로 자동 입력 적용 */
    const pointDetail = { reward_point: point_amount, point_amount: reward_point };
    this.handleChangePayment({ ...baseDetail, ...pointDetail });
  },

  methods: {
    handleClickFullPayment(method) {
      let updated = {
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
      };
      updated[`${method}_amount`] = this.currentPaymentDetail.amount;
      this.handleChangePayment(updated);
    },

    handleChangePayment(value) {
      this.$emit('input', { ...this.value, ...value });
    },

    handleClickSave() {
      const currentValues = { ...this.value };
      const data = this.$utils.mapPaymentData(currentValues);

      const pointValidate = this.$utils.paymentValidation('ticket', this.value.point_amount, this.currentPoint, this.refundTotal);
      if (pointValidate.error) {
        if (pointValidate.message.includes('초과') || this.inputPointAmount - this.inputRewardPoint > this.currentPoint) {
          this.$utils.notify.error(this, '오류', pointValidate.message);
          return;
        }
      }

      this.$emit('save', _.omit(data, 'installment_period'));
    },

    handleUseAllPoint() {
      if (!this.currentPoint) return;
      const updateValue = { point_amount: this.currentPaymentDetail.reward_point };
      this.$emit('input', { ...this.value, ...updateValue });
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-detail {
  &__ticket {
    @include flex(row, center, center);
  }

  &__compare {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 40px 12px;

    &__column {
      display: grid;
      grid-template-rows: repeat(11, 40px);
      grid-gap: 8px;
      align-items: start;

      .second,
      h5 {
        align-self: end;
      }

      &.current {
        padding-top: 32px;
      }

      &:first-child {
        border-right: 1px solid rgba(#000, 0.5);
        padding-right: 42px;
      }
      &:last-child {
        padding-left: 42px;
      }

      &__button {
        margin: auto;
      }
    }
  }

  &__helper-buttons {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 8px;

    button {
      font-size: 12px;
    }
  }

  .point-input {
    position: relative;
  }

  &__price-input {
    /deep/ .el-input__inner {
      color: $charcoal-grey !important;
    }

    &.total {
      /deep/ .el-input__inner {
        color: $dodger-blue !important;
      }
    }

    &.unpaid {
      /deep/ .el-input__inner {
        color: $color-danger !important;
      }
    }

    &.blur {
      /deep/ .el-input__inner {
        opacity: 0.5;
      }
    }

    &:last-child {
      margin: 0;
    }

    &__bottom {
      position: absolute;
      bottom: -20px;
      right: 6px;
      font-size: 12px;
      color: #606266;

      .primary:hover {
        cursor: pointer;
        font-weight: 500;
        color: $color-primary;
      }
    }
  }

  &__installment {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 8px;
    align-items: center;

    /deep/ .el-radio__label {
      padding-left: 4px;
    }

    /deep/ .el-radio + .el-radio {
      margin-left: 8px;
    }
  }

  &__payed-on {
    display: grid;
    grid-template-columns: 44px 1fr;
    grid-gap: 16px;
    align-items: center;

    /deep/ .el-date-editor {
      width: 100%;
    }

    /deep/ .el-input__inner {
      padding: 0 30px;
      text-align: right;
    }

    label {
      font-size: 12px;
      font-weight: bold;
    }
  }

  &__buttons {
    @include flex(row, flex-end, space-between);
    border-top: 1px solid #f0f0f0;
    flex: 1;
    width: 100%;
    padding: 20px 20px 0;
  }
}
</style>
