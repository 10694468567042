<template>
  <div class="lecture-list">
    <div class="lecture-list__header">
      <ListFilter
        class="lecture-list__header__filter"
        :filterValues="filter"
        :filterOptions="filterOptions"
        :availablePeriod="availablePeriod"
        @change="handleFilterChange"
        @reset-click="resetFilter"
      />

      <span v-if="!!weeklyBookingLimit" class="lecture-list__header__limit"
        >최대 주 {{ weeklyBookingLimit | comma }}회 예약 가능</span
      >
      <span v-if="!!monthlyBookingLimit" class="lecture-list__header__limit"
        >최대 월 {{ monthlyBookingLimit | comma }}회 예약 가능</span
      >
    </div>

    <div class="lecture-list__list" ref="lectureList">
      <div class="lecture-list__list__column">
        <div class="lecture-list__list__column__header">
          <h4>예약 가능한 수업 ({{ filteredLectures.length | comma }}개)</h4>
        </div>
        <div class="lecture-list__list__all">
          <p v-if="loading" class="lecture-list__list__message">수업 목록을 가져오는 중...</p>
          <p v-else-if="!filteredLectures.length" class="lecture-list__list__message">예약 가능한 수업이 없습니다.</p>
          <ListItem
            v-else
            v-for="lecture in filteredLectures"
            :key="lecture.id"
            :lecture="lecture"
            :disabled="maxBookingCountReached"
            @click="handleClickLecture"
          />
        </div>
      </div>

      <div class="lecture-list__list__buttons">
        <el-tooltip effect="light" content="전체 선택" placement="left">
          <CircleButton
            class="lecture-list__list__buttons__select-all"
            @click="handleClickSelectAll"
            :disabled="!filteredLectures.length"
            bordered
          >
            <i class="el-icon-d-arrow-right"></i>
          </CircleButton>
        </el-tooltip>
        <el-tooltip effect="light" content="전체 해제" placement="right">
          <CircleButton
            class="lecture-list__list__buttons__remove-all"
            @click="handleClickRemoveAll"
            :disabled="!selectedLectures.length"
            bordered
          >
            <i class="el-icon-d-arrow-left"></i>
          </CircleButton>
        </el-tooltip>
      </div>

      <div class="lecture-list__list__column">
        <div class="lecture-list__list__column__header">
          <h4>선택된 수업 ({{ selectedLectures.length | comma }}개)</h4>
          <span>총 {{ usableCoupon | comma }}회 예약 가능</span>
        </div>
        <div class="lecture-list__list__selected">
          <p class="lecture-list__list__message" v-if="!!filteredLectures.length && !selectedLectures.length">
            예약 가능한 수업 목록에서 수업을 선택해 주세요.
          </p>
          <ListItem v-for="lecture in selectedLectures" :key="lecture.id" :lecture="lecture" @click="unselectLecture" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BOOKING_STATUS } from '@constants';
import ListFilter from './LectureListFilter';
import ListItem from './LectureListItem';

export default {
  components: { ListFilter, ListItem },

  props: {
    value: { type: Array, default: () => [] },
    userTicket: { type: Object, required: true },
    staffOptions: { type: Array, default: () => [] },
  },

  data() {
    return {
      filter: {
        dateRange: [
          this.moment().format('YYYY-MM-DD'),
          this.moment()
            .add(3, 'month')
            .format('YYYY-MM-DD'),
        ],
        timeRanges: { 월: [], 화: [], 수: [], 목: [], 금: [], 토: [], 일: [] },
        exactTimeRange: true,
        instructors: [],
      },

      loading: false,
      lectures: [],
      selectedLectures: [],
    };
  },

  computed: {
    availablePeriod() {
      const start = _.get(this.userTicket, 'availability_start_at');
      const end = _.get(this.userTicket, 'expire_at');
      const startDate = start ? this.moment(start).format('YYYY-MM-DD') : undefined;
      const endDate = end ? this.moment(end).format('YYYY-MM-DD') : undefined;

      return [startDate, endDate];
    },

    holdingDates() {
      const holdings = _.get(this.userTicket, 'holdings');
      let dates = [];
      holdings.forEach(holding => {
        const end = this.moment(holding.end_on);
        let date = this.moment(holding.start_on);
        for (date; date.format('YYYYMMDD') <= end.format('YYYYMMDD'); date.add(1, 'd')) {
          dates.push(date.format('YYYY-MM-DD'));
        }
      });
      return dates;
    },

    defaultDateRange() {
      const [userTicketStart, userTicketEnd] = this.availablePeriod;
      const today = this.moment().format('YYYY-MM-DD');
      return [userTicketStart < today ? today : userTicketStart, userTicketEnd];
    },

    weeklyBookingLimit() {
      return _.get(this.userTicket, 'ticket.booking_limit_per_week');
    },

    monthlyBookingLimit() {
      return _.get(this.userTicket, 'ticket.booking_limit_per_month');
    },

    usableCoupon() {
      return _.get(this.userTicket, 'usable_coupon');
    },

    maxBookingCountReached() {
      return this.usableCoupon <= this.selectedLectures.length;
    },

    selectedLectureIds() {
      return this.selectedLectures.map(({ id }) => id);
    },

    filteredLectures() {
      const userTicketId = this.userTicket.id;
      const isTicketShared = !!this.userTicket.is_shared;

      /**
       * 수업 목록에서 제외
       * 1. 선택된 수업
       * 2. 패밀리 수강권이 아니면서 이미 예약된 수업
       * 3. 정지기간 내의 수업
       */
      return this.lectures.filter(lecture => {
        const selected = this.selectedLectureIds.includes(lecture.id);
        const booked = lecture.bookings.some(({ userTicket, status }) => {
          const isValidBooking = userTicketId === userTicket.id && status !== BOOKING_STATUS.CANCEL;
          return !isTicketShared && isValidBooking;
        });

        const startOn = this.moment(lecture.start_on).format('YYYY-MM-DD');
        const isInHoldingPeriod = this.holdingDates.includes(startOn);

        return !selected && !booked && !isInHoldingPeriod;
      });
    },

    filterOptions() {
      const instructorOptions = this.staffOptions
        .filter(({ id }) => {
          return this.canUpdateOthersGroupLectureBookings || id === _.get(this.currentUser, 'id');
        })
        .map(({ id, name }) => ({ value: id, label: name }));

      return {
        instructors: {
          multiple: true,
          placeholder: '강사 전체',
          options: instructorOptions,
        },
      };
    },
  },

  watch: {
    selectedLectures(lectures) {
      this.$emit('input', lectures);
    },
  },

  async created() {
    this.resetFilter();
    await this.getLectures();
    this.$nextTick(() => {
      this.$refs.lectureList.scrollIntoView({ behavior: 'smooth' });
    });
  },

  methods: {
    async getLectures() {
      try {
        this.loading = true;
        const paramsList = this.mapParamsList(this.filter);
        const res = await Promise.all(
          paramsList.map(async params =>
            this.$api.lecture.getAll({
              ...params,
              with: 'bookings.userTicket.ticket.divisions;bookings.member.avatars;bookings.member;staff.avatars;staff',
            }),
          ),
        );
        const reduced = res.reduce((list, { data }) => [...list, ...data], []);
        this.lectures = _.sortBy(reduced, ['start_on', 'end_on', 'title']);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        this.lectures = [];
      } finally {
        this.loading = false;
      }
    },

    handleFilterChange(filterValues) {
      const filter = { ...this.filter, ...filterValues };
      this.filter = { ...filter };
      this.getLectures();
    },

    resetFilter() {
      this.filter = {
        dateRange: [...this.defaultDateRange],
        timeRanges: { 월: [], 화: [], 수: [], 목: [], 금: [], 토: [], 일: [] },
        instructors:
          !this.canUpdateOthersGroupLectureBookings && this.currentUser ? [_.get(this.currentUser, 'id')] : [],
      };
      this.getLectures();
    },

    handleClickLecture(lecture) {
      if (this.selectedLectureIds.includes(lecture.id)) {
        this.unselectLecture(lecture);
      } else if (!this.maxBookingCountReached) {
        const lectures = [...this.selectedLectures, lecture];
        this.selectedLectures = this.sortLectures(lectures);
      }
    },

    unselectLecture(lecture) {
      this.selectedLectures = this.selectedLectures.filter(({ id }) => id !== lecture.id);
    },

    handleClickSelectAll() {
      const excludeFull = this.filteredLectures.filter(
        ({ current_trainee_count, max_trainee }) => current_trainee_count < max_trainee,
      );

      const lectures = this.sortLectures([...this.selectedLectures, ...excludeFull]);
      this.selectedLectures = lectures.slice(0, this.usableCoupon);
    },

    handleClickRemoveAll() {
      this.selectedLectures = [];
    },

    sortLectures(lectures) {
      return _.sortBy(lectures, ['start_on', 'end_on']);
    },

    mapParamsList(filter) {
      const { dateRange, timeRanges, exactTimeRange, instructors } = filter;
      const [start_date, end_date] = dateRange;

      // 기간 & 페이징 & 정렬
      const baseParams = {
        start_date,
        end_date,
        sort_by_name: 'start_on',
        sort_by_type: 'asc',
        course_type: 'G',
      };
      // 강사
      if (instructors.length) baseParams.staff_id = instructors.join(',');
      // 수업 구분 || 수업 인원
      const divisions = _.get(this.userTicket, 'ticket.divisions');
      const maxTrainee = _.get(this.userTicket, 'ticket.max_trainee');
      if (divisions.length) {
        baseParams.division_id = divisions.map(({ id }) => id).join(',');
      } else {
        baseParams.max_trainee = maxTrainee;
      }
      let paramsList = [];

      const schedule = this.flattenTimeRanges(timeRanges);

      if (schedule.length) {
        paramsList = schedule.map(({ weekday, start, end }) => {
          const params = {
            ...baseParams,
            week_day: weekday,
          };
          if (exactTimeRange) {
            if (start) params.exact_start_time = `${start}:00`;
            if (end) params.exact_end_time = `${end}:00`;
          } else {
            if (start) params.start_time = `${start}:00`;
            if (end) params.end_time = `${end}:00`;
          }
          return params;
        });
      } else {
        paramsList.push(baseParams);
      }

      return paramsList;
    },

    flattenTimeRanges(timeRanges) {
      const weekdays = ['월', '화', '수', '목', '금', '토', '일'];

      let schedule = [];
      weekdays.forEach(weekday => {
        timeRanges[weekday].forEach(({ start, end }) => {
          const weekdayNumber = this.$utils.translate.weekdayText(weekday);
          const duplicate = schedule.find(item => {
            return item.weekday === weekdayNumber && item.start === start && item.end === end;
          });
          if (!duplicate) {
            schedule.push({ weekday: weekdayNumber, start, end });
          }
        });
      });
      return schedule;
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-list {
  &__header {
    @include flex(row, center, space-between);
    padding-top: 8px;

    &__filter {
      flex: 1;
    }

    &__limit {
      color: $color-danger;
    }
  }

  &__list {
    @include flex(column);
    flex-wrap: wrap;

    @include mq(1280px) {
      @include flex(row, center, space-between);
    }

    &__message {
      text-align: center;
      margin-top: 5vh;
      opacity: 0.75;
    }

    &__column {
      flex: 1;
      min-width: 420px;

      &__header {
        @include flex(row, center, space-between);
        padding: 8px 0 8px 8px;

        span {
          font-size: 16px;
        }
      }
    }

    &__all,
    &__selected {
      border: 1px solid $gray-300;
      border-radius: 4px;
      height: 65vh;
      overflow: auto;
    }

    &__buttons {
      font-size: 20px;

      @include flex(row, center, center);

      &__select-all,
      &__remove-all {
        padding: 0;

        i {
          transform: rotate(90deg);
        }
      }

      @include mq(1280px) {
        @include flex(column);

        &__select-all {
          padding: 2px 6px 0 8px;
        }

        &__remove-all {
          padding: 2px 8px 0 6px;
        }

        i {
          transform: rotate(0);
        }
      }

      button {
        margin: 8px;
      }
    }
  }
}
</style>
