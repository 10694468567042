import api from '@api';

const DEFAULT_FORM_DATA = {
    user_id: 0,
    parent_id: null,
    contents: null,
    is_secret: 0,
    updated_at: null,
    created_at: null,
    memosLoading: false,
  };
  
  export const state = {
    formData: { 
      ...DEFAULT_FORM_DATA
    },
  };

  export const getters = {
    formData: state => state.formData,
  };
  
  export const mutations = {
    SET_VALUES(state, values) {
      state.formData = !values ? { ...DEFAULT_FORM_DATA } : { ...state.formData, ...values };
    },
  };

  export const actions = {
    async getMemo({ commit }, detailContentId) {
      try {
        commit('SET_VALUES', { memosLoading: true });
        const res = await api.reply.getAll(detailContentId);
        commit('SET_VALUES', {
          memos: res.data.comments,
          memosLoading: false,
        });
      } catch (error) {
        commit('SET_VALUES', {
          memos: [],
          memosLoading: false,
        });
      }
    },
  }