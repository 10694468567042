export default payment => {
  const { card_amount, cash_amount, wiretransfer_amount, amount, transfer_amount, point_amount } = payment;

  if (!amount && !transfer_amount) return null;

  const onlyCard = !cash_amount && !wiretransfer_amount && !point_amount;
  const onlyCash = !card_amount && !point_amount && (cash_amount || wiretransfer_amount);
  const onlyPoint = !card_amount && !cash_amount && !wiretransfer_amount;

  if (onlyPoint) {
    return 'point';
  } else if (onlyCash) {
    return 'cash'; // 현금 or 계좌이체
  } else if (onlyCard) {
    return 'card';
  } else {
    return 'mixed';
  }
};
