<template>
  <form class="payment-form">
    <div v-if="paymentStatus" class="payment-form__element status">
      <el-tag>{{ paymentStatus }}</el-tag>
    </div>

    <div class="payment-form__element left" :class="{ 'is-refund': isRefund }">
      <PriceInput :label="referenceLabel" labelAlign="row" :value="referencePrice" readonly />
      <PointInput
        v-if="paymentStatus !== '미수금'"
        label="포인트 적립"
        labelAlign="row"
        suffix="P"
        v-model="value.reward_point"
        :readonly="isRefund || readonly"
        @input="handleInputAmount('reward_point')"
      />
      <PriceInput
        v-if="!isNotShow"
        label="할인율"
        labelAlign="row"
        v-model="discountRate"
        suffix="%"
        @input="handleInputDiscountRate"
        :readonly="readonly || this.paymentStatus === '양도'"
      />
      <PriceInput v-if="!isRefund" label="할인금액" labelAlign="row" :value="discountPriceValue" readonly />
      <PriceInput class="payment-form__element__total-amount" label="결제금액" labelAlign="row" :value="totalAmount" readonly />
      <PriceInput
        v-if="isRefund"
        class="payment-form__element__unpaid-amount"
        label="환불금액"
        labelAlign="row"
        :value="value.amount"
        readonly
      />
    </div>

    <div class="payment-form__element right" :class="{ 'is-refund': isRefund }">
      <PriceInputWithAction
        label="카드"
        labelAlign="row"
        v-model="value.card_amount"
        @input="handleInputAmount('card_amount')"
        :readonly="readonly"
        labelTooltip="전액카드"
        @click-label="handleClickFullPayment('card')"
      />
      <div v-if="!isRefund" class="payment-form__element__installment">
        <el-radio-group v-model="value.installment" :disabled="value.card_amount <= 0 || readonly">
          <el-radio :label="1">일시불</el-radio>
          <el-radio :label="0">할부</el-radio>
        </el-radio-group>
        <NumberInput
          v-model="value.installment_period"
          :disabled="value.installment === 1 || value.card_amount <= 0 || readonly"
          :min="2"
          :max="99"
          suffix="개월"
        />
      </div>
      <PriceInputWithAction
        label="현금"
        labelAlign="row"
        v-model="value.cash_amount"
        @input="handleInputAmount('cash_amount')"
        :readonly="readonly"
        labelTooltip="전액현금"
        @click-label="handleClickFullPayment('cash')"
      />
      <PriceInputWithAction
        label="계좌이체"
        labelAlign="row"
        v-model="value.wiretransfer_amount"
        @input="handleInputAmount('wiretransfer_amount')"
        :readonly="readonly"
        labelTooltip="전액계좌이체"
        @click-label="handleClickFullPayment('wiretransfer')"
      />
      <div class="payment-form__point-input">
        <PointInput
          ref="point_amount"
          label="포인트"
          labelAlign="row"
          suffix="P"
          v-model="value.point_amount"
          @input="handleInputAmount('point_amount')"
          :readonly="isRefund || readonly"
        />
        <p class="payment-form__point-input__bottom">
          <span class="primary" @click="handleInputAmount('point_amount', 'useAllPoint')">잔여 포인트</span>
          {{ formatCurrentPoint }} P
        </p>
      </div>
      <div />
      <PriceInput
        v-if="paymentStatus !== '미수금'"
        class="payment-form__element__unpaid-amount"
        :label="isRefund ? '위약금' : '미수금'"
        labelAlign="row"
        v-model="value.unpaid_amount"
        :readonly="readonly"
        @input="isRefund ? null : handleInputUnpaidAmount"
      />
    </div>

    <div class="payment-form__element settlement_at">
      <label>{{ !isRefund ? '결제일시' : '환불일시' }}</label>
      <div>
        <el-date-picker
          v-model="payedOn"
          type="date"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          :clearable="false"
          :readonly="readonly"
          required
        />
        <el-time-select
          v-model="payedAt"
          value-format="HH:mm"
          :disabled="!payedOn"
          :picker-options="{
            start: '00:00',
            end: '23:59',
            step: '00:01',
          }"
          :clearable="false"
          :readonly="readonly"
        />
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
    paymentStatus: { type: String, default: '' },
    referenceLabel: { type: String, default: '판매가' },
    referencePrice: { type: Number, default: 0 },
    readonly: { type: Boolean, default: false },
    pointAmount: { type: Number, default: 0 },
  },

  data() {
    return {
      discountRate: 0,
      discountedPrice: 0,
    };
  },

  computed: {
    policies() {
      return this.$store.getters['studio/policies'];
    },

    isNotShow() {
      return (
        this.value.status === 'refund' || this.value.status === 'installment_payment' || this.value.status === 'full_payment'
      );
    },

    payedOn: {
      get() {
        const { settlement_at } = this.value;
        return this.moment(settlement_at).format('YYYY-MM-DD');
      },
      set(value) {
        if (!value) return;
        this.value.settlement_at = `${value} ${this.payedAt}:00`;
      },
    },

    payedAt: {
      get() {
        const { settlement_at } = this.value;
        return this.moment(settlement_at).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        this.value.settlement_at = `${this.payedOn} ${value}:00`;
      },
    },

    discountPriceValue() {
      return this.paymentStatus === '미수금' || this.paymentStatus === '양도' ? 0 : this.referencePrice - this.discountedPrice;
    },

    formatCurrentPoint() {
      return this.$filters.comma(this.currentPoint);
    },

    currentPoint() {
      const pointDetail = this.$store.getters['pointHistory/pointDetail'];
      return _.get(pointDetail, 'current_point');
    },

    memberId() {
      return this.$route.query.id;
    },

    totalAmount() {
      if (this.isRefund) return this.value.prevTotal;
      const { card_amount, cash_amount, wiretransfer_amount, point_amount } = this.value;
      return card_amount + cash_amount + wiretransfer_amount + point_amount;
    },
  },

  watch: {
    value: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true,
    },
  },

  created() {
    this.handleInputUnpaidAmount();
    this.$store.dispatch('pointHistory/getPointDetail', this.memberId);
  },

  methods: {
    handleInputAmount(key, action) {
      // 음수입력 차단
      if (this.value[key] <= 0 || !this.value[key]) this.value[key] = 0;

      const isAction = action === 'useAllPoint';
      if (isAction) {
        this.value['point_amount'] = this.currentPoint;
      }

      const { card_amount, cash_amount, wiretransfer_amount, point_amount } = this.value;
      const sumValue = card_amount + cash_amount + wiretransfer_amount + point_amount;
      this.value.amount = sumValue;

      this.policies.is_auto_write_unpaid
        ? (this.value.unpaid_amount = Math.max(this.discountedPrice - this.value.amount, 0))
        : null;
    },

    handleClickFullPayment(method) {
      let updated = {
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        point_amount: 0,
      };
      updated[`${method}_amount`] = this.discountedPrice;
      this.$emit('input', { ...this.value, ...updated, amount: this.discountedPrice });
    },

    handleDiscountRate() {
      this.discountRate =
        this.paymentStatus === '미수금' || this.paymentStatus === '양도'
          ? 0
          : Math.round(((this.referencePrice - this.discountedPrice) / this.referencePrice) * 100);
    },

    handleInputUnpaidAmount() {
      const { card_amount, cash_amount, wiretransfer_amount, unpaid_amount, point_amount } = this.value;
      this.discountedPrice = card_amount + cash_amount + wiretransfer_amount + unpaid_amount + point_amount;
      this.handleDiscountRate();
    },

    handleInputDiscountRate() {
      this.discountedPrice = this.referencePrice * ((100 - this.discountRate) / 100);

      this.$emit('input', {
        ...this.value,
        amount: this.discountedPrice,
        card_amount: this.discountedPrice,
        cash_amount: 0,
        wiretransfer_amount: 0,
        unpaid_amount: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'status' 'left' 'right' 'settlement_at';

  @include mq(568px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'status status'
      'left right'
      'settlement_at settlement_at';
  }

  &__element {
    @include flex(column);
    border-bottom: 1px solid rgba(#000, 0.04);
    padding: 15px 20px;

    label {
      font-size: 12px;
      font-weight: 400;
      padding-bottom: 5px;
    }

    /deep/ .el-radio-group {
      padding-top: 5px;
    }

    &__unpaid-amount {
      /deep/ .el-input__inner {
        color: $color-danger !important;
      }
    }

    &__total-amount {
      /deep/ .el-input__inner {
        color: $color-primary !important;
      }
    }

    &__installment {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 8px;
      align-items: center;

      /deep/ .el-radio__label {
        padding-left: 4px;
      }

      /deep/ .el-radio + .el-radio {
        margin-left: 8px;
      }
    }
  }

  &__element.status {
    grid-area: status;
    @include flex(row, center, flex-start);
  }

  &__element.left,
  &__element.right {
    display: grid;
    grid-template-rows: repeat(5, 32px);
    grid-gap: 10px;
    align-items: start;

    &.is-refund {
      grid-template-rows: repeat(4, 32px);
    }
  }

  &__element.left {
    grid-area: left;
  }

  &__element.right {
    grid-area: right;
  }

  &__element.settlement_at {
    grid-area: settlement_at;

    & > div {
      @include flex(row, center, center);
    }

    /deep/ .el-input {
      width: 100%;
    }
  }

  &__point-input {
    display: flex;
    flex-direction: column;

    &__bottom {
      align-self: flex-end;
      margin: 2px 6px 0 0;
      font-size: 12px;
      color: #606266;

      .primary:hover {
        cursor: pointer;
        font-weight: 500;
        color: $color-primary;
      }
    }
  }
}
</style>
