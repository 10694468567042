<template>
  <el-dialog class="point-storage-dialog" :visible="show" @close="$emit('close', false)">
    <h3 slot="title">잔여 포인트 부족</h3>

    <p class="point-storage-dialog__description">
      잔여 포인트가 부족합니다. <br />
      포인트 충전 후 문자를 발송 할 수 있습니다.
    </p>
    <div>
      <p>잔여 포인트 : {{ point | comma }}포인트</p>
      <p v-show="showNeedPointText">필요 포인트 : {{ usePoint | comma }}포인트</p>
    </div>

    <div slot="footer">
      <el-button @click="$emit('close', false)">
        취소
      </el-button>
      <el-button type="primary" @click="$emit('close', false, true)">
        충전
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    point: Number,
    usePoint: { type: Number, default: 12 },
    showNeedPointText: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
.point-storage-dialog {
  /deep/ .el-dialog {
    width: 30%;
    max-width: 1024px;
    &__header,
    &__body {
      padding: 20px 30px;
    }
  }
  &__description {
    margin-bottom: 20px;
  }
}
</style>
