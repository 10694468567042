<template>
  <li class="memo-creating">
    <Textarea ref="memo-creating"
      placeholder="댓글을 입력해주세요."
     :value="value" @input="value => $emit('input', value)" required />
    <div class="memo-creating__buttons">
      <BaseButton @click="$emit('save')" :disabled="disabled" round>댓글 등록</BaseButton>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },

  created() {
    this.$nextTick(() => {
      this.$refs['memo-creating'].$el.focus();
    });
  },
};
</script>

<style lang="scss" scoped>
.memo-creating {
    display: grid;
    grid-template-columns: 1fr 90px;

  &__buttons {
    padding: 8px 0 0 10px;
  }
}
</style>
