import XLSX from 'xlsx';

export default (json, filename) => {
  /** 시트/파일 생성 */
  const sheet = XLSX.utils.json_to_sheet(json);
  const workbook = new XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, sheet);

  /** 다운로드 */
  XLSX.writeFile(workbook, filename);
};
