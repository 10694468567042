<template>
  <MainLayout :padded="false" v-loading="isSaving" :element-loading-text="loadingText">
    <content-header :breadcrumb="breadcrumb" :contentMaxWidth="1191">
      <div class="member-form__header">
        <div class="member-form__header__profile-image">
          <el-upload
            :class="{ hasProfileImage: !!profileImage }"
            ref="profile-image"
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            :file-list="profileImages"
            :auto-upload="false"
            :on-preview="handleProfileImagePreview"
            :on-change="handleProfileImageChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <circle-button
            v-if="!!profileImage || !!profileImages.length"
            class="member-form__header__profile-image__remove"
            @click="handleClickRemoveProfileImage"
          >
            <i class="el-icon-close"></i>
          </circle-button>
          <el-dialog :visible.sync="showProfileImagePreviewDialog">
            <img width="100%" :src="profileImagePreviewUrl" alt="profile preview" />
          </el-dialog>
        </div>

        <div class="member-form__header__name">
          <input ref="name" type="text" v-model="data.profile.name" placeholder="이름을 입력해주세요" />
          <el-select v-if="useUserGrade" v-model="data.user_grade">
            <el-option v-for="item in userGradeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>

        <div class="member-form__header__registered-at">
          <label>등록일:</label>
          <el-date-picker
            v-model="data.profile.registered_at"
            type="date"
            placeholder="등록일을 입력해주세요"
            format="yyyy년 M월 d일"
            value-format="yyyy-MM-dd"
            :clearable="false"
          ></el-date-picker>
        </div>

        <div class="member-form__header__mobile">
          <label>휴대폰번호:</label>
          <el-tooltip
            v-model="focusOnMobile"
            manual
            effect="light"
            content="회원 앱과 연결되는 중요한 정보입니다. 신중히 입력해주세요."
            placement="top"
          >
            <PhoneNumberInput
              v-model="data.mobile"
              placeholder="휴대폰 번호"
              :type="showMobileInput ? 'text' : 'password'"
              :readonly="!showMobileInput"
              @focus="focusOnMobile = true"
              @blur="checkDupPhoneNumber"
            />
          </el-tooltip>
          <span v-if="isDupMobile" class="member-form__header__mobile__dup-mobile">이미 사용중인 전화번호 입니다.</span>
        </div>

        <div class="member-form__header__gender-birthday">
          <label>성별:</label>
          <el-select v-model="data.profile.gender" class="member-form__header__gender">
            <el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>

          <label>생년월일:</label>
          <DateInput class="member-form__header__birthday" v-model="data.profile.birthday" placeholder="생년월일 (YYYY-MM-DD)" />
          <!-- <el-date-picker
            v-model="data.profile.birthday"
            class="member-form__header__birthday"
            type="date"
            placeholder="생년월일"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
          ></el-date-picker> -->
        </div>

        <div class="member-form__header__address">
          <label>주소:</label>
          <TextInput class="member-form__header__address__input" v-model="addressInput" material />
        </div>

        <div class="member-form__header__vaccine">
          <el-checkbox v-model="data.vaccination_yn">백신 접종 14일이 경과된 회원입니다.</el-checkbox>
        </div>
      </div>
    </content-header>

    <section class="member-form__body">
      <!-- <form-block :index="1" label="주소를 입력해주세요">
        <p class="member-form-element__address">
          <label>주소</label>
          <TextInput
            id="address"
            ref="address"
            v-model="data.address.address"
            material
            readonly
            @focus="showPostCode"
          />
        </p>
        <p class="member-form-element__address">
          <label>상세주소</label>
          <TextInput id="detail_address" ref="detail_address" v-model="data.address.detail_address" material />
        </p>
        <div ref="postcode_wrapper" class="member-form-element__postcode-wrapper">
          <circle-button class="member-form-element__postcode-wrapper__close" @click="hidePostCode">
            <i class="el-icon-close"></i>
          </circle-button>
        </div>
      </form-block> -->

      <form-block :index="1" noBtmBorder label="사용중인 수강권을 등록해주세요">
        <ul class="member-form-element__tickets">
          <UserTicketCard
            v-if="canCreateMembersTicket"
            @click="
              () => {
                showTicketIssueModal = true;
              }
            "
            clickable
            addNew
          />

          <!-- 회원 등록시: 발급할 수강권 목록 -->
          <UserTicketCard
            v-for="(ticket, index) in ticketsToIssue"
            :key="index"
            :userTicket="flattenTicketData(ticket)"
            @click="handleClickUserTicketCardToIssue(index, false)"
            @remove="handleClickUserTicketCardRemove(index, false)"
            clickable
            showPrice
            amountTitleText="결제 금액"
            notIssued
          />

          <!-- 회원 정보 수정시: 보유중인 수강권 -->
          <UserTicketCard
            v-loading="isMemberTicketsLoading"
            v-for="userTicket in ticketArray"
            :key="userTicket.id"
            :userTicket="userTicket"
            @click="handleClickUserTicketCard(userTicket)"
            clickable
            showPrice
          />
        </ul>
      </form-block>

      <form-block :unNumbering="true" label="사용중인 상품을 등록해주세요">
        <ul class="member-form-element__tickets">
          <UserTicketCard
            v-if="canCreateMembersTicket"
            @click="
              () => {
                isProduct = true;
                showTicketIssueModal = true;
              }
            "
            clickable
            addNew
            addNewLabel="새로운 상품 만들기"
          />

          <!-- 회원 등록시: 발급할 상품 목록 -->
          <UserTicketCard
            v-for="(ticket, index) in productsToIssue"
            :key="index"
            :userTicket="flattenTicketData(ticket)"
            @click="handleClickUserTicketCardToIssue(index, true)"
            @remove="handleClickUserTicketCardRemove(index, true)"
            clickable
            showPrice
            notIssued
          />

          <!-- 회원 정보 수정시: 보유중인 상품 -->
          <UserTicketCard
            v-loading="isMemberTicketsLoading"
            v-for="userTicket in productArray"
            :key="userTicket.id"
            :userTicket="userTicket"
            @click="handleClickUserTicketCard(userTicket)"
            clickable
            showPrice
            amountTitleText="결제 금액"
          />
        </ul>
      </form-block>

      <form-block :index="2" label="메모를 입력해주세요">
        <CircleButton
          slot="label-helper"
          class="member-form-element__memo__button__sort"
          v-if="!!memos.length"
          @click="toggleMemoSortBy"
          size="small"
        >
          <i class="el-icon-sort" />
        </CircleButton>
        <PlainButton
          slot="checkbox"
          class="member-form-element__memo__button__add"
          @click="handleClickAddMemo"
          :disabled="!!memoCreating"
          round
          >메모 추가</PlainButton
        >

        <ul v-loading="memosLoading" class="member-form-element__memo">
          <MemoCreatingWithImage
            v-if="memoCreating"
            v-model="memoCreating"
            @cancel="memoCreating = null"
            @save="handleClickCreateMemo"
          />

          <!-- 회원 등록시 -->
          <MemoItemWithImage
            v-for="memo in memosToAdd"
            :key="memo.id"
            v-model="memoEditing"
            :memo="memo"
            :isEditing="isEditing(memo.id)"
            @delete="handleClickDeleteMemo"
            @edit="handleClickEditMemo"
            @cancel-edit="handleClickCancelEditMemo"
            @save="handleClickSaveEditMemo"
          />

          <!-- 회원정보 수정시 -->
          <MemoItemWithImage
            v-for="memo in memoSorted"
            :key="memo.id"
            v-model="memoEditing"
            :memo="memo"
            :isEditing="isEditing(memo.id)"
            @delete="handleClickDeleteMemo"
            @edit="handleClickEditMemo"
            @cancel-edit="handleClickCancelEditMemo"
            @save="handleClickSaveEditMemo"
          />
        </ul>
      </form-block>
    </section>

    <bottom-action-bar :message="lastUpdatedAt">
      <el-button v-if="!!member && canDeleteMembers" :disabled="isSaving" type="danger" @click="deleteMember"
        >회원 삭제</el-button
      >
      <el-button :disabled="isSaving" @click="saveMember">{{ title }} 완료</el-button>
    </bottom-action-bar>

    <TicketIssueModal
      v-if="showTicketIssueModal"
      :show="showTicketIssueModal"
      :initial-data="ticketIssueData"
      :isProduct="isProduct"
      @close="handleCloseTicketIssue"
    />

    <TicketEditModal
      v-if="showTicketEditModal"
      :show="showTicketEditModal"
      :isProduct="isProduct"
      @close="handleCloseTicketEdit"
    />
  </MainLayout>
</template>

<script>
import utils from '@utils';
import TicketIssueModal from '@components/Modals/TicketIssue';
import TicketEditModal from '@components/Modals/TicketEdit';

export default {
  components: {
    TicketIssueModal,
    TicketEditModal,
  },

  data() {
    return {
      /** Options */
      genderOptions: [
        { label: '선택안함', value: null },
        { label: '남성', value: 'M' },
        { label: '여성', value: 'F' },
      ],
      userGradeOptions: [],

      focusOnMobile: false,
      isDupMobile: false,

      /** 데이터 - 공통 */
      profileImage: null,
      data: {
        mobile: '',
        rememberMobile: null,
        profile: {
          name: '',
          registered_at: this.moment().format('YYYY-MM-DD'),
          gender: null,
          birthday: '',
        },
        address: {
          address: '',
          detail_address: '',
        },
        user_grade: null,
        vaccination_yn: false,
      },

      memoCreating: null,
      memoEditing: null,

      /** 데이터 - 회원 등록시 */
      ticketsToIssue: [],
      productsToIssue: [],
      memosToAdd: [],
      currentMemoId: 1,

      /** 데이터 - 회원 수정시 */
      member: null,
      memberTickets: [],
      ticketArray: [],
      productArray: [],
      isMemberTicketsLoading: false,
      memos: [],
      memosLoading: false,
      memoSortBy: 'desc',
      profileImages: [],

      /** Profile Image Dialog */
      profileImagePreviewUrl: '',
      showProfileImagePreviewDialog: false,

      /** 수강권 등록 */
      showTicketIssueModal: false,
      ticketIssueDataIndex: null,
      ticketIssueData: null,

      /** 수강권 수정 */
      showTicketEditModal: false,

      isProduct: false,

      isSaving: false,
      loadingText: '',
    };
  },

  computed: {
    breadcrumb() {
      return {
        parent: { path: '/users', label: '회원' },
        current_route_label: this.title,
      };
    },

    title() {
      return !_.get(this.member, 'id') ? '회원 등록' : '회원 수정';
    },

    showMobileInput() {
      return !this.member || this.canViewMembersMobile;
    },

    lastUpdatedAt() {
      if (!this.member || !this.member.id) return null;
      const date = _.get(this.member, 'updated_at') || _.get(this.member, 'created_at');
      if (!date) return null;

      const suffix = this.member.updated_at ? '에 마지막으로 수정됨' : '에 생성됨';

      return `${this.$filters.datetime(date)} ${suffix}`;
    },

    memoSorted() {
      const memos = _.sortBy(this.memos, 'updated_at');
      if (this.memoSortBy === 'desc') memos.reverse();
      return memos;
    },

    useUserGrade() {
      return !!this.studioPolicies.is_use_user_grade;
    },

    addressInput: {
      get() {
        const address = _.get(this.data, 'address.address');
        const detailAddress = _.get(this.data, 'address.detail_address');
        let addressInput = [];
        if (address) addressInput.push(address);
        if (detailAddress) addressInput.push(detailAddress);
        return addressInput.join(' ');
      },
      set(value) {
        this.data.address.address = '';
        this.data.address.detail_address = value;
      },
    },

    newAllIssuedArray() {
      return [...this.ticketsToIssue, ...this.productsToIssue];
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      const authorized = !to.query.id ? vm.canCreateMembers : vm.canUpdateMembers;

      if (!authorized) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/users');
      }

      next();
    });
  },

  watch: {
    'data.mobile': function() {
      if (this.isDupMobile) return (this.isDupMobile = false);
    },
  },

  async created() {
    const memberId = this.$route.query.id;
    const counselingId = this.$route.query.cId;
    this.getUserGrades();

    if (memberId) {
      try {
        const res = await this.$api.member.get(memberId);
        this.member = res.data.member;
        this.data = {
          mobile: this.member.mobile,
          rememberMobile: this.member.mobile,
          profile: { ...this.member.profile },
          address: { ...this.member.address },
          user_grade: this.member.user_grade_id,
          vaccination_yn: this.member.vaccination_yn === 'Y',
        };

        window.localStorage.removeItem(this.member.id);
        let profileImageUrl;

        if (!this.member.avatars.length) {
          profileImageUrl = this.$utils.getImageUrl(_.get(this.member, 'account_avatar'), '148x148');
        } else {
          profileImageUrl = this.$utils.getImageUrl(_.get(this.member, 'avatars[0]'), '148x148');
        }

        if (profileImageUrl) this.profileImages = [{ url: profileImageUrl }];

        this.getUserTickets();
        this.getMemos();
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    } else if (counselingId) {
      try {
        const res = await this.$api.counsel.get(counselingId);
        this.data.profile.name = res.data.counsel.name;
        this.data.mobile = res.data.counsel.phone;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    }

    this.$nextTick(() => {
      this.$refs.name.focus();
    });
  },

  destroyed() {
    window.localStorage.removeItem(this.member.id);
  },

  methods: {
    async getMemos() {
      try {
        this.memosLoading = true;
        const res = await this.$api.memo.getAll('member', this.member.id);
        this.memos = res.data;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.memosLoading = false;
      }
    },

    /** 회원 수강권 가져오기 */
    async getUserTickets() {
      try {
        this.isMemberTicketsLoading = true;
        const params = { member_id: this.member.id, is_all: true };
        const res = await this.$api.userTicket.getAll(params);
        this.memberTickets = res.data.filter(
          ticket => !['사용불가', '이용만료', '환불', '양도', '판매'].includes(this.$utils.getTicketStatus(ticket)),
        );

        const saleProductData = utils.localStorage.get(this.member.id);
        if (saleProductData) {
          this.memberTickets.push(...saleProductData);
        }

        this.ticketArray = this.memberTickets.filter(({ ticket }) => ticket.available_class_type !== 'I');
        this.productArray = this.memberTickets.filter(({ ticket }) => ticket.available_class_type === 'I');
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isMemberTicketsLoading = false;
      }
    },

    async getUserGrades() {
      try {
        const res = await this.$api.studio.userGrades.get();
        this.userGradeOptions = res.data.reduce(
          (options, { id, name }) => {
            options.push({ value: id, label: name });
            return options;
          },
          [{ value: null, label: '선택안함' }],
        );
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    /** 프로필 이미지 미리보기 */
    handleProfileImagePreview(file) {
      this.profileImagePreviewUrl = file.url;
      this.showProfileImagePreviewDialog = true;
    },

    /** 프로필 이미지 변경시 */
    handleProfileImageChange(file, fileList) {
      if (file.size > 2 * 1024 * 1024) {
        this.$utils.notify.error(this, '오류', '이미지 사이즈는 2MB까지 가능합니다.');
        return fileList.splice(fileList.length - 1, 1);
      }
      if (fileList.length > 1) fileList.shift();
      if (fileList.length > 0) {
        this.profileImage = fileList[fileList.length - 1];
      }
    },

    /** 프로필 이미지 제거 */
    handleClickRemoveProfileImage() {
      this.$refs['profile-image'].clearFiles();
      this.profileImage = null;
      this.profileImages = [];
    },

    /** 수강권 추가 */
    async handleCloseTicketIssue(data) {
      this.showTicketIssueModal = false;
      const memberId = _.get(this.member, 'id');
      if (data && !memberId) {
        /** 회원 신규 등록시: 회원 등록 처리할 때 발급 */
        if (this.ticketIssueDataIndex === null) {
          if (this.isProduct) {
            this.productsToIssue.unshift(data);
          } else {
            this.ticketsToIssue.unshift(data);
          }
        } else {
          if (this.isProduct) {
            this.productsToIssue[this.ticketIssueDataIndex] = data;
          } else {
            this.ticketsToIssue[this.ticketIssueDataIndex] = data;
          }
        }
        this.ticketIssueDataIndex = null;
        this.ticketIssueData = null;
      } else if (data) {
        /** 회원 정보 수정시: 바로 발급 */
        try {
          const product = this.$utils.getParamsForTicketIssue(data, memberId);
          const res = await this.$api.userTicket.create({ products: [product] });

          if (res.data[0].ticket.type === 'S') {
            this.setSaleProductOnLocalStorage(memberId, res.data);
          }
          this.$utils.notify.success(this, '성공', `${this.isProduct ? '상품' : '수강권'}이 발급되었습니다.`);
          this.getUserTickets();
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }

      this.isProduct = false;
    },

    setSaleProductOnLocalStorage(memberId, array) {
      const saleProductData = utils.localStorage.get(memberId);
      if (saleProductData) {
        const data = saleProductData.concat(array[0]);
        utils.localStorage.set(memberId, data);
      } else {
        utils.localStorage.set(memberId, array);
      }
    },

    /** 발급 전 수강권 클릭시 */
    handleClickUserTicketCardToIssue(index, boolean) {
      this.isProduct = boolean;
      this.ticketIssueDataIndex = index;
      this.ticketIssueData = this.isProduct ? this.productsToIssue[index] : this.ticketsToIssue[index];
      this.showTicketIssueModal = true;
    },

    /** 발급 전 수강권 제거 */
    handleClickUserTicketCardRemove(index, boolean) {
      this.isProduct = boolean;
      this.isProduct
        ? (this.productsToIssue = this.productsToIssue.filter((ticket, listIndex) => listIndex !== index))
        : (this.ticketsToIssue = this.ticketsToIssue.filter((ticket, listIndex) => listIndex !== index));
      this.isProduct = false;
    },

    /** 발급된 수강권 클릭시 */
    async handleClickUserTicketCard(userTicket) {
      const { ticket, id: userTicketId } = userTicket;
      this.isProduct = ticket.available_class_type === 'I';
      await this.$store.dispatch('ticketEdit/getUserTicket', userTicketId);
      this.showTicketEditModal = true;
    },

    /** 수강권 수정 모달 닫기 */
    async handleCloseTicketEdit() {
      this.showTicketEditModal = false;
      this.getUserTickets();
    },

    /** 메모 정렬 */
    toggleMemoSortBy() {
      this.memoSortBy = this.memoSortBy === 'asc' ? 'desc' : 'asc';
    },

    /** 메모 추가 */
    handleClickAddMemo() {
      this.memoCreating = { memo: '', attachment: [], attachments: [] };
      this.memoEditing = null;
    },

    async handleClickCreateMemo() {
      /** 회원 등록시: 임시 리스트에 추가 */
      if (!this.member) {
        const memo = {
          id: `temp${this.currentMemoId}`,
          staff: {
            id: this.currentUser.id,
            name: this.currentUser.profile.name,
          },
          roles: _.get(this.currentUser, 'roles[0].display_name'),
          ...this.memoCreating,
        };
        this.memosToAdd.push(memo);
        this.memoCreating = null;
        this.currentMemoId++;

        /** 회원 정보 수정시: 바로 추가 */
      } else {
        try {
          const data = {
            ref_type: 'member',
            ref_id: this.member.id,
            ..._.omit(this.memoCreating, 'attachments'),
          };
          await this.$api.memo.create(data);
          this.getMemos();
          this.memoCreating = null;
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    /** 메모 수정인지 체크 */
    isEditing(memoId) {
      return this.memoEditing && this.memoEditing.id === memoId;
    },

    /** 메모 삭제 */
    async handleClickDeleteMemo(memo) {
      /** 회원 등록시: 임시 리스트에서 제거 */
      if (!this.member) {
        this.memosToAdd = this.memosToAdd.filter(({ id }) => id !== memo.id);

        /** 회원 정보 수정시: 바로 삭제 */
      } else {
        const title = '메모 삭제';
        const message = '메모를 삭제하시겠습니까?';
        const proceed = await this.$confirm(message, title)
          .then(() => true)
          .catch(() => false);

        if (proceed) {
          try {
            await this.$api.memo.delete(memo.id);
            this.getMemos();
          } catch (error) {
            this.$utils.notify.parseError(this, error);
          }
        }
      }
    },

    /** 메모 수정 모드 */
    handleClickEditMemo(memo) {
      this.memoEditing = {
        ...memo,
        attachment: memo.attachments.map(({ id }) => id),
      };
      this.memoCreating = null;
    },

    /** 메모 수정 취소 */
    handleClickCancelEditMemo() {
      this.memoEditing = null;
    },

    /** 메모 수정 내용 저장 */
    async handleClickSaveEditMemo() {
      /** 회원 등록시: 임시 리스트의 내용 수정 */
      if (!this.member) {
        this.memosToAdd = this.memosToAdd.map(memo => {
          return memo.id === this.memoEditing.id ? this.memoEditing : memo;
        });
        this.memoEditing = null;

        /** 회원 정보 수정시: 바로 수정 */
      } else {
        try {
          const data = _.omit(this.memoEditing, 'attachments');
          await this.$api.memo.update(data);
          this.getMemos();
          this.memoEditing = null;
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    /** 회원 정보 저장 */
    async saveMember() {
      if (!this.validate()) return;

      this.isSaving = true;
      this.loadingText = '회원 정보 저장중...';
      let memberId = null;
      let success = true;

      /** 1. 회원 정보 저장 */
      try {
        const vaccination_yn = this.data.vaccination_yn ? 'Y' : 'N';
        const profile = _.omit(this.data.profile, ['reward_point', 'paid_point']);
        const data = {
          ...this.data,
          vaccination_yn,
          profile,
        };

        const formData = this.$utils.generateFormData(data);
        if (this.profileImage && this.profileImage.raw) {
          let convertedFile;
          if (this.profileImage.raw.type === 'image/png') {
            await this.$utils.convertPNGToJPEG(this.profileImage.raw).then(jpgFile => {
              convertedFile = jpgFile;
            });
          }
          formData.append('representativeAvatar[image]', convertedFile || this.profileImage.raw);
        } else if (!this.profileImage && !this.profileImages.length && !!this.member && !!this.member.id) {
          formData.append('representativeAvatar', -1);
        }

        const res =
          this.member && this.member.id
            ? await this.$api.member.update(this.member.id, formData)
            : await this.$api.member.create(formData);
        memberId = res.data;
      } catch (error) {
        if (error.response.data.errors?.['representativeAvatar.image']?.[0]) {
          this.$utils.notify.parseErrorImage(this, error, 'image');
        } else {
          this.$utils.notify.parseError(this, error);
        }
        success = false;
      }

      /** 2. 회원 메모 저장 */
      if (this.memosToAdd.length > 0 && success) {
        try {
          this.loadingText = '회원 메모 저장중...';
          await Promise.all(
            this.memosToAdd.map(async memo => {
              await this.$api.memo.create({
                ref_type: 'member',
                ref_id: memberId,
                ..._.pick(memo, ['memo', 'attachment']),
              });
            }),
          );
        } catch (error) {
          this.$utils.notify.parseError(this, error);
          success = false;
        }
      }

      /** 3. 수강권 발급 */
      if (this.newAllIssuedArray.length > 0 && success) {
        try {
          this.loadingText = '수강권 발급중...';

          const products = this.newAllIssuedArray.map(ticket => {
            return this.$utils.getParamsForTicketIssue(ticket, memberId);
          });
          await this.$api.userTicket.create({ products });
        } catch (error) {
          this.$utils.notify.parseError(this, error);
          success = false;
        }
      }

      this.isSaving = false;
      this.loadingText = '';

      if (success) {
        this.$utils.notify.success(this, '성공', '회원 정보가 저장되었습니다.');
        this.$router.push(`/users/detail?id=${memberId}`);
      }
    },

    /** 회원 삭제 */
    async deleteMember() {
      if (!this.canDeleteMembers) return this.$utils.notify.error(this, '오류', '권한이 필요한 기능입니다.');

      const title = '회원 삭제';
      const message = '삭제하면 복구할 수 없습니다. 정말 삭제할까요?';
      const confirmed = await this.$confirm(message, title)
        .then(() => true)
        .catch(() => false);

      if (confirmed) {
        this.isSaving = true;
        try {
          await this.$api.member.delete(this.member.id);
          this.$utils.notify.success(this, '확인', '회원이 삭제되었습니다.');
          this.$router.push('/users');
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.isSaving = false;
        }
      }
    },

    /** 입력 데이터 확인 */
    validate() {
      if (!this.data.profile.name) {
        this.$utils.notify.error(this, '오류', '이름을 입력해주세요.');
        return false;
      } else if (this.data.profile.name.length > 20) {
        this.$utils.notify.error(this, '오류', '이름은 20글자까지만 입력해주세요.');
        return false;
      } else if (!this.$utils.validate.name(this.data.profile.name)) {
        this.$utils.notify.error(this, '오류', '이름은 한글, 영문, 숫자만 입력해주세요.');
        return false;
      } else if (!this.data.profile.registered_at) {
        this.$utils.notify.error(this, '오류', '등록일을 입력해주세요.');
        return false;
      } else if (!!this.data.mobile && !this.$utils.validate.mobile(this.data.mobile)) {
        this.$utils.notify.error(this, '오류', '휴대폰 번호를 올바르게 입력해주세요..');
        return false;
      } else if (this.isDupMobile === true) {
        this.$utils.notify.error(this, '오류', '이미 사용중인 전화번호 입니다.');
        return false;
      } else if (
        (!!this.data.profile.birthday && !this.$utils.validate.birthDay(this.data.profile.birthday)) ||
        (this.data.profile.birthday && this.data.profile.birthday.length !== 10)
      ) {
        this.$utils.notify.error(this, '오류', '생년월일을 올바르게 입력해주세요.');

        return false;
      }

      return true;
    },

    /** 주소 찾기 */
    showPostCode() {
      const self = this;
      const { postcode_wrapper } = this.$refs;
      window.daum.postcode.load(() => {
        const currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop);

        new window.daum.Postcode({
          oncomplete: function(data) {
            let { address, bname } = data;
            // 읍면동 추가
            if (bname) address += ` (${bname})`;

            self.data.address.address = address;
          },

          onclose: function(state) {
            // on forced close
            if (state === 'FORCE_CLOSE') {
              self.data.address.address = '';
            }

            self.hidePostCode();
            document.body.scrollTop = currentScroll;
          },
        }).embed(postcode_wrapper);

        postcode_wrapper.style.display = 'block';
        postcode_wrapper.scrollIntoView({ behavior: 'smooth' });
      });
    },

    hidePostCode() {
      const wrapperEl = this.$refs.postcode_wrapper;
      const detailAddressInput = this.$refs.detail_address.$el.querySelector('input');
      wrapperEl.style.display = 'none';
      detailAddressInput.focus();
    },

    flattenTicketData(ticket) {
      const { selectedTicket, ticketDetail, paymentDetail } = ticket;
      return {
        ...ticketDetail,
        ticket: { ...selectedTicket },
        payments: [{ ...paymentDetail }],
      };
    },

    async checkDupPhoneNumber() {
      this.focusOnMobile = false;
      if (!this.data.mobile.length) return;

      const dupCheck = await this.$api.member.checkPhone(this.data.mobile);
      if (dupCheck.data.is_overlap_mobile && this.data.rememberMobile !== this.data.mobile) {
        this.isDupMobile = true;
      } else {
        this.isDupMobile = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.member-form__header {
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  grid-template-areas: 'image' 'name' 'registered-at' 'mobile' 'gender-birthday' 'address';
  grid-gap: 16px;
  width: 100%;

  @include mq(568px) {
    grid-column-gap: 24px;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'name image'
      'registered-at image'
      'mobile image'
      'gender-birthday image'
      'address image';
  }

  &__profile-image {
    grid-area: image;
    justify-self: center;
    position: relative;
    width: 148px;
    height: 148px;
    overflow: hidden;

    &__remove {
      position: absolute;
      background: $gray-100;
      border: 1px solid $gray-200;
      top: 0;
      right: 0;
    }

    .hasProfileImage {
      /deep/ .el-upload {
        display: none;
      }
    }

    /deep/ .el-upload {
      border-radius: 50%;

      input {
        display: none;
      }
    }
  }

  &__name {
    grid-area: name;
    width: 100%;

    @include flex(row, center);

    input {
      background: transparent;
      font-size: 28px;
      font-weight: bold;
      outline: none;
      max-width: 360px;

      @include mq(568px) {
        font-size: 38px;
      }
    }

    /deep/ .el-select {
      width: 150px;
      margin: 0 4px 4px 0;
    }

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 40px;
      width: 150px;
      padding: 0 15px;
    }
  }

  &__registered-at {
    grid-area: registered-at;

    /deep/ .el-date-editor {
      margin-left: 10px;
      width: 160px;
    }

    /deep/ .el-checkbox {
      margin-left: 30px;
    }
  }

  &__mobile {
    grid-area: mobile;

    /deep/ .el-input {
      width: 150px;
      margin-left: 16px;
    }

    /deep/ .el-input__inner {
      padding: 0 8px;
    }

    &__dup-mobile {
      margin-left: 10px;
      color: $coral;
    }
  }

  &__gender-birthday {
    grid-area: gender-birthday;
  }

  &__gender {
    /deep/ .el-input {
      max-width: 100px;
      margin: 0 24px 0 16px;
    }

    /deep/ .el-input__inner {
      padding-left: 8px;
    }
  }

  &__birthday {
    width: 160px;
    margin-left: 16px;

    /deep/ .el-input__inner {
      padding-left: 8px;
    }

    &.el-date-editor {
      width: 160px;
      margin-left: 16px;
    }
  }

  &__address {
    grid-area: address;
    @include flex(row, center);

    &__input {
      margin-left: 16px;
      max-width: 300px;
    }
  }
}

.member-form__body {
  padding: 10px 20px 60px;
  max-width: 1191px;
  margin: auto;

  @include mq(768px) {
    padding: 30px 40px 60px;
  }
}

.member-form-element {
  &__tickets {
    display: grid;
    grid-template-columns: repeat(auto-fill, 256px);
    grid-gap: 9px;
    place-items: center;
  }

  &__memo {
    @include flex(column);

    &__button {
      &__sort {
        margin-left: 8px;
      }

      &__add {
        margin-left: auto;
        margin-right: 16px;
        width: 104px;
      }
    }
  }

  &__address {
    @include flex(column);
    margin-bottom: 16px;
    max-width: 500px;

    label {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__postcode-wrapper {
    display: none;
    border: 1px solid $charcoal-grey;
    margin: 5px 0;
    width: 502px;
    position: relative;

    &__close {
      background: #000;
      color: #fff;
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: 1;
    }
  }
}
</style>
