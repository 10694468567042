<template>
  <ContentHeaderTabs
    :tabs="headerTabs"
    :activeTab="activeTab"
    :exceldownloading="exceldownloading"
    :handleExcelDownloadClick="
      activeTab === '/lectures'
        ? null
        : () => {
            $emit('exceldown');
          }
    "
  />
</template>

<script>
export default {
  props: {
    activeTab: { type: String, default: '/lectures' },
    exceldownloading: { type: Boolean, default: false },
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },

    headerTabs() {
      let tabs = [
        { path: '/lectures', label: '수업 목록' },
        { path: '/lectures/bookings', label: '예약내역' },
        { path: '/lectures/deleted', label: '삭제된 수업' },
      ];

      if ((this.studio.grade === 2 || this.studio.usable_enter) && this.studioPolicies.is_enter) {
        tabs.push({ path: '/lectures/check_in', label: '체크인내역' });
        return tabs;
      }
      return tabs;
    },
  },
};
</script>
