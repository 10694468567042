<template>
  <el-dropdown size="small" :show-timeout="0" :hide-timeout="0">
    <button @click="$emit('click', $event)" class="el-dropdown-link" @mousedown="handleMousedown" @mouseup="handleMouseup">
      <img :src="src" :alt="tooltip" />
    </button>
    <el-dropdown-menu slot="dropdown">
      <span class="tooltip">{{ tooltip }}</span>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  props: {
    imgSrc: String,
    activeImgSrc: String,
    tooltip: String,
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    src() {
      return this.isActive ? this.activeImgSrc : this.imgSrc;
    },
  },

  methods: {
    handleMousedown() {
      this.isActive = true;
    },
    handleMouseup() {
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  -webkit-padding-end: 6px;
  -webkit-padding-start: 6px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: #e2f3fe;

  &:active {
    background-color: #5fb8ff;
  }
}
.tooltip {
  padding: 0 12px;
  font-size: 12px;
  color: #808080;
  cursor: default;
}
</style>
