<template>
  <div class="filter-container">
    <div class="filters">
      <el-select
        v-for="filterItem in filterOptions"
        :key="filterItem.name"
        :value="filterItem.value"
        @change="
          value => {
            filterItem.onChange(filterItem.name, value);
          }
        "
        :multiple="filterItem.multiple"
        :filterable="filterItem.multiple"
        :collapse-tags="filterItem.multiple"
        :placeholder="filterItem.placeholder"
        :clearable="filterItem.multiple"
        :class="{ 'is-filtered': filterItem.value && filterItem.value !== 'total' }"
        :disabled="filterItem.disabled"
      >
        <el-option
          v-for="option in filterItem.options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        ></el-option>
      </el-select>
      <img src="@/assets/images/icons/sales_refresh.svg" class="refresh-button" @click="refreshClick" />
    </div>
    <el-button type="primary" class="download-button" @click="handleDownload">{{ downloadText }}</el-button>
  </div>
</template>

<script>
export default {
  props: {
    filterOptions: Array,
    refreshClick: Function,
    downloadText: String,
    handleDownload: Function,
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  @include flex(row, center, space-between);

  .download-button {
    background: #64aeff;
    border: none;
    height: 40px;
  }
}

.filters {
  @include flex(row, center);
  flex-wrap: wrap;

  & > .el-select + .el-select {
    margin-left: 16px;
  }

  /deep/ .el-input__inner {
    display: inline-block;
    padding: 10px 12px;
    border: 1px solid #dcdfe6;
    font-size: 14px;
    border-radius: 4px;
    height: 40px;
    width: 150px;
    color: #545454;

    &::placeholder {
      color: #545454;
    }
  }

  .refresh-button {
    width: 36px;
    height: 36px;
    cursor: pointer;
    margin-left: 20px;
  }
}

/deep/ .el-select {
  .el-tag:first-child {
    max-width: 100%;
    width: 100%;
    position: relative;
    .el-select__tags-text {
      width: 80%;
      display: inline-block;
      @include ellipsis;
    }
    .el-tag__close.el-icon-close {
      position: absolute;
      top: 50%;
      right: 7px;
      transform: scale(0.8) translateY(-50%);
    }
  }
}
.is-filtered {
  color: $color-primary;

  /deep/ .el-input__inner {
    color: $color-primary;
  }
}
</style>
