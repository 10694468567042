<template>
  <el-input-number
    v-bind="$attrs"
    class="inline-number-input"
    :value="value"
    :min="min"
    :max="max"
    controls-position="right"
    size="small"
    @input="value => $emit('input', value)"
    :disabled="disabled"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: { type: Number, required: true },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 99 },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.inline-number-input.el-input-number {
  width: 80px;

  /deep/ .el-input-number__decrease {
    border: none;
    border-radius: 4px 4px 0 0;
    bottom: 2px;
    width: 20px;
  }

  /deep/ .el-input-number__increase {
    border: none;
    border-radius: 0 0 4px 4px;
    width: 20px;
  }

  /deep/ .el-input__inner {
    padding: 0 25px 0 5px;
  }
}
</style>
