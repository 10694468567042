<template>
  <div v-html="infoText"></div>
</template>

<script>
export default {
  props: {
    existPlan: null, //현재 이용중인 요금제
    selectedPlan: Object, //새로 선택한 요금제
    paymentDate: String,
  },
  data() {
    return {};
  },
  computed: {
    // caseType() {
    //     return `${this.existPlan}${this.selectedPlan.id}`
    // },
    infoText() {
      switch (`${this.existPlan}${this.selectedPlan.id}`) {
        case '11':
          return `이미 사용중인 요금제 입니다. `;
        case '13':
          return `PLUS 요금은 ${this.$filters.dateKorean(
            this.paymentDate,
          )} 결제되며 그전까지 PLUS 서비스를 무료로 이용하실 수 있습니다.`;

        case '12':
          return '';
        // return `${this.$filters.dateKorean(this.paymentDate)}에 BASIC 1년 이용 요금이 예약 결제됩니다.`;

        case '14':
          return '결제한 즉시 PLUS 서비스를 이용하실 수 있습니다.';
        // return `${this.$filters.dateKorean(
        //   this.paymentDate,
        // )}에 PLUS 1년 이용 요금이 예약 결제됩니다. 그전까지 PLUS 서비스를 무료로 이용하실 수 있습니다.`;

        case '31':
          return `BASIC 요금은 ${this.$filters.dateKorean(
            this.paymentDate,
          )} 결제되며 다음날부터 적용됩니다. 변경전까지 PLUS 서비스를 이용하실수 있습니다.`;

        case '32':
          return '변경전까지 PLUS 서비스를 이용하실수 있습니다.';
        // return `${this.$filters.dateKorean(
        //   this.paymentDate,
        // )}에 BASIC 1년 이용 요금이 예약 결제됩니다. 변경전까지 PLUS 서비스를 계속 이용하실수 있습니다.`;

        case '33':
          return '이미 사용중인 요금제 입니다.';
        case '34':
          return '';
        // return `${this.$filters.dateKorean(this.paymentDate)}에 PLUS 1년 이용 요금이 예약 결제됩니다.`;

        case '21':
          return `[${this.selectedPlan.title}] 요금은 ${this.$filters.dateKorean(this.paymentDate)}에 결제됩니다.`;

        case '23':
          return `변경할 수 없는 요금제 입니다. <br><b style="color:#f56c6c">고객센터로 문의</b>해주세요.`;

        case '24':
          return `남은 잔여기간을  PLUS 요금제로 변경하며 추가요금이 필요합니다. <br><b style="color:#f56c6c">고객센터에 문의</b>해 주시면 처리해드리겠습니다.`;

        case '41':
          return `[${this.selectedPlan.title}] 요금은 ${this.$filters.dateKorean(this.paymentDate)}에 결제됩니다.`;
        // return `결제한 즉시 월 정기결제-BASIC 요금제가 시작됩니다. <br>연 결제 잔여기간 환불은 <b style="color:#f56c6c">고객센터로 문의</b>해주세요.`;

        case '43':
          return `[${this.selectedPlan.title}] 요금은 ${this.$filters.dateKorean(this.paymentDate)}에 결제됩니다.`;

        case '42':
          return `잔여기간 차액만큼 이용기간이 연장됩니다.<br><b style="color:#f56c6c">고객센터에 문의</b>해 주시면 처리해드리겠습니다.`;

        default:
          return ``;
      }
    },
  },
};
</script>
