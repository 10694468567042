<template>
  <MainLayout :borderTop="`#${instructorColor}`" :padded="false">
    <Header />

    <section class="lecture-detail__body">
      <el-tooltip effect="light" placement="bottom">
        <CircleButton
          v-loading="courseLoading"
          :disabled="!prevLectureInCourse"
          class="lecture-detail__body__button"
          @click="handleClickPrevLectureInCourse"
          bordered
        >
          <img src="@/assets/images/icons/arrow-prev.svg" alt="이전클래스" />
        </CircleButton>
        <div slot="content" class="lecture-detail__body__button__popover">
          <p>이전 클래스</p>
          <p>{{ getLectureTime(prevLectureInCourse) }}</p>
        </div>
      </el-tooltip>

      <div class="lecture-detail__body__content">
        <LectureInfo />
        <MembersList />
        <LectureMemo />
      </div>

      <el-tooltip effect="light" placement="bottom">
        <CircleButton
          v-loading="courseLoading"
          :disabled="!nextLectureInCourse"
          class="lecture-detail__body__button"
          @click="handleClickNextLectureInCourse"
          bordered
        >
          <img src="@/assets/images/icons/arrow-next.svg" alt="다음클래스" />
        </CircleButton>
        <div slot="content" class="lecture-detail__body__button__popover">
          <p>다음 클래스</p>
          <p>{{ getLectureTime(nextLectureInCourse) }}</p>
        </div>
      </el-tooltip>
    </section>
  </MainLayout>
</template>

<script>
import Header from '@/components/LectureDetail/Header';
import LectureInfo from '@/components/LectureDetail/LectureInfo';
import MembersList from '@/components/LectureDetail/MembersList';
import LectureMemo from '@/components/LectureDetail/LectureMemo';

export default {
  components: {
    Header,
    LectureInfo,
    MembersList,
    LectureMemo,
  },

  computed: {
    lecture() {
      return this.$store.getters['lecture/lecture'];
    },
    course() {
      return this.$store.getters['lecture/course'];
    },
    courseLoading() {
      return this.$store.getters['lecture/courseLoading'];
    },

    instructorColor() {
      return this._.get(this.lecture, 'instructor.profile.representative_color') || '000';
    },

    courseLectures() {
      return this._.get(this.course, 'lectures') || [];
    },

    currentLectureIndex() {
      return this.courseLectures.findIndex(({ id }) => id === this.lecture.id);
    },

    prevLectureInCourse() {
      if (this.currentLectureIndex <= 0) return null;
      return this.courseLectures[this.currentLectureIndex - 1];
    },

    nextLectureInCourse() {
      if (this.currentLectureIndex < 0 || this.currentLectureIndex >= this.courseLectures.length - 1) {
        return null;
      }
      return this.courseLectures[this.currentLectureIndex + 1];
    },
    lectureId() {
      return this.$store.getters['lecture/lectureId'];
    },
  },

  watch: {
    '$route.query': {
      handler: 'loadData',
      immediate: true,
    },
  },

  methods: {
    async loadData() {
      await this.$store.dispatch('lecture/getLecture', this.$route.query.id);
      if (!this.lecture.id) {
        this.$utils.notify.error(this, '오류', '수업이 존재하지 않습니다.');
        return this.$router.push('/schedule');
      } else if (!this.canViewLecture(this.lecture.course.type, this.lecture.instructor.id)) {
        this.$utils.notify.error(this, '오류', '권한이 없습니다.');
        return this.$router.push('/schedule');
      }

      this.$store.commit('lecture/SET_BOOKABLE_LIST_INIT');
      this.$store.commit('lecture/SET_LECTURE_ID', this.lecture.id);
      this.$store.dispatch('lecture/getMemos', this.lecture.id);
      this.$store.dispatch('lecture/getCourse', this.lecture.course_id);
    },

    handleClickPrevLectureInCourse() {
      if (this.prevLectureInCourse) {
        this.$router.push(`/lecture/detail?id=${this.prevLectureInCourse.id}`);
      }
    },

    handleClickNextLectureInCourse() {
      if (this.nextLectureInCourse) {
        this.$router.push(`/lecture/detail?id=${this.nextLectureInCourse.id}`);
      }
    },

    getLectureTime(lecture) {
      if (!lecture) return null;
      return `${this.moment(lecture.start_on).format('YYYY. M. D. (ddd) HH:mm')}~${this.moment(lecture.end_on).format('HH:mm')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-detail {
  border-top: 3px solid;

  &__body {
    @include flex(row, flex-start, center);
    width: 95%;
    margin: 8px auto 10px;

    &__button {
      min-width: 36px;
      margin-top: 48px;

      &:first-child {
        margin-right: 12px;
      }

      &:last-child {
        margin-left: 12px;
      }

      @include mq(568px) {
        &:first-child {
          margin-right: 24px;
        }

        &:last-child {
          margin-left: 24px;
        }
      }

      @include mq(1024px) {
        margin-top: 35vh;
      }

      img {
        width: 18px;
        height: 18px;
      }

      &__popover {
        @include flex(column, center, center);
        p + p {
          margin-top: 4px;
        }
      }
    }

    &__content {
      border-radius: 2px;
      box-shadow: 0 8px 20px -16px rgba(#000, 0.5), 0 0 20px 0 rgba(#000, 0.03);
      background: #fff;
      width: 100%;
      max-width: 600px;

      @include mq(1024px) {
        max-width: 1280px;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        min-height: calc(100vh - 230px);
      }
    }
  }
}
</style>
