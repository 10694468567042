import api from '@api';

export const state = {
  isLoading: false,
  smsList: [],
  smsTotalNum: 0,
  smsSendCount: 0,
};

export const getters = {
  isLoading: state => state.isLoading,
  smsList: state => state.smsList,
  smsTotalNum: state => state.smsTotalNum,
  smsSendCount: state => state.smsSendCount,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },
  SET_SMS_LIST(state, smsList) {
    state.smsList = smsList;
  },
  SET_SMS_TOTAL_NUM(state, smsTotalNum) {
    state.smsTotalNum = smsTotalNum;
  },
  SET_SMS_SEND_COUNT(state, smsSendCount) {
    state.smsSendCount = smsSendCount;
  },
};

export const actions = {
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading);
  },
  async getSMSList({ commit }, params) {
    try {
      const res = await api.sms.getUsage(params);
      commit('SET_SMS_LIST', res.data.data);
      commit('SET_SMS_TOTAL_NUM', res.data.meta.total);
      commit('SET_SMS_SEND_COUNT', res.data.total);
    } catch (error) {
      commit('SET_SMS_LIST', []);
      return { success: false, error };
    }
  },
};
