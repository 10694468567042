<template>
  <el-dialog
    :visible="show"
    class="edit-payment-dialog"
    :title="paymentStatus === '미수금' ? '미수금 수정' : '결제 정보 수정'"
    :close-on-click-modal="false"
    append-to-body
    @close="$emit('cancel')"
  >
    <PaymentForm
      v-model="paymentEditing"
      :paymentStatus="paymentStatus"
      :referenceLabel="referenceLabel"
      :referencePrice="referencePrice"
      :readonly="readonly"
    />

    <!-- !payment.unpaid ? referencePrice + payment.amount : referencePrice" -->

    <div class="edit-payment-dialog__buttons">
      <div class="edit-paymen-dialog__buttons__delete">
        <el-button
          v-if="!readonly && !payment.unpaid && paymentStatus === '미수금'"
          :disabled="updating"
          type="info"
          @click="handleClickDelete"
          >미수금 삭제</el-button
        >
      </div>
      <div class="edit-payment-dialog__buttons__group">
        <el-button v-if="!readonly" :disabled="updating" @click="$emit('cancel')">취소</el-button>
        <el-button v-if="!readonly" v-loading="updating" @click="handleClickSave" :disabled="updating" type="primary"
          >저장</el-button
        >
        <el-button v-else @click="$emit('cancel')" type="primary">확인</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    payment: { type: Object, required: true },
    paymentStatus: { type: String, default: '' },
    referenceLabel: { type: String, default: '판매가' },
    referencePrice: { type: Number, default: 0 },
    updating: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },

  data() {
    return {
      paymentEditing: {
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        amount: 0,
        unpaid_amount: 0,
        installment: 1,
        installment_period: 0,
        settlement_at: this.moment().format('YYYY-MM-DD HH:mm:ss'),
        reward_point: 0,
        point_amount: 0,
      },
    };
  },

  computed: {
    payments() {
      return _.get(this.$store.getters['ticketEdit/formData'], 'userTicket.payments');
    },

    currentPoint() {
      const pointDetail = this.$store.getters['pointHistory/pointDetail'];
      return _.get(pointDetail, 'current_point');
    },
  },

  watch: {
    payment: {
      handler(payment) {
        this.paymentEditing = { ...this.paymentEditing, ...payment };
      },
      immediate: true,
    },
  },

  methods: {
    handleClickSave() {
      if (this.paymentStatus === '미수금' && this.paymentEditing.unpaid_amount) {
        this.paymentEditing.status = 'installment_payment';
      }

      const currentPayment = { ...this.paymentEditing };
      const data = this.$utils.mapPaymentData(
        this.paymentStatus !== '미수금' ? currentPayment : { ...currentPayment, reward_point: 0, unpaid_amount: 0 },
      );

      this.$emit('save', _.omit(data));
    },

    async handleClickDelete() {
      this.$confirm('미수금 정보를 삭제하시겠습니까?', '미수금 정보 삭제')
        .then(() => this.$emit('save', this.payment.id, true))
        .catch(() => false);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-payment-dialog {
  /deep/ .el-dialog {
    @include flex(column);
    width: 90%;
    max-width: 620px;
  }

  &__buttons {
    @include flex(row, space-between, space-between);
    flex: 1;
    width: 100%;
    padding: 20px 20px 0;
    &__delete {
      @include flex(row, flex-start, flex-start);
    }
    &__group {
      @include flex(row, flex-end, flex-end);
    }
  }
}
</style>
