import moment from 'moment';
import api from '@api';
import utils from '@utils';

const DEFAULT_FILTER = {
  rangeType: 'day',
  date: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
};

const DEFAULT_LIST_FILTER = {
  paymentType: null,
  usageType: null,
  courseType: null,
  paymentMethod: null,
  staffName: [],
  ticketTitle: [],
  searchText: '',
};

export const state = {
  filter: {
    ...DEFAULT_FILTER,
  },
  activeTab: 'ticketsRevenue',
  activeSubTab: 'dashboard',
  loading: false,
  rawData: {
    day: {},
    week: {},
    month: {},
    daterange: {},
  },
  dates: [],
  summary: {
    ticketsRevenue: {},
    lecturesRevenue: {},
  },
  unpaidment: { count: 0, data: [], un_payment_amount: 0 },
  data: {
    day: {
      ticketsRevenue: {},
      lecturesRevenue: {},
    },
    week: {
      ticketsRevenue: {},
      lecturesRevenue: {},
    },
    month: {
      ticketsRevenue: {},
      lecturesRevenue: {},
    },
    daterange: {
      ticketsRevenue: {},
      lecturesRevenue: {},
    },
  },
  listFilter: {
    ...DEFAULT_LIST_FILTER,
  },
  showPasswordInput: true,
};

export const getters = {
  filter: state => state.filter,
  activeTab: state => state.activeTab,
  activeSubTab: state => state.activeSubTab,
  loading: state => state.loading,
  dates: state => state.dates,
  summary: state => state.summary,
  data: state => state.data[state.filter.rangeType],
  total: state => state.total[state.filter.rangeType],
  listFilterOptions: state => generateFilterOptions(state),
  listFilter: state => state.listFilter,
  showPasswordInput: state => state.showPasswordInput,
  unpaidment: state => state.unpaidment,
};

export const mutations = {
  SET_FILTER(state, filterOptions) {
    state.filter = !filterOptions
      ? {
          ...DEFAULT_FILTER,
        }
      : {
          ...state.filter,
          ...filterOptions,
        };
  },

  SET_ACTIVE_TAB(state, tab) {
    state.activeTab = tab;
  },

  SET_ACTIVE_SUB_TAB(state, tab) {
    state.activeSubTab = tab;
  },

  SET_DATES(state, dates) {
    state.dates = [...dates];
  },

  SET_DATA(state, { rangeType, period, data, isCurrent }) {
    if (!data) return;
    let { amount, count, salesData } = utils.mapSalesData(rangeType, period, data);

    if (rangeType === 'daterange') {
      let key = Object.keys(salesData.ticketsRevenue)[0];

      let ticketSalesData = {
        ticketsRevenue: {},
        lecturesRevenue: {},
      };
      let ticketAmount = {
        ticketsRevenue: {},
        lecturesRevenue: {},
      };
      let ticketCount = {
        ticketsRevenue: {},
        lecturesRevenue: {},
      };

      let ticketsRevenueArray = [];
      let lectureRevenueArray = [];
      let amountTicket = { total: 0, new: 0, renew: 0, upgrade: 0, refund: 0, tutorial: 0, installment_payment: 0, transfer: 0 };
      let amountLecture = { total: 0, P: 0, G: 0 };
      let countTicket = { total: 0, new: 0, renew: 0, upgrade: 0, refund: 0, tutorial: 0, installment_payment: 0, transfer: 0 };
      let countLecture = { total: 0, P: 0, G: 0 };
      const ticketType = ['total', 'new', 'renew', 'upgrade', 'refund', 'tutorial', 'installment_payment', 'transfer'];
      const lectureType = ['total', 'P', 'G'];

      for (let i = 0; i < Object.values(salesData.ticketsRevenue).length; i++) {
        ticketsRevenueArray = ticketsRevenueArray.concat(Object.values(salesData.ticketsRevenue)[i]);
        lectureRevenueArray = lectureRevenueArray.concat(Object.values(salesData.lecturesRevenue)[i]);
      }
      for (let i = 0; i < Object.values(amount.ticketsRevenue).length; i++) {
        for (let j = 0; j < ticketType.length; j++) {
          amountTicket[ticketType[j]] += Object.values(amount.ticketsRevenue)[i][ticketType[j]];
          countTicket[ticketType[j]] += Object.values(count.ticketsRevenue)[i][ticketType[j]];
        }

        for (let k = 0; k < lectureType.length; k++) {
          amountLecture[lectureType[k]] += Object.values(amount.lecturesRevenue)[i][lectureType[k]];
          countLecture[lectureType[k]] += Object.values(count.lecturesRevenue)[i][lectureType[k]];
        }
      }

      salesData = {
        ...ticketSalesData,
        ticketsRevenue: { [key]: ticketsRevenueArray },
        lecturesRevenue: { [key]: lectureRevenueArray },
      };
      amount = { ...ticketAmount, ticketsRevenue: { [key]: amountTicket }, lecturesRevenue: { [key]: amountLecture } };
      count = { ...ticketCount, ticketsRevenue: { [key]: countTicket }, lecturesRevenue: { [key]: countLecture } };
    }

    state.data[rangeType] = updateSalesData(rangeType, salesData);

    if (isCurrent) {
      state.summary = getSummary(amount, count, state.filter.date);
    }
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_LIST_FILTER(state, filterOptions) {
    state.listFilter = !filterOptions
      ? {
          ...DEFAULT_LIST_FILTER,
        }
      : {
          ...state.listFilter,
          ...filterOptions,
        };
  },

  SET_SHOW_PASSWORD_INPUT(state, value) {
    state.showPasswordInput = value;
  },

  SET_RAW_DATA(state, payload) {
    if (!payload) {
      state.rawData = {
        day: {},
        week: {},
        month: {},
        daterange: {},
      };
    } else {
      const { rangeType, date, data } = payload;
      state.rawData[rangeType][date] = {
        ...data,
      };
    }
  },

  SET_UNPAIDMENT(state, payload) {
    state.unpaidment = payload;
  },

  SET_SORT_UNPAIDMENT(state, type) {
    state.unpaidment.data = state.unpaidment.data = state.unpaidment.data.sort((prev, next) =>
      dateSort(prev, next, type),
    );

    function dateSort(prev, next, type) {
      const datePrev = new Date(prev.recentDate).getTime();
      const dateNext = new Date(next.recentDate).getTime();
      return type === 'desc' ? (datePrev < dateNext ? 1 : -1) : datePrev > dateNext ? 1 : -1;
    }
  },
};

export const actions = {
  async getInitialData({ dispatch }) {
    /**
     * 페이지 진입시 기초 데이터 가져오기
     * 기간 옵션 (일간/주간/월간) 변경시 속도를 개선하고자
     * 데이터를 미리 가져옴
     */
    await dispatch('getData', {
      date: moment().format('YYYY-MM-DD'),
      rangeType: 'day',
    });

    /** 월별, 주별 데이터 미리 불러오기 */
    /** 규모 확장에 따라 서버 과부하로 로직 제거 */

    /**
    const startDates = {
      month: moment()
        .date(1)
        .format('YYYY-MM-DD'),
      week: moment()
        .day(1)
        .format('YYYY-MM-DD'),
      daterange: moment()
        .day(1)
        .format('YYYY-MM-DD'),
    };

    const UPTO = {
      week: 0,
      month: 0,
      daterange: 0,
    };

    Object.keys(startDates).forEach(async rangeType => {
      const dates = generateDateKeys(rangeType, startDates[rangeType], UPTO);
      let period;
      await Promise.all(
        dates.map(async date => {
          period = {
            start_date: date,
            end_date: moment(date)
              .add(1, rangeType)
              .subtract(1, 'day')
              .format('YYYY-MM-DD'),
          };

          if (rangeType === 'daterange') {
            period = {
              start_date: date,
              end_date: state.filter.endDate,
            };
          }
          let data = state.rawData[rangeType][date];
          if (!data) {
            try {
              data = await fetchSalesData(period);
              commit('SET_RAW_DATA', {
                rangeType,
                date,
                data,
              });
            } catch (error) {
              data = null;
            }
            return commit('SET_DATA', {
              rangeType,
              period,
              data,
            });
          }
        }),
      );
    });
    */
  },

  async getData({ state, commit }) {
    commit('SET_LOADING', true);
    let { date, rangeType } = state.filter;

    let UPTO = {
      day: 6,
      week: 4,
      month: 3,
      daterange: 1,
    };

    UPTO = { [rangeType]: UPTO[rangeType] };

    const dates = generateDateKeys(rangeType, date, UPTO);
    commit('SET_DATES', dates);

    await Promise.all(
      dates.map(async (date, index) => {
        let period = {
          start_date: date,
          end_date: moment(date)
            .add(1, rangeType)
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
        };

        if (rangeType === 'daterange') {
          period = {
            start_date: date,
            end_date: state.filter.endDate,
          };
        }

        let data = state.rawData[rangeType][date];

        if (!data) {
          data = await fetchSalesData(period);
          commit('SET_RAW_DATA', {
            rangeType,
            date,
            data,
          });
        }

        const isCurrent = index === dates.length - 1;

        return commit('SET_DATA', {
          rangeType,
          period,
          data,
          isCurrent,
        });
      }),
    );

    commit('SET_LOADING', false);
  },

  async getUnpaymentData({ commit }) {
    try {
      commit('SET_LOADING', true);
      const unPaymentSummary = await api.sales.getunPaymentSales();
      let unpaymentDatas = [];
      unPaymentSummary.data.data.forEach(({ payments, member, user_id }) => {
        let paymentData = {
          name: member.name,
          ticketName: '',
          ticketType: '',
          totalAmount: 0,
          unpaidAmount: 0,
          recentDate: '',
          payMethod: [],
          userId: user_id,
          userTicketId: 0,
        };

        payments.forEach((payment, index) => {
          paymentData.totalAmount += payment.amount;
          payment.status === 'purchase' || payment.status === 'upgrade' || payment.status === 'extension'
            ? (paymentData.unpaidAmount += payment.unpaid_amount)
            : (paymentData.unpaidAmount -= payment.amount);

          if (index === payments.length - 1) {
            paymentData.recentDate = payment.settlement_at;
            paymentData.ticketName = payment.parent_goods.title;
            paymentData.ticketType = payment.parent_goods.available_class_type;
            paymentData.userTicketId = payment.user_goods.id;
            payment.card_amount ? paymentData.payMethod.push('카드결제') : null;
            payment.cash_amount ? paymentData.payMethod.push('현금결제') : null;
            payment.wiretransfer_amount ? paymentData.payMethod.push('계좌이체') : null;
          }
        });
        unpaymentDatas.push(paymentData);
      });

      commit('SET_UNPAIDMENT', { ...unPaymentSummary.data, data: unpaymentDatas });
    } catch (error) {
      commit('SET_UNPAIDMENT', []);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

/*====================
  Private Helpers
====================*/

/** 기초 데이터 가져오기 */
const fetchSalesData = async period => {
  try {
    const res = await Promise.all([
      api.sales.getPayments({
        ...period,
      }),
      api.sales.getLectureSales({
        ...period,
      }),
      api.sales.getDeductionSales({
        ...period,
      }),
    ]);
    return {
      payments: res[0].data,
      lectures: res[1].data,
      deducts: res[2].data,
    };
  } catch (error) {
    return {
      payments: [],
      lectures: [],
      deducts: [],
    };
  }
};

/** 리스트 필터 옵션 생성 */
const generateFilterOptions = state => {
  const paymentType = [
    {
      value: null,
      label: '결제구분 전체',
    },
    {
      value: 'new',
      label: '신규결제',
    },
    {
      value: 'renew',
      label: '재결제',
    },
    {
      value: 'upgrade',
      label: '업그레이드',
    },
    {
      value: 'transfer',
      label: '양도',
    },
    {
      value: 'refund',
      label: '환불',
    },
    {
      value: 'tutorial',
      label: '체험',
    },
    {
      value: 'installment_payment',
      label: '미수금결제',
    },
  ];
  const usageType = [
    {
      value: null,
      label: '사용구분 전체',
    },
    {
      value: 'lecture',
      label: '수업',
    },
    {
      value: 'deducted',
      label: '차감',
    },
  ];
  const courseType = [
    {
      value: null,
      label: '수업구분 전체',
    },
    {
      value: 'P',
      label: '프라이빗',
    },
    {
      value: 'G',
      label: '그룹',
    },
  ];
  const paymentMethod = [
    {
      value: null,
      label: '결제방법 전체',
    },
    {
      value: 'card',
      label: '카드',
    },
    {
      value: 'cash',
      label: '현금',
    },
  ];
  let staffName = [];
  let ticketTitle = [];

  const { filter, activeTab, data } = state;
  const { rangeType, date } = filter;
  const listData = _.get(data, `${rangeType}.${activeTab}.${date}`);
  if (listData) {
    listData.forEach(row => {
      if (row.staffName) {
        row.staffName.split(',').forEach(v => {
          const name = v.trim();
          staffName.push({
            value: name,
            label: name,
          });
        });
      }
      ticketTitle.push({
        value: row.ticketTitle,
        label: row.ticketTitle,
      });
    });
  }

  /** 중복제거 & 정렬 */
  staffName = _.sortBy(_.uniqBy(staffName, 'value'), 'value');
  ticketTitle = _.sortBy(_.uniqBy(ticketTitle, 'value'), 'value');

  return activeTab === 'ticketsRevenue'
    ? {
        paymentType,
        courseType,
        paymentMethod,
        ticketTitle,
        staffName,
      }
    : {
        usageType,
        courseType,
        staffName,
        ticketTitle,
      };
};

/** 매출 요약 데이터 생성 */
const getSummary = (amount, count, date) => {
  const KEYS = {
    ticketsRevenue: ['total', 'new', 'renew', 'upgrade', 'transfer', 'refund', 'tutorial', 'installment_payment'],
    lecturesRevenue: ['total', 'P', 'G'],
  };

  const LABEL = {
    total: '합계',
    new: '신규결제',
    renew: '재결제',
    upgrade: '업그레이드',
    transfer: '양도',
    refund: '환불',
    tutorial: '체험',
    installment_payment: '미수금결제',
    

    P: '프라이빗',
    G: '그룹',
  };

  const mapSummary = (category, keys) => {
    return keys.map(key => ({
      key,
      label: LABEL[key],
      amount: amount[category][date][key],
      count: count[category][date][key],
    }));
  };

  return {
    ticketsRevenue: mapSummary('ticketsRevenue', KEYS.ticketsRevenue),
    lecturesRevenue: mapSummary('lecturesRevenue', KEYS.lecturesRevenue),
  };
};

/** 매출 데이터 갱신 */
const updateSalesData = (rangeType, data) => {
  return {
    ticketsRevenue: {
      ...state.data[rangeType].ticketsRevenue,
      ...data.ticketsRevenue,
    },
    lecturesRevenue: {
      ...state.data[rangeType].lecturesRevenue,
      ...data.lecturesRevenue,
    },
  };
};

/** 데이터 키로 사용될 날짜 생성 */
const generateDateKeys = (rangeType, date, UPTO) => {
  const periodStartOn = moment(date)
    .subtract(UPTO[rangeType], rangeType)
    .format('YYYY-MM-DD');
  const periodEndOn = moment(date).format('YYYY-MM-DD');

  let dates = [periodStartOn];
  let lastDate = periodStartOn;

  while (moment(lastDate).isBefore(periodEndOn)) {
    lastDate = moment(lastDate)
      .add(1, rangeType)
      .format('YYYY-MM-DD');
    dates.push(lastDate);
  }

  return dates;
};
