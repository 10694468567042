<template>
  <el-dialog
    class="member-list-excel-download-dialog"
    title="회원목록 엑셀 다운로드"
    :visible.sync="show"
    :before-close="
      () => {
        onClose(false);
      }
    "
  >
    <div class="member-list-excel-download-dialog__title">
      <h4>회원 정보</h4>
      <PlainButton @click="toggleSelectedAll('member')">
        {{ !memberInfoSelectedAll ? '전체선택' : '전체해제' }}
      </PlainButton>
    </div>
    <div class="member-list-excel-download-dialog__check-list">
      <CustomCheckbox
        v-for="option in options.member"
        :key="option.value"
        :value="option.value"
        :checked="checkedValues.includes(option.value)"
        :disabled="requiredValues.includes(option.value)"
        @change="handleCheckboxChange"
        >{{ option.label }}</CustomCheckbox
      >
    </div>

    <div class="member-list-excel-download-dialog__title">
      <h4>수강권 정보</h4>
      <PlainButton @click="toggleSelectedAll('userTicket')">
        {{ !userTicketInfoSelectedAll ? '전체선택' : '전체해제' }}
      </PlainButton>
    </div>
    <div class="member-list-excel-download-dialog__check-list">
      <CustomCheckbox
        v-for="option in options.userTicket"
        :key="option.value"
        :value="option.value"
        :checked="checkedValues.includes(option.value)"
        :disabled="requiredValues.includes(option.value)"
        @change="handleCheckboxChange"
        >{{ option.label }}</CustomCheckbox
      >
      <CustomCheckbox
        value="includeExpired"
        :checked="checkedValues.includes('includeExpired')"
        :disabled="userTicketInfoUnselectedAll"
        @change="handleCheckboxChange"
        >만료된 수강권 포함</CustomCheckbox
      >
    </div>

    <div slot="footer" class="close-course-dialog__buttons">
      <el-button @click="onClose(false)">취소</el-button>
      <el-button @click="onClose(true, checkedValues)" type="primary">다운로드</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    onClose: Function,
  },

  data() {
    return {
      options: {
        member: [
          { value: 'name', label: '이름' },
          { value: 'mobile', label: '전화번호' },
          { value: 'email', label: '앱연결 이메일' },
          { value: 'appLink', label: '앱연결 여부' },
          { value: 'registeredOn', label: '등록일' },
          { value: 'lastAttendedOn', label: '최근출석일' },
          { value: 'gender', label: '성별' },
          { value: 'birthday', label: '생년월일' },
          { value: 'address', label: '주소' },
          { value: 'memo', label: '메모' },
        ],
        userTicket: [
          { value: 'ticketTitle', label: '수강권명' },
          { value: 'ticketType', label: '수강권종류' },
          { value: 'ticketPeriod', label: '기간' },
          { value: 'isShared', label: '패밀리 수강권 여부' },
          { value: 'counts', label: '횟수정보' },
          { value: 'issuedOn', label: '발급일' },
          { value: 'updatedOn', label: '최종수정일' },
          { value: 'payments', label: '결제정보' },
          { value: 'status', label: '상태' },
          { value: 'staffs', label: '담당강사' },
        ],
      },

      checkedValues: [
        'name',
        'mobile',
        'email',
        'appLink',
        'registeredOn',
        'lastAttendedOn',
        'gender',
        'birthday',
        'address',
        'memo',
        'ticketTitle',
        'ticketType',
        'ticketPeriod',
        'isShared',
        'counts',
        'issuedOn',
        'updatedOn',
        'payments',
        'status',
        'staffs',
      ],
      requiredValues: ['name'],
    };
  },

  computed: {
    memberInfoValues() {
      return this.options.member.map(({ value }) => value);
    },

    memberInfoSelectedAll() {
      return this.memberInfoValues.every(value => this.checkedValues.includes(value));
    },

    userTicketInfoValues() {
      return this.options.userTicket.map(({ value }) => value);
    },

    userTicketInfoSelectedAll() {
      return this.userTicketInfoValues.every(value => this.checkedValues.includes(value));
    },

    userTicketInfoUnselectedAll() {
      return !this.userTicketInfoValues.some(value => this.checkedValues.includes(value));
    },

    studio() {
      return this.$store.getters['studio/studio']
    }
  },

  created() {
    if (this.studio.policy.is_use_user_grade) {
      this.options.member.splice(1, 0, {value: 'grade', label: '등급'});
      this.checkedValues.push('grade');
    }
  },

  methods: {
    handleCheckboxChange(value) {
      if (this.checkedValues.includes(value)) {
        this.checkedValues = this.checkedValues.filter(v => v !== value);
        if (this.userTicketInfoUnselectedAll)
          this.checkedValues = this.checkedValues.filter(v => v !== 'includeExpired');
      } else {
        this.checkedValues.push(value);
      }
    },

    toggleSelectedAll(key) {
      const isSelectedAll = this[`${key}InfoSelectedAll`];
      const values = this[`${key}InfoValues`];

      if (isSelectedAll) {
        this.checkedValues = this.checkedValues.filter(v => !values.includes(v) || this.requiredValues.includes(v));
        if (this.userTicketInfoUnselectedAll)
          this.checkedValues = this.checkedValues.filter(v => v !== 'includeExpired');
      } else {
        this.checkedValues = this._.uniq(this.checkedValues.concat(values));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.member-list-excel-download-dialog {
  /deep/ .el-dialog {
    width: 90%;
    max-width: 600px;
  }

  /deep/ .el-dialog__body {
    padding: 0 20px;
  }

  &__title {
    @include flex(row, center, space-between);
    border-top: 1px solid $gray-200;
    padding: 20px 0;
  }

  &__check-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 5px 10px;
    padding-bottom: 20px;
  }
}
</style>
