<template>
  <el-popover trigger="hover" :visible-arrow="false" :disabled="addNew">
    <div slot="reference" class="class-list-item" @click="handleClickCard">
      <div class="class-list-item__top-border" :style="{ borderColor: instructorColor }">
        <svg width="60px" height="10px" viewBox="0 0 60 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path
            :fill="addNew ? 'transparent' : instructorColor"
            d="M0,1 L0,10 L49,10 L60,10 L60,7 L53.6794794,7 C51.3440803,7 49.1252841,5.97949817 47.6054266,4.20633099 L45.7959301,2.09525176 C44.6560369,0.765376371 42.9919398,2.29915531e-14 41.2403905,2.48689958e-14 L1,0 C0.44771525,-3.89074334e-16 -2.43241794e-16,0.44771525 -6.66133815e-16,1 Z"
          />
        </svg>
      </div>

      <div v-if="addNew" class="class-list-item__body addNew">
        <img src="@/assets/images/01_graphic/ic_24x24/line/plus_black.svg" alt="add new class" />
        <p>{{ addNewLabel }}</p>
      </div>

      <div v-else class="class-list-item__body">
        <div>
          <div class="class-list-item__body__schedule">
            <img src="@/assets/images/clock.png" alt="clock icon" />
            <span>{{ coursePeriod }}</span>
            <span>{{ schedules.weekdays }}</span>
          </div>
          <div class="class-list-item__body__trainee-count">
            <img src="@/assets/images/people.png" alt="people icon" />
            <span>{{ courseMaxTrainee }}</span>
          </div>
        </div>

        <div>
          <div class="class-list-item__body__course-meta">
            <span>{{ courseTitle }}</span>
            <span>{{ instructorName }} 강사</span>
          </div>
          <img class="class-list-item__body__instructor-image" :src="instructorImageUrl" />
        </div>
      </div>
    </div>

    <div class="class-list-item__popover" :style="{ borderTopColor: instructorColor }">
      <p>{{ coursePeriod }}</p>
      <ul class="class-list-item__popover__schedules">
        <li v-for="schedule in schedules.data" :key="schedule.id">
          <span>
            {{ schedule.weekdayIndex === 1 ? schedule.weekday : null }}
          </span>
          <span>
            {{ schedule.time }}
          </span>
        </li>
      </ul>
    </div>
  </el-popover>
</template>

<script>
export default {
  props: {
    course: { type: Object, default: () => ({}) },
    instructor: { type: Object, default: () => ({}) },
    addNew: { type: Boolean, default: false },
    addNewLabel: { type: String, default: '새로운 클래스 만들기' },
  },

  computed: {
    instructorColor() {
      const color = _.get(this.instructor, 'profile.representative_color') || '000';
      return `#${color}`;
    },

    instructorName() {
      return _.get(this.instructor, 'profile.name');
    },

    instructorImageUrl() {
      return this.$utils.getImageUrl(_.get(this.instructor, 'avatars[0]'), '30x30');
    },

    coursePeriod() {
      const { start_date, end_date } = this.course;
      if (!start_date || !end_date) return null;
      return `${this.$filters.date(start_date)}~${this.$filters.date(end_date)}`;
    },

    schedules() {
      const { schedules } = this.course;

      let weekdays = '';
      let data = [];

      if (!schedules || !schedules.length) {
        return { weekdays, data };
      }

      let weekdaysCount = { 월: 0, 화: 0, 수: 0, 목: 0, 금: 0, 토: 0, 일: 0 };
      data = schedules.map(({ id, weekday, start_time, end_time }) => {
        weekday = this.$utils.translate.weekdayNumber(weekday);
        weekdaysCount[weekday]++;

        return {
          id,
          weekday,
          time: `${start_time.slice(0, 5)} ~ ${end_time.slice(0, 5)}`,
          weekdayIndex: weekdaysCount[weekday],
        };
      });
      weekdays = Object.keys(weekdaysCount)
        .filter(key => weekdaysCount[key])
        .join(', ');

      return { weekdays, data };
    },

    courseMaxTrainee() {
      return _.get(this.course, 'max_trainee');
    },

    courseTitle() {
      return _.get(this.course, 'title');
    },
  },

  methods: {
    handleClickCard() {
      const courseId = _.get(this.course, 'id');
      if (courseId) {
        this.$emit('click', courseId);
      } else {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.class-list-item {
  @include flex(column);
  width: 203px;
  height: 120px;

  &__top-border {
    height: 11px;
    border-bottom: 2.5px solid #000;
    position: relative;

    svg {
      position: absolute;
      bottom: -2.5px;
    }
  }

  &__body {
    flex: 1;
    border: solid $gray-200;
    border-width: 0 1px 1px;
    cursor: pointer;
    padding: 10px 15px;
    transition: 0.3s box-shadow;

    &:hover {
      box-shadow: 0 2px 12px 0 rgba(#000, 0.1);
    }

    &.addNew {
      @include flex(column, center, center);

      p {
        font-size: 11px;
        font-weight: 500;
        color: #ced4da;
        margin-top: 8px;
      }
    }

    & > div {
      @include flex(row, center, space-between);

      & + div {
        border-top: 1px solid rgba(#d8d8d8, 0.4);
        margin-top: 8px;
        padding-top: 8px;
      }
    }

    &__schedule {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 2px 4px;
      align-items: center;
      font-size: 11px;

      img {
        grid-row: span 2;
        width: 10px;
        height: 10px;
        opacity: 0.3;
      }
    }

    &__trainee-count {
      font-size: 11px;

      img {
        width: 10px;
        height: 10px;
        opacity: 0.3;
        margin-right: 4px;
      }
    }

    &__course-meta {
      @include flex(column);

      span {
        font-size: 11.7px;

        &:first-child {
          font-size: 12.8px;
          font-weight: 500;
        }
      }
    }

    &__instructor-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  &__popover {
    border-top: 2.5px solid #000;
    display: grid;
    grid-template-rows: 15px 1fr;
    grid-gap: 4px;
    font-size: 11px;
    padding: 8px 16px;
    width: 203px;

    &__schedules {
      display: grid;
      grid-gap: 4px;
      max-height: 129px;
      overflow: auto;

      li {
        display: grid;
        grid-template-columns: 12px 1fr;
        grid-gap: 0 8px;
        line-height: 1.5;
      }
    }
  }
}
</style>
