<template>
  <section class="booking-history">
    <custom-tabs
      class="booking-history__tabs"
      :active_tab="currentTab"
      :tabs="tabs"
      :handleChangeTab="handleChangeTab"
    />

    <ul v-if="filteredBookings.length" v-loading="loading" class="booking-history__list" ref="bookingList">
      <li v-for="booking in filteredBookings" :key="booking.id">
        <booking :booking="booking" size="medium" />
      </li>
      <el-pagination
        class="booking-history__pagination"
        @current-change="handleChangePage"
        :current-page="currentPage"
        :page-size="10"
        layout="prev, pager, next"
        :total="currentTotal"
      />
    </ul>

    <p v-else class="booking-history__no-data">이용내역이 없습니다.</p>
  </section>
</template>

<script>
import { BOOKING_STATUS } from '@constants';

export default {
  data() {
    return {
      loading: false
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },

    tabs() {
      const { userTicketBookingsCount } = this.formData;
      const { bookingStatus } = this.$utils.translate;

      return [
        BOOKING_STATUS.BOOKED,
        BOOKING_STATUS.WAITING,
        BOOKING_STATUS.ATTENDED,
        BOOKING_STATUS.ABSENT,
        BOOKING_STATUS.NOSHOW,
        BOOKING_STATUS.CANCEL,
      ].reduce(
        (reduced, key) => {
          const count = userTicketBookingsCount[`${key}_count`] || 0;
          reduced.push({
            value: key,
            label: `${bookingStatus(key)}(${count})`,
          });
          return reduced;
        },
        [
          {
            value: 'total',
            label: `전체(${userTicketBookingsCount.total_count || 0})`,
          },
        ],
      );
    },

    // tab, page에 맞는 데이터 (vuex에서 관리)
    filteredBookings() {
      const { userTicketBookings } = this.formData;
      return userTicketBookings;
    },

    currentTab() {
      const { userTicketTab } = this.formData;
      return userTicketTab;
    },

    currentPage() {
      const { userTicketPage } = this.formData;
      return userTicketPage;
    },

    currentTotal() {
      const { userTicketTotal } = this.formData;
      return userTicketTotal;
    }
  },

  methods: {
    handleChangeTab(tabName) {
      this.filterBooking(tabName, 1);
    },

    handleChangePage(page) {
      this.filterBooking(this.currentTab, page);
    },

    // tab, page에 맞는 데이터 요청
    async filterBooking(tabName, page) {
      this.$refs.bookingList?.scrollTo({
        top: 0,
        left: 0,
      });
      const userTicketId = this.formData.userTicket.id;
      this.loading = true;
      await this.$store.dispatch('ticketEdit/getBookings', {
        ticketId: userTicketId,
        status: tabName,
        limit: 10,
        page: page
      });
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.booking-history {
  &__tabs {
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  &__list {
    flex: 1;
    overflow: auto;
    padding: 0 16px;
  }

  &__no-data {
    margin: 10% 0;
    font-size: 15px;
    opacity: 0.5;
    text-align: center;
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }
}
</style>
