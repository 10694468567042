<template>
  <el-dialog class="point-edit-final-check-dialog" :title="title" :visible.sync="show" :before-close="onClose">
    <section class="point-edit-final-check-dialog__info">
      <div class="point-edit-final-check-dialog__info__box">
        <div>
          <span>{{ currentTypeLabel }}</span>
          <span :class="`change ${isReward ? 'primary' : 'danger'}`">{{ applyPointFormat }} P</span>
        </div>

        <div v-if="isUsersDetail">
          <span>{{ currentTypeLabel }} 후 잔여 포인트</span>
          <span>{{ formatCurrentPoint }} P</span>
        </div>
      </div>

      <p v-if="isUsersDetail">
        회원의 잔여 포인트를 수정합니다.<br />
        계속 하시겠습니까?
      </p>

      <p v-else>
        {{ memberLength }}명의 잔여 포인트가 {{ currentTypeLabel }}됩니다.<br />
        계속 하시겠습니까?
      </p>
    </section>

    <section class="point-edit-final-check-dialog__button-group">
      <el-button @click="onClose">취소</el-button>
      <el-button type="primary" :loading="isLoading" @click="onSubmit">확인</el-button>
    </section>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    pointEdit: Object,
    currentPoint: Number,
    currentMemberIds: Array,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    isReward() {
      return this.pointEdit.editType === 'reward';
    },

    currentTypeLabel() {
      return this.isReward ? '적립' : '차감';
    },

    applyPointFormat() {
      let pointFormat = this.$filters.comma(this.finalPoint);
      if (!this.isReward) {
        pointFormat = '-' + pointFormat;
      }
      return pointFormat;
    },

    isUsersDetail() {
      return this.$route.path === '/users/detail';
    },

    finalPoint() {
      const { rewardPoint, deductPoint } = this.pointEdit;
      return this.isReward ? rewardPoint : deductPoint;
    },

    formatCurrentPoint() {
      const { rewardPoint, deductPoint } = this.pointEdit;
      const currentPoint = this.isReward ? this.currentPoint + rewardPoint : this.currentPoint - deductPoint;
      return this.$filters.comma(currentPoint);
    },

    memberId() {
      return this.$route.query.id;
    },

    memberLength() {
      return this.currentMemberIds.length || 0;
    },

    successMessage() {
      const isUsers = this.$route.path === '/users';
      return isUsers
        ? `${this.memberLength}명의 잔여 포인트가 ${this.currentTypeLabel}되었습니다.`
        : '잔여 포인트가 수정되었습니다.';
    },
  },

  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;
        const userIdData = this.isUsersDetail ? { user: this.memberId } : { users: this.currentMemberIds };
        const pointData = {
          ...userIdData,
          point: String(this.isReward ? this.finalPoint : this.finalPoint),
          updated_for: this.pointEdit.editReason,
        };
        const request = this.isReward ? 'postReward' : 'postDeduct';
        const batchRequest = this.isReward ? 'postBatchReward' : 'postBatchDeduct';
        const currentRequest = this.isUsersDetail ? request : batchRequest;
        await this.$api.pointHistory[currentRequest](pointData);
        this.$utils.notify.success(this, '성공', this.successMessage);
        this.$emit('close', 'point');
      } catch (error) {
        this.$utils.notify.error(this, '오류', error.response.data.message);
      } finally {
        setTimeout(() => (this.isLoading = false), 300);
      }
    },

    onClose() {
      this.$emit('close', 'point-final');
    },
  },
};
</script>

<style lang="scss" scoped>
.point-edit-final-check-dialog {
  margin-top: 10vh;

  /deep/ .el-dialog {
    width: 90%;
    max-width: 500px;

    &__header {
      margin-bottom: 26px;
      padding: 20px 20px 0;
    }

    &__body {
      padding: 0 20px 30px;
    }
  }

  &__info {
    &__box {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 18px;
      padding: 12px 16px;
      border-radius: 8px;
      background-color: $gray-100;

      > div {
        display: flex;
        justify-content: space-between;

        span {
          font-size: 15px;
        }

        .change {
          font-size: 15px;
          font-weight: 700;
        }

        .primary {
          color: $color-primary;
        }

        .danger {
          color: $color-danger;
        }
      }
    }

    > p {
      margin-bottom: 26px;
    }
  }

  &__button-group {
    text-align: right;
  }
}
</style>
