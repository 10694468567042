<template>
  <el-dialog
    class="success-charge-dialog"
    :title="modalTitle"
    width="430px"
    :visible="show"
    @close="handleClickCancel"
    append-to-body
  >
    <p>{{ payload.errorMessage }}</p>

    <div slot="footer" class="set-auto-charge-dialog__buttons">
      <el-button @click="handleClickCancel" type="primary">확인</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    handleClickCancel: Function,
    payload: {
      flag: String | undefined,
      errorMessage: String,
    },
  },
  computed: {
    modalTitle() {
      if (this.payload.flag === 'UPDATE') {
        return '금액 변경에 실패했습니다';
      }
      return '결제를 실패했습니다';
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  top: 20vh;
  height: 308px !important;
  padding: 135px 30px 30px 30px;
  &__title {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #343a40;
  }

  &__header {
    padding: 0px 0px 15px 0px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 30px;
      left: 30px;
      width: 90px;
      height: 90px;
      background: url('../../../assets/images/icons/circle_x.svg') 0 0 no-repeat;
      background-size: cover;
    }
  }

  &__body {
    font-size: 14px;
    padding: 0px 0px 30px 0px;
    color: #606266;
  }

  &__footer {
    padding: 0px 0px 0px 0px;

    .el-button {
      position: absolute;
      width: 64px;
      height: 32px;
      bottom: 30px;
      right: 30px;
      padding: 0px 0px 0px 0px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>
