<template>
  <div class="image-view-container">
    <div class="image-container">
      <button @click="close">닫기 <img src="@assets/images/icons/sales_image_close.svg" /></button>
      <img :src="imageUrl" alt="이미지" class="image" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    close: Function,
  },
};
</script>
<style lang="scss" scoped>
.image-view-container {
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;

  @include flex(row, center, center);

  .image-container {
    @include flex(column, flex-end, center);

    button {
      @include flex(row, center, center);
      font-size: 18px;
      font-weight: 500;
      color: white;
      margin-bottom: 10px;
    }

    .image {
      max-width: 50vw;
      max-height: 70vh;
      border-radius: 8px;
    }
  }
}
</style>
