<template>
  <section class="ticket-memo" v-loading="memosLoading">
    <div class="ticket-memo__header">
      <CircleButton @click="toggleMemoSortBy" size="small"><i class="el-icon-sort"/></CircleButton>
      <PlainButton @click="handleClickAddMemo" class="ticket-memo__add-memo" round :disabled="memoCreating"
        >메모 추가</PlainButton
      >
    </div>

    <!-- 메모 리스트 -->
    <ul class="ticket-memo__list" v-if="memos.length > 0 || memoCreating">
      <!-- 메모 추가 폼 -->
      <MemoCreating
        v-if="memoCreating"
        v-model="memoCreating.memo"
        :disabled="!memoCreating.memo"
        @cancel="memoCreating = null"
        @save="handleClickCreateMemo"
      />

      <!-- 메모 리스트 + 메모 수정 폼 -->
      <MemoItem
        v-for="memo in memoSorted"
        :key="memo.id"
        :memo="isEditing(memo.id) ? memoEditing : memo"
        :isEditing="isEditing(memo.id)"
        @delete="handleClickDeleteMemo"
        @edit="handleClickEditMemo"
        @cancel-edit="handleClickCancelEditMemo"
        @save="handleClickSaveEditMemo"
      />
    </ul>

    <p v-else class="ticket-memo__no-data">
      메모가 없습니다.
    </p>
  </section>
</template>

<script>
export default {
  data() {
    return {
      memoCreating: null,
      memoEditing: null,
      memoSortBy: 'desc',
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },
    userTicket() {
      return this.formData.userTicket;
    },
    memosLoading() {
      return this.formData.memosLoading;
    },
    memos() {
      return this.formData.memos;
    },
    memoSorted() {
      const memos = _.sortBy(this.memos, 'updated_at');
      if (this.memoSortBy === 'desc') memos.reverse();
      return memos;
    },
  },

  async created() {
    this.$store.dispatch('ticketEdit/getMemo', this.userTicket.id);
  },

  methods: {
    /** 메모 정렬 */
    toggleMemoSortBy() {
      this.memoSortBy = this.memoSortBy === 'asc' ? 'desc' : 'asc';
    },

    /** 메모 추가 모드 */
    handleClickAddMemo() {
      this.memoCreating = { memo: '' };
    },

    /** 메모 추가 저장 */
    async handleClickCreateMemo() {
      try {
        await this.$api.memo.create({
          ref_type: 'user_tickets',
          ref_id: this.userTicket.id,
          ...this.memoCreating,
        });
        this.$store.dispatch('ticketEdit/getMemo', this.userTicket.id);
        this.memoCreating = null;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    /** 메모 삭제 */
    async handleClickDeleteMemo(memo) {
      const title = '메모 삭제';
      const message = '메모를 삭제하시겠습니까?';
      const proceed = await this.$confirm(message, title)
        .then(() => true)
        .catch(() => false);
      if (proceed) {
        try {
          await this.$api.memo.delete(memo.id);
          this.$store.dispatch('ticketEdit/getMemo', this.userTicket.id);
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    /** 메모 수정인지 체크 */
    isEditing(memoId) {
      return this.memoEditing && this.memoEditing.id === memoId;
    },

    /** 메모 수정 모드 */
    handleClickEditMemo(memo) {
      this.memoEditing = { ...memo };
    },

    /** 메모 수정 취소 */
    handleClickCancelEditMemo() {
      this.memoEditing = null;
    },

    /** 메모 수정 내용 저장 */
    async handleClickSaveEditMemo() {
      try {
        await this.$api.memo.update(this.memoEditing);
        this.$store.dispatch('ticketEdit/getMemo', this.userTicket.id);
        this.memoEditing = null;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-memo {
  position: relative;
  padding: 24px;
  overflow: auto;

  &__header {
    @include flex(row, center, space-between);
    padding: 0 16px 16px;
  }

  &__add-memo {
    width: 100px;
  }

  &__no-data {
    color: #666666;
    font-size: 14px;
    text-align: center;
    padding: 78px 0 0;
  }
}
</style>
