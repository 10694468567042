<template>
  <div class="calendar">
    <div class="calendar__header">
      <CircleButton @click="handleClickPrevMonth" :disabled="currentMonth <= startMonth">
        <img src="@/assets/images/icons/arrow-prev.svg" alt="지난달" />
      </CircleButton>
      <span class="calendar__header__title">{{ title }}</span>
      <CircleButton @click="handleClickNextMonth" :disabled="endMonth <= currentMonth">
        <img src="@/assets/images/icons/arrow-next.svg" alt="다음달" />
      </CircleButton>
    </div>
    <div class="calendar__calendar">
      <div class="calendar__calendar__weekday" v-for="weekday in weekdays" :key="weekday">{{ weekday }}</div>

      <CircleButton
        class="calendar__calendar__day"
        :class="{
          active: date.active,
          holding: date.holding,
          'has-booking': checkIncludesDate(date, existingBookingDates),
          'month-diff': date.prevMonth || date.nextMonth,
          holiday: checkIncludesDate(date, holidayData),
        }"
        v-for="date in dates"
        :key="`${date.year}${date.month}${date.date}`"
        :disabled="isDisabled(date)"
        @click="handleClickDate(date)"
        >{{ date.date }}</CircleButton
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedDates: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    existingBookingDates: { type: Array, default: () => [] },
    holdingDates: { type: Array, default: () => [] },
    userTicketDuration: { type: Object, default: () => ({}) },
    holidayData: { type: Array, default: () => [] },
  },

  data() {
    return {
      weekdays: ['일', '월', '화', '수', '목', '금', '토'],
      current: this.moment().startOf('month'),
    };
  },

  computed: {
    title() {
      return `${this.current.year()}년 ${this.current.month() + 1}월`;
    },
    currentMonth() {
      return this.current.startOf('month').format('YYYY-MM');
    },

    startMonth() {
      const { start } = this.userTicketDuration;
      return this.moment(start)
        .startOf('month')
        .format('YYYY-MM');
    },

    endMonth() {
      const { end } = this.userTicketDuration;
      return this.moment(end)
        .startOf('month')
        .format('YYYY-MM');
    },

    dates() {
      const startOfMonth = this.moment(this.current).startOf('month');
      const startOfMonthWeekday = startOfMonth.weekday();
      const endOfMonth = this.moment(this.current).endOf('month');
      const endOfMonthWeekday = endOfMonth.weekday();

      const startOfCalendar = startOfMonth.subtract(startOfMonthWeekday, 'days');
      const endOfCalendar = endOfMonth.add(6 - endOfMonthWeekday, 'days');

      let dates = [];
      for (let date = startOfCalendar; date <= endOfCalendar; date.add(1, 'd')) {
        dates.push({
          year: date.year(),
          month: date.month(),
          date: date.date(),
          weekday: date.weekday(),
          prevMonth: this.current.month() > date.month(),
          nextMonth: this.current.month() < date.month(),
          active: this.isSelected(date),
          holding: this.holdingDates.includes(date.format('YYYY-MM-DD')),
        });
      }
      return dates;
    },
  },

  methods: {
    handleClickPrevMonth() {
      this.current = this.moment(this.current).subtract(1, 'month');
    },

    handleClickNextMonth() {
      this.current = this.moment(this.current).add(1, 'month');
    },

    isSelected(date) {
      return this.selectedDates.includes(this.moment(date).format('YYYY-MM-DD'));
    },

    /**
     * 날짜 선택 불가한 조건
     * 1. 달력 전체가 disabled 일 때 (선택된 날짜일 경우 제외: 선택해제가 가능해야 하므로)
     * 2. 수강권 기간을 벗어날 때
     * 3. 정지기간에 포함될 때
     */
    isDisabled(date) {
      const formatted = this.formatDate(date).format('YYYY-MM-DD');
      const { start, end } = this.userTicketDuration;
      const userTicketUnavailable = formatted < start || end < formatted;
      const isInHoldingPeriod = this.holdingDates.includes(formatted);

      return (this.disabled && !this.isSelected(date)) || userTicketUnavailable || isInHoldingPeriod;
    },
    checkIncludesDate(date, type) {
      const formatted = this.formatDate(date).format('YYYY-MM-DD');
      return `this.${type}`.includes(formatted);
    },
    handleClickDate(date) {
      const month = this.moment()
        .year(date.year)
        .month(date.month)
        .format('YYYY-MM');
      if (month < this.currentMonth) {
        this.handleClickPrevMonth();
      } else if (month > this.currentMonth) {
        this.handleClickNextMonth();
      }

      this.$emit('pick', this.formatDate(date));
    },

    formatDate({ year, month, date }) {
      return this.moment()
        .year(year)
        .month(month)
        .date(date)
        .startOf('day');
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  width: 300px;
  padding: 16px 0;

  &__header {
    @include flex(row, center, space-between);
    margin-bottom: 8px;

    &__title {
      font-size: 18px;
    }
  }

  &__calendar {
    display: grid;
    grid-template-columns: repeat(7, 36px);
    grid-gap: 8px;

    &__weekday,
    &__day {
      @include flex(row, center, center);
      height: 36px;
    }

    &__weekday {
      color: $gray-600;
      font-size: 12px;
    }

    &__day {
      position: relative;

      &.active {
        background: $dodger-blue;
        color: #fff;
        font-weight: bold;

        &:disabled {
          background: $dodger-blue;
        }
        &.holiday::before {
          content: '';
        }
      }

      &.holding::after,
      &.has-booking::after {
        content: '';
        background: $dodger-blue;
        position: absolute;
        top: 4px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }

      &.holding::after {
        background: $gray-500;
      }

      &.month-diff {
        opacity: 0.5;
      }

      &.holiday {
        color: $coral;
      }
      &.holiday::before {
        content: '휴일';
        font-size: 10px;
        position: absolute;
        bottom: -5px;
      }
    }
  }
}
</style>
