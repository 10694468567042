<template>
  <div class="sms" v-loading.fullscreen="loading" element-loading-text="결제 진행중...">
    <div class="current-count">
      <label>현재 잔여 포인트:</label>
      <span>{{ point | comma }}P</span>
      <span class="payment-history"><PlainButton @click="moveToPaymentInfo" type="text">결제 내역 조회</PlainButton></span>
    </div>

    <div class="products">
      <el-card
        class="product__card"
        :class="{ active: selectedProductId === product.id }"
        v-for="product in smsProducts"
        :key="product.id"
        :shadow="selectedProductId === product.id ? 'always' : 'hover'"
      >
        <div class="product" :class="{ active: selectedProductId === product.id }" @click="handleClickProduct(product)">
          <span class="product__title">{{ product.name }}</span>
          <span class="product__subtitle" :class="{ 'no-data': !product.bonus, highlight: product.id === 3 }">
            {{ product.bonus | comma }}P
          </span>
          <span class="product__price">{{ product.original_price | comma }}</span>
        </div>
      </el-card>
    </div>
    <PaymentSummary
      :smsCount="point"
      :selectedProduct="selectedProduct"
      :selectedProductCount="selectedProductCount"
      :smsCountAfterPayment="smsCountAfterPayment"
      :selectedProductPaymentAmount="selectedProductPaymentAmount"
      :selectedCardId="selectedCardId"
      :handleClickCardItem="handleClickCardItem"
      @handleClickPay="handleClickPayButton"
      @showChangePointProductModal="showChangePointProductModal"
      @showChangeAutoChargeModal="showChangePointAutoChargeModal"
      :setInitialized="initializeChildrenData"
      @handleInitialize="handleInitializeChildren"
      :setRequestPaymentData="setRequestPaymentData"
    />

    <hr />

    <CompanyProfile />

    <change-point-product-modal
      @loading="handleLoading"
      @handleClickProduct="handleClickProduct"
      :selectedProductId="selectedProductId"
      :show="showChangeProductModal"
      :handleClickCancel="closeChangePointProductModal"
      :requestPayNow="requestPayNow"
      :isAutoCharging="isAutoCharging"
      :selectedCardId="selectedCardId"
      :paymentListLength="paymentList.length"
      @showResultChargeModal="showResultChargeModal"
    />
    <point-auto-charge-modal
      @loading="handleLoading"
      :show="showPointAutoChargeModal"
      :handleClickCancel="closePointAutoChargeModal"
    />
    <point-now-charge-modal
      @loading="handleLoading"
      :show="showPointNowChargeModal"
      :handleClickCancel="closePointNowChargeModal"
      :handleClickConfirm="handleClickPayConfirm"
      :requestPayNow="requestPayNow"
      :showResultChargeModal="showResultChargeModal"
      :payload="{ amount: selectedProductPaymentAmount, point: selectedProductCount }"
    />
    <success-charge-modal :show="showSuccessChargeModal" :handleClickCancel="closeSuccessChargeModal" :payload="successPayload" />
    <fail-charge-modal :show="showFailChargeModal" :handleClickCancel="closeFailChargeModal" :payload="failPayload" />
  </div>
</template>

<script>
import CompanyProfile from '@components/shared/CompanyProfile';
import PaymentSummary from '@/components/Settings/Payments/PaymentSummary';
import ChangePointProductModal from '@/components/Modals/Payments/ChangePointProduct';
import PointAutoChargeModal from '@/components/Modals/Payments/PointAutoCharge';
import PointNowChargeModal from '@/components/Modals/Payments/PointNowCharge';
import SuccessChargeModal from '@/components/Modals/Payments/SuccessCharge';
import FailChargeModal from '@/components/Modals/Payments/FailCharge';
export default {
  components: {
    CompanyProfile,
    PaymentSummary,
    ChangePointProductModal,
    PointAutoChargeModal,
    PointNowChargeModal,
    SuccessChargeModal,
    FailChargeModal,
  },
  data() {
    return {
      kspayData: null,
      showChangeProductModal: false,
      showPointAutoChargeModal: false,
      showPointNowChargeModal: false,
      showSuccessChargeModal: false,
      showFailChargeModal: false,
      selectedProduct: null,
      successPayload: { amount: null, point: null, total: null },
      failPayload: { amount: null, point: null, total: null },
      initializeChildrenData: false,
      loading: false,
      selectedCardId: 0,
      requestPaymentData: {
        installment: 0,
      },
    };
  },
  computed: {
    selectedProductId() {
      return _.get(this.selectedProduct, 'id');
    },
    selectedProductCount() {
      return _.get(this.selectedProduct, 'point', 0) + _.get(this.selectedProduct, 'bonus', 0);
    },
    smsCountAfterPayment() {
      return this.point + this.selectedProductCount;
    },
    selectedProductPaymentAmount() {
      return parseInt(_.get(this.selectedProduct, 'actual_price'));
    },
    smsProducts() {
      return this.$store.getters['payment/smsProducts'];
    },
    paymentPolicy() {
      return this.$store.getters['payment/paymentPolicy'];
    },
    point() {
      return this.$store.getters['payment/point'];
    },
    paymentList() {
      return this.$store.getters['payment/cards'];
    },
    isAutoCharging() {
      return !!this.paymentPolicy.usable_point_auto_charge;
    },
  },
  watch: {
    paymentPolicy() {
      this.setProductSelection();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getCurrentSMSCount();
      await this.$store.dispatch('payment/getPaymentList');
      await this.$store.dispatch('payment/getSmsProducts');
      await this.setProductSelection();
    },

    async getCurrentSMSCount() {
      try {
        await this.$store.dispatch('payment/getPointScore');
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },
    setProductSelection() {
      const selected = this.smsProducts.filter(product => product.id === this.paymentPolicy.point_id);
      if (selected.length) {
        this.selectedProduct = selected[0];
        return;
      }
      this.selectedProduct = this.smsProducts[0];
    },
    handleClickProduct(product) {
      this.selectedProduct = product;
    },
    async handleClickPayButton() {
      if (!this.selectedProductId) {
        this.$utils.notify.error(this, '오류', '상품을 선택해주세요.');
        return;
      }
      if (!this.paymentList.length) {
        this.$utils.notify.error(this, '오류', '결제 카드를 추가해주세요.');
        return;
      }
      if (!this.selectedCardId) {
        this.$utils.notify.error(this, '오류', '결제 카드 리스트에서 결제할 카드를 선택해주세요.');
        return;
      }
      this.showSetPointNowChargeModal();
    },

    handleClickPayConfirm: _.debounce(async function() {
      try {
        if (this.loading) return;
        const card = this.paymentList.filter(p => p.id === this.selectedCardId);
        if (!card.length) {
          this.$utils.notify.error(this, '오류', '등록되지 않은 카드입니다.');
          return;
        }
        const { data } = await this.requestPayNow();
        await this.showResultChargeModal(data);
      } catch (error) {
        await (this.failPayload = { errorMessage: error });
        this.loading = false;
        this.closePointNowChargeModal();
        return this.showSetFailChargeModal();
      }
    }, 500),

    async requestPayNow() {
      try {
        this.loading = true;
        const result = await this.$api.payment.requestPayNow(
          {
            point_id: this.selectedProduct.id,
            card_id: this.selectedCardId,
          },
          { installment: this.requestPaymentData.installment > 1 ? this.requestPaymentData.installment : 0 },
        );
        await this.getCurrentSMSCount();
        this.loading = false;
        return result;
      } catch (error) {
        await (this.failPayload = { errorMessage: error });
        this.loading = false;
        this.closePointNowChargeModal();
        return this.showSetFailChargeModal();
      }
    },

    async showResultChargeModal(result) {
      if (result.status === 'success') {
        await (this.successPayload = {
          flag: result.flag,
          amount: this.selectedProduct.actual_price,
          point: this.selectedProduct.point,
          total: this.point,
          installment: this.requestPaymentData.installment,
        });
        this.loading = false;
        this.closePointNowChargeModal();
        this.showSetSuccessChargeModal();
      }
      if (result.status !== 'success') {
        this.loading = false;
        this.failPayload = { flag: result.flag, errorMessage: result.message };
        this.closePointNowChargeModal();
        this.showSetFailChargeModal();
        return;
      }
    },

    showChangePointProductModal() {
      this.showChangeProductModal = true;
    },

    closeChangePointProductModal() {
      this.showChangeProductModal = false;
      this.handleInitializeChildren(true);
    },

    showChangePointAutoChargeModal() {
      this.showPointAutoChargeModal = true;
    },

    closePointAutoChargeModal() {
      this.showPointAutoChargeModal = false;
      this.handleInitializeChildren(true);
    },

    showSetPointNowChargeModal() {
      this.showPointNowChargeModal = true;
    },

    closePointNowChargeModal() {
      this.showPointNowChargeModal = false;
    },

    showSetSuccessChargeModal() {
      this.showSuccessChargeModal = true;
    },
    closeSuccessChargeModal() {
      this.showSuccessChargeModal = false;
      this.init();
      return this.$router.push('/settings/payments/info').catch(() => {});
    },

    showSetFailChargeModal() {
      this.showFailChargeModal = true;
    },

    closeFailChargeModal() {
      this.showFailChargeModal = false;
    },

    handleInitializeChildren(boolean) {
      this.initializeChildrenData = boolean;
    },

    handleLoading(boolean) {
      this.loading = boolean;
    },

    handleClickCardItem(id) {
      this.selectedCardId = id;
    },

    setRequestPaymentData(data) {
      this.requestPaymentData = { ...this.requestPaymentData, ...data };
    },

    moveToPaymentInfo() {
      this.$router.push('/settings/payments/info').catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.sms {
  max-width: 60rem;
  margin: 0 auto;

  hr {
    height: 1px;
    background: #ebebeb;
    border: none;
    margin: 29.5px 0px 0px 0px;
  }
}
.current-count {
  @include flex(row, center, flex-start);
  padding: 24px 0;
  label {
    font-size: 16px;
    margin-right: 8px;
    color: #808080;
  }
  span {
    font-size: 20px;
    font-weight: bold;

    &.payment-history {
      > button {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1da0ff;
        border: none;
      }
    }

    &:first-of-type {
      margin-right: auto;
    }
  }
}
.products {
  border-top: 1px solid $gray-200;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 15rem));
  grid-gap: 1.5rem;
  padding: 24px 0px 30px 0px;
  justify-content: center;
  /deep/ .el-card__body {
    height: 100%;
    padding: 0;
  }
  .product {
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: 210px;
    height: 246px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin: auto;
    transition: 0.15s background, 0.15s color;

    &__card {
      font-family: GmarketSans;
      cursor: pointer;
      &.active {
        color: $white;
        background: #4699f1 !important;

        .product__subtitle {
          border-radius: 20px;
          background-color: $white;
          font-family: GmarketSans;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          border: 1px solid $white;
          text-align: center;
          color: #1da0ff;
        }
      }
    }

    &__title {
      font-family: GmarketSans;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      padding: 0px 0px 24px 0px;
    }
    &__subtitle {
      width: 123px;
      height: 39px;
      line-height: 39px;
      border-radius: 20px;
      border: solid 2px #a3a3a3;
      font-family: GmarketSans;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      font-weight: bold;
      color: #a3a3a3;

      &.highlight {
        border-radius: 20px;
        color: $white;
        border: solid 2px #ffcc21;
        background-color: #ffcc21;
      }
      &::before {
        content: '+ ';
        font-size: 20px;
      }
      &.no-data {
        opacity: 0;
        display: none;
      }
    }
    &__price {
      font-size: 24px;
      font-weight: bold;
      padding: 24px 0px 0px 0px;
      color: $dodger-blue;
      &::after {
        content: '원';
      }
    }
    &.active {
      .product__price {
        color: $white;
      }
    }
  }
}
.payment-summary {
  border-top: 1px solid $gray-200;
  font-size: 16px;
  padding: 24px 0;
  & > div {
    max-width: 35rem;
  }
  .sms-count {
    display: grid;
    grid-template-columns: 10rem 1fr 1fr 1fr;
    grid-gap: 8px;
    margin-bottom: 8px;
    &__current {
      text-align: right;
    }
    &__plus {
      text-align: center;
    }
    &__total {
      color: $dodger-blue;
      font-weight: bold;
    }
  }
  .payment-amount {
    display: grid;
    grid-template-columns: 10rem 1fr 2fr;
    grid-gap: 8px;
    &__amount {
      color: $dodger-blue;
      font-weight: bold;
      text-align: right;
    }
  }
}
.buttons {
  @include flex(row, center, space-between);
  border-top: 1px solid $gray-200;
  padding: 16px 0;
}
</style>
