<template>
  <el-input
    v-bind="$attrs"
    :type="type"
    :value="value"
    @input="handleInput"
    @keypress.native="validateKey"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  />
</template>

<script>
export default {
  props: {
    value: String,
    type: { type: String, default: 'text' },
  },

  methods: {
    handleInput(value) {
      if (!value) {
        return this.$emit('input', null);
      }
      let formatted = value;
      if (value.length === 8) {
        formatted = value.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3');
      } else {
        formatted = value.replace(/-/g,'');
      }
      return this.$emit('input', formatted);
    },

    validateKey(e) {
      const { key, srcElement } = e;
      const isNumber = !!key.replace(/\D/g, '');
      const isMaxLength = srcElement.value.replace(/-/g, '').length >= 8;
      if (!isNumber || isMaxLength) e.preventDefault();
    },
  },
};
</script>
