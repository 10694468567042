<template>
  <div class="payment-info">
    <FormBlock :unNumbering="true" :index="0" label="포인트 충전 금액">
      <p v-if="currentProduct" class="info-txt">
        <span class="info-price" v-if="currentProduct"
          >{{ currentProductLabel }}/{{ currentProduct.original_price | comma }}원</span
        >
        <span>부가세 별도</span>
      </p>
      <p v-if="!currentProduct" class="info-txt">
        <span class="info-icon" />
        <span
          >자동 충전을 설정하지 않으셨어요.<br />
          포인트 걱정 없이 편하게 사용할 수 있는 자동 충전을 설정해 보세요!</span
        >
      </p>
      <PlainButton slot="checkbox" round @click="moveToPayment" class="btn-blue"> {{ changeProductButtonText }} </PlainButton>
    </FormBlock>

    <FormBlock v-if="usablePointAutoCharge" :unNumbering="true" :index="0" label="정기 결제 관리">
      <ul class="payment-info__payment-summary">
        <li class="flex-row">
          <label>정기 결제 카드</label>
          <span v-if="subscriptionCard">
            {{ `${subscriptionCard.card_name}${getLastFourDigits(subscriptionCard)}` }}
            <button class="btn-text-grey" @click="showPaymentListModal">
              {{ changeCardButtonText }}
            </button>
          </span>
        </li>
        <li class="flex-row">
          <label>결제 결과를 받을 휴대전화</label>
          <span>
            <PhoneNumberInput
              v-model="chargeNotificationNumber"
              placeholder="결제 결과를 받을 번호를 입력해 주세요"
              :class="phoneNumberInputClass"
            />
            <p class="warning" v-if="phoneNumberErrorMessage">{{ phoneNumberErrorMessage }}</p>
            <button @click="handleClickChangeChargeNotificationNumber" :class="phoneNumberButtonClass">저장하기</button>
          </span>
        </li>
      </ul>
    </FormBlock>

    <FormBlock :unNumbering="true" :index="0" label="결제 내역" v-loading="loading" class="monthly-table">
      <div class="wrap-table">
        <el-table :data="filteredPayments" fit>
          <el-table-column label="결제 일시" align="center" width="200" :formatter="formatDatetime" />
          <el-table-column label="결제 수단" align="center" width="" :formatter="formatMethod" />
          <el-table-column label="주문번호" align="center" min-width="160" prop="order_no" />
          <el-table-column label="결제 금액" align="center" width="200" :formatter="formatAmount" />
          <el-table-column label="지급 포인트" align="center" width="100" :formatter="formatCountAdded" />
        </el-table>
      </div>
      <el-pagination
        class="payment-info__pagination"
        @current-change="getSMSPayments"
        :current-page.sync="params.page"
        :page-size="params.limit"
        layout="prev, pager, next"
        :total="total"
        v-if="total"
      />
    </FormBlock>

    <RegistCard :show="registCardModalShow" :close="closeRegistCardModal" />
    <payment-list-modal
      :show="showPaymentList"
      :handleClickCancel="closePaymentListModal"
      :paymentList="paymentList"
      :showRegistCardModal="showRegistCardModal"
    />
  </div>
</template>

<script>
import RegistCard from '@components/Modals/Payments/RegistCard';
import PaymentListModal from '@components/Modals/ServicePayments/VerticalPaymentList';

export default {
  components: {
    RegistCard,
    PaymentListModal,
  },
  props: {
    active_tab: String,
  },
  data() {
    return {
      paymentsHistory: null,
      params: { page: 1, limit: 10 },
      total: 0,
      loading: false,
      chargeNotificationNumber: '',
      registCardModalShow: false,
      showPaymentList: false,
      servicePaymentProductType: '',
      startDate: this.moment()
        .subtract(3, 'month')
        .format('YYYY-MM-DD'),
      endDate: this.moment().format('YYYY-MM-DD'),
      payments: [],
    };
  },
  computed: {
    filteredPayments() {
      return this.paymentsHistory;
    },

    studioData() {
      return this.$store.getters['studio/studio'];
    },

    studioExpireDate() {
      return this.studioData.expire_on;
    },

    smsProducts() {
      return this.$store.getters['payment/smsProducts'];
    },

    currentProduct() {
      if (!this.productId) return null;
      return this.smsProducts.filter(p => p.id === this.productId)[0];
    },

    productId() {
      return this.paymentPolicy.point_id;
    },

    reservedPayDate() {
      return this.paymentPolicy.usable_subscription_auto_charge && this.studioExpireDate;
    },

    usablePointAutoCharge() {
      return this.productId && this.paymentPolicy.usable_point_auto_charge;
    },

    changeCardButtonText() {
      if (!this.paymentList.length) return '카드 추가';
      if (!this.paymentPolicy.point_card_id) return '카드 선택';
      return '카드 변경';
    },

    paymentList() {
      return this.$store.getters['payment/cards'];
    },

    paymentPolicy() {
      return this.$store.getters['payment/paymentPolicy'];
    },

    subscriptionCard() {
      return this.paymentList.filter(card => card.id === this.paymentPolicy.point_card_id)[0];
    },

    currentProductLabel() {
      return this.currentProduct.name.toUpperCase();
    },

    phoneNumberInputClass() {
      const isSame = this.paymentPolicy.point_charging_payment_notification_number == this.chargeNotificationNumber;
      if (!this.chargeNotificationNumber || isSame) return 'default';
      return this.phoneNumberErrorMessage ? 'warning' : 'valid';
    },

    phoneNumberErrorMessage() {
      if (!this.chargeNotificationNumber) return '';
      const phoneNumberValidation = /^01([0|1|6|7|9])\d{7,8}$/;
      if (!phoneNumberValidation.test(this.chargeNotificationNumber)) {
        return '핸드폰 번호만 입력이 가능합니다';
      }
      return '';
    },

    phoneNumberButtonClass() {
      if (!this.chargeNotificationNumber) return 'btn-disabled';
      const isSame = this.paymentPolicy.point_charging_payment_notification_number == this.chargeNotificationNumber;
      return `btn-${!this.phoneNumberErrorMessage && !isSame ? 'valid' : 'disabled'}`;
    },

    startDatePickerOptions() {
      const { moment } = this;
      const end = moment(this.endDate).format('YYYYMMDD');

      return {
        disabledDate(date) {
          date = moment(date).format('YYYYMMDD');
          const aYearAgo = moment()
            .subtract(3, 'year')
            .format('YYYYMMDD');
          return date < aYearAgo || date > end;
        },
      };
    },

    endDatePickerOptions() {
      const { moment } = this;
      const start = moment(this.startDate).format('YYYYMMDD');

      return {
        disabledDate(date) {
          date = moment(date).format('YYYYMMDD');
          const today = moment().format('YYYYMMDD');
          return today < date || start > date;
        },
      };
    },

    changeProductButtonText() {
      if (!this.currentProduct) {
        return '금액 설정';
      } else return '금액 변경';
    },
  },

  created() {
    this.initializePaymentData();
    this.setServicePaymentProductType();
  },

  watch: {
    show: {
      handler() {
        this.getSMSPayments();
      },
      immediate: true,
    },

    paymentPolicy() {
      this.chargeNotificationNumber = this.paymentPolicy.point_charging_payment_notification_number;
    },

    startDate: function() {
      this.getSMSPayments();
    },
    endDate: function() {
      this.getSMSPayments();
    },
  },

  methods: {
    async initializePaymentData() {
      await this.$store.dispatch('payment/getPaymentPolicy');
      await this.$store.dispatch('payment/getPaymentList');
      await this.$store.dispatch('payment/getServicePlanProducts');
    },

    formatDataCard(data) {
      if (data.card_number === '-') return '계좌 이체';
      return `${data.card_name} ${this.$filters.cardMask(data.card_number)}`;
    },

    formStatus(data) {
      switch (data.status) {
        case 'purchase':
          return '결제 완료';
        case 'pending':
          return '결제 실패';
        case 'fail':
          return '결제 실패';
        case 'refund':
          return '환불';
        default:
          return '';
      }
    },
    formPlan(data) {
      return data.grade.toUpperCase();
    },
    formPeriod(data) {
      if (data.quantity === 1) return '월정기결제';
      return '연결제';
    },
    formPrice(data) {
      return `${this.$filters.comma(data.price)}원`;
    },
    moveToPayment() {
      this.$router.push('/settings/payments').catch(() => {});
    },
    async handleClickChangeChargeNotificationNumber() {
      if (!this.chargeNotificationNumber) {
        return this.$utils.notify.error(this, '오류', '결제 알림 휴대폰 번호를 입력해주세요.');
      }
      await this.dispatchUpdatePayment({ point_charging_payment_notification_number: this.chargeNotificationNumber });
      await this.$utils.notify.success(this, '확인', '결제 알림 휴대폰 번호가 변경되었습니다.');
    },

    async dispatchUpdatePayment(data) {
      await this.$store.dispatch('payment/updatePaymentPolicy', data);
    },
    showRegistCardModal() {
      this.registCardModalShow = true;
    },

    closeRegistCardModal() {
      this.registCardModalShow = false;
    },

    showPaymentListModal() {
      this.showPaymentList = true;
    },

    closePaymentListModal() {
      this.showPaymentList = false;
    },
    getLastFourDigits({ card_number }) {
      return card_number ? `(${card_number.slice(-4)})` : '';
    },

    async getSMSPayments() {
      try {
        this.loading = true;
        const params = {
          ...this.params,
          start_date: this.startDate,
          end_date: this.endDate,
        };
        const res = await this.$api.payment.getSMSPaymentHistory(params);
        this.paymentsHistory = res.data.data;
        this.total = res.data.meta.total;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    formatDatetime({ settlement_at }) {
      return settlement_at;
    },

    formatMethod() {
      return '카드';
    },

    formatAmount({ actual_price }) {
      return `${this.$filters.comma(actual_price)}원`;
    },

    formatCountAdded({ quantity }) {
      return `${this.$filters.comma(quantity)}P`;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-info {
  .info-txt {
    display: flex;
    justify-content: flex-start;
    position: relative;
    font-size: 16px;
    color: #808080;
    padding: 0px 16px 0px 0px;

    .info-icon {
      position: absolute;
      content: '';
      width: 52px;
      height: 52px;
      padding: 7px;
      border-radius: 50%;
      background-color: #f5fbff;

      &:before {
        content: '';
        width: 38px;
        height: 38px;
        position: absolute;
        top: calc(50% - 19px);
        left: -0.8.4px;
        background: url('../../../assets/images/icons/auto-payment-blue.svg');
        background-size: cover;
      }

      & + span {
        padding: 0px 0px 0px 67px;
      }
    }

    .info-price {
      + span {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #808080;
        padding: 0px 0px 0px 16px;
      }
    }
  }
  span {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: $charcoal-grey;
  }
  .form-block {
    &__label {
      margin-bottom: 15px;
    }
    &.monthly-table {
      .wrap-table {
        padding: 0 8px 10px;
      }

      /deep/ .form-block__content {
        border-bottom: none;
      }
    }
  }

  .flex {
    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-col {
      display: flex;
      flex-direction: column;
    }
  }

  &__payment-summary {
    > li {
      position: relative;
      width: 100%;
      min-height: 78px;
      padding: 15px 25px 15px 63px;

      label {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 48px;
        letter-spacing: normal;
        text-align: left;
        color: #808080;
      }

      label + span {
        padding: 0px 0px 0px 16px;
      }

      &:nth-child(1) {
        padding: 15px 25px 15px 59px;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: calc(50% - 24px);
          left: 0px;
          width: 48px;
          height: 48px;
          background: url('../../../assets/images/icons/credit-card.svg') 0 0 no-repeat;
          background-size: cover;
        }
      }
      &:nth-child(2) {
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 15px;
          left: 0px;
          width: 48px;
          height: 48px;
          background: url('../../../assets/images/icons/cell-phone.svg') 0 0 no-repeat;
          background-size: cover;
        }

        .el-input {
          width: 253px !important;

          &::placeholder {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #bababa;
          }
          + .warning {
            font-size: 12px;
            color: $coral;
            position: absolute;
            top: 48px;
          }
        }

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }
      }

      /deep/.default .el-input__inner:hover,
      /deep/.default .el-input__inner:focus {
        border-bottom: 2px solid #eeeeee;
      }

      /deep/.valid .el-input__inner,
      /deep/.valid .el-input__inner:hover {
        border-bottom: 2px solid $dodger-blue !important;
      }

      /deep/ .warning .el-input__inner,
      /deep/.warning .el-input__inner:focus {
        border: none;
        border-bottom: 2px solid #f45354 !important;
      }

      > * {
        width: 50%;
      }
    }
  }
  .form-block {
    position: relative;

    .btn {
      &-text-grey {
        position: absolute;
        right: 0px;
        width: 114px;
        height: 40px;
        border: solid 1px #8a8a8a;
        border-radius: 2px;
        font-size: 14px;
        text-align: center;
        color: #8a8a8a;
      }
      &-blue {
        position: absolute;
        right: 0px;
        bottom: 34.5px;
        width: 114px;
        height: 40px;
        background-color: #4699f1;
        color: $white;
        border-radius: 2px;
        z-index: 1;
      }
      &-disabled {
        position: absolute;
        right: 0px;
        width: 114px;
        height: 36px;
        border-radius: 2px;
        background: #b5b5b5;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        margin-left: 57px;
        pointer-events: none;
      }
      &-valid {
        position: absolute;
        right: 0px;
        width: 114px;
        height: 36px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1da0ff;
        margin-left: 57px;
        border: solid 1px $dodger-blue;
        background: $white;
      }

      font-size: 14px;
      border-radius: 2px;
      font-weight: 500;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding: 22px 0px 0px 0px;
  }
}
</style>
