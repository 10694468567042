<template>
  <section class="wrap-intro" :style="bgUrl">
    <IntroTopTitle :useText="useText" :mainText="mainText" :subText="subText" />
    <IntroNumberKeypad @showList="handleCheckin" @screenStep="handleCheckin" :fullscreen="fullscreen" />
  </section>
</template>

<script>
import IntroTopTitle from '@/components/Checkin/IntroTopTitle';
import IntroNumberKeypad from '@/components/Checkin/IntroNumberKeypad';

export default {
  components: {
    IntroTopTitle,
    IntroNumberKeypad,
  },

  props: {
    fullscreen: Boolean,
  },

  data() {
    return {
      bgUrl: '',
      policyAttachment: {},
      useText: false,
      mainText: '',
      subText: '',
    };
  },

  async beforeMount() {
    this.policyAttachment = this.$store.getters['studio/studio'].policy.attachment;
    this.checkImg();

    const text = this.$store.getters['studio/studio'].policy.check_in_msg;

    if (text) {
      this.initText(text);
      this.useText = true;
    }
  },

  methods: {
    initText(text) {
      [this.mainText, this.subText] = text.split('$$');
    },

    handleCheckin(dataSet) {
      this.$emit('showListComponent', dataSet);
    },

    //커스텀이미지가 있는지 체크
    checkImg() {
      if (this.policyAttachment) {
        const url = this.getImageUrl(this.policyAttachment.path, 0);
        return (this.bgUrl = `backgroundImage: url(${url})`);
      }
      return (this.bgUrl = `backgroundImage: url(${require('@/assets/images/bg-checkin.jpg')})`);
    },

    getImageUrl(path, size) {
      return this.$utils.getImagePath(path, size);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-intro {
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  @include flex(column, flex-end);
  height: 100%;
  background-size: cover;
  background-position: bottom left;
  font-family: $spoqa-han-sans;
}
</style>
