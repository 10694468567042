<template>
  <ul class="wrap-set-date-list">
    <div class="selected-date">
      <el-date-picker v-model="date" type="date" format="yyyy. M. d." value-format="yyyy-MM-dd" :clearable="false" readonly />
      <span>{{ weekday }}</span>
    </div>
    <div>
      <div class="wrap-line">
        <span>근무시간</span>
        <HoursInput
          v-model="worktime"
          :startHourOptions="{
            start: '00:00',
            end: '23:50',
            step: '00:05',
          }"
          :endHourOptions="{
            start: '00:00',
            end: '23:55',
            step: '00:05',
          }"
          :clearable="false"
          showDuration
          :disabled="value.isHoliday"
        />
        <el-button size="mini" v-if="value.isReserved" @click="editTimes('removeExisItem', value)" circle
          ><i class="el-icon-close"></i
        ></el-button>
        <el-checkbox class="check-holiday" v-model="value.isHoliday" label="휴무일" />
        <el-button size="mini" v-if="value.isFirstWeekday" @click="updateOtherSetTimes(value)" round>
          같은 요일에 일괄 적용
        </el-button>
      </div>
      <div class="wrap-line" v-if="!this.restTime.length">
        <el-button
          size="mini"
          round
          @click="editTimes('addRestTime', { date: value.date, add_number: '00' })"
          :disabled="value.isHoliday"
        >
          휴식시간 추가
          <i class="el-icon-plus"></i>
        </el-button>
      </div>
      <div class="wrap-line" v-else v-for="(el, index) in restTime" :key="`${el}${index}`">
        <span>휴식시간</span>
        <HoursInput
          v-model="restTime[index]"
          :startHourOptions="{
            start: '00:05',
            end: $utils.convertTime.subtractThirtyMinutes(value.open[0].end),
            step: '00:05',
            minTime: worktime.start,
          }"
          :endHourOptions="{
            start: restTime[index].start || value.open[0].start,
            end: value.open[0].end,
            step: '00:05',
            minTime: restTime[index].start,
          }"
          :clearable="false"
          showDuration
          :disabled="value.isHoliday"
        />
        <el-button
          size="mini"
          :disabled="value.isHoliday"
          @click="editTimes('removeRestTime', { date: value.date, remove_no: index })"
          circle
          ><i class="el-icon-minus"></i
        ></el-button>
        <el-button
          v-if="restTime.length - 1 === index"
          :disabled="value.isHoliday"
          size="mini"
          @click="editTimes('addRestTime', { date: value.date, add_number: index })"
          circle
          ><i class="el-icon-plus"></i
        ></el-button>
      </div>
    </div>
  </ul>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    date: {
      get() {
        return this.value.date;
      },
      set(date) {
        this.$emit('input', { ...this.value, date });
      },
    },
    weekday() {
      return this.moment(this.value.date).format('(ddd)');
    },
    worktime: {
      get() {
        const { start, end } = this.value.open[0];
        return { start, end };
      },
      set(worktime) {
        this.$emit('input', { ...this.value, open: [worktime] });
      },
    },
    restTime() {
      return this.value.closed;
    },
  },

  methods: {
    updateOtherSetTimes(datas) {
      this.$emit('updateTimes', datas);
    },
    sortRestTime() {
      this.restTime = _.sortBy(this.restTime, ['start']);
    },
    editTimes(type, datas) {
      if (type === 'removeRestTime') {
        this.$emit('removeRestRow', datas);
      } else if (type === 'addRestTime') {
        this.$emit('addRestRow', datas);
      } else if (type === 'removeExisItem') {
        this.$emit('removeExistData', datas);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-set-date-list {
  padding: 10px 0 40px;
  display: grid;
  grid-template-columns: 160px 1fr;
  &:last-child {
    border-bottom: none;
  }
  .selected-date {
    .el-checkbox {
      margin: 5px 0 0 5px;
    }
  }
  .wrap-line {
    & + .wrap-line {
      margin-top: 10px;
    }
    @include flex(row, center, flex-start);
    button.is-circle {
      margin-left: 4px;
    }
    .hours-input {
      padding: 0 10px;
    }
    .check-holiday {
      margin: 0 20px 0 40px;
    }
  }
}
</style>
