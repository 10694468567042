<template>
  <el-dialog class="ticket-edit-modal" :visible.sync="show" :before-close="handleClose" :close-on-click-modal="false">
    <div slot="title" class="ticket-edit-modal__title">
      <h4>{{ memberName }}님의 {{ title }}</h4>
      <div class="ticket-edit-modal__title__buttons">
        <div v-if="!isProduct">
          <PlainButton
            v-if="showUpgradeTransferDisableButton"
            :disabled="upgradeAndRefundDisabled"
            round
            @click="openTicketUpgradeModal"
            >업그레이드</PlainButton
          >
        </div>
        <PlainButton
          v-if="showUpgradeTransferDisableButton"
          :disabled="upgradeAndRefundDisabled"
          round
          @click="openTicketTransferModal"
          class="button-transfer"
          >양도</PlainButton
        >
        <PlainButton
          v-if="showRefundButton"
          :disabled="upgradeAndRefundDisabled"
          type="danger"
          round
          @click="openTicketRefundModal"
          >환불</PlainButton
        >
        <PlainButton v-if="showUpgradeTransferDisableButton" type="danger" round @click="handleClickDisableTicket"
          >사용불가 처리</PlainButton
        >
        <PlainButton v-if="showDeleteButton" type="danger" round @click="handleClickDeleteTicket">삭제</PlainButton>
        <PlainButton v-if="showRestoreButton" round @click="handleClickRestoreTicket">사용재개</PlainButton>
        <p v-if="isTicketDeleted" style="color: #f95454;">
          삭제된 수강권입니다.
        </p>
      </div>
    </div>

    <TicketSummary />

    <CustomTabs class="ticket-edit-modal__tabs" :active_tab="currentTab" :tabs="tabs" :handleChangeTab="handleChangeTab" />

    <BookingHistory v-if="currentTab === 'bookingHistory'" class="ticket-edit-modal__content set-max-height" />
    <TicketDetail v-if="currentTab === 'ticketDetail'" class="ticket-edit-modal__content" :title="title" :isProduct="isProduct" />
    <PaymentDetail v-if="currentTab === 'paymentDetail'" class="ticket-edit-modal__content" :currentPoint="currentPoint" />
    <HoldingDetail v-if="currentTab === 'holdings'" class="ticket-edit-modal__content set-max-height" :title="title" />
    <TicketHistory v-if="currentTab === 'ticketHistory'" class="ticket-edit-modal__content set-max-height" :title="title" />
    <TicketMemo v-if="currentTab === 'ticketMemo'" class="ticket-edit-modal__content set-max-height" />

    <TicketUpgradeModal
      v-if="showTicketUpgradeModal"
      :show="showTicketUpgradeModal"
      @close="handleCloseTicketUpgrade"
      @save="handleTicketUpgrade"
      :currentPoint="currentPoint"
    />

    <TicketTransferModal
      v-if="showTicketTransferModal"
      :show="showTicketTransferModal"
      :title="title"
      :isProduct="isProduct"
      @close="handleCloseTicketTransfer"
      :currentPoint="currentPoint"
    />

    <TicketRefundModal
      v-if="showTicketRefundModal"
      :show="showTicketRefundModal"
      :title="title"
      @close="handleCloseTicketRefund"
      @save="handleTicketRefund"
    />
  </el-dialog>
</template>

<script>
import TicketSummary from './TicketSummary';
import BookingHistory from './BookingHistory';
import TicketDetail from './TicketDetail';
import PaymentDetail from './PaymentDetail';
import HoldingDetail from './HoldingDetail';
import TicketHistory from './TicketHistory';
import TicketMemo from './TicketMemo';
import TicketUpgradeModal from '@/components/Modals/TicketUpgrade';
import TicketTransferModal from '@/components/Modals/TicketTransfer';
import TicketRefundModal from '@/components/Modals/TicketRefund';
import { PAYMENT_VAR } from '@constants';

export default {
  components: {
    TicketSummary,
    BookingHistory,
    TicketDetail,
    PaymentDetail,
    HoldingDetail,
    TicketHistory,
    TicketMemo,
    TicketUpgradeModal,
    TicketTransferModal,
    TicketRefundModal,
  },

  props: {
    show: Boolean,
    isProduct: Boolean,
  },

  data() {
    return {
      showTicketUpgradeModal: false,
      showTicketTransferModal: false,
      showTicketRefundModal: false,
    };
  },

  computed: {
    title() {
      return this.isProduct ? '상품' : '수강권';
    },
    currentTab() {
      return _.get(this.formData, 'currentTab');
    },
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },
    member() {
      return _.get(this.formData, 'userTicket.member');
    },
    memberName() {
      return _.get(this.member, 'name');
    },
    userTicket() {
      return _.get(this.formData, 'userTicket');
    },
    isTicketDeleted() {
      return !!_.get(this.userTicket, 'ticket.deleted_at');
    },
    payments() {
      return _.get(this.formData, 'userTicket.payments') || [];
    },

    isRefunded() {
      const { payments } = this.userTicket;
      return payments[payments.length - 1].status === 'refund';
    },

    isSaleProduct() {
      const { ticket } = this.userTicket;
      return ticket.type === 'S';
    },

    isRentalOnlyTime() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RT';
    },

    ticketStatus() {
      return !this.userTicket
        ? null
        : this.$utils
            .getTicketStatus(this.userTicket)
            .split('(')[0]
            .trim();
    },

    showUpgradeTransferDisableButton() {
      const isInactive = ['사용불가', '이용만료', '환불', '양도', '판매'].includes(this.ticketStatus);
      return !isInactive && this.canUpdateMembersTicket;
    },

    showRefundButton() {
      const isInactive = ['사용불가', '이용만료', '환불', '양도'].includes(this.ticketStatus);
      return !isInactive && this.canUpdateMembersTicket;
    },

    showDeleteButton() {
      return (
        ['사용불가', '환불', '양도', '판매'].includes(this.ticketStatus) && this.canUpdateMembersTicket && !this.isTicketDeleted
      );
    },

    showRestoreButton() {
      return this.ticketStatus === '사용불가' && this.canUpdateMembersTicket && !this.isTicketDeleted;
    },

    upgradeAndRefundDisabled() {
      let compareAmount = { paid: 0, unpaid: 0 };
      this.payments.map(({ status, amount, unpaid_amount }) => {
        if (status === PAYMENT_VAR.installment_payment || status === PAYMENT_VAR.full_payment) {
          compareAmount.paid += amount;
        }
        compareAmount.unpaid += unpaid_amount;
      });
      return compareAmount.paid - compareAmount.unpaid >= 0 ? false : true;
    },

    isHolding() {
      const { moment } = this;
      const { is_holding, holdings } = this.userTicket;
      const isFuture = holdings.filter(holding => {
        const startOn = moment(holding.start_on);
        if (startOn.startOf('day').isAfter(moment().startOf('day'))) {
          return holding;
        }
      });
      return is_holding || isFuture.length;
    },

    tabs() {
      const tabs = [
        { value: 'bookingHistory', label: '예약내역' },
        { value: 'ticketDetail', label: `${this.title}정보` },
        { value: 'paymentDetail', label: '결제정보' },
        { value: 'holdings', label: '정지기간정보' },
        { value: 'ticketHistory', label: '변경이력' },
        { value: 'ticketMemo', label: '메모' },
      ];

      if (this.isRentalOnlyTime || this.isSaleProduct) {
        const removeKeys = ['bookingHistory', 'holdings'];
        return tabs.filter(tab => !removeKeys.includes(tab.value));
      } else if (this.isProduct) {
        return tabs.filter(tab => tab.value !== 'bookingHistory');
      }

      return tabs;
    },

    currentPoint() {
      const pointDetail = this.$store.getters['pointHistory/pointDetail'];
      return _.get(pointDetail, 'current_point');
    },
  },

  created() {
    if (this.isProduct) {
      this.handleChangeTab('ticketDetail');
    }
    this.$store.dispatch('pointHistory/getPointDetail', this.member.id);
  },

  methods: {
    handleChangeTab(currentTab) {
      this.$store.commit('ticketEdit/SET_VALUES', { currentTab });
    },

    openTicketUpgradeModal() {
      this.showTicketUpgradeModal = true;
    },

    handleCloseTicketUpgrade() {
      this.showTicketUpgradeModal = false;
    },

    openTicketTransferModal() {
      if (this.isHolding) {
        this.$utils.notify.error(this, '오류', `정지중(정지 예정)인 ${this.title}은 양도할 수 없습니다.`);
      } else {
        this.showTicketTransferModal = true;
      }
    },

    handleCloseTicketTransfer() {
      this.showTicketTransferModal = false;
    },

    openTicketRefundModal() {
      this.showTicketRefundModal = true;
    },

    handleCloseTicketRefund() {
      this.showTicketRefundModal = false;
    },

    handleTicketUpgrade(userTicketId) {
      this.showTicketUpgradeModal = false;
      this.$store.dispatch('ticketEdit/getUserTicket', userTicketId);
    },

    handleTicketRefund(userTicketId) {
      this.showTicketRefundModal = false;
      this.$store.dispatch('ticketEdit/getUserTicket', userTicketId);
    },

    async handleClickDisableTicket() {
      const title = '사용불가 처리';
      const message = `${
        this.isProduct ? '' : `<p style="color: #f95454;">예약되어 있는 수업은 자동으로 취소됩니다.</p>`
      } <p>사유를 입력해주세요.</p>`;

      const { proceed, reason } = await this.$prompt(message, title, {
        inputPattern: /^[가-힣|a-z|A-Z|0-9|\s]{0,20}$/,
        inputErrorMessage: '한글, 영문, 숫자 20자 이내로 입력해주세요.',
        dangerouslyUseHTMLString: true,
      })
        .then(({ value }) => ({ proceed: true, reason: value }))
        .catch(() => ({ proceed: false }));

      if (!proceed) return;

      try {
        const { activeToggle } = this.$api.userTicket;
        const res = await activeToggle(this.userTicket.id, reason);
        this.$store.commit('ticketEdit/SET_VALUES', { userTicket: res.data });
        this.$store.dispatch('ticketEdit/getHistory', this.userTicket.id);
        this.$utils.notify.success(this, '확인', `${this.title} 사용불가 처리되었습니다.`);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    async handleClickDeleteTicket() {
      const title = `${this.title} 삭제`;
      const message = `
          <p style="color: #f95454;">삭제된 ${this.title}은 복구할 수 없으며, 결제 내역도 확인할 수 없습니다.</p>
          <p>${this.title}을 삭제하시겠습니까?</p>
        `;

      const proceed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (!proceed) return;

      try {
        await this.$api.userTicket.delete(this.userTicket.id);
        this.$utils.notify.success(this, '확인', `${this.title}이 삭제되었습니다.`);
        this.$emit('close');
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    async handleClickRestoreTicket() {
      const title = `${this.title} 사용재개`;
      const message = `${this.title} 사용재개 하시겠습니까?`;

      const proceed = await this.$confirm(message, title)
        .then(() => true)
        .catch(() => false);

      if (!proceed) return;

      try {
        const { activeToggle } = this.$api.userTicket;
        const res = await activeToggle(this.userTicket.id);
        this.$store.commit('ticketEdit/SET_VALUES', { userTicket: res.data });
        this.$store.dispatch('ticketEdit/getHistory', this.userTicket.id);
        this.$utils.notify.success(this, '확인', `${this.title} 사용재개 처리되었습니다.`);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    handleClose() {
      this.$store.commit('ticketEdit/SET_VALUES');
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-edit-modal {
  /deep/ .el-dialog {
    @include flex(column);
    width: 90%;
    max-width: 640px;
  }

  &__title {
    @include flex(row, center, space-between);
    margin-right: 50px;

    &__buttons {
      .button-transfer {
        border-color: #2a66c9;
        color: #2a66c9;
      }
      button {
        margin-left: 8px;
      }
      div {
        display: inline-block;
      }
    }
  }

  &__tabs {
    border-bottom: 1px solid #eee;
    justify-content: flex-start;
    padding-top: 32px;
  }

  &__content {
    @include flex(column);
    min-height: 370px;

    &.set-max-height {
      max-height: 370px;
    }
  }
}
</style>
