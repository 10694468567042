<template>
  <el-dialog class="point-storage-dialog" :visible="show" @close="$emit('close', false)" append-to-body>
    <h4 slot="title">{{ isReserve ? (isId ? '메시지 예약 수정' : '메시지 예약') : '메시지 보내기' }}</h4>
    <div class="point-storage-dialog__header">
      <p>{{ count }}건{{ isReserve ? (isId ? ' 발송 수정합니다!' : ' 발송 예약합니다!') : '을 즉시 발송합니다!' }}</p>
    </div>
    <el-table :data="messageData" :row-style="{ height: '40px' }" fit>
      <el-table-column v-if="!isId" label="유형" align="center" min-width="20">
        <template>
          {{ isMessage ? 'PUSH' : smstype ? 'LMS' : 'SMS' }}
        </template>
      </el-table-column>
      <el-table-column v-if="isId" label="유형" align="center" min-width="20">
        <template>
          {{ `${isMessage ? 'PUSH' : prevSMSType ? 'LMS' : 'SMS'} → ${isMessage ? 'PUSH' : smstype ? 'LMS' : 'SMS'}` }}
        </template>
      </el-table-column>
      <el-table-column v-if="!isId" label="발송건" align="center" min-width="20">
        <template>
          {{ `총 ${count}  건` }}
        </template>
      </el-table-column>
      <el-table-column v-if="isId" label="발송건" align="center" min-width="20">
        <template>
          {{ `${prevMemberLength} 건 → ${count}건` }}
        </template>
      </el-table-column>
      <el-table-column v-if="!isId" label="차감 포인트" align="center" min-width="20">
        <template> {{ (isMessage && Number(studios.grade) !== 1 ? 0 : usePoint) | comma }} 포인트 </template>
      </el-table-column>
      <el-table-column v-if="isId" label="증감 포인트" align="center" min-width="20">
        <template> {{ plusOrMinusPointScore() }} 포인트 </template>
      </el-table-column>
      <el-table-column v-if="!isId" label="잔여 포인트" align="center" min-width="20">
        <template> {{ (hasPoint - usePoint) | comma }} 포인트 </template>
      </el-table-column>
      <el-table-column v-if="isId" label="잔여 포인트" align="center" min-width="20">
        <template v-if="plusOrMinusPointScore() !== '-'"> {{ (hasPoint + plusOrMinusPointScore()) | comma }} 포인트 </template>
        <template v-else> {{ hasPoint | comma }} 포인트 </template>
      </el-table-column>
    </el-table>
    <div class="point-storage-dialog__content">
      <p class="reserve" v-if="isReserve && !isId">
        예약 발송 시 포인트는 선차감 됩니다. <br />메시지 보내기 예약을 하시겠습니까?
      </p>
      <p>
        {{ isReserve ? (isId ? '메시지 보내기 예약을 수정하시겠습니까?' : null) : '메시지를 보내시겠습니까?' }}
      </p>
    </div>
    <div slot="footer">
      <el-button @click="$emit('close', false)">
        취소
      </el-button>
      <el-button type="primary" :loading="disabled" @click="$emit('close', false, true)" :disabled="disabled">
        확인
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    usePoint: Number,
    count: Number,
    smstype: Boolean,
    isMessage: Boolean,
    isReserve: String,
    isId: String,
    hasPoint: Number,
    disabled: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      //table 쓰기 위한 최소 세팅
      messageData: [{}],
      updateFlag: true,
      prevMemberLength: null,
      prevSMSType: null,
    };
  },

  computed: {
    studios() {
      return this.$store.getters['studio/studio'];
    },
  },

  updated() {
    if (this.updateFlag) {
      this.updateFlag = false;
      this.prevMemberLength = this.count;
      this.prevSMSType = this.smstype;
    }
  },

  methods: {
    plusOrMinusPointScore() {
      if (this.isMessage && Number(this.studios.grade) !== 1) return '-';

      const prevCost =
        this.prevMemberLength * (this.isMessage ? (Number(this.studios.grade) !== 1 ? 0 : 2) : this.prevSMSType ? 37 : 12);
      const nextCost = this.count * (this.isMessage ? (Number(this.studios.grade) !== 1 ? 0 : 2) : this.smstype ? 37 : 12);
      if (this.prevMemberLength === this.count && this.prevSMSType === this.smstype) {
        return '-';
      } else {
        return prevCost - nextCost;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.point-storage-dialog {
  /deep/ .el-dialog {
    max-width: 1024px !important;
    &__header {
      padding: 20px 30px !important;
    }
    &__body {
      display: block !important;
      padding: 0px 30px 0 30px !important;
    }
  }
  &__header {
    text-align: center;
    font-weight: bold;
    font-size: 23px;
    padding-bottom: 20px;
  }
  &__description {
    margin-bottom: 20px;
  }
  &__content {
    margin-top: 20px;
    text-align: center;
    p {
      font-weight: bold;
    }
  }
}
</style>
