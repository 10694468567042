<template>
  <el-dialog class="user-ticket-log" :visible="show" :before-close="onClose">
    <h4 slot="title">지난 내역 보기</h4>

    <el-table :data="logs" height="353" fit v-loading="loading">
      <el-table-column prop="act_at" label="변경일시" header-align="center" align="center" />
      <el-table-column prop="act_by" label="변경한 사람" min-width="50" header-align="center" align="center" />
      <el-table-column prop="status" label="결과" min-width="40" header-align="center" align="center" />
      <el-table-column prop="created_for" label="사유" min-width="60" header-align="center" align="center" />
    </el-table>

    <div slot="footer">
      <el-button @click="onClose" type="primary">
        확인
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import { BOOKING_STATUS } from '@constants';

export default {
  props: {
    show: Boolean,
    onClose: Function,
    booking: Object,
  },

  data() {
    return { logs: [], loading: false };
  },

  async created() {
    const { translate, notify } = this.$utils;

    try {
      this.loading = true;
      const res = await this.$api.userTicket.getLog(this.booking.id);
      this.logs = res.data.map(({ act_at, act_by, status, created_for }) => {
        return {
          act_at,
          act_by: act_by || '시스템',
          status: translate.bookingStatus(status),
          created_for: status === BOOKING_STATUS.ATTENDED ? '' : created_for,
        };
      });
    } catch (error) {
      notify.error(this, '오류', '새로고침 해주세요');
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.user-ticket-log {
  /deep/ .el-dialog {
    width: 90%;
    max-width: 500px;
  }
}
</style>
