export const state = {
  banner: true,
};

export const getters = {
  banner: state => state.banner,
};

export const mutations = {
  SET_BANNER(state, result) {
    state.banner = result;
  },
};
