<template>
  <el-table :data="data" :span-method="spanMethod" fit>
    <el-table-column label="수업일" min-width="210" align="center">
      <template slot-scope="scope"> {{ scope.row.start_on | dateWithWeekday }} {{ getLectureTime(scope.row) }} </template>
    </el-table-column>

    <!--  <el-table-column label="수업시간" min-width="110" align="center">
      <template slot-scope="scope">
        {{ getLectureTime(scope.row) }}
      </template>
    </el-table-column> -->

    <el-table-column label="강사" prop="staff.profile.name" min-width="100" align="center" />

    <el-table-column label="수업구분" min-width="80" align="center">
      <template slot-scope="scope">
        {{ $utils.translate.courseType(scope.row.course.type) }}
      </template>
    </el-table-column>

    <el-table-column label="수업" min-width="150" header-align="center">
      <template slot-scope="scope">
        {{ getLectureTitle(scope.row) }}
      </template>
    </el-table-column>

    <el-table-column label="룸" min-width="100" align="center">
      <template slot-scope="scope">
        {{ getLectureRoom(scope.row) }}
      </template>
    </el-table-column>

    <el-table-column label="예약 상태" min-width="100" align="center">
      <template slot-scope="scope">
        {{ getBookingStatus(scope.row.booking) }}
      </template>
    </el-table-column>

    <el-table-column label="회원" min-width="100" align="center">
      <template slot-scope="scope">
        <div class="name-wrapper">
          <span>{{ getMemberName(scope.row.member) }}</span>
          <vaccine-image v-if="getVaccination(scope.row.member)" />
        </div>
      </template>
    </el-table-column>

    <el-table-column label="휴대폰번호" min-width="120" align="center">
      <template slot-scope="scope">
        {{ getMemberMobile(scope.row.member) }}
      </template>
    </el-table-column>

    <el-table-column label="삭제 시간" min-width="150" align="center">
      <template slot-scope="scope">
        {{ scope.row.deleted_at | datetime }}
      </template>
    </el-table-column>

    <el-table-column label="삭제한 사람" min-width="100" align="center">
      <template slot-scope="scope">
        {{ getDeletedBy(scope.row.deleter) }}
      </template>
    </el-table-column>

    <el-table-column label="삭제 이유" min-width="150" align="center">
      <template slot-scope="scope">
        {{ scope.row.deleted_for }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import VaccineImage from '@components/shared/VaccineImage.vue';
export default {
  components: { VaccineImage },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    spanMethod({ row, columnIndex }) {
      if (columnIndex <= 4) {
        return { rowspan: row.rowspan, colspan: 1 };
      }
      return { rowspan: 1, colspan: 1 };
    },

    getLectureTitle(row) {
      return row.title || row.course.title;
    },

    getLectureTime({ start_on, end_on }) {
      if (!start_on || !end_on) return null;
      return `${this.moment(start_on).format('HH:mm')}~${this.moment(end_on).format('HH:mm')}`;
    },

    getLectureRoom(row) {
      const roomName = _.get(row, 'room.name');
      return !roomName ? null : `${roomName}룸`;
    },

    getBookingStatus(booking) {
      const status = _.get(booking, 'status');
      return status ? this.$utils.translate.bookingStatus(status) : null;
    },

    getMemberName(member) {
      return _.get(member, 'profile.name');
    },

    getVaccination(member) {
      const vaccine = _.get(member, 'vaccination_yn');
      return vaccine === 'Y';
    },

    getMemberMobile(member) {
      const mobile = _.get(member, 'mobile');
      if (!mobile) return null;

      return this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);
    },

    getTicketTitle(booking) {
      return _.get(booking, 'user_ticket.ticket.title');
    },

    getRemainingDays(booking) {
      const { expire_at, availability_start_at } = booking.user_ticket;
      const text = this.$utils.getRemainingDaysText(expire_at, availability_start_at);
      const isExpired = text.split(' ')[1] === '지남';
      return isExpired ? '만료된 수강권' : text;
    },

    getRemainingCount(booking) {
      const { remaining_coupon, max_coupon } = booking.user_ticket;
      return `잔여 횟수 ${remaining_coupon}/${max_coupon}`;
    },

    getDeletedBy(staff) {
      return _.get(staff, 'profile.name') || '시스템';
    },
  },
};
</script>

<style lang="scss" scoped>
.name-wrapper {
  span {
    text-align: left;
  }
}
</style>
