<template>
  <div class="lecture-members" v-loading="loading">
    <BookableMembersList />

    <div
      class="lecture-members__list"
      v-if="bookings.booked.length > 0 || bookings.waiting.length > 0 || showDeletedBookingWaitingList"
    >
      <div class="lecture-members__list__title">
        <h5>예약회원 ({{ membersCount.booked | comma }}명)</h5>
        <PlainButton @click.native="handleClickSendMessage" :disabled="membersCount.booked <= 0" round>메시지 보내기</PlainButton>
      </div>
      <ul>
        <ListItem
          v-for="booking in bookings.booked"
          :key="booking.id"
          :booking="booking"
          :user-ticket="booking.user_ticket"
          :member="booking.member"
          :status-changing="statusChanging"
          @status-change="handleStatusChange"
          show-app-linked
        />
      </ul>

      <div class="lecture-members__list__title">
        <h5 v-if="bookings.waiting.length > 0">예약 대기 회원 ({{ membersCount.waiting | comma }}명)</h5>
      </div>
      <ul v-if="bookings.waiting.length > 0">
        <ListItem
          v-for="(booking, index) in bookings.waiting"
          :key="booking.id"
          :booking="booking"
          :user-ticket="booking.user_ticket"
          :member="booking.member"
          :waiting-index="index"
          :status-changing="statusChanging"
          @status-change="handleStatusChange"
          :status-error="statusChangeError"
          show-app-linked
        />
      </ul>

      <div class="lecture-members__list__title">
        <h5 v-if="showDeletedBookingWaitingList">
          자동 취소된 예약 대기 회원
        </h5>
      </div>
      <ul v-if="showDeletedBookingWaitingList">
        <ListItem
          v-for="(booking, index) in bookings.deleted"
          :key="booking.id"
          :booking="booking"
          :user-ticket="booking.user_ticket"
          :member="booking.member"
          :waiting-index="index"
          :status-change-disabled="true"
        />
      </ul>
    </div>

    <p v-else class="lecture-members__no-data">
      예약된 회원이 없습니다.
    </p>

    <SmsPushCombineModal
      :show="showMessageDialog"
      :membersAppLinked="membersAppLinked"
      :memberIdsForMessage="memberIdsForMessage"
      :isMemberSelectedAll="isMemberSelectedAll"
      :sendType="sendType"
      @changeSendType="value => (sendType = value)"
      @toggleSelectAll="toggleSelectAll"
      @handleCheckboxChange="handleCheckboxChange"
      @close="onMessageSent"
    />

    <CloseLectureConfirm :show="showCloseLectureModal" @close="handleCloseConfirmMocal" />
  </div>
</template>

<script>
import { BOOKING_STATUS, BOOKING_STATUS_ARRAY } from '@constants';
import BookableMembersList from './BookableMembersList';
import ListItem from './MembersListItem';
import SmsPushCombineModal from '@/components/Modals/SmsPushCombineModal';
import CloseLectureConfirm from '@/components/Modals/CloseLectureConfirm';

export default {
  components: {
    BookableMembersList,
    ListItem,
    CloseLectureConfirm,
    SmsPushCombineModal,
  },

  data() {
    return {
      statusChangeError: false,
      statusChanging: false,
      showMessageDialog: false,
      showCloseLectureModal: false,
      memberIdsForMessage: [],
      sendType: 'sms',
      membersAppLinked: { booked: [], waiting: [], all: [] },
    };
  },

  computed: {
    loading() {
      return this.$store.getters['lecture/loading'];
    },
    lecture() {
      return this.$store.getters['lecture/lecture'];
    },
    pagination() {
      return this.$store.getters['lecture/pagination'];
    },

    membersCount() {
      const booked = this.bookings.booked.reduce((sum, booking) => {
        if (!BOOKING_STATUS_ARRAY.ABSENT.includes(booking.status)) {
          sum++;
        }
        return sum;
      }, 0);
      const waiting = this.bookings.waiting.length;

      return { booked, waiting };
    },

    bookings() {
      if (!this.lecture.bookings) {
        return { booked: [], waiting: [], deleted: [] };
      }

      const lists = this.lecture.bookings.reduce(
        (lists, booking) => {
          /** 예약 대기 */
          if (booking.status === BOOKING_STATUS.WAITING) {
            lists.waiting.push(booking);

            /** 자동 취소된 예약 대기 */
          } else if (booking.status === BOOKING_STATUS.CANCEL) {
            if (booking.updated_for === '수업에 결원이 발생하지 않아 취소') {
              lists.deleted.push(booking);
            }

            /** 예약/예약확정/출석/결석/노쇼 */
          } else {
            lists.booked.push(booking);
          }

          return lists;
        },
        { booked: [], waiting: [], deleted: [] },
      );

      /** 출석/결석/노쇼를 리스트의 맨 뒤로 */
      const STATUS_ORDER = [BOOKING_STATUS.BOOKED, BOOKING_STATUS.ATTENDED, BOOKING_STATUS.ABSENT, BOOKING_STATUS.NOSHOW];
      lists.booked.sort((a, b) => {
        const aIndex = STATUS_ORDER.findIndex(status => status === a.status);
        const bIndex = STATUS_ORDER.findIndex(status => status === b.status);
        return aIndex < bIndex ? -1 : 1;
      });

      return lists;
    },

    /** 자동 취소된 예약 대기 명단 표시: 수업 종료 시간 전까지 */
    showDeletedBookingWaitingList() {
      const isLectureDone = this.moment().isAfter(this.lecture.end_on);
      const hasDeletedBookingWaitingList = this.bookings.deleted.length > 0;
      return !isLectureDone && hasDeletedBookingWaitingList;
    },

    isMemberSelectedAll() {
      const isSelectedAll = status => {
        return this.membersAppLinked[status].every(({ id }) => {
          return this.memberIdsForMessage.includes(id);
        });
      };

      const booked = isSelectedAll('booked');
      const waiting = isSelectedAll('waiting');

      return { booked, waiting };
    },
  },

  watch: {
    showMessageDialog() {
      if (!this.showMessageDialog) {
        this.memberIdsForMessage = _.uniq(this.membersAppLinked.all.map(({ id }) => id));
      }
    },
    bookings: function() {
      this.getMessageMember();
    },
    sendType: function() {
      this.getMessageMember();
    },
  },

  methods: {
    /** sms/push 보낼 대상 */
    getMessageMember() {
      const getAppLinked = bookings => {
        if (this.sendType === 'message') {
          return bookings.filter(({ member }) => member.account_id).map(({ member }) => member);
        } else {
          return bookings.filter(({ member }) => member.mobile).map(({ member }) => member);
        }
      };

      const booked = getAppLinked(this.bookings.booked);
      const waiting = getAppLinked(this.bookings.waiting);
      const all = [...booked, ...waiting];
      this.membersAppLinked = { booked, waiting, all };
      this.memberIdsForMessage = _.uniq(this.membersAppLinked.all.map(({ id }) => id));
    },

    async loadData() {
      await this.$store.dispatch('lecture/getLecture', this.lecture.id);
      await this.$store.dispatch('lecture/getBookableTickets');
    },

    /** 메시지 보내기 클릭시 */
    handleClickSendMessage() {
      if (this.membersAppLinked.all.length <= 0) {
        return this.$utils.notify.error(this, '메시지 발송 불가', '메시지는 전화번호가 저장된 회원에게만 보낼 수 있습니다.');
      }
      this.memberIdsForMessage = _.uniq(this.membersAppLinked.all.map(({ id }) => id));
      this.showMessageDialog = true;
    },

    onMessageSent() {
      this.showMessageDialog = false;
    },

    handleCheckboxChange(value) {
      if (this.memberIdsForMessage.includes(value)) {
        this.memberIdsForMessage = this.memberIdsForMessage.filter(v => v !== value);
      } else {
        this.memberIdsForMessage.push(value);
      }
    },

    toggleSelectAll(status) {
      if (this.isMemberSelectedAll[status]) {
        this.memberIdsForMessage = this.memberIdsForMessage.filter(id => {
          return !this.membersAppLinked[status].find(member => member.id === id);
        });
      } else {
        this.memberIdsForMessage = _.uniq([...this.memberIdsForMessage, ...this.membersAppLinked[status].map(({ id }) => id)]);
      }
    },

    /** 예약 상태 변경 */
    async handleStatusChange(booking, status) {
      const { current_trainee_count, max_trainee } = this.lecture;
      if (status === BOOKING_STATUS.CANCEL) {
        return this.cancelBooking(booking.id);
      }

      if (!(current_trainee_count < max_trainee) && status === BOOKING_STATUS.BOOKED) {
        const title = '수강 정원 초과';
        let message = '수강 정원이 마감되었습니다.<br>초과 예약을 하시겠습니까?';

        return this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => {
            this.updateBooking(booking.id, status);
          })
          .catch(() => {
            this.statusChangeError = true;
          })
          .finally(() => {
            this.statusChangeError = false;
          });
      }

      this.updateBooking(booking.id, status);
    },

    async updateBooking(bookingId, status) {
      this.statusChanging = true;
      try {
        await this.$api.booking.update(bookingId, status);
        this.loadData();
        this.$utils.notify.success(this, '확인', '예약 상태를 변경하였습니다.');
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.statusChanging = false;
      }
    },

    /** 예약 취소 */
    async cancelBooking(bookingId) {
      const isLastBooking = this.bookings.booked.length;
      const isPrivate = this.lecture.course.type;
      this.cancelBookingId = bookingId;

      if (
        (isLastBooking === 1 && isPrivate === 'P') ||
        (this.lecture.min_trainee >= isLastBooking && this.moment(this.lecture.autoclose_at) <= this.moment(new Date()))
      ) {
        const title = '예약 취소';
        const message = '회원의 예약은 취소되고 수업은 삭제됩니다.';
        if (isPrivate === 'G') {
          return (this.showCloseLectureModal = true);
        }
        const confirm = await this.$confirm(message, title, { dangerouslyUseHTMLString: true })
          .then(() => true)
          .catch(() => false);

        if (!confirm) return this.$router.push(`/lecture/detail?id=${this.$route.query.id}?`);
      }
      this.cancel();
    },

    async cancel() {
      const isLastBooking = this.bookings.booked.length;
      const isPrivate = this.lecture.course.type;
      this.statusChanging = true;
      try {
        await this.$api.booking.delete(this.cancelBookingId);
        if (isLastBooking && isPrivate === 'P') {
          this.$router.push('/schedule');
        } else {
          this.loadData();
          this.$utils.notify.success(this, '확인', '예약 취소하였습니다.');
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.statusChanging = false;
      }
    },

    async handleCloseConfirmMocal(close, accept, cancelCloseLecture) {
      this.showCloseLectureModal = close;
      if (!accept) return this.$router.push(`/lecture/detail?id=${this.$route.query.id}?`);
      if (cancelCloseLecture) {
        await this.$api.lecture.update({ id: this.$route.query.id, min_trainee: 0 });
      }
      this.cancel();
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-members {
  border: solid #f0f0f0;
  border-width: 1px 0 0 0;
  padding: 24px;
  overflow: auto;

  @include mq(1024px) {
    border-width: 0 1px 0 0;
  }

  &__list {
    &__title {
      @include flex(row, center, space-between);
      margin: 12px 0;

      h5 {
        color: #000;
        font-weight: 400;
      }
    }
  }

  &__no-data {
    font-size: 15px;
    opacity: 0.5;
    text-align: center;
    padding: 20px 0;
  }

  &__send-message {
    /deep/ .el-dialog {
      width: 95%;
      max-width: 850px;
    }

    /deep/ .el-dialog__body {
      @include mq(568px) {
        display: grid;
        grid-template-columns: 180px 1fr;
        grid-gap: 8px;
      }
    }

    &__message {
      grid-column: span 2;
      color: $color-primary;
      font-size: 12px;
      margin-bottom: 12px;
      padding: 0 7px;
    }

    &__members-list {
      margin-bottom: 12px;

      &-wrapper {
        border: solid #ebebeb;
        border-width: 0 0 1px 0;
        padding: 0 0 10px 0;
        margin: 0 0 10px 0;
        max-height: 20vh;
        overflow: auto;

        @include mq(568px) {
          border-width: 0 1px 0 0;
          padding: 0 10px 0 0;
          margin: 0 10px 0 0;
          max-height: 398px;
        }

        h5 {
          @include flex(row, center, space-between);
          margin-bottom: 12px;
          padding: 0 7px;

          button {
            font-size: 11px;
          }
        }

        ul + h5 {
          border-top: 1px solid #ebebeb;
          margin-top: 12px;
          padding-top: 12px;
        }
      }

      li {
        transition: 0.15s background;

        &:hover {
          background: rgba($color-primary, 0.1);
        }
      }
    }
  }
}
</style>
