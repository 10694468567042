<template>
  <section class="message-detail" v-loading="loading">
    <div class="message-detail__buttons">
      <PlainButton size="large" type="info" @click="$router.push('/sms/list')"> <i class="el-icon-tickets" /> 목록 </PlainButton>
      <PlainButton size="large" type="info" :disabled="!prevMessageId" @click="routerAddress('prev')">
        <i class="el-icon-arrow-left" /> 이전
      </PlainButton>
      <PlainButton size="large" type="info" :disabled="!nextMessageId" @click="routerAddress('next')">
        다음 <i class="el-icon-arrow-right" />
      </PlainButton>
    </div>

    <div class="message-detail__header">
      <div class="message-detail__header__title">
        <h4>{{ message.title }}</h4>
        <p>
          <span style="margin-right: 24px;">{{ messageSendOn }}</span>
          <span v-if="message.is_sent === 1">
            <b>{{ message.targets.length | comma }}건</b> 발송
          </span>
          <el-tag v-if="message.is_sent === 0 && message.deleted_at" type="danger" size="mini">예약취소</el-tag>
          <el-tag v-else-if="message.is_sent === 0 && message.is_processing" size="mini" type="warning">처리중</el-tag>
          <el-tag v-else-if="message.is_sent === 0 && !message.deleted_at" size="mini">예약</el-tag>
        </p>
      </div>

      <div class="message-detail__header__summary">
        <label>회원검색조건</label>
        <div class="message-detail__header__summary__filter-tags">
          <el-tag v-for="(tag, index) in memberFilterTags" :key="index" size="small">{{ tag }}</el-tag>
        </div>
        <label>대상회원</label>
        <div class="message-detail__header__summary__members">
          <p ref="members-list" :class="{ 'show-less': !showMoreMembers }">
            {{ membersList }}
          </p>
          <PlainButton size="small" type="info" @click="showMoreMembers = !showMoreMembers">
            더보기
            <i v-if="showMoreMembers" class="el-icon-arrow-up" />
            <i v-else class="el-icon-arrow-down" />
          </PlainButton>
        </div>
      </div>
    </div>

    <div class="message-detail__body">
      <p>{{ message.message }}</p>
    </div>

    <BottomActionBar
      v-if="message.created_at && message.is_sent !== 1 && !message.deleted_at && !message.is_processing"
      contentMaxWidth="944px"
      hideButtonBack
    >
      <PlainButton slot="left" type="white" size="large" @click="handleClickCancel">
        취소
      </PlainButton>
      <BaseButton type="white" size="large" @click="$router.push(`/sms/${message.id}/edit`)">
        수정
      </BaseButton>
    </BottomActionBar>
  </section>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      message: {},
      prevMessageId: null,
      nextMessageId: null,
      showMoreMembers: false,
    };
  },
  created() {
    this.$store.dispatch('payment/getPointScore');
  },
  computed: {
    messageSendOn() {
      return !this.message.send_on ? null : this.moment(this.message.send_on).format('YYYY. M. D. (ddd) HH:mm');
    },
    memberFilterTags() {
      if (!this.message.filter) return [];
      let filterOb = JSON.parse(this.message.filter);
      const label = {
        type: {
          null: '전체회원',
          active: '이용회원',
          inHolding: '정지회원',
          invalid: '만료회원',
          unpaid: '미결제회원',
        },
        course_type: {
          null: '전체수강권',
          P: '프라이빗 수강권',
          G: '그룹 수강권',
        },
        remaining_coupon: '잔여횟수',
        remaining_day: '잔여기간',
        keyword: '',
      };
      return Object.keys(filterOb).reduce((tags, key) => {
        const value = filterOb[key];
        if (typeof label[key] === 'object') {
          tags.push(label[key][value]);
        } else if (key === 'remaining_day') {
          tags.push(!value ? `${label[key]} 전체` : `${label[key]} ${value}일 이하`);
        } else if (key === 'remaining_coupon') {
          tags.push(!value ? `${label[key]} 전체` : `${label[key]} ${value}회 이하`);
        } else if (key === 'keyword') {
          if (value) tags.push(value);
        }
        return tags;
      }, []);
    },
    membersList() {
      if (!this.message.targets) return null;

      return this.message.targets
        .map(({ name }) => name || '[삭제된 회원]')
        .sort()
        .join(', ');
    },
  },
  watch: {
    $route: {
      handler: 'getMessage',
      immediate: true,
    },
  },
  methods: {
    routerAddress(type) {
      const { query } = this.$route;
      let id = type === 'prev' ? this.prevMessageId : this.nextMessageId;
      return query.search
        ? this.$router.push(`/sms/${id}?sms_type=${query.sms_type}&search=${query.search}`)
        : this.$router.push(`/sms/${id}?sms_type=${query.sms_type}`);
    },
    async getMessage() {
      this.loading = true;
      const { params, query } = this.$route;
      try {
        const res = await this.$api.sms.get(params.id, query.sms_type, query.search);
        this.message = this._.get(res, 'data.data');
        if (res.data.today.length === 1) {
          this.prevMessageId = this._.get(res, 'data.prev.id');
          this.nextMessageId = this._.get(res, 'data.next.id');
        } else {
          let index = res.data.today.findIndex(el => el.id === Number(params.id));
          if (index === res.data.today.length - 1) {
            this.prevMessageId = this._.get(res, 'data.prev.id');
            this.nextMessageId = this._.get(res, `data.today[${--index}].id`);
          } else if (index === 0) {
            this.prevMessageId = this._.get(res, `data.today[${++index}].id`);
            this.nextMessageId = this._.get(res, `data.next.id`);
          } else {
            this.nextMessageId = this._.get(res, `data.today[${--index}].id`);
            this.prevMessageId = this._.get(res, `data.today[${(index += 2)}].id`);
          }
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        return this.$router.push('/sms/list');
      } finally {
        this.loading = false;
      }
    },
    async handleClickCancel() {
      const title = '예약 메시지 취소';
      const sendOn = this.moment(this.message.send_on).format('llll');
      const count = this.$filters.comma(this.message.targets.length);
      const message = `${sendOn} ${this.message.title} ${count}건의 예약 메시지를 취소하시겠습니까?`;
      const confirmed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);
      if (confirmed) {
        try {
          this.$store.dispatch('sms/setLoading', true);
          await this.$api.sms.delete(this.message.id);
          this.$utils.notify.success(this, null, '예약 메시지가 취소되었습니다.');
          this.getMessage();
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.$store.dispatch('sms/setLoading', false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-detail {
  @include flex(column);
  &__buttons {
    align-self: flex-end;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 8px;
    @include mq(568px) {
      grid-template-columns: repeat(3, 94px);
    }
  }
  &__header {
    border: solid #d8d8d8;
    border-width: 1px 0;
    padding: 20px;
    margin: 20px 0;
    &__title {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      margin-bottom: 20px;
      p {
        text-align: right;
      }
      @include mq(768px) {
        @include flex(row, center, space-between);
        margin-bottom: 20px;
        p {
          min-width: 300px;
        }
      }
    }
    &__summary {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      @include mq(768px) {
        grid-template-columns: 88px 1fr;
      }
      label {
        margin: 0;
      }
      &__filter-tags {
        @include flex(row, center);
        flex-wrap: wrap;
        span {
          margin: 0 8px 8px 0;
          @include mq(768px) {
            margin: 0 8px 0 0;
          }
        }
      }
      &__members {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 12px;
        align-items: start;
        p.show-less {
          @include ellipsis;
          max-height: 24px;
        }
      }
    }
  }
  &__body {
    padding: 16px;
    white-space: pre-line;
    p {
      word-break: break-word;
    }
  }
}
</style>
