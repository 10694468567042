import { render, staticRenderFns } from "./SalesReport.vue?vue&type=template&id=9280c96c&scoped=true&"
import script from "./SalesReport.vue?vue&type=script&lang=js&"
export * from "./SalesReport.vue?vue&type=script&lang=js&"
import style0 from "./SalesReport.vue?vue&type=style&index=0&id=9280c96c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9280c96c",
  null
  
)

export default component.exports