import axios from '../axios';
import store from '@store';

export default {
  // 로그인
  login: data => axios.post('/staff/login', data),

  // 사용자 정보 가져오기
  getInfo: () => {
    const params = {
      with: 'representativeAvatar;account;profile;roles.permissions;studio;avatars',
    };
    return axios.get('/me/profile', {
      params,
    });
  },

  // 로그인한 사용자 비밀번호 변경
  updateMyPassword: data => axios.patch('/me/passwords', data),

  // 알림 토큰 저장
  updateMessageToken: token => {
    return axios.patch('/staff/token', {
      web_token: token || -1,
    });
  },

  // 아이디 찾기
  getIdsByName: name => {
    const studioId = store.getters['auth/studioId'];
    return axios.get(`/studios/${studioId}/staff/${encodeURI(name)}/identities`);
  },

  // 아이디 링크
  sendIdLink: mobile => {
    const studioId = store.getters['auth/studioId'];
    return axios.get(`/studios/${studioId}/staff/${mobile}/identity/link`);
  },

  // 비밀번호 재설정 링크
  sendPasswordLink: id => {
    const studioId = store.getters['auth/studioId'];
    return axios.post(`/studios/${studioId}/staff/${id}/password/link`);
  },

  // 비밀번호 변경
  updatePassword: (token, data) => axios.patch(`/staff/${token}/password`, data),

  // 계정 생성 링크
  sendCreateAccountLink: id => axios.post(`/staff/${id}/account/link`),

  // 강사 계정 생성
  createAccount: (token, data) => axios.post(`/staff/${token}/account`, data),
};
