import axios from '../axios';
const BASE_URL = '/v2/staff/board';
export default {
  // board 목록
  getAll: (params, tab_name) =>
    axios.get(`/v2/staff/board?type=${tab_name}`, {
      params,
    }),

  // board 상세
  // get: id => axios.get(`/staff/studio_message/${id}`),
  get: id => axios.get(`${BASE_URL}/${id}`),

  // board 생성
  create: board_create => {
    return axios.post('/v2/staff/board',
      board_create);
  },
  // board 수정
  update: board_edit => {
      return axios.patch(`/v2/staff/board/${board_edit.id}`,board_edit);
  },
  // board 삭제
  delete: id => axios.delete(`${BASE_URL}/${id}`)
};
