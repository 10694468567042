<template>
  <el-input v-bind="$attrs" type="text" :value="value" :maxLength="max" @input="handleInput" />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: String,
    max: Number,
  },

  computed: {
    showIconToolTip() {
      const isUserDetail = this.$route.path.includes('/users');
      return !isUserDetail;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
