<template>
  <el-dialog
    :class="['ticket-issue-modal', currentStep === 1 ? 'small-width' : null]"
    :visible.sync="show"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div slot="title" class="ticket-issue-modal__title" v-loading="lockerLoading">
      <h4>락커. {{ formData.no }}</h4>
      <div v-if="formData.edit === 1" class="button-group">
        <PlainButton round @click="editLockerData">수정</PlainButton>
        <PlainButton @click="changeShowConfirmModal(formData, 'IMPOSSIBLE')" type="info" round>사용불가</PlainButton>
        <PlainButton @click="changeShowConfirmModal(formData, 'UNUSE')" type="info" round>사용해제</PlainButton>
        <!-- <PlainButton @click="changeShowConfirmModal(formData, 'DELETE')" type="danger" round>삭제</PlainButton> -->
      </div>
      <ul v-if="!formData.edit">
        <li v-for="step in steps" :key="step.index" :class="{ active: step.active }">
          <span>{{ step.index }}</span>
        </li>
      </ul>
    </div>

    <ChoiceLockerActionType
      v-if="currentStep === 1"
      @close="handleClose"
      :confirmModal="changeShowConfirmModal"
      :formData="formData"
    />
    <ChoiceMember v-if="currentStep === 2" @close="handleClose" />
    <ChoiceLockerInfo v-if="currentStep === 3" @close="handleClose" />
  </el-dialog>
</template>

<script>
import ChoiceLockerActionType from './ChoiceLockerActionType';
import ChoiceMember from './ChoiceMember';
import ChoiceLockerInfo from './ChoiceLockerInfo';
import { LOCKER_STATE_TYPE } from '@constants';

export default {
  components: {
    ChoiceLockerActionType,
    ChoiceMember,
    ChoiceLockerInfo,
  },
  props: {
    show: Boolean,
    initialData: { type: Object, default: null },
  },
  data() {
    return {
      lockerLoading: false,
      changeStateLockerId: null,
      changeStateType: null,
    };
  },
  created() {
    this.$store.dispatch('ticketIssue/getTickets');
    if (this.initialData) {
      this.$store.commit('ticketIssue/SET_VALUES', {
        ...this.initialData,
        currentStep: 2,
      });
    }
  },
  computed: {
    formData() {
      return this.$store.getters['locker/formData'];
    },
    currentStep() {
      return this.formData.currentStep;
    },
    steps() {
      return [
        {
          index: 1,
          title: '락커 타입 선택',
          description: '락커 타입을 선택해주세요',
          active: this.currentStep === 1,
        },
        {
          index: 2,
          title: '회원 선택',
          description: '회원을 선택해주세요',
          active: this.currentStep === 2,
        },
        {
          index: 3,
          title: '상세정보 입력',
          description: '상세정보를 입력해주세요',
          active: this.currentStep === 3,
        },
      ];
    },
  },
  methods: {
    handleClose() {
      this.$store.commit('locker/SET_VALUES');
      this.$emit('close', null);
    },

    async editLockerData() {
      const data = { edit: 2 };
      await this.$store.dispatch('locker/assignLocker', data);
    },

    changeShowConfirmModal(formData, type) {
      const title = `${
        type === LOCKER_STATE_TYPE.IMPOSSIBLE ? '락커 사용 불가' : type === LOCKER_STATE_TYPE.UNUSE ? '사용 해제' : '락커 삭제'
      }`;
      const message = `${
        type === LOCKER_STATE_TYPE.IMPOSSIBLE
          ? `락커를 <b>사용 불가</b> 처리 하시겠습니까?`
          : type === LOCKER_STATE_TYPE.UNUSE
          ? `락커를 <b>사용 해제</b> 하시겠습니까?`
          : `락커를 <b>삭제</b> 하시겠습니까?`
      }`;

      this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          if (formData && type) {
            this.lockerLoading = true;
            await this.changeLockerStatus(formData, type);
            this.lockerLoading = false;
            this.$emit('close', null);
          }
        })
        .catch(() => false);
    },

    async changeLockerStatus(formData, type) {
      await this.$store.dispatch('locker/assignLocker');

      if (type === LOCKER_STATE_TYPE.DELETE) {
        const res = await this.$api.locker.delete(formData.lockerId);
        if (res.status === 204) {
          this.$utils.notify.success(this, '락커 삭제 성공', `락커를 삭제하였습니다.`);
          await this.$store.dispatch('locker/getLockers');
        } else {
          this.$utils.notify.parseError(this, { message: '락커 삭제 실패하였습니다.' });
        }
      } else if (type === LOCKER_STATE_TYPE.IMPOSSIBLE || type === LOCKER_STATE_TYPE.USE || type === LOCKER_STATE_TYPE.UNUSE) {
        //사용불가, 사용해제로 상태변경시 남기고 싶은 정보
        let data = {
          lockerId: formData.lockerId,
          status: type,
          etc: -1,
          start_on: -1,
          end_on: -1,
          memberId: -1,
          userName: null,
          lockerName: null,
        };

        if (type === LOCKER_STATE_TYPE.UNUSE) {
          data = { ...data, status: 'USE' };
        }

        const res = await this.$api.locker.update(data);

        if (res.status === 200) {
          this.$utils.notify.success(this, '락커 수정 성공', `락커 정보를 수정하였습니다.`);
          await this.$store.dispatch('locker/getLockers');
        } else {
          this.$utils.notify.parseError(this, { message: '락커 수정을 실패하였습니다.' });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-issue-modal {
  /deep/ .el-dialog {
    @include flex(column);
    width: 90%;
    max-width: 620px;
  }
  /deep/ .el-dialog__body {
    flex: 1;
  }
  &__title {
    @include flex(row, center, flex-start);
    ul {
      @include flex(row, center, flex-start);
      li {
        @include flex(row, center, center);
        background: white;
        border: 1px solid rgba($charcoal-grey, 0.2);
        border-radius: 50%;
        font-size: 9px;
        width: 18px;
        height: 18px;
        margin: 0 12px;
        position: relative;
        span {
          line-height: 0px;
        }
      }
      li + li::before {
        content: '';
        width: 12px;
        border-bottom: 1px solid rgba($charcoal-grey, 0.2);
        position: absolute;
        left: -18px;
      }
      li.active {
        border-color: $deep-sky-blue;
        background: $deep-sky-blue;
        color: white;
      }
    }
  }
}
.small-width {
  /deep/ .el-dialog {
    width: 400px;
  }
}
.button-group {
  position: relative;
  left: 280px;
  button {
    margin-right: 10px;
  }
}
</style>
