import api from '@api';
import moment from 'moment';

export const state = {
  templates: [],
  previewText: {
    staff: '박나리',
    title: 'VIP 특별 수업',
    studio: '', // 해당 시설명
    member: '김수지',
    ticketName: 'VIP 20회 수강권',
    time: moment().format('YYYY.MM.DD') + ' 20:00',
    ticketDate: 3,
    ticketCount: 3,
    ticketHoldingDate: 3,
    lockerDate: 3,
    lockerNo: 1,
    lockerName: '김수지락커',
  },
  buttonTextList: {
    staff: '강사명',
    title: '수업명',
    studio: '시설명',
    member: '회원명',
    ticketName: '수강권명',
    time: '수업 시작시간',
    ticketDate: '수강권 잔여일',
    ticketCount: '수강권 잔여횟수',
    ticketHoldingDate: '수강권 정지만료일',
    lockerDate: '락커 종료일',
    lockerNo: '락커 번호',
    lockerName: '락커 이름',
  },
  buttonTextOptionList: [
    {
      key: '명칭별',
      values: ['member', 'studio', 'ticketName', 'staff', 'title', 'lockerNo', 'lockerName'],
    },
    {
      key: '수강권 상황별',
      values: ['ticketDate', 'ticketCount', 'ticketHoldingDate'],
    },
    {
      key: '락커 상황별',
      values: ['lockerDate'],
    },
    {
      key: '기타',
      values: ['time'],
    },
  ],
  templateEditInputData: {
    id: 0,
    context: '',
  },
  autoMessageUnitOptions: [
    {
      unitTypeLabel: '수강권',
      unitTypeDescription: '기간 만료',
      message_type: 'REMINDER_TICKET_EXPIRATION',
      push_noti: true,
      message_noti: false,
      option: {
        ticket_expire_noti_term: 0,
      },
    },
    {
      unitTypeLabel: '수강권',
      unitTypeDescription: '잔여 횟수 만료',
      message_type: 'REMINDER_TICKET_COUNT',
      push_noti: true,
      message_noti: false,
      option: {
        remainder_coupon_noti_count: 0,
      },
    },
    {
      unitTypeLabel: '수강권',
      unitTypeDescription: '정지기간 만료',
      message_type: 'REMINDER_TICKET_STOP',
      push_noti: true,
      message_noti: false,

      option: {
        reminder_ticket_holding_noti_term: 0,
      },
    },
    {
      unitTypeLabel: '수업',
      unitTypeDescription: '대기에서 예약으로 전환시',
      message_type: 'GROUP_WAIT_AUTO',
      push_noti: true,
      message_noti: false,
      option: [],
    },
    {
      unitTypeLabel: '수업',
      unitTypeDescription: '최소 수강인원 미달 폐강시',
      message_type: 'GROUP_DELETE_AUTO',
      push_noti: true,
      message_noti: false,
      option: [],
    },
    {
      unitTypeLabel: '수업',
      unitTypeDescription: '수업 시작 전 알림',
      message_type: 'REMINDER_LECTURE_START',
      push_noti: true,
      message_noti: false,
      option: {
        before_lecture_alarm_hour: 0,
        before_private_lecture_alarm_hour: 0,
      },
    },
    {
      unitTypeLabel: '상품',
      unitTypeDescription: '상품 기간 만료',
      message_type: 'REMINDER_ITEM_EXPIRATION',
      push_noti: true,
      message_noti: false,
      option: {},
    },
    {
      unitTypeLabel: '상품',
      unitTypeDescription: '상품 정지기간 만료',
      message_type: 'REMINDER_ITEM_STOP',
      push_noti: true,
      message_noti: false,
      option: {},
    },
    {
      unitTypeLabel: '회원',
      unitTypeDescription: '생일 축하',
      message_type: 'REMINDER_BIRTHDAY',
      push_noti: true,
      message_noti: false,
      option: {
        is_active_user_birth_day_notification: false,
      },
    },
    {
      unitTypeLabel: '회원',
      unitTypeDescription: '락커 기간 만료',
      message_type: 'REMINDER_LOCKER_EXPIRATION',
      push_noti: true,
      message_noti: false,
      option: {
        remainder_locker_expire_date_notification: 0,
      },
    },
  ],
};

export const getters = {
  templates: state => state.templates,
  previewText: state => state.previewText,
  templateEditInputData: state => state.templateEditInputData,
  buttonTextList: state => state.buttonTextList,
  buttonTextOptionList: state => state.buttonTextOptionList,
  autoMessageUnitOptions: state => state.autoMessageUnitOptions,
};

export const mutations = {
  SET_TEMPLATES(state, result) {
    state.templates = result;
  },

  SET_TEMPLATE_INPUT_DATA(state, result) {
    state.templateEditInputData = result;
  },

  SET_STUDIO_NAME(state, data) {
    state.previewText.studio = data;
  },
};

export const actions = {
  async getAllTemplates({ commit, dispatch }) {
    try {
      const { data } = await api.template.autoMessage.getAll();
      const result = await dispatch('formatTemplates', data);
      commit('SET_TEMPLATES', result);
      return;
    } catch (error) {
      return commit('SET_TEMPLATES', []);
    }
  },

  async putTemplate({ state, dispatch }, data) {
    try {
      const { id, flag, type, option } = data;
      const { templateEditInputData } = state;
      if (flag === 'context' && templateEditInputData.id) {
        const { data } = await api.template.autoMessage.put(templateEditInputData.id, { context: templateEditInputData.context });
        data.push_noti = data.type === 'BOTH' || data.type === 'PUSH';
        data.message_noti = data.type === 'BOTH' || data.type === 'MESSAGE';
        dispatch('updateTemplateState', data);
        return data;
      }
      if (flag === 'noti_type' && id) {
        const { data } = await api.template.autoMessage.put(id, { type });
        data.push_noti = data.type === 'BOTH' || data.type === 'PUSH';
        data.message_noti = data.type === 'BOTH' || data.type === 'MESSAGE';
        dispatch('updateTemplateState', data);
        return data;
      }
      if (flag === 'option' && id) {
        const { data } = await api.template.autoMessage.put(id, { ...option });
        data.push_noti = data.type === 'BOTH' || data.type === 'PUSH';
        data.message_noti = data.type === 'BOTH' || data.type === 'MESSAGE';
        dispatch('updateTemplateState', data);
        return data;
      }
    } catch (error) {
      return error;
    }
  },

  setTemplateInputData({ state, commit }, data) {
    if (data.init) {
      return commit('SET_TEMPLATE_INPUT_DATA', {
        id: 0,
        context: '',
      });
    }
    return commit('SET_TEMPLATE_INPUT_DATA', { ...state.templateEditInputData, ...data });
  },

  formatTemplates({ state, rootGetters, commit }, templates) {
    commit('SET_STUDIO_NAME', rootGetters['studio/studio'].name);
    return state.autoMessageUnitOptions.reduce((acc, option) => {
      const template = templates.filter(t => {
        return t.message_type === option.message_type;
      })[0];
      option = { ...option, ...option.option, ...template };

      option.push_noti = option.type === 'BOTH' || option.type === 'PUSH';
      option.message_noti = option.type === 'BOTH' || option.type === 'MESSAGE';
      return template ? [...acc, option] : [...acc];
    }, []);
  },

  updateTemplateState({ state, commit }, data) {
    const currentState = state.templates;
    const newState = currentState.map(state => {
      if (state.id === data.id) {
        return { ...state, ...data };
      }
      return state;
    });
    commit('SET_TEMPLATES', newState);
  },
};
