<template>
  <el-dialog class="create-product-modal" :visible.sync="show" :before-close="onClose">
    <div slot="title" class="create-product-modal__title">
      <p>상품 등록</p>
    </div>

    <div class="create-product-modal__body">
      <router-link :to="{ name: 'create_ticket' }">
        <div>
          <p class="title">수강권</p>
          <p>프라이빗 수업 / 그룹 수업</p>
        </div>
      </router-link>
      <router-link :to="{ name: 'create_product' }">
        <div>
          <p class="title">상품</p>
          <p>운동복, 락커 등 대여 또는 판매 상품</p>
        </div>
      </router-link>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    onClose: Function,
    payload: {},
  },

  methods: {
    handleClickCreatePrivate(payload) {
      if (this.canCreatePrivateLecture()) {
        // this.$router.push('/course/private/create');
        let path = '/course/private/create';
        path += Object.entries(payload).reduce((str, entry, index) => {
          const [key, value] = entry;
          const separator = index === 0 ? '?' : '&';
          str = `${str}${separator}${key}=${value}`;
          return str;
        }, '');
        this.$router.push(path);
      }
    },

    handleClickCreateGroup(payload) {
      if (this.canCreateGroupLecture()) {
        // this.$router.push('/course/group/create');
        let path = '/course/group/create';
        path += Object.entries(payload).reduce((str, entry, index) => {
          const [key, value] = entry;
          const separator = index === 0 ? '?' : '&';
          str = `${str}${separator}${key}=${value}`;
          return str;
        }, '');

        this.$router.push(path);
      }
    },

    handleClickCreateCounseling(payload) {
      if (this.canCreateCounsel) {
        const { start_on, end_on } = payload;
        this.$router.push({
          name: 'counsels',
          params: {
            visitCounseling: true,
          },
          query: {
            start_on,
            end_on,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-product-modal {
  @include flex(row, center, center);
  color: #343a40;

  /deep/ .el-dialog {
    width: 90%;
    max-width: 368px;
  }

  /deep/ .el-dialog__header {
    padding: 20px 28px;
  }

  /deep/ .el-dialog__body {
    padding: 0 0 30px;
  }

  &__title {
    p {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &__body {
    a {
      display: block;
      cursor: pointer;
      padding: 0 28px;

      &:hover {
        background: #eee;
      }

      &:not(:last-child) div {
        border-bottom: 1px solid #eee;
      }

      &.disabled {
        cursor: default;
        pointer-events: none;

        div {
          background: #ddd;
          position: relative;

          &::after {
            content: '권한없음';
            color: $color-danger;
            position: absolute;
            top: 16px;
            right: 16px;
          }
        }
      }

      div {
        padding: 20px 0 20px 66px;
        transition: 0.15s;
        position: relative;

        &:before {
          content: '';
          width: 56px;
          height: 56px;
          position: absolute;
          top: 15px;
          left: 0;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .title {
          font-size: 15px;
          font-weight: bold;
          padding: 2px 10px;
        }

        p {
          font-size: 13px;
          padding: 2px 10px;
        }
      }
    }

    a:first-of-type div {
      &:before {
        background-image: url('../../assets/images/icons/products-floating-modal1.svg');
      }
    }

    a:last-of-type div {
      &:before {
        background-image: url('../../assets/images/icons/products-floating-modal2.svg');
      }
    }
  }
}
</style>
