export const state = {
  colorPlain: [
    ['fa8787', 'ffb7ce', 'ffa4a8', 'ffad87', 'ffda62', 'b5e384', '68e7c5', '81e0fa', '92c6ff', '8792c5', 'c9aaf4'],
    ['f95454', 'ff99b9', 'ff7e83', 'ff8a54', 'ffcb1f', '96d750', '27dead', '4bd4f8', '64aeff', '5464ad', 'b286f0'],
    ['c74343', 'cc7a94', 'cc6468', 'cc6e43', 'cca218', '78ac40', '1fb18a', '3ca9c6', '508bcc', '43508a', '8e6bc0'],
    ['953232', '995b6f', '994b4e', '995232', '997912', '5a8130', '178567', '2d7f94', '3c6899', '323c67', '6a5090'],
    ['632121', '663d4a', '663234', '663721', '66510c', '3c5620', '0f5845', '1e5463', '284566', '212845', '473560'],
  ],
  colorGradient: [
    [
      ['ff8181', 'ff2525'],
      ['ff8697', 'ff5068'],
      ['fd915f', 'ff5100'],
      ['94d26b', '43a700'],
      ['23ffb2', '009651'],
      ['6ef1f1', '0bb7b7'],
      ['808cff', '2a38c2'],
    ],
    [
      ['e67272', 'e52323'],
      ['e67787', 'e6485e'],
      ['ca724a', 'cc4100'],
      ['74a753', '368600'],
      ['1ac98c', '007942'],
      ['56c0c0', '099393'],
      ['5860b1', '1e2787'],
    ],
    [
      ['b25959', '991818'],
      ['b25c68', '99303f'],
      ['b16440', '993101'],
      ['669149', '286401'],
      ['17b17b', '006a39'],
      ['4ca7a7', '098181'],
      ['4c5499', '192275'],
    ],
    [
      ['994c4c', '991717'],
      ['99505a', '99303f'],
      ['975638', '822b03'],
      ['587e3f', '286401'],
      ['14986a', '005b32'],
      ['419090', '095f5f'],
      ['3f457f', '151c61'],
    ],
    [
      ['7f3e3e', '7f1313'],
      ['7f424a', '7f2834'],
      ['7e472d', '7f2800'],
      ['486833', '225301'],
      ['107d57', '004c29'],
      ['357777', '065b5b'],
      ['323765', '11174e'],
    ],
  ],
};

export const getters = {
  colorPlainPalette: state => state.colorPlain,
  colorGradientPalette: state => state.colorGradient,
};
