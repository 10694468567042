<template>
  <el-table
    :data="histories"
    fit
    v-loading="loading"
    @row-click="tableRowClick"
    :row-class-name="isSalesPoint ? 'sales-point' : ''"
    :default-sort="{ prop: 'settlement_at', order: 'ascending' }"
    @sort-change="sortChange"
  >
    <el-table-column
      prop="settlement_at"
      :formatter="formatUpdateAt"
      label="일시"
      align="center"
      width="170"
      sortable="custom"
      :sort-orders="['ascending', 'descending']"
    />

    <el-table-column label="스태프" align="center">
      <template slot-scope="scope">
        {{ scope.row.staff ? scope.row.staff.name : '시스템' }}
      </template>
    </el-table-column>

    <el-table-column v-if="isSalesPoint" label="회원명" align="center">
      <template slot-scope="scope">
        {{ scope.row.member ? scope.row.member.name : '' }}
      </template>
    </el-table-column>

    <el-table-column label="구분" align="center" width="120">
      <template slot-scope="scope">
        <p :class="scope.row.point_type.includes('reward') && 'primary'">{{ POINT_TYPE[scope.row.point_type] }}</p>
      </template>
    </el-table-column>

    <el-table-column label="분류" align="center">
      <template slot-scope="scope">
        {{ paymentStatus(scope.row) }}
      </template>
    </el-table-column>

    <el-table-column label="내용" align="center">
      <template slot-scope="scope">
        <span>
          {{ formatUpdateFor(scope.row) }}
        </span>
        <p v-if="!!scope.row.updated_for">({{ scope.row.updated_for }})</p>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { POINT_TYPE, POINT_STATUS } from '@constants';

export default {
  props: {
    histories: Array,
    loading: Boolean,
  },

  created() {
    this.POINT_TYPE = POINT_TYPE;
  },

  computed: {
    isSalesPoint() {
      return this.$route.path.includes('/sales/point');
    },
  },

  methods: {
    formatUpdateAt(row) {
      return this.moment(row.settlement_at).format('YYYY. MM. DD (ddd) HH:mm');
    },

    formatUpdateFor({ reward_point, point_amount, point_type, status }) {
      const isSalesRefund = this.isSalesPoint && status === 'refund';
      const isPointMix = point_type === 'mix';
      const rewardPoint = this.$filters.comma(reward_point);
      const formatPointAmount = isSalesRefund && isPointMix ? Math.abs(point_amount) : point_amount;
      const pointAmount = this.$filters.comma(formatPointAmount);
      if (isPointMix) {
        if (isSalesRefund) {
          return `환불 ${rewardPoint} / 회수 ${pointAmount}`;
        }
        return `${rewardPoint} / ${pointAmount}`;
      }
      return rewardPoint || pointAmount;
    },

    tableRowClick(row) {
      const isMemberDetail = this.$route.path.includes('/users/detail');
      if (isMemberDetail || !row.ref_id) return;
      this.$router.push(`/users/detail?id=${row.user_id}&user_ticket_id=${row.ref_id}`);
    },

    sortChange({ order }) {
      this.$emit('sort-change', order);
    },

    paymentStatus({ status }) {
      return POINT_STATUS[status] || '-';
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .sales-point {
  cursor: pointer;
}

/deep/ .el-table__row {
  height: 57px;
}

.primary {
  color: $color-primary;
}
</style>
