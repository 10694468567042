<template>
  <section class="sales-list">
    <div class="sales-list__controls">
      <div class="sales-list__controls__search">
        <el-select v-model="sortDateType" placeholder="Select">
          <el-option v-for="item in sortDateOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <TextInput
          :value="listFilter.searchText"
          prefix-icon="el-icon-search"
          placeholder="회원명 검색"
          round
          @input="
            value => {
              handleChangeFilter('searchText', value);
            }
          "
        />
        <FilterResetButton @click="setListFilter" />
      </div>
    </div>

    <div class="sales-list__table">
      <div class="sales-list__table__summary">
        <div>
          <h3>{{ tableSummaryText }}</h3>
        </div>
        <div>
          <el-pagination
            class="sales-list__table__pagination"
            :current-page.sync="page"
            :page-sizes="[10, 15, 20, 50]"
            :page-size="pageSize"
            @size-change="size => (pageSize = size)"
            layout="prev, pager, next, sizes"
            :total="fileterUnPayedData.data.length"
          />
          <el-button
            class="sales-list__table__excel-down"
            v-loading="exceldownloading"
            :disabled="exceldownloading"
            type="primary"
            @click="downloadExcel"
            >엑셀다운로드</el-button
          >
        </div>
      </div>
      <el-table
        :data="paginatedData"
        :key="unpayedData.count"
        fit
        @cell-click="goToMemberDetail"
        row-class-name="sales-list__row"
      >
        <el-table-column prop="name" label="회원명" min-width="100" align="center" />

        <el-table-column label="최근 결제일" min-width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.recentDate | date }}</span>
          </template>
        </el-table-column>

        <el-table-column label="최근 결제방법" min-width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.payMethod.length ? scope.row.payMethod.join(', ') : '-' }}</span>
          </template>
        </el-table-column>

        <el-table-column label="수업 구분" min-width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.ticketType === 'G' ? '그룹' : '프라이빗' }}</span>
          </template>
        </el-table-column>

        <el-table-column label="수강권명" min-width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.ticketName }}</span>
          </template>
        </el-table-column>

        <el-table-column label="누적 결제금액" min-width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.totalAmount | comma }}원</span>
          </template>
        </el-table-column>

        <el-table-column label="남은 미수금" min-width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.unpaidAmount | comma }}원</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      exceldownloading: false,
      page: 1,
      pageSize: 15,
      sortDateOptions: [
        {
          value: 'asc',
          label: '과거순',
        },
        {
          value: 'desc',
          label: '최근순',
        },
      ],
      sortDateType: 'asc',
    };
  },

  computed: {
    unpayedData() {
      return this.$store.getters['sales/unpaidment'];
    },

    fileterUnPayedData() {
      if (this.listFilter.searchText === '') {
        return this.unpayedData;
      }

      const filterResult = this.unpayedData.data.filter(({ name }) => name.indexOf(this.listFilter.searchText) !== -1);
      const filterResultUnpaymentAmount = filterResult.reduce((prev, current) => prev + current.unpaidAmount, 0);
      return { count: filterResult.length, data: filterResult, un_payment_amount: filterResultUnpaymentAmount };
    },

    filter() {
      return this.$store.getters['sales/filter'];
    },

    listFilter() {
      return this.$store.getters['sales/listFilter'];
    },

    paginatedData() {
      if (!this.fileterUnPayedData.data.length) return [];
      const sliceBegin = (this.page - 1) * this.pageSize;
      const sliceEnd = sliceBegin + this.pageSize;
      const paginatedData = this.fileterUnPayedData.data.slice(sliceBegin, sliceEnd);

      return paginatedData;
    },

    tableSummaryText() {
      const { comma } = this.$filters;
      return `총 ${comma(this.fileterUnPayedData.count)}건 (${comma(this.fileterUnPayedData.un_payment_amount)}원)`;
    },
  },

  created() {
    if (this.unpayedData && !this.unpayedData.data.length) {
      this.$store.dispatch('sales/getUnpaymentData');
    }
  },

  watch: {
    sortDateType: function(type) {
      this.$store.commit('sales/SET_SORT_UNPAIDMENT', type);
    },
  },

  methods: {
    setListFilter(values) {
      this.$store.commit('sales/SET_LIST_FILTER', values);
    },

    handleChangeFilter(key, value) {
      this.setListFilter({ [key]: value });
    },

    goToMemberDetail(row) {
      this.$router.push(`/users/detail?id=${row.userId}&user_ticket_id=${row.userTicketId}`);
    },

    downloadExcel() {
      this.exceldownloading = true;
      /** 데이터 가공 */
      const json = this.formatJSON(this.fileterUnPayedData.data);
      this.$utils.downloadExcel(json, `남은미수금_현황.xlsx`);
      this.exceldownloading = false;
    },

    formatJSON(data) {
      if (!data || !data.length) return [];
      return data.map(row => ({
        회원명: row.name,
        최근결제일: this.$filters.date(row.recentDate),
        최근결제방법: row.payMethod.join(', '),
        수업구분: row.ticketType === 'G' ? '그룹' : '프라이빗',
        수강권명: row.ticketName,
        누적결제금액: row.totalAmount,
        남은미수금: row.unpaidAmount,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-list__controls {
  @include flex(row, center, flex-end);

  &__search {
    @include flex(row, center, flex-start);
    width: 340px;
    margin-bottom: 4px;

    /deep/ .el-select {
      width: 160px;
      padding-right: 10px;
    }

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 40px;
      width: 100%;
      padding: 0 15px;

      &::placeholder {
        color: $charcoal-grey;
        opacity: 0.9;
      }
    }

    button {
      min-width: 36px;
      margin-left: 4px;
    }
  }
}

.sales-list__table {
  @include flex(column, stretch, center);

  &__summary {
    @include flex(row, center, space-between);
    flex-wrap: wrap;

    & > div {
      @include flex(row, center, flex-start);
    }

    h3 {
      margin: 20px 0;
    }
  }

  &__pagination {
    @include flex(row, center, flex-start);
    flex-wrap: wrap;
    margin: 10px 0;

    /deep/ .el-input__inner {
      @extend %input-default;
    }

    /deep/ .el-input__suffix {
      right: 8px;
    }

    /deep/ .el-pagination__sizes {
      margin: 0;
    }
  }

  &__excel-down {
    display: none;

    @include mq(768px) {
      display: flex;
    }
  }
}

.el-table {
  /deep/ .sales-list__row {
    cursor: pointer;
  }
}
</style>
