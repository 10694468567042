<template>
  <el-dialog class="template-preview-modal" :visible.sync="show" :before-close="handleClose" :close-on-click-modal="false">
    <div slot="title" class="template-preview-modal__header">
      <h4>메시지 미리보기</h4>
    </div>
    <message-preview
      ref="templateEditInput"
      :isPreviewed="true"
      :context="replacedText"
      :byteCount="byteCount"
      :isOver2000Byte="isOver2000Byte"
    />
    <div class="template-preview-modal__footer">
      <i class="ico_message" />
      {{ inputData.unitTypeDescription }}
    </div>
  </el-dialog>
</template>

<script>
import MessagePreview from '@/components/shared/MessagePreview.vue';
export default {
  components: {
    MessagePreview,
  },
  props: {
    show: Boolean,
    handleClose: Function,
  },

  computed: {
    inputData() {
      return this.$store.getters['autoMessageNotification/templateEditInputData'];
    },

    previewText() {
      return this.$store.getters['autoMessageNotification/previewText'];
    },

    replacedText() {
      return this.replaceAllForPreview();
    },

    byteCount() {
      return this.$utils.smsByteCheckWithPreventWriting(this.replacedText);
    },

    isOver2000Byte() {
      return this.byteCount > 2000;
    },
  },

  methods: {
    replaceAllForPreview() {
      const { context } = this.inputData;
      if (!context) return '';
      return Object.keys(this.previewText).reduce(
        (acc, currentText) => (acc = acc.replaceAll(`:${currentText}`, this.previewText[currentText])),
        context,
      );
    },

    checkLength(byteCount) {
      return byteCount > 90 ? 'LMS' : 'SMS';
    },
  },
};
</script>

<style lang="scss" scopped>
.template-preview-modal {
  .el-dialog {
    width: 379px;
    height: 705px;
    padding: 50px 61px;

    .el-dialog__headerbtn {
      top: 0px;
      right: 0px;
      i {
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          right: -10px;
          width: 36px;
          height: 36px;
          background: url('../../../assets/images/icons/x-close.svg') 0 0 no-repeat;
          background-size: cover;
        }
      }
    }

    &__header {
      position: relative;
      padding: 0px;
    }

    &__body {
      padding: 0px;
    }
  }

  &__header {
    h4 {
      font-size: 28px;
      font-weight: bold;
      text-align: left;
      padding-bottom: 20px;
    }
  }

  .bg-mockup {
    position: relative;
    width: 259px;
    height: 546px;
    background: url('~@icons/cell-phone-mockup.svg');
    background-size: cover;

    &__header {
      top: 44px;
      left: 25px;
      font-weight: bold;
      color: #343a40;
      padding: 44px 25px 8px 25px;

      &:after {
        content: '';
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 71px;
        left: 8.5px;
        height: 1px;
        width: 244px;
      }
    }
  }

  &__footer {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 10px 0px 0px 0px;
    font-size: 15px;
    font-weight: 500;
    color: #343a40;

    i.ico_message {
      content: '';
      display: block;
      position: relative;
      width: 24px;
      height: 24px;
      left: -6px;
      background: url('../../../assets/images/icons/message_gray.svg') 0 0 no-repeat;
      background-size: cover;
    }
  }
}
</style>
