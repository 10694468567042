<template>
  <el-dialog class="create-message-template-modal" :visible.sync="show" :before-close="onClose">
    <div class="create-message-template-modal__header">문자 보관함에 저장될<br />제목을 입력해 주세요</div>
    <div class="create-message-template-modal__main">
      <div :class="`create-message-template-modal__input_box ${inputClassName}`">
        <input
          type="text"
          :maxlength="maxLength"
          :placeholder="`최대 ${maxLength}자까지 입력 가능합니다.`"
          :value="template.template_title"
          @input="handleInputTitle"
        />
        <span class="text_length">
          <b>{{ template.template_title.length }}</b>
          /{{ maxLength }}자
        </span>
      </div>
    </div>
    <div class="create-message-template-modal__footer">
      <el-button :class="inputClassName" :disabled="!inputClassName" @click="dispatchSaveTemplate" type="primary">
        저장
      </el-button>
      <el-button @click="onClose" type="primary"> 취소 </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    onClose: Function,
  },

  data() {
    return {
      isLoading: false,
      maxLength: 10,
    };
  },

  computed: {
    template: {
      deep: true,
      get() {
        return this.$store.getters['template/formText'];
      },
    },

    inputClassName() {
      const {
        maxLength,
        template: { template_title },
      } = this;

      if (!template_title.length) return '';
      if (template_title.length <= maxLength) return 'activated';
      return '';
    },
  },

  methods: {
    async dispatchSaveTemplate() {
      const { template_title, template } = this.template;
      if (this.isLoading) return;
      if (!template_title) {
        return alert('내용을 입력해주세요.');
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch('template/postTemplate', {
          template_title,
          template,
        });
        await this.$store.dispatch('template/updateSent', true);
        await this.$store.dispatch('template/getAllTemplates');
        this.isLoading = false;
        this.onClose();
        this.$utils.notify.success(this, '성공', '작성한 메시지를 보관함에 저장하였습니다.');
      } catch (error) {
        this.$utils.notify.parseErrorTitle(this, error);
      }
    },

    handleInputTitle(ev) {
      if (ev.target.value.length > this.maxLength) return;
      this.$store.dispatch('template/setFormText', { template_title: ev.target.value });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-message-template-modal {
  @include flex(row, center, center);

  /deep/ .el-dialog {
    width: 90%;
    max-width: 367px;
    height: 239px;
    padding: 25px !important;
    position: relative;

    &__header {
      padding: 0px !important;
    }
    &__body {
      padding: 0px !important;
    }
  }

  &__header {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #343a40;
  }

  &__main {
    position: relative;
  }

  &__input_box {
    width: 100%;
    height: 25px;
    border-bottom: solid 1px #e9ecef;
    font-size: 14px;
    text-align: left;
    color: #bababa;
    margin: 20px 0px 0px 0px;
    font-size: 14px;
    text-align: left;
    color: #bababa;

    input {
      width: 263px;
    }

    &.activated input {
      font-size: 14px;
      text-align: left;
      color: #000000;
    }

    &.activated {
      border-bottom: solid 1px #4699f1;
    }

    &.activated input + .text_length {
      b {
        font-weight: 500;
        color: #4699f1;
      }
    }
  }

  input + .text_length {
    display: inline-block;
    position: absolute;
    right: 0px;
    bottom: 4.5px;
    font-size: 14px;
    color: #9f9f9f;

    b {
      font-weight: 500;
    }
  }

  &__footer {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    right: 20px;
    bottom: 20px;

    button {
      width: 64px;
      height: 32px;
      object-fit: contain;
      border-radius: 2px;
      font-size: 14px;
      text-align: center;
      padding: 0px;
      border: none;

      &:first-child {
        background-color: #b5b5b5;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);

        &.activated {
          width: 64px;
          height: 32px;
          object-fit: contain;
          border-radius: 2px;
          background-color: #4699f1;

          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: rgba(255, 255, 255, 0.9);
        }
      }
      &:last-child {
        width: 64px;
        height: 32px;
        border: solid 1px #8a8a8a;
        background-color: $white;
        color: rgba(138, 138, 138, 0.9);
      }
    }
  }
}
</style>
