<template>
  <el-dialog :visible="show" :title="isChangeSubscription ? '요금제 변경' : '결제 확인'" width="500px" @close="$emit('close')">
    <div v-if="isChangeSubscription">
      <span>[{{ selectedProduct.title }}] (으)로 요금제를 변경합니다.</span><br />
      <br />
      <p class="price-info">다음 결제 예정일 : {{ nextPayDate }}</p>
      <p class="price-info">결제 금액 : {{ payPrice | comma }} 원</p>
      <br />
      요금제를 변경하시겠습니까?
    </div>
    <div v-if="!isChangeSubscription">
      <span class="price-info">결제 금액 : {{ payPrice | comma }} 원</span><br />
      확인 버튼을 누르면 결제가 진행됩니다.<br />
      결제를 진행하시겠습니까?
    </div>

    <div slot="footer">
      <PlainButton @click="$emit('close')" size="large">취소</PlainButton>
      <BaseButton @click="$emit('proceedPayment')" :disabled="isLoading" size="large">확인</BaseButton>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    case: String,
    payPrice: Number,
    expireDate: String,
    selectedProduct: Object,
    isLoading: Boolean,
  },
  computed: {
    nextPayDate() {
      return this.expireDate.replaceAll('.', '-');
    },

    isChangeSubscription() {
      const isAfter = this.moment(this.expireDate).isAfter(this.moment());
      return isAfter && this.selectedProduct.quantity === 1;
    },

    showWarnTxt() {
      switch (this.case) {
        case '13':
        case '12':
        case '14':
        case '31':
        case '32':
        case '34':
        case '21':
        case '23':
        case '41':
        case '43':
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.price-info {
  font-weight: bold;
}
.txt-impact {
  color: $color-danger;
  font-weight: bold;
}
.el-dialog__footer {
  button:first-child {
    margin-right: 10px;
  }
}
</style>
