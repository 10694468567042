<template>
  <li class="memo-item">
    <div class="memo-item__meta">
      <span>{{ memo.updated_at | datetime }}</span>
      <span>{{ staffName }}</span>
    </div>

    <div v-if="showButtons" class="memo-item__buttons">
      <PlainButton @click="$emit('delete', memo)" type="danger" round>삭제</PlainButton>
      <PlainButton @click="$emit('edit', memo)" round>수정</PlainButton>
    </div>

    <div v-if="isEditing" class="memo-item__content">
      <Textarea ref="memo-editing" v-model="memo.memo" required />
      <div class="memo-item__content__buttons">
        <PlainButton @click="$emit('cancel-edit')" type="danger" round>취소</PlainButton>
        <BaseButton @click="$emit('save')" :disabled="!memo.memo" round>저장</BaseButton>
      </div>
    </div>

    <div v-else class="memo-item__content">
      <p>{{ memo.contents ? memo.contents : memo.memo }}</p>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    memo: { type: Object, required: true },
    isEditing: { type: Boolean, default: false },
    parentContent: { type: Object, required: false },
  },

  computed: {
    staffName() {
      const staffName = _.get(this.memo, 'staff.name', '');
      const roleName = _.get(this.memo, 'roles', '');
      return this.memo.staff ? `${staffName} ${roleName}` : '시스템';
    },

    /**
     * 1. 수정중이 아니고,
     * 2. 본인이 스튜디오 오너 또는 작성자일 경우
     */
    showButtons() {
      let show = !this.isEditing;

      if (this.memo.staff) {
        const isCurrentUser = _.get(this.memo, 'staff.id') === this.currentUser.id;
        show = show && (isCurrentUser || this.isStudioOwner);
      }

      return show;
    },
  },

  watch: {
    isEditing(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs['memo-editing'].$el.focus();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.memo-item {
  border: solid 1px #cccccc;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 104px;
  grid-gap: 16px;
  align-items: start;
  padding: 16px;
  margin-bottom: 16px;

  &__meta {
    display: grid;
    grid-gap: 8px;
    font-size: 12px;
    color: #666666;
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 48px);
    grid-gap: 8px;
  }

  &__content {
    grid-column: span 2;
    font-size: 12px;
    color: #333333;
    @include flex(column);

    p {
      white-space: pre-wrap;
      word-break: break-word;
    }

    &__buttons {
      align-self: flex-end;
      margin-top: 16px;

      button {
        width: 48px;
      }

      button + button {
        margin-left: 4px;
      }
    }
  }
}
</style>
