<template>
  <section>
    <header class="member-history__header">
      <div v-loading="isCancellingBooking" class="member-history__header__buttons">
        <el-select
          v-model="orderBy"
          class="member-history__header__buttons__order-by-select"
          :popper-append-to-body="false"
        >
          <el-option v-for="item in orderByOptions" :key="item.value" :value="item.value" :label="item.label" />
        </el-select>

        <el-select
          v-model="userTicketId"
          :class="{ 'is-filtered': userTicketId !== null }"
          :popper-append-to-body="false"
          filterable
        >
          <el-option
            v-for="item in userTicketOptions"
            :key="item.value"
            :value="item.value"
            :label="item.label"
            class="member-history__header__user-ticket-select__option"
          >
            <span>
              {{ item.label }}
            </span>
            <span v-if="['이용만료', '사용불가', '환불'].includes(item.status)">
              {{ item.status }}
            </span>
          </el-option>
        </el-select>

        <PlainButton
          v-if="!isOnCancelTab"
          type="info"
          size="xlarge"
          :disabled="cancelableBookingIdsOnPage.length <= 0"
          @click.native="
            () => {
              toggleSelectAll();
            }
          "
          >{{ selectAllButtonLabel }}</PlainButton
        >

        <PlainButton
          v-if="!isOnCancelTab"
          type="danger"
          size="xlarge"
          :disabled="selectedBookingIds.length <= 0"
          @click.native="
            () => {
              handleClickBulkCancel();
            }
          "
          >예약 일괄 취소</PlainButton
        >

        <p v-if="selectedBookingIds.length > 0">{{ selectedBookingIds.length | comma }}개 선택됨</p>
      </div>

      <CustomTabs
        class="member-history__header__tabs"
        :active_tab="currentTab"
        :tabs="tabs"
        :handleChangeTab="tab => (currentTab = tab)"
      />

      <div class="member-history__header__excel-down">
        <PlainButton @click="handleClickExcelDown" v-loading="exceldownloading" round>엑셀 다운로드</PlainButton>
      </div>
    </header>

    <div v-loading="bookingHistoryLoading" class="member-history__list">
      <timeline v-for="date in bookings.keys" :key="date" :title-position="titlePosition" :title-width="85">
        <p slot="title">{{ date | date }}</p>
        <div>
          <BookingListItem
            v-for="booking in bookings.data[date]"
            :key="booking.id"
            :booking="booking"
            :lecture="booking.lecture"
            :handleClickCancelBooking="isOnCancelTab ? handleClickCancelDelete : handleClickCancelBooking"
            :handleClickShowLog="handleClickShowLog"
            :clickable="isBookingCancelable(booking)"
            @click="handleClickBooking"
            :checked="selectedBookingIds.includes(booking.id)"
            :isCancellingBooking="isCancellingBooking"
          />
        </div>
      </timeline>
    </div>
    <el-pagination
      v-if="bookingHistoryTotal > 0"
      class="member-history__list-pagination"
      layout="prev, pager, next"
      @current-change="
        () => {
          dispatchGetBookingHistory();
        }
      "
      :current-page.sync="page"
      :page-size="10"
      :total="bookingHistoryTotal"
    />

    <UserTicketLogModal
      v-if="showUserTicketLogModal"
      :show="showUserTicketLogModal"
      :onClose="
        () => {
          showUserTicketLogModal = false;
        }
      "
      :booking="selectedBooking"
    />
  </section>
</template>

<script>
import { BOOKING_STATUS, BOOKING_STATUS_ARRAY } from '@constants';
import UserTicketLogModal from '@/components/Modals/UserTicketLog';

export default {
  components: { UserTicketLogModal },

  data() {
    return {
      titlePosition: 'left',
      showUserTicketLogModal: false,
      selectedBooking: null,
      selectedBookingIds: [],
      isCancellingBooking: false,
      orderByOptions: [
        { value: null, label: '수업일 내림차순' },
        { value: 'asc', label: '수업일 오름차순' },
      ],
      exceldownloading: false,
    };
  },

  computed: {
    member() {
      return this.$store.getters['member/member'];
    },
    userTicketsActive() {
      return this.$store.getters['member/userTicketsActive'];
    },
    userTicketsInactive() {
      return this.$store.getters['member/userTicketsInactive'];
    },
    bookingHistory() {
      return this.$store.getters['member/bookingHistory'];
    },
    bookingHistoryParams() {
      return this.$store.getters['member/bookingHistoryParams'];
    },
    bookingHistoryTotal() {
      return this.$store.getters['member/bookingHistoryTotal'];
    },
    bookingHistoryCount() {
      return this.$store.getters['member/bookingHistoryCount'];
    },
    bookingHistoryLoading() {
      return this.$store.getters['member/bookingHistoryLoading'];
    },

    currentTab: {
      get() {
        const { status } = this.bookingHistoryParams;
        let tab;
        if (!status) {
          tab = 'all';
        } else if (status.includes(BOOKING_STATUS.BOOKED)) {
          tab = BOOKING_STATUS.BOOKED;
        } else {
          tab = status[0];
        }

        return tab;
      },
      set(tab) {
        let status;
        if (tab === 'all') {
          status = null;
        } else if (tab === BOOKING_STATUS.BOOKED) {
          status = [...BOOKING_STATUS_ARRAY.BOOKED];
        } else {
          status = [tab];
        }

        this.$store.commit('member/SET_BOOKING_HISTORY_PARAMS', { status });
      },
    },

    isOnCancelTab() {
      return this.currentTab === BOOKING_STATUS.CANCEL;
    },

    page: {
      get() {
        return this.bookingHistoryParams.page;
      },
      set(page) {
        this.$store.commit('member/SET_BOOKING_HISTORY_PARAMS', { page });
      },
    },

    orderBy: {
      get() {
        return this.bookingHistoryParams.orderBy;
      },
      set(orderBy) {
        this.$store.commit('member/SET_BOOKING_HISTORY_PARAMS', { orderBy });
      },
    },

    userTicketId: {
      get() {
        return this.bookingHistoryParams.userTicket ? Number(this.bookingHistoryParams.userTicket) : null
      },
      set(userTicket) {
        this.$store.commit('member/SET_BOOKING_HISTORY_PARAMS', { userTicket });
      },
    },

    tabs() {
      const { bookingStatus } = this.$utils.translate;

      return [
        BOOKING_STATUS.BOOKED,
        BOOKING_STATUS.ATTENDED,
        BOOKING_STATUS.ABSENT,
        BOOKING_STATUS.NOSHOW,
        BOOKING_STATUS.CANCEL,
      ].reduce(
        (reduced, key) => {
          const count =
            key === BOOKING_STATUS.BOOKED
              ? this.bookingHistoryCount.booked_count + this.bookingHistoryCount.booking_waiting_count
              : this.bookingHistoryCount[`${key}_count`] || 0;
          reduced.push({
            value: key,
            label: `${bookingStatus(key)}(${count})`,
          });
          return reduced;
        },
        [
          {
            value: 'all',
            label: `전체(${this.bookingHistoryCount.total_count})`,
          },
        ],
      );
    },

    bookings() {
      const mapped = {};
      this.bookingHistory.forEach(booking => {
        const date = booking.lecture.start_on.slice(0, 10);
        if (!mapped[date]) {
          mapped[date] = [booking];
        } else {
          mapped[date].push(booking);
        }
      });

      return {
        keys: Object.keys(mapped).sort((a, b) => b - a),
        data: mapped,
      };
    },

    userTicketOptions() {
      const activeTicketOptions = _.sortBy(this.generateUserTicketOptions(this.userTicketsActive), 'label');
      const inactiveTicketOptions = _.sortBy(this.generateUserTicketOptions(this.userTicketsInactive), 'label');

      return [{ value: null, label: '수강권 전체' }, ...activeTicketOptions, ...inactiveTicketOptions];
    },

    cancelableBookingIdsOnPage() {
      return this.bookingHistory.reduce((cancelableBookingIds, booking) => {
        if (this.isBookingCancelable(booking)) cancelableBookingIds.push(booking.id);
        return cancelableBookingIds;
      }, []);
    },

    isSelectedAll() {
      return this.cancelableBookingIdsOnPage.every(id => this.selectedBookingIds.includes(id));
    },

    selectAllButtonLabel() {
      return !this.isSelectedAll || this.cancelableBookingIdsOnPage.length <= 0 ? '전체 선택' : '전체 선택 해제';
    },
  },

  watch: {
    currentTab() {
      this.dispatchGetBookingHistory(true);
    },
    userTicketId() {
      this.dispatchGetBookingHistory(true);
    },
    orderBy() {
      this.dispatchGetBookingHistory(true);
    },
  },

  created() {
    const { userTicketId } = this.$route.query;
    if (userTicketId) this.userTicketId = userTicketId;

    this.$nextTick(() => {
      window.addEventListener('resize', this.setTitlePosition);
    });
  },

  destroyed() {
    window.removeEventListener('resize', this.setTitlePosition);
  },

  methods: {
    dispatchGetBookingHistory(toFirstPage = false) {
      this.$store.dispatch('member/getBookingHistory', this.member.id);
      if (toFirstPage) {
        this.page = 1;
        this.selectedBookingIds = [];
      }
    },

    isBookingCancelable(booking) {
      const { lecture, status } = booking;
      return (
        status !== BOOKING_STATUS.CANCEL &&
        !lecture.deleted_at &&
        this.canDeleteLectureBookings(lecture.course.type, lecture.instructor_id)
      );
    },

    async handleClickCancelDelete(booking) {
      try {
        const title = `취소 삭제`;
        const message = `
          <p style="color: #f95454;">삭제된 취소내역은 복구할 수 없으며, 예약내역에서도 확인할 수 없습니다.</p>
          <p>취소를 삭제하시겠습니까?</p>
        `;
        const proceed = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (proceed) {
          this.isCancellingBooking = true;
          await this.$api.booking.cancelDelete(booking.id);
          this.$utils.notify.success(
            this,
            '확인',
            `취소가 삭제되었습니다.`,
          );
          const maxPageAfterDelete = Math.ceil((this.bookingHistoryTotal - 1) / 10);
          if (maxPageAfterDelete <= this.page) this.page = maxPageAfterDelete;
          this.dispatchGetBookingHistory();
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isCancellingBooking = false;
      }
    },
    
    async handleClickCancelBooking(booking) {
      try {
        const bookingStatus = this.$utils.translate.bookingStatus(booking.status);
        const title = `${bookingStatus} 취소`;
        const message = `${bookingStatus}${bookingStatus === '노쇼' ? '를' : '을'} 취소 하시겠습니까?`;
        const proceed = await this.$confirm(message, title)
          .then(() => true)
          .catch(() => false);

        if (proceed) {
          this.isCancellingBooking = true;
          await this.$api.booking.delete(booking.id);
          this.$utils.notify.success(
            this,
            '확인',
            `${bookingStatus}${bookingStatus === '노쇼' ? '가' : '이'} 취소되었습니다.`,
          );
          const maxPageAfterDelete = Math.ceil((this.bookingHistoryTotal - 1) / 10);
          if (maxPageAfterDelete <= this.page) this.page = maxPageAfterDelete;
          this.dispatchGetBookingHistory();
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isCancellingBooking = false;
      }
    },

    handleClickShowLog(bookingId) {
      this.selectedBooking = this.bookingHistory.find(({ id }) => id === bookingId);
      this.showUserTicketLogModal = true;
    },

    async handleClickBulkCancel() {
      try {
        const count = this.$filters.comma(this.selectedBookingIds.length);
        const label = this.currentTab === 'all' ? '예약' : this.$utils.translate.bookingStatus(this.currentTab);
        const title = '일괄 예약 취소';
        const message = `${count}개의 ${label}을 취소 하시겠습니까?`;
        const proceed = await this.$confirm(message, title)
          .then(() => true)
          .catch(() => false);

        if (proceed) {
          this.isCancellingBooking = true;
          await this.$api.booking.deleteBulk(this.selectedBookingIds);
          this.$utils.notify.success(this, null, `${count}개의 예약이 일괄 취소되었습니다.`);
          const maxPageAfterDelete = Math.ceil((this.bookingHistoryTotal - count) / 10);
          if (maxPageAfterDelete <= this.page) this.page = maxPageAfterDelete;
          this.dispatchGetBookingHistory();
          this.selectedBookingIds = [];
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isCancellingBooking = false;
      }
    },

    async handleClickExcelDown() {
      try {
        this.exceldownloading = true;
        const params = _.omit(this.bookingHistoryParams, ['page', 'per_page']);
        const res = await this.$api.member.getBookings(this.member.id, params);
        const json = this.formatJSON(res.data);

        const memberName = _.get(this.member, 'profile.name');
        const title = `${memberName}회원_이용내역.xlsx`;

        this.$utils.downloadExcel(json, title);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.exceldownloading = false;
      }
    },

    handleClickBooking(lectureId, booking) {
      if (!lectureId || !booking) return;

      if (this.selectedBookingIds.includes(booking.id)) {
        this.selectedBookingIds = this.selectedBookingIds.filter(id => id !== booking.id);
      } else if (this.isBookingCancelable(booking)) {
        this.selectedBookingIds.push(booking.id);
      }
    },

    generateUserTicketOptions(userTickets) {
      return userTickets.reduce((options, userTicket) => {
        options.push({
          value: userTicket.id,
          label: userTicket.ticket.title,
          status: this.$utils.getTicketStatus(userTicket),
        });
        return options;
      }, []);
    },

    toggleSelectAll() {
      if (this.isSelectedAll) {
        this.selectedBookingIds = this.selectedBookingIds.filter(id => !this.cancelableBookingIdsOnPage.includes(id));
      } else {
        this.selectedBookingIds = [...this.selectedBookingIds, ...this.cancelableBookingIdsOnPage];
      }
    },

    setTitlePosition() {
      this.titlePosition = window.innerWidth <= 568 ? 'right' : 'left';
    },

    formatJSON(bookings) {
      const { moment } = this;
      const { translate } = this.$utils;

      return bookings.map(booking => {
        const startOn = _.get(booking, 'lecture.start_on');
        const endOn = _.get(booking, 'lecture.end_on');
        const bookingStatus = _.get(booking, 'status');

        return {
          수업명: _.get(booking, 'lecture.title'),
          수업일: moment(startOn).format('YYYY-MM-DD'),
          시작시간: moment(startOn).format('HH:mm'),
          종료시간: moment(endOn).format('HH:mm'),
          강사: _.get(booking, 'lecture.instructor.profile.name'),
          룸: _.get(booking, 'lecture.room.name'),
          최대수강인원: _.get(booking, 'lecture.max_trainee'),
          사용된수강권: _.get(booking, 'user_ticket.metadata.title'),
          최종상태: translate.bookingStatus(bookingStatus),
          최종상태변경일시: _.get(booking, 'updated_at'),
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.member-history__header {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  position: sticky;
  background: #fff;
  top: 0;
  padding: 10px 0 20px;
  z-index: 1;

  @include mq(1024px) {
    grid-template-columns: repeat(2, auto);
  }

  &__buttons {
    @include flex(row, center);
    flex-wrap: wrap;

    & > * {
      margin: 0 8px 8px 0;

      @include mq(1024px) {
        margin-bottom: 0;
      }
    }

    /deep/ .el-select.is-filtered {
      /deep/ .el-input__inner {
        color: $color-primary;
      }
    }

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 8px;
      font-size: 16px;
      height: 38px;
      max-width: 133px;
      padding: 0 20px 0 15px;

      &:hover {
        border-radius: 8px;
      }
    }

    &__order-by-select {
      /deep/ .el-input__inner {
        max-width: 150px;
      }
    }

    /deep/ .el-select-dropdown {
      max-width: 300px;
    }

    button {
      width: 112px;
      padding: 8px 4px !important;
    }
  }

  &__tabs {
    margin-left: auto;
  }

  &__excel-down {
    @include mq(1024px) {
      grid-column: span 2;
      justify-self: end;
    }
  }

  &__user-ticket-select__option {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 5px;
    align-items: center;
    width: 298px;

    span {
      @include ellipsis;
    }

    span:nth-child(2) {
      border: 1px solid $color-danger;
      border-radius: 4px;
      color: $color-danger;
      font-size: 12px;
      padding: 0 5px;
      line-height: 1.5;
    }
  }
}

.member-history__list {
  padding: 0 0 20px;

  &-pagination {
    text-align: center;
    overflow: auto;
  }
}
</style>
