<template>
  <div class="service-inspection">
    <div>
      <div class="icon-wrap">
        <img src="@/assets/images/icons/icon_service-inspection.svg" />
      </div>
      <div class="text-title">서비스 점검 안내</div>
      <div class="text-content">안녕하세요. 스튜디오메이트입니다.</div>
      <div class="text-content">시스템 점검 및 고도화를 위해 <span class="blue">서비스가 일시 중단</span>됩니다.</div>
    </div>
    <div class="card">
      <div class="row">
        <span>점검 기간</span>
        <div class="column">
          <span class="bold">{{ time() }}</span>
          <span class="small-text">* 작업시간은 진행 상황에 따라 단축 또는 연장될 수 있습니다.</span>
        </div>
      </div>
      <div class="row">
        <span>중단 서비스</span>
        <span class="bold">웹/앱 및 전체 서비스</span>
      </div>
    </div>
    <div class="info-list">
      <li>
        작업시간 동안
        <b> 모든 서비스 운영이 중단</b>되니 유의해 주시길 바랍니다.
      </li>
      <li>서비스를 이용하는 <b>회원님들에게도 앱 푸시메세지로 사전 공지</b>됩니다.</li>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      startTime: moment(),
      endTime: moment(),
    };
  },

  watch: {
    $route: {
      handler: 'fetchData',
      immediate: true,
    },
  },

  methods: {
    fetchData() {
      const startTime = this.$utils.getCookie('inspection-start-time');
      const endTime = this.$utils.getCookie('inspection-end-time');

      this.startTime = startTime !== 'undefined' && startTime ? moment(startTime) : moment();
      this.endTime = endTime !== 'undefined' && endTime ? moment(endTime) : moment();
    },

    time() {
      const dayDiff = moment.duration(this.endTime.diff(this.startTime)).days();
      const timeDiff = moment.duration(this.endTime.diff(this.startTime)).asHours();
      const hour = Math.floor(timeDiff);
      const minute = Math.floor((timeDiff - hour) * 61);

      return dayDiff
        ? `${moment(this.startTime).format('M. D(ddd) HH:mm')} ~ ${moment(this.endTime).format('M. D(ddd) HH:mm')} (${hour}시간${
            minute ? ` ${minute}분` : ''
          })`
        : `${moment(this.startTime).format('M. D(ddd) HH:mm')} ~ ${moment(this.endTime).format('HH:mm')} (${hour}시간${
            minute ? ` ${minute}분` : ''
          })`;
    },
  },
};
</script>

<style lang="scss" scoped>
.service-inspection {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 64px 0 64px;
  justify-content: center;
  font-size: 20px;

  .icon-wrap {
    img {
      width: 140px;
      height: 140px;
    }
  }

  .text-title {
    margin-bottom: 8px;
    font-size: 40px;
    font-weight: bold;
  }

  .text-content {
    color: #000;
  }

  .blue {
    font-weight: bold;
    color: #4a8eff;
  }
}

.card {
  margin: 40px 0;
  padding: 32px;
  background-color: #f8f8f8;
  border-radius: 8px;
  /* width: 100%; */

  div {
    .bold {
      font-weight: 500;
      color: #000;
    }
  }

  .small-text {
    font-size: 16px;
    color: #666666;
    margin-bottom: 16px;
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: row;

    span:first-child {
      min-width: 180px;
    }
  }
}

.info-list {
  color: #666666;

  li {
    padding: 4px 0;
    list-style: disc;
  }

  li::marker {
    font-size: 1rem;
  }
}
</style>
