<template>
  <MainLayout :padded="false">
    <div class="sales__password-input" v-if="showPasswordInput">
      <el-card>
        <h4>비밀번호 입력</h4>
        <p>
          매출 페이지 접근을 위한 비밀번호를 입력해주세요.
        </p>
        <input type="text" style="width: 0; height: 0; padding: 0; margin: 0;" />
        <TextInput
          ref="password"
          v-model="password"
          type="password"
          size="large"
          placeholder="비밀번호를 입력해주세요."
          :error="$v.password.$dirty && !$v.password.required"
          @input="$v.password.$touch()"
          @enter="handleClickAuth"
          autocomplete="new-password"
          required
        />
        <BaseButton size="large" @click="handleClickAuth">
          확인
        </BaseButton>
      </el-card>
    </div>

    <el-dialog
      class="sales__auth-dialog"
      title="로그인 만료"
      :visible.sync="showAuthRefreshDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p v-if="!authExpired">{{ authRefreshExpire }}초 후에 매출 페이지에서 로그아웃됩니다.</p>
      <p v-else>
        장시간 사용이 없어 매출 페이지에서 로그아웃 되었습니다.<br />
        다시 로그인 해주세요.
      </p>
      <span slot="footer">
        <BaseButton size="large" @click="authExpired ? resetData() : resetAuthTimer()">
          {{ authExpired ? '확인' : '로그인 연장' }}
        </BaseButton>
      </span>
    </el-dialog>
    <div v-if="!showPasswordInput && !authExpired" v-loading="loading">
      <Header />
      <section class="sales__body">
        <SubHeader :ruleType="this.filter.rangeType" />
        <div v-if="activeTab !== 'unpayedRevenue'">
          <Summary />
          <Dashboard v-if="activeSubTab === 'dashboard'" />
          <List v-else-if="activeSubTab === 'list'" :list-data="filteredListData" />
        </div>
        <UnpayedList v-else />
      </section>
    </div>
  </MainLayout>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Header from '@components/Sales/Header';
import SubHeader from '@components/Sales/SubHeader';
import Summary from '@components/Sales/Summary';
import Dashboard from '@components/Sales/Dashboard';
import UnpayedList from '@components/Sales/UnpayedList';
import List from '@components/Sales/List';
import { PAYMENT_VAR } from '@constants';

const AUTH_EXPIRE = 60 * 10; // 10분
const AUTH_REFRESH_EXPIRE = 10;

export default {
  components: {
    Header,
    SubHeader,
    Summary,
    Dashboard,
    List,
    UnpayedList,
  },

  data() {
    return {
      password: '',
      authTimer: null,
      authExpire: AUTH_EXPIRE,
      showAuthRefreshDialog: false,
      authRefreshTimer: null,
      authRefreshExpire: AUTH_REFRESH_EXPIRE,
      authExpired: false,
    };
  },

  computed: {
    data() {
      return this.$store.getters['sales/data'];
    },
    filter() {
      return this.$store.getters['sales/filter'];
    },
    activeTab() {
      return this.$store.getters['sales/activeTab'];
    },
    activeSubTab() {
      return this.$store.getters['sales/activeSubTab'];
    },
    loading() {
      return this.$store.getters['sales/loading'];
    },
    listFilter() {
      return this.$store.getters['sales/listFilter'];
    },
    showPasswordInput() {
      return this.$store.getters['sales/showPasswordInput'];
    },

    filteredListData() {
      if (!this.activeTab) return [];
      const { data, activeTab, filter, filterRow } = this;
      const listData = data[activeTab][filter.date] || [];
      return listData.filter(row => filterRow(row));
    },
  },

  watch: {
    filter: {
      handler() {
        this.$store.dispatch('sales/getData');
      },
      immediate: false,
    },
    showPasswordInput: {
      handler: 'watchShowPasswordInput',
      immediate: true,
    },
    authExpire() {
      if (this.authExpire <= 0) {
        clearInterval(this.authTimer);
        this.showAuthRefreshDialog = true;
        this.authRefreshExpire = AUTH_REFRESH_EXPIRE;
        this.authRefreshTimer = setInterval(() => {
          this.authRefreshExpire--;
        }, 1000);
      }
    },
    authRefreshExpire() {
      if (this.authRefreshExpire <= 0) {
        clearInterval(this.authRefreshTimer);
        this.authExpired = true;
      }
    },
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    password: { required },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.isStudioOwner && !vm.canViewSales) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/');
      }
      next();
    });
  },

  created() {
    // this.$store.commit('sales/SET_FILTER');
    this.$store.commit('sales/SET_LIST_FILTER');
    this.$store.commit('sales/SET_RAW_DATA');
    this.$store.dispatch('sales/getInitialData');
    if (this.$route.query && this.$route.query.token === 'studiomate') {
      this.$store.commit('sales/SET_SHOW_PASSWORD_INPUT', false);
    }
  },

  mounted() {
    if (!this.showPasswordInput) {
      this.resetAuthTimer();
    }
  },

  destroyed() {
    clearInterval(this.authTimer);
    clearInterval(this.authRefreshTimer);
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('sales/SET_SHOW_PASSWORD_INPUT', true);
    next();
  },

  methods: {
    filterRow(row) {
      const { paymentType, staffName, usageType, courseType, paymentMethod, ticketTitle, searchText } = this.listFilter;

      /** 매출 구분 필터 value 구분 로직 */
      let isPaymentTypeMatch = false;

      if (this.activeTab === 'lecturesRevenue' || paymentType === null) {
        isPaymentTypeMatch = true;
      } else if (paymentType === row.paymentType) {
        isPaymentTypeMatch = true;
      } else if (
        paymentType === PAYMENT_VAR.new &&
        row.isNew &&
        row.paymentType === PAYMENT_VAR.purchase &&
        !row.tutorialTicket
      ) {
        isPaymentTypeMatch = true;
      } else if (paymentType === PAYMENT_VAR.tutorial && row.paymentType === PAYMENT_VAR.purchase && row.tutorialTicket) {
        isPaymentTypeMatch = true;
      } else if (
        paymentType === PAYMENT_VAR.renew &&
        !row.tutorialTicket &&
        ((!row.isNew && row.paymentType === PAYMENT_VAR.purchase) || row.paymentType === PAYMENT_VAR.extension)
      ) {
        isPaymentTypeMatch = true;
      } else if (
        paymentType === 'installment_payment' &&
        (row.paymentType === 'installment_payment' || row.paymentType === 'full_payment')
      ) {
        isPaymentTypeMatch = true;
      }

      const isStaffNameMatch =
        !staffName.length ||
        staffName.some(name => {
          if (!row.staffName) return false;
          return row.staffName.split(',').some(v => {
            return name.toLowerCase() === v.trim().toLowerCase();
          });
        });
      const isUsageTypeMatch = this.activeTab === 'ticketsRevenue' ? true : usageType === null || row.usageType === usageType;
      const isCourseTypeMatch = courseType === null || courseType === row.courseType;
      const isPaymentMethodMatch =
        this.activeTab === 'lecturesRevenue'
          ? true
          : paymentMethod === null || row.paymentMethod === 'mixed' || paymentMethod === row.paymentMethod;
      const isTicketTitleMatch =
        !ticketTitle.length ||
        ticketTitle.some(name => {
          return name.toLowerCase() === row.ticketTitle.toLowerCase();
        });
      const containsSearchText = row.memberName.toLowerCase().includes(searchText.toLowerCase());

      return (
        isPaymentTypeMatch &&
        isStaffNameMatch &&
        isUsageTypeMatch &&
        isCourseTypeMatch &&
        isPaymentMethodMatch &&
        isTicketTitleMatch &&
        containsSearchText
      );
    },

    handleChangeTab(tab) {
      this.currentTab = tab;
    },

    async handleClickAuth() {
      if (this.$v.$invalid || this.$v.$anyError) {
        this.$v.password.$touch();
        return;
      }

      try {
        const res = await this.$api.sales.verifySalesPassword({
          password: this.password,
        });
        const verified = res.data.verify;
        if (verified) {
          this.$store.commit('sales/SET_SHOW_PASSWORD_INPUT', false);
          this.resetAuthTimer();
        } else {
          this.$utils.notify.error(this, '오류', '비밀번호가 일치하지 않습니다.');
          this.$refs.password.$el.querySelector('input').select();
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    resetAuthTimer() {
      this.authExpire = AUTH_EXPIRE;
      this.authTimer = setInterval(() => {
        this.authExpire--;
      }, 1000);
      clearInterval(this.authRefreshTimer);
      this.showAuthRefreshDialog = false;
    },

    watchShowPasswordInput() {
      if (this.showPasswordInput) {
        this.$nextTick(() => {
          this.$refs.password.$el.querySelector('input').focus();
        });
        clearInterval(this.authTimer);
      } else {
        this.password = '';
        this.$v.password.$reset();
      }
    },

    resetData() {
      this.$store.commit('sales/SET_SHOW_PASSWORD_INPUT', true);
      this.password = '';
      this.authTimer = null;
      this.authExpire = AUTH_EXPIRE;
      this.showAuthRefreshDialog = false;
      this.authRefreshTimer = null;
      this.authRefreshExpire = AUTH_REFRESH_EXPIRE;
      this.authExpired = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sales__password-input {
  width: 100%;
  height: 100%;
  background: #ebebeb;
  padding: 15vh 0;

  /deep/ .el-card {
    width: 95%;
    max-width: 400px;
    margin: auto;
  }

  /deep/ .el-card__body {
    @include flex(column);
    h4,
    p {
      margin-bottom: 12px;
    }

    button {
      margin: 12px 0 0 auto;
    }
  }
}

.sales__auth-dialog {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 400px;
  }

  /deep/ .el-dialog__body {
    padding: 20px 20px 10px;
  }
}

.sales__body {
  max-width: 1440px;
  margin: auto;
  padding: 0 20px 30px;

  @include mq(568px) {
    padding: 0px 30px 30px;
  }
}
</style>
