<template>
  <el-dialog
    :visible.sync="show"
    @close="close"
    :show-close="false"
    width="620px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="spending-form-container" v-loading="loading">
      <div class="header">
        <h3 class="title">{{ title }}</h3>
        <div class="message" v-if="type === 'view' && !modifiable">
          <img src="@assets/images/icons/sales_spending_info.svg" alt="info" />
          <span>해당 할부결제가 최초로 등록된 내역에서 수정 / 삭제할 수 있습니다.</span>
        </div>
        <div class="buttons">
          <img
            src="@assets/images/icons/sales_edit_disable.svg"
            alt="수정불가"
            v-if="type === 'view' && !modifiable"
            :style="{ cursor: 'default' }"
          />
          <img src="@assets/images/icons/sales_edit.svg" alt="수정" v-if="type === 'view' && modifiable" @click="clickModify" />
          <img src="@assets/images/icons/sales_exit.svg" alt="닫기" @click="close" />
        </div>
      </div>
      <div class="content">
        <div class="left">
          <textarea placeholder="지출 내용을 입력해 주세요." v-model="contents" :readonly="type === 'view'" />
          <div class="images" v-if="imageUrls.length > 0">
            <div
              :class="{ 'img-container': true, pointer: type === 'view' }"
              v-for="(image, i) in imageUrls"
              :key="image"
              @click="() => handleImageClick(image)"
            >
              <img :src="image" alt="업로드 이미지" />
              <img
                src="@assets/images/icons/image-close.svg"
                alt="삭제"
                class="image-delete"
                @click="
                  () => {
                    deleteImage(i);
                  }
                "
                v-if="type !== 'view'"
              />
            </div>
          </div>
          <button class="add-image" v-if="type !== 'view'" @click="handleClickAttachImage" :disabled="imageUrls.length >= 3">
            사진 추가
          </button>
          <input id="image-input" ref="attachImage" type="file" accept=".jpg, .jpeg, .png" @change="handleChangeImage" multiple />
          <div class="image-explanation" v-if="type !== 'view'">최대 3장 / 장당 2MB 이하 (jpg, jpeg, png)만 업로드 가능해요</div>
        </div>
        <div class="right">
          <div class="inputs">
            <div class="line">
              <span class="category">카드</span>
              <PriceInput
                :value="cardAmount"
                @input="
                  v => {
                    cardAmount = v;
                  }
                "
                :readonly="type === 'view'"
              />
            </div>
            <div class="line">
              <div class="radio">
                <el-radio
                  v-model="cardPaymentType"
                  v-for="option in cardPaymentOptions"
                  :key="option.value"
                  :label="option.value"
                  :disabled="type === 'view'"
                  >{{ option.label }}</el-radio
                >
              </div>
              <NumberInput
                v-model="installmentPeriod"
                :min="2"
                :max="99"
                suffix="개월"
                :disabled="cardPaymentType === 'full' || type === 'view'"
              />
            </div>
            <div class="line">
              <span class="category">현금</span>
              <PriceInput
                :value="cashAmount"
                @input="
                  v => {
                    cashAmount = v;
                  }
                "
                :readonly="type === 'view'"
              />
            </div>
            <div class="line">
              <span class="category">계좌이체</span>
              <PriceInput
                :value="wiretransferAmount"
                @input="
                  v => {
                    wiretransferAmount = v;
                  }
                "
                :readonly="type === 'view'"
              />
            </div>
            <div class="line">
              <span class="category">담당 강사</span>
              <el-select
                :value="staffId"
                @change="
                  value => {
                    staffId = value;
                  }
                "
                :filterable="true"
                :disabled="type === 'view'"
              >
                <el-option label="선택 안함" :value="null"></el-option>
                <el-option v-for="staff in staffs" :key="staff.id" :label="staff.name" :value="staff.id"></el-option>
              </el-select>
            </div>
            <div class="line">
              <span class="category">결제일시</span>
              <div class="input">
                <el-date-picker
                  type="date"
                  placeholder="결제 일시"
                  format="yyyy.MM.dd"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  v-model="date"
                  :readonly="type === 'view'"
                ></el-date-picker>
                <el-time-select
                  format="HH:mm"
                  value-format="HH:mm"
                  placeholder="종료시각"
                  v-model="time"
                  :clearable="false"
                  :picker-options="{ start: '00:00', step: '00:01', end: '23:59' }"
                  :readonly="type === 'view'"
                />
              </div>
            </div>
          </div>
          <div class="memo-title">메모</div>
          <div class="memo-textarea">
            <textarea placeholder="최대 200자까지 입력 가능합니다." maxlength="200" v-model="memo" :readonly="type === 'view'" />
            <span class="length">{{ memo.length }} / 200</span>
          </div>
        </div>
      </div>
      <div class="buttons" v-if="type !== 'view'">
        <button class="cancel" v-if="type === 'regist'" @click="close">취소</button>
        <button
          class="delete"
          v-if="type === 'modify'"
          @click="
            () => {
              deleteSpending(spending.id);
            }
          "
        >
          삭제
        </button>
        <button class="save" @click="saveSpending" :disabled="saveButtonDisabled">
          {{ type === 'regist' ? '저장하기' : '수정하기' }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    show: Boolean,
    type: String,
    close: Function,
    spending: Object,
    clickModify: Function,
    registComplete: Function,
    modifyComplete: Function,
    deleteComplete: Function,
    handleImageClick: Function,
  },

  data() {
    return {
      loading: false,
      contents: '',
      cardPaymentType: 'full',
      cardPaymentOptions: [
        { label: '일시불', value: 'full' },
        { label: '할부', value: 'installment' },
      ],
      installmentPeriod: 2,
      cardAmount: 0,
      cashAmount: 0,
      wiretransferAmount: 0,
      memo: '',
      date: moment().format('YYYY.MM.DD'),
      time: moment().format('HH:mm'),
      images: [],
      imageUrls: [],
      staffId: null,
    };
  },

  watch: {
    show: function() {
      if (!this.show) this.reset();
      else {
        if (this.type === 'view') {
          this.matchSpending();
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch('staffs/getAllStaffs');
  },

  computed: {
    title() {
      if (this.type === 'regist') return '지출 등록';
      else if (this.type === 'modify') return '지출 수정';
      else if (this.type === 'view') return '지출 정보';
      return '';
    },
    paymentMethod() {
      let result = '';
      if (this.cardAmount > 0) result = 'card';
      if (this.cashAmount > 0) {
        if (result === '') result = 'cash';
        else result = 'mixed';
      }
      if (this.wiretransferAmount > 0) {
        if (result === '') result = 'cash_transfer';
        else result = 'mixed';
      }
      return result;
    },
    saveButtonDisabled() {
      if (this.cardAmount === 0 && this.cashAmount === 0 && this.wiretransferAmount === 0) return true;
      if (this.contents.trim().length === 0) return true;
      return false;
    },
    staffs() {
      return this.$store.getters['staffs/staffs'];
    },
    modifiable() {
      return this.spending.id === this.spending.parent_id;
    },
  },

  methods: {
    reset() {
      this.contents = '';
      this.cardPaymentType = 'full';

      this.installmentPeriod = 2;
      this.cardAmount = 0;
      this.cashAmount = 0;
      this.wiretransferAmount = 0;
      this.memo = '';
      this.date = moment().format('YYYY.MM.DD');
      this.time = moment().format('HH:mm');
      this.images = [];
      this.imageUrls = [];
      this.staffId = null;
    },

    handleClickAttachImage() {
      this.$refs.attachImage.click();
    },

    handleChangeImage(e) {
      let inputFiles = e.target.files;
      if (!inputFiles || inputFiles.length === 0) return;

      if (this.images.length + inputFiles.length > 3) {
        inputFiles = [...inputFiles].slice(0, 3 - this.images.length);
        if (inputFiles.length === 0) return;
      }
      const tempFiles = [];
      const tempFileUrls = [];
      for (let i = 0; i < inputFiles.length; i++) {
        tempFiles.push(inputFiles[i]);
        tempFileUrls.push(URL.createObjectURL(inputFiles[i]));
      }
      this.images = [...this.images, ...tempFiles];
      this.imageUrls = [...this.imageUrls, ...tempFileUrls];
      e.target.value = null;
    },

    deleteImage(index) {
      URL.revokeObjectURL(this.imageUrls[index]);
      this.images = this.images.filter((v, i) => i !== index);
      this.imageUrls = this.imageUrls.filter((v, i) => i !== index);
    },

    async getAttachmentIds() {
      if (this.images.length === 0) return;

      const responses = await Promise.all(
        this.images.map(async file => {
          if (file.id) return undefined;
          const formData = new FormData();
          formData.append('files[]', file);
          return this.$api.attachment.upload(formData);
        }),
      );

      const ids = responses.map((res, i) => {
        if (res) return res.data[0].id;
        return this.images[i].id;
      });
      return ids;
    },

    async saveSpending() {
      this.loading = true;
      try {
        const attachmentIds = await this.getAttachmentIds();
        const data = {
          context: this.contents,
          memo: this.memo,
          payment_method: this.paymentMethod,
          staff_id: this.staffId,
          card_amount: this.cardAmount,
          cash_amount: this.cashAmount,
          wiretransfer_amount: this.wiretransferAmount,
          installment_period: this.cardPaymentType === 'full' ? 1 : this.installmentPeriod,
          attachment_id: attachmentIds,
          settlement_at: moment(this.date + ' ' + this.time).format('YYYY-MM-DD HH:mm:ss'),
        };
        if (this.type === 'modify') {
          await this.$api.salesNew.updateSpending(this.spending.id, data);
          this.modifyComplete();
        } else {
          await this.$api.salesNew.createSpending(data);
          this.registComplete();
        }
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.loading = false;
      }
    },

    deleteSpending(id) {
      this.$confirm(
        '<span style="color: #e33c3c">삭제된 내용을 복구할 수 없습니다.</span><br/>지출 내용을 삭제하시겠습니까?',
        '지출 삭제',
        {
          confirmButtonText: '삭제',
          cancelButtonText: '취소',
          dangerouslyUseHTMLString: true,
          confirmButtonClass: 'red-button',
          customClass: 'delete-spending-confirm',
        },
      ).then(async () => {
        this.loading = true;
        try {
          await this.$api.salesNew.deleteSpending(id);
          this.deleteComplete();
        } catch (e) {
          this.$utils.notify.parseError(this, e);
        } finally {
          this.loading = false;
        }
      });
    },

    matchSpending() {
      this.contents = this.spending.context;
      this.cardPaymentType = this.spending.installment_period === 1 ? 'full' : 'installment';
      this.installmentPeriod = this.spending.installment_period;
      this.cardAmount = this.spending.card_amount;
      this.cashAmount = this.spending.cash_amount;
      this.wiretransferAmount = this.spending.wiretransfer_amount;
      this.memo = this.spending.memo;
      this.date = moment(this.spending.settlement_at).format('YYYY.MM.DD');
      this.time = moment(this.spending.settlement_at).format('HH:mm');
      this.images = this.spending.attachments;
      this.imageUrls = this.spending.attachments.map(att => this.getImageUrl(att.path));
      this.staffId = this.spending.staff_id;
    },

    getImageUrl(image) {
      const { host, name, path } = image;
      const [filename, extension] = name.split('.');
      return `${host}${path}/${filename}_0x0.${extension}`;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  display: none;
}

.spending-form-container {
  textarea {
    width: 100%;
    resize: none;
    font-size: 12px;
    color: #808080;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    padding: 15px;

    &::placeholder {
      color: #dcdfe6;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h3 {
      font-size: 18px;
      font-weight: bold;
    }

    .message {
      @include flex(row, center, flex-end);
      font-size: 12px;
      font-weight: 500;
      color: #556fa0;
      flex: 1;
      margin-right: 10px;

      img {
        margin-right: 5px;
      }
    }

    .buttons {
      img {
        cursor: pointer;
      }
      img + img {
        margin-left: 15px;
      }
    }
  }

  .content {
    display: flex;
    gap: 16px;

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      textarea {
        flex: 1;
      }

      .images {
        display: flex;
        width: 100%;
        .img-container {
          position: relative;

          &.pointer {
            cursor: pointer;
          }

          &:not(:last-child) {
            margin-right: 10px;
          }

          img:first-child {
            width: 86px;
            height: 86px;
            border-radius: 8px;
            object-fit: cover;
          }

          img.image-delete {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
          }
        }
      }

      button.add-image {
        width: 100%;
        background-color: #ecf3fe;
        color: #4a8eff;
        font-size: 12px;
        font-weight: 500;
        min-height: 40px;
        border-radius: 8px;

        &:disabled {
          background-color: #b5b5b5;
          color: white;
          cursor: default;
        }
      }

      #image-input {
        display: none;
      }

      .image-explanation {
        font-size: 10px;
        color: #dcdfe6;
        text-align: center;
      }
    }

    .right {
      flex: 1;

      .inputs {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        .line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 20px;
          }

          /deep/ .el-select,
          .price-input {
            width: 158px;
          }

          .input {
            display: flex;

            .el-date-editor {
              &:first-child {
                width: 100px;
              }
              &:last-child {
                width: 80px;
              }
            }
          }

          /deep/ .el-input__inner {
            border-bottom: 1px solid #e9ecef;

            &:disabled {
              color: #dcdfe6;
            }
          }

          .radio {
            display: flex;

            & > *:last-child {
              margin-left: 20px;
            }

            /deep/ .el-radio__input + .el-radio__label {
              font-size: 12px;
            }
            /deep/ .el-radio__input.is-checked + .el-radio__label {
              color: #64aeff;
            }

            /deep/ .el-radio__input .el-radio__inner {
              border-color: #ebebeb;
            }

            /deep/ .el-radio__input.is-checked .el-radio__inner {
              background: white;
              padding: 1px;

              &::after {
                content: '';
                background: #64aeff;
                width: 8px;
                height: 8px;
              }
            }
          }

          /deep/ .custom-number-input {
            margin-left: 15px;
          }

          .category {
            font-size: 12px;
            font-weight: 500;
          }

          /deep/ .el-input__inner {
            font-size: 12px;
            color: #808080;
          }

          /deep/ .el-input__suffix {
            font-size: 12px;
            color: #dcdfe6;
          }
        }
      }

      .memo-title {
        font-size: 16px;
        font-weight: 500;
        color: #808080;
        margin-bottom: 20px;
      }
      .memo-textarea {
        position: relative;
        height: 140px;

        textarea {
          height: 100%;
        }

        span.length {
          position: absolute;
          bottom: 15px;
          right: 20px;
          font-size: 10px;
          color: #dcdfe6;
        }
      }
    }
  }

  & > .buttons {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0;

    button {
      width: 132px;
      height: 32px;
    }

    button.cancel {
      border: 1px solid #8a8a8a;
      color: #8a8a8a;
    }
    button.delete {
      border: 1px solid #ff8b8b;
      color: #ff8b8b;
    }
    button.save {
      background-color: $dodger-blue;
      color: white;

      &:disabled {
        background-color: #b5b5b5;
        cursor: default;
      }
    }

    button + button {
      margin-left: 10px;
    }
  }
}
</style>
