<template>
  <el-dialog
    class="ticket-refund-modal"
    :visible.sync="show"
    :before-close="handleClickCancel"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <div slot="title" class="ticket-refund-modal__title">
      <h4>{{ title }} 환불</h4>
    </div>

    <PaymentDetail
      v-model="refundPayment"
      :userTicket="userTicket"
      @save="handleClickSave"
      @cancel="handleClickCancel"
      :saving="saving"
    />
  </el-dialog>
</template>

<script>
import PaymentDetail from './PaymentDetail';

export default {
  components: { PaymentDetail },

  props: { show: Boolean, title: String },

  data() {
    return {
      refundPayment: {
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        point_amount: 0,
        reward_point: 0,
        amount: 0,
        unpaid_amount: 0,
        installment: 1,
        installment_period: 1,
        method: '',
        settlement_at: this.moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      saving: false,
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },
    userTicket() {
      return _.get(this.formData, 'userTicket');
    },
  },

  methods: {
    async handleClickSave(payment) {
      if (!payment) return;

      const title = `${this.title} 환불`;
      const message = `${
        this.title === '수강권' ? `<p style="color: #f95454;">예약(예약 대기)은 자동으로 취소됩니다.</p>` : ''
      } <p>${this.title} 환불 하시겠습니까?</p>`;
      const proceed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (!proceed) return;

      try {
        this.saving = true;
        await this.$api.userTicket.refund(this.userTicket.id, payment);
        this.$utils.notify.success(this, '확인', `${this.title} 환불되었습니다.`);
        this.$emit('save', this.userTicket.id);
      } catch (error) {
        if (_.get(error, 'response.data.code') === 10612) {
          const title = '수강권 환불 실패';
          const message = '확정된 예약이 있어 환불할 수 없습니다.';
          await this.$alert(message, title, {
            showClose: false,
            dangerouslyUseHTMLString: true,
          })
            .then(() => true)
            .catch(() => false);
        } else {
          this.$utils.notify.parseError(this, error);
        }
      } finally {
        this.saving = false;
      }
    },

    handleClickCancel() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-refund-modal {
  /deep/ .el-dialog {
    @include flex(column);
    width: 90%;
    max-width: 620px;
  }

  /deep/ .el-dialog__body {
    flex: 1;
  }
}
</style>
