<template>
  <div class="sales-summary">
    <el-card v-for="item in summary[activeTab]" :key="item.value" shadow="hover">
      <div class="sales-summary__item">
        <label class="sales-summary__item__label">
          {{ item.label }}
        </label>
        <div class="sales-summary__item__content">
          <span class="count">
            {{ item.count | comma }}
          </span>
          <span class="amount">
            {{ item.amount | comma }}
          </span>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  computed: {
    summary() {
      return this.$store.getters['sales/summary'];
    },
    unpaid() {
      return this.$store.getters['sales/unpaidment'];
    },
    activeTab() {
      return this.$store.getters['sales/activeTab'];
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-summary {
  padding: 8px 0 16px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 16px;

  @include mq(768px) {
    grid-gap: 24px;
    padding: 12px 0 24px;
  }

  /deep/ .el-card {
    border: none;
    border-radius: 4px;
  }
  /deep/ .el-card__body {
    padding: 0;
  }

  &__item {
    @include flex(column);
    border: 1px solid $gray-200;
    border-radius: 4px;
    padding: 8px 16px;

    &__label {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &__content {
      @include flex(row, center, space-between);
      font-size: 21px;
      font-weight: bold;

      span::after {
        font-size: 12px;
      }

      .count::after {
        content: '건';
      }

      .amount::after {
        content: '원';
      }
    }
  }
}
</style>
