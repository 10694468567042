<template>
  <li class="memo-creating">
    <div class="memo-creating__input" :class="{ focus }">
      <Textarea ref="memo-creating" v-model="memo" @focus="focus = true" @blur="focus = false" required />
      <div class="memo-creating__images" :class="{ 'no-image': !attachments.length }">
        <div class="memo-creating__images__item" v-for="image in attachments" :key="image.id">
          <img :src="$utils.getImagePath(image.path, 0)" alt="memo image" />
          <CircleButton @click="handleRemoveImage(image.id)" size="small">
            <i class="el-icon-close"></i>
          </CircleButton>
        </div>
      </div>
    </div>
    <div class="memo-creating__buttons">
      <div class="memo-creating__buttons__attach-image">
        <PlainButton @click="handleClickAttachImage" type="info" :disabled="attachments.length >= attachmentLimit" round
          >사진 추가</PlainButton
        >
        <input ref="attachImage" type="file" accept=".jpg, .jpeg, .png" @change="handleChangeImage" />
      </div>

      <div class="memo-creating__buttons__base">
        <PlainButton @click="$emit('cancel')" type="danger" round>취소</PlainButton>
        <BaseButton @click="$emit('save')" :disabled="saveButtonDisabled" round>저장</BaseButton>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({ memo: '', attachments: [], attachment: [] }),
    },
    attachmentLimit: { type: Number, default: 3 },
  },

  data() {
    return {
      focus: false,
    };
  },

  computed: {
    memo: {
      get() {
        return this.value.memo || '';
      },
      set(memo) {
        this.$emit('input', { ...this.value, memo });
      },
    },

    attachments: {
      get() {
        return this.value.attachments || [];
      },
      set(attachments) {
        this.$emit('input', {
          ...this.value,
          attachments,
          attachment: attachments.map(({ id }) => id),
        });
      },
    },

    saveButtonDisabled() {
      return !this.value.memo && !this.value.attachment.length;
    },
  },

  created() {
    this.$nextTick(() => {
      this.$refs['memo-creating'].$el.focus();
    });
  },

  methods: {
    /** 사진 추가 버튼 클릭시 */
    handleClickAttachImage() {
      this.$refs.attachImage.click();
    },

    /** 이미지 추가 */
    async handleChangeImage(e) {
      const file = _.get(e, 'target.files[0]');

      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          return this.$utils.notify.error(this, '오류', '이미지 사이즈는 2MB까지 가능합니다.');
        }

        try {
          let convertedFile;
          if (file.type === 'image/png') {
            await this.$utils.convertPNGToJPEG(file).then(jpgFile => {
              convertedFile = jpgFile;
            });
          }

          const formData = new FormData();
          formData.append('files[]', convertedFile || file);
          const res = await this.$api.attachment.upload(formData);
          const uploaded = _.get(res, 'data[0]');
          if (uploaded) {
            this.attachments = [...this.attachments, uploaded];
          }
          e.target.value = null;
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    /** 이미지 제거 */
    handleRemoveImage(imageId) {
      this.attachments = this.attachments.filter(({ id }) => id !== imageId);
    },
  },
};
</script>

<style lang="scss" scoped>
.memo-creating {
  border: solid 1px #cccccc;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  @include flex(column, flex-end, flex-start);

  &__input {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;

    &.focus {
      border-color: $deep-sky-blue;
    }

    textarea {
      border: none;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    padding: 16px;

    &.no-image {
      padding: 0;
    }

    &__item {
      position: relative;

      img {
        width: 100%;
      }

      button {
        background: $gray-500;
        color: #fff;
        font-size: 16px;
        position: absolute;
        top: -12px;
        right: -12px;

        &:hover {
          background: $gray-200;
          color: $charcoal-grey;
        }
      }
    }
  }

  &__buttons {
    @include flex(row, center, space-between);
    margin-top: 8px;
    width: 100%;

    button + button {
      margin-left: 8px;
    }

    &__attach-image {
      position: relative;
      width: 93px;
      overflow: hidden;

      input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    &__base {
      button {
        width: 48px;
      }
    }
  }
}
</style>
