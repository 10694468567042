import moment from 'moment';
import translate from './translate';
import getPaymentMethod from './getPaymentMethod';
import { PAYMENT_VAR } from '@constants';

const DEFAULT_TICKETS_REVENUE = {
  total: 0,
  new: 0,
  renew: 0,
  upgrade: 0,
  refund: 0,
  tutorial: 0,
  installment_payment: 0,
  transfer: 0,
};
const DEFAULT_LECTURES_REVENUE = {
  total: 0,
  P: 0,
  G: 0,
};

export default (rangeType, period, data) => {
  const { payments, lectures, deducts } = data;

  const { start_date, end_date } = period;

  const amount = {
    ticketsRevenue: {
      [start_date]: {
        ...DEFAULT_TICKETS_REVENUE,
      },
    },
    lecturesRevenue: {
      [start_date]: {
        ...DEFAULT_LECTURES_REVENUE,
      },
    },
  };

  const count = {
    ticketsRevenue: {
      [start_date]: {
        ...DEFAULT_TICKETS_REVENUE,
      },
    },
    lecturesRevenue: {
      [start_date]: {
        ...DEFAULT_LECTURES_REVENUE,
      },
    },
  };

  const salesData = {
    ticketsRevenue: {
      [start_date]: [],
    },
    lecturesRevenue: {
      [start_date]: [],
    },
  };

  /**
   * 데이터 분류 키 생성
   * 기간: 시작일 ~ 종료일
   * 단위: 일, 주, 월
   */
  let dates = [start_date];

  if (rangeType === 'daterange') {
    rangeType = 'day';
  }

  let periodEndOn = moment(end_date)
    .subtract(1, rangeType)
    .add(1, 'day')
    .format('YYYY-MM-DD');
  let lastDate = start_date;
  while (moment(lastDate).isBefore(periodEndOn)) {
    lastDate = moment(lastDate)
      .add(1, rangeType)
      .format('YYYY-MM-DD');
    dates.push(lastDate);
    amount.ticketsRevenue[lastDate] = {
      ...DEFAULT_TICKETS_REVENUE,
    };
    count.ticketsRevenue[lastDate] = {
      ...DEFAULT_TICKETS_REVENUE,
    };
    salesData.ticketsRevenue[lastDate] = [];
    amount.lecturesRevenue[lastDate] = {
      ...DEFAULT_LECTURES_REVENUE,
    };
    count.lecturesRevenue[lastDate] = {
      ...DEFAULT_LECTURES_REVENUE,
    };
    salesData.lecturesRevenue[lastDate] = [];
  }

  /** 수강권 매출 */
  payments.forEach(row => {
    const date = moment(row.settlement_at)
      .startOf(rangeType === 'week' ? 'isoWeek' : rangeType)
      .format('YYYY-MM-DD');
    const totalAmount = row.status === 'refund' ? row.amount * -1 : row.amount;
    const paymentMethod = getPaymentMethod(row);
    const mappedRow = {
      payedOn: moment(row.settlement_at).format('YYYY-MM-DD'),
      staffName: row.staffs.map(({ name }) => name).join(', '),
      staffColor: null,
      courseType: row.course_type,
      ticketType: row.ticket_type,
      memberName: row.member_name,
      memberId: row.user_id,
      goodsId: row.goods_id,
      ticketTitle: row.title,
      isTicketActive: row.active,
      ticketMaxCoupon: row.ticket_type !== 'P' ? row.max_coupon : null,
      pricePerCoupon:
        row.ticket_type !== 'P' && row.status === 'purchase' && row.ticket_max_coupon !== 0
          ? parseInt(row.amount / row.ticket_max_coupon)
          : null,
      cashAmount: _.get(row, 'cash_amount', 0),
      wiretransferAmount: _.get(row, 'wiretransfer_amount', 0),
      cardAmount: _.get(row, 'card_amount', 0),
      totalAmount,
      unpaidAmount: row.unpaid_amount,
      paymentMethod,
      isNew: row.new,
      paymentType: row.status,
      hasWiretransferAmount: !!row.wiretransfer_amount,
      tutorialTicket: row.is_ignore_new_payment,
    };

    /**
     * 금액 합산
     * 1. 총액에 무조건 합산
     * 2. 신규/재결제/업그레이드/환불 분류 합산
     */
    const previousTotal = amount.ticketsRevenue[date];
    if (count.ticketsRevenue[date] && amount.ticketsRevenue[date]) {
      count.ticketsRevenue[date].total++;
      amount.ticketsRevenue[date].total = previousTotal.total + totalAmount;
      salesData.ticketsRevenue[date].push(mappedRow);

      if (row.status === 'installment_payment' || row.status === 'full_payment') {
        count.ticketsRevenue[date].installment_payment++;
        amount.ticketsRevenue[date].installment_payment = previousTotal.installment_payment + totalAmount;
      } else if (row.status === PAYMENT_VAR.transfer) {
        count.ticketsRevenue[date].transfer++;
        amount.ticketsRevenue[date].transfer = previousTotal.transfer + totalAmount;
      } else if (row.is_ignore_new_payment && row.status !== PAYMENT_VAR.refund) {
        count.ticketsRevenue[date].tutorial++;
        amount.ticketsRevenue[date].tutorial = previousTotal.tutorial + totalAmount;
      } else if (row.status === PAYMENT_VAR.upgrade) {
        count.ticketsRevenue[date].upgrade++;
        amount.ticketsRevenue[date].upgrade = previousTotal.upgrade + totalAmount;
      } else if (row.status === PAYMENT_VAR.refund) {
        count.ticketsRevenue[date].refund++;
        amount.ticketsRevenue[date].refund = previousTotal.refund + totalAmount;
      } else if (row.new) {
        count.ticketsRevenue[date].new++;
        amount.ticketsRevenue[date].new = previousTotal.new + totalAmount;
      } else {
        count.ticketsRevenue[date].renew++;
        amount.ticketsRevenue[date].renew = previousTotal.renew + totalAmount;
      }
    }
  });

  /**
   * 수업 매출
   * 수강권 매출은 언제든지 회원이 환불을 요구 할 수 있는 확정되지 않은 매출로 간주.
   * 따라서, 수업 회차가 진행될 때마다 수업으로 인한 매출이 발생한 것으로 통계를 보여줌.
   */

  /** 수업 매출 - 수업 */
  lectures.forEach(row => {
    const date = moment(row.start_on)
      .startOf(rangeType === 'week' ? 'isoWeek' : rangeType)
      .format('YYYY-MM-DD');
    const pastBookingCount = row.before_booking;
    const pastDeduction = getPastDeduction(row.histories);

    // 누적 사용 횟수 = 지난 예약 내역 + 지난 차감 횟수 + 1(첫 출석 생겼을때, pastBookingCount, pastDeduction 값이 0 인데, 첫 출석을 했을때 누적 사용 횟수는 1이어야 하므로 1을 더해줌 )
    const usedCountTotal = pastBookingCount + pastDeduction + 1;
    // const pricePerCoupon = row.ticket_type !== 'P' && row.user_ticket_max_coupon !== 0 ? parseInt(row.amount / row.user_ticket_max_coupon) : 0;

    const { before_user_ticket_amount, before_remaining_coupon, user_ticket_max_coupon } = row;

    const isTransfered = !!before_user_ticket_amount;
    const paymentAmount = isTransfered ? before_user_ticket_amount : row.amount;
    const usableCoupon = isTransfered ? before_remaining_coupon : user_ticket_max_coupon;
    // 최초 발급횟수와 양도된 횟수 중 더 횟수가 많은 것으로 회당금액(pricePerCoupon)을 계산해야함
    const moreLargerTimesCoupon =
      user_ticket_max_coupon > before_remaining_coupon ? user_ticket_max_coupon : before_remaining_coupon;
    let userTicketMaxCoupon;
    let remainingCoupon;
    let pricePerCoupon = 0;

    if (row.ticket_type !== 'P') {
      userTicketMaxCoupon = usableCoupon;
      remainingCoupon = usableCoupon - usedCountTotal;
      pricePerCoupon = parseInt(paymentAmount / moreLargerTimesCoupon);
    } else {
      userTicketMaxCoupon = null;
      remainingCoupon = null;
    }

    // 누적 사용 금액 = 누적 사용 횟수 * 회당금액
    // 단, 누적 사용 금액은 결제 총액을 넘을 수 없음
    const totalAmount = isTransfered ? before_user_ticket_amount : row.amount;
    const deductedAmountTotal = Math.min(usedCountTotal * pricePerCoupon, totalAmount);
    // const isUsedCountTotalExceedsMaxCoupon = usedCountTotal > row.ticket_max_coupon;

    // 양도된 잔여횟수가 최초 발급횟수 보다 작을 때, 잔여횟수의 미수업금 계산시
    // {전체금액 - ((발급횟수 - 양도횟수) * 회당금액)} 가 토탈금액이 되고 여기서 누적금액을 빼야함
    const remainingAmountTotal =
      isTransfered && user_ticket_max_coupon > before_remaining_coupon
        ? totalAmount - (user_ticket_max_coupon - before_remaining_coupon) * pricePerCoupon
        : totalAmount;

    const mappedRow = {
      usageType: 'lecture',
      deductionType: translate.bookingStatus(row.status),
      lectureId: row.id,
      lectureStartOn: row.start_on,
      lectureEndOn: row.end_on,
      staffName: row.staff_name,
      staffColor: row.staff_color,
      courseType: row.course_type,
      ticketType: row.ticket_type,
      memberName: row.member_name,
      ticketTitle: row.title,
      totalAmount,
      ticketMaxCoupon: row.ticket_type !== 'P' ? row.ticket_max_coupon : null,
      userTicketMaxCoupon,
      usedCountTotal,
      usedCount: 1,
      remainingCoupon,
      pricePerCoupon,
      deductedAmountTotal,
      // 수강권의 기본 최대 횟수를 넘어가는 사용 횟수는
      // 보너스로 지급한 횟수로 간주하여 회당금액 계산 안함
      deductedAmount: pricePerCoupon ? pricePerCoupon : 0,
      payableAmount: Math.max(remainingAmountTotal - deductedAmountTotal, 0),
      deletedAt: row.deleted_at,
    };

    /**
     * 금액 합산
     * 기본 수강권의 전체 횟수를 초과한 경우,
     * 서비스 횟수로 간주하여 금액 합산 하지 않음
     */
    if (amount.lecturesRevenue[date]) {
      amount.lecturesRevenue[date].total += pricePerCoupon;
      amount.lecturesRevenue[date][row.course_type] += pricePerCoupon;
    }
    if (count.lecturesRevenue[date]) {
      count.lecturesRevenue[date].total++;
      count.lecturesRevenue[date][row.course_type]++;
      salesData.lecturesRevenue[date].push(mappedRow);
    }
  });

  /** 수업 매출 - 차감 */
  deducts.forEach(row => {
    const deductedOn = _.get(row, 'deducted_at');
    const date = moment(deductedOn)
      .startOf(rangeType === 'week' ? 'isoWeek' : rangeType)
      .format('YYYY-MM-DD');
    const pastBookingCount = row.before_booking;
    const pastDeduction = getPastDeduction(row.histories);
    // 사용한 횟수
    // 차감 구분이 '수강권 발급시(created)'일 때 = 최대 횟수 - 잔여 횟수
    // 나머지 경우 = 변경 전 잔여 횟수 - 변경 후 잔여 횟수
    const usedCount =
      row.kind === 'created'
        ? _.get(row, 'new.max_coupon', 0) - _.get(row, 'new.remaining_coupon', 0)
        : _.get(row, 'old.remaining_coupon', 0) - _.get(row, 'new.remaining_coupon', 0);
    // 누적 사용 횟수 = 지난 예약 내역 + 지난 차감 횟수 + 사용한 횟수
    const usedCountTotal = pastBookingCount + pastDeduction + usedCount;
    const isUsedCountTotalExceedsMaxCoupon = usedCountTotal > row.ticket_max_coupon;
    const pricePerCoupon =
      row.ticket_type !== 'P' && row.ticket_max_coupon !== 0 ? parseInt(row.amount / row.ticket_max_coupon) : 0;

    // 누적 사용 금액 = 누적 사용 횟수 * 회당금액
    // 단, 누적 사용 금액은 결제 총액을 넘을 수 없음
    const deductedAmountTotal = Math.min(usedCountTotal * pricePerCoupon, row.amount);
    const deductedAmount =
      Math.max(Math.min(usedCount, row.ticket_max_coupon - (usedCountTotal - usedCount)), 0) * pricePerCoupon;

    const deductionTypes = {
      deducted: '자동차감',
      count: '횟수변경',
      created: '발급시 차감',
    };

    const mappedRow = {
      usageType: 'deducted',
      deductionType: deductionTypes[row.kind] || '',
      lectureStartOn: deductedOn, // 정렬을 위해 추가
      deductedOn,
      staffName: '',
      staffColor: 'eee',
      courseType: row.course_type,
      ticketType: row.ticket_type,
      memberName: row.member_name,
      ticketTitle: row.title,
      totalAmount: row.amount,
      ticketMaxCoupon: row.ticket_type !== 'P' ? row.ticket_max_coupon : null,
      userTicketMaxCoupon: row.ticket_type !== 'P' ? row.user_ticket_max_coupon : null,
      usedCountTotal,
      usedCount,
      remainingCoupon: row.ticket_type !== 'P' ? Math.max(row.user_ticket_max_coupon - usedCountTotal, 0) : null,
      pricePerCoupon,
      deductedAmountTotal,
      deductedAmount,
      payableAmount: Math.max(row.amount - deductedAmountTotal, 0),
    };

    if (usedCount > 0 && dates.includes(date)) {
      if (!isUsedCountTotalExceedsMaxCoupon) {
        amount.lecturesRevenue[date].total += deductedAmount;
        amount.lecturesRevenue[date][row.type] += deductedAmount;
      }
      count.lecturesRevenue[date].total++;
      count.lecturesRevenue[date][row.type]++;
      salesData.lecturesRevenue[date].push(mappedRow);
    }
  });

  /** 정렬 */
  dates.forEach(date => {
    salesData.ticketsRevenue[date] = _.sortBy(salesData.ticketsRevenue[date], 'payedOn');
    salesData.lecturesRevenue[date] = _.sortBy(salesData.lecturesRevenue[date], 'lectureStartOn');
  });
  return {
    amount,
    count,
    salesData,
  };
};

/** Private Helpers */

// 직전까지의 차감수 합산
// (수강권 발급시: created, 임의변경: count, 자동차감: deducted)
const getPastDeduction = histories => {
  histories = Array.isArray(histories) ? histories : Object.values(histories);

  return histories.reduce((sum, history) => {
    let deduction = 0;
    if (history.kind === 'created') {
      deduction = _.get(history, 'new.max_coupon', 0) - _.get(history, 'new.remaining_coupon', 0);
    } else if (['count', 'deducted'].includes(history.kind)) {
      deduction = _.get(history, 'old.remaining_coupon', 0) - _.get(history, 'new.remaining_coupon', 0);
    }
    return (sum += deduction);
  }, 0);
};
