<template>
  <ul class="paymentSummary">
    <li>
      <div class="wrap-title">
        <label>결제 카드</label>
        <button class="btn-text" @click="moveToInstallmentInfo">무이자 할부 정보 |</button>
        <button class="btn-text-blue" @click="() => (isEditing = !isEditing)">
          {{ editButtonText }}
        </button>
      </div>
      <p>정기 결제 / 포인트 자동 충전 등록된 카드는 삭제할 수 없습니다</p>

      <div class="paymentSummary__payment-list">
        <PaymentList
          :isEditing="isEditing"
          :itemList="paymentCarouselList"
          :handleClickToPrev="handleClickToPrev"
          :handleClickToNext="handleClickToNext"
          :hasPrev="hasPrev"
          :hasNext="hasNext"
          :selectedCardId="selectedCardId"
          :selectedProductId="selectedProduct.id"
          :isInstallmentCondition="isInstallmentCondition"
          @handleClickCardItem="handleClickCardItem"
          @setRequestPaymentData="setRequestPaymentData"
        />
      </div>
    </li>
    <li class="paymentSummary__toggle-box">
      <label>포인트 자동 충전 사용</label>
      <p class="paymentSummary__toggle-box__sub">잔여 포인트가 1만 포인트보다 적으면 자동으로 충전됩니다.</p>
      <span>
        <button class="btn-text" v-if="usablePointAutoCharge" @click="$emit('showChangePointProductModal')">금액 변경</button>
        <el-switch
          class="paymentSummary__toggle-box__switch"
          v-model="usablePointAutoCharge"
          @change="setUsablePointAutoCharge"
        />
      </span>
    </li>
    <li class="flex-row">
      <span>
        <label>결제 알림</label>
        <p class="paymentSummary__input-box__sub">카카오톡 혹은 문자로 결제 결과가 발송됩니다.</p>
      </span>
      <span>
        <PhoneNumberInput
          v-model="chargeNotificationNumber"
          placeholder="휴대폰 번호를 입력해 주세요"
          :class="phoneNumberInputClass"
        />
        <p class="warning" v-if="phoneNumberErrorMessage">{{ phoneNumberErrorMessage }}</p>
        <button @click="handleClickChangeChargeNotificationNumber" :class="phoneNumberButtonClass">저장하기</button>
      </span>
    </li>
    <li class="flex-col">
      <label>결제 금액 확인</label>

      <p class="sms-count">
        <span>충전 후 잔여 포인트</span>
        <span class="sms-count__current">{{ smsCount | comma }}P</span>
        <span class="sms-count__plus" v-if="selectedProduct">+ {{ selectedProductCount | comma }}P</span>
        <span class="sms-count__total" v-if="selectedProduct">= {{ smsCountAfterPayment | comma }}P</span>
      </p>
      <p class="payment-amount">
        <span>결제할 금액</span>
        <span class="payment-amount__amount" v-if="selectedProduct">{{ selectedProductPaymentAmount | comma }}원</span>
        <span v-if="selectedProduct">(VAT 포함)</span>
      </p>
      <button @click="$emit('handleClickPay')" :disabled="!selectedProduct">
        {{ selectedProductPaymentAmount | comma }}원 결제하기
      </button>
    </li>
    <RegistCard :show="registCardModalShow" :close="closeRegistCardModal" />
  </ul>
</template>

<script>
import RegistCard from '@components/Modals/Payments/RegistCard';
export default {
  components: {
    RegistCard,
  },
  props: {
    smsCount: Number,
    selectedProductCount: Number,
    smsCountAfterPayment: Number,
    selectedProductPaymentAmount: Number,
    selectedProduct: Object,
    handleClickPay: Function,
    showChangePointProductModal: Function,
    showChangeAutoChargeModal: Function,
    setInitialized: Boolean,
    handleInitialize: Function,
    handleClickCardItem: Function,
    selectedCardId: Number,
    setRequestPaymentData: Function,
  },
  data() {
    return {
      isEditing: false,
      carouselIndex: 0,
      carouselItemNumber: 3,
      chargeNotificationNumber: null,
      usablePointAutoCharge: false,
      registCardModalShow: false,
    };
  },
  computed: {
    paymentList() {
      return this.setChosen(this.$store.getters['payment/cards']);
    },

    paymentCarouselList() {
      const addCard = {
        class: 'addCard',
        card_name: this.paymentList.length > 0 ? '카드 추가' : `등록된 카드가 없습니다.\n카드 등록을 진행해 주세요`,
        event: this.showRegistCardModal,
      };

      if (this.isEditing) {
        return [addCard, ...this.paymentList].slice(
          this.carouselIndex * this.carouselItemNumber,
          this.carouselIndex * this.carouselItemNumber + this.carouselItemNumber,
        );
      }
      return [...this.paymentList, addCard].slice(
        this.carouselIndex * this.carouselItemNumber,
        this.carouselIndex * this.carouselItemNumber + this.carouselItemNumber,
      );
    },

    hasPrev() {
      return this.carouselIndex - 1 >= 0;
    },

    hasNext() {
      return (this.carouselIndex + 1) * this.carouselItemNumber < this.paymentList.length + 1;
    },

    paymentPolicy() {
      return this.$store.getters['payment/paymentPolicy'];
    },

    editButtonText() {
      return this.isEditing ? '편집 완료' : '카드 편집';
    },

    pointCardId() {
      return this.paymentPolicy.point_card_id;
    },

    phoneNumberInputClass() {
      const isSame = this.paymentPolicy.point_charging_payment_notification_number == this.chargeNotificationNumber;
      if (!this.chargeNotificationNumber || isSame) return 'default';
      return this.phoneNumberErrorMessage ? 'warning' : 'valid';
    },

    phoneNumberErrorMessage() {
      if (!this.chargeNotificationNumber) return '';
      const phoneNumberValidation = /^01([0|1|6|7|9])\d{7,8}$/;
      if (!phoneNumberValidation.test(this.chargeNotificationNumber)) {
        return '핸드폰 번호만 입력이 가능합니다';
      }
      return '';
    },

    phoneNumberButtonClass() {
      if (!this.chargeNotificationNumber) return 'btn-disabled';
      const isSame = this.paymentPolicy.point_charging_payment_notification_number == this.chargeNotificationNumber;
      return `btn-${!this.phoneNumberErrorMessage && !isSame ? 'valid' : 'disabled'}`;
    },

    isBothAutoCharging() {
      return (
        this.paymentPolicy.usable_subscription_auto_charge &&
        this.paymentPolicy.usable_point_auto_charge &&
        this.paymentPolicy.subscription_card_id === this.pointCardId
      );
    },

    isInstallmentCondition() {
      // 실제 결제 금액 5만원 이상 + 단건 결제(정기결제를 하고 있지 않을 때)
      // 일시불 디폴트
      return this.selectedProductPaymentAmount >= 50000;
    },

    installmentInfoLink() {
      return 'https://studiomate.notion.site/studiomate/d97c4ec5aef34a1c9b04e150890c06cb';
    },
  },

  watch: {
    paymentList() {
      if (this.carouselIndex - 1 >= 0 && !this.paymentCarouselList.length) {
        this.handleClickToPrev();
      }
    },
    setInitialized() {
      if (this.setInitialized) {
        this.initialize();
        this.$emit('handleInitialize', false);
      }
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    handleClickToPrev() {
      if (!this.hasPrev) {
        this.carouselIndex = 0;
        return;
      }
      return this.carouselIndex--;
    },

    handleClickToNext() {
      if (!this.hasNext) {
        return;
      }
      return this.carouselIndex++;
    },

    setChosen(data) {
      if (!data) return [];
      return data.reduce((acc, current) => {
        let chosen = { class: '', label: '' };
        if (
          !this.isBothAutoCharging &&
          this.paymentPolicy.usable_subscription_auto_charge &&
          current.id === this.paymentPolicy.subscription_card_id
        ) {
          chosen.label = '서비스 정기 결제 카드';
        }

        if (!this.isBothAutoCharging && this.paymentPolicy.usable_point_auto_charge && current.id === this.pointCardId) {
          chosen.label = '포인트 정기 결제 카드';
        }

        if (this.isBothAutoCharging && this.pointCardId === current.id) {
          // 서비스 결제 및 포인트 충전 모두 자동결제하는 경우
          chosen.label = '서비스 및 포인트 정기 결제 카드';
        }
        if (chosen.label) {
          return (acc = [...acc, { ...current, ...chosen }]);
        }
        return (acc = [...acc, { ...current, class: '' }]);
      }, []);
    },

    async setUsablePointAutoCharge(boolean) {
      if (!boolean) {
        this.$emit('showChangeAutoChargeModal');
        return;
      }
      if (boolean) {
        this.$emit('showChangePointProductModal');
      }
    },

    async dispatchUpdatePayment(data) {
      await this.$store.dispatch('payment/updatePaymentPolicy', data);
    },

    async handleClickChangeChargeNotificationNumber() {
      if (!this.chargeNotificationNumber) return;
      await this.dispatchUpdatePayment({ point_charging_payment_notification_number: this.chargeNotificationNumber });
      await this.$utils.notify.success(this, '확인', '결제 알림 휴대폰 번호가 변경되었습니다.');
    },

    initialize() {
      this.usablePointAutoCharge = this.paymentPolicy.usable_point_auto_charge;
      this.chargeNotificationNumber = this.paymentPolicy.point_charging_payment_notification_number;
    },

    showRegistCardModal() {
      this.registCardModalShow = true;
    },

    closeRegistCardModal() {
      this.registCardModalShow = false;
    },

    moveToInstallmentInfo() {
      const [width, height] = [window.innerWidth / 2, (window.innerHeight / 3) * 2];
      const floor = num => Math.floor(num);
      window.open(
        this.installmentInfoLink,
        '_blank',
        `top=${floor(window.innerHeight / 2 - height / 2)}, left=${floor(
          window.innerWidth / 2 - width / 2,
        )}, width=${width}, height=${height}, menubar=no, toolbar=no`,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.paymentSummary {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #343a40;

  .flex {
    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-col {
      display: flex;
      flex-direction: column;
    }
  }

  &__payment-list {
    padding: 31px 0px 0px 0px;
  }

  li {
    position: relative;
    width: 100%;
    min-height: 78px;
    padding: 15px 25px 15px 63px;

    label {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #343a40;
    }

    &:nth-child(2) {
      padding: 15px 25px 15px 88px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 25px;
        width: 48px;
        height: 48px;
        background: url('../../../assets/images/icons/auto-payment-blue.svg') 0 0 no-repeat;
        background-size: cover;
      }

      .btn-text {
        position: absolute;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1da0ff;
        top: calc(100% - 49px);
        right: 77px;
      }
    }
    &:nth-child(3) {
      padding: 30px 25px 30px 63px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 30px;
        left: 25px;
        width: 48px;
        height: 48px;
        background: url('../../../assets/images/icons/cell-phone.svg') 0 0 no-repeat;
        background-size: cover;
      }

      .el-input {
        width: 253px !important;

        &::placeholder {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #bababa;
        }
      }

      .btn {
        &-disabled {
          width: 114px;
          height: 36px;
          top: 15px;
          border-radius: 2px;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          background: #b5b5b5;
          color: $white;
          pointer-events: none;
        }
        &-valid {
          width: 114px;
          height: 36px;
          top: 15px;
          border-radius: 2px;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #1da0ff;
          border: solid 1px $dodger-blue;
          background: $white;
        }
      }

      span {
        padding: 0px 0px 0px 15px;
        &:nth-child(2) {
          display: flex;
          line-height: 49px;
          .el-input {
            padding: 0px 57px 0px 0px;
          }
        }
      }

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }
    &:nth-child(1) {
      padding: 15px 59px 0px 59px;
      label {
        padding: 0px 0px 0px 21px;
      }

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 25px;
        width: 48px;
        height: 48px;
        background: url('../../../assets/images/icons/credit-card.svg') 0 0 no-repeat;
        background-size: cover;
      }

      button {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #808080;
      }

      .btn-text {
        margin: 0 0 0 auto;

        &-blue {
          font-weight: 500;
          color: #1da0ff;
          padding: 0px 0px 0px 0px;
        }
      }
    }
    &:nth-child(4) {
      padding: 15px 25px 15px 78px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 25px;
        width: 48px;
        height: 48px;
        background: url('../../../assets/images/icons/receipt.svg') 0 0 no-repeat;
        background-size: cover;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $charcoal-grey;
      }

      label {
        padding: 0px 0px 10px 0px;
      }

      span {
        padding: 0px 0px 5px 0px;
      }

      p {
        padding-bottom: 5px;
        > span {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #808080;
        }
        .sms-count {
          &__current,
          &__plus {
            color: $charcoal-grey;
          }
        }
      }

      p:first-of-type > span {
        padding: 0px 15px 0px 0px;
      }

      p:nth-of-type(2) > span:first-of-type {
        padding: 0px 67px 0px 0px;
      }

      .sms-count__total,
      .payment-amount__amount {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $dodger-blue;
      }

      button {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 180px;
        height: 40px;
        object-fit: contain;
        border-radius: 2px;
        border: solid 1px $dodger-blue;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1da0ff;
      }
    }

    /deep/.default .el-input__inner:hover,
    /deep/.default .el-input__inner:focus {
      border-bottom: 2px solid #eeeeee;
    }

    /deep/.valid .el-input__inner,
    /deep/.valid .el-input__inner:hover {
      border-bottom: 2px solid $dodger-blue !important;
    }

    /deep/ .warning .el-input__inner,
    /deep/.warning .el-input__inner:focus {
      border: none;
      border-bottom: 2px solid #f45354 !important;
    }
  }

  &__toggle-box {
    padding: 15px 25px;
    background-color: #f5fbff;
  }

  &__input-box,
  &__toggle-box {
    position: relative;
    &__sub {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #a3a3a3;
      padding: 5px 0px 0px 0px;
    }

    &__switch {
      display: block;
      position: absolute;
      top: calc(100% - 49px);
      right: 25px;
    }
  }

  p.warning {
    font-size: 12px;
    color: $coral;
    position: absolute;
    top: 57px;
  }

  .wrap-title {
    position: relative;
    display: flex;
    justify-content: space-between;

    label {
      font-size: 16px;
      margin-bottom: 10px;
    }

    & + p {
      color: #a3a3a3;
      padding: 0px 0px 0px 20px;
    }
  }

  /deep/.carousel-btn-prev {
    top: 136.5px;
    left: -1px;
  }

  /deep/.carousel-btn-next {
    top: 136.5px;
    right: -20px;
  }
}
</style>
