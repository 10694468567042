<template>
  <div class="container">
    <div class="left">
      <CustomTabs class="boards__tab" :active_tab="activeTab" :tabs="tabs" :handleChangeTab="handleChangeTab" />
      <img src="@/assets/images/icons/sales_refresh.svg" class="refresh-button" @click="handleRefreshButtonClick" />
    </div>
    <div class="chart-or-table" v-if="date.rangeType !== 'daterange'">
      <div :class="{ background: true, right: chartOrTable === 'table' }"></div>
      <div :class="{ item: true, active: chartOrTable === 'chart' }" @click="handleChangeChartOrTable('chart')">차트</div>
      <div :class="{ item: true, active: chartOrTable === 'table' }" @click="handleChangeChartOrTable('table')">리스트</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          label: '수강권 매출',
          value: 'ticket',
        },
        {
          label: '수업 매출',
          value: 'lecture',
        },
        {
          label: '기타 매출',
          value: 'etc',
        },
      ],
    };
  },

  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },
    activeTab() {
      return this.$store.getters['salesNew/activeSalesTab'];
    },
    chartOrTable() {
      return this.$store.getters['salesNew/chartOrTable'];
    },
    refreshAll() {
      return this.$store.getters['salesNew/refreshAll'];
    },
  },

  methods: {
    handleChangeTab: function(tab) {
      this.$store.dispatch('salesNew/setActiveSalesTab', tab);
    },
    handleChangeChartOrTable: function(type) {
      this.$store.dispatch('salesNew/setChartOrTable', type);
    },
    handleRefreshButtonClick: function() {
      if (this.refreshAll) return;
      this.$store.dispatch('salesNew/setRefreshAll', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include flex(row, center, space-between);
  max-width: 1440px;
  padding: 0 30px;
  margin: 30px auto;

  /deep/ li {
    font-size: 18px;
  }

  .left {
    @include flex(row, center);

    /deep/ .boards__tab {
      li + li {
        margin-left: 25px;
      }
    }

    .refresh-button {
      cursor: pointer;
      margin-left: 20px;
    }
  }

  .chart-or-table {
    @include flex(row, center);
    width: 135px;
    height: 40px;
    background-color: #f8f8f8;
    padding: 5px;
    border-radius: 8px;
    position: relative;

    .background {
      background-color: white;
      position: absolute;
      width: calc(50% - 5px);
      height: calc(100% - 10px);
      left: 5px;
      z-index: 1;
      border-radius: 8px;
      transition: left 0.3s;

      &.right {
        left: 50%;
      }
    }

    .item {
      @include flex(row, center, center);
      flex: 1;
      height: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.3s;
      z-index: 2;
      position: relative;

      &.active {
        color: $dodger-blue;
      }
    }
  }
}
</style>
