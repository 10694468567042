<template>
  <el-dialog
    v-loading="loading"
    id="extend-ticket"
    :title="`${title} 일괄 연장`"
    :visible="show"
    :close-on-click-modal="false"
    @close="handleClose(false)"
  >
    <p class="extend-ticket__message">
      기준일에 사용중인 수강권(정지된 수강권 제외) 및 상품만 연장됩니다.
    </p>
    <div class="extend-ticket__form">
      <div class="extend-ticket__form__element">
        <label>기준일</label>
        <el-date-picker
          ref="dateInput"
          v-model="data.date"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          :picker-options="adminMode ? null : pickerOptions"
          :clearable="false"
        />
      </div>
      <div class="extend-ticket__form__element">
        <label>연장일수</label>
        <div class="extend-ticket__form__element__number-input">
          <el-checkbox v-model="extendPeriod" :disabled="extendPeriodDisabled" />
          <NumberInputCircle
            ref="extendPeriodInput"
            v-model="data.expire_count"
            :disabled="!extendPeriod || extendPeriodDisabled"
            :min="adminMode ? -999 : 1"
            :max="999"
            suffix="일"
          />
        </div>

        <p v-if="extendPeriodDisabled" class="extend-ticket__form__element__error">
          횟수만 설정한 상품은 기간을 연장할 수 없습니다.
        </p>
      </div>

      <div class="extend-ticket__form__element">
        <label>연장횟수</label>
        <div class="extend-ticket__form__element__number-input">
          <el-checkbox v-model="extendCount" :disabled="extendCountDisabled" />
          <NumberInputCircle
            ref="extendCountInput"
            v-model="data.booking_count"
            :disabled="!extendCount || extendCountDisabled"
            :min="adminMode ? -999 : 1"
            :max="999"
            suffix="회"
          />
        </div>

        <p v-if="extendCountDisabled" class="extend-ticket__form__element__error">
          기간제 수강권(상품)은 횟수를 연장할 수 없습니다.
        </p>
      </div>
      <div class="extend-ticket__form__element reason">
        <label>연장사유</label>
        <TextInput ref="reasonInput" v-model="data.reason" placeholder="연장사유를 입력해주세요" required material />
      </div>

      <div class="extend-ticket__form__buttons">
        <el-button type="info" size="small" @click="handleClose(false)">취소</el-button>
        <el-button type="primary" size="small" @click="handleClickSubmit($route.path)">연장</el-button>
      </div>
    </div>

    <div class="extend-ticket__history">
      <label>만료일 연장 이력</label>
      <el-table
        :data="$route.path !== '/users' && $route.path !== '/products' ? history : extendMemoData.data"
        :max-height="300"
        fit
      >
        <el-table-column label="연장시각" prop="created_at" align="center" width="160" />
        <el-table-column label="이름" prop="staff.name" align="center" width="80" />
        <el-table-column label="내용" header-align="center">
          <template slot-scope="scope">
            <div v-for="(n, idx) in 2" :key="idx">
              {{ scope.row.memo.split('\\n')[idx] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="10" />
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: { type: String, default: '수강권' },
    ticket: { type: Object, default: undefined },
    ticketIds: Array,
    memberSelected: Array,
    memberIdsSelected: Array,
    selectedOnlyPeriodTickets: { type: Boolean, default: false },
    selectedOnlyRentalTimeTicket: { type: Boolean, default: false },
    filterValues: Object,
    sortType: Object,
    featureValues: String,
    allMembers: Array,
    getMemberAll: Function,
    extendMemoData: Object,
    getMemos: Function,
    selectedTickets: Array,
    dispatchGetMembersList: Function,
    pagination: Object,
  },

  data() {
    return {
      data: {
        date: this.moment().format('YYYY-MM-DD'),
        booking_count: 1,
        expire_count: 1,
        reason: '',
        withHolding: false,
      },
      extendPeriod: true,
      extendCount: false,
      loading: false,
      adminMode: this.$route.query.studiomate ? true : false,
    };
  },

  computed: {
    history() {
      return _.get(this.ticket, 'memos', []);
    },

    pickerOptions() {
      const { moment } = this;
      return {
        disabledDate(date) {
          return moment(date).isBefore(moment().format('YYYY-MM-DD'));
        },
      };
    },

    isRentalOnlyTime() {
      return this.ticket?.type === 'RT';
    },

    isRentalOnlyPeriod() {
      return this.ticket?.type === 'RP';
    },

    extendPeriodDisabled() {
      if (this.selectedOnlyRentalTimeTicket) {
        return true;
      } else if (this.$route.name === 'product_detail' && this.isRentalOnlyTime) {
        return true;
      }
      return false;
    },

    extendCountDisabled() {
      if (_.get(this.ticket, 'type') === 'P') {
        return true;
      } else if (this.selectedOnlyPeriodTickets) {
        return true;
      } else if (this.$route.name === 'product_detail' && this.isRentalOnlyPeriod) {
        return true;
      }
      return false;
    },

    confirmMessageTitle() {
      if (this.$route.path === '/products/detail') return this.title;

      const isOnlyProduct = this.selectedTickets?.every(({ available_class_type }) => available_class_type === 'I');
      const isOnlyClassTicket = this.selectedTickets?.every(({ available_class_type }) => available_class_type !== 'I');
      if (isOnlyProduct) return '상품';
      else if (isOnlyClassTicket) return '수강권';
      else return '수강권 및 상품';
    },
  },

  watch: {
    show() {
      if (this.extendPeriodDisabled) {
        this.extendPeriod = false;
        this.extendCount = true;
      } else {
        this.extendPeriod = true;
        this.extendCount = false;
      }
    },

    ticket() {
      if (this.extendPeriodDisabled) {
        this.extendPeriod = false;
        this.extendCount = true;
      }
    },

    extendPeriod() {
      if (this.extendPeriod) {
        this.$nextTick(() => {
          this.$refs.extendPeriodInput.$el.querySelector('input').focus();
        });
      }
    },

    extendCount() {
      if (this.extendCount) {
        this.$nextTick(() => {
          this.$refs.extendCountInput.$el.querySelector('input').focus();
        });
      }
    },
  },

  methods: {
    /** 모달 닫을 때 데이터 초기화 */
    handleClose(refresh) {
      (this.data = {
        date: this.moment().format('YYYY-MM-DD'),
        booking_count: 1,
        expire_count: 1,
        reason: '',
        withHolding: false,
      }),
        (this.extendPeriod = true);
      this.extendCount = false;
      this.loading = false;
      this.$emit('close', 'extend', refresh);
    },

    /** 연장 클릭시 */
    async handleClickSubmit(path) {
      if (!this.validate()) return;

      try {
        this.loading = true;

        const memberSelected =
          path === '/users' && this.featureValues === 'filtered'
            ? await this.getMemberAll('profile;account;userGrade;avatars;tickets.ticket', false)
            : this.memberSelected;

        const user_ticket = [];
        const userTickets = [];
        const ticketIds = [];
        const memberIdsSelected = [];
        const ticket = path === '/users' ? ticketIds : !this.ticket ? this.ticketIds : [this.ticket.id];
        if (path === '/users') memberIdsSelected.push(...memberSelected.map(member => member.id));
        let data = { ...this.data, ticket, user_ticket };
        data.expire_count = data.expire_count === -1 ? '-1' : data.expire_count;
        data.booking_count = data.booking_count === -1 ? '-1' : data.booking_count;

        if (!this.extendPeriod) delete data.expire_count;
        if (!this.extendCount) delete data.booking_count;
        if (!(await this.confirm(data, memberIdsSelected))) return;

        /** 회원탭 일괄 연장에 대한 내용 */
        if (path === '/users') {
          const filterData = [];
          memberSelected?.map(member => filterData.push(...member.userTickets));

          if (this.featureValues === 'filtered') {
            const status = this.filterValues.type === 'inactive' ? 'expired' : 'using';
            userTickets.push(filterData.filter(ticket => ticket.ticket_usable_status === status));
          } else userTickets.push(filterData);

          const holdingFilter = userTickets.filter(ticket => !ticket.is_holding);

          holdingFilter.flat().map(ticket => {
            user_ticket.push(ticket.id);
            ticketIds.push(ticket.ticket_id);
          });
        } else if (path === '/products') {
          this.selectedTickets.map(ticket => user_ticket.push(...ticket.userTicketIds));
          ticketIds.push(...this.ticketIds);
        }

        /** 회원 중 한명이라도 수강권이 없으면 에러 메시지 출력 */
        const checkTickets = memberSelected?.every(member => member.userTickets.length);
        if (this.$route.path === '/users' && !checkTickets)
          return this.$utils.notify.error(this, '오류', '발급된 수강권이 없는 회원이 포함되어 있습니다.');

        const res = await (path === '/users' || path === '/products'
          ? this.$api.product.userBatchExtend(data)
          : this.$api.product.extend(data));

        const message = this.getResultMessage(res.data);
        if (message) this.$utils.notify.success(this, '확인', message);

        if (this.$route.path === '/users' || this.$route.path === '/products')
          this.getMemos(window.location.hostname.split('.')[0]);

        this.$route.path === '/users' && this.dispatchGetMembersList(this.pagination.page);

        this.handleClose(true);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    validate() {
      if (!this.data.date) {
        this.$refs.dateInput.$el.querySelector('input').focus();
        this.$utils.notify.error(this, '오류', '기준일을 입력해주세요.');
        return false;
      } else if (!this.extendPeriod && !this.extendCount) {
        this.$utils.notify.error(this, '오류', '연장일수 또는 연장횟수를 입력해주세요.');
        return false;
      } else if (this.extendPeriod && !this.data.expire_count) {
        this.$refs.extendPeriodInput.$el.querySelector('input').focus();
        this.$utils.notify.error(this, '오류', '연장일수를 입력해주세요.');
        return false;
      } else if (this.extendCount && !this.data.booking_count) {
        this.$refs.extendCountInput.$el.querySelector('input').focus();
        this.$utils.notify.error(this, '오류', '연장횟수를 입력해주세요.');
        return false;
      } else if (!this.data.reason) {
        this.$refs.reasonInput.$el.querySelector('input').focus();
        this.$utils.notify.error(this, '오류', '연장사유를 입력해주세요.');
        return false;
      }
      return true;
    },

    /** 진행여부 확인 */
    async confirm(data, filterMemberIds) {
      const memberIdsSelected = this.featureValues === 'filtered' ? filterMemberIds : this.memberIdsSelected;
      const contents = this.getMessageContents(data);
      const title = `${this.title} 일괄 연장`;
      const message =
        this.$route.path === '/users'
          ? `${memberIdsSelected.length}명 회원 수강권 또는 상품의 ${contents.join(', ')} 일괄 연장됩니다.<br>계속 하시겠습니까?`
          : `${this.confirmMessageTitle}의 ${contents.join(', ')} 연장됩니다.<br>계속 하시겠습니까?`;

      return await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);
    },

    /** 수강권 연장 결과 메시지 생성 */
    getResultMessage(data) {
      const { itemCount, ticketCount } = data;
      const contents = this.getMessageContents(data);

      if (!itemCount && !ticketCount) return null;

      if (!itemCount) {
        return `${ticketCount}개의 수강권의 ${contents.join(', ')} 연장되었습니다.`;
      } else if (!ticketCount) {
        return `${itemCount}개의 상품의 ${contents.join(', ')} 연장되었습니다.`;
      } else {
        return `${ticketCount}개의 수강권 및 ${itemCount}개의 상품 ${contents.join(', ')} 연장되었습니다.`;
      }
    },

    /** 변경되는 항목에 따른 변경내용 텍스트 생성 */
    getMessageContents({ expire_count, booking_count }) {
      const { comma } = this.$filters;
      const periodExtended = comma(expire_count);
      const countExtended = comma(booking_count);

      let contents = [];
      if (expire_count) contents.push(`기간이 ${periodExtended}일`);
      if (booking_count) contents.push(`횟수가 ${countExtended}회`);

      return contents;
    },
  },
};
</script>

<style lang="scss" scoped>
#extend-ticket {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 620px;
  }

  /deep/ .el-dialog__header {
    padding: 24px;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }
}

.extend-ticket {
  &__message {
    color: $color-danger;
    font-size: 12px;
    margin: 0 24px 24px;
  }

  &__form {
    @include flex(column);

    &__element {
      @include flex(column);
      margin: 0 24px 24px;
      max-width: 250px;

      /deep/ .el-date-editor {
        width: 100%;
      }

      &__number-input {
        @include flex(row, center, center);
        * + * {
          margin-left: 8px;
        }
      }

      &.reason {
        max-width: 100%;
      }

      &__error {
        color: $color-danger;
        font-size: 12px;
        margin-top: 4px;
      }
    }

    &__buttons {
      @include flex(row, center, flex-end);
      border-top: 1px solid #eee;
      padding: 24px;
    }
  }

  &__history {
    padding: 20px 24px;
    border-top: 1px solid #eee;
  }
}
</style>
