<template>
  <MainLayout :padded="false">
    <div class="product-detail" v-loading="loading">
      <Header />

      <section class="product-detail__body">
        <div class="product-detail__body__inner">
          <CustomTabs
            class="product-detail__body__tabs"
            :active_tab="currentTab"
            :tabs="tabs"
            :handleChangeTab="handleChangeTab"
          />
          <UserTicketList v-if="currentTab === 'user_tickets'" v-loading="userTicketsLoading" :title="title" />
          <Classes v-if="currentTab === 'classes' && courseType === 'G'" v-loading="classesLoading" />
        </div>
      </section>
    </div>
  </MainLayout>
</template>

<script>
import Header from '@/components/ProductDetail/Header';
import UserTicketList from '@/components/ProductDetail/UserTicketList';
import Classes from '@/components/ProductDetail/Classes';

export default {
  components: {
    Header,
    UserTicketList,
    Classes,
  },

  data() {
    return {
      currentTab: 'user_tickets',
    };
  },

  computed: {
    ticket() {
      return this.$store.getters['ticket/ticket'];
    },
    loading() {
      return this.$store.getters['ticket/loading'];
    },
    userTicketsLoading() {
      return this.$store.getters['ticket/userTicketsLoading'];
    },
    userTicketsTotal() {
      return this.$store.getters['ticket/userTicketsTotal'];
    },
    userTicketsParams() {
      return this.$store.getters['ticket/userTicketsParams'];
    },
    classesLoading() {
      return this.$store.getters['ticket/classesLoading'];
    },

    courseType() {
      return _.get(this.ticket, 'available_class_type');
    },
    title() {
      return this.courseType === 'I' ? '상품' : '수강권';
    },

    tabs() {
      const userTicketTotal = this.$filters.comma(this.userTicketsTotal);

      let tabs = [{ value: 'user_tickets', label: `발급된 ${this.title} (${userTicketTotal})` }];
      if (this.courseType === 'G') {
        tabs.push({
          value: 'classes',
          label: '이 수강권으로 수강가능한 클래스',
        });
      }

      return tabs;
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!to.query.id) return next('/products');
    next();
  },

  created() {
    let currentTab = 'user_tickets';
    if (['user_tickets', 'classes'].includes(this.$route.query.tab)) {
      currentTab = this.$route.query.tab;
    }
    this.currentTab = currentTab;
    this.loadData();
  },

  methods: {
    async loadData() {
      const ticketId = await this.$store.dispatch('ticket/getTicket', this.$route.query.id);
      if (!ticketId) {
        this.$utils.notify.error(this, '오류', '수강권 정보를 가져오는데 실패했습니다.');
        return this.$router.push('/products');
      }
    },

    handleChangeTab(tab) {
      this.$router.push({ query: { ...this.$route.query, tab } });
      this.currentTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-detail {
  &__body {
    &__inner {
      max-width: 1077px;
      margin: auto;
      padding: 0 15px;
      @include flex(column, flex-start, flex-start);
    }

    &__tabs {
      margin-top: 10px;
    }
  }
}
</style>
