/** png 파일 jpeg로 변환 */
export default (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(event) {
      const image = new Image();

      image.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);

        canvas.toBlob((blob) => {
            const convertedFile = new File([blob], file.name.replace('.png', '.jpg'), { type: 'image/jpeg' });
            resolve(convertedFile);
          },
          'image/jpeg',
          1,
        );
      };

      image.src = event.target.result;
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};