import _ from 'lodash';
import api from '@api';

export const state = {
  loading: false,
  counsels: [],
  total: 0,
};

export const getters = {
  loading: state => state.loading,
  counsels: state => state.counsels,
  total: state => state.total,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_COUNSELS(state, counsels) {
    if (counsels) {
      state.counsels = counsels.data;
      state.total = counsels.meta.total;
    } else {
      state.counsels = [];
      state.total = 0;
    }
    state.loading = false;
  },
};

export const actions = {
  async getCounsels({ commit }, data) {
    try {
      commit('SET_LOADING', true);
      const params = {
        ..._.pick(data, ['page', 'limit']),
        ..._.omit(data.filter, 'dateRange'),
        start_date: data.filter.dateRange[0],
        end_date: data.filter.dateRange[1],
        has_member: true,
      };
      const res = await api.counsel.getAll(params);
      commit('SET_COUNSELS', res.data);
    } catch (error) {
      commit('SET_COUNSELS');
    }
  },
};
