<template>
  <el-dialog
    :visible.sync="show"
    @close="close"
    :show-close="false"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="header">
      <div class="left">
        <img src="@/assets/images/icons/icon_payment_add_Card.svg" alt="카드 추가 아이콘" />
        <span class="title">결제 카드 추가</span>
      </div>
      <div class="right">
        <img src="@/assets/images/icons/cancel.svg" alt="취소" class="cancel" @click="close" />
      </div>
    </div>
    <div class="body column">
      <div class="card column">
        <label>카드 번호</label>
        <div class="card__inputs">
          <div class="input-container" v-for="(num, index) in cardNumbers" :key="index">
            <input
              :type="index < 3 ? 'text' : 'password'"
              maxlength="4"
              v-model="cardNumbers[index]"
              @input="handleCardNumberInput($event, index)"
              ref="cardNumberRef"
              :class="{ 'error-underline': cardNumberInvalid }"
              placeholder="●●●●"
              :autocomplete="index === 3 ? 'new-password' : ''"
            />
          </div>
        </div>
        <span class="error-message" v-if="cardNumberInvalid">{{ cardNumberErrorMessage }}</span>
      </div>
      <div class="card-name column">
        <label>카드 별칭 (선택)</label>
        <input
          type="text"
          placeholder="10자 이내 한글, 영문, 숫자만 가능해요"
          maxlength="10"
          v-model="cardName"
          :class="{ 'error-underline': cardNameInvalid }"
        />
        <span class="error-message" v-if="cardNameInvalid">{{ cardNameErrorMessage }}</span>
      </div>
      <div class="container">
        <div class="card-expiration column">
          <label>카드 유효기간</label>
          <div class="card-expiration__selects">
            <select
              name="월"
              v-model="cardExpiration.month"
              :class="{ 'error-underline': cardExpirationInvalid, 'charcoal-grey': cardExpiration.month }"
            >
              <option value="">월 MM </option>
              <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
            </select>
            /
            <select
              name="년"
              v-model="cardExpiration.year"
              :class="{ 'error-underline': cardExpirationInvalid, 'charcoal-grey': cardExpiration.year }"
            >
              <option value="">년도 YY </option>
              <option v-for="year in years" :key="year" :value="year.slice(2, 4)">{{ year }}</option>
            </select>
            <span class="error-message" v-if="cardExpirationInvalid">{{ cardExpirationErrorMessage }}</span>
          </div>
        </div>
        <div class="card-password column">
          <label>카드 비밀번호 앞 2자리</label>
          <input
            type="password"
            placeholder="비밀번호 2자리"
            maxlength="2"
            v-model="cardPassword"
            :class="{ 'error-underline': cardPasswordInvalid }"
            autocomplete="new-password"
          />
          <span class="error-message" v-if="cardPasswordInvalid">{{ cardPasswordErrorMessage }}</span>
        </div>
      </div>
      <div class="id-number column">
        <label>주민등록번호 앞 6자리 또는 사업자 번호 10자리</label>
        <input
          type="text"
          placeholder="-를 뺀 숫자만 입력해 주세요!"
          maxlength="10"
          v-model="idNumber"
          :class="{ 'error-underline': idNumberInvalid }"
        />
        <span class="error-message" v-if="idNumberInvalid">{{ idNumberErrorMessage }}</span>
      </div>
      <div class="buttons">
        <button class="add" @click="registCard" :disabled="isLoading">추가</button>
        <button @click="close">취소</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';

const cardExpirationRequired = value => value.year && value.month;
const cardExpirationIsFuture = value => {
  const cur = { year: new Date().getFullYear(), month: new Date().getMonth() + 1 };
  const selectedYear = +`20${value.year}`;
  const selectedMonth = +value.month;
  if (cur.year === selectedYear && selectedMonth < cur.month) return false;
  return true;
};
const alphaNumKr = value => /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s]+$/g.test(value);
const idNumberLength = value => value.length === 6 || value.length === 10;

export default {
  props: {
    show: Boolean,
    close: Function,
  },

  data() {
    return {
      cardNumbers: ['', '', '', ''],
      cardNumberInvalid: false,
      cardNumberErrorMessage: '',

      cardName: '',
      cardNameInvalid: false,
      cardNameErrorMessage: '',

      cardExpiration: {
        month: '',
        year: '',
      },
      cardExpirationInvalid: false,
      cardExpirationErrorMessage: '',

      cardPassword: '',
      cardPasswordInvalid: false,
      cardPasswordErrorMessage: '',

      idNumber: '',
      idNumberInvalid: false,
      idNumberErrorMessage: '',

      isLoading: false,
    };
  },

  computed: {
    months() {
      return Array.from(new Array(12), (_, i) => i + 1).map(month => (month < 10 ? `0${month}` : `${month}`));
    },
    years() {
      const curYear = new Date().getFullYear();
      return Array.from(new Array(30), (_, i) => `${i + curYear}`);
    },
    cardNumber() {
      return this.cardNumbers.join('');
    },
  },

  watch: {
    cardNumbers: function() {
      let change = false;
      const fixedCardNumbers = this.cardNumbers.map(number => {
        const fixed = number.replace(/[^0-9]/g, '');
        if (number !== fixed) change = true;
        return fixed;
      });
      if (change) this.cardNumbers = fixedCardNumbers;
    },
    cardPassword: function() {
      this.cardPassword = this.cardPassword.replace(/[^0-9]/g, '');
    },
    idNumber: function() {
      this.idNumber = this.idNumber.replace(/[^0-9]/g, '');
    },

    show: function() {
      if (!this.show) {
        setTimeout(this.clearData, 200);
      } else {
        setTimeout(() => {
          this.$refs.cardNumberRef?.[0].focus();
        }, 100);
      }
    },
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    cardNumber: {
      required,
      minLength: minLength(15),
    },
    cardName: {
      alphaNumKr,
    },
    cardExpiration: {
      cardExpirationRequired,
      cardExpirationIsFuture,
    },
    cardPassword: {
      required,
      minLength: minLength(2),
    },
    idNumber: {
      required,
      idNumberLength,
    },
  },

  methods: {
    handleCardNumberInput(e, index) {
      if (index === 3) return;
      if (e.target.value.length === 4 && !isNaN(+e.data)) {
        this.$refs.cardNumberRef[index + 1].select();
      }
    },
    cardNumberValidCheck() {
      if (!this.$v.cardNumber.required || !this.$v.cardNumber.minLength) {
        this.cardNumberErrorMessage = '카드 번호 16자리를 입력해 주세요!';
        this.cardNumberInvalid = true;
        return;
      }
      this.cardNumberErrorMessage = '';
      this.cardNumberInvalid = false;
    },
    cardNameValidCheck() {
      if (!this.$v.cardName.alphaNumKr && this.$v.cardName.$model) {
        this.cardNameErrorMessage = '특수문자는 입력할 수 없어요!';
        this.cardNameInvalid = true;
        return;
      }
      this.cardNameErrorMessage = '';
      this.cardNameInvalid = false;
    },
    cardExpirationValidCheck() {
      if (!this.$v.cardExpiration.cardExpirationRequired) {
        this.cardExpirationErrorMessage = '카드 유효기간을 선택해 주세요!';
        this.cardExpirationInvalid = true;
        return;
      }
      if (!this.$v.cardExpiration.cardExpirationIsFuture) {
        this.cardExpirationErrorMessage = '카드 유효기간이 지났습니다.';
        this.cardExpirationInvalid = true;
        return;
      }
      this.cardExpirationErrorMessage = '';
      this.cardExpirationInvalid = false;
    },
    cardPasswordValidCheck() {
      if (!this.$v.cardPassword.required || !this.$v.cardPassword.minLength) {
        this.cardPasswordErrorMessage = '비밀번호 앞 2자리를 입력해 주세요!';
        this.cardPasswordInvalid = true;
        return;
      }
      this.cardPasswordErrorMessage = '';
      this.cardPasswordInvalid = false;
    },
    idNumberValidCheck() {
      if (!this.$v.idNumber.required || !this.$v.idNumber.idNumberLength) {
        this.idNumberErrorMessage = '주민등록번호 앞 6자리 또는 사업자 번호 10자리를 입력해 주세요!';
        this.idNumberInvalid = true;
        return;
      }
      this.idNumberErrorMessage = '';
      this.idNumberInvalid = false;
    },
    registCard: _.debounce(async function() {
      this.cardNumberValidCheck();
      this.cardNameValidCheck();
      this.cardExpirationValidCheck();
      this.cardPasswordValidCheck();
      this.idNumberValidCheck();

      if (
        this.cardNumberInvalid ||
        this.cardNameInvalid ||
        this.cardExpirationInvalid ||
        this.cardPasswordInvalid ||
        this.idNumberInvalid
      )
        return;
      this.isLoading = true;
      const data = {
        card_number: this.cardNumber,
        nick_name: this.cardName.trim(),
        year: this.cardExpiration.year,
        month: this.cardExpiration.month,
        password: this.cardPassword,
        id_number: this.idNumber,
      };
      try {
        await this.$api.payment.registCardApi(data);
        this.$utils.notify.success(this, '성공', '카드가 등록되었습니다.');
        this.$store.dispatch('payment/getPaymentList');
        this.isLoading = false;
        this.close();
      } catch (error) {
        switch (error.response.data.card_name) {
          case '카드번호 오류':
          case '미등록카드':
            this.cardNumberInvalid = true;
            this.cardNumberErrorMessage = '카드 번호가 일치하지 않아요!';
            break;
          case '비밀번호오류':
            this.cardPasswordInvalid = true;
            this.cardPasswordErrorMessage = '비밀번호가 일치하지 않아요!';
            break;
          case '유효기간확인요망':
            this.cardExpirationInvalid = true;
            this.cardExpirationErrorMessage = '유효기간이 일치하지 않아요!';
            break;
          case '생년월일불일치':
            this.idNumberInvalid = true;
            this.idNumberErrorMessage = '번호가 일치하지 않아요!';
            break;
        }
        this.isLoading = false;
        this.$utils.notify.error(this, '오류', `카드 등록에 실패했습니다.(${error.response.data.card_name})`);
      }
    }, 500),
    clearData() {
      this.cardNumbers = ['', '', '', ''];
      this.cardNumberInvalid = false;
      this.cardNumberErrorMessage = '';

      this.cardName = '';
      this.cardNameInvalid = false;
      this.cardNameErrorMessage = '';

      this.cardExpiration = {
        month: '',
        year: '',
      };
      this.cardExpirationInvalid = false;
      this.cardExpirationErrorMessage = '';

      this.cardPassword = '';
      this.cardPasswordInvalid = false;
      this.cardPasswordErrorMessage = '';

      this.idNumber = '';
      this.idNumberInvalid = false;
      this.idNumberErrorMessage = '';
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  display: none;
}

input::placeholder {
  color: #bababa;
  font-size: 14px;
}

select {
  color: #bababa;

  &.charcoal-grey {
    color: #343a40;
  }
}

.header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 135px;
  background-color: #e9ecef;
  padding: 30px 30px 15px 30px;
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    gap: 20px;

    .title {
      color: #343a40;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .right {
    .cancel {
      cursor: pointer;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  .error-message {
    color: #f45354;
    font-size: 11px;
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    font-weight: 500;
  }

  .error-underline {
    border-bottom: 1px solid #f45354;
  }
}

.body {
  margin-top: 135px;
  gap: 40px;

  label {
    font-size: 14px;
    font-weight: 500;
    color: #606266;
  }

  input {
    border-bottom: 1px solid #e9ecef;
    font-size: 16px;
    padding-bottom: 4px;
  }

  .container {
    display: flex;

    .card-expiration {
      flex: 1;

      &__selects {
        display: flex;
        gap: 10px;
      }
    }
  }

  .card {
    &__inputs {
      display: flex;

      .input-container + .input-container::before {
        content: '-';
        margin: 0 10px;
      }

      input {
        text-align: center;
        width: 64px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      width: 64px;
      height: 32px;
      border-radius: 2px;
      border: solid 1px #8a8a8a;
      color: rgba(138, 138, 138, 0.9);
      font-weight: 500;
    }

    .add {
      border: none;
      background-color: #4799f2;
      color: white;
    }
  }
}
</style>
