<template>
  <el-dialog width="472px" :visible.sync="show" :before-close="onClose">
    <img slot="title" src="@/assets/images/new-warning.svg" alt="warning" />

    <div class="header-title" slot="title" v-for="(v, idx) in title" :key="idx">
      {{ setTitle[idx] }}
    </div>

    <div>
      <h3 v-html="content" />
      <p class="detail-sub">이 창은 5초 후에 자동으로 사라져요.</p>
    </div>

    <RoundButton @click="onClose()">
      창 닫기
    </RoundButton>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    onClose: Function,
    title: String,
    content: String,
  },

  data() {
    return {
      loading: false,
    };
  },

  beforeUpdate() {
    this.handleDebounce();
  },

  computed: {
    setTitle() {
      return this.title.split('<br>');
    },
  },

  methods: {
    finCheckin() {
      if (!this.loading) this.$emit('checkinConfirm');
      this.loading = true;
    },

    handleDebounce: _.debounce(function() {
      this.onClose();
    }, 5000),
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  border-radius: 16px;

  &__header {
    padding: 34px 30px;
    height: 262px;
    border-radius: 16px 16px 0 0;
    background-color: #ff8b8b;
  }

  &__body {
    height: 262px;
    text-align: center;
  }

  .header-title {
    font-size: 35px;
    font-weight: bold;
    line-height: 1.3;
    color: #fff;
  }

  button {
    width: 330px;
    height: 70px;
    border-radius: 20px;
    font-weight: 600;

    @media (hover: hover) {
      &:hover {
        filter: brightness(1.1);
      }
    }
  }

  .detail-sub {
    margin: 4px 0 38px 0;
    font-size: 20px;
    color: #818181;
  }

  .el-icon-close {
    display: none;
  }
}
</style>
