<template>
  <section class="choice-member" v-loading="membersLoading">
    <div class="body">
      <div class="locker-name">
        <h5>락커 이름</h5>
        <el-input
          :disabled="lockerData.edit === 1"
          maxlength="75"
          ref="lockerName"
          placeholder="락커 이름을 입력해주세요"
          v-model="data.lockerName"
        >
        </el-input>
      </div>

      <div class="name">
        <h5>성함</h5>
        <div class="pop-name-wrapper">
          <label for="name" :class="{ vaccination: getVaccination }">{{ lockerData.userName }}</label>
          <el-input id="name" :disabled="true" placeholder="성함" v-model="lockerData.userName" />
        </div>
      </div>

      <div class="locker-period">
        <h5>락커 이용 기간</h5>

        <el-date-picker
          v-model="periodDate"
          ref="duringDate"
          type="daterange"
          range-separator="~"
          format="yyyy. M. d."
          start-placeholder="이용 시작일"
          end-placeholder="이용 종료일"
          value-format="yyyy-MM-dd HH:mm"
          :clearable="false"
          :disabled="lockerData.edit === 1"
        >
        </el-date-picker>

        <div v-if="dayLocker" class="datetime">
          <hours-input
            ref="duringTime"
            v-model="time"
            startLabel="시작 시간"
            :startHourOptions="{
              start: '00:00',
              end: '23:50',
              step: '00:05',
            }"
            endLabel="종료 시간"
            :endHourOptions="{
              start: time.start || '00:00',
              end: '23:55',
              step: '00:05',
            }"
            :clearable="false"
            :disabled="lockerData.edit === 1"
          />
        </div>
      </div>
      <div class="etc">
        <h5>비고</h5>
        <Textarea
          :class="[lockerData.edit === 1 ? 'disabled' : null]"
          :disabled="lockerData.edit === 1"
          placeholder="메시지를 입력해주세요."
          v-model="data.etc"
          :rows="2"
          :height="150"
        />
      </div>
    </div>

    <div class="footer" v-if="lockerData.edit !== 1">
      <div class="footer__left">
        <el-button v-if="lockerData.edit !== 1" type="basic" @click="goToPrevStep">
          회원선택
        </el-button>
      </div>
      <div class="footer__right">
        <el-button type="basic" @click="$emit('close', false)">
          취소
        </el-button>
        <el-button type="primary" @click="updateLockerData">
          확인
        </el-button>
      </div>
    </div>
  </section>
</template>

<script>
const DEFAULT_FILTER_VALUES = {
  is_account: null,
  searchFor: 'NAME|MOBILE',
};
export default {
  data() {
    return {
      filterValues: { ...DEFAULT_FILTER_VALUES },
      membersLoading: false,
      periodDate: [],
      data: {
        memberId: null,
        userName: null,
        lockerName: null,
        start_on: null,
        end_on: null,
        etc: null,
      },
      time: {
        start: this.moment()
          .startOf('hour')
          .format('HH:mm'),
        end: '23:55',
      },
    };
  },

  computed: {
    lockerData() {
      return this.$store.getters['locker/formData'];
    },

    dayLocker() {
      if (this.periodDate.length === 2) {
        return this.periodDate[0].slice(0, -6) === this.periodDate[1].slice(0, -6);
      }
      return false;
    },

    getVaccination() {
      const vaccine = this.lockerData.vaccination;
      return vaccine === 'Y';
    },
  },

  created() {
    const { lockerData } = this;

    this.data = {
      lockerName: lockerData.lockerName,
      lockerId: lockerData.lockerId,
      userName: lockerData.userName,
      memberId: lockerData.memberId,
      etc: lockerData.etc,
    };

    if (lockerData.start_on) {
      if (lockerData.start_on.slice(0, -9) === lockerData.end_on.slice(0, -9)) {
        this.time = {
          start: lockerData.start_on.slice(11, -3),
          end: lockerData.end_on.slice(11, -3) === '23:59' ? '24:00' : lockerData.end_on.slice(11, -3),
        };
      }
      this.periodDate = [lockerData.start_on.slice(0, -3), lockerData.end_on.slice(0, -3)];
    }
  },

  methods: {
    validate() {
      const { notify } = this.$utils;

      if (!this.periodDate.length) {
        notify.error(this, '오류', '이용 기간을 선택 해주세요');
        this.$refs.duringDate.focus();
        return false;
      } else if (this.periodDate[0].slice(0, -6) === this.periodDate[1].slice(0, -6)) {
        if (!this.time.start || !this.time.end) {
          notify.error(this, '오류', '이용 시간을 선택 해주세요');
          return false;
        }
      }
      return true;
    },

    goToPrevStep() {
      if (this.periodDate.length) this.calDateTime();
      this.$store.dispatch('locker/assignLocker', { ...this.data, currentStep: 2 });
    },

    calDateTime() {
      let duringTime;
      if (this.dayLocker) {
        this.data.type = 'DAILY';
        duringTime =
          this.time.end === '24:00'
            ? {
                start_on: `${this.periodDate[0].slice(0, -6)} ${this.time.start}:00`,
                end_on: `${this.periodDate[0].slice(0, -6)} 23:59:59`,
              }
            : {
                start_on: `${this.periodDate[0].slice(0, -6)} ${this.time.start}:00`,
                end_on: `${this.periodDate[0].slice(0, -6)} ${this.time.end}:00`,
              };
      } else {
        this.data.type = 'TERM';
        duringTime = { start_on: `${this.periodDate[0]}:00`, end_on: `${this.periodDate[1].slice(0, -6)} 23:59:59` };
      }
      this.data.start_on = duringTime.start_on;
      this.data.end_on = duringTime.end_on;
    },

    async updateLockerData() {
      if (!this.validate()) return;
      this.membersLoading = true;
      this.calDateTime();
      const res = await this.$store.dispatch('locker/saveLockerInfo', this.data);
      if (res.status === 200) {
        this.membersLoading = false;
        this.$emit('close', false);
        this.$utils.notify.success(this, '성공', '락커 정보가 변경되었습니다.');
      } else {
        this.membersLoading = false;
        this.$utils.notify.parseError(this, { message: '락커 정보 수정 실패' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.choice-member {
  padding: 0 20px;
  height: 600px;
}
.body {
  height: 90%;
  .locker-name,
  .name,
  .locker-period {
    margin-bottom: 0px;
  }
  .datetime {
    position: relative;
    left: -20px;
    margin-bottom: 20px;
  }
  .etc {
    h5 {
      margin-bottom: 10px;
    }
  }
}
::v-deep .el-table__row {
  height: 40px;
  cursor: pointer;
}
.search {
  width: 230px;
  float: right;
  margin-right: 30px;
}
.member-table {
  height: 500px;
  margin: 15px 0;
  padding: 0 30px;
  overflow-y: auto;
}
.footer-button-group {
  float: right;
}
.footer {
  display: flex;
  justify-content: space-between;
}
.disabled {
  cursor: not-allowed;
}

.pop-name-wrapper {
  position: relative;
  z-index: 1;

  label {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: -1;
    color: transparent;

    &.vaccination {
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        right: -22px;
        width: 18px;
        height: 18px;
        background: url('../../../../assets/images/icons/vaccine.svg') 0 0 no-repeat;
        background-size: cover;
      }
    }
  }
}
</style>
