import moment from 'moment';

/** 조회 시점 기준으로 가장 가까운 수업 반환 */
export default lectures => {
  if (!lectures.length) return null;

  const futureLectures = lectures.filter(({ start_on }) => {
    return moment(start_on).isAfter(moment().format('YYYY-MM-DD'));
  });

  let lectureId = '';
  if (futureLectures.length) {
    lectureId = futureLectures[0].id;
  } else {
    lectureId = lectures[lectures.length - 1].id;
  }
  return lectureId;
};
