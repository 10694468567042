<template>
  <MainLayout :padded="false">
    <content-header :breadcrumb="breadcrumb" :contentMaxWidth="1024">
      <div class="content-title">
        <h3>{{ title }}</h3>
        <template v-if="!isProduct">
          <el-select v-model="data.courseType" v-if="!product.id">
            <el-option v-for="option in courseTypeOptions" :key="option.value" :value="option.value" :label="option.label" />
          </el-select>
          <el-tag v-else type="info" style="font-size: 14px;">
            {{ $utils.translate.courseType(data.courseType) }}형 수업 전용
          </el-tag>
        </template>
      </div>
    </content-header>

    <ProductForm
      v-if="isProduct"
      :data="data"
      :product="product"
      :hasCustomColor="hasCustomColor"
      :colorChips="colorChips"
      :messageOnColorSelected="messageOnColorSelected"
      @handleDefalutColor="handleDefalutColor"
      @openColorSet="openColorSet"
      @handleColorSelect="handleColorSelect"
    />

    <TicketForm
      v-else
      :data="data"
      :product="product"
      :hasCustomColor="hasCustomColor"
      :colorChips="colorChips"
      :messageOnColorSelected="messageOnColorSelected"
      :divisionOptions="divisionOptions"
      @handleDefalutColor="handleDefalutColor"
      @openColorSet="openColorSet"
      @handleColorSelect="handleColorSelect"
      @confirmDecution="confirmDecution"
    />

    <bottom-action-bar :message="lastUpdatedAt">
      <el-button v-loading="isSaving" :disabled="isSaving" @click="saveProduct">
        {{ `${this.title} 완료` }}
      </el-button>
    </bottom-action-bar>
  </MainLayout>
</template>

<script>
import ProductForm from './ProductForm';
import TicketForm from './TicketForm';
export default {
  components: {
    ProductForm,
    TicketForm,
  },

  data() {
    return {
      isSaving: false,
      // showColorsAll: false,
      /** 수강권 데이터 - 공통 */
      data: {
        courseType: 'G', // G: 그룹, P: 프라이빗, I: 상품
        ticketType: 'T', // T: 횟수제, P: 기간제, S: 판매상품, RM: all 대여상품, RT: 횟수만 대여, RP: 기간만 대여
        productType: 'S', // S: 판매, R: 대여
        productTitle: '',
        maxCoupon: 10,
        maxCancel: 10,
        productPeriod: 30,
        productPeriodInput: 0,
        maxTrainee: 4,
        actualPrice: 0,
        rewardPoint: 0,
        weeklyBookingLimit: 0,
        weeklyBookingLimitInput: 5,
        applyToExistingUserTickets: false,
        weeklyAutoDeduction: false,
        division_ids: [],
        dailyBookingChangeLimit: 0,
        hasPeriodClassCancel: false,
        does_change_is_show_cancel_count: false,
        classPeriod: 60,
        classPeriodInput: 61,
        time: { start: null, end: null },
        weekOrMonthLimit: 'week',
        is_ignore_new_payment: false,
        selectedColor: [],
        existColor: '',
      },

      /** 수강권 데이터 - 수정시 참조용 */
      originalData: {},
      product: {},

      /** Options */
      courseTypeOptions: [
        { value: 'P', label: '프라이빗형 수업 전용' },
        { value: 'G', label: '그룹형 수업 전용' },
      ],
      /** 주간/월간 이용 횟수 Options*/
      weekMonthLimitOptions: [
        { value: 'week', label: '주간 이용 횟수' },
        { value: 'month', label: '월간 이용 횟수' },
      ],
      divisionOptions: [],
      checkedColor: true,
      dataCustomColor: [],
    };
  },

  computed: {
    isProduct() {
      return this.$route.path.includes('/products/product');
    },

    breadcrumb() {
      return {
        parent: { path: '/products', label: '수강권' },
        current_route_label: this.title,
      };
    },

    ticket() {
      return this.$store.getters['ticket/ticket'];
    },

    title() {
      return !this.$route.query.id
        ? this.isProduct
          ? '상품 등록'
          : '수강권 등록'
        : this.isProduct
        ? '상품 수정'
        : '수강권 수정';
    },

    lastUpdatedAt() {
      if (!this.product.id) return '';
      const date = this.product.updated_at || this.product.created_at;
      if (!date) return null;

      const suffix = this.product.updated_at ? '에 마지막으로 수정됨' : '에 생성됨';

      return `${this.$filters.datetime(date)} ${suffix}`;
    },

    isProductPeriodValid() {
      return this.data.productPeriod > 0 || this.data.productPeriodInput > 0;
    },

    isMaxTraineeValid() {
      if (this.data.courseType === 'G') {
        return this.data.maxTrainee > 0;
      } else {
        return 1 <= this.data.maxTrainee && this.data.maxTrainee <= 3;
      }
    },

    isWeeklyBookingLimitValid() {
      return this.data.weeklyBookingLimit >= 0 || this.data.weeklyBookingLimitInput > 0;
    },

    updatedFields() {
      if (!this.originalData) return [];
      return Object.keys(this.data).filter(key => this.originalData[key] !== this.data[key]);
    },

    saveButtonDisabled() {
      const { productTitle, maxCoupon } = this.data;
      let saveButtonDisabled =
        !productTitle || !maxCoupon || !this.isProductPeriodValid || !this.isMaxTraineeValid || !this.isWeeklyBookingLimitValid;
      if (this.product.id) {
        saveButtonDisabled = saveButtonDisabled || !this.updatedFields.length;
      }

      return saveButtonDisabled;
    },
    isTypeGroup() {
      return this.courseType === 'G';
    },
    hasCustomColor() {
      return !!this.data.selectedColor.length;
    },
    colorPresets() {
      return this.$store.getters['colorPresets/colorGradientPalette'];
    },
    colorChips() {
      let colorChips = [];
      if (!this.showColorsAll) {
        // 기본컬러셋만 보일경우
        colorChips.push(this.data.selectedColor);
      } else {
        colorChips = this.colorPresets.reduce((combined, colorGroup) => combined.concat(colorGroup), []);
      }

      return colorChips;
    },
    colorSetData() {
      return this.$store.getters['ticket/totalColorSet'];
    },
    colorCount() {
      const compareColor = this.data.selectedColor[0];
      let result = 0;
      this.colorSetData.forEach(function(el) {
        if (el.color.includes(compareColor)) {
          result = el.count;
        }
      });
      return result;
    },
    showColorsAll() {
      return this.data.selectedColor.length;
    },
    messageOnColorSelected() {
      if (this.colorCount >= 1 && this.existColor === this.data.selectedColor[0]) {
        return this.colorCount === 1 ? '' : `${this.colorCount - 1} 개의 다른 수강권에서 사용 중인 색상입니다.`;
      }
      if (this.colorCount) return `${this.colorCount} 개의 다른 수강권에서 사용 중인 색상입니다.`;
      return '';
    },

    maxCoupon() {
      const { courseType, productType, ticketType, maxCoupon } = this.data;
      if (ticketType === 'P' || this.ticketType === 'RP') return 999;
      if (courseType === 'I' && productType === 'S') return null;

      return maxCoupon;
    },

    maxCancel() {
      const { courseType, productType, ticketType, maxCancel, hasPeriodClassCancel } = this.data;
      if (ticketType === 'P' && hasPeriodClassCancel) {
        return maxCancel;
      }
      if (ticketType === 'P' || this.ticketType === 'RP') return 999;
      if ((courseType === 'I' && productType === 'S') || this.ticketType === 'RT') return null;

      return maxCancel;
    },

    availablePeriod() {
      const { courseType, productType, productPeriod, productPeriodInput } = this.data;
      if (courseType === 'I' && productType === 'S') return null;
      if (productPeriod > 0) return productPeriod;

      return productPeriodInput;
    },

    ticketType() {
      const { productType, courseType, ticketType } = this.data;
      if (courseType === 'I' && productType === 'S') return 'S';

      return ticketType;
    },
  },

  watch: {
    $rotue: {
      handler: 'initData',
      immediate: true,
    },
    'data.ticketType'() {
      this.data.weeklyAutoDeduction = false;
    },
    'data.courseType'() {
      if (!this.product.id) {
        if (this.data.courseType === 'P') {
          this.data.maxTrainee = 1;
        } else {
          this.data.maxTrainee = 4;
        }
      }
      if (this.data.courseType === 'P') {
        this.data.division_ids = [];
      }
    },

    'data.weeklyAutoDeduction'() {
      const { weeklyAutoDeduction, weeklyBookingLimit } = this.data;
      if (weeklyAutoDeduction && weeklyBookingLimit === 0) {
        this.data.weeklyBookingLimit = 1;
      }
    },

    'data.hasDailyBookingChangeLimit'() {
      const { hasDailyBookingChangeLimit, dailyBookingChangeLimit } = this.data;
      if (!hasDailyBookingChangeLimit) {
        this.data.dailyBookingChangeLimit = 0;
      } else if (dailyBookingChangeLimit === 0) {
        this.data.dailyBookingChangeLimit = 1;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      const authorized = !to.query.id ? vm.canCreateProduct : vm.canUpdateProduct;

      if (!authorized) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/products');
      }

      next();
    });
  },

  async created() {
    try {
      const res = await this.$api.studio.divisions.get();
      this.divisionOptions = res.data;
      this.$store.dispatch('ticket/getColorSets');
    } catch (error) {
      this.divisionOptions = [];
    }

    /** 초기값 세팅 */
    const { id } = this.$route.query;
    if (id) {
      try {
        const res = await this.$api.product.get(id);
        if (res.data.available_class_type === 'I' && res.data.type !== 'S') {
          this.data.productType = 'R';
        }

        if (res.data.booking_start_time) {
          this.data.time.start = res.data.booking_start_time.substring(0, res.data.booking_start_time.length - 3);
          this.data.time.end = res.data.booking_end_time.substring(0, res.data.booking_end_time.length - 3);
        }

        let productPeriod = 0;
        if (res.data.available_period === null) {
          productPeriod = 30;
        } else if ([30, 60, 90, 180, 365].includes(res.data.available_period)) {
          productPeriod = res.data.available_period;
        } else {
          productPeriod = -1;
        }
        let weeklyBookingLimit;
        if (res.data.booking_limit_per_month) {
          weeklyBookingLimit = res.data.booking_limit_per_month < 5 ? res.data.booking_limit_per_month : -1;
        } else if (res.data.booking_limit_per_week) {
          weeklyBookingLimit = res.data.booking_limit_per_week < 5 ? res.data.booking_limit_per_week : -1;
        } else {
          weeklyBookingLimit = 0;
        }

        const data = {
          courseType: res.data.available_class_type,
          ticketType: res.data.type,
          productTitle: res.data.title,
          maxCoupon: res.data.max_coupon,
          maxCancel: res.data.max_cancel,
          productPeriod,
          productPeriodInput: res.data.available_period,
          maxTrainee: res.data.max_trainee,
          actualPrice: res.data.actual_price,
          weeklyBookingLimit,
          weekOrMonthLimit: res.data.booking_limit_per_month ? 'month' : 'week',
          weeklyBookingLimitInput: res.data.booking_limit_per_month
            ? res.data.booking_limit_per_month
            : res.data.booking_limit_per_week,
          applyToExistingUserTickets: false,
          weeklyAutoDeduction: res.data.use_weekly_auto_coupon_balance === 1,
          division_ids: res.data.divisions.map(({ id }) => id),
          dailyBookingChangeLimit: res.data.daily_booking_change_limit,
          hasDailyBookingChangeLimit: res.data.daily_booking_change_limit > 0,
          classPeriod: [5, 10, 15, 20, 30, 50, 60].includes(res.data.class_period) ? res.data.class_period : -1,
          classPeriodInput: res.data.class_period,
          booking_start_time: res.data.booking_start_time,
          booking_end_time: res.data.booking_end_time,
          hasPeriodClassCancel: res.data.is_show_cancel_count === null ? false : res.data.is_show_cancel_count,
          is_ignore_new_payment: res.data.is_ignore_new_payment,
          selectedColor: res.data.colors,
          rewardPoint: res.data.reward_point,
        };
        this.dataCustomColor = res.data.colors;
        this.product = res.data;
        this.originalData = { ...data };
        this.data = { ...this.data, ...data };
        this.existColor = res.data.colors[0]; //몇개의 수강권에서 컬러 사용중인지 계산식 - 본인의 컬러값
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    }
  },

  methods: {
    initData() {
      if (this.isProduct) {
        this.data = { ...this.data, ticketType: 'S', courseType: 'I' };
      }
    },

    validate() {
      let message = '';
      let refKey = '';
      let isValid = true;

      if (this.data.ticketType === 'S') {
        if (!this.data.productTitle) {
          message = '상품명을 입력해주세요';
          isValid = isValid && false;
        } else if (this.data.actualPrice > 15000000) {
          message = '판매가는 15,000,000원을 초과할 수 없습니다.';
          isValid = isValid && false;
        } else if (this.data.rewardPoint > 15000000) {
          message = '포인트 입력값은 15,000,000을 초과할 수 없습니다.';
          isValid = isValid && false;
        }
      } else {
        if (!this.data.productTitle) {
          message = `${this.isProduct ? '상품' : '수강권'}명을 입력해주세요`;
          isValid = isValid && false;
        } else if (!this.data.maxCoupon) {
          message = '총 이용횟수를 입력해주세요.';
          refKey = 'max-coupon';
          isValid = isValid && false;
        } else if (!this.isProductPeriodValid) {
          message = '수강권 사용기한을 설정해주세요.';
          refKey = 'product-period';
          isValid = isValid && false;
        } else if (!this.isProduct && !this.isMaxTraineeValid) {
          message = '최대 수강 인원을 입력해주세요.';
          refKey = 'max-trainee';
          isValid = isValid && false;
        } else if (this.data.actualPrice > 15000000) {
          message = '판매가는 15,000,000원을 초과할 수 없습니다.';
          isValid = isValid && false;
        } else if (this.data.rewardPoint > 15000000) {
          message = '포인트 입력값은 15,000,000을 초과할 수 없습니다.';
          isValid = isValid && false;
        } else if (!this.isWeeklyBookingLimitValid) {
          message = '주간 이용 횟수를 설정해주세요.';
          refKey = 'weekly-booking-limit';
          isValid = isValid && false;
        } else if (!this.isProduct && this.data.classPeriod === -1 && !this.data.classPeriodInput) {
          message = '수업시간 설정을 입력해주세요.';
          refKey = 'class-period';
          isValid = isValid && false;
        } else if ((this.data.time.start && !this.data.time.end) || (!this.data.time.start && this.data.time.end)) {
          message = '예약 가능 시작 시간과 마감시간을 설정해주세요';
          refKey = 'limit-time';
          isValid = isValid && false;
        } else if (this.product.id && !this.updatedFields.length) {
          this.$router.push(`/products/detail?id=${this.product.id}`);
          return false;
        }
      }

      if (!isValid) {
        this.$utils.notify.error(this, '오류', message);
        if (refKey) {
          this.$refs[refKey].$el.querySelector('input').focus();
        }
      }

      return isValid;
    },

    async saveProduct() {
      if (!this.validate()) return;

      try {
        this.isSaving = true;

        const {
          courseType,
          productTitle,
          maxTrainee,
          actualPrice,
          weeklyBookingLimit,
          weeklyBookingLimitInput,
          weekOrMonthLimit,
          applyToExistingUserTickets,
          weeklyAutoDeduction,
          division_ids,
          hasDailyBookingChangeLimit,
          dailyBookingChangeLimit,
          classPeriod,
          classPeriodInput,
          hasPeriodClassCancel,
          does_change_is_show_cancel_count,
          time,
          is_ignore_new_payment,
          selectedColor,
          rewardPoint,
        } = this.data;

        let booking_limit_per_week = weeklyBookingLimit > -1 ? weeklyBookingLimit : weeklyBookingLimitInput;
        let booking_limit_per_month;
        if (weekOrMonthLimit === 'month') {
          booking_limit_per_month = weeklyBookingLimit > -1 ? weeklyBookingLimit : weeklyBookingLimitInput;
          booking_limit_per_week = 0;
        } else if (weekOrMonthLimit === 'week') {
          booking_limit_per_week = weeklyBookingLimit > -1 ? weeklyBookingLimit : weeklyBookingLimitInput;
          booking_limit_per_month = 0;
        }

        let data = {
          available_class_type: courseType,
          available_period: this.availablePeriod,
          booking_limit_per_week: this.isProduct ? 0 : booking_limit_per_week,
          booking_limit_per_month: this.isProduct ? 0 : booking_limit_per_month,
          class_period: this.isProduct ? null : classPeriod === -1 ? classPeriodInput : classPeriod,
          daily_booking_change_limit:
            !hasDailyBookingChangeLimit || courseType === 'P' || courseType === 'I' ? 0 : dailyBookingChangeLimit,
          division_ids,
          is_active_holding_function: 1,
          max_cancel: this.maxCancel,
          max_coupon: this.maxCoupon,
          is_show_cancel_count: this.isProduct ? 0 : hasPeriodClassCancel,
          does_change_is_show_cancel_count: this.isProduct ? false : does_change_is_show_cancel_count,
          max_trainee: this.isProduct ? 0 : maxTrainee,
          min_trainee: this.isProduct ? 0 : 1,
          title: productTitle,
          type: this.ticketType,
          use_weekly_auto_coupon_balance: this.isProduct ? false : weeklyAutoDeduction,
          goods_type: this.isProduct ? 'item' : 'ticket',
          actual_price: actualPrice,
          original_price: actualPrice,
          is_selling: 1,
          booking_start_time: this.isProduct ? null : time.start,
          booking_end_time: this.isProduct ? null : time.end,
          is_ignore_new_payment: this.isProduct ? false : is_ignore_new_payment,
          colors: selectedColor,
          reward_point: rewardPoint,
        };

        if (!this.product.id) {
          const res = await this.$api.product.create(data);
          this.$utils.notify.success(this, '성공', `${this.isProduct ? '상품' : '수강권'}이 생성되었습니다.`);
          this.$router.push(`/products/detail?id=${res.data.id}`);
        } else {
          if (applyToExistingUserTickets) {
            data.does_change_booking_limit = true;
          }
          await this.$api.product.update(this.product.id, data);
          this.$utils.notify.success(this, '성공', `${this.isProduct ? '상품' : '수강권'} 정보가 수정되었습니다.`);
          this.$router.push(`/products/detail?id=${this.product.id}`);
        }
        this.$store.dispatch('ticket/getColorSets'); // 모든 수강권 컬러 상태 업데이트
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isSaving = false;
      }
    },

    confirmDecution(value) {
      const { id } = this.$route.query;
      if (!id) return;

      const context = {
        title: '주간/월간 이용 횟수 설정',
        message: `이미 발급한 수강권에도 <b>${this.data.weekOrMonthLimit === 'week' ? '주' : '월'}${
          this.data.weeklyBookingLimit === -1
            ? this.data.weeklyBookingLimitInput
            : this.data.weeklyBookingLimit === 0
            ? 1
            : this.data.weeklyBookingLimit
        }회</b>  자동차감을 설정하시겠습니까?`,
        accept: { confirm: '설정', cancel: '설정 안함' },
        reject: { confirm: '취소', cancel: '취소 안함' },
      };
      if (!value) {
        context.title = '주간/월간 이용 횟수 설정 취소';
        context.message = '이미 발급한 수강권의 자동차감 설정을 취소 하시겠습니까?';
      }

      this.$confirm(context.message, context.title, {
        confirmButtonText: value ? context.accept.confirm : context.reject.confirm,
        cancelButtonText: value ? context.accept.cancel : context.reject.cancel,
        dangerouslyUseHTMLString: true,
      })
        /** confirm 클릭 이벤트  */
        .then(() => {
          if (context.title === '주간/월간 이용 횟수 설정') {
            this.data.weeklyAutoDeduction = true;
            this.data.applyToExistingUserTickets = true;
          } else {
            this.data.weeklyAutoDeduction = false;
            this.data.applyToExistingUserTickets = false;
          }
        })
        /** cancel 클릭 이벤트  */
        .catch(() => {
          if (context.title === '주간/월간 이용 횟수 설정') {
            this.data.weeklyAutoDeduction = false;
          } else {
            this.data.weeklyAutoDeduction = true;
          }
        });
    },
    handleDefalutColor() {
      this.data.selectedColor = [];
    },
    handleColorSelect(color) {
      this.data.selectedColor = color;
    },
    openColorSet() {
      this.data.selectedColor = this.dataCustomColor.length ? this.dataCustomColor : ['ff8181', 'ff2525'];
    },
  },
};
</script>

<style lang="scss" scoped>
.content-title {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;

  /deep/ .el-select {
    margin-left: 8px;
  }

  /deep/ .el-input__inner {
    border: 1px solid $gray-300;
    border-radius: 4px;
    padding: 8px;
  }
}
</style>
