<template>
  <section id="product-form">
    <form-block :index="1" label="체험권">
      <el-checkbox v-model="data.is_ignore_new_payment" :true-label="1" :false-label="0" slot="checkbox">사용함</el-checkbox>
    </form-block>

    <form-block :index="2" label="수강권 종류">
      <el-radio-group v-model="data.ticketType" class="product-form__radio-group" :disabled="!!product.id">
        <el-radio label="T">횟수제</el-radio>
        <el-radio label="P">기간제</el-radio>
      </el-radio-group>
    </form-block>

    <form-block :index="3" label="수강권명 입력">
      <TextInput class="product-form__product-title" ref="product-title" v-model="data.productTitle" required material />
    </form-block>
    <form-block :index="4" label="수강권 색상 설정">
      <p>
        수강권 유형별(그룹형, 프라이빗형)로 기본 색상이 설정되어 있습니다.<br />
        다른 색상을 지정하려면 색상 추가 버튼을 클릭해서 색상을 변경 할 수 있습니다.<br />
        기본 색상 외 다른 색상을 지정하면 웹 페이지에서만 반영됩니다.
      </p>
      <!-- color set -->
      <div class="wrap-color">
        <div>
          <div
            @click="$emit('handleDefalutColor')"
            class="type-color-chip"
            :class="{ G: data.courseType === 'G', P: data.courseType === 'P', checked: !hasCustomColor }"
          ></div>
          <div class="wrap-color__chips" v-if="hasCustomColor">
            <ColorGradientChip
              v-for="(color, index) in colorChips"
              :key="index"
              :color="color"
              :checked="color[0] === data.selectedColor[0] && checkedColor"
              @click="$emit('handleColorSelect', color)"
            />
          </div>
        </div>
        <plain-button class="wrap-color__add-new" :disabled="hasCustomColor" @click="$emit('openColorSet')">
          색상 추가
        </plain-button>
        <div
          class="wrap-color__sample"
          :class="{ G: data.courseType === 'G', P: data.courseType === 'P' }"
          :style="
            hasCustomColor
              ? `background-image: linear-gradient(to bottom, #${data.selectedColor[0]},#${data.selectedColor[1]})`
              : ''
          "
        >
          <p class="type">{{ data.courseType === 'G' ? '그룹형 수강권' : '프라이빗 수강권' }}</p>
          <p class="title">수강권 색상 설정 예시</p>
        </div>
        <p class="wrap-color__helper">
          {{ messageOnColorSelected }}
        </p>
      </div>
    </form-block>

    <form-block v-if="data.ticketType === 'P'" :index="5" label="예약 취소 가능 횟수">
      <el-checkbox slot="checkbox" v-model="data.hasPeriodClassCancel">
        설정함
      </el-checkbox>
      <el-checkbox v-if="product.id" slot="checkbox" v-model="data.does_change_is_show_cancel_count">
        이미 발급된 수강권에도 적용
      </el-checkbox>
      <p class="product-form__message__padding">기간제 수강권의 <b>예약 취소 가능한 횟수</b> 표시 여부를 설정합니다.</p>
      <div v-if="data.hasPeriodClassCancel" class="product-form__coupon-count">
        <NumberInput v-model="data.maxCancel" label="취소 가능 횟수" :min="0" :max="999" suffix="회" />
      </div>
    </form-block>

    <form-block v-if="data.ticketType === 'T'" :index="5" label="이용횟수 설정">
      <div class="product-form__coupon-count">
        <NumberInput ref="max-coupon" v-model="data.maxCoupon" label="총 이용횟수" :min="1" :max="999" suffix="회" />
        <NumberInput v-model="data.maxCancel" label="취소 가능 횟수" :min="0" :max="999" suffix="회" />
      </div>
    </form-block>

    <form-block :index="6" label="수강권 사용기한 설정">
      <div class="product-form__product-period">
        <el-radio v-model="data.productPeriod" :label="30">1개월 (30일)</el-radio>
        <el-radio v-model="data.productPeriod" :label="60">2개월 (60일)</el-radio>
        <el-radio v-model="data.productPeriod" :label="90">3개월 (90일)</el-radio>
        <el-radio v-model="data.productPeriod" :label="180">6개월 (180일)</el-radio>
        <el-radio v-model="data.productPeriod" :label="365">1년 (365일)</el-radio>
        <el-radio v-if="data.productPeriod !== -1" v-model="data.productPeriod" :label="-1">직접입력</el-radio>
        <NumberInput v-else ref="product-period" v-model="data.productPeriodInput" :min="1" :max="3000" suffix="일" />
      </div>
    </form-block>

    <form-block :index="7" label="수강인원 설정">
      <NumberInput
        v-if="data.courseType === 'G'"
        ref="max-trainee"
        class="product-form__max-trainee--input"
        v-model="data.maxTrainee"
        :min="1"
        :max="99"
        suffix="명"
      />
      <el-radio-group v-if="data.courseType === 'P'" v-model="data.maxTrainee" class="product-form__radio-group">
        <el-radio :label="1">개인</el-radio>
        <el-radio :label="2">듀엣</el-radio>
        <el-radio :label="3">트리플</el-radio>
      </el-radio-group>
    </form-block>

    <form-block :index="8" label="수강권 판매정보 입력">
      <section class="product-form__wrapper">
        <div class="product-form__price">
          <PriceInput ref="actual-price" v-model="data.actualPrice" label="판매가 입력" />
        </div>

        <div class="product-form__point">
          <PointInput ref="reward-point" v-model="data.rewardPoint" label="적립 포인트 입력" />
        </div>
      </section>
    </form-block>

    <form-block :index="9" label="주간/월간 이용 횟수 설정">
      <p class="product-form__message">
        <b>주간 이용 횟수</b>의 제한 기준은 <b>매주 월요일 ~ 일요일</b> 기준입니다.<br />
        <b>월간 이용 횟수</b>의 제한 기준은 <b>매월 1일 ~ 말일</b> 기준입니다.<br />
      </p>
      <el-checkbox v-if="product.id" slot="checkbox" v-model="data.applyToExistingUserTickets"
        >이미 발급된 수강권에도 적용</el-checkbox
      >
      <div class="product-form__week-month-limit">
        <RadioGroup v-model="data.weekOrMonthLimit" :options="weekMonthLimitOptions" />
      </div>
      <div class="product-form__weekly-booking-limit">
        <el-radio v-model="data.weeklyBookingLimit" :label="0" :disabled="data.weeklyAutoDeduction">제한없음</el-radio>
        <el-radio v-model="data.weeklyBookingLimit" :label="1">1회</el-radio>
        <el-radio v-model="data.weeklyBookingLimit" :label="2">2회</el-radio>
        <el-radio v-model="data.weeklyBookingLimit" :label="3">3회</el-radio>
        <el-radio v-model="data.weeklyBookingLimit" :label="4">4회</el-radio>
        <el-radio v-if="data.weeklyBookingLimit !== -1" v-model="data.weeklyBookingLimit" :label="-1">직접입력</el-radio>
        <NumberInput v-else ref="weekly-booking-limit" v-model="data.weeklyBookingLimitInput" :min="5" :max="99" suffix="회" />
      </div>
      <p v-if="$route.query.id" class="product-form__description">
        설정값을 수정할 경우 수정 시점 이후부터 적용됩니다.
      </p>
    </form-block>

    <form-block v-if="data.ticketType === 'T'" :index="data.ticketType === 'T' ? 10 : 9" label="주간/월간 이용 횟수 자동 차감">
      <el-checkbox slot="checkbox" v-model="data.weeklyAutoDeduction" @change="confirmDecution(data.weeklyAutoDeduction)">
        설정함
      </el-checkbox>
      <p class="product-form__message">
        <b>주간 이용 횟수</b> 만큼 수강권 잔여 횟수가 <b>매주 월요일 0시에 자동으로 차감</b>됩니다.<br />
        <b>월간 이용 횟수</b> 만큼 수강권 잔여 횟수가 <b>매월 1일 0시에 자동으로 차감</b>됩니다.<br />
      </p>
    </form-block>

    <form-block v-if="data.courseType === 'G'" :index="data.ticketType === 'T' ? 11 : 10" label="수업 구분">
      <p class="product-form__message">
        수업의 종목을 지정할 수 있습니다. 수업 종목은 하나 이상 다중선택할 수 있습니다.<br />
        수업 종목을 지정하여 특정 수강권을 가진 회원만 수업을 예약할 수 있습니다.
      </p>
      <el-select
        class="product-form__division-select"
        v-model="data.division_ids"
        size="small"
        multiple
        placeholder="수업 구분 없음"
      >
        <el-option v-for="item in divisionOptions" :key="item.id" :value="item.id" :label="item.title" />
      </el-select>
    </form-block>

    <form-block v-else-if="data.courseType === 'P'" :index="data.ticketType === 'T' ? 11 : 10" label="수업시간 설정">
      <div class="product-form__class-period">
        <el-radio v-model="data.classPeriod" :label="5">5분</el-radio>
        <el-radio v-model="data.classPeriod" :label="10">10분</el-radio>
        <el-radio v-model="data.classPeriod" :label="15">15분</el-radio>
        <el-radio v-model="data.classPeriod" :label="20">20분</el-radio>
        <el-radio v-model="data.classPeriod" :label="30">30분</el-radio>
        <el-radio v-model="data.classPeriod" :label="50">50분</el-radio>
        <el-radio v-model="data.classPeriod" :label="60">1시간</el-radio>
        <el-radio v-if="data.classPeriod !== -1" v-model="data.classPeriod" :label="-1">직접입력</el-radio>
        <NumberInput v-else v-model="data.classPeriodInput" ref="class-period" :min="1" :max="999" suffix="분" />
      </div>
    </form-block>

    <form-block v-if="data.courseType === 'G'" :index="data.ticketType === 'T' ? 12 : 11" label="당일 예약 변경">
      <el-checkbox slot="checkbox" v-model="data.hasDailyBookingChangeLimit">
        사용함
      </el-checkbox>
      <p class="product-form__daily-booking-change">
        회원은 당일 수업 예약을 최대
        <NumberInput
          class="product-form__number-input"
          v-model="data.dailyBookingChangeLimit"
          :disabled="!data.hasDailyBookingChangeLimit"
          :min="!data.hasDailyBookingChangeLimit ? 0 : 1"
          :max="99"
          suffix="회"
        />
        까지 <b style="margin-left: 5px;">예약 변경 가능</b>합니다.
      </p>
    </form-block>
    <form-block
      :index="data.courseType === 'G' ? (data.ticketType === 'T' ? 13 : 12) : data.ticketType === 'T' ? 12 : 11"
      label="예약 가능한 시간 설정"
    >
      <hours-input
        class="product-form__hour-input"
        ref="limit-time"
        v-model="data.time"
        :startHourOptions="{
          start: '00:00',
          end: '23:00',
          step: '00:30',
        }"
        :endHourOptions="{
          start: data.time.start || '00:00',
          end: '23:30',
          step: '00:30',
          minTime: data.time.start,
        }"
        :clearable="false"
      />
    </form-block>
  </section>
</template>

<script>
export default {
  props: {
    data: Object,
    product: Object,
    divisionOptions: Array,
    hasCustomColor: Boolean,
    colorChips: Array,
    messageOnColorSelected: String,
  },

  data() {
    return {
      weekMonthLimitOptions: [
        { value: 'week', label: '주간 이용 횟수' },
        { value: 'month', label: '월간 이용 횟수' },
      ],
      checkedColor: true,
    };
  },

  methods: {
    confirmDecution(value) {
      this.$emit('confirmDecution', value);
    },
  },
};
</script>

<style lang="scss" scoped>
#product-form {
  padding: 10px 20px 60px;
  width: 100%;
  max-width: 1024px;
  margin: auto;

  @include mq(568px) {
    padding: 30px 40px 60px;
  }
}

.product-form {
  &__hour-input.hours-input {
    padding: 0;
  }

  &__cancel_count {
    /deep/ .form-block__label {
      width: 800px;
    }
  }

  &__radio-group {
    margin: 20px 0 10px;
  }

  &__product-title {
    max-width: 300px;
  }

  &__coupon-count,
  &__price,
  &__point {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;

    @include mq(360px) {
      grid-template-columns: repeat(2, minmax(0, 200px));
    }
  }

  &__week-month-limit {
    /deep/ .custom-radio-group {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      margin: 10px 0;
    }
  }

  &__product-period,
  &__weekly-booking-limit,
  &__class-period {
    display: grid;
    align-items: center;
    justify-items: start;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin-top: 12px;
    max-width: 480px;

    @include mq(568px) {
      grid-template-columns: repeat(3, 1fr);
    }

    /deep/ .el-radio {
      margin: 8px 0;
    }
  }

  &__max-trainee {
    &--input.custom-number-input {
      max-width: 200px;
    }
  }

  &__discount-rate {
    @include flex(row, center, flex-start);

    span {
      font-size: 11px;
    }

    /deep/ .el-input {
      box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      background-color: rgba(244, 244, 244, 0.7);
      margin: 0 2px;
      width: 36px;
    }

    /deep/ .el-input__inner {
      border: none;
      font-size: 11px;
      text-align: center;
    }
  }

  &__message {
    font-size: 12px;
    font-weight: 300;

    b {
      font-weight: 500;
    }
  }

  &__message__padding {
    font-size: 12px;
    font-weight: 300;
    padding-bottom: 15px;

    b {
      font-weight: 500;
    }
  }

  &__division-select.el-select {
    margin-top: 12px;
    max-width: 400px;
  }

  &__daily-booking-change {
    @include flex(row, center);

    .custom-number-input {
      max-width: 150px;
    }
  }

  &__description {
    color: $dodger-blue;
    font-size: 12px;
    margin: 20px 0 10px;
  }

  &__wrapper {
    display: flex;
  }
}
.wrap-color {
  margin: 16px 0 16px 0px;
  display: grid;
  grid-template-columns: 272px 170px 1fr;
  grid-gap: 8px;
  align-items: start;
  &__sample {
    @include flex(column);
    width: 256px;
    height: 144px;
    border-radius: 8px;
    padding: 12px;
    position: relative;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    .type {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
    }
    &.G {
      background-image: linear-gradient(122deg, rgba(178, 134, 240, 0), rgba(178, 134, 240, 0.7)),
        linear-gradient(to bottom, #64aeff, #64aeff);
    }
    &.P {
      background-image: linear-gradient(to bottom, rgba($pink, 0), rgba($pink, 0.7)),
        linear-gradient(to bottom, $light-purple, $light-purple);
    }
  }
  .type-color-chip {
    &.G {
      background-image: linear-gradient(122deg, rgba(178, 134, 240, 0), rgba(178, 134, 240, 0.7)),
        linear-gradient(to bottom, #64aeff, #64aeff);
    }
    &.P {
      background-image: linear-gradient(to bottom, rgba($pink, 0), rgba($pink, 0.7)),
        linear-gradient(to bottom, $light-purple, $light-purple);
    }
    margin-bottom: 8px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    transition: background 0.15s;
    position: relative;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: '';
      left: 9px;
      top: 3px;
      width: 0px;
      height: 0px;
      border: solid white;
      border-width: 0 4px 4px 0;
      border-radius: 2px;
      position: absolute;
      opacity: 0;
      transform: rotate(-90deg);
      transition: 0.15s transform, 0.15s opacity, 0.15s width, 0.15s height;
    }

    &.checked::before {
      transform: rotate(40deg);
      opacity: 1;
      width: 12px;
      height: 20px;
    }
  }
  &__chips {
    display: grid;
    grid-template-columns: repeat(7, 32px);
    grid-gap: 8px;
  }

  &__add-new {
    width: 100px;
    height: 32px;
  }

  &__helper {
    grid-column: span 2;
    height: 20px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.6px;
    color: $coral;
    padding-bottom: 10px;
    // border-bottom: solid 1px rgba(#8a8a8a, 0.2);
  }
}
</style>
