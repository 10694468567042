<template>
  <el-table
    :data="data"
    :key="activeTab"
    fit
    @cell-click="goToMemberDetail"
    :row-class-name="activeTab === 'ticketsRevenue' ? 'sales-list__row' : null"
  >
    <el-table-column v-if="activeTab === 'ticketsRevenue'" label="구분" width="90" align="center">
      <template slot-scope="scope">
        <div v-if="scope.row.tutorialTicket && scope.row.paymentType !== 'refund'" class="tags tags__tutorial">
          <span>체험</span>
        </div>
        <el-tag v-else-if="scope.row.paymentType === 'upgrade'" size="mini" type="primary">업그레이드</el-tag>
        <el-tag v-else-if="scope.row.paymentType === 'refund'" size="mini" type="danger">환불</el-tag>
        <el-tag v-else-if="scope.row.paymentType === 'transfer'" size="mini" class="tags tags__transfer">양도</el-tag>
        <el-tag v-else-if="scope.row.isNew" size="mini" type="success">신규결제</el-tag>
        <el-tag v-else-if="scope.row.paymentType === 'purchase' || scope.row.paymentType === 'extension'" size="mini" type="info"
          >재결제</el-tag
        >
        <el-tag v-else size="mini" type="info">
          재결제
        </el-tag>
        <div v-if="isAttachUnpaymentTag(scope.row.paymentType)" class="tags tags__unpaid" style="margin-top: 3px;">
          미수금
        </div>
      </template>
    </el-table-column>

    <el-table-column v-if="activeTab === 'lecturesRevenue'" label="구분" width="60" align="center">
      <template slot-scope="scope">
        <el-tag v-if="scope.row.usageType === 'lecture'" size="mini" type="warning">수업</el-tag>
        <el-tag v-else-if="scope.row.usageType === 'deducted'" size="mini" type="danger">차감</el-tag>
      </template>
    </el-table-column>

    <el-table-column v-if="activeTab === 'ticketsRevenue'" min-width="110" align="center" :formatter="formatPayedOn">
      <span slot="header">결제일<br />(환불일)</span>
    </el-table-column>

    <el-table-column v-if="activeTab === 'lecturesRevenue'" min-width="110" align="center">
      <span slot="header">수업일<br />(차감일)</span>
      <template slot-scope="scope">
        <span v-if="scope.row.usageType === 'lecture'">
          {{ scope.row.lectureStartOn | date }}
        </span>
        <span v-else-if="scope.row.usageType === 'deducted'"> ({{ scope.row.deductedOn | date }}) </span>
      </template>
    </el-table-column>

    <el-table-column
      v-if="activeTab === 'lecturesRevenue'"
      label="수업시간"
      min-width="110"
      align="center"
      :formatter="formatLectureTime"
    />

    <el-table-column label="수업구분" min-width="80" align="center" :formatter="formatCourseType" />

    <el-table-column prop="memberName" label="회원명" min-width="100" align="center" />

    <el-table-column label="수강권명" min-width="150" header-align="center" align="left">
      <template slot-scope="scope">
        <p class="ticket-title">
          <span>{{ scope.row.ticketTitle }}</span>
          <el-tag size="mini" type="danger" v-if="scope.row.isTicketActive === false && scope.row.paymentType !== 'refund'"
            >사용불가</el-tag
          >
        </p>
      </template>
    </el-table-column>

    <el-table-column align="center">
      <template slot-scope="scope">
        <el-tag v-if="scope.row.deletedAt" size="mini" type="danger">삭제</el-tag>
      </template>
    </el-table-column>

    <el-table-column
      v-if="activeTab === 'lecturesRevenue'"
      label="전체횟수"
      prop="ticketMaxCoupon"
      min-width="75"
      align="center"
    />

    <el-table-column
      v-if="activeTab === 'lecturesRevenue'"
      label="발급횟수"
      prop="userTicketMaxCoupon"
      min-width="75"
      align="center"
    />

    <el-table-column
      v-if="activeTab === 'lecturesRevenue'"
      label="회당금액"
      min-width="80"
      align="center"
      :formatter="formatPricePerCoupon"
    />

    <el-table-column min-width="80" align="center" :formatter="formatAmount">
      <span slot="header" v-html="getAmountLabel()" />
    </el-table-column>

    <el-table-column v-if="activeTab === 'ticketsRevenue'" min-width="110" align="center" :formatter="formatUnpaidAmount">
      <span slot="header">미수금<br />(위약금)</span>
    </el-table-column>

    <el-table-column
      v-if="activeTab === 'ticketsRevenue'"
      label="결제방법"
      min-width="120"
      align="center"
      :formatter="formatPaymentMethod"
    />

    <el-table-column v-if="activeTab === 'lecturesRevenue'" min-width="115" align="center">
      <span slot="header">누적 사용횟수<br />(금액)</span>
      <template slot-scope="scope">
        <p>{{ scope.row.usedCountTotal }}</p>
        <p>({{ scope.row.deductedAmountTotal | comma }})</p>
      </template>
    </el-table-column>

    <el-table-column v-if="activeTab === 'lecturesRevenue'" min-width="85" align="center">
      <span slot="header">잔여횟수<br />(미수업금)</span>
      <template slot-scope="scope">
        <p>{{ scope.row.remainingCoupon }}</p>
        <p>({{ scope.row.payableAmount | comma }})</p>
      </template>
    </el-table-column>

    <el-table-column
      prop="staffName"
      :label="activeTab === 'lecturesRevenue' ? '수업강사' : '담당강사'"
      min-width="80"
      align="center"
    />

    <el-table-column
      v-if="activeTab === 'lecturesRevenue'"
      label="차감사유"
      min-width="100"
      align="center"
      :formatter="formatDeductionType"
    />
  </el-table>
</template>

<script>
export default {
  props: {
    data: Array,
    activeTab: String,
    filter: Object,
  },

  methods: {
    formatPayedOn({ payedOn, paymentType }) {
      if (!payedOn) return '-';
      return paymentType !== 'refund' ? this.$filters.date(payedOn) : `(${this.$filters.date(payedOn)})`;
    },

    formatLectureTime({ lectureStartOn, lectureEndOn }) {
      if (!lectureStartOn || !lectureEndOn) return null;
      const start = this.moment(lectureStartOn).format('HH:mm');
      const end = this.moment(lectureEndOn).format('HH:mm');
      return `${start} ~ ${end}`;
    },

    formatCourseType({ courseType }) {
      return this.$utils.translate.courseType(courseType);
    },

    formatPricePerCoupon({ pricePerCoupon }) {
      return !pricePerCoupon ? '-' : this.$filters.comma(pricePerCoupon);
    },

    getAmountLabel() {
      if (this.activeTab === 'lecturesRevenue') return '결제금액';
      const paymentMethod = _.get(this.filter, 'paymentMethod');
      let label = '결제금액<br>(환불금액)';
      if (paymentMethod === 'card') label = '카드 결제금액<br>(카드 환불금액)';
      else if (paymentMethod === 'cash') label = '현금 결제금액<br>(현금 환불금액)';
      return label;
    },

    formatAmount(row) {
      if (this.activeTab === 'lecturesRevenue') {
        return this.$filters.comma(row.totalAmount);
      }
      const paymentMethod = _.get(this.filter, 'paymentMethod');
      let amount = 0;
      if (!paymentMethod) {
        amount = row['totalAmount'];
      } else if (paymentMethod === 'card') {
        amount = row['cardAmount'];
      } else if (paymentMethod === 'cash') {
        amount = row['cashAmount'] + row['wiretransferAmount'];
      }

      const amountString = this.$filters.comma(amount);
      if (paymentMethod) {
        return row.paymentType === 'refund' ? `(-${amountString})` : amountString;
      } else {
        return row.paymentType === 'refund' ? `(${amountString})` : amountString;
      }
    },

    formatUnpaidAmount({ unpaidAmount, paymentType }) {
      return paymentType !== 'refund' ? this.$filters.comma(unpaidAmount) : `(${this.$filters.comma(unpaidAmount)})`;
    },

    formatPaymentMethod({ paymentMethod, hasWiretransferAmount }) {
      let text = this.$utils.translate.paymentMethod(paymentMethod);
      if (hasWiretransferAmount) text += ' (계좌이체)';
      return text;
    },

    formatDeductionType(row) {
      const { usageType, deductionType, usedCount } = row;
      return usageType === 'deducted' ? `${deductionType} (${usedCount * -1})` : deductionType;
    },

    renderMultiRowHeader(h, { column }) {
      const headers = column.label.split('|');
      return h(
        'div',
        {
          style: {
            padding: 0,
          },
        },
        headers.map(header => {
          return h(
            'p',
            {
              style: {
                lineHeight: 1.5,
              },
            },
            header,
          );
        }),
      );
    },

    goToMemberDetail(row) {
      if (this.activeTab === 'ticketsRevenue') {
        this.$router.push(`/users/detail?id=${row.memberId}&user_ticket_id=${row.goodsId}`);
      }
    },

    isAttachUnpaymentTag(paymentType) {
      return !_.get(this.filter, 'paymentType') && (paymentType === 'installment_payment' || paymentType === 'full_payment');
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-title {
  @include flex(row, center, space-between);

  span:first-child {
    @include ellipsis;
  }
}

.el-table {
  /deep/ .sales-list__row {
    cursor: pointer;
  }
}
.tags {
  border-radius: 5px;
  height: 20px;
  font-size: 12px;
  margin: 0 13px;
  line-height: 19px;
  &__tutorial {
    border: 1px solid $light-purple;
    background-color: rgba($light-purple, 0.2);
    color: $light-purple;
  }
  &__unpaid {
    border: 1px solid $golden-yellow;
    background-color: rgba($golden-yellow, 0.2);
    color: $golden-yellow;
  }
  &__transfer {
    border: 1px solid #f9b45e;
    background-color: #fef7ee;
    color: #f9b45e;
  }
}
</style>
