<template>
  <button v-bind="$attrs" :class="[type, size ? '' : null]" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: String,
    size: String,
    round: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
button {
  display: inline-block;
  min-width: 200px;
  background-color: $sky-blue;
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  height: 80px;
  border-radius: 24px;
  &.grey {
    color: #8a8a8a;
    background-color: #fff;
    border: 3px solid #8a8a8a;
  }
}
button + button {
  margin-left: 20px;
}
</style>
