<template>
  <div class="bulk-update-lectures-form-element">
    <div class="bulk-update-lectures-form-element__checkbox">
      <Checkbox :checked="enabled" :disabled="!!errorMessage || disabled" @change="$emit('checkbox-change')" />
    </div>
    <div class="bulk-update-lectures-form-element__content" :class="{ disabled: !enabled }">
      <label>{{ label }}</label>
      <slot></slot>
      <span v-if="!!errorMessage || !!warningMessage" class="bulk-update-lectures-form-element__content__error">{{
        errorMessage || warningMessage
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    enabled: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errorMessage: String,
    warningMessage: String,
    label: String,
  },
};
</script>

<style lang="scss" scoped>
.bulk-update-lectures-form-element {
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-gap: 8px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &__checkbox label {
    padding: 2px 0 0;
  }

  &__content {
    @include flex(column);

    label {
      font-weight: 500;
      margin: 0 3px 3px;
    }

    &.disabled label {
      opacity: 0.5;
    }

    &__error {
      color: $color-danger;
      font-size: 12px;
      margin-top: 4px;
    }
  }
}
</style>
