<template>
  <section>
    <div class="select-ticket__search">
      <TextInput v-model="search" :placeholder="`${title}명 검색`" prefix-icon="el-icon-search" round />
    </div>

    <CustomTabs :active_tab="activeTab" :tabs="tabs" :handleChangeTab="handleChangeTab" />

    <div class="select-ticket__list" v-loading="loading">
      <TicketCard
        v-for="ticket in filteredTickets"
        :key="ticket.id"
        :ticket="ticket"
        @click="handleClickCard"
        clickable
        :showPrice="true"
        :showFavoriteIcon="ticket.favorite_stamp ? true : false"
      />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isProduct: Boolean,
    title: String,
  },

  data() {
    return {
      tabs: [],
      activeTab: 'all',
      search: '',
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketIssue/formData'];
    },

    loading() {
      return this.formData.ticketsLoading;
    },

    tickets() {
      return this.formData.tickets;
    },

    filteredTickets() {
      const { search, activeTab, tickets } = this;
      if (!search && activeTab === 'all') return tickets;

      let ticketsOnActiveTab = [];
      if (activeTab === 'all') {
        ticketsOnActiveTab = [...tickets];
      } else if (activeTab === 'favorite') {
        ticketsOnActiveTab = tickets.filter(ticket => ticket.favorite_stamp);
      } else if (this.isProduct) {
        ticketsOnActiveTab = tickets.filter(ticket => ticket.type.includes(activeTab));
      } else {
        ticketsOnActiveTab = tickets.filter(ticket => ticket.available_class_type === activeTab);
      }

      return !search
        ? ticketsOnActiveTab
        : ticketsOnActiveTab.filter(ticket => {
            return _.includes(ticket.title.toLowerCase(), search.toLowerCase());
          });
    },
  },

  created() {
    this.productsTabs();
  },

  methods: {
    productsTabs() {
      if (this.isProduct) {
        this.tabs = [
          { value: 'all', label: '전체' },
          { value: 'S', label: '판매' },
          { value: 'R', label: '대여' },
          { value: 'favorite', label: '즐겨찾기' },
        ];
      } else {
        this.tabs = [
          { value: 'all', label: '전체' },
          { value: 'P', label: '프라이빗' },
          { value: 'G', label: '그룹' },
          { value: 'favorite', label: '즐겨찾기' },
        ];
      }
    },

    handleChangeTab(tabName) {
      this.activeTab = tabName;
    },

    /** 수강권 클릭시: 해당 수강권이 선택되고 다음단계로 */
    handleClickCard(ticketId) {
      const ticket = this.tickets.find(({ id }) => id === ticketId);
      this.$store.commit('ticketIssue/SET_VALUES', {
        currentStep: 2,
        selectedTicket: ticket,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-ticket {
  &__search {
    padding: 16px;
  }

  &__list {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 16px;
    height: 420px;
    overflow: auto;
    padding: 16px;
  }
}

@media (min-width: 568px) {
  .select-ticket__list {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
