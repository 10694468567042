<template>
  <div class="sales-summary">
    <div class="point-container">
      <el-card shadow="never" class="card" v-for="summary in summaryItems" :key="summary.name">
        <div class="card-body">
          <div class="left">
            <img v-if="summary.name === 'reward'" src="@assets/images/02_icons/icon_reward_point.svg" />
            <img v-else-if="summary.name === 'deduct'" src="@assets/images/02_icons/icon_deduct_point.svg" />
            <img v-else-if="summary.name === 'destruction'" src="@assets/images/02_icons/icon_destruction_point.svg" />
            <img v-else-if="summary.name === 'refundReward'" src="@assets/images/02_icons/icon_refund_reward_point.svg" />
            <img v-else-if="summary.name === 'refundPaid'" src="@assets/images/02_icons/icon_refund_paid_point.svg" />
            <img v-else src="@assets/images/02_icons/icon_current_point.svg" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ summary.label }}</span>
              <el-tooltip v-if="!!summary.info" effect="light" placement="right" :content="summary.info">
                <img src="@/assets/images/icons/sales_tooltip.svg" alt="tooltip" />
              </el-tooltip>
            </div>
            <div class="bottom">
              <div class="count">{{ summary.count }}<span v-if="summary.name !== 'current'" class="small">건</span></div>
              <div class="point">{{ formatPoint(summary) }}<span class="small">P</span></div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    summaryItems: Array,
    name: String,
    value: String,
  },

  methods: {
    formatPoint({ name, point }) {
      const isRefundPaid = name === 'refundPaid';
      const summaryPoint = isRefundPaid ? Math.abs(point) : point;
      return this.$filters.comma(summaryPoint);
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-summary {
  flex: 1;
  * {
    color: #545454;
  }
}

.point-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;

  @include mq-max(968px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  border: 1px solid #dcdfe6;
  border-radius: 8px;

  /deep/ .el-card__body {
    width: 100%;
    height: 100%;
    padding: 16px;
  }

  .card-body {
    @include flex;
    gap: 8px;
    width: 100%;
    height: 100%;

    .left > img {
      margin-top: 2px;
    }

    .right {
      @include flex(column);
      justify-content: space-between;
      flex: 1;

      .top {
        height: 36px;
        @include flex(row, center);
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .count {
          font-size: 18px;

          .small {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .point {
          font-size: 20px;

          .small {
            font-size: 18px;
          }
        }

        .count,
        .point {
          font-weight: 700;
          line-height: 26px;
        }

        .small {
          line-height: 20px;
        }
      }
    }
  }
}
</style>
