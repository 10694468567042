<template>
  <div class="form-block">
    <div class="form-block__index">
      <h5>
        <span v-if="!unNumbering">{{ `00${index}`.slice(-2) }}</span>
        <span v-if="required" class="required">*</span>
      </h5>
    </div>
    <div class="form-block__content" :class="noBtmBorder ? 'no-border' : ''">
      <div class="form-block__label">
        <div class="form-block__label__title">
          <h5 v-html="label" />
          <slot name="label-helper"></slot>
          <slot name="switch-toggle"></slot>
        </div>
        <div class="form-block__label__checkbox">
          <slot name="checkbox"></slot>
        </div>
      </div>
      <div v-if="inputInline" class="form-block__inputs-inline">
        <slot></slot>
      </div>
      <div v-else class="form-block__inputs" :class="{ flexRow }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    label: String,
    required: Boolean,
    flexRow: Boolean,
    inputInline: Boolean,
    unNumbering: Boolean,
    noBtmBorder: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.form-block {
  display: grid;
  grid-template-columns: 30px 1fr;
  max-width: 1440px;
  margin: auto;
  padding-top: 30px;

  &__index {
    width: 60px;

    h5 {
      opacity: 0.9;
      font-size: 15px;
      font-weight: bold;
      margin-right: 25px;
    }

    span.required {
      color: $color-danger;
    }
  }

  &__content {
    @include flex(column);
    border-bottom: 1px solid rgba(#000, 0.08);
    padding-bottom: 30px;
    &.no-border {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }

  &__label {
    display: grid;
    grid-template-columns: 1fr 210px;
    grid-gap: 24px;
    // align-items: center;
    margin-bottom: 12px;

    &__title {
      @include flex(row, center);

      h5 {
        font-size: 13px;
        margin-right: 15px;
      }
    }

    &__checkbox {
      @include flex(row, center, flex-end);
    }
  }

  &__inputs {
    @include flex(column);

    &.flexRow {
      @include flex(row);
      flex-wrap: wrap;
    }
  }
  &__inputs-inline {
    display: inline;
  }
}

@media (min-width: 768px) {
  .form-block {
    grid-template-columns: 60px 1fr;
  }
}
</style>
