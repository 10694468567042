<template>
  <auth-container
    title="비밀번호 재설정"
    :description="
      success
        ? '입력하신 아이디와 연결된 휴대전화 번호로 링크가 전송되었습니다.'
        : '아이디 입력 시 해당 계정과 연결된 휴대전화로 재설정 링크가 전송됩니다.'
    "
    footer_message="아이디를 잊으셨나요?"
    :footer_links="[{ path: '/find_id', label: '아이디 찾기' }]"
  >
    <auth-form :cancel_button="cancel_button" :submit_button="success ? null : submit_button">
      <auth-input v-if="!success" id="identity" type="text" placeholder="아이디 입력" v-model="identity" />
    </auth-form>
  </auth-container>
</template>

<script>
import AuthContainer from '@/components/Auth/Container';
import AuthForm from '@/components/Auth/Form';
import AuthInput from '@/components/Auth/Input';

export default {
  components: {
    AuthContainer,
    AuthForm,
    AuthInput,
  },

  data() {
    return {
      success: false,
      identity: '',
      cancel_button: {
        label: '로그인 화면으로',
        onClick: () => this.$router.push('/login'),
      },
    };
  },

  computed: {
    submit_button() {
      return {
        label: '비밀번호 변경 링크받기',
        disabled: this.identity === '',
        onClick: this.sendLink,
      };
    },
  },

  methods: {
    async sendLink() {
      try {
        await this.$api.auth.sendPasswordLink(this.identity);
        this.success = true;
      } catch (error) {
        this.$utils.notify.error(this, '전송실패!', `'${this.identity}'은(는) 존재하지 않는 아이디입니다.`);
      }
    },
  },
};
</script>
