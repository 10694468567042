import axios from '../axios';

export default {
  // 목록 가져오기
  getAll: params =>
    axios.get('/staff/booking', {
      params,
    }),

  // 예약
  create: (lecture_id, user_ticket_id) => {
    const data = {
      lecture_id,
      user_ticket_id,
    };
    return axios.post('/staff/booking', data);
  },

  // 일괄 예약
  // createBulk: data => axios.post('/staff/booking/lump', data),
  createBulk: data => axios.post('/v2/staff/booking', data),

  // 예약 대기
  createWaiting: (lecture_id, user_ticket_id) => {
    const data = {
      lecture_id,
      user_ticket_id,
    };
    return axios.post('/staff/booking/waiting', data);
  },

  // 예약 변경
  update: (bookingId, status) => {
    return axios.patch(`/staff/booking/${bookingId}/status`, {
      status,
    });
  },

  // 예약 취소
  delete: id => axios.delete(`/staff/booking/${id}`),

  // 일괄 예약 취소
  deleteBulk: ids =>
    axios.post('/staff/booking/all', {
      bookings: ids,
    }),

  // 취소 삭제
  cancelDelete: id => axios.delete(`/v2/staff/booking/${id}`),

  // 일괄 정지 기능과 예약이 겹치는지 확인
  checkBookings: data => axios.post('/v2/staff/userTickets/check/bulkHolding', data),

  /** 일일/주간/월간 예약 제한 횟수 검증 */
  checkBookingCount: ({ lecture_ids, user_ticket_id }) =>
    axios.get('/v2/staff/userTickets/check', { params: { lecture_ids, user_ticket_id } }),
};
