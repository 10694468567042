<template>
  <section class="holding-detail">
    <form class="holding-detail__form">
      <div class="holding-detail__form__element start_date">
        <label>{{ title }} 정지 시작일</label>
        <el-date-picker
          placeholder="정지 시작일"
          type="date"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          :picker-options="startDatePickerOptions"
          v-model="holdingDetail.start_on"
          :disabled="disabledStartDate"
          :clearable="false"
        />
      </div>

      <div class="holding-detail__form__element end_date">
        <label>{{ title }} 정지 종료일</label>
        <el-date-picker
          placeholder="정지 종료일"
          type="date"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          :picker-options="endDatePickerOptions"
          v-model="holdingDetail.end_on"
          :disabled="disabledEndDate"
          :clearable="false"
        />
      </div>

      <div class="holding-detail__form__buttons">
        <el-button v-loading="saving" @click="handleClickOK" type="primary" size="small" :disabled="holdingButtonDisabled">
          {{ holdingDetail.id ? '기간 변경' : `${title} 정지` }}
        </el-button>
        <el-button
          v-if="holdingDetail.id"
          type="danger"
          size="small"
          :disabled="holdingButtonDisabled"
          @click="handleClickCancel(holdingDetail.id)"
          >취소</el-button
        >
      </div>

      <div class="holding-detail__form__checkbox">
        <el-checkbox v-model="autoCalculation" :disabled="disabledCheckExpireDate">만료일 자동 계산</el-checkbox>
        <p>체크하면 정지 기간 만큼 {{ title }} 만료일이 자동 연장됩니다.</p>
      </div>
    </form>

    <ul class="holding-detail__holding-list">
      <li v-for="holding in holdingHistory" :key="holding.id">
        <div class="holding-detail__holding-list__start-on">
          <i class="el-icon-date"></i>
          <span>{{ holding.start_on | date }}</span>
        </div>
        <div class="holding-detail__holding-list__end-on">
          <i class="el-icon-date"></i>
          <span>{{ holding.end_on | date }}</span>
        </div>
        <div class="holding-detail__holding-list__duration">
          <el-tag type="info" size="mini">{{ `${$utils.getDaysDiff(holding.end_on, holding.start_on) + 1}일 정지` }}</el-tag>
          <el-tag v-if="isFuture(holding)" size="mini">예정</el-tag>
          <el-tag v-if="holding.auto_calculation" size="mini">자동 계산</el-tag>
          <el-tooltip v-if="isFuture(holding)" effect="light" content="취소" placement="top">
            <el-button
              class="holding-detail__holding-list__cancel-button"
              size="mini"
              icon="el-icon-close"
              circle
              @click="handleClickCancel(holding.id)"
            ></el-button>
          </el-tooltip>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { BOOKING_STATUS_ARRAY } from '@constants';

const HOLDING_DETAIL_DEFAULT = {
  id: null,
  user_ticket_id: null,
  start_on: '',
  end_on: '',
  auto_calculation: true,
};

export default {
  props: {
    title: String,
  },

  data() {
    return {
      holdingDetail: { ...HOLDING_DETAIL_DEFAULT },
      holdingHistory: [],
      currentHoldingDate: [],
      saving: false,
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },

    userTicket() {
      return _.get(this.formData, 'userTicket');
    },

    userTicketBookings() {
      return _.get(this.formData, 'userTicketBookings');
    },

    isTicketDeleted() {
      return !!_.get(this.userTicket, 'ticket.deleted_at');
    },

    ticketUsable() {
      return _.get(this.userTicket, 'ticket_usable');
    },

    startDatePickerOptions() {
      const { moment, userTicket } = this;
      return {
        disabledDate(time) {
          return time.getTime() < moment(userTicket.availability_start_at).format('x');
        },
      };
    },

    endDatePickerOptions() {
      const { moment, holdingDetail } = this;
      return {
        disabledDate(time) {
          return moment(time).isBefore(holdingDetail.start_on);
        },
      };
    },

    disabledStartDate() {
      return !!this.holdingDetail.id || !this.ticketUsable;
    },

    disabledEndDate() {
      return !this.holdingDetail.start_on || !this.ticketUsable;
    },

    disabledCheckExpireDate() {
      return this.userTicket.is_holding || !this.ticketUsable;
    },

    holdingButtonDisabled() {
      const valuesInvalid = !(this.holdingDetail.start_on.length > 0) || !(this.holdingDetail.end_on.length > 0);

      return valuesInvalid || !this.canUpdateMembersTicket || this.isTicketDeleted || this.saving || !this.ticketUsable;
    },

    autoCalculation: {
      get() {
        return !!this.holdingDetail.auto_calculation;
      },

      set(value) {
        this.holdingDetail.auto_calculation = value ? 1 : 0;
      },
    },

    selectedMembers() {
      return this.$store.getters['members/selectedMembers'];
    },

    selectedMemberIds() {
      return this.$store.getters['members/selectedMemberIds'];
    },
  },

  watch: {
    userTicket: {
      handler() {
        this.setInitialData();
      },
      immediate: true,
    },
  },

  methods: {
    isFuture(holding) {
      return this.moment(holding.start_on)
        .startOf('day')
        .isAfter(this.moment().startOf('day'));
    },

    setInitialData() {
      const { id, is_holding, holdings } = this.userTicket;

      if (is_holding) {
        const today = this.moment().format('YYYY-MM-DD');
        const { currentHolding, holdingHistory } = holdings.reduce(
          (result, holding) => {
            const { start_on, end_on } = holding;
            if (this.moment(today).isBetween(start_on, end_on, null, '[]')) {
              result.currentHolding = holding;
            } else {
              result.holdingHistory.push(holding);
            }
            return result;
          },
          { currentHolding: null, holdingHistory: [] },
        );

        this.holdingDetail = { ...currentHolding };
        this.currentHoldingDate = { ...currentHolding };
        this.holdingHistory = _.sortBy(holdingHistory, ['start_on', 'end_on']).reverse();
      } else {
        this.holdingDetail = {
          ...HOLDING_DETAIL_DEFAULT,
          user_ticket_id: id,
        };
        this.holdingHistory = _.sortBy(holdings, ['start_on', 'end_on']).reverse();
      }
    },

    async handleClickOK(e) {
      this.saving = true;

      const confirmed = await this.checkBookingsInHoldingPeriod();
      if (!confirmed) {
        this.holdingDetail.end_on = this.currentHoldingDate.end_on || '';
        return (this.saving = false);
      }

      try {
        const { userTicket } = this.formData;
        const { moment, holdingDetail } = this;

        const checkHoldingData = userTicket.holdings.map(holding => {
          const standardStartOn = moment(holdingDetail.start_on, 'YYYY-MM-DD');
          const standardEndOn = moment(holdingDetail.end_on, 'YYYY-MM-DD');

          let userStartOn = moment(holding.start_on, 'YYYY-MM-DD');
          let userEndOn = moment(holding.end_on, 'YYYY-MM-DD');

          if (userStartOn < standardStartOn && userEndOn < standardStartOn) return true;
          else if (standardEndOn < userStartOn && standardEndOn < userEndOn) return true;
          else if (userStartOn < moment()) return false;
          return false;
        });

        const checkHolding = checkHoldingData.every(data => data === true);

        if (e.target.innerText !== '기간 변경' && !checkHolding) {
          this.$utils.notify.error(this, '오류', `해당 기간에 이미 정지 상태인 ${this.title}이 존재합니다.`);
          return;
        }

        let message = `${this.title}을 정지하였습니다.`;

        if (!this.holdingDetail.id) {
          await this.$api.userTicket.holding.create(userTicket.id, this.holdingDetail);
        } else {
          message = `${this.title} 정지기간을 변경하였습니다.`;
          await this.$api.userTicket.holding.update(userTicket.id, this.holdingDetail);
        }

        this.holdingDetail.start_on = '';
        this.holdingDetail.end_on = '';

        this.$utils.notify.success(this, '성공', message);
        this.$store.dispatch('ticketEdit/getUserTicket', userTicket.id);

        this.$store.dispatch('members/getAfterStopSelectedMembers', this.selectedMembers);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.saving = false;
      }
    },

    async handleClickCancel(holdingId) {
      this.saving = true;
      try {
        const endOn = this.moment(this.userTicket.expire_at)
          .add(1, 'day')
          .format('YYYY-MM-DD');
        const diffDate = this.$utils.getDaysDiff(this.currentHoldingDate.end_on, this.currentHoldingDate.start_on) + 1;
        const end = this.moment(this.userTicket.expire_at)
          .subtract(diffDate, 'day')
          .format('YYYY-MM-DD');
        const bookingsInPeriod = this.userTicketBookings.filter(booking => {
          const date = this.moment(booking.lecture.start_on);
          const isBetween = date.isBetween(end, endOn, null, '[]');
          const isBooking = BOOKING_STATUS_ARRAY.BOOKED.includes(booking.status);
          return isBetween && isBooking;
        });

        let confirmCancel = true;

        if (bookingsInPeriod.length) {
          const message = `
            정지 취소시 수강권 이용 종료일 이후 모든 예약은 자동으로 <b>취소</b>됩니다.<br>
            수강권 이용 종료일 이후 <b>${bookingsInPeriod.length}</b> 건의 예약/예약대기가 존재합니다.<br>
            수강권 정지 취소하시겠습니까?
        `;
          confirmCancel = await this.$confirm(message, `${this.title} 정지 취소`, {
            dangerouslyUseHTMLString: true,
          })
            .then(() => true)
            .catch(() => false);
        }

        if (!confirmCancel) return;

        this.holdingDetail.start_on = '';
        this.holdingDetail.end_on = '';

        const { userTicket } = this.formData;

        await this.$api.userTicket.holding.delete(userTicket.id, holdingId);

        this.$utils.notify.success(this, '성공', `${this.title} 정지를 취소하였습니다.`);
        this.$store.dispatch('ticketEdit/getUserTicket', userTicket.id);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.selectedMembers.length && this.$store.dispatch('members/getSelectedMembers', this.selectedMemberIds);
        this.saving = false;
      }
    },

    async checkBookingsInHoldingPeriod() {
      const { moment, holdingDetail, currentHoldingDate, userTicket } = this;
      /** 정지 기간 내에 예약내역 (booked, booking_confirmed) 추출 */
      let startOn = moment(holdingDetail.start_on).startOf('day');
      let endOn = moment(holdingDetail.end_on).endOf('day');

      const params = {
        userTicketId: userTicket.id,
        startOn: startOn.format('YYYY-MM-DD'),
        endOn: endOn.format('YYYY-MM-DD'),
      };

      /** 해당 기간에 예약/예약대기/취소가 몇 건 있는지 불러오는 api */
      const { data: bookingCountList } = await this.$api.userTicket.getHoldingOverlapBookingCount(params);
      const currentBookingTypeList = bookingCountList.filter(
        ({ status }) => status === 'booked' || status === 'booking_waiting' || status === 'booking_confirmed',
      );

      /** 정지 기간을 앞 당길 경우 수강권 유효 기간 초과된 예약된 수업 체크 */
      if (currentHoldingDate.end_on && moment(endOn) < moment(currentHoldingDate.end_on)) {
        const diffDate = this.$utils.getDaysDiff(currentHoldingDate.end_on, endOn);
        startOn = moment(this.userTicket.expire_at)
          .subtract(diffDate - 1, 'day')
          .startOf('day');
        endOn = moment(this.userTicket.expire_at).endOf('day');
      }

      /** 예약내역이 존재하지 않을 경우 다음단계 진행 */
      if (!currentBookingTypeList.length) return true;

      const countList = currentBookingTypeList.map(booking => booking.count);
      const currentCount = _.sum(countList);

      /** 예약내역이 존재할 경우 확인 후 진행 */
      const message = `
          <h4 style="line-height: 1.5;">
            설정한 정지 기간 내에 ${currentCount}건의 예약/예약대기가 존재합니다.<br>
            수강권 ${this.currentHoldingDate.end_on ? '기간 변경을' : '정지를'} 설정하시겠습니까?
          </h4>
          <p style="margin-top: 1em; color: #f56c6c; font-size: 13px; line-height: 1.5;">
            1. 정지 설정시 기간 내의 모든 예약은 자동으로 취소됩니다.<br>
            2. 취소된 예약은 정지 설정을 해제해도 복구되지 않습니다.
          </p>
        `;

      return await this.$confirm(message, `수강권 ${this.currentHoldingDate.end_on ? '기간 변경' : '정지'}`, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);
    },
  },
};
</script>

<style lang="scss" scoped>
.holding-detail {
  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: 140px 140px 1fr;
    grid-template-areas:
      'start_date end_date buttons'
      'checkbox checkbox checkbox';
    grid-gap: 16px 8px;
    padding: 20px;
    border-bottom: 1px solid rgba(#000, 0.1);

    &__element {
      @include flex(column);

      label {
        font-size: 12px;
        font-weight: 400;
      }
    }

    &__element.start_date {
      grid-area: start_date;
    }
    &__element.end_date {
      grid-area: end_date;
    }

    &__buttons {
      @include flex(row, center, flex-start);
      grid-area: buttons;
    }

    &__checkbox {
      grid-area: checkbox;

      p {
        color: $link-color;
        font-size: 12px;
        padding: 8px 0 0 24px;
      }
    }
  }

  &__holding-list {
    flex: 1;
    width: 100%;
    overflow: auto;

    li {
      display: grid;
      grid-template-columns: 140px 140px 1fr;
      grid-gap: 16px 8px;
      border-radius: 2px;
      border: 1px solid $gray-200;
      padding: 12px 20px;
      margin-top: 12px;
    }

    &__start-on,
    &__end-on {
      width: 160px;

      span {
        margin-left: 10px;
      }
    }

    &__duration {
      span {
        margin-right: 8px;
      }
    }

    &__cancel-button {
      border: none;
      padding: 5px;
    }
  }
}
</style>
