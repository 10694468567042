import api from '@api';
// import utils from '@utils';

export const state = {
  members: [],
  tickets: [],
  lectures: [],
  finalMessage: '',
};

export const getters = {
  members: state => state.members,
  tickets: state => state.tickets,
  lectures: state => state.lectures,
  finalMessage: state => state.finalMessage,
};

export const mutations = {
  SET_MEMBERS(state, members) {
    members.map(el => {
      let newOne = el.mobile;
      let result = newOne.slice(0, 4) + '**' + newOne.slice(6);
      el.mobile = result;
    });
    state.members = members;
  },

  SET_TICKETS(state, tickets) {
    state.tickets = tickets;
  },

  SET_LECTURES(state, lectures) {
    if (lectures.bookings.length) {
      //bookings 에서 상태가 결석, 취소, 노쇼일경우 리스트에서 제외
      let initData = lectures.bookings;
      const result = initData.filter(item => {
        return item.status !== 'absence' && item.status !== 'noshow' && item.status !== 'cancel';
      });
      lectures.bookings = result;
    }
    state.lectures = lectures;
  },

  SET_CHECKINFINAL(state, res) {
    state.finalMessage = res;
  },
};

export const actions = {
  async getMembers({ commit }, mobileNumber) {
    try {
      const res = await api.checkin.checkMember(mobileNumber);
      commit('SET_MEMBERS', res.data);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  async getTickets({ commit }, id) {
    try {
      const res = await api.checkin.checkTickets(id);
      commit('SET_TICKETS', res.data.userTickets);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  async getLectures({ commit }, idSet) {
    try {
      const res = await api.checkin.checkLectures(idSet);
      commit('SET_LECTURES', res.data);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  async checkinLecture({ commit }, idSet) {
    try {
      const res = await api.checkin.checkinFinal(idSet);
      commit('SET_CHECKINFINAL', res.data);
      if (res.status === 200) return { success: true };

      return;
    } catch (error) {
      return { success: false, error };
    }
  },
};
