import api from '@api';

export const state = {
  type: 'message', // 메시지 > 문자 메시지 > 문자보관함
  templates: [],
  formTextForTemplate: {
    id: 0,
    title: '',
    template_title: '',
    template: '',
    targets: [], // 선택된 회원
    member_filter: {}, // 회원 선택 > 선택된 필터
    send_on: '', // 메시지 예약
    isAdAdded: false,
  },
  pagination: {
    limit: 12,
    page: 1,
    total: 0,
  },
  isSent: false,
  checkedIdsForDelete: [],
};

export const getters = {
  templates: state => state.templates,
  formText: state => state.formTextForTemplate,
  checkedIdsForDelete: state => state.checkedIdsForDelete,
  isSent: state => state.isSent,
  pagination: state => state.pagination,
};

export const mutations = {
  SET_TEMPLATES(state, data) {
    state.templates = data;
  },
  SET_FORM_TEXT(state, data) {
    state.formTextForTemplate = data;
  },
  SET_CHECK_DELETE_IDS(state, ids) {
    state.checkedIdsForDelete = ids;
  },
  SET_IS_SENT(state, bool) {
    state.isSent = bool;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  },
};

export const actions = {
  async getAllTemplates({ state, commit }) {
    try {
      const params = {
        type: state.type,
        limit: state.pagination.limit,
        page: state.pagination.page,
      };
      const { data } = await api.template.message.getAll({ params });
      commit('SET_TEMPLATES', data.data);
      commit('SET_PAGINATION', { ...state.pagination, total: data.total });
      return { success: true };
    } catch (error) {
      commit('SET_TEMPLATES', []);
      return { success: false, error };
    }
  },

  async postTemplate({ state }, postData) {
    try {
      const body = {
        type: state.type,
        title: postData.template_title,
        template: postData.template,
      };
      await api.template.message.post(body);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  async deleteTemplate({ state, commit }) {
    try {
      await state.checkedIdsForDelete.reduce(async (acc, id) => {
        await api.template.message.delete(id);
      }, 0);
      commit('SET_CHECK_DELETE_IDS', []);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  setFormText({ state, commit }, newValue) {
    return commit('SET_FORM_TEXT', { ...state.formTextForTemplate, ...newValue });
  },

  updateCheckedIdsForDelete({ commit }, ids) {
    return commit('SET_CHECK_DELETE_IDS', ids);
  },

  updateSent({ commit }, bool) {
    return commit('SET_IS_SENT', bool);
  },

  updatePage({ state, commit }, page) {
    return commit('SET_PAGINATION', { ...state.pagination, page: page });
  },
};
