<template>
  <div class="wrap-attach-img" v-loading="loading">
    <div>
      <PlainButton type="info" round @click="handleClickAttachImage" :disabled="disabled">사진 추가</PlainButton>
    </div>
    <div>
      <input ref="attachImage" type="file" accept=".jpg, .jpeg, .png" @change="handleChangeImage" />
      <div :class="disabled ? 'area-img area-img_disabled' : 'area-img'">
        <img v-if="setImg" ref="showImg" :src="getImageUrl(setImg, 0, 'bg-checkin.jpg')" />
        <img v-else ref="showImg" />
        <CircleButton @click="handleClickRemoveImage()" size="small" :disabled="disabled">
          <i class="el-icon-close"></i>
        </CircleButton>
      </div>
    </div>
    <div :class="disabled ? 'info-text info-text_disabled' : 'info-text'">
      이미지 용량은 최대 3MB를 넘지 않아야 합니다. <br />이미지 크기는 1024 X 768 이상을 권장합니다.
    </div>
  </div>
</template>

<script>
import { ImageCompressor } from 'image-compressor';

export default {
  ImageCompressor,
  props: {
    disabled: Boolean,
    setImg: {},
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    getImageUrl(path, size, defaultImg) {
      return this.$utils.getImagePath(path, 0, defaultImg);
    },

    /** 1 사진 추가 버튼 클릭시 */
    handleClickAttachImage() {
      if (this.loading) return;
      this.$refs.attachImage.click();
    },

    /** 2 이미지 추가 */
    async handleChangeImage(e) {
      if (this.loading) return;
      this.loading = true;
      const file = _.get(e, 'target.files[0]');

      const fileExtension = file.name.split('.');
      const extensionLength = fileExtension[fileExtension.length - 1];
      const checkExtension = extensionLength === 'jpeg' || extensionLength === 'jpg' || extensionLength === 'png';
      if (!checkExtension) {
        this.loading = false;
        return this.$utils.notify.error(this, '오류', 'jpeg, jpg, png 확장자만 등록 가능합니다.');
      }

      if (file.size > 3 * 1024 * 1024) {
        this.loading = false;
        return this.$utils.notify.error(this, '오류', '이미지 사이즈는 3MB까지 가능합니다.');
      }
      const fileSrc = window.URL.createObjectURL(file);
      this.$refs['showImg'].src = fileSrc;

      this.sendImage(file);
      e.target.value = null;
    },

    /** 3 이미지 최종 api call*/
    async sendImage(compressedSrc) {
      if (compressedSrc) {
        try {
          const formData = new FormData();
          formData.append('files[]', compressedSrc);
          const res = await this.$api.attachment.upload(formData);
          this.$emit('uploadImg', res.data[0].id);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          this.$refs['showImg'].src = '';
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    /** 이미지 제거 */
    handleClickRemoveImage() {
      this.$refs['showImg'].src = require('@/assets/images/bg-checkin.jpg');
      this.$emit('removeImg');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-attach-img {
  padding-top: 10px;
  display: grid;
  grid-template-columns: 90px 190px 1fr;
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .area-img {
    position: relative;
    display: inline-block;
    &_disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    img {
      width: 160px;
      height: 120px;
    }
    button {
      background: $gray-500;
      color: #fff;
      font-size: 16px;
      position: absolute;
      top: 0px;
      right: -12px;
      &:hover {
        background: $gray-200;
        color: $charcoal-grey;
      }
    }
  }
  .info-text {
    color: $gray-700;
    font-size: 12px;
    &_disabled {
      cursor: not-allowed;
      color: $gray-500;
    }
  }
}
</style>
