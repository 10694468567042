<template>
  <div class="bookable-tickets" v-loading="loading">
    <div class="bookable-tickets__title">
      <h4>수강회원</h4>

      <PlainButton v-if="!hideBookableTickets" @click="handleGetBookableList" round
        >예약가능 회원 {{ showBookableTickets ? '숨김' : '펼침' }}</PlainButton
      >
    </div>

    <TextInput
      v-if="!hideBookableTickets"
      class="bookable-tickets__search"
      v-model="keyword"
      placeholder="회원명, 휴대폰 번호 검색"
      prefix-icon="el-icon-search"
      @focus="handleGetBookableList"
      @input="
        keyword => {
          handleChangeKeyword(keyword);
        }
      "
      round
    />

    <div class="bookable-tickets__list" v-if="showBookableTickets && !hideBookableTickets">
      <div class="bookable-tickets__btn-wrapper">
        <h5>예약 가능한 회원 ({{ total | comma }}명)</h5>

        <FilterResetButton @click="setBookableListInit" labelTooltip="새로고침" />
      </div>

      <div v-if="!hideBookableTickets && total > 0">
        <ul>
          <ListItem
            v-for="userTicket in bookableTickets"
            :key="userTicket.id"
            :user-ticket="userTicket"
            :member="userTicket.member"
            @click-booking="handleClickBooking"
            :booking-button-disabled="bookingButtonDisabled"
          />
        </ul>

        <el-pagination
          class="bookable-tickets__pagination"
          @current-change="dispatchGetBookableTicketsList"
          :current-page.sync="page"
          :page-size="limit"
          :page-sizes="[10, 15, 20, 50]"
          @size-change="limit => handleFilterChange({ limit })"
          layout="prev, pager, next, sizes"
          :total="total"
        />
      </div>
      <p v-else class="bookable-tickets__list__no-data">
        예약 가능한 회원이 없습니다.
      </p>
    </div>
  </div>
</template>

<script>
import ListItem from './BookableMembersListItem';
import { BOOKING_STATUS } from '@constants';

export default {
  components: { ListItem },

  data() {
    return {
      showBookableTickets: false,
      keyword: '',
      bookingButtonDisabled: false,
      pressedESC: false,
    };
  },

  computed: {
    lecture() {
      return this.$store.getters['lecture/lecture'];
    },

    bookableTickets() {
      return this.$store.getters['lecture/bookableTickets'];
    },

    total() {
      return this.$store.getters['lecture/total'];
    },

    pagination() {
      return this.$store.getters['lecture/pagination'];
    },

    loading() {
      return this.$store.getters['lecture/bookableTicketsLoading'];
    },

    page: {
      get() {
        return this.pagination.page;
      },
      set(page) {
        this.setPagination({ page });
      },
    },

    limit: {
      get() {
        return this.pagination.limit;
      },
      set(limit) {
        this.setPagination({ limit });
      },
    },

    searchKeyword() {
      return this.$store.getters['lecture/searchKeyword'];
    },

    hideBookableTickets() {
      const courseType = _.get(this.lecture, 'course.type');
      const instructorId = _.get(this.lecture, 'instructor_id');
      const { current_trainee_count, max_trainee } = this.lecture;
      const isPrivate = courseType === 'P';
      const isFull = current_trainee_count >= max_trainee;
      return (isPrivate && isFull) || !this.canUpdateLectureBookings(courseType, instructorId);
    },

    bookingWaitings() {
      const bookings = _.get(this.lecture, 'bookings', []);
      return bookings.filter(({ status }) => status === BOOKING_STATUS.WAITING);
    },
  },

  watch: {
    searchKeyword() {
      this.setPagination({ page: 1 });
      this.dispatchGetBookableTicketsList();
      this.showBookableTickets = true;
    },

    bookableTickets() {
      // 마지막 페이지에서 페이지 사이즈가 작은 곳에서 큰 곳으로 옮길 때
      if (!this.bookableTickets.length && this.page > 1) {
        this.dispatchGetBookableTicketsList();
      }
    },
  },

  created() {
    window.addEventListener('keydown', this.pressESC);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.pressESC);
  },

  methods: {
    pressESC(e) {
      if (e.key === 'Escape') {
        this.pressedESC = true;
      }
    },

    async loadData() {
      await this.$store.dispatch('lecture/getLecture', this.lecture.id);
      await this.dispatchGetBookableTicketsList();
    },

    toggleBookableList(event) {
      if (event.type === 'click') {
        this.showBookableTickets = !this.showBookableTickets;
      } else {
        this.showBookableTickets = true;
      }
    },

    handleGetBookableList(event) {
      if (this.searchKeyword && !this.total) {
        // 검색 결과 없을 때
        return;
      } else if (!this.total) {
        this.dispatchGetBookableTicketsList();
      }

      this.toggleBookableList(event);
    },

    dispatchGetBookableTicketsList() {
      this.$store.dispatch('lecture/getBookableTickets', this.lecture.start_on);
    },

    handleFilterChange({ limit }) {
      this.setPagination({ limit });
      this.dispatchGetBookableTicketsList();
    },

    setBookableListInit() {
      this.keyword = '';
      this.handleChangeKeyword('');
      this.setPagination({ page: 1 });
      this.dispatchGetBookableTicketsList();
    },

    setPagination(values) {
      this.$store.commit('lecture/SET_PAGINATION', values);
    },

    handleChangeKeyword() {
      this.$store.commit('lecture/SET_SEARCH_MEMBER', this.keyword);
    },

    async handleClickBooking(userTicket) {
      const { id, current_trainee_count, max_trainee, end_on } = this.lecture;

      let action = '예약';
      let proceed = false;

      /** 수강 정원 미달시: 바로 예약 진행 */
      if (current_trainee_count < max_trainee) {
        proceed = true;

        /** 수강 정원 초과시 */
      } else {
        const title = '수강 정원 초과';
        let message = '수강 정원이 마감되었습니다.<br>초과 예약을 하시겠습니까?';

        /** 종료된 수업: 초과 예약 여부만 확인 */
        if (this.moment().isAfter(end_on)) {
          /** 초과 예약 진행 if true */
          proceed = await this.$confirm(message, title, {
            dangerouslyUseHTMLString: true,
          })
            .then(() => true)
            .catch(() => false);

          /** 종료되지 않은 수업: 초과/대기 예약 중 선택 */
        } else {
          this.pressedESC = false;
          message += ' 대기 예약을 하시겠습니까?';
          proceed = await this.$confirm(message, title, {
            confirmButtonText: '대기 예약',
            cancelButtonText: '초과 예약',
            dangerouslyUseHTMLString: true,
            distinguishCancelAndClose: true,
          })
            .then(() => {
              /** 예약 대기 */
              action = '예약 대기';
              return true;
            })
            .catch(action => {
              /** 초과 예약 */
              if (action === 'cancel' && !this.pressedESC) return true;
              return false;
            });
          this.pressedESC = false;
        }
      }

      /** 일일/주간 예약 가능 횟수 확인 */
      proceed = proceed && (await this.checkBookingCountExceeds(userTicket, action === '예약 대기'));

      if (proceed) {
        this.bookingButtonDisabled = true;
        try {
          if (action === '예약') {
            await this.$api.booking.create(id, userTicket.id);
          } else if (action === '예약 대기') {
            await this.$api.booking.createWaiting(id, userTicket.id);
          }
          this.$utils.notify.success(this, '확인', `${_.get(userTicket, 'member.name')}님 ${action}되었습니다.`);
          if (this.bookableTickets.length === 1 && !this.bookableTickets[0].is_shared) {
            this.keyword = '';
            this.handleChangeKeyword('');
          }
          this.loadData();
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.bookingButtonDisabled = false;
        }
      }
    },

    /** 일일/주간 예약 가능횟수 확인 */
    async checkBookingCountExceeds(userTicket, forWaiting = false) {
      const isDailyBookingLimited = _.get(this.studioPolicies, 'is_daily_booking_limited');
      const dailyBookingLimitedByTicket = _.get(this.studioPolicies, 'daily_booking_limit_by') === 'ticket';
      const dailyBookingLimit = _.get(this.studioPolicies, 'daily_booking_limit') || 0;
      const weeklyBookingLimit = _.get(userTicket, 'booking_limit_per_week') || 0;
      const monthlyBookingLimit = _.get(userTicket, 'booking_limit_per_month') || 0;
      const bookingWaitingLimit = _.get(this.lecture, 'waiting_trainee_limit') || 0;
      const dailyBookingCountOfTicket = _.get(userTicket, 'ticket_daily_booking_count') || 0;
      const dailyBookingCountOfMember = _.get(userTicket, 'member_daily_booking_count') || 0;
      const weeklyBookingCount = _.get(userTicket, 'ticket_week_booking_count') || 0;
      const monthlyBookingCount = _.get(userTicket, 'ticket_month_booking_count') || 0;

      const limit = {
        day: isDailyBookingLimited ? dailyBookingLimit : 0,
        week: weeklyBookingLimit,
        month: monthlyBookingLimit,
      };
      const count = {
        day: dailyBookingLimitedByTicket ? dailyBookingCountOfTicket : dailyBookingCountOfMember,
        week: weeklyBookingCount,
        month: monthlyBookingCount,
      };
      const label = {
        day: '일일 예약 가능 횟수',
        week: '주간 최대 예약 가능 횟수',
        month: '월간 최대 예약 가능 횟수',
      };

      const countExceeds = ['day', 'week', 'month']
        .filter(key => limit[key] > 0 && limit[key] <= count[key])
        .filter(value => {
          // 패밀리 수강권의 경우 주간/월간 횟수만 제한
          if (userTicket.is_shared) {
            if (value === 'week' || value === 'month') {
              return value;
            }
          } else {
            return value;
          }
        });

      const waitingLimitReached = bookingWaitingLimit > 0 && bookingWaitingLimit <= this.bookingWaitings.length;

      if (!countExceeds.length && !(forWaiting && waitingLimitReached)) {
        return true;
      }

      let title = '';
      let message = '';
      if (forWaiting) {
        if (waitingLimitReached) {
          message = `수업의 예약 대기 가능 인원(${bookingWaitingLimit}명)을 초과했습니다.<br>`;
        }

        if (countExceeds.length) {
          const text = countExceeds.reduce((textArray, key) => [...textArray, label[key]], []).join(', ');
          message += `예약 대기가 예약으로 변경될 때,<br>${text}를 초과하게 됩니다.<br>`;
        }

        title =
          waitingLimitReached && countExceeds.length
            ? '예약 대기 인원 / 예약 가능 횟수 초과'
            : waitingLimitReached
            ? '예약 대기 인원 초과'
            : countExceeds.length
            ? '예약 가능 횟수 초과'
            : undefined;
      } else {
        title = '예약 가능 횟수 초과';
        message = countExceeds.reduce((textArray, key) => [...textArray, `${label[key]}를 초과했습니다.`], []).join('<br>');
        message += '<br>';
      }
      message += '계속 하시겠습니까?';

      return await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);
    },
  },
};
</script>

<style lang="scss" scoped>
.bookable-tickets {
  margin-bottom: 12px;

  &__pagination {
    @include flex(row, center, center);
    margin: 30px 0 50px;

    /deep/ .el-pagination__sizes {
      margin: 0;

      /deep/ .el-input__inner {
        @extend %input-default;
        border-color: #dcdfe6;
        height: 36px;
      }

      /deep/ .el-input__suffix {
        transform: scale(1);
        padding-right: 8px;
      }
    }
  }

  &__title {
    @include flex(row, center, space-between);
    margin-bottom: 20px;

    h4 {
      color: #000;
      font-size: 17px;
      font-weight: 400;
    }
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
    * {
      margin-right: 10px;
    }
    .filter-reset-button {
      width: 28px;
      height: 28px;
    }
  }

  &__search {
    height: 38px;
    margin-bottom: 12px;
  }

  &__list {
    h5 {
      color: #000;
      font-weight: 400;
      padding: 12px 0;
    }

    &__no-data {
      font-size: 15px;
      opacity: 0.5;
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>
