<template>
  <footer>
    <ul class="policy-links">
      <li v-for="link in policy_links" :key="link.label">
        <a :href="link.href" :target="link.href === '#' ? '' : '_blank'" rel="noopener noreferrer">
          {{ link.label }}
        </a>
      </li>
    </ul>
    <p class="copyright">
      Copyright &copy; STUDIO MATE Corp. All rights reserved.
    </p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      policy_links: [
        {
          href: 'https://studiomate.kr/terms-of-service.html',
          label: '이용약관',
        },
        {
          href: 'https://studiomate.kr/privacy-policy.html',
          label: '개인정보 처리방침',
        },
        {
          href: '#',
          label: '운영정책',
        },
        {
          href: '#',
          label: '공지사항',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  border-top: 1px solid #eee;
  font-size: 12px;
  margin-top: 30px;
  padding: 30px 0;
  width: 100%;
}

.policy-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  li + li {
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid $gray-800;
  }
}

.copyright {
  text-align: center;
  margin-top: 12px;
}
</style>
