import moment from 'moment';

export default {
  /**
   * 천단위 콤마
   * @param {*} value
   */
  comma(value) {
    if (!value) return 0;
    value = String(value).replace(/,/g, '');
    return value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  },

  /**
   * 날짜 형식 (년-월-일)
   * @param {Date|String} value
   */
  date(value) {
    return value ? moment(value).format('YYYY. M. D.') : null;
  },

  dateWithWeekday(value) {
    return value ? moment(value).format('YYYY. M. D. (ddd)') : null;
  },

  dateWithWeekdayWithTime(value) {
    return value ? moment(value).format('YYYY. M. D. (ddd) HH:mm') : null;
  },

  /**
   * 시간 형식 (시:분)
   * @param {Date|String} value
   */
  time(value) {
    return value ? moment(value).format('HH:mm') : null;
  },

  /**
   * 날짜 시간 형식 (년.월.일. 시:분)
   * @param {Date|String} value
   */
  datetime(value) {
    return value ? moment(value).format('YYYY. M. D. HH:mm') : null;
  },

  /**
   * 날짜 형식 (YYYY년 MM월 DD일)
   * @param {Date|String} value
   */
  dateKorean(value) {
    return value ? moment(value).format('YYYY년 M월 D일') : null;
  },

  dateKoreanWithWeekday(value) {
    return value ? moment(value).format('YYYY년 M월 D일 (ddd)') : null;
  },

  /**
   * 휴대폰 번호 형식
   * @param {Number} value
   */
  mobileMask(value) {
    try {
      if (value.length >= 11) {
        return `${value.slice(0, 3)}-✳✳✳✳-✳✳✳✳`;
      } else if (value.length == 10) {
        return `${value.slice(0, 3)}-✳✳✳-✳✳✳✳`;
      } else {
        return value;
      }
    } catch (e) {
      return value;
    }
  },

  /**
   * 휴대폰 번호 형식 (000-0000-0000 | 000-000-0000)
   * @param {Number} value
   */
  mobile(value) {
    try {
      if (11 == value.length) {
        return value.slice(0, 3) + '-' + value.slice(3, 7) + '-' + value.slice(7);
      } else if (10 == value.length) {
        return value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
      } else {
        return value;
      }
    } catch (e) {
      return value;
    }
  },

  /**
   * 카드 마스킹
   * @param {Number} value
   */
  cardMask(value) {
    try {
      if (value.length === 16) {
        return `${value.slice(0, 4)}-${value.slice(4, 6)}✳✳-✳✳✳✳-${value.slice(12, 16)}`;
      } else if (value.length == 15) {
        return `${value.slice(0, 4)}-${value.slice(4, 6)}✳✳✳✳-✳${value.slice(12, 15)}`;
      } else {
        return value;
      }
    } catch (e) {
      return value;
    }
  },
};
