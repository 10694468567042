<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <input
      :id="id"
      :ref="id"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <p v-if="info_message || error_message" :class="{ info: info_message, error: error_message }">
      {{ error_message || info_message }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['id', 'label', 'type', 'placeholder', 'value', 'info_message', 'error_message'],
};
</script>

<style lang="scss" scoped>
div {
  width: 100%;
  margin: 4px 0;
}

label {
  margin-top: 18px;
}

input {
  width: 100%;
  min-height: 48px;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  background: #fff;
  font-size: 16px;
  line-height: 1;
  padding: 8px;
  transition: 0.15s;
  vertical-align: middle;

  &:hover {
    border: 1px solid rgba($steel, 0.5);
  }

  &:focus {
    border: 1px solid rgba($steel, 0.75);
  }
}

p {
  font-size: 12px;
  margin: 4px 0 0 4px;

  &.info {
    color: $color-main;
  }

  &.error {
    color: $color-danger;
  }
}
</style>
