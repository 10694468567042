<template>
  <el-dialog width="472px" :visible.sync="show" :before-close="onClose">
    <img slot="title" src="@/assets/images/icons/confirm_lecture_white.svg" alt="checking" />

    <div class="header-title" slot="title" v-for="(v, idx) in title" :key="idx">
      {{ setTitle[idx] }}
    </div>

    <div class="check-modal">
      <img src="@/assets/images/icons/check_modal_line.svg" alt="left-line" />
      <div>
        <h2 v-html="lectureInfo[0]" class="lecture-time" />
        <p v-html="lectureInfo[1]" class="lecture-title" />
      </div>
    </div>

    <div class="btn-wrap">
      <RoundButton class="btn-wrap__back" @click="onClose('info')">돌아가기</RoundButton>
      <RoundButton class="btn-wrap__checkin" @click.once="confirmCheckin(lectureInfo[2])">체크인하기</RoundButton>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    onClose: Function,
    title: String,
    lectureInfo: Array,
    confirmCheckin: Function,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    setTitle() {
      return this.title.split('<br>');
    },
  },

  methods: {
    finCheckin() {
      if (!this.loading) this.$emit('checkinConfirm');
      this.loading = true;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  border-radius: 16px;

  &__header {
    padding: 34px 30px;
    height: 262px;
    border-radius: 16px 16px 0 0;
    background-color: #164fae;
  }

  &__body {
    height: 262px;
    text-align: center;
  }

  .header-title {
    font-size: 35px;
    font-weight: bold;
    line-height: 1.3;
    color: #fff;
  }

  .check-modal {
    @include flex(row);
    gap: 15px;
    margin: 10px 10px 33px 10px;

    div {
      @include flex(column, flex-start);
      line-height: 1.4;

      p {
        font-size: 20px;
      }
    }
  }

  .btn-wrap {
    @include flex();
    gap: 12px;

    &__back {
      background-color: #f0f0f0;
      color: #818181;

      &:hover {
        background-color: #e2e2e2;
      }
    }

    &__checkin {
      &:hover {
        filter: brightness(1.1);
      }
    }

    button {
      margin: 0;
      width: 400px;
      height: 70px;
      border-radius: 16px;
      font-weight: 600;
    }
  }

  .el-icon-close {
    display: none;
  }
}
</style>
