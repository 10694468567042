<template>
  <MainLayout :padded="false">
    <div id="settings">
      <content-header
        :activeTab="$route.path"
        :breadcrumb="breadcrumb"
        :title="title"
        :multipleTitle="multipleTitleData"
        :contentMaxWidth="1280"
      />
      <section class="settings__body">
        <router-view @multipleTitle="checkMultipleTitle"></router-view>
      </section>
    </div>
  </MainLayout>
</template>

<script>
import { ROUTE_LABELS } from '@constants';

export default {
  data() {
    return {
      multipleTitleData: null,
    };
  },
  computed: {
    breadcrumb() {
      const routeName = this.$route.name;
      if (routeName === 'SettingsMain') return null;

      return {
        parent: { path: '/settings', label: '시설정보수정' },
        current_route_label: ROUTE_LABELS[routeName],
      };
    },

    title() {
      const routeName = this.$route.name;
      if (routeName === 'SettingsMain') return '시설 정보 수정';

      return `${ROUTE_LABELS[routeName]}`;
    },
  },

  async created() {
    this.$store.dispatch('studio/getStudio');
  },

  methods: {
    checkMultipleTitle: function(values) {
      this.multipleTitleData = values;
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings__body {
  padding: 10px 10px 60px;
  max-width: 1280px;
  margin: auto;

  @include mq(768px) {
    padding: 30px 30px 60px;
  }
}
</style>
