<template>
  <section class="content-header" :style="header_style">
    <div class="content-header__inner" :style="innerStyle">
      <breadcrumb v-if="breadcrumb" :parent="breadcrumb.parent" :current="breadcrumb.current_route_label" />
      <h3 v-if="title && !multipleTitle">{{ title }}</h3>
      <div v-if="isDiscriptionCondition" class="discription"><span>*</span> <span>필수항목입니다</span></div>
      <ul v-if="title && multipleTitle">
        <li
          v-for="el in multipleTitle"
          :key="el.path"
          :class="{
            'is-active': activeTab === el.path || activeTab === `${el.path}/info`,
          }"
          @click="handleClickTab(el.path)"
        >
          {{ el.title }}
        </li>
      </ul>
      <slot></slot>
    </div>
  </section>
</template>

<script>
import Breadcrumb from './Breadcrumb';
import { ROUTE_LABELS } from '@constants';

export default {
  components: { Breadcrumb },

  props: {
    height: Number,
    borderTop: String,
    breadcrumb: Object,
    title: String,
    multipleTitle: Array,
    contentMaxWidth: Number,
    activeTab: String,
  },
  computed: {
    header_style() {
      const style = {};
      if (this.height) style.height = `${this.height}px`;
      if (this.borderTop) style.borderTop = `3px solid #${this.borderTop}`;

      return style;
    },

    innerStyle() {
      const style = {};
      if (this.contentMaxWidth) style.maxWidth = `${this.contentMaxWidth}px`;

      return style;
    },

    isDiscriptionCondition() {
      switch (this.title) {
        case ROUTE_LABELS.SettingsOperations:
        case ROUTE_LABELS.SettingsPermissions:
        case ROUTE_LABELS.SettingsRooms:
        case ROUTE_LABELS.SettingsDivisions:
        case ROUTE_LABELS.SettingsUserGrades:
          return true;
        default:
          return false;
      }
    },
  },

  methods: {
    handleClickTab(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-header {
  background: #f8f9fa7f;
  border-bottom: rgba(0, 0, 0, 0.04) 1px solid;
  width: 100%;

  &__inner {
    @include flex(column, flex-start);
    padding: 24px;
    margin: auto;

    @include mq(768px) {
      padding: 36px;
    }

    h3 {
      font-size: 28px;
      font-weight: bold;
      margin: 12px 8px;
    }

    ul {
      display: flex;

      li {
        cursor: pointer;
        font-size: 28px;
        font-weight: 700;
        opacity: 0.2;
        transition: 0.15s;

        &:hover {
          opacity: 0.5;
        }

        &.is-active {
          opacity: 1;
        }

        &.disabled {
          cursor: not-allowed;

          &:hover {
            opacity: 0.2;
          }
        }

        & + li {
          margin-left: 12px;
        }
      }
    }
  }
}
.discription {
  color: $color-danger;
  margin-left: 8px;
  span:nth-child(2) {
    position: relative;
    top: -5px;
    font-size: 10px;
  }
}
</style>
