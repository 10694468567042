<template>
  <div class="histories-list-filter">
    <!-- 조회 방식 -->
    <div class="histories-list-filter__query-type">
      <el-select :value="rangeFilter.type" @change="changeRangeFilterType">
        <el-option v-for="item in rangeTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </div>

    <!-- 날짜별 조회 -->
    <div class="histories-list-filter__date" v-show="rangeFilter.type === 'date'">
      <el-button icon="el-icon-arrow-left" :disabled="isPastSixMonth" @click="onClickPrevDate" />
      <el-date-picker
        :value="rangeFilter.dates[0]"
        type="date"
        align="left"
        format="yyyy. M. d."
        value-format="yyyy-MM-dd"
        :picker-options="rangePickerOptions"
        :clearable="false"
        @input="handleChangeDate"
      />
      <el-button icon="el-icon-arrow-right" @click="onClickNextDate" />
      <el-button v-if="rangeFilter.dates[0] !== moment().format('YYYY-MM-DD')" @click="onClickToday">
        오늘
      </el-button>
    </div>

    <!-- 기간별 조회 -->
    <div class="histories-list-filter__date" v-show="rangeFilter.type === 'range'">
      <el-date-picker
        :value="rangeFilter.dates"
        type="daterange"
        align="left"
        format="yyyy. M. d."
        value-format="yyyy-MM-dd"
        :picker-options="rangePickerOptions"
        :clearable="false"
        @input="handleChangeRange"
      />
    </div>

    <div :class="staffFilterList.length ? 'is-filtered staff-filter' : 'staff-filter'">
      <el-select :value="staffFilterList" multiple placeholder="강사 전체" collapse-tags clearable @change="changeStaffFilters">
        <el-option :v-if="!!staffList.length" v-for="staff in staffList" :label="staff.name" :key="staff.id" :value="staff.id">
          {{ staff.name }}
          <span v-if="staff.deleted_at">삭제 강사</span>
        </el-option>
      </el-select>
    </div>

    <FilterResetButton @click="clickReset" />

    <TextInput
      class="histories-list-filter__search-box"
      prefix-icon="el-icon-search"
      placeholder="회원명"
      :value="searchName"
      @input="onChange"
      @enter="onSubmit"
      round
    />
  </div>
</template>

<script>
export default {
  props: {
    staffList: [],
  },

  data() {
    return {
      rangeTypeOptions: [
        { value: 'date', label: '날짜' },
        { value: 'range', label: '기간' },
      ],
      localFilterList: [],
    };
  },

  computed: {
    rangeFilter() {
      return this.$store.getters['ticketHistory/rangeFilter'];
    },

    rangePickerOptions() {
      const { moment } = this;
      return {
        disabledDate(time) {
          return time.getTime() < moment().subtract(6, 'month');
        },
      };
    },

    isPastSixMonth() {
      const yesterday = this.moment(this.rangeFilter.dates[0]).subtract(1, 'day');
      return yesterday.isBefore(this.moment().subtract(6, 'month'));
    },

    searchName() {
      return this.$store.getters['ticketHistory/searchName'];
    },

    staffFilterList() {
      return this.$store.getters['ticketHistory/staffFilterList'];
    },
  },

  methods: {
    handleChangeDate(date) {
      this.$store.commit('ticketHistory/SET_DATE_FILTER', {
        type: 'date',
        dates: [date, date],
      });
    },

    handleChangeRange(dates) {
      this.$store.commit('ticketHistory/SET_DATE_FILTER', {
        type: 'range',
        dates,
      });
    },

    onClickPrevDate() {
      const yesterday = this.moment(this.rangeFilter.dates[0])
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      this.handleChangeDate(yesterday);
    },

    onClickNextDate() {
      const tommorrow = this.moment(this.rangeFilter.dates[0])
        .add(1, 'day')
        .format('YYYY-MM-DD');
      this.handleChangeDate(tommorrow);
    },

    onClickToday() {
      const today = this.moment().format('YYYY-MM-DD');
      this.handleChangeDate(today);
    },

    onChange(text) {
      this.$emit('search-change', text);
    },

    onSubmit() {
      this.$emit('submit');
    },

    changeRangeFilterType(type) {
      if (type === 'range') {
        const startDate = this.moment()
          .subtract(7, 'day')
          .format('YYYY-MM-DD');
        const today = this.moment().format('YYYY-MM-DD');

        this.$store.commit('ticketHistory/SET_DATE_FILTER', {
          type: 'range',
          dates: [startDate, today],
        });
      } else {
        this.onClickToday();
      }
    },

    changeStaffFilters(staff) {
      this.$emit('change-staff-filters', staff);
    },

    clickReset() {
      this.$store.commit('ticketHistory/SET_RESET');
    },
  },
};
</script>

<style lang="scss" scoped>
.histories-list-filter {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;

  & > * {
    height: 36px;
    margin: 0 8px 8px 0;
  }

  /deep/ .el-button {
    height: 36px;
  }

  /deep/ .el-input__inner {
    @extend %input-default;
    border-color: #dcdfe6;
    height: 36px;

    &::placeholder {
      color: $charcoal-grey;
    }
  }

  /deep/ .el-select .el-input__inner {
    padding: 0 20px 0 10px;
  }

  /deep/ .el-date-editor {
    min-width: 135px;
    max-width: 250px;
  }

  &__query-type {
    width: 80px;
  }

  &__local-filter {
    width: 140px;
  }

  &__date {
    @include flex(row, center, center);

    & > * {
      margin-right: 4px;

      &:last-child {
        margin: 0;
      }
    }

    /deep/ .el-button {
      padding: 0;
      height: 36px;
      width: 36px;
    }
  }

  &__summary {
    @include flex(row, center, center);

    span {
      font-size: 15px;
      font-weight: bold;
      color: #000;
    }

    span + span {
      margin-left: 12px;
    }
  }

  &__search-box {
    width: 230px !important;
    margin-right: 60px;
  }

  &__popover {
    width: 140px;
    &__label {
      width: 140px;
      font-size: 14px;
    }
    &__inputs {
      padding: 10px 0;
    }

    &__checkbox {
      padding: 10px 20px;
    }

    &__buttons {
      padding: 10px 20px;
      text-align: right;
    }
  }
}

.time-search-box {
  &__inputs {
    padding: 10px 0;
  }

  &__checkbox {
    padding: 10px 20px;
  }

  &__buttons {
    padding: 10px 20px;
    text-align: right;
  }
}

.none {
  display: none;
}

.el-select-dropdown__item {
  span {
    background-color: rgba(245, 108, 108, 0.1);
    border: 1px solid rgba(245, 108, 108, 0.2);
    color: #f95454;
    width: 60px;
    height: 20px;
    padding: 0 3px;
    margin-left: 2px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    border-radius: 5px;
  }
}

/deep/ .el-select__tags > span {
  @include flex(row, center);
}

/deep/ .el-tag {
  background: none !important;
  color: $charcoal-grey;
  font-size: 14px;
  padding: 0;
  max-width: 80px;
  @include ellipsis;

  &::after {
    content: ', ';
  }

  &:last-child::after {
    content: '';
  }

  i {
    display: none;
  }
}

.staff-filter {
  /deep/ .el-button {
    padding: 0 10px;
    height: 36px;
    width: 100%;

    span {
      @include flex(row, center, space-between);
      i {
        color: #c0c4cc;
      }

      button {
        @include flex(row, center, center);
        border-radius: 50%;
        padding: 0;

        &:hover {
          background: $deep-sky-blue;
          i {
            color: #fff;
          }
        }
      }
    }
  }

  &.is-filtered {
    /deep/ .el-input__inner,
    /deep/ .el-button {
      border-color: $deep-sky-blue;
      color: $deep-sky-blue;

      i {
        color: $deep-sky-blue;
      }
    }

    /deep/ .el-input__icon,
    /deep/ .el-tag {
      color: $deep-sky-blue;
    }
  }
}
</style>
