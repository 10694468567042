import convertTime from './convertTime';

export default ({ start, end }) => {
  let startTime = '09:00';
  let endTime = '10:00';

  if (!start || !end)
    return {
      startTime,
      endTime,
    };

  const [startHour, startMinute] = start.split(':');
  const [endHour, endMinute] = end.split(':');

  /** 이전 수업 시작 시간 -> 분으로 변환 */
  const startInMinute = convertTime.combineHourMinute({
    hour: parseInt(startHour),
    minute: parseInt(startMinute),
  });
  /** 이전 수업 종료 시간 -> 분으로 변환 */
  const endInMinute = convertTime.combineHourMinute({
    hour: parseInt(endHour),
    minute: parseInt(endMinute),
  });
  /** 이전 수업 시간 범위 */
  const difference = endInMinute - startInMinute;

  let endTimeInMinute = 0;
  /**
   * 이전 수업 종료 시간이 정각이거나 (ex. 10:00)
   * 시작시간의 분단위가 종료시간의 분단위보다 클 경우 (ex. start: '09:30', end: '10:20')
   * 다음 수업 시작 시간 = {이전 수업 종료 시간의 시각}:{이전 수업 시작 시간의 분}
   * 다음 수업 종료 시간 = 다음 수업 시작 시간 + 이전 수업 시간 범위
   */
  if (
    endInMinute % 60 === 0 ||
    parseInt(startMinute) > parseInt(endMinute) ||
    (endInMinute % 30 === 0 && startInMinute % 30 === 0)
  ) {
    startTime = `${endHour}:${startMinute}`;
    endTimeInMinute =
      convertTime.combineHourMinute({
        hour: parseInt(endHour),
        minute: parseInt(startMinute),
      }) + difference;
  } else {
    /**
     * 나머지 경우 (ex. start: '09:10', end: '09:50')
     * 다음 수업 시작 시간 = {이전 수업 종료 시간의 시각 + 1}:{이전 수업 시작 시간의 분}
     * 다음 수업 종료 시간 = 다음 수업 시작 시간 + 이전 수업 시간 범위
     */
    const newEndHour = `0${parseInt(endHour) + 1}`.slice(-2);
    startTime = `${newEndHour}:${startMinute}`;
    endTimeInMinute =
      convertTime.combineHourMinute({
        hour: parseInt(newEndHour),
        minute: parseInt(startMinute),
      }) + difference;
  }

  /** 다음 수업 시작 시간이 24:00이 넘을 경우 */
  if (endTimeInMinute - difference >= 1440)
    return {
      startTime: null,
      endTime: null,
    };

  const { hour, minute } = convertTime.parseHourMinute(endTimeInMinute);
  const hourInTwoDigit = `0${hour}`.slice(-2);
  const minuteInTwoDigit = `0${minute}`.slice(-2);
  endTime = `${hourInTwoDigit}:${minuteInTwoDigit}`;

  return {
    startTime,
    endTime,
  };
};
