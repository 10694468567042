import axios from '../axios';

const BASE_URL = '/v2/staff/userTickets';
const USER_BATCH_BASE_URL = '/v2/staff/userTickets/add/user-ticket';

export default {
  // 회원 수강권 목록
  getAll: params =>
    axios.get(BASE_URL, {
      params,
    }),

  // 개별 회원 수강권
  get: (id, params) => axios.get(`${BASE_URL}/${id}`, { params }),

  // 회원 수강권 예약 내역
  getBookings: id => {
    const params = {
      with: ['member', 'lecture.instructor.profile', 'lecture.instructor.avatars', 'lecture.course'],
    };
    return axios.get(`/staff/user_ticket/${id}/booking`, {
      params,
    });
  },

  // 회원 수강권 예약 내역 페이지네이션
  getPagedBookings: (id, status = 'total', params = {}) => {
    params.with = ['member', 'lecture.instructor.profile', 'lecture.instructor.avatars', 'lecture.course'];
    return axios.get(`${BASE_URL}/reserve-list/${id}/booking${status === 'total' ? '' : `/${status}`}`, { params });
  },

  // 수업 상세 페이지의 예약 가능 회원 목록
  getBookableList: (lecture_id, params) => axios.get(`${BASE_URL}/usable/${lecture_id}`, { params }),

  // 시설 전체에 대한 수강권 변경이력 (회원 > 수강권 정보 변경이력탭)
  getStudioTicketHistory: params => {
    return axios.get(`${BASE_URL}/histories`, {
      params,
    });
  },

  // 회원 수강권 변경이력 (회원 > 수강권 상세 > 변경이력)
  // getHistory: id => axios.get(`/user-tickets/${id}/count-update-history`),
  getHistory: id => axios.get(`${BASE_URL}/${id}/histories`),

  // 예약의 변경 이력
  getLog: bookingId => {
    return axios.get(`/v2/staff/booking/${bookingId}/history`);
  },

  // 프라이빗 수강권 (강사의 담당회원 목록 / 프라이빗 수업 생성)
  getPrivate: params =>
    axios.get(BASE_URL, {
      params,
    }),

  // 회원 수강권 발급
  create: data => axios.post(BASE_URL, data),

  // 회원 수강권 수정
  update: (id, data) => axios.patch(`${BASE_URL}/${id}`, data),

  // 회원 수강권 결제정보 수정
  updatePayment: data => axios.patch(`/v2/staff/payment/${data.id}`, data),

  // 회원 수강권 결제정보 추가
  addPayment: data => axios.post(`/v2/staff/payment/${data.id}`, data),

  // 회원 수강권 결제정보 삭제
  deletePayment: id => axios.delete(`/v2/staff/payment/${id}`),

  // 회원 수강권 업그레이드 (교체)
  upgrade: (userTicketId, ticketId, payment) => {
    return axios.post(`${BASE_URL}/${userTicketId}/${ticketId}`, payment);
  },

  // 회원 수강권 양도
  transfer: (userTicketId, receivedUserId, params) => {
    return axios.post(`${BASE_URL}/transfer/${userTicketId}/${receivedUserId}`, params);
  },

  refund: (userTicketId, payment) => axios.post(`${BASE_URL}/refund/${userTicketId}`, payment),

  // 회원 수강권 사용불가 처리 토글
  activeToggle: (id, reason) => {
    return axios.post(`${BASE_URL}/active-toggle/${id}`, {
      reason,
    });
  },

  // 회원 수강권 삭제
  delete: id => axios.delete(`${BASE_URL}/${id}`),

  // 일괄 연장, 일괄 정지 이력 정보 불러오기
  batchExtend: id => axios.get(`${USER_BATCH_BASE_URL}/addHistories?studio_id=${id}`),

  batchStop: id => axios.get(`${USER_BATCH_BASE_URL}/holdHistories?studio_id=${id}`),

  // 회원 수강권 정지
  holding: {
    create: (userTicketId, holding) => {
      return axios.post(`/staff/user_ticket/${userTicketId}/holding`, {
        user_ticket_holding: holding,
      });
    },

    update: (userTicketId, holding) => {
      return axios.patch(`/staff/user_ticket/${userTicketId}/holding/${holding.id}`, {
        user_ticket_holding: holding,
      });
    },

    delete: (userTicketId, holdingId) => {
      return axios.delete(`/staff/user_ticket/${userTicketId}/holding/${holdingId}`);
    },
  },

  getHoldingOverlapBookingCount: ({ userTicketId, startOn, endOn }) => {
    return axios.get(`${BASE_URL}/${userTicketId}/booking?start_on=${startOn}&end_on=${endOn}`);
  },
};
