/**
 * 문자열 바이트 체크
 * @param {String} str
 * @param {Number} maxBytes
 *
 * @return {Object} { exceeded: Boolean, exceededAt: Int }
 */
export default (str = '') => {
  var tcount = 0;
  var onechar;
  for (var i = 0; i < str.length; i++) {
    onechar = str.charAt(i);

    if (escape(onechar).length > 4) {
      tcount += 2;
    } else if (onechar != '\r\n') {
      tcount++;
    }
  }
  return tcount;
};