<template>
  <div>
    <div class="title-wrapper">
      <h4>포인트 합계</h4>
      <PlainButton round @click="showEdit">포인트 수정</PlainButton>
    </div>
    <PointDetail :pointDetail="pointDetail" :loading="pointDetailLoading" />

    <div class="title-wrapper">
      <h4>적립 및 차감내역</h4>
    </div>
    <section class="point-histories-section">
      <PointHistoryListFilter :rangeFilter="rangeFilter" :filters="filters" />

      <PointHistoryList :histories="histories" :loading="historiesLoading" @sort-change="sortChange" />

      <el-pagination
        v-if="!!total"
        class="list__pagination"
        layout="prev, pager, next"
        :current-page="page"
        :page-size="LIMIT"
        :total="total"
        @current-change="pageChange"
      />
    </section>

    <PointEditModal
      title="포인트 수정"
      desc="회원의 잔여 포인트를 수정합니다."
      :show="showPointEdit"
      :pointEdit="pointEdit"
      :currentPoint="pointDetail.current_point"
      @close="onClose"
      @change="onChange"
    />
  </div>
</template>

<script>
import PointDetail from '@components/MemberDetail/PointHistory/PointDetail';
import PointHistoryList from '@components/MemberDetail/PointHistory/PointHistoryList';
import PointHistoryListFilter from '@components/MemberDetail/PointHistory/PointHistoryListFilter';
import PointEditModal from '@components/Modals/PointEdit';

const LIMIT = 10;

export default {
  components: {
    PointDetail,
    PointHistoryList,
    PointHistoryListFilter,
    PointEditModal,
  },

  data() {
    return {
      showPointEdit: false,
      pointEdit: {
        editType: 'reward',
        editReason: '',
        rewardPoint: 0,
        deductPoint: 0,
      },
    };
  },

  created() {
    this.LIMIT = LIMIT;
    this.dispatchPointHistories();
    this.dispatchPointDetail();
  },

  watch: {
    rangeFilter() {
      this.dispatchPointHistories('initPage');
    },

    filters() {
      this.dispatchPointHistories('initPage');
    },

    page() {
      this.dispatchPointHistories();
    },

    sort() {
      this.dispatchPointHistories();
    },
  },

  computed: {
    rangeFilter() {
      return this.$store.getters['pointHistory/rangeFilter'];
    },

    filters() {
      return this.$store.getters['pointHistory/filters'];
    },

    histories() {
      return this.$store.getters['pointHistory/histories'];
    },

    historiesLoading() {
      return this.$store.getters['pointHistory/historiesLoading'];
    },

    page() {
      return this.$store.getters['pointHistory/page'];
    },

    total() {
      return this.$store.getters['pointHistory/total'];
    },

    memberId() {
      return this.$route.query.id;
    },

    pointDetail() {
      return this.$store.getters['pointHistory/pointDetail'];
    },

    pointDetailLoading() {
      return this.$store.getters['pointHistory/pointDetailLoading'];
    },

    sort() {
      return this.$store.getters['pointHistory/sort'];
    },
  },

  methods: {
    getPointHistories() {
      const filterStaffs = this.filters[0];
      const filterTypes = this.filters[1];
      const filterStatus = this.filters[2];

      const params = {
        start_date: this.rangeFilter.months[0],
        end_date: this.rangeFilter.months[1],
        staff_ids: filterStaffs.value,
        type: filterTypes.value,
        status: filterStatus.value,
        limit: LIMIT,
        page: this.page,
        order_by: this.sort,
      };
      this.$store.dispatch('pointHistory/getPointDetail', this.memberId);
      this.$store.dispatch('pointHistory/getHistories', { memberId: this.memberId, params });
    },

    dispatchPointHistories(initPage) {
      if (initPage) {
        this.pageChange(1);
      }
      this.getPointHistories();
    },

    onClose(type) {
      this.showPointEdit = false;
      this.resetPointEdit();

      if (type === 'point') {
        this.dispatchPointHistories('initPage');
      }
    },

    resetPointEdit() {
      this.pointEdit = {
        editType: 'reward',
        rewardPoint: 0,
        deductPoint: 0,
        editReason: '',
      };
    },

    onChange(type, value) {
      if (type === 'point') {
        const currentPointType = value.type === 'reward' ? 'rewardPoint' : 'deductPoint';
        this.pointEdit = { ...this.pointEdit, [currentPointType]: value.value };
        return;
      }
      this.pointEdit = { ...this.pointEdit, [type]: value };
    },

    showEdit() {
      this.showPointEdit = true;
      this.dispatchPointDetail();
    },

    pageChange(value) {
      this.$store.commit('pointHistory/SET_PAGE', value);
    },

    dispatchPointDetail() {
      this.$store.dispatch('pointHistory/getPointDetail', this.memberId);
    },

    sortChange(value) {
      const sort = value === 'ascending' ? 'asc' : 'desc';
      this.$store.commit('pointHistory/SET_SORT', sort);
    },
  },
};
</script>

<style lang="scss" scoped>
.point-histories-section {
  margin-bottom: 60px;
}

/deep/ .title-wrapper {
  @include flex(row, center, space-between);
  margin-bottom: 16px;

  > h4 {
    font-size: 20px;
    font-weight: 500;
  }
}

.list__pagination {
  @include flex(row, center, center);
  margin-top: 30px;

  /deep/ .el-pagination__sizes {
    margin: 0;

    /deep/ .el-input__inner {
      @extend %input-default;
      border-color: #dcdfe6;
      height: 36px;
    }

    /deep/ .el-input__suffix {
      transform: scale(1);
      padding-right: 8px;
    }
  }
}
</style>
