<template>
  <section class="reply-wrap">
    <div class="reply-wrap__header">
      <!-- <button class="" @click="toggleMemoSortBy"> -->
      <button>
        <!-- <img v-if="memoSortBy === 'desc'" src="@icons/descending.png" alt="sorting" />
          <img v-else src="@icons/ascending.png"  alt="sorting" />
          <span>{{ memoSortBy === 'desc' ? '최신순' : '과거순' }}</span> -->
        <span>댓글</span>
        <span class="reply-length"> {{ memoSorted.length }} </span>
      </button>
    </div>

    <!-- 댓글 리스트 -->
    <ul class="reply-wrap__list">
      <!-- 댓글 리스트 + 댓글 수정 폼 -->
      <BoardItem
        v-for="memo in memoSorted"
        v-loading="replyLoading"
        :key="memo.id"
        :memo="isEditing(memo.id) ? memoEditing : memo"
        :isEditing="isEditing(memo.id)"
        :parentContent="board"
        @delete="handleClickDeleteMemo"
        @edit="handleClickEditMemo"
        @cancel-edit="handleClickCancelEditMemo"
        @save="handleClickSaveEditMemo"
      />
      <!-- 댓글 추가 폼 -->
      <ReplyCreating
        v-if="memoCreating && canCreateQnaBoard"
        v-model="memoCreating.memo"
        :disabled="!memoCreating.memo"
        @save="handleClickCreateMemo"
      />
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    board: Object,
  },
  data() {
    return {
      memoCreating: { memo: '' },
      memoEditing: null,
      memoSortBy: 'asc',
      replyLoading: false,
    };
  },

  computed: {
    formData() {
      return this.$store.getters['reply/formData'];
    },
    userTicket() {
      return this.formData.userTicket;
    },
    memosLoading() {
      return this.formData.memosLoading;
    },
    memos() {
      if (this.formData.memos) {
        let updatedData = this.formData.memos;
        updatedData.forEach(el => {
          el.memo = el.contents;
          if (el.staff) {
            el.roles = el.staff.roles[0].display_name;
          }
        });
      }

      return this.formData.memos;
    },
    memoSorted() {
      const memos = _.sortBy(this.memos, 'updated_at');
      if (this.memoSortBy === 'desc') memos.reverse();
      return memos;
    },
  },

  methods: {
    goBackListQna() {
      this.$router.push('/boards/list-qna');
    },

    /** 메모 정렬 */
    toggleMemoSortBy() {
      this.memoSortBy = this.memoSortBy === 'asc' ? 'desc' : 'asc';
    },

    /** 메모 추가 저장 */
    async handleClickCreateMemo() {
      try {
        await this.$api.reply.create({
          is_secret: 0,
          contents: this.memoCreating.memo,
          board_id: this.board.id,
        });
        this.memoCreating = { memo: '' };
        this.$utils.notify.success(this, '성공', '댓글이 등록되었습니다.');
        this.$store.dispatch('reply/getMemo', this.board.id);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        this.goBackListQna();
      }
    },

    /** 메모 삭제 */
    async handleClickDeleteMemo(memo) {
      const proceed = await this.$confirm('댓글을 삭제하시겠습니까?', '댓글 삭제')
        .then(() => true)
        .catch(() => false);

      if (proceed) {
        try {
          await this.$api.reply.delete(memo.id);
          this.$utils.notify.success(this, '성공', '댓글이 삭제되었습니다.');
          this.$store.dispatch('reply/getMemo', this.board.id);
        } catch (error) {
          this.$utils.notify.parseError(this, error);
          this.goBackListQna();
        }
      }
    },

    /** 메모 수정인지 체크 */
    isEditing(memoId) {
      return this.memoEditing && this.memoEditing.id === memoId;
    },

    /** 메모 수정 모드 */
    handleClickEditMemo(memo) {
      this.memoEditing = { ...memo };
    },

    /** 메모 수정 취소 */
    handleClickCancelEditMemo() {
      this.memoEditing = null;
    },

    /** 메모 수정 내용 저장 */
    async handleClickSaveEditMemo() {
      this.replyLoading = true;
      try {
        this.memoEditing.board_id = this.board.id;
        this.memoEditing.contents = this.memoEditing.memo;
        await this.$api.reply.update(this.memoEditing);
        this.$store.dispatch('reply/getMemo', this.board.id);
        this.memoEditing = null;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        this.goBackListQna();
      }
      this.replyLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.reply-wrap {
  &__header {
    padding-bottom: 12px;
    img {
      margin-right: 3px;
    }
    span {
      vertical-align: 7px;
      color: #8a8a8a;
      font-size: 16px;
    }
  }

  &__add-memo {
    width: 100px;
  }

  &__no-data {
    color: #666666;
    font-size: 14px;
    text-align: center;
    padding: 78px 0 0;
  }
  .reply-length {
    padding-left: 5px;
    font-size: 12px;
    font-weight: bold;
    color: $gray-700;
  }
}
</style>
