<template>
  <label :class="{ disabled, hasSlots }">
    <input
      v-bind="$attrs"
      type="checkbox"
      :disabled="disabled"
      :class="{
        indeterminate,
        multi: type === 'multi',
      }"
      @change="$emit('change', value)"
    />
    <slot></slot>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: { type: String, default: 'single' }, // single || multi
    color: { type: String, default: '#4799f2' },
    value: [Number, String, Boolean],
    disabled: Boolean,
    indeterminate: Boolean,
  },

  computed: {
    hasSlots() {
      return !_.isEmpty(this.$slots);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  padding: 0.5rem;

  &.disabled {
    cursor: not-allowed;
  }

  input {
    appearance: none;
    background: url('~@icons/checkbox_unchecked.svg');
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 18px;
    height: 18px;

    &:checked {
      background: url('~@icons/checkbox_checked.svg');

      &:hover {
        background: url('~@icons/checkbox_checked.svg');
      }
    }

    &:disabled,
    &:disabled:hover {
      cursor: not-allowed;
      background: url('~@icons/checkbox_unchecked.svg');

      &:checked {
        background: url('~@icons/checkbox_checked_disabled.svg');
      }
    }

    &.multi {
      background: url('~@icons/checkbox_unchecked_all.svg');

      &:checked {
        background: url('~@icons/checkbox_checked_all.svg');

        &:hover {
          background: url('~@icons/checkbox_checked_all.svg');
        }
      }

      &:disabled,
      &:disabled:hover {
        cursor: not-allowed;
        background: url('~@icons/checkbox_unchecked_all.svg');

        &:checked {
          background: url('~@icons/checkbox_checked_all_disabled.svg');
        }
      }
    }

    &.indeterminate {
      background: url('~@icons/checkbox_indeterminate.svg');
    }

    &:hover {
      background: url('~@icons/checkbox_hover.svg');
    }
  }

  &.hasSlots {
    input {
      margin: 0 1rem 0 0;
    }
  }
}
</style>
