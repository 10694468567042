<template>
  <el-table :data="histories" @sort-change="sortChange" :default-sort="{ prop: 'created_at', order: sortType }">
    <el-table-column
      label="변경일시"
      prop="created_at"
      min-width="80"
      align="center"
      sortable="custom"
      :sort-orders="['ascending', 'descending']"
    >
      <template slot-scope="scope">
        {{ scope.row.created_at }}
      </template>
    </el-table-column>

    <el-table-column label="변경한 사람" width="120" align="center">
      <template slot-scope="scope">
        {{ scope.row.staff_name }}
      </template>
    </el-table-column>

    <el-table-column label="회원명" width="120" align="center">
      <template slot-scope="scope">
        <a @click="handleClickMemberName(scope.row.member_id, scope.row.member_deleted_at)">
          {{ scope.row.member_name }}
        </a>
      </template>
    </el-table-column>

    <el-table-column label="수강권명" min-width="60" align="center">
      <template slot-scope="scope">
        {{ scope.row.ticket_title }}
      </template>
    </el-table-column>

    <el-table-column label="종류" min-width="60" align="center">
      <template slot-scope="scope"> {{ formatKind(scope.row.kind, scope.row.context) }} </template>
    </el-table-column>

    <el-table-column label="변경 전, 후 내용" header-align="center" min-width="80">
      <template slot-scope="scope">
        <ul>
          <li class="content" v-for="content in formatContent(scope.row)" :key="content.label">
            <label>{{ content.label }}</label>
            <span :class="{ hide: ['created', 'text'].includes(scope.row.kind) }">{{ content.oldValue }}</span>
            <i class="el-icon-caret-right"></i>
            <span class="primary">{{ content.newValue }}</span>
          </li>
        </ul>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { TICKET_HISTORY_KIND, CONTENT_LABEL } from '@constants';

export default {
  props: {
    histories: {
      type: Array,
      default: () => [],
    },
    formatContent: Function,
  },

  computed: {
    sortType() {
      return this.$store.getters['ticketHistory/sortType'];
    },
  },

  methods: {
    handleClickMemberName(memberId, memberDeletedAt) {
      if (memberDeletedAt) {
        this.$utils.notify.error(this, '오류', '삭제된 회원입니다.');
        return;
      }
      this.$router.push(`/users/detail?id=${memberId}`);
    },

    formatKind(kind, context) {
      if (kind === 'text') return context;
      return TICKET_HISTORY_KIND[kind];
    },

    changeStatusFormat(key) {
      return CONTENT_LABEL[key];
    },

    changeValueFormat(value, key) {
      switch (key) {
        case 'usable_coupon':
        case 'max_cancel':
          return;
        case 'is_shared':
        case 'is_show_cancel_count':
          return value ? 'O' : 'X';
      }

      const regex = /\d{4}-\d{2}-\d{2}/;
      if (regex.test(value)) {
        const formatDate = value.slice(0, 10);
        return formatDate;
      }

      return value;
    },

    sortChange(e) {
      this.$emit('sort-change', e.order);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #606266;
}

.content {
  display: grid;
  grid-template-columns: 72px 1fr auto 1fr;
  grid-gap: 4px;
  align-items: center;
  text-align: center;
  place-items: center;

  label {
    margin: 2px 0;
    min-width: 100px;
  }

  span {
    text-align: center;

    &.hide {
      color: transparent;
    }
  }

  .primary {
    color: #4799f2;
  }
}
</style>
