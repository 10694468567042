<template>
  <el-card
    :class="className"
    shadow="hover"
    :style="{ borderTopColor: borderColor, borderTopWidth: '2px' }"
    @click.native="handleClickCard"
  >
    <div v-if="addNew" class="booking-list-item__addNew">
      <img src="@/assets/images/01_graphic/ic_24x24/line/plus_black.svg" alt="add new booking" />
      <p>{{ addNewLabel }}</p>
    </div>

    <div v-else class="booking-list-item__meta">
      <div class="booking-list-item__meta__title">
        <h5 @click.stop="handleClickLectureTitle">{{ lectureTitle }}</h5>
        <el-tag v-if="isLectureDeleted" size="mini" type="danger">삭제된 수업</el-tag>
      </div>
      <p class="booking-list-item__meta__lecture-detail">
        <span class="booking-list-item__meta__lecture-detail__start-on">
          <img src="@/assets/images/clock.png" alt="clock icon" />
          <span>{{ lectureStartOn }}</span>
        </span>
        <span>
          <img src="@/assets/images/people.png" alt="person icon" />
          <span>{{ lectureMemberCount }}</span>
        </span>
        <span class="booking-list-item__meta__lecture-detail__room">{{ lectureRoom }}</span>
      </p>
      <p class="booking-list-item__meta__instructor">
        <img :src="instructorImage" alt="instructor profile image" />
        <span @click.stop="handleClickInstructor">{{ instructorName }}</span>
      </p>
    </div>

    <div v-if="size === 'medium'" class="booking-list-item__status">
      <div class="booking-list-item__status__header">
        <h5>{{ bookingStatus }}</h5>
      </div>
      <div class="booking-list-item__status__details">
        <label>사용된 수강권</label>
        <span>{{ userTicketTitle }}</span>
        <label v-if="bookingStatus !== '출석' || (bookingStatus === '출석' && !booking.enter_at)"
          >{{ bookingStatus }} 일시</label
        >
        <span v-if="bookingStatus !== '출석' || (bookingStatus === '출석' && !booking.enter_at)">
          {{ bookingUpdatedAt | dateWithWeekdayWithTime }}
        </span>
        <!-- booking.status가 출석일때만 노출  -->
        <label v-if="bookingStatus === '출석' && booking.enter_at">체크인 일시</label>
        <span v-if="bookingStatus === '출석' && booking.enter_at">
          {{ checkinEnterAt }}
        </span>
      </div>
    </div>

    <div v-if="size === 'medium'" class="booking-list-item__controls">
      <div class="booking-list-item__controls__buttons">
        <PlainButton
          v-loading="isCancellingBooking"
          v-if="clickable || bookingStatus === '취소'"
          type="danger"
          @click.native.stop="
            () => {
              handleClickCancelBooking(booking);
            }
          "
          round
          >{{ bookingStatus === '취소' ? '취소 삭제' : `${bookingStatus} 취소` }}</PlainButton
        >
        <PlainButton
          type="info"
          @click.native.stop="
            () => {
              handleClickShowLog(booking.id);
            }
          "
          round
          >지난 내역 보기</PlainButton
        >
      </div>
      <div v-if="clickable" class="booking-list-item__controls__checkbox">
        <img v-if="checked" src="@/assets/images/icons/checkbox_circle_checked.svg" alt="checked" />
        <img v-else src="@/assets/images/icons/checkbox_circle_unchecked.svg" alt="unchecked" />
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    booking: Object,
    lecture: Object,
    instructor: Object,
    handleClickCancelBooking: Function,
    handleClickShowLog: Function,
    size: { type: String, default: 'medium' },
    clickable: Boolean,
    addNew: Boolean,
    addNewLabel: { type: String, default: '수업 예약하기' },
    checked: { type: Boolean, default: false },
    isCancellingBooking: { type: Boolean, default: false },
  },

  computed: {
    className() {
      let className = `booking-list-item ${this.size}`;
      if (this.clickable) {
        className += ' clickable';
      }
      return className;
    },

    borderColor() {
      const color =
        _.get(this.lecture, 'instructor.profile.representative_color') ||
        _.get(this.instructor, 'profile.representative_color') ||
        '000';
      return `#${color}`;
    },

    lectureTitle() {
      return _.get(this.lecture, 'title') || _.get(this.lecture, 'course.title');
    },

    isLectureDeleted() {
      return !!_.get(this.lecture, 'deleted_at');
    },

    lectureStartOn() {
      const startOn = _.get(this.lecture, 'start_on');
      return this.moment(startOn).format('YYYY. M. D. (ddd) HH:mm');
    },

    lectureMemberCount() {
      const current = _.get(this.lecture, 'current_trainee_count');
      const max = _.get(this.lecture, 'max_trainee');
      return `${current} / ${max}`;
    },

    lectureRoom() {
      const roomName = _.get(this.lecture, 'room.name');
      if (!roomName) return null;

      const { exceeded, exceededAt } = this.$utils.checkBytesOfString(roomName, 8);
      return !exceeded ? `${roomName} 룸` : `${roomName.slice(0, exceededAt)}... 룸`;
    },

    instructorImage() {
      const avatar = _.get(this.lecture, 'instructor.avatars[0]') || _.get(this.instructor, 'avatars[0]');
      return this.$utils.getImageUrl(avatar, '20x20');
    },

    instructorName() {
      const name = _.get(this.lecture, 'instructor.profile.name') || _.get(this.instructor, 'profile.name');
      return `${name} 강사`;
    },

    bookingStatus() {
      return this.$utils.translate.bookingStatus(this.booking.status);
    },

    userTicketTitle() {
      return _.get(this.booking, 'user_ticket.metadata.title');
    },

    bookingUpdatedAt() {
      return _.get(this.booking, 'updated_at');
    },

    checkinEnterAt() {
      const time = _.get(this.booking, 'enter_at');
      return this.moment(time).format('YYYY. M. D. (ddd) HH:mm');
    },
  },

  methods: {
    handleClickLectureTitle() {
      const courseType = _.get(this.lecture, 'course.type') || _.get(this.lecture, 'type');
      const instructorId = _.get(this.lecture, 'instructor_id') || _.get(this.lecture, 'staff.id');
      if (this.canViewLecture(courseType, instructorId)) {
        this.$router.push(`/lecture/detail?id=${this.lecture.id}`);
      }
    },

    handleClickInstructor() {
      const instructorId = _.get(this.lecture, 'instructor_id');
      if (instructorId && this.canViewStaff) {
        this.$router.push(`/staffs/detail?id=${instructorId}&tab=basic`);
      }
    },

    handleClickCard() {
      const lectureId = _.get(this.lecture, 'id');
      if (this.clickable) {
        this.$emit('click', lectureId, this.booking);
      } else {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-list-item {
  border-radius: 2px;
  margin-bottom: 10px;

  @include mq(768px) {
    margin-bottom: 16px;
  }

  &.clickable {
    cursor: pointer;
  }

  &.mini {
    @include flex(row, stretch, stretch);
    margin-bottom: 0;

    /deep/ .el-card__body {
      padding: 10px 15px;
      width: 100%;
    }
  }

  &.medium {
    /deep/ .el-card__body {
      padding: 16px;

      @include mq(1024px) {
        display: grid;
        grid-template-columns: 310px 1fr auto;
      }
    }
  }

  &__addNew {
    @include flex(column, center, center);
    height: 99px;

    p {
      font-size: 11px;
      font-weight: 500;
      color: #ced4da;
      margin-top: 8px;
    }
  }

  &__meta {
    display: grid;
    align-items: center;
    grid-gap: 6px;

    &__title {
      @include flex(row, center, space-between);

      h5 {
        @include ellipsis;
        cursor: pointer;

        @include mq-max(1024px) {
          min-width: 500px;
        }
        max-width: 250px;
        font-size: 15px;
        font-weight: 400;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__lecture-detail {
      display: grid;
      grid-gap: 8px;

      img {
        width: 10px;
        height: 10px;
        margin-right: 4px;
        opacity: 0.3;
      }

      span {
        @include flex(row, center, flex-start);
        font-size: 12px;

        &:last-child {
          grid: span 2;
          margin-right: 0;
        }
      }
    }

    &__instructor {
      @include flex(row, center, flex-start);

      img {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      span {
        @include ellipsis;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.mini &__meta__lecture-detail {
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(2, 18px);
    grid-gap: 4px;

    &__start-on {
      grid-column: span 2;
    }

    &__room {
      justify-content: flex-end;
      @include ellipsis;
    }
  }

  &.medium &__meta {
    grid-gap: 8px;
  }

  &.medium &__meta__title {
    h5 {
      font-size: 18px;
    }
  }

  &.medium &__meta__lecture-detail {
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(2, 18px);
    grid-gap: 16px;

    @include mq(568px) {
      grid-template-columns: repeat(2, auto) 1fr;
      grid-template-rows: 18px;
    }

    span {
      font-size: 14px;

      &:last-child {
        @include ellipsis;
      }
    }
  }

  &__status {
    border: solid rgba(#000, 0.06);
    border-width: 1px 0 0 0;
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;

    @include mq(1024px) {
      border-width: 0 0 0 1px;
      margin: 0 0 0 20px;
      padding: 0 0 0 20px;
    }

    &__header {
      @include flex(row, center, space-between);

      h5 {
        font-size: 18px;
        font-weight: bold;
      }

      img {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 7.5px;

        &:hover {
          background: $gray-100;
        }
      }
    }

    &__details {
      display: grid;
      grid-gap: 8px;
      padding-top: 8px;

      @include mq(568px) {
        grid-template-columns: 100px auto;
      }

      label {
        color: #646464;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }

      span {
        font-size: 14px;
        @include ellipsis;
      }
    }
  }

  &__controls {
    @include flex(column, flex-start, flex-start);

    &__buttons {
      @include flex(row, center, flex-end);
      flex-wrap: wrap;

      button {
        font-size: 12px;
      }

      button + button {
        margin-left: 8px;
      }
    }

    &__checkbox {
      flex: 1;
      align-self: flex-end;
      @include flex(row, flex-end, flex-end);
      img {
        margin-right: 32px;
      }
    }
  }
}
</style>
