import axios from '../axios';

const BASE_URL = '/v2/staff/attachment';

export default {
  upload: formData => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return axios.post(BASE_URL, formData, {
      headers,
    });
  },
};
