<template>
  <li class="members-list-item" :class="{ uncounted: bookingUncounted }">
    <img class="members-list-item__profile" :src="memberImageUrl" :alt="`image of ${memberName}`" />

    <div class="members-list-item__info-container">
      <div class="members-list-item__info-wrapper">
        <div class="members-list-item__name">
          <a v-if="!isMemberDeleted && canViewMembers" @click="$router.push(`/users/detail?id=${member.id}`)">{{
            memberNameAndMobile
          }}</a>
          <span v-else>
            {{ memberNameAndMobile }}
          </span>
          <span v-if="showAppLinked && memberAppLinked" class="members-list-item__name__app-linked">{{ ' · 앱 연결' }}</span>
        </div>

        <span class="members-list-item__ticket-info">
          {{ userTicketInfo }}
        </span>
      </div>

      <div class="members-list-item__vaccine" v-if="member.vaccination_yn === 'Y'">
        <img src="@/assets/images/icons/vaccine.svg" alt="백신 접종 완료 후 14일 경과" />
      </div>
    </div>

    <div class="members-list-item__button">
      <PlainButton v-if="!booking" v-loading="bookingButtonDisabled" @click="$emit('click-booking', userTicket)" round
        >예약</PlainButton
      >

      <el-select
        v-else
        v-loading="statusChanging"
        class="members-list-item__select"
        :class="{ wide: wideSelect }"
        popper-class="members-list-item__select__options"
        size="mini"
        :disabled="bookingStatusChangeDisabled"
        v-model="bookingStatus"
        @change="$emit('status-change', booking, bookingStatus)"
      >
        <el-option
          v-for="option in statusOptions"
          :key="option.value"
          :value="option.value"
          :disabled="getOptionDisabled(option)"
          :label="getOptionLabel(option)"
        />
      </el-select>
    </div>
  </li>
</template>

<script>
import { BOOKING_STATUS, BOOKING_STATUS_ARRAY } from '@constants';

export default {
  props: {
    userTicket: { type: Object, default: null },
    booking: { type: Object, default: null },
    member: { type: Object, default: null },
    waitingIndex: { type: Number, default: null },
    bookingButtonDisabled: { type: Boolean, default: false },
    statusChangeDisabled: { type: Boolean, default: false },
    statusChanging: { type: Boolean, default: false },
    showAppLinked: { type: Boolean, default: false },
    statusError: { type: Boolean, default: false },
  },

  data() {
    return {
      bookingStatus: null,
    };
  },
  watch: {
    statusError() {
      this.bookingStatus = BOOKING_STATUS.WAITING;
    },
  },

  computed: {
    lecture() {
      return this.$store.getters['lecture/lecture'];
    },

    isMemberDeleted() {
      return !!_.get(this.member, 'profile.deleted_at');
    },

    memberImageUrl() {
      if (this.member.avatars && this.member.avatars.length) {
        return this.$utils.getImageUrl(_.get(this.member, 'avatars[0]'), '40x40');
      }
      return this.$utils.getImageUrl(_.get(this.member, 'account_avatar'), '40x40');
    },

    memberName() {
      return _.get(this.member, 'profile.name');
    },

    memberNameAndMobile() {
      let text = this.memberName;
      if (!this.isMemberDeleted && this.member.mobile) {
        const mobile = this.canViewMembersMobile
          ? this.$filters.mobile(this.member.mobile)
          : this.$filters.mobileMask(this.member.mobile);
        text += ` · ${mobile}`;
      }
      return text;
    },

    memberAppLinked() {
      return _.get(this.member, 'account_id');
    },

    userTicketInfo() {
      const { date, comma } = this.$filters;
      let ticketTitle = _.get(this.userTicket, 'ticket.title');
      if (ticketTitle.length > 24) {
        ticketTitle = ticketTitle.slice(0, 24) + '...';
      }
      const start = date(this.userTicket.availability_start_at);
      const end = date(this.userTicket.expire_at);
      const ticketType = _.get(this.userTicket, 'ticket.type');
      const usableCoupon = comma(this.userTicket.usable_coupon);
      return ticketType === 'P'
        ? `${ticketTitle} · ${start}~${end}`
        : `${ticketTitle} · ${usableCoupon}회 남음 · ${start}~${end}`;
    },

    statusOptions() {
      const { status } = this.booking;
      if (!status) return [];

      const booked = [BOOKING_STATUS.BOOKED, BOOKING_STATUS.CANCEL, BOOKING_STATUS.ABSENT, BOOKING_STATUS.NOSHOW];

      const confirmed = [
        BOOKING_STATUS.CONFIRMED,
        BOOKING_STATUS.CANCEL,
        BOOKING_STATUS.ABSENT,
        BOOKING_STATUS.NOSHOW,
        BOOKING_STATUS.ATTENDED,
      ];

      const waiting = [BOOKING_STATUS.WAITING, BOOKING_STATUS.BOOKED, BOOKING_STATUS.CANCEL];

      const afterClassStatus = [BOOKING_STATUS.CANCEL, BOOKING_STATUS.ABSENT, BOOKING_STATUS.NOSHOW, BOOKING_STATUS.ATTENDED];

      const options = {
        [BOOKING_STATUS.BOOKED]: booked,
        [BOOKING_STATUS.CONFIRMED]: confirmed,
        [BOOKING_STATUS.WAITING]: waiting,
        [BOOKING_STATUS.ATTENDED]: [...afterClassStatus],
        [BOOKING_STATUS.ABSENT]: [...afterClassStatus],
        [BOOKING_STATUS.NOSHOW]: [...afterClassStatus],
        [BOOKING_STATUS.CANCEL]: [BOOKING_STATUS.CANCEL],
      };

      return options[status].map(value => ({
        value,
        label: this.$utils.translate.bookingStatus(value),
      }));
    },

    bookingUncounted() {
      return BOOKING_STATUS_ARRAY.ABSENT.includes(this.bookingStatus);
    },

    bookingStatusChangeDisabled() {
      const courseType = _.get(this.lecture, 'course.type');
      const instructorId = _.get(this.lecture, 'instructor_id');
      return !this.canUpdateLectureBookings(courseType, instructorId) || this.statusChangeDisabled || this.isMemberDeleted;
    },

    bookingCancelDisabled() {
      const courseType = _.get(this.lecture, 'course.type');
      const instructorId = _.get(this.lecture, 'instructor_id');
      return !this.canDeleteLectureBookings(courseType, instructorId);
    },

    wideSelect() {
      return [BOOKING_STATUS.WAITING, BOOKING_STATUS.CONFIRMED].includes(this.bookingStatus);
    },
  },

  created() {
    if (this.booking) this.bookingStatus = this.booking.status;
  },

  methods: {
    appendWaitingIndex(label) {
      return `${label} (${this.waitingIndex + 1})`;
    },

    getOptionLabel(option) {
      return option.value === BOOKING_STATUS.WAITING ? `${option.label} (${this.waitingIndex + 1})` : option.label;
    },

    getOptionDisabled(option) {
      return option.value === BOOKING_STATUS.CANCEL ? this.bookingCancelDisabled : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.members-list-item {
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr auto;
  grid-gap: 0 12px;
  grid-template-areas:
    'profile name button'
    'profile ticket-info button';

  border-bottom: 1px solid #ebebeb;
  padding: 12px 6px;
  transition: 0.15s background;
  overflow: hidden;

  &:hover {
    background: rgba($color-primary, 0.1);

    .members-list-item__button button {
      width: 56px;
      transform: translateX(0);
    }
  }

  @include mq(568px) {
    grid-gap: 0 24px;
    padding: 12px;
  }

  &__profile {
    grid-area: profile;
  }
  &__button {
    grid-area: button;

    button {
      transform: translateX(60px);
      transition: transform 0.15s;
    }
  }

  &__profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &__info-container {
    display: flex;
    align-items: center;
  }

  &__vaccine {
    width: 22px;
    height: 22px;
    margin-left: 10px;
  }

  &__name {
    font-size: 12px;

    @include mq(568px) {
      font-size: 13px;
    }
    @include mq(1280px) {
      font-size: 14px;
    }

    &__app-linked {
      color: $deep-sky-blue;
    }
  }

  &__ticket-info {
    font-size: 10px;
    opacity: 0.7;

    @include mq(568px) {
      font-size: 11px;
    }
  }

  &__select {
    width: 60px;

    &.wide {
      width: 100px;
    }

    /deep/ .el-input__inner {
      border: 1px solid $gray-100;
      border-radius: 5px;
      background: $gray-100;
      color: $link-color;
      font-size: 13px;
      padding: 5px 24px 5px 5px;
      text-align: center;

      &:hover {
        border-color: $gray-200;
      }
    }
  }

  &.uncounted &__select {
    /deep/ .el-input__inner {
      color: $gray-500;
    }
  }
}
</style>
