<template>
  <section class="ticket-detail">
    <div class="ticket-detail__tickets">
      <TicketCard :ticket="userTicket.ticket" :showFavoriteIcon="false" />
      <img src="@/assets/images/big-arrow.svg" alt="big arrow" />
      <TicketCard :ticket="ticket" :showFavoriteIcon="false" />
    </div>

    <div class="ticket-detail__diff">
      <div v-for="item in ticketDetail" :key="item.key" class="ticket-detail__diff__row">
        <div class="ticket-detail__diff__col">
          <label>
            {{ item.label }}
          </label>
          <span>{{ item.currentValue }}</span>
        </div>
        <div class="ticket-detail__diff__col diff">
          <span v-if="item.diff !== 0">
            {{ getDiff(item) }}
          </span>
          <div class="diff-line"></div>
        </div>
        <div class="ticket-detail__diff__col">
          <label
            :class="{
              increase: item.diff > 0,
              decrease: item.diff < 0,
            }"
          >
            {{ item.label }}
          </label>
          <span>{{ userTicket.ticket_id === ticket.id ? item.extendValue : item.upgradeValue }}</span>
        </div>
      </div>
    </div>

    <div class="ticket-detail__buttons">
      <el-button @click="$emit('click-prev')">
        이전
      </el-button>
      <el-button @click="$emit('click-next')" type="primary">다음</el-button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    userTicket: { type: Object, required: true },
    ticket: { type: Object, required: true },
  },

  computed: {
    ticketDetail() {
      if (!this.ticket) return [];

      /** 이용기간 */
      const currentTicketStartOn = this.moment(this.userTicket.availability_start_at);
      const currentTicketEndOn = this.moment(this.userTicket.expire_at);
      const periodDiff =
        this.userTicket.ticket_id === this.ticket.id
          ? this.ticket.available_period
          : this.ticket.available_period - this.userTicket.ticket.available_period;
      const upgradeTicketEndOn = this.moment(currentTicketEndOn).add(periodDiff, 'days');
      const extendTicketEndOn = this.moment(currentTicketEndOn).add(periodDiff, 'days');

      /** 잔여횟수, 전체횟수 */
      const currentTicketMaxCoupon = this.userTicket.max_coupon;
      const upgradeTicketMaxCoupon = this.ticket.max_coupon;
      const extendTicketTicketMaxCoupon = currentTicketMaxCoupon + this.ticket.max_coupon;
      const maxCouponDiff =
        this.userTicket.ticket_id === this.ticket.id
          ? upgradeTicketMaxCoupon
          : upgradeTicketMaxCoupon - this.userTicket.ticket.max_coupon;

      const currentTicketRemainingCoupon = this.userTicket.remaining_coupon;
      const extendTicketRemainingCoupon = currentTicketRemainingCoupon + maxCouponDiff;

      /** 취소 가능 횟수 */
      const currentTicketRemainigCancel = this.userTicket.remaining_cancel;
      const maxCancelDiff =
        this.userTicket.ticket_id === this.ticket.id
          ? this.ticket.max_cancel
          : this.ticket.max_cancel - this.userTicket.ticket.max_cancel;
      const upgradeTicketRemainigCancel = Math.max(currentTicketRemainigCancel + maxCancelDiff, 0);
      const extendTicketRemainingCancel = Math.max(currentTicketRemainigCancel + maxCancelDiff, 0);

      /** 당일 예약변경 가능횟수 */
      const currentDailyBookingChangeLimit = _.get(this.userTicket, 'ticket.daily_booking_change_limit');
      const upgradeDailyBookingChangeLimit = _.get(this.ticket, 'daily_booking_change_limit');
      const dailyBookingChangeLimitDiff = upgradeDailyBookingChangeLimit - currentDailyBookingChangeLimit;

      return [
        {
          key: 'period',
          label: '수강권 이용기간',
          currentValue: `${this.$filters.date(currentTicketStartOn)} ~ ${this.$filters.date(currentTicketEndOn)}`,
          upgradeValue: `${this.$filters.date(currentTicketStartOn)} ~ ${this.$filters.date(upgradeTicketEndOn)}`,
          extendValue: `${this.$filters.date(currentTicketStartOn)} ~ ${this.$filters.date(extendTicketEndOn)}`,
          diff: periodDiff,
        },
        {
          key: 'remainingCoupon',
          label: '잔여 횟수',
          currentValue: this.ticket.type === 'P' ? '무제한' : `${currentTicketRemainingCoupon} 회`,
          upgradeValue:
            this.ticket.type === 'P' ? '무제한' : this.setMaxNumber999(`${currentTicketRemainingCoupon + maxCouponDiff}`),
          extendValue: this.ticket.type === 'P' ? '무제한' : this.setMaxNumber999(`${extendTicketRemainingCoupon}`),
          diff: this.ticket.type === 'P' ? 0 : maxCouponDiff,
        },
        {
          key: 'maxCoupon',
          label: '전체 횟수',
          currentValue: this.ticket.type === 'P' ? '무제한' : `${currentTicketMaxCoupon} 회`,
          upgradeValue: this.ticket.type === 'P' ? '무제한' : this.setMaxNumber999(`${currentTicketMaxCoupon + maxCouponDiff}`),
          extendValue: this.ticket.type === 'P' ? '무제한' : this.setMaxNumber999(`${extendTicketTicketMaxCoupon}`),
          diff: this.ticket.type === 'P' ? 0 : maxCouponDiff,
        },
        {
          key: 'remainingCancel',
          label: '예약 취소 가능 횟수',
          currentValue:
            this.ticket.type === 'P' && !this.ticket.is_show_cancel_count
              ? '무제한'
              : this.setMaxNumber999(`${currentTicketRemainigCancel}`),
          upgradeValue:
            this.ticket.type === 'P' && !this.ticket.is_show_cancel_count
              ? '무제한'
              : this.setMaxNumber999(`${upgradeTicketRemainigCancel}`),
          extendValue:
            this.ticket.type === 'P' && !this.ticket.is_show_cancel_count
              ? '무제한'
              : this.setMaxNumber999(`${extendTicketRemainingCancel}`),
          diff:
            this.ticket.type === 'P' && !this.ticket.is_show_cancel_count
              ? 0
              : currentTicketRemainigCancel >= 999
              ? 0
              : maxCancelDiff,
        },
        {
          key: 'dailyBookingChangeLimit',
          label: '당일 예약 변경 가능 횟수',
          currentValue: currentDailyBookingChangeLimit ? `${currentDailyBookingChangeLimit} 회` : '사용안함',
          upgradeValue: upgradeDailyBookingChangeLimit ? `${upgradeDailyBookingChangeLimit} 회` : '사용안함',
          extendValue: upgradeDailyBookingChangeLimit ? `${upgradeDailyBookingChangeLimit} 회` : '사용안함',
          diff: dailyBookingChangeLimitDiff,
        },
      ];
    },
  },

  methods: {
    getDiff({ key, diff }) {
      const sign = diff > 0 ? '+' : '-';
      const suffix = key === 'period' ? '일' : '회';
      return diff !== 0 ? `${sign}${Math.abs(diff)}${suffix}` : '-';
    },
    setMaxNumber999(value) {
      return value > 999 ? `999 회` : `${value} 회`;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-detail {
  &__tickets {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 16px;
    align-items: center;
  }

  &__diff {
    padding: 40px 0;

    &__row {
      display: grid;
      grid-template-columns: 1fr 80px 1fr;
      grid-gap: 8px;
      align-items: center;
    }

    &__col {
      @include flex(column);
      height: 100%;
      padding: 8px;
      position: relative;

      label {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;

        &.increase {
          color: $dodger-blue;
        }
        &.decrease {
          color: $color-danger;
        }
      }

      span {
        font-size: 14px;
      }

      &.diff {
        align-items: center;
        justify-content: center;
        padding: 4px;

        span {
          @include flex(row, center, center);
          border: 1px solid rgba(#000, 0.5);
          border-radius: 50%;
          background: #fff;
          font-size: 12px;
          width: 48px;
          height: 48px;
          overflow: hidden;
          z-index: 99;
        }

        .diff-line {
          background: rgba(#000, 0.5);
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
        }
      }
    }
  }

  &__buttons {
    @include flex(row, flex-end, space-between);
    border-top: 1px solid #f0f0f0;
    flex: 1;
    width: 100%;
    padding: 20px 20px 0;
  }
}
</style>
