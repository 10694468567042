import axios from '../axios';

const BASE_URL = '/v2/staff/report/renew';

export default {
  // getPayments: params =>
  //   axios.get(`${BASE_URL}/payments`, {
  //     params,
  //   }),
  // getLectureSales: params =>
  //   axios.get(`${BASE_URL}/lectures`, {
  //     params,
  //   }),
  // getDeductionSales: params =>
  //   axios.get(`${BASE_URL}/deducts`, {
  //     params,
  //   }),
  // getunPaymentSales: () => axios.get(`${BASE_URL}/un-payments`),
  // verifySalesPassword: data => axios.post(`${BASE_URL}/verify`, data),
  // updateSalesPassword: data => axios.patch(`${BASE_URL}/change-password`, data),
  // resetSalesPassword: () => axios.patch(`${BASE_URL}/reset-password`),

  // 매출
  getTicketSalesDashboard: params => axios.get(`${BASE_URL}/tickets/dashboard`, { params }),
  getTicketSalesData: params => axios.get(`${BASE_URL}/tickets`, { params }),
  getTicketMetaData: (params, type) => axios.get(`${BASE_URL}/tickets/meta/${type}`, { params }),

  // 매출 > 수강권 매출 (수강권, 강사)
  getTicketSalesPeriod: params => axios.get(`${BASE_URL}/tickets/by-period/ticket`, { params }),
  getStaffSalesPeriod: params => axios.get(`${BASE_URL}/tickets/by-period/staff`, { params }),

  // 매출 > 기타 매출 (상품, 강사)
  getProductEtcPeriod: params => axios.get(`${BASE_URL}/items/by-period/item`, { params }),
  getStaffsEtcPeriod: params => axios.get(`${BASE_URL}/items/by-period/staff`, { params }),

  getLectures: params => axios.get(`${BASE_URL}/lectures`, { params }),
  getDeducted: params => axios.get(`${BASE_URL}/deducted`, { params }),

  getItemSalesDashboard: params => axios.get(`${BASE_URL}/items/dashboard`, { params }),
  getItemSalesData: params => axios.get(`${BASE_URL}/items`, { params }),
  getItemMetaData: (params, type) => axios.get(`${BASE_URL}/items/meta/${type}`, { params }),

  // 지출
  getSpendingDashBoard: params => axios.get(`${BASE_URL}/expenditure/dashboard`, { params }),
  getSpendingData: params => axios.get(`${BASE_URL}/expenditure`, { params }),
  createSpending: data => axios.post(`${BASE_URL}/expenditure`, data),
  updateSpending: (id, data) => axios.patch(`${BASE_URL}/expenditure/${id}`, data),
  deleteSpending: id => axios.delete(`${BASE_URL}/expenditure/${id}`),

  // 지출 > 강사 필터 전용
  getStaffSpendingPeriod: params => axios.get(`${BASE_URL}/expenditure/by-period/staff`, { params }),

  // 미수금
  getUnPaymentDashboard: params => axios.get(`${BASE_URL}/un-payment/dashboard`, { params }),
  getUnPaymentData: params => axios.get(`${BASE_URL}/un-payment`, { params }),
  getUnPaymentMetaData: (params, type) => axios.get(`${BASE_URL}/un-payment/meta/${type}`, { params }),

  // 매출 리포트
  getPolicy: () => axios.get(`${BASE_URL}/policy`),
  updatePolicy: data => axios.patch(`${BASE_URL}/policy`, data),

  // 포인트
  getDashboard: params => axios.get(`${BASE_URL}/member-point/dashboard`, { params }),
  getHistories: params => axios.get(`${BASE_URL}/member-point/histories`, { params }),
};
