<template>
  <textarea
    v-bind="$attrs"
    ref="textarea"
    :class="[material ? 'material' : '', error ? 'error' : '', size, height ? `height${height}` : '']"
    :rows="rows"
    style="resize: none;"
    :value="value"
    @input="$emit('input', $event.target.value)"
    @focus="resizeElement($event.target)"
    @blur="$emit('blur')"
    :limit="limitLength"
    @keyup="limitByte"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: String,
    rows: { type: Number, default: 1 },
    material: { type: Boolean, default: false },
    size: String,
    error: { type: Boolean, default: false },
    height: Number,
    limitLength: Number,
    preventInitFocus: Boolean,
  },

  watch: {
    value() {
      this.resizeElement(this.$refs.textarea);
    },
  },

  created() {
    if (!this.preventInitFocus) {
      this.$nextTick(() => {
        this.resizeElement(this.$refs.textarea);
      });
    }
  },

  methods: {
    limitByte(e) {
      if (this.limitLength) {
        let bytes = this.$utils.smsByteCheckWithPreventWriting(e.target.value);
        if (bytes > 1999) {
          e.target.value = e.target.value.substring(0, e.target.value.length - 3);
        }
      }
    },

    resizeElement(element) {
      element.style.height = 'auto';
      if (this.height) {
        element.style.height = `${this.height}px`;
      } else {
        element.style.height = `${element.scrollHeight}px`;
      }
      this.$emit('focus');
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  @extend %input-default;
  border-radius: 4px;
  padding: 8px;
  line-height: 1.5;
  width: 100%;
  height: auto;
  overflow-y: auto;

  &::placeholder {
    opacity: 0.7;
  }

  &.large {
    padding: 10px 16px 10px 16px;
  }

  &.height400 {
    height: 400px;
  }

  &.height150 {
    height: 150px;
  }

  &.height180 {
    height: 180px;
  }

  &.material {
    border: none;
    border-bottom: 2px solid #eee;
    border-radius: 0;
    transition: border 0.15s;

    &:focus {
      border-color: $color-primary;
    }
  }

  &.error {
    border-color: rgba($color-danger, 0.7);
    &::placeholder {
      color: $color-danger;
    }
  }
}
</style>
