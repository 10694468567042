const env = process.env.VUE_APP_ENV;
const TITLE_PREFIX = {
  local: '(LOCAL) ',
  qa: '(QA) ',
  staging: '(STAGING) ',
  dev: '(DEV) ',
  beta: '(BETA) ',
  production: '',
};

module.exports = {
  title: `${TITLE_PREFIX[env]}스튜디오메이트`,
};
