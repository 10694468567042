<template>
  <section class="point-detail" v-loading="loading">
    <section class="left">
      <div class="icon-title">
        <img src="@/assets/images/02_icons/icon_current_point.svg" alt="current_point" />
        <span>잔여 포인트</span>
      </div>
      <div class="current-point">{{ $filters.comma(pointDetail.current_point) }}P</div>
    </section>

    <div class="vertical-line" />

    <section class="right">
      <div>
        <div class="icon-title">
          <img src="@/assets/images/02_icons/icon_reward_point.svg" alt="reward_point" />
          <span>적립 포인트</span>
        </div>
        <p>{{ $filters.comma(pointDetail.reward_point) }}P</p>
      </div>

      <div>
        <div class="icon-title">
          <img src="@/assets/images/02_icons/icon_paid_point.svg" alt="paid_point" />
          <span>사용 포인트</span>
        </div>
        <p>{{ $filters.comma(pointDetail.paid_point) }}P</p>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    pointDetail: Object,
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.point-detail {
  @include flex(row, none, space-between);
  margin-bottom: 64px;
  padding: 26px;
  height: 120px;
  border-radius: 16px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

  .icon-title {
    @include flex(row, center, none);

    > img {
      margin-right: 8px;
    }

    span {
      font-size: 16px;
      line-height: 1.4;
      color: #5d6166;
    }
  }

  .left {
    position: relative;
    width: 100%;

    .current-point {
      position: absolute;
      right: 0;
      bottom: -4px;
      font-size: 32px;
      font-weight: 700;
      color: #353a40;
    }
  }

  .vertical-line {
    margin: 0 30px;
    width: 1px;
    background-color: #ebeef5;
  }

  .right {
    @include flex(column, none, space-between);
    width: 100%;
    height: 100%;

    > div {
      @include flex(row, none, space-between);

      > p {
        font-size: 16px;
        font-weight: 700;
        color: rgba(53, 58, 64, 0.8);
      }

      &:last-of-type > p {
        color: rgba(108, 117, 125, 0.8);
      }
    }
  }
}
</style>
