<template>
  <section class="payment-detail">
    <div class="payment-detail__tickets">
      <TicketCard :ticket="userTicket.ticket" :userTicket="userTicket" :showFavoriteIcon="false" />
      <img src="@/assets/images/big-arrow.svg" alt="big arrow" />
      <TicketCard :ticket="ticket" :userTicket="userTicket" :showFavoriteIcon="false" />
    </div>

    <div class="payment-detail__compare">
      <div class="payment-detail__compare__column">
        <h5>양도</h5>
        <h5>결제 금액</h5>

        <PriceInput
          class="payment-detail__price-input blur"
          label="카드"
          labelAlign="row"
          v-model="currentPaymentDetail.card_amount"
          readonly
        />
        <div class="payment-detail__installment">
          <el-radio-group
            :value="currentPaymentDetail.card_amount <= 0 ? null : currentPaymentDetail.installment_period > 1 ? 0 : 1"
            disabled
          >
            <el-radio :label="1">일시불</el-radio>
            <el-radio :label="0">할부</el-radio>
          </el-radio-group>
          <NumberInput
            :value="currentPaymentDetail.card_amount <= 0 ? undefined : currentPaymentDetail.installment_period"
            disabled
            :min="0"
            :max="99"
            suffix="개월"
          />
        </div>
        <PriceInput
          class="payment-detail__price-input blur"
          label="현금"
          labelAlign="row"
          v-model="currentPaymentDetail.cash_amount"
          readonly
        />
        <PriceInput
          class="payment-detail__price-input blur"
          label="계좌이체"
          labelAlign="row"
          v-model="currentPaymentDetail.wiretransfer_amount"
          readonly
        />
        <PointInput label="포인트" labelAlign="row" suffix="P" :value="pointAmount" readonly />
        <PriceInput
          class="payment-detail__price-input total blur second"
          label="총 결제 금액"
          labelAlign="row"
          :value="currentPaymentDetail.amount"
          readonly
        />
        <PriceInput
          class="payment-detail__price-input unpaid blur second"
          label="미수금"
          labelAlign="row"
          v-model="currentPaymentDetail.unpaid_amount"
          readonly
        />
        <div class="payment-detail__payed-on second">
          <label>결제일</label>
          <el-date-picker
            v-model="currentPaymentDetail.settlement_at"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :clearable="false"
            required
            readonly
          />
        </div>
      </div>

      <div class="payment-detail__compare__column">
        <h5>양수 (양도비용)</h5>
        <h5>결제 금액</h5>

        <PriceInput
          class="payment-detail__price-input"
          label="카드"
          labelAlign="row"
          v-model="paymentDetailForm.card_amount"
          @input="handleInputAmount('card_amount')"
        />
        <div class="payment-detail__installment">
          <el-radio-group v-model="paymentDetailForm.installment" :disabled="paymentDetailForm.card_amount <= 0">
            <el-radio :label="1">일시불</el-radio>
            <el-radio :label="0">할부</el-radio>
          </el-radio-group>
          <NumberInput
            v-model="paymentDetailForm.installment_period"
            :disabled="paymentDetailForm.installment === 1 || paymentDetailForm.card_amount <= 0"
            :min="2"
            :max="99"
            suffix="개월"
          />
        </div>
        <PriceInput
          class="payment-detail__price-input"
          label="현금"
          labelAlign="row"
          v-model="paymentDetailForm.cash_amount"
          @input="handleInputAmount('cash_amount')"
        />
        <PriceInput
          class="payment-detail__price-input"
          label="계좌이체"
          labelAlign="row"
          v-model="paymentDetailForm.wiretransfer_amount"
          @input="handleInputAmount('wiretransfer_amount')"
        />
        <div class="payment-detail__point-input">
          <PointInput
            label="포인트"
            labelAlign="row"
            suffix="P"
            v-model="paymentDetailForm.point_amount"
            @input="handleInputAmount('point_amount')"
          />
          <p class="payment-detail__point-input__bottom">
            <span class="primary" @click="handleInputAmount('point_amount', 'useAllPoint')">잔여 포인트</span>
            {{ formatCurrentPoint }} P
          </p>
        </div>
        <PriceInput
          class="payment-detail__price-input total second"
          label="총 결제 금액"
          labelAlign="row"
          :value="transferTotalAmount"
          readonly
        />
        <PriceInput
          class="payment-detail__price-input unpaid second"
          label="미수금"
          labelAlign="row"
          v-model="paymentDetailForm.unpaid_amount"
        />
        <div class="payment-detail__payed-on second">
          <label>양도일</label>
          <el-date-picker
            v-model="transferedOn"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :clearable="false"
            required
          />
        </div>
      </div>
    </div>

    <div class="payment-detail__buttons">
      <el-button @click="prev">
        이전
      </el-button>
      <el-button v-loading="saving" @click="handleClickSave" type="primary">저장</el-button>
    </div>
  </section>
</template>

<script>
import { PAYMENT_VAR } from '@constants';
export default {
  props: {
    userTicket: { type: Object, required: true },
    ticket: { type: Object, required: true },
    saving: { type: Boolean, default: false },
    receivedUserId: Number,
  },

  data() {
    return {
      paymentDetailForm: {
        before_user_ticket_amount: 0,
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        point_amount: 0,
        transfer_amount: 0,
        unpaid_amount: 0,
        installment: 1,
        installment_period: 1,
        method: '',
        settlement_at: this.moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      transferCurrentPoint: 0,
    };
  },

  computed: {
    payments() {
      return _.get(this.userTicket, 'payments', []);
    },

    currentPaymentDetail() {
      let data = {
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        amount: 0,
        transfer_amount: 0,
        unpaid_amount: 0,
      };

      if (this.userTicket) {
        data = this.payments.reduce((total, payment) => {
          Object.keys(data).forEach(key => (total[key] += payment[key]));
          return total;
        }, data);
      }

      const lastPayment = this.payments[this.payments.length - 1];

      return {
        ...data,
        ..._.pick(lastPayment, ['installment_period', 'settlement_at']),
        actual_price: _.get(this.userTicket, 'ticket.actual_price'),
        unpaid_amount: 0,
      };
    },

    transferedOn: {
      get() {
        const { settlement_at } = this.paymentDetailForm;
        return this.moment(settlement_at).format('YYYY-MM-DD');
      },
      set(value) {
        if (!value) return;
        this.paymentDetailForm.settlement_at = `${value} ${this.transferedAt}:00`;
      },
    },

    transferedAt: {
      get() {
        const { settlement_at } = this.paymentDetailForm;
        return this.moment(settlement_at).format('HH:mm');
      },
      set(value) {
        if (!value) return;
        this.paymentDetailForm.settlement_at = `${this.transferedOn} ${value}:00`;
      },
    },

    formatCurrentPoint() {
      return this.$filters.comma(this.transferCurrentPoint);
    },

    pointAmount() {
      return this.payments[this.payments.length - 1].point_amount;
    },

    transferTotalAmount() {
      const { card_amount, cash_amount, wiretransfer_amount, point_amount } = this.paymentDetailForm;
      return card_amount + cash_amount + wiretransfer_amount + point_amount;
    },
  },

  mounted() {
    // 양도할 수강권 최초 발급할 때의 결제 금액을 before_user_ticket_amount에 할당
    const { status, amount, before_user_ticket_amount, unpaid_amount } = this.payments[0];

    if (status === PAYMENT_VAR.purchase) {
      this.paymentDetailForm.before_user_ticket_amount = amount + unpaid_amount;
    } else {
      this.paymentDetailForm.before_user_ticket_amount = before_user_ticket_amount;
    }

    this.dispatchTransferCurrentPoint();
  },

  methods: {
    prev() {
      this.$emit('click-prev');
    },

    handleInputAmount(key, action) {
      // 음수입력 차단
      if (this.paymentDetailForm[key] <= 0 || !this.paymentDetailForm[key]) this.paymentDetailForm[key] = 0;

      const isAction = action === 'useAllPoint';
      if (isAction) {
        this.paymentDetailForm['point_amount'] = this.transferCurrentPoint;
      }

      const { card_amount, cash_amount, wiretransfer_amount, point_amount } = this.paymentDetailForm;
      const sumValue = card_amount + cash_amount + wiretransfer_amount + point_amount;
      this.paymentDetailForm.transfer_amount = sumValue;
    },

    handleClickSave() {
      if (!this.saving) {
        const paymentDetail = this.$utils.mapPaymentData({ ...this.paymentDetailForm });
        this.$emit('click-save', { paymentDetail, currentPoint: this.transferCurrentPoint });
      }
    },

    async dispatchTransferCurrentPoint() {
      try {
        const res = await this.$api.pointHistory.getPointDetail(this.receivedUserId);
        this.transferCurrentPoint = res.data.current_point;
      } catch (error) {
        this.$utils.notify.error(this, '오류', error.response.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-detail {
  &__tickets {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 14px;
    align-items: center;
  }

  &__compare {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 40px 12px;

    &__column {
      display: grid;
      grid-template-rows: repeat(10, 40px);
      grid-gap: 8px;
      align-items: start;

      .second,
      h5 {
        align-self: end;
      }

      &.current {
        padding-top: 32px;
      }

      &:first-child {
        border-right: 1px solid rgba(#000, 0.5);
        padding-right: 42px;
      }
      &:last-child {
        padding-left: 42px;
      }

      &__button {
        margin: auto;
      }
    }
  }

  &__helper-buttons {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 8px;

    button {
      font-size: 12px;
    }
  }

  &__price-input {
    /deep/ .el-input__inner {
      color: $charcoal-grey !important;
    }

    &.total {
      /deep/ .el-input__inner {
        color: $dodger-blue !important;
      }
    }

    &.unpaid {
      /deep/ .el-input__inner {
        color: $color-danger !important;
      }
    }

    &.blur {
      /deep/ .el-input__inner {
        opacity: 0.5;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  &__installment {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 8px;
    align-items: center;

    /deep/ .el-radio__label {
      padding-left: 4px;
    }

    /deep/ .el-radio + .el-radio {
      margin-left: 8px;
    }
  }

  &__payed-on {
    display: grid;
    grid-template-columns: 44px 1fr;
    grid-gap: 16px;
    align-items: center;

    /deep/ .el-date-editor {
      width: 100%;
    }

    /deep/ .el-input__inner {
      padding: 0 30px;
      text-align: right;
    }

    label {
      font-size: 12px;
      font-weight: bold;
    }
  }

  &__buttons {
    @include flex(row, flex-end, space-between);
    border-top: 1px solid #f0f0f0;
    flex: 1;
    width: 100%;
    padding: 20px 20px 0;
  }

  &__point-input {
    display: flex;
    flex-direction: column;

    &__bottom {
      align-self: flex-end;
      margin: 2px 6px 0 0;
      font-size: 12px;
      color: #606266;

      .primary:hover {
        cursor: pointer;
        font-weight: 500;
        color: $color-primary;
      }
    }
  }
}
</style>
