import api from '@api';

const DEFAULT_LOCKER_MAKE_DATA = {
  currentStep: 1,
  type: null,
  status: null,
  userName: null,
  memberId: null,
  lockerName: null,
  start_on: null,
  end_on: null,
  etc: null,
  lockerId: null,
  edit: null,
  no: null,
};

export const state = {
  locker: [],
  initLocker: [],
  loading: false,
  keyword: null,
  formData: {
    ...DEFAULT_LOCKER_MAKE_DATA,
  },
};

export const getters = {
  locker: state => state.locker,
  loading: state => state.loading,
  keyword: state => state.keyword,
  formData: state => state.formData,
};

export const mutations = {
  SET_LOCKER(state, params) {
    state.locker = params;
    state.initLocker = params;
  },
  SET_FILTER_LOCKER(state, lockers) {
    state.locker = lockers;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_VALUES(state, values) {
    state.formData = !values ? { ...DEFAULT_LOCKER_MAKE_DATA } : { ...state.formData, ...values };
  },
  SET_KEYWORD(state, keyword) {
    state.keyword = keyword;
  },
};

export const actions = {
  async getLockers({ commit }) {
    try {
      commit('SET_LOADING', true);
      const res = await api.locker.get();
      commit('SET_LOCKER', res.data);
    } catch (error) {
      commit('SET_LOADING', false);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async increseLockerCount({ commit }, data) {
    commit('SET_LOADING', true);
    try {
      const res = await api.locker.create(data);
      if (res.status === 200) {
        const res = await api.locker.get();
        commit('SET_LOCKER', res.data);
      }
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOCKER', []);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async assignLocker({ commit }, data) {
    commit('SET_LOADING', true);
    commit('SET_VALUES', data);
    commit('SET_LOADING', false);
  },

  async saveLockerInfo({ commit }, data) {
    commit('SET_LOADING', true);
    try {
      const res = await api.locker.update(data);
      if (res.status === 200) {
        const res = await api.locker.get();
        commit('SET_LOCKER', res.data);
      }
      return res;
    } catch (error) {
      commit('SET_LOCKER', []);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  filterLocker({ commit, state }, keyword) {
    const newLocker = [...state.initLocker];

    commit('SET_LOADING', true);
    commit('SET_KEYWORD', keyword);
    commit(
      'SET_FILTER_LOCKER',
      keyword ? newLocker.filter(({ member }) => member && member.name.indexOf(keyword) !== -1) : newLocker,
    );
    commit('SET_LOADING', false);
  },
};
