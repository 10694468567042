<template>
  <div class="container">
    <TrendChartNew
      :category="category"
      :categoryOptions="categoryOptions"
      :chartData="trendChartData"
      :changeFilter="changeFilter"
      v-loading="loading"
    />
    <CategoryChartNew
      v-for="(categoryChartData, i) in categoryChartDatas"
      :title="categoryChartData.title"
      :chartData="categoryChartData.categoryChartData"
      :key="i"
      v-loading="loading"
    />
  </div>
</template>

<script>
import moment from 'moment';
import TrendChartNew from '@components/Sales/TrendChartNew';
import CategoryChartNew from '@components/Sales/CategoryChartNew';

export default {
  components: {
    TrendChartNew,
    CategoryChartNew,
  },
  data() {
    return {
      loading: false,
      wholeData: [],
      category: 'paymentType',
      categoryOptions: [
        { value: 'paymentType', label: '결제구분' },
        { value: 'classType', label: '수업구분' },
        { value: 'paymentMethod', label: '결제방법' },
        { value: 'ticket', label: '수강권명' },
      ],
      filterOptions: {
        paymentType: [
          { value: 'new', label: '신규결제' },
          { value: 're-take', label: '재결제' },
          { value: 'experience', label: '체험' },
          { value: 'refund', label: '환불' },
          { value: 'upgrade', label: '업그레이드' },
          { value: 'commission', label: '양도 / 환불 수수료' },
          { value: 'installment_payment', label: '미수금 결제' },
        ],
        classType: [
          { value: 'P', label: '프라이빗' },
          { value: 'G', label: '그룹' },
        ],
        ticket: [],
        paymentMethod: [
          { value: 'card', label: '카드' },
          { value: 'cash', label: '현금' },
          { value: 'wiretransfer', label: '계좌이체' },
          { value: 'point', label: '포인트' },
          { value: 'etc', label: '선택안함' },
        ],
      },
    };
  },

  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },
    refreshAll() {
      return this.$store.getters['salesNew/refreshAll'];
    },
    trendStartDate() {
      if (this.date.rangeType === 'day') {
        return moment(this.date.date)
          .subtract(6, 'day')
          .format('YYYY-MM-DD');
      } else if (this.date.rangeType === 'week') {
        return moment(this.date.date)
          .subtract(4, 'week')
          .format('YYYY-MM-DD');
      } else {
        return moment(this.date.date)
          .subtract(3, 'month')
          .format('YYYY-MM-DD');
      }
    },

    trendDateRanges() {
      const { rangeType } = this.date;
      const dateRanges = [];
      if (rangeType === 'day') {
        for (let i = 0; i < 7; i++) {
          dateRanges.push({
            start: moment(this.trendStartDate)
              .add(i, 'day')
              .format('YYYY-MM-DD'),
            end: moment(this.trendStartDate)
              .add(i, 'day')
              .format('YYYY-MM-DD'),
          });
        }
      } else if (rangeType === 'week') {
        for (let i = 0; i < 5; i++) {
          dateRanges.push({
            start: moment(this.trendStartDate)
              .add(i, 'week')
              .format('YYYY-MM-DD'),
            end: moment(this.trendStartDate)
              .add(i, 'week')
              .add(6, 'day')
              .format('YYYY-MM-DD'),
          });
        }
      } else {
        for (let i = 0; i < 4; i++) {
          dateRanges.push({
            start: moment(this.trendStartDate)
              .add(i, 'month')
              .format('YYYY-MM-DD'),
            end: moment(this.trendStartDate)
              .add(i + 1, 'month')
              .subtract(1, 'day')
              .format('YYYY-MM-DD'),
          });
        }
      }
      return dateRanges;
    },

    trendLabels() {
      const { rangeType } = this.date;
      if (rangeType === 'day') {
        return this.trendDateRanges.map(range => moment(range.start).format('M월 D일 (ddd)'));
      } else if (rangeType === 'week') {
        return this.trendDateRanges.map(
          range => `${moment(range.start).format('M월 D일')} ~ ${moment(range.end).format('M월 D일')}`,
        );
      } else {
        return this.trendDateRanges.map(range => moment(range.start).format('YY년 M월'));
      }
    },

    trendDatasets() {
      const types = this.filterOptions[this.category];
      const datasets = types.map((type, i) => {
        const typeDatas = this.wholeData.filter(data => data[this.category] === type.value);

        const datas = this.trendDateRanges.map(range =>
          typeDatas
            .filter(
              typeData =>
                moment(typeData.settlement_at).isAfter(moment(range.start)) &&
                moment(typeData.settlement_at).isBefore(moment(range.end).endOf('day')),
            )
            .reduce((prev, cur) => {
              return prev + cur.amount;
            }, 0),
        );
        return {
          backgroundColor: this.$utils.salesColors[this.category][i % 11],
          data: datas,
          label: type.label,
          stack: 1,
          type: 'bar',
          yAxisID: 'amount',
        };
      });
      return datasets;
    },

    trendChartData() {
      return { labels: this.trendLabels, datasets: this.trendDatasets };
    },

    categoryChartDatas() {
      const curData = this.wholeData.filter(
        dataItem =>
          moment(dataItem.settlement_at).isAfter(moment(this.date.date)) &&
          moment(dataItem.settlement_at).isBefore(moment(this.date.endDate).endOf('day')),
      );
      const data = Object.keys(this.filterOptions).map(type => {
        const labels = this.filterOptions[type].map(option => option.label);
        let countData;
        let amountData;
        if (type === 'paymentMethod') {
          countData = [0, 0, 0, 0, 0];
          amountData = [0, 0, 0, 0, 0];
          curData.forEach(data => {
            if (data.card_amount !== 0) {
              countData[0]++;
              amountData[0] += data.card_amount;
            }
            if (data.cash_amount !== 0) {
              countData[1]++;
              amountData[1] += data.cash_amount;
            }
            if (data.wiretransfer_amount !== 0) {
              countData[2]++;
              amountData[2] += data.wiretransfer_amount;
            }
            if (data.point_amount && data.point_amount !== 0) {
              countData[3]++;
              amountData[3] += data.point_amount;
            }
            if (
              data.amount > 0 &&
              data.card_amount === 0 &&
              data.cash_amount === 0 &&
              data.wiretransfer_amount === 0 &&
              data.point_amount === 0
            ) {
              countData[4]++;
              amountData[4] += data.amount;
            }
          });
        } else {
          countData = this.filterOptions[type].map(option => curData.filter(dataItem => dataItem[type] === option.value).length);
          amountData = this.filterOptions[type].map(option =>
            curData.filter(dataItem => dataItem[type] === option.value).reduce((prev, cur) => prev + cur.amount, 0),
          );
        }

        const datasets = {
          backgroundColor: this.$utils.salesColors[type],
          countData: countData,
          data: amountData,
          type: 'pie',
        };
        return { title: this.getTitle(type), categoryChartData: { labels, datasets: [datasets] } };
      });
      return data;
    },
  },

  watch: {
    date: function() {
      this.fetchData();
    },
    refreshAll: function(value) {
      if (value) {
        this.fetchData();
      }
    },
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const params = {
          start_date: this.trendStartDate,
          end_date: this.date.endDate,
        };
        const data = await this.$utils.promiseAllData(this.$api.salesNew.getTicketSalesData, params);
        const wholeData = [];
        const tickets = [];
        const ticketIdSet = new Set();
        data.forEach(item => {
          if (
            moment(item.settlement_at).isAfter(moment(this.date.date)) &&
            moment(item.settlement_at).isBefore(moment(this.date.endDate).endOf('day')) &&
            !ticketIdSet.has(item.ticket_id)
          ) {
            tickets.push({ value: item.ticket_id, label: item.ticket_name });
            ticketIdSet.add(item.ticket_id);
          }
          const { type, ticket_id: ticket, class_type: classType, method: paymentMethod, settlement_at } = item;
          let paymentType = type;
          let amount = item.amount.amount;
          let { card_amount, cash_amount, wiretransfer_amount, point_amount } = item.amount;
          if (type === 'refund') {
            wholeData.push({
              amount: -amount,
              paymentType,
              ticket,
              classType,
              paymentMethod,
              settlement_at,
              card_amount: -card_amount,
              cash_amount: -cash_amount,
              wiretransfer_amount: -wiretransfer_amount,
            });
            if (item.amount.commission_amount > 0) {
              amount = item.amount.commission_amount;
              card_amount = 0;
              cash_amount = 0;
              wiretransfer_amount = 0;
              paymentType = 'commission';
            } else {
              return;
            }
          } else if (type === 'transfer') {
            paymentType = 'commission';
          }
          wholeData.push({
            amount,
            paymentType,
            ticket,
            classType,
            paymentMethod,
            settlement_at,
            card_amount,
            cash_amount,
            wiretransfer_amount,
            point_amount,
          });
        });
        this.wholeData = wholeData;
        this.filterOptions.ticket = tickets;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.$store.dispatch('salesNew/setRefreshAll', false);
        this.loading = false;
      }
    },

    changeFilter(value) {
      this.category = value;
    },

    getTitle(type) {
      return this.categoryOptions.find(option => option.value === type).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: auto;
  padding: 0 30px 30px 30px;
  max-width: 1440px;
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  & > *:first-child {
    grid-column: 1 / 3;
  }

  @include mq-max(968px) {
    grid-template-columns: repeat(1, 1fr);
    & > *:first-child {
      grid-column: 1 / 2;
    }
  }
}
</style>
