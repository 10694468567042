<template>
  <el-dialog :visible="open" :show-close="false">
    <h3>비밀번호 변경</h3>
    <form @submit.prevent="handleSubmit()">
      <Input
        validType="password"
        ref="current_password"
        label="사용중인 비밀번호"
        type="password"
        placeholder="사용중인 비밀번호를 입력해주세요"
        v-model="current_password"
        @input="$v.current_password.$touch()"
        :error_message="current_password_error"
      />
      <Input
        validType="password"
        ref="new_password"
        label="변경할 비밀번호"
        type="password"
        placeholder="변경하실 비밀번호를 입력해주세요"
        v-model="new_password"
        @input="$v.new_password.$touch()"
        :error_message="new_password_error"
      />
      <Input
        validType="password"
        ref="new_password_confirmation"
        label="비밀번호 재입력"
        type="password"
        placeholder="변경하실 비밀번호를 한번 더 입력해주세요"
        v-model="new_password_confirmation"
        @input="$v.new_password_confirmation.$touch()"
        :error_message="new_password_confirmation_error"
      />

      <div class="password-reset-dialog__buttons">
        <el-button @click="onClose">
          취소
        </el-button>
        <el-button native-type="submit" type="primary" :disabled="submit_disabled">변경 저장</el-button>
      </div>
    </form>
  </el-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';

const alphaNum = value => /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,32}$/.test(value);

export default {
  props: {
    open: Boolean,
    onClose: Function,
  },

  data() {
    return {
      current_password: '',
      new_password: '',
      new_password_confirmation: '',
    };
  },

  computed: {
    current_password_error() {
      return this.$v.current_password.$dirty && !this.$v.current_password.required ? '사용중인 비밀번호를 입력해주세요' : '';
    },

    new_password_error() {
      let message = '';

      if (this.$v.new_password.$dirty && !this.$v.new_password.required) {
        message = '변경할 비밀번호를 입력해주세요';
      } else if (
        this.$v.new_password.$dirty &&
        (!this.$v.new_password.minLength || !this.$v.new_password.maxLength || !this.$v.new_password.alphaNum)
      ) {
        message = '비밀번호는 영문, 숫자를 포함하여 8-32자리로 입력해주세요';
      }

      return message;
    },

    new_password_confirmation_error() {
      let message = '';

      if (this.$v.new_password_confirmation.$dirty && !this.$v.new_password_confirmation.required) {
        message = '변경할 비밀번호를 한번 더 입력해주세요';
      } else if (this.$v.new_password_confirmation.$dirty && !this.$v.new_password_confirmation.sameAs) {
        message = '비밀번호를 동일하게 입력해주세요';
      }

      return message;
    },

    submit_disabled() {
      return this.$v.current_password.$invalid || this.$v.new_password.$invalid || this.$v.new_password_confirmation.$invalid;
    },
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    current_password: { required },
    new_password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(32),
      alphaNum,
    },
    new_password_confirmation: {
      required,
      sameAs: sameAs('new_password'),
    },
  },

  created() {
    this.$nextTick(() => {
      this.$refs['current_password'].$el.querySelector('input').focus();
    });
  },

  methods: {
    async handleSubmit() {
      if (!this.submit_disabled) {
        const data = {
          current_password: this.current_password,
          new_password: this.new_password,
          new_password_confirmation: this.new_password_confirmation,
        };

        try {
          await this.$api.auth.updateMyPassword(data);
          this.$utils.notify.success(this, '확인', '비밀번호가 변경되었습니다.');
          return this.onClose();
        } catch (error) {
          if (error.response.status === 400) {
            this.$utils.notify.error(this, '오류', '사용중인 비밀번호가 맞지 않습니다.');
          } else if (error.response.status === 422) {
            this.$utils.notify.error(this, '오류', '사용중인 비밀번호와 변경할 비밀번호가 달라야 합니다.');
          } else {
            this.$utils.notify.parseError(this, error);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
h3 {
  text-align: center;
  margin-bottom: 32px;
}

.password-reset-dialog__buttons {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
</style>
