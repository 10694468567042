<template>
  <el-card class="category-chart" shadow="hover">
    <div class="category-chart__header">
      <h4>{{ title }}</h4>
    </div>
    <div v-if="isDataReady" class="category-chart__chart">
      <PieChart :chartData="chartData" :options="chartOptions" />
    </div>
    <div class="category-chart__legend">
      <ul v-if="isDataReady">
        <li v-for="(legend, index) in legends" :key="`${legend.label}${index}`">
          <label>{{ legend.label }}</label>
          <span class="category-chart__legend__count">{{ legend.count | comma }}건</span>
          <span class="category-chart__legend__value">{{ legend.value | comma }}원</span>
        </li>
      </ul>
      <p class="category-chart__no-data" v-else>표시할 데이터가 없습니다.</p>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    title: String,
    chartData: Object,
  },

  computed: {
    isDataReady() {
      const labels = this._.get(this.chartData, 'labels');
      const data = this._.get(this.chartData, 'datasets[0].data');
      const hasNoLabels = !labels || !labels.length;
      const hasNoData = !data || !data.length || data.every(value => !value);
      return !hasNoLabels && !hasNoData;
    },

    legends() {
      const labels = this._.get(this.chartData, 'labels');
      const data = this._.get(this.chartData, 'datasets[0].data');
      const countData = this._.get(this.chartData, 'datasets[0].countData');
      const legends = labels.map((label, index) => ({
        label,
        value: data[index],
        count: countData[index],
      }));
      return legends;
    },

    chartOptions() {
      return {
        responsive: true,
        tooltips: {
          mode: 'point',
          bodyFontSize: 15,
          bodySpacing: 7,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            label(tooltipItem, { labels }) {
              return labels[tooltipItem.index];
            },
          },
        },
        legend: {
          display: false,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.category-chart {
  /deep/ .el-card__body {
    display: grid;
    grid-template-columns: auto minmax(150px, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'header chart'
      'legend legend';
    grid-gap: 20px;
    min-height: 300px;

    @include mq(568px) {
      grid-template-columns: auto minmax(200px, 1fr);
    }
  }

  &__header {
    grid-area: header;
  }

  &__chart {
    grid-area: chart;
    height: 150px;
    width: 150px;
    justify-self: end;

    @include mq(568px) {
      height: 180px;
      width: 180px;
    }
  }

  &__legend {
    grid-area: legend;
    align-self: start;

    ul {
      overflow-y: hidden;

      li {
        display: grid;
        align-items: center;
        grid-template-columns: 65px 55px 1fr;
        grid-gap: 10px;
        font-size: 16px;
        padding: 2px 5px 3px 5px;
        transition: 0.15s background;

        &:hover {
          background: $gray-100;
        }

        @include mq(568px) {
          grid-template-columns: 120px 70px 1fr;
        }

        label {
          @include ellipsis;
          font-size: 10px;
          font-weight: 400;
          margin: 0;

          @include mq(568px) {
            font-size: 12px;
          }
        }

        span.category-chart__legend__count {
          font-size: 10px;
          text-align: right;

          @include mq(568px) {
            font-size: 12px;
          }
        }

        span.category-chart__legend__value {
          font-size: 12px;
          text-align: right;

          @include mq(568px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  &__no-data {
    opacity: 0.6;
  }
}
</style>
