<template>
  <el-dialog class="sms-auto-message-dialog" :visible="show" @close="$emit('close', false)">
    <h3 slot="title">문자 메시지 발송 결과</h3>
    <el-table
      v-if="detailData"
      :data="detailData"
      :default-sort="{ prop: 'name', order: 'ascending' }"
      :row-style="{ height: '40px' }"
      :height="resultListHeight"
      fit
    >
      <el-table-column label="발송시간" align="center" min-width="100">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="회원명" align="center" min-width="100">
        <div class="name-wrapper" slot-scope="scope">
          <span>{{ scope.row.member.name }}</span>
          <vaccine-image v-if="getVaccination(scope.row)" />
        </div>
      </el-table-column>
      <el-table-column label="전화번호" align="center" min-width="100">
        <template slot-scope="scope">
          {{ scope.row.member.mobile | mobile }}
        </template>
      </el-table-column>
      <el-table-column label="결과" align="center" min-width="100">
        <span class="hint-box" slot="header">
          <p>결과</p>
          <el-tooltip effect="light" content="'처리중..' 이 지속되면 새로고침 해주세요" placement="top">
            <i class="el-icon-question" :style="{ color: '#64AEFF', fontSize: '18px' }" />
          </el-tooltip>
        </span>
        <template slot-scope="scope">
          {{ scope.row.status }}
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="$emit('close', false)"> 닫기 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import VaccineImage from '@/components/shared/VaccineImage.vue';
export default {
  components: { VaccineImage },
  props: {
    show: Boolean,
    close: Function,
    detailData: Array,
  },

  data() {
    return {
      resultListHeight: 0,
    };
  },

  created() {
    this.$nextTick(() => {
      this.resultListHeight = window.innerHeight * 0.45;
    });
  },

  methods: {
    getVaccination(row) {
      const vaccine = _.get(row, 'studio_user.vaccination_yn');
      return vaccine === 'Y';
    },
  },
};
</script>

<style lang="scss" scoped>
.sms-auto-message-dialog {
  .hint-box {
    display: flex;
    justify-content: center;
  }

  /deep/ .el-icon-question {
    line-height: 24px;
    margin-left: 5px;
  }
}
</style>
