<template>
  <MainLayout v-loading="loading || processing">
    <HeaderTabs activeTab="/lectures" />

    <div class="lectures-header">
      <ListFilter
        :filterValues="filter"
        :filterOptions="filterOptions"
        :total="total"
        :selectedLecturesCount="lecturesSelected.length"
        @change="handleFilterChange"
        @reset-click="handleFilterChange"
      />

      <div class="lectures-header__buttons">
        <PlainButton type="danger" :disabled="!lecturesSelected.length" @click="handleClickDeleteLectures">수업 삭제</PlainButton>
        <PlainButton :disabled="!lecturesSelected.length" @click="handleClickUpdateLectures">일괄 수정</PlainButton>
        <BaseButton v-if="isStudioOwner || canExcelDownloadMembers" @click="handleClickExcelDown">엑셀 다운</BaseButton>
      </div>
    </div>

    <List
      :lectures="lectures"
      :lectureIdsSelected="lectureIdsSelected"
      @toggle-selection="handleToggleSelection"
      @toggle-select-all="handleToggleSelectAll"
      :selectedAll="isSelectedAll"
      :indeterminate="isIndeterminate"
      :order="filter.sort_by_type"
      @sort-change="handleSortChange"
    />

    <el-pagination
      class="lectures__pagination"
      :current-page.sync="page"
      :page-size="limit"
      :page-sizes="[10, 15, 20, 50]"
      @size-change="limit => handleFilterChange({ limit }, false)"
      layout="prev, pager, next, sizes"
      :total="total"
    />

    <BulkUpdateLecturesDialog
      :show="showBulkUpdateLecturesDialog"
      :instructorOptions="_.get(filterOptions, 'activeInstructors.options', [])"
      :roomOptions="_.get(filterOptions, 'rooms.options', [])"
      :lectures="lecturesSelected"
      @close="handleBulkUpdateLecturesDialogClose"
    />
  </MainLayout>
</template>

<script>
import HeaderTabs from '@components/Lectures/HeaderTabs';
import ListFilter from '@components/Lectures/LectureListFilter';
import List from '@components/Lectures/LectureList';
import BulkUpdateLecturesDialog from '@components/Modals/BulkUpdateLectures';

export default {
  components: {
    HeaderTabs,
    ListFilter,
    List,
    BulkUpdateLecturesDialog,
  },

  data() {
    return {
      instructors: [],
      divisions: [],
      rooms: [],

      lecturesSelected: [],
      processing: false,
      showBulkUpdateLecturesDialog: false,
    };
  },

  computed: {
    isStudioOwner() {
      return this.$store.getters['auth/isStudioOwner'];
    },
    loading() {
      return this.$store.getters['lectures/loading'];
    },
    lectures() {
      return this.$store.getters['lectures/lectures'];
    },
    total() {
      return this.$store.getters['lectures/total'];
    },
    filter() {
      return this.$store.getters['lectures/filter'];
    },

    page: {
      get() {
        return this.filter.page;
      },
      set(page) {
        this.handleFilterChange({ page }, false);
      },
    },

    limit: {
      get() {
        return this.filter.limit;
      },
      set(limit) {
        this.handleFilterChange({ limit }, false);
      },
    },

    filterOptions() {
      const instructorFiltering = (type = null) => {
        return this.instructors.filter(({ id, deleted_at }) => {
          if (!this.canViewOthersGroupLecture && !this.canViewOthersPrivateLecture && id !== _.get(this.currentUser, 'id')) {
            return false;
          }
          return type === 'deleted' ? deleted_at : !deleted_at;
        });
      };

      const staffs = instructorFiltering();
      const deletedStaffs = instructorFiltering('deleted');
      const allStaffs = staffs.concat(deletedStaffs);
      const formatInstructorOptions = ({ id, profile, deleted_at }) => {
        if (!profile) {
          // QA서버 테스트 계정 대응
          return { value: id, label: '프로필 없는 계정', deleted_at };
        } else {
          return { value: id, label: profile.name, deleted_at };
        }
      };

      const instructorOptions = allStaffs.map(formatInstructorOptions);
      const activeInstructorOptions = staffs.map(formatInstructorOptions);

      const divisionOptions = this.divisions.map(({ id, title }) => ({
        value: id,
        label: title,
      }));
      const roomOptions = this.rooms.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      return {
        weekdays: {
          multiple: true,
          placeholder: '모든 요일',
          options: [
            { value: 1, label: '월' },
            { value: 2, label: '화' },
            { value: 3, label: '수' },
            { value: 4, label: '목' },
            { value: 5, label: '금' },
            { value: 6, label: '토' },
            { value: 0, label: '일' },
          ],
        },
        instructors: {
          multiple: true,
          placeholder: '강사 전체',
          options: instructorOptions,
        },
        activeInstructors: {
          multiple: true,
          placeholder: '강사 전체(삭제 제외)',
          options: activeInstructorOptions,
        },
        courseType: {
          multiple: false,
          placeholder: '수업 전체',
          options: [
            { value: 'G', label: '그룹' },
            { value: 'P', label: '프라이빗' },
          ],
        },
        divisions: {
          multiple: true,
          placeholder: '수업구분 전체',
          options: divisionOptions,
        },
        rooms: {
          multiple: true,
          placeholder: '룸 전체',
          options: roomOptions,
        },
      };
    },

    lectureIdsSelected() {
      return this.lecturesSelected.map(({ id }) => id);
    },

    lectureIdsOnPage() {
      return this.lectures.map(({ id }) => id);
    },

    isSelectedAll() {
      return !!this.lectures.length && this.lectureIdsOnPage.every(id => this.lectureIdsSelected.includes(id));
    },

    isIndeterminate() {
      return !this.isSelectedAll && this.lectureIdsOnPage.some(id => this.lectureIdsSelected.includes(id));
    },

    canViewOthersLecture() {
      return this.canViewOthersGroupLecture || this.canViewOthersPrivateLecture;
    },

    canDeleteLectures() {
      return this.lecturesSelected.every(lecture => {
        const courseType = _.get(lecture, 'type');
        const instructorId = _.get(lecture, 'staff.id');
        return this.canDeleteLecture(courseType, instructorId);
      });
    },

    canUpdateLectures() {
      return this.lecturesSelected.every(lecture => {
        const courseType = _.get(lecture, 'type');
        const instructorId = _.get(lecture, 'staff.id');
        return this.canUpdateLecture(courseType, instructorId);
      });
    },
  },

  watch: {
    filter: 'dispatchGetLectures',
    canViewOthersGroupLecture: {
      handler(value) {
        if (!value && this.currentUser) {
          const filter = { instructors: [_.get(this.currentUser, 'id')] };
          this.$store.commit('lectures/SET_FILTER', { filter });
        }
      },
      immediate: true,
    },
  },

  created() {
    this.getInstructors();
    this.getDivisions();
    this.getRooms();
  },

  methods: {
    dispatchGetLectures() {
      this.$store.dispatch('lectures/getLectures');
    },

    async getInstructors() {
      try {
        const res = await this.$api.staff.getAllWithDeleted();
        this.instructors = res.data.deletedStaffs;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    async getDivisions() {
      try {
        const res = await this.$api.studio.divisions.get();
        this.divisions = res.data;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    async getRooms() {
      try {
        const res = await this.$api.studio.rooms.get();
        this.rooms = res.data;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    handleFilterChange(filterValues, resetSelection = true) {
      const filter =
        !this.canViewOthersLecture && this.currentUser
          ? { ...filterValues, instructors: [_.get(this.currentUser, 'id')] }
          : filterValues;
      this.$store.commit('lectures/SET_FILTER', {
        filter,
        reset: !filterValues,
      });
      if (resetSelection) this.lecturesSelected = [];
    },

    handleToggleSelection(lecture) {
      if (!this.lectureIdsSelected.includes(lecture.id)) {
        this.lecturesSelected.push(lecture);
      } else {
        this.lecturesSelected = this.lecturesSelected.filter(({ id }) => id !== lecture.id);
      }
    },

    handleSortChange(order) {
      this.handleFilterChange({ sort_by_type: order });
    },

    handleToggleSelectAll() {
      if (!this.isSelectedAll) {
        this.lecturesSelected = _.uniqBy([...this.lecturesSelected, ...this.lectures], 'id');
      } else {
        this.lecturesSelected = this.lecturesSelected.filter(({ id }) => !this.lectureIdsOnPage.includes(id));
      }
    },

    async handleClickDeleteLectures() {
      if (!this.canDeleteLectures) {
        return this.$utils.notify.error(this, '오류', '삭제 권한이 없는 수업이 선택되었습니다.');
      }

      const { comma } = this.$filters;

      const title = '수업 삭제';
      const message = `
          <p>${comma(this.lectureIdsSelected.length)}개의 수업을 삭제하시겠습니까?</p>
          <p style="color: #f95454;">
            지난 수업을 삭제할 경우 회원의 예약은 취소되고 횟수는 복구됩니다.
          </p>
        `;
      const proceed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (!proceed) return;

      try {
        this.processing = true;
        await this.$api.lecture.delete(this.lectureIdsSelected);
        const count = this.$filters.comma(this.lectureIdsSelected.length);
        this.$utils.notify.success(this, '확인', `${count}개의 수업이 삭제되었습니다.`);
        this.dispatchGetLectures();
        this.lecturesSelected = [];
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.processing = false;
      }
    },

    handleClickUpdateLectures() {
      if (!this.canUpdateLectures) {
        return this.$utils.notify.error(this, '오류', '수정 권한이 없는 수업이 선택되었습니다.');
      }

      this.showBulkUpdateLecturesDialog = true;
    },

    handleBulkUpdateLecturesDialogClose(refresh) {
      this.showBulkUpdateLecturesDialog = false;
      this.lecturesSelected = [];
      if (refresh) {
        this.dispatchGetLectures();
      }
    },

    async handleClickExcelDown() {
      this.processing = true;

      /** 데이터 가공 */
      const lectures = await this.$store.dispatch('lectures/getLecturesForExcel');
      const json = this.formatJSON(lectures);

      /** 제목 생성 */
      const [start, end] = this.filter.dateRange;
      let range = start;
      if (start !== end) {
        range += `_${end}`;
      }
      const title = `수업목록_${range}.xlsx`;

      /** 다운로드 */
      this.$utils.downloadExcel(json, title);

      this.processing = false;
    },

    formatJSON(lectures) {
      const { courseType } = this.$utils.translate;

      return lectures.map(
        ({
          start_on,
          end_on,
          staff,
          type,
          title,
          division,
          room,
          max_trainee,
          min_trainee,
          waiting_trainee_limit,
          booking_start_at,
          booking_end_at,
          booking_cancel_start_at,
          booking_cancel_end_at,
          autoclose_at,
        }) => {
          /** 예약가능시간 */
          let bookableTimeRange = '';
          if (!booking_start_at && !booking_end_at) {
            bookableTimeRange = '-';
          } else if (!booking_start_at) {
            bookableTimeRange = `${booking_end_at} 까지`;
          } else {
            bookableTimeRange = `${booking_start_at} ~ ${booking_end_at}`;
          }

          /** 취소가능시간 */
          let cancellableTimeRange = '';
          if (!booking_cancel_start_at && !booking_cancel_end_at) {
            cancellableTimeRange = '-';
          } else if (!booking_cancel_start_at) {
            cancellableTimeRange = `${booking_cancel_end_at} 까지`;
          } else {
            cancellableTimeRange = `${booking_cancel_start_at} ~ ${booking_cancel_end_at}`;
          }

          /** 자동폐강시간 */
          const autocloseDeadline = autoclose_at || '-';

          return {
            수업일: this.moment(start_on).format('YYYY-MM-DD'),
            수업시작시간: this.moment(start_on).format('HH:mm'),
            수업종료시간: this.moment(end_on).format('HH:mm'),
            강사: _.get(staff, 'name'),
            수업: courseType(type),
            수업명: title,
            수업구분: division,
            룸: _.get(room, 'name'),
            최대수강인원: max_trainee,
            최소수강인원: min_trainee,
            예약대기가능인원: type === 'P' ? null : waiting_trainee_limit || '무제한',
            예약가능시간: bookableTimeRange,
            취소가능시간: cancellableTimeRange,
            폐강시간: autocloseDeadline,
          };
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.lectures {
  &-header {
    @include flex(row, flex-start, space-between);
    flex-wrap: wrap;

    @include mq(1280px) {
      flex-wrap: nowrap;
    }

    &__buttons {
      margin-left: auto;
      display: grid;
      grid-template-columns: repeat(3, 80px);
      grid-gap: 8px;

      @include mq(1280px) {
        margin: 0;
      }

      button {
        width: 80px;
        height: 36px;
        border-radius: 18px !important;
      }
    }
  }

  &__pagination {
    @include flex(row, center, center);
    margin-top: 36px;

    /deep/ .btn-prev {
      margin-left: auto;
    }

    /deep/ .btn-next {
      margin-right: auto;
    }

    /deep/ .el-pagination__sizes {
      margin: 0;

      /deep/ .el-input__inner {
        @extend %input-default;
        border-color: #dcdfe6;
        height: 36px;
      }

      /deep/ .el-input__suffix {
        transform: scale(1);
        padding-right: 8px;
      }
    }
  }
}
</style>
