import axios from '../axios';

const PAYMENT_URL = '/v2/staff/token-payment';
const PAYMENT_POLICY_URL = '/v2/staff/payment-policy';

export default {
  getSmsProducts: () => {
    return axios.get(`${PAYMENT_URL}/point/products`);
  },
  getServicePlanProducts: () => axios.get(`${PAYMENT_URL}/service/products`),

  getCards: () => axios.get(`${PAYMENT_URL}/card`), // GET
  registCardApi: body => axios.post(`${PAYMENT_URL}/card/add-token-card`, body),
  deleteCard: id => axios.delete(`${PAYMENT_URL}/card/${id}`), // DELETE

  requestPayNow: ({ point_id, card_id }, body) => {
    return axios
      .post(`${PAYMENT_URL}/point/${card_id}/${point_id}`, body)
      .then(response => response)
      .catch(error => error.response);
  }, //포인트 단건 결제
  requestPayment: ({ subscription_id, card_id }, body) =>
    axios.post(`${PAYMENT_URL}/service/${card_id}/${subscription_id}`, body), // 서비스 단건 결제
  // 결제 정보
  getPaymentPolicy: () => axios.get(`${PAYMENT_POLICY_URL}`), // GET
  patchPaymentPolicy: body => axios.patch(`${PAYMENT_POLICY_URL}`, body), // PATCH
  getSMSPaymentHistory: params =>
    axios.get(`${PAYMENT_URL}/point`, {
      params,
    }),
  getServicePaymentHistory: params =>
    axios.get(`${PAYMENT_URL}/service`, {
      params,
    }),

  getServicePaymentInfo: () => axios.get(`${PAYMENT_URL}/service/payment`),
};
