<template>
  <section>
    <template v-if="isPrivateStartIntervalOn">
      <div class="staff-basic__header">
        <h4>프라이빗 수업 예약 시간 단위 설정</h4>
      </div>
      <div class="block">
        <div class="line">
          오전 <span class="time">00 : 00 ~ 12 : 00</span>
          <span class="interval">{{ staff.profile.private_start_interval_am === 30 ? '30분으로 표시' : '정시로 표시' }}</span>
        </div>
        <div class="line">
          오후 <span class="time">13 : 00 ~ 24 : 00</span>
          <span class="interval">{{ staff.profile.private_start_interval_pm === 30 ? '30분으로 표시' : '정시로 표시' }}</span>
        </div>
      </div>
    </template>
    <div class="staff-basic__header">
      <h4>근무시간</h4>
    </div>
    <div class="staff-basic__staff-working-hours">
      <ul>
        <li v-for="(el, index) in workingHoursByWeekday" :key="index">
          <div>
            <h5>{{ el.day_of_week_txt }}요일</h5>
            <label>근무시간</label>
            <span>{{ el.open }}</span>
          </div>
          <div v-if="el.closed.length">
            <h5></h5>
            <label>휴식시간</label>
            <span>
              <span v-for="(item, index) in el.closed" :key="index">
                {{ item.time }}
              </span>
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="eventWorktimeDatas.length" class="staff-basic__staff-working-hours">
      <ul>
        <li
          v-for="(el, index) in eventWorktimeDatas"
          :key="index"
          :class="isDiffMonth(el.date, eventWorktimeDatas[index + 1]) ? 'next-month' : ''"
        >
          <div>
            <h5>{{ el.date | dateWithWeekday }}</h5>
            <label v-if="!(el.open === 'dayoff')">근무시간</label>
            <label v-else class="no-border">휴무일</label>
            <span v-if="!(el.open === 'dayoff')">{{ el.open }}</span>
          </div>
          <div v-if="el.closed.length">
            <h5></h5>
            <label>휴식시간</label>
            <span>
              <span v-for="(item, index) in el.closed" :key="index">
                {{ item.time }}
              </span>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      workingHoursByWeekday: [],
      eventWorktimeDatas: [],
    };
  },
  computed: {
    staff() {
      return this.$store.getters['staff/staff'];
    },
    staffId() {
      return this.$store.getters['staff/staff'].id;
    },
    dataSets() {
      return this.$store.getters['staff/working_times'];
    },
    updateFormatData() {
      if (this.dataSets) this.seperateDatas();
      return '';
    },
    isPrivateStartIntervalOn() {
      return !!this.staff.profile.private_start_interval_on_off;
    },
  },
  created() {
    this.getStaffWorkTimes(this.staffId);
  },
  methods: {
    isDiffMonth(el, nextel) {
      const compare_val = nextel ? `${nextel.date[5]}${nextel.date[6]}` : `${el[5]}${el[6]}`;
      return `${el[5]}${el[6]}` !== compare_val;
    },
    /** 강사 근무 시간 get*/
    async getStaffWorkTimes(id) {
      await this.$store.dispatch('staff/getWorkTimes', id);
      if (this.dataSets.length) this.seperateDatas();
    },
    seperateDatas() {
      let sortData = _.cloneDeep(this.dataSets);
      sortData = _.sortBy(sortData, 'type').reverse();

      sortData.forEach(el => {
        el.type === 'workTime' || el.type === 'eventWorkTime' ? this.addWorkTimesData(el) : this.addRestTimesData(el);

        if (el.type === 'eventRestTime' && el.start_time === '00:00:00' && el.end_time === '23:59:59') {
          this.eventWorktimeDatas.push({
            date: el.date,
            day_of_week: el.day_of_week,
            day_of_week_txt: this.$utils.translate.weekdayNumber(el.day_of_week),
            closed: [],
            open: `dayoff`,
          });
        }
        this.eventWorktimeDatas = _.sortBy(this.eventWorktimeDatas, 'date');
      });
    },
    addWorkTimesData(el) {
      const data_name = el.type === 'workTime' ? this.workingHoursByWeekday : this.eventWorktimeDatas;
      data_name.push({
        date: el.date,
        day_of_week: el.day_of_week,
        day_of_week_txt: this.$utils.translate.weekdayNumber(el.day_of_week),
        order_no: el.day_of_week === 0 ? '7' : el.day_of_week,
        closed: [],
        open: `${_.get(el, 'start_time').slice(0, 5)} ~ ${_.get(el, 'end_time').slice(0, 5)}`,
      });
      this.workingHoursByWeekday = _.sortBy(this.workingHoursByWeekday, 'order_no');
    },
    addRestTimesData(el) {
      const data_name = el.type === 'restTime' ? this.workingHoursByWeekday : this.eventWorktimeDatas;
      data_name.forEach(item => {
        const compare_rule = el.type === 'restTime' ? el.day_of_week === item.day_of_week : el.date === item.date;
        if (compare_rule) {
          item.closed.push({
            time: `${_.get(el, 'start_time').slice(0, 5)} ~ ${_.get(el, 'end_time').slice(0, 5)}`,
          });
        }
        item.closed = _.sortBy(item.closed, 'time');
      });
      this.workingHoursByWeekday = _.sortBy(this.workingHoursByWeekday, 'order_no');
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-basic__header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;

  h4 {
    font-size: 20px;
    font-weight: 500;
  }

  p {
    grid-column: 1 / 3;
    font-size: 12px;
    opacity: 0.5;
  }
}

.staff-basic__staff-working-hours {
  padding: 15px 4px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 40px;
  ul li.next-month {
    margin-bottom: 20px;
  }
  ul li div {
    display: grid;
    grid-template-columns: 130px 80px auto;
    grid-gap: 10px 20px;
    align-items: center;
    margin-bottom: 10px;
    label {
      font-weight: 300;
      margin: 0;
      opacity: 0.6;
      border-right: 0.5px solid #868e96;
      &.no-border {
        border-right: none;
      }
    }
    span > span {
      margin-right: 10px;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}

.block {
  margin-top: 15px;
  margin-bottom: 40px;
  border-left: 4px solid $gray-200;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;
  padding-left: 15px;
  color: $charcoal-grey;
  gap: 15px;

  .time {
    color: #a3a3a3;
    margin-left: 10px;
    margin-right: 30px;
  }
}
</style>
