import axios from '../axios';

export default {
  // 회원 목록
  getSummary: () => axios.get('/staff/member/summary'),
  // getAll: params =>
  //   axios.get('/staff/member', {
  //     params,
  //   }),

  getAll: params =>
    axios.get('/v2/staff/members', {
      params,
    }),

  // 강사의 담당회원 목록
  getMembers: params =>
    axios.get('/v2/staff/members/assigned', {
      params,
    }),

  checkPhone: data => axios.get(`/v2/staff/members/phone/${data}`),

  // 개별 회원
  get: id => axios.get(`/staff/member/${id}`),

  // 회원 등록
  create: data => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return axios.post('/staff/member', data, {
      headers,
    });
  },

  // 회원 수정
  update: (id, data) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return axios.post(`/staff/member/${id}`, data, {
      headers,
    });
  },

  // 회원 삭제
  delete: id => axios.delete(`/staff/member/${id}`),

  // 회원의 예약된 수업 목록
  getLectures: id => axios.get(`/members/${id}/lectures`),

  // 회원의 예약 내역
  getBookings: (id, params) => {
    params = {
      ...params,
      with: 'lecture.course;lecture.instructor.profile;lecture.instructor.avatars;userTicket.metadata',
    };
    return axios.get(`/staff/member/${id}/booking`, {
      params,
    });
  },

  // 헤더 회원 검색
  getHeaderMembers: params => {
    return axios.get('/v2/staff/members/top-bar-search', {
      params,
    });
  },

  getSelectedMembers: ids => axios.post('/v2/staff/userTickets/member', { member_id: ids }),
};
