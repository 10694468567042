<template>
  <section class="message-template">
    <div class="message-template__header">
      <h5>문자보관함</h5>
      <span>내용을 클릭하면 메시지 입력창에 입력됩니다</span>
      <div class="message-template__header__buttons">
        <button @click="checkAll">{{ checkAllButtonText }}</button>
        <button :class="isCheckedNone ? 'disabled' : ''" :disabled="isCheckedNone" @click="handleClickDeleteTemplateButton">
          선택삭제
        </button>
      </div>
    </div>
    <div :class="`message-template__list ${noData ? 'no_data' : ''}`">
      <div v-if="noData">저장된 문자가 없습니다.</div>
      <div
        id="message-template__item"
        class="message-template__item"
        v-for="({ id, template, template_title, checkByte, created_at }, templateKey) in formattedTemplates"
        :key="templateKey"
      >
        <div class="message-template__item__box">
          <textarea
            class="message-template__item__box-content"
            @click="dispatchSetFormText({ template })"
            readonly
            :value="template"
          ></textarea>
          <div class="message-template__item__box-header">
            <Checkbox
              class="message-template__item__box-header__title"
              type="checkbox"
              :value="id"
              :checked="checkedIdsForDelete.includes(id)"
              @change="handleCheckDeleteTemplate({ id })"
            >
              {{ template_title }}
            </Checkbox>
            <div>
              <span>{{ moment(created_at).format('YYYY.MM.DD') }}</span>
              <span>{{ checkByte }} 바이트</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      :class="`message-template__pagination ${noData ? 'no_data' : ''}`"
      @current-change="handleChangePagination"
      :current-page="pagination.page"
      :page-size="pagination.limit"
      layout="prev, pager, next"
      :total="pagination.total"
    />
  </section>
</template>

<script>
export default {
  props: {
    deleteTemplate: Function,
    handleClickAddAdText: Function,
  },

  computed: {
    templates() {
      return this.$store.getters['template/templates'];
    },
    pagination() {
      return this.$store.getters['template/pagination'];
    },
    checkedIdsForDelete() {
      return this.$store.getters['template/checkedIdsForDelete'];
    },
    checkAllButtonText() {
      return this.isCheckedAll ? '전체해제' : '전체선택';
    },
    formText() {
      return this.$store.getters['template/formText'];
    },
    noData() {
      return !this.formattedTemplates.length;
    },
    isCheckedNone() {
      return this.checkedIdsForDelete.length === 0;
    },
    formattedTemplates() {
      return this.templates.map(template => ({
        ...template,
        template_title: template.title,
        created_at: this.moment(template.created_at).format('YYYY.MM.DD hh:mm:ss'),
        checkByte: this.$utils.smsByteCheckWithPreventWriting(template.template),
        checkDelete: false,
      }));
    },
    isCheckedAll() {
      return this.checkedIdsForDelete.length === this.templates.length;
    },
  },

  watch: {
    'pagination.page'() {
      this.$store.dispatch('template/updateCheckedIdsForDelete', []);
    },
  },

  created() {
    this.dispatchGetTemplateList();
  },

  destroyed() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch('template/setFormText', { template: '', title: '', template_title: '' });
      this.$store.dispatch('template/updateCheckedIdsForDelete', []);
      this.$store.dispatch('template/updatePage', 1);
    },

    dispatchGetTemplateList() {
      this.$store.dispatch('template/getAllTemplates');
    },

    checkAll() {
      this.templates.map(t => (t.checkDelete = !this.isCheckedAll));
      this.dispatchCheckedIdsForDelete();
    },

    dispatchSetFormText({ template }) {
      if (this.formText.template) {
        return this.$confirm('선택한 문자로 바꾸시겠습니까?', '보관된 문자 사용', {
          dangerouslyUseHTMLString: true,
        })
          .then(() => {
            this.$store.dispatch('template/setFormText', { template });
            this.$store.dispatch('template/updateSent', false);
            this.handleClickAddAdText('changeTemplate');
          })
          .catch(() => false);
      } else {
        this.$store.dispatch('template/setFormText', { template });
        this.handleClickAddAdText('changeTemplate');
      }
    },

    dispatchCheckedIdsForDelete() {
      const checkedIdsForDelete = this.templates.filter(t => t.checkDelete).map(t => t.id);
      this.$store.dispatch('template/updateCheckedIdsForDelete', checkedIdsForDelete);
    },

    handleCheckDeleteTemplate(template) {
      this.templates.map(t => {
        if (t.id === template.id) {
          return (t.checkDelete = t.checkDelete ? false : true);
        }
      });
      this.dispatchCheckedIdsForDelete();
    },

    async handleClickDeleteTemplateButton() {
      if (!this.checkedIdsForDelete.length) {
        return this.$alert('선택된 문자가 없습니다.', null, {
          showClose: false,
          dangerouslyUseHTMLString: true,
        });
      }
      this.$confirm('문자를 삭제하시겠습니까?', '보관된 문자 삭제', {
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          await this.$store.dispatch('template/deleteTemplate');
          await this.$store.dispatch('template/updatePage', 1);
          await this.dispatchGetTemplateList();
        })
        .catch(() => false);
    },

    handleChangePagination(page) {
      this.$store.dispatch('template/updatePage', page);
      this.dispatchGetTemplateList();
    },
  },
};
</script>

<style lang="scss" scopped>
.message-template {
  &__header {
    display: flex;
    line-height: 40px;

    h5 {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: $black;
    }

    span {
      position: relative;
      font-size: 13px;
      text-align: left;
      color: #797979;
      padding: 0px 0px 30px 38px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 11px;
        left: 15px;
        background: url('../../assets/images/icons/circle_exclamation_mark.svg') 0 0 no-repeat;
        background-size: cover;
      }
    }

    &__buttons {
      margin-left: auto;
      button {
        width: 96px;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
      button:first-child {
        border: solid 1px #64aeff;
        color: #64aeff;
      }
      button:last-child {
        border: solid 1px $coral;
        margin-left: 16px;
        color: $coral;
        background-color: $white;
      }
      button.disabled {
        border: none;
        color: $white;
        background-color: #e5e5e5;
        pointer-events: none;
      }
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    &.no_data {
      display: block;
      position: relative;
      grid-template-columns: 0;
      grid-gap: 0;

      div:first-child {
        font-size: 18px;
        font-weight: 500;
        padding: 180px 0px 40px 0px;
        width: 100%;
        margin: auto;
        color: #adadad;
        text-align: center;

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 120px;
          height: 120px;
          top: calc(50% - 60px);
          left: calc(50% - 60px);
          background: url('../../assets/images/icons/dotted_message.svg') 0 0 no-repeat;
          background-size: cover;
        }
      }
    }
  }

  &__item {
    min-width: 220px;
    height: 205px;
    border: solid 1px #eee;
    border-radius: 4px;
    &__box {
      position: relative;
      height: 205px;
      padding: 0px 0px 60px 0px;
      &-content {
        resize: none;
        cursor: pointer;
        font-size: 12px;
        text-align: left;
        color: #414141;
        padding: 15px;
        width: 100%;
        min-width: 218px;
        height: 100%;
        line-height: normal;
        border-radius: 4px 4px 0px 0px;
        &:disabled {
          background: #fff;
        }

        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          width: 8px;
          border-left: 1px solid #e8e8e8;
          background-color: $white;
        }
        &::-webkit-scrollbar-track {
          background-color: #e8e8e8;
        }
      }

      &-header {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        bottom: 2px;
        width: 100%;
        height: 60px;
        object-fit: contain;
        border-radius: 0px 0px 3px 3px;
        background-color: #f5f5f5;
        padding: 8px 15px;

        input[type='checkbox'] {
          background: none;
          vertical-align: middle;
          margin: 0px 8px 0px 0px !important;

          &:not(:checked) {
            border-radius: 4px !important;
            border: solid 1px #e8e8e8 !important;
            background-color: $white !important;

            &:hover {
              border: solid 1px #64aeff !important;
              background: none !important;
              background-color: $white !important;
            }
          }
        }

        div:last-child {
          display: flex;
          justify-content: space-between;
          font-size: 11px;
          color: $black;
        }

        &__title {
          display: block !important;
          width: 180px;
          font-size: 13px;
          font-weight: 500 !important;
          text-align: left;
          color: $black;
          margin: 0px !important;
          padding: 0px !important;

          font-size: 13px;
          width: 214px;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding: 40px 0px;

    &.no_data {
      display: none;
    }
  }
}
</style>
