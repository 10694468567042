<template>
  <el-dialog class="point-storage-dialog" :visible="show" @close="$emit('close')">
    <h4 slot="title">광고 문자 표기 의무사항 확인</h4>
    <div class="description">
      <p>정보통신망 이용촉진 및 정보보호 등에 관한 법률(시행 2019. 6. 25.) 제 50조의 4</p>
      <p>(정보 전송 역무 제공 등의 제한)에 근거하여 광고메시지 전송시 표기의무 준수해야</p>
      <p>하며 표기 위반시<span class="description__accent"> 과태료가 최대 3천만원 부과</span> 될 수 있습니다.</p>
      <p>야간 광고(21시~익일 8시) 전송 시 별도의 수신 동의가 필요합니다.</p>
    </div>
    <img src="@/assets/images/AdWarningX3.png" alt="광고경고" />
    <div slot="footer">
      <el-button type="primary" @click="$emit('close')">
        닫기
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.point-storage-dialog {
  /deep/ .el-dialog {
    max-width: 590px;
    &__header,
    &__body {
      padding: 20px 30px;
    }
  }
  .description {
    p {
      font-size: 15px;
    }
    &__accent {
      font-weight: bold;
      color: red;
    }
  }
  img {
    width: 100%;
    margin-top: 20px;
  }
  .preview-message {
    white-space: pre-line;
    border: 1px solid $deep-sky-blue;
    border-radius: 4px;
    height: 500px;
    width: 370px;
    overflow-y: auto;
    margin: 0 auto;
    padding: 10px;
    p {
      word-break: break-word;
    }
  }
  /deep/ .el-dialog__footer {
    padding-top: 0;
  }
}
</style>
