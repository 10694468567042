<template>
  <div class="contact">
    <el-select
      v-model="contact.type_id"
      :class="['contact__select', contact.type_id === 5 || disabled ? 'non-arrow' : '']"
      :disabled="contact.type_id === 5 || disabled"
    >
      <el-option
        v-for="type in contactTypes"
        :value="type.id"
        :label="type.display"
        :disabled="type.disabled"
        :key="type.id"
      />
    </el-select>
    <TextInput
      v-if="contact.type_id === 4"
      v-model="contact.contact"
      class="contact__input"
      placeholder="카카오 아이디를 입력해주세요"
      material
    />
    <PhoneNumberInput
      v-else
      v-model="contact.contact"
      class="contact__input"
      type="text"
      :placeholder="placeholder"
      :required="index === 0"
    />
    <el-button
      v-if="contact.type_id !== 5"
      class="contact__button"
      :icon="index === 0 ? 'el-icon-plus' : 'el-icon-minus'"
      size="mini"
      circle
      @click="index === 0 ? handleClickAdd() : handleClickRemove(index)"
    />
  </div>
</template>

<script>
import PhoneNumberInput from './PhoneNumberInput';
import TextInput from './TextInput';

export default {
  components: { PhoneNumberInput, TextInput },

  props: {
    contact: Object,
    // -> { type_id: 2, contact: '', is_representative: true },
    index: Number,
    handleClickAdd: Function,
    handleClickRemove: Function,
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      contactTypes: [],
    };
  },

  computed: {
    placeholder() {
      if (!this.contactTypes.length) return null;
      const label = this.contactTypes.find(({ id }) => id === this.contact.type_id).display;
      if (label === '발신자 번호') {
        return '문자 전송 번호를 입력해주세요';
      }
      return `${label} 번호를 입력해주세요`;
    },
  },

  async created() {
    let contactTypes = [];
    try {
      const res = await this.$api.getContactTypes();
      contactTypes = res.data.map(({ id, display }) => ({ id, display }));
    } catch (error) {
      this.$utils.notify.parseError(this, error);
    }
    this.contactTypes = contactTypes.map(el => (el.id === 5 ? { ...el, disabled: true } : el));
  },
};
</script>

<style lang="scss" scoped>
.contact {
  @include flex(row, center, flex-start);
  margin-bottom: 12px;

  &__select {
    margin-right: 8px;
  }

  &__input {
    font-weight: 300;
    margin-right: 8px;
    max-width: 240px;
  }

  &__button {
    width: 29px;
    height: 29px;
  }

  /deep/ .el-select {
    width: 120px;
    font-weight: 300;
  }

  /deep/ .el-input__inner {
    margin-bottom: 0 !important;
    font-weight: 300;
    padding: 0 5px;
  }
}
.non-arrow {
  /deep/ .el-input__inner {
    color: #606266;
  }
  /deep/ .el-input__icon {
    display: none;
  }
}
</style>
