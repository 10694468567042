import Vue from 'vue';

const requireComponent = require.context(
  '.', // 현재 폴더 검색
  false, // 하위 폴더 확인 안함
  /[\w-]+\.vue$/, // 확장자가 vue인 파일
);

requireComponent.keys().forEach(filename => {
  const componentConfig = requireComponent(filename);
  const componentName = filename
    .replace(/^\.\//, '') // 시작부분 "./" 제거
    .replace(/\.\w+$/, ''); // 확장자 제거

  Vue.component(componentName, componentConfig.default || componentConfig);
});
