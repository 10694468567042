<template>
  <section class="ticket-summary">
    <div class="ticket-summary__product-card">
      <UserTicketCard :userTicket="userTicket" />
    </div>

    <div class="ticket-summary__meta">
      <p>
        <label>이용상태</label>
        <span>{{ ticketStatus }}</span>
      </p>

      <table v-if="_.get(userTicket, 'type') !== 'P'">
        <thead>
          <tr>
            <th v-for="(label, index) in countData.header" :key="index">
              {{ label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(count, index) in countData.body" :key="index">
              {{ count | comma }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="ticket-summary__meta__payment">
        <label>결제정보</label>
        <p>
          <el-tag type="info" size="mini">오프라인</el-tag>
          <span>{{ paymentMethod }}</span>
          <span>{{ paymentAmount }}</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { CONTENT_LABEL } from '@/constants';
export default {
  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },

    userTicket() {
      return _.get(this.formData, 'userTicket');
    },
    ticketStatus() {
      return this.$utils.getTicketStatus(this.userTicket);
    },
    payments() {
      return _.get(this.userTicket, 'payments', []);
    },

    // 대여형 (기간, 횟수 둘다)
    isRentalAll() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RM';
    },

    // 대여형 (횟수만)
    isRentalOnlyTime() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RT';
    },

    countData() {
      if (!this.userTicket) return {};
      const { max_coupon, remaining_coupon, usable_coupon, remaining_cancel, is_show_cancel_count } = this.userTicket;
      if (this.userTicket.ticket.type === 'T') {
        return {
          header: ['전체', '잔여', '예약 가능', '취소 가능'],
          body: [max_coupon, remaining_coupon, usable_coupon, remaining_cancel],
        };
      }

      if (this.isRentalAll || this.isRentalOnlyTime) {
        return {
          header: ['전체', '잔여', '예약 가능'],
          body: [max_coupon, remaining_coupon, usable_coupon],
        };
      }
      if (is_show_cancel_count) {
        return {
          header: ['취소 가능'],
          body: [remaining_cancel > 999 ? 999 : remaining_cancel],
        };
      }
      return {
        header: [],
        body: [],
      };
    },

    paymentSum() {
      return this.payments.reduce(
        (sum, payment) => {
          const sign = payment.status === 'refund' ? -1 : 1;
          sum.card_amount += payment.card_amount * sign;
          sum.cash_amount += payment.cash_amount * sign;
          sum.wiretransfer_amount += payment.wiretransfer_amount * sign;
          sum.point_amount += payment.point_amount * sign;
          sum.amount += payment.amount * sign;
          if (payment.status === 'refund' && payment.reward_point) {
            sum.point_amount -= payment.reward_point; // 환불일 때 포인트 reward_amount도 고려
          }
          return sum;
        },
        { card_amount: 0, cash_amount: 0, wiretransfer_amount: 0, point_amount: 0, amount: 0 },
      );
    },

    paymentMethod() {
      const paymentRemained = Object.keys(this.paymentSum)
        .filter(key => key !== 'amount') // amount는 총합이므로 제외
        .filter(key => this.paymentSum[key] !== 0)
        .map(key => CONTENT_LABEL[key]);
      return paymentRemained.join('+');
    },

    paymentAmount() {
      return `${this.$filters.comma(this.paymentSum.amount)}원`;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(286px, 1fr));

  &__product-card {
    @include flex(row, center, center);
    margin: 15px;
  }

  &__meta {
    flex: 1;
    @include flex(column);
    margin: 15px;

    p {
      @include flex(row, center);
    }

    table {
      @include flex(column);
      margin: 8px 0;
      width: 100%;

      thead,
      tbody {
        width: 100%;
      }

      tr {
        @include flex(row, stretch, space-between);
        width: 100%;

        &:last-child td {
          border-bottom-width: 1px;
        }
      }

      th,
      td {
        border: solid #ddd;
        border-width: 1px 0 0 1px;
        padding: 4px;
        text-align: center;
        width: 100%;

        &:last-child {
          border-right-width: 1px;
        }
      }

      th {
        background: #eee;
        font-size: 12px;
      }
    }

    label {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.5;
      margin-right: 10px;
      opacity: 0.5;
    }

    /deep/ .el-tag {
      border: none;
      background: #4c4c4c;
      color: white;
      font-size: 9px;
    }

    &__payment {
      @include flex(column);

      label {
        margin: 18px 0 10px;
      }

      span {
        margin-right: 8px;
      }
    }
  }
}
</style>
