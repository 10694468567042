import axios from '../axios';

export default {
  // 코스 목록
  getAll: params =>
    axios.get('/staff/course', {
      params,
    }),

  // 개별 코스
  get: (id, params) =>
    axios.get(`/staff/course/${id}`, {
      params,
    }),

  // 코스 생성
  create: course =>
    axios.post(
      '/staff/course',
      {
        course,
      },
      { timeout: 1000 * 60 * 3 },
    ),

  // 코스 수정
  update: course =>
    axios.patch(`/staff/course/${course.id}`, {
      course,
    }),

  // 코스 폐강
  close: (id, close_start_date) => {
    return axios.post(
      `/staff/course/${id}/close`,
      {
        close_start_date,
      },
      { timeout: 1000 * 60 * 3 },
    );
  },

  // 코스 연장
  extend: (id, end_date) => {
    return axios.post(
      `/staff/course/${id}/add-lecture`,
      {
        end_date,
      },
      { timeout: 1000 * 60 * 3 },
    );
  },

  // 코스 생성 & 예약
  createAndBook: (userTicketIds, course) => {
    return axios.post(`/staff/course/booking/${userTicketIds}`, {
      course,
    });
  },
};
