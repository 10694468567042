/** null -> -1 로 변경 (서버 전송 전) */
const replaceNullWithMinusOne = json => {
  let replaced = {};
  Object.keys(json).forEach(key => {
    if (json[key] && Array.isArray(json[key])) {
      replaced[key] = json[key];
    } else if (json[key] && typeof json[key] === 'object') {
      replaced[key] = replaceNullWithMinusOne(json[key]);
    } else {
      replaced[key] = !json[key] && typeof json[key] !== 'number' ? -1 : json[key];
    }
  });
  return replaced;
};

export default replaceNullWithMinusOne;
