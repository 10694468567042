<template>
  <div
    :class="{ checked }"
    :style="`background-image: linear-gradient(to bottom, #${color[0]},#${color[1]})`"
    @click="$emit('click', color)"
  ></div>
</template>

<script>
export default {
  props: {
    color: Array,
    checked: Boolean,
  },
};
</script>

<style lang="scss" scoped>
div {
  cursor: pointer;
  width: 32px;
  height: 32px;
  transition: background 0.15s;
  position: relative;

  &:hover {
    opacity: 0.7;
  }

  &::before {
    content: '';
    left: 9px;
    top: 3px;
    width: 0px;
    height: 0px;
    border: solid white;
    border-width: 0 4px 4px 0;
    border-radius: 2px;
    position: absolute;
    opacity: 0;
    transform: rotate(-90deg);
    transition: 0.15s transform, 0.15s opacity, 0.15s width, 0.15s height;
  }

  &.checked::before {
    transform: rotate(40deg);
    opacity: 1;
    width: 12px;
    height: 20px;
  }
}
</style>
