<template>
  <div class="hours-input" :class="{ disabled }">
    <div class="hours-input__start">
      <label v-if="startLabel">{{ startLabel }}</label>
      <el-time-select
        v-bind="$attrs"
        placeholder="시작시각"
        format="HH:mm"
        value-format="HH:mm"
        :picker-options="startHourOptions"
        :disabled="disabled"
        :value="value.start"
        @input="value => handleInput('start', value)"
        tabindex="1"
      />
    </div>
    <span>~</span>
    <div class="hours-input__end">
      <label v-if="endLabel">{{ endLabel }}</label>
      <el-time-select
        v-bind="$attrs"
        format="HH:mm"
        value-format="HH:mm"
        placeholder="종료시각"
        :picker-options="endHourOptions"
        :disabled="disabled"
        :value="value.end"
        @input="value => handleInput('end', value)"
        tabindex="2"
      />
    </div>
    <p v-if="showDuration">
      {{ `(${getTimeDifference(value.start, value.end)}시간)` }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    disabled: Boolean,
    startHourOptions: Object,
    endHourOptions: Object,
    startLabel: String,
    endLabel: String,
    showDuration: Boolean,
  },

  methods: {
    handleInput(key, value) {
      this.value[key] = value;

      if (this.value.start && this.value.start >= this.value.end) {
        if (!!this.startHourOptions && !!this.startHourOptions.step) {
          const [hour, minute] = this.value.start.split(':');
          const step = this.startHourOptions.step.split(':')[1];
          const endTime = this.moment()
            .hour(hour)
            .minute(minute)
            .add(step, 'minutes')
            .format('HH:mm');
          this.value.end = endTime.slice(0, 2) === '00' ? '23:55' : endTime;
        }
      }
      this.$emit('input', {
        start: this.value.start,
        end: this.value.end,
      });
    },

    getTimeDifference(start, end) {
      if (!start || !end) return 0;
      const [start_hour, start_minute] = start.split(':');
      const [end_hour, end_minute] = end.split(':');
      const start_time = parseInt(start_hour) * 60 + parseInt(start_minute);
      const end_time = parseInt(end_hour) * 60 + parseInt(end_minute);
      const difference = end_time - start_time;

      // eslint-disable-next-line no-useless-escape
      var num_check=/^([0-9]*)[\.]?([0-9])?$/;
      if (!num_check.test(difference / 60)) {
        return (difference / 60).toFixed(1);
      }
      return (difference / 60).toFixed(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.hours-input {
  display: flex;
  align-items: center;
  padding: 0 20px;

  /deep/ .el-date-editor {
    max-width: 110px !important;

    .el-input__inner {
      margin-bottom: 0;
      text-align: center;
    }
  }

  span {
    align-self: flex-end;
    font-size: 20px;
    font-weight: 300;
    margin: 0 12px;
  }

  p {
    margin-left: 12px;
  }

  &.disabled {
    span,
    p {
      opacity: 0.3;
    }
  }

  &__start,
  &__end {
    @include flex(column);

    label {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
</style>
