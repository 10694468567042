<template>
  <el-table
    ref="lectureList"
    class="lecture-list"
    :data="lectures"
    :row-style="{ height: '48px', cursor: 'pointer' }"
    :key="`${selectedAll}${indeterminate}`"
    @cell-click="row => $emit('toggle-selection', row)"
    @sort-change="handleClickHeader"
    fit
  >
    <el-table-column label="selection" width="50" align="center">
      <span slot="header">
        <el-tooltip effect="light" content="전체 선택" placement="top">
          <Checkbox
            type="multi"
            :checked="selectedAll"
            :indeterminate="indeterminate"
            @change="$emit('toggle-select-all')"
          ></Checkbox>
        </el-tooltip>
      </span>
      <template slot-scope="scope">
        <Checkbox :checked="lectureIdsSelected.includes(scope.row.id)"></Checkbox>
      </template>
    </el-table-column>

    <el-table-column
      label="수업일시"
      prop="start_on"
      min-width="230"
      align="center"
      :formatter="formatLectureDatetime"
      sortable="custom"
      :sort-orders="['ascending', 'descending']"
      @sort-change="() => {}"
    />

    <el-table-column label="강사" min-width="100" align="center" :formatter="formatInstructor" show-overflow-tooltip />

    <el-table-column label="수업" min-width="80" align="center" :formatter="formatCourseType" />

    <el-table-column label="수업명" prop="title" min-width="180" align="center" show-overflow-tooltip>
      <template slot-scope="scope">
        <a v-if="canViewLecture(scope.row.type, scope.row.staff.id)" @click.stop="handleClickTitle(scope.row)">
          {{ scope.row.title }}
        </a>
        <span v-else>
          {{ scope.row.title }}
        </span>
      </template>
    </el-table-column>

    <el-table-column label="수업구분" min-width="80" align="center" :formatter="formatDivision" show-overflow-tooltip />

    <el-table-column
      v-if="showRoomColumn"
      label="룸"
      min-width="100"
      align="center"
      :formatter="formatRoom"
      show-overflow-tooltip
    />

    <el-table-column min-width="80" align="center" :formatter="formatMemberCount" show-overflow-tooltip>
      <span slot="header"> 최대/최소<br />수강인원 </span>
    </el-table-column>

    <el-table-column
      v-if="showWaitingTraineeLimit"
      min-width="80"
      align="center"
      :formatter="formatWaitingLimit"
      show-overflow-tooltip
    >
      <span slot="header">예약대기<br />가능인원</span>
    </el-table-column>

    <el-table-column label="예약 가능 시간" min-width="175" align="center">
      <template slot-scope="scope">
        <p v-html="formatBookableTimeRange(scope.row)" class="lecture-list__bookable-time-range" />
      </template>
    </el-table-column>

    <el-table-column label="취소 가능 시간" min-width="175" align="center">
      <template slot-scope="scope">
        <p v-html="formatCancellableTimeRange(scope.row)" class="lecture-list__cancellable-time-range" />
      </template>
    </el-table-column>

    <el-table-column
      label="당일 예약 변경 가능 시간"
      min-width="175"
      align="center"
      :formatter="formatBookingChangeDeadline"
      show-overflow-tooltip
    />

    <el-table-column
      label="폐강 시간"
      min-width="165"
      align="center"
      :formatter="formatAutocloseDeadline"
      show-overflow-tooltip
    />
  </el-table>
</template>

<script>
export default {
  props: {
    lectures: { type: Array, default: () => [] },
    lectureIdsSelected: { type: Array, default: () => [] },
    selectedAll: { type: Boolean },
    indeterminate: { type: Boolean },
    order: { type: String, default: 'asc' },
  },

  computed: {
    showRoomColumn() {
      return !!_.get(this.studioPolicies, 'is_use_rooms');
    },

    showWaitingTraineeLimit() {
      return !!_.get(this.studioPolicies, 'weekly_waiting_limit');
    },
  },

  methods: {
    formatLectureDatetime(row) {
      return `${this.moment(row.start_on).format('YYYY. M. D. (ddd) HH:mm')}~${this.moment(row.end_on).format('HH:mm')}`;
    },

    formatInstructor(row) {
      return _.get(row, 'staff.name');
    },

    formatCourseType({ type }) {
      return this.$utils.translate.courseType(type);
    },

    formatDivision(row) {
      if (_.get(row, 'division')) {
        return _.get(row, 'division');
      }
      return '-';
    },

    formatRoom(row) {
      if (_.get(row, 'room')) {
        return _.get(row, 'room').name;
      }
      return '-';
    },

    formatMemberCount({ max_trainee = 0, min_trainee = 0 }) {
      return `${max_trainee}/${min_trainee}`;
    },

    formatWaitingLimit({ type, waiting_trainee_limit }) {
      return type === 'P' ? '-' : waiting_trainee_limit <= 0 ? '무제한' : waiting_trainee_limit;
    },

    formatBookableTimeRange({ booking_start_at, booking_end_at }) {
      const { datetime } = this.$filters;

      if (!booking_start_at && !booking_end_at) {
        return '-';
      } else if (!booking_start_at) {
        return `${datetime(booking_end_at)} 까지`;
      } else {
        return `${datetime(booking_start_at)} ~<br>${datetime(booking_end_at)}`;
      }
    },

    formatCancellableTimeRange({ booking_cancel_start_at, booking_cancel_end_at }) {
      const { datetime } = this.$filters;

      if (!booking_cancel_start_at && !booking_cancel_end_at) {
        return '-';
      } else if (!booking_cancel_start_at) {
        return `${datetime(booking_cancel_end_at)} 까지`;
      } else {
        return `${datetime(booking_cancel_start_at)} ~<br>${datetime(booking_cancel_end_at)}`;
      }
    },

    formatBookingChangeDeadline({ type, daily_change_booking_end_at }) {
      if (!daily_change_booking_end_at || type === 'P') return '-';
      return `${this.$filters.datetime(daily_change_booking_end_at)} 까지`;
    },

    formatAutocloseDeadline({ type, autoclose_at }) {
      if (!autoclose_at || type === 'P') return '-';
      return this.$filters.datetime(autoclose_at);
    },

    handleClickTitle(row) {
      const lectureId = _.get(row, 'id');
      const courseType = _.get(row, 'type');
      const instructorId = _.get(row, 'staff.id');

      if (this.canViewLecture(courseType, instructorId)) {
        this.$router.push(`/lecture/detail?id=${lectureId}`);
      }
    },

    handleClickHeader(e) {
      const order = e.column.order === 'ascending' ? 'asc' : 'desc';
      this.$emit('sort-change', order);
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-list {
  &__bookable-time-range,
  &__cancellable-time-range {
    line-height: 1.2;
    text-align: left;
    max-width: 155px;
    margin: auto;
  }
}
</style>
