import api from '@api';
import axios from '@api/axios';
import router from '@router';
import utils from '@utils';
import messaging from '/src/fcm';

export const state = {
  studioId: utils.localStorage.get('studioId'),
  accessToken: utils.localStorage.get('accessToken'),
  currentUser: utils.localStorage.get('currentUser'),
  webToken: null,
  firebaseKey:
    'AAAAhDmS-bY:APA91bFjF4dIAkSA-GWSFBgxRmQVwFudM1MII40rlqKSGG9eMWtH7lhQDVCrdv7-Ji7rID6eqOxhPFeHFo5Mb8wBr7SzPv_yUqntcNwATg6MP-vXkZovj7MyB4WPBXhNfW0JzFSU7oBq',
};

export const getters = {
  studioId: state => state.studioId,
  currentUser: state => state.currentUser,
  isLoggedIn: state => !!state.accessToken && !!state.currentUser,
  isStudioOwner: state => {
    if (!state.currentUser || !state.currentUser.roles) return false;
    return state.currentUser.roles[0].is_immutable;
  },
  userPermissions: state => {
    if (!state.currentUser || !state.currentUser.roles) return [];
    return state.currentUser.roles[0].permissions.map(({ id }) => id);
  },
  webToken: state => state.webToken,
  firebaseKey: state => state.firebaseKey,
};

export const mutations = {
  SET_STUDIO_ID(state, studioId) {
    state.studioId = utils.localStorage.set('studioId', studioId);
  },

  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = utils.localStorage.set('accessToken', accessToken);
  },

  SET_CURRENT_USER(state, user) {
    state.currentUser = utils.localStorage.set('currentUser', user);
  },

  SET_WEB_TOKEN(state, webToken) {
    state.webToken = webToken;
  },
};

export const actions = {
  init({ dispatch }) {
    dispatch('getStudioId');
    dispatch('getCurrentUser');
  },

  async getStudioId({ commit }) {
    const subdomain = window.location.hostname.split('.')[0];

    try {
      const res = await api.studio.getId(subdomain);
      commit('SET_STUDIO_ID', res.data.id);
    } catch (error) {
      commit('SET_STUDIO_ID', null);
      router.push('/404');
    }
  },

  async login({ commit, dispatch }, payload) {
    const { identity, password, vm } = payload;
    const studio_id = state.studioId;

    try {
      const res = await api.auth.login({
        identity,
        password,
        studio_id,
      });
      commit('SET_ACCESS_TOKEN', res.data.access_token);
      return await dispatch('getCurrentUser');
    } catch (error) {
      commit('SET_ACCESS_TOKEN', null);
      utils.notify.error(vm, '로그인 실패', '아이디 또는 비밀번호가 일치하지 않습니다.<br>입력한 내용을 다시 확인해주세요.');
    }
  },

  // Admin 페이지 > 스태프 관리 > 무적 로그인시
  async superLogin({ commit, dispatch }, data) {
    try {
      const res = await api.auth.login(data);
      commit('SET_ACCESS_TOKEN', res.data.access_token);
      return await dispatch('getCurrentUser', false);
    } catch (error) {
      console.log(error);
    }
  },

  async getCurrentUser({ commit, dispatch }, updateMessageToken = true) {
    if (!state.accessToken) return;
    try {
      setAuthHeaders(state.accessToken);
      const res = await api.auth.getInfo();
      commit('SET_CURRENT_USER', res.data);

      // FCM Token 갱신
      if (updateMessageToken) {
        dispatch('updateMessageToken');
      }

      return res.data;
    } catch (error) {
      commit('SET_CURRENT_USER', null);
      return null;
    }
  },

  logout({ commit }) {
    api.auth.updateMessageToken(-1);
    commit('SET_ACCESS_TOKEN', null);
    commit('SET_CURRENT_USER', null);
  },

  async updateMessageToken({ commit }) {
    try {
      await window.Notification.requestPermission().then(() => {
        messaging.getToken().then(async currentToken => {
          commit('SET_WEB_TOKEN', currentToken);
          api.auth.updateMessageToken(currentToken);
        });
      });
    } catch (error) {
      commit('SET_WEB_TOKEN', -1);
      api.auth.updateMessageToken(-1);
    }
  },
};

function setAuthHeaders(token) {
  axios.defaults.headers = {
    ...axios.defaults.headers,
    Authorization: `Bearer ${token}`,
  };
}
