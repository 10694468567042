import axios from '../axios';

const BASE_URL = '/v2/staff/locker';

export default {
  get: params => {
    params = { ...params, orderBy: 'id:asc', with: 'member' };
    return axios.get(BASE_URL, { params });
  },

  create: data => {
    return axios.post(`${BASE_URL}/batch/${data}`, {
      type: data.type === 'T' ? 'TERM' : 'DAILY',
    });
  },

  update: data => {
    const params = {
      member_id: data.memberId,
      name: data.lockerName,
      start_on: data.start_on,
      end_on: data.end_on,
      type: data.type,
      status: data.status ? data.status : 'USE',
      etc: data.etc,
    };

    return axios.patch(`${BASE_URL}/${data.lockerId}`, params);
  },

  delete: id => axios.delete(`${BASE_URL}/${id}`),
};
