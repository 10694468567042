<template>
  <div class="lecture-list-filter">
    <!-- 조회 방식 -->
    <div class="lecture-list-filter__query-type">
      <el-select v-model="queryType">
        <el-option v-for="item in rangeTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>

    <!-- 날짜별 조회 -->
    <div class="lecture-list-filter__date" v-show="filterValues.rangeType === 'date'">
      <el-button icon="el-icon-arrow-left" @click="onClickPrevDate" />
      <el-date-picker v-model="date" type="date" align="left" format="yyyy. M. d." value-format="yyyy-MM-dd" :clearable="false" />
      <el-button icon="el-icon-arrow-right" @click="onClickNextDate" />
    </div>

    <!-- 기간별 조회 -->
    <div class="lecture-list-filter__date" v-show="filterValues.rangeType === 'range'">
      <el-date-picker
        v-model="range"
        type="daterange"
        align="left"
        format="yyyy. M. d."
        value-format="yyyy-MM-dd"
        :clearable="false"
      />
    </div>

    <div
      class="lecture-list-filter__filter"
      :class="{ 'is-filtered': isFiltered(key, filterValues[key]) }"
      v-for="key in filterKeys"
      :key="key"
    >
      <el-popover
        v-if="key === 'timeRange'"
        popper-class="lecture-list-filter__popover"
        placement="bottom"
        v-model="showPopover[key]"
        @after-leave="() => handleClickCancel(key)"
      >
        <div class="lecture-list-filter__popover__inputs">
          <HoursInput
            :value="customValues[key]"
            startLabel="시작시간"
            endLabel="종료시간"
            :startHourOptions="{ start: '00:00', end: '23:55', step: '00:05' }"
            :endHourOptions="{
              start: customValues[key].start || '00:00',
              end: '23:55',
              step: '00:05',
              minTime: customValues[key].start,
            }"
            clearable
          />
        </div>

        <div class="lecture-list-filter__popover__checkbox">
          <el-checkbox v-model="searchRange">범위로 검색</el-checkbox>
        </div>

        <div class="lecture-list-filter__popover__buttons">
          <el-button @click="showPopover[key] = false">
            취소
          </el-button>
          <el-button type="primary" @click="handleClickApply(key)">
            적용
          </el-button>
        </div>
        <el-button slot="reference">
          {{ getButtonLabel(key) }}
          <i v-if="!hasTimeRangeValues" class="el-icon-arrow-down"></i>
          <button v-else @click="handleClickResetTimeRange">
            <i class="el-icon-close"></i>
          </button>
        </el-button>
      </el-popover>

      <!-- '강사 전체' 필터  -->
      <el-select
        v-else-if="key === 'instructors'"
        :value="filterValues[key]"
        @change="value => handleChangeFilter({ [key]: value })"
        :multiple="filterOptions[key].multiple"
        :placeholder="filterOptions[key].placeholder"
        collapse-tags
        clearable
      >
        <el-option v-for="item in filterOptions[key].options" :key="item.value" :label="item.label" :value="item.value">
          {{ item.label }}
          <span v-if="item.deleted_at">삭제 강사</span>
        </el-option>
      </el-select>

      <!-- 그 외 필터 -->
      <el-select
        v-else
        :value="filterValues[key]"
        @change="value => handleChangeFilter({ [key]: value })"
        :multiple="filterOptions[key].multiple"
        :placeholder="filterOptions[key].placeholder"
        collapse-tags
        clearable
      >
        <el-option
          class="lecture-list-filter__option-list"
          v-for="item in filterOptions[key].options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <FilterResetButton @click="$emit('reset-click')" />

    <span class="lecture-list-filter__lectures-count">
      <span> 필터된 수업 {{ total | comma }} 개 </span>
      <span v-if="!!selectedLecturesCount">/</span>
      <span v-if="!!selectedLecturesCount" class="lecture-list-filter__lectures-count--selected">
        {{ selectedLecturesCount | comma }} 개 선택됨
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    filterValues: Object,
    filterOptions: Object,
    total: { type: Number, default: 0 },
    selectedLecturesCount: { type: Number, default: 0 },
  },

  data() {
    return {
      rangeTypeOptions: [
        { value: 'date', label: '날짜' },
        { value: 'range', label: '기간' },
      ],
      showPopover: {
        timeRange: false,
      },
      customValues: {
        timeRange: { start: null, end: null },
        exactTimeRange: true,
      },
    };
  },

  computed: {
    filterKeys() {
      let keys = ['weekdays', 'timeRange', 'instructors', 'courseType', 'divisions'];
      return !this.studioPolicies.is_use_rooms ? keys : [...keys, 'rooms'];
    },

    queryType: {
      get() {
        return this.filterValues.rangeType;
      },
      set(rangeType) {
        const today = this.moment();
        let dateRange = [today.format('YYYY-MM-DD')];
        if (rangeType === 'date') {
          dateRange.unshift(today.format('YYYY-MM-DD'));
        } else if (rangeType === 'range') {
          dateRange.unshift(today.subtract(1, 'week').format('YYYY-MM-DD'));
        }

        this.$emit('change', {
          ...this.filterValues,
          rangeType,
          dateRange,
        });
      },
    },

    date: {
      get() {
        return this.filterValues.dateRange[0];
      },
      set(date) {
        if (!date) return;
        this.handleChangeDate(date);
      },
    },

    range: {
      get() {
        return this.filterValues.dateRange;
      },
      set(dateRange) {
        this.$emit('change', { ...this.filterValues, dateRange });
      },
    },

    hasTimeRangeValues() {
      const { start, end } = this.filterValues.timeRange;
      return !!start || !!end;
    },

    searchRange: {
      get() {
        return !this.customValues.exactTimeRange;
      },
      set(value) {
        this.customValues.exactTimeRange = !value;
      },
    },
  },

  methods: {
    handleChangeDate(date) {
      this.$emit('change', {
        ...this.filterValues,
        dateRange: [date, date],
      });
    },

    onClickPrevDate() {
      const yesterday = this.moment(this.date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      this.handleChangeDate(yesterday);
    },

    onClickNextDate() {
      const tommorrow = this.moment(this.date)
        .add(1, 'day')
        .format('YYYY-MM-DD');
      this.handleChangeDate(tommorrow);
    },

    handleChangeFilter(values) {
      this.$emit('change', { ...this.filterValues, ...values });
    },

    isFiltered(key, value) {
      if (key === 'timeRange') {
        const { start, end } = value;
        return !!start || !!end;
      }
      return !(!value || !value.length);
    },

    handleClickApply(key) {
      this.showPopover[key] = false;
      this.handleChangeFilter({ ...this.customValues });
    },

    handleClickCancel(key) {
      this.showPopover[key] = false;
      this.customValues = {
        timeRange: { ...this.filterValues.timeRange },
        exactTimeRange: this.filterValues.exactTimeRange,
      };
    },

    getButtonLabel(key) {
      const { start, end } = this.filterValues[key];
      let label = '';

      if (!start && !end) label = '수업시간 전체';
      else if (!end) label = `${start} ~`;
      else if (!start) label = `~ ${end}`;
      else label = `${start} ~ ${end}`;

      return label;
    },

    handleClickResetTimeRange() {
      this.customValues = {
        timeRange: { start: null, end: null },
        exactTimeRange: true,
      };
      this.handleChangeFilter({ ...this.customValues });
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-list-filter {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;

  & > * {
    height: 36px;
    margin: 0 8px 8px 0;
  }

  /deep/ .el-input__inner {
    @extend %input-default;
    border-color: #dcdfe6;
    height: 36px;

    &::placeholder {
      color: $charcoal-grey;
    }
  }

  /deep/ .el-select .el-input__inner {
    padding: 0 20px 0 10px;
  }

  /deep/ .el-date-editor {
    min-width: 135px;
    max-width: 250px;
  }

  &__query-type {
    width: 80px;
  }

  &__date {
    @include flex(row, center, center);

    & > * {
      margin-right: 4px;

      &:last-child {
        margin: 0;
      }
    }

    /deep/ .el-button {
      padding: 0;
      height: 36px;
      width: 36px;
    }
  }

  &__filter {
    width: 140px;

    /deep/ .el-select__tags > span {
      @include flex(row, center);
    }

    /deep/ .el-tag {
      background: none !important;
      color: $charcoal-grey;
      font-size: 14px;
      padding: 0;
      max-width: 80px;
      @include ellipsis;

      &::after {
        content: ', ';
      }

      &:last-child::after {
        content: '';
      }

      i {
        display: none;
      }
    }

    /deep/ .el-button {
      padding: 0 10px;
      height: 36px;
      width: 100%;

      span {
        @include flex(row, center, space-between);
        i {
          color: #c0c4cc;
        }

        button {
          @include flex(row, center, center);
          border-radius: 50%;
          padding: 0;

          &:hover {
            background: $deep-sky-blue;
            i {
              color: #fff;
            }
          }
        }
      }
    }

    &.is-filtered {
      /deep/ .el-input__inner,
      /deep/ .el-button {
        border-color: $deep-sky-blue;
        color: $deep-sky-blue;

        i {
          color: $deep-sky-blue;
        }
      }

      /deep/ .el-input__icon,
      /deep/ .el-tag {
        color: $deep-sky-blue;
      }
    }
  }

  &__option-list {
    max-width: 320px;
  }

  &__popover {
    &__inputs {
      padding: 10px 0;
    }

    &__checkbox {
      padding: 10px 20px;
    }

    &__buttons {
      padding: 10px 20px;
      text-align: right;
    }
  }

  &__lectures-count {
    @include flex(row, center, center);
    cursor: default;
    font-size: 14px;
    font-weight: bold;

    & > span {
      margin-right: 8px;
    }

    &--selected {
      color: $dodger-blue;
    }
  }
}

.el-select-dropdown__item {
  span {
    background-color: rgba(245, 108, 108, 0.1);
    border: 1px solid rgba(245, 108, 108, 0.2);
    color: #f95454;
    width: 60px;
    height: 20px;
    padding: 0 3px;
    margin-left: 2px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    border-radius: 5px;
  }
}
</style>
