import _ from 'lodash';
import api from '@api';
import { PERMISSION_ORDER } from '@/constants';

export const state = {
  studio: {},
  roles: [],
  activeRole: null,
  permissions: [],
  legacyPermissionIds: _.range(1, 20),
  permissionTabs: [],
  activePermissionTab: 'facility_management',
};

export const getters = {
  studio: state => state.studio,
  holidays: state => {
    if (!state.studio.holidays) return [];
    return state.studio.holidays.filter(({ deleted_at }) => !deleted_at).map(({ date }) => date);
  },
  policies: state => state.studio.policy || {},

  roles: state => state.roles,
  activeRole: state => state.activeRole,
  permissions: state => state.permissions,
  legacyPermissionIds: state => state.legacyPermissionIds,
  permissionItems: state => {
    const permissionTypeActive = state.permissions.find(({ name }) => name === state.activePermissionTab);

    return permissionTypeActive ? permissionTypeActive.items : [];
  },
  permissionTabs: state => {
    return state.permissions.map(({ name, display_name }) => {
      return { value: name, label: display_name };
    });
  },
  activePermissionTab: state => state.activePermissionTab,
};

export const mutations = {
  SET_STUDIO(state, studio) {
    state.studio = { ...studio };
  },

  SET_ROLES(state, roles) {
    state.roles = roles;
  },

  SET_ACTIVE_ROLE(state, role) {
    state.activeRole = role;
  },

  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },

  SET_ACTIVE_PERMISSION_TAB(state, permission_name) {
    state.activePermissionTab = permission_name;
  },
};

export const actions = {
  init({ rootState, dispatch }) {
    if (!rootState.auth || !rootState.auth.accessToken) {
      return;
    }
    dispatch('getStudio');
  },

  async getStudio({ commit }) {
    try {
      const res = await api.studio.get();
      commit('SET_STUDIO', res.data);
    } catch (error) {
      commit('SET_STUDIO', {});
    }
  },

  async updateStudio({ commit }, data) {
    try {
      const res = await api.studio.update(data);
      commit('SET_STUDIO', res.data);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  async getRoles({ state, commit, dispatch }, activeRoleId) {
    try {
      const res = await api.studio.roles.getAll();
      commit('SET_ROLES', res.data);
      if (typeof activeRoleId === 'number') {
        const role = res.data.find(({ id }) => id === activeRoleId);
        dispatch('setActiveRole', role);
      } else if (!state.activeRole) {
        dispatch('setActiveRole', res.data[0]);
      }
    } catch (error) {
      commit('SET_ROLES', []);
    }

    return;
  },

  async setActiveRole({ commit }, role) {
    if (!role) commit('SET_ACTIVE_ROLE', null);

    try {
      const res = await api.studio.roles.permissions.get(role.id);
      role.permissions = res.data;
      commit('SET_ACTIVE_ROLE', role);
    } catch (error) {
      commit('SET_ACTIVE_ROLE', null);
    }
  },

  async getPermissions({ commit }) {
    try {
      const res = await api.studio.permissions.getTypes();
      const permissions = await Promise.all(
        res.data.map(async type => {
          try {
            const itemsRes = await api.studio.permissions.get(type.id);
            /** 권한 구조화 */
            let items = itemsRes.data.filter(item => {
              item.order = PERMISSION_ORDER.findIndex(key => key === item.id);
              item.children = itemsRes.data.filter(({ parent_id }) => parent_id === item.id);
              return !item.parent_id && item.id > 100000;
            });

            /** 정렬 */
            items.forEach(item => (item.children = _.sortBy(item.children, 'order')));
            items = _.sortBy(items, 'order');

            return { ...type, items };
          } catch (error) {
            return type;
          }
        }),
      );
      commit('SET_PERMISSIONS', permissions);
    } catch (error) {
      commit('SET_PERMISSIONS', []);
    }
  },
  async agreeServiceTerms({ dispatch }) {
    try {
      const res = await api.studio.agreeTerms.update();
      if (res.data.is_terms === 1) {
        dispatch('getStudio');
      }
    } catch (error) {
      this.$alert('약관동의에 실패하였습니다.', '약관 동의 실패', {
        confirmButtonText: '확인',
      });
    }
  },
};
