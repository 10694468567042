<template>
  <MainLayout>
    <HeaderTabs activeTab="/lectures/check_in" :exceldownloading="exceldownloading" @exceldown="downloadExcel" />

    <ListFilter
      v-loading="loading"
      :rangeFilter="rangeFilter"
      :localFilter="localFilter"
      :localFilterOptions="localFilterOptions"
      @change="handleFilterChange"
    />

    <h3 v-if="lectureCount" style="padding: 10px">수업 {{ lectureCount }}건 (회원 {{ bookingsCount.total }}명)</h3>

    <List
      v-loading="loading"
      :element-loading-text="checkInLoadingPercent"
      :bookings="bookingListData"
      :checkIn="true"
      @sort-change="handleFilterChange"
      :attendanceStatus="attendanceStatus"
    />
  </MainLayout>
</template>

<script>
import HeaderTabs from '@components/Lectures/HeaderTabs';
import ListFilter from '@components/Lectures/ListFilter';
import List from '@components/Lectures/BookingList';

export default {
  components: { HeaderTabs, ListFilter, List },

  data() {
    const { moment } = this;

    return {
      loading: false,
      bookings: [],
      bookingApiReqArr: [],
      bookingApiReqTerm: 1,
      exceldownloading: false,
      bookingListData: [],
      memberLoading: {
        loadingPage: 0,
        loadingTotalPage: 1,
      },
      attendanceStatus: null,

      rangeFilter: {
        type: 'date',
        dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },
      localFilter: {
        instructor: null,
        courseType: null,
        name: null,
        time: { start: null, end: null, range: false },
        attendance: null,
      },
    };
  },

  async created() {
    await this.fetchBookings();
    this.filteredBookings();
  },

  computed: {
    localFilterOptions() {
      let filterOptions = {
        courseType: [{ value: null, label: '수업 전체' }],
        weekdays: {
          multiple: true,
          placeholder: '모든 요일',
          options: [
            { value: 1, label: '월' },
            { value: 2, label: '화' },
            { value: 3, label: '수' },
            { value: 4, label: '목' },
            { value: 5, label: '금' },
            { value: 6, label: '토' },
            { value: 0, label: '일' },
          ],
        },
        instructor: [{ value: null, label: '강사 전체' }],
        attendance: [{ value: null, label: '출결 전체' }],
      };

      if (!this.bookings.length) return filterOptions;

      this.bookings.forEach(({ status, lecture }) => {
        if (!lecture.deleted_at) {
          const attendance = status === 'attendance' ? '출석' : status === 'absence' ? '결석' : '취소';
          const value = {
            instructor: _.get(lecture, 'staff.profile.name'),
            courseType: _.get(lecture, 'course.type'),
            attendance,
          };
          const label = {
            instructor: value.instructor,
            courseType: this.$utils.translate.courseType(value.courseType),
            attendance: value.attendance,
          };

          Object.keys(value).forEach(key => {
            if (!filterOptions[key].some(option => option.value === value[key])) {
              filterOptions[key].push({ value: value[key], label: label[key] });
            }
          });
        }
      });

      return filterOptions;
    },

    lectureCount() {
      return _.uniq(this.bookingListData.map(({ lecture_id }) => lecture_id)).length;
    },

    bookingsCount() {
      const total = this.bookingListData.length;
      const P = this.bookingListData.filter(booking => booking.lecture.course.type === 'P').length;
      const G = total - P;
      return { total, P, G };
    },

    checkInLoadingPercent() {
      if (this.memberLoading.loadingTotalPage) {
        return `${this.memberLoading.loadingPage} / ${this.memberLoading.loadingTotalPage}`;
      }
      return null;
    },

    checkInSortType() {
      return this.$store.getters['lectures/checkInSortType'];
    },

    updateTimeSortType() {
      return this.$store.getters['lectures/updateTimeSortType'];
    },
  },

  watch: {
    $route: {
      handler: 'filteredBookings',
      immediate: true,
    },

    attendanceStatus() {
      this.fetchBookings();
    },
  },

  methods: {
    filteredBookings(keyword, header = 'start_on') {
      const { instructor, courseType, weekdays, time, attendance } = this.localFilter;
      /**
       * 삭제된 수업 제거
       * 필터 조건 적용
       */
      let bookingsCountInLecture = {};

      let filteredBookings = this.bookings.filter(({ lecture, status, updated_for, member, enter_at }) => {
        //취소 허용시 &&로 바꿈
        if (status === 'cancel' && updated_for === '당일예약 변경') return false;
        const isInstructorMatch = !instructor || lecture.staff.profile.name === instructor;
        const isCourseTypeMatch = !courseType || lecture.course.type === courseType;

        let currentAttendanceStatus;
        switch (attendance) {
          case '출석':
            currentAttendanceStatus = 'attendance';
            break;
          case '결석':
            currentAttendanceStatus = 'absence';
            break;
          case '취소':
            currentAttendanceStatus = 'cancel';
        }
        const isAttendanceMatch = !attendance || currentAttendanceStatus === status;

        let searchForName = false;
        if (
          !keyword ||
          (keyword && member.profile.name.includes(keyword)) ||
          (member.mobile && member.mobile.includes(keyword))
        ) {
          searchForName = true;
        }

        let isWeekdays = !weekdays ? true : false;

        let days;
        if (weekdays) {
          days = weekdays.split(',');
          days.map(el => {
            if (Number(el) === this.moment(enter_at).day()) {
              return (isWeekdays = true);
            }
          });
        }

        let isRangeTime = !time.start && !time.end ? true : false;
        if (!isRangeTime) {
          const start = this.$filters.time(lecture.start_on);
          const end = this.$filters.time(lecture.end_on);

          if (time.range) {
            if (
              (!time.end && Number(start.split(':').join('')) >= Number(time.start.split(':').join(''))) ||
              (!time.start && Number(end.split(':').join('')) <= Number(time.end.split(':').join(''))) ||
              (Number(start.split(':').join('')) >= Number(time.start.split(':').join('')) &&
                Number(end.split(':').join('')) <= Number(time.end.split(':').join('')))
            ) {
              isRangeTime = true;
            }
          } else {
            if (
              (time.start && !time.end && start === time.start) ||
              (!time.start && time.end && end === time.end) ||
              (time.start && time.end && start === time.start && end === time.end)
            ) {
              isRangeTime = true;
            }
          }
        }

        if (
          !isInstructorMatch ||
          !isCourseTypeMatch ||
          !isAttendanceMatch ||
          !!lecture.deleted_at ||
          !searchForName ||
          !isWeekdays ||
          !isRangeTime
        ) {
          return false;
        }

        /** 수업의 예약 카운트 저장 (rowspan 값으로 활용) */
        if (bookingsCountInLecture[lecture.id]) {
          bookingsCountInLecture[lecture.id]++;
        } else {
          bookingsCountInLecture[lecture.id] = 1;
        }

        return true;
      });

      const headers = [
        'lecture.start_on',
        'lecture.staff.profile.name',
        'lecture.course.type',
        'lecture.title',
        'lecture.room',
        'lecture.course.title',
      ];

      const currentHeader = header === 'start_on' ? this.checkInSortType : this.updateTimeSortType;
      const currentSortArr = header === 'start_on' ? [...headers, 'enter_at'] : ['enter_at', ...headers];
      const currentOrder = _.orderBy(filteredBookings, currentSortArr, [
        currentHeader === 'ascending' ? 'asc' : 'desc',
        'asc',
        'asc',
        'asc',
        'asc',
        'asc',
        'asc',
      ]);

      /** 정렬 */
      filteredBookings = currentOrder;

      /** 데이터에 rowspan 값 추가 */
      let prevLectureId;
      let filteredBookingsWithSpanInfo = [];
      filteredBookings.forEach(booking => {
        const lectureId = booking.lecture_id;
        if (prevLectureId !== lectureId) {
          booking.rowspan = bookingsCountInLecture[lectureId];
          prevLectureId = lectureId;
          filteredBookingsWithSpanInfo.push(booking);
        } else {
          booking.rowspan = 0;
          filteredBookingsWithSpanInfo.push(booking);
        }
        return booking;
      });
      this.bookingListData = filteredBookings;
      return filteredBookings;
    },

    recurReqTerm(count, t1, t2, reqIndex) {
      if (reqIndex === this.bookingApiReqTerm) {
        this.bookingApiReqArr.push({
          start_date: t1.format('YYYY-MM-DD'),
          end_date: t1.add({ months: reqIndex }).format('YYYY-MM-DD'),
        });
      } else {
        this.bookingApiReqArr.push({
          start_date: t1.add({ days: 1 }).format('YYYY-MM-DD'),
          end_date: t1
            .add({ months: this.bookingApiReqTerm })
            .subtract({ day: 1 })
            .format('YYYY-MM-DD'),
        });
      }

      count = count - this.bookingApiReqTerm;

      if (count > this.bookingApiReqTerm) {
        this.recurReqTerm(count, t1, t2, reqIndex + this.bookingApiReqTerm);
      } else {
        this.bookingApiReqArr.push({
          start_date: t1.add({ days: 1 }).format('YYYY-MM-DD'),
          end_date: this.rangeFilter.dates[1],
        });
      }
    },

    async fetchBookings() {
      this.memberLoading.loadingPage = 0;
      this.bookings = [];

      try {
        this.loading = true;
        let t1 = this.moment(this.rangeFilter.dates[0], 'YYYY-M-DD HH:mm');
        let t2 = this.moment(this.rangeFilter.dates[1], 'YYYY-MM-DD HH:mm');

        let count = this.moment.duration(t2.diff(t1)).asMonths();
        if (count > this.bookingApiReqTerm) {
          await this.recurReqTerm(count, t1, t2, this.bookingApiReqTerm);
        } else {
          const lectureDateSort = this.checkInSortType === 'ascending' ? 'asc' : 'desc';
          const checkinDateSort = this.updateTimeSortType === 'ascending' ? 'asc' : 'desc';

          this.bookingApiReqArr = [
            {
              start_date: this.rangeFilter.dates[0],
              end_date: this.rangeFilter.dates[1],

              sort_type: this.sort?.header === 'enter_at' ? checkinDateSort : lectureDateSort,
              is_check_in: true,
            },
          ];
        }

        if (this.bookingApiReqArr.length === 1) {
          this.memberLoading.loadingTotalPage = 0;
        } else {
          this.memberLoading.loadingTotalPage = this.bookingApiReqArr.length;
        }

        const resultParams = {
          params: {
            start_date: this.rangeFilter.dates[0],
            end_date: this.rangeFilter.dates[1],
            page: 1,
            limit: 15,
            is_check_in: true,
          },
          name: 'booking',
        };

        const res = await this.$utils.getApis(resultParams);

        this.bookings = res.data;
        this.bookingApiReqArr = [];
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    async handleFilterChange(key, value, keyword) {
      if (value.weekdays) value.weekdays = value.weekdays.toString();
      this[key] = value;

      if (key === 'rangeFilter' || key === 'sort') {
        await this.fetchBookings();
      }

      this.filteredBookings(keyword, value.header);
    },

    async downloadExcel() {
      this.exceldownloading = true;
      const json = this.formatJSON(this.bookingListData);

      let range = this.rangeFilter.dates[0];
      if (this.rangeFilter.type === 'week') {
        range += `_${this.rangeFilter.dates[1]}`;
      }

      /** 다운로드 */
      this.$utils.downloadExcel(json, `체크인내역_${range}.xlsx`);
      this.exceldownloading = false;
    },

    formatJSON(bookings) {
      return bookings.map(({ lecture, member, user_ticket, status, enter_at }) => {
        const lectureStartOn = this.moment(_.get(lecture, 'start_on'));
        const lectureEndOn = this.moment(_.get(lecture, 'end_on'));
        const courseType = this.$utils.translate.courseType(_.get(lecture, 'course.type'));
        const remainingDays = this.$utils.getRemainingDaysText(
          _.get(user_ticket, 'expire_at'),
          _.get(user_ticket, 'availability_start_at'),
        );
        const mobile = _.get(member, 'mobile');
        const mobileConverted = this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);

        let data = {
          수업일: lectureStartOn.format('YYYY-MM-DD'),
          수업시작: lectureStartOn.format('HH:mm'),
          수업종료: lectureEndOn.format('HH:mm'),
          강사: _.get(lecture, 'staff.profile.name'),
          수업구분: courseType,
          수업명: _.get(lecture, 'title') || _.get(lecture, 'course.title'),
          룸: _.get(lecture, 'room.name'),
          예약상태: this.$utils.translate.bookingStatus(status),
          체크인시간: this.$filters.time(enter_at),
          회원명: _.get(member, 'profile.name'),
          휴대폰번호: mobile ? mobileConverted : null,
          수강권명: _.get(user_ticket, 'ticket.title'),
          수강권잔여기간: remainingDays,
        };

        if (user_ticket.ticket.type !== 'P') {
          data = {
            ...data,
            수강권잔여횟수: _.get(user_ticket, 'remaining_coupon'),
            수강권전체횟수: _.get(user_ticket, 'max_coupon'),
          };
        }

        return data;
      });
    },
  },
};
</script>
