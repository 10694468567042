<template>
  <li class="user-ticket" :class="{ selected }" @click="$emit('click', userTicket)">
    <img class="user-ticket__image" :src="memberImage" :alt="memberName" />

    <p class="user-ticket__user-profile name-wrapper">
      <span>{{ memberName }}</span>
      <span v-if="memberMobile"> · {{ memberMobile | mobile }}</span>
      <vaccine-image v-if="getVaccination" />
    </p>

    <p class="user-ticket__ticket-info">
      <span>{{ ticketTitle }}</span>
      <span v-if="ticketType !== 'P'"> · {{ usableCoupon }}회 남음</span>
      <span> · {{ availablePeriod }}</span>
    </p>

    <img class="user-ticket__selected-icon" :src="selectedIcon" alt="selected-icon" />
  </li>
</template>

<script>
import VaccineImage from '../shared/VaccineImage.vue';
export default {
  components: { VaccineImage },
  props: {
    userTicket: { type: Object, required: true },
    selected: { type: Boolean, default: false },
  },

  computed: {
    memberImage() {
      return this.$utils.getImagePath(_.get(this.userTicket, 'member.image'), 36);
    },

    memberName() {
      return _.get(this.userTicket, 'member.name');
    },

    memberMobile() {
      return this.canViewMembersMobile
        ? _.get(this.userTicket, 'member.mobile')
        : this.$filters.mobileMask(_.get(this.userTicket, 'member.mobile')) || '';
    },

    getVaccination() {
      const vaccine = _.get(this.userTicket, 'member.vaccination_yn');
      return vaccine === 'Y';
    },

    ticketTitle() {
      return _.get(this.userTicket, 'ticket.title');
    },

    ticketType() {
      return _.get(this.userTicket, 'ticket.type');
    },

    usableCoupon() {
      return _.get(this.userTicket, 'usable_coupon');
    },

    availablePeriod() {
      const start = _.get(this.userTicket, 'availability_start_at');
      const end = _.get(this.userTicket, 'expire_at');
      return `${this.$filters.date(start)}~${this.$filters.date(end)}`;
    },

    selectedIcon() {
      return !this.selected ? require('@icons/checkbox_circle_unchecked.svg') : require('@icons/checkbox_circle_checked.svg');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-ticket {
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'image user-profile selected-icon'
    'image ticket-info selected-icon';
  grid-column-gap: 12px;
  padding: 12px;
  transition: 0.15s;

  & + & {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  &:hover {
    background: $gray-100;
  }

  &.selected {
    background: rgba($color-primary, 0.1);
  }

  &__image {
    grid-area: image;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  &__user-profile {
    grid-area: user-profile;
    font-size: 13px;
  }

  &__ticket-info {
    grid-area: ticket-info;
    font-size: 11px;
    opacity: 0.7;
  }

  &__selected-icon {
    align-self: center;
    grid-area: selected-icon;
  }
}
.name-wrapper {
  justify-content: flex-start;

  span:first-of-type {
    margin-right: 2px;
  }
}
</style>
