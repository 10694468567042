<template>
  <el-dialog
    class="payment-result-dialog"
    :title="payResult"
    :visible="show"
    :show-close="false"
    :close-on-click-modal="false"
    width="500px"
  >
    <div v-html="paymentResultInfo"></div>

    <div v-if="!payload.success" class="payment-result-dialog__fail">
      <span>실패사유 : {{ payload.paymentResultCode }} ({{ payload.errorMessage }}) </span>
    </div>
    <txtChangePayPlan
      v-if="showInfo"
      :existPlan="payload.existPlan"
      :selectedPlan="payload.selectedPlan"
      :paymentDate="payload.paymentDate"
    />
    <div slot="footer">
      <BaseButton @click="$emit('close')" size="large">확인</BaseButton>
    </div>
  </el-dialog>
</template>

<script>
import txtChangePayPlan from './txtChangePayPlan';
export default {
  components: {
    txtChangePayPlan,
  },
  props: {
    show: { type: Boolean, default: false },
    payload: { type: Object, default: null },
  },
  computed: {
    paymentResultInfo() {
      if (this.payload.success) {
        return '성공적으로 결제 되었습니다.';
      }

      return '결제 실패하였습니다.';
    },

    payResult() {
      return this.payload.success ? '결제 성공' : '결제 실패';
    },
    showInfo() {
      if (this.payload.success) {
        return (
          (this.payload.existPlan === 2 || this.payload.existPlan === 4) &&
          (this.payload.selectedPlan.id === 1 || this.payload.selectedPlan.id === 3)
        );
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped></style>
