<template>
  <div class="number-input-circle">
    <div class="number-input-circle__input">
      <input
        :class="['number-input-circle__input__input', value < 0 ? 'minus' : null]"
        :value="value"
        :disabled="disabled"
        @keypress="validateKey"
        @input="handleInput($event.target.value)"
        @blur="onBlur"
      />
      <span v-if="!!suffix" class="number-input-circle__input__suffix" v-html="suffix" />
    </div>
    <button class="number-input-circle__button" :disabled="disabled || value >= max" @click="handleInput(value + 1)">
      <i class="el-icon-plus"></i>
    </button>
    <button class="number-input-circle__button" :disabled="disabled || value <= min" @click="handleInput(value - 1)">
      <i class="el-icon-minus"></i>
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: [Number, String],
    suffix: String,
    min: { type: Number, default: 0 },
    max: { type: Number, default: 999999999 },
    disabled: { type: Boolean, default: false },
  },

  methods: {
    validateKey(e) {
      if (!e.key.replace(/\D/g, '')) return e.preventDefault();

      const newValue = Number(e.srcElement.value + e.key);
      if (!newValue) return e.preventDefault();

      const isExceedsMin = newValue < this.min;
      const isExceedsMax = newValue > this.max;
      if (isExceedsMin || isExceedsMax) {
        e.preventDefault();
        this.handleInput(newValue);
      }
    },

    handleInput(value) {
      this.$emit('input', this.calibrateValue(value));
    },

    onBlur(e) {
      if (!e.target.value) {
        this.$emit('input', null);
      }
    },

    calibrateValue(value) {
      return Math.max(Math.min(Number(value), this.max), this.min);
    },
  },
};
</script>

<style lang="scss" scoped>
.number-input-circle {
  @include flex(row, center, center);

  &__input {
    flex: 1;
    @include flex(row, center, center);
    position: relative;
    .minus {
      color: $coral;
    }
    &__input {
      @extend %input-default;
      border-width: 0 0 2px 0;
      border-radius: 0;
      padding: 5px;
      flex: 1;
      line-height: 1.45;
      width: 100%;

      &::placeholder {
        opacity: 0.7;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &__suffix {
      position: absolute;
      opacity: 0.6;
      right: 8px;
    }
  }

  &__button {
    @include flex(row, center, center);
    border: 1px solid $charcoal-grey;
    color: $charcoal-grey;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 0 0 8px;
    padding: 0;
    transition: 0.15s background, 0.15s border-color, 0.15s color;

    &:hover {
      background: rgba($deep-sky-blue, 0.2);
      border-color: $deep-sky-blue;
      color: $deep-sky-blue;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background: none;
        border-color: $charcoal-grey;
        color: $charcoal-grey;
      }
    }
  }
}
</style>
