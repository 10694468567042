import getPaymentMethod from './getPaymentMethod';

/** 결제 정보를 서버 전송용으로 맵핑 */
export default payment => {
  const {
    installment,
    installment_period
  } = payment;
  const method = getPaymentMethod(payment) || 'mixed';

  return {
    ..._.omit(payment, 'installment'),
    method,
    installment_period: installment || installment_period,
  };
};