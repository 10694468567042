<template>
  <div class="datetime-input">
    <el-date-picker
      v-model="date"
      type="date"
      format="yyyy. M. d."
      value-format="yyyy-MM-dd"
      :clearable="false"
      readonly
    />
    <span>{{ weekday }}</span>
    <HoursInput
      v-model="time"
      :startHourOptions="{
        start: '00:00',
        end: '23:50',
        step: '00:05',
      }"
      :endHourOptions="{
        start: time.start || '00:00',
        end: '23:55',
        step: '00:05',
        minTime: time.start,
      }"
      :clearable="false"
    />
    <el-button size="small" type="info" :disabled="!time.start" @click="setEndTime(30)">30분</el-button>
    <el-button size="small" type="info" :disabled="!time.start" @click="setEndTime(50)">50분</el-button>
    <PlainButton @click="handleClickApplyAll" type="info" round>같은 요일에 일괄 적용</PlainButton>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
  },

  computed: {
    date: {
      get() {
        return this.value.date;
      },
      set(date) {
        this.$emit('input', { ...this.value, date });
      },
    },

    weekday() {
      return this.moment(this.value.date).format('(ddd)');
    },

    time: {
      get() {
        const { start, end } = this.value;
        return { start, end };
      },
      set(time) {
        this.$emit('input', { ...this.value, ...time });
      },
    },
  },

  methods: {
    setEndTime(duration) {
      const [hour, minute] = this.value.start.split(':');
      const end = this.moment()
        .hour(hour)
        .minute(minute)
        .add(duration, 'minutes')
        .format('HH:mm');
      this.$emit('input', { ...this.value, end });
    },

    handleClickApplyAll() {
      this.$emit('apply-all', this.date, this.time);
    },
  },
};
</script>

<style lang="scss" scoped>
.datetime-input {
  @include flex(row, center, flex-start);

  button + button {
    margin-left: 8px;
  }
}
</style>
