import api from '@api';

const DEFAULT_PARAMS = { limit: 15, page: 1 };

export const state = {
  params: { ...DEFAULT_PARAMS },
  notifications: [],
  checkinNotis: [],
  enterAttendanceCount: 0,
  total: 0,
  checkInTotal: 0,
  unreadCount: 0,
  unreadEnterCount: 0,
  loading: false,
  isOnFCMMessage: false,
  liveCheckinNotis: [],
};

export const getters = {
  params: state => state.params,
  notifications: state => state.notifications,
  checkinNotis: state => state.checkinNotis,
  enterAttendanceCount: state => state.enterAttendanceCount,
  total: state => state.total,
  checkInTotal: state => state.checkInTotal,
  unreadCount: state => state.unreadCount,
  unreadEnterCount: state => state.unreadEnterCount,
  loading: state => state.loading,
  isOnFCMMessage: state => state.isOnFCMMessage,
  liveCheckinNotis: state => state.liveCheckinNotis,
};

export const mutations = {
  SET_PARAMS(state, params) {
    state.params = { ...params };
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_NOTIFICATIONS(state, data) {
    state.notifications = [...data.data];
    state.total = data.meta ? data.meta.total : data.total;
    state.unreadCount = data.unchecked_count;
  },

  APPEND_NOTIFICATIONS(state, data) {
    state.notifications = [...state.notifications, ...data.data];
  },

  SET_CHECKIN_NOTI(state, data) {
    state.checkinNotis = [...data.data];
    state.enterAttendanceCount = data.enter_attendance_count;
    state.checkInTotal = data.meta ? data.meta.total : data.total;
    state.unreadEnterCount = data.unchecked_enter_count;
  },

  APPEND_CHECKIN_NOTI(state, data) {
    state.checkinNotis = [...state.checkinNotis, ...data.data];
  },

  SET_ON_FCM_MESSAGE(state, data) {
    state.isOnFCMMessage = data;
  },

  ADD_LIVE_CHECKIN_NOTIS(state, data) {
    if (state.liveCheckinNotis.length === 4) state.liveCheckinNotis.pop();
    state.liveCheckinNotis.unshift(data);
  },

  INIT_LIVE_CHECKIN_NOTIS(state) {
    state.liveCheckinNotis = [];
  },

  SET_CLOSE_CHECKIN_NOTIS(state, data) {
    state.liveCheckinNotis = [...data];
  },
};

export const actions = {
  async getNotifications({ state, commit }, reset = true) {
    try {
      commit('SET_LOADING', true);
      const params = reset ? { ...DEFAULT_PARAMS, type: state.params.type } : { ...state.params };
      const res = await api.notification.v2getAll(params);

      if (reset) {
        commit('SET_NOTIFICATIONS', res.data);
      } else {
        commit('APPEND_NOTIFICATIONS', res.data);
      }
      commit('SET_PARAMS', params);
    } catch (error) {
      commit('SET_NOTIFICATIONS', { data: [], total: 0 });
      commit('SET_PARAMS', DEFAULT_PARAMS);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getCheckinNoti({ state, commit }, reset = true) {
    try {
      commit('SET_LOADING', true);
      const params = reset ? { ...DEFAULT_PARAMS, type: state.params.type } : { ...state.params };
      const res = await api.notification.getAllCheckin(params);

      if (reset) {
        commit('SET_CHECKIN_NOTI', res.data);
      } else {
        commit('APPEND_CHECKIN_NOTI', res.data);
      }
      commit('SET_PARAMS', params);
    } catch (error) {
      commit('SET_CHECKIN_NOTI', { data: [], total: 0 });
      commit('SET_PARAMS', DEFAULT_PARAMS);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getLiveCheckinNotis({ commit }, data) {
    try {
      if (!data) commit('INIT_LIVE_CHECKIN_NOTIS');
      else {
        commit('ADD_LIVE_CHECKIN_NOTIS', data);
      }
    } catch (error) {
      commit('INIT_LIVE_CHECKIN_NOTIS');
    }
  },

  async getCloseLiveCheckinNotis({ state, commit }, bookingId) {
    try {
      const finalData = state.liveCheckinNotis.filter(data => data.booking_id !== bookingId);

      if (!finalData.length) commit('INIT_LIVE_CHECKIN_NOTIS');
      else commit('SET_CLOSE_CHECKIN_NOTIS', finalData);
    } catch (error) {
      commit('INIT_LIVE_CHECKIN_NOTIS');
    }
  },
};
