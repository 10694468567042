<template>
  <button :type="type" :class="variation" @click="onClick">
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    type: String,
    variation: String,
    label: String,
    onClick: Function,
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  min-height: 48px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.2;
  transition: 0.15s;
  word-break: keep-all;

  &:hover {
    opacity: 0.8;
  }
}

.cancel {
  background: $gray-800;
  color: #fff;

  &:disabled {
    background: $gray-400;
  }
}

.success {
  background: $link-color;
  color: #fff;

  &:disabled {
    cursor: not-allowed;
    background: $gray-400;
    opacity: 1;
  }
}
</style>
