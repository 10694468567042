<template>
  <section class="auto-message-detail" v-loading="loading">
    <div class="auto-message-detail__buttons">
      <PlainButton size="large" type="info" @click="$router.push({ path: '/message/auto-message/list', query })">
        <i class="el-icon-tickets" /> 목록
      </PlainButton>
      <PlainButton size="large" type="info" :disabled="!prevMessageId" @click="handleClickNavMessage(prevMessageId)">
        <i class="el-icon-arrow-left" /> 이전
      </PlainButton>
      <PlainButton size="large" type="info" :disabled="!nextMessageId" @click="handleClickNavMessage(nextMessageId)">
        다음 <i class="el-icon-arrow-right" />
      </PlainButton>
    </div>

    <div class="auto-message-detail__header">
      <div class="auto-message-detail__header__title">
        <h4>{{ message.title }}</h4>
        <p>
          <span style="margin-right: 24px;">{{ messageSendOn }}</span>
          <span v-if="message.status === '전송 성공'"> <b>1건</b> 발송 </span>
          <span v-else> {{ message.status }} </span>
        </p>
      </div>

      <div class="auto-message-detail__header__summary">
        <label>대상회원</label>
        <div class="auto-message-detail__header__summary__members">
          <p ref="members-list" class="show-less">
            {{ message.member.name }}
          </p>
        </div>
      </div>
    </div>

    <div class="auto-message-detail__body">
      <p>{{ message.context }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      message: {
        title: '',
        member: { name: '', mobile: '' },
        context: '',
      },
      prevMessageId: 0,
      nextMessageId: 0,
    };
  },

  computed: {
    messageSendOn() {
      return !this.message.created_at ? null : this.moment(this.message.created_at).format('YYYY. M. D. (ddd) HH:mm');
    },
    query() {
      return this.$route.query;
    },
  },

  created() {
    this.getMessage();
  },

  methods: {
    async getMessage() {
      this.loading = true;
      try {
        const { data } = await this.$api.message.automessage.getOne(this.$route.params.id, this.query);
        this.message = data.data;
        this.prevMessageId = data.nav.back;
        this.nextMessageId = data.nav.next;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },
    handleClickNavMessage(id) {
      return this.$router.push({ path: `/message/auto-message/${id}`, query: this.query });
    },
  },
};
</script>

<style lang="scss" scoped>
.auto-message-detail {
  @include flex(column);

  &__buttons {
    align-self: flex-end;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 8px;

    @include mq(568px) {
      grid-template-columns: repeat(3, 94px);
    }
  }

  &__header {
    border: solid #d8d8d8;
    border-width: 1px 0;
    padding: 20px;
    margin: 20px 0;

    &__title {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      margin-bottom: 20px;

      p {
        text-align: right;
      }

      @include mq(768px) {
        @include flex(row, center, space-between);
        margin-bottom: 20px;

        p {
          min-width: 300px;
        }
      }
    }

    &__summary {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;

      @include mq(768px) {
        grid-template-columns: 88px 1fr;
      }

      label {
        margin: 0;
      }

      &__filter-tags {
        @include flex(row, center);
        flex-wrap: wrap;

        span {
          margin: 0 8px 8px 0;

          @include mq(768px) {
            margin: 0 8px 0 0;
          }
        }
      }

      &__members {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 12px;
        align-items: start;
      }
    }
  }

  &__body {
    padding: 16px;
    white-space: pre-line;
  }
}
</style>
