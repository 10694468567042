<template>
  <li class="bookable-members-list-item">
    <img class="bookable-members-list-item__profile" :src="memberImageUrl" :alt="`image of ${memberName}`" />

    <div class="bookable-members-list-item__info-container">
      <div class="bookable-members-list-item__info-wrapper">
        <div class="bookable-members-list-item__name">
          <a v-if="!isMemberDeleted && canViewMembers" @click="$router.push(`/users/detail?id=${member.id}`)">
            {{ memberNameAndMobile }}
          </a>
          <span v-else>{{ memberNameAndMobile }}</span>
        </div>

        <span class="bookable-members-list-item__ticket-info">{{ userTicketInfo }}</span>
      </div>

      <div class="bookable-members-list-item__vaccine" v-if="member.vaccination_yn === 'Y'">
        <img src="@/assets/images/icons/vaccine.svg" alt="백신 접종 완료 후 14일 경과" />
      </div>
    </div>
    <div class="bookable-members-list-item__button">
      <PlainButton
        v-loading="bookingButtonDisabled"
        :disabled="bookingButtonDisabled"
        @click="$emit('click-booking', userTicket)"
        round
        >예약</PlainButton
      >
    </div>
  </li>
</template>

<script>
export default {
  props: {
    userTicket: { type: Object, default: null },
    member: { type: Object, default: null },
    bookingButtonDisabled: { type: Boolean, default: false },
  },

  computed: {
    isMemberDeleted() {
      return !!_.get(this.member, 'deleted_at');
    },

    memberImageUrl() {
      return this.$utils.getImagePath(_.get(this.member, 'image'), 40);
    },

    memberName() {
      return _.get(this.member, 'name');
    },

    memberNameAndMobile() {
      let text = this.memberName;
      if (!this.isMemberDeleted && this.member.mobile) {
        const mobile = this.canViewMembersMobile
          ? this.$filters.mobile(this.member.mobile)
          : this.$filters.mobileMask(this.member.mobile);
        if (mobile) text += ` · ${mobile}`;
      }
      return text;
    },

    userTicketInfo() {
      const { date, comma } = this.$filters;
      let ticketTitle = _.get(this.userTicket, 'ticket.title');
      const { exceeded, exceededAt } = this.$utils.checkBytesOfString(ticketTitle, 40);
      ticketTitle = !exceeded ? ticketTitle : `${ticketTitle.slice(0, exceededAt)}...`;

      const start = date(this.userTicket.availability_start_at);
      const end = date(this.userTicket.expire_at);
      const ticketType = _.get(this.userTicket.ticket, 'type');
      const usableCoupon = comma(this.userTicket.usable_coupon);
      return ticketType === 'P'
        ? `${ticketTitle} · ${start}~${end}`
        : `${ticketTitle} · ${usableCoupon}회 남음 · ${start}~${end}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.bookable-members-list-item {
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr auto;
  grid-gap: 2px 12px;
  grid-template-areas:
    'profile name button'
    'profile ticket-info button';

  border-bottom: 1px solid #ebebeb;
  padding: 12px 6px;
  transition: 0.15s background;
  overflow: hidden;

  &:hover {
    background: rgba($color-primary, 0.1);

    .bookable-members-list-item__button button {
      width: 56px;
      transform: translateX(0);
    }
  }

  @include mq(568px) {
    grid-gap: 3px 24px;
    padding: 12px;
  }

  &__profile {
    grid-area: profile;
  }

  &__name {
    grid-area: name;
  }
  &__ticket-info {
    grid-area: ticket-info;
  }
  &__button {
    grid-area: button;

    button {
      transform: translateX(60px);
      transition: transform 0.15s;
    }
  }

  &__profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &__info-container {
    display: flex;
    align-items: center;
  }

  &__vaccine {
    width: 22px;
    height: 22px;
    margin-left: 10px;
  }

  &__name {
    font-size: 12px;

    @include mq(568px) {
      font-size: 13px;
    }
    @include mq(1280px) {
      font-size: 14px;
    }

    &__app-linked {
      color: $deep-sky-blue;
    }
  }

  &__ticket-info {
    font-size: 10px;
    opacity: 0.7;

    @include mq(568px) {
      font-size: 11px;
    }
  }
}
</style>
