<template>
  <div class="locker" v-loading="lockerLoading">
    <div class="is-no-locker" v-if="isLockerLength">
      등록된 락커가 없습니다.
    </div>
    <ul>
      <li
        v-for="(item, index) in lockers"
        :key="index"
        :class="[pickBackgroundColor(item.end_on, item.start_on)]"
        @click="popUpUpdateModal(item)"
      >
        <div class="locker-layout pause" v-if="item.status === 'IMPOSSIBLE'">
          <div class="locker-layout__locker-title">
            <div class="diagonal"></div>
            <p>{{ item.no }}.</p>
          </div>
        </div>

        <div v-if="item.status === 'USE' && !item.start_on" class="locker-layout normal">
          <div class="locker-layout__locker-title">
            <p>{{ item.no }}.</p>
            <img src="@/assets/images/cross-35px.svg" alt="cross" />
          </div>
        </div>

        <div v-if="item.status === 'USE' && item.start_on" class="assign">
          <p>{{ item.no }}. {{ item.name }}</p>
          <div class="name-wrapper">
            <p>{{ item.member.name }}</p>
            <vaccine-image v-if="item.member.vaccination_yn === 'Y'" />
          </div>
          <p class="expired-text">{{ duringText(item.end_on, item.start_on) }}</p>
        </div>
      </li>
    </ul>

    <LockerCountChange
      :show="showCountChangeModal"
      :lockerChangeType="lockerChangeType"
      :lockers="lockers"
      @close="handleChangeLockerCount"
    />

    <UpdateLocker :show="showUpdateModal" @close="handleUpdateLocker" />

    <FloatingActionButton v-if="!keyword" @click.native.stop="addLocker" />

    <button v-if="!keyword" class="locker-delete" @click.stop="deleteLocker">
      <i class="el-icon-delete"></i>
    </button>
  </div>
</template>

<script>
import LockerCountChange from '@/components/Modals/locker/LockerCountChange';
import UpdateLocker from '@/components/Modals/locker/UpdateLocker';
import { LOCKER_STATE_TYPE } from '@constants';
import VaccineImage from '@/components/shared/VaccineImage.vue';

export default {
  components: {
    LockerCountChange,
    UpdateLocker,
    VaccineImage,
  },

  data() {
    return {
      lockerLoading: false,
      showCountChangeModal: false,
      showUpdateModal: false,
      lockerName: null,
      etc: null,
      changeStateLockerId: null,
      changeStateType: null,
      isLockerLength: false,
      lockerChangeType: null,
    };
  },
  async created() {
    if (!this.lockers) {
      this.lockerLoading = true;
      await this.$store.dispatch('locker/getLockers');
      this.lockerLoading = false;
    }
  },

  computed: {
    lockers() {
      return this.$store.getters['locker/locker'];
    },
    keyword() {
      return this.$store.getters['locker/keyword'];
    },
  },

  watch: {
    lockers: function() {
      this.lockers.length ? (this.isLockerLength = false) : (this.isLockerLength = true);
    },

    '$route.query': {
      handler: 'handleRouteQueryChange',
      immediate: true,
    },
  },

  methods: {
    async handleRouteQueryChange() {
      const { lockerId } = this.$route.query;
      await this.$store.dispatch('locker/getLockers');
      if (lockerId) {
        const lockerData = this.lockers.filter(({ id }) => id === Number(lockerId));
        this.popUpUpdateModal(lockerData[0]);
      }
    },

    pickBackgroundColor(expire, start) {
      if (this.moment(start) < this.moment()) {
        const text = this.$utils.getRemainingDaysText(expire, start);
        if (text.slice(-2) === '지남') {
          return 'minus';
        } else if (Number(text.slice(0, -4)) <= 7 || text === '오늘 만료') {
          if (text === '오늘 만료' && this.moment() > this.moment(expire)) return 'minus';
          return 'soon';
        } else {
          return 'assign';
        }
      } else {
        if (start) return 'toUse';
      }
    },

    async handleChangeLockerCount(close, data, type) {
      this.showCountChangeModal = close;

      if (type === LOCKER_STATE_TYPE.ADD && data && data.addCount > 0) {
        this.changeLockerStatus(null, LOCKER_STATE_TYPE.ADD, data.addCount);
        data.addCount = null;
      } else if (type === LOCKER_STATE_TYPE.DELETE && data && data.addCount > 0) {
        this.changeLockerStatus(null, LOCKER_STATE_TYPE.DELETE, data.addCount);
        data.addCount = null;
      }
    },

    async handleUpdateLocker(close) {
      this.showUpdateModal = close;
    },

    async popUpUpdateModal(val) {
      // 사용불가 -> 사용 바꿀 시
      if (val.status === 'IMPOSSIBLE') {
        return this.useLockerModal(val, 'USE');
      }

      // 각 락커 클릭 or 수정시
      if (val.end_on) {
        const data = {
          currentStep: 3,
          lockerName: val.name,
          lockerId: val.id,
          start_on: val.start_on,
          end_on: val.end_on,
          memberId: val.member.id,
          userName: val.member.name,
          type: val.type,
          status: val.status,
          etc: val.etc,
          edit: 1,
          no: val.no,
          vaccination: val.member.vaccination_yn,
        };
        await this.$store.dispatch('locker/assignLocker', data);
        return (this.showUpdateModal = true);
      }

      //할당시
      const data = { lockerName: val.name, lockerId: val.id, no: val.no };
      await this.$store.dispatch('locker/assignLocker', data);
      this.showUpdateModal = true;
    },

    useLockerModal(formData) {
      const message = `락커를 <b>사용</b> 하시겠습니까?`;

      this.$confirm(message, '락커 사용', {
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          if (formData) {
            this.lockerLoading = true;
            await this.changeLockerStatus(formData.id, 'USE');
            this.lockerLoading = false;
          }
        })
        .catch(() => false);
    },

    async changeLockerStatus(id, type, count) {
      this.lockerLoading = true;
      if (type === LOCKER_STATE_TYPE.ADD && count) {
        const res = await this.$store.dispatch('locker/increseLockerCount', count);

        if (res.status === 200) {
          this.$utils.notify.success(this, '락커 생성 성공', `락커 ${count}개 생성하였습니다.`);
          this.lockerLoading = false;
        } else {
          this.$utils.notify.parseError(this, { message: '락커 생성 실패하였습니다.' });
          this.lockerLoading = false;
        }
      } else if (type === LOCKER_STATE_TYPE.DELETE && count) {
        const res = await this.$api.locker.delete(count);
        if (res.status === 204) {
          this.$utils.notify.success(this, '락커 삭제 성공', `락커를 삭제하였습니다.`);
          await this.$store.dispatch('locker/getLockers');
          this.lockerLoading = false;
        } else {
          this.$utils.notify.parseError(this, { message: '락커 삭제 실패하였습니다.' });
          this.lockerLoading = false;
        }
      } else if (type === LOCKER_STATE_TYPE.IMPOSSIBLE || type === LOCKER_STATE_TYPE.USE) {
        const data = { lockerId: id, status: type };
        const res = await this.$api.locker.update(data);
        if (res.status === 200) {
          this.$utils.notify.success(this, '락커 수정 성공', `락커 정보를 수정하였습니다.`);
          await this.$store.dispatch('locker/getLockers');
          this.lockerLoading = false;
        } else {
          this.$utils.notify.parseError(this, { message: '락커 수정을 실패하였습니다.' });
          this.lockerLoading = false;
        }
      }
      this.lockerLoading = false;
    },

    duringText(end, start) {
      const res = this.$utils.getRemainingDaysText(end, start);
      if ((res === '오늘 만료' && this.moment() > this.moment(end)) || res.slice(-2) === '지남') {
        return '만료됨';
      }
      return res;
    },

    deleteLocker() {
      this.lockerChangeType = LOCKER_STATE_TYPE.DELETE;
      this.showCountChangeModal = true;
    },

    addLocker() {
      this.lockerChangeType = LOCKER_STATE_TYPE.ADD;
      this.showCountChangeModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.locker {
  height: 70vh;
  border-radius: 4px;
  overflow-y: auto;
  width: 100%;
  margin: 0 auto;

  .is-no-locker {
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: NotoSansKR;
    font-size: 24px;
    color: #8a8a8a;
  }

  ul {
    margin-top: 16px;
    li {
      display: inline-block;
      margin: 0 0 16px 16px;
      width: 160px;
      height: 112px;
      border-radius: 4px;
      vertical-align: text-bottom;
      &.minus {
        border: solid 2px $coral;
        .expired-text {
          color: red;
        }
      }
      &.soon {
        border: solid 2px #ffcb1f;
      }
      &.assign {
        width: 160px;
        border: solid 1px #979797;
      }
      &.toUse {
        border: solid 2px green;
      }
    }
    li:hover {
      transition: 0.2s;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }

  .assign {
    p {
      margin: 7px;
      @include ellipsis;
      font-size: 14px;
    }
    p:nth-child(2) {
      margin: 10px 0 0 0;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }
    p:nth-child(3) {
      margin-top: 16px;
      text-align: center;
      font-size: 14px;
    }
  }
  &__add-locker {
    text-align: center;
    div {
      img {
        margin: 15px 0 8px 0;
        width: 32px;
        height: 32px;
      }
    }
  }
}
.delete {
  border: solid 1px $color-danger;
  color: $color-danger;
}
.delete:hover {
  background-color: #eee;
  background-color: rgba($color-danger, 0.1);
}
.pause {
  border: 1px solid $coral;
  border-radius: 4px;
  background-color: rgba($coral, 0.4);
}
.locker-layout {
  height: 100%;
  display: flex;
  &__locker-title {
    height: 100%;
    width: 73%;
    p {
      @include ellipsis;
      margin: 7px;
      font-size: 14px;
    }
    img {
      position: relative;
      top: 8px;
      left: 65px;
    }
  }
  &__button-group {
    display: flex;
    flex-direction: column;
    width: 27%;
    z-index: 2;
    button {
      border-radius: 13px;
      margin: 6px 6px 0 0;
      height: 18px;
      width: 38px;
      line-height: 9px;
      font-size: 10px;
    }
  }
}

.name-wrapper {
  padding: 0 5px;

  .vaccine {
    margin-left: 0;
  }
}

/deep/ .el-input__inner {
  margin: 5px 0 20px 0;
}
/deep/ .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
}
/deep/ .el-dialog {
  margin-top: 40vh;
  width: 430px;
}
/deep/ .el-date-editor--daterange.el-input__inner {
  width: 390px;
}
.custom-color-dialog {
  width: 20px;
}
.diagonal {
  z-index: 2;
  border-bottom: 1px solid $coral;
  width: 165%;
  position: relative;
  top: 72px;
  right: 20px;
  transform: translateY(-18px) translateX(5px) rotate(-35deg);
}
.normal {
  border: solid 1px #64aeff;
  border-radius: 4px;
}
.locker-delete {
  position: fixed;
  width: 56px;
  height: 56px;
  bottom: 50px;
  right: 120px;
  border: 1px solid rgba(grey, 0.4);
  border-radius: 50%;
  /deep/ .el-icon-delete {
    font-size: 23px;
  }
}
.locker-delete:hover {
  background-color: rgba($coral, 0.2);
  border: solid 1px rgba($coral, 0.2);
  /deep/ .el-icon-delete {
    color: $coral;
  }
}
</style>
