<template>
  <section class="message-form" v-loading="loadingMessage">
    <div class="message-list-warning">
      <img src="@/assets/images/icons/icon_warning.svg" alt="warning" />
      <div class="text-wrapper">
        <p class="red">
          1. 문자 발송 시 통신사 사정에 의해 최대 72시간까지 발송결과에 대한 응답 대기 시간이 소요될 수 있습니다.
        </p>
        <p class="red">
          2. 응답 대기 중인 메시지는 '처리중..'으로 표시되며 통신사 응답이 완료되면 '전송 성공'으로 변경되고 '전송 실패'에 한해
          차감된 포인트는 환불됩니다.
        </p>
        <p>
          3. 제목은 40byte까지만 입력되어야 하며 한글, 영어, 숫자, 띄어쓰기 및 (), [], &lt; &gt;만 입력할 것을 권장합니다. 이 외
          특수 기호 사용 시 전송이 실패될 수 있습니다.
        </p>
        <p>
          4. 다음과 같은 경우, 기타 에러로 표시되어 전송에 실패할 수 있습니다.
        </p>
        <p class="gray">■ 음영 지역, 잘못된 번호, 통신사 수신 거부, 일반 수신 거부, 네크워트 에러</p>
        <p class="gray">■ 수신 번호를 찾을 수 없음, 단말기 일시정지, LMS 미지원 단말기 등 기타 사유</p>
      </div>
    </div>

    <div class="message-form__header">
      <div class="message-form__header__infoPoint">
        <p>SMS 건당 <b>12 포인트</b> / 90 바이트&ensp;LMS 건당 <b>37 포인트</b> / 2000 바이트</p>
      </div>
      <div class="message-form__header__myPoint">
        잔여 {{ point | comma }} 포인트
        <BaseButton round @click="$router.push('/settings/payments')"><span>포인트 충전하기</span></BaseButton>
      </div>
    </div>
    <div class="message-form__body">
      <div class="message-form__body__send-message">
        <div class="message-form__body__send-message__post-number">
          <div>
            <span>발송번호</span>
          </div>
          <div>
            <p :class="{ 'no-post-number': !hasPostNumber }">{{ formatContact(contractNumber) }}</p>
          </div>
          <PlainButton slot="left" size="large" @click="$router.push('/settings/required')"> 발송번호 설정 </PlainButton>
        </div>
        <div class="message-form__body__send-message__title">
          <TextInput
            @input="handleInputFormTitle"
            :value="studio_message.title"
            ref="title"
            label="제목"
            labelAlign="row"
            labelWidth="60px"
            size="large"
            placeholder="40바이트 제한 및 특수문자 (), <>, [] 사용가능"
            :maxlength="59"
            :error="$v.studio_message.title.$dirty && !$v.studio_message.title.required"
            required
          />
        </div>
        <Textarea
          @input="handleInputFormText"
          :value="studio_message.template"
          size="large"
          placeholder="메시지를 입력해주세요."
          :error="$v.studio_message.template.$dirty && !$v.studio_message.template.required"
          required
          :height="400"
        />
        <div class="message-form__helpers">
          <div class="wrap-content">
            <p>
              <span v-if="studio_message.send_on" class="message-form__helpers__send-on">
                {{ moment(studio_message.send_on).format('YYYY. M. D. (ddd) HH:mm') }}
              </span>
              <el-tag size="mini">
                {{ !studio_message.send_on ? '즉시 발송' : '보내기 예약' }}
              </el-tag>
            </p>
            <p class="message-form__helpers__byte">
              <span :class="[checkByte > 2000 ? 'overwrite' : '']">{{ checkByte }} </span> /
              {{ checkByte > 90 ? '2000 바이트' : '90 바이트' }}
            </p>
          </div>
        </div>
      </div>
      <div class="message-form__body__select-option-box">
        <div class="message-form__body__ad">
          <span class="message-form__body__ad__checkbox">
            <el-checkbox v-model="isAdAdded" />
            &nbsp;메시지에 (광고) 문구 추가</span
          >
          <button class="message-form__body__ad__button" @click="showAdWarningModal = true">
            <i class="ico-warning" />
            <span>광고 문자 표기 의무사항 확인</span>
          </button>
        </div>
        <div class="message-form__body__select-member__header">
          <span>받는 사람</span>
          <div>
            <p>총 {{ membersSelectedArray.length }} 명</p>
          </div>
          <PlainButton v-if="!hideMemberListButton" slot="left" size="large" @click="showSelectModal('members')">
            회원목록
          </PlainButton>
          <PlainButton v-if="!hideMemberListButton" slot="left" size="large" @click="showSelectModal('counsels')">
            상담고객목록
          </PlainButton>
        </div>
        <div :class="['message-form__body__select-member__body', error ? 'error' : '']">
          <span class="placeholder" v-if="membersSelectedArray.length === 0"> 목록에서 회원을 선택하세요. </span>
          <ul>
            <li v-for="(item, index) in membersSelectedArray" :key="index">
              <div class="name">{{ item.name }}</div>
              <div>
                {{
                  canViewMembersMobile
                    ? formatContact(item.mobile || item.phone)
                    : maskedMobile(formatContact(item.mobile || item.phone))
                }}
              </div>
              <img src="@/assets/images/circle-x.svg" @click="deleteSelectMember(index)" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <BottomActionBar contentMaxWidth="944px" hideButtonBack>
      <el-popover slot="left" placement="top-start" trigger="manual" v-model="showSendOnPopover">
        <div class="message-form__send-on">
          <div class="message-form__send-on__header">
            <h4>보내기 예약</h4>
            <CircleButton @click="showSendOnPopover = false">
              <i class="el-icon-close"></i>
            </CircleButton>
          </div>
          <div class="message-form__send-on__body">
            <p>즉시발송하는 경우에는 비워두고, 발송예약하려면 날짜와 시간을 지정해주세요.</p>
            <label>예약시간</label>
            <div>
              <el-date-picker
                v-model="sendOnDate"
                type="date"
                format="yyyy. M. d."
                value-format="yyyy-MM-dd"
                :picker-options="sendOnDatePickerOptions"
                required
              />
              <el-time-select
                v-model="sendOnTime"
                value-format="HH:mm"
                :disabled="!sendOnDate"
                :picker-options="sendOnTimePickerOptions"
              />
            </div>
            <span>
              {{ sendOnError ? '예약발송은 최소 10분후 부터 가능합니다.' : '' }}
            </span>
          </div>
          <div class="message-form__send-on__footer">
            <BaseButton type="info" size="large" @click="showSendOnPopover = false"> 취소 </BaseButton>
            <BaseButton size="large" @click="setSendOn"> 확인 </BaseButton>
          </div>
        </div>
        <PlainButton slot="reference" type="white" size="large" @click="toggleSendOnPopover" v-if="hasPostNumber">
          보내기 예약
        </PlainButton>
      </el-popover>
      <div class="footer-button-group">
        <BaseButton
          type="white"
          size="large"
          :disabled="isSent"
          @click="
            {
              if (checkOverWrite) {
                return (showOverWriteModal = true);
              }
              if (titleCheckOverWrite) {
                return (showTitleOverwriteModal = true);
              }
              saveTemplate();
            }
          "
        >
          문자보관함 저장
        </BaseButton>
        <BaseButton type="white" size="large" @click="openPreviewModal"> 미리보기 </BaseButton>
        <BaseButton
          v-loading="isSendingMessage"
          v-if="hasPostNumber"
          type="white"
          size="large"
          :disabled="isSendingMessage"
          @click="checkSendCondition"
          >{{ submitButtonLabel }}</BaseButton
        >
      </div>
    </BottomActionBar>

    <MemberSelectDialog
      v-if="showMemberSelectDialog"
      :show="showMemberSelectDialog"
      :membersSelected="membersSelectedArray"
      :selectMemberType="selectMemberType"
      @close="handleMemberSelectDialogClose"
    />

    <PointShortage :show="showShortageModal" :point="point" :usePoint="pointGap" @close="handlePointStorageClose" />

    <MessageConfirm
      :show="showMessageConfirmModal"
      :count="membersSelectedArray.length"
      :usePoint="usePoint"
      :hasPoint="point"
      :smstype="checkByte > 90"
      :isReserve="studio_message.send_on"
      :isId="$route.params.id"
      @close="handleConfirmMocalClose"
      :disabled="isSendingMessage"
    />

    <el-dialog class="point-storage-dialog" :visible="showOverWriteModal" @close="overWriteClose">
      <h3 slot="title">2000바이트 초과</h3>
      2000바이트를 초과하였습니다. 2000바이트 초과시 문자가 발송 되지 않습니다.
      <div slot="footer">
        <el-button @click="overWriteClose"> 닫기 </el-button>
      </div>
    </el-dialog>

    <el-dialog class="point-storage-dialog" :visible="showTitleOverWriteModal" @close="overWriteClose">
      <h3 slot="title">40바이트 초과</h3>
      40바이트를 초과하였습니다. 40바이트 초과시 문자가 발송 되지 않습니다.
      <div slot="footer">
        <el-button @click="overWriteClose"> 닫기 </el-button>
      </div>
    </el-dialog>

    <PreviewMessage
      :show="showPreviewModal"
      :byteCount="checkByte"
      :total="membersSelectedArray.length"
      :message="studio_message.template"
      @close="showPreviewModal = false"
    />

    <AdWarning :show="showAdWarningModal" @close="showAdWarningModal = false" />
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import MemberSelectDialog from '@/components/Modals/MemberSelect';
import PointShortage from '@/components/Modals/PointShortage';
import MessageConfirm from '@/components/Modals/MessageConfirm';
import PreviewMessage from '@/components/Modals/PreviewMessage';
import AdWarning from '@components/Modals/AdWarning';

const DEFALUT_MESSAGE = {
  title: '',
  template: '',
  targets: [],
  member_filter: {},
  send_on: '',
  id: 0,
};

export default {
  components: { MemberSelectDialog, PointShortage, MessageConfirm, PreviewMessage, AdWarning },
  /** Form validation */
  mixins: [validationMixin],
  validations: {
    studio_message: {
      title: { required },
      template: { required },
    },
  },

  props: {
    hideMemberListButton: { type: Boolean, default: false },
    members: { type: Array, default: () => [] },
    defaultMessage: { type: String, default: '' },
    saveTemplate: Function,
    handleClickAddAdText: Function,
    containsEmoji: Function,
  },

  data() {
    const { moment } = this;

    return {
      /** 메시지 데이터 */
      loadingMessage: false,
      /** Helpers */
      membersSelected: '',
      membersSelectedArray: [],
      tempMemberSelect: [],
      originalCost: 0,
      memberTypeState: 'members',
      selectMemberType: 'members',
      showSendOnPopover: false,
      error: null,
      sendOn: '',
      sendOnDatePickerOptions: {
        disabledDate(date) {
          return moment(date).format('YYYYMMDD') < moment().format('YYYYMMDD');
        },
      },
      showMemberSelectDialog: false,
      showShortageModal: false,
      showMessageConfirmModal: false,
      isConfirmSendMessage: false,
      isSendingMessage: false,
      showTitleOverWriteModal: false,
      showOverWriteModal: false,
      showPreviewModal: false,
      showAdWarningModal: false,
    };
  },

  computed: {
    pointGap() {
      return this.usePoint - this.originalCost;
    },

    phoneNumber() {
      return this.$store.getters['studio/studio'];
    },

    usePoint() {
      return this.membersSelectedArray.length * (this.checkByte > 90 ? 37 : 12);
    },

    titleCharacterCheck() {
      const regex = /^[a-zA-Z0-9\p{L}\s.,()<>[\]]*$/u;
      return regex.test(this.studio_message.title);
    },

    titleCheckByte() {
      return this.$utils.smsByteCheckWithPreventWriting(this.studio_message.title);
    },

    checkByte() {
      return this.$utils.smsByteCheckWithPreventWriting(this.studio_message.template);
    },

    titleCheckOverWrite() {
      return this.titleCheckByte > 40;
    },

    checkOverWrite() {
      return this.checkByte > 2000;
    },

    point() {
      return this.$store.getters['payment/point'];
    },

    contractNumber() {
      const addNumberContext = '발신자 번호를 설정해주세요';
      const findContractNumber = this.phoneNumber.contact_infos.findIndex(el => el.type_id === 5);
      if (findContractNumber === -1) return addNumberContext;
      return this.phoneNumber.contact_infos[findContractNumber].contact;
    },

    sendOnDate: {
      get() {
        return this.sendOn ? this.moment(this.sendOn).format('YYYY-MM-DD') : null;
      },
      set(value) {
        const time =
          value === this.moment().format('YYYY-MM-DD')
            ? this.moment()
                .add(15, 'minutes')
                .format('HH:mm')
            : this.sendOnTime || '00:00:00';
        this.sendOn = !value ? null : `${value} ${time}`;
      },
    },

    sendOnTime: {
      get() {
        return this.sendOn ? this.moment(this.sendOn).format('HH:mm') : null;
      },
      set(value) {
        value = value || '00:00';
        this.sendOn = `${this.sendOnDate} ${value}:00`;
      },
    },

    sendOnTimePickerOptions() {
      const { moment, sendOn } = this;
      if (moment(sendOn).format('YYYYMMDD') !== moment().format('YYYYMMDD')) {
        return { start: '00:00', end: '23:55', step: '00:05', minTime: null };
      }

      return {
        start: '00:00',
        end: '23:55',
        step: '00:05',
        minTime: moment()
          .add(10, 'minutes')
          .format('HH:mm'),
      };
    },

    sendOnError() {
      return (
        this.sendOn &&
        this.sendOn <
          this.moment()
            .add(10, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss')
      );
    },

    submitButtonLabel() {
      return !this.studio_message.id ? '보내기' : '수정 완료';
    },

    submitButtonDisabled() {
      const { title, template } = this.$v.studio_message;
      return !title.required || !template.required || !this.membersSelectedArray.length;
    },

    hasPostNumber() {
      return this.contractNumber !== '발신자 번호를 설정해주세요';
    },

    formText() {
      return this.$store.getters['template/formText'].template;
    },

    studio_message: {
      deep: true,
      get() {
        return this.$store.getters['template/formText'];
      },
    },

    isSent() {
      return this.$store.getters['template/isSent'];
    },

    isAdAdded: {
      get() {
        return _.get(this.$store.getters['template/formText'], 'isAdAdded');
      },
      set(newValue) {
        this.$store.dispatch('template/setFormText', { isAdAdded: newValue });
        this.handleClickAddAdText();
      },
    },
  },

  watch: {
    $route: {
      handler: 'handleRouteChange',
      immediate: true,
    },
    '$props.members': {
      handler: 'handleMembersChange',
      immediate: true,
    },
    checkOverWrite() {
      if (this.checkOverWrite) {
        this.showOverWriteModal = true;
      }
    },

    titleCheckOverWrite() {
      if (this.titleCheckOverWrite) {
        this.showTitleOverWriteModal = true;
      }
    },
  },

  created() {
    this.$store.dispatch('payment/getPointScore');
  },

  methods: {
    maskedMobile(value) {
      return this.$filters.mobileMask(value);
    },

    async handleRouteChange() {
      const messageId = this._.get(this.$route, 'params.id');

      //예약 메시지 수정
      if (!messageId) {
        this.$store.dispatch('template/setFormText', { ...DEFALUT_MESSAGE });
      } else {
        this.loadingMessage = true;
        try {
          const res = await this.$api.sms.get(messageId, null);
          const studioMessage = this._.omit(
            { ...res.data.data, template: res.data.data.message },
            'created_at',
            'updated_at',
            'deleted_at',
            'message',
          );
          this.$store.dispatch('template/setFormText', studioMessage);
          this.originalCost = studioMessage.targets.length * (studioMessage.sms_type === 'SMS' ? 12 : 37);
          this.membersSelectedArray = this.studio_message.targets.map(el => {
            let id = '';
            if (el.counseling_log_id) {
              id = el.counseling_log_id;
              /** 수정시 상담인원이 있으면 상담 모드로 바꾼다 */
              this.memberTypeState = 'counsels';
              this.selectMemberType = 'counsels';
            } else {
              id = el.studio_user_id;
            }
            return {
              id,
              name: el.name,
              mobile: el.mobile,
            };
          });
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.loadingMessage = false;
        }
      }
    },

    handleMembersChange() {
      const { targets, memberNames } = this.members.reduce(
        ({ targets, memberNames }, member) => {
          targets.push({ studio_user_id: member.id });
          memberNames.push(member.name);
          return { targets, memberNames };
        },
        { targets: [], memberNames: [] },
      );

      this.$store.dispatch('template/setFormText', { ...this.studio_message, targets });
      this.membersSelected = memberNames.join(', ');
      this.$nextTick(() => {
        this.$refs.title.$el.querySelector('input').focus();
      });
    },

    toggleSendOnPopover() {
      if (this.titleCheckOverWrite) return (this.showTitleOverWriteModal = true);
      if (this.checkOverWrite) return (this.showOverWriteModal = true);
      if (this.containsEmoji()) {
        this.$utils.notify.error(this, '오류', '문자 메시지에는 이모지를 포함할 수 없습니다.');
        return;
      }
      if (!this.showSendOnPopover) this.sendOn = this.studio_message.send_on;
      this.showSendOnPopover = !this.showSendOnPopover;
    },

    setSendOn() {
      if (!this.sendOnError) {
        this.$store.dispatch('template/setFormText', { send_on: this.sendOn });
        this.showSendOnPopover = false;
      }
    },

    handleMemberSelectDialogClose(result, membersSelected, memberFilterValues) {
      // 현재 멤버 배열이 있고, 캔슬을 눌렀을때
      this.showMemberSelectDialog = false;
      this.$nextTick(() => {
        this.$refs.title.$el.querySelector('input').focus();
      });

      /** 목록 선택 후 취소시 이전 회원 목록 복구 */
      if (!membersSelected) {
        // 취소한 경우 state 되돌린다
        if (this.tempMemberSelect.length) {
          return (this.membersSelectedArray = this.tempMemberSelect);
        }
        return;
      }

      if (membersSelected.length === 0) {
        this.$store.dispatch('template/setFormText', { targets: [] });
        this.membersSelectedArray = [];
        return;
      }

      if (result && !!membersSelected) {
        this.memberTypeState = this.selectMemberType;
        this.membersSelectedArray = membersSelected;
        this.tempMemberSelect = [];

        this.$store.dispatch('template/setFormText', {
          targets: this.membersSelectedArray.map(({ id }) => ({
            studio_user_id: id,
          })),
          member_filter: { ...memberFilterValues },
        });
        this.error = null;
      } else if (result) {
        this.$store.dispatch('template/setFormText', {
          targets: [],
          member_filter: {},
        });
        // this.membersSelected = '';
        this.membersSelectedArray = [];
      }
    },

    async showSelectModal(type) {
      /** 일반 회원 목록 선택후 상담 고객목록 선택 || 상담 고객 선택 후 일반 회원 선택시 기존 선택 멤버 초기화 경고 */
      let checkSelectMemberInit = true;
      if (this.membersSelectedArray.length && type !== this.memberTypeState) {
        const message = `다른 종류의 회원 목록을 선택 할 경우<br> 기존 선택된 회원 목록이 초기화 됩니다.<br>계속 하시겠습니까?`;
        checkSelectMemberInit = await this.$confirm(message, '문자 전송 회원 목록 초기화', {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);
      }

      if (!checkSelectMemberInit) return;

      /** 일반 회원 목록 선택후 상담 고객목록 선택시 기존 멤버선택 배열 초기화 */
      if (type !== this.memberTypeState) {
        this.tempMemberSelect = this.membersSelectedArray;
        this.membersSelectedArray = [];
      }

      type === 'counsels' ? (this.selectMemberType = 'counsels') : (this.selectMemberType = 'members');
      this.showMemberSelectDialog = true;
    },

    handlePointStorageClose(close, charge) {
      this.showShortageModal = close;
      if (charge) {
        return this.$router.push('/settings/payments');
      }
    },

    checkSendCondition() {
      if (!this.titleCharacterCheck) {
        this.$utils.notify.error(this, '오류', '제목의 특수문자는 (), <>, []만 사용 가능합니다.');
        return;
      }

      if (this.titleCheckOverWrite) return (this.showTitleOverWriteModal = true);
      if (this.checkOverWrite) return (this.showOverWriteModal = true);
      if (this.containsEmoji()) {
        this.$utils.notify.error(this, '오류', '문자 메시지에는 이모지를 포함할 수 없습니다.');
        return;
      }
      //포인트 부족시 모달
      if (this.point < this.pointGap) {
        return (this.showShortageModal = true);
      }

      //필수항목 미입력시 border 붉게
      if (this.submitButtonDisabled) {
        if (!this.membersSelectedArray.length) {
          this.error = 'error';
        }
        ['title', 'template'].forEach(key => {
          if (!this.$v.studio_message[key].required) {
            this.$v.studio_message[key].$touch();
          }
        });
        return;
      } else {
        this.showMessageConfirmModal = true;
      }
    },

    handleConfirmMocalClose(close, accept) {
      this.showMessageConfirmModal = close;
      if (!accept) return;
      this.handleClickSend(close);
    },

    async handleClickSend() {
      /** 상담 회원인 경우 값을 counseling_log에 id를 배열로 보냄, 일반 회원은 users에 id를 배열로 보냄 */
      let users = [];
      let counselingLogs = [];
      if (this.selectMemberType === 'members') {
        users = this.membersSelectedArray.map(el => el.id);
      } else {
        this.membersSelectedArray.map(({ id, member_id }) => {
          member_id ? users.push(member_id) : counselingLogs.push(id);
        });
      }

      this.isSendingMessage = true;

      try {
        this.$store.dispatch('sms/setLoading', true);
        const { studio_message } = this;
        let sendMessage = {
          users,
          message: studio_message.template,
          title: studio_message.title,
          send_on: studio_message.send_on === '' ? null : studio_message.send_on,
          filter: studio_message.member_filter,
          is_message: false,
          from: this.formatContact(this.contractNumber),
        };

        counselingLogs.length ? (sendMessage = { ...sendMessage, counseling_logs: counselingLogs }) : null;

        if (!studio_message.id) {
          let message = `${this.membersSelectedArray.length}건의 메시지가 발송되었습니다.`;
          const res = await this.$api.sms.create(sendMessage);
          if (res.data.is_sent !== 1) {
            message = `메시지 발송이 예약되었습니다.<br>${this.moment(studio_message.send_on).format(
              'llll',
            )}에 메시지가 발송될 예정입니다.`;
          }
          this.$store.dispatch('payment/getPointScore');

          this.$utils.notify.success(this, null, message);
          this.resetForm();
        } else {
          // sms 예약 수정
          let users = [];
          let counselingLogs = [];
          if (this.selectMemberType === 'members') {
            users = this.membersSelectedArray.map(el => el.id);
          } else {
            this.membersSelectedArray.map(({ id, member_id }) => {
              member_id ? users.push(member_id) : counselingLogs.push(id);
            });
          }
          users.length ? (sendMessage = { ...sendMessage, users }) : null;
          counselingLogs.length ? (sendMessage = { ...sendMessage, counseling_logs: counselingLogs }) : null;

          await this.$api.sms.update(studio_message.id, sendMessage);
          this.$utils.notify.success(this, null, '예약 메시지가 수정되었습니다.');
          this.$router.push(`/sms/${studio_message.id}`);
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        setTimeout(() => (this.isSendingMessage = false), 300);
        this.$store.dispatch('sms/setLoading', false);
        this.$emit('messageSent');
      }
    },

    resetForm() {
      this.$store.dispatch('template/setFormText', { ...DEFALUT_MESSAGE });
      this.membersSelectedArray = [];
      this.$v.$reset();
    },
    formatContact(number) {
      if (!number) return;
      return number.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
    },
    overWriteClose() {
      this.showTitleOverWriteModal = false;
      this.showOverWriteModal = false;
    },
    deleteSelectMember(index) {
      this.membersSelectedArray.splice(index, 1);
    },
    handleInputFormTitle(value) {
      this.$store.dispatch('template/setFormText', { title: value });
    },

    handleInputFormText(value) {
      this.$store.dispatch('template/setFormText', { template: value });
      this.$store.dispatch('template/updateSent', false);
    },

    openPreviewModal() {
      if (this.titleCheckOverWrite) return (this.showTitleOverWriteModal = true);
      if (this.checkOverWrite) return (this.showOverWriteModal = true);
      if (this.containsEmoji()) {
        this.$utils.notify.error(this, '오류', '문자 메시지에는 이모지를 포함할 수 없습니다.');
        return;
      }
      this.showPreviewModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-form {
  &__header {
    display: flex;
    position: relative;
    justify-content: space-between;
    line-height: 40px;
    padding: 0px 0px 14.5px 0px;

    &__infoPoint {
      padding-left: 37px;
      display: flex;
      font-size: 14px;
      text-align: left;
      color: #a3a3a3;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        top: calc(50% - 20px);
        left: -4px;
        background: url('../../assets/images/icons/message_gray.svg') 0 0 no-repeat;
        background-size: cover;
      }
    }
    &__myPoint {
      font-size: 18px;
      color: $deep-sky-blue;
      font-weight: bold;
      button {
        width: 240px;
        height: 40px;
        color: $deep-sky-blue;
        text-align: center;
        margin-left: 25px !important;
        padding: 10px 59px;
        object-fit: contain;
        border-radius: 20px !important;
        background-color: #64aef9;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;

        span {
          position: relative;
          padding: 0px 0px 0px 20px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            top: calc(50% - 9px);
            left: -10px;
            background: url('../../assets/images/icons/circle_plus.svg') 0 0 no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }

  &__body {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 15px 0px 0px 0px;
    border-top: 1px solid #eee;
    &__send-message {
      width: 49%;
      margin-right: 24px;
      &__post-number {
        display: flex;
        div:nth-child(1) {
          min-width: 60px;
          height: 40px;
          span {
            line-height: 40px;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #000000;
          }
        }
        button {
          height: 40px;
        }
        div:nth-child(2) {
          border: 1px solid #606266;
          width: 62%;
          border-radius: 4px;
          margin-right: 15px;
          height: 40px;
          p {
            padding: 10px 16px 10px 16px;
          }
          .no-post-number {
            color: #4799f2;
          }
        }
      }
      &__title {
        padding: 15px 0px 20px 0px;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #000000;
      }
      &__textarea {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        height: 400px;
        width: 100%;
        padding: 10px;
      }
      &__textarea:focus {
        border: 1px solid $deep-sky-blue;
      }
    }
    &__select-option-box {
      width: 49%;
    }
    &__select-member {
      &__header {
        display: flex;
        line-height: 40px;
        padding: 15px 0px 20px 0px;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #000000;

        button {
          width: 115px;
          margin: 0px 0px 0px 10px;
          height: 40px;

          &:first-of-type {
            margin: 0px 0px 0px 25px;
          }
        }
        div {
          margin-left: auto;
          font-size: 15px;
          color: $deep-sky-blue;
          font-weight: bold;
        }
      }
      .error {
        border: 1px solid $color-danger;
        span {
          color: $color-danger;
        }
      }
      &__body {
        border: 1px solid #606266;
        height: 458px;
        border-radius: 4px;
        overflow-y: auto;
        .placeholder {
          color: #dcdfe6;
          position: relative;
          top: 10px;
          margin-left: 20px;
        }
        ul {
          height: 40px;
          margin-top: 10px;
          li {
            padding: 8px 16px;
            display: grid;
            grid-template-columns: 150px 250px auto;
            grid-gap: 8px;
            .name {
              @include ellipsis;
            }
          }
          img:hover {
            cursor: pointer;
          }
          li:hover {
            background: rgba($color-primary, 0.15);
          }
        }
      }
      &__body:hover {
        border: 1px solid $deep-sky-blue;
      }
    }
    &__ad {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__checkbox {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #000000;
      }

      &__button {
        position: relative;
        width: 240px;
        height: 40px;
        line-height: 40px;
        object-fit: contain;
        border-radius: 4px;
        vertical-align: middle;
        border: solid 1px $coral;
        background-color: $white;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        color: #ff5c5c;
        padding: 0px;

        i {
          display: inline-block;
          width: 24px;
          height: 24px;
          vertical-align: -6px;
          background: url('../../assets/images/icons/warning.svg') 0 0 no-repeat;
          background-size: cover;
        }
        span {
          padding: 0px 0px 0px 8px;
        }
      }
    }
  }

  &__helpers {
    .wrap-content {
      display: flex;
      display: grid;
      grid-template-columns: 1fr 1fr;
      p {
        display: inline-block;
        &.message-form__helpers__byte {
          text-align: right;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          .overwrite {
            color: red;
            font-weight: bold;
          }
        }
      }
    }

    &__send-on {
      font-size: 14px;
      font-weight: 500;
      margin-right: 16px;
    }
    &__lms {
      font-size: 14px;
      color: $deep-sky-blue;
      font-weight: 500;
    }
  }

  &__input {
    @include flex(row);

    &.has-button {
      @include flex(column, flex-end, center);

      @include mq(568px) {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: stretch;
        grid-gap: 16px;
      }
    }
  }

  &__use-type-point {
    border: 1px solid $deep-sky-blue;
    background-color: rgba(100, 174, 255, 0.1);
    font-size: 14px;
    font-weight: bold;
    color: $deep-sky-blue;
    text-align: center;
    height: 40px;
    border-radius: 4px;
    line-height: 40px;
  }

  &__send-on {
    min-width: 320px;

    &__header,
    &__body,
    &__footer {
      padding: 24px;
    }

    &__header {
      @include flex(row, center, space-between);

      h4 {
        font-size: 18px;
        font-weight: 500;
      }
    }

    &__body {
      display: grid;
      grid-template-columns: 80px 1fr;
      grid-template-rows: auto auto 16px;
      grid-gap: 6px;
      align-items: center;

      p {
        grid-column: span 2;
        margin-bottom: 12px;
      }

      /deep/ .el-date-editor {
        min-width: 140px;
      }

      span {
        grid-column: 2 / 2;
        color: $color-danger;
        font-size: 12px;
      }
    }

    &__footer {
      display: grid;
      grid-template-columns: repeat(2, 75px);
      grid-gap: 10px;
      justify-content: end;
      border-top: 1px solid #f0f0f0;
    }
  }

  &__leving-point {
    background-color: white;
    color: $deep-sky-blue;
    font-size: 15px;
    text-align: center;
    padding: 5px 10px;
  }

  &__modal-buttons {
    @include flex(row, center, space-between);
    margin-top: 30px;
  }
  .footer-button-group {
    display: flex;

    button {
      font-weight: bold;
      margin-right: 10px;
    }

    button:last-child {
      margin: 0px;
    }
  }
}

.message-list-warning {
  margin-bottom: 30px;
  position: relative;

  img {
    position: absolute;
    top: 17px;
    left: 11px;
    width: 36px;
    height: 36px;
    z-index: 1;
  }

  .text-wrapper {
    padding: 15px 25px 15px 82px;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    background-color: #f6f6f6;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &:before {
      content: '';
      width: 57px;
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px soild #e33c3c;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #e33c3c;
    }
  }

  p {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.8;
  }

  .red {
    color: #bb0000;
  }

  .gray {
    font-weight: normal;
    color: #777777;
    line-height: 1.5;
  }
}
</style>
