<template>
  <section
    class="product-detail-header"
    :class="[courseType, isSelling ? 'isSelling' : '']"
    :style="
      isSelling && haveCustomColor ? `background-image: linear-gradient(122deg, #${ticketColor[0]},#${ticketColor[1]})` : ''
    "
  >
    <div class="product-detail-header__inner">
      <Breadcrumb
        class="product-detail-header__breadcrumb"
        :parent="{ path: '/products', label: '수강권' }"
        :current="`${this.title} 상세보기`"
        background-color="transparent"
        text-color="#fff"
        link-color="#fff"
      />

      <div class="product-detail-header__card">
        <TicketCard :ticket="ticket" @favorite-icon-click="toggleFavorite" />
      </div>

      <h1 class="product-detail-header__title">
        {{ ticketTitle }}
      </h1>

      <p class="product-detail-header__tags">
        <CustomTag v-for="division in divisions" :key="division.id" type="white">{{ division.title }}</CustomTag>
      </p>

      <div class="product-detail-header__buttons">
        <BaseButton type="white" :disabled="!canUpdateProduct || loading" @click="handleClickEditProduct">수정</BaseButton>
        <BaseButton type="white" :disabled="!canDeleteProduct || loading" @click="toggleIsSelling">{{
          isSelling ? '판매 정지' : '판매 재개'
        }}</BaseButton>
        <BaseButton v-if="!isSelling" type="danger" :disabled="!canDeleteProduct || loading" @click="deleteProduct"
          >삭제</BaseButton
        >
        <BaseButton
          v-if="isSelling && ticketType !== 'S'"
          type="white"
          :disabled="!canUpdateProduct || !userTicketsTotal"
          @click="showExtendTicket = true"
          >{{ title }} 일괄 연장</BaseButton
        >
      </div>

      <div class="product-detail-header__prices">
        <h2>판매가 {{ actualPrice | comma }}원</h2>
      </div>
    </div>

    <ExtendTicket :show="showExtendTicket" :ticket="ticket" @close="handleCloseExtendTicket" :title="title" />
  </section>
</template>

<script>
import ExtendTicket from '@/components/Modals/ExtendTicket';

export default {
  components: { ExtendTicket },

  data() {
    return {
      showExtendTicket: false,
    };
  },

  computed: {
    ticket() {
      return this.$store.getters['ticket/ticket'];
    },
    userTicketsParams() {
      return this.$store.getters['ticket/userTicketsParams'];
    },
    userTicketsTotal() {
      return this.$store.getters['ticket/userTicketsTotal'];
    },

    courseType() {
      return _.get(this.ticket, 'available_class_type');
    },
    ticketType() {
      return _.get(this.ticket, 'type');
    },
    ticketTitle() {
      return _.get(this.ticket, 'title');
    },
    divisions() {
      return _.get(this.ticket, 'divisions', []);
    },
    isSelling() {
      return _.get(this.ticket, 'is_selling');
    },
    actualPrice() {
      return _.get(this.ticket, 'actual_price');
    },
    haveCustomColor() {
      return this.ticket && this.ticket.colors.length;
    },
    ticketColor() {
      return _.get(this.ticket, 'colors');
    },
    loading() {
      return this.$store.getters['ticket/userTicketsLoading'];
    },
    productOrTicket() {
      return this.courseType === 'I' ? 'product' : 'ticket';
    },
    title() {
      return this.courseType === 'I' ? '상품' : '수강권';
    },
  },

  methods: {
    /** 즐겨찾기 토글 */
    async toggleFavorite(ticketId) {
      const data = {
        favorite_stamp: !this.ticket.favorite_stamp ? this.moment().format('YYYY-MM-DD HH:mm:ss') : null,
      };
      await this.$api.product.update(ticketId, data);
      this.$store.dispatch('ticket/getTicket', ticketId);
    },

    /** 수강권 수정 */
    handleClickEditProduct() {
      this.$router.push(`/products/${this.productOrTicket}/edit?id=${this.ticket.id}`);
    },

    /** 수강권 판매 정지/재개 */
    async toggleIsSelling() {
      const title = `${this.title} ${this.isSelling ? '판매 정지' : '판매 재개'}`;
      const message = this.isSelling
        ? !this.userTicketsTotal
          ? `이 ${this.title}을 판매 정지 하시겠습니까?`
          : `현재 이 ${this.title}을 가진 회원이 ${this.$filters.comma(this.userTicketsTotal)}명 있습니다.<br>${
              this.title
            }이 판매 정지 되어도 회원은 보유한 ${this.title} 잔여횟수를 사용할 수 있습니다. ${
              this.title
            }을 판매 정지 하시겠습니까?`
        : `이 ${this.title}을 판매중으로 전환 하시겠습니까?`;

      const proceed = await this.$confirm(message, title, {
        confirmButtonText: this.isSelling ? '판매 정지' : '판매 재개',
        cancelButtonText: '취소',
        dangerouslyUseHTMLString: true,
      });

      if (proceed) {
        const data = {
          is_selling: !this.isSelling,
          favorite_stamp: null,
        };
        try {
          await this.$api.product.update(this.ticket.id, data);
          this.$utils.notify.success(this, '확인', `${this.title}이 ${this.isSelling ? '판매 정지' : '판매 재개'} 되었습니다.`);
          this.$store.dispatch('ticket/getTicket', this.ticket.id);
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    /** 수강권,상품 삭제 */
    async deleteProduct() {
      if (this.userTicketsTotal) {
        this.$utils.notify.error(
          this,
          '오류',
          `${this.$filters.comma(this.userTicketsTotal)}명의 회원이 사용중인 ${this.title}입니다. 삭제할 수 없습니다.`,
        );
        return;
      }

      const title = `${this.title} 삭제`;
      const message = `
          <p style="color: #f95454;">삭제된 ${this.title}은 복구할 수 없습니다.</p>
          <p>${this.title}을 삭제하시겠습니까?</p>
        `;

      const proceed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      });

      if (proceed) {
        try {
          await this.$api.product.delete(this.ticket.id);
          this.$utils.notify.success(this, '확인', `${this.title}이 삭제되었습니다.`);
          this.$router.push('/products');
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    handleCloseExtendTicket(refresh) {
      this.showExtendTicket = false;

      if (refresh) {
        this.$store.dispatch('ticket/getTicket', this.ticket.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-detail-header {
  background-color: #64aeff;
  background-image: linear-gradient(122deg, rgba(#000, 0.3), rgba(#000, 0.7)), linear-gradient(to bottom, #ebebeb, #ebebeb);

  &.isSelling.P {
    background-image: linear-gradient(to bottom, rgba($pink, 0), rgba($pink, 0.7)),
      linear-gradient(to bottom, $light-purple, $light-purple);
  }

  &.isSelling.G,
  &.isSelling.I {
    background-image: linear-gradient(122deg, rgba($light-purple, 0), rgba($light-purple, 0.7)),
      linear-gradient(to bottom, $deep-sky-blue, $deep-sky-blue);
  }

  &__inner {
    max-width: 1077px;
    margin: auto;
    padding-top: 36px;

    @include mq(568px) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 5px;
      grid-template-areas:
        'breadcrumb .'
        'title card'
        'tags card'
        'buttons card'
        'prices card';
    }
  }

  &__breadcrumb {
    grid-area: breadcrumb;
  }

  &__card {
    grid-area: card;
    align-self: start;
    @include flex(row, center, center);
    padding: 15px;
  }

  &__title {
    grid-area: title;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    padding: 5px 15px;
  }

  &__tags {
    grid-area: tags;
    padding: 0 15px 15px;
  }

  &__buttons {
    grid-area: buttons;
    @include flex(row, center, flex-start);
    flex-wrap: wrap;
    padding: 5px 15px;

    button {
      margin-right: 5px;
    }
  }

  &__prices {
    grid-area: prices;
    @include flex(row, center, flex-start);
    flex-wrap: wrap;
    padding: 15px;

    h2 {
      color: #fff;
      width: 100%;
      font-size: 20px;
      font-weight: 500;
      padding: 12px 0;
    }
  }
}
</style>
