<template>
  <el-input
    v-bind="$attrs"
    :type="type"
    :value="formatContact(value)"
    :maxlength="maxLength"
    @input="value => $emit('input', value.replace(/-/g, ''))"
    @keypress.native="validateKey"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: {
    value: String,
    type: { type: String, default: 'text' },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxLengthProps: { type: Number, default: 12 },
  },

  data() {
    return {
      maxLength: 999,
    };
  },

  methods: {
    formatContact(number) {
      if (!number) return;
      if (number.length === 8) {
        return number.replace(/(\d{4})(\d{4})/, '$1-$2');
      } else if (number.length === 11) {
        return number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      } else if (number.length === 9) {
        return number.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
      } else if (number.length === 12) {
        return number.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
      } else {
        if (number.indexOf('02') === 0) {
          return number.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        } else {
          this.maxLength = 999;
          return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
      }
    },

    validateKey(e) {
      const isNumber = !!e.key.replace(/\D/g, '');
      const isMaxLength = e.srcElement.value.replace(/-/g, '').length >= this.maxLengthProps;
      if (!isNumber || isMaxLength) e.preventDefault();
    },
  },
};
</script>
