import axios from '../axios';

const BASE_URL = '/v2/staff/sms';

export default {
  getUsage: params =>
    axios.get(BASE_URL, {
      params,
    }),

  get: (id, type, filter) => {
    let typeConverter = [];
    if (type) {
      switch (type) {
        case 'ALL':
          typeConverter = ['SMS', 'LMS'];
          break;
        case 'SMS':
          typeConverter = ['SMS'];
          break;
        case 'MESSAGE':
          typeConverter = ['MESSAGE'];
          break;
        default:
          typeConverter = ['LMS'];
      }
    }

    if (!filter) {
      return axios.get(`${BASE_URL}/${id}`, {
        params: {
          sms_type: typeConverter,
        },
      });
    }
    return axios.get(`${BASE_URL}/${id}`, {
      params: {
        sms_type: typeConverter,
        search: filter,
      },
    });
  },

  create: studio_message => {
    return axios.post(`${BASE_URL}`, studio_message);
  },

  update: (id, studio_message) => {
    return axios.patch(`${BASE_URL}/${id}`, studio_message);
  },

  delete: id => axios.delete(`${BASE_URL}/${id}`),

  // 자동 알림 발송 내역 (푸시 제외)
  automessage: {
    getAll: params => axios.get(`/v2/staff/message`, { params: { ...params, type: params.type ?? ['SMS', 'LMS'] } }),
    getOne: (id, params) => axios.get(`/v2/staff/message/${id}`, { params: { ...params, type: params.type ?? ['SMS', 'LMS'] } }),
  },
};
