<template>
  <div class="payment-info">
    <FormBlock :unNumbering="true" :index="0" label="이용 중인 요금제">
      <p class="info-txt">
        요금제 <span v-if="nowPayment.product_name">{{ nowPayment.product_name }}</span>
      </p>
      <PlainButton slot="checkbox" round @click="moveToServicePlan" class="btn-blue"> 요금제 변경 </PlainButton>
      <p class="info-txt fixed" v-if="nowPayment.product_type">
        결제 유형 <span>{{ nowPayment.product_type }}</span>
      </p>

      <!--   정기결제 진행 중   -->
      <p class="info-txt" v-if="nowPayment.payment_date">
        다음 결제 예정일
        <span>
          <b>{{ nowPayment.payment_date | date }}</b>
        </span>
      </p>
      <!--   정기결제 X   -->
      <p class="info-txt" v-if="nowPayment.expire_on">
        만료일
        <span>
          <b>{{ nowPayment.expire_on | date }}</b>
        </span>
      </p>
    </FormBlock>

    <FormBlock v-if="usableSubscriptionAutoCharge" :unNumbering="true" :index="0" label="정기 결제 관리">
      <ul class="payment-info__payment-summary">
        <li>
          <label>정기 결제 카드</label>
          <span v-if="subscriptionCard">{{ `${subscriptionCard.card_name}${getLastFourDigits(subscriptionCard)}` }}</span>
          <button class="btn-text-grey" @click="showPaymentListModal">
            {{ editButtonText }}
          </button>
        </li>
        <li class="flex-row">
          <span>
            <label>결제 결과를 받을 휴대전화</label>
          </span>
          <span>
            <PhoneNumberInput
              v-model="chargeNotificationNumber"
              placeholder="결제 결과를 받을 번호를 입력해 주세요"
              :class="phoneNumberInputClass"
            />
            <p class="warning" v-if="phoneNumberErrorMessage">{{ phoneNumberErrorMessage }}</p>
            <button @click="handleClickChangeChargeNotificationNumber" :class="phoneNumberButtonClass">저장하기</button>
          </span>
        </li>
      </ul>
    </FormBlock>

    <FormBlock v-if="showNextProduct" :unNumbering="true" :index="0" label="다음 결제 정보">
      <PlainButton class="btn-text-blue" slot="checkbox" @click="open"> 결제 취소 및 환불 안내 </PlainButton>
      <p class="info-txt monthly" v-if="nextPayment.product_name">
        요금제 <span>{{ nextPayment.product_name }}</span>
      </p>
      <p class="info-txt monthly" v-if="nextPayment.product_type">
        결제 유형 <span>{{ nextPayment.product_type }}</span>
      </p>
      <p class="info-txt monthly" v-if="nextPayment.payment_date">
        결제 예정일 <span>{{ nextPayment.payment_date | date }}</span>
      </p>
      <p class="info-txt monthly" v-if="nextPayment.expire_on">
        만료일 <span>{{ nextPayment.expire_on | date }}</span>
      </p>
    </FormBlock>

    <FormBlock :unNumbering="true" :index="0" label="결제 내역" v-loading="loading" class="monthly-table">
      <div class="wrap-table">
        <el-table :data="filteredPayments" fit>
          <el-table-column label="결제일" align="center" width="200" :formatter="formatDatetime" />
          <el-table-column label="카드 정보" align="center" width="" :formatter="formatDataCard" />
          <el-table-column label="결제 상태" align="center" width="130" :formatter="formStatus" />
          <el-table-column label="요금제" align="center" width="130" :formatter="formPlan" />
          <el-table-column label="결제 유형" align="center" width="100" :formatter="formPeriod" />
          <el-table-column label="결제 금액" align="center" width="200" :formatter="formPrice" />
        </el-table>
      </div>
      <el-pagination
        class="service-payment__pagination"
        @current-change="requestServicePaymentHistory"
        :current-page.sync="params.page"
        :page-size="params.limit"
        layout="prev, pager, next"
        :total="total"
        v-if="total"
      />
    </FormBlock>

    <RegistCard :show="registCardModalShow" :close="closeRegistCardModal" />
    <PaymentTerms v-if="showPaymentTerms" :open="showPaymentTerms" @close="showPaymentTerms = false" />
    <payment-list-modal
      :show="showPaymentList"
      :handleClickCancel="closePaymentListModal"
      :paymentList="paymentList"
      :showRegistCardModal="showRegistCardModal"
    />
  </div>
</template>

<script>
import RegistCard from '@components/Modals/Payments/RegistCard';
import PaymentTerms from '@components/Modals/ServicePaymentTerms';
import PaymentListModal from '@components/Modals/ServicePayments/VerticalPaymentList';

export default {
  components: {
    RegistCard,
    PaymentTerms,
    PaymentListModal,
  },
  props: {
    active_tab: String,
  },
  data() {
    return {
      showPaymentTerms: false,
      paymentsHistory: null,
      params: { page: 1, limit: 10 },
      total: 0,
      loading: false,
      chargeNotificationNumber: '',
      registCardModalShow: false,
      showPaymentList: false,
    };
  },
  computed: {
    filteredPayments() {
      return this.paymentsHistory;
    },

    studioData() {
      return this.$store.getters['studio/studio'];
    },

    servicePlanProducts() {
      return this.$store.getters['payment/servicePlanProducts'];
    },

    showNextProduct() {
      if (!this.paymentPolicy.subscription_id) return false;
      if (!this.nextPayment.product_name || !this.nowPayment.product_name) return false;
      return !!this.nextPayment.product_name;
    },

    productId() {
      return this.paymentPolicy.subscription_id;
    },

    usableSubscriptionAutoCharge() {
      return this.productId && this.paymentPolicy.usable_subscription_auto_charge;
    },

    editButtonText() {
      if (!this.paymentList.length) return '카드 추가';
      if (!this.paymentPolicy.subscription_card_id) return '카드 선택';
      return '카드 변경';
    },

    paymentList() {
      return this.$store.getters['payment/cards'];
    },

    paymentPolicy() {
      return this.$store.getters['payment/paymentPolicy'];
    },

    subscriptionCard() {
      return this.paymentList.filter(card => card.id === this.paymentPolicy.subscription_card_id)[0];
    },

    isSameWithDefault() {
      // 시설 전화번호 기본값과 동일한지 확인
      // 동일할 경우, 핸드폰 번호 양식에 맞지 않더라도 에러메시지 x=
      return this.studioData.contact_infos.some(({ contact }) => contact === this.chargeNotificationNumber);
    },

    phoneNumberInputClass() {
      const isSame = this.paymentPolicy.subscription_payment_notification_number == this.chargeNotificationNumber;
      if (!this.chargeNotificationNumber || isSame) return 'default';
      return this.phoneNumberErrorMessage ? 'warning' : 'valid';
    },

    phoneNumberErrorMessage() {
      if (!this.chargeNotificationNumber) return '';
      if (this.isSameWithDefault) return '';
      const phoneNumberValidation = /^01([0|1|6|7|9])\d{7,8}$/;
      if (!phoneNumberValidation.test(this.chargeNotificationNumber)) {
        return '핸드폰 번호만 입력이 가능합니다';
      }
      return '';
    },

    phoneNumberButtonClass() {
      // 시설 전화번호가 기본값으로 들어가므로, button disabled 하여 입력하지 않고 저장하는 것을 제한
      if (!this.chargeNotificationNumber) return 'btn-disabled';
      const isSame = this.paymentPolicy.subscription_payment_notification_number == this.chargeNotificationNumber;
      return `btn-${!this.phoneNumberErrorMessage && !isSame ? 'valid' : 'disabled'}`;
    },

    paymentInfo() {
      return this.$store.getters['payment/paymentInfo'];
    },

    nowPayment() {
      return this.paymentInfo.now_payment;
    },

    nextPayment() {
      return this.paymentInfo.next_payment;
    },

    isMonthlyPayment() {
      return this.nowPayment?.product_type === '월 정기 결제';
    },
  },

  created() {
    this.initializePaymentData();
  },

  watch: {
    show: {
      handler() {
        this.requestServicePaymentHistory();
      },
      immediate: true,
    },

    paymentPolicy() {
      this.chargeNotificationNumber = this.paymentPolicy.subscription_payment_notification_number;
    },
  },

  methods: {
    async initializePaymentData() {
      await this.$store.dispatch('payment/getPaymentPolicy');
      await this.$store.dispatch('payment/getPaymentList');
      await this.$store.dispatch('payment/getServicePlanProducts');
      await this.$store.dispatch('payment/getPaymentInfo');
    },

    formatDatetime(data) {
      return data.settlement_at;
    },

    formatDataCard(data) {
      if (data.card_number === '-') return '계좌 이체';
      return `${data.card_name} ${this.$filters.cardMask(data.card_number)}`;
    },

    formStatus(data) {
      switch (data.status) {
        case 'purchase':
          return '결제 완료';
        case 'pending':
          return '결제 실패';
        case 'fail':
          return '결제 실패';
        case 'refund':
          return '환불';
        default:
          return '';
      }
    },
    formPlan(data) {
      return data.grade.toUpperCase();
    },
    formPeriod(data) {
      if (data.quantity === 1) return '월정기결제';
      return '연결제';
    },
    formPrice(data) {
      return `${this.$filters.comma(data.price)}원`;
    },
    open() {
      this.showPaymentTerms = true;
    },
    moveToServicePlan() {
      this.$router.push('/settings/service-payments').catch(() => {});
    },
    async handleClickChangeChargeNotificationNumber() {
      if (!this.chargeNotificationNumber) {
        return this.$utils.notify.error(this, '오류', '결제 알림 휴대폰 번호를 입력해주세요.');
      }
      await this.dispatchUpdatePayment({ subscription_payment_notification_number: this.chargeNotificationNumber });
      await this.$utils.notify.success(this, '확인', '결제 알림 휴대폰 번호가 변경되었습니다.');
    },

    async dispatchUpdatePayment(data) {
      await this.$store.dispatch('payment/updatePaymentPolicy', data);
    },
    async requestServicePaymentHistory() {
      this.loading = true;
      try {
        const params = { ...this.params };
        this.loading = true;
        const res = await this.$api.payment.getServicePaymentHistory(params);
        if (res) {
          this.paymentsHistory = res.data.data;
          this.total = res.data.meta.total;
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    showRegistCardModal() {
      this.registCardModalShow = true;
    },

    closeRegistCardModal() {
      this.registCardModalShow = false;
    },

    showPaymentListModal() {
      this.showPaymentList = true;
    },

    closePaymentListModal() {
      this.showPaymentList = false;
    },

    getLastFourDigits({ card_number }) {
      if (!card_number) return '';
      return `(${card_number.slice(-4)})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-info {
  .info-txt {
    font-size: 16px;
    color: #808080;
    padding: 0px 16px 0px 0px;

    &.wrap-section {
      display: grid;
      grid-template-columns: 1fr 210px;
      grid-gap: 24px;
      p:first-child {
        @include flex(row, flex-start, flex-start);
      }
      p:last-child {
        @include flex(row, flex-start, flex-end);
        .wrap-change-card {
          display: block;
          .wrap-btn {
            display: flex;
            justify-content: flex-end;
          }
          button {
            display: block;
          }
          .warn-txt-card {
            display: block;
            text-align: right;
            color: $coral;
            font-weight: bold;
            font-size: 12px;
          }
        }
      }
    }

    span {
      position: absolute;
      left: 200px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: $charcoal-grey;
      padding: 0px 0px 0px 16px;
    }

    b {
      color: #343a40;
    }
  }
  .form-block.monthly-table {
    .wrap-table {
      padding: 0 8px 10px;
    }
    /deep/ .form-block__content {
      border-bottom: none;
    }
  }

  .flex {
    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-col {
      display: flex;
      flex-direction: column;
    }
  }

  &__payment-summary {
    > li {
      position: relative;
      width: 100%;
      min-height: 78px;
      padding: 15px 25px 15px 63px;

      label {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #808080;
      }

      label + span {
        padding: 0px 0px 0px 16px;
      }

      &:nth-child(1) {
        padding: 15px 59px 15px 59px;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 5px;
          left: 0px;

          width: 48px;
          height: 48px;
          background: url('../../../assets/images/icons/credit-card.svg') 0 0 no-repeat;
          background-size: cover;
        }
      }
      &:nth-child(2) {
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 15px;
          left: 0px;
          width: 48px;
          height: 48px;
          background: url('../../../assets/images/icons/cell-phone.svg') 0 0 no-repeat;
          background-size: cover;
        }

        .el-input {
          width: 253px !important;

          &::placeholder {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #bababa;
          }
          + .warning {
            font-size: 12px;
            color: $coral;
            position: absolute;
            top: 42px;
          }
        }

        span {
          padding: 0px 115px 0px 0px;
          margin: auto 0;
          &:nth-child(2) {
            display: flex;
            line-height: 49px;
          }
        }

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }
      }

      /deep/.default .el-input__inner:hover,
      /deep/.default .el-input__inner:focus {
        border-bottom: 2px solid #eeeeee;
      }

      /deep/.valid .el-input__inner,
      /deep/.valid .el-input__inner:hover {
        border-bottom: 2px solid $dodger-blue !important;
      }

      /deep/ .warning .el-input__inner,
      /deep/.warning .el-input__inner:focus {
        border: none;
        border-bottom: 2px solid #f45354 !important;
      }
    }
  }
  .form-block {
    position: relative;

    .fixed + .monthly {
      margin-top: 16px;
    }

    .btn {
      &-text {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 16px;
        text-align: left;
        color: #1da0ff;
      }

      &-text-grey {
        position: absolute;
        right: 0px;
        width: 114px;
        height: 40px;
        border: solid 1px #8a8a8a;
        border-radius: 2px;
        font-size: 14px;
        text-align: center;
        color: #8a8a8a;
      }

      &-text-blue {
        background: none;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #1da0ff;
        border: none;
      }

      &-blue {
        position: absolute;
        right: 0px;
        bottom: 34.5px;
        width: 114px;
        height: 40px;
        background-color: #4699f1;
        color: $white;
        border-radius: 2px;
      }

      &-grey {
        position: absolute;
        right: 0px;
        width: 114px;
        height: 36px;
        border-radius: 2px;
        background: #b5b5b5;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        margin-left: 57px;
      }

      &-disabled {
        position: absolute;
        right: 0px;
        width: 114px;
        height: 36px;
        border-radius: 2px;
        background: #b5b5b5;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        margin-left: 57px;
        pointer-events: none;
      }
      &-valid {
        position: absolute;
        right: 0px;
        width: 114px;
        height: 36px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1da0ff;
        margin-left: 57px;
        border: solid 1px $dodger-blue;
        background: $white;
      }

      font-size: 14px;
      border-radius: 2px;
      font-weight: 500;
    }
  }
}

.service-payment__pagination {
  margin: 0 auto;
}
</style>
