export default {
  paymentType: ['#c0e796', '#d1d1d1', '#a2ceff', '#fb9898', '#F9B45E', '#d1b6f6', '#fdde77'],
  classType: ['#b286f0', '#64aeff'],
  lectureType: ['#b286f0', '#64aeff'],
  ticketType: ['#998EC3', '#F1A340', '#a7255f'],
  paymentMethod: ['#998EC3', '#F1A340', '#a7255f'],
  ticket: [
    '#f95454',
    '#ff99b9',
    '#ff7e83',
    '#ff8a54',
    '#ffcb1f',
    '#96d750',
    '#27dead',
    '#4bd4f8',
    '#64aeff',
    '#5464ad',
    '#b286f0',
  ],
  item: ['#f95454', '#ff99b9', '#ff7e83', '#ff8a54', '#ffcb1f', '#96d750', '#27dead', '#4bd4f8', '#64aeff', '#5464ad', '#b286f0'],
  ticketId: [
    '#f95454',
    '#ff99b9',
    '#ff7e83',
    '#ff8a54',
    '#ffcb1f',
    '#96d750',
    '#27dead',
    '#4bd4f8',
    '#64aeff',
    '#5464ad',
    '#b286f0',
  ],
  staffId: [
    '#f95454',
    '#ff99b9',
    '#ff7e83',
    '#ff8a54',
    '#ffcb1f',
    '#96d750',
    '#27dead',
    '#4bd4f8',
    '#64aeff',
    '#5464ad',
    '#b286f0',
  ],
};
