<template>
  <div>
    <div v-show="checkDevice" class="area-full-btn">
      <el-button circle @click="handleFullScreen">
        <img src="@/assets/images/icons/fullscreen.svg" alt="icon-fullScreen" />
      </el-button>
    </div>
    <div ref="full" class="bg-intro">
      <Intro v-if="!hideList" :dataSet="dataSet" @showListComponent="changeStatusShowList" :fullscreen="fullscreen" />
      <SelectList v-if="hideList" :dataSet="dataSet" @showIntro="changeStatusShowList" :fullscreen="fullscreen" />
    </div>
  </div>
</template>

<script>
import Intro from '@/components/Checkin/Intro';
import SelectList from '@/components/Checkin/SelectList';
import fullscreen from 'vue-fullscreen';
import Vue from 'vue';

Vue.use(fullscreen);

export default {
  components: {
    Intro,
    SelectList,
  },

  data() {
    return {
      hideList: false,
      dataSet: Object,
      fullscreen: false,
    };
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },

    checkDevice() {
      const { userAgent } = navigator;

      if (userAgent.includes('CriOS')) return false;
      return true;
    },
  },

  watch: {
    $route: {
      handler() {
        this.checkProduct();

        let lastTouchEnd = 0;
        document.documentElement.addEventListener(
          'touchend',
          function(event) {
            let now = new Date().getTime();
            if (now - lastTouchEnd <= 300 && !event.srcElement.localName.includes('button')) {
              event.preventDefault();
            }
            lastTouchEnd = now;
          },
          false,
        );
      },
      immediate: true,
    },
  },

  methods: {
    async checkProduct() {
      await this.$store.dispatch('studio/getStudio');
      if (this.studio.grade !== 2 && !this.studio.usable_enter) {
        //(!this.studio.policy.is_enter) 일때로 해야하는지?
        this.$router.push(`/schedule`);
      }
    },

    changeStatusShowList(dataSet) {
      this.hideList = !this.hideList;
      this.dataSet = dataSet;
    },

    handleFullScreen() {
      this.$fullscreen.toggle(this.$refs['full'], {
        wrap: false,
        callback: this.fullscreenChange,
      });
    },

    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
  },
};
</script>

<style lang="scss" scoped>
.area-full-btn {
  position: absolute;
  left: 4%;
  bottom: 4%;

  button {
    padding: 10px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);

    &:focus {
      border-style: none;
    }

    img {
      overflow: hidden;
    }
  }
}

.bg-intro {
  height: 100vh;
  width: 100vw;
  touch-action: none;
  background-color: #fff;
}
</style>
