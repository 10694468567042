<template>
  <ul>
    <li
      v-for="tab in tabs"
      :key="tab.value"
      :class="{
        active: handleCheckActiveTab(tab),
        disabled: tab.disabled,
      }"
      :style="active_tab === tab.value && active_tab_style"
      @click="changeTab(tab)"
    >
      {{ tab.label }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    active_tab: String,
    active_tab_color: String,
    tabs: Array,
    handleChangeTab: Function,
  },
  computed: {
    active_tab_style() {
      if (!this.active_tab_color) return null;
      return {
        borderBottomColor: this.active_tab_color,
        color: this.active_tab_color,
      };
    },
  },
  methods: {
    changeTab(tab) {
      if (tab.disabled) return;
      this.handleChangeTab(tab.value);
    },
    handleCheckActiveTab(tab) {
      if (this.active_tab === tab.value) return true;
      return this.active_tab.replace('auto-', '') === `${tab.value.slice(0, -4)}list`;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  @include flex(row, center, center);
  li {
    cursor: pointer;
    font-size: 28px;
    font-weight: 400;
    padding: 10px 16px 10px 0;
    opacity: 0.5;
    transition: 0.15s;
    &:hover {
      opacity: 0.7;
    }
    &.active {
      color: black;
      font-weight: 500;
      opacity: 1;
    }
    &.disabled {
      cursor: not-allowed;
      &:hover {
        border-bottom-color: $gray-200;
        opacity: 0.5;
      }
    }
  }
}
</style>
