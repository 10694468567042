import axios from '../axios';

const BASE_URL = '/v2/staff/report';

export default {
  getPayments: params =>
    axios.get(`${BASE_URL}/payments`, {
      params,
    }),
  getLectureSales: params =>
    axios.get(`${BASE_URL}/lectures`, {
      params,
    }),
  getDeductionSales: params =>
    axios.get(`${BASE_URL}/deducts`, {
      params,
    }),
  getunPaymentSales: () => axios.get(`${BASE_URL}/un-payments`),
  verifySalesPassword: data => axios.post(`${BASE_URL}/verify`, data),
  updateSalesPassword: data => axios.patch(`${BASE_URL}/change-password`, data),
  resetSalesPassword: () => axios.patch(`${BASE_URL}/reset-password`),
};
