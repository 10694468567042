<template>
  <span class="breadcrumb" :style="containerStyle">
    <router-link :to="parent.path" :style="linkStyle">
      {{ parent.label }}
    </router-link>
    <i class="el-icon-arrow-right"></i>
    <span>{{ current }}</span>
  </span>
</template>

<script>
export default {
  props: {
    parent: Object,
    current: String,
    backgroundColor: {
      type: String,
      default: '#f5f5f5',
    },
    textColor: {
      type: String,
      default: '#bebebe',
    },
    linkColor: {
      type: String,
      default: '#777',
    },
  },

  computed: {
    containerStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
      };
    },

    linkStyle() {
      return { color: this.linkColor };
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  opacity: 0.9;
  font-size: 11px;
  font-weight: 400;
  line-height: 2;
  text-align: left;
  color: #bebebe;
  margin: 0 0 12px 0;
  padding: 3px 6px;
  border-radius: 4px;

  a:hover {
    text-decoration: underline;
  }
}
</style>
