import axios from '../axios';

const BASE_URL = '/v2/staff/lectures';

export default {
  // 수업 목록
  getAll: params =>
    axios.get(BASE_URL, {
      params,
      is_min: 1,
    }),

  // 수업 목록 count
  getCount: params =>
    axios.get(`${BASE_URL}/count`, {
      params,
    }),

  // 삭제된 수업 목록
  getDeleted: params => {
    params = {
      ...params,
      is_trashed: 1,
      is_min: 1,
    };
    return axios.get('/staff/lecture', {
      params,
    });
  },

  // 중복 체크
  checkOverlap: data => axios.post(`${BASE_URL}/overlap-check`, data),

  // 개별 수업
  get: id => axios.get(`/staff/lecture/${id}`),

  // 수업 생성
  create: data => axios.post(BASE_URL, data),

  // 수업 수정
  update: lecture =>
    axios.patch(`/staff/lecture/${lecture.id}`, {
      lecture,
    }),

  // 수업 일괄 수정
  updateAll: data => axios.post(`${BASE_URL}/bulk`, data),

  // 수업 삭제
  delete: lectureIds => {
    const data = {
      lectures: lectureIds,
    };
    return axios.delete(BASE_URL, {
      data,
    });
  },
};
