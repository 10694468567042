<template>
  <div class="histories-list-filter">
    <!-- 날짜 조회 방식 -->
    <div class="histories-list-filter__query-type">
      <el-select @change="changeRangeFilterType" :value="rangeFilter.type">
        <el-option v-for="option in rangeTypeOptions" :key="option.value" :label="option.label" :value="option.value" />
      </el-select>
    </div>

    <!-- 1. 월별 조회 -->
    <div class="histories-list-filter__date" v-show="rangeFilter.type === 'month'">
      <el-button icon="el-icon-arrow-left" @click="() => monthChangeAction('prev')" />
      <el-date-picker
        type="month"
        format="yyyy년 MM월"
        value-format="yyyy-MM-dd"
        :value="rangeFilter.months[0]"
        :picker-options="rangePickerOptions"
        :clearable="false"
        @input="handleChangeMonth"
      />
      <el-button icon="el-icon-arrow-right" :disabled="!isThisMonthCheck" @click="() => monthChangeAction('next')" />

      <el-button class="this-month" v-if="isThisMonthCheck" @click="() => monthChangeAction('today')">
        이번달
      </el-button>
    </div>

    <!-- 2. 기간별 조회 (월별과 다르게 일자 선택 가능)-->
    <div class="histories-list-filter__date" v-show="rangeFilter.type === 'range'">
      <el-date-picker
        type="daterange"
        format="yyyy. MM. d."
        value-format="yyyy-MM-dd"
        :value="rangeFilter.months"
        :picker-options="rangePickerOptions"
        :clearable="false"
        @input="handleChangeRange"
      />
    </div>

    <!-- 강사, 구분 필터 -->
    <el-select
      :class="filter.value.length ? 'is-filtered style-filter' : 'style-filter'"
      v-for="filter in filters"
      :key="filter.name"
      :placeholder="filter.placeholder"
      :value="filter.value"
      :multiple="filter.multiple"
      :filterable="filter.multiple"
      :collapse-tags="filter.multiple"
      :clearable="filter.multiple"
      :disabled="filter.disabled"
      @change="value => filterChange(filter.name, value)"
    >
      <el-option v-for="option in filter.options" :key="option.value" :label="option.label" :value="option.value" />
    </el-select>

    <FilterResetButton @click="clickReset" />
  </div>
</template>

<script>
export default {
  props: {
    rangeFilter: Object,
    filters: Array,
  },

  data() {
    return {
      rangeTypeOptions: [
        { value: 'month', label: '월간' },
        { value: 'range', label: '기간' },
      ],
    };
  },

  computed: {
    rangePickerOptions() {
      return {
        firstDayOfWeek: 1,
        disabledDate(date) {
          return date > new Date();
        },
      };
    },

    isThisMonthCheck() {
      const startDate = this.rangeFilter.months[0];
      const thisMonth = this.moment().startOf('month');
      return startDate !== thisMonth.format('YYYY-MM-DD');
    },
  },

  methods: {
    monthChangeAction(type) {
      let currentDate;
      switch (type) {
        case 'prev':
          currentDate = this.moment(this.rangeFilter.months[0]).subtract(1, 'month');
          break;
        case 'next':
          currentDate = this.moment(this.rangeFilter.months[0]).add(1, 'month');
          break;
        case 'today':
          currentDate = this.moment();
      }

      this.handleChangeMonth(currentDate.format('YYYY-MM-DD'));
    },

    handleChangeMonth(month) {
      const date = this.moment(month);
      const startDate = date.startOf('month').format('YYYY-MM-DD');
      const endDate = date.endOf('month').format('YYYY-MM-DD');

      this.$store.commit('pointHistory/SET_RANGE_FILTER', {
        type: 'month',
        months: [startDate, endDate],
      });
    },

    handleChangeRange(months) {
      const searchTerm = this.$utils.getDaysDiff(months[1], months[0], 'month');
      if (searchTerm > 4) {
        return this.$utils.notify.parseError(this, { message: '최대 5개월까지 선택 가능합니다' });
      }

      this.$store.commit('pointHistory/SET_RANGE_FILTER', {
        type: 'range',
        months,
      });
    },

    changeRangeFilterType(type) {
      if (type === 'range') {
        const startDate = this.moment()
          .date(1)
          .format('YYYY-MM-DD');
        const today = this.moment().format('YYYY-MM-DD');

        this.$store.commit('pointHistory/SET_RANGE_FILTER', {
          type: 'range',
          months: [startDate, today],
        });
      } else {
        this.monthChangeAction('today');
      }
    },

    clickReset() {
      this.$store.commit('pointHistory/SET_RESET');
    },

    filterChange(name, values) {
      this.$store.commit('pointHistory/SET_FILTERS', { name, values });
    },
  },
};
</script>

<style lang="scss" scoped>
.histories-list-filter {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 30px;

  & > * {
    height: 36px;
    margin: 0;
  }

  /deep/ .el-button {
    height: 36px;
  }

  /deep/ .el-input__inner {
    @extend %input-default;
    border-color: #dcdfe6;
    height: 36px;

    &::placeholder {
      color: $charcoal-grey;
    }
  }

  /deep/ .el-select .el-input__inner {
    padding: 0 20px 0 10px;
  }

  /deep/ .el-date-editor {
    min-width: 135px;
    max-width: 250px;
  }

  &__query-type {
    width: 80px;
  }

  &__date {
    @include flex(row, center, center);

    /deep/ .el-date-editor {
      margin: 0 4px 0 4px;
    }

    /deep/ .el-input__icon {
      display: none;
    }

    /deep/ .el-input__inner {
      padding-left: 28px;
    }

    & > * {
      margin-right: 4px;

      &:last-child {
        margin: 0;
      }
    }

    /deep/ .el-button {
      margin: 0;
      padding: 0;
      height: 36px;
      width: 36px;
    }

    .this-month {
      margin-left: 8px;
      width: 60px;
    }
  }

  &__summary {
    @include flex(row, center, center);

    span {
      font-size: 15px;
      font-weight: bold;
      color: #000;
    }

    span + span {
      margin-left: 12px;
    }
  }
}

.el-select-dropdown__item > span {
  background-color: rgba(245, 108, 108, 0.1);
  border: 1px solid rgba(245, 108, 108, 0.2);
  color: $coral;
  width: 60px;
  height: 20px;
  padding: 0 3px;
  margin-left: 2px;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  border-radius: 5px;
}

/deep/ .el-tag {
  background: none !important;
  color: $charcoal-grey;
  font-size: 14px;
  padding: 0;
  max-width: 80px;
  @include ellipsis;

  &::after {
    content: ', ';
  }

  &:last-child::after {
    content: '';
  }

  i {
    display: none;
  }
}

.style-filter {
  /deep/ .el-button {
    padding: 0 10px;
    height: 36px;
    width: 100%;

    span {
      @include flex(row, center, space-between);
      i {
        color: #c0c4cc;
      }

      button {
        @include flex(row, center, center);
        border-radius: 50%;
        padding: 0;

        &:hover {
          background: $deep-sky-blue;
          i {
            color: #fff;
          }
        }
      }
    }
  }
}

/deep/ .is-filtered * {
  color: $deep-sky-blue;
}
</style>
