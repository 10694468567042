<template>
  <el-dialog
    class="result-dialog"
    :title="hasNoError ? '예약 성공' : '예약 결과'"
    :visible="show"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div v-if="successLectures.length" class="result-dialog__block">
      <p class="result-dialog__result">
        {{ totalTry | comma }}개의 수업중 {{ successLectures.length | comma }}개의 수업에 예약 되었습니다.
      </p>

      <li class="result-dialog__list-header">
        <span></span>
        <span>수업명</span>
        <span>수업일</span>
        <span>수업시간</span>
        <span>강사</span>
        <span>룸</span>
        <span></span>
      </li>

      <ul class="result-dialog__list">
        <li class="result-dialog__list-item" v-for="lecture in successLectures" :key="lecture.id">
          <i class="el-icon-success"></i>
          <span>{{ lecture.title }}</span>
          <span>{{ lecture.date }}</span>
          <span>{{ lecture.time }}</span>
          <span>
            {{ lecture.instructor ? `${lecture.instructor} 강사` : '' }}
          </span>
          <span>
            {{ lecture.room ? `${lecture.room} 룸` : '' }}
          </span>
          <span></span>
        </li>
      </ul>
    </div>

    <div v-if="failedLectures.length" class="result-dialog__block">
      <p class="result-dialog__result">{{ failedLectures.length | comma }}개의 수업과 일정이 중복됩니다.</p>

      <li class="result-dialog__list-header">
        <span></span>
        <span>수업명</span>
        <span>수업일</span>
        <span>수업시간</span>
        <span>강사</span>
        <span>룸</span>
        <span>중복항목</span>
      </li>

      <ul class="result-dialog__list">
        <li class="result-dialog__list-item" v-for="lecture in failedLectures" :key="lecture.id">
          <i class="el-icon-error"></i>
          <a :href="`/lecture/detail?id=${lecture.id}`" target="_blank" rel="noopenner noreferrer">{{
            lecture.title
          }}</a>
          <span>{{ lecture.date }}</span>
          <span>{{ lecture.time }}</span>
          <span :class="{ highlight: lecture.overlappingProp === 'instructor' }">
            {{ lecture.instructor ? `${lecture.instructor} 강사` : '' }}
          </span>
          <span :class="{ highlight: lecture.overlappingProp === 'room' }">
            {{ lecture.room ? `${lecture.room} 룸` : '' }}
          </span>
          <span>{{ getOverlappingProp(lecture) }}</span>
        </li>
      </ul>
    </div>

    <div v-if="failedCounsels.length" class="result-dialog__block">
      <p class="result-dialog__result">{{ failedCounsels.length | comma }}개의 상담과 일정이 중복됩니다.</p>

      <li class="result-dialog__list-header">
        <span></span>
        <span>이름</span>
        <span>날짜</span>
        <span>시간</span>
        <span>강사</span>
        <span></span>
        <span>중복항목</span>
      </li>

      <ul class="result-dialog__list">
        <li class="result-dialog__list-item" v-for="counsel in failedCounsels" :key="counsel.id">
          <i class="el-icon-error"></i>
          <span>{{ counsel.title }}</span>
          <span>{{ counsel.date }}</span>
          <span>{{ counsel.time }}</span>
          <span class="highlight">
            {{ counsel.instructor ? `${counsel.instructor} 강사` : '' }}
          </span>
          <span></span>
          <span>강사</span>
        </li>
      </ul>
    </div>

    <div slot="footer" class="result-dialog__buttons">
      <PlainButton v-if="!!successLectures.length" @click="handleClickRedirectToUserList" size="large"
        >회원 목록으로</PlainButton
      >
      <PlainButton v-if="!!successLectures.length" @click="handleClickContinueBooking" size="large"
        >계속 예약</PlainButton
      >
      <BaseButton v-if="!!successLectures.length" @click="handleClickRedirectToUserBookings" size="large"
        >예약 내역 보기</BaseButton
      >
      <BaseButton v-else @click="handleClickConfirm" size="large">확인</BaseButton>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    resultData: { type: Object },
    totalTry: { type: Number, default: 0 },
  },

  computed: {
    successData() {
      return _.get(this.resultData, 'success');
    },

    failData() {
      return _.get(this.resultData, 'errors');
    },

    hasNoError() {
      return !this.failedLectures.length && !this.failedCounsels.length;
    },

    successLectures() {
      if (!this.successData || !this.successData.length) return [];
      return this.mapSuccessData(this.successData);
    },

    failedLectures() {
      if (!this.failData) return [];

      let lectures = [];
      // 같이 예약하는 회원이 겹칠 때
      if (Array.isArray(this.failData)) {
        const data = this.failData.map(row => row['lecture-data']);
        lectures = this.mapFailData(data, 'member');

        // 강사/룸/상담일정이 겹칠 때
      } else {
        lectures = [
          ...this.mapFailData(this.failData.staff, 'instructor'),
          ...this.mapFailData(this.failData.room, 'room'),
        ];
      }

      return _.sortBy(lectures, ['raw.start_on']);
    },

    failedCounsels() {
      if (!this.failData) return [];

      return Array.isArray(this.failData) ? [] : this.mapFailData(this.failData.counsel, 'counsel');
    },
  },

  methods: {
    handleClickRedirectToUserList() {
      // params: redirectToUserList, redirectToUserBookings, resetForm
      this.$emit('close', true);
    },

    handleClickContinueBooking() {
      this.$emit('close', false, false, true);
    },

    handleClickRedirectToUserBookings() {
      this.$emit('close', false, true);
    },

    handleClickConfirm() {
      this.$emit('close', false, false, false);
    },

    getOverlappingProp({ overlappingProp }) {
      const PROP_LABEL = {
        instructor: '강사',
        room: '룸',
        member: '회원',
      };

      return PROP_LABEL[overlappingProp];
    },

    mapSuccessData(data) {
      return data.map(({ lecture }) => {
        const { start_on, end_on } = lecture;
        const title = _.get(lecture, 'title');
        const date = this.$filters.dateKoreanWithWeekday(start_on);
        const startOn = this.$filters.time(start_on);
        const endOn = this.$filters.time(end_on);
        const time = `${startOn}~${endOn}`;
        const instructor = _.get(lecture, 'staff.profile.name');
        const room = _.get(lecture, 'room.name');

        return {
          title,
          date,
          time,
          instructor,
          room,
          raw: { ...lecture },
        };
      });
    },

    mapFailData(data, dataKey) {
      return data.map(rawData => {
        const { start_on, end_on } = rawData;
        const title = dataKey === 'counsel' ? _.get(rawData, 'name') : _.get(rawData, 'title');
        const date = this.$filters.dateKoreanWithWeekday(start_on);
        const startOn = this.$filters.time(start_on);
        const endOn = this.$filters.time(end_on);
        const time = `${startOn}~${endOn}`;
        const instructor = _.get(rawData, 'staff.profile.name');
        const room = _.get(rawData, 'room.name');

        return {
          title,
          date,
          time,
          instructor,
          room,
          overlappingProp: dataKey,
          raw: { ...rawData },
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.result-dialog {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 720px;
  }

  /deep/ .el-dialog__body {
    padding: 10px 20px;
  }

  &__block {
    margin-bottom: 24px;
  }

  &__result {
    font-weight: bold;
    opacity: 0.75;
  }

  &__list {
    @include flex(column);
    margin-top: 8px;
    max-height: 25vh;
    overflow-y: auto;

    &-header,
    &-item {
      display: grid;
      grid-template-columns: 20px 2fr 3fr repeat(4, 2fr);
      place-items: center;
      padding-bottom: 8px;
    }

    &-header {
      border-bottom: 1px solid #ebebeb;
      margin-top: 8px;
      font-weight: bold;
    }

    &-item {
      i.el-icon-error {
        color: $color-danger;
      }

      i.el-icon-success {
        color: $dodger-blue;
      }

      a,
      span {
        @include ellipsis;
        text-align: center;
        width: 100%;
      }

      span.highlight {
        color: $color-danger;
        font-weight: bold;
      }
    }
  }

  &__buttons {
    @include flex(row, center, center);

    button + button {
      margin-left: 16px;
    }
  }
}
</style>
