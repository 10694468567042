export default {
  getInitializedQuery(vm, options) {
    const query = { ...vm.$route.query };
    Object.values(options).forEach(option => {
      query[option.key] ??= option.default;
    });
    return query;
  },

  setQuery(vm, changed, pageKey) {
    const newQuery = { ...vm.$route.query, ...changed };
    const page = pageKey || 'page';
    if (newQuery[page] && !Object.keys(changed).includes(page)) {
      // 페이지 조작이 아니라면 페이지 초기화
      newQuery[page] = 1;
    }
    Object.keys(newQuery).forEach(key => {
      // 빈 문자열인 query 삭제
      if (newQuery[key] === '') {
        newQuery[key] = undefined;
      }
    });
    vm.$router.push({ query: newQuery });
  },
};
