<template>
  <section class="settings-payments">
    <CustomTabs class="settings-payments__tabs" :active_tab="activeTab" :tabs="tabs" :handleChangeTab="handleChangeTab" />

    <div class="settings-payments__content">
      <PaymentInfo v-if="isPaymentInfoPage" :active_tab="activeTab" />
      <ServicePlan v-else :active_tab="activeTab" />
    </div>
  </section>
</template>

<script>
import PaymentInfo from './PaymentInfo';
import ServicePlan from './ServicePlan';

export default {
  components: { PaymentInfo, ServicePlan },
  data() {
    return {
      tabs: [
        { value: 'SettingsServicePayments_paymentInfo', label: '결제 정보' },
        { value: 'SettingsServicePayments', label: '요금제' },
      ],
    };
  },
  computed: {
    activeTab() {
      return this.$route.name || '';
    },

    isPaymentInfoPage() {
      return this.activeTab === `SettingsServicePayments_paymentInfo`;
    },
  },
  watch: {
    $route: {
      handler() {
        this.$emit('multipleTitle', [
          { title: '서비스 결제', path: '/settings/service-payments' },
          { title: '포인트 충전', path: '/settings/payments' },
        ]);
      },
      immediate: true,
    },
  },

  methods: {
    handleChangeTab(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-payments {
  &__tabs {
    justify-content: flex-start;
    padding: 0px 68px 0px 68px;
  }
  &__content {
    padding: 16px 8px;
  }
}
</style>
