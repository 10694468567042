<template>
  <div class="tooltip">
    <h4>수업 시간 시작 기준</h4>
    <p>회원 앱에 표시되는 예약 시간 단위입니다.</p>
    <div class="setting-info">
      <div class="content">
        <h5>정시로 설정</h5>
        <div class="card">
          <img src="@/assets/images/private-interval-60.png" alt="정시 설명" />
        </div>
      </div>
      <div class="content">
        <h5>30분 단위로 설정</h5>
        <div class="card">
          <img src="@/assets/images/private-interval-30.png" alt="30분 설명" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.tooltip {
  padding: 5px;
}
h4 {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}
p {
  font-size: 12px;
  color: #a3a3a3;
  margin-bottom: 15px;
}
.setting-info {
  @include flex(row, center, space-between);
  & > .content + .content {
    margin-left: 20px;
  }
  .content {
    flex: 1;
    h5 {
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 6px;
    }
  }
}
</style>
