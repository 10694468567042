<template>
  <div class="container">
    <div :class="{ summary: true, daterange: date.rangeType === 'daterange' }">
      <PrevSummary :title="prevDataTitle" :summaryItem="prevData" v-if="date.rangeType !== 'daterange'" v-loading="prevLoading" />
      <SummaryNew
        :summaryItems="dashBoardData"
        type="ticket"
        :value="filterOptions[0].value"
        :name="filterOptions[0].name"
        @handleSelectChange="handleSelectChange"
        v-loading="dashBoardLoading"
      />
    </div>
    <FilterNew
      :filterOptions="filterOptions"
      :refreshClick="refresh"
      downloadText="엑셀 다운로드"
      :handleDownload="downloadExcel"
    />
    <SubHeaderNew
      :count="total"
      :amount="totalAmount"
      :search="search"
      :handleSearchChange="handleSearchChange"
      :searchResult="searchResult"
      placeholder="회원명 검색"
      :invisibleCount="filterOptions[0].value === 'commission'"
    />
    <ListTableNew
      :tableData="tableData"
      type="ticket"
      :tableRowClick="tableRowClick"
      v-loading="tableLoading"
      :hidePaymentMethod="hidePaymentMethod"
      :sort="sort"
      :sortChange="handleSortChange"
    />
    <el-pagination
      @current-change="fetchTableData"
      :current-page.sync="page"
      :page-size="limit"
      :pager-count="5"
      layout="prev, pager, next, sizes"
      :total="total"
      v-show="tableData.length > 0"
      :page-sizes="[10, 15, 20, 50]"
      @size-change="size => (limit = size)"
    />
  </div>
</template>

<script>
import SummaryNew from '@components/Sales/SummaryNew';
import PrevSummary from '@components/Sales/PrevSummary';
import FilterNew from '@components/Sales/FilterNew';
import SubHeaderNew from '@components/Sales/SubHeaderNew';
import ListTableNew from '@components/Sales/ListTableNew';
import moment from 'moment';

export default {
  components: {
    SummaryNew,
    PrevSummary,
    FilterNew,
    SubHeaderNew,
    ListTableNew,
  },
  data() {
    return {
      dashBoardData: [
        { name: 'total', count: 0, amount: 0, label: '합계' },
        { name: 'new', count: 0, amount: 0, label: '신규결제' },
        { name: 're-take', count: 0, amount: 0, label: '재결제' },
        { name: 'experience', count: 0, amount: 0, label: '체험' },
        { name: 'refund', count: 0, amount: 0, label: '환불' },
        { name: 'upgrade', count: 0, amount: 0, label: '업그레이드' },
        { name: 'installment_payment', count: 0, amount: 0, label: '미수금 결제' },
        { name: 'commission', count: 0, amount: 0, label: '양도 / 환불 수수료' },
      ],
      filterOptions: [
        {
          name: 'paymentType',
          onChange: this.handleSelectChange,
          options: [
            { value: 'total', label: '결제 구분 전체' },
            { value: 'new', label: '신규결제' },
            { value: 're-take', label: '재결제' },
            { value: 'experience', label: '체험' },
            { value: 'refund', label: '환불' },
            { value: 'upgrade', label: '업그레이드' },
            { value: 'installment_payment', label: '미수금 결제' },
            { value: 'commission', label: '양도 / 환불 수수료' },
          ],
          value: 'total',
        },
        {
          name: 'classType',
          onChange: this.handleSelectChange,
          options: [
            { value: 'total', label: '수업 전체' },
            { value: 'P', label: '프라이빗' },
            { value: 'G', label: '그룹' },
          ],
          value: 'total',
        },
        {
          name: 'ticket',
          onChange: this.handleSelectChange,
          options: [],
          value: [],
          multiple: true,
          placeholder: '수강권 전체',
        },
        {
          name: 'paymentMethod',
          onChange: this.handleSelectChange,
          options: [
            { value: 'card', label: '카드' },
            { value: 'cash', label: '현금' },
            { value: 'wiretransfer', label: '계좌이체' },
            { value: 'point', label: '포인트' },
          ],
          value: [],
          placeholder: '결제 방법 전체',
          multiple: true,
          disabled: false,
        },
        {
          name: 'staff',
          onChange: this.handleSelectChange,
          options: [],
          value: [],
          multiple: true,
          placeholder: '강사 전체',
        },
      ],
      prevData: { totalAmount: 0, totalCount: 0, new: 0, 're-take': 0, refund: 0 },
      page: 1,
      limit: 10,
      total: 1,
      totalAmount: 1,
      tableData: [],
      search: '',
      prevLoading: false,
      dashBoardLoading: false,
      tableLoading: false,
      sort: 'ascending',
    };
  },
  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },
    prevDataTitle() {
      this.fetchPrevData();
      if (this.date?.rangeType === 'day') return '어제';
      else if (this.date?.rangeType === 'week') return '지난 주';
      else if (this.date?.rangeType === 'month') return '지난 달';
      else return '';
    },
    refreshAll() {
      return this.$store.getters['salesNew/refreshAll'];
    },
    hidePaymentMethod() {
      return this.filterOptions.find(item => item.name === 'paymentType').value === 'commission';
    },
  },

  watch: {
    date: function() {
      this.page = 1;
      this.fetchSalesTickets();
      this.fetchDashBoard();
      this.fetchTableData();
      this.fetchSalesStaff();
    },
    filterOptions: function() {
      this.fetchTableData();
    },
    refreshAll: function(value) {
      if (value) {
        this.fetchPrevData();
        this.fetchTableData();
        this.fetchDashBoard();
      }
    },
    limit: function() {
      this.fetchTableData();
    },
    sort: function() {
      this.fetchTableData();
    },
  },

  mounted() {
    this.fetchSalesTickets();
    this.fetchDashBoard();
    this.fetchTableData();
    this.fetchSalesStaff();
  },

  methods: {
    async fetchSalesTickets() {
      try {
        const res = await this.$api.salesNew.getTicketSalesPeriod({
          start_date: this.date.date,
          end_date: this.date.endDate,
        });
        const tickets = res.data;
        const ticketOptions = tickets.map(ticket => ({
          value: ticket.id,
          label: ticket.title,
        }));
        this.filterOptions.find(option => option.name === 'ticket').options = ticketOptions;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      }
    },

    async fetchDashBoard() {
      this.dashBoardLoading = true;
      try {
        const res = await this.$api.salesNew.getTicketSalesDashboard({
          start_date: this.date.date,
          end_date: this.date.endDate,
        });
        const data = res?.data;
        const newDashBoardData = [...this.dashBoardData];

        Object.keys(data).forEach(type => {
          const dashBoardItem = newDashBoardData.find(data => data.name === type);
          dashBoardItem.count = data[type].count;
          dashBoardItem.amount = type === 'refund' && data[type].amount > 0 ? -data[type].amount : data[type].amount;
        });

        this.dashBoardData = newDashBoardData;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.dashBoardLoading = false;
        if (!this.prevLoading && !this.tableLoading) {
          this.$store.dispatch('salesNew/setRefreshAll', false);
        }
      }
    },
    async fetchTableData() {
      this.tableLoading = true;
      try {
        const paymentType = this.filterOptions.find(filter => filter.name === 'paymentType').value;
        const classType = this.filterOptions.find(filter => filter.name === 'classType').value;
        const ticket = this.filterOptions.find(filter => filter.name === 'ticket').value;
        const paymentMethod = this.filterOptions.find(filter => filter.name === 'paymentMethod').value;
        const staff = this.filterOptions.find(filter => filter.name === 'staff').value;

        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
          limit: this.limit,
          page: this.page,
          payment_type: paymentType === 'total' ? null : paymentType,
          ticket_id: ticket,
          class_type: classType === 'total' ? null : classType,
          method_type: paymentMethod === 'total' ? null : paymentMethod,
          staff_id: staff,
          search: this.search,
          orderBy: this.sort === 'descending' ? 'desc' : 'asc',
        };

        const res = await this.$api.salesNew.getTicketSalesData(params);
        this.tableData = this.updateAmount(res.data.data);
        this.total = res.data.meta.total;
        this.totalAmount = res.data.meta.amount;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.tableLoading = false;
        if (!this.dashBoardLoading && !this.prevLoading) {
          this.$store.dispatch('salesNew/setRefreshAll', false);
        }
      }
    },

    async fetchSalesStaff() {
      try {
        const res = await this.$api.salesNew.getStaffSalesPeriod({
          start_date: this.date.date,
          end_date: this.date.endDate,
        });
        const staffs = res.data;
        const staffOptions = staffs.map(staff => ({
          value: staff.id,
          label: staff.name,
        }));
        this.filterOptions.find(option => option.name === 'staff').options = staffOptions;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      }
    },

    updateAmount(data) {
      const paymentMethod = this.filterOptions.find(filter => filter.name === 'paymentMethod').value;
      if (paymentMethod.length === 0) return data;
      return data.map(item => {
        let totalAmount = 0;
        if (paymentMethod.includes('card')) {
          totalAmount += item.amount.card_amount;
        }
        if (paymentMethod.includes('cash')) {
          totalAmount += item.amount.cash_amount;
        }
        if (paymentMethod.includes('wiretransfer')) {
          totalAmount += item.amount.wiretransfer_amount;
        }
        if (paymentMethod.includes('point')) {
          totalAmount += item.amount.point_amount;
        }
        return {
          ...item,
          amount: {
            ...item.amount,
            amount: totalAmount,
            unpaid_amount: 0,
          },
        };
      });
    },

    async fetchPrevData() {
      this.prevLoading = true;
      const startDate = moment(this.date.date)
        .subtract(1, this.date.rangeType)
        .format('YYYY-MM-DD');
      const endDate =
        this.date.rangeType === 'month'
          ? moment(this.date.date)
              .subtract(1, 'day')
              .format('YYYY-MM-DD')
          : moment(this.date.endDate)
              .subtract(1, this.date.rangeType)
              .format('YYYY-MM-DD');

      const params = {
        start_date: startDate,
        end_date: endDate,
      };

      try {
        const { data } = await this.$api.salesNew.getTicketSalesDashboard(params);

        const newPrevData = {
          ...this.prevData,
        };

        Object.keys(data).forEach(type => {
          const typeData = data[type];
          if (type === 'total') {
            newPrevData.totalAmount = typeData.amount;
            newPrevData.totalCount = typeData.count;
          } else if (type === 'new' || type === 're-take') {
            newPrevData[type] = typeData.amount;
          } else if (type === 'refund') {
            newPrevData[type] = typeData.amount > 0 ? -typeData.amount : typeData.amount;
          }
        });

        this.prevData = newPrevData;
      } catch (e) {
        this.prevData = {
          totalAmount: 0,
          totalCount: 0,
          new: 0,
          're-take': 0,
          refund: 0,
        };
      } finally {
        this.prevLoading = false;
        if (!this.dashBoardLoading && !this.tableLoading) {
          this.$store.dispatch('salesNew/setRefreshAll', false);
        }
      }
    },
    handleSearchChange(value) {
      this.search = value;
    },
    searchResult() {
      this.page = 1;
      this.fetchTableData();
    },
    handleSelectChange(name, value) {
      if (name === 'paymentType' && value === 'commission') {
        this.handleSelectChange('paymentMethod', []);
      }
      const newFilterOptions = [...this.filterOptions];
      newFilterOptions.find(filter => filter.name === name).value = value;
      if (name === 'paymentType' && value === 'commission') {
        newFilterOptions.find(filter => filter.name === 'paymentMethod').disabled = true;
      }
      if (name === 'paymentType' && value !== 'commission') {
        newFilterOptions.find(filter => filter.name === 'paymentMethod').disabled = false;
      }
      this.filterOptions = newFilterOptions;
      this.page = 1;
    },

    refresh() {
      this.handleSelectChange('paymentType', 'total');
      this.handleSelectChange('classType', 'total');
      this.handleSelectChange('ticket', []);
      this.handleSelectChange('paymentMethod', []);
      this.handleSelectChange('staff', []);
    },

    tableRowClick(item) {
      this.$router.push(`/users/detail?id=${item.member_id}&user_ticket_id=${item.user_ticket_id}`);
    },

    formatJSON(data) {
      if (!data || !data.length) return [];

      return data.map(item => {
        const typeMap = {
          new: '신규 결제',
          're-take': '재결제',
          experience: '체험',
          refund: '환불',
          upgrade: '업그레이드',
          installment_payment: '미수금 결제',
          commission: '양도 / 환불',
          transfer: '양도',
        };
        const type = typeMap[item.type];
        const date = moment(item.settlement_at).format('YYYY. MM. DD (dd)');
        const {
          amount,
          card_amount,
          cash_amount,
          wiretransfer_amount,
          point_amount,
          unpaid_amount,
          at_time_amount,
        } = item.amount;

        const isRefund = item.type === 'refund';

        let method = '-';
        const arr = [];
        if (card_amount > 0) arr.push('카드');
        if (cash_amount > 0) arr.push('현금');
        if (wiretransfer_amount > 0) arr.push('계좌이체');
        if (point_amount > 0) arr.push('포인트');

        if (arr.length > 0) method = arr.join('+');

        let cardAmount;
        if (isRefund) cardAmount = card_amount * -1;
        else cardAmount = card_amount;

        let cashAmount;
        if (isRefund) cashAmount = cash_amount * -1;
        else cashAmount = cash_amount;

        let wiretransferAmount;
        if (isRefund) wiretransferAmount = wiretransfer_amount * -1;
        else wiretransferAmount = wiretransfer_amount;

        let pointAmount = point_amount;
        if (isRefund) pointAmount = (amount && amount - card_amount - cash_amount - wiretransfer_amount) * -1;

        let atTimeAmount;
        if (at_time_amount === '-') atTimeAmount = '-';
        else atTimeAmount = at_time_amount;

        return {
          구분: type,
          수업: item.class_type === 'G' ? '그룹' : '프라이빗',
          결제일: date,
          회원명: item.member_name,
          수강권명: item.ticket_name,
          카드결제금액: cardAmount,
          현금결제금액: cashAmount,
          계좌이체금액: wiretransferAmount,
          '포인트금액(환불 포인트)': pointAmount,
          결제금액합계: cardAmount + cashAmount + wiretransferAmount + pointAmount,
          '회당 금액': atTimeAmount,
          '전체 횟수': item.count.max_coupon,
          '미수금(위약금)': unpaid_amount,
          '결제 방법': method,
          '담당 강사': item.staffs.join(','),
        };
      });
    },

    async downloadExcel() {
      // this.exceldownloading = true;
      /** 데이터 가공 */
      try {
        const paymentType = this.filterOptions.find(filter => filter.name === 'paymentType').value;
        const classType = this.filterOptions.find(filter => filter.name === 'classType').value;
        const ticket = this.filterOptions.find(filter => filter.name === 'ticket').value;
        const paymentMethod = this.filterOptions.find(filter => filter.name === 'paymentMethod').value;
        const staff = this.filterOptions.find(filter => filter.name === 'staff').value;

        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
          limit: this.limit,
          page: this.page,
          payment_type: paymentType === 'total' ? null : paymentType,
          ticket_id: ticket,
          class_type: classType === 'total' ? null : classType,
          method_type: paymentMethod === 'total' ? null : paymentMethod,
          staff_id: staff,
          search: this.search,
        };

        const data = await this.$utils.promiseAllData(this.$api.salesNew.getTicketSalesData, params);
        const json = this.formatJSON(data);
        this.$utils.downloadExcel(json, `수강권매출_현황${params.start_date}~${params.end_date}.xlsx`);
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      }
      // this.exceldownloading = false;
    },

    handleSortChange(e) {
      this.sort = e.order;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include flex(column);
  margin: auto;
  padding: 0 30px 30px 30px;
  max-width: 1440px;
  gap: 30px;

  /deep/ .el-pagination {
    @include flex(row, center, center);
  }

  .summary {
    display: grid;
    column-gap: 16px;
    grid-template-columns: auto 1fr;

    &.daterange {
      grid-template-columns: 1fr;
    }
  }
}
</style>
