const SEARCH_FOR = {
  'NAME|MOBILE': 'search_name',
  'ADDRESS': 'search_addr',
  'MEMO': 'search_memo',
}

export default params => {
  const newParams = _.omit(params, ['type', 'keyword', 'searchFor']);

  switch (params.type) {
    case 'active':
      newParams.active = true;
      break;
    case 'inHolding':
      newParams.in_holding = true;
      break;
    case 'inactive':
      newParams.inactive = true;
      break;
    case 'unpaid':
      newParams.unpaid = true;
      break;
  }

  switch (params.vaccination_yn) {
    case 'Y':
      newParams.vaccination_yn = 'Y';
      break;
    case 'N':
      newParams.vaccination_yn = 'N';
      break;
    default: break;
  }

  if (params.searchFor && params.keyword) {
    const key = SEARCH_FOR[params.searchFor];
    newParams[key] = params.keyword;
  }

  return newParams;
};