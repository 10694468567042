<template>
  <div class="lecture-memo" v-loading="loading">
    <div class="lecture-memo__title">
      <h4>
        메모
        <CircleButton @click="toggleSortBy" size="small" style="margin-left: 8px;"
          ><i class="el-icon-sort"
        /></CircleButton>
      </h4>
      <button class="lecture-memo__button" @click="handleClickAddMemo" :disabled="memoCreating || memoEditing" round>
        추가
      </button>
    </div>

    <ul class="lecture-memo__list" v-if="memosSorted.length > 0 || memoCreating">
      <li class="lecture-memo__list__item" v-if="memoCreating">
        <Textarea ref="memo-creating" v-model="memoCreating.memo" required />
        <span></span>
        <button class="lecture-memo__button danger" @click="memoCreating = null">
          취소
        </button>
        <button class="lecture-memo__button" @click="handleClickCreateMemo" :disabled="!memoCreating.memo">
          저장
        </button>
      </li>
      <li class="lecture-memo__list__item" v-for="memo in memosSorted" :key="memo.id">
        <div class="lecture-memo__list__item__datetime">
          <span>{{ memo.updated_at | datetime }}</span>
          <span>{{ getStaffName(memo) }}</span>
        </div>
        <button
          v-if="!memoEditing && isMemoEditable(_.get(memo, 'staff.id'))"
          class="lecture-memo__button danger"
          @click="handleClickDeleteMemo(memo)"
        >
          삭제
        </button>
        <button
          v-if="!memoEditing && isMemoEditable(_.get(memo, 'staff.id'))"
          class="lecture-memo__button"
          @click="handleClickEditMemo(memo)"
          :disabled="memoEditing && !memoEditing.memo"
        >
          수정
        </button>
        <Textarea v-if="isEditing(memo.id)" ref="memo-editing" v-model="memoEditing.memo" required />
        <p v-else>{{ memo.memo }}</p>
        <div></div>
        <button v-if="memoEditing" class="lecture-memo__button danger" @click="handleClickCancelEditMemo">
          취소
        </button>
        <button
          v-if="memoEditing"
          class="lecture-memo__button"
          @click="handleClickSaveEditMemo"
          :disabled="memoEditing && !memoEditing.memo"
        >
          저장
        </button>
      </li>
    </ul>

    <p v-else class="lecture-memo__no-data">
      메모가 없습니다.
    </p>
  </div>
</template>

<script>
const DEFAULT_MEMO = { memo: '' };

export default {
  data() {
    return {
      memoSortBy: 'desc',
      memoCreating: null,
      memoEditing: null,
    };
  },

  computed: {
    lecture() {
      return this.$store.getters['lecture/lecture'];
    },
    loading() {
      return this.$store.getters['lecture/memosLoading'];
    },
    memos() {
      return this.$store.getters['lecture/memos'];
    },

    memosSorted() {
      const memos = _.sortBy(this.memos, 'updated_at');
      if (this.memoSortBy === 'desc') memos.reverse();
      return memos;
    },
  },

  methods: {
    toggleSortBy() {
      if (this.memoSortBy === 'asc') {
        this.memoSortBy = 'desc';
      } else {
        this.memoSortBy = 'asc';
      }
    },

    /** 메모 추가 모드 */
    handleClickAddMemo() {
      this.memoCreating = { ...DEFAULT_MEMO };
      this.$nextTick(() => {
        this.$refs['memo-creating'].$el.focus();
      });
    },

    /** 메모 추가 저장 */
    async handleClickCreateMemo() {
      try {
        await this.$api.memo.create({
          ...this.memoCreating,
          ref_type: 'lectures',
          ref_id: this.lecture.id,
        });
        this.$store.dispatch('lecture/getMemos', this.lecture.id);
        this.memoCreating = null;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    /** 내 메모만 수정 가능 (오너는 무적) */
    isMemoEditable(staffId) {
      return this.currentUser.id == staffId || this.isStudioOwner;
    },

    /** 메모 삭제 */
    async handleClickDeleteMemo(memo) {
      const title = '메모 삭제';
      const message = '메모를 삭제하시겠습니까?';
      const proceed = await this.$confirm(message, title)
        .then(() => true)
        .catch(() => false);

      if (proceed) {
        try {
          await this.$api.memo.delete(memo.id);
          this.$store.dispatch('lecture/getMemos', this.lecture.id);
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    /** 메모 수정인지 체크 */
    isEditing(memoId) {
      return this.memoEditing && this.memoEditing.id === memoId;
    },

    /** 메모 수정 모드 */
    handleClickEditMemo(memo) {
      this.memoEditing = { ...memo };
      this.$nextTick(() => {
        this.$refs['memo-editing'][0].$el.focus();
      });
    },

    /** 메모 수정 취소 */
    handleClickCancelEditMemo() {
      this.memoEditing = null;
    },

    /** 메모 수정 내용 저장 */
    async handleClickSaveEditMemo() {
      try {
        await this.$api.memo.update(this.memoEditing);
        this.$store.dispatch('lecture/getMemos', this.lecture.id);
        this.memoEditing = null;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    getStaffName(memo) {
      const staffName = _.get(memo, 'staff.name');
      const roleName = _.get(memo, 'roles');

      return memo.staff ? `${staffName} ${roleName}` : '삭제됨';
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-memo {
  border: solid #f0f0f0;
  border-width: 1px 0 0 0;
  padding: 24px;
  overflow: auto;

  @include mq(1024px) {
    border-width: 0;
  }

  &__button {
    font-size: 12px;
    color: #329af0;
    padding: 4px;

    &.danger {
      color: $color-danger;
    }

    &:hover {
      text-decoration: underline;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__title {
    @include flex(row, center, space-between);
    flex-wrap: wrap;
    margin-bottom: 14px;

    h4 {
      @include flex(row, center);
      color: #000;
      font-size: 17px;
      font-weight: 400;
    }
  }

  &__list {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 8px;
    align-items: start;

    &__item {
      display: grid;
      grid-template-columns: 1fr auto auto;
      grid-gap: 6px;
      align-items: start;
      padding: 16px;
      border: solid 1px rgba(#979797, 0.1);

      &__datetime {
        display: grid;
        grid-gap: 6px;
      }

      &:last-child {
        margin-bottom: 12px;
      }

      span,
      button,
      p,
      textarea {
        font-size: 12px;
      }

      span {
        color: rgba(#000, 0.3);
      }

      p,
      textarea {
        grid-column: span 3;
      }

      p {
        white-space: pre-wrap;
      }
    }
  }

  &__no-data {
    font-size: 15px;
    opacity: 0.5;
    text-align: center;
    padding: 20px 0;
  }
}
</style>
