<template>
  <div class="profile-container">
    <img :src="getImagePath(avatar, 40)" alt="" class="avatar" />
    <div :class="{ text: true, reporter: isReporter }">
      <div class="box">
        <div :class="{ name: true, 'max-width': !!mobile }">{{ name }}</div>
        <div class="mobile">{{ filteredMobile }}</div>
      </div>
      <div class="role">{{ subName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatar: undefined,
    name: String,
    subName: String,
    mobile: String,
    isReporter: Boolean,
  },
  methods: {
    getImagePath(path, size) {
      return this.$utils.getImagePath(path, size);
    },
  },

  computed: {
    filteredMobile() {
      return this.$filters.mobile(this.mobile);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-container {
  display: flex;
  align-items: center;

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-right: 10px;
  }

  .text {
    .box {
      @include flex(row);
      font-size: 14px;
      font-weight: 500;
      .max-width {
        max-width: 50%;
      }
      .name {
        margin-right: 5px;
        @include ellipsis;
      }
      .mobile {
        color: #808080;
      }
    }
    .role {
      font-size: 12px;
      color: #808080;
    }
  }

  .reporter {
    max-width: 180px;

    .max-width {
      max-width: 40% !important;
    }
  }
}
</style>
