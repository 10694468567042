<template>
  <p class="available-hours-input" v-if="typePrimary === 'R'">
    <slot name="prefix" />

    <el-input-number
      v-if="typeSecondary === 'R'"
      :value="parseTime(relativeStart).hour"
      controls-position="right"
      :min="0"
      :max="99"
      :disabled="disabled"
      size="small"
      @change="value => handleRelativeTimeChange('relativeStart.hour', value)"
    />

    <span v-if="typeSecondary === 'R'" :class="{ 'word-spacing': this.path === 'operations' }">시간</span>
    <el-input-number
      v-if="typeSecondary === 'R'"
      :value="parseTime(relativeStart).minute"
      controls-position="right"
      :min="0"
      :max="59"
      :disabled="disabled"
      size="small"
      @change="value => handleRelativeTimeChange('relativeStart.minute', value)"
    />
    <span v-if="typeSecondary === 'R'" :class="{ 'word-spacing': this.path === 'operations' }">분 전부터</span>

    
    <!-- max time 이 있는 경우 -->
    <el-input-number
      v-if="setMaxTime"
      :value="parseTime(relativeEnd).hour"
      controls-position="right"
      :min="0"
      :max="setMaxTime"
      :disabled="disabled"
      size="small"
      @change="value => handleRelativeTimeChange('relativeEnd.hour', value)"
    />
    <el-input-number
      v-else
      :value="parseTime(relativeEnd).hour"
      controls-position="right"
      :min="0"
      :max="99"
      :disabled="disabled"
      size="small"
      @change="value => handleRelativeTimeChange('relativeEnd.hour', value)"
    />
    <span :class="{ 'word-spacing': this.path === 'operations' }">시간</span>
    <el-input-number
      :value="parseTime(relativeEnd).minute"
      controls-position="right"
      :min="0"
      :max="59"
      :disabled="disabled"
      size="small"
      @change="value => handleRelativeTimeChange('relativeEnd.minute', value)"
    />
    <span v-if="afterText">분 후<slot name="suffix"/></span>
    <span v-else :class="{ 'word-spacing': this.path === 'operations' }">분 전<slot name="suffix"/></span>
  </p>

  <p class="available-hours-input" v-else-if="typePrimary === 'A'">
    <slot name="prefix" />
    <el-input-number
      v-if="typeSecondary === 'R'"
      :value="absoluteStartDays || 0"
      controls-position="right"
      :min="0"
      :max="99"
      :disabled="disabled"
      size="small"
      @change="value => handleTimeChange('absoluteStartDays', value)"
    />
    <span v-if="typeSecondary === 'R'">일 전</span>
    <el-time-select
      v-if="typeSecondary === 'R'"
      placeholder="시작시각"
      format="HH:mm"
      value-format="HH:mm"
      :picker-options="{ start: '00:00', end: '23:59', step: '00:01' }"
      :value="formatAbsoluteTime(absoluteStartTime)"
      :disabled="disabled"
      @input="value => handleTimeChange('absoluteStartTime', value)"
    />
    <span v-if="typeSecondary === 'R'">부터</span>

    <el-input-number
      :value="absoluteEndDays || 0"
      controls-position="right"
      :min="0"
      :max="99"
      :disabled="disabled"
      size="small"
      @change="value => handleTimeChange('absoluteEndDays', value)"
    />
    <span>일 전</span>
    <el-time-select
      placeholder="종료시각"
      format="HH:mm"
      value-format="HH:mm"
      :picker-options="{ start: '00:00', end: '23:59', step: '00:01' }"
      :value="formatAbsoluteTime(absoluteEndTime)"
      :disabled="disabled"
      @input="value => handleTimeChange('absoluteEndTime', value)"
    />
    <slot name="suffix" />
  </p>
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'R' },
    disabled: { type: Boolean, default: false },
    relativeStart: { type: Number, default: null },
    relativeStartPropName: String,
    relativeEnd: { type: Number, default: null },
    relativeEndPropName: String,
    absoluteStartDays: { type: Number, default: null },
    absoluteStartDaysPropName: String,
    absoluteStartTime: { type: [String, Number], default: null },
    absoluteStartTimePropName: String,
    absoluteEndDays: { type: Number, default: null },
    absoluteEndDaysPropName: String,
    absoluteEndTime: { type: [String, Number], default: null },
    absoluteEndTimePropName: String,
    afterText: Boolean,
    path: String,
    setMaxTime: Number
  },

  computed: {
    typePrimary() {
      return !this.type ? null : this.type.split('')[0];
    },
    typeSecondary() {
      return !this.type ? null : this.type.split('')[1];
    },
    isRelativeStartLater() {
      return this.relativeStart < this.relativeEnd;
    },
    isAbsoluteStartLater() {
      let startTime = this.$utils.changeTypeStringToDayNumber(this.absoluteStartTime);
      let endTime = this.$utils.changeTypeStringToDayNumber(this.absoluteEndTime);

      if (this.absoluteStartDays < this.absoluteEndDays) {
        return true;
      } else if (this.absoluteStartDays === this.absoluteEndDays && startTime > endTime) {
        return true;
      }
      return false;
    },
  },

  watch: {
    type() {
      if (this.type === 'RR' && this.isRelativeStartLater) {
        this.$emit('change', {
          [this.relativeStartPropName]: this.relativeEnd + 60 * 12,
        });
      } else if (this.type === 'AR' && this.isAbsoluteStartLater) {
        this.$emit('change', {
          [this.absoluteStartDaysPropName]: this.absoluteEndDays + 7,
        });
        this.$emit('change', {
          [this.absoluteStartTimePropName]: this.absoluteEndTime,
        });
      }
    },
  },

  methods: {
    parseTime(value) {
      const hour = parseInt(value / 60) || 0;
      const minute = parseInt(value % 60) || 0;
      return { hour, minute };
    },

    combineTime({ hour, minute }) {
      return hour * 60 + minute || 0;
    },

    formatAbsoluteTime(time) {
      if (!time) return '00:00';
      if (typeof time === 'string') {
        return time.slice(0, -3);
      } else {
        if (!time) return '23:55';
        const timeInString = `000${time}`.slice(-4);
        const hour = timeInString.slice(-4, -2);
        const minute = timeInString.slice(-2);
        return `${hour}:${minute}`;
      }
    },

    handleRelativeTimeChange(key, value) {
      const [parent, child] = key.split('.');
      let parsed = this.parseTime(this[parent]);
      parsed[child] = value;
      const newValue = this.combineTime(parsed);
      const propName = this[`${parent}PropName`];
      this.$emit('change', { [`${propName}`]: newValue });
      this.$nextTick(() => {
        this.calibrateRange();
      });
    },

    handleTimeChange(key, value) {
      if (isNaN(value)) value = parseInt(value.replace(':', ''));
      const propName = this[`${key}PropName`];
      this.$emit('change', { [`${propName}`]: value });
      this.$nextTick(() => {
        this.calibrateRange();
      });
    },

    calibrateRange() {
      if (this.type === 'RR' && this.isRelativeStartLater) {
        //시간 까지 > 시간 전 : 까지와 전 동시에 증가
        this.$emit('change', {
          [this.relativeStartPropName]: this.relativeEnd,
        });
      } else if (this.type === 'AR' && this.isAbsoluteStartLater) {
        //날짜 역전 방지
        this.$emit('change', {
          [this.absoluteStartDaysPropName]: this.absoluteEndDays,
        });

        //날짜 동일하면 시간 까지 > 시간 전 : 까지와 전 동시에 증가
        this.$emit('change', {
          [this.absoluteStartTimePropName]: this.absoluteEndTime,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.available-hours-input {
  .word-spacing {
    font-size: 13px;
  }
  /deep/ .el-input-number {
    width: 80px;

    /deep/ .el-input-number__decrease {
      border: none;
      border-radius: 4px 4px 0 0;
      bottom: 2px;
      width: 20px;
    }

    /deep/ .el-input-number__increase {
      border: none;
      border-radius: 0 0 4px 4px;
      width: 20px;
    }

    /deep/ .el-input__inner {
      padding: 0 25px 0 5px;
    }
  }

  /deep/ .el-date-editor {
    width: 90px;

    /deep/ .el-input__inner {
      padding: 0 15px 0 10px;
      text-align: center;
    }

    /deep/ .el-input__prefix {
      display: none;
    }
  }
}
</style>
