<template>
  <section>
    <!-- 수강권 -->
    <form-block class="settings-automessage-notification__list" :unNumbering="true" label="수강권">
      <ul>
        <li class="settings-automessage-notification__list__item notification__block" v-if="reminderTicketExpiration">
          <label for="reminder_ticket_expiration">기간 만료</label>
          <p id="reminder_ticket_expiration" ref="reminder_ticket_expiration">
            수강권 만료
            <InlineNumberInput
              v-model="reminderTicketExpiration.option.ticket_expire_noti_term"
              @input="handleChangeOption(reminderTicketExpiration, '발송일')"
            />일 전에 전송합니다.
          </p>
          <div class="settings-automessage-notification__checkbox-wrapper">
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderTicketExpiration.push_noti" @change="changePushNoti(reminderTicketExpiration)" />
              Push
            </span>
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderTicketExpiration.message_noti" @change="changeMessageNoti(reminderTicketExpiration)" />
              문자
            </span>
          </div>
          <message-box
            :option="reminderTicketExpiration"
            :confirm="() => handleClickEditButton('REMINDER_TICKET_EXPIRATION')"
            :notify="() => handleClickPreviewButton('REMINDER_TICKET_EXPIRATION')"
          />
        </li>
        <li class="settings-automessage-notification__list__item notification__block" v-if="reminderTicketCount">
          <label for="reminder_ticket_count">잔여횟수 만료</label>
          <p id="reminder_ticket_count" ref="reminder_ticket_count">
            수강권 잔여 횟수
            <InlineNumberInput
              v-model="reminderTicketCount.option.remainder_coupon_noti_count"
              @input="handleChangeOption(reminderTicketCount, '발송 잔여 횟수')"
            />회일 때 전송합니다.
          </p>
          <div class="settings-automessage-notification__checkbox-wrapper">
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderTicketCount.push_noti" @change="changePushNoti(reminderTicketCount)" />
              Push
            </span>
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderTicketCount.message_noti" @change="changeMessageNoti(reminderTicketCount)" />
              문자
            </span>
          </div>
          <message-box
            :option="reminderTicketCount"
            :confirm="() => handleClickEditButton('REMINDER_TICKET_COUNT')"
            :notify="() => handleClickPreviewButton('REMINDER_TICKET_COUNT')"
          />
        </li>
        <li class="settings-automessage-notification__list__item notification__block" v-if="reminderTicketStop">
          <label for="reminder_ticket_stop">정지기간 만료</label>
          <p id="reminder_ticket_stop" ref="reminder_ticket_stop">
            수강권 정지 만료
            <InlineNumberInput
              v-model="reminderTicketStop.option.reminder_ticket_holding_noti_term"
              @input="handleChangeOption(reminderTicketStop, '발송일')"
            />일 전에 전송합니다.
          </p>
          <div class="settings-automessage-notification__checkbox-wrapper">
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderTicketStop.push_noti" @change="changePushNoti(reminderTicketStop)" />
              Push
            </span>
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderTicketStop.message_noti" @change="changeMessageNoti(reminderTicketStop)" />
              문자
            </span>
          </div>
          <message-box
            :option="reminderTicketStop"
            :confirm="() => handleClickEditButton('REMINDER_TICKET_STOP')"
            :notify="() => handleClickPreviewButton('REMINDER_TICKET_STOP')"
          />
        </li>
      </ul>
    </form-block>

    <!-- 수업 -->
    <form-block class="settings-automessage-notification__list" :unNumbering="true" label="수업">
      <ul>
        <li class="settings-automessage-notification__list__item notification__block" v-if="groupWaitAuto">
          <label for="group_wait_auto">대기에서 예약으로 전환 시</label>
          <p id="group_wait_auto" ref="group_wait_auto">이 문자는 실시간으로 전송됩니다.</p>
          <div class="settings-automessage-notification__checkbox-wrapper">
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="groupWaitAuto.push_noti" @change="changePushNoti(groupWaitAuto)" />
              Push
            </span>
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="groupWaitAuto.message_noti" @change="changeMessageNoti(groupWaitAuto)" />
              문자
            </span>
          </div>
          <message-box
            :option="groupWaitAuto"
            :confirm="() => handleClickEditButton('GROUP_WAIT_AUTO')"
            :notify="() => handleClickPreviewButton('GROUP_WAIT_AUTO')"
          />
        </li>
        <li class="settings-automessage-notification__list__item notification__block" v-if="groupDeleteAuto">
          <label for="group_delete_auto">최소 수강인원 미달 폐강 시</label>
          <p id="group_delete_auto" ref="group_delete_auto">이 문자는 폐강시간에 맞춰 전송합니다.</p>
          <div class="settings-automessage-notification__checkbox-wrapper">
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="groupDeleteAuto.push_noti" @change="changePushNoti(groupDeleteAuto)" />
              Push
            </span>
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="groupDeleteAuto.message_noti" @change="changeMessageNoti(groupDeleteAuto)" />
              문자
            </span>
          </div>
          <message-box
            :option="groupDeleteAuto"
            :confirm="() => handleClickEditButton('GROUP_DELETE_AUTO')"
            :notify="() => handleClickPreviewButton('GROUP_DELETE_AUTO')"
          />
        </li>
        <li class="settings-automessage-notification__list__item notification__block" v-if="reminderLectureStart">
          <label for="group_wait_auto">수업 시작 전 알림</label>
          <p id="group_wait_auto" ref="group_wait_auto">
            프라이빗 수업 시작
            <InlineNumberInput
              v-model="reminderLectureStart.option.before_private_lecture_alarm_hour"
              @input="handleChangeOption(reminderLectureStart, '발송시간')"
            />
            시간 전에 전송합니다.
          </p>
          <p id="group_wait_auto" ref="group_wait_auto">
            그룹 수업 시작
            <InlineNumberInput
              v-model="reminderLectureStart.option.before_lecture_alarm_hour"
              @input="handleChangeOption(reminderLectureStart, '발송시간')"
            />
            시간 전에 전송합니다.
          </p>
          <div class="settings-automessage-notification__checkbox-wrapper">
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderLectureStart.push_noti" @change="changePushNoti(reminderLectureStart)" />
              Push
            </span>
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderLectureStart.message_noti" @change="changeMessageNoti(reminderLectureStart)" />
              문자
            </span>
          </div>
          <message-box
            :option="reminderLectureStart"
            :confirm="() => handleClickEditButton('REMINDER_LECTURE_START')"
            :notify="() => handleClickPreviewButton('REMINDER_LECTURE_START')"
            :smallHeight="true"
          />
        </li>
      </ul>
    </form-block>

    <!-- 회원 -->
    <form-block class="settings-automessage-notification__list" :unNumbering="true" label="회원">
      <ul>
        <li class="settings-automessage-notification__list__item notification__block" v-if="reminderBirthday">
          <label for="reminder_birthday">생일축하</label>
          <p id="reminder_birthday" ref="reminder_birthday">이 문자는 실시간으로 전송됩니다.</p>
          <div class="settings-automessage-notification__checkbox-wrapper">
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderBirthday.push_noti" @change="changePushNoti(reminderBirthday)" />
              Push
            </span>
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderBirthday.message_noti" @change="changeMessageNoti(reminderBirthday)" />
              문자
            </span>
          </div>
          <message-box
            :option="reminderBirthday"
            :confirm="() => handleClickEditButton('REMINDER_BIRTHDAY')"
            :notify="handleClickPreviewButton"
          />
          <div class="settings-automessage-notification__switch-expired">
            <span>만료된 회원에게 전송하지 않기</span>
            <span>
              <el-switch v-model="isActivebirthdayNotification" @change="changeExpose" />
            </span>
          </div>
        </li>
      </ul>
    </form-block>
    <!-- 락커 -->
    <form-block class="settings-automessage-notification__list" :unNumbering="true" label="락커">
      <ul>
        <li class="settings-automessage-notification__list__item notification__block" v-if="reminderLockerExpiration">
          <label for="reminder_ticket_stop">락커 기간 만료 알림</label>
          <p id="reminder_ticket_stop" ref="reminder_ticket_stop">
            락커 기간 만료
            <InlineNumberInput
              v-model="reminderLockerExpiration.option.remainder_locker_expire_date_notification"
              @input="handleChangeOption(reminderLockerExpiration, '발송일')"
            />일 전에 전송합니다.
          </p>
          <div class="settings-automessage-notification__checkbox-wrapper">
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderLockerExpiration.push_noti" @change="changePushNoti(reminderLockerExpiration)" />
              Push
            </span>
            <span class="settings-automessage-notification__checkbox">
              <el-checkbox :value="reminderLockerExpiration.message_noti" @change="changeMessageNoti(reminderLockerExpiration)" />
              문자
            </span>
          </div>
          <message-box
            :option="reminderLockerExpiration"
            :confirm="() => handleClickEditButton('REMINDER_LOCKER_EXPIRATION')"
            :notify="() => handleClickPreviewButton('REMINDER_LOCKER_EXPIRATION')"
          />
        </li>
      </ul>
    </form-block>
  </section>
</template>

<script>
import InlineNumberInput from '../../shared/InlineNumberInput.vue';
import MessageBox from '../../shared/MessageBox.vue';
import emojiCheck from '@/utils/emojiCheck';

export default {
  components: { InlineNumberInput, MessageBox },
  props: {
    formatAutoMessageUnitOptions: Object,
    checkLength: Function,
    handleClickEditButton: Function,
    handleClickPreviewButton: Function,
    showShortageModal: Boolean,
  },

  data() {
    return {
      isLoading: false,
      isActivebirthdayNotification: false,
      reminderTicketExpiration: {
        option: {
          ticket_expire_noti_term: 0,
        },
      },
      reminderTicketCount: {
        option: {
          remainder_coupon_noti_count: 0,
        },
      },
      reminderTicketStop: {
        option: {
          reminder_ticket_holding_noti_term: 0,
        },
      },
      groupWaitAuto: {},
      groupDeleteAuto: {},
      reminderLectureStart: {
        option: {
          before_lecture_alarm_hour: 0,
          before_private_lecture_alarm_hour: 0,
        },
      },
      reminderBirthday: {
        option: {
          is_active_user_birth_day_notification: false,
        },
      },
      reminderLockerExpiration: {
        option: {
          remainder_locker_expire_date_notification: 0,
        },
      },
    };
  },

  watch: {
    async formatAutoMessageUnitOptions() {
      this.isLoading = true;
      await this.syncWithUpdatedProps();
      this.isLoading = false;
    },
  },

  created() {
    this.isLoading = true;
  },

  computed: {
    point() {
      return this.$store.getters['payment/point'];
    },
  },

  methods: {
    syncWithUpdatedProps() {
      this.reminderTicketExpiration = {
        ...this.formatAutoMessageUnitOptions.REMINDER_TICKET_EXPIRATION,
        option: { ...this.formatAutoMessageUnitOptions.REMINDER_TICKET_EXPIRATION.option },
      };
      this.reminderTicketCount = {
        ...this.formatAutoMessageUnitOptions.REMINDER_TICKET_COUNT,
        option: { ...this.formatAutoMessageUnitOptions.REMINDER_TICKET_COUNT.option },
      };
      this.reminderTicketStop = {
        ...this.formatAutoMessageUnitOptions.REMINDER_TICKET_STOP,
        option: { ...this.formatAutoMessageUnitOptions.REMINDER_TICKET_STOP.option },
      };
      this.groupWaitAuto = this.formatAutoMessageUnitOptions.GROUP_WAIT_AUTO;
      this.groupDeleteAuto = this.formatAutoMessageUnitOptions.GROUP_DELETE_AUTO;
      this.reminderLectureStart = {
        ...this.formatAutoMessageUnitOptions.REMINDER_LECTURE_START,
        option: { ...this.formatAutoMessageUnitOptions.REMINDER_LECTURE_START.option },
      };
      this.reminderBirthday = {
        ...this.formatAutoMessageUnitOptions.REMINDER_BIRTHDAY,
        option: { ...this.formatAutoMessageUnitOptions.REMINDER_BIRTHDAY.option },
      };
      this.isActivebirthdayNotification = this.formatAutoMessageUnitOptions.REMINDER_BIRTHDAY.option.is_active_user_birth_day_notification;
      this.reminderLockerExpiration = {
        ...this.formatAutoMessageUnitOptions.REMINDER_LOCKER_EXPIRATION,
        option: { ...this.formatAutoMessageUnitOptions.REMINDER_LOCKER_EXPIRATION.option },
      };
    },

    async handleChangeNoti(option, { optionName }) {
      const notiType =
        option.push_noti && option.message_noti
          ? 'BOTH'
          : !option.push_noti && !option.message_noti
          ? 'NONE'
          : option.push_noti
          ? 'PUSH'
          : 'MESSAGE';
      const result = await this.$store.dispatch('autoMessageNotification/putTemplate', {
        id: option.id,
        flag: 'noti_type',
        type: notiType,
      });

      if (option.push_noti === result.push_noti && option.message_noti === result.message_noti) {
        return this.$utils.notify.success(this, '성공', `${optionName} 옵션이 수정되었습니다.`);
      }
      return this.$utils.notify.error(this, '오류', '옵션이 정상적으로 반영되지 않았습니다');
    },

    async handleChangeOption(newState, optionName) {
      if (this.isLoading) return;
      const { message_type, option } = newState;
      const newValues = Object.values(option);
      const currentState = this.formatAutoMessageUnitOptions[message_type];
      const every = Object.values(currentState.option).every((opt, optIdx) => opt === newValues[optIdx]);
      if (every) return;

      const optionValue = `${optionName}${optionName === '발송 잔여 횟수' ? '를' : '을'}`;
      // confirm popup
      this.isLoading = true;
      const message = `${optionValue}<br/>수정하시겠습니까?`;
      const title = '';
      return this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
        customClass: 'settings-automessage-notification-modal',
      })
        .then(async () => {
          await this.$store.dispatch('autoMessageNotification/putTemplate', {
            id: newState.id,
            flag: 'option',
            option: option,
          });
          this.isLoading = false;
          const optionSubject = `${optionName}${optionName === '발송 잔여 횟수' ? '가' : '이'}`;
          return this.$utils.notify.success(this, '성공', `${optionSubject} 수정되었습니다.`);
        })
        .catch(() => {
          this.isLoading = false;
          return this.syncWithUpdatedProps(); //새로운 값으로 바뀌지 않고, 기존 상태로 돌아오기
        });
    },

    changePushNoti(option) {
      option.push_noti = !option.push_noti;
      this.handleChangeNoti(option, { optionName: 'Push' });
    },

    changeMessageNoti(option) {
      const byteCount = this.$utils.smsByteCheckWithPreventWriting(option.convertedContext);
      if (!option.message_noti && this.point < 12) {
        this.$emit('changeShowShortageModal', true);
      } else if (!option.message_noti && byteCount > 90 && this.point < 37) {
        this.$emit('changeShowShortageModal', true);
      } else {
        if (emojiCheck(option.convertedContext) && !option.message_noti) {
          this.$utils.notify.error(this, '오류', '문자 메시지에는 이모지를 포함할 수 없습니다.');
          return;
        }

        option.message_noti = !option.message_noti;
        this.handleChangeNoti(option, { optionName: '문자' });
      }
    },

    async changeExpose() {
      await this.$store.dispatch('autoMessageNotification/putTemplate', {
        id: this.formatAutoMessageUnitOptions.REMINDER_BIRTHDAY.id,
        flag: 'option',
        option: { is_active_user_birth_day_notification: this.isActivebirthdayNotification },
      });
    },
  },
};
</script>

<style lang="scss" scopped>
.settings-automessage-notification {
  &__list ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    align-items: start;
  }

  &__list {
    padding-bottom: 60px;
    &__item {
      label {
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        color: #343a40;
      }

      p {
        font-size: 13px;
        text-align: left;
        color: #777;
        padding-top: 11px;

        &:first-of-type {
          padding-top: 12px;
        }
        &:last-of-type {
          padding-bottom: 12px;
        }
      }
    }
  }

  .form-block {
    // ToDo
    grid-template-columns: 0px 1fr !important;
    padding: 0px 0px 60px !important;

    &:first-child {
      padding-top: 40px !important;
    }

    &__label {
      margin-bottom: 16.5px !important;
      &__title {
        h5 {
          font-size: 18px !important;
          font-weight: bold;
          text-align: left;
          color: #343a40;
        }
      }
    }

    &__content {
      border-bottom: none !important;
      padding: 0px !important;
    }
  }

  .inline-number-input {
    &.el-input-number {
      width: 45px;
      height: 20px;
      line-height: 0px;

      .el-input__inner {
        width: 45px;
        height: 20px;
        border-bottom: 0px;
        border-radius: 3px;
        border: solid 1px #dedede;
        padding: 0 5px 0 5px;
      }

      .el-input-number {
        border-radius: 3px;
        border: solid 1px #dedede;

        &__increase {
          top: 0px;
          right: 0px;
          border-radius: 0px 3px 0 0;
          border: solid 1px #dedede;
          background-color: #f7f7f7;
          width: 13px;
          height: 11px;
          line-height: 10px;
          right: 0px;
          i {
            position: absolute;
            top: -2px;
          }
        }

        &__decrease {
          bottom: 0px;
          right: 0px;
          border-radius: 0 0 3px 0px;
          border: solid 1px #dedede;
          background-color: #f7f7f7;
          width: 13px;
          height: 11px;
          line-height: 10px;
          right: 0px;
          i {
            position: absolute;
            top: -1px;
          }
        }
      }
    }

    .el-icon-arrow {
      &-up {
        color: #dedede !important;
        right: calc(50% - 6.5px);
      }
      &-down {
        color: #dedede !important;
        right: calc(50% - 6.5px);
      }
    }
  }

  &__checkbox {
    margin-right: 15px;

    &-wrapper {
      padding-bottom: 11.5px;
    }
  }

  &__switch-expired {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 16px 10px;
    margin: 15px 0px 0px 0px;
    border-radius: 4px;
    background-color: #f5fbff;

    span {
      font-size: 13px;
      font-weight: 500;
      text-align: left;
      color: #343a40;
    }
  }

  &-modal {
    max-width: 320px !important;
    padding-bottom: 20px;
    .el-message-box {
      &__header {
        display: none !important;
      }
      &__content {
        padding: 20px 20px 15px 20px;
      }
      &__message {
        display: block;
        font-size: 18px !important;
        line-height: 1;
        color: #303133 !important;
      }
    }
  }
}
</style>
