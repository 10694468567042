import axios from '../axios';

const BASE_URL = '/v2/staff/ticket';
const USER_BATCH_BASE_URL = '/v2/staff/userTickets';

export default {
  // 수강권 목록
  getAll: params =>
    axios.get(BASE_URL, {
      params,
    }),

  // 수강권 발급 내역
  getUserTickets: (id, params) => {
    return axios.get(`${BASE_URL}/${id}/user-tickets`, {
      params,
    });
  },

  // 수강권으로 수강 가능한 코스 목록
  getClasses: id => {
    return axios.get(`/tickets/${id}/courses`, {
      params: {
        with: 'instructor.profile;instructor.avatars;schedules;lectures',
      },
    });
  },

  getColorSet: () => axios.get(`${BASE_URL}/colors`),
  // 개별 수강권
  get: id => id && axios.get(`${BASE_URL}/${id}`),

  // 수강권 생성
  create: data => axios.post(BASE_URL, data),

  // 수강권 수정
  update: (id, data) => axios.patch(`${BASE_URL}/${id}`, data),

  // 수강권 삭제
  delete: id => axios.delete(`/products/${id}`),

  // 수강권 일괄 연장
  extend: data => axios.post(`${BASE_URL}/add`, data),

  // 선택 회원 수강권 일괄 연장
  userBatchExtend: data => axios.post(
    `${USER_BATCH_BASE_URL}/add/user-ticket`, data
  ),

  // 선택 회원 수강권 일괄 정지
  userBatchStop: data => axios.post(
    `${USER_BATCH_BASE_URL}/active-toggle-bulk`, { holding: data }
  ),
};
