<template>
  <el-dialog class="point-storage-dialog" :visible="show" @close="$emit('close', false)">
    <h3 slot="title">예약 취소</h3>
    취소할 경우 최소 수강인원보다 예약 인원이 적어 <br>
    폐강 시간 이후 수업이 삭제됩니다.<br>
    <div class="checkbox">
      <el-checkbox v-model="cancelCloseLecture">체크시 수업을 자동 폐강 하지 않습니다.</el-checkbox>
    </div>
    <div slot="footer">
      <el-button @click="$emit('close', false)">
        취소
      </el-button>
      <el-button type="primary" @click="$emit('close', false, true, cancelCloseLecture)">
        확인
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      cancelCloseLecture: false,
    }
  }
};
</script>

<style lang="scss" scoped>
.point-storage-dialog {
  /deep/ .el-dialog {
    width: 28%;
    max-width: 1000px;
    &__header {
      padding: 20px 30px 0 30px;
    }
    &__body {
      padding: 20px 30px;
    }
  }
  .title {
    font-size: 20px;
  }
  .checkbox {
    margin-top: 20px;
  }
}
</style>