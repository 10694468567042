<template>
  <li class="user-ticket-list-item" :class="{ selected }" @click="$emit('click', data.id)">
    <img class="user-ticket-list-item__image" :src="memberImage" :alt="memberName" />

    <div class="user-ticket-list-item__info-container">
      <div class="user-ticket-list-item__info-wrapper">
        <p class="user-ticket-list-item__user-profile">
          <span>{{ memberName }}</span>
          <span> · </span>
          <span>{{ memberMobile | mobile }}</span>
        </p>
        <p class="user-ticket-list-item__ticket-info">
          <span>{{ ticketTitle }}</span>
          <span v-if="ticketType !== 'P'"> · {{ usableCoupon }}회 남음</span>
        </p>
      </div>

      <div class="user-ticket-list-item__vaccine" v-if="getVaccination">
        <img src="@/assets/images/icons/vaccine.svg" alt="백신 접종 완료 후 14일 경과" />
      </div>
    </div>
    <div class="user-ticket-list-item__controls">
      <el-tag v-if="selected">선택됨</el-tag>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    data: Object,
    selected: Boolean,
    v2Api: { type: Boolean, default: false },
  },

  computed: {
    memberImage() {
      return !this.v2Api
        ? this.$utils.getImageUrl(_.get(this.data, 'member.avatars[0]'), '36x36')
        : this.$utils.getImagePath(_.get(this.data, 'member.image'), 36);
    },

    memberName() {
      return !this.v2Api ? _.get(this.data, 'member.profile.name') : _.get(this.data, 'member.name');
    },

    getVaccination() {
      const vaccine = _.get(this.data, 'member.vaccination_yn');
      return vaccine === 'Y';
    },

    memberMobile() {
      return this.canViewMembersMobile
        ? _.get(this.data, 'member.mobile')
        : this.$filters.mobileMask(_.get(this.data, 'member.mobile')) || '';
    },

    ticketTitle() {
      return _.get(this.data, 'ticket.title');
    },

    ticketType() {
      return _.get(this.data, 'ticket.type');
    },

    usableCoupon() {
      return _.get(this.data, 'usable_coupon');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-ticket-list-item {
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'image user-profile controls'
    'image ticket-info controls';
  grid-column-gap: 12px;
  padding: 12px;
  transition: 0.15s;

  & + & {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  &:hover {
    background: $gray-100;
  }

  &.selected {
    background: rgba($color-primary, 0.05);
  }

  &__image {
    grid-area: image;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  &__info-container {
    display: flex;
    align-items: center;
  }

  &__vaccine {
    width: 22px;
    height: 22px;
    margin-left: 10px;
  }

  &__user-profile {
    grid-area: user-profile;
    font-size: 13px;
  }

  &__ticket-info {
    grid-area: ticket-info;
    font-size: 11px;
    opacity: 0.7;
  }

  &__controls {
    align-self: center;
    grid-area: controls;
  }
}
</style>
