<template>
  <div class="list-filter">
    <div class="list-filter__selects">
      <span v-for="(filterOption, key) in filterOptions" :key="key">
        <el-select
          v-if="filterOption.type === 'select'"
          :value="filterValues[key]"
          :class="{ 'is-filtered': filterValues[key] !== null }"
          @change="value => handleFilterChange({ [key]: value })"
        >
          <el-option
            class="list-filter__option-list"
            v-for="option in filterOption.options"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
        <el-popover
          v-else
          popper-class="list-filter__popover"
          placement="bottom"
          v-model="showPopover[key]"
          :disabled="key !== 'type' && filterValues.type === 'inactive'"
          @after-leave="() => handleClickCancel(key)"
        >
          <el-radio-group v-model="hasValue[key]">
            <el-radio :label="false">{{ `${label[key]} 전체` }}</el-radio>
            <el-radio :label="true">
              <span>{{ label[key] }}</span>
              <NumberInput v-model="customValues[key]" :disabled="!hasValue[key]" :min="1" :max="999" :suffix="suffix[key]" />
            </el-radio>
          </el-radio-group>
          <div class="list-filter__popover__buttons">
            <el-button @click="showPopover[key] = false">취소</el-button>
            <el-button type="primary" @click="handleClickApply(key)">적용</el-button>
          </div>
          <el-button slot="reference" :class="{ 'is-filtered': filterValues[key] !== null }">{{ getButtonLabel(key) }}</el-button>
        </el-popover>
      </span>

      <FilterResetButton @click="$emit('reset-click')" />

      <div class="list-filter__count-wrapper" v-if="!loading">
        <span class="list-filter__count" v-if="activeRoute === `/users`">필터된 회원 {{ filteredTotal | comma }}명</span>
        <span class="list-filter__count" v-if="activeRoute === `/users`">
          |
        </span>
        <span class="list-filter__count" v-if="activeRoute === `/users`"
          >선택된 회원 {{ selectedMemberIds.length | comma }}명</span
        >
      </div>
    </div>

    <div
      :class="[
        activeRoute === '/sms' || activeRoute === `/sms/${$route.params.id}/edit` ? 'list-filter__sms-text' : 'list-filter__text',
      ]"
    >
      <el-select
        v-model="searchFor"
        v-if="activeRoute === '/message' || activeRoute === `/message/${$route.params.id}/edit` || activeRoute === `/users`"
      >
        <el-option v-for="option in searchForOptions" :key="option.value" :label="option.label" :value="option.value" />
      </el-select>

      <TextInput
        v-model="keyword"
        :placeholder="searchTextPlaceholder"
        prefix-icon="el-icon-search"
        round
        @keyup.enter.native="handleChangeKeyword"
      />
    </div>
    <div v-if="activeRoute !== '/users'" class="list-filter__sms-text__box">
      <el-checkbox v-model="checkAll" @change="$emit('handleCheckSelect', checkAll)">
        <span class="list-filter__sms-text__count"> 필터된 회원 {{ smsMemberTotal | comma }}명 전체 선택 </span>
      </el-checkbox>
      <span v-if="!checkAll" class="list-filter__sms-text__count">
        |
      </span>
      <span v-if="!checkAll" class="list-filter__sms-text__count select-width"> 선택된 회원 {{ selectedLength | comma }}명 </span>
    </div>
  </div>
</template>

<script>
const PLACEHOLDERS = {
  'NAME|MOBILE': '회원 이름 또는 전화번호 검색',
  ADDRESS: '주소 검색',
  MEMO: '메모 검색',
};

export default {
  props: {
    filterValues: { type: Object, required: true },
    filterOptions: { type: Object, required: true },
    filteredTotal: { type: Number, default: 0 },
    smsMemberTotal: { type: Number, default: 0 },
    selectedLength: { type: Number, default: 0 },
    checkSelectAll: { type: Boolean, default: () => false },
  },

  data() {
    return {
      keyword: '',
      searchFor: null,
      searchForOptions: [
        { value: 'NAME|MOBILE', label: '이름/전화' },
        { value: 'ADDRESS', label: '주소' },
        { value: 'MEMO', label: '메모' },
      ],

      showPopover: {
        remaining_coupon: false,
        remaining_day: false,
        last_attendance_day: false,
      },
      hasValue: {
        remaining_coupon: false,
        remaining_day: false,
        last_attendance_day: false,
      },
      customValues: {
        remaining_coupon: 5,
        remaining_day: 5,
        last_attendance_day: 5,
      },
      label: {
        remaining_coupon: '잔여횟수',
        remaining_day: '잔여기간',
        last_attendance_day: '미방문일수',
      },
      suffix: {
        remaining_coupon: '회 이하',
        remaining_day: '일 이하',
        last_attendance_day: '일 이상',
      },
      defaultFilterValue: {
        is_account: true,
        type: null,
        course_type: null,
        remaining_coupon: null,
        user_grade: null,
        remaining_day: null,
        searchFor: 'NAME|MOBILE',
        keyword: '',
      },
      checkAll: false,
    };
  },

  computed: {
    searchTextPlaceholder() {
      return PLACEHOLDERS[this.searchFor];
    },
    activeRoute() {
      return this.$route.path;
    },
    loading() {
      return this.$store.getters['members/loading'];
    },
    selectedMemberIds() {
      return this.$store.getters['members/selectedMemberIds'];
    },
  },

  watch: {
    filterValues() {
      ['remaining_coupon', 'remaining_day', 'last_attendance_day'].forEach(key => {
        if (!this.filterValues[key]) {
          this.hasValue[key] = false;
        } else {
          this.hasValue[key] = true;
          this.customValues[key] = this.filterValues[key];
        }
      });
    },

    'filterValues.keyword': {
      handler(value) {
        this.keyword = value;
      },
      immediate: true,
    },

    'filterValues.searchFor': {
      handler(value) {
        this.searchFor = value;
      },
      immediate: true,
    },

    checkSelectAll: {
      handler(value) {
        this.checkAll = value;
      },
    },
  },

  created() {
    this.checkQuery();
    this.setKeywordFromStore();
  },

  methods: {
    closeDataProps(length) {
      this.selectedLength = length;
    },

    handleFilterChange(values) {
      this.$emit('filter-change', { ...this.filterValues, ...values });
    },

    handleChangeKeyword() {
      this.handleFilterChange({
        searchFor: this.searchFor,
        keyword: this.keyword,
      });
    },

    handleClickCancel(key) {
      this.showPopover[key] = false;
      if (!this.filterValues[key]) {
        this.hasValue[key] = false;
      } else {
        this.hasValue[key] = true;
        this.customValues[key] = this.filterValues[key];
      }
    },

    handleClickApply(key) {
      this.showPopover[key] = false;
      if (!this.hasValue[key]) this.handleFilterChange({ [key]: null });
      else this.handleFilterChange({ [key]: this.customValues[key] });
    },

    getButtonLabel(key) {
      const value = this.filterValues[key];
      let suffix = `${value}${this.suffix[key]}`;
      if (!value) suffix = '전체';
      return `${this.label[key]} ${suffix}`;
    },

    checkQuery() {
      const keyword = this.$route.query.keyword;
      if (!keyword) {
        this.keyword = '';
        return;
      }
      this.keyword = keyword;
      this.handleChangeKeyword();
    },

    setKeywordFromStore() {
      this.keyword = this.filterValues.keyword;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  @include flex(row, center, space-between);
  flex-wrap: wrap;
  margin-bottom: 24px;

  &__selects {
    @include flex(row, center, flex-start);
    flex-wrap: wrap;

    /deep/ .el-select,
    /deep/ .el-button {
      margin: 0 4px 4px 0;
      font-weight: 400;
    }

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 40px;
      width: 150px;
      padding: 0 15px;
    }
  }

  &__option-list {
    width: 160px;
  }

  &__popover {
    .el-radio-group {
      @include flex(column);
      padding: 6px 0;

      .el-radio {
        @include flex(row, center, flex-start);
        margin: 0;
        padding: 0 20px;
        height: 34px;
        transition: 0.15s background;

        &:hover {
          background: #f5f7fa;
        }

        /deep/ .el-radio__label {
          @include flex(row, center, flex-start);
        }

        .custom-number-input {
          max-width: 180px;
          margin-left: 8px;
        }
      }
    }

    &__buttons {
      padding: 10px 20px;
      text-align: right;
    }
  }

  &__count-wrapper {
    position: absolute;
    top: 36px;
    right: 494px;
  }

  &__count {
    color: $deep-sky-blue;
    font-weight: 500;
    margin: 0 4px 4px 12px;
    font-size: 14px;
  }

  &__box {
    width: 430px;
  }

  &__text {
    display: grid;
    grid-template-columns: 105px auto;
    grid-gap: 8px;
    margin: 0 4px 4px auto;
    width: 290px;

    /deep/ .el-input__inner {
      @extend %input-round;
    }

    /deep/ .el-select {
      width: 100%;

      .el-input__inner {
        padding: 0 16px;
        width: 105px;

        &:hover {
          @extend %input-round;
        }
      }
    }

    /deep/ .custom-text-input__inner {
      width: 1px;

      input {
        width: 180px;
      }
    }
  }

  &__sms-text {
    width: 230px;
    /deep/ .custom-text-input__inner {
      input {
        width: 180px;
      }
    }
    display: flex;
    &__box {
      width: 330px;
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }
    &__count {
      color: $deep-sky-blue;
      font-weight: 500;
      line-height: 30px;
    }
  }

  .select-width {
    width: 120px !important;
  }

  .is-filtered {
    color: $color-primary;

    /deep/ .el-input__inner {
      color: $color-primary;
    }
  }
}
</style>
