<template>
  <span :class="[type, size]">
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: 12px;
  border: 1px solid;
  border-radius: 4px;
  white-space: nowrap;

  /** Colors */
  &.primary {
    border-color: $color-primary;
    color: $color-primary;
  }
  &.white {
    border-color: #fff;
    color: #fff;
  }

  /** Sizes */
  &.large {
    padding: 3px 10px;
  }
  &.medium {
    padding: 2px 8px;
  }
  &.small {
    padding: 1px 6px;
  }
  &.mini {
    padding: 0px 5px;
  }

  & + span {
    margin-left: 4px;
  }
}
</style>
