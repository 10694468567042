<template>
  <form class="list-filter" @keydown.enter.prevent="$emit('change', filterValues)">
    <div class="list-filter__selects">
      <el-select
        v-for="(options, key) in filterOptions"
        :key="key"
        v-model="filterValues[key]"
        @change="$emit('change', filterValues)"
        :class="[filterValues[key] !== null ? 'is-filtered' : null]"
        :disabled="key !== 'type' && filterValues.type === 'inactive'"
      >
        <el-option v-for="option in options" :key="option.value" :label="option.label" :value="option.value" />
      </el-select>
      <div class="list-filter__selects__date-range">
        <el-date-picker
          v-model="filterValues.dateRange"
          type="daterange"
          start-placeholder="기간"
          end-placeholder="필터"
          range-separator="-"
          :clearable="false"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          @change="$emit('change', filterValues)"
        >
        </el-date-picker>
      </div>

      <el-tooltip effect="light" content="필터 초기화" placement="top">
        <el-button @click="$emit('reset')" size="small" circle>
          <i class="el-icon-refresh"></i>
        </el-button>
      </el-tooltip>
    </div>
    <div class="list-filter__text">
      <TextInput v-model="filterValues.keyword" :placeholder="placeholders.keyword" prefix-icon="el-icon-search" round />
    </div>
  </form>
</template>

<script>
export default {
  props: {
    filterValues: Object,
    filterOptions: Object,
    placeholders: Object,
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  @include flex(row, center, space-between);
  flex-wrap: wrap;
  margin-bottom: 24px;

  fieldset {
    @include flex(row, center, flex-start);
    border: none;
    margin: 0;
    padding: 0;
  }

  &__selects {
    flex: 1;

    /deep/ .el-select {
      width: 150px;
      margin: 0 4px 4px 0;
    }

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 40px;
      width: 150px;
      padding: 0 15px;
    }

    &__date-range {
      display: inline-block;
      width: 255px;

      /deep/ .el-date-editor {
        width: 250px;
      }
      /deep/ .el-range-separator {
        line-height: 40px;
      }
      /deep/ .el-range__close-icon {
        display: none;
      }
      /deep/ .el-range-input {
        color: $color-primary;
      }
    }
  }

  &__text {
    /deep/ .el-input__inner {
      @extend %input-round;
    }
  }

  .is-filtered {
    color: $color-primary;

    /deep/ .el-input__inner {
      color: $color-primary;
    }
  }
}
</style>
