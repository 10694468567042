<template>
  <div class="message-box-form">
    <textarea
      type="textarea"
      :id="option.unitTypeDescription"
      :disabled="true"
      v-model="option.convertedContext"
      :class="{ 'small-height': smallHeight }"
    />
    <div :class="`message-box-form__bottom ${checkLength(byteCount) === 'LMS' ? 'highlight' : ''} ${isOver2000Byte && 'over'}`">
      <span>
        예상바이트&ensp;<b>{{ byteCount }}</b> 바이트</span
      >
      <span class="tag">
        <b>{{ checkLength(byteCount) }}</b>
      </span>
    </div>
    <div class="message-box-form__buttons">
      <button class="confirm" @click="confirm">수정</button>
      <button class="notify" @click="notify(option.message_type)">미리보기</button>
    </div>
  </div>
</template>

<script>
export default {
  props: { option: Object, confirm: Function, notify: Function, smallHeight: Boolean },

  computed: {
    byteCount() {
      return this.$utils.smsByteCheckWithPreventWriting(this.option.convertedContext);
    },

    isOver2000Byte() {
      return this.byteCount > 2000;
    },
  },

  methods: {
    checkLength(byteCount) {
      return byteCount > 90 ? 'LMS' : 'SMS';
    },
  },
};
</script>

<style lang="scss" scopped>
.message-box-form {
  display: flex;
  width: 100%;
  min-height: 205px;
  flex-direction: column;
  flex-wrap: wrap;

  &__buttons {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
    width: 100%;

    button {
      display: inline-block;
      width: 48%;
      height: 32px;
      font-size: 14px;
      text-align: center;
      border-radius: 2px;
    }

    .confirm {
      background-color: #4699f1;
      color: rgba(255, 255, 255, 0.9);
    }

    .notify {
      border: solid 1px #8a8a8a;
      background-color: #ffffff;
      color: rgba(138, 138, 138, 0.9);
    }
  }

  textarea {
    width: 100%;
    resize: none;
    min-height: 205px;
    padding: 15px;
    background-color: #ffffff !important;
    font-size: 12px;
    text-align: left;
    color: #414141;
    border-radius: 4px 4px 0px 0px;
    border: solid 1px #eee;
    border-bottom: 0px;
    line-height: normal;

    &.small-height {
      min-height: 173px;
    }
  }

  &__bottom {
    display: flex;
    position: relative;
    height: 34px;
    line-height: 34px;
    border: solid 1px #eee;
    border-radius: 0px 0px 4px 4px;
    background-color: #f8f8f8;

    span {
      position: absolute;
      font-size: 13px;
      font-weight: normal;
      text-align: left;
      color: #343a40;
      line-height: 34px;

      &:first-child {
        left: 13.5px;
      }

      &:last-child {
        right: 13.5px;
      }
    }

    &.highlight {
      span {
        b {
          color: #ffac26;
        }

        &.tag {
          width: 35px;
          height: 19px;
          top: calc(50% - 9px);
          line-height: 18px;
          border-radius: 3px;
          border: solid 1px #ffac26;
          background-color: #ffedd1;
          color: #ffac26;
          text-align: center;
        }
      }
    }

    &.over {
      span {
        &:first-of-type {
          b {
            color: #ff0000;
          }
        }
      }
    }
  }
}
</style>
