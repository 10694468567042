import axios from '../axios';

const BASE_URL = '/v2/staff/members';

export default {
  getPointDetail: memberId => axios.get(`${BASE_URL}/${memberId}/point/detail`),

  getPointHistories: ({ memberId, params }) => {
    return axios.get(`${BASE_URL}/${memberId}/point/histories`, { params });
  },

  postBatchReward: params => axios.post(`${BASE_URL}/point/batch-reward`, params),

  postBatchDeduct: params => axios.post(`${BASE_URL}/point/batch-deduct`, params),

  postReward: params => axios.post(`${BASE_URL}/point/reward`, params),

  postDeduct: params => axios.post(`${BASE_URL}/point/deduct`, params),
};
