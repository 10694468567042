<template>
  <auth-container
    title="아이디 찾기"
    :description="description[step - 1]"
    footer_message="비밀번호를 잊으셨나요?"
    :footer_links="[{ path: '/reset_password', label: '비밀번호 재설정' }]"
  >
    <auth-form :cancel_button="cancel_button" :submit_button="submit_button[step - 1]">
      <!-- Step 1: 이름으로 아이디 검색 -->
      <auth-input v-if="step === 1" id="name" type="text" placeholder="이름 입력" v-model="name" />

      <!-- Step 2: 검색된 아이디 표시 -->
      <ul v-if="step === 2" class="find-id__identities">
        <hr />
        <li v-for="id in identities" :key="id">
          {{ id }}
        </li>
      </ul>

      <!-- Step 3: 아이디 휴대폰 문자로 전송 -->
      <div v-if="step === 3" class="send-sms">
        <auth-input id="mobile" type="number" placeholder="휴대전화 번호 입력 (숫자만)" v-model="mobile" />
        <auth-button variation="success" label="문자 받기" type="submit" :disabled="mobile === ''" :onClick="sendSMS" />
        <p v-if="sms_success" class="send-sms__message">
          문자가 전송되었습니다.
        </p>
      </div>
    </auth-form>
  </auth-container>
</template>

<script>
import AuthContainer from '@/components/Auth/Container';
import AuthForm from '@/components/Auth/Form';
import AuthInput from '@/components/Auth/Input';
import AuthButton from '@/components/Auth/Button';

export default {
  components: {
    AuthContainer,
    AuthForm,
    AuthInput,
    AuthButton,
  },

  data() {
    return {
      /** All Steps */
      step: 1,
      cancel_button: {
        label: '로그인 화면으로',
        onClick: () => this.$router.push('/login'),
      },

      /** Step 1 */
      name: '',

      /** Step 2 */
      identities: [],

      /** Step 3 */
      mobile: '',
      sms_success: false,
    };
  },

  computed: {
    /** 각 단계별 Submit 버튼 */
    submit_button() {
      return [
        {
          label: '아이디 찾기',
          disabled: this.name === '',
          onClick: this.findId,
        },
        {
          label: '문자로 확인하기',
          onClick: () => this.setStep(3),
        },
        {
          label: '이전으로',
          onClick: () => this.setStep(2),
        },
      ];
    },

    /** 각 단계별 상세 메시지 */
    description() {
      return [
        '시설에 사용중인 강사명을 입력해주세요.',
        `<b>'${this.name}'</b>님의 이름으로 찾은 아이디이며, 동명이인의 아이디가 검색될 수 있습니다.<br>
          정확한 아이디가 기억나지 않으실 경우 '문자로 확인하기'를 클릭해주세요.`,
        '휴대전화 번호(숫자만)를 입력해주세요.',
      ];
    },
  },

  methods: {
    async findId() {
      try {
        const res = await this.$api.auth.getIdsByName(this.name);
        this.identities = res.data;
        this.step = 2;
      } catch (error) {
        this.$utils.notify.error(this, '아이디 찾기 실패!', '존재하지 않는 강사명입니다.');
      }
    },

    async sendSMS() {
      try {
        await this.$api.auth.sendIdLink(this.mobile);
        this.sms_success = true;
      } catch (error) {
        this.$utils.notify.error(this, '문자 전송 실패!', '존재하지 않는 휴대폰 번호입니다.');
      }
    },

    setStep(step) {
      this.step = step;
    },
  },
};
</script>

<style lang="scss" scoped>
.send-sms {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex: 1;
  }

  & > button {
    width: auto;
    margin: 4px 0 4px 8px;
  }

  &__message {
    color: $link-color;
    width: 100%;
  }
}
</style>
