<template>
  <div id="app">
    <RouterView :key="$route.fullPath" />
  </div>
</template>

<script>
import appConfig from '@/app.config';

export default {
  metaInfo: { title: appConfig.title },
  created() {
    const store = this.$store;
    this.interval = setInterval(async function() {
      await store.dispatch('studio/getStudio');
    }, 1000 * 60 * 60 * 24);
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,500,700&display=swap');
@import '~normalize.css/normalize.css';
@import './assets/css/custom-element';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  color: #343a40;
  background: #fff;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select,
input,
textarea,
button {
  color: #343a40;
  font-size: 100%;
  margin: 0;
  border-style: none;
  outline: none;
}

button {
  cursor: pointer;
  background: transparent;
}

a,
a:visited,
a:focus,
a:hover {
  cursor: pointer;
  text-decoration: none;
  text-underline-position: under;
  color: $charcoal-grey;
}

a:hover {
  text-decoration: underline;
}

ol,
ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

p {
  margin: 0;
}

b {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}
</style>
