<template>
  <section>
    <div class="staff-basic__header">
      <h4>오늘의 일정</h4>
      <plain-button v-if="!!todaysLectures.length" @click="toggleShowTodaysLecturesAll">
        {{ showTodaysLecturesAll ? '한줄보기' : '모두보기' }}
      </plain-button>
    </div>
    <div v-loading="lecturesLoading" class="staff-basic__todays-lectures" ref="lectures-list">
      <BookingListItem
        v-for="lecture in todaysLectures"
        :key="lecture.id"
        :lecture="lecture"
        :instructor="staff"
        size="mini"
        clickable
        @click="handleClickBookingListItem"
      />
      <p v-if="!todaysLectures.length && !lecturesLoading">
        오늘 일정이 없습니다.
      </p>
    </div>

    <div class="staff-basic__header">
      <h4>강사 프로필</h4>
    </div>
    <div class="staff-basic__staff-profile">
      <label>자기 소개</label>
      <p>{{ introduction }}</p>
      <label>주요 이력</label>
      <ul v-if="!!careers.length">
        <li v-for="(career, index) in careers" :key="index" :class="career.career === ''?'no-text':''">
          {{ career.career === ''?'주요이력 없음':career.career }}
        </li>
      </ul>
      <p v-else>주요이력 없음</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      showTodaysLecturesAll: false,
    };
  },

  computed: {
    staff() {
      return this.$store.getters['staff/staff'];
    },
    lectures() {
      return this.$store.getters['staff/lectures'];
    },
    lecturesLoading() {
      return this.$store.getters['staff/lecturesLoading'];
    },
    todaysLectures() {
      return this.lectures.filter(({ start_on }) => {
        return this.moment(start_on).format('YYYYMMDD') === this.moment().format('YYYYMMDD');
      });
    },

    introduction() {
      return _.get(this.staff, 'profile.self_introduction') || '자기소개 없음';
    },

    careers() {
      return _.get(this.staff, 'careers') || [];
    },
  },

  methods: {
    toggleShowTodaysLecturesAll() {
      this.showTodaysLecturesAll = !this.showTodaysLecturesAll;
      if (this.showTodaysLecturesAll) {
        this.$refs['lectures-list'].style.height = 'auto';
      } else {
        this.$refs['lectures-list'].style.height = '125px';
      }
    },

    handleClickBookingListItem(lectureId) {
      const lecture = this.todaysLectures.find(({ id }) => id === lectureId);
      const courseType = _.get(lecture, 'type');
      const instructorId = _.get(lecture, 'staff.id');
      if (this.canViewLecture(courseType, instructorId)) {
        this.$router.push(`/lecture/detail?id=${lectureId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-basic__header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;

  h4 {
    font-size: 20px;
    font-weight: 500;
  }

  p {
    grid-column: 1 / 3;
    font-size: 12px;
    opacity: 0.5;
  }
}

.staff-basic__todays-lectures,
.staff-basic__staff-profile {
  margin: 15px 4px 64px;
}

.staff-basic__todays-lectures {
  display: grid;
  grid-template-columns: repeat(auto-fill, 203px);
  grid-template-rows: 120px;
  grid-gap: 8px;
  height: 125px;
  min-height: 125px;
  overflow: hidden;

  p {
    opacity: 0.6;
  }
}

.staff-basic__staff-profile {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 10px;

  label {
    font-size: 14px;
    margin: 0;
    opacity: 0.5;
  }

  ul li::before {
    content: '-';
    margin-right: 2px;
  }
  ul li.no-text::before {
    content: ''
  }
}
</style>
