<template>
  <MainLayout :padded="false">
    <ContentHeader :breadcrumb="breadcrumb" :title="title" :contentMaxWidth="1024" />
    <section class="create-private-lesson">
      <form-block :index="1" label="담당 강사" class="create-private-lesson__staff-select">
        <el-select
          v-model="instructorId"
          ref="instructorId"
          :disabled="!canCreateOthersPrivateLecture"
          filterable
          placeholder="강사 선택"
        >
          <el-option
            v-for="instructor in filteredInstructors"
            :key="instructor.id"
            :value="instructor.id"
            :label="instructor.name"
          />
        </el-select>
      </form-block>

      <form-block :index="2" label="수업 일정" flexRow>
        <div class="create-private-lesson__datetime">
          <label>수업 일자</label>
          <el-date-picker
            ref="lectureDate"
            v-model="date"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :clearable="false"
            required
          />
        </div>
        <div class="create-private-lesson__datetime">
          <hours-input
            ref="lectureTime"
            v-model="time"
            startLabel="수업 시작"
            :startHourOptions="{
              start: '00:00',
              end: '23:50',
              step: '00:05',
            }"
            endLabel="수업 종료"
            :endHourOptions="{
              start: time.start || '00:00',
              end: '23:55',
              step: '00:05',
              minTime: time.start,
            }"
            :clearable="false"
          />
        </div>
        <el-button
          class="create-private-lesson__datetime__button"
          :disabled="!time.start"
          size="small"
          type="info"
          @click="setEndTime(30)"
          >30분</el-button
        >
        <el-button
          class="create-private-lesson__datetime__button"
          :disabled="!time.start"
          size="small"
          type="info"
          @click="setEndTime(50)"
          >50분</el-button
        >
        <el-button
          class="create-private-lesson__datetime__button"
          :disabled="!time.start"
          size="small"
          type="info"
          @click="setEndTime(60)"
          >1시간</el-button
        >
      </form-block>

      <form-block :index="3" label="룸 설정" class="create-private-lesson__room">
        <el-select v-model="roomId" placeholder="룸 설정 안함" :disabled="!useRooms" filterable clearable>
          <el-option
            class="create-private-lesson__option-list"
            v-for="room in rooms"
            :key="room.id"
            :value="room.id"
            :label="room.name"
          />
        </el-select>
      </form-block>

      <form-block :index="4" label="수업 인원" class="create-private-lesson__max-trainee">
        <el-radio-group v-model="maxTrainee">
          <el-radio :label="1">개인</el-radio>
          <el-radio :label="2">듀엣</el-radio>
          <el-radio :label="3">트리플</el-radio>
        </el-radio-group>
      </form-block>

      <form-block ref="memberSelect" :index="5" label="수강회원 선택" class="create-private-lesson__member-select">
        <label>강사/일정/인원을 선택해야 수강회원 목록이 보입니다.</label>
        <TextInput
          class="create-private-lesson__member-select__input"
          v-model="searchMember"
          placeholder="회원명 검색"
          prefix-icon="el-icon-search"
          :disabled="selectedAll"
          @input="searchMember"
          round
        />

        <ul v-if="!!selectedUserTickets.length" class="create-private-lesson__member-select__list">
          <UserTicketListItem
            v-for="ticket in selectedUserTickets"
            :key="ticket.id"
            :data="ticket"
            @click="handleClickUserTicket(ticket)"
            selected
            v2Api
          />
        </ul>
        <div class="create-private-lesson__member-select__wrapper" v-loading="loading">
          <p v-if="instructorId && total === 0">예약 가능한 회원이 없습니다.</p>
          <ul v-if="!selectedAll" class="create-private-lesson__member-select__list">
            <UserTicketListItem
              v-for="userTicket in filteredUserTickets"
              :key="userTicket.id"
              :data="userTicket"
              @click="handleClickUserTicket(userTicket)"
              v2Api
            />
          </ul>

          <div v-if="!selectedAll">
            <el-pagination
              v-if="showPagination && total > 0"
              class="create-private-lesson__pagination"
              @current-change="getUserTickets"
              :current-page.sync="page"
              :page-size="limit"
              :page-sizes="[10, 15, 20, 50]"
              @size-change="limit => handleFilterChange({ limit })"
              layout="prev, pager, next, sizes"
              :total="total"
            />
          </div>
        </div>
      </form-block>

      <form-block :index="6" label="수업명" class="create-private-lesson__title">
        <TextInput v-model="lectureTitle" :placeholder="defaultLectureTitle" material />
        <p class="create-private-lesson__title__description">
          수업명을 입력하지 않으면 자동으로 회원명을 사용하여 수업이 만들어집니다.
        </p>
      </form-block>
    </section>

    <bottom-action-bar>
      <el-button v-loading="isSaving" :disabled="isSaving" @click="createLesson">수업 등록 완료</el-button>
    </bottom-action-bar>
  </MainLayout>
</template>

<script>
export default {
  data() {
    return {
      instructors: [],
      rooms: [],
      userTickets: [],
      selectedUserTickets: [],
      isSaving: false,

      /** 수업데이터 */
      lectureTitle: '',
      instructorId: null,
      roomId: null,
      startOn: this.moment()
        .startOf('hour')
        .format('YYYY-MM-DD HH:mm:ss'),
      endOn: this.moment()
        .startOf('hour')
        .add(30, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss'),
      maxTrainee: 1,
      searchMember: '',

      pagination: {
        page: 1,
        limit: 10,
      },
      total: 0,
      showPagination: false,
      loading: false,
    };
  },

  computed: {
    breadcrumb() {
      return {
        parent: { path: '/schedule', label: '일정' },
        current_route_label: this.title,
      };
    },

    title() {
      return '프라이빗 수업 만들기';
    },

    filteredInstructors() {
      if (!this.instructors.length) return [];

      if (!this.canCreateOthersPrivateLecture) {
        return this.instructors.filter(({ id }) => id === this.currentUser.id);
      } else if (!this.canCreateMyPrivateLecture) {
        return this.instructors.filter(({ id }) => id !== this.currentUser.id);
      }

      return this.instructors;
    },

    filteredUserTickets() {
      if (!this.userTickets.length) return [];

      return this.userTickets.filter(({ id, usable_coupon }) => {
        const isSelected = this.selectedUserTickets.some(ticket => ticket.id === id);
        const hasUsableCoupon = usable_coupon > 0;

        return !isSelected && hasUsableCoupon;
      });
    },

    defaultLectureTitle() {
      if (!this.selectedUserTickets.length) return '수업명';
      const name = _.get(this.selectedUserTickets[0], 'member.name');

      return this.maxTrainee === 1 ? `${name}님` : `${name} 외 ${this.maxTrainee - 1}명`;
    },

    date: {
      get() {
        return this.moment(this.startOn).format('YYYY-MM-DD');
      },
      set(date) {
        if (!date) return;
        this.startOn = `${date} ${this.time.start}:00`;
        this.endOn = `${date} ${this.time.end}:00`;
      },
    },

    time: {
      get() {
        const start = this.moment(this.startOn).format('HH:mm');
        const end = this.moment(this.endOn).format('HH:mm');
        return { start, end };
      },
      set(time) {
        if (!time.start || !time.end) return;
        this.startOn = `${this.date} ${time.start}:00`;
        this.endOn = `${this.date} ${time.end}:00`;
      },
    },

    selectedAll() {
      return this.selectedUserTickets.length >= this.maxTrainee;
    },

    useRooms() {
      return _.get(this.studioPolicies, 'is_use_rooms');
    },

    page: {
      get() {
        return this.pagination.page;
      },
      set(page) {
        this.setPagination({ page });
      },
    },

    limit: {
      get() {
        return this.pagination.limit;
      },
      set(limit) {
        this.setPagination({ limit });
      },
    },
  },

  watch: {
    date: 'handleDateChange',
    time: 'getEmptyRooms',

    searchMember: _.debounce(function() {
      this.setPagination({ page: 1 });
      this.getUserTickets();
    }, 250),

    instructorId() {
      this.setPagination({ page: 1 });
      this.getUserTickets();
    },

    maxTrainee() {
      this.searchMember = '';
      this.selectedUserTickets = [];
      this.setPagination({ page: 1 });
      this.getUserTickets();
    },

    defaultLectureTitle: function(newValue) {
      this.lectureTitle = newValue;
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      let authorized = vm.canCreateMyPrivateLecture || vm.canCreateOthersPrivateLecture;

      if (!authorized) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/schedule');
      }

      next();
    });
  },

  async created() {
    /** 강사 목록 */
    try {
      const res = await this.$api.staff.getAll();
      this.instructors = res.data.staffs.map(staff => {
        return { id: staff.id, name: staff.profile.name };
      });
    } catch (error) {
      this.instructors = [];
    }

    /** 사용하지 않는 룸 목록 */
    this.getEmptyRooms();

    /** 초기값 세팅 */
    if (this.$route.query) {
      let { instructor_id, room_id, max_trainee, start_on, end_on, copy } = this.$route.query;

      this.instructorId = parseInt(instructor_id) || null;
      this.roomId = parseInt(room_id) || null;
      this.maxTrainee = parseInt(max_trainee) || 1;

      if (start_on && end_on && !copy) {
        this.startOn = this.moment(start_on).format('YYYY-MM-DD HH:mm:ss');
        this.endOn = this.moment(end_on).format('YYYY-MM-DD HH:mm:ss');
      }

      this.$nextTick(() => {
        if (copy) {
          this.$refs.lectureDate.$el.querySelector('input').focus();
        }
      });
    }

    if (!this.canCreateOthersPrivateLecture) {
      this.instructorId = this.currentUser.id;
    }
  },

  methods: {
    setEndTime(duration) {
      const [hour, minute] = this.time.start.split(':');
      let endTime = this.moment()
        .hour(hour)
        .minute(minute)
        .second(0)
        .add(duration, 'minutes')
        .format('HH:mm:ss');

      if (endTime.slice(0, 2) === '00') endTime = '23:59:00';

      this.endOn = `${this.date} ${endTime}`;
    },

    /** 빈방 가져오기 */
    async getEmptyRooms() {
      if (!this.startOn || !this.endOn) return;

      try {
        const params = { start_date: this.startOn, end_date: this.endOn };
        const res = await this.$api.studio.rooms.getEmpty(params);
        this.rooms = res.data;
        if (this.rooms.findIndex(room => room.id == this.roomId) < 0) {
          this.roomId = null;
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    setPagination(values) {
      this.pagination = { ...this.pagination, ...values };
    },

    handleFilterChange({ limit }) {
      this.setPagination({ limit });
      this.getUserTickets();
    },

    /** 수강권 가져오기 */
    async getUserTickets() {
      if (this.instructorId !== null) {
        this.loading = true;
      }
      const { instructorId, date, maxTrainee } = this;

      if (!instructorId || !date || !maxTrainee) return;
      if (date === 'Invalid date') {
        return this.$alert('새로고침 후 이용해주세요.', '회원 가져오기 실패', {
          confirmButtonText: '확인',
        });
      }

      try {
        let params = {
          date,
          max_trainee: maxTrainee,
          staff_id: !this.canCreateOthersPrivateLecture ? instructorId : null,
          page: this.pagination.page,
          limit: this.pagination.limit,
          order_by_name: 'Y',
        };
        if (this.searchMember) {
          params = {
            ...params,
            member_name: this.searchMember,
          };
        }
        const res = await this.$api.userTicket.getPrivate(params);
        this.userTickets = res.data.data.sort((prev, next) => (prev.member.name > next.member.name ? 1 : -1));
        this.total = res.data.meta.total;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.showPagination = true;
        this.loading = false;
      }
    },

    /** 날짜 변경시 */
    handleDateChange() {
      this.getUserTickets();
      this.getEmptyRooms();
    },

    /** 수강권 클릭시 */
    handleClickUserTicket(ticket) {
      const isSeletedIds = this.selectedUserTickets.some(({ id }) => id === ticket.id);
      if (isSeletedIds) {
        this.selectedUserTickets = this.selectedUserTickets.filter(({ id }) => id !== ticket.id);
      } else {
        this.selectedUserTickets.push(ticket);
      }
    },

    validate() {
      if (!this.instructorId) {
        this.$utils.notify.error(this, '오류', '담당강사를 선택해주세요.');
        this.$refs.instructorId.$el.querySelector('input').focus();
        return false;
      } else if (!this.startOn || !this.endOn) {
        this.$utils.notify.error(this, '오류', '수업 일정을 입력해주세요.');
        if (!this.startOn) {
          this.$refs.lectureDate.$el.querySelector('input').focus();
        } else if (!this.endOn) {
          this.$refs.lectureTime.$el.querySelectorAll('input')[1].focus();
        }
        return false;
      } else if (this.selectedUserTickets.length < 1) {
        this.$utils.notify.error(this, '오류', '수강 회원을 선택해주세요.');
        this.$refs.memberSelect.$el.scrollIntoView({ behavior: 'smooth' });
        return false;
      }

      return true;
    },

    async createLesson() {
      if (!this.validate()) return;

      /** 주간 예약 제한 확인 */
      const exceedsWeeklyLimit = this.checkWeeklyLimit(this.selectedUserTickets.map(({ id }) => id).join(','));
      if (exceedsWeeklyLimit) {
        const title = '예약 가능횟수 초과';
        const message = `${exceedsWeeklyLimit}<br>계속 하시겠습니까?`;
        const proceed = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (!proceed) return;
      }

      /** 룸 설정 확인 */
      if (this.useRooms && !this.roomId) {
        const title = '룸 설정';
        const message = '룸이 설정되지 않았습니다.<br>계속 하시겠습니까?';
        const proceed = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (!proceed) return;
      }

      try {
        this.isSaving = true;
        const course = this.generateCourseData();
        const ticketIds = this.selectedUserTickets.map(({ id }) => id).join(',');
        const res = await this.$api.course.createAndBook(ticketIds, course);
        const { first_lecture_id } = res.data;
        this.$utils.notify.success(this, '성공', '수업이 생성되었습니다.');
        this.$router.push(`/lecture/detail?id=${first_lecture_id}`);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isSaving = false;
      }
    },

    checkWeeklyLimit(ids) {
      return this.userTickets.reduce((text, userTicket) => {
        const {
          id,
          member,
          booking_limit_per_week,
          booking_limit_per_month,
          ticket_week_booking_count,
          ticket_month_booking_count,
        } = userTicket;

        const isSelected = ids.includes(id);
        const isBookingLimited = booking_limit_per_week > 0 || booking_limit_per_month > 0;
        let exceedsLimit;
        if (booking_limit_per_month) exceedsLimit = booking_limit_per_month <= ticket_month_booking_count;
        if (booking_limit_per_week) exceedsLimit = booking_limit_per_week <= ticket_week_booking_count;

        if (isSelected && isBookingLimited && exceedsLimit) {
          if (booking_limit_per_month) {
            text += `월간 최대 예약 가능 횟수를 초과했습니다.<br><b>${member.name} (예약: ${ticket_month_booking_count}회 / 월간 최대 예약 가능 횟수: ${booking_limit_per_month}회)</b>`;
          } else if (booking_limit_per_week) {
            text += `주간 최대 예약 가능 횟수를 초과했습니다.<br><b>${member.name} (예약: ${ticket_week_booking_count}회 / 주간 최대 예약 가능 횟수: ${booking_limit_per_week}회)</b>`;
          }
        }
        return text;
      }, '');
    },

    generateCourseData() {
      return {
        active: 1,
        coupon_deduction: 1,
        end_date: this.date,
        instructor_id: this.instructorId,
        lectures: [
          {
            start_on: this.startOn,
            end_on: this.endOn,
          },
        ],
        max_trainee: this.maxTrainee,
        min_trainee: this.maxTrainee,
        start_date: this.date,
        title: this.lectureTitle || this.defaultLectureTitle,
        type: 'P',
        room_id: this.roomId,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.create-private-lesson {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 10px 10px 60px;

  @include mq(768px) {
    padding: 30px 30px 60px;
  }

  &__title {
    .custom-text-input {
      max-width: 200px;
    }

    &__description {
      color: $charcoal-grey;
      font-size: 12px;
      padding: 12px 0;
    }
  }

  &__staff-select,
  &__room {
    /deep/ .el-select {
      width: 100%;
      max-width: 200px;
    }
  }

  &__option-list {
    max-width: 220px;
  }

  &__datetime {
    @include flex(column);
    margin-top: 10px;

    label {
      font-size: 12px;
      font-weight: 500;
    }

    .hours-input {
      padding: 0;

      @include mq(768px) {
        padding: 0 20px;
      }
    }

    &__button {
      align-self: center;
      margin-top: 10px;
    }
  }

  &__max-trainee {
    /deep/ .el-radio + .el-radio {
      margin-left: 10px;

      @include mq(768px) {
        margin-left: 30px;
      }
    }
  }

  &__member-select {
    &__wrapper {
      max-width: 360px;

      & > p {
        padding: 12px 0;
      }
    }

    label {
      font-size: 13px;
      font-weight: 300;
      margin-bottom: 12px;
    }

    &__input {
      max-width: 300px;
    }

    &__list {
      padding: 12px 0;
      width: 100%;
      max-width: 360px;
    }
  }
}

/deep/ .bottom-action-bar-container {
  z-index: 2001 !important;
}
</style>
