<template>
  <div class="container" v-loading="loading">
    <div class="summary">
      <div class="prev-summary" v-if="prevDataTitle">
        <span class="light">{{ prevDataTitle }}</span>
        <span><span class="small">총</span> {{ prevData.count.toLocaleString() }}<span class="small">건</span></span>
        <span><span class="small">총</span> {{ prevData.amount.toLocaleString() }}<span class="small">원</span></span>
      </div>
      <SummaryNew
        type="spending"
        :summaryItems="dashBoardData"
        value="total"
        :name="filterOptions[0].name"
        v-loading="dashBoardLoading"
      />
    </div>
    <FilterNew
      :filterOptions="filterOptions"
      :refreshClick="refresh"
      downloadText="엑셀 다운로드"
      :handleDownload="downloadExcel"
    />
    <SubHeaderNew
      :count="total"
      :amount="totalAmount"
      :search="search"
      :handleSearchChange="handleSearchChange"
      :searchResult="searchResult"
      placeholder="지출내용 검색"
    />
    <ListTableNew
      type="spending"
      :tableData="tableData"
      :tableRowClick="tableRowClick"
      v-loading="tableLoading"
      :sort="sort"
      :sortChange="handleSortChange"
    />
    <el-pagination
      @current-change="fetchTableData"
      :current-page.sync="page"
      :page-size="limit"
      :pager-count="5"
      layout="prev, pager, next, sizes"
      :total="total"
      v-show="tableData.length > 0"
      :page-sizes="[10, 15, 20, 50]"
      @size-change="size => (limit = size)"
    />
    <SpendingForm
      :show="modalShow"
      :type="modalType"
      :close="closeModal"
      :spending="selectedSpending"
      :clickModify="clickModify"
      :registComplete="registComplete"
      :modifyComplete="modifyComplete"
      :deleteComplete="deleteComplete"
      :handleImageClick="handleImageClick"
    />
    <ImageView :close="closeImageView" v-if="imageShow" :imageUrl="imageUrl" />
    <FloatingActionButton @click="registModalShow" />
  </div>
</template>

<script>
import SummaryNew from '@components/Sales/SummaryNew';
import FilterNew from '@components/Sales/FilterNew';
import SubHeaderNew from '@components/Sales/SubHeaderNew';
import ListTableNew from '@components/Sales/ListTableNew';
import SpendingForm from '@components/Sales/SpendingForm';
import ImageView from '@components/Sales/ImageView';
import moment from 'moment';

export default {
  components: {
    SummaryNew,
    FilterNew,
    SubHeaderNew,
    ListTableNew,
    SpendingForm,
    ImageView,
  },

  data() {
    return {
      prevData: {
        count: 0,
        amount: 0,
      },
      dashBoardData: [
        {
          label: '합계',
          count: 0,
          amount: 0,
          name: 'total',
        },
      ],
      filterOptions: [
        {
          name: 'paymentMethod',
          value: [],
          options: [
            { label: '카드', value: 'card' },
            { label: '현금', value: 'cash' },
            { label: '계좌이체', value: 'wiretransfer' },
          ],
          onChange: this.handleSelectChange,
          placeholder: '결제 방법 전체',
          multiple: true,
        },
        {
          name: 'staff',
          value: [],
          options: [],
          onChange: this.handleSelectChange,
          multiple: true,
          placeholder: '강사 전체',
        },
      ],
      tableData: [],
      search: '',
      modalShow: false,
      modalType: 'regist', // regist, view, modify
      selectedSpending: {},
      page: 1,
      total: 0,
      totalAmount: 0,
      limit: 10,
      loading: false,
      tableLoading: false,
      dashBoardLoading: false,
      imageShow: false,
      imageUrl: '',
      sort: 'ascending',
    };
  },

  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },
    prevDataTitle() {
      this.fetchPrevData();
      if (this.date?.rangeType === 'day') return '어제';
      else if (this.date?.rangeType === 'week') return '지난 주';
      else if (this.date?.rangeType === 'month') return '지난 달';
      else return '';
    },
  },

  watch: {
    date: function() {
      this.page = 1;
      this.fetchDashBoard();
      this.fetchTableData();
      this.fetchSpendingStaff();
    },
    filterOptions: function() {
      this.fetchTableData();
      this.fetchSpendingStaff();
    },
    limit: function() {
      this.fetchTableData();
    },
    tableData: function() {
      this.fetchSpendingStaff();
    },
    sort: function() {
      this.fetchTableData();
    },
  },

  mounted() {
    this.fetchDashBoard();
    this.fetchTableData();
    this.fetchSpendingStaff();
  },

  methods: {
    refresh() {
      this.handleSelectChange('paymentMethod', []);
      this.handleSelectChange('staff', []);
    },

    handleSearchChange(value) {
      this.search = value;
    },

    searchResult() {
      this.page = 1;
      this.fetchTableData();
    },

    registModalShow() {
      this.modalType = 'regist';
      this.modalShow = true;
    },

    closeModal() {
      this.modalShow = false;
    },

    handleSelectChange(name, value) {
      const newFilterOptions = [...this.filterOptions];
      newFilterOptions.find(filter => filter.name === name).value = value;
      this.filterOptions = newFilterOptions;
      this.page = 1;
    },

    async fetchTableData() {
      const paymentMethod = this.filterOptions.find(filter => filter.name === 'paymentMethod').value;
      const staff = this.filterOptions.find(filter => filter.name === 'staff').value;

      this.tableLoading = true;

      try {
        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
          limit: this.limit,
          page: this.page,
          payment_method: paymentMethod,
          staff_id: staff,
          search: this.search,
          orderBy: this.sort === 'descending' ? 'desc' : 'asc',
        };
        const res = await this.$api.salesNew.getSpendingData(params);

        this.tableData = this.updateAmount(res.data.data);
        this.total = res.data.meta.total;
        this.totalAmount = res.data.meta.amount;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.tableLoading = false;
      }
    },

    async fetchSpendingStaff() {
      try {
        const res = await this.$api.salesNew.getStaffSpendingPeriod({
          start_date: this.date.date,
          end_date: this.date.endDate,
        });
        const staffs = res.data;
        const staffOptions = staffs.map(staff => ({
          value: staff.id,
          label: staff.name,
        }));
        this.filterOptions.find(option => option.name === 'staff').options = staffOptions;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      }
    },

    updateAmount(data) {
      const paymentMethod = this.filterOptions.find(filter => filter.name === 'paymentMethod').value;
      if (paymentMethod.length === 0) return data;
      return data.map(spending => {
        let totalAmount = 0;
        if (paymentMethod.includes('card')) {
          const cardAmount = Math.round(spending.card_amount / spending.installment_period);
          totalAmount += cardAmount;
        }
        if (paymentMethod.includes('cash')) {
          totalAmount += spending.cash_amount;
        }
        if (paymentMethod.includes('wiretransfer')) {
          totalAmount += spending.wiretransfer_amount;
        }
        return {
          ...spending,
          amount: totalAmount,
        };
      });
    },

    async fetchPrevData() {
      this.dashBoardLoading = true;
      const startDate = moment(this.date.date)
        .subtract(1, this.date.rangeType)
        .format('YYYY-MM-DD');
      const endDate =
        this.date.rangeType === 'month'
          ? moment(this.date.date)
              .subtract(1, 'day')
              .format('YYYY-MM-DD')
          : moment(this.date.endDate)
              .subtract(1, this.date.rangeType)
              .format('YYYY-MM-DD');

      const params = {
        start_date: startDate,
        end_date: endDate,
      };

      try {
        const res = await this.$api.salesNew.getSpendingDashBoard(params);

        this.prevData = res.data.total;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.dashBoardLoading = false;
      }
    },

    async fetchDashBoard() {
      this.dashBoardLoading = true;
      try {
        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
        };
        const res = await this.$api.salesNew.getSpendingDashBoard(params);

        const newDashBoardData = [...this.dashBoardData];
        newDashBoardData[0].count = res.data.total.count;
        newDashBoardData[0].amount = res.data.total.amount;

        this.dashBoardData = newDashBoardData;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.dashBoardLoading = false;
      }
    },

    tableRowClick(spending) {
      this.showDetail(spending);
    },

    showDetail(spending) {
      this.selectedSpending = spending;
      this.modalType = 'view';
      this.modalShow = true;
    },

    clickModify() {
      this.modalType = 'modify';
    },

    modifyComplete() {
      this.fetchTableData();
      this.fetchDashBoard();
      this.modalShow = false;
      this.$utils.notify.success(this, '성공', '수정이 완료되었습니다.');
    },

    registComplete() {
      this.fetchTableData();
      this.modalShow = false;
      this.fetchDashBoard();
      this.$utils.notify.success(this, '성공', '등록이 완료되었습니다.');
    },

    deleteComplete() {
      this.fetchTableData();
      this.modalShow = false;
      this.fetchDashBoard();
      this.$utils.notify.success(this, '성공', '삭제가 완료되었습니다.');
    },

    formatJSON(data) {
      if (!data || !data.length) return [];

      return data.map(item => {
        const date = moment(item.settlement_at).format('YYYY. MM. DD (dd)');
        const amount = item.amount;
        const amountInstallment = `${item.amount}(${item.card_amount + item.cash_amount + item.wiretransfer_amount})`;

        const installment = item.installment_period === 1 ? '일시불' : `할부(${item.installment_period}개월)`;
        const staff = item.staffs?.name ? item.staffs.name : '-';
        const attachment = item.attachments && item.attachments.length > 0 ? 'O' : '-';
        const arr = [];
        if (item.card_amount > 0) arr.push('카드');
        if (item.cash_amount > 0) arr.push('현금');
        if (item.wiretransfer_amount > 0) arr.push('계좌이체');
        const method = arr.join('+');

        return {
          결제일: date,
          '지출 내용': item.context,
          '지출 금액': installment === '일시불' ? amount : amountInstallment,
          '지출 방법': method === '' ? '-' : method,
          할부: installment,
          '담당 강사': staff,
          첨부파일: attachment,
        };
      });
    },

    async downloadExcel() {
      this.excelLoading = true;
      /** 데이터 가공 */
      try {
        const paymentMethod = this.filterOptions.find(filter => filter.name === 'paymentMethod').value;
        const staff = this.filterOptions.find(filter => filter.name === 'staff').value;

        const params = {
          start_date: this.date.date,
          end_date: this.date.endDate,
          limit: this.limit,
          page: this.page,
          payment_method: paymentMethod,
          staff_id: staff,
          search: this.search,
        };

        const data = await this.$utils.promiseAllData(this.$api.salesNew.getSpendingData, params);
        const json = this.formatJSON(data);
        this.$utils.downloadExcel(json, `지출_현황${params.start_date}~${params.end_date}.xlsx`);
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.excelLoading = false;
      }
    },

    handleImageClick(imgUrl) {
      if (this.modalType === 'view') {
        this.imageUrl = imgUrl;
        this.imageShow = true;
      }
    },

    closeImageView() {
      this.imageShow = false;
    },

    handleSortChange(e) {
      this.sort = e.order;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include flex(column);
  margin: auto;
  padding: 30px;
  max-width: 1440px;
  gap: 30px;

  /deep/ .el-pagination {
    @include flex(row, center, center);
  }

  .summary {
    @include flex(row);

    .prev-summary {
      @include flex(column, flex-end);
      margin-right: 16px;
      padding: 10px 13px;
      background-color: #f8f8f8;
      border: 1px solid #dcdfe6;
      border-radius: 8px;
      min-width: 150px;
      width: 150px;
      height: 102px;
      color: #545454;

      .light {
        font-size: 16px;
      }

      span:first-child {
        align-self: flex-start;
        margin-bottom: 10px;
      }

      span:nth-child(2) {
        margin-bottom: 3px;
      }

      .small {
        font-size: 12px;
      }
    }
  }
}
</style>
