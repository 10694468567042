<template>
  <div class="container" v-loading="loading">
    <div class="header-info-message">
      <div class="icon-box">
        <img src="@assets/images/icons/sales_report_info.svg" alt="info" />
      </div>
      <div class="message">
        <p>1. 카카오 알림톡을 통해 매출 리포트가 발송됩니다.</p>
        <p>2. 발송 주기를 설정하지 않으면 리포트가 발송되지 않습니다.</p>
        <p>3. 메시지 수신까지 최대 10분까지 소요될 수 있습니다.</p>
        <div class="box">
          <p>4. 다음과 같은 경우, 기타 에러로 표시되어 전송에 실패할 수 있습니다.</p>
          <span>■ 음영 지역, 잘못된 번호, 통신사 수신 거부, 일반 수신 거부, 네크워트 에러</span>
          <span>■ 수신 번호를 찾을 수 없음, 단말기 일시정지, LMS 미지원 단말기 등 기타 사유</span>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="reporter-container">
        <h3>
          리포트 대상 선택 {{ reporterData.changedReporters.length > 0 ? ` (${reporterData.changedReporters.length}/5)` : '' }}
        </h3>
        <p class="explanation">인원은 5명까지 지정할 수 있습니다.</p>
        <div class="no-reporter" v-if="reporterData.changedReporters.length === 0">
          <img src="@assets/images/icons/icon_no_search_result_140.svg" alt="info" />
          <div class="text">
            지정된 인원이 없습니다.<br />
            인원을 선택하거나 추가해 주세요.
          </div>
        </div>
        <ul class="reporters" v-else>
          <li v-for="(reporter, i) in reporterData.changedReporters" :key="i">
            <Profile
              v-if="reporter.staff_id"
              :avatar="reporter.avatar"
              :name="reporter.name"
              :subName="reporter.roles[0]"
              :mobile="reporter.mobile"
              :isReporter="true"
            />
            <Profile v-else :avatar="undefined" :name="mobile(reporter.mobile)" subName="휴대폰 번호로 추가됨" />
            <button
              class="delete"
              @click="
                () => {
                  deleteReporterByIndex(i);
                }
              "
            >
              <img src="@assets/images/icons/sales_delete_18.svg" alt="delete" />
              <span>삭제</span>
            </button>
          </li>
        </ul>
        <div class="tab">
          <button
            :class="{ active: reporterData.tab === 'list' }"
            @click="() => (reporterData.tab = 'list')"
            :disabled="reporterData.changedReporters.length === 5"
          >
            목록에서 선택
          </button>
          <button
            :class="{ active: reporterData.tab === 'add' }"
            @click="() => (reporterData.tab = 'add')"
            :disabled="reporterData.changedReporters.length === 5"
          >
            직접 추가
          </button>
        </div>
        <div class="disable-message" v-if="reporterData.changedReporters.length === 5">인원은 최대 5명까지 지정할 수 있어요.</div>
        <div class="list-select-container" v-if="reporterData.tab === 'list' && reporterData.changedReporters.length !== 5">
          <TextInput
            prefix-icon="el-icon-search"
            placeholder="이름, 휴대폰 번호로 검색"
            class="staff-search-input"
            :value="reporterData.search"
            @input="
              v => {
                reporterData.search = v;
              }
            "
            @keyup.native.enter="
              () => {
                reporterData.page = 1;
                fetchStaffs();
              }
            "
          />
          <div class="no-result" v-if="reporterData.staffs.length === 0 && reporterData.lastSearchValue.length > 0">
            <img src="/img/icons/icon_nomember.png" alt="no member" />
            <h3>"{{ reporterData.lastSearchValue }}"</h3>
            <p>에 대한 검색 결과가 없습니다.</p>
            <span>회원명, 전화번호를 확인해주세요!</span>
          </div>
          <ul class="staff-list">
            <li v-for="staff in reporterData.staffs" :key="staff.id">
              <el-checkbox
                :value="staffChecked(staff)"
                @change="
                  v => {
                    handleStaffCheckboxChange(v, staff.id);
                  }
                "
                :disabled="!staff.contact_infos || staff.contact_infos.length === 0"
              />
              <Profile
                :avatar="staff.avatars.length > 0 ? staff.avatars[0].image : undefined"
                :name="staff.name"
                :subName="staff.roles.length > 0 ? staff.roles[0].display_name : ''"
                :mobile="staff.contact_infos.length > 0 ? staff.contact_infos[0].contact : '전화번호 없음'"
                :isReporter="false"
              />
            </li>
          </ul>
          <el-pagination
            @current-change="fetchStaffs"
            :current-page.sync="reporterData.page"
            :page-size="reporterData.limit"
            :pager-count="5"
            layout="prev, pager, next"
            :total="reporterData.total"
            v-show="reporterData.staffs.length > 0"
          />
        </div>
        <div class="add-number" v-else-if="reporterData.tab === 'add' && reporterData.changedReporters.length !== 5">
          <div class="top">
            <img src="@assets/images/icons/sales_add_number.svg" alt="add" />
            <div class="text">
              직접 휴대폰 번호를 입력해<br />
              인원을 추가할 수 있습니다.
            </div>
          </div>
          <PhoneNumberInput
            :value="reporterData.mobile"
            @input="v => (reporterData.mobile = v)"
            placeholder="휴대폰 번호를 입력해 주세요"
            :maxLengthProps="11"
          />
          <button :disabled="reporterData.mobile.length < 8" @click="addReporterByMobile">
            추가하기
          </button>
        </div>
      </div>
      <div class="options-container">
        <h3>리포트 항목 선택</h3>
        <section class="group">
          <h4>매출</h4>
          <div class="line">
            <el-checkbox v-model="optionsData.payment_select_all" @change="v => selectAll(v, 'payment')">모두 선택</el-checkbox>
          </div>
          <div class="line space-between">
            <div class="flex1">
              <el-checkbox v-model="optionsData.send_report_payment_new" @change="() => checkSelectAll('payment')"
                >신규 결제</el-checkbox
              >
            </div>
            <div class="flex1">
              <el-checkbox v-model="optionsData.send_report_payment_re" @change="() => checkSelectAll('payment')"
                >재결제</el-checkbox
              >
            </div>
          </div>
          <div class="line space-between">
            <div class="flex1">
              <el-checkbox v-model="optionsData.send_report_payment_upgrade" @change="() => checkSelectAll('payment')"
                >업그레이드</el-checkbox
              >
            </div>
            <div class="flex1">
              <el-checkbox v-model="optionsData.send_report_payment_transfer" @change="() => checkSelectAll('payment')"
                >양도</el-checkbox
              >
            </div>
          </div>
          <div class="line space-between">
            <div class="flex1">
              <el-checkbox v-model="optionsData.send_report_payment_experience" @change="() => checkSelectAll('payment')"
                >체험</el-checkbox
              >
            </div>
            <div class="flex1">
              <el-checkbox v-model="optionsData.send_report_payment_refund" @change="() => checkSelectAll('payment')"
                >환불</el-checkbox
              >
            </div>
          </div>
        </section>
        <section class="group">
          <h4>회원</h4>
          <div class="line">
            <el-checkbox v-model="optionsData.user_select_all" @change="v => selectAll(v, 'user')">모두 선택</el-checkbox>
          </div>
          <div class="line space-between">
            <div class="flex1">
              <el-checkbox v-model="optionsData.send_report_user_available" @change="() => checkSelectAll('user')"
                >유효 회원 수</el-checkbox
              >
            </div>
            <div class="flex1">
              <el-checkbox v-model="optionsData.send_report_user_new" @change="() => checkSelectAll('user')"
                >신규 회원 수</el-checkbox
              >
            </div>
          </div>
          <div class="line space-between">
            <div class="flex1">
              <el-checkbox v-model="optionsData.send_report_user_re" @change="() => checkSelectAll('user')"
                >재등록 회원 수</el-checkbox
              >
            </div>
            <div class="flex1">
              <el-checkbox
                v-model="optionsData.send_report_user_expire"
                @change="
                  v => {
                    changeExpire(v);
                    checkSelectAll('user');
                  }
                "
                >만료 예정 회원 수</el-checkbox
              >
            </div>
          </div>
          <div class="line">
            <el-checkbox
              v-model="optionsData.use_send_report_user_expire_count"
              @change="
                v => {
                  checkExpire(v);
                  checkSelectAll('user');
                }
              "
              >잔여 횟수가</el-checkbox
            >
            <NumberInput
              :min="1"
              :max="99"
              v-model="optionsData.send_report_user_expire_count"
              :disabled="!optionsData.use_send_report_user_expire_count"
            />회 남은 회원
          </div>
          <div class="line">
            <el-checkbox
              v-model="optionsData.use_send_report_user_expire_date"
              @change="
                v => {
                  checkExpire(v);
                  checkSelectAll('user');
                }
              "
              >잔여 기간이</el-checkbox
            >
            <NumberInput
              :min="1"
              :max="99"
              v-model="optionsData.send_report_user_expire_date"
              :disabled="!optionsData.use_send_report_user_expire_date"
            />일 남은 회원
          </div>
        </section>
        <section class="group">
          <h4>일정</h4>
          <div class="line">
            <el-checkbox v-model="optionsData.schedule_select_all" @change="v => selectAll(v, 'schedule')">모두 선택</el-checkbox>
          </div>
          <div class="line">
            <el-checkbox v-model="optionsData.send_report_schedule_group" @change="() => checkSelectAll('schedule')"
              >그룹 수업</el-checkbox
            >
          </div>
          <div class="line">
            <el-checkbox v-model="optionsData.send_report_schedule_private" @change="() => checkSelectAll('schedule')"
              >프라이빗 수업</el-checkbox
            >
          </div>
        </section>
        <section class="group">
          <h4>발송 주기</h4>
          <p>리포트 발송을 위해 꼭 발송 주기를 설정해 주세요!</p>
          <div class="line">
            <el-checkbox v-model="optionsData.use_send_report_daily"> 일일 리포트를 매일</el-checkbox>
            <el-time-select
              value-format="HH:mm"
              :placeholder="this.sendReportDailyRandomTime"
              :clearable="false"
              :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }"
              v-model="optionsData.send_report_daily_time"
              :disabled="!optionsData.use_send_report_daily"
            />
            에 발송합니다.
          </div>
          <div class="line">
            <el-checkbox v-model="optionsData.use_send_report_weekly"> 주간 리포트를 매주 월요일</el-checkbox>
            <el-time-select
              format="HH:mm"
              value-format="HH:mm"
              :placeholder="this.sendReportWeeklyRandomTime"
              :clearable="false"
              :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }"
              v-model="optionsData.send_report_weekly_time"
              :disabled="!optionsData.use_send_report_weekly"
            />
            에 발송합니다.
          </div>
          <div class="line">
            <el-checkbox v-model="optionsData.use_send_report_monthly">월간 리포트를 매월 1일</el-checkbox>
            <el-time-select
              format="HH:mm"
              value-format="HH:mm"
              :placeholder="this.sendReportMonthlyRandomTime"
              :clearable="false"
              :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }"
              v-model="optionsData.send_report_monthly_time"
              :disabled="!optionsData.use_send_report_monthly"
            />
            에 발송합니다.
          </div>
        </section>
      </div>
      <div class="preview-container">
        <h3>리포트 미리 보기</h3>
        <p class="explanation">
          해당 이미지는 이해를 돕기 위한 미리보기 입니다. <br />표시된 금액, 인원, 수업 건수는 실제 데이터와 상이합니다.
        </p>
        <div class="preview" v-html="previewText"></div>
      </div>
    </div>

    <div class="footer">
      <button @click="save">저장 완료</button>
    </div>
  </div>
</template>

<script>
import Profile from '@/components/Sales/Profile';
export default {
  components: {
    Profile,
  },
  data() {
    return {
      loading: false,
      reporterData: {
        originalReporters: [],
        changedReporters: [],
        tab: 'list',
        staffs: [],
        search: '',
        limit: 10,
        page: 1,
        total: 1,
        lastSearchValue: '',
        mobile: '',
      },
      optionsData: {
        payment_select_all: false,
        send_report_payment_new: false,
        send_report_payment_re: false,
        send_report_payment_experience: false,
        send_report_payment_upgrade: false,
        send_report_payment_transfer: false,
        send_report_payment_refund: false,

        user_select_all: false,
        send_report_user_available: false,
        send_report_user_new: false,
        send_report_user_re: false,
        send_report_user_expire: false,
        use_send_report_user_expire_count: false,
        use_send_report_user_expire_date: false,
        send_report_user_expire_count: 3,
        send_report_user_expire_date: 3,

        schedule_select_all: false,
        send_report_schedule_group: false,
        send_report_schedule_private: false,
        use_send_report_daily: false,
        use_send_report_weekly: false,
        use_send_report_monthly: false,
        send_report_daily_time: null,
        send_report_weekly_time: null,
        send_report_monthly_time: null,
      },
    };
  },

  mounted() {
    this.fetchStaffs();
    this.fetchPolicy();
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },

    previewText() {
      return `[스튜디오메이트] ${this.studio.name}<br/>
          20XX. X. XX (화) [일간] 매출 리포트 입니다.
          <br/>
          <br/>
          [매출] ${this.previewUseCheck('payment') ? '' : '<br/>・ 설정안함'}<br/>
          ${this.previewUseCheck('payment') ? '매출 합계: 3,500,000원<br/>' : ''}
          ${this.previewUseCheck('payment') ? '<span class="light">(전일 매출: 2,700,000원)</span><br /><br />' : ''}

          ${this.optionsData.send_report_payment_new ? '・ 신규: 123,000원<br/>' : ''}
          ${this.optionsData.send_report_payment_re ? '・ 재결제: 150,000원<br/>' : ''}
          ${this.optionsData.send_report_payment_upgrade ? '・ 업그레이드: 100,000원<br/>' : ''}
          ${this.optionsData.send_report_payment_transfer ? '・ 양도: 50,000원<br/>' : ''}
          ${this.optionsData.send_report_payment_experience ? '・ 체험: 50,000원<br/>' : ''}
          ${this.optionsData.send_report_payment_refund ? '・ 환불: 99,000원<br/>' : ''}
          ${this.optionsData.send_report_payment_refund ? '・ 환불 위약금: 99,000원<br/>' : ''}


          <br />
          [회원] ${this.previewUseCheck('user') ? '' : '<br/>・ 설정안함'}<br/>
          ${this.optionsData.send_report_user_available ? '・ 유효 회원: 999명<br/>' : ''}
          ${this.optionsData.send_report_user_new ? '・ 신규 등록: 999명<br/>' : ''}
          ${this.optionsData.send_report_user_re ? '・ 재등록: 999명<br/>' : ''}
          ${this.optionsData.send_report_user_expire ? '・ 만료 예정<br/>' : ''}
          ${
            this.optionsData.use_send_report_user_expire_count
              ? `<span class="tab">- 잔여 횟수 ${this.optionsData.send_report_user_expire_count}회 미만: 90명</span><br/>`
              : ''
          }
          ${
            this.optionsData.use_send_report_user_expire_date
              ? `<span class="tab">- 잔여 기간 ${this.optionsData.send_report_user_expire_date}일 미만: 90명</span><br/>`
              : ''
          }

          <br/>
          [일정] ${this.previewUseCheck('schedule') ? '' : '<br/>・ 설정안함'}<br/>
          ${this.previewUseCheck('schedule') ? '・ 전체: 999건 (999명)<br />' : ''}
          ${this.optionsData.send_report_schedule_group ? '・ 그룹: 99건 (99명)<br />' : ''}
          ${this.optionsData.send_report_schedule_private ? '・ 프라이빗: 99건 (99명)<br />' : ''}
          `;
    },

    sendReportDailyRandomTime() {
      return this.getRandomSendingDefaultTime();
    },

    sendReportWeeklyRandomTime() {
      return this.getRandomSendingDefaultTime();
    },

    sendReportMonthlyRandomTime() {
      return this.getRandomSendingDefaultTime();
    },

    checkNullableSendTime() {
      const {
        use_send_report_daily,
        use_send_report_weekly,
        use_send_report_monthly,
        send_report_daily_time,
        send_report_weekly_time,
        send_report_monthly_time,
      } = this.optionsData;
      const targetTimeArray = [];

      if (use_send_report_daily && !send_report_daily_time) targetTimeArray.push('일일');
      if (use_send_report_weekly && !send_report_weekly_time) targetTimeArray.push('주간');
      if (use_send_report_monthly && !send_report_monthly_time) targetTimeArray.push('월간');

      return targetTimeArray;
    },
  },

  methods: {
    mobile(value) {
      return this.$filters.mobile(value);
    },

    previewUseCheck(type) {
      const keys = Object.keys(this.optionsData).filter(
        key => key.includes(type) && !key.includes('all') && this.optionsData[key] === true,
      );
      if (keys.length > 0) return true;
      return false;
    },

    async fetchStaffs() {
      this.loading = true;
      try {
        const params = {
          per_page: this.reporterData.limit,
          page: this.reporterData.page,
          search: this.reporterData.search,
        };
        const res = await this.$api.staff.getAll(params);
        const staffs = res?.data?.staffs?.data ? res.data.staffs?.data : [];
        this.reporterData.staffs = staffs;
        this.reporterData.total = res?.data?.staffs?.total ? res?.data?.staffs?.total : 0;
        this.reporterData.lastSearchValue = this.reporterData.search;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.loading = false;
      }
    },

    async fetchPolicy() {
      this.loading = true;
      try {
        const res = await this.$api.salesNew.getPolicy();
        this.changePolicyState(res);
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.loading = false;
      }
    },

    getRandomSendingDefaultTime() {
      const randomTimeValues = ['08:00', '09:00', '10:00', '11:00', '12:00'];
      return randomTimeValues[Math.floor(Math.random() * randomTimeValues.length)];
    },

    changePolicyState(res) {
      const options = res.data['report-policy'];
      const reporters = res.data.reporter;
      delete options.created_at;
      delete options.deleted_at;
      delete options.id;
      delete options.studio_id;
      delete options.updated_at;

      options.send_report_daily_time = !options.use_send_report_daily
        ? this.sendReportDailyRandomTime
        : this.hourToString(options.send_report_daily_time);
      options.send_report_weekly_time = !options.use_send_report_weekly
        ? this.sendReportWeeklyRandomTime
        : this.hourToString(options.send_report_weekly_time);
      options.send_report_monthly_time = !options.use_send_report_monthly
        ? this.sendReportMonthlyRandomTime
        : this.hourToString(options.send_report_monthly_time);

      this.optionsData = { ...this.optionsData, ...options };
      this.checkExpire(options.use_send_report_user_expire_count || options.use_send_report_user_expire_date);
      this.checkSelectAll('payment');
      this.checkSelectAll('user');
      this.checkSelectAll('schedule');
      this.reporterData = {
        ...this.reporterData,
        originalReporters: reporters,
        changedReporters: reporters,
      };
    },

    handleStaffCheckboxChange(v, staffId) {
      if (v) {
        this.addReporterByStaffId(staffId);
      } else {
        this.deleteReporterByStaffId(staffId);
      }
    },

    addReporterByStaffId(staffId) {
      if (this.reporterData.changedReporters.length === 5) return;
      const staff = this.reporterData.staffs.find(staff => staff.id === staffId);
      const newReporter = {
        id: null,
        staff_id: staff.id,
        avatar: staff.avatars?.[0]?.image,
        name: staff.name,
        roles: [staff.roles[0].display_name],
        mobile: staff.contact_infos[0].contact,
      };
      if (staff) {
        const newChangedReporters = [...this.reporterData.changedReporters, newReporter];
        this.reporterData = { ...this.reporterData, changedReporters: newChangedReporters };
      }
    },

    addReporterByMobile() {
      const mobile = this.reporterData.mobile;
      const isMobile = /^\d+$/.test(mobile);
      if (!isMobile) {
        this.$utils.notify.error(this, '에러', '올바른 휴대폰 번호를 입력해주세요.');
        return;
      }
      const newReporter = {
        id: null,
        staff_id: null,
        mobile: this.reporterData.mobile,
        avatar: undefined,
      };
      const newChangedReporters = [...this.reporterData.changedReporters, newReporter];
      this.reporterData = { ...this.reporterData, changedReporters: newChangedReporters };
      this.reporterData.mobile = '';
    },

    deleteReporterByIndex(index) {
      const newChangedReporters = this.reporterData.changedReporters.filter((reporter, i) => i !== index);
      this.reporterData = { ...this.reporterData, changedReporters: newChangedReporters };
    },

    deleteReporterByStaffId(staffId) {
      const newChangedReporters = this.reporterData.changedReporters.filter(reporter => reporter.staff_id !== staffId);
      this.reporterData = { ...this.reporterData, changedReporters: newChangedReporters };
    },

    async save() {
      /** 발송 주기 시간 null 체크 */
      if (this.checkNullableSendTime.length) {
        const message = `${this.checkNullableSendTime.join(', ')} 리포트의 시간을 선택해주세요.`;
        this.$utils.notify.error(this, '오류', message);

        return;
      }

      this.loading = true;
      const shouldAddReporters = this.reporterData.changedReporters.filter(reporter => {
        if (reporter.staff_id) {
          return !this.reporterData.originalReporters.find(originalReporter => originalReporter.staff_id === reporter.staff_id);
        } else {
          return !this.reporterData.originalReporters.find(
            originalReporter => !originalReporter.staff_id && originalReporter.mobile === reporter.mobile,
          );
        }
      });
      const shouldDeleteReporters = this.reporterData.originalReporters.filter(reporter => {
        if (reporter.staff_id) {
          return !this.reporterData.changedReporters.find(originalReporter => originalReporter.staff_id === reporter.staff_id);
        } else {
          return !this.reporterData.changedReporters.find(
            originalReporter => !originalReporter.staff_id && originalReporter.mobile === reporter.mobile,
          );
        }
      });

      const policy = {
        ...this.optionsData,
        send_report_daily_time: this.stringToHour(
          this.optionsData.use_send_report_daily ? this.optionsData.send_report_daily_time : this.sendReportDailyRandomTime,
        ),
        send_report_weekly_time: this.stringToHour(
          this.optionsData.use_send_report_weekly ? this.optionsData.send_report_weekly_time : this.sendReportWeeklyRandomTime,
        ),
        send_report_monthly_time: this.stringToHour(
          this.optionsData.use_send_report_monthly ? this.optionsData.send_report_monthly_time : this.sendReportMonthlyRandomTime,
        ),
      };
      policy.send_report_user_expire = undefined;
      policy.user_select_all = undefined;
      policy.payment_select_all = undefined;
      policy.schedule_select_all = undefined;

      const staffs = shouldAddReporters.filter(reporter => reporter.staff_id).map(reporter => reporter.staff_id);
      const mobiles = shouldAddReporters.filter(reporter => !reporter.staff_id).map(reporter => reporter.mobile);
      const deleteReporters = shouldDeleteReporters.map(reporter => reporter.id);

      const data = {
        'report-policy': policy,
        staff: staffs,
        mobile: mobiles,
        'delete-user': deleteReporters,
      };

      try {
        const res = await this.$api.salesNew.updatePolicy(data);
        this.changePolicyState(res);
        this.$utils.notify.success(this, '', '저장 완료');
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.loading = false;
      }
    },

    selectAll(v, type) {
      const keys = Object.keys(this.optionsData).filter(
        key => key.includes(type) && !key.includes('all') && this.optionsData[key] === !v,
      );

      const newOptionsData = { ...this.optionsData };
      keys.forEach(key => {
        newOptionsData[key] = v;
      });
      this.optionsData = newOptionsData;
    },

    checkSelectAll(type) {
      const keys = Object.keys(this.optionsData).filter(key => key.includes(type) && !key.includes('all'));
      const selectAllKey = Object.keys(this.optionsData).find(key => key.includes(type) && key.includes('all'));
      if (selectAllKey) {
        if (keys.some(key => this.optionsData[key] === false)) {
          this.optionsData[selectAllKey] = false;
        } else {
          this.optionsData[selectAllKey] = true;
        }
      }
    },

    changeExpire(v) {
      const newOptionsData = { ...this.optionsData };
      if (v) {
        newOptionsData.use_send_report_user_expire_count = true;
        newOptionsData.use_send_report_user_expire_date = true;
      } else {
        newOptionsData.use_send_report_user_expire_count = false;
        newOptionsData.use_send_report_user_expire_date = false;
      }
      this.optionsData = newOptionsData;
    },

    checkExpire(v) {
      const newOptionsData = { ...this.optionsData };
      if (v) {
        newOptionsData.send_report_user_expire = true;
      } else {
        if (!newOptionsData.use_send_report_user_expire_count && !newOptionsData.use_send_report_user_expire_date) {
          newOptionsData.send_report_user_expire = false;
        }
      }
      this.optionsData = newOptionsData;
    },

    staffChecked(staff) {
      return !!this.reporterData.changedReporters.find(reporter => reporter.staff_id === staff.id);
    },

    hourToString(hour) {
      const hourString = hour < 10 ? `0${hour}` : `${hour}`;
      return `${hourString}:00`;
    },

    stringToHour(str) {
      return parseInt(str.split(':')[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include flex(column);
  margin: auto;
  padding: 30px 30px 90px 30px;
  max-width: 1440px;
  color: #545454;

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #64aeff;
    border-color: #64aeff;
  }

  .disable-message {
    font-size: 12px;
    color: #b00;
    text-align: center;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
  }

  p.explanation {
    font-size: 14px;
  }

  .header-info-message {
    @include flex(row);
    border-radius: 8px;
    border: solid 1px #dcdfe6;
    background-color: #f8f8f8;
    overflow: hidden;
    margin-bottom: 30px;

    .icon-box {
      background-color: #556fa0;
      width: 40px;
      padding: 8px;
    }
    .message {
      @include flex(column);
      justify-content: center;
      padding: 14px;
      gap: 8px;
      font-size: 16px;

      p {
        color: #393939;
        font-weight: 500;
      }

      .box {
        @include flex(column);
        span {
          font-size: 14px;
          color: #777;
        }
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 90px;

    .reporter-container {
      width: 280px;
      p {
        margin-bottom: 15px;
      }
      .no-reporter {
        display: flex;
        align-items: center;

        .text {
          font-size: 14px;
          font-weight: 500;
          color: #808080;
        }
      }

      .reporters {
        width: 100%;

        li {
          display: flex;
          justify-content: space-between;
          padding: 15px 0;
          width: 100%;

          button {
            display: flex;
            align-items: center;
            color: #ff8b8b;
            font-size: 12px;
            min-width: 53px;
          }
        }
      }

      .tab {
        display: flex;
        gap: 17px;
        margin-bottom: 15px;
        margin-top: 15px;

        button {
          height: 40px;
          border-radius: 20px;
          border: solid 1px #dcdfe6;
          font-weight: 500;
          flex: 1;
          color: #545454;

          &.active {
            color: white;
            background-color: $charcoal-grey;
          }

          &:disabled {
            color: #dcdfe6;
            cursor: default;
            background-color: white;
          }
        }
      }

      .list-select-container {
        @include flex(column, center);
        .staff-search-input {
          margin-bottom: 20px;

          /deep/ input {
            border-radius: 8px;
            border: solid 1px rgba(0, 0, 0, 0.3);
            font-size: 12px;

            &::placeholder {
              color: rgba(0, 0, 0, 0.3);
              font-weight: 300;
            }
          }
        }

        .no-result {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px;

          img {
            margin-bottom: 3px;
          }
          h3 {
            font-size: 14px;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: center;
          }
          p {
            font-size: 14px;
            color: #666;
            margin-bottom: 15px;
          }
          span {
            font-size: 12px;
          }
        }

        .staff-list {
          width: 100%;
          margin-bottom: 30px;

          li {
            display: flex;
            align-items: center;
            padding: 15px 0;

            & > *:first-child {
              margin-right: 10px;
            }

            &:not(:last-child) {
              border-bottom: 1px solid #eee;
            }
          }
        }
      }

      .add-number {
        .top {
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-weight: 500;
          color: #808080;
          margin-bottom: 20px;
        }

        /deep/ input {
          &::placeholder {
            font-size: 14px;
            color: #b5b5b5;
          }
        }

        button {
          margin-top: 20px;
          height: 36px;
          width: 100%;
          border-radius: 4px;
          border: solid 1px $dodger-blue;
          color: $dodger-blue;
          font-weight: 500;

          &:disabled {
            background-color: #b5b5b5;
            color: white;
            border: none;
            cursor: default;
          }
        }
      }
    }
    .options-container {
      section.group {
        padding: 15px 0;
        @include flex(column);
        gap: 10px;

        p {
          font-size: 14px;
          color: #556fa0;
        }

        .line {
          display: flex;
          align-items: center;
          font-weight: normal;
          gap: 10px;
          font-size: 16px;

          &.space-between {
            justify-content: space-between;
            & > div {
              flex: 1;
            }
          }

          /deep/ .el-checkbox__label {
            font-weight: normal;
            color: #545454;
            font-size: 16px;
          }

          /deep/ .custom-number-input {
            width: 100px;
          }

          /deep/ .el-date-editor {
            width: 90px;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }
      }
      .custom-time-input {
        width: 60px;

        input {
          padding-left: 28px;
          &:disabled {
            color: #c0c4cc;
          }
        }
      }
    }
    .preview-container {
      width: 300px;

      .preview {
        font-size: 16px;
        padding: 16px;
        background-color: #f8f8f8;
        border-radius: 16px;
        margin-top: 20px;

        /deep/ .tab {
          margin-left: 10px;
        }
      }
    }
  }

  .footer {
    position: fixed;
    left: 56px;
    right: 0;
    bottom: 0;
    height: 60px;
    background-color: #64aeff;
    z-index: $z-index-header;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 52px;

    button {
      width: 130px;
      height: 36px;
      border-radius: 2px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.09);
      background-color: white;
      font-weight: 500;
      color: #606266;
    }
  }
}
</style>
