<template>
  <div class="deadline-settings-helper">
    <h4>1. 수업시간 기준 (예약/취소 동일)</h4>
    <div class="deadline-settings-helper__block">
      <h5>예약 마감시간 설정</h5>
      <p>: <b>수업시작 시간 기준</b>으로 <b>N시간 전까지</b>만 예약/취소 가능하도록 설정합니다.</p>
      <p>예시) <el-tag size="mini">수업시간 기준 - 마감시간</el-tag> 선택 후 <b>6시간 전까지</b>로 설정시</p>
      <p>
        ⟶ 2019-07-08 (월) 17시 수업의 경우, 수업 당일 11시까지만 예약/취소 가능합니다.
      </p>
    </div>
    <div class="deadline-settings-helper__block">
      <h5>예약 오픈-마감시간 설정</h5>
      <p>: <b>수업시작 시간 기준</b>으로 <b>N시간 전부터 N시간 전까지</b>만 예약/취소 가능하도록 설정합니다.</p>
      <p>
        예시)
        <el-tag size="mini">수업시간 기준 - 시작시간부터 마감시간까지</el-tag>
        선택 후 <b>1시간 전부터 30분 전까지</b>로 설정시
      </p>
      <p>
        ⟶ 2019-07-08 (월) 17시 수업의 경우, 수업 당일 16시부터 16시 30분까지만 예약/취소 가능합니다.
      </p>
    </div>
    <h4>2. 특정시간 기준 (예약/취소 동일)</h4>
    <div class="deadline-settings-helper__block">
      <h5>예약 마감시간 설정</h5>
      <p>: <b>수업일 기준</b>으로 <b>N시까지</b>만 예약/취소 가능하도록 설정합니다.</p>
      <p>예시) <el-tag size="mini">수업일 기준 - 마감시간</el-tag> 선택 후 <b>1일 전 22시까지</b>로 설정시</p>
      <p>
        ⟶ 2019-07-08 (월) 수업의 경우, 2019-07-07 (일) 22시까지만 예약/취소 가능합니다.
      </p>
    </div>
    <div class="deadline-settings-helper__block">
      <h5>예약 오픈-마감시간 설정</h5>
      <p>: <b>수업일 기준</b>으로 <b>N시부터 N시까지</b>만 예약/취소 가능하도록 설정합니다.</p>
      <p>
        예시)
        <el-tag size="mini">수업일 기준 - 시작시간부터 마감시간까지</el-tag>
        선택 후 <b>3일 전 9시부터 1일 전 22시까지</b>로 설정시
      </p>
      <p>
        ⟶ 2019-07-08 (월) 수업의 경우, 2019-07-05 (금) 9시부터 2019-07-07 (일) 22시까지만 예약/취소 가능합니다.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.deadline-settings-helper {
  padding: 20px;
  line-height: 2;

  &__block {
    margin-bottom: 20px;
  }

  h5 {
    padding-left: 1em;

    &::before {
      content: '•';
      margin-right: 0.5em;
    }
  }

  p {
    padding-left: 2em;

    &:last-child {
      padding-left: 4em;
    }
  }
}
</style>
