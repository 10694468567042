<template>
  <ul>
    <div style="display: flex">
      <li
        v-for="tab in tabs"
        :key="tab.value"
        :class="{
          active: handleCheckActiveTab(tab),
          disabled: tab.disabled,
        }"
        :style="active_tab === tab.value && active_tab_style"
        @click="changeTab(tab)"
      >
        {{ tab.label }}
        <img v-if="tab.label === '스튜디오메이트 공지' && isNewNoti" src="@/assets/images/notification.png" />
      </li>
    </div>

    <div v-if="infoMessage" style="display: flex">
      <img src="@/assets/images/icons/info-icon.svg" alt="정보" style="margin-right: 5px;" />
      <span>{{ infoMessage }}</span>
    </div>
  </ul>
</template>

<script>
export default {
  props: {
    active_tab: String,
    active_tab_color: String,
    tabs: Array,
    handleChangeTab: Function,
    infoMessage: String,
  },

  computed: {
    active_tab_style() {
      if (!this.active_tab_color) return null;

      return {
        borderBottomColor: this.active_tab_color,
        color: this.active_tab_color,
      };
    },
    isNewNoti() {
      return this.$store.getters['boardNotice/isNewNotice'];
    },
  },
  methods: {
    changeTab(tab) {
      if (tab.disabled) return;
      this.handleChangeTab(tab.value);
    },

    handleCheckActiveTab(tab) {
      if (this.active_tab === tab.value) return true;
      return this.active_tab === tab.value.replace('sms-', '');
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  @include flex(row, center, center);
  &.messages__tab {
    justify-content: space-between;
  }
  li {
    border-bottom: 2px solid transparent;
    cursor: pointer;
    font-weight: 400;
    padding: 16px 8px;
    opacity: 0.5;
    transition: 0.15s;
    position: relative;

    &:hover {
      border-bottom-color: $color-primary;
      opacity: 0.7;
    }

    &.active {
      border-bottom-color: $color-primary;
      color: $color-primary;
      font-weight: 500;
      opacity: 1;
    }
    &.disabled {
      cursor: not-allowed;
      &:hover {
        border-bottom-color: $gray-200;
        opacity: 0.5;
      }
    }
    img {
      position: absolute;
      right: -8px;
      top: 12px;
    }
  }
}
</style>
