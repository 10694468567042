import getPaymentMethod from './getPaymentMethod';

/** 회원 수강권 발급을 위한 데이터 생성 */
export default (data, user_id) => {
  const { selectedTicket, ticketDetail, paymentDetail } = data;
  const method = getPaymentMethod(paymentDetail) || 'mixed';

  return {
    user_id,
    ticket_id: selectedTicket.id,
    ...ticketDetail,
    ...paymentDetail,
    method,
    installment_period: paymentDetail.installment || paymentDetail.installment_period,
  };
};
