<template>
  <el-dialog
    id="change-course-end-date-dialog"
    title="클래스 종료일 변경"
    :visible="show"
    @close="handleClickCancel"
    append-to-body
  >
    <el-radio-group v-model="changeType">
      <el-tooltip effect="light" placement="left">
        <p slot="content">선택한 날짜부터 수업을 폐강합니다.</p>
        <el-radio-button label="CLOSE_COURSE" :disabled="!canCloseCourse">폐강</el-radio-button>
      </el-tooltip>
      <el-tooltip effect="light" placement="right">
        <p slot="content">선택한 날짜까지 수업을 추가로 생성합니다.</p>
        <el-radio-button label="EXTEND_COURSE" :disabled="!canExtendCourse">연장</el-radio-button>
      </el-tooltip>
    </el-radio-group>

    <div class="change-course-end-date-dialog__dates">
      <div>
        <label>현재 종료일</label>
        <el-date-picker v-model="courseEndOn" format="yyyy. M. d." value-format="yyyy-MM-dd" readonly />
      </div>

      <img src="@assets/images/icons/arrow-right.svg" alt="arrow-right" />

      <div>
        <label>{{ changeType === 'CLOSE_COURSE' ? '폐강 시작일' : '새로운 종료일' }}</label>
        <el-date-picker
          v-model="date"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          :clearable="false"
        />
      </div>
    </div>

    <p class="change-course-end-date-dialog__message" v-if="changeType === 'CLOSE_COURSE'">
      <b>{{ date | date }}</b> 부터 수업을 <span class="close-course"><b>폐강</b></span> 처리합니다.
    </p>

    <p class="change-course-end-date-dialog__message" v-else-if="changeType === 'EXTEND_COURSE'">
      <b>{{ date | date }}</b> 까지 기간을 <span class="extend-course"><b>연장</b></span
      >하여 수업을 생성합니다.
    </p>

    <div slot="footer" class="change-course-end-date-dialog__buttons">
      <el-button @click="handleClickCancel">취소</el-button>
      <el-button @click="handleClickConfirm" type="primary" :disabled="!canCloseCourse && !canExtendCourse"
        >변경</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    courseEndOn: String,
    courseStartOn: String,
    courseType: { type: String, default: 'G' },
    instructorId: { type: [String, Number] },
    canCloseCourse: { type: Boolean, default: false },
    canExtendCourse: { type: Boolean, default: false },
    defaultChangeType: { type: String, default: 'CLOSE_COURSE' },
  },

  data() {
    return {
      changeType: 'CLOSE_COURSE', // CLOSE_COURSE | EXTEND_COURSE
      date: '',
    };
  },

  computed: {
    pickerOptions() {
      const { moment, courseEndOn, courseStartOn, changeType } = this;
      return {
        disabledDate(time) {
          const date = moment(time).format('YYYY-MM-DD');
          const today =
            moment() < moment(courseStartOn)
              ? moment(courseStartOn).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD');
          const aYearLater = moment()
            .add(1, 'year')
            .format('YYYY-MM-DD');
          const disabled =
            changeType === 'CLOSE_COURSE'
              ? !moment(date).isBetween(today, courseEndOn, null, '[]')
              : !moment(date).isBetween(courseEndOn, aYearLater, null, '(]');
          return disabled;
        },
      };
    },
  },

  watch: {
    changeType() {
      const { moment, courseEndOn, courseStartOn, changeType } = this;

      if (changeType === 'CLOSE_COURSE') {
        this.date =
          moment() < moment(courseStartOn) ? moment(courseStartOn).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      } else {
        this.date = moment(courseEndOn)
          .add(1, 'd')
          .format('YYYY-MM-DD');
      }
    },

    defaultChangeType: {
      handler(value) {
        this.changeType = value;
      },
      immediate: true,
    },
  },

  created() {
    const { moment, courseStartOn, changeType } = this;

    if (changeType === 'CLOSE_COURSE') {
      this.date =
        moment() < moment(courseStartOn) ? moment(courseStartOn).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    }
  },

  methods: {
    resetData() {
      this.changeType = this.defaultChangeType;
      this.date = this.moment().format('YYYY-MM-DD');
    },

    handleClickCancel() {
      this.resetData();
      this.$emit('close', false);
    },

    handleClickConfirm() {
      const shouldExtendCourse = this.changeType === 'EXTEND_COURSE';
      this.$emit('close', true, this.date, shouldExtendCourse);
    },
  },
};
</script>

<style lang="scss" scoped>
#change-course-end-date-dialog {
  /deep/ .el-dialog {
    width: 90%;
    max-width: 400px;
  }

  /deep/ .el-dialog__body {
    @include flex(column, center);
  }
}

.change-course-end-date-dialog {
  &__message {
    .close-course {
      color: $color-danger;
    }

    .extend-course {
      color: $color-primary;
    }
  }

  &__dates {
    align-self: stretch;
    @include flex(row, flex-end, space-around);
    margin: 30px 0;

    div {
      display: flex;
      flex-direction: column;

      label {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 12px;
      }
    }

    img {
      margin: 6px 3px;
    }
  }
}
</style>
