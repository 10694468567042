<template>
  <section class="sales-list">
    <div class="sales-list__controls">
      <div class="sales-list__controls__filters">
        <div v-for="(options, key) in listFilterOptions" :key="key">
          <el-select
            :class="{ filtered: !!listFilter[key] }"
            :value="listFilter[key]"
            @change="
              value => {
                handleChangeFilter(key, value);
              }
            "
            :filterable="['staffName', 'ticketTitle'].includes(key)"
            :clearable="['staffName', 'ticketTitle'].includes(key)"
            :multiple="['staffName', 'ticketTitle'].includes(key)"
            :placeholder="getPlaceholder(key)"
            :disabled="!options.length"
            collapse-tags
          >
            <el-option v-for="option in options" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </div>
      </div>
      <div class="sales-list__controls__search">
        <TextInput
          :value="listFilter.searchText"
          prefix-icon="el-icon-search"
          placeholder="회원명 검색"
          round
          @input="
            value => {
              handleChangeFilter('searchText', value);
            }
          "
        />
        <FilterResetButton @click="setListFilter" />
      </div>
    </div>
    <div class="sales-list__table">
      <div class="sales-list__table__summary">
        <div>
          <h3>{{ tableSummaryText }}</h3>
        </div>
        <div>
          <el-pagination
            class="sales-list__table__pagination"
            :current-page.sync="page"
            :page-sizes="[10, 15, 20, 50]"
            :page-size="pageSize"
            @size-change="size => (pageSize = size)"
            layout="prev, pager, next, sizes"
            :total="listData.length"
          />
          <el-button
            class="sales-list__table__excel-down"
            v-loading="exceldownloading"
            :disabled="exceldownloading"
            type="primary"
            @click="downloadExcel"
            >엑셀다운로드</el-button
          >
        </div>
      </div>
      <ListTable :data="paginatedData" :active-tab="activeTab" :filter="listFilter" />
    </div>
  </section>
</template>

<script>
import ListTable from './ListTable';
import { PAYMENT_STATUS, PAYMENT_VAR } from '@constants';

export default {
  components: { ListTable },

  props: {
    listData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      exceldownloading: false,
      page: 1,
      pageSize: 15,
    };
  },

  computed: {
    filter() {
      return this.$store.getters['sales/filter'];
    },
    activeTab() {
      return this.$store.getters['sales/activeTab'];
    },
    listFilter() {
      return this.$store.getters['sales/listFilter'];
    },
    listFilterOptions() {
      return this.$store.getters['sales/listFilterOptions'];
    },

    paginatedData() {
      if (!this.listData.length) return [];
      const sliceBegin = (this.page - 1) * this.pageSize;
      const sliceEnd = sliceBegin + this.pageSize;
      const paginatedData = this.listData.slice(sliceBegin, sliceEnd);
      return paginatedData;
    },

    tableSummaryText() {
      const { comma } = this.$filters;

      let text = '';
      if (this.activeTab === 'ticketsRevenue') {
        const count = comma(this.listData.length);
        const { paymentMethod } = this.listFilter;
        const totalAmount =
          paymentMethod === 'card'
            ? this.listData.reduce(
                (total, row) => (row.paymentType === 'refund' ? (total -= row.cardAmount) : (total += row.cardAmount)),
                0,
              )
            : paymentMethod === 'cash'
            ? this.listData.reduce(
                (total, row) => (row.paymentType === 'refund' ? (total -= (row.cashAmount + row.wiretransferAmount)) : (total += (row.cashAmount + row.wiretransferAmount))),
                0,
              )
            : this.listData.reduce((total, row) => (total += row.totalAmount), 0);
        text = `총 ${count}건 (${comma(totalAmount)}원)`;
      } else if (this.activeTab === 'lecturesRevenue') {
        let count = { lecture: 0, deducted: 0 };
        let total = { lecture: 0, deducted: 0 };
        let lectureIds = [];
        this.listData.forEach(({ usageType, lectureId, deductedAmount }) => {
          if (usageType === 'lecture' && !lectureIds.includes(lectureId)) {
            lectureIds.push(lectureId);
          }
          count[usageType]++;
          total[usageType] += deductedAmount;
        });
        let textArray = [];
        Object.keys(count).forEach(key => {
          if (count[key] > 0) {
            if (key === 'lecture') {
              textArray.push(`수업 ${comma(lectureIds.length)}건 (회원 ${comma(count[key])}명) (${comma(total[key])}원)`);
            } else if (key === 'deducted') {
              textArray.push(`차감 ${comma(count[key])}건 (${comma(total[key])}원)`);
            }
          }
        });
        text = textArray.join(', ');
      }
      return text;
    },
  },

  methods: {
    setListFilter(values) {
      this.$store.commit('sales/SET_LIST_FILTER', values);
    },

    handleChangeFilter(key, value) {
      this.setListFilter({ [key]: value });
    },

    getPlaceholder(key) {
      if (!['staffName', 'ticketTitle'].includes(key)) return null;

      const placeholders = {
        staffName: this.activeTab === 'ticketsRevenue' ? '담당강사 전체' : '수업강사 전체',
        ticketTitle: '수강권 전체',
      };

      return placeholders[key];
    },

    downloadExcel() {
      this.exceldownloading = true;
      /** 데이터 가공 */
      const json = this.formatJSON(this.listData);

      const { rangeType, date } = this.filter;
      let dateString = `${date}`;
      if (rangeType === 'week') {
        dateString += `_${this.moment(date)
          .add(6, 'days')
          .format('YYYY-MM-DD')}`;
      } else if (rangeType === 'month') {
        dateString += `_${this.moment(date)
          .endOf('month')
          .format('YYYY-MM-DD')}`;
      }

      /** 다운로드 */
      const TITLE = {
        ticketsRevenue: '수강권 매출',
        lecturesRevenue: '수업 매출',
      };
      const title = TITLE[this.activeTab];
      this.$utils.downloadExcel(json, `${title}_${dateString}.xlsx`);
      this.exceldownloading = false;
    },

    formatJSON(data) {
      if (!data || !data.length) return [];

      return data.map(row => {
        if (this.activeTab === 'ticketsRevenue') {
          let paymentMethod = this.$utils.translate.paymentMethod(row.paymentMethod);
          if(row.hasWiretransferAmount) {
            paymentMethod += ' (계좌이체)';
          }
          return {
            결제구분:
              row.paymentType === 'installment_payment' || row.paymentType === 'full_payment'
                ? '미수금결제'
                : row.tutorialTicket
                ? PAYMENT_STATUS.tutorial
                : row.paymentType === PAYMENT_VAR.upgrade
                ? PAYMENT_STATUS.upgrade
                : row.paymentType === PAYMENT_VAR.transfer
                ? PAYMENT_STATUS.transfer
                : row.paymentType === PAYMENT_VAR.refund
                ? PAYMENT_STATUS.refund
                : row.isNew
                ? PAYMENT_STATUS.new
                : PAYMENT_STATUS.reNew,
            결제일: this.moment(row.payedOn).format('YYYY-MM-DD'),
            수업구분: this.$utils.translate.courseType(row.courseType),
            회원명: row.memberName,
            수강권명: row.ticketTitle,
            // 수강권횟수: row.ticketMaxCoupon,
            // 회당금액: row.pricePerCoupon,
            카드결제금액: row.paymentType === PAYMENT_VAR.refund ? -row.cardAmount : row.cardAmount,
            현금결제금액: row.paymentType === PAYMENT_VAR.refund ? -row.cashAmount : row.cashAmount,
            계좌이체금액: row.paymentType === PAYMENT_VAR.refund ? -row.wiretransferAmount : row.wiretransferAmount,
            결제금액합계: row.totalAmount,
            '미수금(위약금)': row.unpaidAmount,
            결제방법: paymentMethod,
            담당강사: row.staffName,
          };
        } else if (this.activeTab === 'lecturesRevenue') {
          return {
            구분: row.usageType === 'lecture' ? '수업' : '차감',
            날짜: this.moment(row.lectureStartOn).format('YYYY-MM-DD'),
            수업시작: row.usageType === 'lecture' ? this.moment(row.lectureStartOn).format('HH:mm') : '',
            수업종료: row.usageType === 'lecture' ? this.moment(row.lectureEndOn).format('HH:mm') : '',
            수업구분: this.$utils.translate.courseType(row.courseType),
            회원명: row.memberName,
            수강권명: row.ticketTitle,
            결제금액합계: row.totalAmount,
            전체횟수: row.ticketMaxCoupon,
            발급횟수: row.userTicketMaxCoupon,
            회당금액: row.pricePerCoupon,
            누적사용횟수: row.usedCountTotal,
            누적사용금액: row.deductedAmountTotal,
            잔여횟수: row.remainingCoupon,
            미수업금: row.payableAmount,
            수업강사: row.staffName,
            차감사유: row.deductionType,
            차감횟수: row.usageType === 'deducted' ? row.usedCount : '',
          };
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-list__controls {
  @include flex(row, center, space-between);
  flex-wrap: wrap;

  &__filters,
  &__search {
    margin-bottom: 4px;

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 40px;
      width: 100%;
      padding: 0 15px;

      &::placeholder {
        color: $charcoal-grey;
        opacity: 0.9;
      }
    }
  }

  &__filters {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 4px;

    /deep/ .el-select__tags {
      margin: 0 8px;

      & > span {
        @include flex(row, center);
        flex-wrap: wrap;
      }
    }

    /deep/ .el-select__input {
      margin-left: 0;
    }

    /deep/ .el-tag {
      background: none !important;
      color: $charcoal-grey;
      font-size: 14px;
      margin: 0;
      padding: 0;

      &::after {
        content: ', ';
      }

      &:last-child::after {
        content: '';
      }

      i {
        display: none;
      }
    }

    .filtered {
      /deep/ .el-tag,
      /deep/ .el-input__inner {
        color: $color-primary;
      }
    }
  }

  &__search {
    @include flex(row, center, flex-start);

    button {
      min-width: 36px;
      margin-left: 4px;
    }
  }
}

.sales-list__table {
  @include flex(column, stretch, center);

  &__summary {
    @include flex(row, center, space-between);
    flex-wrap: wrap;

    & > div {
      @include flex(row, center, flex-start);
    }

    h3 {
      margin: 20px 0;
    }
  }

  &__pagination {
    @include flex(row, center, flex-start);
    flex-wrap: wrap;
    margin: 10px 0;

    /deep/ .el-input__inner {
      @extend %input-default;
    }

    /deep/ .el-input__suffix {
      right: 8px;
    }

    /deep/ .el-pagination__sizes {
      margin: 0;
    }
  }

  &__excel-down {
    display: none;

    @include mq(768px) {
      display: flex;
    }
  }
}
</style>
