export const parseHourMinute = number => {
  if (!number || typeof number !== 'number') {
    return { hour: 0, minute: 0 };
  }
  const hour = parseInt(number / 60);
  const minute = parseInt(number % 60);
  return { hour, minute };
};

export default {
  /** 분 -> 시간/분 */
  parseHourMinute,

  /** 시간/분 -> 분 */
  combineHourMinute: ({ hour, minute }) => hour * 60 + minute,

  /**
   * @param {String} time ('HH:mm')
   */
  subtractThirtyMinutes: time => {
    if (!time) return null;

    const [hour, minute] = time.split(':');

    return `${hour}:${parseInt(minute) - 5}`;
  },

  /**
   * @param {String} time ('HH:mm')
   */
  addThirtyMinutes: time => {
    if (!time) return null;

    let [hour, minute] = time.split(':');
    hour = parseInt(hour);
    minute = parseInt(minute);

    let minute_value = minute + 30;
    if (hour === 23 && minute >= 30) {
      return `23:59`;
    }

    if (minute_value >= 60) {
      minute_value -= 60;
      hour = (hour + 1) % 24;
    }

    hour = hour < 10 ? `0${hour}` : `${hour}`;
    minute = minute_value < 10 ? `0${minute_value}` : `${minute_value}`;

    return `${hour}:${minute}`;
  },
};
