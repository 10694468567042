<template>
  <MainLayout :padded="false">
    <content-header :breadcrumb="breadcrumb" :contentMaxWidth="1134">
      <div class="content-title">
        <div class="content-title__profile-image">
          <img :src="profileImageUrl" :alt="staffName" />
        </div>
        <div class="content-title__staff-detail">
          <div class="content-title__staff-detail__name">
            <h3>{{ staffName }}</h3>
            <plain-button v-if="canUpdateStaff" @click="$router.push(`/staffs/edit?id=${staff.id}`)">수정하기</plain-button>
          </div>
          <p>
            <span v-if="!!staffRole">{{ staffRole }}</span>
            <span v-if="!!staff.mobile">{{ staff.mobile | mobile }}</span>
            <span v-if="!!staff.registered_at">등록일: {{ staff.registered_at | dateKorean }}</span>
          </p>
        </div>
      </div>
      <CustomTabs class="staff-detail__tabs" :active_tab="currentTab" :tabs="tabs" :handleChangeTab="handleChangeTab" />
    </content-header>

    <components :is="currentComp" class="staff-detail__body"></components>
  </MainLayout>
</template>

<script>
import Basic from '@/components/StaffDetail/Basic';
import Classes from '@/components/StaffDetail/Classes';
import WorkTime from '@/components/StaffDetail/WorkTime';
import Members from '@/components/StaffDetail/Members';

export default {
  components: {
    Basic,
    Classes,
    WorkTime,
    Members,
  },

  data() {
    return {
      currentTab: 'basic',
    };
  },

  computed: {
    currentComp() {
      return {
        basic: Basic,
        worktime: WorkTime,
        classes: Classes,
        members: this.membersTabEnabled ? Members : '',
      }[this.currentTab];
    },
    staff() {
      return this.$store.getters['staff/staff'];
    },

    isMe() {
      return this.currentUser.id === this.staff.id;
    },

    membersTabEnabled() {
      return this.isMe || this.canViewMembers;
    },

    tabs() {
      let tabs = [
        { value: 'basic', label: '기본정보' },
        { value: 'worktime', label: '시간정보' },
        { value: 'classes', label: '수업' },
      ];
      if (this.membersTabEnabled) tabs.push({ value: 'members', label: '담당회원' });
      return tabs;
    },

    breadcrumb() {
      return {
        parent: { path: '/staffs', label: '강사' },
        current_route_label: this.isMe ? '마이페이지' : '상세 정보',
      };
    },

    profileImageUrl() {
      return this.$utils.getImageUrl(_.get(this.staff, 'avatars[0]'), '148x148');
    },

    staffName() {
      return _.get(this.staff, 'profile.name');
    },

    staffRole() {
      return _.get(this.staff, 'roles[0].display_name');
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!to.query.id && to.path !== '/staffs/me') return next('/staffs');

    next(vm => {
      const isMe = vm.currentUser.id == to.query.id || to.path === '/staffs/me';
      if (!vm.canViewStaff && !isMe) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/staffs');
      }

      next();
    });
  },

  async created() {
    let staffId = this.$route.query.id;
    if (this.$route.path === '/staffs/me') staffId = this.currentUser.id;
    const { success, error } = await this.$store.dispatch('staff/getStaff', staffId);

    if (!success) {
      this.$utils.notify.parseError(this, error);
      return this.$router.push('/staffs');
    }

    let currentTab = 'basic';
    if (['basic', 'classes', 'worktime', 'members'].includes(this.$route.query.tab)) {
      currentTab = this.$route.query.tab;
    }
    // TODO 탭 이동 시 페이지 리랜더링이 되서 불필요한 created훅이 실행되서 성능저하가 생김.
    this.handleChangeTab(currentTab);
  },

  methods: {
    handleChangeTab(tab) {
      if (tab === 'members' && !this.membersTabEnabled) tab = 'basic';
      this.$router.replace({ query: { ...this.$route.query, tab } }).catch(() => {});
      this.currentTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-title {
  width: 100%;

  @include mq(568px) {
    @include flex(row-reverse);
  }

  &__profile-image {
    @include flex(row, center, center);
    @include mq(568px) {
      margin-left: 20px;
    }

    img {
      border: solid 1px rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      width: 148px;
      height: 148px;
    }
  }

  &__staff-detail {
    @include flex(column);
    @include mq(568px) {
      flex: 1;
    }

    &__name {
      @include flex(row, center, space-between);
      margin-bottom: 12px;

      @include mq(568px) {
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      h3 {
        font-size: 38px !important;
        font-weight: bold;
        margin: 0 !important;
      }

      button {
        margin-left: 12px;
      }
    }

    p {
      @include flex(column);
      opacity: 0.66;
      font-size: 16px;

      span {
        margin-bottom: 8px;
      }

      @include mq(768px) {
        @include flex(row, center, flex-start);
        flex-wrap: wrap;

        span + span:before {
          content: '·';
          margin: 0 10px;
        }
      }
    }
  }
}

.staff-detail__tabs {
  transform: translateY(25px);

  @include mq(768px) {
    transform: translateY(37px);
  }
}

.staff-detail__body {
  @include flex(column);
  padding: 20px;
  max-width: 1095px;
  margin: auto;

  @include mq(768px) {
    padding: 30px 20px;
  }
}
</style>
