<template>
  <header>
    <div class="sales-header__inner">
      <h3 class="sales-header__inner__title">
        매출 통계
        <CircleButton @click="handleClickReset" style="margin-left: 8px;">
          <img src="@assets/images/icons/refresh.svg" alt="refresh" />
        </CircleButton>
      </h3>

      <div v-if="activeTab !== 'unpayedRevenue'" class="sales-header__range-type-select">
        <el-select v-model="rangeType">
          <el-option v-for="item in rangeTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-button v-if="todayButton.show && rangeType !== 'daterange'" @click="handleClickToday">{{
          todayButton.label
        }}</el-button>
      </div>

      <div v-if="activeTab !== 'unpayedRevenue'" class="sales-header__navigate-range">
        <el-button v-if="rangeType !== 'daterange'" icon="el-icon-arrow-left" @click="handleClickPrev" />
        <div class="sales-header__navigate-range__datepicker">
          <h3>{{ dateDisplay }}</h3>
          <div v-if="rangeType !== 'daterange'">
            <el-date-picker
              v-model="date"
              align="center"
              :type="rangeType === 'day' ? 'date' : rangeType"
              :editable="false"
              :clearable="false"
              :picker-options="datePickerOptions"
            />
          </div>
          <el-date-picker
            v-else
            v-model="date"
            align="center"
            :type="rangeType === 'day' ? 'date' : rangeType"
            :editable="false"
            :clearable="false"
            :picker-options="datePickerOptions"
          />
          <span
            :class="['sales-header__navigate-range__datepicker__week-range', rangeType === 'daterange' ? 'warning-text' : null]"
          >
            {{ rangeType === 'daterange' ? '최대 5개월까지 선택 가능합니다' : weekRangeHelperText }}
          </span>
        </div>
        <el-button
          v-if="rangeType !== 'daterange'"
          icon="el-icon-arrow-right"
          :disabled="!todayButton.show"
          @click="handleClickNext"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      rangeTypeOptions: [
        { value: 'day', label: '일간' },
        { value: 'week', label: '주간' },
        { value: 'month', label: '월간' },
        { value: 'daterange', label: '기간' },
      ],
      present: {
        day: this.moment().format('YYYY-MM-DD'),
        week: this.moment()
          .day(1)
          .format('YYYY-MM-DD'),
        month: this.moment()
          .date(1)
          .format('YYYY-MM-DD'),
        daterange: this.moment()
          .date(1)
          .format('YYYY-MM-DD'),
      },
      todayLabel: {
        day: '오늘',
        week: '이번주',
        month: '이번달',
        daterange: '이번달',
      },
    };
  },

  computed: {
    filter() {
      return this.$store.getters['sales/filter'];
    },

    rangeType: {
      get() {
        return this.filter.rangeType;
      },
      set(rangeType) {
        let date = null;
        if (rangeType === 'day') date = this.moment();
        else if (rangeType === 'week') date = this.moment().day(1);
        else if (rangeType === 'month') date = this.moment().date(1);
        else if (rangeType === 'daterange') {
          date = this.moment().date(1);
          this.$store.commit('sales/SET_ACTIVE_SUB_TAB', 'list');
        }
        date = date.format('YYYY-MM-DD');
        this.$store.commit('sales/SET_FILTER', { rangeType, date });
        this.$store.commit('sales/SET_LIST_FILTER');
        return date;
      },
    },
    date: {
      get() {
        if (this.filter.rangeType === 'daterange') {
          return { startDate: this.filter.date, endDate: this.filter.endDate };
        }
        return this.filter.date;
      },
      set(date) {
        if (this.rangeType === 'week') date = this.moment(date).day(1);
        if (this.rangeType !== 'daterange') {
          date = this.moment(date).format('YYYY-MM-DD');
        } else {
          const searchTerm = this.$utils.getDaysDiff(date[1], date[0], 'month');
          if (searchTerm > 4) {
            return this.$utils.notify.parseError(this, { message: '최대 5개월까지 선택 가능합니다' });
          }
          let endDate = this.moment(date[1]).format('YYYY-MM-DD');
          date = this.moment(date[0]).format('YYYY-MM-DD');

          this.$store.commit('sales/SET_FILTER', { date, endDate });
          return this.$store.commit('sales/SET_LIST_FILTER');
        }

        this.$store.commit('sales/SET_FILTER', { date });
        this.$store.commit('sales/SET_LIST_FILTER');
      },
    },

    dateDisplay() {
      const { date, rangeType } = this.filter;

      let text = this.moment(date).format('YYYY년 M월 D일 (ddd)');
      if (rangeType === 'week') {
        const endOfWeek = this.moment(date).add(6, 'days');
        const year = endOfWeek.year();
        const month = endOfWeek.month() + 1;
        const week = Math.ceil(endOfWeek.date() / 7);
        text = `${year}년 ${month}월 ${week}주`;
      } else if (rangeType === 'month') {
        text = this.moment(date).format('YYYY년 M월');
      } else if (rangeType === 'daterange') {
        text = `${this.moment(this.date.startDate).format('YYYY. M. D.')} ~ ${this.moment(this.date.endDate).format(
          'YYYY. M. D.',
        )}`;
      }

      return text;
    },

    weekRangeHelperText() {
      if (this.rangeType !== 'week') {
        return '';
      }
      return `(${this.moment(this.date).format('YYYY. M. D.')} ~ ${this.moment(this.date)
        .add(6, 'days')
        .format('YYYY. M. D.')})`;
    },

    todayButton() {
      const { rangeType, date, present, todayLabel } = this;
      return {
        show: present[rangeType] !== date,
        label: todayLabel[rangeType],
      };
    },

    datePickerOptions() {
      /** 매출 조회 제한 해제로 미사용 로직 */
      // const { moment, rangeType } = this;

      return {
        firstDayOfWeek: 1,
        disabledDate(date) {
          return date > new Date();

          /** 매출 조회 제한 해제로 미사용 함수 */

          // const isBefore = moment(date).isBefore(
          //   moment()
          //     .subtract(100, 'years')
          //     .startOf('month'),
          // );
          // let isAfter;
          // if (rangeType === 'day') {
          //   isAfter = moment(date).isAfter(moment());
          // } else if (rangeType === 'week') {
          //   isAfter = moment(date)
          //     .weekday(6)
          //     .isAfter(moment().weekday(6));
          // } else if (rangeType === 'month') {
          //   isAfter = moment(date)
          //     .startOf('month')
          //     .isAfter(moment().endOf('month'));
          // } else if (rangeType === 'daterange') {
          //   isAfter = moment(date).isAfter(moment());
          // }
          // return isBefore || isAfter;
        },
      };
    },

    /** 매출 조회 제한 해제로 미사용 함수 */
    // prevButtonDisabled() {
    //   const { rangeType, date, moment } = this;
    //   const prevDate = moment(date).subtract(1, rangeType);
    //   return prevDate.isBefore(
    //     moment()
    //       .subtract(100, 'years')
    //       .startOf('month'),
    //   );
    // },

    activeTab() {
      return this.$store.getters['sales/activeTab'];
    },
  },

  methods: {
    handleClickReset() {
      if (this.activeTab === 'unpayedRevenue') {
        this.$store.dispatch('sales/getUnpaymentData');
      } else {
        this.$store.commit('sales/SET_RAW_DATA');
        this.$store.dispatch('sales/getData');
      }
    },

    handleClickToday() {
      this.$store.commit('sales/SET_FILTER', {
        date: this.present[this.rangeType],
      });
    },

    handleClickPrev() {
      const { rangeType, date, moment } = this;
      const dateTo = moment(date)
        .subtract(1, rangeType)
        .format('YYYY-MM-DD');
      this.$store.commit('sales/SET_FILTER', { date: dateTo });
    },

    handleClickNext() {
      const { rangeType, date, moment } = this;
      const dateTo = moment(date)
        .add(1, rangeType)
        .format('YYYY-MM-DD');
      this.$store.commit('sales/SET_FILTER', { date: dateTo });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background: #fff;
  position: sticky;
  top: 0;
  margin-bottom: 8px;
  padding-bottom: 8px;
  z-index: 98;

  &::after {
    content: '';
    background-image: linear-gradient(to bottom, #fff, rgba(#fff, 0));
    height: 24px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  .sales-header__inner {
    @include flex(row, center, space-between);
    flex-wrap: wrap;
    max-width: 1440px;
    margin: auto;
    padding: 10px 20px;

    @include mq(568px) {
      padding: 0px 30px;
    }

    h3 {
      height: 100px;
      @include flex(row, center, center);
      font-size: 24px;
      font-weight: bold;

      @include mq(768px) {
        font-size: 28px;
      }
    }
  }

  .sales-header__range-type-select {
    @include flex(row-reverse);

    @include mq(768px) {
      @include flex(row, flex-end, flex-end);
      flex: 1;
      align-self: center;
    }

    /deep/ .el-input {
      max-width: 80px;
    }

    /deep/ .el-input__inner {
      @extend %input-default;
      padding: 9.5px 20px 9.5px 10px;
      height: 100%;
      text-align: center;
    }

    /deep/ .el-button {
      margin-right: 10px;

      @include mq(768px) {
        margin: 0 0 0 10px;
      }
    }
  }

  .sales-header__navigate-range {
    @include flex(row, center, center);
    margin: 10px 0;
    width: 100%;
    height: 100px;

    @include mq(768px) {
      margin: 0 0 0 20px;
      width: auto;
    }

    &__datepicker {
      @include flex(column);
      position: relative;

      h3 {
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        text-align: center;

        @include mq(768px) {
          font-size: 24px;
        }
      }

      &__week-range {
        font-size: 11px;
        text-align: center;

        @include mq(768px) {
          font-size: 14px;
          min-height: 20px;
        }
      }
    }

    /deep/ .el-input {
      min-width: 130px;
      margin: 0 20px;

      @include mq(768px) {
        min-width: 260px;
      }
    }

    /deep/ .el-input__inner {
      border-bottom: 3px solid transparent;
      color: transparent;
      font-size: 18px;
      font-weight: bold;
      padding: 0;
      height: 100%;
      text-align: center;

      @include mq(768px) {
        font-size: 32px;
        padding: 20px 0 0;
      }

      &:hover {
        cursor: pointer;
        border-bottom-color: $charcoal-grey;
      }
      /deep/ .el-input__icon,
      .el-range-separator,
      .el-range-input {
        visibility: hidden;
      }

      .el-range-input,
      .el-range-separator {
        display: none;
      }
    }

    /deep/ .el-input__prefix {
      display: none;
    }
  }
}
.warning-text {
  color: $coral;
}
</style>
