<template>
  <div class="bookings-list-filter">
    <!-- 조회 방식 -->
    <div class="bookings-list-filter__query-type">
      <el-select v-model="queryType">
        <el-option v-for="item in rangeTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>

    <!-- 날짜별 조회 -->
    <div class="bookings-list-filter__date" v-show="rangeFilter.type === 'date'">
      <el-button icon="el-icon-arrow-left" :disabled="isPastSixMonth" @click="onClickPrevDate" />
      <el-date-picker
        v-model="date"
        type="date"
        align="left"
        format="yyyy. M. d."
        value-format="yyyy-MM-dd"
        :picker-options="rangePickerOptions"
        :clearable="false"
      />
      <el-button icon="el-icon-arrow-right" @click="onClickNextDate" />
      <el-button v-if="date !== moment().format('YYYY-MM-DD')" @click="onClickToday">
        오늘
      </el-button>
    </div>

    <!-- 기간별 조회 -->
    <div class="bookings-list-filter__date" v-show="rangeFilter.type === 'range'">
      <el-date-picker
        v-model="range"
        type="daterange"
        align="left"
        format="yyyy. M. d."
        value-format="yyyy-MM-dd"
        :picker-options="rangePickerOptions"
        :clearable="false"
      />
    </div>

    <el-select
      class="weekdays-filter"
      v-if="localFilterOptions.weekdays"
      multiple
      v-model="weeks"
      :placeholder="localFilterOptions.weekdays.placeholder"
      @change="value => searchForWeek(value)"
      collapse-tags
      clearable
    >
      <el-option v-for="item in localFilterOptions.weekdays.options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>

    <div
      class="bookings-list-filter__local-filter"
      :class="key === 'name' || key === 'weekdays' || key === 'time' || key === 'keyword' || key === 'attendance' ? 'none' : null"
      v-for="key in Object.keys(localFilter)"
      :key="key"
    >
      <el-select :value="localFilter[key]" @change="value => handleChangeLocalFilter(key, value)">
        <el-option v-for="item in localFilterOptions[key]" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>

    <div>
      <el-popover v-if="routeName === '/lectures/check_in'" popper-class="time-search-box" placement="bottom" v-model="showPopUp">
        <div class="time-search-box__inputs">
          <HoursInput
            v-model="lectureTime"
            startLabel="시작시간"
            endLabel="종료시간"
            :startHourOptions="{ start: '00:00', end: '23:55', step: '00:05' }"
            :endHourOptions="{
              start: lectureTime.start || '00:00',
              end: '23:55',
              step: '00:05',
              minTime: lectureTime.start,
            }"
            clearable
          />
        </div>

        <div class="time-search-box__checkbox">
          <el-checkbox v-model="timeRange">범위로 검색</el-checkbox>
        </div>

        <div class="time-search-box__buttons">
          <el-button @click="cencelChangeTime">
            취소
          </el-button>
          <el-button type="primary" @click="changeTime()">
            적용
          </el-button>
        </div>
        <el-button class="time-search-button" slot="reference">
          <div class="time-search-text">{{ buttonLabel }}</div>
          <i v-if="buttonLabel === '수업시간 전체'" class="el-icon-arrow-down total-icon"></i>
          <button v-else>
            <i class="el-icon-close" @click="resetTimeRange"></i>
          </button>
        </el-button>
      </el-popover>
    </div>

    <div v-if="isCheckInTab" class="bookings-list-filter__local-filter" :class="null">
      <el-select :value="localFilter['attendance']" @change="value => handleChangeLocalFilter('attendance', value)">
        <el-option v-for="item in localFilterOptions['attendance']" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>

    <TextInput
      class="bookings-list-filter__search-box"
      v-model="searchName"
      prefix-icon="el-icon-search"
      placeholder="회원명 또는 전화번호"
      @keyup.native.enter="searchForName"
      round
    />

    <div class="bookings-list-filter__summary">
      <span v-if="bookingsCount.total > 0">전체 {{ bookingsCount.total | comma }}명</span>
      <span v-if="bookingsCount.P > 0">프라이빗 {{ bookingsCount.P | comma }}명</span>
      <span v-if="bookingsCount.G > 0">그룹 {{ bookingsCount.G | comma }}명</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rangeFilter: {
      type: Object,
      default() {
        return {
          type: 'date',
          dates: [this.moment().format('YYYY-MM-DD'), this.moment().format('YYYY-MM-DD')],
        };
      },
    },
    localFilter: {
      type: Object,
      default() {
        return { instructor: null, courseType: null };
      },
    },
    localFilterOptions: {
      type: Object,
      default() {
        return { instructor: [], courseType: [], weekdays: {} };
      },
    },
    bookingsCount: {
      type: Object,
      default() {
        return { total: 0, P: 0, G: 0 };
      },
    },
  },

  data() {
    return {
      rangeTypeOptions: [
        { value: 'date', label: '날짜' },
        { value: 'range', label: '기간' },
      ],
      lectureTime: { start: null, end: null },
      timeRange: false,
      searchName: null,
      weeks: [],
      showPopUp: false,
      buttonLabel: '수업시간 전체',
    };
  },

  computed: {
    routeName() {
      return this.$route.path;
    },

    queryType: {
      get() {
        return this.rangeFilter.type;
      },
      set(type) {
        const today = this.moment();
        let dates = [today.format('YYYY-MM-DD')];
        if (type === 'date') {
          dates.unshift(today.format('YYYY-MM-DD'));
        } else if (type === 'range') {
          dates.unshift(today.subtract(1, 'week').format('YYYY-MM-DD'));
        }
        const rangeFilter = { type, dates };
        this.$emit('change', 'rangeFilter', rangeFilter, this.searchName);
      },
    },

    date: {
      get() {
        return this.rangeFilter.dates[0];
      },
      set(date) {
        if (!date) return;
        this.handleChangeDate(date);
      },
    },

    range: {
      get() {
        return this.rangeFilter.dates;
      },
      set(dates) {
        this.$emit('change', 'rangeFilter', { ...this.rangeFilter, dates }, this.searchName);
      },
    },

    rangePickerOptions() {
      const { moment } = this;
      return {
        disabledDate(time) {
          return time.getTime() < moment().subtract(6, 'month');
        },
      };
    },

    isPastSixMonth() {
      const yesterday = this.moment(this.rangeFilter.dates[0]).subtract(1, 'day');
      return yesterday.isBefore(this.moment().subtract(6, 'month'));
    },

    isCheckInTab() {
      return this.$route.path.includes('lectures/check_in');
    },
  },

  methods: {
    searchForName() {
      this.$emit('change', 'localFilter', { ...this.localFilter, keyword: this.searchName }, this.searchName);
    },

    changeTime() {
      const { start, end } = this.lectureTime;

      if (!start && !end) this.buttonLabel = '수업시간 전체';
      else if (!end) this.buttonLabel = `${start} ~       `;
      else if (!start) this.buttonLabel = `          ~ ${end}`;
      else this.buttonLabel = `${start} ~ ${end}`;

      this.showPopUp = false;
      this.$emit(
        'change',
        'localFilter',
        {
          ...this.localFilter,
          time: _.cloneDeep({ ...this.lectureTime, range: this.timeRange }),
        },
        this.searchName,
      );
    },

    cencelChangeTime() {
      const time = this.buttonLabel.split('~');

      if (time.length === 1) {
        this.lectureTime.start = null;
        this.lectureTime.end = null;
      } else {
        this.lectureTime.start = time[0];
        this.lectureTime.end = time[1];
      }
      this.showPopUp = false;
    },

    resetTimeRange() {
      this.buttonLabel = '수업시간 전체';
      this.lectureTime.start = null;
      this.lectureTime.end = null;
      this.timeRange = false;
      this.$emit(
        'change',
        'localFilter',
        {
          ...this.localFilter,
          time: _.cloneDeep({ ...this.lectureTime, range: this.timeRange }),
        },
        this.searchName,
      );
    },

    handleChangeDate(date) {
      this.$emit(
        'change',
        'rangeFilter',
        {
          ...this.rangeFilter,
          dates: [date, date],
        },
        this.searchName,
      );
    },

    onClickPrevDate() {
      const yesterday = this.moment(this.rangeFilter.dates[0])
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      this.handleChangeDate(yesterday);
    },

    onClickNextDate() {
      const tommorrow = this.moment(this.rangeFilter.dates[0])
        .add(1, 'day')
        .format('YYYY-MM-DD');
      this.handleChangeDate(tommorrow);
    },

    onClickToday() {
      const today = this.moment().format('YYYY-MM-DD');
      this.handleChangeDate(today);
    },

    handleChangeLocalFilter(key, value) {
      this.$emit(
        'change',
        'localFilter',
        {
          ...this.localFilter,
          [key]: value,
        },
        this.searchName,
      );
    },
    searchForWeek(value) {
      this.$emit(
        'change',
        'localFilter',
        {
          ...this.localFilter,
          weekdays: value,
        },
        this.searchName,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.bookings-list-filter {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;

  & > * {
    height: 36px;
    margin: 0 8px 8px 0;
  }

  /deep/ .el-button {
    height: 36px;
  }

  /deep/ .el-input__inner {
    @extend %input-default;
    border-color: #dcdfe6;
    height: 36px;

    &::placeholder {
      color: $charcoal-grey;
    }
  }

  /deep/ .el-select .el-input__inner {
    padding: 0 20px 0 10px;
  }

  /deep/ .el-date-editor {
    min-width: 135px;
    max-width: 250px;
  }

  &__query-type {
    width: 80px;
  }

  &__local-filter {
    width: 140px;
  }

  &__date {
    @include flex(row, center, center);

    & > * {
      margin-right: 4px;

      &:last-child {
        margin: 0;
      }
    }

    /deep/ .el-button {
      padding: 0;
      height: 36px;
      width: 36px;
    }
  }

  &__summary {
    @include flex(row, center, center);

    span {
      font-size: 15px;
      font-weight: bold;
      color: #000;
    }

    span + span {
      margin-left: 12px;
    }
  }

  &__search-box {
    width: 230px !important;
    margin-right: 60px;
  }

  .weekdays-filter {
    width: 140px;
  }
  &__popover {
    width: 140px;
    &__label {
      width: 140px;
      font-size: 14px;
    }
    &__inputs {
      padding: 10px 0;
    }

    &__checkbox {
      padding: 10px 20px;
    }

    &__buttons {
      padding: 10px 20px;
      text-align: right;
    }
  }
}

.time-search-box {
  &__inputs {
    padding: 10px 0;
  }

  &__checkbox {
    padding: 10px 20px;
  }

  &__buttons {
    padding: 10px 20px;
    text-align: right;
  }
}

.time-search-button {
  padding: 0 20px 0 10px !important;
  width: 140px;
  .time-search-text {
    display: inline-block;
    width: 90px;
    text-align: left;
  }
  .total-icon {
    position: relative;
    left: 15px;
  }
  button {
    padding: 0;
    position: relative;
    left: 10px;
    width: 20px;
    text-align: center;
    height: 20px;
  }
  button:hover {
    background: $color-primary;
    width: 20px;
    text-align: center;
    padding: 0;
    height: 20px;
    border-radius: 10px;
    i {
      color: white;
    }
  }
}

.none {
  display: none;
}
</style>
