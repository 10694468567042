<template>
  <div :class="className">
    <div class="label-wrapper">
      <label v-if="label">{{ label }}</label>
      <el-tooltip v-if="showIconToolTip" effect="light" :content="inputText" placement="right">
        <i class="el-icon-question" :style="{ color: '#64AEFF', fontSize: '18px' }" />
      </el-tooltip>
    </div>
    <el-input
      ref="point-limit"
      type="text"
      :value="formattedValue"
      :readonly="readonly"
      :disabled="disabled"
      @input="value => handleInput(value)"
      @keypress.native="validateKey"
    >
      <span slot="suffix">{{ suffix }}</span>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    labelAlign: {
      type: String,
      default: 'column',
    },
    value: Number,
    readonly: Boolean,
    disabled: Boolean,
    suffix: { type: String, default: 'P' },
    editType: String,
    maxPoint: Number,
  },

  computed: {
    className() {
      let className = `point-input label-align-${this.labelAlign}`;
      if (this.readonly) className += ' readonly';
      return className;
    },

    formattedValue() {
      return this.$filters.comma(parseInt(this.value));
    },

    showIconToolTip() {
      const isUserDetail = this.$route.path.includes('/users');
      return !isUserDetail;
    },

    inputText() {
      const path = this.$route.path;
      const content = path.includes('/products/ticket') ? '수강권' : '상품';
      return `회원에게 ${content} 발급 시 자동으로 적립되는 포인트 입니다.`;
    },
  },

  methods: {
    handleInput(value) {
      const numValue = Number(value.replace(/,/g, '')) || 0;
      const isPointLimit = this.maxPoint && numValue > this.maxPoint;
      const inputRef = this.$refs['point-limit'];
      const pointElement = inputRef.$el.querySelector('input');

      if (isPointLimit) {
        requestAnimationFrame(() => {
          pointElement.value = this.$filters.comma(this.maxPoint);
        });
      }

      const point = isPointLimit ? this.maxPoint : numValue;
      this.$emit('input', point);
    },

    validateKey(e) {
      if (!e.key.replace(/\D/g, '')) e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.point-input {
  display: grid;

  &.label-align-column {
    grid-template-columns: 1fr;
    grid-gap: 4px;
  }

  &.label-align-row {
    align-items: center;
    grid-template-columns: minmax(44px, auto) 1fr;
    grid-gap: 16px;
  }

  &.readonly {
    /deep/ .el-input__inner {
      border: none;
      color: #c0c4cc;
    }
  }

  label {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  /deep/ .el-input__inner {
    padding-right: 30px;
    text-align: right;
  }

  /deep/ .el-input__prefix,
  /deep/ .el-input__suffix {
    @include flex(row, center, center);
    height: 30px;
  }
}

.label-wrapper {
  display: flex;
  align-items: center;

  label {
    margin-right: 4px;
  }
}
</style>
