<template>
  <div class="box" v-if="type === 'lecture'">
    <div class="top">
      <span class="title">{{ title }}</span>
      <span><span class="small">총</span> {{ toLocaleString(summaryItem.totalCount) }}<span class="small">건</span></span>
      <span><span class="small">총</span> {{ toLocaleString(summaryItem.totalAmount) }}<span class="small">원</span></span>
    </div>
    <div class="divider"></div>
    <div class="bottom">
      <div class="item">
        <div class="line">
          <span>그룹</span>
          <span>{{ toLocaleString(summaryItem.groupCount) }}건</span>
        </div>
        <div class="line flex-end">
          <span>{{ toLocaleString(summaryItem.groupAmount) }}원</span>
        </div>
      </div>
      <div class="item">
        <div class="line">
          <span>프라이빗</span>
          <span>{{ toLocaleString(summaryItem.privateCount) }}건</span>
        </div>
        <div class="line flex-end">
          <span>{{ toLocaleString(summaryItem.privateAmount) }}원</span>
        </div>
      </div>
    </div>
  </div>
  <div class="box" v-else-if="type === 'item'">
    <div class="top">
      <span class="title">{{ title }}</span>
      <span><span class="small">총</span> {{ toLocaleString(summaryItem.totalCount) }}<span class="small">건</span></span>
      <span><span class="small">총</span> {{ toLocaleString(summaryItem.totalAmount) }}<span class="small">원</span></span>
    </div>
    <div class="divider"></div>
    <div class="bottom">
      <div class="line">
        <span>판매</span>
        <span>{{ toLocaleString(summaryItem.sale) }}원</span>
      </div>
      <div class="line">
        <span>대여</span>
        <span>{{ toLocaleString(summaryItem.rental) }}원</span>
      </div>
      <div class="line">
        <span>환불</span>
        <span>{{ toLocaleString(summaryItem.refund) }}원</span>
      </div>
    </div>
  </div>
  <div class="box" v-else>
    <div class="top">
      <span class="title">{{ title }}</span>
      <span><span class="small">총</span> {{ toLocaleString(summaryItem.totalCount) }}<span class="small">건</span></span>
      <span><span class="small">총</span> {{ toLocaleString(summaryItem.totalAmount) }}<span class="small">원</span></span>
    </div>
    <div class="divider"></div>
    <div class="bottom">
      <div class="line">
        <span>신규</span>
        <span>{{ toLocaleString(summaryItem.new) }}원</span>
      </div>
      <div class="line">
        <span>재등록</span>
        <span>{{ toLocaleString(summaryItem['re-take']) }}원</span>
      </div>
      <div class="line">
        <span>환불</span>
        <span>{{ toLocaleString(summaryItem.refund) }}원</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    summaryItem: Object,
    type: String,
  },
  methods: {
    toLocaleString(num) {
      return num.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  @include flex(column);
  padding: 19px 13px;
  background-color: #f8f8f8;
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  min-width: 150px;
  width: 150px;
  height: 100%;
  max-height: 260px;

  .top {
    @include flex(column);
    font-size: 14px;

    .title {
      font-size: 16px;
    }

    .small {
      font-size: 12px;
    }

    span:first-child {
      margin-bottom: 10px;
    }

    span:not(:first-child) {
      align-self: flex-end;
      margin-bottom: 5px;
    }
  }

  .divider {
    border-bottom: 1px solid #dcdfe6;
    margin: 10px 0;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    height: 100%;
    gap: 10px;

    .item {
      @include flex(column);
      gap: 5px;
    }

    .line {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &.flex-end {
        justify-content: flex-end;
      }
    }
  }
}
</style>
