<template>
  <div class="transfer-member-table">
    <el-table
      :data="members"
      :key="this.pagination.page"
      @cell-click="handleCellClick"
      empty-text="회원이 없습니다"
      row-class-name="transfer-members-list__row"
      fit
    >
      <el-table-column label="이름" min-width="150" header-align="center">
        <template v-slot="scope">
          <div class="transfer-members-list__member-profile">
            <img :src="$utils.getImagePath(_.get(scope.row, 'avatars'), 36)" :alt="scope.row.name" />
            <div class="transfer-members-list__name-wrapper">
              <h5>{{ scope.row.name }}</h5>
              <img
                v-if="scope.row.vaccination_yn === 'Y'"
                class="vaccine"
                src="@/assets/images/icons/vaccine.svg"
                alt="백신 접종 완료 후 14일 경과"
              />
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="전화번호" min-width="130" header-align="center" align="center">
        <template v-slot="scope">
          {{ getMobileNumber(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column label="보유수강권" min-width="600" header-align="center">
        <template v-slot="scope">
          <ul class="transfer-members-list__ticket-list">
            <li v-for="userTicket in scope.row.userTickets" :key="userTicket.id">
              <div class="transfer-members-list__ticket-list__title">
                {{ userTicket.ticket.title }}
              </div>
              <div class="transfer-members-list__ticket-list__tags">
                <el-tag size="medium" type="info">
                  {{ userTicket.availability_start_at | date }} ~
                  {{ userTicket.expire_at | date }}
                </el-tag>
                <el-tag v-if="!isExpired(userTicket)" size="medium" type="info">
                  {{ $utils.getRemainingDaysText(userTicket.expire_at, userTicket.availability_start_at) }}
                </el-tag>
                <el-tag size="medium" type="info" v-if="userTicket.ticket.type !== 'P' && !isExpired(userTicket)">
                  잔여 횟수 {{ userTicket.remaining_coupon }}/{{ userTicket.max_coupon }}
                </el-tag>
                <el-tag v-if="userTicket.is_holding" size="medium" type="danger">
                  {{ $utils.getHoldingPeriodText(userTicket.holdings) }}
                </el-tag>
                <el-tag v-if="isExpired(userTicket)" size="medium" type="danger">
                  {{ getExpiredStatus(userTicket) }}
                </el-tag>
              </div>
            </li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    members: Array,
  },

  computed: {
    pagination() {
      return this.$store.getters['ticketEdit/pagination'];
    },
  },

  methods: {
    handleCellClick(row) {
      this.$emit('handleClickCell', row.id);
    },

    getMobileNumber({ mobile }) {
      if (!mobile) return null;
      return this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);
    },

    isExpired(userTicket) {
      return this.$utils.getTicketStatus(userTicket) === '이용만료';
    },

    getExpiredStatus({ expire_at, remaining_coupon }) {
      if (remaining_coupon <= 0) return '횟수만료';

      const remainingDays = this.$utils.getDaysDiff(expire_at);
      if (remainingDays < 0) return '기간만료';

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer-members-list {
  &__member-profile {
    display: grid;
    grid-template-columns: 36px 1fr;
    grid-gap: 5px;
    align-items: center;

    img {
      border-radius: 50%;
      width: 36px;
      height: 36px;
    }

    .transfer-members-list__name-wrapper {
      display: flex;
      align-items: center;

      .vaccine {
        width: 18px;
        height: 18px;
        margin-left: 3px;
      }
    }
  }

  &__ticket-list {
    li {
      display: flex;
      margin: 6px 6px 6px 0;
    }

    &__title {
      @include ellipsis;
      @include flex(row, center);
      width: 120px;
      text-align: left;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      padding-left: 10px;

      span {
        font-size: 14px;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.transfer-member-table {
  height: 530px;
  overflow-y: auto;
}

.el-table {
  /deep/ .transfer-members-list__row {
    cursor: pointer;
  }
}
</style>
