/**
 * JSON Object를 FormData로 변경
 */
export default json => {
  const generateFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        generateFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  };

  const formData = new FormData();
  generateFormData(formData, json);
  return formData;
};