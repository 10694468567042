import _ from 'lodash';
import moment from 'moment';
import api from '@api';

const DEFAULT_LECTURES_FILTER = {
  limit: 10,
  page: 1,

  sort_by_name: 'start_on',
  sort_by_type: 'asc',

  rangeType: 'date',
  dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],

  weekdays: [],
  timeRange: {
    start: null,
    end: null,
  },
  exactTimeRange: true,
  instructors: [],
  courseType: null,
  divisions: [],
  rooms: [],
};

export const state = {
  loading: false,
  lectures: [],
  total: 0,
  filter: {
    ...DEFAULT_LECTURES_FILTER,
  },
  checkInSortType: 'ascending',
  updateTimeSortType: 'ascending'
};

export const getters = {
  loading: state => state.loading,
  lectures: state => state.lectures,
  total: state => state.total,
  filter: state => state.filter,
  checkInSortType: state => state.checkInSortType,
  updateTimeSortType: state => state.updateTimeSortType
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_LECTURES(state, payload) {
    if (!payload) return;

    const { data, meta } = payload;
    state.lectures = [...data];
    state.total = meta.total;
  },

  SET_FILTER(state, data) {
    const { filter, reset = false } = data;
    const originalFilterValues = !reset ? state.filter : DEFAULT_LECTURES_FILTER;
    state.filter = {
      ...originalFilterValues,
      ...filter,
    };
  },
  SET_LIST_SORT_TYPE(state, data) {
    switch(data.sortType) {
      case 'checkIn':
        state.updateTimeSortType = data.data;
        break;
      case 'lectureStartOn':
        state.checkInSortType = data.data;
        break;
      default:
        return;
    }
  },
};
export const actions = {
  async getLectures({ state, commit }) {
    try {
      commit('SET_LOADING', true);
      const params = generateLecturesParams(state.filter);
      const res = await api.lecture.getAll({...params, with:"room;division;staff.profile"});
      commit('SET_LECTURES', res.data);
    } catch (error) {
      commit('SET_LECTURES', null);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getLecturesForExcel({ state }) {
    try {
      const params = generateLecturesParams(_.omit(state.filter, ['page', 'limit']));
      const res = await api.lecture.getAll({...params, with:"room;division;staff.profile"});
      return res.data;
    } catch (error) {
      return [];
    }
  },
};

/**
 * Private Helpers
 */

const generateLecturesParams = filter => {
  const { dateRange, timeRange, exactTimeRange, courseType, weekdays, divisions, instructors, rooms } = filter;
  const [start_date, end_date] = dateRange;
  const { start, end } = timeRange;

  // 기간 & 페이징 & 정렬
  const params = {
    start_date,
    end_date,
    ..._.pick(filter, ['limit', 'page', 'sort_by_name', 'sort_by_type']),
  };

  // 시간
  if (exactTimeRange) {
    if (start) params.exact_start_time = `${start}:00`;
    if (end) params.exact_end_time = `${end}:00`;
  } else {
    if (start) params.start_time = `${start}:00`;
    if (end) params.end_time = `${end}:00`;
  }

  // 그룹/프라이빗
  if (courseType) params.course_type = courseType;

  // 요일
  if (weekdays.length) params.week_day = weekdays.join(',');

  // 수업구분
  if (divisions.length) params.division_id = divisions.join(',');

  // 강사
  if (instructors.length) params.staff_id = instructors.join(',');

  // 룸
  if (rooms.length) params.room_id = rooms.join(',');

  return params;
};
