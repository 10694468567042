<template>
  <el-dialog class="noti-dialog" :visible="show" @close="$emit('close', false, isNoSeeNoti)">
    <h4 slot="title">스튜디오메이트 공지</h4>
    <div class="description">
      <div class="noti-box" v-if="data.length"><span class="noti-box__textarea" v-html="modifiedContext" /></div>
      <div v-else>
        공지가 없습니다
      </div>
    </div>

    <div slot="footer" class="noti-footer">
      <el-checkbox v-model="isNoSeeNoti">다시 열지 않기</el-checkbox>
      <el-button type="primary" @click="$emit('close', false, isNoSeeNoti)">
        닫기
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    data: Array,
  },

  data() {
    return {
      isNoSeeNoti: false,
    };
  },

  computed: {
    modifiedContext() {
      if (this.data.length && this.data[0].context) {
        // _500x0.png을 _2000x0.png로 수정
        return this.data[0].context.replace(/_500x0\.png/g, '_2000x0.png');
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.noti-dialog {
  /deep/ .el-dialog {
    max-width: 620px;
    min-height: 780px;
    margin-top: 5vh !important;
    &__header,
    &__body {
      padding: 20px 30px;
    }
  }
  .description {
    height: 700px;
    p {
      font-size: 15px;
    }
  }

  .noti-box {
    height: 700px;
    border: 1px solid;
    border-radius: 4px;
    overflow-y: auto;
    padding: 20px;
    &__textarea {
      /deep/ p {
        margin: 10px 0;
      }
    }
  }

  /deep/ .el-dialog__footer {
    border-top: 1px solid #eee;
  }
}

.noti-footer {
  display: flex;
  justify-content: space-between;
}

.el-checkbox {
  line-height: 40px;
}
</style>
