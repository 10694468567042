<template>
  <div class="custom-text-input" :class="className">
    <label v-if="label" :style="{ minWidth: labelWidth }">{{ label }}</label>
    <span class="custom-text-input__inner">
      <span v-if="!!prefix" class="custom-text-input__prefix" v-html="prefix" />
      <input
        v-bind="$attrs"
        :type="type"
        :value="value"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
        @focus="$emit('focus', $event)"
        @keypress.enter="$emit('enter')"
      />
      <span v-if="!!suffix" class="custom-text-input__suffix" v-html="suffix" />
      <CircleButton v-if="clearable && !!value" class="custom-text-input__clear-button" size="small" @click="$emit('input', '')">
        <i class="el-icon-close" />
      </CircleButton>
    </span>
    <span class="custom-text-input__error">
      {{ error ? errorMessage : null }}
    </span>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: String,
    labelAlign: { type: String, default: 'column' },
    labelWidth: { type: String, default: 'auto' },
    type: { type: String, default: 'text' },
    value: String,
    'prefix-text': String,
    'prefix-icon': String,
    'suffix-text': String,
    'suffix-icon': String,
    round: Boolean,
    material: Boolean,
    clearable: Boolean,
    size: String,
    error: { type: Boolean, default: false },
    errorMessage: String,
    height: Number,
    disabled: Boolean,
  },

  computed: {
    className() {
      let className = `align-${this.labelAlign} ${this.size}`;
      if (this.prefix) className += ' prefix';
      if (this.suffix) className += ' suffix';
      if (this.round) className += ' round';
      if (this.material) className += ' material';
      if (this.error) className += ' error';
      if (this.errorMessage) className += ' error-message';
      if (this.height) className += ` height${this.height}`;
      return className;
    },

    prefix() {
      if (!this.prefixText && !this.prefixIcon) return null;
      return this.prefixText || `<i class="${this.prefixIcon}"/>`;
    },

    suffix() {
      if (!this.suffixText && !this.suffixIcon) return null;
      return this.suffixText || `<i class="${this.suffixIcon}"/>`;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-text-input {
  @include flex(column);
  width: 100%;

  @include mq(568px) {
    &.align-row {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;

      label {
        margin: 0 0 3px;
      }
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;
    margin: 0 3px 3px;
  }

  input {
    @extend %input-default;
    padding: 5px;
    flex: 1;
    line-height: 1.45;

    &::placeholder {
      opacity: 0.7;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: #eee;
      color: $gray-500;
    }
  }

  &.large input {
    padding: 10px 16px 10px 16px;
  }
  &.round input {
    @extend %input-round;
  }
  &.height400 input {
    height: 400px;
  }
  &.material input {
    border-width: 0 0 2px 0;
    border-radius: 0;
  }
  &.error input {
    border-color: rgba($color-danger, 0.7);
    &::placeholder {
      color: $color-danger;
    }
  }
  &.prefix input {
    padding-left: 32px;
  }

  &__inner {
    @include flex(row, center);
    flex: 1;
    position: relative;

    input {
      background-color: transparent;
    }
  }

  &.error-message &__inner {
    margin-bottom: 3px;
  }

  &__prefix,
  &__suffix {
    position: absolute;
    opacity: 0.6;
  }
  &__prefix {
    left: 8px;
  }
  &__suffix {
    right: 8px;
  }

  &__clear-button {
    position: absolute;
    right: -24px;
  }

  &__error {
    font-size: 12px;
    color: $color-danger;

    @include mq(568px) {
      grid-column: 2;
    }
  }

  &.error-message &__error {
    margin: 0 0 3px 3px;
  }
}
</style>
