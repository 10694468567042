<template>
  <button :disabled="disabled" v-bind="$attrs" :class="[type, size, round ? 'round' : null]" @click="$emit('click', $event)">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: String,
    size: String,
    disabled: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
button {
  border: solid 1px $dodger-blue;
  border-radius: 2px;
  color: $dodger-blue;
  font-size: 14px;
  padding: 4px 8px;
  transition: background 0.15s;

  &.round {
    border-radius: 13px;
  }

  &:hover {
    background: rgba($dodger-blue, 0.1);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    border: 1px solid $gray-500;
    color: $gray-500;

    &:hover {
      background: transparent !important;
    }
  }

  &.danger {
    border-color: $color-danger;
    color: $color-danger;

    &:hover {
      background: rgba($color-danger, 0.1);
    }
  }

  &.white {
    border-color: #fff;
    color: #fff;

    &:hover {
      background: rgba(#fff, 0.1);
    }
  }

  &.info {
    border-color: #606266;
    color: #606266;

    &:hover {
      background: rgba(#606266, 0.1);
    }
  }

  &.large {
    border-radius: 4px;
    font-size: 14px;
    padding: 7px 14px;

    &.round {
      border-radius: 16px;
    }
  }

  &.xlarge {
    border-radius: 8px;
    font-size: 16px;
    padding: 9px 18px;
  }

  &.small {
    padding: 2px 4px;
    &.round {
      border-radius: 16px;
    }
  }
}
</style>
