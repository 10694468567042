import { render, staticRenderFns } from "./ReplyCreating.vue?vue&type=template&id=172dc1de&scoped=true&"
import script from "./ReplyCreating.vue?vue&type=script&lang=js&"
export * from "./ReplyCreating.vue?vue&type=script&lang=js&"
import style0 from "./ReplyCreating.vue?vue&type=style&index=0&id=172dc1de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "172dc1de",
  null
  
)

export default component.exports