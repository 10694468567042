<template>
  <section class="settings-automessage-notification">
    <div class="settings-automessage-notification__title">
      <h4>이용 가이드</h4>
      <p>SMS 건당 12 포인트 / 90바이트&emsp;LMS 건당 37 포인트 / 2,000바이트</p>
    </div>
    <div class="message-list-warning">
      <img src="@/assets/images/icons/icon_warning.svg" alt="warning" />
      <div class="text-wrapper">
        <p>1. LMS 메시지는 한/영 구분 없이 띄어쓰기 포함 최대 2,000자까지 입력 가능하며 초과 시 전송에 실패할 수 있습니다.</p>
        <p class="gray">■ 예상 바이트 수는 시설명, 회원명, 수강권명 등 메시지 내용에 따라 달라질 수 있습니다.</p>
        <p>2. 대기 - 예약전환 메시지는 실시간으로 발송되며, 발송시간을 설정할 수 없습니다.</p>
        <p>3. 폐강 시 발송되는 메시지는 폐강시간에 맞게 전송됩니다. 폐강시간은 운영 정보에서 설정할 수 있습니다.</p>
        <p>4. 생일 축하 메시지는 오후 3시에 발송되며 발송시간 변경은 불가합니다.</p>
      </div>
    </div>
    <TemplateBoxList
      :formatAutoMessageUnitOptions="formatAutoMessageUnitOptions"
      :handleClickEditButton="handleClickEditButton"
      :handleClickPreviewButton="handleClickPreviewButton"
      :showShortageModal="showShortageModal"
      @changeShowShortageModal="handleShowShortageModal"
    />
    <edit-template-modal :show="showEditTemplateModal" :handleClose="handleCloseEditTemplateModal" />
    <preview-template-modal :show="showPreviewTemplateModal" :handleClose="handleClosePreviewTemplateModal" />
    <PointShortage :show="showShortageModal" :point="point" :showNeedPointText="false" @close="handlePointStorageClose" />
  </section>
</template>
<script>
import TemplateBoxList from './templateList';
import EditTemplateModal from '@/components/Modals/AutoMessageNotification/EditTemplate';
import PreviewTemplateModal from '@/components/Modals/AutoMessageNotification/PreviewTemplate';
import PointShortage from '@/components/Modals/PointShortage';

export default {
  components: {
    TemplateBoxList,
    EditTemplateModal,
    PreviewTemplateModal,
    PointShortage,
  },
  data() {
    return {
      showEditTemplateModal: false,
      showPreviewTemplateModal: false,
      showShortageModal: false,
    };
  },
  async created() {
    await this.$store.dispatch('autoMessageNotification/getAllTemplates');
    await this.$store.dispatch('payment/getPointScore');

    const isIncludeLMSMessage = this.templates.some(
      template => this.$utils.smsByteCheckWithPreventWriting(template.convertedContext) > 90,
    );
    if (this.point < 12) {
      this.showShortageModal = true;
    }
    if (isIncludeLMSMessage && this.point < 37) {
      this.showShortageModal = true;
    }
  },

  computed: {
    templates() {
      return this.$store.getters['autoMessageNotification/templates'];
    },
    formatAutoMessageUnitOptions() {
      return this.templates.reduce((acc, option) => {
        option.convertedContext = this.replaceAllEngToKor(option.context);
        return {
          ...acc,
          [option.message_type]: { ...(acc[option.message_type] || []), ...option },
        };
      }, {});
    },

    buttonTextList() {
      return this.$store.getters['autoMessageNotification/buttonTextList'];
    },
    point() {
      return this.$store.getters['payment/point'];
    },
  },

  methods: {
    async handleClickEditButton(type) {
      const option = this.filterInputData(type);
      await this.$store.dispatch('autoMessageNotification/setTemplateInputData', option);
      this.showEditTemplateModal = true;
    },

    filterInputData(type) {
      return this.templates.filter(template => {
        return template.message_type === type;
      })[0];
    },

    async handleCloseEditTemplateModal() {
      this.showEditTemplateModal = false;
      await this.$store.dispatch('autoMessageNotification/setTemplateInputData', { init: true });
    },

    async handleClickPreviewButton(type) {
      const option = this.filterInputData(type);
      await this.$store.dispatch('autoMessageNotification/setTemplateInputData', option);
      this.showPreviewTemplateModal = true;
    },

    async handleClosePreviewTemplateModal() {
      this.showPreviewTemplateModal = false;
      await this.$store.dispatch('autoMessageNotification/setTemplateInputData', { init: true });
    },

    replaceAllEngToKor(context) {
      const { buttonTextList } = this;
      return Object.keys(buttonTextList).reduce(
        (acc, currentText) => (acc = acc.replaceAll(`:${currentText}`, `[${buttonTextList[currentText]}]`)),
        context,
      );
    },

    handlePointStorageClose(close, charge) {
      this.showShortageModal = close;
      if (charge) {
        return this.$router.push('/settings/payments');
      }
    },

    handleShowShortageModal(value) {
      this.showShortageModal = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-automessage-notification {
  &__title {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 30px 0px;

    p {
      position: relative;
      justify-content: end;
      font-size: 14px;
      color: #a3a3a3;
      text-align: right;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        top: -1px;
        right: 420px;
        background: url('../../../assets/images/icons/message_gray.svg') 0 0 no-repeat;
        background-size: cover;
      }
    }
  }

  .message-list-warning {
    position: relative;

    img {
      position: absolute;
      top: 17px;
      left: 11px;
      width: 36px;
      height: 36px;
      z-index: 1;
    }

    .text-wrapper {
      padding: 15px 25px 15px 82px;
      border: 1px solid #d4d4d4;
      border-radius: 8px;
      background-color: #f6f6f6;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

      &:before {
        content: '';
        width: 57px;
        height: 100%;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border: 1px soild #e33c3c;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #e33c3c;
      }
    }

    p {
      font-weight: bold;
      font-size: 13px;
      line-height: 1.8;
    }

    .red {
      color: #bb0000;
    }

    .gray {
      font-weight: normal;
      color: #777777;
      line-height: 1.5;
    }
  }
}
</style>
