import axios from 'axios';
import qs from 'qs';

const baseURL = process.env.VUE_APP_API_BASE_URL;
const headers = {
  ...axios.defaults.headers,
  'Cache-control': 'no-cache',
  'Content-Encoding': 'gzip',
  'web-version': process.env.VUE_APP_VERSION,
};
const paramsSerializer = params => {
  return qs.stringify(params, {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
    skipNulls: true,
  });
};

const instance = axios.create({
  baseURL,
  headers,
  timeout: 1000 * 60,
  paramsSerializer,
});

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    // 오류 응답을 처리

    if (error && error.response.status == 503) {
      document.cookie = `inspection-start-time=${error.response.data['start-time']}`;
      document.cookie = `inspection-end-time=${error.response.data['end-time']}`;
      if (window.location.pathname !== '/service-inspection') {
        window.location.href = '/service-inspection';
      }
    }
    return Promise.reject(error);
  },
);

export default instance;
