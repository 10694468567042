<template>
  <div class="lecture-item" :class="{ disabled, full }" @click="handleClick">
    <span class="lecture-item__date">
      {{ lectureDate | dateWithWeekday }}
    </span>
    <span class="lecture-item__time">
      {{ lectureTime }}
    </span>
    <span class="lecture-item__title">
      {{ lectureTitle }}
    </span>
    <span class="lecture-item__instructor">
      {{ instructor ? `${instructor} 강사` : '-' }}
    </span>
    <span class="lecture-item__member-count">
      {{ memberCount }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    lecture: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    bookings() {
      return _.get(this.lecture, 'bookings');
    },

    lectureDate() {
      return _.get(this.lecture, 'start_on');
    },

    lectureTime() {
      const start = this.$filters.time(_.get(this.lecture, 'start_on'));
      const end = this.$filters.time(_.get(this.lecture, 'end_on'));
      return `${start}~${end}`;
    },

    lectureTitle() {
      return _.get(this.lecture, 'title');
    },

    instructor() {
      return _.get(this.lecture, 'staff.name');
    },

    memberCount() {
      return `${this.currentTrainee}명/${this.maxTrainee}명`;
    },

    currentTrainee() {
      return _.get(this.lecture, 'current_trainee_count', 0);
    },

    maxTrainee() {
      return _.get(this.lecture, 'max_trainee');
    },

    full() {
      return this.currentTrainee >= this.maxTrainee;
    },
  },

  methods: {
    handleClick() {
      if (!this.disabled && !this.full) {
        this.$emit('click', this.lecture);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-item {
  display: grid;
  grid-template-columns: 5fr 4fr 5fr 3.5fr 3fr;
  grid-gap: 8px;
  align-items: center;

  cursor: pointer;
  padding: 8px;
  transition: background 0.15s;

  &:hover {
    background: $gray-200;
  }

  &.disabled,
  &.full {
    background: $gray-100;
    color: $gray-500;
    cursor: not-allowed;
    opacity: 0.9;
  }

  &.full &__member-count {
    color: $color-danger;
  }

  span {
    @include ellipsis;
  }

  &__title {
    @include ellipsis;
  }

  &__member-count {
    text-align: right;
  }
}
</style>
