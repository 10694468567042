import api from '@api';
import utils from '@utils';

const DEFAULT_FILTER_VALUES = {
  type: null,
  sort_type: {},
  sort_name: 'asc',
  sort_date: null,
  sort_attendance: null,
  user_grade: null,
  course_type: null,
  remaining_coupon: null,
  remaining_day: null,
  last_attendance_day: null,
  vaccination_yn: null,
  searchFor: 'NAME|MOBILE', // 'NAME|MOBILE' | 'ADDRESS' | 'MEMO'
  keyword: '',
};

const DEFAULT_FILTER_OPTIONS = {
  type: {
    type: 'select',
    options: [
      {
        value: null,
        label: '전체회원',
      },
      {
        value: 'active',
        label: '이용회원',
      },
      {
        value: 'inHolding',
        label: '정지회원',
      },
      {
        value: 'inactive',
        label: '만료회원',
      },
      {
        value: 'unpaid',
        label: '미결제회원',
      },
    ],
  },
  user_grade: {
    type: 'select',
    options: [
      {
        value: null,
        label: '회원등급 전체',
      },
    ],
  },
  course_type: {
    type: 'select',
    options: [
      {
        value: null,
        label: '전체수강권',
      },
      {
        value: 'G',
        label: '그룹',
      },
      {
        value: 'P',
        label: '프라이빗',
      },
    ],
  },
  vaccination_yn: {
    type: 'select',
    options: [
      {
        value: null,
        label: '백신접종 전체',
      },
      {
        value: 'Y',
        label: '백신접종',
      },
      {
        value: 'N',
        label: '백신미접종',
      },
    ],
  },
  last_attendance_day: {
    type: 'popover',
  },
  remaining_day: {
    type: 'popover',
  },
  remaining_coupon: {
    type: 'popover',
  },
};

export const state = {
  loading: false,
  members: [],
  pagination: {
    page: 1,
    limit: 10,
  },
  total: 0,
  filterOptions: {
    ...DEFAULT_FILTER_OPTIONS,
  },
  filterValues: {
    ...DEFAULT_FILTER_VALUES,
  },
  selectForSendMessageCount: 0,
  selectedMemberIds: [],
  selectedMembers: [],
};

export const getters = {
  loading: state => state.loading,
  members: state => state.members,
  pagination: state => state.pagination,
  total: state => state.total,
  filterOptions: state => state.filterOptions,
  filterValues: state => state.filterValues,
  selectForSendMessageCount: state => state.selectForSendMessageCount,
  selectedMemberIds: state => state.selectedMemberIds,
  selectedMembers: state => state.selectedMembers,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_FILTER_OPTIONS(state, data) {
    const { summary, userGrades } = data;
    const modifiedFilterOptions = {};

    if (summary) {
      const { total_count, active_count, holding_count, inactive_count, unpaid_count } = summary;

      modifiedFilterOptions.type = {
        type: 'select',
        options: [
          {
            value: null,
            label: `전체회원 (${total_count}명)`,
          },
          {
            value: 'active',
            label: `이용회원 (${active_count}명)`,
          },
          {
            value: 'inHolding',
            label: `정지회원 (${holding_count}명)`,
          },
          {
            value: 'inactive',
            label: `만료회원 (${inactive_count}명)`,
          },
          {
            value: 'unpaid',
            label: `미결제회원 (${unpaid_count}명)`,
          },
        ],
      };
    }

    if (userGrades) {
      const options = userGrades.reduce(
        (options, userGrade) => {
          options.push({
            value: userGrade.id,
            label: userGrade.name,
          });
          return options;
        },
        [
          {
            value: null,
            label: '회원등급 전체',
          },
        ],
      );

      modifiedFilterOptions.user_grade = {
        type: 'select',
        options,
      };
    }

    state.filterOptions = {
      ...state.filterOptions,
      ...modifiedFilterOptions,
    };
  },

  SET_FILTER_VALUES(state, values) {
    if (!values) {
      state.filterValues = {
        ...DEFAULT_FILTER_VALUES,
      };
    } else {
      state.filterValues = {
        ...values,
      };
    }
  },

  SET_MEMBERS(state, data) {
    if (data) {
      state.members = data.data;
      state.total = data.meta.total;
    } else {
      state.members = [];
      state.total = 0;
    }
  },

  SET_PAGINATION(state, value) {
    state.pagination = {
      ...state.pagination,
      ...value,
    };
  },

  SET_SEND_MESSAGE_COUNT(state, count) {
    state.selectForSendMessageCount = count;
  },

  SET_SELECTED_MEMBERS(state, members) {
    if (!members) state.selectedMembers = [];
    else state.selectedMembers = [...members];

    state.selectedMemberIds = state.selectedMembers.length ? state.selectedMembers.map(member => member.id) : [];
  },

  SET_AFTER_SELECTED_MEMBERS(state, members) {
    if (members) {
      state.selectedMembers = members;
      state.selectedMemberIds = state.selectedMembers.map(member => member.id);
    }
  },
};

export const actions = {
  async getMembers({ commit }, params) {
    try {
      commit('SET_LOADING', true);
      params = utils.mapParamsForMemberList(params);
      const res = await Promise.all([
        api.member.getAll({ ...params, with: 'profile;account;userGrade;avatars;tickets.ticket;lockers' }),
        api.studio.userGrades.get(),
      ]);

      commit('SET_MEMBERS', res[0].data);
      commit('SET_FILTER_OPTIONS', {
        summary: res[0].data.count,
        userGrades: res[1].data,
      });
    } catch (error) {
      commit('SET_FILTER_OPTIONS', {
        summary: null,
        userGrades: null,
      });
      commit('SET_MEMBERS');
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getAfterStopSelectedMembers({ commit }, members) {
    try {
      commit('SET_AFTER_SELECTED_MEMBERS', members);
    } catch (error) {
      commit('SET_AFTER_SELECTED_MEMBERS', null);
    }
  },

  async getSelectedMembers({ commit }, ids) {
    try {
      const res = await api.member.getSelectedMembers(ids);
      commit('SET_AFTER_SELECTED_MEMBERS', res.data);
    } catch (error) {
      commit('SET_AFTER_SELECTED_MEMBERS', null);
    }
  },
};
