<template>
  <section
    class="ticket-detail"
    :class="{
      product: this.ticket.available_class_type === 'I',
    }"
  >
    <div class="ticket-detail__card">
      <TicketCard :ticket="ticket" :showFavoriteIcon="ticket.favorite_stamp ? true : false" />
    </div>

    <form
      class="ticket-detail__form"
      :class="{
        rental_period: isRentalOnlyPeriod,
        rental_time: isRentalOnlyTime,
        rental_all: isRentalAll,
        sale_product: isSaleProduct,
      }"
    >
      <template v-if="!isSaleProduct">
        <div v-if="!isRentalOnlyTime" class="ticket-detail__form__element start_date">
          <label>이용시작일</label>
          <el-date-picker
            placeholder="이용시작일"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :picker-options="startDatePickerOptions"
            :clearable="false"
            v-model="ticketDetail.availability_start_at"
          />
        </div>

        <div v-if="!isRentalOnlyTime" class="ticket-detail__form__element end_date">
          <label>이용종료일</label>
          <el-date-picker
            placeholder="이용종료일"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :picker-options="endDatePickerOptions"
            :disabled="!ticketDetail.availability_start_at"
            :clearable="false"
            v-model="ticketDetail.expire_at"
          />
        </div>

        <div v-if="isProduct" class="ticket-detail__form__element staff">
          <label>담당강사</label>
          <el-select
            v-model="ticketDetail.staffs"
            placeholder="강사 선택"
            clearable
            filterable
            multiple
            :multiple-limit="1000"
            collapse-tags
          >
            <el-option v-for="staff in staffOptions" :key="staff.id" :value="staff.id" :label="staff.name" />
          </el-select>
        </div>

        <div v-if="isNotPeriodType" class="ticket-detail__form__element remaining_coupon">
          <label>잔여 횟수</label>
          <NumberInput
            v-model="ticketDetail.remaining_coupon"
            :min="0"
            :max="999"
            @change="handleChangeNumberInput('remaining_coupon')"
          />
        </div>

        <div v-if="isNotPeriodType" class="ticket-detail__form__element max_coupon">
          <label>전체 횟수</label>
          <NumberInput v-model="ticketDetail.max_coupon" :min="0" :max="999" @change="handleChangeNumberInput('max_coupon')" />
        </div>

        <div v-if="ticket.type === 'T'" class="ticket-detail__form__element remaining_cancel">
          <label>예약 취소 가능 횟수</label>
          <NumberInput
            v-model="ticketDetail.remaining_cancel"
            :min="0"
            :max="999"
            @change="handleChangeNumberInput('remaining_cancel')"
          />
        </div>

        <div
          v-if="ticket.type === 'P' && ticketDetail.is_show_cancel_count"
          class="ticket-detail__form__element plus-grid remaining_coupon"
        >
          <label>예약 취소 가능 횟수</label>
          <NumberInput
            v-model="ticketDetail.remaining_cancel"
            :min="0"
            :max="999"
            @change="handleChangeNumberInput('remaining_cancel')"
          />
        </div>

        <div v-if="!isProduct" class="ticket-detail__form__element is_shared">
          <label>패밀리 수강권 설정</label>
          <el-switch v-model="ticketDetail.is_shared" />
          <p>
            패밀리 수강권으로 가족이 함께 이용할 수 있습니다.<br />
            하나의 수업에 중복으로 예약할 수 있고 이용 가능한 총 횟수를 통합 관리합니다.<br />
            패밀리수강권은 '일일 예약 가능횟수 설정'에 영향받지 않습니다.
          </p>
        </div>
      </template>

      <div v-if="isSaleProduct || !isProduct" class="ticket-detail__form__element staff">
        <label>담당강사</label>
        <el-select
          v-model="ticketDetail.staffs"
          placeholder="강사 선택"
          clearable
          filterable
          multiple
          :multiple-limit="1000"
          collapse-tags
        >
          <el-option v-for="staff in staffOptions" :key="staff.id" :value="staff.id" :label="staff.name" />
        </el-select>
      </div>
    </form>

    <div class="ticket-detail__buttons">
      <el-button @click="handleClickPrev">
        이전
      </el-button>
      <el-button @click="handleClickNext" type="primary" :disabled="nextButtonDisabled">다음</el-button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isProduct: Boolean,
  },

  data() {
    return {
      ticketDetail: {
        availability_start_at: this.moment().format('YYYY-MM-DD'),
        expire_at: this.moment().format('YYYY-MM-DD'),
        remaining_coupon: 0,
        max_coupon: 0,
        remaining_cancel: 0,
        is_shared: false,
        staffs: [],
      },
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketIssue/formData'];
    },
    ticket() {
      return this.formData.selectedTicket;
    },
    staffOptions() {
      return this.formData.staffOptions;
    },

    startDatePickerOptions() {
      const { moment } = this;
      return {
        disabledDate(time) {
          return moment(time).isAfter('2031-12-30');
        },
      };
    },

    endDatePickerOptions() {
      const { moment, ticketDetail } = this;
      return {
        disabledDate(time) {
          return moment(time).isBefore(ticketDetail.availability_start_at) || moment(time).isAfter('2031-12-31');
        },
      };
    },

    nextButtonDisabled() {
      const { remaining_coupon, max_coupon, remaining_cancel, availability_start_at, expire_at } = this.ticketDetail;
      return (
        remaining_coupon === undefined ||
        max_coupon === undefined ||
        remaining_cancel === undefined ||
        !availability_start_at ||
        !expire_at
      );
    },

    isNotPeriodType() {
      return this.ticket.type === 'T' || this.ticket.type === 'RT' || this.ticket.type === 'RM';
    },

    isSaleProduct() {
      return this.ticket.type === 'S';
    },

    isRentalAll() {
      return this.ticket.type === 'RM';
    },

    isRentalOnlyPeriod() {
      return this.ticket.type === 'RP';
    },

    isRentalOnlyTime() {
      return this.ticket.type === 'RT';
    },
  },

  watch: {
    'ticketDetail.availability_start_at': {
      handler() {
        this.setEndDate();
      },
      immediate: true,
    },
  },

  async created() {
    const { ticketDetail } = this.formData;
    if (ticketDetail) {
      this.ticketDetail = {
        ...this.ticketDetail,
        ...ticketDetail,
      };
    } else {
      this.ticketDetail = {
        ...this.ticketDetail,
        remaining_coupon: this.ticket.max_coupon,
        max_coupon: this.ticket.max_coupon,
        remaining_cancel: this.ticket.max_cancel,
        is_show_cancel_count: this.ticket.is_show_cancel_count,
      };
    }
  },

  methods: {
    /** 종료일 자동 설정 */
    setEndDate() {
      const { moment, ticketDetail, ticket } = this;
      const period = ticket.available_period - 1;
      const endDate =
        ticket.type === 'S' || this.isRentalOnlyTime
          ? '2031-12-31'
          : moment(ticketDetail.availability_start_at)
              .add(period, 'd')
              .format('YYYY-MM-DD');

      if (!ticketDetail.availability_start_at) {
        this.ticketDetail.expire_at = '';
      } else {
        this.ticketDetail.expire_at = !this.ticketDetail.availability_start_at
          ? ''
          : moment(endDate).isAfter('2031-12-31')
          ? '2031-12-31'
          : endDate;
      }
    },

    /** 숫자 입력 변경시 */
    handleChangeNumberInput(key) {
      if (key === 'remaining_coupon') {
        this.handleChangeRemainingCoupon();
      } else if (key === 'max_coupon') {
        this.handleChangeMaxCoupon();
      }

      this.preventNegativeValues(key);
    },

    /**
     * 잔여횟수 변경시: 잔여횟수가 전체횟수보다 클 경우
     * 전체횟수 = 잔여횟수
     */
    handleChangeRemainingCoupon() {
      if (this.ticketDetail.remaining_coupon > this.ticketDetail.max_coupon) {
        this.ticketDetail.max_coupon = this.ticketDetail.remaining_coupon;
      }
    },

    /**
     * 전체횟수 변경시: 전체횟수가 잔여횟수보다 작을 경우
     * 잔여횟수 = 전체횟수
     */
    handleChangeMaxCoupon() {
      if (this.ticketDetail.remaining_coupon > this.ticketDetail.max_coupon) {
        this.ticketDetail.remaining_coupon = this.ticketDetail.max_coupon;
        this.preventNegativeValues('remaining_coupon');
      }
    },

    /** 음수입력 차단 */
    preventNegativeValues(key) {
      if (this.ticketDetail[key] < 0) this.ticketDetail[key] = 0;
    },

    /** 이전단계로 */
    handleClickPrev() {
      const { currentStep } = this.formData;
      this.$store.commit('ticketIssue/SET_VALUES', {
        currentStep: currentStep - 1,
        paymentDetail: null,
        ticketDetail: null,
      });
    },

    setCoupon999() {
      if (this.isSaleProduct || this.isRentalOnlyPeriod) {
        this.ticketDetail = {
          ...this.ticketDetail,
          remaining_coupon: 999,
          max_coupon: 999,
          max_cancel: 999,
          remaining_cancel: 999,
        };
      }
    },

    /** 수강권 상세정보 저장 후 다음단계로 */
    handleClickNext() {
      const { currentStep } = this.formData;

      this.setCoupon999();

      this.$store.commit('ticketIssue/SET_VALUES', {
        currentStep: currentStep + 1,
        ticketDetail: { ...this.ticketDetail },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-detail {
  @include flex(column, center);
  min-height: 438px;

  &__card {
    margin: 20px 0;

    & > div {
      margin: 0;
    }
  }

  &__form {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'start_date'
      'end_date'
      'remaining_coupon'
      'max_coupon'
      'remaining_cancel'
      'is_shared'
      'staff';
    &__element {
      @include flex(column);
      border-bottom: 1px solid rgba(#000, 0.04);
      padding: 15px;

      label {
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 5px;
      }
    }
    &__element.plus-grid {
      width: 290px;
    }
    &__element.start_date {
      grid-area: start_date;
    }
    &__element.end_date {
      grid-area: end_date;
    }
    &__element.remaining_coupon {
      grid-area: remaining_coupon;
    }
    &__element.max_coupon {
      grid-area: max_coupon;
    }
    &__element.remaining_cancel {
      grid-area: remaining_cancel;
    }
    &__element.is_shared {
      @include flex(row, center, space-between);
      flex-wrap: wrap;
      grid-area: is_shared;

      p {
        font-size: 10px;
      }
    }
    &__element.staff {
      grid-area: staff;

      /deep/ .el-select__tags > span {
        @include flex(row, center);
        flex-wrap: wrap;
      }

      /deep/ .el-select__input {
        margin-left: 0;
      }

      /deep/ .el-tag {
        background: none !important;
        color: $charcoal-grey;
        font-size: 14px;
        margin: 0;
        padding: 0;

        &::after {
          content: ', ';
        }

        &:last-child::after {
          content: '';
        }

        i {
          display: none;
        }
      }
    }
  }

  &__buttons {
    @include flex(row, flex-end, space-between);
    flex: 1;
    width: 100%;
    padding: 20px 20px 0;
  }
}

.weekly-auto-rebalance-message {
  color: $color-danger;
  font-size: 11px;
  margin: 4px 0 0 4px;
}

@media (min-width: 568px) {
  .ticket-detail {
    &.product &__form {
      margin: 30px 0;
    }
  }

  .ticket-detail__form {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'start_date start_date start_date end_date end_date end_date'
      'remaining_coupon remaining_coupon max_coupon max_coupon remaining_cancel remaining_cancel'
      'is_shared is_shared is_shared is_shared staff staff';

    &__element.start_date,
    &__element.remaining_coupon,
    &__element.max_coupon,
    &__element.is_shared {
      border-right: 1px solid rgba(#000, 0.04);
    }

    &.sale_product {
      display: block;
      width: 100%;
    }

    &.sale_product &__element {
      padding: 15px 200px;
    }

    &.rental_period {
      display: flex;
      flex: none;
      width: 100%;

      & > div {
        flex: 1;
        width: 100%;
      }
    }

    &.rental_period &__element,
    &.rental_time &__element {
      & > div {
        width: 100%;
      }

      &.end_date,
      &.max_coupon {
        border-right: 1px solid rgba(#000, 0.04);
      }
    }

    &.rental_time {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'remaining_coupon max_coupon staff';
    }

    &.rental_all {
      grid-template-areas:
        'start_date start_date start_date end_date end_date end_date'
        'remaining_coupon remaining_coupon max_coupon max_coupon staff staff';
    }

    &.rental_all &__element {
      & > div {
        width: 100%;
      }
    }
  }
}
</style>
