<template>
  <el-dialog
    class="ticket-transfer-modal"
    :class="currentStep === 1 ? 'step1' : ''"
    :visible.sync="show"
    :before-close="
      () => {
        $emit('close');
      }
    "
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <div slot="title" class="ticket-transfer-modal__title">
      <h4>{{ title }} 양도</h4>
      <ul>
        <li v-for="step in [1, 2, 3]" :key="step" :class="{ active: step === currentStep }">
          {{ step }}
        </li>
      </ul>
    </div>

    <!-- 회원 선택 -->
    <template v-if="currentStep === 1">
      <TextInput
        v-model="keyword"
        placeholder="회원명, 휴대폰 번호 검색"
        prefix-icon="el-icon-search"
        round
        @keyup.enter.native="handleChangeKeyword"
      />

      <MemberList v-loading="loading" :members="transferMembers" @handleClickCell="handleClickCell" />

      <el-pagination
        class="ticket-transfer__pagination"
        @current-change="dispatchGetMembersList"
        :current-page.sync="page"
        :page-size="limit"
        :page-sizes="[10, 15, 20, 50]"
        @size-change="limit => handleFilterChange({ limit })"
        layout="prev, pager, next, sizes"
        :total="total"
      />
    </template>

    <!-- 수강권 정보 확인 -->
    <TicketDetail
      v-else-if="currentStep === 2"
      :userTicket="userTicket"
      :ticket="ticketSelected"
      :isProduct="isProduct"
      :title="title"
      @click-prev="handleClickPrev"
      @click-next="handleClickNext"
    />

    <!-- 결제 정보 확인 -->
    <PaymentDetail
      v-else-if="currentStep === 3"
      :userTicket="userTicket"
      :ticket="ticketSelected"
      @click-prev="handleClickPrev"
      @click-save="handleClickSave"
      :saving="saving"
      :receivedUserId="receivedUserId"
    />
  </el-dialog>
</template>

<script>
import MemberList from './List';
import TicketDetail from './TicketDetail';
import PaymentDetail from './PaymentDetail';

export default {
  components: {
    MemberList,
    TicketDetail,
    PaymentDetail,
  },

  props: {
    show: Boolean,
    isProduct: Boolean,
    title: String,
    currentPoint: Number,
  },

  data() {
    return {
      currentStep: 1,
      ticketSelected: null,
      receivedUserId: null,
      saving: false,
      keyword: '',
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },
    loading() {
      return this.$store.getters['ticketEdit/loading'];
    },
    member() {
      return this.$store.getters['member/member'];
    },
    transferUserId() {
      return _.get(this.member, 'id');
    },
    transferMembers() {
      return this.$store.getters['ticketEdit/transferMembers'];
    },
    transferKeyword() {
      return this.$store.getters['ticketEdit/transferKeyword'];
    },
    transferDetail() {
      return this.$store.getters['ticketEdit/transferDetail'];
    },
    total() {
      return this.$store.getters['ticketEdit/total'];
    },
    pagination() {
      return this.$store.getters['ticketEdit/pagination'];
    },

    page: {
      get() {
        return this.pagination.page;
      },
      set(page) {
        this.setPagination({ page });
      },
    },
    limit: {
      get() {
        return this.pagination.limit;
      },
      set(limit) {
        this.setPagination({ limit });
      },
    },

    userTicket() {
      return _.get(this.formData, 'userTicket');
    },
  },

  watch: {
    transferKeyword() {
      this.setPagination({ page: 1 });
      this.dispatchGetMembersList();
    },
  },

  created() {
    this.setTransferListInit();
    this.dispatchGetMembersList();
  },

  methods: {
    setTransferListInit() {
      this.$store.commit('ticketEdit/SET_TRANSFER_KEYWORD', '');
      this.$store.commit('ticketEdit/SET_PAGINATION', { page: 1, total: 0 });
    },

    dispatchGetMembersList() {
      this.$store.dispatch('ticketEdit/getTransferMembers', this.transferUserId);
    },

    setPagination(values) {
      this.$store.commit('ticketEdit/SET_PAGINATION', values);
    },

    handleFilterChange({ limit }) {
      this.setPagination({ limit });
      this.dispatchGetMembersList();
    },

    searchMember(keyword) {
      this.$store.commit('ticketEdit/SET_TRANSFER_KEYWORD', keyword);
    },

    handleChangeKeyword() {
      this.searchMember(this.keyword);
    },

    /** Step 1 - 양도 받을 회원 선택시 */
    async handleClickCell(id) {
      this.receivedUserId = id;
      const { ticket } = this.userTicket;
      this.currentStep = 2;
      this.ticketSelected = ticket;
    },

    handleClickPrev() {
      this.currentStep--;
    },

    handleClickNext() {
      this.currentStep++;
    },

    async handleClickSave({ paymentDetail, currentPoint }) {
      if (!paymentDetail) return;

      const pointValidate = this.$utils.paymentValidation('ticket', paymentDetail.point_amount, currentPoint);
      if (pointValidate.error) {
        this.$utils.notify.error(this, '오류', pointValidate.message);
        return;
      }

      const title = `${this.title} 양도`;
      const message = `
      ${this.isProduct ? '' : `<p style="color: #f95454;">예약(예약 대기)은 자동으로 취소됩니다.</p>`}
        <p>${this.title}을 양도 하시겠습니까?</p>
      `;
      const proceed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (!proceed) return;

      try {
        this.saving = true;
        let params = {
          ...paymentDetail,
          ...this.transferDetail,
        };
        await this.$api.userTicket.transfer(this.userTicket.id, this.receivedUserId, params);
        this.$utils.notify.success(this, '확인', `${this.title}이 양도되었습니다.`);
        this.$router.push(`/users/detail?id=${this.receivedUserId}`);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.$store.commit('ticketEdit/SET_PAGINATION', { page: 1, total: 0 });
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-transfer__pagination {
  margin-top: 20px;
  text-align: center;
}

.ticket-transfer-modal {
  &.step1 {
    /deep/ .el-dialog {
      max-width: 960px;
    }
  }
  /deep/ .el-dialog {
    @include flex(column);
    width: 90%;
    max-width: 620px;
  }

  /deep/ .el-dialog__body {
    flex: 1;
  }

  /deep/ .custom-text-input {
    margin-bottom: 10px;
  }

  &__title {
    @include flex(row, center, flex-start);

    ul {
      @include flex(row, center, flex-start);

      li {
        @include flex(row, flex-start, center);
        background: white;
        border: 1px solid rgba($charcoal-grey, 0.2);
        border-radius: 50%;
        font-size: 9px;
        width: 18px;
        height: 18px;
        margin: 0 12px;
        position: relative;
      }

      li + li::before {
        content: '';
        width: 12px;
        border-bottom: 1px solid rgba($charcoal-grey, 0.2);
        position: absolute;
        top: 50%;
        left: -18px;
      }

      li.active {
        border-color: $charcoal-grey;
        background: $charcoal-grey;
        color: white;
      }
    }
  }
}
</style>
