<template>
  <section class="settings-divisions">
    <form-block :index="1" label="수업 구분 추가" required>
      <div class="settings-divisions__add" v-loading="divisionsLoading">
        <button v-if="!divisionCreating" class="settings-divisions__add__button" @click="handleClickAddDivision">
          + 수업 구분 추가
        </button>
        <div v-else class="settings-divisions__add__form">
          <input
            v-model="divisionCreating.title"
            ref="create-division"
            type="text"
            placeholder="수업 구분을 입력해주세요."
            @keypress.enter="handleClickAddSave(divisionCreating)"
          />
          <PlainButton @click="handleClickAddCancel()">취소</PlainButton>
          <BaseButton :disabled="!divisionCreating.title" @click="handleClickAddSave(divisionCreating)">저장</BaseButton>
        </div>
        <ul class="settings-divisions__add__list">
          <li v-for="division in divisions" :key="division.id" class="settings-divisions__add__list__item">
            <input
              v-if="isEditing(division)"
              v-model="divisionEditing.title"
              ref="edit-division"
              type="text"
              placeholder="수업 구분을 입력해주세요."
              @keypress.enter="handleClickEditSave(divisionEditing)"
            />
            <p v-else>{{ division.title }}</p>
            <PlainButton
              round
              @click="isEditing(division) ? handleClickEditSave(divisionEditing) : handleClickEditDivision(division)"
              >{{ isEditing(division) ? '저장' : '수정' }}</PlainButton
            >
            <PlainButton
              type="danger"
              round
              @click="isEditing(division) ? handleClickEditCancel() : handleClickDeleteDivision(division)"
              >{{ isEditing(division) ? '취소' : '삭제' }}</PlainButton
            >
          </li>
        </ul>
      </div>
    </form-block>
  </section>
</template>

<script>
export default {
  data() {
    return {
      divisions: [],
      divisionsLoading: false,
      divisionCreating: null,
      divisionEditing: null,
    };
  },

  created() {
    this.getDivisions();
  },

  methods: {
    async getDivisions() {
      try {
        this.divisionsLoading = true;
        const res = await this.$api.studio.divisions.get();
        this.divisions = res.data;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.divisionsLoading = false;
      }
    },

    handleClickAddDivision() {
      this.divisionCreating = { title: '' };
      this.$nextTick(() => {
        this.$refs['create-division'].focus();
      });
    },

    async handleClickAddSave(division) {
      try {
        await this.$api.studio.divisions.create(division);
        this.getDivisions();
        this.divisionCreating = null;
      } catch (error) {
        this.$utils.notify.error(this, '오류', '중복된 이름입니다.');
      }
    },

    handleClickAddCancel() {
      this.divisionCreating = null;
    },

    handleClickEditDivision(division) {
      this.divisionEditing = { ...division };
      this.$nextTick(() => {
        this.$refs['edit-division'][0].select();
      });
    },

    async handleClickEditSave(division) {
      try {
        await this.$api.studio.divisions.update(division);
        this.getDivisions();
        this.divisionEditing = null;
      } catch (error) {
        this.$utils.notify.error(this, '오류', '중복된 이름입니다.');
      }
    },

    handleClickEditCancel() {
      this.divisionEditing = null;
    },

    async handleClickDeleteDivision(division) {
      try {
        const { comma } = this.$filters;
        const { tickets_count, lectures_count } = division;
        const title = '수업 구분 삭제';
        let message = '';
        if (tickets_count && lectures_count) {
          message += `${comma(tickets_count)}개의 수강권, ${comma(
            lectures_count,
          )}개의 수업에 수업 구분으로 지정되어 있습니다.<br>`;
        } else if (tickets_count) {
          message += `${comma(tickets_count)}개의 수강권에 수업 구분으로 지정되어 있습니다.<br>`;
        } else if (lectures_count) {
          message += `${comma(lectures_count)}개의 수업에 수업 구분으로 지정되어 있습니다.<br>`;
        }
        message += '수업 구분을 삭제하시겠습니까?';

        const proceed = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (proceed) {
          await this.$api.studio.divisions.delete(division.id);
          this.$utils.notify.success(this, '확인', `<b>${division.title}</b> 수업 구분이 삭제 되었습니다.`);
          this.getDivisions();
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    isEditing(division) {
      return this.divisionEditing && this.divisionEditing.id === division.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-divisions {
  &__add {
    max-width: 382px;
    margin-top: 40px;

    &__button {
      font-size: 14px;
      font-weight: 500;
      color: $deep-sky-blue;
      border: solid 1px $deep-sky-blue;
      border-radius: 16px;
      margin: 0 16px;
      padding: 7px;
      width: calc(100% - 32px);
      transition: background 0.15s;

      &:hover {
        background: rgba($deep-sky-blue, 0.1);
      }
    }

    &__form {
      display: grid;
      grid-template-columns: 1fr 48px 48px;
      grid-gap: 8px;
      margin: 0 16px;

      input {
        border-radius: 2px;
        border: solid 1px rgba(#8a8a8a, 0.5);
        padding: 7px 4px;

        &:hover,
        &:focus {
          border-color: #8a8a8a;
        }

        &::placeholder {
          color: rgba(#8a8a8a, 0.5);
        }
      }

      button {
        height: 32px;
        border-radius: 16px;
      }
    }

    &__list {
      @include flex(column);
      margin-top: 20px;

      &__item {
        display: grid;
        grid-template-columns: 1fr 48px 48px;
        grid-template-rows: 32px;
        grid-gap: 8px;
        align-items: center;
        padding: 13px 16px;
        border-bottom: 1px solid rgba(#000, 0.08);

        input {
          border-radius: 2px;
          border: solid 1px rgba(#8a8a8a, 0.5);
          padding: 7px 4px;

          &:hover,
          &:focus {
            border-color: #8a8a8a;
          }

          &::placeholder {
            color: rgba(#8a8a8a, 0.5);
          }
        }

        p {
          @include ellipsis;
        }

        button {
          font-size: 14px;
          padding: 0;
          height: 24px;
          border-radius: 12px;
        }
      }
    }
  }
}
</style>
