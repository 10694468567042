import axios from '../axios';

export default {
  // 스튜디오 정보
  getId: subdomain => axios.get(`/studios/${subdomain}`),
  get: () => {
    return axios.get('/studios', {
      params: {
        with:
          'policy;subscriptionPayments;holidays;contactInfos;address;openHour.events.recurringPattern;closeHour.events.recurringPattern',
      },
    });
  },
  update: data => axios.patch('/studios', data),

  // 역할
  roles: {
    getAll: () => axios.get('/roles'),
    create: data => axios.post(`/roles`, data),
    update: (id, data) => axios.patch(`/roles/${id}`, data),
    delete: id => axios.delete(`/roles/${id}`),

    permissions: {
      get: id => axios.get(`/roles/${id}/permissions`),
      update: (roleId, permission_ids) => {
        return axios.post(`/roles/${roleId}/permissions`, {
          permission_ids,
        });
      },
    },
  },

  // 권한
  permissions: {
    get: typeId => axios.get(`/permissions?search=type_id:${typeId}`),
    getTypes: () => axios.get('/permissions/types'),
  },

  // 룸
  rooms: {
    get: () => axios.get('/staff/room'),
    getEmpty: params =>
      axios.get('/staff/room/empty', {
        params,
      }),
    create: room => axios.post('/staff/room', room),
    update: room => axios.patch(`/staff/room/${room.id}/update`, room),
    delete: id => axios.delete(`/staff/room/${id}`),
  },

  // 수업구분
  divisions: {
    get: () => axios.get('/v2/staff/division'),
    create: division => axios.post('/v2/staff/division', division),
    update: division => axios.patch(`/v2/staff/division/${division.id}`, division),
    delete: id => axios.delete(`/v2/staff/division/${id}`),
  },

  // 회원 등급
  userGrades: {
    get: () => axios.get('/v2/staff/user-grade'),
    create: grade => axios.post('/v2/staff/user-grade', grade),
    update: grade => axios.patch(`/v2/staff/user-grade/${grade.id}`, grade),
    delete: id => axios.delete(`/v2/staff/user-grade/${id}`),
  },

  // 휴일 설정
  holidays: {
    create: holiday =>
      axios.post('/staff/studio/holiday', {
        holiday,
      }),
    delete: id => axios.delete(`/staff/studio/holiday/${id}`),
  },

  //서비스 결제 약관 동의
  agreeTerms: {
    update: () =>
      axios.patch('/studios/terms', {
        is_terms: 1,
      }),
  },

  // 캐시 처리된 point 조회
  getPoint: () => {
    return axios.get(`v2/staff/point`);
  },
};
