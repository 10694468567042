<template>
  <div v-loading="loading">
    <div class="send-message-group">
      <div class="send-message-group__select">
        <el-select
          v-for="(feature, key) in featureFilter"
          :disabled="feature.type === 'selected' && !memberSelected.length"
          :key="key"
          :placeholder="feature.label"
          :value="feature.label"
          @change="value => featureFilterChange(feature.type, value)"
        >
          <el-option label="문자 메시지 전송" value="message"></el-option>
          <el-option label="수강권 연장" value="ticketExtend" :disabled="filterValues.type === null"></el-option>
          <el-option label="수강권 정지" value="ticketStop" :disabled="isAllOrInactiveFilter"></el-option>
          <el-option
            label="포인트"
            value="point"
            :disabled="feature.type === 'selected' ? !memberSelected.length : isAllOrInactiveFilter"
          ></el-option>
        </el-select>
      </div>
    </div>

    <el-table
      ref="membersTable"
      :data="members"
      :key="`${isSelectedAll}${isIndeterminate}`"
      @cell-click="handleCellClick"
      empty-text="회원이 없습니다"
      row-class-name="members-list__row"
      fit
      @sort-change="sortChange"
    >
      <el-table-column label="selection" width="50" align="center">
        <span slot="header">
          <el-tooltip effect="light" content="전체 선택" placement="top">
            <Checkbox
              type="multi"
              :checked="isSelectedAll"
              :indeterminate="isIndeterminate"
              @change="handleToggleSelectAll"
            ></Checkbox>
          </el-tooltip>
        </span>
        <template v-slot="scope">
          <Checkbox :checked="selectedMemberIds.includes(scope.row.id)" @click.native.stop="checkedMember(scope.row)"></Checkbox>
        </template>
      </el-table-column>

      <el-table-column
        prop="sort_name"
        label="이름"
        class-name="sort-confirm"
        min-width="150"
        header-align="center"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      >
        <template v-slot="scope">
          <div class="members-list__member-profile">
            <img :src="getShowingImageSrc(scope.row)" :alt="scope.row.name" />
            <div class="members-list__name-wrapper">
              <h5>{{ scope.row.name }}</h5>
              <img
                v-if="scope.row.vaccination_yn === 'Y'"
                class="vaccine"
                src="@/assets/images/icons/vaccine.svg"
                alt="백신 접종 완료 후 14일 경과"
              />
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="" min-width="70" header-align="center" align="center">
        <template v-slot="scope">
          <div class="members-list__inactive-member" v-if="scope.row.inactiveMember">
            <p>만료 회원</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column v-if="useUserGrades" label="회원등급" min-width="100" header-align="center" align="center">
        <template v-slot="scope">
          <span
            v-if="!!getUserGrade(scope.row)"
            class="members-list__user-grade"
            :style="{ 'background-color': `#${scope.row.user_grade.representative_color}` }"
          >
            {{ getUserGrade(scope.row) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="전화번호" min-width="130" header-align="center" align="center">
        <template v-slot="scope">
          {{ getMobileNumber(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column
        prop="sort_date"
        label="등록일"
        class-name="sort-confirm"
        min-width="110"
        header-align="center"
        align="center"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      >
        <template v-slot="scope">
          {{ scope.row.registered_at | date }}
        </template>
      </el-table-column>

      <el-table-column
        prop="sort_attendance"
        label="최근출석일"
        class-name="sort-confirm"
        min-width="110"
        v-if="showLastAttendedOn"
        align="center"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      >
        <template v-slot="scope">
          {{ scope.row.lastAttendance | date }}
        </template>
      </el-table-column>

      <el-table-column label="보유수강권" min-width="550" header-align="center">
        <template v-slot="scope">
          <ul class="members-list__ticket-list">
            <li v-for="userTicket in getUserTicketsArray(scope.row.userTickets)" :key="userTicket.id">
              <div class="members-list__ticket-list__title">
                {{ userTicket.ticket.title }}
              </div>
              <div class="members-list__ticket-list__tags">
                <div class="ticket-info-wrapper" v-if="!isRentalOnlyTime(userTicket.ticket)">
                  <el-tag size="medium" type="info">
                    {{ userTicket.availability_start_at | date }} ~
                    {{ userTicket.expire_at | date }}
                  </el-tag>
                  <el-tag v-if="!isExpired(userTicket)" size="medium" type="info">
                    {{ $utils.getRemainingDaysText(userTicket.expire_at, userTicket.availability_start_at) }}
                  </el-tag>
                </div>
                <el-tag size="medium" type="info" v-if="isNotPeriodType(userTicket.ticket) && !isExpired(userTicket)">
                  잔여 횟수 {{ userTicket.remaining_coupon }}/{{ userTicket.max_coupon }}
                </el-tag>
                <el-tag v-if="userTicket.is_holding" size="medium" type="danger">
                  {{ $utils.getHoldingPeriodText(userTicket.holdings) }}
                </el-tag>
                <el-tag v-if="isExpired(userTicket)" size="medium" type="danger">
                  {{ getExpiredStatus(userTicket) }}
                </el-tag>
              </div>
            </li>
          </ul>
        </template>
      </el-table-column>

      <el-table-column v-if="studioPolicies.is_locker" label="락커" min-width="180" header-align="center">
        <template v-slot="scope">
          <ul class="members-list__locker-list">
            <li v-for="lockers in scope.row.lockers" :key="lockers.id">
              <div class="members-list__locker-list__title">{{ lockers.no }}. {{ lockers.name }}</div>
              <div class="members-list__ticket-list__tags">
                <el-tag size="medium" type="info">
                  {{ lockers.end_on | date }}
                </el-tag>
              </div>
            </li>
          </ul>
        </template>
      </el-table-column>

      <el-table-column v-if="showAppLink" width="80" header-align="center" align="center">
        <template slot="header">
          <div class="members-list__app-link-header">
            <span>앱연결</span>
            <el-tooltip effect="light" placement="bottom-end">
              <i class="el-icon-question"></i>
              <img
                slot="content"
                src="@/assets/images/linked-account-guide.png"
                srcset="@/assets/images/linked-account-guide@2x.png 2x, @/assets/images/linked-account-guide@3x.png 3x"
                alt="앱연결 가이드"
              />
            </el-tooltip>
          </div>
        </template>
        <template v-slot="scope">
          <el-tooltip effect="light" placement="left">
            <div class="members-list__app-link" :class="{ has_email: !!scope.row.email }">
              <img :src="getAppLinkImage(scope.row)" :alt="scope.row.has_user_account ? '연결' : '미연결'" />
            </div>
            <div slot="content" v-if="scope.row.email" class="members-list__user-email">
              <span :ref="scope.row.email">{{ scope.row.email }}</span>
              <button
                v-clipboard:copy="scope.row.email"
                v-clipboard:success="
                  () => {
                    handleEmailCopySuccess(scope.row.email);
                  }
                "
              >
                <i class="el-icon-document"></i>
                <i class="el-icon-document"></i>
              </button>
            </div>
            <div slot="content" v-else class="members-list__user-email">
              <div class="members-list__user-email__text-group">
                <span :ref="scope.row.email">앱 링크 문자 보내기</span>
                <span v-if="!getMobileNumber(scope.row)" style="color:red;">전화번호가 없습니다</span>
              </div>
              <button
                v-if="getMobileNumber(scope.row)"
                :disabled="!getMobileNumber(scope.row)"
                @click="sendAppLinkMessage(scope.row)"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    d="M17.5,4 C18.8807119,4 20,5.11928813 20,6.5 L20,6.5 L20,14.5 C20,15.8807119 18.8807119,17 17.5,17 L17.5,17 L10.667,17 L6.8,19.9 C6.43091948,20.1768104 5.93334674,19.8501113 6.00531881,19.4230195 L6.00531881,19.4230195 L6.02565835,19.3418861 L6.805,17 L6.5,17 C5.1745166,17 4.08996133,15.9684641 4.00531768,14.6643757 L4.00531768,14.6643757 L4,14.5 L4,6.5 C4,5.11928813 5.11928813,4 6.5,4 L6.5,4 Z M17.5,5 L6.5,5 C5.67157288,5 5,5.67157288 5,6.5 L5,6.5 L5,14.5 C5,15.3284271 5.67157288,16 6.5,16 L6.5,16 L7.5,16 C7.84127886,16 8.0822635,16.3343483 7.97434165,16.6581139 L7.97434165,16.6581139 L7.48,18.14 L10.2,16.1 C10.2649111,16.0513167 10.3403514,16.0194084 10.4196552,16.0064969 L10.4196552,16.0064969 L10.5,16 L17.5,16 C18.3284271,16 19,15.3284271 19,14.5 L19,14.5 L19,6.5 C19,5.67157288 18.3284271,5 17.5,5 L17.5,5 Z M12.5,13 C12.7761424,13 13,13.2238576 13,13.5 C13,13.7761424 12.7761424,14 12.5,14 L7.5,14 C7.22385763,14 7,13.7761424 7,13.5 C7,13.2238576 7.22385763,13 7.5,13 L12.5,13 Z M14.5,10 C14.7761424,10 15,10.2238576 15,10.5 C15,10.7761424 14.7761424,11 14.5,11 L8.5,11 C8.22385763,11 8,10.7761424 8,10.5 C8,10.2238576 8.22385763,10 8.5,10 L14.5,10 Z M10.5,7 C10.7761424,7 11,7.22385763 11,7.5 C11,7.77614237 10.7761424,8 10.5,8 C10.2238576,8 10,7.77614237 10,7.5 C10,7.22385763 10.2238576,7 10.5,7 Z M16.5,7 C16.7761424,7 17,7.22385763 17,7.5 C17,7.77614237 16.7761424,8 16.5,8 L13.5,8 C13.2238576,8 13,7.77614237 13,7.5 C13,7.22385763 13.2238576,7 13.5,7 L16.5,7 Z"
                    id="Combined-Shape"
                    fill="#757575"
                  ></path>
                </svg>
              </button>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog class="message-modal" :visible.sync="showMessageDialog">
      <CustomTabs class="message-modal__tabs" active_tab="sms" :tabs="[{ value: 'sms', label: 'SMS 보내기' }]" />
      <MessageForm
        v-if="showMessageDialog"
        hide-member-list-button
        is-on-modal
        :members="[messageMember]"
        messageType="sms"
        :defaultMessage="defaultMessage.body"
        :defaultMessageTitle="defaultMessage.title"
        @messageSent="showMessageDialog = false"
      />
    </el-dialog>

    <ExtendTicket
      :show="showExtendTicket"
      :memberIdsSelected="memberIdsSelected"
      :ticket="ticket"
      :memberSelected="memberSelected"
      @close="onClose"
      :filterValues="filterValues"
      :sortType="sortType"
      :featureValues="featureValues"
      :allMembers="allMembers"
      :getMemberAll="getMemberAll"
      :extendMemoData="extendMemoData"
      :getMemos="getMemos"
      :dispatchGetMembersList="dispatchGetMembersList"
      :pagination="pagination"
    />

    <BatchStopTicket
      :show="showBatchStopTicket"
      :ticket="ticket"
      @close="onClose"
      :filterValues="filterValues"
      :sortType="sortType"
      :featureValues="featureValues"
      :allMembers="allMembers"
      :getMemberAll="getMemberAll"
      :stopMemoData="stopMemoData"
      :getMemos="getMemos"
      :dispatchGetMembersList="dispatchGetMembersList"
      :pagination="pagination"
      :selectedMembers="selectedMembers"
      :selectedMemberIds="selectedMemberIds"
    />

    <PointEditModal
      title="포인트 일괄 적용"
      :desc="editModalDesc"
      :show="showBatchPointEdit"
      :pointEdit="pointEdit"
      :currentMemberIds="currentMemberIds"
      @close="onClose"
      @change="onChange"
    />
  </div>
</template>

<script>
import MessageForm from '@/components/Messages/Form';
import ExtendTicket from '@/components/Modals/ExtendTicket';
import BatchStopTicket from '@/components/Modals/BatchStopTicket';
import PointEditModal from '@components/Modals/PointEdit';

export default {
  components: {
    MessageForm,
    ExtendTicket,
    BatchStopTicket,
    PointEditModal,
  },

  props: {
    members: Array,
    filterValues: { type: Object, required: true },
    showAppLink: { type: Boolean, default: true },
    showLastAttendedOn: { type: Boolean, default: false },
    sendMessageCheked: Function,
    sendMessageAll: Function,
    sortRule: { type: Object, required: true },
    sortType: { type: Object, required: true },
    reset: Boolean,
    getMemberAll: Function,
    dispatchGetMembersList: Function,
    pagination: Object,
  },

  data() {
    return {
      showMessageDialog: false,
      messageMember: {},
      memberSelected: [],
      memberIdsSelected: [],
      showExtendTicket: false,
      showBatchStopTicket: false,
      showBatchPointEdit: false,

      pointEdit: {
        editType: 'reward',
        editReason: '',
        rewardPoint: 0,
        deductPoint: 0,
      },

      loading: false,

      /** 드롭다운 메뉴를 위한 데이터(필터 기준 연장, 정지) */
      featureFilter: [
        { label: '필터된 회원에게', type: 'filtered' },
        { label: '선택된 회원에게', type: 'selected' },
      ],
      featureValues: null,
      allMembers: [],
      extendMemoData: {},
      stopMemoData: {},
    };
  },

  beforeMount() {
    this.memberSelected = [...this.$store.getters['members/selectedMembers']];
    this.memberIdsSelected = [...this.$store.getters['members/selectedMemberIds']];
  },

  created() {
    const subDomain = window.location.hostname.split('.')[0];
    this.getMemos(subDomain);
  },

  computed: {
    selectedOnlyPeriodTickets() {
      return this.memberSelected.every(({ userTickets: { ticket } }) => ticket.type === 'P' || ticket.type === 'RP');
    },
    useUserGrades() {
      return !!_.get(this.studioPolicies, 'is_use_user_grade');
    },

    defaultMessage() {
      const stuidoName = this.$store.getters['studio/studio'].name;
      return {
        title: '회원앱 다운로드 링크입니다.',
        body: `[${stuidoName}] 안녕하세요, 회원님. 어플 다운로드 링크 보내드립니다.
                회원님의 기간 조회, 수업 예약 등을 간편하게 이용하실 수 있습니다.

                안드로이드 - https://play.google.com/store/apps/details?id=kr.studiomate.user
                아이폰 - https://apps.apple.com/kr/app/스튜디오메이트-회원용/id1400126585`,
      };
    },

    memberIdsOnPage() {
      return this.members.map(({ id }) => id);
    },

    isSelectedAll() {
      return !!this.members.length && this.memberIdsOnPage.every(id => this.memberIdsSelected.includes(id));
    },

    isIndeterminate() {
      return !this.isSelectedAll && this.memberIdsOnPage.some(id => this.memberIdsSelected.includes(id));
    },

    ticket() {
      return this.$store.getters['ticket/ticket'];
    },

    selectedMembers() {
      return this.$store.getters['members/selectedMembers'];
    },

    selectedMemberIds() {
      return this.$store.getters['members/selectedMemberIds'];
    },

    isAllOrInactiveFilter() {
      const isAllFilter = this.filterValues.type === null;
      const isInactiveFilter = this.filterValues.type === 'inactive';
      return isAllFilter || isInactiveFilter;
    },

    isSelected() {
      return this.featureValues === 'selected';
    },

    currentMemberIds() {
      const memberIds = this.isSelected ? this.selectedMemberIds : this.allMembers.map(member => member.id);
      return memberIds;
    },

    editModalDesc() {
      const feature = this.isSelected ? '선택된' : '필터된';
      const type = this.pointEdit.editType === 'reward' ? '적립' : '차감';
      return `${feature} 회원에게 포인트를 ${type}합니다.`;
    },
  },

  methods: {
    sortChange(e) {
      this.sortType.sort_name = null;
      this.sortType.sort_date = null;
      this.sortType.sort_attendance = null;

      this.sortType[e.prop] = e.order === 'ascending' ? 'asc' : 'desc';
      this.changePage();
    },

    changePage() {
      this.$emit('filter-change', { ...this.filterValues, ...this.sortType });
    },

    handleToggleSelectAll() {
      if (!this.isSelectedAll) {
        this.memberSelected = _.uniqBy([...this.memberSelected, ...this.members], 'id');
      } else {
        this.memberIdsOnPage.forEach(memberId => {
          const index = this.memberSelected.findIndex(el => el.id === memberId);
          if (index !== -1) {
            this.memberSelected.splice(index, 1);
          }
        });
      }
      this.memberIdsSelected = this.memberSelected.map(({ id }) => id);
      this.handleSelected();
    },

    checkedMember(data) {
      this.memberSelected = [...this.selectedMembers];
      this.memberIdsSelected = [...this.selectedMemberIds];

      if (this.selectedMemberIds.includes(data.id)) {
        this.memberIdsSelected = this.selectedMemberIds.filter(memberId => memberId !== data.id);
        this.memberSelected = this.selectedMembers.filter(member => member.id !== data.id);
      } else {
        this.memberIdsSelected.push(data.id);
        this.memberSelected.push(data);
      }
      this.handleSelected();
    },

    handleSelected() {
      this.$store.commit('members/SET_SELECTED_MEMBERS', this.memberSelected);
      this.$store.commit('members/SET_SEND_MESSAGE_COUNT', this.memberSelected.length);
    },

    handleCellClick(row, column) {
      if (column.label !== '앱연결') {
        this.$router.push('/users/detail?id=' + row.id);
      }
    },

    getUserGrade({ user_grade }) {
      if (!user_grade) return null;
      const { exceeded, exceededAt } = this.$utils.checkBytesOfString(user_grade.name, 5);

      return !exceeded ? user_grade.name : `${user_grade.name.slice(0, exceededAt)}...`;
    },

    getMobileNumber({ mobile }) {
      if (!mobile) return null;
      return this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);
    },

    getUserTicketsArray(tickets) {
      const ticketArray = tickets.filter(({ ticket }) => ticket.available_class_type !== 'I');
      const rentalProductArray = tickets.filter(({ ticket }) => ticket.available_class_type === 'I' && ticket.type !== 'S');
      return [...ticketArray, ...rentalProductArray];
    },

    getAppLinkImage({ has_user_account }) {
      return has_user_account
        ? require('@/assets/images/01_graphic/ic_16x16/solid/app-active.png')
        : require('@/assets/images/01_graphic/ic_16x16/solid/app-inactive.png');
    },

    handleEmailCopySuccess(email) {
      this.$message({
        message: `이메일이 복사되었습니다 (${email})`,
        type: 'success',
        duration: 2000,
      });
    },

    sendAppLinkMessage(row) {
      this.messageMember = row;
      this.showMessageDialog = true;
    },

    isRentalOnlyTime(ticket) {
      const ticketType = _.get(ticket, 'type');
      return ticketType === 'RT';
    },

    isNotPeriodType(ticket) {
      const ticketType = _.get(ticket, 'type');
      return ticketType === 'T' || ticketType === 'RT' || ticketType === 'RM';
    },

    isExpired(userTicket) {
      return this.$utils.getTicketStatus(userTicket) === '이용만료';
    },

    getExpiredStatus({ expire_at, remaining_coupon }) {
      if (remaining_coupon <= 0) return '횟수만료';

      const remainingDays = this.$utils.getDaysDiff(expire_at);
      if (remainingDays < 0) return '기간만료';

      return null;
    },

    getShowingImageSrc(row) {
      if (row.avatars) {
        return this.$utils.getImagePath(_.get(row, 'avatars'), 36);
      } else if (row.accountAvatar) {
        return this.$utils.getImagePath(_.get(row, 'accountAvatar'), 36);
      } else {
        return this.$utils.getImagePath(_.get(row, 'accountAvatarImage'), 36);
      }
    },

    async featureFilterChange(feature, value) {
      this.featureValues = feature;

      if (value === 'ticketExtend' || value === 'ticketStop' || value === 'point') {
        const withParams = 'profile;account;userGrade;avatars;tickets.ticket';
        this.allMembers = await this.getMemberAll(withParams, false);
      }

      switch (value) {
        case 'message':
          if (feature === 'filtered') this.$emit('sendMessageAll');
          else this.$emit('sendMessageCheked', this.memberSelected);
          break;
        case 'ticketExtend':
          this.showExtendTicket = true;
          break;
        case 'ticketStop':
          this.showBatchStopTicket = true;
          break;
        case 'point':
          this.showBatchPointEdit = true;
      }
    },

    onClose(type, refresh) {
      if (refresh) this.closeRefresh();

      switch (type) {
        case 'extend':
          this.showExtendTicket = false;
          break;
        case 'stop':
          this.showBatchStopTicket = false;
          break;
        case 'point':
          this.showBatchPointEdit = false;
          this.resetPointEdit();
      }
    },

    resetPointEdit() {
      this.pointEdit = {
        editType: 'reward',
        rewardPoint: 0,
        deductPoint: 0,
        editReason: '',
      };
    },

    closeRefresh() {
      this.$store.commit('members/SET_SEND_MESSAGE_COUNT', this.memberSelected.length);
    },

    async getMemos(subDomain) {
      const studioId = await this.$api.studio.getId(subDomain);
      const extendData = await this.$api.userTicket.batchExtend(studioId.data.id);
      const stopData = await this.$api.userTicket.batchStop(studioId.data.id);

      this.extendMemoData = extendData;
      this.stopMemoData = stopData;
    },

    onChange(type, value) {
      if (type === 'point') {
        const currentPointType = value.type === 'reward' ? 'rewardPoint' : 'deductPoint';
        this.pointEdit = { ...this.pointEdit, [currentPointType]: value.value };
        return;
      }
      this.pointEdit = { ...this.pointEdit, [type]: value };
    },
  },

  watch: {
    reset() {
      this.$refs.membersTable.clearSort();
      this.$emit('reset-event', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.members-list {
  &__member-profile {
    display: grid;
    grid-template-columns: 36px 1fr;
    grid-gap: 5px;
    align-items: center;

    img {
      border-radius: 50%;
      width: 36px;
      height: 36px;
    }

    .members-list__name-wrapper {
      display: flex;
      align-items: center;

      .vaccine {
        width: 18px;
        height: 18px;
        margin-left: 3px;
      }
    }
  }

  &__inactive-member {
    background-color: rgba(245, 108, 108, 0.1);
    border: 1px solid rgba(245, 108, 108, 0.2);
    color: $coral;
    width: 60px;
    height: 20px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    border-radius: 5px;
  }

  &__user-grade {
    background: #757575;
    color: #fff;
    font-size: 11px;
    padding: 0 8px;
    border-radius: 4px;
  }

  &__ticket-list,
  &__locker-list {
    li {
      display: flex;
      margin: 6px 6px 6px 0;
    }

    &__title {
      @include ellipsis;
      @include flex(row, center);
      width: 120px;
      text-align: left;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      padding-left: 10px;

      span {
        font-size: 14px;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }
      .ticket-info-wrapper {
        margin-right: 4px;
      }
    }
  }

  &__app-link {
    background: $gray-100;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    transition: background 0.15s;

    &.has_email:hover {
      cursor: pointer;
      background: rgba($link-color, 0.1);
    }

    &-header {
      @include flex(row, center);
      margin: 0;
      padding: 0;

      i {
        color: $deep-sky-blue;
        font-size: 18px;
        margin-left: 4px;
      }
    }
  }

  &__user-email {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;

    &__text-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    span {
      text-align: right;
      transition: 0.15s;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-left: 4px;
      transition: background 0.15s, color 0.15s;

      &:hover {
        background: $link-color;
        color: white;
        svg {
          path {
            fill: #eee;
          }
        }
      }

      i:first-child {
        transform: translate(3px, 2px);
      }

      i:last-child {
        transform: translate(-3px, -2px);
      }
    }
  }
}

.el-table {
  /deep/ .members-list__row {
    cursor: pointer;
  }
}

.message-modal {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px 20px;
  }

  &__tabs {
    justify-content: flex-start;
    padding-bottom: 30px;
  }
}

.send-message-group {
  position: absolute;
  top: 0;
  right: 155px;

  button {
    margin-right: 10px;
    height: 36px;
    border-radius: 4px;
  }

  .el-button {
    margin-right: 0;
  }

  &__select {
    @include flex(row, center, flex-start);
    flex-wrap: wrap;

    /deep/ .el-select {
      margin: 30px 6px 0 6px;
    }

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 36px;
      width: 150px;
      padding: 0 15px;
    }
  }

  .is-filtered {
    color: $color-primary;

    /deep/ .el-input__inner {
      color: $color-primary;
    }
  }
}
</style>
