<template>
  <MainLayout v-loading="loading">
    <ScheduleHeader />
    <CalendarControls />
    <Calendar />
    <Noti :show="isShowModal" @close="noSeeOneDay" :data="noticeData" />
  </MainLayout>
</template>

<script>
import ScheduleHeader from '@components/Schedule/Header';
import CalendarControls from '@components/Schedule/CalendarControls';
import Calendar from '@components/Schedule/Calendar';
import Noti from '@components/Modals/Noti';

export default {
  components: {
    ScheduleHeader,
    CalendarControls,
    Calendar,
    Noti,
  },
  data() {
    return {
      isShowModal: false,
      loading: false,
      noticeData: [],
    };
  },

  async created() {
    let noticeLimitTime = null;
    if (!this.$utils.localStorage.get('notice')) {
      this.$utils.localStorage.set('notice', null);
    } else {
      noticeLimitTime = this.$utils.localStorage.get('notice');
    }

    try {
      this.loading = true;
      const res = await this.$api.notification.getNoti({ target_date: noticeLimitTime });
      if (res.data.length) {
        this.isShowModal = true;
        this.noticeData = res.data;
        this.$store.commit('boardNotice/GET_NEW_NOTICE', true);
      } else {
        this.$store.commit('boardNotice/GET_NEW_NOTICE', false);
      }
    } finally {
      this.loading = false;
    }
  },

  methods: {
    noSeeOneDay(close, isSeeNoti) {
      this.isShowModal = close;
      if (isSeeNoti) {
        this.$utils.localStorage.set('notice', this.moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        this.$store.commit('boardNotice/GET_NEW_NOTICE', false);
      }
    },
  },
};
</script>
