<template>
  <section class="subheader">
    <div class="subheader__tabs">
      <el-card v-for="tab in tabs" :key="tab.value" :shadow="activeTab === tab.value ? 'always' : 'hover'">
        <div
          class="subheader__tabs__tab"
          :class="{ active: activeTab === tab.value }"
          @click="handleChangeTab(tab.value)"
        >
          {{ tab.label }}
        </div>
      </el-card>
    </div>

    <div class="subheader__subtabs" v-if="!(this.ruleType === 'daterange' || this.activeTab === 'unpayedRevenue')">
      <el-card v-for="tab in subTabs" :key="tab.value" :shadow="activeSubTab === tab.value ? 'always' : 'hover'">
        <div
          class="subheader__subtabs__tab"
          :class="{ active: activeSubTab === tab.value }"
          @click="handleChangeSubTab(tab.value)"
        >
          <svg
            v-if="tab.value === 'dashboard'"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M12 3.75V12h9a9 9 0 1 1-9-9v.75zm-1 .312A8.001 8.001 0 0 0 12 20a8.001 8.001 0 0 0 7.938-7H11V4.062zm3-1.017V10h6.955C20.59 5.996 18.004 3.41 14 3.045zM21.25 11H13V2c5.465 0 9 3.535 9 9h-.75z"
            />
          </svg>
          <svg
            v-if="tab.value === 'list'"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M12 3.414V10h6.586L12 3.414zM19 11h-8V3H5v18h14V11zm-7-9l8 8v12H4V2h8zM7 14h10v1H7v-1zm0 3h10v1H7v-1z"
            />
          </svg>
          <span>{{ tab.label }}</span>
        </div>
      </el-card>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    ruleType: { type: String, required: true },
  },

  data() {
    return {
      tabs: [
        { value: 'ticketsRevenue', label: '수강권 매출' },
        { value: 'lecturesRevenue', label: '수업 매출' },
        { value: 'unpayedRevenue', label: '남은 미수금' },
      ],
      subTabs: [
        { value: 'dashboard', label: '차트' },
        { value: 'list', label: '목록' },
      ],
    };
  },

  computed: {
    activeTab() {
      return this.$store.getters['sales/activeTab'];
    },
    activeSubTab() {
      return this.$store.getters['sales/activeSubTab'];
    },
  },

  methods: {
    handleChangeTab(value) {
      this.$store.commit('sales/SET_ACTIVE_TAB', value);
      this.$store.commit('sales/SET_LIST_FILTER');
    },

    handleChangeSubTab(value) {
      this.$store.commit('sales/SET_ACTIVE_SUB_TAB', value);
      this.$router.push({ query: { ...this.$route.query, tab: value } }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.subheader {
  @include flex(row, center, space-between);
  flex-wrap: wrap;
  padding: 16px 0 8px;

  @include mq(768px) {
    padding-bottom: 12px;
  }

  /deep/ .el-card {
    border: none;
    border-radius: 4px;
  }
  /deep/ .el-card__body {
    padding: 0;
  }

  &__tabs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    width: 100%;

    @include mq(768px) {
      grid-template-columns: repeat(3, minmax(auto, 160px));
      grid-gap: 12px;
      width: auto;
    }

    &__tab {
      @include flex(row, center, center);
      border: 1px solid $gray-200;
      border-radius: 4px;
      cursor: pointer;
      font-size: 18px;
      height: 48px;
      padding: 0 8px;
      transition: 0.15s background, 0.15s border, 0.15s color;

      @include mq(768px) {
        height: 56px;
        padding: 0 16px;
      }

      &.active {
        border-color: $color-primary;
        background: $color-primary;
        font-weight: bold;
        color: #fff;
      }
    }
  }

  &__subtabs {
    margin-left: auto;
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(2, 48px);
    grid-gap: 8px;

    @include mq(768px) {
      grid-template-columns: repeat(2, 56px);
      grid-gap: 12px;
      margin-top: 0;
    }

    &__tab {
      @include flex(row, center, center);
      border: 1px solid $gray-200;
      border-radius: 4px;
      cursor: pointer;
      font-size: 24px;
      width: 48px;
      height: 48px;
      position: relative;

      span {
        font-size: 15px;
        opacity: 0;
      }
      svg {
        fill: $gray-300;
        width: 32px;
        height: 32px;
        opacity: 1;
      }
      span,
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.15s opacity;
      }

      @include mq(768px) {
        font-size: 32px;
        width: 56px;
        height: 56px;
      }

      &.active {
        background: $color-primary;
        color: #fff;

        svg {
          fill: #fff;
        }
      }

      &:hover {
        span {
          opacity: 1;
        }
        svg {
          opacity: 0;
        }
      }
    }
  }
}
</style>
