<template>
  <el-dialog class="delete-message-template-modal" :visible.sync="show" :before-close="onClose">
    <div slot="title" class="delete-message-template-modal__header">문자를 삭제하시겠습니까?</div>
    <div class="delete-message-template-modal__main">
      <div slot="footer">
        <el-button @click="deleteTemplate" type="primary"> 확인 </el-button>
        <el-button @click="onClose" type="primary"> 취소 </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    onClose: Function,
    show: Boolean,
  },
  methods: {
    async deleteTemplate() {
      await this.$store.dispatch('template/deleteTemplate');
      await this.dispatchGetTemplateList(1);
      this.onClose();
    },

    async dispatchGetTemplateList(page) {
      if (page) {
        await this.$store.dispatch('template/updatePage', page);
      }
      await this.$store.dispatch('template/getAllTemplates');
    },
  },
};
</script>

<style></style>
