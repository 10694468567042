<template>
  <div
    class="userticket-card"
    :class="{
      P: courseType === 'P',
      G: courseType === 'G',
      I: courseType === 'I',
      clickable,
      disabled: !addNew && disabled,
      expired: !addNew && expired,
      holding: !addNew && holding,
      showPrice,
    }"
  >
    <div v-if="clickable" class="userticket-card__click-listener" @click="$emit('click', userTicket.id)" />

    <div v-if="addNew" class="userticket-card__meta" :class="{ addNew }" />

    <div
      v-else
      class="userticket-card__meta"
      :style="
        !addNew && !disabled && !expired && !holding && haveCustomColor
          ? `background-image: linear-gradient(to bottom, #${ticketColor[0]},#${ticketColor[1]})`
          : ''
      "
    >
      <div class="userticket-card__header">
        <h5>{{ ticketTypeString }}</h5>
        <CircleButton v-if="notIssued" class="userticket-card__header__button" size="small" @click="$emit('remove')">
          <i class="el-icon-close"></i>
        </CircleButton>
        <span v-else class="userticket-card__header__tag">{{ ticketTag }}</span>
      </div>
      <div class="userticket-card__body">
        <h3>{{ ticketTitle }}</h3>
        <div>
          <span v-if="isShared" class="userticket-card__body__is-shared">패밀리</span>
          <span v-if="ticket.is_ignore_new_payment" class="userticket-card__body__is-shared">체험권</span>
        </div>
      </div>
      <div class="userticket-card__footer">
        <p v-if="!(ticketType === 'S' || disabled || isRentalOnlyTime)">{{ ticketPeriod }}</p>
        <p class="userticket-card__footer__counts">
          <span v-for="count in ticketCounts" :key="count.label">
            {{ `${count.label} ${count.value}` }}
          </span>
        </p>
        <p v-if="this.ticketTag === '사용불가'">{{ ticketInactiveReason }}</p>
      </div>
    </div>

    <div v-if="addNew" class="userticket-card__extra">
      <p class="userticket-card__extra__add-new">{{ `+ ${addNewLabel}` }}</p>
    </div>

    <div v-if="showPrice" class="userticket-card__extra">
      <div class="userticket-card__extra__price">
        <span class="userticket-card__extra__price__total">{{ amountTitleText }} {{ ticketPrice.total | comma }}원</span>
        <span>
          <span class="userticket-card__extra__price__per-coupon">
            {{ pricePerCoupon }}
          </span>
          <span class="userticket-card__extra__price__unpaid">
            {{ unpaidAmount }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@utils';
import { PAYMENT_VAR } from '@constants';

export default {
  props: {
    userTicket: { type: Object, default: () => ({}) },
    clickable: { type: Boolean, default: false },
    showPrice: { type: Boolean, default: false },
    addNew: { type: Boolean, default: false },
    addNewLabel: { type: String, default: '새로운 수강권 만들기' },
    notIssued: { type: Boolean, default: false },
    amountTitleText: { type: String, default: '판매 금액' },
  },

  computed: {
    ticket() {
      return _.get(this.userTicket, 'ticket');
    },
    courseType() {
      return _.get(this.ticket, 'available_class_type');
    },
    ticketType() {
      return _.get(this.ticket, 'type');
    },
    ticketTypeString() {
      return this.$utils.translate.ticketType(this.ticket, this.userTicket, 'userTicket');
    },
    ticketTitle() {
      return _.get(this.ticket, 'title');
    },

    ticketTag() {
      return !this.userTicket
        ? null
        : this.$utils
            .getTicketStatus(this.userTicket)
            .split('(')[0]
            .trim();
    },
    haveCustomColor() {
      return this.ticket && this.ticket.colors.length;
    },
    ticketColor() {
      return _.get(this.ticket, 'colors');
    },
    holding() {
      return this.ticketTag === '정지중';
    },
    disabled() {
      if (this.isSaleProductOnLocalStorage || this.notIssued) {
        return false;
      }
      return ['사용불가', '양도', '환불', '판매'].includes(this.ticketTag);
    },
    expired() {
      return this.ticketTag === '이용만료';
    },

    ticketInactiveReason() {
      return _.get(this.userTicket, 'inactive_reason');
    },
    isShared() {
      return _.get(this.userTicket, 'is_shared');
    },
    isPeriodType() {
      return this.ticketType === 'P';
    },
    // 회원 수정 폼에서 새로 발급한 판매 상품
    isSaleProductOnLocalStorage() {
      const memberId = _.get(this.userTicket, 'member.id');
      return utils.localStorage.get(memberId);
    },
    // 기간제가 아닌 수강권 및 상품
    isNotPeriodType() {
      return this.ticket.type === 'T' || this.isRentalOnlyTime || this.ticketType === 'RM';
    },
    isRentalOnlyTime() {
      return this.ticket.type === 'RT';
    },
    isRentalOnlyPeriod() {
      return this.ticket.type === 'RP';
    },

    ticketPeriod() {
      if (!this.userTicket) return null;

      const { date } = this.$filters;

      const startOn = _.get(this.userTicket, 'availability_start_at');
      const endOn = _.get(this.userTicket, 'expire_at');
      const remainingDays = this.$utils.getRemainingDaysText(endOn, startOn);
      let period = `${date(startOn)}~${date(endOn)}`;
      if (!this.expired && !this.disabled && !this.notIssued) period += ` (${remainingDays})`;
      return period;
    },

    ticketCounts() {
      const isExpired = this.disabled || this.expired;
      if (isExpired) return null;

      const { comma } = this.$filters;
      const remainingCoupon = comma(_.get(this.userTicket, 'remaining_coupon'));
      // const maxCoupon = comma(_.get(this.userTicket, 'max_coupon'));
      let usableCoupon = comma(_.get(this.userTicket, 'remaining_coupon'));
      let remainingCancel = comma(_.get(this.userTicket, 'remaining_cancel'));
      if (!this.notIssued) {
        usableCoupon = comma(_.get(this.userTicket, 'usable_coupon'));
      }

      const alreadyUsed = _.get(this.userTicket, 'change_daily_booking_count'); // 이미 사용한 당일예약 변경 횟수
      const totalDailyBookingChange = _.get(this.ticket, 'daily_booking_change_limit');
      const remainingCount = totalDailyBookingChange - alreadyUsed;
      const dailyBookingChangeLimit = remainingCount > 0 ? remainingCount : 0;

      let items = [];

      if (this.isNotPeriodType) {
        if (this.isRentalOnlyTime || this.ticketType === 'RM') {
          items = [
            { label: '예약가능', value: usableCoupon },
            { label: '잔여', value: remainingCoupon },
          ];
        } else {
          items = [
            { label: '예약가능', value: usableCoupon },
            { label: '취소가능', value: remainingCancel },
            { label: '잔여', value: remainingCoupon },
          ];
        }
      }
      if (totalDailyBookingChange) {
        items.push(
          // { label: '취소가능', value: remainingCancel },
          { label: '당일변경', value: dailyBookingChangeLimit },
        );
      }

      if (this.isPeriodType && this.userTicket.is_show_cancel_count) {
        items.push({
          label: '취소가능',
          value: _.get(this.userTicket, 'remaining_cancel') > 999 ? 999 : remainingCancel,
        });
      }

      return items;
    },

    ticketPrice() {
      const maxCoupon = _.get(this.userTicket, 'max_coupon');
      const payments = _.get(this.userTicket, 'payments', []);
      const exceptTotalPaymentStatus = ['installment_payment', 'full_payment', 'refund'];
      const { total, unpaid } = payments.reduce(
        ({ beforeAmount, unpaid, total }, payment) => {
          if (payment.status === PAYMENT_VAR.transfer) {
            total = beforeAmount + payment.before_user_ticket_amount;
          } else if (exceptTotalPaymentStatus.indexOf(payment.status) === -1 || !payment.status) {
            // !payment.status는 회원 생성시에 수강권 등록할 때
            total = beforeAmount + payment.amount + payment.unpaid_amount;
          }
          beforeAmount = total;

          if (payment.status === PAYMENT_VAR.installment_payment || payment.status === PAYMENT_VAR.full_payment) {
            unpaid -= payment.amount;
          } else {
            unpaid += payment.unpaid_amount;
          }

          return { beforeAmount, unpaid, total };
        },
        { beforeAmount: 0, unpaid: 0, total: 0 },
      );

      return { total, unpaid, perCoupon: parseInt(total / maxCoupon) };
    },

    pricePerCoupon() {
      if (this.ticketType === 'P' || this.isRentalOnlyPeriod || this.ticketType === 'S') return null;

      const pricePerCoupon = this.$filters.comma(this.ticketPrice.perCoupon);
      return `회당 가격 ${pricePerCoupon}원`;
    },

    unpaidAmount() {
      if (!this.ticketPrice.unpaid) return null;

      const unpaidAmount = this.$filters.comma(this.ticketPrice.unpaid);
      return `미수금 ${unpaidAmount}원`;
    },
  },
};
</script>

<style lang="scss" scoped>
.userticket-card {
  @include flex(column);
  border-radius: 8px;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  position: relative;
  width: 256px;

  &__meta {
    @include flex(column);
    width: 100%;
    height: 144px;
    border-radius: 8px;
    padding: 12px;
    position: relative;

    &.addNew {
      background: rgba(#e9ecef, 0.3) url('~@/assets/images/01_graphic/ic_24x24/line/plus_black.svg') no-repeat center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 8px 8px 0 0;
    }
  }

  &.showPrice &__meta {
    border-radius: 8px 8px 0 0;
  }

  &.P &__meta {
    background-image: linear-gradient(to bottom, rgba($pink, 0), rgba($pink, 0.7)),
      linear-gradient(to bottom, $light-purple, $light-purple);
  }

  &.P &__header__tag {
    color: $light-purple;
  }

  &.G &__meta,
  &.I &__meta {
    background-image: linear-gradient(122deg, rgba($light-purple, 0), rgba($light-purple, 0.7)),
      linear-gradient(to bottom, $deep-sky-blue, $deep-sky-blue);
  }

  &.G &__header__tag,
  &.I &__header__tag {
    color: $deep-sky-blue;
  }

  @include mq(1025px) {
    //hover css가 아이패드에서 클릭이 한번에 안되는 버그를 발생시킴
    &.clickable:hover {
      box-shadow: 1px 2px 7px 0 rgba(#000, 0.5);
      transform: translateY(-1px);
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }
  }

  &.holding &__meta {
    background-image: linear-gradient(122deg, rgba(#000, 0.3), rgba(#000, 0.7)), linear-gradient(to bottom, #ebebeb, #ebebeb);
  }

  &.holding &__header__tag {
    color: #696b6d;
  }

  &.disabled &__meta {
    background-image: linear-gradient(122deg, rgba(#fff, 0.2), rgba(#fff, 0)), linear-gradient(to bottom, #343434, #343434);
  }

  &.disabled &__header__tag {
    color: $coral;
  }

  &.expired &__meta {
    background-image: linear-gradient(122deg, rgba(#fff, 0.2), rgba(#fff, 0)), linear-gradient(to bottom, #000, #000);
  }

  &.expired &__header__tag {
    color: #000;
  }

  &__click-listener {
    background-image: linear-gradient(to bottom, rgba(white, 0.2), rgba(white, 0));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
  }

  @include mq(1025px) {
    //hover css가 아이패드에서 클릭이 한번에 안되는 버그를 발생시킴
    &.clickable:hover &__click-listener {
      opacity: 1;
      transition: 0.2s opacity ease-in-out;
    }
  }

  &__header {
    @include flex(row, center, space-between);
    margin-bottom: 4px;

    h5 {
      color: white;
      font-size: 12px;
      font-weight: 400;
    }

    &__button {
      cursor: pointer;
      z-index: 10;

      &.favorite {
        transition: 0.15s opacity;

        @include mq(1025px) {
          //hover css가 아이패드에서 클릭이 한번에 안되는 버그를 발생시킴
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    &__tag {
      width: 48px;
      height: 16px;
      background-color: #fff;
      border-radius: 8px;
      font-size: 10px;
      font-weight: 500;
      line-height: 1;
      @include flex(row, center, center);
    }
  }

  &__body {
    flex: 1;
    @include flex(row, flex-start, space-between);

    h3 {
      flex: 1;
      color: white;
      font-size: 18px;
      font-weight: 500;
      max-height: 54px;
      overflow: hidden;
      white-space: normal;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__is-shared {
      width: 48px;
      height: 16px;
      border-radius: 2px;
      border: solid 1px #fff;
      color: #fff;
      font-size: 10px;
      font-weight: 400;
      text-align: center;
      margin: 6px 0 4px 8px;
      line-height: 1;
      font-family: NotoSansKR;
      @include flex(row, center, center);
    }
  }

  &__footer {
    color: white;
    font-size: 12px;

    p {
      height: 18px;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__counts {
      letter-spacing: -1.1px;

      span + span::before {
        content: '·';
      }
    }
  }

  &__extra {
    @include flex(row, center, space-between);
    padding: 12px;
    height: 64px;

    &__add-new {
      grid-row: span 2;
      color: #4dadf7;
      font-size: 12px;
    }

    &__price {
      display: grid;
      grid-template-rows: 20px 15px;
      grid-gap: 5px;
      align-items: center;
      width: 100%;

      & > span {
        @include flex(row, center, space-between);
      }

      &__total {
        font-size: 14px;
        font-weight: 500;
      }

      &__unpaid {
        color: $coral;
        font-size: 10px;
      }

      &__per-coupon {
        color: #333;
        font-size: 10px;
      }
    }

    &__checkbox {
      align-self: flex-end;
      cursor: pointer;
      transition: 0.15s opacity;
      z-index: 10;

      @include mq(1025px) {
        //hover css가 아이패드에서 클릭이 한번에 안되는 버그를 발생시킴
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
