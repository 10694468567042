<template>
  <section class="settings-operations">
    <form-block :index="1" label="예약·취소 가능 시간 설정" required>
      <el-tooltip style="margin-left:10px;" slot="label-helper" effect="light" placement="right-start">
        <DeadlineSettingsHelper slot="content" />
        <i class="el-icon-question" :style="{ color: '#64AEFF', fontSize: '18px' }" />
      </el-tooltip>
      <el-checkbox slot="checkbox" v-model="policy.booking_deadline_apply_all">
        이미 생성된 수업에도 모두 적용
      </el-checkbox>

      <!-- <RadioGroup v-model="policy.rule_type" :options="deadlineTypeOptions" /> -->

      <div class="settings-operations__description-wrapper">
        <p>회원 예약 가능 시간을 설정해주세요.</p>
      </div>

      <!-- 예약 '가능' 프라이빗 수업  -->
      <div class="settings-operations__block-type">
        <div class="settings-operations__group">
          <p class="settings-operations__type-selector">
            <el-select v-model="policy.private_booking_rule_type" placeholder="예약 타입을 선택해 주세요">
              <el-option v-for="item in deadlineTypeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </p>
          <AvailableHoursInput
            :type="policy.private_booking_rule_type"
            :relativeStart="policy.private_booking_startline"
            relativeStartPropName="private_booking_startline"
            :relativeEnd="policy.private_booking_deadline"
            relativeEndPropName="private_booking_deadline"
            :absoluteStartDays="policy.private_booking_start_days"
            absoluteStartDaysPropName="private_booking_start_days"
            :absoluteStartTime="policy.private_booking_start_time"
            absoluteStartTimePropName="private_booking_start_time"
            :absoluteEndDays="policy.private_booking_end_days"
            absoluteEndDaysPropName="private_booking_end_days"
            :absoluteEndTime="policy.private_booking_end_time"
            absoluteEndTimePropName="private_booking_end_time"
            @change="handleAvailableHoursChange"
            path="operations"
          >
            <span slot="prefix" class="word-spacing"><b>프라이빗 수업</b>은 수업 시작</span>
            <span slot="suffix" class="word-spacing">까지 <b>예약 가능</b>합니다.</span>
          </AvailableHoursInput>
        </div>
        <div class="settings-operations__group">
          <p class="settings-operations__type-selector">
            <el-select
              v-model="policy.group_booking_rule_type"
              placeholder="예약 타입을 선택해 주세요"
              :class="{ 'is-filtered': !!policy.group_booking_rule_type }"
            >
              <el-option v-for="item in deadlineTypeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </p>
          <!-- 예약 가능 그룹 수업 -->
          <AvailableHoursInput
            :type="policy.group_booking_rule_type"
            :relativeStart="policy.group_booking_startline"
            relativeStartPropName="group_booking_startline"
            :relativeEnd="policy.group_booking_deadline"
            relativeEndPropName="group_booking_deadline"
            :absoluteStartDays="policy.group_booking_start_days"
            absoluteStartDaysPropName="group_booking_start_days"
            :absoluteStartTime="policy.group_booking_start_time"
            absoluteStartTimePropName="group_booking_start_time"
            :absoluteEndDays="policy.group_booking_end_days"
            absoluteEndDaysPropName="group_booking_end_days"
            :absoluteEndTime="policy.group_booking_end_time"
            absoluteEndTimePropName="group_booking_end_time"
            @change="handleAvailableHoursChange"
            path="operations"
          >
            <span slot="prefix" class="word-spacing"><b>그룹 수업</b>은 수업 시작</span>
            <span slot="suffix" class="word-spacing">까지 <b>예약 가능</b>합니다.</span>
          </AvailableHoursInput>
        </div>
      </div>

      <div class="settings-operations__description-wrapper">
        <p>회원 예약 취소 가능 시간을 설정해주세요.</p>
      </div>
      <div class="settings-operations__block-type">
        <!-- 예약 '취소' 프라이빗 수업 -->
        <div class="settings-operations__group">
          <p class="settings-operations__type-selector">
            <el-select v-model="policy.private_booking_cancel_rule_type" placeholder="예약 타입을 선택해 주세요">
              <el-option v-for="item in deadlineTypeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </p>
          <AvailableHoursInput
            :type="policy.private_booking_cancel_rule_type"
            :relativeStart="policy.private_booking_cancel_startline"
            relativeStartPropName="private_booking_cancel_startline"
            :relativeEnd="policy.private_booking_cancel_deadline"
            relativeEndPropName="private_booking_cancel_deadline"
            :absoluteStartDays="policy.private_booking_cancel_start_days"
            absoluteStartDaysPropName="private_booking_cancel_start_days"
            :absoluteStartTime="policy.private_booking_cancel_start_time"
            absoluteStartTimePropName="private_booking_cancel_start_time"
            :absoluteEndDays="policy.private_booking_cancel_end_days"
            absoluteEndDaysPropName="private_booking_cancel_end_days"
            :absoluteEndTime="policy.private_booking_cancel_end_time"
            absoluteEndTimePropName="private_booking_cancel_end_time"
            @change="handleAvailableHoursChange"
            path="operations"
          >
            <span slot="prefix" class="word-spacing"><b>프라이빗 수업</b>은 수업 시작</span>
            <span slot="suffix" class="word-spacing">까지 <b>예약 취소</b> 가능합니다.</span>
          </AvailableHoursInput>
        </div>

        <div class="settings-operations__group">
          <p class="settings-operations__type-selector">
            <el-select
              v-model="policy.group_booking_cancel_rule_type"
              placeholder="예약 타입을 선택해 주세요"
              :class="{ 'is-filtered': !!policy.group_booking_cancel_rule_type }"
            >
              <el-option v-for="item in deadlineTypeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </p>
          <!-- 예약 취소 그룹수업 -->
          <AvailableHoursInput
            :type="policy.group_booking_cancel_rule_type"
            :relativeStart="policy.group_booking_cancel_startline"
            relativeStartPropName="group_booking_cancel_startline"
            :relativeEnd="policy.group_booking_cancel_deadline"
            relativeEndPropName="group_booking_cancel_deadline"
            :absoluteStartDays="policy.group_booking_cancel_start_days"
            absoluteStartDaysPropName="group_booking_cancel_start_days"
            :absoluteStartTime="policy.group_booking_cancel_start_time"
            absoluteStartTimePropName="group_booking_cancel_start_time"
            :absoluteEndDays="policy.group_booking_cancel_end_days"
            absoluteEndDaysPropName="group_booking_cancel_end_days"
            :absoluteEndTime="policy.group_booking_cancel_end_time"
            absoluteEndTimePropName="group_booking_cancel_end_time"
            @change="handleAvailableHoursChange"
            path="operations"
          >
            <span slot="prefix" class="word-spacing"><b>그룹 수업</b>은 수업 시작</span>
            <span slot="suffix" class="word-spacing">까지 <b>예약 취소</b> 가능합니다.</span>
          </AvailableHoursInput>
        </div>
      </div>
    </form-block>

    <form-block :index="2" label="당일 예약 변경 가능 시간 설정">
      <el-checkbox slot="checkbox" v-model="policy.daily_booking_change_deadline_apply_all">
        이미 생성된 수업에도 모두 적용
      </el-checkbox>
      <div class="settings-operations__description-wrapper">
        <p>
          그룹 수업의 당일 예약 변경 가능 시간을 설정해주세요.
        </p>
      </div>
      <div class="settings-operations__block">
        <AvailableHoursInput
          type="R"
          :relativeStart="policy.daily_booking_change_deadline"
          relativeStartPropName="daily_booking_change_deadline"
          :relativeEnd="policy.daily_booking_change_deadline"
          relativeEndPropName="daily_booking_change_deadline"
          @change="handleAvailableHoursChange"
        >
          <span slot="prefix"><b>그룹 수업</b>은 수업 시작</span>
          <span slot="suffix">까지 <b>당일 예약 변경</b> 가능합니다.</span>
        </AvailableHoursInput>
      </div>
    </form-block>

    <form-block :index="3" label="수업 폐강 시간 설정" required>
      <el-checkbox slot="checkbox" v-model="policy.autoclose_deadline_apply_all">
        이미 생성된 수업에도 모두 적용
      </el-checkbox>
      <div class="settings-operations__description-wrapper">
        <p>
          최소 수강인원 미달 수업의 폐강 시간을 설정해주세요.
          <el-tooltip effect="light" placement="right">
            <p slot="content">
              그룹 수업은 폐강 시간 기준으로 최소 수강 인원 미달시<br />
              자동으로 삭제되며 회원의 예약은 취소됩니다.
            </p>
            <i class="el-icon-question" :style="{ color: '#64AEFF', fontSize: '18px' }"></i>
          </el-tooltip>
        </p>
      </div>
      <div class="settings-operations__block">
        <AvailableHoursInput
          type="R"
          :relativeStart="policy.autoclose_deadline"
          relativeStartPropName="autoclose_deadline"
          :relativeEnd="policy.autoclose_deadline"
          relativeEndPropName="autoclose_deadline"
          @change="handleAvailableHoursChange"
        >
          <span slot="prefix"><b>최소 수강인원 미달 수업</b>은 수업 시작</span>
          <span slot="suffix">에 <b>폐강</b>됩니다.</span>
        </AvailableHoursInput>
      </div>
    </form-block>

    <form-block :index="4" label="예약대기 자동 예약 시간 설정" required>
      <p class="settings-operations__description">
        예약대기가 예약으로 변경되는 시간을 설정해주세요.<br />
        값을 변경하지 않는 경우 '취소 가능한 시간'으로 적용됩니다.
      </p>
      <div class="settings-operations__block">
        <AvailableHoursInput
          type="R"
          :relativeStart="policy.booking_auto_shift_deadline"
          relativeStartPropName="booking_auto_shift_deadline"
          :relativeEnd="policy.booking_auto_shift_deadline"
          relativeEndPropName="booking_auto_shift_deadline"
          @change="handleAvailableHoursChange"
        >
          <span slot="prefix"> <b>예약대기</b>는 공석이 발생할 경우 수업시작 </span> 시간
          <span slot="suffix"> 까지 <b>자동 예약</b>됩니다. </span>
        </AvailableHoursInput>
      </div>
    </form-block>

    <form-block :index="5" label="예약 대기 가능 횟수 설정" required>
      <p class="settings-operations__description">
        한 주간 그룹 수업에 예약 대기 가능한 횟수를 설정해주세요.<br />
        0을 입력하거나 값을 입력하지 않는 경우 회원은 예약 대기 기능을 사용할 수 없습니다.
      </p>
      <div class="settings-operations__block">
        <p>
          <span>회원은 일주일동안 그룹수업에 최대</span>
          <InlineNumberInput v-model="policy.weekly_waiting_limit" />
          <span>회까지 <b>예약 대기 가능</b>합니다.</span>
        </p>
      </div>
      <p class="settings-operations__description">
        설정값을 수정할 경우 수정 시점 이후부터 적용됩니다.
      </p>
    </form-block>

    <form-block :index="6" label="일일 예약 가능횟수 설정">
      <el-checkbox v-model="policy.is_daily_booking_limited" slot="checkbox">사용함</el-checkbox>
      <RadioGroup
        v-if="policy.is_daily_booking_limited"
        v-model="policy.daily_booking_limit_by"
        :options="dailyBookingLimitByOptions"
      />
      <p v-if="policy.is_daily_booking_limited" class="settings-operations__description">
        당일 그룹 수업에 예약 가능한 횟수를 설정해주세요.
      </p>
      <div v-if="policy.is_daily_booking_limited" class="settings-operations__block">
        <p>
          <span>회원은 하루에 그룹 수업을 최대</span>
          <InlineNumberInput v-model="policy.daily_booking_limit" />
          <span>회까지 <b>예약 가능</b>합니다.</span>
        </p>
      </div>
      <p v-if="policy.is_daily_booking_limited" class="settings-operations__description">
        설정값을 수정할 경우 수정 시점 이후부터 적용됩니다.
      </p>
    </form-block>

    <form-block :index="7" label="예약 가능 기한 설정" required>
      <el-checkbox v-model="isBookingLimitFixed[bookingLimitPrefix]" slot="checkbox">고급설정</el-checkbox>
      <RadioGroup v-model="bookingLimitPrefix" :options="bookingLimitPrefixOptions" />
      <p class="settings-operations__description">
        예약 가능한 기한을 설정하여, 예약 기한을 제한할 수 있습니다.<br />
        원활한 예약을 위해 예약 가능 시간을 <b>16시</b>로 설정하는 것을 추천합니다.
      </p>
      <div class="settings-operations__block">
        <p v-if="!isBookingLimitFixed[bookingLimitPrefix]">
          <span>회원은 수업일 기준으로 </span>
          <InlineNumberInput v-model="policy[`${bookingLimitPrefix}_booking_limit_day_term`]" />
          <span class="settings-operations__content-padding"> 일 전 </span>
          <el-time-select
            class="settings-operations__reserve-time"
            format="HH:mm"
            value-format="HH:mm"
            v-model="bookingLimitDayTermTime"
            :picker-options="{ start: '00:00', step: '00:01', end: '23:59' }"
            :clearable="false"
          />
          <span> 부터 <b>예약 가능</b>합니다.</span>
        </p>
        <p v-else>
          <span>회원은</span>
          <el-date-picker
            ref="bookingLimitDate"
            class="settings-operations__inline-date-picker"
            v-model="bookingLimitDate"
            size="small"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            placeholder="예약 가능 일자"
            required
            :clearable="false"
            :picker-options="pickerOptions"
          />
          <span>까지 <b>예약 가능</b>합니다.</span>
        </p>
        <p v-if="isBookingLimitFixed[bookingLimitPrefix]">
          <span>{{ bookingLimitDate | dateKorean }}</span>
          <el-time-select
            format="HH:mm"
            value-format="HH:mm"
            v-model="bookingLimitTime"
            :picker-options="{ start: '00:00', step: '00:01', end: '23:59' }"
            :clearable="false"
          />
          <span>에 자동으로</span>
          <InlineNumberInput v-model="policy[`${bookingLimitPrefix}_booking_limit_day_term`]" />
          <span>일 연장합니다.</span>
        </p>
      </div>
    </form-block>

    <form-block :index="8" label="프라이빗 수업 예약 시간 단위 설정">
      <p class="settings-operations__description">
        회원 앱에서 프라이빗 수업을 예약할 때 설정한 시간 단위로 나타납니다.
      </p>
      <div class="settings-operations__block">
        <p>
          <el-radio v-model="policy.private_start_interval" :label="60">
            정시
          </el-radio>
          <el-radio v-model="policy.private_start_interval" :label="30">
            30분
          </el-radio>
          <el-radio v-model="policy.private_start_interval" :label="20">
            20분
          </el-radio>
          <el-radio v-model="policy.private_start_interval" :label="15">
            15분
          </el-radio>
          <el-radio v-model="policy.private_start_interval" :label="10">
            10분
          </el-radio>
          <el-radio v-model="policy.private_start_interval" :label="5">
            5분
          </el-radio>
        </p>
      </div>
    </form-block>

    <!-- 프라이빗 수업 동시간대 생성 수업 최대 개수 설정 -->
    <form-block :index="9" label="프라이빗 수업 생성 최대 개수 설정">
      <el-checkbox
        v-model="isPrivateDupTimeCreate"
        :true-label="1"
        :false-label="0"
        slot="checkbox"
        @change="value => (!value ? (policy.same_time_max_private_lecture_limit = 0) : null)"
        >사용함</el-checkbox
      >
      <p class="settings-operations__description">
        프라이빗 수업을 같은 시간대에 만들 수 있는 최대 개수를 제한합니다.
      </p>
      <div class="settings-operations__block">
        <NumberInput
          style="width: 140px;"
          v-model="policy.same_time_max_private_lecture_limit"
          v-if="isPrivateDupTimeCreate"
          :min="1"
          :max="999"
        />
      </div>
      <p class="settings-operations__description">
        설정값을 수정할 경우 수정 시점 이후부터 적용됩니다.
      </p>
    </form-block>

    <form-block :index="10" label="그룹 수업 예약/대기 인원 표시 설정">
      <p class="settings-operations__description">
        회원 앱에서 그룹 수업의 예약된 인원 표시 여부를 설정합니다.
      </p>
      <div class="settings-operations__block">
        <p>
          <el-radio v-model="policy.is_show_current_count" :label="1">
            그룹 수업 예약 인원 표시
          </el-radio>
          <el-radio v-model="policy.is_show_current_count" :label="0">
            그룹 수업 예약 인원 표시 안함
          </el-radio>
        </p>
      </div>
      <p class="settings-operations__description">
        회원 앱에서 그룹 수업의 예약대기 중인 인원 표시 여부를 설정합니다.
      </p>
      <div class="settings-operations__block">
        <p>
          <el-radio v-model="policy.is_show_booking_waiting_count" :label="true">
            그룹 수업 예약대기 인원 표시
          </el-radio>
          <el-radio v-model="policy.is_show_booking_waiting_count" :label="false">
            그룹 수업 예약대기 인원 표시 안함
          </el-radio>
        </p>
      </div>
    </form-block>

    <!-- 게시판 사용 여부 설정 -->
    <form-block :index="11" label="문의 게시판 설정">
      <el-checkbox v-model="policy.is_visible_qna" :true-label="1" :false-label="0" slot="checkbox">사용함</el-checkbox>
      <p class="settings-operations__description">
        체크시 문의 게시판 기능을 사용합니다.
      </p>
    </form-block>

    <!-- 모든 수업 노출 사용 여부 설정 -->
    <form-block :index="12" label="모든 수업 보기 설정">
      <el-checkbox v-model="policy.is_visible_all_lectures" :true-label="1" :false-label="0" slot="checkbox">사용함</el-checkbox>
      <p class="settings-operations__description">
        체크시 회원 앱에서 회원들이 가진 수강권으로 볼 수 없는 수업 목록도 보이게됩니다.
      </p>
    </form-block>

    <!-- 락커 사용 여부 설정 -->
    <form-block :index="13" label="락커 설정">
      <div class="settings-operations__description-wrapper">
        <p>체크시, 메뉴 상단에 락커 기능이 추가됩니다. <br />회원 탭에 회원 별 사용 중인 락커와 만료일이 표시됩니다.</p>
      </div>
      <el-checkbox v-model="policy.is_locker" :true-label="1" :false-label="0" slot="checkbox">사용함</el-checkbox>
    </form-block>

    <!-- 게시판 사용 여부 설정 -->
    <form-block :index="14" label="횟수 차감되는 취소 설정">
      <el-checkbox v-model="policy.is_absence_by_user" :true-label="1" :false-label="0" slot="checkbox">사용함</el-checkbox>
      <p class="settings-operations__description">
        취소 가능 시간이 지난 후 회원이 예약을 취소하면 횟수가 차감되며 취소됩니다.
      </p>
    </form-block>

    <form-block :index="15" label="회원앱에서 만료된 수강권 숨기기">
      <div class="settings-operations__description-wrapper">
        <p>만료된 수강권은 회원앱에서 보이지 않게 됩니다.</p>
      </div>
      <el-checkbox v-model="policy.hide_tickets_deleted" :true-label="1" :false-label="0" slot="checkbox">사용함</el-checkbox>
    </form-block>

    <!-- 수강권 미수금 자동 입력 ON/OFF -->
    <form-block :index="16" label="수강권 미수금 자동 입력">
      <div class="settings-operations__description-wrapper">
        <p>체크시, 수강권 발급 및 결제 정보 수정시 미수금이 자동 입력됩니다.</p>
      </div>
      <el-checkbox v-model="policy.is_auto_write_unpaid" :true-label="1" :false-label="0" slot="checkbox">사용함</el-checkbox>
    </form-block>

    <!-- 체크인 사용 여부 -->
    <form-block
      :index="17"
      v-if="studio.grade === 2 || studio.usable_enter"
      :inputInline="true"
      label="체크인 설정"
      class="wrap-enter"
    >
      <el-checkbox
        v-model="policy.is_enter"
        @change="turnOnAutoAbsenceIfCheckInOffed"
        :true-label="1"
        :false-label="0"
        slot="checkbox"
        >사용함</el-checkbox
      >
      <el-checkbox slot="checkbox" v-if="policy.is_enter" v-model="policy.enter_deadline_apply_all">
        이미 생성된 수업에도 모두 적용
      </el-checkbox>
      <p class="description">
        체크인 기능 사용 여부를 설정합니다.
      </p>
      <div class="left-bar">
        <AvailableHoursInput
          style="margin-right: 20px"
          type="R"
          :relativeStart="policy.enter_start_deadline === null ? 300 : policy.enter_start_deadline"
          relativeStartPropName="enter_start_deadline"
          :relativeEnd="policy.enter_start_deadline === null ? 300 : policy.enter_start_deadline"
          relativeEndPropName="enter_start_deadline"
          :disabled="!!!policy.is_enter"
          :setMaxTime="5"
          @change="handleAvailableHoursChange"
        >
          <span slot="prefix"><b>수업 시작</b> </span>
          <span slot="suffix"> 부터</span>
        </AvailableHoursInput>
        <AvailableHoursInput
          type="R"
          :relativeStart="policy.enter_end_deadline === null ? 300 : policy.enter_end_deadline"
          relativeStartPropName="enter_end_deadline"
          :relativeEnd="policy.enter_end_deadline === null ? 300 : policy.enter_end_deadline"
          relativeEndPropName="enter_end_deadline"
          :afterText="true"
          :setMaxTime="5"
          :disabled="!!!policy.is_enter"
          @change="handleAvailableHoursChange"
        >
          <span slot="prefix"><b>수업 종료</b> </span>
          <span slot="suffix"> 까지 <b>체크인(입장)</b> 가능합니다.</span>
        </AvailableHoursInput>
      </div>

      <!-- 체크인 화면  문구 -->
      <div class="title-text">체크인 안내 문구 설정</div>
      <div class="wrap-text">
        <label for="">주 인사말</label>
        <TextInput
          ref="welcome1"
          v-model="welcomeInput1"
          :disabled="!!!policy.is_enter"
          material
          placeholder="인사말을 입력해주세요. (최대 30자)"
        />

        <label for="">보조 인사말</label>
        <TextInput
          ref="welcome2"
          class="text-2"
          v-model="welcomeInput2"
          :disabled="!!!policy.is_enter"
          material
          placeholder="보조 인사말을 입력해주세요. (최대 50자)"
        />
      </div>

      <!--체크인 화면 이미지 -->
      <div class="title-text">체크인 배경 사진 설정</div>
      <AttachImage
        :setImg="checkinImgPath"
        :disabled="!!!policy.is_enter"
        @uploadImg="updateCheckinImg"
        @removeImg="removedImg"
      />

      <!-- 체크인 자동 결석 처리 -->
      <div class="checkin__auto">
        <p class="title">미체크인 시 자동 결석 처리</p>
        <el-checkbox v-model="policy.is_auto_absence_for_enter" :disabled="!this.policy.is_enter">사용함</el-checkbox>
      </div>
      <p class="checkin__auto__description">수업 예약 후 회원이 체크인을 하지 않으면 횟수가 차감되고 결석처리 됩니다.</p>
    </form-block>

    <!-- 회원앱 수다메이트(라운지) 설정 ON/OFF -->
    <form-block :index="studio.grade === 2 || studio.usable_enter ? 18 : 17" label="회원앱 라운지 설정">
      <div class="settings-operations__description-wrapper">
        <p>체크시, 회원앱에서 라운지 기능을 사용할 수 있습니다.</p>
      </div>
      <el-checkbox v-model="policy.is_sudamate" :true-label="1" :false-label="0" slot="checkbox">사용함</el-checkbox>
    </form-block>

    <bottom-action-bar :message="last_updated_at">
      <el-button @click="saveSettings">
        {{ studio.id ? '정보 수정 완료' : '정보 등록 완료' }}
      </el-button>
    </bottom-action-bar>
  </section>
</template>

<script>
import AvailableHoursInput from '@/components/Settings/AvailableHoursInput';
import DeadlineSettingsHelper from '@/components/Settings/DeadlineSettingsHelper';

const DEFAULT_DEADLINES = {
  rule_type: 'R',
  group_booking_cancel_rule_type: 'R',
  private_booking_cancel_rule_type: 'R',
  group_booking_rule_type: 'R',
  private_booking_rule_type: 'R',
  private_booking_startline: null,
  private_booking_deadline: 0,
  private_booking_start_days: null,
  private_booking_start_time: null,
  private_booking_end_days: null,
  private_booking_end_time: null,
  group_booking_startline: null,
  group_booking_deadline: 0,
  group_booking_start_days: null,
  group_booking_start_time: null,
  group_booking_end_days: null,
  group_booking_end_time: null,
  private_booking_cancel_startline: null,
  private_booking_cancel_deadline: 0,
  private_booking_cancel_start_days: null,
  private_booking_cancel_start_time: null,
  private_booking_cancel_end_days: null,
  private_booking_cancel_end_time: null,
  group_booking_cancel_startline: null,
  group_booking_cancel_deadline: 0,
  group_booking_cancel_start_days: null,
  group_booking_cancel_start_time: null,
  group_booking_cancel_end_days: null,
  group_booking_cancel_end_time: null,
};

export default {
  components: {
    AvailableHoursInput,
    DeadlineSettingsHelper,
  },

  watch: {
    studioPolicies: function() {
      this.setInitialValue();
    },
  },

  data() {
    const { moment } = this;

    return {
      welcomeInput1: '',
      welcomeInput2: '',
      loadedImg: null,
      checkinImg: null,
      isBookingLimitFixed: {
        private: false,
        group: false,
      },
      isPrivateDupTimeCreate: false,
      bookingLimitPrefix: 'private', // 'private' | 'group'
      bookingLimitPrefixOptions: [
        { value: 'private', label: '프라이빗 수업' },
        { value: 'group', label: '그룹 수업' },
      ],
      deadlineTypeOptions: [
        { value: 'R', label: '수업시간 기준 - 마감시간' },
        { value: 'RR', label: '수업시간 기준 - 시작시간부터 마감시간까지' },
        { value: 'A', label: '수업일 기준 - 마감시간' },
        { value: 'AR', label: '수업일 기준 - 시작시간부터 마감시간까지' },
      ],
      dailyBookingLimitByOptions: [
        { value: 'ticket', label: '수강권별 제한' },
        { value: 'daily', label: '날짜별 제한' },
      ],

      policy: {
        weekly_waiting_limit: 0,
        is_daily_booking_limited: false,
        daily_booking_limit: 0,
        daily_booking_limit_by: 'ticket',
        private_booking_limit_day_term: 0,
        private_booking_limit_date: '',
        group_booking_limit_day_term: 0,
        group_booking_limit_date: '',
        private_start_interval: 60,
        is_show_current_count: 1,
        is_show_booking_waiting_count: false,
        is_ticket_expire_push_noti: true,
        ticket_expire_noti_term: 1,
        is_remainder_coupon_push_noti: true,
        remainder_coupon_noti_count: 3,
        is_ticket_holding_end_push_noti: true,
        reminder_ticket_holding_noti_term: 3,
        is_before_lecture_alarm_push: true,
        before_lecture_alarm_hour: 2,
        before_private_lecture_alarm_hour: 2,
        same_time_max_private_lecture_limit: 0,
        /** one-off flags */
        daily_booking_change_deadline_apply_all: false,
        booking_deadline_apply_all: false,
        autoclose_deadline_apply_all: false,
        enter_deadline_apply_all: false,
        is_visible_qna: 0,
        is_visible_all_lectures: 0,
        is_locker: 0,
        is_absence_by_user: 0,
        is_auto_write_unpaid: 0,
        hide_tickets_deleted: 0,
        is_enter: 0,
        is_auto_absence_for_enter: false,

        /** 예약/취소 가능 시간 */
        ...DEFAULT_DEADLINES,

        /** 수다메이트(라운지) 이용 여부 */
        is_sudamate: 0,
      },

      is_changed: {
        is_daily_booking_limited: false,
        daily_booking_limit: 0,
        weekly_waiting_limit: 0,
      },

      originalData: {},

      pickerOptions: {
        disabledDate(date) {
          return moment(date).isAfter(moment('2030-12-31'), 'day');
        },
      },
    };
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },

    bookingLimitDate: {
      get() {
        const propName = `${this.bookingLimitPrefix}_booking_limit_date`;
        return this.moment(this.policy[propName]).format('YYYY-MM-DD') || this.moment().format('YYYY-MM-DD');
      },
      set(date) {
        const propName = `${this.bookingLimitPrefix}_booking_limit_date`;
        if (!date) date = this.moment(this.policy[propName]).format('YYYY-MM-DD');
        this.policy[propName] = `${date} ${this.bookingLimitTime}:00`;
      },
    },

    bookingLimitTime: {
      get() {
        const propName = `${this.bookingLimitPrefix}_booking_limit_date`;
        return this.moment(this.policy[propName]).format('HH:mm') || '00:00';
      },
      set(time) {
        const propName = `${this.bookingLimitPrefix}_booking_limit_date`;
        if (!time) time = this.moment(this.policy[propName]).format('HH:mm');
        this.policy[propName] = `${this.bookingLimitDate} ${time}:00`;
      },
    },

    bookingLimitDayTermTime: {
      get() {
        const propName = `${this.bookingLimitPrefix}_booking_limit_day_term_time`;
        return this.policy[propName] ? this.policy[propName].slice(0, 5) : '00:00';
      },
      set(time) {
        const propName = `${this.bookingLimitPrefix}_booking_limit_day_term_time`;
        if (!time) time = this.moment(this.policy[propName]).format('HH:mm');
        this.policy[propName] = time;
      },
    },

    last_updated_at() {
      const date = this.studio.updated_at || this.studio.created_at;
      if (!date) return null;

      const suffix = this.studio.updated_at ? '에 마지막으로 수정됨' : '에 생성됨';

      return `${this.$filters.datetime(date)} ${suffix}`;
    },

    //체크인 화면 문구
    checkinText() {
      if (this.welcomeInput1 && this.welcomeInput2) {
        return `${this.welcomeInput1}$$${this.welcomeInput2}`; // 환영문구를 두 줄로 나눠서 보여주기 위해서
      } else if (this.welcomeInput1) {
        return this.welcomeInput1;
      } else if (this.welcomeInput2) {
        return this.welcomeInput2;
      }
      return null;
    },
    //체크인 화면 이미지
    checkinImgPath() {
      if (this.policy.attachment) {
        return this.policy.attachment.path;
      }
      return require('@/assets/images/bg-checkin.jpg');
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.canUpdateOperationsSettings) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/settings');
      }

      next();
    });
  },

  async created() {
    this.setInitialValue();
  },

  mounted() {
    if (this.policy.attachment) {
      this.loadedImg = this.policy.attachment.id;
      this.checkinImg = this.policy.attachment.id;
    }
    if (this.policy.check_in_msg) {
      const text = this.policy.check_in_msg;
      if (text) {
        if (text.includes('$$')) {
          return ([this.welcomeInput1, this.welcomeInput2] = text.split('$$'));
        }
        return (this.welcomeInput1 = text);
      }
      this.welcome2 = '';
      this.welcome1 = '';
    }
  },
  methods: {
    validate() {
      if (this.welcomeInput1.length > 30) {
        this.$utils.notify.error(this, '오류', '체크인 화면 문구1은 한글기준 30자를 넘을수 없습니다.');
        this.$refs.welcome1.$el.querySelector('input').focus();
        return false;
      }
      if (this.welcomeInput2.length > 50) {
        this.$utils.notify.error(this, '오류', '체크인 화면 문구2은 한글기준 50자를 넘을수 없습니다.');
        this.$refs.welcome2.$el.querySelector('input').focus();
        return false;
      }
      return true;
    },
    //체크인 배경 이미지 제거
    removedImg() {
      this.checkinImg = null;
    },
    /** 변경사항 저장 */
    async saveSettings() {
      if (!this.validate()) return;

      const changeDataType = [];
      this.updatedFields().forEach(type => {
        if (type.includes('same_time_max_private_lecture_limit')) {
          changeDataType.push('프라이빗 수업 생성');
        } else if (type.includes('weekly_waiting_limit')) {
          changeDataType.push('예약 대기');
        } else if (type.includes('daily_booking_limit')) {
          changeDataType.push('일일 예약');
        }
      });
      if (changeDataType.length) {
        const messages = changeDataType.join(', ');
        const confirm = await this.$confirm(`${messages} 설정값을 수정할 경우 수정 시점 이후부터 적용됩니다.`, `${messages} 수정`)
          .then(() => true)
          .catch(() => false);
        if (!confirm) return;
      }

      /** 예약 가능 기한이 고정된 날짜가 아닐 경우 */
      if (!this.isBookingLimitFixed.private) {
        this.policy.private_booking_limit_date = '';
      } else if (!this.policy.private_booking_limit_date) {
        this.$utils.notify.error(this, '오류', '프라이빗 수업의 예약 가능 일자를 입력해주세요');
        this.bookingLimitPrefix = 'private';
        return this.$refs.bookingLimitDate.focus();
      }

      if (!this.isBookingLimitFixed.group) {
        this.policy.group_booking_limit_date = '';
      } else if (!this.policy.group_booking_limit_date) {
        this.$utils.notify.error(this, '오류', '그룹 수업의 예약 가능 일자를 입력해주세요');
        this.bookingLimitPrefix = 'group';
        return this.$refs.bookingLimitDate.focus();
      }

      const deadlines = this.getDeadlines();
      if (!deadlines) return;

      const loadingInstance = this.$loading({
        fullscreen: true,
        text: '저장중입니다.',
      });

      //체크인 문구 추가
      this.policy.check_in_msg = this.checkinText;

      //체크인 이미지 추가시 불필요한 attachment 라는 필드가 policy에 추가되는데 그것 삭제
      if (this.policy.attachment || this.policy.attachment === null) {
        delete this.policy.attachment;
      }

      // 체크인 이미지 최종 확인
      let paramsData = null;

      if (this.loadedImg == this.checkinImg) {
        //이미지 변경이 없는경우
        paramsData = { policy: { ...this.policy, ...deadlines } };
      } else {
        if (this.checkinImg === null) {
          //이미지 삭제한 경우
          paramsData = {
            policy: { ...this.policy, ...deadlines },
            attachment_id: null,
          };
        } else {
          //이미지 변경한 경우
          paramsData = {
            policy: { ...this.policy, ...deadlines },
            attachment_id: this.checkinImg,
          };
        }
      }
      const result = await this.$store.dispatch('studio/updateStudio', paramsData);

      loadingInstance.close();
      if (result.success) {
        this.$utils.notify.success(this, '성공', '저장되었습니다');
      } else {
        this.$utils.notify.parseError(this, result.error);
      }
    },

    /** 초기값 설정 */
    setInitialValue() {
      this.policy = {
        ...this.studioPolicies,
        booking_deadline_apply_all: false,
        daily_booking_change_deadline_apply_all: false,
        autoclose_deadline_apply_all: false,
        enter_deadline_apply_all: false,
      };

      this.isBookingLimitFixed = {
        private: !!this.policy.private_using_booking_limit_detail_option,
        group: !!this.policy.group_using_booking_limit_detail_option,
      };
      if (!this.policy.private_booking_limit_date) {
        this.policy.private_booking_limit_date = this.moment().format('YYYY-MM-DD');
      }
      if (!this.policy.group_booking_limit_date) {
        this.policy.group_booking_limit_date = this.moment().format('YYYY-MM-DD');
      }
      this.isPrivateDupTimeCreate = this.policy.same_time_max_private_lecture_limit ? 1 : 0;

      this.is_changed = {
        is_daily_booking_limited: this.studioPolicies.is_daily_booking_limited,
        daily_booking_limit: this.studioPolicies.daily_booking_limit,
        weekly_waiting_limit: this.studioPolicies.weekly_waiting_limit,
      };
      this.originalData = { ...this.policy };
    },

    //체크인 화면 이미지
    updateCheckinImg(data) {
      this.checkinImg = data;
    },

    handleAvailableHoursChange(value) {
      this.policy = { ...this.policy, ...value };
    },

    getDeadlines() {
      let deadlines = {
        ...DEFAULT_DEADLINES,
        group_booking_cancel_rule_type: this.policy.group_booking_cancel_rule_type,
        private_booking_cancel_rule_type: this.policy.private_booking_cancel_rule_type,
        group_booking_rule_type: this.policy.group_booking_rule_type,
        private_booking_rule_type: this.policy.private_booking_rule_type,
        rule_type: this.policy.rule_type,
      };

      deadlines = {
        ...deadlines,
        private_booking_start_days: this.policy.private_booking_start_days || 0,
        private_booking_start_time: this.policy.private_booking_start_time || 2400,
        private_booking_end_days: this.policy.private_booking_end_days || 0,
        private_booking_end_time: this.policy.private_booking_end_time || 2400,
        group_booking_start_days: this.policy.group_booking_start_days || 0,
        group_booking_start_time: this.policy.group_booking_start_time || 2400,
        group_booking_end_days: this.policy.group_booking_end_days || 0,
        group_booking_end_time: this.policy.group_booking_end_time || 2400,
        private_booking_cancel_start_days: this.policy.private_booking_cancel_start_days || 0,
        private_booking_cancel_start_time: this.policy.private_booking_cancel_start_time || 2400,
        private_booking_cancel_end_days: this.policy.private_booking_cancel_end_days || 0,
        private_booking_cancel_end_time: this.policy.private_booking_cancel_end_time || 2400,
        group_booking_cancel_start_days: this.policy.group_booking_cancel_start_days || 0,
        group_booking_cancel_start_time: this.policy.group_booking_cancel_start_time || 2400,
        group_booking_cancel_end_days: this.policy.group_booking_cancel_end_days || 0,
        group_booking_cancel_end_time: this.policy.group_booking_cancel_end_time || 2400,
        private_booking_startline: this.policy.private_booking_startline || 0,
        private_booking_deadline: this.policy.private_booking_deadline || 0,
        group_booking_startline: this.policy.group_booking_startline || 0,
        group_booking_deadline: this.policy.group_booking_deadline || 0,
        private_booking_cancel_startline: this.policy.private_booking_cancel_startline || 0,
        private_booking_cancel_deadline: this.policy.private_booking_cancel_deadline || 0,
        group_booking_cancel_startline: this.policy.group_booking_cancel_startline || 0,
        group_booking_cancel_deadline: this.policy.group_booking_cancel_deadline || 0,
        private_using_booking_limit_detail_option: this.isBookingLimitFixed.private ? 1 : 0,
        group_using_booking_limit_detail_option: this.isBookingLimitFixed.group ? 1 : 0,
      };

      switch (
        this.$utils.checkBookingTime(
          deadlines,
          this.policy.private_booking_rule_type,
          this.policy.group_booking_rule_type,
          this.policy.private_booking_cancel_rule_type,
          this.policy.group_booking_cancel_rule_type,
        )
      ) {
        case 'privateBookingTimeError':
          this.$utils.notify.error(this, '프라이빗 수업 예약 가능 시간', '시작 시간이 종료 시간보다 빨라야 합니다.');
          break;
        case 'groupBookingTimeError':
          this.$utils.notify.error(this, '그룹 수업 예약 가능 시간', '시작 시간이 종료 시간보다 빨라야 합니다.');
          break;
        case 'privateCancelTimeError':
          this.$utils.notify.error(this, '프라이빗 수업 예약 취소 가능 시간', '시작 시간이 종료 시간보다 빨라야 합니다.');
          break;
        case 'groupCancelTimeError':
          this.$utils.notify.error(this, '그룹 수업 예약 취소 가능 시간', '시작 시간이 종료 시간보다 빨라야 합니다.');
          break;
        default:
          return deadlines;
      }
    },

    updatedFields() {
      if (!this.originalData) return [];
      return Object.keys(this.policy).filter(key => this.originalData[key] !== this.policy[key]);
    },
    turnOnAutoAbsenceIfCheckInOffed() {
      if (this.policy.is_enter === 0) {
        this.policy.is_auto_absence_for_enter = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-operations {
  max-width: 1024px;
  margin: auto;

  &__type-selector {
    display: flex;
    flex-direction: row;
    /deep/ .el-select {
      width: 238px;
      margin: 0 4px 16px 0;
    }

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 32px;
      width: 238px;
      padding: 0 15px 0 0;
      text-align: center;
      font-size: 12px;
      color: $gray-500;
      &:hover {
        color: $color-primary;
        border: 1px solid $color-primary;
      }
    }
  }

  &__description {
    color: $dodger-blue;
    font-size: 12px;
    margin: 20px 0 10px;
  }

  &__reserve-time {
    /deep/ .el-input__inner {
      text-align: center;
    }
  }

  &__content-padding {
    padding-right: 10px;
  }

  &__description-wrapper {
    @include flex(row, center, space-between);
    flex-wrap: wrap;
    margin: 20px 0 10px;

    p {
      color: $dodger-blue;
      font-size: 12px;
    }

    span {
      /deep/ .el-checkbox__label {
        font-size: 12px;
      }
    }
  }

  &__inline-input.el-input,
  &__inline-date-picker.el-date-editor--date {
    max-width: 40px;
    margin: 0 4px;

    /deep/ .el-input__inner {
      text-align: center;
      margin: 0;
    }
  }

  &__inline-date-picker.el-date-editor--date {
    max-width: 150px;
  }

  &__block-type {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 300;
    opacity: 0.8;
    margin: 15px 0;
  }
  &__group {
    display: flex;
    flex-direction: row;
  }

  &__block {
    border-left: 2px solid $gray-200;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 300;
    opacity: 0.8;
    padding-left: 15px;
    margin: 15px 0;

    & > p {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &:last-child {
        margin: 0;
      }
    }

    &.notification-settings {
      @include flex(row, center);

      .notification-settings__checkbox {
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        margin: 0 30px 0 0;
        padding-right: 30px;

        /deep/ .el-checkbox__label {
          color: $charcoal-grey;
          font-weight: 300;
        }

        &.is-checked {
          /deep/ .el-checkbox__label {
            color: $charcoal-grey;
          }
        }
      }

      .notification-settings__description {
        p + p {
          margin-top: 16px;
        }
      }
    }
  }

  .word-spacing {
    font-size: 13.5px;
    letter-spacing: -0.9px;
  }
  .wrap-enter {
    /deep/ p {
      display: inline;
      color: $gray-600;
    }
    .left-bar {
      border-left: 2px solid #e9ecef;
      padding-left: 15px;
    }
    .description {
      display: block;
      color: $dodger-blue;
      font-size: 12px;
      margin: 5px 0 10px;
    }
    .title-text {
      font-size: 12px;
      margin-top: 30px;
    }
    .wrap-text {
      border-left: 2px solid #e9ecef;
      padding: 5px 0 1px 15px;
      margin: 5px 0 15px;
      label {
        color: $gray-600;
        font-size: 12px;
      }
      .custom-text-input {
        margin-bottom: 15px;
      }
    }

    .checkin__auto {
      @include flex(row, center, space-between);
      margin: 20px 0 11px;
      height: 32px;
      border-left: 2px solid $gray-200;
      padding-left: 15px;
      .title {
        color: $charcoal-grey;
        font-size: 13px;
        font-weight: 700;
      }
      &__description {
        @extend .description;
        margin: 0 17px 0;
      }
    }
  }
}
</style>
