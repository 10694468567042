<template>
  <el-dropdown trigger="click" @command="value => $emit('input', value)">
    <TextInput
      class="custom-select"
      v-bind="$attrs"
      :value="value"
      @input="value => $emit('input', value)"
      :readonly="!allowCreate"
      suffix-icon="el-icon-arrow-down"
    />
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="(option, index) in options" :key="`${option}${index}`" :command="option">{{
        option
      }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import TextInput from './TextInput';

export default {
  inheritAttrs: false,

  components: { TextInput },

  props: {
    value: String,
    options: { type: Array, default: () => [] },
    'allow-create': { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  /deep/ input {
    cursor: pointer;
  }
}
</style>
