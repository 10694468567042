<template>
  <MainLayout v-loading="smsLoading">
    <div id="messages">
      <MessageHeaderTabs class="messages__tab" :active_tab="activeTab" :tabs="tabs" :handleChangeTab="handleChangeTab" />
      <CustomTabs
        v-if="activeTab === 'message-form' || activeTab === 'message-list' || activeTab === 'auto-message-list'"
        class="messages__tab"
        :active_tab="activeTab"
        :tabs="pushTabs"
        :handleChangeTab="handleChangeTab"
      />

      <CustomTabs v-else class="messages__tab" :active_tab="activeTab" :tabs="smsTabs" :handleChangeTab="handleChangeTab" />

      <div class="messages__body">
        <RouterView />

        <MessageForm v-if="activeTab === 'message-form'" :messageType="'message'" />
        <SmsForm
          v-if="activeTab === 'sms-message-form'"
          :saveTemplate="handleClickCreateTemplateButton"
          :handleClickAddAdText="handleClickAddAdText"
          :containsEmoji="containsEmoji"
        />
        <MessageDetail v-else-if="activeTab === 'message-list' && $route.params.id" />
        <SmsMessageDetail v-else-if="activeTab === 'sms-message-list' && $route.params.id" />
        <MessageList v-else-if="activeTab === 'message-list'" />
        <SmsList v-else-if="activeTab === 'sms-message-list'" />
        <MessageTemplate
          v-if="activeTab === 'sms-message-form'"
          :setTemplateToFormText="handleClickSetTemplateModal"
          :deleteTemplate="handleShowDeleteTemplateModal"
          :handleClickAddAdText="handleClickAddAdText"
        />
        <AutoMessageDetail v-else-if="activeTab === 'auto-message-list' && $route.params.id" />
        <AutoMessageList v-else-if="activeTab === 'auto-message-list'" />
        <SmsAutoMessageDetail v-else-if="activeTab === 'sms-auto-message-list' && $route.params.id" />
        <SmsAutoMessageList v-else-if="activeTab === 'sms-auto-message-list'" />
      </div>
    </div>
    <create-template-modal :show="showCreateTemplateModal" :onClose="handleCloseCreateTemplateModal"></create-template-modal>
    <delete-template-modal :show="showDeleteTemplateModal" :onClose="handleCloseDeleteTemplateModal"></delete-template-modal>
  </MainLayout>
</template>

<script>
import MessageForm from '@/components/Messages/Form';
import MessageDetail from '@/components/Messages/Detail';
import SmsMessageDetail from '@/components/Messages/SmsDetail';
import MessageList from '@/components/Messages/List';
import SmsList from '@/components/Messages/SmsList';
import SmsForm from '@/components/Messages/SmsForm';
import MessageTemplate from '@/components/Messages/MessageTemplate';
import CreateTemplateModal from '@/components/Modals/Messages/CreateTemplate';
import DeleteTemplateModal from '@/components/Modals/Messages/DeleteTemplate';
import AutoMessageList from '@/components/Messages/AutoMessageList';
import SmsAutoMessageList from '@/components/Messages/SmsAutoMessageList';
import AutoMessageDetail from '@/components/Messages/AutoMessageDetail';
import SmsAutoMessageDetail from '@/components/Messages/SmsAutoMessageDetail';
import emojiCheck from '@/utils/emojiCheck';

export default {
  components: {
    MessageForm,
    MessageDetail,
    MessageList,
    SmsMessageDetail,
    SmsList,
    SmsForm,
    MessageTemplate,
    CreateTemplateModal,
    DeleteTemplateModal,
    AutoMessageList,
    SmsAutoMessageList,
    AutoMessageDetail,
    SmsAutoMessageDetail,
  },

  data() {
    return {
      tabs: [
        { value: 'sms-message-form', label: '문자 메시지' },
        { value: 'message-form', label: '앱 푸시 메시지' },
      ],
      pushTabs: [
        { value: 'message-form', label: '메시지 보내기' },
        { value: 'message-list', label: '보낸 메시지 목록' },
        { value: 'auto-message-list', label: '자동 발송 목록' },
      ],
      smsTabs: [
        { value: 'sms-message-form', label: '메시지 보내기' },
        { value: 'sms-message-list', label: '보낸 메시지 목록' },
        { value: 'sms-auto-message-list', label: '자동 발송 목록' },
      ],
      showCreateTemplateModal: false,
      showDeleteTemplateModal: false,
      showSetTemplateModal: false,
    };
  },

  computed: {
    activeTab() {
      return this.$route.name;
    },
    formText() {
      return this.$store.getters['template/formText'];
    },
    isAdAdded() {
      return _.get(this.$store.getters['template/formText'], 'isAdAdded');
    },
    studio_message: {
      deep: true,
      get() {
        return this.$store.getters['template/formText'];
      },
    },
    smsLoading() {
      return this.$store.getters['sms/isLoading'];
    },
  },

  methods: {
    handleChangeTab(name) {
      this.$router.push({ name });
    },

    handleClickCreateTemplateButton() {
      if (this.containsEmoji()) {
        this.$utils.notify.error(this, '오류', '문자 메시지에는 이모지를 포함할 수 없습니다.');
        return;
      }
      const { template } = this.formText;
      if (!template) {
        this.$alert('내용을 입력해주세요.', '메시지 정보 없음', {
          showClose: false,
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      this.showCreateTemplateModal = true;
    },

    handleCloseCreateTemplateModal() {
      this.$store.dispatch('template/setFormText', { template_title: '' });
      this.showCreateTemplateModal = false;
    },

    handleShowDeleteTemplateModal() {
      this.showDeleteTemplateModal = true;
    },

    handleCloseDeleteTemplateModal() {
      this.showDeleteTemplateModal = false;
    },

    handleClickSetTemplateModal() {
      this.showSetTemplateModal = true;
    },

    handleCloseSetTemplateModal() {
      this.showSetTemplateModal = false;
    },

    async handleClickAddAdText(flag) {
      if (this.isAdAdded) {
        const titleAdIndex = this.studio_message.title.indexOf('(광고)');
        if (titleAdIndex === 0) {
          this.$store.dispatch('template/setFormText', {
            title: this.studio_message.title.replace('(광고)', ''),
          });
        }
        await this.$store.dispatch('template/setFormText', {
          title: `(광고)${this.studio_message.title}`,
          template: `(광고)${this.studio_message.template}`,
        });
        return;
      }
      if (flag === 'changeTemplate') return;
      //템플릿 변경될 때 아래 로직 통과하면 템플릿 내 (광고) 문구 삭제 방지
      else {
        const titleAdIndex = this.studio_message.title.indexOf('(광고)');
        if (titleAdIndex === 0) {
          this.$store.dispatch('template/setFormText', {
            title: this.studio_message.title.replace('(광고)', ''),
          });
        }

        const templateAdIndex = this.studio_message.template.indexOf('(광고)');
        if (templateAdIndex === 0) {
          this.$store.dispatch('template/setFormText', {
            template: `${this.studio_message.template.replace('(광고)', '')}`,
          });
        }
      }
    },

    containsEmoji() {
      const { title, template } = this.studio_message;
      return emojiCheck(title) || emojiCheck(template);
    },
  },
};
</script>

<style lang="scss" scoped>
#messages {
  @include flex(column);
  max-width: 1024px;
  margin: auto;

  h3 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.messages__tab {
  border-bottom: 1px solid #fbfbfb;
  justify-content: flex-start;
}

.messages__body > section {
  padding: 25px 0px;
}
</style>
