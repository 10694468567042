<template>
  <!-- <el-dialog class="wrap-terms" :visible="open" :show-close="true"> -->
  <el-dialog class="wrap-terms" :visible="open" @close="$emit('close')">
    <div slot="title" class="title-terms">
      약관 동의
    </div>

    <div class="wrap-content">
      <!-- 약관 내용 시작-->
      <iframe
        src="https://studiomate.kr/terms-of-service.html"
        style="width: 100%; height: 40vh"
        frameborder="0"
      ></iframe>
    </div>

    <div slot="footer" class="wrap-btn">
      <div class="wrap-txt">
        <img :src="icon" class="check-icon" alt="check-icon" @click="updateAgree" />
        <span class="txt-label" @click="updateAgree">이용 약관에 동의 합니다.</span>
      </div>
      <div>
        <el-button type="" size="small" @click="$emit('close')">취소</el-button>
        <el-button type="primary" size="small" v-loading="loading" :disabled="!agreedTerms" @click="agreeTerms"
          >확인</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    open: Boolean,
    onClose: Function,
  },
  data() {
    return {
      agreedTerms: false,
      loading: false,
    };
  },
  computed: {
    studioData() {
      return this.$store.getters['studio/studio'];
    },
    icon() {
      return !this.agreedTerms
        ? require('@icons/checkbox_circle_unchecked.svg')
        : require('@icons/checkbox_circle_checked.svg');
    },
  },
  methods: {
    updateAgree() {
      this.agreedTerms = !this.agreedTerms;
    },
    async agreeTerms() {
      this.loading = true;
      try {
        this.$store.dispatch('studio/agreeServiceTerms');
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-terms {
  .title-terms {
    font-weight: bold;
    font-size: 16px;
  }
  .wrap-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrap-content {
    // height: 40vh;
    border: 1px solid $gray-300;
    // overflow-y: scroll;
    overflow-x: hidden;
    // padding: 1em;
  }
  .wrap-txt {
    display: flex;
    align-items: center;
  }
  .check-icon {
    width: 20px;
    cursor: pointer;
  }
  .txt-label {
    display: block;
    padding-left: 5px;
    cursor: pointer;
  }
}
</style>
