<template>
  <el-dialog class="set-holiday" :visible="show" @close="$emit('close')">
    <div slot="title" class="set-holiday__title">
      <h4>휴일설정</h4>
    </div>

    <div class="set-holiday__body">
      <div class="set-holiday__form">
        <div class="set-holiday__form__date">
          <label>날짜</label>
          <el-date-picker
            ref="holiday_picker"
            v-model="date"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          />
        </div>

        <el-tooltip class="set-holiday__form__checkbox" effect="light" placement="top-start">
          <el-checkbox v-model="preventAutobalance">
            자동차감 실행 안함
          </el-checkbox>
          <p slot="content">
            체크하면, 해당 주에 대한 자동차감이 실행되지 않습니다.
          </p>
        </el-tooltip>

        <el-button
          v-loading="setting_holiday"
          @click="setHoliday"
          :disabled="setting_holiday"
          type="primary"
          size="small"
          >휴일설정</el-button
        >
      </div>

      <div class="set-holiday__log">
        <h5>휴일설정이력</h5>
        <el-table :data="studio.holidays" row-class-name="set-holiday__log__row" height="450" fit>
          <el-table-column label="휴일" align="center" min-width="110">
            <template slot-scope="scope">
              {{ scope.row.date | date }}
            </template>
          </el-table-column>

          <el-table-column label="등록시각" align="center" min-width="140">
            <template slot-scope="scope">
              {{ scope.row.created_at | datetime }}
            </template>
          </el-table-column>

          <el-table-column label="자동차감" align="center">
            <template slot-scope="scope">
              {{ scope.row.not_action_auto_balance === 1 ? '미실행' : '실행' }}
            </template>
          </el-table-column>

          <el-table-column header-align="center" align="center">
            <template slot-scope="scope">
              <div class="set-holiday__log__button">
                <el-tag v-if="scope.row.deleted_at" size="medium" type="warning">취소됨</el-tag>
                <el-button
                  v-else-if="scope.row.date >= moment().format('YYYY-MM-DD')"
                  v-loading="cancelling_hoiday"
                  size="mini"
                  type="warning"
                  :disabled="cancelling_hoiday"
                  @click="
                    () => {
                      deleteHoliday(scope.row);
                    }
                  "
                  >취소</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    onClose: Function,
  },

  data() {
    const { moment } = this;

    return {
      setting_holiday: false,
      cancelling_hoiday: false,
      date: moment().format('YYYY-MM-DD'),
      preventAutobalance: false,
      pickerOptions: {
        disabledDate(date) {
          return moment(date).isBefore(moment(), 'day') || moment(date).isAfter(moment().add(1, 'y'), 'day');
        },
      },
    };
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },
  },

  methods: {
    /** 휴일 설정 */
    setHoliday() {
      if (!this.date) {
        this.$utils.notify.error(this, '오류', '날짜를 지정해야 합니다.');
        return this.$refs.holiday_picker.focus();
      }

      const title = '휴일 설정';
      const message = `
          <h4>${this.date} 휴일로 설정하시겠습니까?</h4>
          <p style="margin-top: 1em; color: #f56c6c">
            1. 휴일에 등록된 수업/예약/상담이 취소되며 이는 복구할 수 없습니다.<br>
            2. 휴일에는 그룹 수업을 생성하지 않습니다.
          </p>
        `;
      this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          this.setting_holiday = true;
          try {
            const holiday = {
              date: this.date,
              not_action_auto_balance: this.preventAutobalance,
              // reason: '휴일 입니다.',
              // color: 'f95454',
            };
            await this.$api.studio.holidays.create(holiday);
            this.$utils.notify.success(this, '확인', '휴일이 설정되었습니다.');
            this.$store.dispatch('studio/getStudio');
            this.$emit('close', true);
          } catch (error) {
            this.$utils.notify.parseError(this, error);
          } finally {
            this.setting_holiday = false;
          }
        })
        .catch(() => {});
    },

    /** 휴일 해제 */
    deleteHoliday(holiday) {
      const title = '휴일 취소';
      const message = `<h4>정말로 ${holiday.date} 휴일을 취소하시겠습니까?</h4>`;
      this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(async () => {
          this.cancelling_hoiday = true;
          try {
            await this.$api.studio.holidays.delete(holiday.id);
            this.$utils.notify.success(this, '확인', '휴일이 취소되었습니다.');
            this.$store.dispatch('studio/getStudio');
            this.$emit('close', true);
          } catch (error) {
            this.$utils.notify.parseError(this, error);
          } finally {
            this.cancelling_hoiday = false;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.set-holiday {
  @include flex(row, center, center);
  overflow: auto;

  /deep/ .el-dialog {
    width: 90%;
    max-width: 600px;
    margin: auto !important;
  }

  &__form {
    @include flex(row, center, flex-start);
    flex-wrap: wrap;

    &__date {
      margin-right: 16px;

      label {
        margin-right: 8px;
      }
    }

    &__checkbox {
      margin-right: 24px;

      /deep/ .el-checkbox__label {
        padding-left: 8px;
      }
    }
  }

  &__log {
    margin-top: 22px;

    &__button {
      width: 54px;
      min-height: 28px;
      margin: auto;
    }
  }
}
</style>
