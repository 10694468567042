<template>
  <el-dialog
    v-loading="saving"
    id="batch-cancel-ticket"
    title="수강권 일괄 정지"
    :visible="show"
    :close-on-click-modal="false"
    @close="handleClose(false)"
  >
    <p class="batch-cancel-ticket__message">
      {{
        featureValues === 'filtered'
          ? `필터된 ${allMembers.length}명 회원의 수강권 또는 상품을 일괄 연장 합니다.`
          : `선택된 ${selectedMemberIds.length}명 회원의 수강권 또는 상품을 일괄 정지 합니다.`
      }}
    </p>

    <form class="batch-cancel-ticket__form">
      <div class="batch-cancel-ticket__form__element start_date">
        <label>수강권 정지 시작일</label>
        <el-date-picker
          placeholder="정지 시작일"
          type="date"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          :picker-options="startDatePickerOptions"
          v-model="holdingDetail.start_on"
          :clearable="false"
        />
      </div>
      <div class="batch-cancel-ticket__form__element end_date">
        <label>수강권 정지 종료일</label>
        <el-date-picker
          placeholder="정지 종료일"
          type="date"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          :picker-options="endDatePickerOptions"
          v-model="holdingDetail.end_on"
          :disabled="!holdingDetail.start_on"
          :clearable="false"
        />
      </div>
      <div class="batch-cancel-ticket__form__checkbox">
        <el-checkbox v-model="autoCalculation">만료일 자동 계산</el-checkbox>
        <p>체크하면 정지 기간 만큼 수강권 또는 상품의 만료일이 자동 연장됩니다.</p>
      </div>
    </form>

    <div class="batch-cancel-ticket__form__buttons">
      <el-button type="info" size="small" @click="handleClose(false)">취소</el-button>
      <el-button type="danger" size="small" @click="handleClickOK($route.path)">정지</el-button>
    </div>

    <div class="batch-cancel-ticket__history">
      <label>일괄 정지 이력</label>
      <el-table :data="stopMemoData.data" :max-height="300" fit>
        <el-table-column label="정지시각" prop="created_at" align="center" min-width="150" height="100" />
        <el-table-column label="이름" prop="staff.name" align="center" min-width="80" height="100" />
        <el-table-column label="내용" align="left" min-width="300" height="100">
          <template slot-scope="scope">
            <div v-for="(n, idx) in 2" :key="idx">
              {{ scope.row.memo.split('\\n')[idx] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { BOOKING_STATUS_ARRAY } from '@constants';

const HOLDING_DETAIL_DEFAULT = {
  user_ticket_id: null,
  start_on: '',
  end_on: '',
  auto_calculation: true,
};

export default {
  props: {
    show: Boolean,
    ticket: Object,
    ticketIds: Array,
    containsPeriodTicket: { type: Boolean, default: false },
    filterValues: Object,
    sortType: Object,
    featureValues: String,
    allMembers: Array,
    getMemberAll: Function,
    stopMemoData: Object,
    getMemos: Function,
    dispatchGetMembersList: Function,
    pagination: Object,
    selectedMembers: Array,
    selectedMemberIds: Array,
  },

  data() {
    return {
      data: {
        user_ticket_id: null,
        start_on: '',
        end_on: '',
        auto_calculation: true,
      },

      holdingDetail: { ...HOLDING_DETAIL_DEFAULT },
      holdingHistory: [],
      currentHoldingDate: [],
      saving: false,
      newMemo: [],
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },

    userTicket() {
      return 'test', _.get(this.formData, 'userTicket');
    },

    startDatePickerOptions() {
      const { moment } = this;

      return {
        disabledDate(time) {
          return moment(time).add(1, 'days') <= moment();
        },
      };
    },

    endDatePickerOptions() {
      const { moment, holdingDetail } = this;

      return {
        disabledDate(time) {
          return moment(time).isBefore(holdingDetail.start_on);
        },
      };
    },

    autoCalculation: {
      get() {
        return !!this.holdingDetail.auto_calculation;
      },

      set(value) {
        this.holdingDetail.auto_calculation = value ? 1 : 0;
      },
    },
  },

  methods: {
    /** 모달 닫을 때 데이터 초기화 */
    handleClose(refresh) {
      this.holdingDetail.start_on = '';
      this.holdingDetail.end_on = '';

      this.saving = false;
      this.$emit('close', 'stop', refresh);
    },

    isFuture(holding) {
      return this.moment(holding.start_on)
        .startOf('day')
        .isAfter(this.moment().startOf('day'));
    },

    async handleClickOK() {
      if (!this.validate()) return;

      try {
        this.saving = true;

        const { moment, holdingDetail, filterValues, featureValues, currentHoldingDate, formData } = this;

        const userTicketIds = [];
        const expireTickets = [];
        const memberIdList = [];
        const filterData = [];

        /** 필터, 선택 기준에 따른 회원 데이터 */
        const memberSelected =
          featureValues === 'filtered'
            ? await this.getMemberAll('profile;account;userGrade;avatars;tickets.ticket', false)
            : this.selectedMembers;

        memberSelected.map(member => {
          memberIdList.push(member.id);
          filterData.push(...member.userTickets);
        });

        const usingData = [];
        const stoppedData = [];
        const checkToUse = [];

        usingData.push(...filterData.filter(ticket => ticket.ticket_usable_status === 'using'));
        stoppedData.push(...filterData.filter(ticket => ticket.ticket_usable_status === 'stopped'));
        checkToUse.push(...filterData.filter(ticket => ticket.ticket_usable_status === 'to_use'));

        if (!usingData.length && checkToUse.length)
          return this.$utils.notify.error(this, '오류', '사용 예정인 수강권은 정지되지 않습니다.');

        const today = moment().format('YYYY-MM-DD');
        const finalTickets = filterValues.type === 'active' ? usingData : [...usingData, ...stoppedData];

        finalTickets.map(ticket => {
          userTicketIds.push(ticket.id);
          moment(ticket.expire_at) >= moment() && expireTickets.push(ticket.expire_at.substring(0, 10));
        });

        /** 사용자 티켓의 여러 정지 이벤트 중, picker의 범위에 들어가는게 하나라도 있는지 체크 */
        const standardStartOn = moment(holdingDetail.start_on, 'YYYY-MM-DD');
        const standardEndOn = moment(holdingDetail.end_on, 'YYYY-MM-DD');

        let userStartOn = null;
        let userEndOn = null;

        const checkHoldingData = finalTickets.map(ticket =>
          ticket.holdings.map(holding => {
            userStartOn = moment(holding.start_on, 'YYYY-MM-DD');
            userEndOn = moment(holding.end_on, 'YYYY-MM-DD');

            if (userStartOn < standardStartOn && userEndOn < standardStartOn) return true;
            if (standardEndOn < userStartOn && standardEndOn < userEndOn) return true;
            return false;
          }),
        );

        /** 해당 티켓의 모든 정지 이벤트 중 기간이 겹치지 않으면 계속 진행 */
        const setHoldingData = [];
        checkHoldingData.map(data => setHoldingData.push(...data));

        const checkHolding = setHoldingData.every(data => data === true);
        const checkExpire = expireTickets.every(date => moment(holdingDetail.start_on) <= moment(date));

        if (!checkHolding) {
          this.$utils.notify.error(this, '오류', '해당 기간에 이미 정지 상태인 수강권 또는 상품이 존재합니다.');
          return false;
        } else if (!checkExpire) {
          this.$utils.notify.error(this, '오류', '시작일은 수강권 만료 날짜 이전으로만 설정할 수 있습니다.');
          return false;
        }

        /** 선택된 수강권 중 예약 내역이 있는지 확인 */
        const confirmedBooking = await this.checkBookingsInHoldingPeriod(userTicketIds);

        if (!confirmedBooking) {
          holdingDetail.end_on = currentHoldingDate.end_on || '';
          return (this.saving = false);
        }

        const data = [];

        userTicketIds.map(id => {
          data.push({
            auto_calculation: holdingDetail.auto_calculation,
            start_on: holdingDetail.start_on,
            end_on: holdingDetail.end_on,
            user_ticket_id: id,
          });
        });

        const { userTicket } = formData;
        const checkTickets = memberSelected?.every(member => member.userTickets.length);

        if (!(await this.confirm(memberIdList))) return;

        /** 이차원 객체 형태로 오는 데이터 배열로 가공 */
        const res = await this.$api.product.userBatchStop(data);
        const updateMembers = Object.values(res.data.member);
        const updateData = [];

        updateMembers.map(member => updateData.push({ id: member.id, userTickets: Object.values(member.userTickets) }));

        if (!checkTickets) this.$utils.notify.error(this, '오류', '수강권이 존재하지 않는 회원이 포함되어 있습니다.');
        else if (userTicketIds.length) res;
        else await this.$api.userTicket.holding.update(userTicket.id, holdingDetail);

        this.getMemos(window.location.hostname.split('.')[0]);

        if (res.status === 200) {
          const message = '수강권 또는 상품을 정지하였습니다.';
          this.$utils.notify.success(this, '성공', message);

          if (holdingDetail.start_on === today) this.moveStopFilter();
          this.$store.dispatch('members/getAfterStopSelectedMembers', updateData);
          this.handleClose(true);
        }

        this.handleClose(false);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.saving = false;
      }
    },

    moveStopFilter() {
      const setData = { ...this.filterValues };
      setData.type = 'inHolding';

      this.$store.commit('members/SET_FILTER_VALUES', setData);
    },

    validate() {
      const { holdingDetail } = this;

      if (!holdingDetail.start_on && !holdingDetail.end_on) {
        this.$utils.notify.error(this, '오류', '정지를 시작할 날짜, 종료할 날짜를 설정해주세요.');
        return false;
      } else if (!holdingDetail.end_on) {
        this.$utils.notify.error(this, '오류', '정지를 종료할 날짜를 설정해주세요.');
        return false;
      }

      return true;
    },

    async confirm(memberIdList) {
      const title = '수강권 일괄 정지';
      const message = `${memberIdList.length}명 회원 수강권 또는 상품이 일괄 정지되며 사용 예정인 수강권 또는 상품은 정지되지 않습니다.<br>
            <span style='color:rgb(243, 105, 105)'>
              기간: ${this.holdingDetail.start_on} ~ ${this.holdingDetail.end_on}
            </span><br><br>계속 하시겠습니까?`;

      return await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);
    },

    async handleClickCancel(holdingId) {
      this.saving = true;

      try {
        const endOn = this.moment(this.userTicket.expire_at)
          .add(1, 'day')
          .format('YYYY-MM-DD');

        const diffDate = this.$utils.getDaysDiff(this.currentHoldingDate.end_on, this.currentHoldingDate.start_on) + 1;
        const end = this.moment(this.userTicket.expire_at)
          .subtract(diffDate, 'day')
          .format('YYYY-MM-DD');

        const bookingsInPeriod = this.userTicketBookings.filter(booking => {
          const date = this.moment(booking.lecture.start_on);
          const isBetween = date.isBetween(end, endOn, null, '[]');
          const isBooking = BOOKING_STATUS_ARRAY.BOOKED.includes(booking.status);

          return isBetween && isBooking;
        });

        let confirmCancel = true;

        if (bookingsInPeriod.length) {
          const message = `
            정지 취소시 수강권 이용 종료일 이후 모든 예약은 자동으로 <b>취소</b>됩니다.<br>
            수강권 이용 종료일 이후 <b>${bookingsInPeriod.length}</b> 건의 예약/예약대기가 존재합니다.<br>
            수강권 정지 취소하시겠습니까?
        `;

          confirmCancel = await this.$confirm(message, '수강권 정지 취소', {
            dangerouslyUseHTMLString: true,
          })
            .then(() => true)
            .catch(() => false);
        }

        if (!confirmCancel) return;

        this.holdingDetail.start_on = '';
        this.holdingDetail.end_on = '';

        const { userTicket } = this.formData;
        await this.$api.userTicket.holding.delete(userTicket.id, holdingId);

        this.$utils.notify.success(this, '성공', '수강권 정지를 취소하였습니다.');
        this.$store.dispatch('ticketEdit/getUserTicket', userTicket.id);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.saving = false;
      }
    },

    async checkBookingsInHoldingPeriod(userTicketIds) {
      const data = {
        user_ticket_id: userTicketIds,
        start_on: this.holdingDetail.start_on,
        end_on: this.holdingDetail.end_on,
      };

      const res = await this.$api.booking.checkBookings(data);

      /** 선택된 수강권 중 정지 기간과 예약 내역이 하나도 겹치지 않을 때 */
      if (!res.data.count) return true;

      /** 예약내역이 존재할 경우 경고 문구 생성 */
      const message = `
          <h4 style="line-height: 1.5;">
            설정한 정지 기간 내에 ${res.data.count}건의 예약/예약대기가 존재합니다.<br>
            수강권 정지를 설정하시겠습니까?
          </h4>
          <p style="margin-top: 1em; color: #f56c6c; font-size: 13px; line-height: 1.5;">
            1. 정지 설정시 기간 내의 모든 예약은 자동으로 취소됩니다.<br>
            2. 취소된 예약은 정지 설정을 해제해도 복구되지 않습니다.
          </p>
        `;

      return await this.$confirm(message, '수강권 정지', {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);
    },

    setMemo(cellValue) {
      const memo = cellValue.memo.split('\\n');
      return memo;
    },
  },
};
</script>

<style lang="scss" scoped>
#batch-cancel-ticket {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 600px;
  }

  /deep/ .el-dialog__header {
    padding: 24px;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }
}

.batch-cancel-ticket {
  &__message {
    color: $color-danger;
    font-size: 12px;
    margin: 0 24px 24px;
  }

  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'start_date end_date buttons'
      'checkbox checkbox checkbox';
    grid-gap: 16px 8px;
    padding: 20px;

    &__element {
      @include flex(column);

      label {
        font-size: 12px;
        font-weight: 400;
      }
    }

    &__element.start_date {
      grid-area: start_date;
    }
    &__element.end_date {
      grid-area: end_date;
    }

    &__checkbox {
      grid-area: checkbox;

      p {
        color: $link-color;
        font-size: 12px;
        padding: 8px 0 0 24px;
      }
    }

    &__buttons {
      @include flex(row, center, flex-end);
      border-top: 1px solid #eee;
      margin-top: 20px;
      padding: 24px;
      grid-area: buttons;
    }
  }

  &__history {
    padding: 20px 24px;
    border-top: 1px solid #eee;

    .test {
      color: red;
    }
  }
}
</style>
