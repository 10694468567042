import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ko';
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-analytics';
import VueGtm from 'vue-gtm';
import VueAxios from 'vue-axios';
// import VueCookies from 'vue-cookies';
import './hotjar';

import App from './App';
import router from '@router';
import store from '@store';
import axios from '@api/axios';
import api from '@api';
import utils from '@utils';
import filters from '@filters';
import permissionMixin from './mixins/permissionMixin';
import './registerServiceWorker';
import '@layouts';
import '@components/shared';

moment.locale('ko');
Vue.prototype._ = _;
Vue.prototype.moment = moment;
Vue.prototype.$api = api;
Vue.prototype.$utils = { ...utils };
Vue.prototype.$filters = filters; // to use in script tag
Vue.options.filters = filters; // import filters
Vue.router = router;

Vue.use(moment);
Vue.use(Element, { locale });
Vue.use(Vuelidate);
Vue.use(VueClipboard);
Vue.use(VueMeta);

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GA_TRACKING_ID,
    router,
    autoTracking: {
      skipSamePath: true,
      transformQueryString: false,
    },
  });

  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_CONTAINER_ID, // String || Array
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
  });
}

Vue.use(VueAxios, axios);

// Vue.use(VueCookies);

Vue.mixin(permissionMixin);

Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.config.devtools = process.env.VUE_APP_ENV !== 'production';

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
