<template>
  <div class="timeline" :class="[titlePosition]">
    <div
      v-if="titlePosition === 'left'"
      class="timeline__title"
      :style="{ width: titleWidth ? `${titleWidth}px` : null }"
    >
      <slot name="title" />
    </div>
    <div class="timeline__line">
      <div class="timeline__line__dot" />
      <div class="timeline__line__tail" />
    </div>
    <div class="timeline__content">
      <div v-if="titlePosition === 'right'" class="timeline__title">
        <slot name="title" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titlePosition: { type: String, default: 'left' },
    titleWidth: { type: [Number, String], default: null },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  display: grid;
  grid-template-columns: auto auto 1fr;

  &.right {
    grid-template-columns: auto 1fr;
  }

  &__title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__line {
    display: grid;
    grid-template-rows: 18px 1fr;
    place-items: center;
    width: 30px;

    &__dot {
      background: #d8d8d8;
      border-radius: 50%;
      width: 5px;
      height: 5px;
    }

    &__tail {
      background: #d8d8d8;
      width: 1px;
      height: 100%;
    }
  }

  &__content {
    flex: 1;
    padding: 10px 0 0;
  }

  &.right &__content {
    padding: 0;
  }
}
</style>
