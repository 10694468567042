<template>
  <section id="sales-dashboard" :class="activeTab">
    <TrendChart class="sales-dashboard__trend-chart" />
    <CategoryChart
      v-for="chartName in chartList"
      :key="chartName"
      :class="`sales-dashboard__${chartName}-chart`"
      :title="chartNames[chartName]"
      :chartData="chartData[chartName]"
    />
  </section>
</template>

<script>
import TrendChart from './TrendChart';
import CategoryChart from './CategoryChart';

export default {
  components: {
    TrendChart,
    CategoryChart,
  },

  computed: {
    data() {
      return this.$store.getters['sales/data'];
    },
    filter() {
      return this.$store.getters['sales/filter'];
    },
    activeTab() {
      return this.$store.getters['sales/activeTab'];
    },

    dataSource() {
      return this.data[this.activeTab][this.filter.date];
    },

    chartNames() {
      return {
        paymentType: '결제구분',
        instructors: '수업강사',
        courseType: '수업구분',
        paymentMethod: '결제방법',
        ticketTitle: '수강권명',
      };
    },

    chartList() {
      if (this.activeTab === 'ticketsRevenue') {
        return ['paymentType', 'courseType', 'paymentMethod', 'ticketTitle'];
      } else if (this.activeTab === 'lecturesRevenue') {
        return ['instructors', 'courseType', 'ticketTitle'];
      }
      return [];
    },

    chartData() {
      if (!this.dataSource) return this.$utils.categorizeSalesData();

      const mapped = this.$utils.categorizeSalesData(this.dataSource, this.activeTab);

      let converted = {};
      this.chartList.forEach(chartName => {
        converted[chartName] = {
          labels: mapped[chartName].labels,
          datasets: [
            {
              type: 'pie',
              data: mapped[chartName].values,
              countData: mapped[chartName].counts,
              backgroundColor: mapped[chartName].colors,
            },
          ],
        };
      });

      return converted;
    },

    chartOptions() {
      const { comma } = this.$filters;

      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          titleFontSize: 13,
          titleMarginBottom: 10,
          bodyFontSize: 15,
          bodySpacing: 7,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            label: function(tooltipItem, { datasets, labels }) {
              const label = labels[tooltipItem.index];
              const value = datasets[0].data[tooltipItem.index];
              return `${label}: ${comma(value)}원`;
            },
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#sales-dashboard {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @include mq(1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  &.ticketsRevenue {
    @include mq(1024px) {
      grid-template-areas:
        'trend-chart trend-chart'
        'paymentType paymentMethod'
        'courseType ticketTitle';
    }

    @include mq(1440px) {
      /* grid-template-rows: repeat(2, 300px) minmax(300px, auto); */
      grid-template-areas:
        'trend-chart trend-chart paymentType'
        'trend-chart trend-chart paymentMethod'
        '. courseType ticketTitle';
    }
  }

  &.lecturesRevenue {
    @include mq(1024px) {
      grid-template-areas:
        'trend-chart trend-chart'
        'courseType instructors'
        'ticketTitle .';
    }

    @include mq(1440px) {
      grid-template-rows: auto minmax(300px, auto);
      grid-template-areas:
        'trend-chart trend-chart trend-chart'
        'courseType instructors ticketTitle';
    }
  }
}

@include mq(1024px) {
  .sales-dashboard {
    &__trend-chart {
      grid-area: trend-chart;
    }
    &__paymentType-chart {
      grid-area: paymentType;
    }
    &__instructors-chart {
      grid-area: instructors;
    }
    &__courseType-chart {
      grid-area: courseType;
    }
    &__paymentMethod-chart {
      grid-area: paymentMethod;
    }
    &__ticketTitle-chart {
      grid-area: ticketTitle;
    }
  }
}

@include mq(1440px) {
  .sales-dashboard {
    &__instructors-chart,
    &__courseType-chart,
    &__ticketTitle-chart {
      align-self: start;
    }
  }
}
</style>
