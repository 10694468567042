<template>
  <!-- :index="data.courseType === 'G' ? (data.ticketType === 'T' ? 13 : 12) : data.ticketType === 'T' ? 12 : 11" -->
  <section id="product-form">
    <form-block :index="1" label="상품 종류">
      <el-radio-group v-model="data.productType" class="product-form__radio-group" :disabled="!!product.id">
        <el-radio label="S">판매 상품</el-radio>
        <el-radio label="R">대여 상품</el-radio>
      </el-radio-group>
    </form-block>

    <form-block v-if="data.productType !== 'S'" :index="2" label="상품 유형">
      <el-radio-group v-model="data.ticketType" class="product-form__radio-group" :disabled="!!product.id">
        <el-radio label="RP">기간제</el-radio>
        <el-radio label="RT">횟수제</el-radio>
        <el-radio label="RM">혼합</el-radio>
      </el-radio-group>
    </form-block>

    <form-block :index="data.productType !== 'S' ? 3 : 2" label="상품명 입력">
      <TextInput class="product-form__product-title" ref="product-title" v-model="data.productTitle" required material />
    </form-block>

    <form-block :index="data.productType !== 'S' ? 4 : 3" label="상품 색상 설정">
      <p>
        수강권 유형별(그룹형, 프라이빗형)로 기본 색상이 설정되어 있습니다.<br />
        다른 색상을 지정하려면 색상 추가 버튼을 클릭해서 색상을 변경 할 수 있습니다.<br />
        기본 색상 외 다른 색상을 지정하면 웹 페이지에서만 반영됩니다.
      </p>
      <!-- color set -->
      <div class="wrap-color">
        <div>
          <div
            @click="$emit('handleDefalutColor')"
            class="type-color-chip"
            :class="{ G: data.courseType === 'G', P: data.courseType === 'P', checked: !hasCustomColor }"
          ></div>
          <div class="wrap-color__chips" v-if="hasCustomColor">
            <ColorGradientChip
              v-for="(color, index) in colorChips"
              :key="index"
              :color="color"
              :checked="color[0] === data.selectedColor[0] && checkedColor"
              @click="$emit('handleColorSelect', color)"
            />
          </div>
        </div>
        <plain-button class="wrap-color__add-new" :disabled="hasCustomColor" @click="$emit('openColorSet')">
          색상 추가
        </plain-button>
        <div
          class="wrap-color__sample"
          :class="{ G: data.courseType === 'G', P: data.courseType === 'P' }"
          :style="
            hasCustomColor
              ? `background-image: linear-gradient(to bottom, #${data.selectedColor[0]},#${data.selectedColor[1]})`
              : ''
          "
        >
          <p class="type">상품</p>
          <p class="title">상품 색상 설정 예시</p>
        </div>
        <p class="wrap-color__helper">
          {{ messageOnColorSelected }}
        </p>
      </div>
    </form-block>

    <template v-if="data.productType !== 'S'">
      <template v-if="showPeriodFormBlock">
        <form-block
          :index="data.ticketType === 'RP' || data.ticketType === 'RM' ? 5 : 4"
          :noBtmBorder="data.ticketType === 'RM'"
          label="사용기한 설정"
        >
          <div class="product-form__product-period">
            <el-radio v-model="data.productPeriod" :label="30">1개월 (30일)</el-radio>
            <el-radio v-model="data.productPeriod" :label="60">2개월 (60일)</el-radio>
            <el-radio v-model="data.productPeriod" :label="90">3개월 (90일)</el-radio>
            <el-radio v-model="data.productPeriod" :label="180">6개월 (180일)</el-radio>
            <el-radio v-model="data.productPeriod" :label="365">1년 (365일)</el-radio>
            <el-radio v-if="data.productPeriod !== -1" v-model="data.productPeriod" :label="-1">직접입력</el-radio>
            <NumberInput v-else ref="product-period" v-model="data.productPeriodInput" :min="1" :max="3000" suffix="일" />
          </div>
        </form-block>
      </template>

      <template v-if="showTimeFormBlock">
        <form-block
          :index="data.ticketType === 'RT' || data.ticketType === 'RM' ? 5 : 4"
          :unNumbering="data.ticketType === 'RM'"
          label="이용횟수 설정"
        >
          <div class="product-form__coupon-count">
            <NumberInput ref="max-coupon" v-model="data.maxCoupon" label="총 이용횟수" :min="1" :max="999" suffix="회" />
          </div>
        </form-block>
      </template>
    </template>

    <form-block :index="data.productType !== 'S' ? 6 : 4" label="상품 판매정보 입력">
      <section class="product-form__wrapper">
        <div class="product-form__price">
          <PriceInput ref="actual-price" v-model="data.actualPrice" label="판매가 입력" />
        </div>

        <div class="product-form__point">
          <PointInput ref="reward-point" v-model="data.rewardPoint" label="적립 포인트 입력" />
        </div>
      </section>
    </form-block>
  </section>
</template>

<script>
export default {
  props: {
    data: Object,
    product: Object,
    colorChips: Array,
    hasCustomColor: Boolean,
    messageOnColorSelected: String,
  },

  data() {
    return {
      checkedColor: true,
    };
  },

  computed: {
    showPeriodFormBlock() {
      const { productType, ticketType } = this.data;
      return productType !== 'S' && (ticketType === 'RP' || ticketType === 'RM');
    },

    showTimeFormBlock() {
      const { productType, ticketType } = this.data;
      return productType !== 'S' && (ticketType === 'RT' || ticketType === 'RM');
    },
    isRentalTicketType() {
      const { ticketType } = this.data;
      if (this.$route.name === 'update_product') {
        if (ticketType === 'S') return;
        if (ticketType === 'RT') return 'RT';
        if (ticketType === 'RM') return 'RM';
        if (ticketType === 'RP') return 'RP';
      }
      return 'RP';
    },

    isRentalProduct() {
      const { ticketType } = this.data;
      return ticketType === 'RP' || ticketType === 'RM' || ticketType === 'RT';
    },
  },

  mounted() {
    if (this.$route.name === 'create_product') {
      this.data.ticketType = 'RP';
    }
  },
};
</script>

<style lang="scss" scoped>
#product-form {
  padding: 10px 20px 60px;
  width: 100%;
  max-width: 1024px;
  margin: auto;

  @include mq(568px) {
    padding: 30px 40px 60px;
  }
}

.product-form {
  /deep/ .form-block__label__title {
    h5 {
      font-size: 16px;
    }
  }

  &__cancel_count {
    /deep/ .form-block__label {
      width: 800px;
    }
  }

  &__radio-group {
    margin: 20px 0 10px;
  }

  &__product-title {
    max-width: 300px;
  }

  &__coupon-count,
  &__price,
  &__point {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;

    @include mq(360px) {
      grid-template-columns: repeat(2, minmax(0, 200px));
    }
  }

  &__week-month-limit {
    /deep/ .custom-radio-group {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      margin: 10px 0;
    }
  }

  &__product-period {
    display: grid;
    align-items: center;
    justify-items: start;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin-top: 12px;
    max-width: 480px;

    @include mq(568px) {
      grid-template-columns: repeat(3, 1fr);
    }

    /deep/ .el-radio {
      margin: 8px 0;
    }
  }

  &__wrapper {
    display: flex;
  }
}
.wrap-color {
  margin: 16px 0 16px 0px;
  display: grid;
  grid-template-columns: 272px 170px 1fr;
  grid-gap: 8px;
  align-items: start;
  &__sample {
    @include flex(column);
    width: 256px;
    height: 144px;
    border-radius: 8px;
    padding: 12px;
    position: relative;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    background-image: linear-gradient(122deg, rgba(178, 134, 240, 0), rgba(178, 134, 240, 0.7)),
      linear-gradient(to bottom, #64aeff, #64aeff);
    .type {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .type-color-chip {
    margin-bottom: 8px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-image: linear-gradient(122deg, rgba(178, 134, 240, 0), rgba(178, 134, 240, 0.7)),
      linear-gradient(to bottom, #64aeff, #64aeff);
    transition: background 0.15s;
    position: relative;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: '';
      left: 9px;
      top: 3px;
      width: 0px;
      height: 0px;
      border: solid white;
      border-width: 0 4px 4px 0;
      border-radius: 2px;
      position: absolute;
      opacity: 0;
      transform: rotate(-90deg);
      transition: 0.15s transform, 0.15s opacity, 0.15s width, 0.15s height;
    }

    &.checked::before {
      transform: rotate(40deg);
      opacity: 1;
      width: 12px;
      height: 20px;
    }
  }
  &__chips {
    display: grid;
    grid-template-columns: repeat(7, 32px);
    grid-gap: 8px;
  }

  &__add-new {
    width: 100px;
    height: 32px;
  }

  &__helper {
    grid-column: span 2;
    height: 20px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.6px;
    color: $coral;
    padding-bottom: 10px;
    // border-bottom: solid 1px rgba(#8a8a8a, 0.2);
  }
}
</style>
