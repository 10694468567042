<template>
  <div class="message-preview bg-mockup">
    <div class="message-preview__header">메시지</div>
    <div class="message-preview__content_box">
      <textarea
        class="message-preview-input"
        :disabled="isPreviewed"
        :value="context"
        @input="handleInputContext"
        ref="message-preview-textarea"
      />
      <div :class="`message-preview__footer ${checkLength(byteCount) === 'LMS' ? 'highlight' : ''} ${isOver2000Byte && 'over'}`">
        <span
          >예상바이트&ensp;<b>{{ byteCount }}</b> 바이트</span
        >
        <span class="tag">
          <b>{{ checkLength(byteCount) }}</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isPreviewed: Boolean,
    context: String,
    handleInputContext: {
      default: () => {},
      type: Function,
    },
    isOver2000Byte: Boolean,
    byteCount: Number,
  },

  mounted() {
    if (this.isPreviewed) return;
    this.$refs['message-preview-textarea'].focus();
  },

  methods: {
    checkLength(byteCount) {
      return byteCount > 90 ? 'LMS' : 'SMS';
    },
  },
};
</script>

<style lang="scss" scopped>
.message-preview {
  &.bg-mockup {
    position: relative;
    width: 259px;
    height: 546px;
    background: url('~@icons/cell-phone-mockup.svg');
    background-size: cover;
  }

  &-input {
    width: 244px;
    height: 413.5px;
    padding: 10px 15px;
    margin: 0px 9px;
    resize: none;
    line-height: normal;

    &:disabled {
      background: none;
    }
  }

  &__header {
    padding: 0px 0px 20px 0px;
  }

  &__header {
    top: 44px;
    left: 25px;
    font-weight: bold;
    color: #343a40;
    padding: 44px 25px 8px 25px;

    &:after {
      content: '';
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 71px;
      left: 8.5px;
      height: 1px;
      width: 244px;
    }
  }

  &__content_box {
    width: 259px;
    height: 413.5px;
  }

  &__footer {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    padding: 14.5px 19px;
    margin: 0px 8.5px;
    bottom: 6px;
    font-size: 13px;
    font-weight: normal;

    span {
      b {
        font-weight: 500;
        color: #343a40;
      }
    }

    &.highlight {
      span {
        b {
          color: #ffac26;
        }

        &.tag {
          width: 35px;
          height: 19px;
          top: calc(50% - 9px);
          line-height: 18px;
          border-radius: 3px;
          border: solid 1px #ffac26;
          background-color: #ffedd1;
          color: #ffac26;
          text-align: center;
        }
      }
    }

    &.over {
      span {
        &:first-of-type {
          b {
            color: #ff0000;
          }
        }
      }
    }

    &:after {
      content: '';
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0px;
      left: 0px;
      height: 1px;
      width: 244px;
    }
  }
}
</style>
