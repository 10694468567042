<template>
  <MainLayout :padded="false">
    <div class="sales__password-input" v-if="showPasswordInput">
      <el-card>
        <h4>비밀번호 입력</h4>
        <p>
          매출 페이지 접근을 위한 비밀번호를 입력해주세요.
        </p>
        <input type="text" style="width: 0; height: 0; padding: 0; margin: 0;" />
        <TextInput
          ref="password"
          v-model="password"
          type="password"
          size="large"
          placeholder="비밀번호를 입력해주세요."
          :error="$v.password.$dirty && !$v.password.required"
          @input="$v.password.$touch()"
          @enter="handleClickAuth"
          autocomplete="new-password"
          required
        />
        <BaseButton size="large" @click="handleClickAuth">
          확인
        </BaseButton>
      </el-card>
    </div>

    <el-dialog
      class="sales__auth-dialog"
      title="로그인 만료"
      :visible.sync="showAuthRefreshDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p v-if="!authExpired">{{ authRefreshExpire }}초 후에 매출 페이지에서 로그아웃됩니다.</p>
      <p v-else>
        장시간 사용이 없어 매출 페이지에서 로그아웃 되었습니다.<br />
        다시 로그인 해주세요.
      </p>
      <span slot="footer">
        <BaseButton size="large" @click="authExpired ? resetData() : resetAuthTimer()">
          {{ authExpired ? '확인' : '로그인 연장' }}
        </BaseButton>
      </span>
    </el-dialog>
    <div class="container" v-if="!showPasswordInput && !authExpired">
      <Header />
      <router-view />
    </div>
  </MainLayout>
</template>

<script>
import Header from '@/components/Sales/HeaderNew';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const AUTH_EXPIRE = 60 * 10; // 10분
const AUTH_REFRESH_EXPIRE = 10;

export default {
  components: {
    Header,
  },

  data() {
    return {
      password: '',
      authTimer: null,
      authExpire: AUTH_EXPIRE,
      showAuthRefreshDialog: false,
      authRefreshTimer: null,
      authRefreshExpire: AUTH_REFRESH_EXPIRE,
      authExpired: false,
    };
  },

  computed: {
    showPasswordInput() {
      return this.$store.getters['salesNew/showPasswordInput'];
    },
  },

  watch: {
    showPasswordInput: {
      handler: 'watchShowPasswordInput',
      immediate: true,
    },
    authExpire() {
      if (this.authExpire <= 0) {
        clearInterval(this.authTimer);
        this.showAuthRefreshDialog = true;
        this.authRefreshExpire = AUTH_REFRESH_EXPIRE;
        this.authRefreshTimer = setInterval(() => {
          this.authRefreshExpire--;
        }, 1000);
      }
    },
    authRefreshExpire() {
      if (this.authRefreshExpire <= 0) {
        clearInterval(this.authRefreshTimer);
        this.authExpired = true;
      }
    },
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    password: { required },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.isStudioOwner && !vm.canViewSales) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/');
      }
      next();
    });
  },

  created() {
    if (this.$route.query && this.$route.query.token === 'studiomate') {
      this.$store.commit('salesNew/SET_SHOW_PASSWORD_INPUT', false);
    }
  },

  mounted() {
    if (!this.showPasswordInput) {
      this.resetAuthTimer();
    }
  },

  destroyed() {
    clearInterval(this.authTimer);
    clearInterval(this.authRefreshTimer);
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('salesNew/SET_SHOW_PASSWORD_INPUT', true);
    next();
  },

  methods: {
    async handleClickAuth() {
      if (this.$v.$invalid || this.$v.$anyError) {
        this.$v.password.$touch();
        return;
      }

      try {
        const res = await this.$api.sales.verifySalesPassword({
          password: this.password,
        });
        const verified = res.data.verify;
        if (verified) {
          this.$store.commit('salesNew/SET_SHOW_PASSWORD_INPUT', false);
          this.resetAuthTimer();
        } else {
          this.$utils.notify.error(this, '오류', '비밀번호가 일치하지 않습니다.');
          this.$refs.password.$el.querySelector('input').select();
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    resetAuthTimer() {
      this.authExpire = AUTH_EXPIRE;
      this.authTimer = setInterval(() => {
        this.authExpire--;
      }, 1000);
      clearInterval(this.authRefreshTimer);
      this.showAuthRefreshDialog = false;
    },

    watchShowPasswordInput() {
      if (this.showPasswordInput) {
        this.$nextTick(() => {
          this.$refs.password.$el.querySelector('input').focus();
        });
        clearInterval(this.authTimer);
      } else {
        this.password = '';
        this.$v.password.$reset();
      }
    },

    resetData() {
      this.$store.commit('salesNew/SET_SHOW_PASSWORD_INPUT', true);
      this.password = '';
      this.authTimer = null;
      this.authExpire = AUTH_EXPIRE;
      this.showAuthRefreshDialog = false;
      this.authRefreshTimer = null;
      this.authRefreshExpire = AUTH_REFRESH_EXPIRE;
      this.authExpired = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sales__password-input {
  width: 100%;
  height: 100%;
  background: #ebebeb;
  padding: 15vh 0;

  /deep/ .el-card {
    width: 95%;
    max-width: 400px;
    margin: auto;
  }

  /deep/ .el-card__body {
    @include flex(column);
    h4,
    p {
      margin-bottom: 12px;
    }

    button {
      margin: 12px 0 0 auto;
    }
  }
}

.sales__auth-dialog {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 400px;
  }

  /deep/ .el-dialog__body {
    padding: 20px 20px 10px;
  }
}
</style>
