<template>
  <el-dialog class="change-subscription-result-dialog" width="500px" :visible="show" title="요금제 변경">
    <div>[{{ payload }}] (으)로 요금제가 변경되었습니다.</div>
    <div slot="footer">
      <BaseButton @click="handleClickClose" size="large">확인</BaseButton>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: Boolean,
    payload: String,
  },

  methods: {
    handleClickClose() {
      this.$emit('close');
      return this.$router.push('/settings/service-payments/info').catch(() => {});
    },
  },
};
</script>
