import { render, staticRenderFns } from "./ChangePointProduct.vue?vue&type=template&id=b1c9a5fe&scoped=true&"
import script from "./ChangePointProduct.vue?vue&type=script&lang=js&"
export * from "./ChangePointProduct.vue?vue&type=script&lang=js&"
import style0 from "./ChangePointProduct.vue?vue&type=style&index=0&id=b1c9a5fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1c9a5fe",
  null
  
)

export default component.exports