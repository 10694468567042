/* eslint-disable no-console */
import firebase from 'firebase/app';
import 'firebase/messaging';

let messaging;
try {
  firebase.initializeApp({
    apiKey: 'AIzaSyBcGo2wbkOi3aPkvtMtXaUdwfjvLbuIAao',
    authDomain: 'studiomate-f7328.firebaseapp.com',
    databaseURL: 'https://studiomate-f7328.firebaseio.com',
    projectId: 'studiomate-f7328',
    storageBucket: 'studiomate-f7328.appspot.com',
    messagingSenderId: '567901616566',
    appId: '1:567901616566:web:1038c7f1a042117c',
  });

  messaging = firebase.messaging();
  messaging.usePublicVapidKey('BFQCMT4xq-9bSDkNluef7oczgCisCqz2iKuMUKjZAP6ANLnK_OFC05B788rbwRavbdwWhycY3OOlyHCCzBNHcqQ');
} catch (error) {
  console.log('fcm.js 에러', error);
}

export default messaging;
