export default {
  get(key) {
    return JSON.parse(window.localStorage.getItem(key));
  },

  set(key, value) {
    value = JSON.stringify(value || null);
    window.localStorage.setItem(key, value);
    return JSON.parse(value);
  },
};
