import api from '@api';

export const state = {
  point: 0,
  cards: [],
  type: 'subscription_card_id', // point_card_id || subscription_card_id
  smsProducts: [],
  servicePlanProducts: [],
  paymentInfo: {}, //now_product_name (현재 적용 상품)
  paymentPolicy: {
    studio_id: 0,
    point_card_id: 0,
    point_id: 0,
    usable_point_auto_charge: false,
    point_charging_payment_notification_number: '',
    subscription_card_id: 0,
    subscription_id: 0, //마지막 결제 상품 (현재 적용 상품과 다를 수 있음)
    subscription_change_date: null,
    usable_subscription_auto_charge: false,
    subscription_payment_notification_number: '',
  },
};

export const getters = {
  point: state => state.point,
  smsProducts: state => state.smsProducts,
  servicePlanProducts: state => state.servicePlanProducts,
  cards: state => state.cards,
  paymentPolicy: state => state.paymentPolicy,
  type: state => state.type,
  paymentInfo: state => state.paymentInfo,
};

export const mutations = {
  SET_POINT(state, point) {
    state.point = point;
  },

  SET_SMS_PRODUCTS(state, data) {
    state.smsProducts = data;
  },

  SET_SERVICE_PLAN_PRODUCTS(state, data) {
    state.servicePlanProducts = data;
  },

  SET_CARDS(state, data) {
    state.cards = data;
  },

  SET_PAYMENT_POLICY(state, data) {
    state.paymentPolicy = data;
  },

  SET_TYPE(state, type) {
    state.type = type;
  },

  SET_PAYMENT_INFO(state, data) {
    state.paymentInfo = data;
  },
};

export const actions = {
  init({ rootState, dispatch }) {
    if (!rootState.auth || !rootState.auth.accessToken) {
      return;
    }
    dispatch('getPointScore');
    dispatch('getPaymentPolicy');
  },

  async getPointScore({ commit }) {
    try {
      const res = await api.studio.getPoint();
      commit('SET_POINT', res.data);
      return { success: true };
    } catch (error) {
      commit('SET_POINT', {});
      return { success: false, error };
    }
  },

  async getSmsProducts({ commit }) {
    try {
      const { data } = await api.payment.getSmsProducts();
      commit('SET_SMS_PRODUCTS', data);
      return { success: true };
    } catch (error) {
      commit('SET_SMS_PRODUCTS', []);
      return { success: false, error };
    }
  },

  async getServicePlanProducts({ commit }) {
    try {
      const { data } = await api.payment.getServicePlanProducts();
      commit('SET_SERVICE_PLAN_PRODUCTS', data);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  async getPaymentList({ dispatch }) {
    try {
      const { data } = await api.payment.getCards();
      dispatch('sortPaymentList', data);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  async getPaymentPolicy({ commit }) {
    try {
      const { data } = await api.payment.getPaymentPolicy();
      commit('SET_PAYMENT_POLICY', data);
      return { success: true };
    } catch (error) {
      commit('SET_PAYMENT_POLICY', {});
      return { success: false, error };
    }
  },

  async updatePaymentPolicy({ state, commit }, data) {
    try {
      const response = await api.payment.patchPaymentPolicy(data);
      if (!state.paymentPolicy) {
        commit('SET_PAYMENT_POLICY', { ...data });
      } else commit('SET_PAYMENT_POLICY', { ...state.paymentPolicy, ...data });
      return { data: response.data, success: true };
    } catch (error) {
      return { error, success: false };
    }
  },

  async deletePayment({ state, commit }, id) {
    try {
      const response =  await api.payment.deleteCard(id)
      const result = state.cards.filter(card => card.id !== id);
      commit('SET_CARDS', result);
      return { data: response.data, success: true };
    } catch (error) {
      return { error, success: false };
    }
  },

  async requestPayment({ commit }, { subscription_id, card_id, installment }) {
    try {
      const body = installment ? { installment } : {};
      const { data } = await api.payment.requestPayment({ subscription_id, card_id }, body);
      await commit('SET_PAYMENT_POLICY', data.paymentPolicy);
      return data;
    } catch ({ response }) {
      return response.data;
    }
  },

  sortPaymentList({ state, commit }, data) {
    if (!state.type) return commit('SET_CARDS', data);
    const {
      usable_subscription_auto_charge,
      subscription_card_id,
      usable_point_auto_charge,
      point_card_id,
    } = state.paymentPolicy;
    if (state.type === 'subscription_card_id') {
      const sorted = data.reduce((acc, current) => {
        if (usable_subscription_auto_charge && current.id === subscription_card_id) {
          return [current, ...acc];
        }
        return [...acc, current];
      }, []);
      return commit('SET_CARDS', sorted);
    }
    if (state.type === 'point_card_id') {
      const sorted = data.reduce((acc, current) => {
        if (usable_point_auto_charge && current.id === point_card_id) {
          return [current, ...acc];
        }
        return [...acc, current];
      }, []);
      return commit('SET_CARDS', sorted);
    }
  },

  updateType({ commit }, newType) {
    commit('SET_TYPE', newType);
  },

  async getPaymentInfo({ commit }) {
    const { data } = await api.payment.getServicePaymentInfo();
    commit('SET_PAYMENT_INFO', data);
    return data;
  },
};
