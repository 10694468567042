import api from '@api';
import { POINT_STATUS_OPTIONS, POINT_TYPE_OPTIONS } from '@constants';
import moment from 'moment';

const DEFAULT_STATE = {
  rangeFilter: {
    type: 'month', // month or range
    months: [
      moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ],
  },
  filters: [
    {
      name: 'staff',
      value: [],
      options: [],
      multiple: true,
      placeholder: '강사 전체',
    },
    {
      name: 'pointType',
      value: [],
      options: POINT_TYPE_OPTIONS,
      placeholder: '구분 전체',
      multiple: true,
    },
    {
      name: 'pointStatus',
      value: [],
      options: POINT_STATUS_OPTIONS,
      multiple: true,
      placeholder: '분류 전체',
    },
  ],

  histories: [],
  pointDetail: {
    current_point: 0,
    paid_point: 0,
    reward_point: 0,
  },
  params: {},
  page: 1,
  sort: 'asc',
  total: 1,
  historiesLoading: false,
  pointDetailLoading: false,
};

export const state = {
  ...DEFAULT_STATE,
};

export const getters = {
  rangeFilter: state => state.rangeFilter,
  filters: state => state.filters,
  histories: state => state.histories,
  pointDetail: state => state.pointDetail,
  params: state => state.params,
  page: state => state.page,
  sort: state => state.sort,
  total: state => state.total,
  historiesLoading: state => state.historiesLoading,
  pointDetailLoading: state => state.pointDetailLoading,
};

export const mutations = {
  SET_RANGE_FILTER(state, rangeFilter) {
    state.rangeFilter = { ...rangeFilter };
  },

  SET_FILTERS(state, { name, values }) {
    const newFilters = [...state.filters];
    newFilters.find(filter => filter.name === name).value = values;
    state.filters = newFilters;
  },

  SET_HISTORIES(state, payload) {
    if (!payload) return;

    const { data, meta } = payload;
    const staffOptions = meta.staffs.map(staff => {
      return {
        value: staff.id,
        label: staff.name,
      };
    });
    state.total = meta.total;
    state.filters[0].options = staffOptions;
    state.histories = [...data];
  },

  SET_PAGE(state, page) {
    state.page = page;
  },

  SET_POINT_DETAIL(state, pointDetail) {
    state.pointDetail = pointDetail;
  },

  SET_RESET(state) {
    state.rangeFilter = { ...DEFAULT_STATE.rangeFilter };
    state.filters.forEach(filter => {
      filter.value = [];
    });
    state.page = 1;
  },

  SET_LOADING(state, { type, loading }) {
    if (type === 'histories') {
      state.historiesLoading = loading;
    } else {
      state.pointDetailLoading = loading;
    }
  },

  SET_SORT(state, sort) {
    state.sort = sort;
  },
};

export const actions = {
  async getHistories({ commit }, params) {
    try {
      commit('SET_LOADING', { type: 'histories', loading: true });

      const res = await api.pointHistory.getPointHistories({ ...params });
      commit('SET_HISTORIES', res.data);
    } catch (error) {
      commit('SET_HISTORIES', []);
    } finally {
      commit('SET_LOADING', { type: 'histories', loading: false });
    }
  },

  async getPointDetail({ commit }, memberId) {
    try {
      commit('SET_LOADING', { type: 'pointDetail', loading: true });

      const { data: detail } = await api.pointHistory.getPointDetail(memberId);
      commit('SET_POINT_DETAIL', detail);
    } catch (error) {
      commit('SET_POINT_DETAIL', {
        current_point: 0,
        paid_point: 0,
        reward_point: 0,
      });
    } finally {
      commit('SET_LOADING', { type: 'pointDetail', loading: false });
    }
  },
};
