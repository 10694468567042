var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-card",class:{
    P: _vm.courseType === 'P',
    G: _vm.courseType === 'G',
    I: _vm.courseType === 'I',
    clickable: _vm.clickable,
    inactive: !_vm.isTicketSelling,
    showPrice: _vm.showPrice,
  }},[(_vm.clickable)?_c('div',{staticClass:"ticket-card__click-listener",on:{"click":function($event){return _vm.$emit('click', _vm.ticket.id)}}}):_vm._e(),_c('div',{staticClass:"ticket-card__meta",style:(_vm.isTicketSelling && _vm.haveCustomColor
        ? ("background-image: linear-gradient(to bottom, #" + (_vm.ticketColor[0]) + ",#" + (_vm.ticketColor[1]) + ")")
        : '')},[_c('div',{staticClass:"ticket-card__header"},[_c('h5',[_vm._v(_vm._s(_vm.ticketType))]),(!_vm.isTicketSelling)?_c('span',{staticClass:"ticket-card__header__tag"},[_vm._v(" 판매정지 ")]):(_vm.showFavoriteIcon)?_c('img',{staticClass:"ticket-card__header__button favorite",attrs:{"src":_vm.favoriteIcon,"alt":"수강권 즐겨찾기"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('favorite-icon-click', _vm.ticket.id)}}}):_vm._e()]),_c('div',{staticClass:"ticket-card__body"},[_c('h3',[_vm._v(_vm._s(_vm.ticketTitle))]),(_vm.tutorial)?_c('span',{staticClass:"ticket-card__body__tutorial"},[_vm._v("체험권")]):_vm._e()]),_c('div',{staticClass:"ticket-card__footer"},[_c('p',_vm._l((_vm.ticketMeta),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item))])}),0)])]),(_vm.showPrice)?_c('div',{staticClass:"ticket-card__extra"},[_c('div',{staticClass:"ticket-card__extra__price"},[_c('p',{staticClass:"ticket-card__extra__price__total"},[_vm._v("판매 금액 "+_vm._s(_vm._f("comma")(_vm.ticketPrice.total))+"원")]),(!_vm.isMaxCoupon)?_c('p',{staticClass:"ticket-card__extra__price__per-coupon"},[_vm._v("회당 가격 "+_vm._s(_vm._f("comma")(_vm.ticketPrice.perCoupon))+"원")]):_vm._e()]),(_vm.showCheckbox)?_c('img',{staticClass:"ticket-card__extra__checkbox",attrs:{"src":_vm.checkboxIcon,"alt":"수강권 선택"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('checkbox-click', _vm.ticket)}}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }