<template>
  <el-dialog class="wrap-terms" :visible="open" @close="$emit('close')">
    <div slot="title">
      결제 취소 및 환불 안내
    </div>

    <div class="wrap-content">
      <!-- 약관 내용 시작-->
      <h4>제12조 (이용권 결제, 취소 및 환불)</h4>

      <ol class="no-style">
        <li>
          ① 서비스에 오류가 있을 경우 시설은 오류발생 시점부터 7일 이내에 회사에 정정신청을 하여야 하며, 회사는 시설의 정정
          신청일로부터 최대 1개월 이내에 정정절차를 마쳐야 합니다. 단, 시설은 필요한 경우 서비스 오류를 증명할 수 있는 객관적인
          자료를 제시 하여야 합니다.
        </li>
        <li>
          ② 서비스 만료 기간이 지나면 접속 및 이용이 불가합니다. 시설의 만료일 최소 7일 전에 웹페이지에 게시합니다. 또 시설은
          만료기간전에 회사 서비스의 설정화면에서 만료기간 확인이 가능합니다.
        </li>
        <li>
          ③ 회사의 이벤트 등의 마케팅 활동으로 시설에게 추가로 제공된 이용권의 유효기간은 회사의 정책에 따라 별도로 책정될 수
          있으며 이는 별도로 공지합니다.
        </li>
        <li>
          ④ 시설이 서비스 이용을 중지하고자 환불하는 경우, 이용 시작 전과 이용 시작 후에 따라 다르며 해당 내용은 아래와 같습니다.
        </li>
      </ol>

      <em
        >※ 이용 시작 전, 시설이 납부완료한 이용료에 대한 환불을 요청하는 경우, 한국 소비자원의 소비자 분쟁해결 기준 및
        공정거래위원회의 표준약관에 의거하여 아래 규정에 따라 진행됩니다.</em
      >
      <table>
        <tr>
          <th>구분</th>
          <th>내용</th>
        </tr>
        <tbody>
          <tr>
            <td>이용시작전</td>
            <td>
              <ul>
                <li>결제일로부터 7일 이내 결제 취소 요청 시 전액 환불</li>
                <li>
                  결제일로부터 7일 경과된 경우에는 결제액에서 위약금<br />
                  (결제상품의 10%) 공제 후 환불
                </li>
                <li>이용기간이 만료된 상품의 경우 환불 불가</li>
                <li>할인특가상품의 경우 할인가격이 아닌 정상가격을 기준으로 공제한 후 환불</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>이용시작 후</td>
            <td>
              <ul>
                <li>시설은 유료 서비스 공급일 이후 7일 이내에 결제 취소(청약 철회)를 요청할 수 있습니다.</li>
                <li>
                  정기결제 시설이 유료 서비스 공급일 이후 청약 철회가 가능한 기간(유료 서비스 공급일로부터 7일 이내)을 경과하여
                  청약 철회를 신청하면 환불은 불가능 합니다.
                </li>
                <li>
                  정기결제 시설이 이미 결제한 서비스 이용 계약을 해제, 해지 했을 때, 남은 계약 기간동안 유료서비스를 이용할 수
                  있습니다.
                </li>
                <li>
                  정기결제 시설이 현재 상품을 이용하고 다음 달부터 해지하는 경우 다음 달부터 정기 결제가 취소됩니다.
                </li>
                <li>
                  <p>
                    일시불 결제 시설이 유료 서비스 공급일 이후 청약 철회가 가능한 기간(유료 서비스 공급일로부터 7일 이내)을
                    경과하여 결제 취소시
                  </p>
                  <ul>
                    <li>
                      위약금(결제 상품의 10%)과 상품 결제에 대한 할인분을 적용하지 않고 월 정기결제 기준의 가격으로 월할 및 일할
                      계산하여 정산 후 환불합니다.
                    </li>
                    <li>
                      연결제 상품의 경우 위약금(결제 상품가격의 10%)과 연 결제에 대한 할인분을 적용하지 않고 월 정기결제 기준의
                      가격으로 월할 및 일할 계산하여 정산 후 환불.
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>예외사항</td>
            <td>
              <ul>
                <li>
                  시설이 내부공사 등 부득이한 사정으로 이용이 불가한 경우에는 담당자 확인 후 월 10일 이상 휴장에 한하여 사용 기간
                  만큼의 이용료 계산 후 환급 가능
                </li>
                <li>정기 휴무, 휴가 등 시설 사정에 따른 월 10일 미만의 미사용 기간은 환불 불가</li>
                <li>
                  해외 출장 및 질병, 부상에 의해 10일 이상 이용불가에 대해서는 해당 내용에 관한 서류를 회사로 전송 시, 확인 후
                  위약금 공제 없이 환불 가능
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <em>※ 결제수단 별 상세 환불 절차는 아래 규정에 따라 진행됩니다.</em>

      <table>
        <tr>
          <td>
            CMS 자동이체, 무통장 입금
          </td>
          <td>환불 규정에 따라 공제 후 계좌 환불</td>
        </tr>
        <tr>
          <td>
            카드결제
          </td>
          <td>
            환불 규정에 따라 공제 후 결제 부분 취소. 영업일 기준으로 3~7일 소요(정확한 일정은 해당 카드사에 문의하여 확인하실 수
            있습니다.)
          </td>
        </tr>
      </table>

      <h4>제13조 (이용권 변경, 정지 및 해지)</h4>

      <ul class="no-style">
        <li>① 이용권 상품 변경은 스튜디오메이트 고객센터로 본인이 직접 신청 해 주셔야 합니다.</li>
        <li>② 정기결제 이용자는 결제수단에 따라 아래와 같이 변경할 수 있습니다.</li>
      </ul>
      <div class="deep-count-style">
        <ol>
          <li>
            신용카드 정기결제
            <ol>
              <li>
                변경된 상품은 결제청구 이후 이용시작일부터 적용됩니다.
              </li>
              <li>
                정기 결제일 5일 전까지 신청해야 다음 이용권부터 변경된 상품이 적용되며 그 후에 신청할 경우, 그 다음 이용권부터
                변경된 상품이 적용됩니다.
              </li>
            </ol>
          </li>
          <li>
            CMS (계좌이체) 정기결제
            <ol>
              <li>변경된 상품은 익월 1일부터 적용됩니다.</li>
              <li>매월 20일 이후 신청한 경우에는 익익월 1일부터 적용됩니다.</li>
            </ol>
          </li>
        </ol>
      </div>
      <!-- 약관 내용 끝-->
    </div>
    <div slot="footer">
      <el-button type="primary" size="small" @click="$emit('close')">닫기</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    open: Boolean,
    onClose: Function,
  },
  data() {
    return {
      agreedTerms: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.wrap-terms {
  .el-dialog__header {
    color: #000;
  }
  .agree-paragraph {
    text-align: right;
    padding-top: 5px;
  }
  header {
    font-weight: bold;
  }
  .wrap-content {
    height: 40vh;
    border: 1px solid $gray-300;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1em;
  }
  h2 {
    font-size: 16px;
    font-weight: normal;
    line-height: 2;
    margin-top: 30px;
  }
  h4 {
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
  }
  ul li {
    list-style: disc;
  }
  ol li {
    list-style: decimal;
  }
  li {
    margin: 0 0 1em 2em;
  }
  .no-style > li {
    list-style: none;
    margin-left: 1em;
  }
  .count-style {
    margin-top: 1em;
    & > li {
      list-style-type: decimal;
    }
  }
  .more-indent > li {
    margin-left: 3em;
  }
  table {
    margin: 1em;
  }
  table,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid $gray-200;
    padding: 0.5em 1em;
  }
}
</style>
