<template>
  <MainLayout>
    <div id="locker">
      <h3>락커</h3>
      <LockerCount />
      <div class="locker__body">
        <LockerForm />
      </div>
    </div>
  </MainLayout>
</template>

<script>
import LockerForm from '@/components/Locker/Form';
import LockerCount from '@/components/Locker/LockerCount';

export default {
  components: { LockerForm, LockerCount },
};
</script>

<style lang="scss" scoped>
#locker {
  @include flex(column);
  max-width: 1865px;
  margin: auto;
  h3 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
.locker__tab {
  border-bottom: 1px solid #fbfbfb;
  justify-content: flex-start;
}
.locker__body {
  padding: 20px 0 40px 0;
}
</style>
