import axios from '../axios';

const BASE_URL = '/v2/staff/comment';

export default {
  getAll: (parentContentId) => {
    const res = axios.get(`/v2/staff/board/${parentContentId}`);
    // const comments = res.comments
    return res
  },
  get: id => axios.get(`${BASE_URL}/${id}`),
  // create: data => axios.post(BASE_URL, data),
  update: data => axios.patch(`${BASE_URL}/${data.id}`, data),
  delete: id => axios.delete(`${BASE_URL}/${id}`),
 create: reply_create => {
    return axios.post(BASE_URL,
    reply_create);
  },
//   update: data => {
//     return axios.patch(`${BASE_URL}/${data.id}`, data)
//   },
//   delete: id => axios.delete(`${BASE_URL}/${id}`)
};
