<template>
  <section class="payment-detail">
    <div class="payment-detail__card">
      <TicketCard :ticket="ticket" :showFavoriteIcon="ticket.favorite_stamp ? true : false" />
    </div>

    <PaymentForm v-model="paymentDetail" :referencePrice="actualPrice" />

    <div class="payment-detail__buttons">
      <el-button @click="handleClickPrev">
        이전
      </el-button>
      <el-button @click="handleClickComplete" type="primary" :disabled="completeButtonDisabled">
        완료
      </el-button>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      paymentDetail: {
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        unpaid_amount: 0,
        amount: 0,
        installment: 1,
        installment_period: 2,
        settlement_at: this.moment().format('YYYY-MM-DD HH:mm:ss'),
        reward_point: 0,
        current_point: 0,
        point_amount: 0,
      },
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketIssue/formData'];
    },

    ticket() {
      return this.formData.selectedTicket;
    },

    actualPrice() {
      return _.get(this.ticket, 'actual_price') || 0;
    },

    completeButtonDisabled() {
      return !this.paymentDetail.settlement_at;
    },

    rewardPoint() {
      return _.get(this.ticket, 'reward_point');
    },

    memberId() {
      return this.$route.query.id;
    },
  },

  async created() {
    try {
      const { paymentDetail, currentPoint } = this.formData;

      if (paymentDetail) {
        this.paymentDetail = {
          ...paymentDetail,
          current_point: currentPoint,
        };
      } else {
        this.paymentDetail = {
          ...this.paymentDetail,
          amount: this.actualPrice,
          card_amount: this.actualPrice,
          reward_point: this.rewardPoint,
          current_point: currentPoint,
        };
      }
    } catch (error) {
      this.$utils.notify.error(this, '오류', error.response.data.message);
    }
  },

  methods: {
    /** 이전 단계로 */
    handleClickPrev() {
      const { currentStep } = this.formData;
      this.savePaymentDetail();
      this.$store.commit('ticketIssue/SET_VALUES', {
        currentStep: currentStep - 1,
      });
    },

    /** 완료버튼 클릭시: 수강권 발급 */
    handleClickComplete() {
      this.savePaymentDetail();
      this.$emit('complete');
    },

    /** 입력 정보 임시 저장 */
    savePaymentDetail() {
      const newValues = _.omit(this.paymentDetail, 'current_point');
      this.$store.commit('ticketIssue/SET_VALUES', {
        paymentDetail: { ...newValues },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-detail {
  @include flex(column, center);
  min-height: 554px;

  &__card {
    margin: 20px 0;
  }

  &__buttons {
    @include flex(row, flex-end, space-between);
    flex: 1;
    width: 100%;
    padding: 20px 20px 0;
  }
}
</style>
