<template>
  <section id="settings-required">
    <form-block :index="1" label="상호명">
      <TextInput class="settings-required__studio_name" v-model="name" placeholder="상호명을 입력해주세요" material />
    </form-block>

    <form-block :index="2" label="주소">
      <el-input
        class="settings-required__address"
        ref="address"
        v-model="address"
        type="text"
        placeholder="주소를 입력해주세요"
        @focus="showPostCode()"
        readonly
      />
      <TextInput
        class="settings-required__detail_address"
        ref="detail_address"
        v-model="detail_address"
        placeholder="상세주소를 입력해주세요"
        material
      />
    </form-block>

    <form-block :index="3" label="연락처">
      <transition-group name="el-zoom-in-top">
        <ContactInput
          v-for="(contact, index) in contacts"
          :key="`${contact.id}${index}`"
          :contact="contact"
          :index="index"
          :handleClickAdd="handleClickAddContact"
          :handleClickRemove="handleClickRemoveContact"
        />
      </transition-group>
    </form-block>

    <form-block :index="4" label="영업시간 설정">
      <WorkingHourInput
        v-for="(weekday, index) in workingHoursByWeekday"
        :key="index"
        :weekday="weekday"
        :index="index"
        :applyToAllOpenHours="applyToAllOpenHours"
        hideClosedHours
      />
    </form-block>

    <form-block :index="5" label="매출 페이지 비밀번호 설정" v-if="isStudioOwner">
      <div class="settings-required__sales-password">
        <PlainButton style="max-width: 150px;" @click.native="showUpdatePasswordDialog = true">비밀번호 변경</PlainButton>
        <PlainButton v-loading="updatingSalesPassword" style="max-width: 150px;" @click.native="handleClickResetPassword"
          >비밀번호 초기화</PlainButton
        >
      </div>
    </form-block>

    <bottom-action-bar :message="last_updated_at">
      <el-button @click="saveSettings" v-loading="isSendingMessage" :disabled="isSendingMessage">
        {{ studio.id ? '정보 수정 완료' : '정보 등록 완료' }}
      </el-button>
    </bottom-action-bar>

    <el-dialog
      class="settings-required__update-password-dialog"
      title="매출 페이지 비밀번호 변경"
      :visible.sync="showUpdatePasswordDialog"
    >
      <div class="settings-required__update-password-dialog__body">
        <TextInput
          ref="oldPassword"
          v-model="oldPassword"
          label="현재 비밀번호"
          labelAlign="row"
          labelWidth="150px"
          type="password"
          size="large"
          placeholder="현재 비밀번호를 입력해주세요."
          :error="inputErrors.oldPassword.error"
          @input="$v.oldPassword.$touch()"
          @enter="handleClickUpdatePassword"
          required
        />
        <TextInput
          v-model="newPassword"
          label="새로운 비밀번호"
          labelAlign="row"
          labelWidth="150px"
          type="password"
          size="large"
          placeholder="새로운 비밀번호를 입력해주세요."
          :error="inputErrors.newPassword.error"
          :errorMessage="inputErrors.newPassword.errorMessage"
          @input="$v.newPassword.$touch()"
          @enter="handleClickUpdatePassword"
          required
        />
        <TextInput
          v-model="newPasswordConfirm"
          label="새로운 비밀번호 확인"
          labelAlign="row"
          labelWidth="150px"
          type="password"
          size="large"
          placeholder="새로운 비밀번호를 한번 더 입력해주세요."
          :error="inputErrors.newPasswordConfirm.error"
          :errorMessage="inputErrors.newPasswordConfirm.errorMessage"
          @input="$v.newPasswordConfirm.$touch()"
          @enter="handleClickUpdatePassword"
          required
        />
      </div>
      <span slot="footer">
        <PlainButton size="large" @click="showUpdatePasswordDialog = false">
          취소
        </PlainButton>
        <BaseButton v-loading="updatingSalesPassword" size="large" style="margin-left: 16px;" @click="handleClickUpdatePassword"
          >비밀번호 변경</BaseButton
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      name: '',
      address: '',
      detail_address: '',
      contacts: [{ type_id: 1, contact: '', is_representative: true }],
      workingHoursByWeekday: {},

      showUpdatePasswordDialog: false,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      updatingSalesPassword: false,
      isSendingMessage: false,
    };
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },

    last_updated_at() {
      const date = this.studio.updated_at || this.studio.created_at;
      if (!date) return null;

      const suffix = this.studio.updated_at ? '에 마지막으로 수정됨' : '에 생성됨';

      return `${this.$filters.datetime(date)} ${suffix}`;
    },

    inputErrors() {
      return {
        oldPassword: {
          error: this.$v.oldPassword.$dirty && this.$v.oldPassword.$error,
        },
        newPassword: {
          error: this.$v.newPassword.$dirty && this.$v.newPassword.$error,
          errorMessage:
            this.$v.newPassword.required && (!this.$v.newPassword.minLength || !this.$v.newPassword.minLength)
              ? '비밀번호는 4~25자로 설정해주세요.'
              : '',
        },
        newPasswordConfirm: {
          error: this.$v.newPasswordConfirm.$dirty && this.$v.newPasswordConfirm.$error,
          errorMessage:
            this.$v.newPasswordConfirm.required && !this.$v.newPasswordConfirm.sameAs ? '비밀번호를 동일하게 입력해주세요.' : '',
        },
      };
    },
  },

  watch: {
    showUpdatePasswordDialog() {
      if (this.showUpdatePasswordDialog) {
        this.$nextTick(() => {
          this.$refs.oldPassword.$el.querySelector('input').focus();
        });
      } else {
        this.oldPassword = '';
        this.newPassword = '';
        this.newPasswordConfirm = '';
        this.$v.oldPassword.$reset();
        this.$v.newPassword.$reset();
        this.$v.newPasswordConfirm.$reset();
      }
    },
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    oldPassword: { required },
    newPassword: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(25),
    },
    newPasswordConfirm: {
      required,
      sameAs: sameAs('newPassword'),
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.canUpdateFacilitySettings) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/settings');
      }

      next();
    });
  },

  async created() {
    this.setInitialValues();
  },

  methods: {
    /** 초기 데이터 설정 */
    async setInitialValues() {
      const { name, address, contact_infos, open_hour, close_hour } = this.studio;

      this.name = name;
      this.address = address.address;
      this.detail_address = address.detail_address;
      let checkSMSSendNumber = false;
      if (contact_infos && contact_infos.length) {
        this.contacts = contact_infos.map(({ type_id, contact, is_representative }, index) => {
          if (type_id === 5) {
            checkSMSSendNumber = true;
          }
          return {
            type_id,
            contact,
            is_representative: index === 0 ? true : is_representative,
          };
        });
      }

      if (!checkSMSSendNumber) {
        this.contacts.push({ type_id: 5, contact: null });
      }

      let events = [];
      if (open_hour) events = [...open_hour.events];
      if (close_hour) events = [...events, ...close_hour.events];
      this.workingHoursByWeekday = this.groupWorkingHoursByWeekday(events);
    },

    /** 변경 사항 저장 */
    async saveSettings() {
      this.isSendingMessage = true;
      let phoneNumberCheck = true;

      /** 연락처 정보 */
      if (!this.contacts.length || !this.contacts[0].contact) {
        this.isSendingMessage = false;
        return this.$utils.notify.error(this, '오류', '연락처를 입력해주세요');
      }
      const contactInfos = this.contacts.filter(({ contact }) => contact);
      /**연락처 문자열 입력시 오류 */
      await contactInfos.map(el => {
        if (el.type_id !== 4 && isNaN(Number(el.contact))) {
          phoneNumberCheck = false;
          return this.$utils.notify.parseError(this, { message: '연락처는 숫자만 입력 가능합니다.' });
        }
      });

      if (!phoneNumberCheck) return (this.isSendingMessage = false);

      /** 주소 정보 */
      const address = {
        studio_id: this.studio.id,
        address: this.address,
        detail_address: this.detail_address,
      };

      /** 영업시간 정보 */
      const { openedHour, closedHours } = this.flattenWorkingHours(this.workingHoursByWeekday);

      /** 정보 저장 */
      const data = {
        name: this.name,
        address,
        contactInfos,
        openedHour,
        closedHours,
      };
      const result = await this.$store.dispatch('studio/updateStudio', data);
      await this.$store.dispatch('studio/getStudio');
      if (result.success) {
        this.$utils.notify.success(this, '성공', '저장되었습니다');
      } else {
        if (result.error.response.data.status_code === 422 && result.error.response.data.errors.name) {
          this.$utils.notify.parseErrorImage(this, result.error, 'name'); //중복 상호명 수정 에러 처리
        } else {
          this.$utils.notify.parseError(this, result.error);
        }
      }
      this.isSendingMessage = false;
    },

    /** 매출 페이지 비밀번호 변경 */
    async handleClickUpdatePassword() {
      if (!this.$v.$anyDirty || this.$v.$anyError) {
        ['oldPassword', 'newPassword', 'newPasswordConfirm'].forEach(key => {
          if (!this.$v[key].required) {
            this.$v[key].$touch();
          }
        });
        return;
      }

      try {
        this.updatingSalesPassword = true;
        if (this.oldPassword === this.newPassword) {
          this.$utils.notify.parseError(this, { message: '이전 비밀번호와 동일합니다.' });
        } else {
          await this.$api.sales.updateSalesPassword({
            old_password: this.oldPassword,
            password: this.newPassword,
            password_confirmation: this.newPasswordConfirm,
          });
          this.$utils.notify.success(this, '확인', '매출 페이지 비밀번호가 변경되었습니다.');
          this.showUpdatePasswordDialog = false;
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.updatingSalesPassword = false;
      }
    },

    async handleClickResetPassword() {
      const title = '매출 페이지 비밀번호 초기화';
      const message = '매출 페이지 비밀번호를 초기화합니다.<br>계속 하시겠습니까?';

      const proceed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (!proceed) return;

      try {
        this.updatingSalesPassword = true;
        await this.$api.sales.resetSalesPassword();
        this.$utils.notify.success(this, '확인', '매출 페이지 비밀번호가 초기화되었습니다.');
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.updatingSalesPassword = false;
      }
    },

    /** 연락처 추가 */
    handleClickAddContact() {
      this.contacts.push({ type_id: 1, contact: '' });
    },

    /** 연락처 제거 */
    handleClickRemoveContact(index) {
      this.contacts.splice(index, 1);
    },

    /** 휴식시간 추가 */
    handleClickAddRestHours(weekday) {
      const { open } = this.workingHoursByWeekday[weekday];
      this.workingHoursByWeekday[weekday].closed.push({
        start: open[0].start,
        end: open[0].end,
      });
    },

    /** 휴식시간 제거 */
    handleClickRemoveRestHours(weekday, closed_hour_index) {
      this.workingHoursByWeekday[weekday].closed.splice(closed_hour_index, 1);
    },

    /** 영업시간 일괄 적용 */
    applyToAllOpenHours() {
      const { start, end } = this.workingHoursByWeekday[0].open[0];
      const { closed } = this.workingHoursByWeekday[0];
      Object.keys(this.workingHoursByWeekday).forEach(weekday => {
        if (!this.workingHoursByWeekday[weekday].isHoliday) {
          this.workingHoursByWeekday[weekday].open = [{ start, end }];
          this.workingHoursByWeekday[weekday].closed = [...closed];
        }
      });
    },

    /** 주소입력창 팝업 */
    showPostCode() {
      // 주소입력 인풋에서 포커스 해제 (중복 실행 방지)
      this.$refs.address.blur();

      // load core script
      const self = this;
      window.daum.postcode.load(() => {
        new window.daum.Postcode({
          oncomplete: function(data) {
            let { address, bname } = data;
            // 읍면동 추가
            if (bname) address += ` (${bname})`;

            self.address = address;
            self.$refs.detail_address.$el.querySelector('input').focus();
          },

          onclose: function(state) {
            // on forced close
            if (state === 'FORCE_CLOSE') {
              self.address = '';
            }
          },
        }).open();
      });
    },

    /**
     * 요일별 영업시간 정보 -> 영업시간/휴식시간 구분
     * (서버 전달용으로 변환)
     */
    flattenWorkingHours(workingHoursByWeekday) {
      let open_hours = [];
      let closed_hours = [];

      this._.each(workingHoursByWeekday, ({ isHoliday, open, closed }, index) => {
        if (isHoliday) return;

        let day_of_week;
        if (parseInt(index) === 6) {
          day_of_week = 0;
        } else {
          day_of_week = parseInt(index) + 1;
        }

        open.forEach(({ start, end }) => {
          open_hours.push({
            start_time: start,
            end_time: end,
            recurringPattern: {
              day_of_week,
              recurring_type_id: 2,
            },
          });
        });

        closed.forEach(({ start, end }) => {
          closed_hours.push({
            start_time: start,
            end_time: end,
            recurringPattern: {
              day_of_week,
              recurring_type_id: 2,
            },
          });
        });
      });

      return {
        openedHour: { events: open_hours },
        closedHours: { events: closed_hours },
      };
    },

    /**
     * 영업시간/휴식시간 구분 -> 요일별 영업시간 정보
     * (화면 표시용으로 전환)
     */
    groupWorkingHoursByWeekday(events) {
      let mapped = {};

      this._.range(7).forEach(weekday => {
        mapped[weekday] = {
          open: [{ start: '09:00', end: '18:00' }],
          closed: [],
          isHoliday: true,
        };
      });

      events.forEach(event => {
        const { schedule_type, recurring_pattern, start_time, end_time } = event;

        if (!schedule_type || !recurring_pattern || !start_time || !end_time) return;

        const weekday = recurring_pattern.day_of_week !== 0 ? recurring_pattern.day_of_week - 1 : 6;
        const type = schedule_type === 'facilityOpenedHour' ? 'open' : schedule_type === 'facilityClosedHour' ? 'closed' : '';

        if (type === 'open') {
          mapped[weekday].isHoliday = false;
          mapped[weekday][type] = [
            {
              start: start_time.slice(0, 5),
              end: end_time.slice(0, 5),
            },
          ];
        } else {
          mapped[weekday][type].push({
            start: start_time.slice(0, 5),
            end: end_time.slice(0, 5),
          });
        }
      });

      return mapped;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-required {
  &__studio_name {
    max-width: 200px;
    font-weight: 300;
    margin-bottom: 12px;
  }

  &__address {
    /deep/ .el-input__inner {
      max-width: 490px;
      font-weight: 300;
      margin-bottom: 12px;
    }
  }

  &__detail_address {
    max-width: 490px;
    font-weight: 300;
    margin-bottom: 12px;
  }

  &__sales-password {
    button {
      margin-right: 8px;
    }
  }

  &__update-password-dialog {
    /deep/ .el-dialog {
      width: 95%;
      max-width: 568px;
    }

    /deep/ .el-dialog__body {
      padding: 20px 20px 10px;
    }

    &__body {
      display: grid;
      grid-template-rows: repeat(3, auto);
      grid-gap: 10px;
    }
  }
}
</style>
