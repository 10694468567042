<template>
  <section>
    <el-dialog
      class="point-edit-dialog"
      :title="title"
      :visible.sync="show"
      :before-close="() => onClose('point')"
      @open="firstFocus"
    >
      <p class="point-edit-dialog__desc">{{ desc }}</p>

      <section class="point-edit-dialog__input-group">
        <el-radio-group :value="pointEdit.editType" @input="radioChange">
          <div class="point-edit-dialog__input-group__radio" v-for="option in editTypeOptions" :key="option.value">
            <el-radio :label="option.value">{{ option.label }}</el-radio>
            <PointInput
              :ref="option.value"
              :disabled="option.value !== pointEdit.editType"
              :value="option.value === 'reward' ? pointEdit.rewardPoint : pointEdit.deductPoint"
              @input="pointChange"
            />
          </div>
        </el-radio-group>
      </section>

      <section class="point-edit-dialog__reason">
        <label>포인트 수정 사유를 입력해 주세요.</label>
        <UnderlineTextInput placeholder="최대 20자까지 입력" :max="20" :value="pointEdit.editReason" @input="reasonChange" />
      </section>

      <section class="point-edit-dialog__button-group">
        <el-button @click="() => onClose('point')">취소</el-button>
        <el-button type="primary" @click="finalCheck">수정</el-button>
      </section>
    </el-dialog>

    <PointEditFinalCheckModal
      :show="showPointEditFinal"
      :title="title"
      :pointEdit="pointEdit"
      :finalPoint="pointEdit.editType === 'reward' ? pointEdit.rewardPoint : pointEdit.deductPoint"
      :currentPoint="currentPoint"
      :currentMemberIds="currentMemberIds"
      @close="onClose"
    />
  </section>
</template>

<script>
import PointEditFinalCheckModal from '@components/Modals/PointEditFinalCheck';

export default {
  components: {
    PointEditFinalCheckModal,
  },

  props: {
    title: String,
    desc: String,
    show: Boolean,
    pointEdit: Object,
    currentPoint: Number,
    currentMemberIds: Array,
  },

  data() {
    return {
      editTypeOptions: [
        { label: '적립', value: 'reward' },
        { label: '차감', value: 'deduct' },
      ],
      showPointEditFinal: false,
    };
  },

  methods: {
    setDeductPoint(point) {
      if (!point) return;

      requestAnimationFrame(() => {
        const inputRef = this.$refs['deduct'][0];
        const deductElement = inputRef.$el.querySelector('input');
        deductElement.value = this.$filters.comma(point);
      });
    },

    radioChange(value) {
      const inputRef = this.$refs[value][0];
      const inputElement = inputRef.$el.querySelector('input');
      requestAnimationFrame(() => inputElement.focus());
      this.$emit('change', 'editType', value);

      const { deductPoint } = this.pointEdit;
      const resultPoint = deductPoint >= this.currentPoint ? this.currentPoint : deductPoint;
      this.setDeductPoint(resultPoint);
    },

    reasonChange(value) {
      this.$emit('change', 'editReason', value);
    },

    pointChange(value) {
      const { editType } = this.pointEdit;
      const isDeduct = editType === 'deduct';
      const currentValue = isDeduct && value >= this.currentPoint ? this.currentPoint : value;

      if (isDeduct) {
        this.setDeductPoint(currentValue);
      }
      this.$emit('change', 'point', { type: editType, value: currentValue });
    },

    finalCheck() {
      const { rewardPoint, deductPoint, editType } = this.pointEdit;
      const point = editType === 'reward' ? rewardPoint : deductPoint;
      const pointValidate = this.$utils.paymentValidation(editType, point, this.currentPoint);
      if (pointValidate.error) {
        this.$utils.notify.error(this, '오류', pointValidate.message);
        return;
      }

      this.showPointEditFinal = true;
    },

    onClose(type) {
      this.showPointEditFinal = false;

      if (type !== 'point-final') {
        requestAnimationFrame(() => {
          const refs = ['reward', 'deduct'].map(type => this.$refs[type][0]);
          refs.forEach(ref => {
            const element = ref.$el.querySelector('input');
            element.value = 0;
          });
        });

        this.$emit('close', type);
      }
    },

    firstFocus() {
      requestAnimationFrame(() => this.radioChange('reward'));
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  width: 90%;
  max-width: 600px;
}

/deep/ .el-dialog__body {
  padding: 0 20px 30px;
}

.point-edit-dialog {
  &__desc {
    margin-bottom: 40px;
  }

  &__input-group {
    margin-bottom: 64px;

    /deep/ .el-radio-group {
      display: block;
    }

    &__radio {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0 20px;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  &__reason {
    @include flex(column, flex-start);
    gap: 4px;
    margin: 0 20px 40px;
  }

  &__button-group {
    margin: 0 20px;
    padding: 20px 20px 0;
    border-top: 1px solid #eee;
    text-align: right;
  }
}
</style>
