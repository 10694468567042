import axios from '../axios';

export default {
  // 상담 목록
  getAll: params =>
    axios.get('/v2/staff/counsel', {
      params,
    }),

  // 개별 상담
  get: id => axios.get(`/staff/counsel/${id}`),

  // 상담 등록
  create: counsel =>
    axios.post('/staff/counsel', {
      counsel,
    }),

  // 상담 수정
  update: counsel =>
    axios.patch(`/staff/counsel/${counsel.id}`, {
      counsel,
    }),

  // 상담 삭제
  delete: id => axios.delete(`/staff/counsel/${id}`),
};
