<template>
  <section class="boards-list">
    <TextInput
      style="width: 200px; margin-left: auto;"
      v-show="isShowQna"
      v-model="search"
      :placeholder="active_tab !== 'studio-notice' ? '제목 or 작성자' : '제목'"
      prefix-icon="el-icon-search"
      round
      @keyup.native.enter="handleChangeSearch"
    />
    <el-table
      v-loading="loadingBoards"
      v-show="isShowQna"
      :data="data"
      :row-style="{ cursor: 'pointer', height: '40px' }"
      :row-class-name="tableRowClassName"
      @row-click="handleClickRow"
    >
      <el-table-column v-if="active_tab === 'notice'" label="작성일시" align="center" width="200" class-name="created-at">
        <template slot-scope="scope">
          <img v-if="scope.row.is_top_fixed" src="@/assets/images/icons/push_pin.svg" />
          <span>{{ scope.row.updated_at | dateWithWeekdayWithTime }}</span>
        </template>
      </el-table-column>

      <el-table-column label="제목" align="center" min-width="306">
        <template slot-scope="scope">
          <p class="boards-list__title">
            {{ scope.row.title }}
            <span class="reply-length">
              {{ active_tab === 'qna' ? scope.row.comments.length : '' }}
            </span>
          </p>
        </template>
      </el-table-column>

      <el-table-column v-if="active_tab === 'notice'" label="회원" align="center" width="88">
        <template slot-scope="scope">
          {{ targetMember(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column v-if="active_tab === 'notice'" label="팝업" align="center" width="80">
        <template slot-scope="scope">
          <span class="boards-list__popup_icon" :class="{ show: scope.row.popup_on }">{{ scope.row.popup_on ? 'Y' : 'N' }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="active_tab !== 'studio-notice'" label="작성자" align="center" min-width="148">
        <template slot-scope="scope">
          <span v-if="!scope.row.staff && !scope.row.member">스튜디오메이트</span>
          <span v-if="scope.row.staff" class="writer-name">
            <span> {{ scope.row.staff.name }}</span>
            <span v-if="scope.row.staff.roles[0]" class="role">{{ scope.row.staff.roles[0].display_name }}</span>
          </span>
          <span v-if="scope.row.member" class="writer-name">
            {{ scope.row.member.name }}<span class="role-member">회원</span>
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="active_tab !== 'notice'" label="작성일자" align="center" min-width="150">
        <template slot-scope="scope">
          {{ scope.row.created_at | datetime }}
        </template>
      </el-table-column>

      <el-table-column v-if="active_tab === 'notice'" label="게시기간" align="center" width="194" class-name="top-fixed-period">
        <template slot-scope="scope">
          {{ noticePeriod(scope.row) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="boards-list__buttons">
      <floating-action-button
        v-show="active_tab === 'notice' && canCreateNoticeBoard"
        @click="$router.push(`/boards/notice/form`)"
      />
    </div>
    <el-pagination
      v-show="isShowQna"
      class="boards-list__pagination"
      @current-change="handleChangePage"
      :current-page.sync="params.page"
      :page-size="params.limit"
      layout="prev, pager, next"
      :total="total"
    />
  </section>
</template>

<script>
import { BOARD_TYPES } from '@constants';
export default {
  props: {
    active_tab: String,
  },
  data() {
    return {
      loadingBoards: false,
      params: { page: 1, limit: 10 },
      search: undefined,
      data: [],
      total: 0,
    };
  },
  computed: {
    isShowQna() {
      switch (this.active_tab) {
        case BOARD_TYPES.NOTICE:
          return this.canViewNoticeBoard;
        case BOARD_TYPES.QNA: {
          const checkQna = _.get(this.studioPolicies, 'is_visible_qna');
          return this.canViewQnaBoard && checkQna;
        }
        case BOARD_TYPES.STUDIO_NOTICE:
          return true;
        default:
          return false;
      }
    },
  },
  created() {
    this.$router.replace({
      query: {
        ...this.$route.query,
        page: this.$route.query.page || 1,
      },
    });

    this.getBoards();
    this.$store.commit('boardNotice/SET_BOARD_DETAIL');
  },
  methods: {
    handleChangePage(page) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page,
        },
      });
    },

    handleChangeSearch() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          page: 1,
        },
      });
    },

    async getBoards() {
      this.loadingBoards = true;
      const commonParams = { ...this.params, page: this.$route.query.page };

      try {
        let res = [];
        if (this.active_tab === BOARD_TYPES.STUDIO_NOTICE) {
          const studioNoticeParams = { ...commonParams, all: 1, search: this.$route.query.search };
          res = await this.$api.notification.getNoti(studioNoticeParams);
          this.total = res.data.total;
        } else {
          const boardParams = { ...commonParams, title: this.$route.query.search };
          res = await this.$api.boards.getAll(boardParams, this.active_tab);
          this.total = res.data.meta.total;
        }
        this.data = res.data.data;
        this.search = this.$route.query.search;
        this.params = { ...commonParams, page: Number(commonParams.page) };
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        this.data = [];
        this.total = 0;
      } finally {
        this.loadingBoards = false;
      }
    },

    tableRowClassName({ row }) {
      if (row.is_top_fixed) {
        return 'top-fixed-row';
      }
    },

    handleClickRow(row) {
      const path = `/boards/${this.active_tab}/${row.id}`;
      const query = `?page=${this.params.page}` + `${this.search ? `&search=${this.search}` : ''}`;

      this.$router.push(`${path}${query}`);
    },

    targetMember(row) {
      const targetMember = _.get(row, 'target_member');
      return this.$utils.arrangeStringWithComma([
        { label: '유효', value: targetMember !== 'expired' },
        { label: '만료', value: targetMember !== 'active' },
      ]);
    },

    noticePeriod(row) {
      const { is_used_period, is_unlimited_period, start_on, end_on } = row;

      if (is_unlimited_period) {
        return '제한없음';
      } else if (is_used_period) {
        return this.$utils.getPeriod(start_on, end_on);
      } else {
        return '미설정';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.boards-list {
  &__title {
    @include ellipsis;
    text-align: left;
    .reply-length {
      padding-left: 5px;
      font-size: 12px;
      // font-weight: bold;
      color: $gray-500;
    }
  }

  /deep/.el-table {
    .created-at .cell {
      padding-left: 35px;
      position: relative;

      img {
        position: absolute;
        left: 12px;
        top: 50%;
        margin-top: -8px;
      }
    }

    .top-fixed-row {
      font-weight: 500;
      background: rgba(71, 153, 242, 0.06);

      .top-fixed-period .cell {
        padding: 0 5px;
      }
    }
  }

  &__popup_icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-weight: 700;
    color: #909399;
    opacity: 0.64;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #909399;
      border-radius: 50%;
      opacity: 0.12;
    }

    &.show {
      color: #4799f2;
      opacity: 1;

      &::before {
        background-color: #4799f2;
      }
    }
  }

  .notice {
    color: $color-primary;
  }

  &__pagination {
    margin-top: 20px;
    text-align: center;
  }
  &__buttons {
    padding-top: 15px;
    text-align: right;
  }
  .writer-name {
    .role {
      font-size: 12px;
      color: $gray-600;
      padding-left: 5px;
      &-member {
        font-size: 12px;
        padding-left: 5px;
        color: $gray-500;
      }
    }
  }
}
</style>
