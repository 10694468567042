/**
 * 문자열 바이트 체크
 * @param {String} str
 * @param {Number} maxBytes
 *
 * @return {Object} { exceeded: Boolean, exceededAt: Int }
 */
export default (str = '', maxBytes) => {
  let totalBytes = 0;
  let exceededAt = 0;

  str.split('').some((char, index) => {
    if (escape(char).length > 4) {
      totalBytes += 2;
    } else {
      totalBytes++;
    }

    if (totalBytes > maxBytes) {
      exceededAt = index;
      return true;
    }

    return false;
  });

  return {
    exceeded: totalBytes > maxBytes,
    exceededAt,
  };
};
