<template>
  <el-dialog
    class="ticket-upgrade-modal"
    :visible.sync="show"
    :before-close="
      () => {
        $emit('close');
      }
    "
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <div slot="title" class="ticket-upgrade-modal__title">
      <h4>수강권 업그레이드</h4>
      <ul>
        <li v-for="step in [1, 2, 3]" :key="step" :class="{ active: step === currentStep }">
          {{ step }}
        </li>
      </ul>
    </div>

    <!-- 수강권 선택 -->
    <SelectTicket v-if="currentStep === 1" :loading="ticketsLoading" :tickets="tickets" @select="handleClickCard" />

    <!-- 수강권 정보 확인 -->
    <TicketDetail
      v-else-if="currentStep === 2"
      :userTicket="userTicket"
      :ticket="ticketSelected"
      @click-prev="handleClickPrev"
      @click-next="handleClickNext"
    />

    <!-- 결제 정보 확인 -->
    <PaymentDetail
      v-else-if="currentStep === 3"
      :userTicket="userTicket"
      :ticket="ticketSelected"
      @click-prev="handleClickPrev"
      @click-save="handleClickSave"
      :saving="saving"
      :currentPoint="currentPoint"
    />
  </el-dialog>
</template>

<script>
import SelectTicket from './SelectTicket';
import TicketDetail from './TicketDetail';
import PaymentDetail from './PaymentDetail';

export default {
  components: {
    SelectTicket,
    TicketDetail,
    PaymentDetail,
  },

  props: {
    show: Boolean,
    currentPoint: Number,
  },

  data() {
    return {
      currentStep: 1,
      ticketsLoading: false,
      tickets: [],
      ticketSelected: null,
      saving: false,
    };
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },
    userTicket() {
      return _.get(this.formData, 'userTicket');
    },
  },

  created() {
    this.getTickets();
  },

  methods: {
    async getTickets() {
      try {
        this.ticketsLoading = true;

        const { ticket } = this.userTicket;
        let params = {
          limit: 999,
          orderBy: 'desc',
          is_selling_now: 1,

          /**
           * 업그레이드 가능 조건
           * 1. 코스 타입이 같을 것 (프라이빗/그룹)
           * 2. 티켓 타입이 같을 것 (횟수제/기간제)
           * 3. 자동차감 여부가 일치할 것
           */
          class_type: ticket.available_class_type,
          ticket_type: ticket.type,
          use_weekly_auto_coupon_balance: ticket.use_weekly_auto_coupon_balance,
        };
        if (!this.userTicket.ticket.divisions.length) {
          params = { ...params, max_trainee: this.userTicket.ticket.max_trainee };
        } else {
          let divisions = this.userTicket.ticket.divisions.map(({ id }) => id).join();
          params = { ...params, divisions };
        }
        const res = await this.$api.product.getAll(params);

        /**
         * 업그레이드 가능 조건
         * 4. 같은 수강권이면 연장으로 업그레이드
         * 5. 주간 이용 횟수가 더 많거나 무제한
         * 6. 전체횟수, 기간이 같거나 커야 함
         * 7. 수강권 인원 또는 수업구분이 일치할 것
         * 8. 수업구분이 일치하거나 포함될 것
         */

        this.tickets = res.data.data.filter(
          ({ booking_limit_per_week, max_coupon, available_period, divisions, max_trainee }) => {
            return (
              (!booking_limit_per_week || ticket.booking_limit_per_week <= booking_limit_per_week) &&
              ticket.max_coupon <= max_coupon &&
              ticket.available_period <= available_period &&
              (ticket.max_trainee === max_trainee ||
                (ticket.max_trainee !== max_trainee && this.checkDivisions(ticket.divisions, divisions)))
            );
          },
        );
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.ticketsLoading = false;
      }
    },

    /** Step 1 - 수강권 선택시 */
    handleClickCard(ticketId) {
      const ticket = this.tickets.find(({ id }) => id === ticketId);
      if (!ticket) return;

      this.currentStep = 2;
      this.ticketSelected = ticket;
    },

    handleClickPrev() {
      this.currentStep--;
      if (this.currentStep === 1) {
        this.ticketSelected = null;
      }
    },

    handleClickNext() {
      this.currentStep++;
    },

    async handleClickSave(paymentDetail) {
      if (!paymentDetail) return;

      const pointValidate = this.$utils.paymentValidation('ticket', paymentDetail.point_amount, this.currentPoint);
      if (pointValidate.error) {
        this.$utils.notify.error(this, '오류', pointValidate.message);
        return;
      }

      try {
        this.saving = true;
        let payment = {
          ...paymentDetail,
          status: 'upgrade',
          user_id: _.get(this.userTicket, 'member.id'),
        };

        if (this.ticketSelected.id === this.userTicket.ticket_id) {
          payment = { ...payment, status: 'extension' };
        }

        await this.$api.userTicket.upgrade(this.userTicket.id, this.ticketSelected.id, payment);
        this.$utils.notify.success(this, '확인', '수강권이 변경되었습니다.');
        this.$emit('save', this.userTicket.id);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.saving = false;
      }
    },

    checkDivisions(userTicketDivisions, ticketDivisions) {
      if (!userTicketDivisions) {
        return false;
      }
      const divisionIds = ticketDivisions.map(({ id }) => id);
      return userTicketDivisions.every(({ id }) => divisionIds.includes(id));
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-upgrade-modal {
  /deep/ .el-dialog {
    @include flex(column);
    width: 90%;
    max-width: 620px;
  }

  /deep/ .el-dialog__body {
    flex: 1;
  }

  &__title {
    @include flex(row, center, flex-start);

    ul {
      @include flex(row, center, flex-start);

      li {
        @include flex(row, flex-start, center);
        background: white;
        border: 1px solid rgba($charcoal-grey, 0.2);
        border-radius: 50%;
        font-size: 9px;
        width: 18px;
        height: 18px;
        margin: 0 12px;
        position: relative;
      }

      li + li::before {
        content: '';
        width: 12px;
        border-bottom: 1px solid rgba($charcoal-grey, 0.2);
        position: absolute;
        top: 50%;
        left: -18px;
      }

      li.active {
        border-color: $charcoal-grey;
        background: $charcoal-grey;
        color: white;
      }
    }
  }
}
</style>
