import axios from '../axios';

export default {
  // 알림 목록
  getAll: params =>
    axios.get('/staff/notification', {
      params,
    }),

  // v2 알림 목록
  v2getAll: params => axios.get('/v2/staff/staffNotices/common', { params }),

  // 체크인 알림 목록
  getAllCheckin: params => axios.get('/v2/staff/enter/staffNotices', { params }),

  // 체크인 알림 모두 읽음
  checkinReadAll: () => axios.post('/v2/staff/enter/read-all/staffNotices'),

  // 알림 읽음
  read: id =>
    axios.patch(`/v2/staff/staffNotices/${id}`, {
      is_checked: 1,
    }),

  // 알림 안읽음
  unread: id =>
    axios.patch(`/v2/staff/staffNotices/${id}`, {
      is_checked: 0,
    }),

  // 알림 모두 읽음
  readAll: () => axios.post('/v2/staff/read-all/staffNotices'),

  // 스튜디오메이트 -> 시설 공지
  getNoti: params => {
    return axios.get('/v2/staff/notice', { params });
  },

  // 스튜디오메이트 -> 시설 공지 -> id별 세부 내용
  getNotiDetail: (id, params) => {
    return axios.get(`/v2/staff/notice/${id}`, { params });
  },
};
