import axios from '../axios';
const BASE_URL = `/v2/staff/template`;

export default {
  message: {
    getAll: params => axios.get(`${BASE_URL}/message`, params),
    get: id => axios.get(`${BASE_URL}/message/${id}`),
    put: id => axios.patch(`${BASE_URL}/message/${id}`),
    post: body => axios.post(`${BASE_URL}/message`, body),
    delete: id => axios.delete(`${BASE_URL}/message/${id}`),
  },
  autoMessage: {
    getAll: () => axios.get(`${BASE_URL}/auto-message`),
    get: param => axios.get(`${BASE_URL}/auto-message/${param}`),
    put: (id, body) => axios.patch(`${BASE_URL}/auto-message/${id}`, body),
  },
};
