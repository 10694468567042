import api from '@api';

/**
 * get all method, 페이지네이션 안 할때만 쓴다.
 *
 * @param {name: string, params: object}
 */
export default async apis => {
  let newRes = [];
  let res = [];
  const apiAddress = { memberGetAll: 'member', counselsGetAll: 'counsel', booking: 'booking' };

  res = await api[apiAddress[apis.name]].getAll(apis.params);
  newRes = res.data.data || res.data.bookings.data;
  const lastPageValue = (res.data.meta && res.data.meta.last_page) || res.data.bookings.last_page;
  const currentPageValue = (res.data.meta && res.data.meta.current_page) || res.data.bookings.current_page;
  if (lastPageValue !== 1 && !apis.once) {

    let promiseArray = [];
    for (let i = 1; i <= lastPageValue; i++) {
      if (currentPageValue !== i) {
        promiseArray.push({ value: i });
      }
    }

    const members = await Promise.all(
      promiseArray.map(el => {
        return api[apiAddress[apis.name]].getAll({
          ...apis.params,
          page: el.value,
        });
      }),
    );

    newRes = members.reduce(
      (combined, data) => combined.concat((data.data.bookings && data.data.bookings.data) || data.data.data),
      newRes,
    );
  }

  return { data: newRes, meta: res.data.meta };
};
