<template>
  <el-card class="trend-chart" shadow="hover">
    <div class="trend-chart__header">
      <h3>TREND</h3>
      <el-select v-model="category">
        <el-option v-for="item in categoryOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>
    <div v-if="isDataReady" class="trend-chart__chart">
      <BarChart :chartData="chartData" :options="chartOptions" />
    </div>
    <p class="trend-chart__no-data" v-else>표시할 데이터가 없습니다.</p>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      category: 'paymentType',
    };
  },

  computed: {
    data() {
      return this.$store.getters['sales/data'];
    },
    filter() {
      return this.$store.getters['sales/filter'];
    },
    activeTab() {
      return this.$store.getters['sales/activeTab'];
    },
    dates() {
      return this.$store.getters['sales/dates'];
    },

    isDataReady() {
      const labels = this._.get(this.chartData, 'labels');
      const datasets = this._.get(this.chartData, 'datasets');
      const hasNoLabels = !labels || !labels.length;
      const hasNoData = datasets.every(item => item.data.every(value => !value));
      return !hasNoLabels && !hasNoData;
    },

    categoryOptions() {
      const categoryOptions = {
        ticketsRevenue: [
          { value: 'paymentType', label: '결제구분' },
          // { value: 'instructors', label: '담당강사' },
          { value: 'courseType', label: '수업구분' },
          { value: 'paymentMethod', label: '결제방법' },
          { value: 'ticketTitle', label: '수강권명' },
        ],
        lecturesRevenue: [
          { value: 'instructors', label: '수업강사' },
          { value: 'courseType', label: '수업구분' },
          { value: 'ticketTitle', label: '수강권명' },
        ],
      };
      return categoryOptions[this.activeTab];
    },

    chartData() {
      const { rangeType } = this.filter;
      const dataSource = this.data[this.activeTab];

      let labels = [];
      let barChartData = {};
      let mappedByDate = {};

      this.dates.forEach(date => {
        /** 레이블 */
        if (rangeType === 'day') {
          labels.push(this.moment(date).format('M월 D일 (ddd)'));
        } else if (rangeType === 'week') {
          const endOfWeek = this.moment(date).add(6, 'days');
          const month = endOfWeek.month() + 1;
          const week = Math.ceil(endOfWeek.date() / 7);
          labels.push(`${month}월 ${week}주`);
        } else if (rangeType === 'month') {
          labels.push(this.moment(date).format('YY년 M월'));
        }

        const mapped = this.$utils.categorizeSalesData(dataSource[date], this.activeTab);

        const data = mapped[this.category];
        data.labels.forEach((label, index) => {
          if (!barChartData[label]) {
            barChartData[label] = { counts: [], values: [] };
          }

          barChartData[label].color = data.colors[index];
        });

        mappedByDate[date] = data;
      });

      this.dates.forEach(date => {
        Object.keys(barChartData).forEach(label => {
          const index = mappedByDate[date].labels.findIndex(value => value === label);
          if (index < 0) {
            barChartData[label].counts.push(0);
            barChartData[label].values.push(0);
          } else {
            barChartData[label].counts.push(mappedByDate[date].counts[index]);
            barChartData[label].values.push(mappedByDate[date].values[index]);
          }
        });
      });

      let datasets = [];
      Object.keys(barChartData).forEach(label => {
        datasets.push({
          label,
          type: 'bar',
          backgroundColor: barChartData[label].color,
          data: barChartData[label].values,
          yAxisID: 'amount',
          stack: 1,
        });
      });

      return { labels, datasets };
    },

    chartOptions() {
      const { comma } = this.$filters;

      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'point',
          bodyFontSize: 15,
          bodySpacing: 7,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            title() {
              return null;
            },
            label(tooltipItem, { datasets }) {
              const { datasetIndex, value } = tooltipItem;
              if (value != 0) {
                return datasets[datasetIndex].label;
              }
            },
          },
        },
        legend: {
          display: true,
        },
        scales: {
          yAxes: [
            {
              id: 'amount',
              position: 'left',
              gridLines: {
                drawOnChartArea: false,
              },
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return `${comma(value)}원`;
                },
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              barPercentage: 0.7,
              gridLines: {
                display: false,
              },
            },
          ],
        },
      };
    },
  },

  watch: {
    activeTab: {
      handler: 'setInitialCategory',
      immediate: true,
    },
  },

  methods: {
    setInitialCategory() {
      this.category = this.activeTab === 'ticketsRevenue' ? 'paymentType' : 'instructors';
    },
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  &__header {
    @include flex(row, center, space-between);
    margin-bottom: 20px;

    h3 {
      font-weight: bold;
    }

    /deep/ .el-input {
      max-width: 100px;
    }

    /deep/ .el-input__inner {
      @extend %input-default;
      padding: 9.5px 20px 9.5px 10px;
      height: 100%;
      text-align: center;
    }
  }

  &__chart {
    padding-top: 50px;
  }

  &__no-data {
    opacity: 0.6;
  }
}
</style>
