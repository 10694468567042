<template>
  <header id="main-header">
    <slot></slot>

    <div class="main-header__inner">
      <router-link to="/" class="main-header__home">
        <img src="@/assets/images/03_item/logo/32x20/black.svg" alt="스튜디오 메이트 로고" />
      </router-link>
      <navigation />
      <search-bar />
      <user-menu />
    </div>
  </header>
</template>

<script>
import Navigation from './Navigation';
import UserMenu from './UserMenu';
import SearchBar from './SearchBar';

export default {
  components: {
    Navigation,
    UserMenu,
    SearchBar,
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },
  },

  watch: {
    $route: {
      handler: 'handleRouteChange',
      immediate: true,
    },
  },

  methods: {
    async handleRouteChange() {
      if (!this.studio || !this.studio.name || this.studio.expire_on < this.moment().format('YYYY-MM-DD')) {
        this.$store.dispatch('studio/getStudio');
        this.$store.dispatch('payment/getPointScore');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#main-header {
  @include flex(column);
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  z-index: $z-index-header;
}

.main-header__inner {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;

  /deep/ .el-menu {
    border-bottom: none;
  }
}

.main-header__home {
  display: flex;
  padding: 18px 24px;
}
</style>
