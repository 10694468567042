<template>
  <div class="container">
    <SummaryNew
      type="un-payment"
      :summaryItems="dashBoardData"
      value="remaining-payment"
      name="remaining-payment"
      @handleSelectChange="handleSelectChange"
      v-loading="loading"
    />
    <FilterNew
      :filterOptions="filterOptions"
      :refreshClick="refresh"
      downloadText="엑셀 다운로드"
      :handleDownload="downloadExcel"
    />
    <SubHeaderNew
      :count="total"
      :amount="totalAmount"
      :search="search"
      :handleSearchChange="handleSearchChange"
      :searchResult="searchResult"
      placeholder="회원명 검색"
    />
    <ListTableNew
      type="un-payment"
      :tableData="filteredPagedData"
      v-loading="loading"
      :tableRowClick="tableRowClick"
      :sort="sort"
      :sortChange="handleSortChange"
    />
    <el-pagination
      :current-page.sync="page"
      :page-size="limit"
      :pager-count="5"
      layout="prev, pager, next, sizes"
      :total="total"
      v-show="filteredPagedData.length > 0"
      :page-sizes="[10, 15, 20, 50]"
      @size-change="size => (limit = size)"
    />
  </div>
</template>

<script>
import SummaryNew from '@components/Sales/SummaryNew';
import FilterNew from '@components/Sales/FilterNew';
import SubHeaderNew from '@components/Sales/SubHeaderNew';
import ListTableNew from '@components/Sales/ListTableNew';
import moment from 'moment';

export default {
  components: {
    SummaryNew,
    FilterNew,
    SubHeaderNew,
    ListTableNew,
  },
  data() {
    return {
      dashBoardData: [
        {
          label: '남은 미수금',
          count: 0,
          amount: 0,
          name: 'remaining-payment',
        },
      ],
      filterOptions: [
        {
          name: 'classType',
          onChange: this.handleSelectChange,
          value: 'total',
          options: [
            { value: 'total', label: '구분 전체' },
            { value: 'P', label: '프라이빗' },
            { value: 'G', label: '그룹' },
            { value: 'S', label: '판매' },
            { value: 'R', label: '대여' },
          ],
        },
        {
          name: 'productId',
          value: [],
          options: [],
          onChange: this.handleSelectChange,
          multiple: true,
          placeholder: '상품 전체',
        },
      ],
      search: '',
      page: 1,
      limit: 10,
      loading: false,
      wholeData: [],
      filteredData: [],
      sort: 'ascending',
    };
  },

  mounted() {
    this.fetchAllProduct();
    this.fetchData();
  },

  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },
    filteredPagedData() {
      const startIndex = this.limit * (this.page - 1);
      const endIndex = startIndex + this.limit;
      const pagedData = this.filteredData.slice(startIndex, endIndex);
      return pagedData;
    },
    total() {
      return this.filteredData.length;
    },

    totalAmount() {
      return this.filteredData.reduce((prev, cur) => prev + cur.remainingAmount, 0);
    },
  },

  watch: {
    filterOptions: function() {
      this.getFilteredData();
    },
    wholeData: function() {
      this.getFilteredData();
      this.getDashBoardData();
    },
    sort: function() {
      this.getFilteredData();
    },
  },

  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const unPaymentData = await this.$utils.promiseAllData(this.$api.salesNew.getUnPaymentData, {});

        const wholeData = [];

        unPaymentData.forEach(userTicketData => {
          const classType =
            userTicketData.class_type === 'P' || userTicketData.class_type === 'G'
              ? userTicketData.class_type
              : userTicketData.ticket_type[0];
          const userName = userTicketData.user_name;
          const userId = userTicketData.user_id;
          const productId = userTicketData.ticket_id;
          const productName = userTicketData.ticket_name;
          const userTicketId = userTicketData.user_ticket_id;

          const remainingAmount = userTicketData.payments.reduce(
            (prev, cur) => (cur.type === 'installment_payment' ? prev - cur.amount : prev + cur.unpaid_amount),
            0,
          );
          const paymentAmount = userTicketData.payments.reduce((prev, cur) => prev + cur.amount, 0);
          const recentDate = userTicketData.payments[userTicketData.payments.length - 1].settlement_at;
          let recentPaymentMethod = [];
          const payment = userTicketData.payments[userTicketData.payments.length - 1];
          if (payment.card_amount > 0) recentPaymentMethod.push('카드');
          if (payment.cash_amount > 0) recentPaymentMethod.push('현금');
          if (payment.wiretransfer_amount > 0) recentPaymentMethod.push('계좌이체');
          recentPaymentMethod = recentPaymentMethod.length === 0 ? '-' : recentPaymentMethod.join('+');

          wholeData.push({
            recentDate,
            remainingAmount,
            paymentAmount,
            classType,
            userName,
            userId,
            productId,
            productName,
            userTicketId,
            recentPaymentMethod,
          });
        });

        this.wholeData = wholeData;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      } finally {
        this.loading = false;
      }
    },

    async fetchAllProduct() {
      try {
        const params = {
          ticket_type: ['S', 'RM', 'RT', 'RP'],
          is_web: 'Y',
        };
        const productRes = await this.$api.product.getAll(params);
        const products = productRes.data;
        const ticketRes = await this.$api.product.getAll();
        const tickets = ticketRes.data;

        const newFilterOptions = [...this.filterOptions];
        newFilterOptions.find(filter => filter.name === 'productId').options = [
          ...products.map(product => ({
            value: product.id,
            label: product.title,
          })),
          ...tickets.map(ticket => ({ value: ticket.id, label: ticket.title })),
        ];
        this.filterOptions = newFilterOptions;
      } catch (e) {
        this.$utils.notify.parseError(this, e);
      }
    },

    getFilteredData() {
      let filteredData = [...this.wholeData];
      const classType = this.filterOptions.find(option => option.name === 'classType').value;
      const productId = this.filterOptions.find(option => option.name === 'productId').value;

      if (classType !== 'total') {
        filteredData = filteredData.filter(data => data.classType === classType);
      }
      if (productId.length > 0) {
        filteredData = filteredData.filter(data => productId.includes(data.productId));
      }
      if (this.search !== '') {
        filteredData = filteredData.filter(data => data.userName.includes(this.search));
      }

      filteredData.sort((item1, item2) =>
        this.sort === 'ascending'
          ? moment(item1.recentDate).isAfter(moment(item2.recentDate))
            ? 1
            : -1
          : moment(item2.recentDate).isAfter(moment(item1.recentDate))
          ? 1
          : -1,
      );

      this.filteredData = filteredData;
      this.page = 1;
    },

    getDashBoardData() {
      let count = 0;
      let amount = 0;
      this.wholeData.forEach(item => {
        count++;
        amount += item.remainingAmount;
      });
      const newDashBoardData = [...this.dashBoardData];
      newDashBoardData.find(data => data.name === 'remaining-payment').count = count;
      newDashBoardData.find(data => data.name === 'remaining-payment').amount = amount;
      this.dashBoardData = newDashBoardData;
    },

    handleSelectChange(name, value) {
      const newFilterOptions = [...this.filterOptions];
      newFilterOptions.find(filter => filter.name === name).value = value;
      this.filterOptions = newFilterOptions;
    },

    refresh() {
      this.handleSelectChange('classType', 'total');
      this.handleSelectChange('productId', []);
    },

    handleSearchChange(value) {
      this.search = value;
    },

    searchResult() {
      this.getFilteredData();
    },

    tableRowClick(item) {
      this.$router.push(`/users/detail?id=${item.userId}&user_ticket_id=${item.userTicketId}`);
    },

    formatJSON(data) {
      if (!data || !data.length) return [];
      return data.map(item => {
        const typeMap = {
          G: '그룹',
          P: '프라이빗',
          S: '판매',
          R: '대여',
        };
        const type = typeMap[item.classType];

        const date = moment(item.recentDate).format('YYYY. MM. DD (dd)');

        return {
          구분: type,
          '최근 결제일': date,
          '최근 결제방법': item.recentPaymentMethod,
          회원명: item.userName,
          상품명: item.productName,
          '누적 결제금액': item.paymentAmount,
          '남은 미수금': item.remainingAmount,
        };
      });
    },

    async downloadExcel() {
      const json = this.formatJSON(this.filteredData);
      this.$utils.downloadExcel(json, `미수금_현황.xlsx`);
    },

    handleSortChange(e) {
      this.sort = e.order;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include flex(column);
  margin: auto;
  padding: 30px;
  max-width: 1440px;
  gap: 30px;

  /deep/ .el-pagination {
    @include flex(row, center, center);
  }
}
</style>
