<template>
  <div>
    <FormBlock :index="2" label="강사 선택">
      <el-select
        class="instructor"
        v-model="instructorId"
        ref="instructorId"
        placeholder="강사를 선택하세요"
        clearable
        filterable
        @change="handleChangeInstructorId"
      >
        <el-option v-for="staff in staffOptions" :key="staff.id" :value="staff.id" :label="staff.name">
          <span class="instructor__option">
            <img :src="$utils.getImagePath(staff.image, 24)" :alt="staff.name" />
            <span>{{ staff.name }}</span>
          </span>
        </el-option>
      </el-select>
    </FormBlock>

    <FormBlock :index="3" label="수업 일정">
      <div class="schedule">
        <div class="schedule__calendar">
          <p>달력에서 날짜를 선택해 일정을 추가해 주세요.</p>
          <p>날짜를 한번 더 선택하면 선택 해제됩니다.</p>
          <div class="legend">
            <p class="with-dot">이미 예약이 있는 날짜</p>
            <p class="with-dot gray">정지 기간</p>
          </div>
          <Calendar
            ref="calendar"
            @pick="handlePickDate"
            :selectedDates="selectedDates"
            :userTicketDuration="userTicketDuration"
            :existingBookingDates="existingBookingDates"
            :holdingDates="holdingDates"
            :disabled="maxScheduleCountReached"
            :holidayData="holidayDates"
          />
        </div>

        <div class="schedule__datetimes">
          <div class="schedule__datetimes__description">
            <span class="schedule__datetimes__description__count">{{ schedules.length | comma }}개의 일정 선택됨</span>
            <PlainButton @click="resetScheduleSelection" :disabled="!schedules.length" round>선택 초기화</PlainButton>
            <span v-if="!!weeklyBookingLimit" class="schedule__datetimes__description__limit"
              >최대 주 {{ weeklyBookingLimit | comma }}회 예약 가능</span
            >
            <span v-if="!!monthlyBookingLimit" class="schedule__datetimes__description__limit"
              >최대 월 {{ monthlyBookingLimit | comma }}회 예약 가능</span
            >
          </div>

          <div class="schedule__datetimes__inputs">
            <DatetimeInput
              v-for="(schedule, index) in schedules"
              :key="`${schedule.date}${index}`"
              v-model="schedules[index]"
              @apply-all="handleClickApplyAll"
            />
          </div>
        </div>
      </div>
    </FormBlock>

    <FormBlock :index="4" label="수업명">
      <TextInput class="title" v-model="title" :placeholder="titlePlaceholder" material />
    </FormBlock>

    <FormBlock :index="5" label="룸 설정" v-if="useRooms">
      <el-select class="room" v-model="roomId" placeholder="룸 설정 안함" :disabled="!useRooms" filterable clearable>
        <el-option v-for="room in roomOptions" :key="room.id" :value="room.id" :label="room.name" />
      </el-select>
    </FormBlock>
  </div>
</template>

<script>
import DatetimeInput from '@components/BulkBookings/DatetimeInput';
import Calendar from '@components/BulkBookings/PrivateFormCalendar';

export default {
  components: { DatetimeInput, Calendar },

  props: {
    value: { type: Object, required: true },
    userTicket: { type: Object, required: true },
    staffOptions: { type: Array, default: () => [] },
    roomOptions: { type: Array, default: () => [] },
    titlePlaceholder: { type: String, default: '수업명' },
    selectedTeamUserTickets: { type: Array, default: () => [] },
  },

  computed: {
    instructorId: {
      get() {
        return this.value.instructorId;
      },
      set(instructorId) {
        this.$emit('input', { ...this.value, instructorId });
      },
    },

    schedules: {
      get() {
        return this.value.schedules;
      },
      set(schedules) {
        this.$emit('input', { ...this.value, schedules });
      },
    },

    title: {
      get() {
        return this.value.title;
      },
      set(title) {
        this.$emit('input', { ...this.value, title });
      },
    },

    roomId: {
      get() {
        return this.value.roomId;
      },
      set(roomId) {
        this.$emit('input', { ...this.value, roomId });
      },
    },

    defaultTime() {
      return this.moment()
        .add(1, 'hour')
        .startOf('hour');
    },

    weeklyBookingLimit() {
      return _.get(this.userTicket, 'ticket.booking_limit_per_week');
    },

    monthlyBookingLimit() {
      return _.get(this.userTicket, 'ticket.booking_limit_per_month');
    },

    usableCoupon() {
      return _.get(this.userTicket, 'usable_coupon');
    },

    maxScheduleCountReached() {
      return this.schedules.length >= this.usableCoupon;
    },

    existingBookingDates() {
      const bookings = _.get(this.userTicket, 'bookings', []);
      return bookings.map(({ lecture }) => {
        return this.moment(lecture.start_on).format('YYYY-MM-DD');
      });
    },

    holidayDates() {
      return this.holidayData.filter(({ deleted_at }) => !deleted_at).map(({ date }) => date);
    },

    holdingDates() {
      let dates = [];
      const holdingArray = _.cloneDeep(this.selectedTeamUserTickets);
      holdingArray.push({ holdings: _.get(this.userTicket, 'holdings') });

      for (let i = 0; i < holdingArray.length; i++) {
        holdingArray[i].holdings.forEach(holding => {
          const end = this.moment(holding.end_on);
          let date = this.moment(holding.start_on);
          for (date; date.format('YYYYMMDD') <= end.format('YYYYMMDD'); date.add(1, 'd')) {
            dates.push(date.format('YYYY-MM-DD'));
          }
        });
      }

      return dates;
    },

    selectedDates() {
      return this.schedules.reduce((dates, { date }) => {
        if (!dates.includes(date)) dates.push(date);
        return dates;
      }, []);
    },

    userTicketDuration() {
      const { moment } = this;
      if (!this.selectedTeamUserTickets.length) {
        return {
          start: moment(_.get(this.userTicket, 'availability_start_at')).format('YYYY-MM-DD'),
          end: moment(_.get(this.userTicket, 'expire_at')).format('YYYY-MM-DD'),
        };
      }

      const allTickets = [this.userTicket, ...this.selectedTeamUserTickets];
      const start = _.last(_.sortBy(allTickets, 'availability_start_at'));
      const end = _.first(_.sortBy(allTickets, 'expire_at'));

      return {
        start: moment(_.get(start, 'availability_start_at')).format('YYYY-MM-DD'),
        end: moment(_.get(end, 'expire_at')).format('YYYY-MM-DD'),
      };
    },

    bookingDateList() {
      let weekOrMonthLimit = this.monthlyBookingLimit ? '/M' : '|w';
      const dates = [...this.existingBookingDates, ...this.selectedDates].sort();
      return dates.reduce((mapped, date) => {
        const weekOfYear = this.moment(date).format(`YYYY${weekOrMonthLimit}`);
        mapped[weekOfYear] = !mapped[weekOfYear] ? 1 : mapped[weekOfYear] + 1;
        return mapped;
      }, {});
    },

    useRooms() {
      return _.get(this.studioPolicies, 'is_use_rooms');
    },

    holidayData() {
      return this.$store.getters['studio/studio'].holidays;
    },
  },

  watch: {
    staffOptions: {
      handler(options) {
        if (options.length === 1) {
          this.instructorId = options[0].id;
          this.$nextTick(() => {
            this.handleChangeInstructorId(this.instructorId);
          });
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleChangeInstructorId(instructorId) {
      if (instructorId) this.scrollToRef('calendar');
    },

    async handlePickDate(date) {
      date = date.format('YYYY-MM-DD');
      const dateExists = this.selectedDates.includes(date);

      if (dateExists) {
        this.schedules = this.schedules.filter(schedule => schedule.date !== date);
      } else if (!this.maxScheduleCountReached) {
        if (this.monthlyBookingLimit) {
          if (!(await this.checkBookingLimit(date, '/M'))) return;
        } else {
          if (!(await this.checkBookingLimit(date, '|w'))) return;
        }

        const start = this.moment(this.defaultTime).format('HH:mm');
        const end = this.moment(this.defaultTime)
          .add(30, 'minute')
          .format('HH:mm');
        this.schedules.push({ date, start, end });
        this.schedules = _.sortBy(this.schedules, ['date', 'start', 'end']);
      }
    },

    handleClickApplyAll(date, { start, end }) {
      const weekday = this.moment(date).weekday();

      this.schedules = this.schedules.map(schedule => {
        const scheduleWeekday = this.moment(schedule.date).weekday();
        return scheduleWeekday === weekday ? { ...schedule, start, end } : schedule;
      });
    },

    resetScheduleSelection() {
      this.schedules = [];
    },

    scrollToRef(ref) {
      this.$nextTick(() => {
        const el = _.get(this.$refs, `${ref}.$el`);
        if (!el) return;
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    },

    async checkBookingLimit(date, type) {
      const dateWeekOrMonth = this.moment(date).format(`YYYY${type}`);
      const bookingCount = this.bookingDateList[dateWeekOrMonth];

      if (
        (bookingCount && this.monthlyBookingLimit && bookingCount >= this.monthlyBookingLimit) ||
        (bookingCount && this.weeklyBookingLimit && bookingCount >= this.weeklyBookingLimit)
      ) {
        const title = '예약 가능횟수 초과';
        const message = `${type === '/M' ? '월간' : '주간'} 최대 예약 가능 횟수를 초과했습니다.<br>계속 하시겠습니까?`;

        return await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.instructor,
.title,
.room {
  max-width: 200px;
}

.instructor__option {
  @include flex(row, center, flex-start);

  img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.schedule {
  @include flex(row, flex-start, flex-start);
  flex-wrap: wrap;

  &__calendar {
    margin-right: 32px;

    p {
      @include flex(row, center, flex-start);
      color: $dodger-blue;
      padding: 0 8px;
    }

    .legend {
      @include flex(row, center, flex-start);

      p.with-dot::before {
        content: '';
        background: $dodger-blue;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        margin-right: 4px;
      }

      p.with-dot.gray {
        color: $gray-500;

        &::before {
          background: $gray-500;
        }
      }
    }
  }

  &__datetimes {
    width: 740px;

    &__description {
      @include flex(row, center, flex-start);
      margin: 0 32px 0 8px;

      &__count {
        font-size: 18px;
        margin-right: 16px;
      }

      &__limit {
        color: $color-danger;
        margin-left: auto;
      }
    }

    &__inputs {
      margin-top: 8px;
      max-height: 360px;
      overflow: auto;

      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
  }
}
</style>
