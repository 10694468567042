import moment from 'moment';
import filters from '@filters';
import getDaysDiff from './getDaysDiff';

/**
 * 잔여일 문구 생성
 *
 * @param {Date} expire_on
 */
export default (expire_on, start_on) => {
  const start = moment(start_on).format('YYYYMMDD') <= moment().format('YYYYMMDD') ?
    moment() :
    moment(start_on).subtract(1, 'd');
  const diff = getDaysDiff(expire_on, start);

  return diff === 0 ? '오늘 만료' : diff < 0 ? `${filters.comma(diff * -1)}일 지남` : `${filters.comma(diff)}일 남음`;
};