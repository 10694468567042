<template>
  <MainLayout>
    <ContentHeaderTabs
      :tabs="headerTabs"
      :activeTab="$route.path"
      :exceldownloading="exceldownloading"
      :handleExcelDownloadClick="downloadExcel"
    />

    <CounselForm
      :counselSelected="counselSelected"
      :channelOptions="filterOptions.channel"
      :handleSaveCounselSuccess="dispatchGetCounselList"
      :staffs="staffs"
      :currentStaffId="$route.params.instructor_id"
    />

    <ListFilter
      :filterValues="params.filter"
      :filterOptions="filterOptions"
      :placeholders="{ keyword: '회원명 / 전화번호 / 내용' }"
      @change="handleFilterChange"
      @reset="handleFilterReset"
    />

    <CounselList
      v-loading="loading"
      :counsels="counsels"
      :channelOptions="filterOptions.channel"
      :handleRowClick="handleRowClick"
      :handleClickDelete="handleClickDelete"
    />

    <el-pagination
      class="counsels__pagination"
      @current-change="dispatchGetCounselList"
      :current-page.sync="params.page"
      :page-size="params.limit"
      layout="prev, pager, next"
      :total="total"
    />
  </MainLayout>
</template>

<script>
import CounselForm from '@components/Counsels/CounselForm';
import CounselList from '@components/Counsels/CounselList';

export default {
  components: { CounselForm, CounselList },
  name: 'counsels',

  data() {
    return {
      headerTabs: [
        { path: '/users', label: '회원' },
        { path: '/users/counseling', label: '상담고객' },
        { path: '/users/ticket-history', label: '수강권 정보 변경이력' },
      ],
      exceldownloading: false,
      params: {
        page: 1,
        limit: 10,
        filter: {
          channel: null,
          staff_id: null,
          keyword: '',
          dateRange: [],
        },
      },
      filterOptions: {
        channel: [
          { value: null, label: '인입경로 전체' },
          { value: 'phone', label: '전화상담' },
          { value: 'visit', label: '방문상담' },
          { value: 'chat', label: '채팅상담' },
          { value: 'etc', label: '기타' },
        ],
        staff_id: [],
      },
      counselSelected: null,
      staffs: [],
    };
  },

  computed: {
    counsels() {
      return this.$store.getters['counsels/counsels'];
    },
    loading() {
      return this.$store.getters['counsels/loading'];
    },
    total() {
      return this.$store.getters['counsels/total'];
    },
  },

  async created() {
    /** 상담 보기 권한이 없을 경우 */
    if (!this.canViewCounsel) {
      this.staffs = [{ ...this.currentUser }];
      this.filterOptions.staff_id.push({
        value: this.currentUser.id,
        label: this.currentUser.profile.name,
      });
      this.params.filter.staff_id = this.currentUser.id;

      /** 상담 보기 권한이 있을 경우 */
    } else {
      const res = await this.$api.staff.getAll();
      this.staffs = res.data.staffs;
      this.filterOptions.staff_id = [{ value: null, label: '담당스태프 전체' }];
      this.staffs.forEach(staff => {
        const id = _.get(staff, 'id');
        const name = _.get(staff, 'profile.name');
        this.filterOptions.staff_id.push({ value: id, label: name });
      });
    }
    this.dispatchGetCounselList();
  },

  methods: {
    dispatchGetCounselList() {
      this.$store.dispatch('counsels/getCounsels', this.params);
    },

    handleFilterChange(filter) {
      this.params.filter = filter;
      this.params.page = 1;
      this.dispatchGetCounselList();
    },

    handleFilterReset() {
      this.params = {
        page: 1,
        limit: 10,
        filter: {
          channel: null,
          staff_id: null,
          keyword: '',
          dateRange: [],
        },
      };
      this.dispatchGetCounselList();
    },

    handleRowClick(row) {
      this.counselSelected = row;
    },

    async handleClickDelete(id) {
      if (!this.canDeleteCounsel) {
        return this.$utils.notify.error(this, '오류', '권한이 필요한 기능입니다.');
      }

      const proceed = await this.$confirm('이 상담을 삭제할까요?', '상담 내역 삭제', {
        confirmButtonText: '삭제',
        cancelButtonText: '취소',
      })
        .then(() => true)
        .catch(() => false);

      if (proceed) {
        try {
          await this.$api.counsel.delete(id);
          this.$utils.notify.success(this, '성공', '상담 내역이 삭제되었습니다.');
          this.dispatchGetCounselList();
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    async downloadExcel() {
      this.exceldownloading = true;
      /** 데이터 요청 및 가공 */
      const params = { ...this.params.filter, has_member: true };
      const res = await this.$api.counsel.getAll(params);
      const json = this.formatJSON(res.data);
      const title = `상담고객목록_${this.moment().format('YYYYMMDD_HHmm')}.xlsx`;

      /** 다운로드 */
      this.$utils.downloadExcel(json, title);
      this.exceldownloading = false;
    },

    formatJSON(counsels) {
      return counsels.map(({ name, phone, created_at, start_on, channel, contents, staff, member_id }) => {
        const date = this.moment(start_on || created_at).format('YYYY-MM-DD');
        const channelToString = this.filterOptions.channel.find(option => option.value === channel).label;
        const staffName = _.get(staff, 'name', '-');

        return {
          이름: name,
          전화번호: this.$filters.mobile(phone),
          상담일자: date,
          인입경로: channelToString,
          상담내용: contents,
          담당스태프: staffName,
          회원등록여부: member_id ? 'Y' : 'N',
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.counsels__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
</style>
