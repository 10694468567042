<template>
  <MainLayout>
    <div id="boards">
      <h3>게시판</h3>
      <CustomTabs class="boards__tab" :active_tab="activeTab" :tabs="tabs" :handleChangeTab="handleChangeTab" />

      <div class="boards__body">
        <BoardsList
          v-if="activePath === '/boards' || activePath === '/boards/list-qna' || activePath === `/boards/studio-notice`"
          :active_tab="activeTab"
        />
        <BoardsForm
          v-else-if="activePath === '/boards/notice/form' || activePath === '/boards/qna/form'"
          :active_tab="activeTab"
        />

        <BoardsDetail
          v-else-if="
            activePath === `/boards/notice/${$route.params.id}` ||
              activePath === `/boards/qna/${$route.params.id}` ||
              activePath === `/boards/studio-notice/${$route.params.id}`
          "
          :active_tab="activeTab"
        />
      </div>
    </div>
  </MainLayout>
</template>
<script>
import BoardsForm from '@/components/Boards/Form';
import BoardsDetail from '@/components/Boards/Detail';
import BoardsList from '@/components/Boards/List';

export default {
  components: { BoardsForm, BoardsDetail, BoardsList },

  data() {
    return {
      tabs: [
        { value: 'notice', label: '공지사항' },
        { value: 'studio-notice', label: '스튜디오메이트 공지' },
      ],
      tabsWithQna: [
        { value: 'notice', label: '공지사항' },
        { value: 'qna', label: '문의 게시판' },
        { value: 'studio-notice', label: '스튜디오메이트 공지' },
      ],
    };
  },
  created() {
    if (this.canViewQnaBoard && this.isShowQna) this.tabs = this.tabsWithQna;
  },
  computed: {
    activePath() {
      return this.$route.path;
    },
    activeTab() {
      return this.$route.name;
    },
    isShowQna() {
      return _.get(this.studioPolicies, 'is_visible_qna');
    },
  },
  methods: {
    handleChangeTab(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
#boards {
  @include flex(column);
  max-width: 1024px;
  margin: auto;

  h3 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.boards__tab {
  border-bottom: 1px solid #fbfbfb;
  justify-content: flex-start;
}

.boards__body {
  padding: 40px 0;
}
</style>
