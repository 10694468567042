<template>
  <el-dialog
    class="change-point-product-dialog"
    title="자동 충전"
    width="430px"
    :visible="show"
    @close="handleClickCancel"
    append-to-body
  >
    <p>잔여 포인트가 1만 포인트보다 적으면 설정한 금액으로 자동 결제됩니다.</p>
    <el-checkbox-group v-model="checkboxSelected">
      <el-checkbox
        v-for="(product, productKey) in smsProducts"
        :label="product.id"
        :key="productKey"
        @change="() => handleChangeSelection(product)"
      >
        <b>{{ product.name }}</b>
        {{ product.actual_price | comma }}원
      </el-checkbox>
    </el-checkbox-group>
    <p>모든 항목에는 부가세 (VAT)가 포함되어 있습니다.</p>

    <div slot="footer" class="change-course-end-date-dialog__buttons">
      <el-button @click="handleClickConfirm" type="primary" :disabled="!checkboxSelected.length">{{
        confirmButtonText
      }}</el-button>
      <el-button @click="handleClickCancel">취소</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    requestPayNow: Function,
    handleClickCancel: Function,
    selectedProductId: Number,
    selectedCardId: Number,
    paymentListLength: Number,
    isAutoCharging: Boolean,
  },

  data() {
    return {
      checkboxSelected: [],
    };
  },

  computed: {
    smsProducts() {
      return this.$store.getters['payment/smsProducts'];
    },

    confirmButtonText() {
      if (!this.isAutoCharging) {
        return '결제';
      }
      return '변경';
    },
  },

  watch: {
    show() {
      this.initSelection();
    },

    selectedProductId() {
      this.checkboxSelected = this.selectedProductId ? [this.selectedProductId] : [];
    },
  },

  methods: {
    initSelection() {
      this.selected = [];
      this.checkboxSelected = [this.selectedProductId];
    },

    handleClickConfirm() {
      if (!this.isAutoCharging) {
        return this.handleClickActivateAutoCharge();
      }
      return this.handleClickUpdatePointProduct();
    },
    async handleClickActivateAutoCharge() {
      this.$emit('loading', true);
      if (!this.paymentListLength) {
        return this.$utils.notify.error(this, '오류', '카드를 먼저 등록해주세요.');
      }
      const params = {
        point_id: this.selectedProductId,
        usable_point_auto_charge: true,
      };
      if (!this.isAutoCharging) {
        params.point_card_id = this.selectedCardId;
      }
      const { data } = await this.requestPayNow();
      if (data.status === 'success') {
        await this.$store.dispatch('payment/updatePaymentPolicy', params);
      }
      this.handleClickCancel();
      this.$emit('showResultChargeModal', data);
      this.$emit('loading', false);
    },

    async handleClickUpdatePointProduct() {
      this.$emit('loading', true);
      if (!this.paymentListLength) {
        return this.$utils.notify.error(this, '오류', '카드를 먼저 등록해주세요.');
      }
      const params = {
        point_id: this.selectedProductId,
        usable_point_auto_charge: true,
      };

      const { data } = await this.$store.dispatch('payment/updatePaymentPolicy', params);
      this.handleClickCancel();
      this.$emit('showResultChargeModal', { flag: 'UPDATE', status: 'success', ...data });
      this.$emit('loading', false);
    },

    handleChangeSelection(product) {
      if (this.selectedProductId === product.id) {
        this.checkboxSelected = [this.selectedProductId];
        return;
      }
      this.$emit('handleClickProduct', product);
    },
  },
};
</script>

<style lang="scss" scoped>
.change-point-product-dialog {
  /deep/ .el-dialog {
    top: 5vh;
    height: 414px !important;
    padding: 135px 26px 30px 30px;

    &__header {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #343a40;
      padding: 0px 0px 6px 0px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 30px;
        left: 30px;
        width: 90px;
        height: 90px;
        background: url('../../../assets/images/icons/auto-payment.svg') 0 0 no-repeat;
        background-size: cover;
      }
    }

    &__body {
      font-family: NotoSansKR;
      padding: 0px 0px 0px 0px;
      > p {
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #a3a3a3;
        padding: 20px 0px 30px 0px;

        &:first-of-type {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.3px;
          text-align: left;
          color: #a3a3a3;
          padding: 0px 0px 20px 0px;
        }
      }
    }

    .el-checkbox-group {
      display: flex;
      flex-direction: column;

      .el-checkbox {
        margin: 0px 0px 10px 0px;
        &:last-child {
          margin: 0px 0px 0px 0px;
        }

        &__label {
          color: #808080;
          b {
            color: #1da0ff;
          }
        }
      }
    }
    &__footer {
      padding: 0px 0px 0px 0px;

      .el-button {
        width: 64px;
        height: 32px;
        padding: 0px 0px 0px 0px;
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
