<template>
  <form v-loading="loading" :disabled="loading" id="counsel-form" @submit.prevent="handleSubmit">
    <div class="counsel-form__inputs">
      <el-select class="counsel-form__inputs__channel" v-model="counsel.channel" size="small">
        <el-option
          v-for="item in channelOptions.filter(v => v.value !== null)"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        />
      </el-select>

      <el-select class="counsel-form__inputs__staff" v-model="counsel.staff_id" size="small">
        <el-option v-for="item in staffs" :key="item.id" :label="item.profile.name + ' 강사'" :value="item.id">
          <span class="counsel-form__inputs__staff-select-image">
            <img :src="$utils.getImageUrl(_.get(item, 'avatars[0]'), '24x24')" :alt="item.profile.name" />
            <span>{{ item.profile.name }}</span>
          </span>
        </el-option>
      </el-select>

      <div class="counsel-form__inputs__datetime">
        <el-date-picker
          v-model="startDate"
          type="date"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          placeholder="상담일자"
          required
          :clearable="false"
        />
        <hours-input
          v-model="time"
          :startHourOptions="{
            start: '05:00',
            end: '23:50',
            step: '00:05',
          }"
          :endHourOptions="{
            start: time.start || '05:00',
            end: '23:55',
            step: '00:05',
            minTime: time.start,
          }"
          :disabled="!time.start"
          :clearable="false"
        />
      </div>
      <TextInput class="counsel-form__inputs__name" v-model="counsel.name" placeholder="이름" required material />
      <PhoneNumberInput
        v-if="isSelectedMobileDisabled"
        class="counsel-form__inputs__phone"
        v-model="selectedMaskedPhone"
        placeholder="휴대폰번호 (숫자만)"
        disabled
      />
      <PhoneNumberInput v-else class="counsel-form__inputs__phone" v-model="counsel.phone" placeholder="휴대폰번호 (숫자만)" />
      <Textarea
        class="counsel-form__inputs__contents"
        v-model="counsel.contents"
        placeholder="* 상담 내용을 입력해주세요..."
        required
        material
      />
    </div>
    <div class="counsel-form__buttons">
      <el-button v-if="isPermitted" type="primary" native-type="submit" plain>
        {{ counsel.id ? '수정' : '저장' }}
      </el-button>
      <el-button type="danger" plain @click="resetForm()">
        취소
      </el-button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    counselSelected: Object,
    channelOptions: Array,
    handleSaveCounselSuccess: Function,
    staffs: Array,
    currentStaffId: String,
  },

  data() {
    return {
      counsel: {
        id: null,
        channel: 'phone',
        contents: '',
        staff_id: null,
        start_on: null,
        phone: '',
        name: '',
        maskedPhone: '',
      },
      selectedMaskedPhone: '',

      loading: false,
    };
  },

  computed: {
    isSelectedMobileDisabled() {
      return !this.canViewMembersMobile && this.counselSelected && !!this.counselSelected.member_id;
    },
    startDate: {
      get() {
        return this.moment(this.counsel.start_on).format('YYYY-MM-DD');
      },
      set(date) {
        if (!date) return;
        const { start, end } = this.time;
        this.counsel.start_on = `${date} ${start}:00`;
        this.counsel.end_on = `${date} ${end}:00`;
      },
    },

    time: {
      get() {
        const start = this.moment(this.counsel.start_on).format('HH:mm');
        const end = this.moment(this.counsel.end_on).format('HH:mm');
        return { start, end };
      },
      set(time) {
        if (!time.start || !time.end) return;
        const date = this.startDate;
        const start_on = `${date} ${time.start}:00`;
        const end_on = `${date} ${time.end}:00`;
        this.counsel = { ...this.counsel, start_on, end_on };
      },
    },

    isPermitted() {
      if (!this.counsel.id) {
        return this.canCreateCounsel;
      } else {
        return this.canUpdateCounsel;
      }
    },
  },

  watch: {
    counselSelected() {
      if (this.counselSelected) {
        this.counsel = {
          ..._.pick(this.counselSelected, 'id', 'channel', 'contents', 'start_on', 'end_on', 'name', 'phone'),
          staff_id: this.staffs.some(({ id }) => id === this.counselSelected.staff.id) ? this.counselSelected.staff.id : null,
        };
        this.selectedMaskedPhone = this.$filters.mobileMask(this.counselSelected.phone);
      }
    },
  },

  created() {
    // 일정 페이지 달력에서 이미 등록된 상담 아이템을 클릭
    // visitCounseling: 일정 > 전체 탭의 빈 달력 or 플로팅 버튼 클릭으로 상담 일정 생성하여 넘어올 경우 (boolean)
    if (Object.keys(this.$route.params).length && !this.$route.params.visitCounseling) {
      const { id, contents, end_on, name, phone, start_on, staff_id } = this.$route.params;
      this.counsel = {
        ...this.counsel,
        channel: 'visit',
        staff_id,
        id,
        name,
        phone,
        contents,
        end_on: this.moment(end_on).format('YYYY-MM-DD HH:mm:ss'),
        start_on: this.moment(start_on).format('YYYY-MM-DD HH:mm:ss'),
      };
    } else {
      this.resetForm();
    }
  },

  methods: {
    async handleSubmit() {
      if (this.validate()) {
        try {
          this.loading = true;
          if (this.counsel.id) {
            await this.$api.counsel.update(this.counsel);
          } else {
            await this.$api.counsel.create(this.counsel);
          }
          this.$utils.notify.success(this, '성공', '저장되었습니다.');
          this.resetForm();
          this.handleSaveCounselSuccess();
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.loading = false;
        }
      }
    },

    validate() {
      let message = '';
      let is_valid = true;

      if (!this.isPermitted) {
        message = '권한이 필요한 기능입니다.';
        is_valid = is_valid && false;
      } else if (!this.counsel.staff_id) {
        message = '상담 강사를 선택해주세요.';
        is_valid = is_valid && false;
      } else if (this.counsel.name.trim().length < 2) {
        message = '이름을 공백 없이 2자 이상 입력 해 주세요.';
        is_valid = is_valid && false;
      } else if (!this.counsel.contents) {
        message = '상담 내용을 입력해주세요.';
        is_valid = is_valid && false;
      } else if (this.counsel.phone && !this.$utils.validate.phone(this.counsel.phone)) {
        message = '휴대폰 번호를 확인해주세요.';
        is_valid = is_valid && false;
      }

      if (!is_valid) {
        this.$utils.notify.error(this, '오류', message);
      }

      return is_valid;
    },
    resetForm() {
      let channel = 'phone';
      let staff_id = Number(this.currentStaffId) || this.currentUser.id;

      const now = this.moment();
      const calibrated = now.subtract(now.minute() % 5, 'minutes');
      const datetimeFormat = 'YYYY-MM-DD HH:mm:ss';
      let startOn = calibrated.format(datetimeFormat);
      let endOn = calibrated.add(30, 'minutes').format(datetimeFormat);

      const { instructor_id, start_on, end_on } = this.$route.query;
      const { visitCounseling } = this.$route.params;

      if (instructor_id) staff_id = parseInt(instructor_id);

      if (start_on && end_on) {
        startOn = this.moment(start_on).format(datetimeFormat);
        endOn = this.moment(end_on).format(datetimeFormat);
        channel = 'visit';
      } else if (visitCounseling) {
        channel = 'visit';
      }

      this.counsel = {
        id: null,
        channel,
        staff_id,
        start_on: startOn,
        end_on: endOn,
        name: '',
        phone: '',
        contents: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#counsel-form {
  border-radius: 2px;
  border: solid 1px #eee;
  box-shadow: 0 8px 21px -5px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 24px;
  font-size: 12px;
  margin: 15px auto 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  max-width: 1024px;

  @include mq(768px) {
    grid-template-columns: 1fr auto;
    grid-gap: 40px;
  }
}

.counsel-form__inputs {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'channel' 'staff' 'datetime' 'name' 'phone' 'contents';
  grid-gap: 10px;
  align-items: start;

  @include mq(568px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'channel staff'
      'datetime datetime'
      'name phone'
      'contents contents';
  }

  @include mq(768px) {
    grid-template-columns: repeat(2, minmax(120px, 150px)) 1fr;
    grid-template-areas:
      'channel staff datetime'
      'name phone contents';
    grid-gap: 10px 20px;
  }

  &__channel {
    grid-area: channel;
  }

  &__staff {
    grid-area: staff;

    /deep/ .el-select-dropdown__item {
      background: red !important;
    }
  }

  &__staff-select-image {
    @include flex(row, center, flex-start);

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 7px;
    }
  }

  &__datetime {
    grid-area: datetime;
    @include flex(row, center, flex-start);
    flex-wrap: wrap;
  }

  &__name {
    grid-area: name;
  }

  &__phone {
    grid-area: phone;
  }

  &__contents {
    grid-area: contents;
  }
}

.counsel-form__buttons {
  @include flex(row, center, flex-end);

  @include mq(768px) {
    @include flex(column, flex-end, flex-start);
  }

  button {
    margin: 5px;
  }
}
</style>
