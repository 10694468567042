<template>
  <section>
    <div class="staff-classes__header">
      <h4>담당 클래스</h4>
    </div>
    <div class="staff-classes__group-lessons" v-loading="coursesLoading">
      <ClassListItem
        v-if="canCreateGroupLecture(staff.id)"
        :instructor="staff"
        addNew
        @click="handleClickAddNewLesson('group')"
      />
      <ClassListItem
        v-for="course in courses"
        :key="course.id"
        :course="course"
        :instructor="staff"
        @click="handleClickClassListItem"
      />
    </div>

    <div class="staff-classes__header">
      <h4>담당 프라이빗 수업</h4>
      <span>{{ privateLessonHeaderHelperText }}</span>
    </div>
    <div class="staff-classes__private-lessons" v-loading="lecturesLoading">
      <BookingListItem
        v-if="canCreatePrivateLecture(staff.id)"
        :instructor="staff"
        size="mini"
        clickable
        @click="handleClickAddNewLesson('private')"
        addNew
        addNewLabel="새로운 프라이빗 수업 만들기"
      />
      <p v-else-if="!lecturesLoading && !lectures.length">
        예약된 수업이 없습니다.
      </p>
      <BookingListItem
        v-for="lecture in lectures"
        :key="lecture.id"
        :lecture="lecture"
        :instructor="staff"
        size="mini"
        clickable
        @click="handleClickBookingListItem"
      />
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    staff() {
      return this.$store.getters['staff/staff'];
    },
    lectures() {
      return this.$store.getters['staff/lectures'];
    },
    lecturesLoading() {
      return this.$store.getters['staff/lecturesLoading'];
    },
    courses() {
      return this.$store.getters['staff/courses'];
    },
    coursesLoading() {
      return this.$store.getters['staff/coursesLoading'];
    },

    privateLessonHeaderHelperText() {
      const aWeekLater = this.$filters.date(this.moment().add(1, 'week'));
      return `일주일동안 예약된 수업입니다. (오늘 ~ ${aWeekLater})`;
    },
  },

  methods: {
    handleClickAddNewLesson(courseType) {
      if (!courseType) return;
      const queryParam = courseType === 'private' ? 'instructor_id' : 'staff_id';
      this.$router.push(`/course/${courseType}/create?${queryParam}=${this.staff.id}`);
    },

    handleClickBookingListItem(lectureId) {
      const lecture = this.lectures.find(({ id }) => id === lectureId);
      const courseType = _.get(lecture, 'type');
      const instructorId = _.get(lecture, 'staff.id');
      if (this.canViewLecture(courseType, instructorId)) {
        this.$router.push(`/lecture/detail?id=${lectureId}`);
      }
    },

    async handleClickClassListItem(courseId) {
      const res = await this.$api.course.get(courseId);
      const courseType = _.get(res.data.course, 'type');
      const instructorId = _.get(res.data.course, 'instructor_id');

      if (!this.canViewLecture(courseType, instructorId)) {
        return this.$utils.notify.error(this, '오류', '권한이 없습니다.');
      } else {
        const lectures = res.data.course.lectures;
        const lectureId = this.$utils.getNearestLectureIdOfCourse(lectures);

        if (!lectureId) {
          this.$utils.notify.error(this, '오류', '해당 클래스의 수업이 존재하지 않습니다.');
        } else {
          this.$router.push(`/lecture/detail?id=${lectureId}`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-classes__header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px 8px;

  h4 {
    font-size: 20px;
    font-weight: 500;
  }

  p {
    grid-column: 1 / 3;
    font-size: 12px;
    opacity: 0.5;
  }
}

.staff-classes__group-lessons,
.staff-classes__private-lessons {
  margin: 15px 4px 64px;
}

.staff-classes__group-lessons {
  display: grid;
  grid-template-columns: repeat(auto-fill, 203px);
  grid-gap: 8px;
}

.staff-classes__private-lessons {
  display: grid;
  grid-template-columns: repeat(auto-fill, 203px);
  grid-gap: 12px 8px;

  p {
    opacity: 0.6;
  }
}
</style>
