<template>
  <section class="board-form" v-loading="loading">
    <div class="board-form__name select">
      <label>
        공지설정
      </label>
      <el-checkbox slot="checkbox" v-model="formData.popup_on" :disabled="popupPeriodType === 'none'">
        팝업사용
      </el-checkbox>
      <el-checkbox slot="checkbox" v-model="isTopFixed" :disabled="popupPeriodType === 'none'">
        상단고정
      </el-checkbox>
    </div>

    <div class="board-form__name select">
      <label>
        공지대상
      </label>
      <el-checkbox slot="checkbox" v-model="isPopupTargetActive">
        유효회원
      </el-checkbox>
      <el-checkbox slot="checkbox" v-model="isPopupTargetExpired">
        만료회원
      </el-checkbox>
    </div>

    <div class="board-form__name select">
      <label>
        게시기간
      </label>
      <el-radio-group v-model="popupPeriodType">
        <el-radio label="limit">
          <span>설정</span>
          <div class="board-form__date">
            <el-date-picker
              placeholder="게시 시작일"
              type="date"
              v-model="formData.start_on"
              format="yyyy. M. d."
              value-format="yyyy-MM-dd"
              :disabled="true"
              :clearable="false"
            />
            <span>~</span>
            <el-date-picker
              placeholder="게시 종료일"
              type="date"
              v-model="formData.end_on"
              format="yyyy. M. d."
              value-format="yyyy-MM-dd"
              :disabled="popupPeriodType !== 'limit'"
              :clearable="false"
              :picker-options="endDatePickerOptions"
            />
          </div>
        </el-radio>
        <el-radio label="none">
          미설정 (공지사항이 노출되지 않습니다.)
        </el-radio>
        <el-radio label="unlimited">
          제한없음
        </el-radio>
      </el-radio-group>
    </div>

    <div class="board-form__name">
      <TextInput
        ref="titleInput"
        v-model="formData.title"
        size="large"
        label="제목"
        labelAlign="row"
        labelWidth="100px"
        placeholder="제목을 입력해주세요."
        :error="$v.formData.title.$dirty && !$v.formData.title.required"
      />
    </div>

    <div class="board-form__name textarea">
      <label>내용</label>
      <BoardCreatingWithImage v-model="formData" size="large" placeholder="내용을 입력하세요" :isEditing="isEditing" />
    </div>

    <BottomActionBar contentMaxWidth="1366px" :onClickBack="handleClickBack">
      <template v-if="isEditing">
        <el-button class="btn-delete" v-if="canDeleteNoticeBoard" @click="handleClickDelete">삭제</el-button>
        <el-button v-if="canCreateNoticeBoard" :disabled="submitButtonDisabled" @click="handleClickEdit">수정</el-button>
      </template>

      <el-button v-if="!isEditing" :disabled="submitButtonDisabled" @click="handleClickCreate">등록</el-button>
    </BottomActionBar>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const DEFAULT_BOARD = {
  title: '',
  is_attachment: 0,
  user_type: 'staff',
  type: 'notice',
  popup_on: true,
  /** 내용 관련 state (아래 3개) */
  contents: '',
  attachment: [],
  attachments: [],
};

export default {
  data() {
    return {
      /**보드 데이터 */
      formData: {
        ...DEFAULT_BOARD,
        start_on: this.moment().format('YYYY-MM-DD'),
        end_on: null,
      },
      today: this.moment().format('YYYY-MM-DD'),
      initEditFormData: { ...DEFAULT_BOARD },
      isTopFixed: false,
      isPopupTargetActive: true,
      isPopupTargetExpired: false,
      popupPeriodType: 'limit',
      createdId: Number,
      requestSuccess: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters['boardNotice/loading'];
    },

    boardDetail() {
      return this.$store.getters['boardNotice/boardDetail'];
    },

    isEditing() {
      return !!this.$route.query.id;
    },

    submitButtonDisabled() {
      const { title } = this.$v.formData;
      return !title.required;
    },

    endDatePickerOptions() {
      const {
        moment,
        initEditFormData: { end_on },
      } = this;
      const date = !end_on || (end_on && moment(end_on).isAfter(this.today)) ? this.today : end_on;

      return {
        disabledDate(time) {
          return moment(time).isBefore(date);
        },
      };
    },

    checkOverWrite() {
      return this.formData.title.length;
    },
  },

  watch: {
    /** 공지 수정 시 현재 데이터 세팅 */
    boardDetail() {
      if (!this.boardDetail) return;

      this.initEditFormData = this.boardDetail;

      const { popup_on, is_top_fixed, is_unlimited_period, is_used_period, target_member, start_on, ...rest } = this.boardDetail;

      this.formData = {
        ...rest,
        popup_on: !!popup_on,
        start_on: start_on || this.today,
      };

      this.isTopFixed = !!is_top_fixed;
      this.isPopupTargetActive = target_member !== 'expired';
      this.isPopupTargetExpired = target_member !== 'active';
      this.popupPeriodType = is_unlimited_period ? 'unlimited' : is_used_period ? 'limit' : 'none';
    },

    popupPeriodType(value) {
      /** 게시기간 미설정 클릭 시 팝업사용, 상단고정 체크 해제 */
      if (value === 'none') {
        this.formData.popup_on = false;
        this.isTopFixed = false;
      }
    },
  },

  mounted() {
    if (this.isEditing) {
      const id = this.$route.query.id;
      this.$store.dispatch('boardNotice/getBoardDetail', { id });
    } else {
      this.$nextTick(() => {
        this.$refs.titleInput.$el.querySelector('input').focus();
      });
    }
  },

  /** Form validation */
  mixins: [validationMixin],
  validations: {
    formData: {
      title: { required },
    },
  },

  methods: {
    handleClickBack() {
      let hasContents = false;
      if (this.isEditing) {
        hasContents =
          this.formData.title !== this.initEditFormData.title || this.formData.contents !== this.initEditFormData.contents;
      } else {
        hasContents = this.formData.title || this.formData.contents;
      }

      if (hasContents) {
        const title = '알림';
        const message = `작성중인 글이 있습니다. <br /> 페이지를 벗어나시겠습니까?`;

        this.$confirm(message, title, { dangerouslyUseHTMLString: true, confirmButtonText: '나가기' })
          .then(() => {
            this.$router.go(-1);
            this.$store.commit('boardNotice/SET_BOARD_DETAIL');
          })
          .catch(() => false);
      } else {
        this.$router.go(-1);
        this.$store.commit('boardNotice/SET_BOARD_DETAIL');
      }
    },

    validate() {
      if (!this.canCreateNoticeBoard) {
        this.$utils.notify.error(this, '오류', '공지사항 등록 권한이 없습니다.');
        return false;
      } else if (!this.isPopupTargetActive && !this.isPopupTargetExpired) {
        this.$utils.notify.error(this, '오류', '공지대상을 선택해주세요.');
        return false;
      } else if (this.popupPeriodType === 'limit' && !this.formData.end_on) {
        this.$utils.notify.error(this, '오류', '게시기간을 선택해주세요.');
        return false;
      } else if (this.popupPeriodType === 'limit' && this.moment(this.formData.end_on).isBefore(this.today)) {
        this.$utils.notify.error(this, '오류', '게시 종료일을 다시 선택해 주세요. 오늘보다 과거 날짜는 선택할 수 없습니다.');
        return false;
      } else if (this.checkOverWrite > 255) {
        this.$utils.notify.error(this, '오류', '제목은 255자 이내로 입력해 주세요.');
        return false;
      }

      return true;
    },

    getRequestData() {
      this.formData.is_attachment = 1;

      let target_member = null;
      if (this.isPopupTargetActive && this.isPopupTargetExpired) {
        target_member = 'both';
      } else if (this.isPopupTargetActive) {
        target_member = 'active';
      } else if (this.isPopupTargetExpired) {
        target_member = 'expired';
      }

      const data = {
        ..._.omit(this.formData, ['attachments', 'start_on', 'end_on']),
        target_member,
        is_top_fixed: this.isTopFixed ? 1 : 0,
        is_used_period: this.popupPeriodType === 'limit' ? 1 : 0,
        is_unlimited_period: this.popupPeriodType === 'unlimited' ? 1 : 0,
        start_on: this.popupPeriodType === 'limit' ? this.formData.start_on : null,
        end_on: this.popupPeriodType === 'limit' ? this.formData.end_on : null,
      };

      return data;
    },

    /** 공지 생성 */
    async handleClickCreate() {
      if (!this.validate()) return;

      this.requestSuccess = true;

      try {
        const data = this.getRequestData();
        const res = await this.$api.boards.create(data);
        if (res) {
          this.createdId = res.data.id;
        }
      } catch (error) {
        this.requestSuccess = false;
        this.$utils.notify.parseError(this, error);
      }
      if (this.requestSuccess) {
        this.$utils.notify.success(this, '성공', '작성하신 글이 등록되었습니다.');
        this.$router.push(`/boards/notice/${this.createdId}`);
      }
    },

    /** 공지 수정 */
    async handleClickEdit() {
      if (!this.validate()) return;

      this.requestSuccess = true;

      try {
        const data = this.getRequestData();
        await this.$api.boards.update(data);
      } catch (error) {
        this.requestSuccess = false;
        this.$utils.notify.parseErrorTitle(this, error);
      }

      if (this.requestSuccess) {
        this.$utils.notify.success(this, '성공', '작성하신 글이 수정되었습니다.');
        this.$router.go(-1);
      }
    },

    /** 공지 삭제 */
    async handleClickDelete() {
      this.requestSuccess = true;
      const title = '게시글 삭제';
      const message = '게시글을 삭제하시겠습니까?';
      const proceed = await this.$confirm(message, title)
        .then(() => true)
        .catch(() => false);
      if (proceed) {
        try {
          const id = this.$route.query.id;
          await this.$api.boards.delete(id);
        } catch (error) {
          this.requestSuccess = false;
          this.$utils.notify.parseError(this, error);
        }
        if (this.requestSuccess) {
          this.$utils.notify.success(this, '성공', '해당 글이 삭제 되었습니다.');
          this.$router.go(-2);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.board-form {
  position: relative;

  &__name {
    label {
      display: inline-block;
      font-weight: 500;
      min-width: 100px;
    }
    margin-bottom: 10px;

    &.textarea {
      @include flex(row, flex-start, flex-start);

      .memo-creating {
        flex: 1;

        /deep/ &__input {
          border-color: #eee;
          &.focus {
            border-color: $deep-sky-blue;
          }
        }
      }
    }

    &.select {
      @include flex(row, flex-start, flex-start);
      margin-bottom: 20px;

      > label {
        padding: 8px 0;
      }

      .el-checkbox + .el-checkbox {
        margin-left: 0;
      }

      .el-radio-group {
        @include flex(column, center, flex-start);
        gap: 14px;

        .el-radio {
          @include flex(row, center, flex-start);
          margin: 0;
          padding-right: 20px;
          height: 36px;
          width: 100%;
          transition: 0.15s background;

          /deep/ .el-radio__label {
            @include flex(row, center, flex-start);
            gap: 16px;
            height: 100%;
          }

          /deep/ .el-icon-date {
            width: 20px;
            margin-left: 0;
            margin-right: 5px;
          }
        }
      }
    }
  }

  &__date {
    @include flex(row, center, flex-start);
    gap: 12px;

    span {
      color: #606266;
    }
  }

  .bottom-action-bar-container {
    /deep/ button {
      width: 112px;

      &.btn-delete {
        background: transparent;
        color: #fff;
      }
    }
  }
}
</style>
