<template>
  <el-dialog class="ticket-expired-modal" :visible.sync="show" :before-close="onClose" :close-on-click-modal="false">
    <div slot="title" class="ticket-expired-modal__title">
      <h4>이전 {{ this.title }} 보기</h4>
    </div>

    <p class="ticket-expired-modal__description">
      기간이 만료된 {{ this.title }}, 이용 횟수를 모두 사용한 {{ this.title }}, 사용 불가 처리된 {{ this.title }} 내역입니다.
    </p>

    <custom-tabs :active_tab="filter" :tabs="filterOptions" :handleChangeTab="handleChangeFilter" />

    <ul v-if="!!filteredTicketList.length" class="ticket-expired-modal__product-list" v-loading="loading">
      <UserTicketCard
        v-for="userTicket in filteredTicketList"
        :key="userTicket.id"
        :userTicket="userTicket"
        @click="handleClickCard"
        clickable
      />
    </ul>

    <p v-else class="ticket-expired-modal__no-data">{{ this.title }}이 없습니다.</p>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    onClose: Function,
    handleClickCard: Function,
    isProduct: Boolean,
  },

  data() {
    return {
      filter: 'all',
    };
  },

  computed: {
    title() {
      return this.isProduct ? '상품' : '수강권';
    },
    loading() {
      return this.$store.getters['member/userTicketsLoading'];
    },
    userTickets() {
      return this.isProduct
        ? this.$store.getters['member/userProductsInactive']
        : this.$store.getters['member/userTicketsInactive'];
    },

    filterOptions() {
      const tabs = [
        { value: 'all', label: '전체' },
        { value: '이용만료', label: '이용만료' },
        { value: '양도', label: '양도' },
        { value: '사용불가', label: '사용불가' },
        { value: '환불', label: '환불' },
      ];
      const addToProductTabs = { value: '판매', label: '판매' };
      if (this.isProduct) {
        tabs.push(addToProductTabs);
      }

      return tabs;
    },

    filteredTicketList() {
      if (this.filter === 'all') return this.userTickets;

      return this.userTickets.filter(ticket => {
        return this.filter === this.$utils.getTicketStatus(ticket);
      });
    },
  },

  methods: {
    handleChangeFilter(filter_name) {
      this.filter = filter_name;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-expired-modal {
  /deep/ .el-dialog {
    @include flex(column);
    width: 90%;
    max-width: 620px;
    height: 654px;
  }

  &__description {
    font-size: 13px;
    opacity: 0.6;
    text-align: center;
    padding-bottom: 20px;
  }

  &__product-list {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(auto, 144px);
    grid-gap: 16px;
    height: 460px;
    overflow: auto;
    padding: 16px;
  }

  &__no-data {
    padding: 50px;
    text-align: center;
  }
}

@media (min-width: 568px) {
  .ticket-expired-modal__product-list {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
