const success = (vm, title, message) => {
  vm.$notify({
    dangerouslyUseHTMLString: true,
    title: title,
    message: message,
    type: 'success',
  });
};

const error = (vm, title, message) => {
  vm.$notify.error({
    dangerouslyUseHTMLString: true,
    title: title,
    message: message,
  });
};

const parseError = (vm, err) => {
  if (err.response) {
    error(vm, 'ERROR', err.response.data.message);
  } else {
    error(vm, 'ERROR', err.message);
  }
};

const parseErrorImage = (vm, err,type) => {
  let errMsg = ''
  switch (type) {
    case 'image':
      errMsg = 'JPG 또는 PNG 형식의 이미지를 올려주세요.'
      
      break;

      case 'name':
        errMsg = err.response.data.errors.name[0]

      break;
      default:
        return errMsg  = '오류입니다.'

  } 
  if (err.response) {
    error(vm, 'ERROR', errMsg);
  } else {
    error(vm, 'ERROR', err.message);
  }
};

const parseErrorTitle = (vm, err) => {
  if (err.response) {
    error(vm, 'ERROR', err.response.data.errors.title[0]);
  } else {
    error(vm, 'ERROR', err.message);
  }
};

export default {
  success,
  error,
  parseError,
  parseErrorTitle,
  parseErrorImage,
};
