<template>
  <div class="auth-wrapper">
    <div class="auth-header">
      <img src="@/assets/images/03_item/logo_full/200x20/login.svg" />
    </div>
    <section class="auth-body">
      <div class="auth-body__content">
        <img v-if="show_logo" class="auth-body__logo" src="@/assets/images/03_item/logo_full/200x20/black.svg" />

        <h3 v-if="title" class="auth-body__title">
          {{ title }}
        </h3>
        <p v-if="description" v-html="description" class="auth-body__description" />

        <slot></slot>

        <hr v-if="show_footer" />
        <div v-if="show_footer" class="auth-footer">
          <div>
            <img src="@/assets/images/lock.svg" />
            <span>{{ footer_message }}</span>
          </div>
          <div class="auth-footer__links">
            <router-link v-for="link in footer_links" :key="link.path" :to="link.path">
              {{ link.label }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    show_logo: Boolean,
    title: String,
    description: String,
    footer_message: String,
    footer_links: Array,
  },

  computed: {
    show_footer() {
      return this.footer_message || this.footer_links;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.auth-header {
  width: 100%;

  img {
    width: 100%;
  }
}

.auth-body {
  flex: 1;
  width: 100%;
  padding: 80px 10px 40px;

  &__content {
    width: 100%;
    max-width: 450px;
    margin: auto;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }

  &__logo {
    align-self: flex-start;
    margin-bottom: 18px;
  }

  &__title {
    width: 100%;
    margin-bottom: 16px;
  }

  &__description {
    margin-top: 16px;
  }

  hr {
    width: 100%;
    margin: 30px 0;
    background: $gray-200;
  }
}

.auth-footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    align-items: center;
    display: flex;

    object {
      margin-right: 8px;
    }
  }

  &__links {
    color: $sky-blue;

    a {
      color: inherit;

      &::after {
        content: '·';
      }

      &:hover {
        text-decoration: underline;
      }

      &:last-child::after {
        content: '';
      }
    }
  }
}
</style>
