var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userticket-card",class:{
    P: _vm.courseType === 'P',
    G: _vm.courseType === 'G',
    I: _vm.courseType === 'I',
    clickable: _vm.clickable,
    disabled: !_vm.addNew && _vm.disabled,
    expired: !_vm.addNew && _vm.expired,
    holding: !_vm.addNew && _vm.holding,
    showPrice: _vm.showPrice,
  }},[(_vm.clickable)?_c('div',{staticClass:"userticket-card__click-listener",on:{"click":function($event){return _vm.$emit('click', _vm.userTicket.id)}}}):_vm._e(),(_vm.addNew)?_c('div',{staticClass:"userticket-card__meta",class:{ addNew: _vm.addNew }}):_c('div',{staticClass:"userticket-card__meta",style:(!_vm.addNew && !_vm.disabled && !_vm.expired && !_vm.holding && _vm.haveCustomColor
        ? ("background-image: linear-gradient(to bottom, #" + (_vm.ticketColor[0]) + ",#" + (_vm.ticketColor[1]) + ")")
        : '')},[_c('div',{staticClass:"userticket-card__header"},[_c('h5',[_vm._v(_vm._s(_vm.ticketTypeString))]),(_vm.notIssued)?_c('CircleButton',{staticClass:"userticket-card__header__button",attrs:{"size":"small"},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('i',{staticClass:"el-icon-close"})]):_c('span',{staticClass:"userticket-card__header__tag"},[_vm._v(_vm._s(_vm.ticketTag))])],1),_c('div',{staticClass:"userticket-card__body"},[_c('h3',[_vm._v(_vm._s(_vm.ticketTitle))]),_c('div',[(_vm.isShared)?_c('span',{staticClass:"userticket-card__body__is-shared"},[_vm._v("패밀리")]):_vm._e(),(_vm.ticket.is_ignore_new_payment)?_c('span',{staticClass:"userticket-card__body__is-shared"},[_vm._v("체험권")]):_vm._e()])]),_c('div',{staticClass:"userticket-card__footer"},[(!(_vm.ticketType === 'S' || _vm.disabled || _vm.isRentalOnlyTime))?_c('p',[_vm._v(_vm._s(_vm.ticketPeriod))]):_vm._e(),_c('p',{staticClass:"userticket-card__footer__counts"},_vm._l((_vm.ticketCounts),function(count){return _c('span',{key:count.label},[_vm._v(" "+_vm._s(((count.label) + " " + (count.value)))+" ")])}),0),(this.ticketTag === '사용불가')?_c('p',[_vm._v(_vm._s(_vm.ticketInactiveReason))]):_vm._e()])]),(_vm.addNew)?_c('div',{staticClass:"userticket-card__extra"},[_c('p',{staticClass:"userticket-card__extra__add-new"},[_vm._v(_vm._s(("+ " + _vm.addNewLabel)))])]):_vm._e(),(_vm.showPrice)?_c('div',{staticClass:"userticket-card__extra"},[_c('div',{staticClass:"userticket-card__extra__price"},[_c('span',{staticClass:"userticket-card__extra__price__total"},[_vm._v(_vm._s(_vm.amountTitleText)+" "+_vm._s(_vm._f("comma")(_vm.ticketPrice.total))+"원")]),_c('span',[_c('span',{staticClass:"userticket-card__extra__price__per-coupon"},[_vm._v(" "+_vm._s(_vm.pricePerCoupon)+" ")]),_c('span',{staticClass:"userticket-card__extra__price__unpaid"},[_vm._v(" "+_vm._s(_vm.unpaidAmount)+" ")])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }