import api from '@api';

const DEFAULT_FORM_DATA = {
  currentStep: 1,
  selectedTicket: null,
  ticketDetail: null,
  paymentDetail: null,
  tickets: [],
  ticketsLoading: false,
  staffOptions: [],
  currentPoint: 0,
};

export const state = {
  formData: {
    ...DEFAULT_FORM_DATA,
  },
};

export const getters = {
  formData: state => state.formData,
};

export const mutations = {
  SET_VALUES(state, values) {
    state.formData = !values
      ? {
          ...DEFAULT_FORM_DATA,
        }
      : {
          ...state.formData,
          ...values,
        };
  },
};

export const actions = {
  async getTickets({ commit, dispatch }, isProduct) {
    try {
      commit('SET_VALUES', {
        ticketsLoading: true,
      });
      const params = {
        limit: 999,
        orderBy: 'desc',
        is_selling_now: 1,
        is_web: 'Y',
      };
      const res = await api.product.getAll(params);

      if (window.location.pathname === '/users/detail') {
        const searchParams = new URL(window.location.href).searchParams;
        const userId = Number(searchParams.get('id'));
        const { data } = await api.pointHistory.getPointDetail(userId);

        commit('SET_VALUES', {
          currentPoint: data.current_point,
        });
      }

      if (isProduct) {
        commit('SET_VALUES', {
          tickets: res.data.data.filter(({ available_class_type }) => available_class_type === 'I'),
        });
      } else {
        commit('SET_VALUES', {
          tickets: res.data.data.filter(({ available_class_type }) => available_class_type !== 'I'),
        });
      }
    } catch (error) {
      commit('SET_VALUES', {
        tickets: [],
      });
    } finally {
      commit('SET_VALUES', {
        ticketsLoading: false,
      });
      dispatch('getStaffOptions');
    }
  },

  async getStaffOptions({ commit }) {
    try {
      const res = await api.staff.getAll();
      const staffOptions = res.data.staffs.map(staff => ({
        id: staff.id,
        name: staff.profile.name,
      }));
      commit('SET_VALUES', {
        staffOptions,
      });
    } catch (error) {
      commit('SET_VALUES', {
        staffOptions: [],
      });
    }
  },
};
