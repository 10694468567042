<template>
  <el-dialog
    class="set-auto-charge-dialog"
    title="포인트 자동 충전을 해제하시겠습니까?"
    width="430px"
    :visible="show"
    @close="handleClickCancel"
    append-to-body
  >
    <p>자동 충전은 언제든지 다시 활성화할 수 있어요</p>

    <div slot="footer" class="set-auto-charge-dialog__buttons">
      <el-button @click="handleClickConfirm" type="primary">해제</el-button>
      <el-button @click="handleClickCancel">취소</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    handleClickCancel: Function,
  },

  methods: {
    async handleClickConfirm() {
      this.$emit('loading', true);
      await this.$store.dispatch('payment/updatePaymentPolicy', { usable_point_auto_charge: false });
      await this.$utils.notify.success(this, '확인', '포인트 자동 충전 설정을 해제하였습니다.');
      this.handleClickCancel();
      this.$emit('loading', false);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  top: 20vh;
  height: 288px !important;
  padding: 135px 30px 30px 30px;

  &__header {
    padding: 0px 0px 15px 0px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 30px;
      left: 30px;
      width: 90px;
      height: 90px;
      background: url('../../../assets/images/icons/circle-question-mark.svg') 0 0 no-repeat;
      background-size: cover;
    }
  }

  &__body {
    padding: 0px 0px 30px 0px;
  }

  &__footer {
    padding: 0px 0px 0px 0px;

    .el-button {
      width: 64px;
      height: 32px;
      padding: 0px 0px 0px 0px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>
