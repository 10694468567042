<template>
  <el-dialog
    class="set-auto-charge-dialog"
    :title="`결제 금액: ${$filters.comma(payload.amount)}원 / ${$filters.comma(payload.point)} P`"
    width="430px"
    :visible="show"
    @close="handleClickCancel"
    append-to-body
  >
    <p>확인 버튼을 누르면 결제가 진행됩니다.</p>
    <p>결제를 진행하시겠습니까?</p>

    <div slot="footer" class="set-auto-charge-dialog__buttons">
      <el-button @click="handleClickConfirm" type="primary">결제</el-button>
      <el-button @click="handleClickCancel">취소</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    handleClickCancel: Function,
    handleClickConfirm: Function,
    payload: { amount: Number, point: Number },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  top: 20vh;
  height: 308px !important;
  padding: 135px 30px 30px 30px;
  &__title {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #343a40;
  }

  &__header {
    padding: 0px 0px 15px 0px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 30px;
      left: 30px;
      width: 90px;
      height: 90px;
      background: url('../../../assets/images/icons/card-payment.svg') 0 0 no-repeat;
      background-size: cover;
    }
  }

  &__body {
    padding: 0px 0px 30px 0px;
  }

  &__footer {
    padding: 0px 0px 0px 0px;

    .el-button {
      width: 64px;
      height: 32px;
      padding: 0px 0px 0px 0px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>
