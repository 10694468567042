<template>
  <div class="wrap-top-menu">
    <div class="wrap-top-menu__left">
      <HeaderButton
        @click="openCreateUserPage"
        imgSrc="/img/icons/icon_add_member.png"
        activeImgSrc="/img/icons/icon_add_member-white.png"
        tooltip="회원 추가"
      >
      </HeaderButton>
      <HeaderButton
        @click="openEnterScreen"
        v-show="(studio.grade === 2 || studio.usable_enter) && studioPolicies.is_enter"
        imgSrc="/img/icons/icon_checkin.png"
        activeImgSrc="/img/icons/icon_checkin-white.png"
        tooltip="체크인"
      >
      </HeaderButton>
    </div>
    <div class="divider"></div>
    <el-dropdown id="user-menu" trigger="click" placement="top-end" @command="command => command()">
      <span class="user-menu__user">
        <img :src="profile_image" alt="사용자 프로필" />
        <span>
          <b>{{ user_name }}</b> {{ role_name }}님
          <i class="el-icon-arrow-down"></i>
        </span>
      </span>
      <el-dropdown-menu slot="dropdown">
        <li class="user-menu__expire_on" :class="{ expire_soon, expired }">
          <span>만료일: </span>
          <p v-html="expiration_text" />
        </li>
        <li class="user-menu__expire_on" :class="{ expire_soon, expired }">
          <span>포인트: {{ point | comma }} 포인트</span>
        </li>
        <el-dropdown-item v-for="item in menu_items" :key="item.label" :divided="item.divided" :command="item.onClick">
          {{ item.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import HeaderButton from './HeaderButton.vue';
export default {
  components: {
    HeaderButton,
  },
  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },

    menu_items() {
      return [
        {
          label: '마이페이지',
          divided: true,
          onClick: this.handleClickMyPage,
        },
        {
          label: '로그아웃',
          onClick: this.handleClickLogout,
        },
      ];
    },

    point() {
      return this.$store.getters['payment/point'];
    },

    profile_image() {
      const avatar = _.get(this.currentUser, 'avatars[0]');
      return this.$utils.getImageUrl(avatar, '32x32');
    },

    user_name() {
      return _.get(this.currentUser, 'profile.name');
    },

    role_name() {
      return _.get(this.currentUser, 'roles[0].display_name');
    },

    expire_on() {
      return _.get(this.currentUser, 'studio.expire_on');
    },

    expire_in() {
      return this.$utils.getDaysDiff(this.expire_on);
    },

    expire_soon() {
      return this.expire_in <= 7;
    },

    expired() {
      return this.expire_in < 0;
    },

    expiration_text() {
      const { expire_on, expire_in, moment } = this;

      let text = '없음';
      if (expire_on) {
        text = moment(expire_on).format('ll');
        if (expire_in === 0) {
          text += '<br>(오늘 만료)';
        } else if (expire_in > 0) {
          text += `<br>(${this.$filters.comma(expire_in)}일 남음)`;
        } else if (expire_in < 0) {
          text += `<br>(${this.$filters.comma(Math.abs(expire_in))}일 지남)`;
        }
      }
      return text;
    },
  },

  methods: {
    handleClickMyPage() {
      this.$router.push('/staffs/me');
    },

    handleClickLogout() {
      this.$router.push({ name: 'logout' });
    },

    openEnterScreen() {
      this.$router.push('/checkin');
      // let route = this.$router.resolve({path: '/checkin'});
      // window.open(route.href, '_blank');
    },
    openCreateUserPage() {
      this.$router.push('/users/create');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-top-menu {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-gap: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &__left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
}
.divider {
  height: 36px;
  border-left: 1px solid #eee;
  margin: 0 15px;
}
#user-menu {
  display: flex;
  align-items: stretch;
}

.user-menu {
  &__user {
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    span {
      display: none;

      @include mq(1024px) {
        display: inline-block;
      }
    }
  }

  &__expire_on {
    color: rgba($charcoal-grey, 0.5);
    display: flex;
    font-size: 12px;
    padding: 0 20px;

    span {
      margin-right: 4px;
    }

    &.expire_soon {
      color: $color-danger;
    }

    &.expired {
      color: $color-danger;
      font-weight: bold;
    }
  }
}
</style>
