<template>
  <section>
    <div class="locker-type">
      <div class="create-lesson-modal__body">
        <span @click="goToMemberSelect">
          <div>
            <h5>회원 할당</h5>
            <p>락커에 회원을 할당 합니다</p>
          </div>
        </span>
        <span @click="confirmModal(formData, 'IMPOSSIBLE')">
          <div>
            <h5>사용 불가</h5>
            <p>고장, 파손 등의 이유로 사용 불가 처리합니다</p>
          </div>
        </span>
        <!-- <span @click="confirmModal(lockerId, 'DELETE')">
          <div>
            <h5>삭제</h5>
            <p>락커를 삭제합니다</p>
          </div>
        </span> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    formData: Object,
    confirmModal: Function,
  },

  methods: {
    goToMemberSelect() {
      return this.$store.dispatch('locker/assignLocker', { currentStep: 2 });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-lesson-modal {
  @include flex(row, center, center);

  /deep/ .el-dialog {
    width: 90%;
    max-width: 368px;
  }

  &__body {
    span {
      cursor: pointer;

      &:hover div {
        background: #eee;
      }

      div {
        padding: 20px 0;
        transition: 0.15s;
        border-bottom: 1px solid #eee;

        h5 {
          font-size: 14px;
          padding: 2px 10px;
        }

        p {
          font-size: 11px;
          padding: 2px 10px;
        }
      }
    }
  }

  &__footer {
    background: rgba(250, 251, 251, 0.5);
    border: 1px solid rgba(#000, 0.06);
    border-radius: 2px;
    margin-top: 15px;
    padding: 15px;

    p {
      font-size: 10px;
    }
  }
}
</style>
