<template>
  <div class="container">
    <SalesHeader />
    <SalesTicket v-if="activeTab === 'ticket' && chartOrTable === 'table'" />
    <SalesTicketChart v-else-if="activeTab === 'ticket' && chartOrTable === 'chart'" />
    <SalesLecture v-else-if="activeTab === 'lecture' && chartOrTable === 'table'" />
    <SalesLectureChart v-else-if="activeTab === 'lecture' && chartOrTable === 'chart'" />
    <SalesItem v-else-if="activeTab === 'etc' && chartOrTable === 'table'" />
    <SalesItemChart v-else-if="activeTab === 'etc' && chartOrTable === 'chart'" />
  </div>
</template>

<script>
import SalesHeader from '@/components/Sales/SalesHeader';
import SalesTicket from '@/router/views/SalesTicket';
import SalesLecture from '@/router/views/SalesLecture';
import SalesItem from '@/router/views/SalesItem';
import SalesTicketChart from '@/router/views/SalesTicketChart';
import SalesLectureChart from '@/router/views/SalesLectureChart';
import SalesItemChart from '@/router/views/SalesItemChart';

export default {
  components: {
    SalesHeader,
    SalesTicket,
    SalesLecture,
    SalesItem,
    SalesItemChart,
    SalesTicketChart,
    SalesLectureChart,
  },

  watch: {
    date: function(date) {
      if (date.rangeType === 'daterange') {
        this.$store.dispatch('salesNew/setChartOrTable', 'table');
      }
    },
  },

  computed: {
    date() {
      return this.$store.getters['salesNew/filter'];
    },
    activeTab() {
      return this.$store.getters['salesNew/activeSalesTab'];
    },
    chartOrTable() {
      return this.$store.getters['salesNew/chartOrTable'];
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: #545454;
}
</style>
