<template>
  <div>
    <el-dialog class="send-message" :visible="show" @close="$emit('close', false)" append-to-body>
      <CustomTabs class="send-message__tabs" :active_tab="sendType" :tabs="messageTabs" :handleChangeTab="handleMessageTab" />

      <p class="send-message__message">
        <span v-if="sendType === 'message'">앱푸시 메시지는 앱연결된 회원에게만 보낼 수 있습니다.</span>
        <span v-else>메시지는 전화번호가 등록된 회원에게만 보낼 수 있습니다.</span>
      </p>

      <div class="send-message__members-list-wrapper">
        <h5 v-if="membersAppLinked.booked.length > 0">
          {{ activeRouter === 'users' ? '회원' : '예약 회원' }}
          <PlainButton @click.native="$emit('toggleSelectAll', 'booked')" size="small" round>{{
            isMemberSelectedAll.booked ? '전체 해제' : '전체 선택'
          }}</PlainButton>
        </h5>
        <ul v-if="membersAppLinked.booked.length > 0" class="send-message__members-list">
          <li v-for="(member, index) in _.uniqBy(membersAppLinked.booked, 'id')" :key="`${member.id}${index}`">
            <CustomCheckbox
              :value="member.id"
              :checked="memberIdsForMessage.includes(member.id)"
              @change="$emit('handleCheckboxChange', member.id)"
              >{{ member.name ? member.name : member.profile.name }}</CustomCheckbox
            >
          </li>
        </ul>
        <h5 v-if="membersAppLinked.waiting.length > 0">
          대기 회원
          <PlainButton @click.native.prevent="$emit('toggleSelectAll', 'waiting')" size="small" round>{{
            isMemberSelectedAll.waiting ? '전체 해제' : '전체 선택'
          }}</PlainButton>
        </h5>
        <ul v-if="membersAppLinked.waiting.length > 0" class="send-message__members-list">
          <li v-for="(member, index) in _.uniqBy(membersAppLinked.waiting, 'id')" :key="`${member.id}${index}`">
            <CustomCheckbox
              :value="member.id"
              :checked="memberIdsForMessage.includes(member.id)"
              @change="$emit('handleCheckboxChange', member.id)"
              >{{ member.name ? member.name : member.profile.name }}</CustomCheckbox
            >
          </li>
        </ul>
      </div>

      <MessageForm
        hide-member-list-button
        is-on-modal
        :members="membersForMessage"
        :default-message="defaultMessage"
        :messageType="sendType"
        @messageSent="$emit('close', false)"
        placeholder="40바이트 제한 및 특수문자 (), <>, [] 사용가능"
      />
    </el-dialog>
  </div>
</template>

<script>
import MessageForm from '@/components/Messages/Form';

export default {
  components: {
    MessageForm,
  },

  props: {
    show: Boolean,
    handleCheckboxChange: Function,
    toggleSelectAll: Function,
    onMessageSent: Function,
    membersAppLinked: { type: Object, default: () => {} },
    memberIdsForMessage: { type: Array, default: () => [] },
    isMemberSelectedAll: { type: Object, default: () => {} },
    sendType: String,
  },

  computed: {
    activeRouter() {
      return this.$route.name;
    },

    lecture() {
      return this.$store.getters['lecture/lecture'];
    },

    defaultMessage() {
      if (this.activeRouter === 'users') return null;

      const { dateKorean, time } = this.$filters;
      let message = '\n----------\n';
      const title = _.get(this.lecture, 'title') || _.get(this.lecture, 'course.title');
      const lectureStartOn = _.get(this.lecture, 'start_on');
      const lectureEndOn = _.get(this.lecture, 'end_on');
      const lectureDate = dateKorean(lectureStartOn);
      const lectureTime = `${time(lectureStartOn)}~${time(lectureEndOn)}`;
      return `${message}${title} / ${lectureDate} ${lectureTime}`;
    },

    membersForMessage() {
      return this.memberIdsForMessage.map(memberId => {
        return this.membersAppLinked.all.find(({ id }) => id === memberId);
      });
    },

    messageTabs() {
      return [
        { value: 'sms', label: 'SMS 보내기' },
        { value: 'message', label: '앱푸시 보내기' },
      ];
    },
  },

  methods: {
    handleMessageTab(tab) {
      this.$emit('changeSendType', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.send-message {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 850px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog__body {
    @include mq(568px) {
      display: grid;
      grid-template-columns: 180px 1fr;
      grid-gap: 8px;
    }
    padding: 10px 20px 30px 20px;
  }

  &__tabs {
    width: 185px;
    margin-bottom: 15px;
  }

  &__message {
    grid-column: span 2;
    color: $color-primary;
    font-size: 12px;
    margin-bottom: 12px;
    padding: 0 7px;
  }

  &__members-list {
    margin-bottom: 12px;

    &-wrapper {
      border: solid #ebebeb;
      border-width: 0 0 1px 0;
      padding: 0 0 10px 0;
      margin: 0 0 10px 0;
      max-height: 20vh;
      overflow: auto;

      @include mq(568px) {
        border-width: 0 1px 0 0;
        padding: 0 10px 0 0;
        margin: 0 10px 0 0;
        max-height: 398px;
      }

      h5 {
        @include flex(row, center, space-between);
        margin-bottom: 12px;
        padding: 0 7px;

        button {
          font-size: 11px;
        }
      }

      ul + h5 {
        border-top: 1px solid #ebebeb;
        margin-top: 12px;
        padding-top: 12px;
      }
    }

    li {
      transition: 0.15s background;

      &:hover {
        background: rgba($color-primary, 0.1);
      }
    }
  }
}
</style>
