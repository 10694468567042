<template>
  <div v-if="useText" class="wrap-checkin-top">
    <div>
      <div class="wrap-top">
        <h3 class="wrap-top__title">{{ mainText }}</h3>
        <h3 class="wrap-top__text">{{ subText }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    useText: Boolean,
    mainText: String,
    subText: String,
  },
};
</script>

<style lang="scss" scoped>
.wrap-checkin-top {
  .wrap-top {
    @include flex(row);
    justify-content: center;
    align-items: center;

    padding: 14px 20px;
    width: 100vw;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;

    &__title {
      font-size: 20px;
      margin-right: 30px;
    }

    &__text {
      font-size: 20px;
      font-weight: 400;
    }

    @include mq-height(960px) {
      padding: 24px 40px;
    }
  }
}
</style>
