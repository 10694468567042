<template>
  <div class="lecture-info">
    <div class="lecture-info__block" v-loading="lectureLoading">
      <div class="lecture-info__block__header">
        <h4>수업정보</h4>
        <div class="lecture-info__block__header__buttons">
          <button v-if="course.type === 'P' && canCreatePrivateLecture(lecture.instructor.id)" @click="handleClickCopyLecture">
            복사
          </button>
          <button v-if="showCloseBookingButton" @click="toggleCloseBooking">
            {{ !bookingClosedAt ? '예약마감' : '예약마감 해제' }}
          </button>
          <button
            v-if="canUpdateLecture(course.type, lecture.instructor.id)"
            @click="$router.push(`/lecture/edit?id=${lecture.id}`)"
          >
            수정
          </button>
          <button
            class="danger"
            v-if="canDeleteLecture(course.type, lecture.instructor.id)"
            v-loading="isDeletingLecture"
            @click="handleClickDeleteLecture"
          >
            삭제
          </button>
        </div>
      </div>

      <div class="lecture-info__block__instructor">
        <label>강사</label>
        <img :src="instructorImageUrl" alt="강사 이미지" />
        <span v-if="instructorLinkDisabled">
          {{ instructorName }}
          <el-tag v-if="isInstructorDeleted" type="danger" size="mini">삭제됨</el-tag>
        </span>
        <a v-else @click="$router.push(`/staffs/detail?id=${lecture.instructor_id}`)">{{ instructorName }}</a>
      </div>

      <div
        v-for="(item, index) in lectureMetaItems"
        :key="index"
        class="lecture-info__block__lecture-meta"
        :class="{ error: item.error }"
      >
        <label>{{ item.label }}</label>
        <span class="lecture-info__item-value">{{ item.value }}</span>
      </div>
    </div>

    <div v-if="course.type === 'G'" v-loading="isSavingCourse || courseLoading" class="lecture-info__block">
      <div class="lecture-info__block__header">
        <h4>클래스정보</h4>
        <div class="lecture-info__block__header__buttons">
          <button v-if="canCloseCourse || canExtendCourse" @click="showChangeCourseEndDateDialog = true">
            종료일변경
          </button>
          <button
            v-if="canUpdateLecture(course.type, lecture.instructor.id)"
            @click="$router.push(`/course/group/edit?id=${course.id}`)"
          >
            수정
          </button>
        </div>
      </div>

      <p class="lecture-info__block__class-info">{{ coursePeriod }}</p>

      <ul v-if="!!courseSchedules.length" class="lecture-info__block__class-schedules">
        <li v-for="schedule in courseSchedules" :key="schedule.id">
          <span>{{ schedule.weekdayIndex === 1 ? schedule.weekday : null }}</span>
          <span>{{ schedule.time }}</span>
        </li>
      </ul>
    </div>

    <ChangeCourseEndDateDialog
      v-if="canCloseCourse || canExtendCourse"
      :show="showChangeCourseEndDateDialog"
      :courseEndOn="courseEndOn"
      :courseStartOn="courseStartOn"
      :courseType="courseType"
      :instructorId="instructorId"
      :canCloseCourse="canCloseCourse"
      :canExtendCourse="canExtendCourse"
      :defaultChangeType="defaultChangeType"
      @close="handleCloseChangeCourseEndDateDialog"
    />

    <el-dialog
      title="종료일 연장 실패"
      class="overlapping-lectures"
      :visible="!!overlappingLectures.length"
      @close="overlappingLectures = []"
    >
      <p class="overlapping-lectures__result">{{ overlappingLectures.length }}개의 수업과 일정이 중복됩니다.</p>
      <li class="overlapping-lectures__list-header">
        <span></span>
        <span>수업명</span>
        <span>수업일</span>
        <span>수업시간</span>
        <span>강사</span>
        <span>룸</span>
      </li>
      <ul class="overlapping-lectures__list">
        <li class="overlapping-lectures__list-item" v-for="lecture in overlappingLectures" :key="lecture.id">
          <i class="el-icon-error"></i>
          <a :href="`/lecture/detail?id=${lecture.id}`" target="_blank" rel="noopenner noreferrer">{{ lecture.title }}</a>
          <span>{{ lecture.start_on | dateKoreanWithWeekday }}</span>
          <span>{{ lecture.start_on | time }} ~ {{ lecture.end_on | time }}</span>
          <span :class="{ highlight: lecture.instructorOverlapped }">
            {{ lecture.staff ? `${lecture.staff.name} 강사` : '' }}
          </span>
          <span :class="{ highlight: lecture.roomOverlapped }">
            {{ lecture.room ? `${lecture.room.name} 룸` : '' }}
          </span>
        </li>
      </ul>
      <div slot="footer" class="overlapping-lectures__buttons">
        <el-button @click="overlappingLectures = []" type="primary">
          확인
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ChangeCourseEndDateDialog from '@/components/Modals/ChangeCourseEndDate';

export default {
  components: { ChangeCourseEndDateDialog },

  data() {
    return {
      isDeletingLecture: false,
      showChangeCourseEndDateDialog: false,
      isSavingCourse: false,
      overlappingLectures: [],
    };
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },
    lecture() {
      return this.$store.getters['lecture/lecture'];
    },
    lectureLoading() {
      return this.$store.getters['lecture/loading'];
    },
    course() {
      return this.$store.getters['lecture/course'];
    },
    courseLoading() {
      return this.$store.getters['lecture/courseLoading'];
    },

    instructorLinkDisabled() {
      return this.isInstructorDeleted || !this.canViewStaff;
    },

    isInstructorDeleted() {
      return !!_.get(this.lecture, 'instructor.deleted_at');
    },

    instructorImageUrl() {
      return this.$utils.getImageUrl(_.get(this.lecture, 'instructor.avatars[0]'), '48x48');
    },

    instructorName() {
      return _.get(this.lecture, 'instructor.profile.name');
    },

    bookingClosedAt() {
      return _.get(this.lecture, 'booking_closed_at');
    },

    bookings() {
      return _.get(this.lecture, 'bookings');
    },

    lectureMetaItems() {
      const { datetime } = this.$filters;
      const {
        current_trainee_count,
        max_trainee,
        min_trainee,
        waiting_trainee_limit,
        booking_start_at,
        booking_end_at,
        booking_cancel_start_at,
        booking_cancel_end_at,
        booking_auto_shift_available_minutes_from_start,
        daily_change_booking_end_at,
        autoclose_at,
      } = this.lecture;
      const { type } = this.course;
      const bookingStart = booking_start_at ? datetime(booking_start_at) : null;
      const bookingEnd = booking_end_at ? datetime(booking_end_at) : null;
      const cancelStart = booking_cancel_start_at ? datetime(booking_cancel_start_at) : null;
      const cancelEnd = booking_cancel_end_at ? datetime(booking_cancel_end_at) : null;
      const autoShiftAvailable = booking_auto_shift_available_minutes_from_start
        ? datetime(booking_auto_shift_available_minutes_from_start)
        : null;

      const bookingAvailable =
        !bookingStart && !bookingEnd ? '-' : !bookingStart ? `${bookingEnd} 까지` : `${bookingStart} ~ ${bookingEnd}`;
      const cancelAvailable =
        !cancelStart && !cancelEnd ? '-' : !cancelStart ? `${cancelEnd} 까지` : `${cancelStart} ~ ${cancelEnd}`;

      const cancelAutoShiftAvailable = autoShiftAvailable ? `${autoShiftAvailable} 까지` : null;

      const dailyBookingChangeEndAt = !daily_change_booking_end_at ? '-' : `${datetime(daily_change_booking_end_at)} 까지`;
      const items = [
        {
          label: '예약 인원',
          value: `${current_trainee_count}명`,
          error: current_trainee_count > max_trainee,
          show: true,
        },
        {
          label: type === 'G' && min_trainee && autoclose_at ? '최대 수강 인원 / 최소 수강 인원' : '최대 수강 인원',
          value: type === 'G' && min_trainee && autoclose_at ? `${max_trainee}명 / ${min_trainee}명` : `${max_trainee}명`,
          show: true,
        },
        {
          label: '예약 대기 가능 인원',
          value: waiting_trainee_limit ? `${waiting_trainee_limit}명` : '무제한',
          show: type === 'G' && this.studioPolicies.weekly_waiting_limit,
        },
        {
          label: '',
          value: `예약 필수 수업`,
          show: type === 'G' && this.studioPolicies.is_enter && this.lecture.is_booking_only,
        },
        {
          label: '예약 가능 시간',
          value: bookingAvailable,
          show: true,
        },
        {
          label: '취소 가능 시간',
          value: cancelAvailable,
          show: true,
        },
        {
          label: '예약대기 자동 예약 시간',
          value: cancelAutoShiftAvailable,
          show: type === 'G',
        },
        {
          label: '당일 예약 변경 가능 시간',
          value: dailyBookingChangeEndAt,
          show: type === 'G',
        },
        {
          label: '폐강 시간',
          value: datetime(autoclose_at),
          show: type === 'G' && min_trainee && autoclose_at,
        },
        {
          label: '체크인 가능 시간',
          value: `${this.moment(this.lecture.enter_start_at).format('YYYY.M.D. HH:mm')} ~ ${this.moment(
            this.lecture.enter_end_at,
          ).format('YYYY.M.D. HH:mm')}`,
          show:
            this.studioPolicies.is_enter &&
            this.lecture.enter_start_at &&
            this.lecture.enter_end_at &&
            (this.studio.grade === 2 || this.studio.usable_enter),
        },
      ];

      return items.filter(({ show }) => show);
    },

    courseEndOn() {
      return _.get(this.course, 'end_date');
    },

    courseStartOn() {
      return _.get(this.course, 'start_date');
    },

    courseType() {
      return _.get(this.course, 'type');
    },

    instructorId() {
      return _.get(this.lecture, 'instructor.id');
    },

    canCloseCourse() {
      const isEndDatePast = this.moment(this.courseEndOn).format('YYYYMMDD') < this.moment().format('YYYYMMDD');

      return this.courseType && this.instructorId && this.canDeleteLecture(this.courseType, this.instructorId) && !isEndDatePast;
    },

    canExtendCourse() {
      const isCourseInstructorDeleted = !!_.get(this.course, 'instructor.deleted_at');

      return this.canCreateGroupLecture(this.instructorId) && !isCourseInstructorDeleted;
    },

    defaultChangeType() {
      return this.canCloseCourse ? 'CLOSE_COURSE' : this.canExtendCourse ? 'EXTEND_COURSE' : null;
    },

    coursePeriod() {
      const { start_date, end_date } = this.course;
      const start = this.$filters.date(start_date);
      const end = this.$filters.date(end_date);
      return `${start} ~ ${end}`;
    },

    courseSchedules() {
      const { schedules } = this.course;
      if (!schedules.length) return [];

      let weekdaysCount = { 월: 0, 화: 0, 수: 0, 목: 0, 금: 0, 토: 0, 일: 0 };
      return schedules.map(({ id, weekday, start_time, end_time }) => {
        weekday = this.$utils.translate.weekdayNumber(weekday);
        weekdaysCount[weekday]++;

        return {
          id,
          weekday,
          time: `${start_time.slice(0, 5)} ~ ${end_time.slice(0, 5)}`,
          weekdayIndex: weekdaysCount[weekday],
        };
      });
    },

    showCloseBookingButton() {
      return this.canUpdateLecture(this.course.type, this.lecture.instructor.id) && this.course.type === 'G';
    },
  },

  methods: {
    /** 수업 복사 클릭시 */
    handleClickCopyLecture() {
      this.$router.push({
        path: '/course/private/create',
        query: {
          ..._.pick(this.lecture, ['instructor_id', 'room_id', 'max_trainee', 'start_on', 'end_on']),
          user_tickets: this.lecture.bookings.map(({ user_ticket_id }) => user_ticket_id).join(','),
          copy: true,
        },
      });
    },

    /** 예약 마감 클릭시 */
    async toggleCloseBooking() {
      const title = !this.bookingClosedAt ? '예약 마감' : '예약 마감 해제';
      const message = !this.bookingClosedAt
        ? '예약 마감한 이후 회원은 앱에서 예약을 할 수 없습니다.<br>예약 마감하시겠습니까?'
        : '예약 마감을 해제하면 회원은 앱에서 다시 예약을 할 수 있습니다.<br>예약 마감을 해제하시겠습니까?';
      const proceed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (proceed) {
        try {
          const lecture = {
            id: this.lecture.id,
            booking_closed_at: !this.bookingClosedAt ? this.moment().format('YYYY-MM-DD HH:mm:ss') : null,
          };
          await this.$api.lecture.update(lecture);
          this.$utils.notify.success(this, '확인', `${title} 되었습니다.`);
          this.$store.dispatch('lecture/getLecture', this.lecture.id);
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
    },

    /** 수업 삭제 클릭시 */
    async handleClickDeleteLecture() {
      const title = '수업 삭제';
      const message = `수업을 삭제하시겠습니까?
        <br><span style="color: red; font-weight: bold;">지난 수업을 삭제할 경우 회원의 예약은 취소되고 횟수는 복구됩니다.</span>`;
      const proceed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (proceed) {
        this.isDeletingLecture = true;
        try {
          await this.$api.lecture.delete([this.lecture.id]);
          this.$utils.notify.success(this, '확인', '수업을 삭제하였습니다');
          this.$router.push('/schedule');
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.isDeletingLecture = false;
        }
      }
    },

    /** 코스 종료일 수정시 강사 및 룸 중복 체크 */
    checkDupCourse(data, prevEndDate, nextEndDate, course) {
      const { moment } = this;

      function dateAndIdFilter(data) {
        return data.filter(
          ({ start_on, course_id }) =>
            moment(start_on) > moment(prevEndDate) &&
            moment(start_on) < moment(nextEndDate).add({ days: 1 }) &&
            course_id !== course.id,
        );
      }

      const staff = dateAndIdFilter(data.staff);
      const room = dateAndIdFilter(data.room);

      const dupRes = room.concat(staff).sort((prev, next) => (moment(prev.start_on) > moment(next.start_on) ? 1 : -1));

      /** 하이라이트 추가 */
      dupRes.map(data => {
        data.instructorOverlapped = data.staff.id === course.instructor_id;
        data.roomOverlapped = data.room_id === course.room_id;
      });

      /** 룸, 강사 동시 중복시 중복 1개 제거 */
      return _.uniqBy(dupRes, 'id');
    },

    /** 코스 종료일 수정 대화상자 종료시 */
    async handleCloseChangeCourseEndDateDialog(result, date, shouldExtendPeriod) {
      this.showChangeCourseEndDateDialog = false;

      if (result && !!date && !this.isSavingCourse) {
        /** 폐강 */
        if (!shouldExtendPeriod) {
          try {
            this.isSavingCourse = true;
            const { start_on } = this.lecture;
            const shouldRedirect = date <= this.moment(start_on).format('YYYY-MM-DD');
            await this.$api.course.close(this.course.id, date);
            this.$utils.notify.success(this, '확인', '클래스 폐강 설정이 완료되었습니다.');

            if (shouldRedirect) {
              this.$router.push('/schedule');
            } else {
              this.$store.dispatch('lecture/getCourse', this.course.id);
            }
          } catch (error) {
            this.$utils.notify.parseError(this, error);
          } finally {
            this.isSavingCourse = false;
          }
          /** 기간 연장 */
        } else {
          try {
            this.isSavingCourse = true;

            let params = {
              instructor_id: this.course.instructor_id,
              start_date: this.course.start_date,
              end_date: date,
              room_id: this.course.room_id,
              schedules: this.course.schedules,
            };
            const res = await this.$api.lecture.checkOverlap(params);
            const filterRes = this.checkDupCourse(res.data, this.course.end_date, date, this.course);

            if (filterRes.length) {
              return (this.overlappingLectures = filterRes);
            }

            await this.$api.course.extend(this.course.id, date);
            this.$utils.notify.success(this, '확인', '클래스 연장 설정이 완료되었습니다.');
            this.$store.dispatch('lecture/getCourse', this.course.id);
          } catch (error) {
            this.$utils.notify.parseError(this, error);
          } finally {
            this.isSavingCourse = false;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-info {
  @include flex(column);

  @include mq(1024px) {
    border-right: 1px solid #f0f0f0;
  }

  &__block {
    border-top: 1px solid #f0f0f0;
    padding: 24px;

    &:first-child {
      border-top: none;
    }

    &__header {
      @include flex(row, center, space-between);
      flex-wrap: wrap;

      h4 {
        color: #000;
        font-size: 17px;
        font-weight: 400;
      }

      &__buttons {
        margin-left: auto;

        button {
          font-size: 12px;
          color: #329af0;
          padding: 4px;

          &.danger {
            color: $color-danger;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__instructor {
      display: grid;
      grid-template-columns: 1fr 48px;
      grid-gap: 10px;
      justify-items: start;
      padding: 16px 0 24px;
      border-bottom: 1px solid #f5f5f5;
      margin-bottom: 8px;

      label {
        color: rgba(#000, 0.4);
        font-size: 12px;
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        grid-row: span 2;
      }

      span,
      a {
        color: rgba(#000, 0.9);
        font-size: 15px;
      }
    }

    &__lecture-meta {
      @include flex(column);
      padding: 8px 0;

      label {
        color: rgba(#000, 0.4);
        font-size: 12px;
        padding-bottom: 4px;
      }

      span {
        color: rgba(#000, 0.9);
        font-size: 15px;
      }
    }

    &__class-info {
      color: rgba(#000, 0.9);
      font-size: 15px;
      margin-top: 8px;
    }

    &__class-schedules {
      margin-top: 11px;
      max-height: 126px;
      overflow: auto;

      li {
        @include flex(row, center);
        font-size: 12px;

        span {
          min-width: 12px;
        }

        span + span {
          margin-left: 16px;
        }
      }
    }
  }
  &__item-value {
    word-spacing: -1px;
  }
}

.overlapping-lectures {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 720px;
  }

  &__result {
    font-weight: bold;
    opacity: 0.6;
  }

  &__list {
    @include flex(column);
    margin-top: 5px;
    max-height: 40vh;
    overflow-y: auto;

    &-header,
    &-item {
      display: grid;
      grid-template-columns: 20px 2fr 3fr repeat(3, 2fr);
      place-items: center;
      padding-bottom: 8px;
    }

    &-header {
      border-bottom: 1px solid #ebebeb;
      margin-top: 8px;
      font-weight: bold;
    }

    &-item {
      i {
        color: $color-danger;
      }

      a,
      span {
        @include ellipsis;
        text-align: center;
        width: 100%;
      }

      span.highlight {
        color: $color-danger;
        font-weight: bold;
      }
    }
  }
}
</style>
