<template>
  <section class="sms-auto-message-list-container">
    <div class="sms-auto-message-list-warning">
      <img src="@/assets/images/icons/icon_warning.svg" alt="warning" />
      <div class="text-wrapper">
        <p class="red">
          1. 문자 발송 시, 통신사 사정에 의해 최대 72시간까지 발송결과에 대한 응답 대기 시간이 소요될 수 있습니다.
        </p>
        <p class="red">
          2. 발송 결과에는 '처리중..'으로 표시되고 통신사에서 처리되었으나, '전송 실패' 건에 한해 차감된 포인트는 환불됩니다.
        </p>
        <p>3. 다음과 같은 경우, 기타 에러로 표시되어 전송에 실패할 수 있습니다.</p>
        <p class="gray">■ 음영 지역, 잘못된 번호, 통신사 수신 거부, 일반 수신 거부, 네크워트 에러</p>
        <p class="gray">■ 수신 번호를 찾을 수 없음, 단말기 일시정지, LMS 미지원 단말기 등 기타 사유</p>
      </div>
    </div>

    <div class="sms-auto-message-list">
      <div class="sms-auto-message-list__header">
        <div class="sms-auto-message-list__header__selects">
          <el-select
            :value="getFilter(FILTERS.LENGTH)"
            :placeholder="FILTERS.LENGTH.placeholder"
            filterable
            :class="{ 'is-filtered': getFilter(FILTERS.LENGTH) }"
            @change="value => setFilter(FILTERS.LENGTH, value)"
          >
            <el-option v-for="option in LENGTH_TYPE_OPTIONS" :key="option.label" :value="option.value" :label="option.label">
              {{ option.label }}
            </el-option>
          </el-select>
          <el-select
            :value="getFilter(FILTERS.TYPE)"
            :placeholder="FILTERS.TYPE.placeholder"
            filterable
            :class="{ 'is-filtered': !!getFilter(FILTERS.TYPE) }"
            @change="value => setFilter(FILTERS.TYPE, value)"
          >
            <el-option
              v-for="(option, optionKey) in autoMessageUnitOptions"
              :key="optionKey"
              :value="option.value || option.message_type"
              :label="option.label || option.unitTypeDescription"
            >
            </el-option>
          </el-select>

          <el-date-picker
            :value="[getFilter(FILTERS.START), getFilter(FILTERS.END)]"
            type="daterange"
            range-separator="-"
            :start-placeholder="FILTERS.START.placeholder"
            :end-placeholder="FILTERS.END.placeholder"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :clearable="false"
            required
            @input="setDateFilter"
          />
          <p v-if="!loadingMessages">발송건수 : {{ meta.total }}건</p>
          <!-- <p>잔여포인트 : {{ point | comma }}P</p> -->
        </div>

        <TextInput
          style="width: 200px"
          v-model="search"
          :placeholder="FILTERS.USER.placeholder"
          prefix-icon="el-icon-search"
          round
          @enter="() => setFilter(FILTERS.USER, search)"
        />
      </div>

      <el-table
        v-loading="loadingMessages"
        :data="messages"
        :row-style="{ cursor: 'pointer', height: '40px' }"
        @row-click="handleClickRow"
        fit
      >
        <template slot="empty">
          <div>{{ loadingMessages ? '' : '데이터 없음' }}</div>
        </template>
        <el-table-column label="발송시간" align="center" min-width="120">
          <template slot-scope="scope">
            {{ getTime(scope.row.created_at) }}
          </template>
        </el-table-column>

        <el-table-column label="알림 종류" align="center" min-width="100">
          <template slot-scope="scope">
            {{ replaceOptionEngToKor(scope.row.message_type) }}
          </template>
        </el-table-column>

        <el-table-column label="메시지" header-align="center" min-width="325">
          <template slot-scope="scope">
            <p class="sms-auto-message-list__title">
              {{ scope.row.context }}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="종류" header-align="center" min-width="50">
          <template slot-scope="scope">
            <p class="sms-auto-message-list__sms-type">
              {{ scope.row.type }}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="받는 사람" align="center" min-width="100">
          <template slot-scope="scope">
            {{ scope.row.member.name }}
          </template>
        </el-table-column>

        <el-table-column header-align="center" align="center" min-width="100">
          <template slot="header">
            <p>처리현황</p>
            <span style="font-size: 10px">
              <span style="color: #69de35">성공</span>
              <span> / </span>
              <span style="color: #fd9f9f">실패</span>
              <span> / </span>
              <span style="color: #cbcbcb">처리 중</span>
            </span>
          </template>

          <template slot-scope="scope">
            <span
              :class="scope.row.status === '전송 성공' ? 'success' : scope.row.status === '처리중..' ? 'processing' : 'failed'"
            >
              {{ scope.row.status !== '전송 성공' && scope.row.status !== '처리중..' ? '전송 실패' : scope.row.status }}
            </span>
            <p style="font-size: 10px">
              <span style="color: #cbcbcb">차감 </span>
              <span>{{ scope.row.status === '전송 성공' ? scope.row.price : '0' }}P </span>
            </p>
          </template>
        </el-table-column>

        <el-table-column label="발송 결과" align="center" min-width="80">
          <template slot-scope="scope">
            <PlainButton size="small" @click.native.stop="handleClickDetail(scope.row)">결과 보기</PlainButton>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="sms-auto-message-list__pagination"
        @current-change="page => setFilter(FILTERS.PAGE, page)"
        v-show="meta.last_page > 1"
        :current-page="meta.current_page"
        :page-size="DEFAULT_LIMIT"
        layout="prev, pager, next"
        :total="meta.total"
      />

      <SmsAutoMessageDetailModal :show="showDetailModal" @close="handleDetailListClose" :detailData="[detailData]" />
    </div>
  </section>
</template>

<script>
import SmsAutoMessageDetailModal from '@/components/Modals/SmsAutoMessageDetail';
import moment from 'moment';

export const FILTERS = {
  LENGTH: {
    key: 'type',
    placeholder: '메세지 종류 전체',
  },
  TYPE: {
    key: 'message-type',
    placeholder: '알림 종류 전체',
  },
  START: {
    key: 'start-date',
    placeholder: '이용 시작일',
    default: moment().format('YYYY-MM-01'),
  },
  END: {
    key: 'end-date',
    placeholder: '이용 종료일',
    default: moment().format('YYYY-MM-DD'),
  },
  USER: {
    key: 'search',
    placeholder: '회원 이름으로 검색',
  },
  PAGE: {
    key: 'page',
    default: 1,
  },
};
const DEFAULT_LIMIT = 10;

const LENGTH_TYPE_OPTIONS = [
  { value: undefined, label: '메시지 종류 전체' },
  { value: 'SMS', label: 'SMS' },
  { value: 'LMS', label: 'LMS' },
];

export default {
  components: { SmsAutoMessageDetailModal },
  data() {
    return {
      loadingMessages: false,
      showDetailModal: false,
      detailData: null,
      messages: [],
      meta: {
        current_page: 1,
        last_page: 1,
        total: 0,
      },
      search: '',
    };
  },

  created() {
    this.FILTERS = FILTERS;
    this.DEFAULT_LIMIT = DEFAULT_LIMIT;
    this.LENGTH_TYPE_OPTIONS = LENGTH_TYPE_OPTIONS;
    this.setQuery = changed => this.$utils.uriQuery.setQuery(this, changed);
    this.getMessages();
  },

  computed: {
    autoMessageUnitOptions() {
      const initialOption = [{ value: '', label: FILTERS.TYPE.placeholder }];
      return [...initialOption, ...this.$store.getters['autoMessageNotification/autoMessageUnitOptions']];
    },
    currentQuery() {
      return this.$utils.uriQuery.getInitializedQuery(this, FILTERS);
    },
  },

  methods: {
    async getMessages() {
      this.loadingMessages = true;
      try {
        const params = { limit: DEFAULT_LIMIT, ...this.currentQuery };
        const { data } = await this.$api.sms.automessage.getAll(params);
        this.messages = data.data;
        this.meta = data.meta;
        this.search = params.search ?? '';
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loadingMessages = false;
      }
    },

    handleClickRow(row) {
      this.$router.push({
        path: `/sms/auto-message/${row.id}`,
        query: this.$route.query,
      });
    },

    handleClickDetail(row) {
      this.detailData = row;
      this.showDetailModal = true;
    },

    handleDetailListClose(close) {
      this.showDetailModal = close;
    },

    replaceOptionEngToKor(option) {
      const { unitTypeDescription } = this.autoMessageUnitOptions.filter(o => o.message_type === option)[0];
      return unitTypeDescription;
    },
    setFilter(option, value) {
      this.setQuery({ [option.key]: value });
      this.getMessages();
    },
    setDateFilter([start, end]) {
      this.setQuery({
        [FILTERS.START.key]: start,
        [FILTERS.END.key]: end,
      });
      this.getMessages();
    },
    getFilter(option) {
      return this.currentQuery[option.key];
    },
    getTime(timeString) {
      return moment(timeString).format('YYYY.MM.DD. HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.messages__body > section.sms-auto-message-list-container {
  padding: 20px 0 40px;
}

.sms-auto-message-list-warning {
  margin-bottom: 30px;
  position: relative;

  img {
    position: absolute;
    top: 17px;
    left: 11px;
    width: 36px;
    height: 36px;
    z-index: 1;
  }

  .text-wrapper {
    padding: 15px 25px 15px 82px;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    background-color: #f6f6f6;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &:before {
      content: '';
      width: 57px;
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px soild #e33c3c;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #e33c3c;
    }
  }

  p {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.8;
  }

  .red {
    color: #bb0000;
  }

  .gray {
    font-weight: normal;
    color: #777777;
    line-height: 1.5;
  }
}

.sms-auto-message-list {
  @include flex(column, center, center);

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    &__selects {
      @include flex(row, center, flex-start);
      flex-wrap: wrap;
      gap: 10px;

      /deep/ .el-select {
        width: 150px;
      }

      /deep/ .el-input__inner {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        height: 40px;
        padding: 0 30px 0 15px;
        color: #5d646b;
      }

      /deep/ .el-date-editor {
        max-width: 250px;
        padding: 0 15px;
      }

      /deep/ .el-range-separator {
        line-height: 3;
      }

      /deep/ .el-range-input:nth-child(2) {
        margin-left: 10px;
      }

      /deep/ .el-range__close-icon {
        display: none;
      }

      p {
        color: #64aeff;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }

  .success {
    color: #69de35;
  }
  .failed {
    color: #fd9f9f;
  }
  .processing {
    color: #cbcbcb;
  }

  &__title {
    @include ellipsis;
  }

  &__sms-type,
  &__sms-send-length {
    text-align: center;
  }

  &__pagination {
    margin-top: 20px;
  }

  &__column-button {
    margin-right: 8px;
  }

  .is-filtered {
    color: $color-primary;

    /deep/ .el-input__inner {
      color: $color-primary;
    }
  }
}
</style>
