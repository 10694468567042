<template>
  <section id="ticket-history">
    <el-table :data="history" height="353" :span-method="spanMethod" fit>
      <el-table-column label="변경일시" width="105" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.created_at | date }}</p>
          <p style="font-size: 12px;">{{ scope.row.created_at | time }}</p>
        </template>
      </el-table-column>

      <el-table-column label="스태프" width="65" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="type" style="white-space: none; word-break: none;">{{
            !scope.row.staff ? '' : scope.row.staff.name
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="종류" width="88" align="center">
        <template slot-scope="scope">
          <span class="type">{{ formatKind(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="내용" header-align="center">
        <template slot-scope="scope">
          <ul>
            <li class="content" v-for="content in formatContent(scope.row)" :key="content.label">
              <label>{{ content.label }}</label>
              <span :class="{ hide: ['created', 'text'].includes(scope.row.kind) }">{{ content.oldValue }}</span>
              <i class="el-icon-caret-right"></i>
              <span>{{ content.newValue }}</span>
            </li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
const KIND = {
  count: '변경',
  created: '발급',
  deducted: '차감',
  payment: '결제',
  upgrade: '업그레이드',
  extension: '연장',
  batch: '일괄변경',
  text: '변경', // -> context 내용 그대로 출력
  installment_payment: '미수금',
  delete: '삭제',
  full_payment: '미수금',
  holding: '수강권정지',
  staff: '담당강사',
  transfer: '양도',
};

const CONTENT_LABEL = {
  // 횟수 정보
  max_coupon: '전체횟수',
  remaining_coupon: '잔여횟수',
  // usable_coupon: '예약가능횟수', -> 사용자가 변경할 수 없는 항목
  remaining_cancel: '취소가능횟수',
  daily_booking_change_limit: '당일예약변경',
  booking_limit_per_week: '주간예약제한',
  booking_limit_per_month: '월간예약제한',

  //기간제 예약 취소 표시
  is_show_cancel_count: '취소가능표시',

  // 기간 정보
  availability_start_at: '이용시작일',
  expire_at: '이용종료일',
  start_holding: '정지시작일',
  end_holding: '정지종료일',

  // 사용여부 정보 (true/false)
  is_shared: '패밀리수강권',
  use_weekly_auto_coupon_balance: '자동차감',

  // 티켓타입 정보
  type: '수강권타입',

  // 결제정보
  amount: '결제금액',
  card_amount: '카드',
  installment_period: '할부개월수',
  cash_amount: '현금',
  wiretransfer_amount: '계좌이체',
  unpaid_amount: '미수금',
  settlement_at: '결제일',

  // 포인트
  point_amount: '포인트',
  reward_point: '포인트 적립',
};

const TYPEOF = {
  // 횟수 정보
  max_coupon: 'count',
  remaining_coupon: 'count',
  remaining_cancel: 'count',
  daily_booking_change_limit: 'count',
  booking_limit_per_week: 'count',
  booking_limit_per_month: 'count',

  // 기간 정보
  availability_start_at: 'date',
  expire_at: 'date',
  start_holding: 'date',
  end_holding: 'date',

  // 사용여부 정보 (true/false)
  is_shared: 'boolean',
  use_weekly_auto_coupon_balance: 'boolean',
  is_show_cancel_count: 'boolean',

  // 티켓타입 정보
  type: 'ticketType',

  // 결제정보
  amount: 'price',
  card_amount: 'price',
  installment_period: 'installment_period',
  cash_amount: 'price',
  wiretransfer_amount: 'price',
  unpaid_amount: 'price',
  settlement_at: 'date',

  //포인트
  reward_point: 'point',
  point_amount: 'point',
};

export default {
  props: {
    title: String,
  },

  computed: {
    formData() {
      return this.$store.getters['ticketEdit/formData'];
    },
    userTicket() {
      return this.formData.userTicket;
    },
    history() {
      return this.formData.history;
    },
    isSaleProduct() {
      const { ticket } = this.userTicket;
      return ticket.type === 'S';
    },

    isRentalOnlyPeriod() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RP';
    },
    isRentalOnlyTime() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RT';
    },
    isRentalAll() {
      const { ticket } = this.userTicket;
      return ticket.type === 'RM';
    },
  },

  methods: {
    spanMethod({ row, columnIndex }) {
      if (!(row.kind === 'text')) return;

      if (columnIndex === 2) {
        return [1, 2];
      }
    },

    formatKind(row) {
      if (row.kind === 'holding') {
        return row.new.end_holding ? `${this.title}정지` : `${this.title} 정지취소`;
      }
      return row.kind === 'text' ? row.context : KIND[row.kind];
    },

    formatContent({ kind, old: oldValues, new: newValues }) {
      if (kind === 'text') return [];
      if (kind === 'staff') {
        return [{ label: '강사변경', oldValue: oldValues.join(', '), newValue: newValues.join(', ') }];
      }

      const keys = Object.keys(CONTENT_LABEL);
      const newValuesKeys = Object.keys(newValues);
      let filteredKeys = keys.filter(key => newValuesKeys.includes(key));

      if (this.isSaleProduct) {
        const removeKeys = ['max_coupon', 'remaining_coupon', 'remaining_cancel', 'expire_at', 'is_show_cancel_count'];
        filteredKeys = filteredKeys.filter(key => !removeKeys.includes(key));
      }
      if (this.isRentalOnlyTime) {
        const removeKeys = ['availability_start_at', 'expire_at', 'is_show_cancel_count', 'remaining_cancel'];
        filteredKeys = filteredKeys.filter(key => !removeKeys.includes(key));
      }
      if (this.isRentalOnlyPeriod) {
        const removeKeys = ['max_coupon', 'remaining_coupon', 'remaining_cancel', 'is_show_cancel_count'];
        filteredKeys = filteredKeys.filter(key => !removeKeys.includes(key));
      }
      if (this.isRentalAll) {
        const removeKeys = ['remaining_cancel', 'is_show_cancel_count'];
        filteredKeys = filteredKeys.filter(key => !removeKeys.includes(key));
      }

      return filteredKeys.reduce((content, key) => {
        const label = CONTENT_LABEL[key];
        const type = TYPEOF[key];
        let oldValue = oldValues[key];
        let newValue = newValues[key];

        switch (type) {
          case 'installment_period':
            oldValue = oldValue == 0 ? null : oldValue == 1 ? '일시불' : oldValue;
            newValue = newValue == 0 ? null : newValue == 1 ? '일시불' : newValue;
            break;

          case 'count':
          case 'price':
            oldValue =
              (key === 'booking_limit_per_week' && oldValue === 0) ||
              (key === 'booking_limit_per_month' && oldValue === 0) ||
              (this.userTicket.ticket.type === 'P' &&
                oldValues['max_coupon'] >= 999 &&
                (key === 'max_coupon' || key === 'remaining_coupon')) ||
              (this.userTicket.ticket.type === 'P' && oldValues['max_cancel'] >= 999 && key === 'remaining_cancel')
                ? '무제한'
                : key === 'daily_booking_change_limit' && oldValue === 0
                ? '사용안함'
                : this.$filters.comma(Math.max(oldValue, 0));
            newValue =
              (key === 'booking_limit_per_week' && newValue === 0) ||
              (key === 'booking_limit_per_month' && newValue === 0) ||
              (this.userTicket.ticket.type === 'P' &&
                newValue >= 999 &&
                (key === 'max_coupon' || key === 'remaining_coupon' || key === 'remaining_cancel'))
                ? '무제한'
                : key === 'daily_booking_change_limit' && newValue === 0
                ? '사용안함'
                : this.$filters.comma(Math.max(newValue, 0));
            break;

          case 'date':
            oldValue = this.$filters.date(oldValue);
            newValue = this.$filters.date(newValue);
            if (oldValue === newValue) {
              if (kind === 'transfer') {
                oldValue = this.$filters.date(oldValue);
                newValue = this.$filters.date(newValue);
              } else {
                oldValue = this.$filters.datetime(oldValues[key]);
                newValue = this.$filters.datetime(newValues[key]);
              }
            }
            break;

          case 'ticketType':
            oldValue = oldValue === 'T' ? '횟수제' : '기간제';
            newValue = newValue === 'T' ? '횟수제' : '기간제';
            break;

          case 'boolean':
            oldValue = oldValue ? 'Y' : 'N';
            newValue = newValue ? 'Y' : 'N';
            break;

          case 'point':
            oldValue = this.$filters.comma(oldValue);
            newValue = this.$filters.comma(newValue);
        }

        if (oldValue !== newValue) {
          if (kind === 'installment_payment' || kind === 'full_payment') {
            if (label !== '미수금') {
              oldValue = null;
              content.push({ label, oldValue, newValue });
            }
          } else if (kind === 'delete') {
            content.push({ label, oldValue, newValue: '삭제' });
          } else {
            content.push({ label, oldValue, newValue });
          }
        } else {
          if (kind === 'transfer' && key in oldValues) {
            // 취소가능표시는 기간제 수강권의 경우에만 표시
            const except =
              (this.userTicket.ticket.type === 'T' || this.userTicket.ticket.available_class_type === 'I') &&
              key === 'is_show_cancel_count' &&
              oldValues[key] === false;

            if (!except) {
              content.push({ label, oldValue, newValue });
            }
          }
        }

        const resultContent = content.filter(({ label, oldValue, newValue }) => {
          if (label !== '포인트 적립') return true;
          if (!oldValue && !newValue) return false;
          return true;
        });

        return resultContent;
      }, []);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-table .hide-row {
  display: none;
}
#ticket-history {
  .el-tooltip .type {
    white-space: initial;
  }
  .content {
    display: grid;
    grid-template-columns: 72px 1fr auto 1fr;
    grid-gap: 4px;
    align-items: center;
    text-align: center;

    font-size: 13px;

    span {
      text-align: center;
      word-break: keep-all;

      &.hide {
        color: transparent;
      }
    }
  }
}
</style>
