<template>
  <el-table :data="counsels" @row-click="handleRowClick" class="counsel-list" fit>
    <el-table-column prop="name" label="이름" width="100" align="center" />

    <el-table-column label="상담일자" width="120" align="center">
      <template slot-scope="scope">
        {{ getCounselDate(scope.row) }}
      </template>
    </el-table-column>

    <el-table-column label="상담내용" min-width="300" header-align="center">
      <template slot-scope="scope">
        <el-tag v-if="scope.row.channel" size="mini" disable-transitions>
          {{ getChannelLabel(scope.row) }}
        </el-tag>
        <p class="counsel-list__contents">{{ scope.row.contents }}</p>
      </template>
    </el-table-column>

    <el-table-column label="담당스태프" width="140" header-align="center">
      <template slot-scope="scope">
        <div class="counsel-list__staff">
          <img
            class="counsel-list__profile-image"
            :src="$utils.getImagePath(_.get(scope.row, 'staff.image'), 24)"
            :alt="_.get(scope.row, 'staff.name') || '-'"
          />
          {{ _.get(scope.row, 'staff.name') || '-' }}
        </div>
      </template>
    </el-table-column>

    <el-table-column
      v-if="canCreateMembers || canDeleteCounsel"
      fixed="right"
      label="관리"
      width="150"
      header-align="center"
      align="right"
    >
      <template slot-scope="scope">
        <PlainButton
          v-if="canCreateMembers"
          class="counsel-list__button"
          @click.native.stop="
            !scope.row.member_id ? handleClickNewMember(scope.row.id) : handleClickViewMember(scope.row.member_id)
          "
          round
          :type="!scope.row.member_id ? 'primary' : 'info'"
          >{{ !scope.row.member_id ? '회원등록' : '회원보기' }}</PlainButton
        >
        <PlainButton
          v-if="canDeleteCounsel"
          class="counsel-list__button"
          @click.native.stop="handleClickDelete(scope.row.id, scope.$index)"
          type="danger"
          round
          >삭제</PlainButton
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    counsels: Array,
    channelOptions: Array,
    handleRowClick: Function,
    handleClickDelete: Function,
  },

  methods: {
    getCounselDate(row) {
      return this.$filters.date(row.start_on || row.created_at);
    },

    getChannelLabel(row) {
      return this.channelOptions.find(option => option.value === row.channel).label;
    },

    handleClickNewMember(id) {
      this.$router.push(`/users/create?cId=${id}`);
    },

    handleClickViewMember(id) {
      this.$router.push(`/users/detail?id=${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.counsel-list {
  /deep/ .el-table__row {
    cursor: pointer;
  }

  &__contents {
    white-space: pre-line;
  }

  &__staff {
    @include flex(row, center, flex-start);

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  &__button + &__button {
    margin-left: 8px;
  }
}
</style>
