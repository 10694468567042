<template>
  <div class="select-ticket">
    <div
      class="select-ticket__item"
      v-for="userTicket in filteredUserTickets"
      :key="userTicket.id"
      @click="$emit('select', userTicket)"
    >
      <img :src="icon" alt="check-icon" />
      <UserTicketCard :userTicket="userTicket" clickable />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userTickets: { type: Array, required: true },
    selectedUserTicket: { type: Object, default: null },
  },

  computed: {
    filteredUserTickets() {
      return !this.selectedUserTicket ? this.userTickets : [this.selectedUserTicket];
    },

    icon() {
      return !this.selectedUserTicket
        ? require('@icons/checkbox_circle_unchecked.svg')
        : require('@icons/checkbox_circle_checked.svg');
    },
  },
};
</script>

<style lang="scss" scoped>
.select-ticket {
  @include flex(row, center, flex-start);
  flex-wrap: wrap;

  &__item {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 8px;
    place-items: center;
    padding: 12px;
  }
}
</style>
