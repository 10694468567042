<template>
  <MainLayout>
    <content-header-tabs
      :tabs="headerTabs"
      :activeTab="$route.path"
      :exceldownloading="exceldownloading"
      :handleExcelDownloadClick="handleDownloadExcel"
    />

    <ticket-history-list-filter
      :rangeFilter="rangeFilter"
      @search-change="searchChange"
      @submit="onSubmit"
      @change-staff-filters="changeStaffFilters"
      :staffList="staffList"
    />

    <ticket-history-list v-loading="loading" :histories="histories" @sort-change="handleSort" :formatContent="formatContent" />

    <el-pagination
      class="list__pagination"
      layout="prev, pager, next, sizes"
      :current-page="page"
      :page-size="limit"
      :page-sizes="[10, 15, 20, 50]"
      :total="total || 1"
      @size-change="sizeChange"
      @current-change="pageChange"
    />
  </MainLayout>
</template>

<script>
import TicketHistoryList from '@components/Members/TicketHistoryList';
import TicketHistoryListFilter from '@components/Members/TicketHistoryListFilter';
import { TICKET_HISTORY_KIND, CONTENT_LABEL, TYPEOF } from '@constants';

export default {
  components: {
    TicketHistoryList,
    TicketHistoryListFilter,
  },

  data() {
    return {
      exceldownloading: false,
      headerTabs: [
        { path: '/users', label: '회원' },
        { path: '/users/counseling', label: '상담고객' },
        { path: '/users/ticket-history', label: '수강권 정보 변경이력' },
      ],
      staffList: [],
    };
  },

  watch: {
    rangeFilter() {
      this.pageInit();
    },

    page() {
      this.getHistory();
    },

    limit() {
      this.getHistory();
    },

    staffFilterList() {
      this.pageInit();
    },
  },

  created() {
    this.getStaffFilterList();
  },

  computed: {
    rangeFilter() {
      return this.$store.getters['ticketHistory/rangeFilter'];
    },

    histories() {
      return this.$store.getters['ticketHistory/histories'];
    },

    total() {
      return this.$store.getters['ticketHistory/total'];
    },

    params() {
      return this.$store.getters['ticketHistory/params'];
    },

    limit() {
      return this.$store.getters['ticketHistory/limit'];
    },

    page() {
      return this.$store.getters['ticketHistory/page'];
    },

    searchName() {
      return this.$store.getters['ticketHistory/searchName'];
    },

    staffFilterList() {
      return this.$store.getters['ticketHistory/staffFilterList'];
    },

    sortType() {
      return this.$store.getters['ticketHistory/sortType'];
    },

    loading() {
      return this.$store.getters['ticketHistory/loading'];
    },
  },

  methods: {
    async getHistory() {
      try {
        const startDate = this.rangeFilter.dates[0];
        const endDate = this.rangeFilter.dates[1];

        const monthsLimit = this.moment(endDate).diff(this.moment(startDate), 'months');

        if (monthsLimit >= 6) {
          this.$utils.notify.error(this, '오류', '최대 6개월까지 선택 가능합니다');
          return;
        }

        const params = {
          start_date: startDate,
          end_date: endDate,
          page: this.page,
          limit: this.limit,
          sort_type: this.sortType === 'ascending' ? 'asc' : 'desc',
          sort_target: 'created_at',
          member_name: this.searchName,
          staff_id: this.staffFilterList.join(),
        };

        this.$store.commit('ticketHistory/SET_PARAMS', params);
        this.$store.dispatch('ticketHistory/getHistories', params);
      } catch (error) {
        this.$utils.notify.error(this, '오류', '목록을 불러올 수 없습니다. 페이지를 새로고침 해주세요.');
      }
    },

    async getStaffFilterList() {
      try {
        const res = await this.$api.staff.getAllWithDeleted();
        const list = res.data.deletedStaffs;
        const deleteStaffSortList = list.sort((a, b) => {
          if (!a.deleted_at && b.deleted_at) return -1;
          else if (a.deleted_at && !b.deleted_at) return 1;
          else return 0;
        });
        this.staffList = deleteStaffSortList;
      } catch (error) {
        this.$utils.notify.error(this, '오류', '문제가 발생했습니다. 페이지를 새로고침 해주세요.');
      }
    },

    handleSort(sort) {
      this.$store.commit('ticketHistory/SET_SORT_TYPE', sort);
      this.getHistory();
    },

    sizeChange(e) {
      this.$store.commit('ticketHistory/SET_SIZE', e);
    },

    pageChange(e) {
      this.$store.commit('ticketHistory/SET_PAGE', e);
    },

    searchChange(text) {
      this.$store.commit('ticketHistory/SET_SEARCH_NAME', text);
    },

    onSubmit() {
      this.getHistory();
    },

    changeStaffFilters(staff) {
      this.$store.commit('ticketHistory/SET_STAFF_FILTER_LIST', staff);
      this.getHistory();
    },

    async handleDownloadExcel() {
      try {
        this.exceldownloading = true;

        const allData = [];

        const res = await this.$api.userTicket.getStudioTicketHistory({ ...this.params, page: 1 });
        allData.push(...res.data.data);

        const currentPage = res.data.meta.current_page;
        const lastPage = res.data.meta.last_page;
        const pages = [];

        if (currentPage !== lastPage) {
          for (let i = 2; i <= lastPage; i++) {
            pages.push(i);
          }

          const promiseAllRequest = await Promise.all(
            pages.map(page => {
              return this.$api.userTicket.getStudioTicketHistory({ ...this.params, page });
            }),
          );

          const promiseAllFilter = promiseAllRequest.map(res => res.data.data);
          allData.push(...promiseAllFilter.flat());
        }

        const json = this.formatJSON(allData);

        /** 제목 생성 */
        const startDate = this.rangeFilter.dates[0];
        const endDate = this.rangeFilter.dates[1];
        let range = startDate;
        if (startDate !== endDate) {
          range += `_${endDate}`;
        }
        const title = `수강권정보변경이력_${range}.xlsx`;

        this.$utils.downloadExcel(json, title);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.exceldownloading = false;
      }
    },

    formatJSON(allHistories) {
      const format = allHistories.map(history => {
        const kind = _.get(history, 'kind');
        const currentKind = kind === 'text' ? _.get(history, 'context') : TICKET_HISTORY_KIND[kind];

        /** 변경 전, 후 데이터 가공 */
        const resultData = this.formatContent(history);
        const currentTitle = resultData.map(data => data.label).join('\n');
        const beforeData = resultData.map(data => data.oldValue).join('\n');
        const afterData = resultData.map(data => data.newValue).join('\n');

        return {
          변경일시: _.get(history, 'created_at'),
          '변경한 사람': _.get(history, 'staff_name'),
          회원명: _.get(history, 'member_name'),
          수강권명: _.get(history, 'ticket_title'),
          종류: currentKind,
          '변경전-항목': currentTitle,
          '변경전-데이터': beforeData,
          '변경후-항목': currentTitle,
          '변경후-데이터': afterData,
        };
      });
      return format;
    },

    getKeys(value) {
      return value ? Object.keys(value) : null;
    },

    formatContent({ kind, old: oldValues, new: newValues }) {
      if (kind === 'text') return [];
      if (kind === 'staff') {
        return [{ label: '강사변경', oldValue: oldValues.join(', '), newValue: newValues.join(', ') }];
      }

      const keys = Object.keys(CONTENT_LABEL);
      const newValuesKeys = Object.keys(newValues);
      let filteredKeys = keys.filter(key => newValuesKeys.includes(key));

      return filteredKeys.reduce((content, key) => {
        const label = CONTENT_LABEL[key];
        const type = TYPEOF[key];
        let oldValue = oldValues[key];
        let newValue = newValues[key];

        switch (type) {
          case 'installment_period':
            oldValue = oldValue == 0 ? null : oldValue == 1 ? '일시불' : oldValue;
            newValue = newValue == 0 ? null : newValue == 1 ? '일시불' : newValue;
            break;

          case 'count':
          case 'price':
            oldValue =
              (key === 'booking_limit_per_week' && oldValue === 0) ||
              (key === 'booking_limit_per_month' && oldValue === 0) ||
              (oldValues['max_coupon'] >= 999 && (key === 'max_coupon' || key === 'remaining_coupon')) ||
              (oldValues['max_cancel'] >= 999 && key === 'remaining_cancel')
                ? '무제한'
                : key === 'daily_booking_change_limit' && oldValue === 0
                ? '사용안함'
                : this.$filters.comma(Math.max(oldValue, 0));
            newValue =
              (key === 'booking_limit_per_week' && newValue === 0) ||
              (key === 'booking_limit_per_month' && newValue === 0) ||
              (newValue >= 999 && (key === 'max_coupon' || key === 'remaining_coupon' || key === 'remaining_cancel'))
                ? '무제한'
                : key === 'daily_booking_change_limit' && newValue === 0
                ? '사용안함'
                : this.$filters.comma(Math.max(newValue, 0));
            break;

          case 'date':
            oldValue = this.$filters.date(oldValue);
            newValue = this.$filters.date(newValue);
            if (oldValue === newValue) {
              if (kind === 'transfer') {
                oldValue = this.$filters.date(oldValue);
                newValue = this.$filters.date(newValue);
              } else {
                oldValue = this.$filters.datetime(oldValues[key]);
                newValue = this.$filters.datetime(newValues[key]);
              }
            }
            break;

          case 'ticketType':
            oldValue = oldValue === 'T' ? '횟수제' : '기간제';
            newValue = newValue === 'T' ? '횟수제' : '기간제';
            break;

          case 'boolean':
            oldValue = oldValue ? 'Y' : 'N';
            newValue = newValue ? 'Y' : 'N';
            break;

          case 'point':
            oldValue = this.$filters.comma(oldValue);
            newValue = this.$filters.comma(newValue);
        }

        if (oldValue !== newValue) {
          if (kind === 'installment_payment' || kind === 'full_payment') {
            if (label !== '미수금') {
              oldValue = null;
              content.push({ label, oldValue, newValue });
            }
          } else if (kind === 'delete') {
            content.push({ label, oldValue, newValue: '삭제' });
          } else {
            content.push({ label, oldValue, newValue });
          }
        } else {
          if (kind === 'transfer' && key in oldValues) {
            // 취소가능표시는 기간제 수강권의 경우에만 표시
            const except = key === 'is_show_cancel_count' && oldValues[key] === false;

            if (!except) {
              content.push({ label, oldValue, newValue });
            }
          }
        }

        const resultContent = content.filter(({ label, oldValue, newValue }) => {
          if (label !== '포인트 적립') return true;
          if (!oldValue && !newValue) return false;
          return true;
        });

        return resultContent;
      }, []);
    },

    formatBeforeAfterTitle(newValue) {
      const beforeTitleKeys = this.getKeys(newValue);
      const beforeTitleList = beforeTitleKeys
        ?.map(key => key !== 'usable_coupon' && key !== 'max_cancel' && CONTENT_LABEL[key])
        .filter(Boolean);
      return beforeTitleList?.join('\n');
    },

    formatBeforeAfterData(value) {
      const titleKeys = this.getKeys(value);
      const filterTitleList = titleKeys?.filter(key => key !== 'usable_coupon' && key !== 'max_cancel');
      const matchTitleToValue = filterTitleList?.map(title => {
        const currentValue = value[title];

        const regex = /\d{4}-\d{2}-\d{2}/;
        if (regex.test(currentValue)) {
          const formatDate = currentValue.slice(0, 10);
          return formatDate;
        }

        if (title === 'is_shared' || typeof currentValue === 'boolean') {
          return currentValue ? 'Y' : 'N';
        }
        return currentValue;
      });
      return matchTitleToValue?.join('\n');
    },

    pageInit() {
      this.$store.commit('ticketHistory/SET_PAGE', 1);
      this.getHistory();
    },
  },
};
</script>

<style lang="scss" scoped>
.list__pagination {
  @include flex(row, center, center);
  margin-top: 30px;

  /deep/ .el-pagination__sizes {
    margin: 0;

    /deep/ .el-input__inner {
      @extend %input-default;
      border-color: #dcdfe6;
      height: 36px;
    }

    /deep/ .el-input__suffix {
      transform: scale(1);
      padding-right: 8px;
    }
  }
}
</style>
